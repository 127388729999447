import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  faArrowLeft,
  faArrowRight,
  faCirclePlus,
  faEdit,
  faTicket,
  faTrash,
  faUsers
} from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { EnumEnquiryType } from 'src/app/enums/enquiry-type.enum';
import { EnumLanguage } from 'src/app/enums/language.enum';
import { IEnquiryFlight } from 'src/app/interfaces/enquiry-flight.interface';
import { IEnquiry } from 'src/app/interfaces/enquiry.interface';
import { IFlightBriefCatering } from 'src/app/interfaces/flight-brief-catering.interface';
import {
  EnumFlightBriefPassengerCivility,
  getFlightBriefPassengerCivilityLabel,
  IFlightBriefPassengerInfo
} from 'src/app/interfaces/flight-brief.interface';
import { generateRandomId } from 'src/app/misc.utils';
import { AclService } from 'src/app/services/acl.service';
import { FlightBriefCateringService } from 'src/app/services/flight-brief-caterings/flight-brief-caterings.service';

@Component({
  selector: 'app-enquiry-flight-edit-passengers',
  templateUrl: './enquiry-flight-edit-passengers.component.html',
  styleUrls: ['./enquiry-flight-edit-passengers.component.scss']
})
export class EnquiryFlightEditPassengersComponent implements OnInit, OnChanges, OnDestroy {
  @Input() enquiry: IEnquiry | null = null;
  @Input() enquiryFlight: IEnquiryFlight | null = null;

  @Output() goPrev: EventEmitter<void> = new EventEmitter();
  @Output() saveAndGoNext: EventEmitter<IEnquiryFlight> = new EventEmitter();

  @ViewChild('modalFlightBriefCatering', { static: false })
  modalFlightBriefCateringElement: ElementRef;

  form: FormGroup = this.resetForm();

  faArrowLeft = faArrowLeft;
  faArrowRight = faArrowRight;
  faUsers = faUsers;
  faTrash = faTrash;
  faCirclePlus = faCirclePlus;
  faEdit = faEdit;

  EnumEnquiryType = EnumEnquiryType;
  EnumLanguage = EnumLanguage;
  EnumAcl = EnumAcl;

  getFlightBriefPassengerCivilityLabel = getFlightBriefPassengerCivilityLabel;

  flightBriefCaterings: IFlightBriefCatering[] = [];
  displayedFlightBriefCaterings: IFlightBriefCatering[] = [];
  loadingFlightBriefCaterings: boolean = false;
  translationObj: { [key: string]: any } = {};

  editingFlightBriefCatering: IFlightBriefCatering | null = null;

  subscriptionsForCatering = new Subscription();
  subscriptionsTranslations = new Subscription();

  constructor(
    private flightBriefCateringService: FlightBriefCateringService,
    private translateService: TranslateService,
    private aclService: AclService
  ) {}

  ngOnInit(): void {
    this.applyEnquiryFlight();

    this.loadFlightBriefCaterings();
    this.loadTranslation();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['enquiryFlight'] &&
      (!changes['enquiryFlight'].previousValue ||
        changes['enquiryFlight'].previousValue !== changes['enquiryFlight'].currentValue)
    ) {
      this.applyEnquiryFlight();
    }
  }

  ngOnDestroy(): void {
    window['$']('.tooltip').remove();

    this.subscriptionsForCatering.unsubscribe();
    this.subscriptionsTranslations.unsubscribe();
  }

  get pax(): FormControl {
    return this.form.get('pax') as FormControl;
  }

  get boardingMinutesBeforeDeparture(): FormControl {
    return this.form.get('boardingMinutesBeforeDeparture') as FormControl;
  }

  get passengers(): FormArray {
    return this.form.get('passengers') as FormArray;
  }

  getPassenger(i: number): FormGroup {
    return this.passengers.at(i) as FormGroup;
  }

  getPassengerField(i: number, field: string): FormControl {
    return this.getPassenger(i).get(field) as FormControl;
  }

  get flightBriefCateringId(): FormControl {
    return this.form.get('flightBriefCateringId') as FormControl;
  }

  get catering(): FormControl {
    return this.form.get('catering') as FormControl;
  }

  get luggageQty(): FormControl {
    return this.form.get('luggageQty') as FormControl;
  }

  get luggagesWeight(): FormControl {
    return this.form.get('luggagesWeight') as FormControl;
  }

  get luggageIsCustom(): FormControl {
    return this.form.get('luggageIsCustom') as FormControl;
  }

  get luggages(): FormControl {
    return this.form.get('luggages') as FormControl;
  }

  getCivilities(): EnumFlightBriefPassengerCivility[] {
    return Object.values(EnumFlightBriefPassengerCivility);
  }

  applyEnquiryFlight(): void {
    this.form = this.resetForm();

    if (this.enquiryFlight) {
      for (const field in this.form.value) {
        if (typeof this.enquiryFlight[field] !== 'undefined') {
          switch (field) {
            default:
              this.form.get(field).setValue(this.enquiryFlight[field]);
              break;
            case 'passengers':
              this.passengers.clear();

              for (const passenger of this.enquiryFlight.passengers) {
                this.addPassengerAndSet(passenger);
              }

              break;
            case 'flightBriefCateringId':
              this.enquiryFlight = this.refreshFlightBriefCateringId(this.enquiryFlight);

              this.form.get(field).setValue(this.enquiryFlight[field]);
              break;
          }
        }
      }

      this.refreshDisplayedFlightBriefCaterings();

      this.form.enable();
    }
  }

  refreshFlightBriefCateringId(enquiryFlight: IEnquiryFlight): IEnquiryFlight {
    // Fix for previous system

    if (this.flightBriefCaterings.length) {
      for (const flightBriefCatering of this.flightBriefCaterings) {
        if (flightBriefCatering.id !== enquiryFlight.flightBriefCateringId) {
          for (const language in flightBriefCatering.textByLanguage) {
            if (
              flightBriefCatering.textByLanguage[language] === enquiryFlight.flightBriefCateringId
            ) {
              enquiryFlight.flightBriefCateringId = flightBriefCatering.id;
              break;
            }
          }
        }
      }
    }

    return enquiryFlight;
  }

  resetForm(): FormGroup {
    return new FormGroup({
      pax: new FormControl(null),
      boardingMinutesBeforeDeparture: new FormControl(20),
      passengers: new FormArray([]),
      flightBriefCateringId: new FormControl(null),
      catering: new FormControl(null),
      luggageQty: new FormControl(1),
      luggagesWeight: new FormControl(12),
      luggageIsCustom: new FormControl(false),
      luggages: new FormControl(null)
    });
  }

  addPassenger(): void {
    this.passengers.push(
      new FormGroup({
        id: new FormControl(generateRandomId(), [Validators.required]),
        civility: new FormControl(EnumFlightBriefPassengerCivility.mr, Validators.required),
        firstname: new FormControl('', [Validators.required]),
        lastname: new FormControl('', [Validators.required])
      })
    );
  }

  addPassengerAndSet(passenger: IFlightBriefPassengerInfo): void {
    this.addPassenger();

    const i: number = this.passengers.length - 1;

    for (const field in this.getPassenger(i).value) {
      if (typeof passenger[field] !== 'undefined') {
        this.getPassengerField(i, field).setValue(passenger[field]);
      }
    }
  }

  removePassenger(i: number): void {
    if (confirm('Êtes-vous sûr de vouloir supprimer cette ligne ?')) {
      this.passengers.removeAt(i);
    }
  }

  prev(): void {
    this.goPrev.emit();
  }

  saveAndNext(): void {
    if (this.form.valid) {
      this.saveAndGoNext.emit(this.form.value);
    }
  }

  loadFlightBriefCaterings(): void {
    this.loadingFlightBriefCaterings = true;

    if (this.subscriptionsForCatering) {
      this.subscriptionsForCatering.unsubscribe();
    }

    this.subscriptionsForCatering = new Subscription();

    this.subscriptionsForCatering.add(
      this.flightBriefCateringService
        .getAll()
        .subscribe((flightBriefCaterings: IFlightBriefCatering[]) => {
          this.flightBriefCaterings = flightBriefCaterings;

          this.refreshDisplayedFlightBriefCaterings();

          this.loadingFlightBriefCaterings = false;
        })
    );
  }

  refreshDisplayedFlightBriefCaterings(): void {
    this.displayedFlightBriefCaterings = [];

    if (this.enquiryFlight) {
      for (const flightBriefCatering of this.flightBriefCaterings) {
        if (flightBriefCatering.enquiryTypes?.includes(this.enquiryFlight.enquiryType)) {
          this.displayedFlightBriefCaterings.push(flightBriefCatering);
        }
      }
    }
  }

  updatedFlightBriefCateringId(): void {
    if (this.flightBriefCateringId.value === 'custom') {
      this.catering.setValue(null);
    } else {
      this.catering.setValue(this.flightBriefCateringId.value);
    }

    this.catering.updateValueAndValidity();
  }

  updateLuggageQty(inc: number = 1): void {
    this.luggageQty.setValue(this.luggageQty.value + inc);

    this.refreshLuggageText();
  }

  updateLuggageWeight(inc: number = 1): void {
    this.luggagesWeight.setValue(this.luggagesWeight.value + inc);

    this.refreshLuggageText();
  }

  refreshLuggageText(): void {
    if (!this.luggageIsCustom.value) {
      this.luggages.setValue(
        this.luggageQty.value +
          'X' +
          this.luggagesWeight.value +
          this.translationObj[EnumLanguage.fr]?.FLIGHT_BRIEF.KG_BY_PAX
      );
    }
  }

  updateLuggageIsCustom(): void {
    if (!this.luggageIsCustom.value) {
      this.refreshLuggageText();
    } else {
      this.luggages.setValue(null);
    }
  }

  loadTranslation(): void {
    if (this.subscriptionsTranslations) {
      this.subscriptionsTranslations.unsubscribe();
    }

    this.subscriptionsTranslations = new Subscription();

    this.subscriptionsTranslations.add(
      this.translateService.getTranslation(EnumLanguage.fr).subscribe((translationObj: any) => {
        this.translationObj[EnumLanguage.fr] = translationObj;

        this.refreshLuggageText();
      })
    );
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  addFlightBriefCatering(): void {
    window['$'](this.modalFlightBriefCateringElement.nativeElement).modal('show');
  }

  editFlightBriefCatering(): void {
    if (this.flightBriefCateringId.value) {
      for (const flightBriefCatering of this.flightBriefCaterings) {
        if (flightBriefCatering.id === this.flightBriefCateringId.value) {
          this.editingFlightBriefCatering = flightBriefCatering;
          break;
        }
      }
    }

    window['$'](this.modalFlightBriefCateringElement.nativeElement).modal('show');
  }

  dismissFlightBriefCateringModal(newId: string | null = null): void {
    if (newId) {
      this.flightBriefCateringId.setValue(newId);
      this.flightBriefCateringId.updateValueAndValidity();
    }

    window['$'](this.modalFlightBriefCateringElement.nativeElement).modal('hide');
  }
}
