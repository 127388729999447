<ul class="nav nav-pills mb-3 justify-content-center" role="tablist">
    <li class="nav-item" role="presentation" *ngFor="let period of getPeriods()">
        <button
            class="nav-link"
            [class.active]="currentPeriod === period"
            [id]="'department-period-' + period"
            type="button"
            [attr.aria-selected]="currentPeriod === period"
            [innerText]="getPeriodLabel(period)"
            (click)="changeCurrentPeriod(period)"
        ></button>
    </li>
</ul>

<div class="tab-content">
    <div
        class="tab-pane fade"
        *ngFor="let period of getPeriods()"
        [class.show]="currentPeriod === period"
        [class.active]="currentPeriod === period"
        [id]="'department-period-' + period"
        role="tabpanel"
        [attr.aria-labelledby]="'department-period-' + period"
        tabindex="0"
    >
        <div class="card mb-4" *ngIf="loading || loadingYearsOnly">
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th></th>
                                <th class="text-end">Marge</th>
                                <th class="text-center">Parts</th>
                                <th class="text-center">Nb</th>
                                <th class="text-center">Parts</th>
                                <th class="text-center">Clotûrés</th>
                                <th class="text-end">CA</th>
                                <th class="text-center">Parts</th>
                                <th class="text-center">Marges</th>
                                <th class="text-center">Workforces</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="loading">
                            <tr *ngFor="let row of [].constructor(12)">
                                <td *ngFor="let number of [].constructor(10)">
                                    <div class="skeleton-text"></div>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot *ngIf="loading">
                            <tr>
                                <th *ngFor="let number of [].constructor(10)">
                                    <div class="skeleton-text"></div>
                                </th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>

        <ng-container *ngIf="!(loading || loadingYearsOnly)">
            <div class="card mb-4" *ngFor="let financialReportDepartmentAllAndTypes of getData()">
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th
                                        [innerText]="financialReportDepartmentAllAndTypes.label"
                                    ></th>
                                    <th class="text-end">Marge</th>
                                    <th class="text-center">Parts</th>
                                    <th class="text-center">Nb</th>
                                    <th class="text-center">Parts</th>
                                    <th class="text-center">Clotûrés</th>
                                    <th class="text-end">CA</th>
                                    <th class="text-center">Parts</th>
                                    <th class="text-center">Marges</th>
                                    <th class="text-center">Workforces</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let enquiryType of getEnquiryTypes()"
                                    [class.text-muted]="
                                        !financialReportDepartmentAllAndTypes
                                            .financialReportDepartmentsByType[enquiryType]
                                    "
                                    [class.enquiry-business]="
                                        enquiryType ===
                                        EnumFinancialReportDepartmentEnquiryType.business
                                    "
                                    [class.enquiry-commercial]="
                                        enquiryType ===
                                        EnumFinancialReportDepartmentEnquiryType.commercial
                                    "
                                    [class.enquiry-cargo]="
                                        enquiryType ===
                                        EnumFinancialReportDepartmentEnquiryType.cargo
                                    "
                                    [class.enquiry-sport]="
                                        enquiryType ===
                                        EnumFinancialReportDepartmentEnquiryType.sport
                                    "
                                    [class.enquiry-helico]="
                                        enquiryType ===
                                        EnumFinancialReportDepartmentEnquiryType.helico
                                    "
                                    [class.enquiry-business-helico]="
                                        enquiryType ===
                                        EnumFinancialReportDepartmentEnquiryType.businessHelico
                                    "
                                >
                                    <td
                                        [innerText]="
                                            getFinancialReportDepartmentEnquiryTypeLabel(
                                                enquiryType
                                            )
                                        "
                                    ></td>
                                    <td
                                        class="text-end"
                                        [innerText]="
                                            formatPrice(
                                                financialReportDepartmentAllAndTypes
                                                    .financialReportDepartmentsByType[enquiryType]
                                                    ?.marginHT || 0,
                                                currentCurrency ?? getDefaultCurrency()
                                            )
                                        "
                                    ></td>
                                    <td
                                        class="text-center"
                                        [innerText]="
                                            (financialReportDepartmentAllAndTypes.virtualFields[
                                                enquiryType
                                            ][
                                                EnumFinancialReportDepartmentVirtualField
                                                    .marginHTPercent
                                            ] || 0) + '%'
                                        "
                                    ></td>
                                    <td
                                        class="text-center"
                                        [innerText]="
                                            financialReportDepartmentAllAndTypes
                                                .financialReportDepartmentsByType[enquiryType]
                                                ?.nbContracts || 0
                                        "
                                    ></td>
                                    <td
                                        class="text-center"
                                        [innerText]="
                                            (financialReportDepartmentAllAndTypes.virtualFields[
                                                enquiryType
                                            ][
                                                EnumFinancialReportDepartmentVirtualField
                                                    .nbContractsPercent
                                            ] || 0) + '%'
                                        "
                                    ></td>
                                    <td class="text-center">
                                        {{
                                            financialReportDepartmentAllAndTypes
                                                .financialReportDepartmentsByType[enquiryType]
                                                ?.nbCompleted || 0
                                        }}
                                        ({{
                                            financialReportDepartmentAllAndTypes
                                                .financialReportDepartmentsByType[enquiryType]
                                                ?.completedPercent || 0
                                        }}%)
                                    </td>
                                    <td
                                        class="text-end"
                                        [innerText]="
                                            formatPrice(
                                                financialReportDepartmentAllAndTypes
                                                    .financialReportDepartmentsByType[enquiryType]
                                                    ?.ca || 0,
                                                currentCurrency ?? getDefaultCurrency()
                                            )
                                        "
                                    ></td>
                                    <td
                                        class="text-center"
                                        [innerText]="
                                            (financialReportDepartmentAllAndTypes.virtualFields[
                                                enquiryType
                                            ][
                                                EnumFinancialReportDepartmentVirtualField.caPercent
                                            ] || 0) + '%'
                                        "
                                    ></td>
                                    <td
                                        class="text-center"
                                        [innerText]="
                                            (financialReportDepartmentAllAndTypes
                                                .financialReportDepartmentsByType[enquiryType]
                                                ?.marginPercent || 0) + '%'
                                        "
                                    ></td>
                                    <td class="text-center">
                                        <button
                                            type="button"
                                            class="btn btn-light btn-sm"
                                            [innerText]="
                                                financialReportDepartmentAllAndTypes
                                                    .financialReportDepartmentsByType[enquiryType]
                                                    ?.usersId?.length
                                                    ? financialReportDepartmentAllAndTypes
                                                          .financialReportDepartmentsByType[
                                                          enquiryType
                                                      ]?.usersId.length
                                                    : 0
                                            "
                                            (click)="
                                                openUserList(
                                                    financialReportDepartmentAllAndTypes
                                                        .financialReportDepartmentsByType[
                                                        enquiryType
                                                    ]?.usersId
                                                )
                                            "
                                        ></button>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>Total</th>
                                    <th
                                        class="text-end"
                                        [innerText]="
                                            formatPrice(
                                                financialReportDepartmentAllAndTypes.all
                                                    ?.marginHT || 0,
                                                currentCurrency ?? getDefaultCurrency()
                                            )
                                        "
                                    ></th>
                                    <th
                                        class="text-center"
                                        [innerText]="
                                            roundNumber(
                                                financialReportDepartmentAllAndTypes.virtualFields
                                                    .all[
                                                    EnumFinancialReportDepartmentVirtualField
                                                        .marginHTPercent
                                                ],
                                                0
                                            ) + '%'
                                        "
                                    ></th>

                                    <th
                                        class="text-center"
                                        [innerText]="
                                            financialReportDepartmentAllAndTypes.all?.nbContracts ||
                                            0
                                        "
                                    ></th>
                                    <th
                                        class="text-center"
                                        [innerText]="
                                            roundNumber(
                                                financialReportDepartmentAllAndTypes.virtualFields
                                                    .all[
                                                    EnumFinancialReportDepartmentVirtualField
                                                        .nbContractsPercent
                                                ],
                                                0
                                            ) + '%'
                                        "
                                    ></th>
                                    <th class="text-center">
                                        {{
                                            financialReportDepartmentAllAndTypes.all?.nbCompleted ||
                                                0
                                        }}
                                        ({{
                                            financialReportDepartmentAllAndTypes.all
                                                ?.completedPercent || 0
                                        }}%)
                                    </th>
                                    <th
                                        class="text-end"
                                        [innerText]="
                                            formatPrice(
                                                financialReportDepartmentAllAndTypes.all?.ca || 0,
                                                currentCurrency ?? getDefaultCurrency()
                                            )
                                        "
                                    ></th>
                                    <th
                                        class="text-center"
                                        [innerText]="
                                            roundNumber(
                                                financialReportDepartmentAllAndTypes.virtualFields
                                                    .all[
                                                    EnumFinancialReportDepartmentVirtualField
                                                        .caPercent
                                                ],
                                                0
                                            ) + '%'
                                        "
                                    ></th>
                                    <th
                                        class="text-center"
                                        [innerText]="
                                            (financialReportDepartmentAllAndTypes.all
                                                ?.marginPercent || 0) + '%'
                                        "
                                    ></th>
                                    <th class="text-center">
                                        <button
                                            type="button"
                                            class="btn btn-light btn-sm"
                                            [innerText]="
                                                financialReportDepartmentAllAndTypes.all?.usersId
                                                    ?.length
                                                    ? financialReportDepartmentAllAndTypes.all
                                                          .usersId.length
                                                    : 0
                                            "
                                            (click)="
                                                openUserList(
                                                    financialReportDepartmentAllAndTypes.all
                                                        ?.usersId
                                                )
                                            "
                                        ></button>
                                    </th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<div
    class="modal fade"
    id="modal-users-list"
    tabindex="-1"
    aria-labelledby="modal-users-list-label"
    aria-hidden="true"
    #modalUsersList
>
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="modal-users-list-label">
                    Workforces ({{ usersDisplayedInModalList.length }})
                </h1>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <div class="modal-body no-padding">
                <ul class="list-group list-group-flush" *ngIf="loadingUsers">
                    <li class="list-group-item" *ngFor="let number of usersDisplayedInModalList">
                        <div class="skeleton-text mb-0"></div>
                    </li>
                </ul>
                <div
                    class="alert alert-warning text-center mb-0"
                    *ngIf="!loadingUsers && !usersDisplayedInModalList.length"
                >
                    Aucun utilisateur
                </div>
                <div
                    class="list-group list-group-flush"
                    *ngIf="!loadingUsers && usersDisplayedInModalList.length"
                >
                    <a
                        [href]="'/admin/users/' + userId"
                        target="_blank"
                        class="list-group-item list-group-item-action"
                        *ngFor="let userId of usersDisplayedInModalList"
                        [innerText]="usersObj[userId] ? getUserFullname(usersObj[userId]) : ''"
                    >
                    </a>
                </div>
            </div>
            <div class="modal-footer justify-content-center">
                <small class="text-muted text-center">
                    Un commercial peut être sur plusieurs divisions
                </small>
            </div>
        </div>
    </div>
</div>
