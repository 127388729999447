<div class="row">
    <div class="col-md-8">
        <div class="card mb-4">
            <div
                class="card-header"
                [innerText]="encaissement.libelle"
                *ngIf="encaissement?.libelle"
            ></div>
            <ul class="list-group list-group-flush" *ngIf="loading">
                <li class="list-group-item" *ngFor="let number of [].constructor(10)">
                    <div class="skeleton-text mb-0"></div>
                </li>
            </ul>
            <ul class="list-group list-group-flush" *ngIf="!loading && !encaissement">
                <li class="list-group-item list-group-item-warning text-center">
                    Aucune donnée à afficher
                </li>
            </ul>

            <ul class="list-group list-group-flush" *ngIf="!loading && encaissement">
                <li class="list-group-item">
                    <strong>Montant</strong> :
                    {{ formatPrice(encaissement.amount, encaissement.currency) }}
                </li>
                <li class="list-group-item">
                    <strong>Type</strong> : {{ encaissementType?.name }}
                </li>
                <li class="list-group-item">
                    <strong>Compte bancaire</strong> : {{ bankAccount?.name ?? "Aucun" }}
                </li>
                <li class="list-group-item">
                    <strong>Date</strong> : {{ encaissement.date | date : "dd/MM/yyyy" }}
                </li>
                <li class="list-group-item">
                    <strong>Rapproché avec le compte bancaire ?</strong> :
                    {{ encaissement.isReconciled ? "Oui" : "Non" }}
                </li>
                <li class="list-group-item" *ngIf="encaissement.comment.length">
                    <strong>Commentaire</strong> :
                    {{ encaissement.comment }}
                </li>
            </ul>
        </div>

        <div class="btn-toolbar btn-toolbar-actions">
            <button
                type="button"
                (click)="delete()"
                class="btn btn-danger"
                *ngIf="hasAclAccess(EnumAcl.encaissementsDelete)"
            >
                <fa-icon [icon]="faTrash"></fa-icon>
                Supprimer
            </button>

            <a
                [routerLink]="'/admin/encaissements/' + encaissementId + '/edit'"
                class="btn btn-primary"
                *ngIf="hasAclAccess(EnumAcl.encaissementsEdit)"
            >
                <fa-icon [icon]="faEdit"></fa-icon> Modifier</a
            >
        </div>
    </div>
    <div class="col-md-4">
        <div class="card mb-4">
            <div class="card-header">Facture liée</div>
            <ul class="list-group list-group-flush" *ngIf="loading">
                <li class="list-group-item" *ngFor="let number of [].constructor(10)">
                    <div class="skeleton-text mb-0"></div>
                </li>
            </ul>
            <ul class="list-group list-group-flush" *ngIf="!loading && !encaissement">
                <li class="list-group-item list-group-item-warning text-center">
                    Aucune donnée à afficher
                </li>
            </ul>
            <div class="list-group list-group-flush" *ngIf="!loading && encaissement">
                <li
                    class="list-group-item list-group-item-warning text-center"
                    *ngIf="!encaissement.invoiceIds"
                >
                    Aucune facture liée
                </li>
                <a
                    [routerLink]="'/admin/invoices/' + invoiceId"
                    class="list-group-item list-group-item-action d-flex align-items-center justify-content-between gap-2 placeholder-glow"
                    *ngFor="let invoiceId of encaissement.invoiceIds"
                    target="_blank"
                >
                    <div *ngIf="!invoicesObj[invoiceId]" class="placeholder w-100"></div>
                    <div *ngIf="invoicesObj[invoiceId]">
                        <strong [innerText]="invoicesObj[invoiceId].ref"></strong><br />
                        {{
                            formatPrice(
                                invoicesObj[invoiceId].amountTtcTotal,
                                invoicesObj[invoiceId].currency
                            )
                        }}
                    </div>
                    <fa-icon [icon]="faExternalLink"></fa-icon>
                </a>
            </div>
        </div>
    </div>
</div>
