import { finalize } from 'rxjs/operators';
import {
  Component,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { RemoteService } from '../../../../services/remote.service';
import { AclService } from '../../../../services/acl.service';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { ISimulateurAircraftModel } from 'src/app/interfaces/simulateur-aircraft-model.interface';
import { generateRandomId } from 'src/app/misc.utils';

@Component({
  selector: 'app-simulateur-aircraft-model-edit',
  templateUrl: './simulateur-aircraft-model-edit.component.html',
  styleUrls: ['./simulateur-aircraft-model-edit.component.scss']
})
export class SimulateurAircraftModelEditComponent implements OnChanges {
  @ViewChild('imageUrlInput', { static: false }) imageUrlInputElement: ElementRef;
  @ViewChild('imageInsideUrlInput', { static: false }) imageInsideUrlInputElement: ElementRef;
  @ViewChild('imagePlanUrlInput', { static: false }) imagePlanUrlInputElement: ElementRef;

  isLogged: boolean = false;
  form: FormGroup;
  sending: boolean = false;
  simulateurAircraftModel: ISimulateurAircraftModel | null = null;
  simulateurAircraftModelId: string;

  @Input('inModal') inModal: boolean = false;
  private oldTriggerSaveAicraftModal: boolean = true;
  @Input('triggerSaveAicraftModal') triggerSaveAicraftModal: boolean = false;
  @Output() getCreatedAircraftModel: EventEmitter<string> = new EventEmitter();

  fireCategories: {
    description: string;
    value: number;
  }[] = [
    {
      description: 'Longueur appareil : < 9 m et Largeur maximal fuselage : 2m',
      value: 1
    },
    {
      description: 'Longueur appareil : entre 9 et 12 m et Largeur maximal fuselage : 2m',
      value: 2
    },
    {
      description: 'Longueur appareil : entre 12 et 18 m et Largeur maximal fuselage : 3m',
      value: 3
    },
    {
      description: 'Longueur appareil : entre 18 et 24 m et Largeur maximal fuselage : 4m',
      value: 4
    },
    {
      description: 'Longueur appareil : entre 24 et 28 m et Largeur maximal fuselage : 4m',
      value: 5
    },
    {
      description: 'Longueur appareil : entre 28 et 39 m et Largeur maximal fuselage : 5m',
      value: 6
    },
    {
      description: 'Longueur appareil : entre 39 et 49 m et Largeur maximal fuselage : 5m',
      value: 7
    },
    {
      description: 'Longueur appareil : entre 49 et 61 m et Largeur maximal fuselage : 7m',
      value: 8
    },
    {
      description: 'Longueur appareil : entre 61 et 76 m et Largeur maximal fuselage : 7m',
      value: 9
    },
    {
      description: 'Longueur appareil : supérieur à 76 m et Largeur maximal fuselage : 8m',
      value: 10
    }
  ];

  constructor(
    private formBuilder: FormBuilder,
    private remoteService: RemoteService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private aclService: AclService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );

    this.form = this.formBuilder.group({
      title: ['', [Validators.required]],
      slug: ['', [Validators.required]],
      paxMax: ['', [Validators.required]],
      speedInKmh: ['', [Validators.required]],
      rangeMaxInKms: ['', [Validators.required]],
      flyTimeInHours: ['', [Validators.required]],
      priceByHour: ['', [Validators.required]],
      priceByPax: ['', [Validators.required]],
      imageUrl: [''],
      fireCategories: [[], [Validators.required]],
      minHardSurfaceRunwayInMeters: ['', [Validators.required]],
      description: ['']
    });

    this.form.disable();

    this.activatedRoute.url.subscribe(async () => {
      this.simulateurAircraftModelId = this.activatedRoute.snapshot.paramMap.get(
        'simulateurAircraftModelId'
      );

      if (!this.simulateurAircraftModelId) {
        await this.aclService.checkAclAccess(EnumAcl.simulateurAircraftModelsAdd);
        this.form.enable();
      } else {
        await this.aclService.checkAclAccess(EnumAcl.simulateurAircraftModelsEdit);
      }

      this.loadData();
    });
  }

  ngOnChanges() {
    if (this.oldTriggerSaveAicraftModal !== this.triggerSaveAicraftModal) {
      this.oldTriggerSaveAicraftModal = this.triggerSaveAicraftModal;

      this.submitForm();
    }
  }

  async loadData(): Promise<void> {
    if (this.isLogged) {
      if (this.simulateurAircraftModelId) {
        this.loadSimulateurAircraftModel();
      }
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  async loadSimulateurAircraftModel(): Promise<void> {
    const doc = await this.remoteService.getDocument(
      'simulateurAircraftModels',
      this.simulateurAircraftModelId
    );

    this.simulateurAircraftModel = doc as ISimulateurAircraftModel;

    this.setAircraftModel();
  }

  setAircraftModel(): void {
    if (this.form && this.simulateurAircraftModel) {
      this.form.get('slug').setValue(this.simulateurAircraftModel.slug);
      this.form.get('title').setValue(this.simulateurAircraftModel.title);
      this.form.get('paxMax').setValue(this.simulateurAircraftModel.paxMax);
      this.form.get('speedInKmh').setValue(this.simulateurAircraftModel.speedInKmh);
      this.form.get('rangeMaxInKms').setValue(this.simulateurAircraftModel.rangeMaxInKms);
      this.form.get('flyTimeInHours').setValue(this.simulateurAircraftModel.flyTimeInHours);
      this.form.get('priceByHour').setValue(this.simulateurAircraftModel.priceByHour);
      this.form.get('priceByPax').setValue(this.simulateurAircraftModel.priceByPax);
      this.form.get('imageUrl').setValue(this.simulateurAircraftModel.imageUrl);
      this.form.get('fireCategories').setValue(this.simulateurAircraftModel.fireCategories || []);
      this.form
        .get('minHardSurfaceRunwayInMeters')
        .setValue(this.simulateurAircraftModel.minHardSurfaceRunwayInMeters);
      this.form.get('description').setValue(this.simulateurAircraftModel.description);

      this.form.enable();
    }
  }

  async submitForm(): Promise<void> {
    this.form.markAsTouched();

    if (this.form.valid) {
      let data = Object.assign({}, this.form.value);

      for (const field in data) {
        if (typeof data[field] == 'undefined') {
          data[field] = null;
        }
      }

      this.sending = true;

      this.form.disable();

      if (this.simulateurAircraftModelId) {
        this.remoteService
          .updateDocumentToCollection(
            'simulateurAircraftModels',
            this.simulateurAircraftModelId,
            data
          )
          .then(async () => {
            await this.remoteService.loadAircraftModels();

            this.sending = false;
            await this.afterSave();
          })
          .catch(err => {
            this.sending = false;
            this.form.enable();

            alert(err.message);
          });
      } else {
        this.remoteService
          .addDocumentToCollection('simulateurAircraftModels', data)
          .then(async (docId: string) => {
            this.simulateurAircraftModelId = docId;

            await this.remoteService.loadAircraftModels();

            this.sending = false;
            await this.afterSave();
          })
          .catch(err => {
            this.sending = false;
            this.form.enable();

            alert(err.message);
          });
      }
    }
  }

  private async afterSave(): Promise<void> {
    if (this.inModal) {
      this.form.reset();
      this.getCreatedAircraftModel.emit(this.simulateurAircraftModelId);
    } else {
      this.router.navigate(['/admin/simulateur-aircraft-models/' + this.simulateurAircraftModelId]);
    }
  }

  updateTitle(): void {
    if (!this.simulateurAircraftModelId) {
      // Only for new aircraft model
      this.form.get('slug').setValue(this.slugify(this.form.value.title));
    }
  }

  triggerInputFile(fileFieldName: string): void {
    if (this.form.enabled) {
      document.getElementById(fileFieldName + 'Input').click();
    }
  }

  changeInputFile(fileFieldName: string, fileInput: any): void {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.upload(fileFieldName, fileInput.target.files[0]);
    }
  }

  upload(fileFieldName: string, file: File): Promise<void> {
    return new Promise((resolve, reject) => {
      this.form.disable();

      const result = this.remoteService.upload(
        'simulateurAircraftModels/images',
        generateRandomId(),
        file,
        'file'
      );

      result['task']
        .snapshotChanges()
        .pipe(
          finalize(() => {
            result['ref'].getDownloadURL().subscribe((downloadUrl: string) => {
              this.form.get(fileFieldName).setValue(downloadUrl);

              (<HTMLInputElement>document.getElementById(fileFieldName + 'Input')).value = '';

              this.form.enable();

              this.form.updateValueAndValidity();
            });
          })
        )
        .subscribe();
    });
  }

  deleteImage(fileFieldName: string): void {
    if (confirm('Êtes-vous sûr de vouloir supprimer cette image ?')) {
      this.form.get(fileFieldName).setValue(null);
    }
  }

  toggleFireCategories(fireCategory: number): void {
    const index: number = this.form.value.fireCategories.indexOf(fireCategory);

    let newValue: number[] = [...this.form.value.fireCategories];

    if (index === -1) {
      newValue.push(fireCategory);
    } else {
      newValue.splice(index, 1);
    }

    newValue.sort((a, b) => (a < b ? -1 : 1));

    this.form.get('fireCategories').setValue(newValue);
  }

  private slugify(...args: (string | number)[]): string {
    const value = args.join(' ');

    return value
      .normalize('NFD') // split an accented letter in the base letter and the acent
      .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
      .toLowerCase()
      .trim()
      .replace(/[^a-z0-9 +]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
      .replace(/\s+/g, '-'); // separator
  }
}
