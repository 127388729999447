<div class="row">
    <div class="col-lg-4 order-lg-last">
        <div class="row">
            <div class="col-md-6 col-lg-12">
                <div class="card mb-4">
                    <div class="card-body" *ngIf="loading">
                        <h3><div class="skeleton-text"></div></h3>
                    </div>
                    <div class="card-body" *ngIf="!loading && airline">
                        <h3 [innerHtml]="airline.title" class="text-center"></h3>
                        <dl class="row">
                            <dt class="col-md-6">Continent</dt>
                            <dd class="col-md-6">{{ getContinentLabel(airline.continentCode) }}</dd>
                            <dt class="col-md-6">Pays</dt>
                            <dd class="col-md-6">{{ getCountryLabel(airline.countryCode) }}</dd>
                            <dt class="col-md-6">Compagnie nationale ?</dt>
                            <dd class="col-md-6">
                                {{ airline.isNationalCompany ? "Oui" : "Non" }}
                            </dd>
                            <dt class="col-md-6">Doit absolument être contactée ?</dt>
                            <dd class="col-md-6">{{ airline.mustBeContacted ? "Oui" : "Non" }}</dd>
                            <dt class="col-md-6">Est blacklistée ?</dt>
                            <dd class="col-md-6">{{ airline.isBlacklisted ? "Oui" : "Non" }}</dd>
                            <dt class="col-md-6" *ngIf="airline.isBlacklisted">
                                Raison du black listage
                            </dt>
                            <dd class="col-md-6" *ngIf="airline.isBlacklisted">
                                {{ airline.reasonBlackListed }}
                            </dd>
                            <dt class="col-md-6">Date</dt>
                            <dd class="col-md-6">
                                {{
                                    airline.companyCreationYear
                                        ? airline.companyCreationYear
                                        : "Inconnu"
                                }}
                                -
                                {{ airline.companyClosureYear ? airline.companyClosureYear : "?" }}
                            </dd>
                            <dt class="col-md-6">Est active ?</dt>
                            <dd class="col-md-6">
                                {{ airline.isActive ? "Oui" : "Non" }}
                            </dd>
                            <dt class="col-md-6">Codes</dt>
                            <dd class="col-md-6">{{ airline.codes }}</dd>
                            <dt class="col-md-6">Callsign</dt>
                            <dd class="col-md-6">
                                {{ airline.callsign ? airline.callsign : "&nbsp;" }}
                            </dd>
                            <dt class="col-md-6">Site web</dt>
                            <dd class="col-md-6">
                                <span *ngIf="!airline.website">&nbsp;</span>
                                <a
                                    [href]="airline.website"
                                    target="_blank"
                                    [innerHtml]="airline.website"
                                    *ngIf="airline.website"
                                ></a>
                            </dd>
                            <dt class="col-md-6">Adresse</dt>
                            <dd class="col-md-6">
                                <span *ngIf="!airline.formattedAddress">&nbsp;</span>
                                <address *ngIf="airline.formattedAddress">
                                    {{ getStreetNumberAndRoute(airline) }}<br />
                                    {{
                                        airline.postalCode
                                            ? airline.postalCode + " " + airline.city
                                            : airline.city
                                    }}
                                </address>
                            </dd>
                            <dt class="col-md-6">Numéro de TVA</dt>
                            <dd class="col-md-6">
                                {{ airline.tvaNumber ? airline.tvaNumber : "&nbsp;" }}
                            </dd>
                            <dt class="col-md-6">Airfleets</dt>
                            <dd class="col-md-6">
                                <a [href]="airline.url" target="_blank" *ngIf="airline.url">Lien</a>
                                <div *ngIf="airline.airfleetTitle">
                                    Nom dans Airfleets: {{ airline.airfleetTitle }}
                                </div>
                            </dd>
                        </dl>

                        <p
                            *ngIf="airline.history && airline.history !== ''"
                            [innerHtml]="'Historique : ' + airline.history"
                        ></p>
                        <p
                            *ngIf="airline.fusionsTransfers?.length"
                            [innerHtml]="
                                'Fusion/Transfert vers : ' + airline.fusionsTransfers.join(', ')
                            "
                        ></p>
                        <p
                            *ngIf="airline.previousNames?.length"
                            [innerHtml]="
                                'Dans le passé, voir aussi : ' + airline.previousNames.join(', ')
                            "
                        ></p>

                        <div class="text-center mt-4">
                            <a
                                [routerLink]="'/admin/airlines/' + airline.id + '/edit'"
                                class="btn btn-primary mr-4"
                                *ngIf="hasAclAccess(EnumAcl.airlinesEdit)"
                                >Éditer</a
                            >
                            <button
                                type="button"
                                (click)="refreshFleet()"
                                class="btn btn-primary ml-4"
                                *ngIf="hasAclAccess(EnumAcl.airlinesRefreshFleet)"
                            >
                                Rafraîchir flotte
                            </button>
                            <button
                                type="button"
                                (click)="delete()"
                                class="btn btn-danger ml-4"
                                *ngIf="hasAclAccess(EnumAcl.airlinesDelete)"
                            >
                                Supprimer
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-12">
                <h5 class="text-center">Contact(s)</h5>
                <div class="accordion mb-4" id="contacts" *ngIf="loading">
                    <div class="card" *ngFor="let number of [].constructor(3)">
                        <div class="card-header" id="heading-{{ number }}">
                            <h2 class="mb-0">
                                <button
                                    class="btn btn-link btn-block text-center"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    [attr.data-bs-target]="'#collapse-' + number"
                                    aria-expanded="true"
                                    [attr.aria-controls]="'collapse-' + number"
                                >
                                    <div class="skeleton-text"></div>
                                </button>
                            </h2>
                        </div>

                        <div
                            id="collapse-{{ number }}"
                            class="collapse"
                            [attr.aria-labelledby]="'heading-' + number"
                            data-parent="#contacts"
                        >
                            <div class="card-body text-center">
                                <div class="skeleton-text"></div>
                                <div class="skeleton-text"></div>
                                <div class="skeleton-text"></div>
                                <div class="skeleton-text"></div>
                                <div class="skeleton-text"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="alert alert-info text-center"
                    *ngIf="!loading && airline && !airline.companyContacts?.length"
                >
                    Aucun contact pour cette compagnie aérienne.
                </div>

                <div
                    class="accordion mb-4"
                    id="contacts"
                    *ngIf="!loading && airline && airline.companyContacts?.length"
                >
                    <div
                        class="accordion-item"
                        *ngFor="let companyContact of airline.companyContacts; let i = index"
                    >
                        <div class="accordion-header" id="heading-{{ i }}">
                            <button
                                class="accordion-button"
                                type="button"
                                data-bs-toggle="collapse"
                                [attr.data-bs-target]="'#collapse-' + i"
                                aria-expanded="true"
                                [attr.aria-controls]="'collapse-' + i"
                            >
                                {{ companyContact.title }}
                            </button>
                        </div>

                        <div
                            id="collapse-{{ i }}"
                            class="accordion-collapse collapse"
                            [attr.aria-labelledby]="'heading-' + i"
                            data-parent="#contacts"
                        >
                            <div class="accordion-body text-center">
                                <div *ngFor="let contact of companyContact.contacts; let j = index">
                                    <div *ngIf="contact.lastname || contact.firstname">
                                        <strong
                                            [innerHtml]="contact.lastname + ' ' + contact.firstname"
                                        ></strong>
                                    </div>
                                    <div *ngIf="contact.fonction">{{ contact.fonction }}</div>
                                    <div *ngIf="contact.phone">
                                        Tel :
                                        <a
                                            [href]="'tel:' + contact.phone"
                                            [innerHtml]="contact.phone"
                                        ></a>
                                    </div>
                                    <div *ngIf="contact.mobile">
                                        Portable :
                                        <a
                                            [href]="'tel:' + contact.mobile"
                                            [innerHtml]="contact.mobile"
                                        ></a>
                                    </div>
                                    <div *ngIf="contact.email">
                                        Email :
                                        <a
                                            [href]="'mailto:' + contact.email"
                                            [innerHtml]="contact.email"
                                        ></a>
                                    </div>
                                    <hr *ngIf="j < companyContact.contacts?.length - 1" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg">
        <div class="row" *ngIf="!loading && airline && airline.description">
            <div class="col-md-6">
                <strong>Description de la compagnie</strong><br />
                <div class="skeleton-text" *ngIf="loading"></div>
                <p [innerHtml]="airline.description" class="description"></p>
            </div>
        </div>

        <div class="text-end mb-4">
            <a
                [routerLink]="'/admin/airline-supervisors/airline/' + airlineId"
                class="btn btn-primary mr-4"
                *ngIf="hasAclAccess(EnumAcl.airlineSupervisorsList)"
                >Superviseurs</a
            >
            <a
                [routerLink]="'/admin/crews/airline/' + airlineId"
                class="btn btn-primary mr-4"
                *ngIf="hasAclAccess(EnumAcl.crewsList)"
                >Équipage</a
            >
            <a
                [routerLink]="'/admin/aircrafts/add/' + airlineId"
                class="btn btn-primary"
                *ngIf="hasAclAccess(EnumAcl.aircraftsAdd) && airline && airline.isActive"
                >+ Nouvel avion</a
            >
        </div>

        <ul class="nav nav-pills mb-4 justify-content-center" id="myTab" role="tablist">
            <li class="nav-item" role="presentation" *ngFor="let type of paginationsType">
                <a
                    class="nav-link"
                    [class.active]="type.active"
                    [id]="type.id + '-tab'"
                    data-bs-toggle="tab"
                    [href]="'#' + type.id"
                    role="tab"
                    [attr.aria-controls]="type.id"
                    aria-selected="false"
                    [innerHtml]="type.title"
                    (click)="changeTab(type.id)"
                ></a>
            </li>
        </ul>
        <div class="tab-content" id="myTabContent">
            <div
                class="tab-pane fade"
                [class.show]="type.active"
                [class.active]="type.active"
                [id]="type.id"
                role="tabpanel"
                [attr.aria-labelledby]="type.id + '-tab'"
                *ngFor="let type of paginationsType"
            >
                <div
                    class="alert alert-info text-center"
                    *ngIf="
                        !loading &&
                        paginationService.pagination[type.pagination].data &&
                        !paginationService.pagination[type.pagination].data?.length
                    "
                >
                    Aucun appareil aérien pour cette compagnie aérienne.
                </div>

                <div
                    class="card mb-4"
                    *ngIf="
                        !loading &&
                        paginationService.pagination[type.pagination].data &&
                        paginationService.pagination[type.pagination].data?.length
                    "
                >
                    <div class="table-responsive no-border">
                        <table class="table table-striped table-hover">
                            <thead
                                *ngIf="
                                    paginationService.pagination[type.pagination].data &&
                                    paginationService.pagination[type.pagination].data?.length
                                "
                            >
                                <tr>
                                    <th
                                        scope="col"
                                        class="sortable"
                                        *ngFor="
                                            let field of paginationService.pagination[
                                                type.pagination
                                            ].fields
                                        "
                                        [class.is-sorted]="
                                            paginationService.pagination[type.pagination].orderBy
                                                ?.field == field.code
                                        "
                                        [class.asc]="
                                            paginationService.pagination[type.pagination].orderBy
                                                ?.field == field.code &&
                                            paginationService.pagination[type.pagination].orderBy
                                                ?.direction == 'asc'
                                        "
                                        [class.desc]="
                                            paginationService.pagination[type.pagination].orderBy
                                                ?.field == field.code &&
                                            paginationService.pagination[type.pagination].orderBy
                                                ?.direction == 'desc'
                                        "
                                        (click)="
                                            paginationService.sortField(type.pagination, field.code)
                                        "
                                        [innerHtml]="field.title"
                                    ></th>
                                </tr>
                            </thead>
                            <tbody *ngIf="paginationService.loading">
                                <tr *ngFor="let number of [].constructor(10)">
                                    <td
                                        *ngFor="
                                            let field of paginationService.pagination[
                                                type.pagination
                                            ].fields
                                        "
                                    >
                                        <div class="skeleton-text"></div>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody
                                *ngIf="!paginationService.loading && type.id === 'all-aircrafts'"
                            >
                                <tr
                                    *ngIf="
                                        !paginationService.pagination[type.pagination].data ||
                                        !paginationService.pagination[type.pagination].data?.length
                                    "
                                    class="table-warning text-center"
                                >
                                    <td
                                        [attr.colspan]="
                                            paginationService.pagination[type.pagination].fields
                                                ?.length
                                        "
                                    >
                                        Aucun avion n'a été trouvé.
                                    </td>
                                </tr>
                                <tr
                                    *ngFor="
                                        let aircraft of paginationService.pagination[
                                            type.pagination
                                        ].data
                                    "
                                    [routerLink]="'/admin/aircrafts/' + aircraft.id"
                                    class="clickable"
                                >
                                    <td [innerHtml]="aircraft.serialNumber"></td>
                                    <td>
                                        <span
                                            [innerHtml]="
                                                aircraftModelsObj[aircraft.aircraftModelId].title
                                            "
                                            *ngIf="aircraftModelsObj[aircraft.aircraftModelId]"
                                        ></span>
                                        <span
                                            *ngIf="!aircraftModelsObj[aircraft.aircraftModelId]"
                                        ></span>
                                        <br /><span [innerHtml]="aircraft.type"></span>
                                    </td>
                                    <td [innerHtml]="aircraft.registration"></td>
                                    <td
                                        [innerHtml]="
                                            aircraft.firstFlightDate
                                                ? aircraft.firstFlightDate
                                                : 'Inconnu'
                                        "
                                    ></td>
                                    <td [innerHtml]="aircraft.seatTotal"></td>
                                    <td [innerHtml]="aircraft.isCargo ? 'Oui' : 'Non'"></td>
                                    <td [innerHtml]="aircraft.created | date : 'dd/MM/yyyy'"></td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="!paginationService.loading && type.id === 'passengers'">
                                <tr
                                    *ngIf="
                                        !paginationService.pagination[type.pagination].data ||
                                        !paginationService.pagination[type.pagination].data.length
                                    "
                                    class="table-warning text-center"
                                >
                                    <td
                                        [attr.colspan]="
                                            paginationService.pagination[type.pagination].fields
                                                .length
                                        "
                                    >
                                        Aucun avion n'a été trouvé.
                                    </td>
                                </tr>
                                <tr
                                    *ngFor="
                                        let aircraftCompiled of paginationService.pagination[
                                            type.pagination
                                        ].data
                                    "
                                    (click)="
                                        openAircraftCompiledModal(aircraftCompiled.aircraftsId)
                                    "
                                    class="clickable"
                                >
                                    <td>
                                        <span
                                            [innerHtml]="
                                                aircraftModelsObj[aircraftCompiled.aircraftModelId]
                                                    .title
                                            "
                                            *ngIf="
                                                aircraftModelsObj[aircraftCompiled.aircraftModelId]
                                            "
                                        ></span>
                                        <span
                                            *ngIf="
                                                !aircraftModelsObj[aircraftCompiled.aircraftModelId]
                                            "
                                        ></span>
                                    </td>
                                    <td [innerHtml]="aircraftCompiled.count"></td>
                                    <td [innerHtml]="aircraftCompiled.seatTotal"></td>
                                    <td
                                        [innerHtml]="
                                            aircraftCompiled.classes
                                                ? aircraftCompiled.classes.join('/')
                                                : ''
                                        "
                                    ></td>
                                    <td [innerHtml]="aircraftCompiled.targetTitle"></td>
                                    <td [innerHtml]="aircraftCompiled.ageAverage"></td>
                                </tr>
                            </tbody>
                            <tbody
                                *ngIf="
                                    !paginationService.loading && type.id === EnumEnquiryType.cargo
                                "
                            >
                                <tr
                                    *ngIf="
                                        !paginationService.pagination[type.pagination].data ||
                                        !paginationService.pagination[type.pagination].data.length
                                    "
                                    class="table-warning text-center"
                                >
                                    <td
                                        [attr.colspan]="
                                            paginationService.pagination[type.pagination].fields
                                                .length
                                        "
                                    >
                                        Aucun avion n'a été trouvé.
                                    </td>
                                </tr>
                                <tr
                                    *ngFor="
                                        let aircraftCompiled of paginationService.pagination[
                                            type.pagination
                                        ].data
                                    "
                                    (click)="
                                        openAircraftCompiledModal(aircraftCompiled.aircraftsId)
                                    "
                                    class="clickable"
                                >
                                    <td>
                                        <span
                                            [innerHtml]="aircraftCompiled.modelTitle.toUpperCase()"
                                            *ngIf="
                                                aircraftModelsObj[aircraftCompiled.aircraftModelId]
                                            "
                                        ></span>
                                        <span
                                            *ngIf="
                                                !aircraftModelsObj[aircraftCompiled.aircraftModelId]
                                            "
                                        ></span>
                                    </td>
                                    <td [innerHtml]="aircraftCompiled.count"></td>
                                    <td [innerHtml]="aircraftCompiled.weight"></td>
                                    <td [innerHtml]="aircraftCompiled.volume"></td>
                                    <td [innerHtml]="aircraftCompiled.targetTitle"></td>
                                    <td [innerHtml]="aircraftCompiled.ageAverage"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <nav
                    aria-label="Liste navigation"
                    class="mt-4 mb-4"
                    *ngIf="
                        !paginationService.loading &&
                        paginationService.pagination[type.pagination].data &&
                        paginationService.pagination[type.pagination].data.length
                    "
                >
                    <ul class="pagination justify-content-center">
                        <li
                            class="page-item"
                            [class.disabled]="
                                paginationService.pagination[type.pagination].currentPage === 0
                            "
                        >
                            <button
                                type="button"
                                class="page-link"
                                (click)="paginationService.loadPreviousPage(type.pagination)"
                            >
                                Précédent
                            </button>
                        </li>
                        <li class="page-item disabled">
                            <span
                                class="page-link"
                                [innerHtml]="
                                    paginationService.pagination[type.pagination].currentPage + 1
                                "
                            ></span>
                        </li>
                        <li
                            class="page-item"
                            [class.disabled]="
                                paginationService.pagination[type.pagination].data &&
                                (!paginationService.pagination[type.pagination].data.length ||
                                    paginationService.pagination[type.pagination].data.length <
                                        paginationService.pagination[type.pagination].limit)
                            "
                        >
                            <button
                                type="button"
                                class="page-link"
                                (click)="paginationService.loadNextPage(type.pagination)"
                            >
                                Suivant
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>

        <div class="row" *ngIf="!loading && airline && airline.documentsUrl.length">
            <div class="col-md-6">
                <strong>Documentation compagnie</strong><br />
                <ul class="list-unstyled">
                    <li *ngFor="let documentUrl of airline.documentsUrl">
                        <a
                            [href]="documentUrl.url"
                            target="_blank"
                            [innerHtml]="documentUrl.title"
                        ></a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="modal" tabindex="-1" role="dialog" #modalAircraftCompiled>
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Liste des avions</h5>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <div class="table-responsive">
                <table class="table table-striped table-hover mb-0">
                    <thead>
                        <tr>
                            <th scope="col">MSN</th>
                            <th scope="col">Modèle</th>
                            <th scope="col">Immatriculation</th>
                            <th scope="col">Date de livraison</th>
                            <th scope="col">Cargo ?</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngIf="
                                !listAircraftsOfCompiledAircrafts ||
                                !listAircraftsOfCompiledAircrafts.length
                            "
                            class="table-warning text-center"
                        >
                            <td [attr.colspan]="6">Aucun avion n'a été trouvé.</td>
                        </tr>
                        <tr
                            *ngFor="let aircraft of listAircraftsOfCompiledAircrafts"
                            data-bs-dismiss="modal"
                        >
                            <td [innerHtml]="aircraft.serialNumber"></td>
                            <td>
                                <a
                                    [href]="
                                        '/admin/aircraft-models/' +
                                        aircraftModelsObj[aircraft.aircraftModelId].id
                                    "
                                    [innerHtml]="aircraftModelsObj[aircraft.aircraftModelId].title"
                                    *ngIf="aircraftModelsObj[aircraft.aircraftModelId]"
                                ></a>
                                <span *ngIf="!aircraftModelsObj[aircraft.aircraftModelId]"></span>
                                <br /><span [innerHtml]="aircraft.type"></span>
                            </td>
                            <td [innerHtml]="aircraft.registration"></td>
                            <td
                                [innerHtml]="
                                    aircraft.firstFlightDate ? aircraft.firstFlightDate : 'Inconnu'
                                "
                            ></td>
                            <td [innerHtml]="aircraft.isCargo ? 'Oui' : 'Non'"></td>
                            <td>
                                <a
                                    [routerLink]="'/admin/aircrafts/' + aircraft.id"
                                    class="btn btn-primary"
                                >
                                    <fa-icon [icon]="faEye"></fa-icon>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
