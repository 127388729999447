import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  faCancel,
  faCheck,
  faClock,
  faClockRotateLeft,
  faEdit,
  faExternalLink,
  faEye,
  faHome,
  faPlaneArrival,
  faPlaneDeparture,
  faPlus,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { EnumEnquiryType } from 'src/app/enums/enquiry-type.enum';
import {
  getEnquiryFlightFlyTimeInMin,
  IEnquiryFlight
} from 'src/app/interfaces/enquiry-flight.interface';
import { IEnquiry } from 'src/app/interfaces/enquiry.interface';
import { getFboFullAddress, IFbo } from 'src/app/interfaces/fbo.interface';
import { IFlightChecklistTemplate } from 'src/app/interfaces/flight-checklist-template.interface';
import {
  IFlightChecklist,
  getFinishedCheckOnTotalForFlight
} from 'src/app/interfaces/flight-checklist.interface';
import { convertMinutesToTime } from 'src/app/misc.utils';
import { AclService } from 'src/app/services/acl.service';
import { EnquiryFlightService } from 'src/app/services/enquiry-flights/enquiry-flights.service';
import { FboService } from 'src/app/services/fbos/fbos.service';
import { FlightChecklistTemplateService } from 'src/app/services/flight-checklist-templates/flight-checklist-templates.service';
import { FlightChecklistService } from 'src/app/services/flight-checklists/flight-checklists.service';
import { LoaderService } from 'src/app/services/loader/loader.service';

import countries from '../../countries_fr.json';
import { ICrew } from 'src/app/interfaces/crew.interface';
import { CrewService } from 'src/app/services/crews/crews.service';
import { AirlineSupervisorService } from 'src/app/services/airline-supervisors/airline-supervisors.service';
import { IAirlineSupervisor } from 'src/app/interfaces/airline-supervisor.interface';
import { EnumEnquiryFlightWizardStep } from '../enquiry-flight-edit/enquiry-flight-edit.component';

@Component({
  selector: 'app-enquiry-flights-cards',
  templateUrl: './enquiry-flights-cards.component.html',
  styleUrls: ['./enquiry-flights-cards.component.scss']
})
export class EnquiryFlightsCardsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() loading: boolean = false;
  @Input() enquiry: IEnquiry | null = null;
  @Input() enquiryFlights: IEnquiryFlight[] = [];
  @Input() noDeleteBtn: boolean = false;
  @Input() noChecklistBtn: boolean = false;

  @ViewChild('modalChecklist', { static: false }) modalChecklistElement: ElementRef;
  @ViewChild('modalEnquiryFlightEdit', { static: false }) modalEnquiryFlightEditElement: ElementRef;
  @ViewChild('modalEnquiryFlightAdd', { static: false }) modalEnquiryFlightAddElement: ElementRef;

  EnumAcl = EnumAcl;
  EnumEnquiryType = EnumEnquiryType;

  getFinishedCheckOnTotalForFlight = getFinishedCheckOnTotalForFlight;
  convertMinutesToTime = convertMinutesToTime;
  getFboFullAddress = getFboFullAddress;
  getEnquiryFlightFlyTimeInMin = getEnquiryFlightFlyTimeInMin;

  faHome = faHome;
  faEdit = faEdit;
  faEye = faEye;
  faCancel = faCancel;
  faCheck = faCheck;
  faClockRotateLeft = faClockRotateLeft;
  faTrash = faTrash;
  faPlus = faPlus;

  faPlaneDeparture = faPlaneDeparture;
  faPlaneArrival = faPlaneArrival;
  faClock = faClock;
  faExternalLink = faExternalLink;

  countries: { [countryCode: string]: string } = countries;

  calendarDates: string[] = [];

  nbDaysDisplayed: number = 5;

  selectedFlight: IEnquiryFlight | null = null;

  flightChecklistTemplateByEnquiryType: { [enquiryType: string]: IFlightChecklistTemplate | null } =
    {};
  flightChecklistByEnquiryFlight: { [enquiryFlightId: string]: IFlightChecklist | null } = {};

  flightChecklistTemplate: IFlightChecklistTemplate | null = null;
  loadingFlightChecklistTemplate: boolean = false;

  flightChecklist: IFlightChecklist | null = null;
  loadingFlightChecklist: boolean = false;

  currentEnquiryFlightStep: EnumEnquiryFlightWizardStep = EnumEnquiryFlightWizardStep.aircraft;
  currentEnquiryFlight: IEnquiryFlight | null = null;

  fbosObj: { [id: string]: IFbo | null } = {};
  crewsObj: { [id: string]: ICrew | null } = {};
  supervisorsObj: { [id: string]: IAirlineSupervisor | null } = {};

  private subscriptions = new Subscription();
  private subscriptionFlightChecklist = new Subscription();

  constructor(
    private aclService: AclService,
    private enquiryFlightService: EnquiryFlightService,
    private loaderService: LoaderService,
    private flightChecklistTemplateService: FlightChecklistTemplateService,
    private flightChecklistService: FlightChecklistService,
    private fboService: FboService,
    private crewService: CrewService,
    private airlineSupervisorService: AirlineSupervisorService
  ) {}

  ngOnInit(): void {
    this.refreshChecklistAndTemplateFromEnquiryFlights();

    this.refreshFbosForAllEnquiryFlights();
    this.refreshCrewsAndSupervisorsForAllEnquiryFlights();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['enquiryFlights']) {
      this.refreshFbosForAllEnquiryFlights();
    }

    this.refreshChecklistAndTemplateFromEnquiryFlights();
    this.refreshCrewsAndSupervisorsForAllEnquiryFlights();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.subscriptionFlightChecklist.unsubscribe();

    window['$']('.tooltip').remove();

    this.removeModal();
  }

  refreshFbosForAllEnquiryFlights(): void {
    for (const enquiryFlight of this.enquiryFlights) {
      for (const field of ['departFboId', 'arrivalFboId']) {
        if (enquiryFlight[field]) {
          this.loadFbo(enquiryFlight[field]);
        }
      }
    }
  }

  refreshCrewsAndSupervisorsForAllEnquiryFlights(): void {
    for (const enquiryFlight of this.enquiryFlights) {
      if (enquiryFlight.airlineId) {
        this.loadCrewsForAirline(enquiryFlight.airlineId);
        this.loadSupervisorsForAirline(enquiryFlight.airlineId);
      }
    }
  }

  removeModal(): void {
    window['$'](this.modalChecklistElement.nativeElement).modal('hide');
    window['$'](this.modalEnquiryFlightEditElement.nativeElement).modal('hide');
    window['$'](this.modalEnquiryFlightAddElement.nativeElement).modal('hide');
    window['$']('body').removeClass('modal-open');
    window['$']('.modal-backdrop').remove();
  }

  selectFlight(flight: IEnquiryFlight | null = null): void {
    this.selectedFlight = flight;
  }

  viewFlightChecklist(flight: IEnquiryFlight): void {
    this.selectFlight(flight);

    this.loadFlightChecklistAndTemplate();

    window['$'](this.modalChecklistElement.nativeElement).modal('show');

    this.updateTooltip();
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  loadFlightChecklistAndTemplate(): void {
    if (this.selectedFlight) {
      if (this.subscriptionFlightChecklist) {
        this.subscriptionFlightChecklist.unsubscribe();
      }

      this.subscriptionFlightChecklist = new Subscription();

      this.loadFlightChecklistTemplate();
      this.loadFlightChecklist();
    }
  }

  private loadFlightChecklistTemplate(): void {
    if (this.selectedFlight) {
      this.loadingFlightChecklistTemplate = true;

      this.subscriptionFlightChecklist.add(
        this.flightChecklistTemplateService
          .getOneForEnquiryType(this.selectedFlight.enquiryType)
          .subscribe((flightChecklistTemplate: IFlightChecklistTemplate) => {
            this.flightChecklistTemplate = flightChecklistTemplate;

            if (this.flightChecklistTemplate) {
              this.flightChecklistTemplateByEnquiryType[this.flightChecklistTemplate.enquiryType] =
                this.flightChecklistTemplate;
            }

            this.loadingFlightChecklistTemplate = false;
          })
      );
    }
  }

  private loadFlightChecklist(): void {
    if (this.selectedFlight) {
      this.loadingFlightChecklist = true;

      this.subscriptionFlightChecklist.add(
        this.flightChecklistService
          .getForFlight(this.selectedFlight.id)
          .subscribe((flightChecklist: IFlightChecklist) => {
            this.flightChecklist = flightChecklist;

            if (this.flightChecklist) {
              this.flightChecklistByEnquiryFlight[this.selectedFlight.id] = this.flightChecklist;
            }

            this.loadingFlightChecklist = false;
          })
      );
    }
  }

  updateTooltip(): void {
    if (window['$']) {
      window['$']('.tooltip').remove();

      window['$']('[rel="tooltip"]').tooltip({
        html: true,
        boundary: 'window',
        trigger: 'hover'
      });
    }
  }

  refreshChecklistAndTemplateFromEnquiryFlights(): void {
    if (this.enquiryFlights) {
      for (const enquiryFlight of this.enquiryFlights) {
        this.loadChecklistTemplate(enquiryFlight.enquiryType);
        this.loadChecklist(enquiryFlight.id);
      }
    }
  }

  loadChecklistTemplate(enquiryType: EnumEnquiryType): void {
    if (typeof this.flightChecklistTemplateByEnquiryType[enquiryType] === 'undefined') {
      this.flightChecklistTemplateByEnquiryType[enquiryType] = null;

      this.subscriptions.add(
        this.flightChecklistTemplateService
          .getOneForEnquiryType(enquiryType)
          .subscribe((flightChecklistTemplate: IFlightChecklistTemplate) => {
            if (flightChecklistTemplate) {
              this.flightChecklistTemplateByEnquiryType[enquiryType] = flightChecklistTemplate;
            }
          })
      );
    }
  }

  loadChecklist(enquiryFlightId: string): void {
    if (typeof this.flightChecklistByEnquiryFlight[enquiryFlightId] === 'undefined') {
      this.flightChecklistByEnquiryFlight[enquiryFlightId] = null;

      this.subscriptions.add(
        this.flightChecklistService
          .getForFlight(enquiryFlightId)
          .subscribe((flightChecklist: IFlightChecklist) => {
            if (flightChecklist) {
              this.flightChecklistByEnquiryFlight[enquiryFlightId] = flightChecklist;
            }
          })
      );
    }
  }

  async deleteLeg(enquiryFlight: IEnquiryFlight): Promise<void> {
    if (
      confirm(
        'Êtes-vous sûr de vouloir supprimer ce leg ? Il sera supprimé définitivement du dossier.'
      )
    ) {
      this.loaderService.presentLoader();

      this.enquiryFlightService
        .delete(enquiryFlight.id)
        .then(async () => {
          await this.loaderService.hideLoaderOnSuccess();

          this.updateTooltip();
        })
        .catch(async err => {
          await this.loaderService.hideLoaderOnFailure(err);
        });
    }
  }

  async addLeg(): Promise<void> {
    this.currentEnquiryFlight = null;
    this.currentEnquiryFlightStep = EnumEnquiryFlightWizardStep.aircraft;

    if (this.enquiryFlights.length) {
      if (this.modalEnquiryFlightAddElement.nativeElement) {
        window['$'](this.modalEnquiryFlightAddElement.nativeElement).modal('show');
      }
    } else {
      if (this.modalEnquiryFlightEditElement.nativeElement) {
        window['$'](this.modalEnquiryFlightEditElement.nativeElement).modal('show');
      }
    }
  }

  editLeg(enquiryFlight: IEnquiryFlight): void {
    this.currentEnquiryFlight = enquiryFlight;
    this.currentEnquiryFlightStep = EnumEnquiryFlightWizardStep.aircraft;

    if (this.modalEnquiryFlightEditElement.nativeElement) {
      window['$'](this.modalEnquiryFlightEditElement.nativeElement).modal('show');
    }
  }

  loadFbo(fboId: string): void {
    if (typeof this.fbosObj[fboId] === 'undefined') {
      this.fbosObj[fboId] = null;

      this.subscriptions.add(
        this.fboService.getFromId(fboId).subscribe((fbo: IFbo | null) => {
          this.fbosObj[fboId] = fbo;
        })
      );
    }
  }

  loadCrewsForAirline(airlineId: string): void {
    this.subscriptions.add(
      this.crewService.getForAirline(airlineId).subscribe((crews: ICrew[]) => {
        for (const crew of crews) {
          this.crewsObj[crew.id] = crew;
        }
      })
    );
  }

  loadSupervisorsForAirline(airlineId: string): void {
    this.subscriptions.add(
      this.airlineSupervisorService
        .getForAirline(airlineId)
        .subscribe((supervisors: IAirlineSupervisor[]) => {
          for (const supervisor of supervisors) {
            this.supervisorsObj[supervisor.id] = supervisor;
          }
        })
    );
  }

  assignSelectedEnquiryFlight(enquiryFlightId: string): void {
    this.currentEnquiryFlight = null;

    for (const enquiryFlight of this.enquiryFlights) {
      if (enquiryFlight.id === enquiryFlightId) {
        this.currentEnquiryFlight = enquiryFlight;
        break;
      }
    }
  }
}
