<div class="row" [class.mt-4]="embed">
    <div class="col-md-6 mb-4">
        <a
            routerLink="/admin/export-requests/add"
            class="btn btn-primary"
            *ngIf="hasAclAccess(EnumAcl.exportRequestsAdd)"
            >+ Nouvel export</a
        >
    </div>
</div>

<div class="table-responsive">
    <table class="table table-striped table-hover">
        <thead>
            <tr>
                <th
                    scope="col"
                    class="sortable"
                    *ngFor="let field of paginationService.pagination[currentPagination].fields"
                    [class.is-sorted]="
                        paginationService.pagination[currentPagination].orderBy.field == field.code
                    "
                    [class.asc]="
                        paginationService.pagination[currentPagination].orderBy.field ==
                            field.code &&
                        paginationService.pagination[currentPagination].orderBy.direction == 'asc'
                    "
                    [class.desc]="
                        paginationService.pagination[currentPagination].orderBy.field ==
                            field.code &&
                        paginationService.pagination[currentPagination].orderBy.direction == 'desc'
                    "
                    (click)="paginationService.sortField(currentPagination, field.code)"
                    [innerHtml]="field.title"
                ></th>
            </tr>
        </thead>
        <tbody *ngIf="paginationService.loading">
            <tr *ngFor="let number of [].constructor(10)">
                <td *ngFor="let field of paginationService.pagination[currentPagination].fields">
                    <div class="skeleton-text"></div>
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="!paginationService.loading">
            <tr
                *ngIf="
                    !paginationService.pagination[currentPagination].data ||
                    !paginationService.pagination[currentPagination].data.length
                "
                class="table-warning text-center"
            >
                <td [attr.colspan]="paginationService.pagination[currentPagination].fields.length">
                    Aucun export n'a été trouvé.
                </td>
            </tr>
            <tr
                *ngFor="let exportRequest of paginationService.pagination[currentPagination].data"
                [routerLink]="'/admin/export-requests/' + exportRequest.id"
                class="clickable"
            >
                <td [innerText]="getExportRequestLabel(exportRequest)"></td>
                <td>
                    <span
                        class="badge text-bg-primary"
                        [class.text-bg-warning]="
                            exportRequest.status === EnumExportRequestStatus.requested
                        "
                        [class.text-bg-primary]="
                            exportRequest.status === EnumExportRequestStatus.ongoing
                        "
                        [class.text-bg-success]="
                            exportRequest.status === EnumExportRequestStatus.completed
                        "
                        [innerText]="getExportRequestStatusLabel(exportRequest.status)"
                    ></span>
                </td>
                <td [innerText]="exportRequest.created | date : 'dd/MM/yyyy HH:mm'"></td>
            </tr>
        </tbody>
    </table>
</div>

<nav aria-label="Liste navigation" class="mt-4 mb-4" *ngIf="!paginationService.loading">
    <ul class="pagination justify-content-center">
        <li
            class="page-item"
            [class.disabled]="paginationService.pagination[currentPagination].currentPage === 0"
        >
            <button
                type="button"
                class="page-link"
                (click)="paginationService.loadPreviousPage(currentPagination)"
            >
                Précédent
            </button>
        </li>
        <li class="page-item disabled">
            <span
                class="page-link"
                [innerHtml]="paginationService.pagination[currentPagination].currentPage + 1"
            ></span>
        </li>
        <li
            class="page-item"
            [class.disabled]="
                paginationService.pagination[currentPagination].data &&
                (!paginationService.pagination[currentPagination].data.length ||
                    paginationService.pagination[currentPagination].data.length <
                        paginationService.pagination[currentPagination].limit)
            "
        >
            <button
                type="button"
                class="page-link"
                (click)="paginationService.loadNextPage(currentPagination)"
            >
                Suivant
            </button>
        </li>
    </ul>
</nav>
