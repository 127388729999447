<div class="card flights-calendar mb-4">
    <div class="card-header flights-calendar-header">
        <div class="flights-calendar-header-start">
            <button
                type="button"
                class="flights-calendar-nav"
                (click)="goBackToday()"
                [disabled]="loadingEnquiryFlights"
                *ngIf="!calendarDates.includes(todayStr)"
            >
                <fa-icon [icon]="faHome"></fa-icon>
                Aujourd'hui
            </button>
        </div>
        <div class="flights-calendar-header-center">
            <button
                type="button"
                class="flights-calendar-nav"
                (click)="goPrevPage()"
                [disabled]="loadingEnquiryFlights"
            >
                <fa-icon [icon]="faArrowLeft"></fa-icon>
            </button>
            <button
                type="button"
                class="flights-calendar-header-dates"
                *ngIf="calendarDates.length"
                data-bs-toggle="modal"
                data-bs-target="#calendarModal"
                [disabled]="loadingEnquiryFlights"
            >
                <fa-icon [icon]="faCalendar" class="me-2"></fa-icon>
                <span
                    [innerText]="calendarDates[0] | date : 'EE dd MMMM yyyy' : undefined : 'fr'"
                ></span>
                -
                <span
                    [innerText]="
                        calendarDates[calendarDates.length - 1]
                            | date : 'EE dd MMMM yyyy' : undefined : 'fr'
                    "
                ></span>
            </button>
            <button
                type="button"
                class="flights-calendar-nav"
                (click)="goNextPage()"
                [disabled]="loadingEnquiryFlights"
            >
                <fa-icon [icon]="faArrowRight"></fa-icon>
            </button>
        </div>
        <div class="flights-calendar-header-end">
            <div class="form-group mb-0">
                <select
                    [(ngModel)]="predefinedTimezone"
                    class="form-select"
                    (change)="updatedPredefinedTimezone()"
                    [disabled]="loadingEnquiryFlights"
                >
                    <option
                        *ngFor="let timezone of getAllTimezonePredefined()"
                        [value]="timezone"
                        [innerText]="getTimezonePredefinedLabel(timezone)"
                    ></option>
                </select>
            </div>
        </div>
    </div>

    <div class="flights-calendar-container">
        <div class="flights-calendar-sidebar">
            <div class="count-flights placeholder-glow">
                <div class="placeholder w-100" *ngIf="loadingEnquiryFlights"></div>
                <div
                    *ngIf="!loadingEnquiryFlights"
                    [innerText]="
                        enquiryFlights.length > 1
                            ? enquiryFlights.length + ' vols'
                            : enquiryFlights.length + ' vol'
                    "
                ></div>
            </div>

            <div
                class="enquiry-type"
                [class.enquiry-business]="enquiryType === EnumEnquiryType.business"
                [class.enquiry-commercial]="enquiryType === EnumEnquiryType.commercial"
                [class.enquiry-cargo]="enquiryType === EnumEnquiryType.cargo"
                [class.enquiry-sport]="enquiryType === EnumEnquiryType.sport"
                [class.enquiry-helico]="enquiryType === EnumEnquiryType.helico"
                *ngFor="let enquiryType of getEnquiryTypes()"
            >
                <button
                    type="button"
                    class="btn"
                    (click)="toggleAccordion(enquiryType)"
                    [disabled]="
                        loadingEnquiryFlights || !flightByEnquiryTypes[enquiryType]?.flights.length
                    "
                >
                    {{ getEnumEnquiryTypeLabel(enquiryType) }}
                    <fa-icon
                        [icon]="enquiryTypesOpened[enquiryType] ? faChevronDown : faChevronRight"
                        *ngIf="
                            !loadingEnquiryFlights &&
                            flightByEnquiryTypes[enquiryType]?.flights.length
                        "
                    ></fa-icon>
                </button>
                <div
                    class="list-group list-group-flush"
                    *ngIf="
                        !loadingEnquiryFlights &&
                        enquiryTypesOpened[enquiryType] &&
                        flightByEnquiryTypes[enquiryType]?.itineraries.length
                    "
                >
                    <button
                        type="button"
                        class="list-group-item list-group-item-action"
                        *ngFor="let itinerary of flightByEnquiryTypes[enquiryType]?.itineraries"
                        (click)="openFlight(itinerary, 0)"
                    >
                        <div class="list-group-item-line">
                            <div
                                [class.text-muted]="!itinerary.aircraftRegistration"
                                [innerText]="itinerary.aircraftRegistration || '#Immat'"
                            ></div>
                            <div
                                [class.text-muted]="!itinerary.aircraftModelTitle"
                                [innerText]="itinerary.aircraftModelTitle || '#Aircraft'"
                            ></div>
                        </div>
                        <div class="list-group-item-line">
                            <div
                                [class.text-muted]="!itinerary.airlineTitle"
                                [innerText]="itinerary.airlineTitle || '#Airline'"
                            ></div>
                            <div>
                                <span *ngIf="enquiryType === EnumEnquiryType.cargo">
                                    {{ itinerary.weight || 0 }} kg /
                                    {{ itinerary.volume || 0 }} m<sup>3</sup></span
                                ><span
                                    *ngIf="enquiryType !== EnumEnquiryType.cargo"
                                    [innerText]="itinerary.pax || 0"
                                ></span>
                                pax
                            </div>
                        </div>
                    </button>
                </div>
            </div>
        </div>
        <div class="flights-calendar-responsive">
            <div class="flights-calendar-body">
                <div class="flights-calendar-dates-header">
                    <div class="header-date" *ngFor="let calendarDate of calendarDates">
                        <div
                            class="calendar-date"
                            [innerText]="calendarDate | date : 'EE dd' : undefined : 'fr'"
                        ></div>
                        <div
                            class="calendar-date-flight-indicator"
                            *ngIf="flightByDates[calendarDate]"
                        >
                            <div
                                class="indicator-flights"
                                [class.empty]="!flightByDates[calendarDate].nbNonFerry"
                                [innerText]="flightByDates[calendarDate].nbNonFerry || 0"
                            ></div>
                            <div
                                class="indicator-ferry"
                                [class.empty]="!flightByDates[calendarDate].nbFerry"
                                [innerText]="flightByDates[calendarDate].nbFerry || 0"
                            ></div>
                        </div>
                        <div class="calendar-date-time-indication">
                            <div>6</div>
                            <div>12</div>
                            <div>18</div>
                        </div>
                    </div>
                </div>
                <div class="flights-calendar-lines">
                    <div
                        class="calendar-line"
                        *ngFor="let enquiryType of getEnquiryTypes()"
                        [class.enquiry-business]="enquiryType === EnumEnquiryType.business"
                        [class.enquiry-commercial]="enquiryType === EnumEnquiryType.commercial"
                        [class.enquiry-cargo]="enquiryType === EnumEnquiryType.cargo"
                        [class.enquiry-sport]="enquiryType === EnumEnquiryType.sport"
                        [class.enquiry-helico]="enquiryType === EnumEnquiryType.helico"
                    >
                        <div class="enquiry-type-indicators calendar-by-days">
                            <div *ngFor="let calendarDate of calendarDates">
                                <div
                                    class="indicator-flights"
                                    [class.empty]="
                                        !flightByEnquiryTypes[enquiryType]?.flightByDate[
                                            calendarDate
                                        ].nbNonFerry
                                    "
                                >
                                    <fa-icon [icon]="faPlaneUp"></fa-icon>
                                    {{
                                        flightByEnquiryTypes[enquiryType]?.flightByDate[
                                            calendarDate
                                        ].nbNonFerry || 0
                                    }}
                                </div>
                                <div
                                    class="indicator-ferry"
                                    [class.empty]="
                                        !flightByEnquiryTypes[enquiryType]?.flightByDate[
                                            calendarDate
                                        ].nbFerry
                                    "
                                >
                                    <fa-icon [icon]="faPlaneUp"></fa-icon>
                                    {{
                                        flightByEnquiryTypes[enquiryType]?.flightByDate[
                                            calendarDate
                                        ].nbFerry || 0
                                    }}
                                </div>
                            </div>
                        </div>
                        <ng-container
                            *ngIf="
                                !loadingEnquiryFlights &&
                                enquiryTypesOpened[enquiryType] &&
                                flightByEnquiryTypes[enquiryType]?.itineraries.length
                            "
                        >
                            <div
                                class="itinerary-for-enquiry-type"
                                *ngFor="
                                    let itinerary of flightByEnquiryTypes[enquiryType].itineraries
                                "
                            >
                                <div class="calendar-by-days">
                                    <div *ngFor="let calendarDate of calendarDates"></div>
                                </div>

                                <div class="flight-indicators">
                                    <ng-container
                                        *ngFor="let flight of itinerary.flights; let i = index"
                                    >
                                        <button
                                            type="button"
                                            [style.left]="flight.x1 + '%'"
                                            [style.width]="flight.x2 - flight.x1 + '%'"
                                            [class.is-ferry-flight]="
                                                flight.enquiryFlight.isFerryFlight
                                            "
                                            class="flight-indicator"
                                            rel="tooltip"
                                            [title]="flight.tooltipHtml"
                                            (click)="openFlight(itinerary, i)"
                                        ></button>
                                        <div
                                            class="flight-immo-container"
                                            [style.left]="flight.x2 + '%'"
                                            [style.width]="
                                                itinerary.flights[i + 1].x1 - flight.x2 + '%'
                                            "
                                            *ngIf="
                                                itinerary.flights[i + 1] &&
                                                flight.enquiryFlight.isAircraftGroundedOnArrival
                                            "
                                        >
                                            <div class="flight-immo-detail">
                                                Immo.
                                                {{ flight.enquiryFlight.airportArrivalIataCode }}
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>
                    </div>

                    <div
                        class="current-time-indicator"
                        [style.left]="currentTimeIndicatorLeftPercent + '%'"
                        *ngIf="currentTimeIndicatorLeftPercent !== null"
                    >
                        <div class="current-time-indicator-bar"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div
    class="modal fade"
    id="calendarModal"
    tabindex="-1"
    aria-labelledby="calendarModalLabel"
    aria-hidden="true"
>
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="calendarModalLabel">Sélection date</h1>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <div class="modal-body">
                <app-calendar
                    [dateStr]="firstDateStr"
                    (updatedDate)="updatedDate($event)"
                ></app-calendar>
            </div>
        </div>
    </div>
</div>

<div
    class="offcanvas offcanvas-end"
    tabindex="-1"
    id="offcanvasFlight"
    aria-labelledby="offcanvasFlightLabel"
    #offcanvasFlight
>
    <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasFlightLabel">Détails du vol</h5>
        <button
            type="button"
            class="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
        ></button>
    </div>
    <div class="offcanvas-body" *ngIf="selectedEnquiryFlight">
        <div class="card mb-4">
            <div class="card-body">
                <div class="row mb-2">
                    <div class="col-6 placeholder-glow">
                        <div>
                            <a
                                [routerLink]="'/admin/enquiries/' + selectedEnquiryFlight.enquiryId"
                                target="_blank"
                                [innerText]="selectedEnquiryFlight.enquiryRefContractTitle"
                            ></a>
                        </div>
                        <div
                            class="placeholder w-100"
                            *ngIf="loadingFlightBriefsForEnquiry[selectedEnquiryFlight.enquiryId]"
                        ></div>
                        <div
                            *ngIf="!loadingFlightBriefsForEnquiry[selectedEnquiryFlight.enquiryId]"
                        >
                            <div
                                class="text-muted"
                                *ngIf="
                                    !flightBriefsForEnquiry[selectedEnquiryFlight.enquiryId]?.length
                                "
                            >
                                Aucun flight brief
                            </div>
                            <ul
                                class="list-unstyled mb-0"
                                *ngIf="
                                    flightBriefsForEnquiry[selectedEnquiryFlight.enquiryId]?.length
                                "
                            >
                                <li
                                    *ngFor="
                                        let flightBrief of flightBriefsForEnquiry[
                                            selectedEnquiryFlight.enquiryId
                                        ]
                                    "
                                >
                                    <a
                                        [routerLink]="'/admin/flight-briefs/' + flightBrief.id"
                                        target="_blank"
                                        [innerText]="flightBrief.title"
                                    ></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-6 text-end">
                        <div>
                            <a
                                [href]="
                                    'https://flightradar24.com/' +
                                    selectedEnquiryFlight.flightNumber
                                "
                                [innerText]="selectedEnquiryFlight.flightNumber"
                                *ngIf="selectedEnquiryFlight.flightNumber"
                            ></a>
                            <span class="text-muted">#numéro de vol</span>
                        </div>
                        <div>
                            <a
                                [href]="
                                    'https://globe.adsbexchange.com/?icao=' +
                                    selectedEnquiryFlight.aircraftRegistration
                                "
                                [innerText]="selectedEnquiryFlight.aircraftRegistration"
                                *ngIf="selectedEnquiryFlight.aircraftRegistration"
                            ></a>
                            <span class="text-muted">#immat</span>
                        </div>
                    </div>
                </div>

                <div class="d-flex align-items justify-content-between">
                    <div class="legs-nav">
                        <button
                            type="button"
                            class="btn btn-sm"
                            *ngIf="selectedTotalFlightsForItinerary > 1"
                            [disabled]="selectedFlightIndex === 0"
                            (click)="openFlight(selectedItinerary, selectedFlightIndex - 1)"
                        >
                            <fa-icon [icon]="faArrowLeft"></fa-icon>
                        </button>
                        Leg {{ selectedFlightIndex + 1 }} / {{ selectedTotalFlightsForItinerary }}
                        <button
                            type="button"
                            class="btn btn-sm"
                            *ngIf="selectedTotalFlightsForItinerary > 1"
                            [disabled]="selectedFlightIndex >= selectedTotalFlightsForItinerary - 1"
                            (click)="openFlight(selectedItinerary, selectedFlightIndex + 1)"
                        >
                            <fa-icon [icon]="faArrowRight"></fa-icon>
                        </button>
                    </div>

                    <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        (click)="editCurrentSelectedFlight()"
                    >
                        <fa-icon [icon]="faEdit"></fa-icon> Éditer
                    </button>
                </div>
            </div>
        </div>

        <div class="row flight-itinerary">
            <div class="col-6">
                <div class="flight-itinerary-airport">
                    <a
                        [routerLink]="'/admin/airports/' + selectedEnquiryFlight.airportDepartId"
                        target="_blank"
                    >
                        {{ selectedEnquiryFlight.airportDepartIataCode }}</a
                    >
                    <div
                        class="airport-title"
                        [innerText]="selectedEnquiryFlight.airportDepartTitle"
                    ></div>
                </div>
            </div>
            <div class="col-6 text-end">
                <div class="flight-itinerary-airport">
                    <a
                        [routerLink]="'/admin/airports/' + selectedEnquiryFlight.airportArrivalId"
                        target="_blank"
                    >
                        {{ selectedEnquiryFlight.airportArrivalIataCode }}
                    </a>
                    <div
                        class="airport-title"
                        [innerText]="selectedEnquiryFlight.airportArrivalTitle"
                    ></div>
                </div>
            </div>
        </div>

        <div class="row flight-itinerary mb-4">
            <div class="col">
                <div class="flight-itinerary-datetime">
                    {{
                        selectedEnquiryFlight.departDateLocal
                            | date : "EE dd MMM" : undefined : "fr"
                    }}<br />
                    <strong>LT :</strong> {{ selectedEnquiryFlight.departTimeLocal }}<br />
                    <strong>UTC :</strong> {{ selectedEnquiryFlight.departTimeUtc }}
                    <div *ngIf="selectedEnquiryFlight.departSlotEnabled">
                        <strong>Slot :</strong> {{ selectedEnquiryFlight.departSlotTime }} LT
                    </div>
                    <div *ngIf="selectedEnquiryFlight.departTerminalEnabled">
                        <strong>Terminal :</strong> {{ selectedEnquiryFlight.departTerminal }}
                    </div>
                </div>
            </div>
            <div class="col text-center">
                <div class="flight-time mt-2 mb-2">
                    <fa-icon [icon]="faPlane"></fa-icon>

                    <span
                        [innerText]="
                            convertMinutesToTime(selectedEnquiryFlight.flyTimeInMin, 'h ', 'min')
                        "
                        *ngIf="selectedEnquiryFlight.flyTimeInMin > 0"
                    ></span>
                    <div
                        class="alert alert-danger text-center"
                        *ngIf="selectedEnquiryFlight.flyTimeInMin < 0"
                    >
                        Le temps de vol est impossible à calculer. Vérifiez les heures de départ et
                        arrivée.
                    </div>
                    <div class="text-muted" *ngIf="!selectedEnquiryFlight.flyTimeInMin">#Temps</div>
                </div>
            </div>
            <div class="col text-end">
                <div class="flight-itinerary-datetime">
                    <span
                        class="text-muted"
                        *ngIf="
                            selectedEnquiryFlight.departDateTimeUtc ===
                            selectedEnquiryFlight.arrivalDateTimeUtc
                        "
                        >#Date<br />#Heure</span
                    >
                    <ng-container
                        *ngIf="
                            selectedEnquiryFlight.departDateTimeUtc !==
                            selectedEnquiryFlight.arrivalDateTimeUtc
                        "
                    >
                        {{
                            selectedEnquiryFlight.arrivalDateLocal
                                | date : "EE dd MMM" : undefined : "fr"
                        }}<br />
                        <strong>LT :</strong> {{ selectedEnquiryFlight.arrivalTimeLocal }}<br />
                        <strong>UTC :</strong> {{ selectedEnquiryFlight.arrivalTimeUtc }}
                    </ng-container>
                    <div *ngIf="selectedEnquiryFlight.arrivalSlotEnabled">
                        <strong>Slot :</strong> {{ selectedEnquiryFlight.arrivalSlotTime }} LT
                    </div>
                    <div *ngIf="selectedEnquiryFlight.arrivalTerminalEnabled">
                        <strong>Terminal :</strong> {{ selectedEnquiryFlight.arrivalTerminal }}
                    </div>
                    <div *ngIf="selectedEnquiryFlight.isAircraftGroundedOnArrival">
                        Reste en immobilisation
                    </div>
                </div>
            </div>
        </div>

        <div class="card mb-4" *ngIf="selectedEnquiryFlight.comment">
            <div class="card-header">Remarque</div>
            <div class="card-body" [innerText]="selectedEnquiryFlight.comment"></div>
        </div>

        <ul class="nav nav-pills mb-4 justify-content-center" id="flight-tabs" role="tablist">
            <li class="nav-item" role="presentation">
                <button
                    type="button"
                    class="nav-link active"
                    id="flight-tab"
                    href="#flight-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#flight-pane"
                    role="tab"
                    aria-controls="flight-tab"
                    aria-selected="true"
                >
                    Vol
                </button>
            </li>
            <li
                class="nav-item"
                role="presentation"
                *ngIf="hasAclAccess(EnumAcl.flightChecklistsView)"
            >
                <button
                    type="button"
                    class="nav-link"
                    id="checklist-tab"
                    href="#checklist-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#checklist-pane"
                    role="tab"
                    aria-controls="checklist-tab"
                    aria-selected="false"
                >
                    Checklist
                    <span
                        class="ms-1"
                        [innerText]="
                            getFinishedCheckOnTotalForFlight(
                                flightChecklistTemplateByEnquiryType[
                                    selectedEnquiryFlight.enquiryType
                                ],
                                flightChecklistByEnquiryFlight[selectedEnquiryFlight.id]
                            )
                        "
                        *ngIf="
                            flightChecklistTemplateByEnquiryType[selectedEnquiryFlight.enquiryType]
                        "
                    ></span>
                </button>
            </li>
        </ul>

        <div class="tab-content" id="flight-tabs-content">
            <div
                class="tab-pane fade show active"
                id="flight-pane"
                role="tabpanel"
                aria-labelledby="flight-tab"
                tabindex="0"
            >
                <div class="card mb-4">
                    <div class="card-header">Détails avion</div>
                    <div class="card-body">
                        Immatriculation :
                        <span class="text-muted" *ngIf="!selectedEnquiryFlight.aircraftId"
                            >À préciser</span
                        >
                        <a
                            *ngIf="selectedEnquiryFlight.aircraftId"
                            target="_blank"
                            [routerLink]="'/admin/aircrafts/' + selectedEnquiryFlight.aircraftId"
                            target="_blank"
                        >
                            {{
                                selectedEnquiryFlight.aircraftRegistration ||
                                    "Immatriculation inconnue"
                            }}</a
                        ><br />
                        Type Avion:
                        <span class="text-muted" *ngIf="!selectedEnquiryFlight.aircraftModelId"
                            >À préciser</span
                        >
                        <a
                            *ngIf="selectedEnquiryFlight.aircraftModelId"
                            [routerLink]="
                                '/admin/aircraft-models/' + selectedEnquiryFlight.aircraftModelId
                            "
                            target="_blank"
                        >
                            {{ selectedEnquiryFlight.aircraftModelTitle }}</a
                        ><br />

                        Opérateur :
                        <span class="text-muted" *ngIf="!selectedEnquiryFlight.airlineId"
                            >À préciser</span
                        >
                        <a
                            *ngIf="selectedEnquiryFlight.airlineId"
                            [routerLink]="'/admin/airlines/' + selectedEnquiryFlight.airlineId"
                            target="_blank"
                        >
                            {{ selectedEnquiryFlight.airlineTitle }}</a
                        >
                    </div>
                </div>

                <div class="card mb-4">
                    <div
                        class="card-header"
                        [innerText]="
                            [EnumEnquiryType.cargo, EnumEnquiryType.commercial].includes(
                                selectedEnquiryFlight.enquiryType
                            )
                                ? 'Détails opérateur'
                                : 'Détails équipage'
                        "
                    ></div>
                    <div class="card-body placeholder-glow">
                        OPS H24 :
                        <span class="text-muted" *ngIf="!selectedEnquiryFlight.opsH24Phone"
                            >À préciser</span
                        >
                        <a
                            [href]="'tel:' + selectedEnquiryFlight.opsH24Phone"
                            [innerText]="selectedEnquiryFlight.opsH24Phone"
                            *ngIf="selectedEnquiryFlight.opsH24Phone"
                        ></a
                        ><br />
                        <ng-container
                            *ngIf="
                                [EnumEnquiryType.business].includes(
                                    selectedEnquiryFlight.enquiryType
                                )
                            "
                        >
                            PIC :
                            <span
                                class="placeholder w-100"
                                *ngIf="
                                    selectedEnquiryFlight.captainId &&
                                    !crewsObj[selectedEnquiryFlight.captainId]
                                "
                            ></span>
                            <span class="text-muted" *ngIf="!selectedEnquiryFlight.captainId"
                                >À préciser</span
                            >
                            <a
                                *ngIf="
                                    selectedEnquiryFlight.captainId &&
                                    crewsObj[selectedEnquiryFlight.captainId]
                                "
                                [routerLink]="'/admin/crews/' + selectedEnquiryFlight.captainId"
                                target="_blank"
                            >
                                {{
                                    crewsObj[selectedEnquiryFlight.captainId].lastname +
                                        " " +
                                        crewsObj[selectedEnquiryFlight.captainId].firstname
                                }}
                            </a>
                            <br />
                            SIC :
                            <span
                                class="placeholder w-100"
                                *ngIf="
                                    selectedEnquiryFlight.coPilotId &&
                                    !crewsObj[selectedEnquiryFlight.coPilotId]
                                "
                            ></span>
                            <span class="text-muted" *ngIf="!selectedEnquiryFlight.coPilotId"
                                >À préciser</span
                            >
                            <a
                                *ngIf="
                                    selectedEnquiryFlight.coPilotId &&
                                    crewsObj[selectedEnquiryFlight.coPilotId]
                                "
                                [routerLink]="'/admin/crews/' + selectedEnquiryFlight.coPilotId"
                                target="_blank"
                                class="me-2"
                            >
                                {{
                                    crewsObj[selectedEnquiryFlight.coPilotId].lastname +
                                        " " +
                                        crewsObj[selectedEnquiryFlight.coPilotId].firstname
                                }}
                            </a>
                            <br />
                            CC :
                            <span
                                class="text-muted"
                                *ngIf="!selectedEnquiryFlight.stewardessIds?.length"
                                >À préciser</span
                            >
                            <ng-container
                                *ngFor="let stewardessId of selectedEnquiryFlight.stewardessIds"
                            >
                                <span
                                    class="placeholder w-100"
                                    *ngIf="!crewsObj[stewardessId]"
                                ></span>
                                <a
                                    *ngIf="crewsObj[stewardessId]"
                                    [routerLink]="'/admin/crews/' + stewardessId"
                                    target="_blank"
                                    class="me-2"
                                >
                                    {{
                                        crewsObj[stewardessId].lastname +
                                            " " +
                                            crewsObj[stewardessId].firstname
                                    }}
                                </a> </ng-container
                            ><br />
                        </ng-container>
                        SUP :
                        <span
                            class="text-muted"
                            *ngIf="!selectedEnquiryFlight.supervisorIds?.length"
                            >À préciser</span
                        >
                        <ng-container
                            *ngFor="let supervisorId of selectedEnquiryFlight.supervisorIds"
                        >
                            <span
                                class="placeholder w-100"
                                *ngIf="!supervisorsObj[supervisorId]"
                            ></span>
                            <a
                                *ngIf="supervisorsObj[supervisorId]"
                                [routerLink]="'/admin/airline-supervisors/' + supervisorId"
                                target="_blank"
                                class="me-2"
                            >
                                {{
                                    supervisorsObj[supervisorId].lastname +
                                        " " +
                                        supervisorsObj[supervisorId].firstname
                                }}
                            </a>
                        </ng-container>
                    </div>
                </div>

                <div class="card mb-4" *ngIf="selectedEnquiryFlight.departFboEnabled">
                    <div class="card-header">FBO départ</div>
                    <div class="card-body placeholder-glow">
                        <ng-container *ngIf="!fbosObj[selectedEnquiryFlight.departFboId]">
                            <div
                                class="placeholder w-100"
                                *ngFor="let number of [].constructor(4)"
                            ></div>
                        </ng-container>

                        <ng-container *ngIf="fbosObj[selectedEnquiryFlight.departFboId]">
                            <strong
                                [innerText]="fbosObj[selectedEnquiryFlight.departFboId].name"
                            ></strong>
                            {{ getFboFullAddress(fbosObj[selectedEnquiryFlight.departFboId]) }}
                            <div *ngIf="fbosObj[selectedEnquiryFlight.departFboId].phoneNumber">
                                Téléphone :
                                <a
                                    [href]="
                                        'tel:' +
                                        fbosObj[selectedEnquiryFlight.departFboId].phoneNumber
                                    "
                                    [innerText]="
                                        fbosObj[selectedEnquiryFlight.departFboId].phoneNumber
                                    "
                                ></a>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="card mb-4" *ngIf="selectedEnquiryFlight.departAgentEnabled">
                    <div class="card-header">Représentant départ</div>
                    <div class="card-body placeholder-glow">
                        <a
                            *ngIf="selectedEnquiryFlight.departAgentUserId"
                            [routerLink]="'/admin/users/' + selectedEnquiryFlight.departAgentUserId"
                            target="_blank"
                            [innerText]="
                                selectedEnquiryFlight.departAgentUserFullName +
                                ' (' +
                                selectedEnquiryFlight.departAgentUserInitials +
                                ')'
                            "
                        ></a>
                        <strong
                            [innerText]="selectedEnquiryFlight.departAgentUserFullName"
                            *ngIf="!selectedEnquiryFlight.departAgentUserId"
                        ></strong>
                        <div *ngIf="selectedEnquiryFlight.departAgentCompany">
                            Société : {{ selectedEnquiryFlight.departAgentCompany }}
                        </div>
                        <div *ngIf="selectedEnquiryFlight.departAgentPhone">
                            Téléphone :
                            <a
                                [href]="'tel:' + selectedEnquiryFlight.departAgentPhone"
                                [innerText]="selectedEnquiryFlight.departAgentPhone"
                            ></a>
                        </div>
                        <div *ngIf="selectedEnquiryFlight.departAgentEmail">
                            Email :
                            <a
                                [href]="'mailto:' + selectedEnquiryFlight.departAgentEmail"
                                [innerText]="selectedEnquiryFlight.departAgentEmail"
                            ></a>
                        </div>
                    </div>
                </div>

                <div class="card mb-4" *ngIf="selectedEnquiryFlight.arrivalFboEnabled">
                    <div class="card-header">FBO arrivée</div>
                    <div class="card-body placeholder-glow">
                        <ng-container *ngIf="!fbosObj[selectedEnquiryFlight.arrivalFboId]">
                            <div
                                class="placeholder w-100"
                                *ngFor="let number of [].constructor(4)"
                            ></div>
                        </ng-container>

                        <ng-container *ngIf="fbosObj[selectedEnquiryFlight.arrivalFboId]">
                            <strong
                                [innerText]="fbosObj[selectedEnquiryFlight.arrivalFboId].name"
                            ></strong>
                            {{ getFboFullAddress(fbosObj[selectedEnquiryFlight.arrivalFboId]) }}
                            <div *ngIf="fbosObj[selectedEnquiryFlight.arrivalFboId].phoneNumber">
                                Téléphone :
                                <a
                                    [href]="
                                        'tel:' +
                                        fbosObj[selectedEnquiryFlight.arrivalFboId].phoneNumber
                                    "
                                    [innerText]="
                                        fbosObj[selectedEnquiryFlight.arrivalFboId].phoneNumber
                                    "
                                ></a>
                            </div>
                        </ng-container>
                    </div>
                </div>

                <div class="card mb-4" *ngIf="selectedEnquiryFlight.arrivalAgentEnabled">
                    <div class="card-header">Représentant arrivé</div>
                    <div class="card-body placeholder-glow">
                        <a
                            *ngIf="selectedEnquiryFlight.arrivalAgentUserId"
                            [routerLink]="
                                '/admin/users/' + selectedEnquiryFlight.arrivalAgentUserId
                            "
                            target="_blank"
                            [innerText]="
                                selectedEnquiryFlight.arrivalAgentUserFullName +
                                ' (' +
                                selectedEnquiryFlight.arrivalAgentUserInitials +
                                ')'
                            "
                        ></a>
                        <strong
                            [innerText]="selectedEnquiryFlight.arrivalAgentUserFullName"
                            *ngIf="!selectedEnquiryFlight.arrivalAgentUserId"
                        ></strong>
                        <div *ngIf="selectedEnquiryFlight.arrivalAgentCompany">
                            Société : {{ selectedEnquiryFlight.arrivalAgentCompany }}
                        </div>
                        <div *ngIf="selectedEnquiryFlight.arrivalAgentPhone">
                            Téléphone :
                            <a
                                [href]="'tel:' + selectedEnquiryFlight.arrivalAgentPhone"
                                [innerText]="selectedEnquiryFlight.arrivalAgentPhone"
                            ></a>
                        </div>
                        <div *ngIf="selectedEnquiryFlight.arrivalAgentEmail">
                            Email :
                            <a
                                [href]="'mailto:' + selectedEnquiryFlight.arrivalAgentEmail"
                                [innerText]="selectedEnquiryFlight.arrivalAgentEmail"
                            ></a>
                        </div>
                    </div>
                </div>

                <div class="card mb-4">
                    <div class="card-header">Détails client</div>
                    <div class="card-body">
                        Contact :
                        <span
                            [innerText]="
                                enquiriesObj[selectedEnquiryFlight.enquiryId]
                                    ? enquiriesObj[selectedEnquiryFlight.enquiryId].contactTitle
                                    : selectedEnquiryFlight.clientContactTitle
                            "
                        ></span
                        ><br />
                        Client/Agence :
                        <a
                            [routerLink]="'/admin/clients/' + selectedEnquiryFlight.clientId"
                            target="_blank"
                            [innerText]="
                                enquiriesObj[selectedEnquiryFlight.enquiryId]
                                    ? enquiriesObj[selectedEnquiryFlight.enquiryId].clientTitle
                                    : selectedEnquiryFlight.clientName
                            "
                        ></a>
                    </div>
                </div>

                <div class="card mb-4">
                    <div class="card-header">Détails emport</div>
                    <div class="card-body text-center" *ngIf="selectedEnquiryFlight.isFerryFlight">
                        Vol de positionnement
                    </div>
                    <ng-container *ngIf="!selectedEnquiryFlight.isFerryFlight">
                        <div
                            class="card-body"
                            *ngIf="selectedEnquiryFlight.enquiryType !== EnumEnquiryType.cargo"
                        >
                            PAX : {{ selectedEnquiryFlight.pax }}
                        </div>
                        <div
                            class="card-body"
                            *ngIf="selectedEnquiryFlight.enquiryType === EnumEnquiryType.cargo"
                        >
                            CARGO : {{ selectedEnquiryFlight.weight }}kg /
                            {{ selectedEnquiryFlight.volume }}m³
                        </div>
                    </ng-container>
                </div>

                <div class="card mb-4">
                    <div class="card-header">Commercial</div>
                    <div class="card-body">
                        <a
                            [routerLink]="
                                '/admin/users/' + selectedEnquiryFlight.enquiryProcessedBy
                            "
                            target="_blank"
                            [innerText]="
                                selectedEnquiryFlight.userFullName +
                                ' (' +
                                selectedEnquiryFlight.userInitials +
                                ')'
                            "
                        ></a>
                    </div>
                </div>
            </div>
            <div
                class="tab-pane fade"
                id="checklist-pane"
                role="tabpanel"
                aria-labelledby="checklist-tab"
                tabindex="0"
            >
                <app-enquiry-flight-checklist
                    [enquiryFlight]="selectedEnquiryFlight"
                ></app-enquiry-flight-checklist>
            </div>
        </div>
    </div>
</div>

<div
    class="modal fade"
    id="enquiry-flight-edit-modal"
    tabindex="-1"
    aria-labelledby="enquiry-flight-edit-modalLabel"
    aria-hidden="true"
    #modalEnquiryFlightEdit
>
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <app-enquiry-flight-edit
                [enquiry]="
                    selectedEnquiryFlight && enquiriesObj[selectedEnquiryFlight.enquiryId]
                        ? enquiriesObj[selectedEnquiryFlight.enquiryId]
                        : null
                "
                [enquiryFlight]="selectedEnquiryFlight"
                [inModal]="true"
                [isFirst]="selectedFlightIndex === 0"
            ></app-enquiry-flight-edit>
        </div>
    </div>
</div>
