import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
  ValidationErrors
} from '@angular/forms';

import { RemoteService } from '../services/remote.service';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { LoaderService } from '../services/loader/loader.service';

@Component({
  selector: 'app-password-forgotten',
  templateUrl: './password-forgotten.component.html',
  styleUrls: ['./password-forgotten.component.scss']
})
export class PasswordForgottenComponent implements OnInit {
  faArrowLeft = faArrowLeft;

  form: FormGroup;

  constructor(private remoteService: RemoteService, private loaderService: LoaderService) {}

  get email(): FormControl {
    return this.form.get('email') as FormControl;
  }

  ngOnInit() {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email])
    });
  }

  submitForm(): void {
    this.form.markAsTouched();

    if (this.form.valid) {
      this.form.disable();

      this.loaderService.presentLoader();

      this.remoteService
        .resetPassword(this.form.value.email)
        .then(async () => {
          await this.loaderService.hideLoaderOnSuccess(
            'Un email vous a été envoyé, vérifiez votre boîte de réception !'
          );
          this.form.enable();
          this.form.reset();
        })
        .catch(async err => {
          console.log(err);
          this.form.enable();

          await this.loaderService.hideLoaderOnFailure(err.message);
        });
    }
  }
}
