import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { RemoteService } from '../services/remote.service';
import { AuthService } from '../services/auth/auth.service';
import { Subscription } from 'rxjs';
import { IUser } from '../interfaces/user.interface';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { LoaderService } from '../services/loader/loader.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  faEye = faEye;
  faEyeSlash = faEyeSlash;

  form: FormGroup;

  showPassword: boolean = false;

  user: IUser;

  private subscriptions = new Subscription();

  constructor(
    private router: Router,
    private authService: AuthService,
    private loaderService: LoaderService
  ) {}

  get email(): FormControl {
    return this.form.get('email') as FormControl;
  }

  get password(): FormControl {
    return this.form.get('password') as FormControl;
  }

  ngOnInit() {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(6)])
    });
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  loadUserProfile() {
    const authSub = this.authService.currentUser$.subscribe(async user => {
      this.user = user;

      if (this.user) {
        this.router.navigate(['/admin']);
      }
    });
    this.subscriptions.add(authSub);
  }

  submitForm(): void {
    this.form.markAsTouched();

    if (this.form.valid) {
      this.form.disable();

      this.loaderService.presentLoader();

      this.authService
        .signInWithEmailAndPassword(this.form.value.email, this.form.value.password)
        .then(
          async () => {
            await this.loaderService.hideLoaderOnSuccess();

            this.loadUserProfile();
          },
          async err => {
            this.form.enable();

            await this.loaderService.hideLoaderOnFailure(err.message);
          }
        )
        .finally(() => {
          this.form.enable();
        });
    }
  }

  toggleType(): void {
    this.showPassword = !this.showPassword;
  }
}
