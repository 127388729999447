import { EnumEnquiryType, getEnumEnquiryTypeLabel } from '../enums/enquiry-type.enum';
import { IStoreData } from './store-data.interface';

export interface IFlightBriefCatering extends IStoreData {
  id: string;
  textByLanguage: {
    [language: string]: string;
  };
  position: number;
  enquiryTypes: EnumEnquiryType[];
}

export const getFlightBriefCateringDepartmentList = (item: IFlightBriefCatering): string[] => {
  const labels: string[] = [];

  if (item.enquiryTypes) {
    for (const enquiryType of item.enquiryTypes) {
      labels.push(getEnumEnquiryTypeLabel(enquiryType));
    }
  }

  return labels;
};
