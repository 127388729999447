import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { faEdit, faCheck, faCancel, faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { IEnquiryFlight } from 'src/app/interfaces/enquiry-flight.interface';
import {
  IFlightChecklistTemplate,
  IFlightChecklistTemplateField,
  IFlightChecklistTemplateFieldOption,
  EnumFlightChecklistTemplateFieldType,
  EnumFlightChecklistTemplateFieldOptionStatus
} from 'src/app/interfaces/flight-checklist-template.interface';
import {
  IFlightChecklist,
  IFlightChecklistFieldDataValue,
  initFlightChecklistFromTemplate
} from 'src/app/interfaces/flight-checklist.interface';
import { IUser, getUserFullname } from 'src/app/interfaces/user.interface';
import { AclService } from 'src/app/services/acl.service';
import { FlightChecklistTemplateService } from 'src/app/services/flight-checklist-templates/flight-checklist-templates.service';
import { FlightChecklistService } from 'src/app/services/flight-checklists/flight-checklists.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { RemoteService } from 'src/app/services/remote.service';

@Component({
  selector: 'app-enquiry-flight-checklist',
  templateUrl: './enquiry-flight-checklist.component.html',
  styleUrls: ['./enquiry-flight-checklist.component.scss']
})
export class EnquiryFlightChecklistComponent implements OnInit, OnDestroy, OnChanges {
  @Input() enquiryFlight: IEnquiryFlight | null = null;

  faEdit = faEdit;
  faCheck = faCheck;
  faCancel = faCancel;
  faClockRotateLeft = faClockRotateLeft;

  EnumAcl = EnumAcl;
  EnumFlightChecklistTemplateFieldType = EnumFlightChecklistTemplateFieldType;
  EnumFlightChecklistTemplateFieldOptionStatus = EnumFlightChecklistTemplateFieldOptionStatus;

  flightChecklistTemplateByEnquiryType: { [enquiryType: string]: IFlightChecklistTemplate | null } =
    {};
  flightChecklistByEnquiryFlight: { [enquiryFlightId: string]: IFlightChecklist | null } = {};
  flightChecklistTemplate: IFlightChecklistTemplate | null = null;
  flightChecklistTemplateFieldsById: { [fieldId: string]: IFlightChecklistTemplateField } = {};
  loadingFlightChecklistTemplate: boolean = false;

  flightChecklist: IFlightChecklist | null = null;
  loadingFlightChecklist: boolean = false;

  currentUser: IUser | null = null;

  flightChecklistFieldValuesById: {
    [fieldId: string]: {
      field: IFlightChecklistTemplateField;
      value: IFlightChecklistFieldDataValue | null;
      selectedOption: IFlightChecklistTemplateFieldOption | null;
      history: IFlightChecklistFieldDataValue[];
    };
  } = {};

  editingChecklistFieldId: string | null = null;

  showHistoryFieldId: string | null = null;

  private subscriptions = new Subscription();
  private subscriptionFlightChecklist = new Subscription();

  constructor(
    private loaderService: LoaderService,
    private flightChecklistTemplateService: FlightChecklistTemplateService,
    private flightChecklistService: FlightChecklistService,
    private remoteService: RemoteService,
    private aclService: AclService
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.remoteService.userObservable.subscribe((user: IUser) => {
        this.currentUser = user;
      })
    );

    this.loadFlightChecklistAndTemplate();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['enquiryFlight']) {
      this.loadFlightChecklistAndTemplate();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.subscriptionFlightChecklist.unsubscribe();

    window['$']('.tooltip').remove();
  }

  updateTooltip(): void {
    window['$']('.tooltip').remove();

    window['$']('[rel="tooltip"]').tooltip({
      html: true,
      boundary: 'window',
      trigger: 'hover'
    });
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  loadFlightChecklistAndTemplate(): void {
    if (this.enquiryFlight) {
      if (this.subscriptionFlightChecklist) {
        this.subscriptionFlightChecklist.unsubscribe();
      }

      this.subscriptionFlightChecklist = new Subscription();

      this.loadFlightChecklistTemplate();
      this.loadFlightChecklist();
    }
  }

  private loadFlightChecklistTemplate(): void {
    if (this.enquiryFlight) {
      this.loadingFlightChecklistTemplate = true;

      this.subscriptionFlightChecklist.add(
        this.flightChecklistTemplateService
          .getOneForEnquiryType(this.enquiryFlight.enquiryType)
          .subscribe((flightChecklistTemplate: IFlightChecklistTemplate) => {
            this.flightChecklistTemplate = flightChecklistTemplate;

            if (this.flightChecklistTemplate) {
              this.flightChecklistTemplateByEnquiryType[this.flightChecklistTemplate.enquiryType] =
                this.flightChecklistTemplate;

              for (const module of this.flightChecklistTemplate.modules) {
                for (const field of module.fields) {
                  this.flightChecklistTemplateFieldsById[field.id] = field;
                }
              }
            }

            this.refreshFlightChecklist();

            this.loadingFlightChecklistTemplate = false;
          })
      );
    }
  }

  private loadFlightChecklist(): void {
    if (this.enquiryFlight) {
      this.loadingFlightChecklist = true;

      this.subscriptionFlightChecklist.add(
        this.flightChecklistService
          .getForFlight(this.enquiryFlight.id)
          .subscribe((flightChecklist: IFlightChecklist) => {
            this.flightChecklist = flightChecklist;

            if (this.flightChecklist) {
              this.flightChecklistByEnquiryFlight[this.enquiryFlight.id] = this.flightChecklist;
            }

            this.refreshFlightChecklist();

            this.loadingFlightChecklist = false;
          })
      );
    }
  }

  refreshFlightChecklist(): void {
    if (this.flightChecklistTemplate && !this.flightChecklist?.id) {
      this.flightChecklist = initFlightChecklistFromTemplate(
        this.enquiryFlight,
        this.flightChecklistTemplate
      );
    }

    this.flightChecklistFieldValuesById = {};

    if (this.flightChecklist) {
      if (this.flightChecklistTemplate) {
        for (const module of this.flightChecklistTemplate.modules) {
          for (const field of module.fields) {
            this.flightChecklistFieldValuesById[field.id] = {
              field,
              value: null,
              selectedOption: null,
              history: []
            };
          }
        }
      }

      for (const fieldData of this.flightChecklist.fieldData) {
        if (this.flightChecklistTemplateFieldsById[fieldData.fieldId]) {
          let selectedOption: IFlightChecklistTemplateFieldOption | null = null;
          let value: string | null = null;

          if (fieldData.history.length) {
            value = fieldData.history[fieldData.history.length - 1].value;
          } else {
            value = fieldData.defaultOptionId;
          }

          if (
            value &&
            this.flightChecklistTemplateFieldsById[fieldData.fieldId].type ===
              EnumFlightChecklistTemplateFieldType.select
          ) {
            for (const option of this.flightChecklistTemplateFieldsById[fieldData.fieldId]
              .options) {
              if (option.id === value) {
                selectedOption = option;
                break;
              }
            }
          }

          if (fieldData.history.length) {
            this.flightChecklistFieldValuesById[fieldData.fieldId] = {
              field: this.flightChecklistTemplateFieldsById[fieldData.fieldId],
              value: fieldData.history[fieldData.history.length - 1],
              selectedOption,
              history: fieldData.history
            };
          } else {
            this.flightChecklistFieldValuesById[fieldData.fieldId] = {
              field: this.flightChecklistTemplateFieldsById[fieldData.fieldId],
              value: {
                value: fieldData.defaultOptionId
              },
              selectedOption,
              history: []
            };
          }
        }
      }
    }

    this.updateTooltip();
  }

  editChecklistField(fieldId: string): void {
    this.editingChecklistFieldId = fieldId;

    setTimeout(() => {
      this.updateTooltip();
    }, 1000);
  }

  clearEditChecklistField(): void {
    this.editingChecklistFieldId = null;

    this.refreshFlightChecklist();
  }

  async saveEditChecklistField(): Promise<void> {
    this.updateTooltip();

    await this.loaderService.presentLoader('Sauvegarde en cours ...');

    try {
      for (const fieldData of this.flightChecklist.fieldData) {
        if (fieldData.fieldId === this.editingChecklistFieldId) {
          fieldData.history.push({
            value: this.flightChecklistFieldValuesById[fieldData.fieldId].value?.value || null,
            userId: this.currentUser?.id,
            userInitials: this.currentUser?.initials,
            userFullName: getUserFullname(this.currentUser),
            date: new Date()
          });
        }
      }

      let promise;
      if (this.flightChecklist.id) {
        promise = () => this.flightChecklistService.update(this.flightChecklist);
      } else {
        promise = () => this.flightChecklistService.create(this.flightChecklist);
      }

      await promise();

      this.clearEditChecklistField();

      await this.loaderService.hideLoaderOnSuccess();
    } catch (err) {
      await this.loaderService.hideLoaderOnFailure(err.message);
    } finally {
      this.updateTooltip();
    }
  }

  getFieldValueFromOption(fieldId: string, value: string): string | null {
    if (
      this.flightChecklistTemplateFieldsById[fieldId] &&
      this.flightChecklistTemplateFieldsById[fieldId].type ===
        EnumFlightChecklistTemplateFieldType.select
    ) {
      for (const option of this.flightChecklistTemplateFieldsById[fieldId].options) {
        if (option.id === value) {
          return option.text;
        }
      }
    }

    return value;
  }

  getFieldStatusFromOption(
    fieldId: string,
    value: string
  ): EnumFlightChecklistTemplateFieldOptionStatus | null {
    if (
      this.flightChecklistTemplateFieldsById[fieldId] &&
      this.flightChecklistTemplateFieldsById[fieldId].type ===
        EnumFlightChecklistTemplateFieldType.select
    ) {
      for (const option of this.flightChecklistTemplateFieldsById[fieldId].options) {
        if (option.id === value) {
          return option.status;
        }
      }
    }

    return null;
  }

  showFieldHistory(fieldId: string): void {
    if (this.showHistoryFieldId === fieldId) {
      this.showHistoryFieldId = null;
    } else {
      this.showHistoryFieldId = fieldId;
    }

    this.updateTooltip();
  }
}
