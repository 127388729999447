import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { faEdit, faEye, faHome } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { EnumEnquiryType } from 'src/app/enums/enquiry-type.enum';
import { IEnquiryFlight } from 'src/app/interfaces/enquiry-flight.interface';
import {
  IFlightChecklistTemplate,
  IFlightChecklistTemplateField
} from 'src/app/interfaces/flight-checklist-template.interface';
import {
  IFlightChecklist,
  getFinishedCheckOnTotalForFlight
} from 'src/app/interfaces/flight-checklist.interface';
import { AclService } from 'src/app/services/acl.service';
import { EnquiryFlightService } from 'src/app/services/enquiry-flights/enquiry-flights.service';
import { FlightChecklistTemplateService } from 'src/app/services/flight-checklist-templates/flight-checklist-templates.service';
import { FlightChecklistService } from 'src/app/services/flight-checklists/flight-checklists.service';
import { LoaderService } from 'src/app/services/loader/loader.service';

@Component({
  selector: 'app-enquiry-flights-table',
  templateUrl: './enquiry-flights-table.component.html',
  styleUrls: ['./enquiry-flights-table.component.scss']
})
export class EnquiryFlightsTableComponent implements OnInit, OnChanges, OnDestroy {
  @Input() loading: boolean = false;
  @Input() enquiryFlights: IEnquiryFlight[] = [];
  @Input() hideEnquiryColumns: boolean = false;

  @ViewChild('modalComment', { static: false }) modalCommentElement: ElementRef;
  @ViewChild('modalChecklist', { static: false }) modalChecklistElement: ElementRef;

  EnumAcl = EnumAcl;
  EnumEnquiryType = EnumEnquiryType;

  getFinishedCheckOnTotalForFlight = getFinishedCheckOnTotalForFlight;

  faHome = faHome;
  faEdit = faEdit;
  faEye = faEye;

  calendarDates: string[] = [];

  nbDaysDisplayed: number = 5;

  selectedFlight: IEnquiryFlight | null = null;
  formComment: FormGroup = new FormGroup({
    comment: new FormControl('')
  });

  flightChecklistTemplateByEnquiryType: { [enquiryType: string]: IFlightChecklistTemplate | null } =
    {};
  flightChecklistByEnquiryFlight: { [enquiryFlightId: string]: IFlightChecklist | null } = {};

  flightChecklistTemplate: IFlightChecklistTemplate | null = null;
  flightChecklistTemplateFieldsById: { [fieldId: string]: IFlightChecklistTemplateField } = {};
  loadingFlightChecklistTemplate: boolean = false;

  flightChecklist: IFlightChecklist | null = null;
  loadingFlightChecklist: boolean = false;

  private subscriptions = new Subscription();
  private subscriptionFlightChecklist = new Subscription();

  constructor(
    private aclService: AclService,
    private enquiryFlightService: EnquiryFlightService,
    private loaderService: LoaderService,
    private flightChecklistTemplateService: FlightChecklistTemplateService,
    private flightChecklistService: FlightChecklistService
  ) {}

  ngOnInit(): void {
    this.refreshChecklistAndTemplateFromEnquiryFlights();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.refreshChecklistAndTemplateFromEnquiryFlights();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.subscriptionFlightChecklist.unsubscribe();

    window['$']('.tooltip').remove();

    this.removeModal();
  }

  removeModal(): void {
    window['$'](this.modalCommentElement.nativeElement).modal('hide');
    window['$']('body').removeClass('modal-open');
    window['$']('.modal-backdrop').remove();
  }

  selectFlight(flight: IEnquiryFlight | null = null): void {
    this.selectedFlight = flight;
  }

  editComment(flight: IEnquiryFlight): void {
    this.selectFlight(flight);

    this.formComment.reset();
    this.formComment.get('comment').setValue(flight.comment || null);
    this.formComment.get('comment').updateValueAndValidity();

    window['$'](this.modalCommentElement.nativeElement).modal('show');
  }

  viewFlightChecklist(flight: IEnquiryFlight): void {
    this.selectFlight(flight);

    this.loadFlightChecklistAndTemplate();

    window['$'](this.modalChecklistElement.nativeElement).modal('show');

    this.updateTooltip();
  }

  async submitFormComment(): Promise<void> {
    this.formComment.markAsTouched();

    if (this.formComment.status == 'VALID' && this.selectedFlight) {
      this.formComment.disable();

      this.loaderService.presentLoader();

      try {
        await this.enquiryFlightService.update({
          id: this.selectedFlight.id,
          comment: this.formComment.value.comment
        } as IEnquiryFlight);

        this.formComment.reset();
        this.formComment.enable();

        window['$'](this.modalCommentElement.nativeElement).modal('hide');

        this.loaderService.hideLoaderOnSuccess();
      } catch (err: any) {
        this.loaderService.hideLoaderOnFailure(err.message);
      }
    }
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  loadFlightChecklistAndTemplate(): void {
    if (this.selectedFlight) {
      if (this.subscriptionFlightChecklist) {
        this.subscriptionFlightChecklist.unsubscribe();
      }

      this.subscriptionFlightChecklist = new Subscription();

      this.loadFlightChecklistTemplate();
      this.loadFlightChecklist();
    }
  }

  private loadFlightChecklistTemplate(): void {
    if (this.selectedFlight) {
      this.loadingFlightChecklistTemplate = true;

      this.subscriptionFlightChecklist.add(
        this.flightChecklistTemplateService
          .getOneForEnquiryType(this.selectedFlight.enquiryType)
          .subscribe((flightChecklistTemplate: IFlightChecklistTemplate) => {
            this.flightChecklistTemplate = flightChecklistTemplate;

            if (this.flightChecklistTemplate) {
              this.flightChecklistTemplateByEnquiryType[this.flightChecklistTemplate.enquiryType] =
                this.flightChecklistTemplate;
            }

            this.loadingFlightChecklistTemplate = false;
          })
      );
    }
  }

  private loadFlightChecklist(): void {
    if (this.selectedFlight) {
      this.loadingFlightChecklist = true;

      this.subscriptionFlightChecklist.add(
        this.flightChecklistService
          .getForFlight(this.selectedFlight.id)
          .subscribe((flightChecklist: IFlightChecklist) => {
            this.flightChecklist = flightChecklist;

            if (this.flightChecklist) {
              this.flightChecklistByEnquiryFlight[this.selectedFlight.id] = this.flightChecklist;
            }

            this.loadingFlightChecklist = false;
          })
      );
    }
  }

  updateTooltip(): void {
    window['$']('.tooltip').remove();

    window['$']('[rel="tooltip"]').tooltip({
      html: true,
      boundary: 'window',
      trigger: 'hover'
    });
  }

  refreshChecklistAndTemplateFromEnquiryFlights(): void {
    if (this.enquiryFlights) {
      for (const enquiryFlight of this.enquiryFlights) {
        this.loadChecklistTemplate(enquiryFlight.enquiryType);
        this.loadChecklist(enquiryFlight.id);
      }
    }
  }

  loadChecklistTemplate(enquiryType: EnumEnquiryType): void {
    if (typeof this.flightChecklistTemplateByEnquiryType[enquiryType] === 'undefined') {
      this.flightChecklistTemplateByEnquiryType[enquiryType] = null;

      this.subscriptions.add(
        this.flightChecklistTemplateService
          .getOneForEnquiryType(enquiryType)
          .subscribe((flightChecklistTemplate: IFlightChecklistTemplate) => {
            if (flightChecklistTemplate) {
              this.flightChecklistTemplateByEnquiryType[enquiryType] = flightChecklistTemplate;
            }
          })
      );
    }
  }

  loadChecklist(enquiryFlightId: string): void {
    if (typeof this.flightChecklistByEnquiryFlight[enquiryFlightId] === 'undefined') {
      this.flightChecklistByEnquiryFlight[enquiryFlightId] = null;

      this.subscriptions.add(
        this.flightChecklistService
          .getForFlight(enquiryFlightId)
          .subscribe((flightChecklist: IFlightChecklist) => {
            if (flightChecklist) {
              this.flightChecklistByEnquiryFlight[enquiryFlightId] = flightChecklist;
            }
          })
      );
    }
  }
}
