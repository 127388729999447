<ng-container *ngIf="loading">
    <div class="card mt-4 mb-4" *ngFor="let number of [].constructor(2)">
        <div class="card-body">
            <div class="placeholder w-100"></div>
            <div class="placeholder w-100"></div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="!loading">
    <div class="text-end mt-2 mb-2">
        <button type="button" class="btn btn-primary" (click)="addLeg()">
            <fa-icon [icon]="faPlus"></fa-icon> Ajout leg
        </button>
    </div>
    <div *ngIf="!enquiryFlights.length" class="alert alert-warning text-center">
        Aucun vol n'a été trouvé pour ce dossier.
    </div>
    <div class="card mb-4" *ngFor="let enquiryFlight of enquiryFlights; let i = index">
        <div class="card-header d-flex align-items-center justify-content-between">
            Leg #{{ i + 1 }}

            <div class="btn-toolbar gap-2">
                <div class="btn-group">
                    <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        (click)="viewFlightChecklist(enquiryFlight)"
                        *ngIf="hasAclAccess(EnumAcl.flightChecklistsView) && !noChecklistBtn"
                    >
                        Checklist
                        <span
                            class="ms-2"
                            [innerText]="
                                getFinishedCheckOnTotalForFlight(
                                    flightChecklistTemplateByEnquiryType[enquiryFlight.enquiryType],
                                    flightChecklistByEnquiryFlight[enquiryFlight.id]
                                )
                            "
                            *ngIf="flightChecklistTemplateByEnquiryType[enquiryFlight.enquiryType]"
                        ></span>
                    </button>
                </div>

                <div class="btn-group">
                    <button
                        type="button"
                        class="btn btn-danger btn-sm"
                        rel="tooltip"
                        title="Supprimer leg"
                        (click)="deleteLeg(enquiryFlight)"
                        *ngIf="!noDeleteBtn"
                    >
                        <fa-icon [icon]="faTrash"></fa-icon>
                    </button>
                    <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        rel="tooltip"
                        title="Éditer leg"
                        (click)="editLeg(enquiryFlight)"
                    >
                        <fa-icon [icon]="faEdit"></fa-icon>
                    </button>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="row flight-itinerary">
                <div class="col-lg-4">
                    <div class="flight-itinerary-airport">
                        <fa-icon [icon]="faPlaneDeparture"></fa-icon>
                        <a
                            [routerLink]="'/admin/airports/' + enquiryFlight.airportDepartId"
                            target="_blank"
                        >
                            {{ enquiryFlight.airportDepartIataCode }}</a
                        ><br />
                        {{ enquiryFlight.airportDepartTitle }}
                    </div>
                    <div class="flight-itinerary-datetime">
                        {{
                            enquiryFlight.departDateLocal
                                | date : "EE dd MMM yyyy" : undefined : "fr"
                        }}<br />
                        <strong>LT :</strong> {{ enquiryFlight.departTimeLocal }}<br />
                        <strong>UTC :</strong> {{ enquiryFlight.departTimeUtc }}
                        <div *ngIf="enquiryFlight.departSlotEnabled">
                            <strong>Slot :</strong> {{ enquiryFlight.departSlotTime }} LT
                        </div>
                        <div *ngIf="enquiryFlight.departTerminalEnabled">
                            <strong>Terminal :</strong> {{ enquiryFlight.departTerminal }}
                        </div>
                    </div>
                    <div class="card card-child mt-4 mb-4" *ngIf="enquiryFlight.departFboEnabled">
                        <div class="card-header d-flex justify-content-between align-items-center">
                            <span class="fake-btn"></span>
                            FBO départ
                            <a
                                [routerLink]="'/admin/fbos/' + enquiryFlight.departFboId"
                                class="btn btn-light btn-sm"
                                target="_blank"
                                ><fa-icon [icon]="faEye"></fa-icon
                            ></a>
                        </div>
                        <div class="card-body placeholder-glow">
                            <ng-container *ngIf="!fbosObj[enquiryFlight.departFboId]">
                                <div
                                    class="placeholder w-100"
                                    *ngFor="let number of [].constructor(4)"
                                ></div>
                            </ng-container>

                            <ng-container *ngIf="fbosObj[enquiryFlight.departFboId]">
                                <strong
                                    [innerText]="fbosObj[enquiryFlight.departFboId].name"
                                ></strong>
                                <div
                                    [innerHTML]="
                                        getFboFullAddress(fbosObj[enquiryFlight.departFboId])
                                    "
                                ></div>
                                <div *ngIf="fbosObj[enquiryFlight.departFboId].phoneNumber">
                                    Téléphone :
                                    <a
                                        [href]="
                                            'tel:' + fbosObj[enquiryFlight.departFboId].phoneNumber
                                        "
                                        [innerText]="fbosObj[enquiryFlight.departFboId].phoneNumber"
                                    ></a>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div class="card card-child mt-4 mb-4" *ngIf="enquiryFlight.departAgentEnabled">
                        <div class="card-header text-center">Représentant départ</div>
                        <div class="card-body placeholder-glow">
                            <a
                                *ngIf="enquiryFlight.departAgentUserId"
                                [routerLink]="'/admin/users/' + enquiryFlight.departAgentUserId"
                                [innerText]="
                                    enquiryFlight.departAgentUserFullName +
                                    ' (' +
                                    enquiryFlight.departAgentUserInitials +
                                    ')'
                                "
                            ></a>
                            <strong
                                [innerText]="enquiryFlight.departAgentUserFullName"
                                *ngIf="!enquiryFlight.departAgentUserId"
                            ></strong>
                            <div *ngIf="enquiryFlight.departAgentCompany">
                                Société : {{ enquiryFlight.departAgentCompany }}
                            </div>
                            <div *ngIf="enquiryFlight.departAgentPhone">
                                Téléphone :
                                <a
                                    [href]="'tel:' + enquiryFlight.departAgentPhone"
                                    [innerText]="enquiryFlight.departAgentPhone"
                                ></a>
                            </div>
                            <div *ngIf="enquiryFlight.departAgentEmail">
                                Email :
                                <a
                                    [href]="'mailto:' + enquiryFlight.departAgentEmail"
                                    [innerText]="enquiryFlight.departAgentEmail"
                                ></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="flight-time mt-2 mb-2">
                        <div
                            [class.text-muted]="!enquiryFlight.flightNumber"
                            [innerText]="enquiryFlight.flightNumber || 'Numéro de vol à préciser'"
                        ></div>
                        <fa-icon [icon]="faClock"></fa-icon>
                        <span
                            [innerText]="
                                convertMinutesToTime(
                                    getEnquiryFlightFlyTimeInMin(enquiryFlight),
                                    'h ',
                                    'min'
                                )
                            "
                            *ngIf="getEnquiryFlightFlyTimeInMin(enquiryFlight) > 0"
                        ></span>
                        <div
                            class="alert alert-danger text-center"
                            *ngIf="getEnquiryFlightFlyTimeInMin(enquiryFlight) < 0"
                        >
                            Le temps de vol est impossible à calculer. Vérifiez les heures de départ
                            et arrivée.
                        </div>
                        <div
                            class="text-muted"
                            *ngIf="getEnquiryFlightFlyTimeInMin(enquiryFlight) === 0"
                        >
                            x h x min
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="flight-itinerary-airport">
                        <fa-icon [icon]="faPlaneArrival"></fa-icon>
                        <a
                            [routerLink]="'/admin/airports/' + enquiryFlight.airportArrivalId"
                            target="_blank"
                        >
                            {{ enquiryFlight.airportArrivalIataCode }} </a
                        ><br />
                        {{ enquiryFlight.airportArrivalTitle }}
                    </div>
                    <div class="flight-itinerary-datetime">
                        <span
                            class="text-muted"
                            *ngIf="
                                enquiryFlight.departDateTimeUtc === enquiryFlight.arrivalDateTimeUtc
                            "
                            >Date et heure d'arrivée à préciser</span
                        >
                        <ng-container
                            *ngIf="
                                enquiryFlight.departDateTimeUtc !== enquiryFlight.arrivalDateTimeUtc
                            "
                        >
                            {{
                                enquiryFlight.arrivalDateLocal
                                    | date : "EE dd MMM yyyy" : undefined : "fr"
                            }}<br />
                            <strong>LT :</strong> {{ enquiryFlight.arrivalTimeLocal }}<br />
                            <strong>UTC :</strong> {{ enquiryFlight.arrivalTimeUtc }}
                        </ng-container>
                        <div *ngIf="enquiryFlight.arrivalSlotEnabled">
                            <strong>Slot :</strong> {{ enquiryFlight.arrivalSlotTime }} LT
                        </div>
                        <div *ngIf="enquiryFlight.arrivalTerminalEnabled">
                            <strong>Terminal :</strong> {{ enquiryFlight.arrivalTerminal }}
                        </div>
                        <div *ngIf="enquiryFlight.isAircraftGroundedOnArrival">
                            Reste en immobilisation
                        </div>
                    </div>
                    <div class="card card-child mt-4 mb-4" *ngIf="enquiryFlight.arrivalFboEnabled">
                        <div class="card-header d-flex justify-content-between align-items-center">
                            <span class="fake-btn"></span>
                            FBO arrivée
                            <a
                                [routerLink]="'/admin/fbos/' + enquiryFlight.arrivalFboId"
                                class="btn btn-light btn-sm"
                                target="_blank"
                                ><fa-icon [icon]="faEye"></fa-icon
                            ></a>
                        </div>
                        <div class="card-body placeholder-glow">
                            <ng-container *ngIf="!fbosObj[enquiryFlight.arrivalFboId]">
                                <div
                                    class="placeholder w-100"
                                    *ngFor="let number of [].constructor(4)"
                                ></div>
                            </ng-container>

                            <ng-container *ngIf="fbosObj[enquiryFlight.arrivalFboId]">
                                <strong
                                    [innerText]="fbosObj[enquiryFlight.arrivalFboId].name"
                                ></strong>
                                <div
                                    [innerHTML]="
                                        getFboFullAddress(fbosObj[enquiryFlight.arrivalFboId])
                                    "
                                ></div>
                                <div *ngIf="fbosObj[enquiryFlight.arrivalFboId].phoneNumber">
                                    Téléphone :
                                    <a
                                        [href]="
                                            'tel:' + fbosObj[enquiryFlight.arrivalFboId].phoneNumber
                                        "
                                        [innerText]="
                                            fbosObj[enquiryFlight.arrivalFboId].phoneNumber
                                        "
                                    ></a>
                                </div>
                            </ng-container>
                        </div>
                    </div>

                    <div
                        class="card card-child mt-4 mb-4"
                        *ngIf="enquiryFlight.arrivalAgentEnabled"
                    >
                        <div class="card-header text-center">Représentant arrivé</div>
                        <div class="card-body placeholder-glow">
                            <a
                                *ngIf="enquiryFlight.arrivalAgentUserId"
                                [routerLink]="'/admin/users/' + enquiryFlight.arrivalAgentUserId"
                                [innerText]="
                                    enquiryFlight.arrivalAgentUserFullName +
                                    ' (' +
                                    enquiryFlight.arrivalAgentUserInitials +
                                    ')'
                                "
                            ></a>
                            <strong
                                [innerText]="enquiryFlight.arrivalAgentUserFullName"
                                *ngIf="!enquiryFlight.arrivalAgentUserId"
                            ></strong>
                            <div *ngIf="enquiryFlight.arrivalAgentCompany">
                                Société : {{ enquiryFlight.arrivalAgentCompany }}
                            </div>
                            <div *ngIf="enquiryFlight.arrivalAgentPhone">
                                Téléphone :
                                <a
                                    [href]="'tel:' + enquiryFlight.arrivalAgentPhone"
                                    [innerText]="enquiryFlight.arrivalAgentPhone"
                                ></a>
                            </div>
                            <div *ngIf="enquiryFlight.arrivalAgentEmail">
                                Email :
                                <a
                                    [href]="'mailto:' + enquiryFlight.arrivalAgentEmail"
                                    [innerText]="enquiryFlight.arrivalAgentEmail"
                                ></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-md-4">
                    <div class="card card-child mb-4">
                        <div class="card-header">Détails avion</div>
                        <div class="card-body">
                            Immatriculation :
                            <span class="text-muted" *ngIf="!enquiryFlight.aircraftId"
                                >À préciser</span
                            >
                            <a
                                *ngIf="enquiryFlight.aircraftId"
                                [routerLink]="'/admin/aircrafts/' + enquiryFlight.aircraftId"
                                target="_blank"
                            >
                                {{
                                    enquiryFlight.aircraftRegistration || "Immatriculation inconnue"
                                }}</a
                            ><br />
                            Type Avion:
                            <span class="text-muted" *ngIf="!enquiryFlight.aircraftModelId"
                                >À préciser</span
                            >
                            <a
                                *ngIf="enquiryFlight.aircraftModelId"
                                [routerLink]="
                                    '/admin/aircraft-models/' + enquiryFlight.aircraftModelId
                                "
                                target="_blank"
                            >
                                {{ enquiryFlight.aircraftModelTitle }}</a
                            ><br />

                            Opérateur :
                            <span class="text-muted" *ngIf="!enquiryFlight.airlineId"
                                >À préciser</span
                            >
                            <a
                                *ngIf="enquiryFlight.airlineId"
                                [routerLink]="'/admin/airlines/' + enquiryFlight.airlineId"
                                target="_blank"
                            >
                                {{ enquiryFlight.airlineTitle }}</a
                            ><br />
                            Nationalité :
                            {{
                                enquiryFlight.countryCode
                                    ? countries[enquiryFlight.countryCode]
                                    : "À préciser"
                            }}
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card card-child mb-4">
                        <div
                            class="card-header"
                            [innerText]="
                                [EnumEnquiryType.cargo, EnumEnquiryType.commercial].includes(
                                    enquiry?.type
                                )
                                    ? 'Détails opérateur'
                                    : 'Détails équipage'
                            "
                        ></div>
                        <ul class="list-group list-group-flush placeholder-glow list-group-crew">
                            <li class="list-group-item">
                                <label>OPS H24</label>
                                <span class="text-muted" *ngIf="!enquiryFlight.opsH24Phone">
                                    À préciser
                                </span>
                                <a
                                    [href]="'tel:' + enquiryFlight.opsH24Phone"
                                    [innerText]="enquiryFlight.opsH24Phone"
                                    *ngIf="enquiryFlight.opsH24Phone"
                                ></a>
                            </li>
                            <ng-container
                                *ngIf="[EnumEnquiryType.business].includes(enquiry?.type)"
                            >
                                <li class="list-group-item">
                                    <label>PIC</label>
                                    <span class="text-muted" *ngIf="!enquiryFlight.captainId">
                                        À préciser
                                    </span>
                                    <div *ngIf="enquiryFlight.captainId">
                                        <div
                                            class="placeholder w-100"
                                            *ngIf="!crewsObj[enquiryFlight.captainId]"
                                        ></div>
                                        <a
                                            *ngIf="crewsObj[enquiryFlight.captainId]"
                                            [routerLink]="'/admin/crews/' + enquiryFlight.captainId"
                                            target="_blank"
                                            [innerText]="
                                                crewsObj[enquiryFlight.captainId].lastname +
                                                ' ' +
                                                crewsObj[enquiryFlight.captainId].firstname
                                            "
                                        >
                                        </a>
                                        <div *ngIf="crewsObj[enquiryFlight.captainId]?.phoneNumber">
                                            Téléphone :
                                            <a
                                                [href]="
                                                    'tel:' +
                                                    crewsObj[enquiryFlight.captainId].phoneNumber
                                                "
                                                [innerText]="
                                                    crewsObj[enquiryFlight.captainId].phoneNumber
                                                "
                                            ></a>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <label>SIC</label>
                                    <span class="text-muted" *ngIf="!enquiryFlight.coPilotId">
                                        À préciser
                                    </span>
                                    <div *ngIf="enquiryFlight.coPilotId">
                                        <div
                                            class="placeholder w-100"
                                            *ngIf="!crewsObj[enquiryFlight.coPilotId]"
                                        ></div>

                                        <a
                                            *ngIf="crewsObj[enquiryFlight.coPilotId]"
                                            [routerLink]="'/admin/crews/' + enquiryFlight.coPilotId"
                                            target="_blank"
                                            class="me-2"
                                            [innerText]="
                                                crewsObj[enquiryFlight.coPilotId].lastname +
                                                ' ' +
                                                crewsObj[enquiryFlight.coPilotId].firstname
                                            "
                                        >
                                        </a>
                                        <div *ngIf="crewsObj[enquiryFlight.coPilotId]?.phoneNumber">
                                            Téléphone :
                                            <a
                                                [href]="
                                                    'tel:' +
                                                    crewsObj[enquiryFlight.coPilotId].phoneNumber
                                                "
                                                [innerText]="
                                                    crewsObj[enquiryFlight.coPilotId].phoneNumber
                                                "
                                            ></a>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <label>CC</label>
                                    <span
                                        class="text-muted"
                                        *ngIf="!enquiryFlight.stewardessIds?.length"
                                    >
                                        À préciser
                                    </span>
                                    <div *ngIf="enquiryFlight.stewardessIds?.length">
                                        <ng-container
                                            *ngFor="let stewardessId of enquiryFlight.stewardessIds"
                                        >
                                            <span
                                                class="placeholder w-100"
                                                *ngIf="!crewsObj[stewardessId]"
                                            ></span>
                                            <a
                                                *ngIf="crewsObj[stewardessId]"
                                                [routerLink]="'/admin/crews/' + stewardessId"
                                                target="_blank"
                                                class="me-2"
                                                [innerText]="
                                                    crewsObj[stewardessId].lastname +
                                                    ' ' +
                                                    crewsObj[stewardessId].firstname
                                                "
                                            >
                                            </a>
                                            <div *ngIf="crewsObj[stewardessId]?.phoneNumber">
                                                Téléphone :
                                                <a
                                                    [href]="
                                                        'tel:' + crewsObj[stewardessId].phoneNumber
                                                    "
                                                    [innerText]="crewsObj[stewardessId].phoneNumber"
                                                ></a>
                                            </div>
                                        </ng-container>
                                    </div>
                                </li>
                            </ng-container>

                            <li class="list-group-item">
                                <label>SUP</label>
                                <span
                                    class="text-muted"
                                    *ngIf="!enquiryFlight.supervisorIds?.length"
                                >
                                    À préciser
                                </span>
                                <div *ngIf="enquiryFlight.supervisorIds?.length">
                                    <ng-container
                                        *ngFor="let supervisorId of enquiryFlight.supervisorIds"
                                    >
                                        <span
                                            class="placeholder w-100"
                                            *ngIf="!supervisorsObj[supervisorId]"
                                        ></span>
                                        <a
                                            *ngIf="supervisorsObj[supervisorId]"
                                            [routerLink]="
                                                '/admin/airline-supervisors/' + supervisorId
                                            "
                                            target="_blank"
                                            class="me-2"
                                            [innerText]="
                                                supervisorsObj[supervisorId].lastname +
                                                ' ' +
                                                supervisorsObj[supervisorId].firstname
                                            "
                                        >
                                        </a>
                                        <div *ngIf="supervisorsObj[supervisorId]?.phoneNumber">
                                            Téléphone :
                                            <a
                                                [href]="
                                                    'tel:' +
                                                    supervisorsObj[supervisorId].phoneNumber
                                                "
                                                [innerText]="
                                                    supervisorsObj[supervisorId].phoneNumber
                                                "
                                            ></a>
                                        </div>
                                    </ng-container>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card card-child mb-4">
                        <div class="card-header">Détails emport</div>
                        <div class="card-body text-center" *ngIf="enquiryFlight.isFerryFlight">
                            Vol de positionnement
                        </div>
                        <ng-container *ngIf="!enquiryFlight.isFerryFlight">
                            <div
                                class="card-body"
                                *ngIf="enquiryFlight.enquiryType !== EnumEnquiryType.cargo"
                            >
                                PAX : {{ enquiryFlight.pax }}
                            </div>
                            <div
                                class="card-body"
                                *ngIf="enquiryFlight.enquiryType === EnumEnquiryType.cargo"
                            >
                                CARGO : {{ enquiryFlight.weight }}kg / {{ enquiryFlight.volume }}m³
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="card card-child" *ngIf="enquiryFlight.comment">
                <div class="card-header">Remarque</div>
                <div class="card-body" [innerText]="enquiryFlight.comment"></div>
            </div>
        </div>
    </div>
</ng-container>

<div
    class="modal fade"
    id="checklist-modal"
    tabindex="-1"
    aria-labelledby="checklist-modal-label"
    aria-hidden="true"
    #modalChecklist
>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="checklist-modal-label">
                    Checklist
                    <span
                        class="ms-1"
                        *ngIf="flightChecklistTemplate && flightChecklist"
                        [innerText]="
                            getFinishedCheckOnTotalForFlight(
                                flightChecklistTemplate,
                                flightChecklist
                            )
                        "
                    ></span>
                </h1>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <div class="modal-body">
                <app-enquiry-flight-checklist
                    [enquiryFlight]="selectedFlight"
                ></app-enquiry-flight-checklist>
            </div>
        </div>
    </div>
</div>

<div
    class="modal fade"
    id="enquiry-flight-edit-modal"
    tabindex="-1"
    aria-labelledby="enquiry-flight-edit-modalLabel"
    aria-hidden="true"
    #modalEnquiryFlightEdit
>
    <div class="modal-dialog modal-xxl">
        <div class="modal-content">
            <app-enquiry-flight-edit
                [enquiry]="enquiry"
                [enquiryFlightId]="currentEnquiryFlight?.id || null"
                [inModal]="true"
                [currentStep]="currentEnquiryFlightStep"
                (updatedSelectedEnquiryFlight)="assignSelectedEnquiryFlight($event)"
            ></app-enquiry-flight-edit>
        </div>
    </div>
</div>

<div
    class="modal fade"
    id="enquiry-flight-add-modal"
    tabindex="-1"
    aria-labelledby="enquiry-flight-add-modalLabel"
    aria-hidden="true"
    #modalEnquiryFlightAdd
>
    <div class="modal-dialog">
        <div class="modal-content">
            <app-enquiry-flight-add
                [enquiry]="enquiry"
                [enquiryFlights]="enquiryFlights"
                [inModal]="true"
                (editEnquiryFlight)="editLeg($event)"
            ></app-enquiry-flight-add>
        </div>
    </div>
</div>
