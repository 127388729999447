<nav class="navbar">
    <div class="navbar-content">
        <button
            class="navbar-toggler d-lg-none"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvas-admin-menu"
            aria-controls="offcanvas-admin-menu"
        >
            <fa-icon [icon]="faBars"></fa-icon>
        </button>

        <div class="header-content">
            <app-header-menu-breadcrumbs *ngIf="displayBreadcrumbs"></app-header-menu-breadcrumbs>
        </div>
    </div>

    <ul class="navbar-nav">
        <li class="nav-item">
            <button
                class="nav-link btn-notification"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                *ngIf="currentUser"
            >
                <fa-icon [icon]="faBell"></fa-icon>
                <span class="count-pill" *ngIf="unreadCount > 0">
                    {{ unreadCount }}
                    <span class="visually-hidden">notifications non-lus</span>
                </span>
            </button>
            <div class="dropdown-menu dropdown-menu-end">
                <ul
                    class="list-group list-group-flush notifications-list"
                    *ngIf="loadingNotifications"
                >
                    <li
                        class="list-group-item placeholder-glow"
                        *ngFor="let number of [].constructor(5)"
                    >
                        <div class="placeholder w-100"></div>
                    </li>
                </ul>
                <ul
                    class="list-group list-group-flush notifications-list"
                    *ngIf="!loadingNotifications"
                >
                    <li
                        class="list-group-item list-group-item-warning text-center"
                        *ngIf="!notifications.length"
                    >
                        Aucune notification
                    </li>
                    <button
                        type="button"
                        class="list-group-item list-group-item-action"
                        [class.read]="notification.read"
                        *ngFor="let notification of notifications"
                        (click)="openNotification(notification)"
                    >
                        <fa-icon [icon]="getNotificationIcon(notification)"></fa-icon>
                        <div class="notification-content">
                            <strong [innerText]="notification.title"></strong>
                            <div [innerHTML]="notification.message"></div>
                            <div
                                class="notification-date"
                                [innerText]="notification.created | date : 'dd/MM/yyyy HH:mm'"
                            ></div>
                        </div>
                    </button>
                </ul>
            </div>
        </li>
        <li class="nav-item dropdown">
            <button
                class="nav-link btn-user"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                *ngIf="currentUser"
            >
                <fa-icon [icon]="faCircleUser"></fa-icon>
            </button>
            <ul class="dropdown-menu dropdown-menu-end">
                <li *ngIf="currentUser">
                    <a class="dropdown-item" routerLink="/admin/my-account">
                        <fa-icon [icon]="faCircleUser"></fa-icon>
                        {{ getUserFullname(currentUser) }}
                    </a>
                </li>
                <li><hr class="dropdown-divider" /></li>
                <li>
                    <a class="dropdown-item" routerLink="/admin/my-account">
                        <fa-icon [icon]="faUser"></fa-icon> Mon profil</a
                    >
                </li>
                <li><hr class="dropdown-divider" /></li>
                <li>
                    <button type="button" class="dropdown-item" (click)="signOut()">
                        <fa-icon [icon]="faPowerOff"></fa-icon>
                        Déconnexion
                    </button>
                </li>
            </ul>
        </li>
    </ul>
</nav>
