import { Component, OnInit, NgZone, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { PaginationService } from '../../../services/pagination.service';
import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';
import { EnumLanguage, getLanguageLabel } from 'src/app/enums/language.enum';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { IEmailTemplate } from 'src/app/interfaces/email-template.interface';

@Component({
  selector: 'app-email-template-view',
  templateUrl: './email-template-view.component.html',
  styleUrls: ['./email-template-view.component.scss']
})
export class EmailTemplateViewComponent implements OnInit {
  EnumLanguage = EnumLanguage;
  EnumAcl = EnumAcl;
  getLanguageLabel = getLanguageLabel;

  loading: boolean = false;
  emailTemplateId: string;
  emailTemplate: IEmailTemplate | null = null;
  isLogged: boolean = false;

  constructor(
    private remoteService: RemoteService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public paginationService: PaginationService,
    private aclService: AclService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
  }

  ngOnInit(): void {
    this.activatedRoute.url.subscribe(() => {
      this.emailTemplateId = this.activatedRoute.snapshot.paramMap.get('emailTemplateId');

      this.loadData();
    });
  }

  loadData(): void {
    if (this.isLogged) {
      this.loadEmailTemplate();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  async loadEmailTemplate(): Promise<void> {
    this.loading = true;

    const doc = await this.remoteService.getDocument('emailTemplates', this.emailTemplateId);

    this.emailTemplate = doc as IEmailTemplate;

    this.loading = false;
  }

  delete(): void {
    if (this.hasAclAccess(EnumAcl.emailTemplatesDelete)) {
      const result = confirm(
        "La suppression d'un template d'email sera permanente. Êtes-vous sûr de vouloir continuer?"
      );

      if (result) {
        this.remoteService
          .deleteDocumentInCollection('emailTemplates', this.emailTemplateId)
          .then(() => {
            this.router.navigate(['/admin/email-templates']);

            alert('La suppression a été effectuée avec succès.');
          })
          .catch(err => {
            console.log(err);
          });
      }
    }
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }
}
