import {
  faBell,
  faFolder,
  faPlaneDeparture,
  IconDefinition
} from '@fortawesome/free-solid-svg-icons';
import { IStoreData } from './store-data.interface';

export interface INotification extends IStoreData {
  type: EnumNotificationType;
  userId: string;
  title: string;
  message: string;
  hasEmailNotification: boolean;
  emailPoolId: string | null;
  read: boolean;
  readDate?: Date;
  params: INotificationParams;
}

export interface INotificationParams {
  enquiryId?: string;
  invoiceId?: string;
}

export enum EnumNotificationType {
  enquiryIsConfirmed = 'enquiryIsConfirmed',
  cfmuNotification = 'cfmuNotification',
  enquiryFollowUp = 'enquiryFollowUp'
}

export const getNotificationIcon = (notification: INotification): IconDefinition => {
  switch (notification.type) {
    default:
      return faBell;
    case EnumNotificationType.enquiryIsConfirmed:
      return faFolder;
    case EnumNotificationType.cfmuNotification:
      return faPlaneDeparture;
    case EnumNotificationType.enquiryFollowUp:
      return faFolder;
  }
};

export const getNotificationUrl = (notification: INotification): string | null => {
  switch (notification.type) {
    case EnumNotificationType.enquiryIsConfirmed:
    case EnumNotificationType.cfmuNotification:
    case EnumNotificationType.enquiryFollowUp:
      return notification.params.enquiryId
        ? '/admin/enquiries/' + notification.params.enquiryId
        : null;
  }

  return null;
};
