<div class="row justify-content-center">
    <div class="col-md-6 col-xl-4">
        <div class="card mb-4">
            <div class="card-body">
                <p class="text-center">
                    Veuillez importer le fichier du paiement des dossiers en se basant sur le
                    fichier d'exemple
                    <a href="assets/exemple_import_paiement_dossiers.xlsx" target="_blank">ici</a>.
                </p>

                <div class="form-group mb-0">
                    <input
                        type="file"
                        id="fileInput"
                        class="form-control"
                        (change)="changeInputFile($event)"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    />
                </div>
            </div>
        </div>
    </div>
</div>
<div class="card mb-4" *ngIf="fileContentLoaded">
    <div class="card-body">
        <div class="alert alert-danger text-center mt-2" *ngIf="!dataToImportValid">
            Le fichier importé n'est pas au bon format.<br />
            Veuillez comparer avec le fichier d'exemple.
        </div>
        <div
            class="alert alert-warning text-center mt-2"
            *ngIf="dataToImportValid && !dataToImport.lines.length"
        >
            Le fichier est vide de donnée.
        </div>

        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th>Validation</th>
                        <th
                            *ngFor="let column of dataToImport.headers"
                            [class.text-muted]="fieldsIgnoredWhenImported.includes(column)"
                        >
                            <div *ngIf="fieldsIgnoredWhenImported.includes(column)">
                                <fa-icon
                                    [icon]="faQuestionCircle"
                                    rel="tooltip"
                                    title="Cette colonne est ignorée lors de l'importation"
                                ></fa-icon>
                            </div>
                            {{ column }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let line of dataToImport.lines">
                        <td class="text-center">
                            <div
                                class="badge text-bg-success"
                                *ngIf="line.status === EnumDataToImportLineStatus.valid"
                            >
                                Valide
                            </div>
                            <div
                                class="badge text-bg-warning"
                                *ngIf="line.status === EnumDataToImportLineStatus.validating"
                            >
                                <div class="spinner-border spinner-border-sm me-2" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>

                                Validation en cours
                            </div>
                            <div
                                class="badge text-bg-danger"
                                *ngIf="line.status === EnumDataToImportLineStatus.issueToFix"
                            >
                                Invalide
                            </div>
                            <div
                                class="badge text-bg-warning"
                                *ngIf="line.status === EnumDataToImportLineStatus.warning"
                            >
                                Attention
                            </div>
                            <div
                                class="badge text-bg-info"
                                *ngIf="line.status === EnumDataToImportLineStatus.info"
                            >
                                Information
                            </div>

                            <div
                                class="mt-2"
                                [class.text-danger]="
                                    getValidationStatusLevel(validationMsg.status) === 'error'
                                "
                                [class.text-warning]="
                                    getValidationStatusLevel(validationMsg.status) === 'warning'
                                "
                                [class.text-info]="
                                    getValidationStatusLevel(validationMsg.status) === 'info'
                                "
                                *ngFor="let validationMsg of line.validationMsgs"
                            >
                                <div
                                    *ngIf="
                                        validationMsg.status ===
                                        EnumValidationMsgStatus.enquiryNotFound
                                    "
                                    class="mb-2"
                                >
                                    Le contrat
                                    <strong>{{ line.enquiryRefContractTitle }}</strong>
                                    est introuvable dans SimplyFly.
                                </div>

                                <div
                                    *ngIf="
                                        validationMsg.status ===
                                        EnumValidationMsgStatus.mandatoryFieldsEmpty
                                    "
                                    class="mb-2"
                                >
                                    Le champ suivant est obligatoire :
                                    {{ validationMsg.comment }}.
                                </div>

                                <div
                                    *ngIf="
                                        validationMsg.status ===
                                        EnumValidationMsgStatus.courtierIncorrect
                                    "
                                    class="mb-2"
                                >
                                    Le <strong>courtier</strong> est différent de celui dans
                                    SimplyFly. SimplyFly : {{ validationMsg.comment }}. <br />
                                    Fichier d'import: {{ line.userInitials }}.
                                </div>

                                <div
                                    *ngIf="
                                        validationMsg.status === EnumValidationMsgStatus.modNotValid
                                    "
                                    class="mb-2"
                                >
                                    Le <strong>MOD</strong> n'existe pas dans SimplyFly.
                                    <br />
                                    Fichier d'import: {{ validationMsg.comment }}.
                                </div>

                                <div
                                    *ngIf="
                                        validationMsg.status ===
                                        EnumValidationMsgStatus.clientIncorrect
                                    "
                                    class="mb-2"
                                >
                                    Le <strong>client</strong> est différent de celui dans
                                    SimplyFly. SimplyFly : {{ validationMsg.comment }}. <br />
                                    Fichier d'import: {{ line.clientName }}.
                                </div>

                                <div
                                    *ngIf="
                                        validationMsg.status ===
                                        EnumValidationMsgStatus.airlineIncorrect
                                    "
                                    class="mb-2"
                                >
                                    La <strong>compagnie aérienne</strong> est différente du dossier
                                    dans SimplyFly. <br />
                                    SimplyFly: {{ validationMsg.comment }}.<br />
                                    Fichier d'import:
                                    {{ line.airlineTitles?.join(", ") }}.
                                </div>

                                <div
                                    *ngIf="
                                        validationMsg.status ===
                                        EnumValidationMsgStatus.purchaseInvoiceNotFound
                                    "
                                    class="mb-2"
                                >
                                    La <strong>facture d'achat</strong> n'est pas présente dans
                                    SimplyFly. Son montant sera importé sans la pièce PDF.
                                </div>
                                <div
                                    *ngIf="
                                        validationMsg.status ===
                                        EnumValidationMsgStatus.addTemporaryLine
                                    "
                                    class="mb-2"
                                >
                                    La ligne sera ajouté en tant que ligne temporaire.
                                </div>
                                <div
                                    *ngIf="
                                        validationMsg.status ===
                                        EnumValidationMsgStatus.definitiveInvoiceNotFound
                                    "
                                    class="mb-2"
                                >
                                    La
                                    <strong>facture définitive {{ line.invoiceRef }}</strong> n'est
                                    pas présente dans SimplyFly.
                                </div>
                                <div
                                    *ngIf="
                                        validationMsg.status ===
                                        EnumValidationMsgStatus.invoiceAmountIncorrect
                                    "
                                    class="mb-2"
                                >
                                    Le montant de la facture ne correspond pas. SimplyFly:
                                    {{ validationMsg.comment }}.<br />
                                    Fichier d'import:
                                    {{
                                        formatPrice(
                                            line.invoiceAmountHtTotal,
                                            line.invoiceCurrency
                                        )
                                    }}.
                                </div>
                                <div
                                    *ngIf="
                                        validationMsg.status ===
                                        EnumValidationMsgStatus.encaissementMissing
                                    "
                                    class="mb-2"
                                >
                                    L'<strong>encaissement</strong> n'est pas présent dans
                                    SimplyFly. Son montant sera importé automatiquement.
                                </div>
                            </div>
                        </td>
                        <td>
                            <div *ngFor="let item of line.legDates" [innerText]="item"></div>
                        </td>
                        <td [innerText]="line.enquiryRefContractTitle"></td>
                        <td [innerText]="line.userInitials"></td>
                        <td [innerText]="line.clientName"></td>
                        <td>
                            <div *ngFor="let item of line.airlineTitles" [innerText]="item"></div>
                        </td>
                        <td [innerText]="line.invoiceRef"></td>
                        <td
                            [innerText]="
                                line.invoiceIssueDate
                                    ? (line.invoiceIssueDate | date : 'yyyy-MM-dd')
                                    : ''
                            "
                        ></td>
                        <td
                            [class.text-danger]="line.invoiceAmountHtTotal < 0"
                            [innerText]="
                                line.invoiceAmountHtTotal
                                    ? formatPrice(line.invoiceAmountHtTotal, line.invoiceCurrency)
                                    : null
                            "
                        ></td>
                        <td
                            [class.text-danger]="line.invoiceAmountTvaTotal < 0"
                            [innerText]="
                                line.invoiceAmountTvaTotal
                                    ? formatPrice(line.invoiceAmountTvaTotal, line.invoiceCurrency)
                                    : null
                            "
                        ></td>
                        <td
                            [class.text-danger]="line.invoiceAmountTtcTotal < 0"
                            [innerText]="
                                line.invoiceAmountTtcTotal
                                    ? formatPrice(line.invoiceAmountTtcTotal, line.invoiceCurrency)
                                    : null
                            "
                        ></td>
                        <td
                            [class.text-danger]="line.enquiryMargin < 0"
                            [innerText]="
                                line.enquiryMargin
                                    ? formatPrice(line.enquiryMargin, line.invoiceCurrency)
                                    : null
                            "
                        ></td>
                        <td>
                            <div
                                *ngFor="let item of line.invoiceProductsLabel"
                                [innerText]="item"
                            ></div>
                        </td>
                        <td>
                            <div *ngFor="let item of line.enquiryRouting" [innerText]="item"></div>
                        </td>
                        <td
                            [innerText]="
                                line.invoiceDueDate
                                    ? (line.invoiceDueDate | date : 'yyyy-MM-dd')
                                    : ''
                            "
                        ></td>
                        <td
                            [class.text-danger]="line.encaissementsAmount < 0"
                            [innerText]="
                                line.encaissementsAmount
                                    ? formatPrice(line.encaissementsAmount, line.invoiceCurrency)
                                    : null
                            "
                        ></td>
                        <td [innerText]="line.encaissementsTypeId"></td>
                        <td
                            [innerText]="
                                line.encaissementsDate
                                    ? (line.encaissementsDate | date : 'yyyy-MM-dd')
                                    : ''
                            "
                        ></td>
                        <td [innerText]="line.marginHT"></td>
                        <td [innerText]="line.tva"></td>
                        <td [innerText]="line.marginTtc"></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="card-footer d-flex justify-content-between">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>

        <button
            type="button"
            class="btn btn-primary"
            (click)="import()"
            [disabled]="!dataToImportValid || importing || !allDataToImportAreValid()"
        >
            Importer
        </button>
    </div>
</div>
