<div class="btn-toolbar mb-4 justify-content-between" role="toolbar">
    <a
        routerLink="/admin/aircraft-models/add"
        class="btn btn-primary"
        *ngIf="hasAclAccess(EnumAcl.aircraftModelsAdd)"
        >+ Nouveau modèle d'avion</a
    >
    <a
        routerLink="/admin/statistics/aircraft-models"
        class="btn btn-primary"
        *ngIf="hasAclAccess(EnumAcl.aircraftModelsStatistics)"
        >Statistiques</a
    >
</div>
<div class="card mb-4">
    <div class="table-responsive no-border">
        <table class="table table-striped table-hover">
            <thead>
                <tr>
                    <th
                        scope="col"
                        class="sortable"
                        *ngFor="let field of paginationService.pagination[currentPagination].fields"
                        [class.is-sorted]="
                            paginationService.pagination[currentPagination].orderBy.field ==
                            field.code
                        "
                        [class.asc]="
                            paginationService.pagination[currentPagination].orderBy.field ==
                                field.code &&
                            paginationService.pagination[currentPagination].orderBy.direction ==
                                'asc'
                        "
                        [class.desc]="
                            paginationService.pagination[currentPagination].orderBy.field ==
                                field.code &&
                            paginationService.pagination[currentPagination].orderBy.direction ==
                                'desc'
                        "
                        (click)="paginationService.sortField(currentPagination, field.code)"
                        [innerHtml]="field.title"
                    ></th>
                </tr>
            </thead>
            <tbody *ngIf="paginationService.loading">
                <tr *ngFor="let number of [].constructor(10)">
                    <td
                        *ngFor="let field of paginationService.pagination[currentPagination].fields"
                    >
                        <div class="skeleton-text"></div>
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="!paginationService.loading">
                <tr
                    *ngIf="
                        !paginationService.pagination[currentPagination].data ||
                        !paginationService.pagination[currentPagination].data.length
                    "
                    class="table-warning text-center"
                >
                    <td
                        [attr.colspan]="
                            paginationService.pagination[currentPagination].fields.length
                        "
                    >
                        Aucun modèle d'avion n'a été trouvé.
                    </td>
                </tr>
                <tr
                    *ngFor="
                        let aircraftModel of paginationService.pagination[currentPagination].data
                    "
                    [routerLink]="'/admin/aircraft-models/' + aircraftModel.id"
                    class="clickable"
                >
                    <td>
                        <div [innerHtml]="aircraftModel.title"></div>
                        <img
                            [src]="aircraftModel.imageUrl"
                            *ngIf="aircraftModel.imageUrl"
                            style="max-width: 300px"
                        />
                        <img
                            [src]="aircraftModel.imageInsideUrl"
                            *ngIf="aircraftModel.imageInsideUrl"
                            style="max-width: 300px"
                        />
                        <img
                            [src]="aircraftModel.imagePlanUrl"
                            *ngIf="aircraftModel.imagePlanUrl"
                            style="max-width: 300px"
                        />
                    </td>
                    <td [innerHtml]="aircraftModel.family.toUpperCase()"></td>
                    <td [innerHtml]="aircraftModel.targetTitle"></td>
                    <td [innerHtml]="aircraftModel.speedInKts"></td>
                    <td [innerHtml]="aircraftModel.rangeInKms"></td>
                    <td [innerHtml]="aircraftModel.weight"></td>
                    <td [innerHtml]="aircraftModel.volume"></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<nav
    aria-label="Liste navigation"
    class="mt-4 mb-4"
    *ngIf="
        !paginationService.loading &&
        paginationService.pagination[currentPagination].data &&
        paginationService.pagination[currentPagination].data.length
    "
>
    <ul class="pagination justify-content-center">
        <li
            class="page-item"
            [class.disabled]="paginationService.pagination[currentPagination].currentPage === 0"
        >
            <button
                type="button"
                class="page-link"
                (click)="paginationService.loadPreviousPage(currentPagination)"
            >
                Précédent
            </button>
        </li>
        <li class="page-item disabled">
            <span
                class="page-link"
                [innerHtml]="paginationService.pagination[currentPagination].currentPage + 1"
            ></span>
        </li>
        <li
            class="page-item"
            [class.disabled]="
                paginationService.pagination[currentPagination].data &&
                (!paginationService.pagination[currentPagination].data.length ||
                    paginationService.pagination[currentPagination].data.length <
                        paginationService.pagination[currentPagination].limit)
            "
        >
            <button
                type="button"
                class="page-link"
                (click)="paginationService.loadNextPage(currentPagination)"
            >
                Suivant
            </button>
        </li>
    </ul>
</nav>
