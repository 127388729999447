<div class="row justify-content-center align-items-end">
    <div class="col-md-6 col-lg-4 col-xl-3">
        <div class="form-group">
            <label for="userId">Accéder une demande</label>
            <app-enquiry-ref-picker
                inputName="enquiryId"
                (setValueToFormControl)="setValueToFormControl($event)"
            ></app-enquiry-ref-picker>
        </div>
    </div>
    <div class="col-md-6 col-lg-4 col-xl-3">
        <ng-container *ngIf="hasAclAccess(EnumAcl.enquiriesFilterByDepartment)">
            <div class="form-check mt-md-3">
                <input
                    type="radio"
                    name="person-or-department"
                    id="person-or-department-person"
                    [value]="EnumFilterPerPersonOrDepartment.person"
                    [(ngModel)]="filterByPersonOrDepartment"
                    class="form-check-input"
                    (change)="updatedFilterByPersonOrDepartment()"
                />
                <label class="form-check-label" for="person-or-department-person">
                    Filtrer par personne
                </label>
            </div>
            <div class="form-check">
                <input
                    type="radio"
                    name="person-or-department"
                    id="person-or-department-department"
                    [value]="EnumFilterPerPersonOrDepartment.department"
                    [(ngModel)]="filterByPersonOrDepartment"
                    class="form-check-input"
                    (change)="updatedFilterByPersonOrDepartment()"
                />
                <label class="form-check-label" for="person-or-department-department">
                    Filtrer par département
                </label>
            </div>
        </ng-container>

        <div
            class="form-group"
            *ngIf="filterByPersonOrDepartment === EnumFilterPerPersonOrDepartment.person"
        >
            <select
                class="form-select"
                id="userId"
                [(ngModel)]="userId"
                required
                [disabled]="!usersList || !usersList.length"
                (change)="userChanged()"
            >
                <option value="all">Tout le monde</option>
                <option
                    [value]="user.value"
                    *ngFor="let user of usersList"
                    [innerHtml]="user.value == currentUser.id ? user.title + ' (moi)' : user.title"
                ></option>
            </select>
        </div>
        <div
            class="form-group"
            *ngIf="filterByPersonOrDepartment === EnumFilterPerPersonOrDepartment.department"
        >
            <select
                class="form-select"
                id="enquiryType"
                [(ngModel)]="enquiryType"
                required
                (change)="enquiryTypeChanged()"
            >
                <option value="all">Tous les département</option>
                <option
                    [value]="enquiryType"
                    *ngFor="let enquiryType of getEnquiryTypes()"
                    [innerText]="getEnumEnquiryTypeLabel(enquiryType)"
                ></option>
            </select>
        </div>
    </div>

    <div class="col-md-6 col-lg-4 col-xl-3">
        <div class="form-group">
            <label for="status">Filtrées par statut</label>
            <select
                class="form-select"
                id="status"
                [(ngModel)]="status"
                required
                (change)="statusChanged()"
            >
                <option value="all">Tous</option>
                <option
                    [value]="status"
                    *ngFor="let status of getEnquiryStatuses()"
                    [innerText]="getEnumEnquiryStatusLabel(status)"
                ></option>
            </select>
        </div>
    </div>
    <div class="col-md-6 col-lg-4 col-xl-3" *ngIf="status === EnumEnquiryStatus.cancelled">
        <div class="form-group">
            <label for="cancelledReason">Filtrées par raison d'annulation</label>
            <select
                class="form-select"
                id="cancelledReason"
                [(ngModel)]="cancelledReason"
                required
                (change)="cancelledReasonChanged()"
            >
                <option value="all">Tous</option>
                <option
                    [value]="cancelledReason"
                    *ngFor="let cancelledReason of getEnquiryCancelledReason()"
                    [innerText]="getEnumEnquiryCancelledReasonLabel(cancelledReason)"
                ></option>
            </select>
        </div>
    </div>
    <div class="col-md-6 col-lg-4 col-xl-3">
        <div class="form-check mt-md-3">
            <input
                type="radio"
                name="enquiry-date-or-flight-date"
                id="enquiry-date-or-flight-date-enquiry-date"
                [value]="EnumFilterPerEnquiryDateOrFlightDate.enquiryDate"
                [(ngModel)]="filterPerEnquiryDateOrFlightDate"
                class="form-check-input"
                (change)="updatedFilterByEnquiryDateOrFlightDate()"
            />
            <label class="form-check-label" for="enquiry-date-or-flight-date-enquiry-date">
                Filtrer par date de dossier
            </label>
        </div>
        <div class="form-check">
            <input
                type="radio"
                name="enquiry-date-or-flight-date"
                id="enquiry-date-or-flight-date-flight-date"
                [value]="EnumFilterPerEnquiryDateOrFlightDate.flightDate"
                [(ngModel)]="filterPerEnquiryDateOrFlightDate"
                class="form-check-input"
                (change)="updatedFilterByEnquiryDateOrFlightDate()"
            />
            <label class="form-check-label" for="enquiry-date-or-flight-date-flight-date">
                Filtrer par date de vol
            </label>
        </div>

        <div class="form-group">
            <select
                class="form-select"
                id="filterByPeriod"
                [(ngModel)]="filterByPeriod"
                (change)="updateFilterPeriod()"
            >
                <option [ngValue]="null">Tous</option>
                <option
                    [value]="filter.value"
                    *ngFor="let filter of filtersByPeriod"
                    [innerHtml]="filter.title"
                ></option>
            </select>
        </div>
    </div>
</div>

<enquiries-list
    [loading]="paginationService.loading"
    [enquiries]="paginationService.pagination[currentPagination].data"
    [usersObj]="usersObj"
    [status]="status"
    [enquiryType]="enquiryType"
></enquiries-list>

<nav aria-label="Liste navigation" class="mt-4 mb-4" *ngIf="!paginationService.loading">
    <ul class="pagination justify-content-center">
        <li
            class="page-item"
            [class.disabled]="paginationService.pagination[currentPagination].currentPage === 0"
        >
            <button type="button" class="page-link" (click)="loadPrevPage(currentPagination)">
                Précédent
            </button>
        </li>
        <li class="page-item disabled">
            <span
                class="page-link"
                [innerHtml]="paginationService.pagination[currentPagination].currentPage + 1"
            ></span>
        </li>
        <li
            class="page-item"
            [class.disabled]="
                paginationService.pagination[currentPagination].data &&
                (!paginationService.pagination[currentPagination].data.length ||
                    paginationService.pagination[currentPagination].data.length <
                        paginationService.pagination[currentPagination].limit)
            "
        >
            <button type="button" class="page-link" (click)="loadNextPage(currentPagination)">
                Suivant
            </button>
        </li>
    </ul>
</nav>
