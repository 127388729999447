import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {
  faArrowLeft,
  faArrowRight,
  faEdit,
  faExternalLink,
  faPlaneArrival,
  faPlaneDeparture,
  faPlusCircle
} from '@fortawesome/free-solid-svg-icons';
import { addMinutes, differenceInDays, differenceInMinutes } from 'date-fns';
import { Subscription } from 'rxjs';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { EnumEnquiryType } from 'src/app/enums/enquiry-type.enum';
import { IAirlineSupervisor } from 'src/app/interfaces/airline-supervisor.interface';
import {
  getAirportLocalDateTimeFromUTC,
  getAirportLocalTimeFromUTC,
  getAirportLocalTimeFullFromUTC,
  getAirportUTCFullTimeString,
  getAirportUTCTime,
  IAirport
} from 'src/app/interfaces/airport.interface';
import {
  EnumEnquiryFlightAgentType,
  getEnquiryFlightAgentTypeLabel,
  IEnquiryFlight
} from 'src/app/interfaces/enquiry-flight.interface';
import { IEnquiry } from 'src/app/interfaces/enquiry.interface';
import { IFbo } from 'src/app/interfaces/fbo.interface';
import { IHandling } from 'src/app/interfaces/handling.interface';
import { getUserFullname, IUser } from 'src/app/interfaces/user.interface';
import { capitalize, estimatedFlyTimeInMinutes } from 'src/app/misc.utils';
import { AclService } from 'src/app/services/acl.service';
import { AirportService } from 'src/app/services/airports/airports.service';
import { FboService } from 'src/app/services/fbos/fbos.service';
import { HandlingService } from 'src/app/services/handlings/handlings.service';
import { IPipedriveOrganization } from 'src/app/interfaces/pipedrive.interface';
import { UserService } from 'src/app/services/user/user.service';
import { PipedriveService } from 'src/app/services/pipedrive.service';
import { IAircraftModel } from 'src/app/interfaces/aircraft-model.interface';
import { AircraftModelService } from 'src/app/services/aircraft-models/aircraft-models.service';
import { formatInTimeZone } from 'date-fns-tz';

type TTimeField = 'local' | 'utc';
type TAirportField = 'depart' | 'arrival';

@Component({
  selector: 'app-enquiry-flight-edit-airport',
  templateUrl: './enquiry-flight-edit-airport.component.html',
  styleUrls: ['./enquiry-flight-edit-airport.component.scss']
})
export class EnquiryFlightEditAirportComponent implements OnInit, OnChanges, OnDestroy {
  @Input() enquiry: IEnquiry | null = null;
  @Input() enquiryFlight: IEnquiryFlight | null = null;
  @Input() airportField: TAirportField;
  @Output() goPrev: EventEmitter<void> = new EventEmitter();
  @Output() saveAndGoNext: EventEmitter<IEnquiryFlight> = new EventEmitter();

  @ViewChild('modalFbo', { static: false }) modalFboElement: ElementRef;
  @ViewChild('modalHandling', { static: false }) modalHandlingElement: ElementRef;

  EnumEnquiryType = EnumEnquiryType;
  EnumAcl = EnumAcl;
  EnumEnquiryFlightAgentType = EnumEnquiryFlightAgentType;

  capitalize = capitalize;
  getEnquiryFlightAgentTypeLabel = getEnquiryFlightAgentTypeLabel;
  getUserFullname = getUserFullname;

  form: FormGroup = this.resetForm();

  airportsObj: { [id: string]: IAirport | null } = {};
  fbosObj: { [airportId: string]: IFbo[] } = {};
  clientsObj: { [id: string]: IPipedriveOrganization | null } = {};
  handlingsObj: { [airportId: string]: IHandling[] } = {};
  supervisors: IAirlineSupervisor[] = [];
  loadingFbosForAirport: { [airportId: string]: boolean } = {};
  loadingHandlingsForAirport: { [airportId: string]: boolean } = {};
  users: IUser[] = [];
  loadingUsers: boolean = false;
  editingFbo: IFbo | null = null;
  editingHandling: IHandling | null = null;
  aircraftModelsObj: { [id: string]: IAircraftModel | null } = {};

  faExternalLink = faExternalLink;
  faPlusCircle = faPlusCircle;
  faEdit = faEdit;
  faArrowLeft = faArrowLeft;
  faArrowRight = faArrowRight;
  faPlaneDeparture = faPlaneDeparture;
  faPlaneArrival = faPlaneArrival;

  private subscriptions = new Subscription();

  constructor(
    private airportService: AirportService,
    private aircraftModelService: AircraftModelService,
    private fboService: FboService,
    private handlingService: HandlingService,
    private aclService: AclService,
    private userService: UserService,
    private pipedriveService: PipedriveService
  ) {}

  ngOnInit(): void {
    this.updateFieldValidations();

    this.applyEnquiryFlight();
    this.updatedEnquiry();
    this.loadUsers();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateFieldValidations();

    if (
      changes['enquiryFlight'] &&
      (!changes['enquiryFlight'].previousValue ||
        changes['enquiryFlight'].previousValue !== changes['enquiryFlight'].currentValue)
    ) {
      this.applyEnquiryFlight();
    }

    if (
      changes['enquiry'] &&
      (!changes['enquiry'].previousValue ||
        changes['enquiry'].previousValue !== changes['enquiry'].currentValue)
    ) {
      this.updatedEnquiry();
    }
  }

  updatedEnquiry(): void {
    if (this.enquiry?.clientId) {
      this.loadClient(this.enquiry.clientId.toString());
    }

    this.updateFieldValidations();
  }

  async loadClient(clientId: string): Promise<void> {
    if (typeof this.clientsObj[clientId] === 'undefined') {
      this.clientsObj[clientId] = null;

      this.clientsObj[clientId] = await this.pipedriveService.getOrganization(clientId);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();

    window['$']('.tooltip').remove();

    this.removeModal();
  }

  removeModal(): void {
    if (this.modalFboElement) {
      window['$'](this.modalFboElement.nativeElement).modal('hide');
    }
    if (this.modalHandlingElement) {
      window['$'](this.modalHandlingElement.nativeElement).modal('hide');
    }
    window['$']('body').removeClass('modal-open');
    window['$']('.modal-backdrop').remove();
  }

  updateTooltip(): void {
    if (window['$']('[rel="tooltip"]').length) {
      window['$']('[rel="tooltip"]').tooltip({
        html: true,
        boundary: 'window',
        trigger: 'hover'
      });
    }
  }

  get airportId(): FormControl {
    return this.form.get('airport' + capitalize(this.airportField) + 'Id') as FormControl;
  }

  get airportTitle(): FormControl {
    return this.form.get('airport' + capitalize(this.airportField) + 'Title') as FormControl;
  }

  get airportIataCode(): FormControl {
    return this.form.get('airport' + capitalize(this.airportField) + 'IataCode') as FormControl;
  }

  get timezoneField(): FormControl {
    return this.form.get(this.airportField + 'Timezone') as FormControl;
  }

  getDateTimeField(timeField: TTimeField): FormControl {
    return this.form.get(this.airportField + 'DateTime' + capitalize(timeField)) as FormControl;
  }

  getDateTimeFieldForAirport(airportField: TAirportField, timeField: TTimeField): FormControl {
    return this.form.get(airportField + 'DateTime' + capitalize(timeField)) as FormControl;
  }

  getDateField(timeField: TTimeField): FormControl {
    return this.form.get(this.airportField + 'Date' + capitalize(timeField)) as FormControl;
  }

  getTimeField(timeField: TTimeField): FormControl {
    return this.form.get(this.airportField + 'Time' + capitalize(timeField)) as FormControl;
  }

  get flyTimeInMin(): FormControl {
    return this.form.get('flyTimeInMin') as FormControl;
  }

  get flightNumber(): FormControl {
    return this.form.get('flightNumber') as FormControl;
  }

  get fboEnabled(): FormControl {
    return this.form.get(this.airportField + 'FboEnabled') as FormControl;
  }

  get fboId(): FormControl {
    return this.form.get(this.airportField + 'FboId') as FormControl;
  }

  get handlingEnabled(): FormControl {
    return this.form.get(this.airportField + 'HandlingEnabled') as FormControl;
  }

  get handlingId(): FormControl {
    return this.form.get(this.airportField + 'HandlingId') as FormControl;
  }

  get slotEnabled(): FormControl {
    return this.form.get(this.airportField + 'SlotEnabled') as FormControl;
  }

  get slotTime(): FormControl {
    return this.form.get(this.airportField + 'SlotTime') as FormControl;
  }

  get terminalEnabled(): FormControl {
    return this.form.get(this.airportField + 'TerminalEnabled') as FormControl;
  }

  get terminal(): FormControl {
    return this.form.get(this.airportField + 'Terminal') as FormControl;
  }

  get boardingMinutesBeforeDeparture(): FormControl {
    return this.form.get('boardingMinutesBeforeDeparture') as FormControl;
  }

  get deliveryCargoHoursBeforeDeparture(): FormControl {
    return this.form.get('deliveryCargoHoursBeforeDeparture') as FormControl;
  }

  get agentEnabled(): FormControl {
    return this.form.get(this.airportField + 'AgentEnabled') as FormControl;
  }
  get agentType(): FormControl {
    return this.form.get(this.airportField + 'AgentType') as FormControl;
  }
  get agentUserId(): FormControl {
    return this.form.get(this.airportField + 'AgentUserId') as FormControl;
  }
  get agentUserInitials(): FormControl {
    return this.form.get(this.airportField + 'AgentUserInitials') as FormControl;
  }
  get agentUserFullName(): FormControl {
    return this.form.get(this.airportField + 'AgentUserFullName') as FormControl;
  }
  get agentCompany(): FormControl {
    return this.form.get(this.airportField + 'AgentCompany') as FormControl;
  }
  get agentPhone(): FormControl {
    return this.form.get(this.airportField + 'AgentPhone') as FormControl;
  }
  get agentEmail(): FormControl {
    return this.form.get(this.airportField + 'AgentEmail') as FormControl;
  }

  get agentTypes(): EnumEnquiryFlightAgentType[] {
    return Object.values(EnumEnquiryFlightAgentType);
  }

  resetForm(): FormGroup {
    return new FormGroup({
      airportDepartId: new FormControl(null, Validators.required),
      airportDepartTitle: new FormControl(null, Validators.required),
      airportDepartIataCode: new FormControl(null, Validators.required),
      airportArrivalId: new FormControl(null, Validators.required),
      airportArrivalTitle: new FormControl(null, Validators.required),
      airportArrivalIataCode: new FormControl(null, Validators.required),
      departTimezone: new FormControl(null, Validators.required),
      departDateTimeLocal: new FormControl(null, [
        Validators.required,
        this.dateMinimum('2000-01-01')
      ]),
      departDateLocal: new FormControl(null, [Validators.required, this.dateMinimum('2000-01-01')]),
      departTimeLocal: new FormControl(null, Validators.required),
      departDateTimeUtc: new FormControl(null, [
        Validators.required,
        this.dateMinimum('2000-01-01')
      ]),
      departDateUtc: new FormControl(null, Validators.required),
      departTimeUtc: new FormControl(null, Validators.required),
      arrivalTimezone: new FormControl(null, Validators.required),
      arrivalDateTimeLocal: new FormControl(null, [
        Validators.required,
        this.dateMinimum('2000-01-01')
      ]),
      arrivalDateLocal: new FormControl(null, [
        Validators.required,
        this.dateMinimum('2000-01-01')
      ]),
      arrivalTimeLocal: new FormControl(null, Validators.required),
      arrivalDateTimeUtc: new FormControl(null, [
        Validators.required,
        this.dateMinimum('2000-01-01')
      ]),
      arrivalDateUtc: new FormControl(null, Validators.required),
      arrivalTimeUtc: new FormControl(null, Validators.required),
      flyTimeInMin: new FormControl(null),
      departFboEnabled: new FormControl(false),
      departFboId: new FormControl(null),
      arrivalFboEnabled: new FormControl(false),
      arrivalFboId: new FormControl(null),
      departHandlingEnabled: new FormControl(false),
      departHandlingId: new FormControl(null),
      arrivalHandlingEnabled: new FormControl(false),
      arrivalHandlingId: new FormControl(null),
      departSlotEnabled: new FormControl(false),
      departSlotTime: new FormControl(null),
      arrivalSlotEnabled: new FormControl(false),
      arrivalSlotTime: new FormControl(null),
      departTerminalEnabled: new FormControl(false),
      departTerminal: new FormControl(null),
      arrivalTerminalEnabled: new FormControl(false),
      arrivalTerminal: new FormControl(null),
      departAgentEnabled: new FormControl(false),
      departAgentType: new FormControl(null),
      departAgentUserId: new FormControl(null),
      departAgentUserInitials: new FormControl(null),
      departAgentUserFullName: new FormControl(null),
      departAgentCompany: new FormControl(null),
      departAgentPhone: new FormControl(null),
      departAgentEmail: new FormControl(null),
      arrivalAgentEnabled: new FormControl(false),
      arrivalAgentType: new FormControl(null),
      arrivalAgentUserId: new FormControl(null),
      arrivalAgentUserInitials: new FormControl(null),
      arrivalAgentUserFullName: new FormControl(null),
      arrivalAgentCompany: new FormControl(null),
      arrivalAgentPhone: new FormControl(null),
      arrivalAgentEmail: new FormControl(null),
      boardingMinutesBeforeDeparture: new FormControl(20),
      deliveryCargoHoursBeforeDeparture: new FormControl(2),
      isAircraftGroundedOnArrival: new FormControl(false)
    });
  }

  dateMinimum(date: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      if (differenceInDays(new Date(control.value), new Date(date)) < 0) {
        return {
          dateBelowMinimum: true,
          dateMinimum: new Date(date)
        };
      } else {
        return null;
      }
    };
  }

  async setValueToFormControl($event: {
    fields: {
      name: string;
      value: any;
    }[];
  }): Promise<void> {
    for (let field of $event.fields) {
      const nameList = field.name.split('.');

      let formControl: AbstractControl = this.form;
      for (let name of nameList) {
        formControl = formControl.get(name);
      }

      formControl.setValue(field.value);

      if (['airportDepartId', 'airportArrivalId'].includes(field.name) && field.value) {
        this.loadAirport(field.value);
      }

      formControl.markAsTouched();
      formControl.updateValueAndValidity();
    }
  }

  loadAirport(airportId: string): void {
    if (airportId && typeof this.airportsObj[airportId] === 'undefined') {
      this.airportsObj[airportId] = null;

      this.subscriptions.add(
        this.airportService.getFromId(airportId).subscribe((airport: IAirport | null) => {
          this.airportsObj[airportId] = airport;

          this.applyAirportIfSelected(airportId);
        })
      );
    } else {
      this.applyAirportIfSelected(airportId);
    }
  }

  applyAirportIfSelected(airportId: string): void {
    if (this.airportsObj[airportId]) {
      for (const field of ['airportDepartId', 'airportArrivalId']) {
        if (this.form.get(field).value === airportId) {
          const fieldWithoutId: string = field.replace('Id', '');

          this.form.get(fieldWithoutId + 'Title').setValue(this.airportsObj[airportId].title);
          this.form.get(fieldWithoutId + 'IataCode').setValue(this.airportsObj[airportId].iataCode);
          this.form
            .get(this.airportField + 'Timezone')
            .setValue(this.airportsObj[airportId].timezoneId);

          this.updatedDateTime('local');
        }
      }
    }
  }

  updatedDateTime(timeField: TTimeField): void {
    if (this.getDateTimeField(timeField).valid && this.getDateTimeField(timeField).value) {
      const dateSplitted: string[] = this.getDateTimeField(timeField).value.split('T');

      if (dateSplitted.length === 2) {
        this.getDateField(timeField).setValue(dateSplitted[0]);
        this.getTimeField(timeField).setValue(dateSplitted[1]);

        if (
          this.form.get('airport' + capitalize(this.airportField) + 'Id').value &&
          this.airportsObj[this.form.get('airport' + capitalize(this.airportField) + 'Id').value]
        ) {
          switch (timeField) {
            case 'local':
              const timeUtc: string | null = getAirportUTCFullTimeString(
                this.airportsObj[
                  this.form.get('airport' + capitalize(this.airportField) + 'Id').value
                ],
                dateSplitted[0],
                dateSplitted[1]
              );

              if (timeUtc) {
                const timeUtcSplitted: string[] = timeUtc.split(' ');

                this.getDateTimeField('utc').setValue(timeUtc);
                this.getDateField('utc').setValue(timeUtcSplitted[0]);
                this.getTimeField('utc').setValue(timeUtcSplitted[1]);
              }
              break;
            case 'utc':
              const timeLocal: string | null = getAirportLocalTimeFullFromUTC(
                this.airportsObj[
                  this.form.get('airport' + capitalize(this.airportField) + 'Id').value
                ],
                dateSplitted[0],
                dateSplitted[1]
              );

              if (timeLocal) {
                const timeLocalSplitted: string[] = timeLocal.split(' ');

                this.getDateTimeField('local').setValue(timeLocal);
                this.getDateField('local').setValue(timeLocalSplitted[0]);
                this.getTimeField('local').setValue(timeLocalSplitted[1]);
              }
              break;
          }
        }
      }

      this.refreshFlyTimeInMin();
    }
  }

  refreshFlyTimeInMin(): void {
    if (
      this.getDateTimeFieldForAirport('depart', 'utc').value &&
      this.getDateTimeFieldForAirport('arrival', 'utc').value
    ) {
      const flyTimeInMin: number = differenceInMinutes(
        new Date(this.getDateTimeFieldForAirport('arrival', 'utc').value + ':00Z'),
        new Date(this.getDateTimeFieldForAirport('depart', 'utc').value + ':00Z')
      );

      this.flyTimeInMin.setValue(flyTimeInMin);
      this.flyTimeInMin.updateValueAndValidity();
    }
  }

  updatedFboEnabled(): void {
    if (this.form.get('airport' + capitalize(this.airportField) + 'Id').value) {
      this.loadFbosForAirport(
        this.form.get('airport' + capitalize(this.airportField) + 'Id').value
      );
    }

    if (this.fboEnabled.value) {
      this.fboId.addValidators(Validators.required);
    } else {
      this.fboId.clearValidators();
    }

    this.fboId.updateValueAndValidity();
  }

  updatedHandlingEnabled(): void {
    if (this.form.get('airport' + capitalize(this.airportField) + 'Id').value) {
      this.loadHandlingsForAirport(
        this.form.get('airport' + capitalize(this.airportField) + 'Id').value
      );
    }

    if (this.handlingEnabled.value) {
      this.handlingId.addValidators(Validators.required);
    } else {
      this.handlingId.clearValidators();
    }

    this.handlingId.updateValueAndValidity();
  }

  updatedSlotEnabled(): void {
    if (this.slotEnabled.value) {
      this.slotTime.addValidators(Validators.required);
    } else {
      this.slotTime.clearValidators();
    }

    this.slotTime.updateValueAndValidity();
  }

  updatedTerminalEnabled(): void {
    if (this.terminalEnabled.value) {
      this.terminal.addValidators(Validators.required);
    } else {
      this.terminal.clearValidators();
    }

    this.terminal.updateValueAndValidity();
  }

  updateIsFerryFlight(): void {}

  updatedAgentEnabled(): void {
    if (this.agentEnabled.value) {
      this.agentType.addValidators(Validators.required);
    } else {
      this.agentType.clearValidators();
      this.agentType.setValue(null);
    }
    this.agentType.updateValueAndValidity();
  }

  updatedAgentType(forceResetExternal: boolean = true): void {
    if (this.agentType.value) {
      switch (this.agentType.value) {
        case EnumEnquiryFlightAgentType.internal:
          this.agentUserId.addValidators(Validators.required);

          this.updatedAgentUserId();
          break;
        case EnumEnquiryFlightAgentType.external:
          if (forceResetExternal) {
            for (const field of [
              'UserId',
              'UserInitials',
              'UserFullName',
              'Company',
              'Phone',
              'Email'
            ]) {
              this.form.get(this.airportField + 'Agent' + field).clearValidators();
              this.form.get(this.airportField + 'Agent' + field).setValue(null);
            }
          }
          break;
      }
    } else {
      for (const field of ['UserId', 'UserInitials', 'UserFullName', 'Company', 'Phone', 'Email']) {
        this.form.get(this.airportField + 'Agent' + field).clearValidators();
        this.form.get(this.airportField + 'Agent' + field).setValue(null);
      }
    }
  }

  updatedAgentUserId(): void {
    if (this.agentUserId.value) {
      for (const user of this.users) {
        if (user.id === this.agentUserId.value) {
          this.agentUserInitials.setValue(user.initials);
          this.agentUserFullName.setValue(getUserFullname(user));
          this.agentCompany.setValue('Artheau Aviation');
          this.agentPhone.setValue(user.phone);
          this.agentEmail.setValue(user.email);
          break;
        }
      }
    } else {
      for (const field of ['UserInitials', 'UserFullName', 'Company', 'Phone', 'Email']) {
        this.form.get(this.airportField + 'Agent' + field).clearValidators();
      }
    }
  }

  loadFbosForAirport(airportId: string): void {
    if (typeof this.fbosObj[airportId] === 'undefined') {
      this.loadingFbosForAirport[airportId] = true;

      this.fbosObj[airportId] = [];

      this.subscriptions.add(
        this.fboService.getForAirport(airportId).subscribe((fbos: IFbo[]) => {
          this.fbosObj[airportId] = fbos;

          this.fbosObj[airportId].sort((a, b) =>
            a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ? -1 : 1
          );

          this.loadingFbosForAirport[airportId] = false;
        })
      );
    }
  }

  loadHandlingsForAirport(airportId: string): void {
    if (typeof this.handlingsObj[airportId] === 'undefined') {
      this.loadingHandlingsForAirport[airportId] = true;

      this.handlingsObj[airportId] = [];

      this.subscriptions.add(
        this.handlingService.getForAirport(airportId).subscribe((fbos: IFbo[]) => {
          this.handlingsObj[airportId] = fbos;

          this.handlingsObj[airportId].sort((a, b) =>
            a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ? -1 : 1
          );

          this.loadingHandlingsForAirport[airportId] = false;
        })
      );
    }
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  addFbo(): void {
    window['$'](this.modalFboElement.nativeElement).modal('show');
  }

  editFbo(): void {
    if (
      this.fboId.value &&
      this.fbosObj[this.form.get('airport' + capitalize(this.airportField) + 'Id').value]
    ) {
      for (const fbo of this.fbosObj[
        this.form.get('airport' + capitalize(this.airportField) + 'Id').value
      ]) {
        if (fbo.id === this.fboId.value) {
          this.editingFbo = fbo;
          break;
        }
      }
    }

    window['$'](this.modalFboElement.nativeElement).modal('show');
  }

  dismissFboModal(newId: string | null = null): void {
    if (this.airportField && newId) {
      this.fboId.setValue(newId);
      this.fboId.updateValueAndValidity();
    }

    window['$'](this.modalFboElement.nativeElement).modal('hide');
  }

  addHandling(): void {
    window['$'](this.modalHandlingElement.nativeElement).modal('show');
  }

  editHandling(): void {
    if (
      this.handlingId.value &&
      this.handlingsObj[this.form.get('airport' + capitalize(this.airportField) + 'Id').value]
    ) {
      for (const handling of this.handlingsObj[
        this.form.get('airport' + capitalize(this.airportField) + 'Id').value
      ]) {
        if (handling.id === this.handlingId.value) {
          this.editingHandling = handling;
          break;
        }
      }
    }

    window['$'](this.modalHandlingElement.nativeElement).modal('show');
  }

  dismissHandlingModal(newId: string | null = null): void {
    if (this.airportField && newId) {
      this.handlingId.setValue(newId);
      this.handlingId.updateValueAndValidity();
    }

    window['$'](this.modalHandlingElement.nativeElement).modal('hide');
  }

  applyEnquiryFlight(): void {
    this.form = this.resetForm();

    this.updateFieldValidations();

    this.updateTooltip();

    if (this.enquiryFlight) {
      for (const field in this.form.value) {
        if (typeof this.enquiryFlight[field] !== 'undefined') {
          switch (field) {
            default:
              this.form.get(field).setValue(this.enquiryFlight[field]);
              break;
          }
        }
      }

      for (const timeField of ['local', 'utc']) {
        if (
          this.getDateField(timeField as TTimeField).value &&
          this.getTimeField(timeField as TTimeField).value
        ) {
          this.getDateTimeField(timeField as TTimeField).setValue(
            this.getDateField(timeField as TTimeField).value +
              'T' +
              this.getTimeField(timeField as TTimeField).value
          );

          this.getDateTimeField(timeField as TTimeField).updateValueAndValidity();
        }
      }

      for (const airportField of ['depart', 'arrival']) {
        this.loadAirport(this.form.get('airport' + capitalize(airportField) + 'Id').value);
      }

      this.updatedFboEnabled();
      this.updatedHandlingEnabled();

      if (this.form.get('airport' + capitalize(this.airportField) + 'Id').value) {
        this.loadFbosForAirport(
          this.form.get('airport' + capitalize(this.airportField) + 'Id').value
        );
      }

      this.updatedSlotEnabled();
      this.updatedTerminalEnabled();
      this.updatedAgentEnabled();
      this.updatedAgentType(false);

      this.updateIsFerryFlight();

      this.form.enable();
    }
  }

  updateFieldValidations(): void {
    let mandatoryFields: string[] = [];
    let hiddenFields: string[] = [];
    let dateFields: string[] = [];

    if (this.enquiry) {
      switch (this.enquiry.type) {
        default:
          mandatoryFields.push('boardingMinutesBeforeDeparture');
          hiddenFields.push('deliveryCargoHoursBeforeDeparture');
          break;
        case EnumEnquiryType.cargo:
          mandatoryFields.push('deliveryCargoHoursBeforeDeparture');
          hiddenFields.push('boardingMinutesBeforeDeparture');
          break;
      }
    }

    if (this.airportField) {
      for (const field of [
        'airport' + capitalize(this.airportField) + 'Id',
        'airport' + capitalize(this.airportField) + 'Title',
        'airport' + capitalize(this.airportField) + 'IataCode',
        this.airportField + 'Timezone',
        this.airportField + 'DateTimeLocal',
        this.airportField + 'DateLocal',
        this.airportField + 'TimeLocal',
        this.airportField + 'DateTimeUtc',
        this.airportField + 'DateUtc',
        this.airportField + 'TimeUtc'
      ]) {
        mandatoryFields.push(field);

        if (field.match('Date')) {
          dateFields.push(field);
        }
      }

      const invertedField: TAirportField = this.airportField === 'depart' ? 'arrival' : 'depart';

      for (const field of [
        'airport' + capitalize(invertedField) + 'Id',
        'airport' + capitalize(invertedField) + 'Title',
        'airport' + capitalize(invertedField) + 'IataCode',
        invertedField + 'Timezone',
        invertedField + 'DateTimeLocal',
        invertedField + 'DateLocal',
        invertedField + 'TimeLocal',
        invertedField + 'DateTimeUtc',
        invertedField + 'DateUtc',
        invertedField + 'TimeUtc'
      ]) {
        hiddenFields.push(field);
      }
    }

    for (const field of mandatoryFields) {
      if (dateFields.includes(field)) {
        this.form.get(field).setValidators([Validators.required, this.dateMinimum('2000-01-01')]);
      } else {
        this.form.get(field).setValidators(Validators.required);
      }
      this.form.get(field).updateValueAndValidity();
    }

    for (const field of hiddenFields) {
      this.form.get(field).clearValidators();
      this.form.get(field).updateValueAndValidity();
    }
  }

  loadUsers(): void {
    this.loadingUsers = true;

    this.subscriptions.add(
      this.userService.getAll().subscribe((users: IUser[]) => {
        this.users = users;

        this.users.sort((a, b) => (getUserFullname(a) < getUserFullname(b) ? -1 : 1));

        this.loadingUsers = false;
      })
    );
  }

  prev(): void {
    this.goPrev.emit();
  }

  saveAndNext(): void {
    if (this.form.valid) {
      this.saveAndGoNext.emit(this.form.value);
    }
  }

  getEstimatedArrivalTime(): { local: string; utc: string } | null {
    if (
      this.enquiryFlight &&
      this.enquiryFlight.aircraftModelId &&
      this.form.value.airportDepartId &&
      this.form.value.airportArrivalId
    ) {
      this.loadAircraftModel(this.enquiryFlight.aircraftModelId);

      if (
        this.aircraftModelsObj[this.enquiryFlight.aircraftModelId] &&
        this.airportsObj[this.form.value.airportDepartId] &&
        this.airportsObj[this.form.value.airportArrivalId]
      ) {
        // Set estimated arrival time
        const flyTimeInMinutes: number = estimatedFlyTimeInMinutes(
          this.aircraftModelsObj[this.enquiryFlight.aircraftModelId],
          this.airportsObj[this.form.value.airportDepartId],
          this.airportsObj[this.form.value.airportArrivalId]
        );

        if (flyTimeInMinutes) {
          const arrivalTimeUtc: Date = addMinutes(
            new Date(
              this.form.value.departDateTimeUtc[this.form.value.departDateTimeUtc.length - 1] ===
              'T'
                ? this.form.value.departDateUtc
                : this.form.value.departDateTimeUtc
            ),
            flyTimeInMinutes
          );

          return {
            local: getAirportLocalDateTimeFromUTC(
              this.airportsObj[this.form.value.airportDepartId],
              arrivalTimeUtc
            ),
            utc: formatInTimeZone(arrivalTimeUtc, 'utc', 'yyyy-MM-dd HH:mm')
          };
        }
      }
    }

    return null;
  }

  getEstimatedArrivalTimeForInfo(): string | null {
    const dateTimes: { local: string; utc: string } | null = this.getEstimatedArrivalTime();

    if (dateTimes) {
      const splittedDateTimes: { local: string[]; utc: string[] } = {
        local: dateTimes.local.split(' '),
        utc: dateTimes.utc.split(' ')
      };

      return (
        splittedDateTimes.local[splittedDateTimes.local.length - 1] +
        ' <strong>LT</strong> ' +
        splittedDateTimes.utc[splittedDateTimes.utc.length - 1] +
        ' <strong>UTC</strong>'
      );
    }

    return null;
  }

  loadAircraftModel(aircraftModelId: string): void {
    if (aircraftModelId && typeof this.aircraftModelsObj[aircraftModelId] === 'undefined') {
      this.aircraftModelsObj[aircraftModelId] = null;

      this.subscriptions.add(
        this.aircraftModelService
          .getFromId(aircraftModelId)
          .subscribe((aircraftModel: IAircraftModel | null) => {
            this.aircraftModelsObj[aircraftModelId] = aircraftModel;
          })
      );
    }
  }

  applyEstimatedArrivalTime(): void {
    const dateTimes: { local: string; utc: string } | null = this.getEstimatedArrivalTime();

    if (dateTimes) {
      const splittedDateTimes: { local: string[]; utc: string[] } = {
        local: dateTimes.local.split(' '),
        utc: dateTimes.utc.split(' ')
      };

      this.getDateTimeField('utc').setValue(dateTimes.utc);
      this.getDateField('utc').setValue(splittedDateTimes.utc[0]);
      this.getTimeField('utc').setValue(splittedDateTimes.utc[1]);

      this.getDateTimeField('local').setValue(dateTimes.local);
      this.getDateField('local').setValue(splittedDateTimes.local[0]);
      this.getTimeField('local').setValue(splittedDateTimes.local[1]);

      this.form.updateValueAndValidity();
    }
  }
}
