import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import html2pdf from 'html2pdf.js';

import { PaginationService } from '../../../services/pagination.service';
import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';
import { getEnquiryBreadcrumbTitle, IEnquiry } from 'src/app/interfaces/enquiry.interface';
import { Subscription } from 'rxjs';
import { EnquiryService } from 'src/app/services/enquiry/enquiry.service';
import { EnumAcl } from 'src/app/enums/acl.enum';
import {
  getPassengerLabel,
  getTimeBoardingBeforeDeparture,
  IFlightBrief,
  IFlightBriefAirportsAddressDetails,
  IFlightBriefTrip
} from 'src/app/interfaces/flight-brief.interface';
import { FlightBriefService } from 'src/app/services/flight-briefs/flight-briefs.service';
import {
  faCalendar,
  faEdit,
  faHourglass,
  faPlane,
  faPlaneArrival,
  faPlaneDeparture,
  faSuitcaseRolling,
  faUsers,
  faUtensils
} from '@fortawesome/free-solid-svg-icons';
import { AirportService } from 'src/app/services/airports/airports.service';
import {
  getAirportCity,
  getAirportUTCTimeString,
  IAirport
} from 'src/app/interfaces/airport.interface';
import { format } from 'date-fns';
import { fr, enUS } from 'date-fns/locale';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user/user.service';
import { getUserFullname, IUser } from 'src/app/interfaces/user.interface';
import { EnumLanguage } from 'src/app/enums/language.enum';
import { IPipedriveOrganization } from 'src/app/interfaces/pipedrive.interface';
import { EnumEnquiryType } from 'src/app/enums/enquiry-type.enum';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs/breadcrumbs.service';

@Component({
  selector: 'app-flight-brief-view',
  templateUrl: './flight-brief-view.component.html',
  styleUrls: ['./flight-brief-view.component.scss']
})
export class FlightBriefViewComponent implements OnInit, OnDestroy {
  @ViewChild('modalMarkAsSent', { static: false }) modalMarkAsSentElement: ElementRef;

  getEnquiryBreadcrumbTitle = getEnquiryBreadcrumbTitle;
  getAirportCity = getAirportCity;
  getTimeBoardingBeforeDeparture = getTimeBoardingBeforeDeparture;
  getPassengerLabel = getPassengerLabel;
  getUserFullname = getUserFullname;
  getAirportUTCTimeString = getAirportUTCTimeString;

  EnumAcl = EnumAcl;
  EnumLanguage = EnumLanguage;

  faPlaneDeparture = faPlaneDeparture;
  faPlaneArrival = faPlaneArrival;
  faCalendar = faCalendar;
  faPlane = faPlane;
  faHourglass = faHourglass;
  faUsers = faUsers;
  faUtensils = faUtensils;
  faSuitcaseRolling = faSuitcaseRolling;
  faEdit = faEdit;

  loading: boolean = false;
  flightBriefId: string;
  flightBrief: IFlightBrief;
  isLogged: boolean = false;
  enquiry: IEnquiry;
  client: IPipedriveOrganization | null = null;
  airportsObj: { [key: string]: IAirport } = {};
  subscriptions = new Subscription();
  subscriptionsTranslations = new Subscription();
  generatingPdf: boolean = false;

  imgsToBase64: {
    googleMapStaticUrls: { [key: string]: string };
    imageOutsideUrl: string | null;
    imageInsideUrl: string | null;
  } = {
    googleMapStaticUrls: {},
    imageOutsideUrl: null,
    imageInsideUrl: null
  };

  flightBriefsTripIndexDisplayed: string[] = [];

  formMarkAsSent: FormGroup = new FormGroup({
    isSent: new FormControl(false, Validators.required),
    sentDate: new FormControl(null),
    sentBy: new FormControl(null)
  });

  currentUser: IUser | null = null;
  users: IUser[] = [];

  savingMarkAsSent: boolean = false;

  isRoundTrip: boolean = false;

  constructor(
    private remoteService: RemoteService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public paginationService: PaginationService,
    private aclService: AclService,
    private enquiryService: EnquiryService,
    private flightBriefService: FlightBriefService,
    private airportService: AirportService,
    private userService: UserService,
    private breadcrumbsService: BreadcrumbsService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
    this.remoteService.userObservable.subscribe((user: IUser) => {
      this.currentUser = user;
    });
  }

  ngOnInit(): void {
    this.activatedRoute.url.subscribe(() => {
      this.flightBriefId = this.activatedRoute.snapshot.paramMap.get('flightBriefId');

      this.loadData();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.subscriptionsTranslations.unsubscribe();

    this.removeModal();
  }

  loadData(): void {
    if (this.isLogged) {
      this.loadFlightBrief();
      this.loadUsers();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  async loadFlightBrief(): Promise<void> {
    this.loading = true;

    this.subscriptions.add(
      this.flightBriefService
        .getFromId(this.flightBriefId)
        .subscribe(async (flightBrief: IFlightBrief) => {
          if (flightBrief) {
            this.flightBrief = flightBrief;

            this.breadcrumbsService.setCurrentItem({
              id: this.flightBrief.id,
              text: this.flightBrief.title
            });

            this.checkIfRoundTrip();

            this.setMarkAsSentForm();

            this.selectAllTrips();

            this.loadEnquiry();

            for (const trip of this.flightBrief.trips) {
              for (const field of ['airportDepartId', 'airportArrivalId']) {
                if (trip[field]) {
                  this.loadAirport(trip[field]);
                }
              }
            }
          }

          this.loading = false;
        })
    );
  }

  checkIfRoundTrip(): void {
    this.isRoundTrip = false;

    if (this.flightBrief) {
      this.isRoundTrip =
        this.flightBrief.trips.length === 2 &&
        this.flightBrief.trips[0].airportArrivalId === this.flightBrief.trips[1].airportDepartId &&
        this.flightBrief.trips[1].airportArrivalId === this.flightBrief.trips[0].airportDepartId;

      if (this.isRoundTrip) {
        // Check if passengers are different
        const passengersLabelByTrip: string[][] = [];
        for (const trip of this.flightBrief.trips) {
          const passengersLabel: string[] = [];

          for (const passenger of trip.passengers) {
            passengersLabel.push(getPassengerLabel(passenger));
          }

          passengersLabelByTrip.push(passengersLabel);
        }

        for (let i = 0; i < passengersLabelByTrip.length; i++) {
          passengersLabelByTrip[i].sort((a, b) =>
            a.toLocaleLowerCase() < b.toLocaleLowerCase() ? -1 : 1
          );
        }

        // A round trip has only 2 trips
        if (passengersLabelByTrip.length === 2) {
          if (passengersLabelByTrip[0].length !== passengersLabelByTrip[1].length) {
            this.isRoundTrip = false;
          } else {
            for (let i = 0; i < passengersLabelByTrip[0].length; i++) {
              if (passengersLabelByTrip[0][i] !== passengersLabelByTrip[1][i]) {
                this.isRoundTrip = false;

                break;
              }
            }
          }
        }
      }
    }
  }

  setMarkAsSentForm(): void {
    for (const field of ['isSent', 'sentDate', 'sentBy']) {
      this.formMarkAsSent.get(field).setValue(this.flightBrief[field]);
    }

    this.updatedIsSent();

    this.formMarkAsSent.updateValueAndValidity();
  }

  selectAllTrips(): void {
    if (this.flightBrief) {
      this.flightBriefsTripIndexDisplayed = [];

      for (const trip of this.flightBrief.trips) {
        this.flightBriefsTripIndexDisplayed.push(trip.id);
      }
    }
  }

  toggleTrip(tripId: string): void {
    if (this.flightBrief) {
      const index: number = this.flightBriefsTripIndexDisplayed.indexOf(tripId);

      if (index === -1) {
        this.flightBriefsTripIndexDisplayed.push(tripId);
      } else {
        this.flightBriefsTripIndexDisplayed.splice(index, 1);
      }
    }
  }

  getDisplayedTrips(): IFlightBriefTrip[] {
    const trips: IFlightBriefTrip[] = [];

    for (const trip of this.flightBrief?.trips) {
      if (this.flightBriefsTripIndexDisplayed.includes(trip.id)) {
        trips.push(trip);
      }
    }

    return trips;
  }

  getDisplayedAirportsAddressDetails(trip: IFlightBriefTrip): IFlightBriefAirportsAddressDetails[] {
    const airportsId: string[] = [];
    const airportsAddressDetails: IFlightBriefAirportsAddressDetails[] = [];

    for (const field of ['airportDepartId', 'airportArrivalId']) {
      if (trip[field] && !airportsId.includes(trip[field])) {
        airportsId.push(trip[field]);
      }
    }

    for (const airportAddressDetails of this.flightBrief?.airportsAddressDetails) {
      if (airportsId.includes(airportAddressDetails.airportId)) {
        airportsAddressDetails.push(airportAddressDetails);
      }
    }

    return airportsAddressDetails;
  }

  loadEnquiry(): void {
    if (this.flightBrief?.enquiryId) {
      this.subscriptions.add(
        this.enquiryService.getFromId(this.flightBrief.enquiryId).subscribe((enquiry: IEnquiry) => {
          this.enquiry = enquiry;

          if (this.enquiry) {
            this.breadcrumbsService.setCurrentItem({
              id: this.enquiry.id,
              text: getEnquiryBreadcrumbTitle(this.enquiry)
            });
          }
        })
      );
    }
  }

  async delete(): Promise<void> {
    if (this.hasAclAccess(EnumAcl.flightBriefsDelete)) {
      const result = confirm(
        'La suppression du flight brief sera permanente. Êtes-vous sûr de vouloir continuer ?'
      );

      if (result) {
        try {
          await this.flightBriefService.delete(this.flightBriefId);

          if (this.flightBrief.enquiryId) {
            this.router.navigate(['/admin/flight-briefs/enquiry/' + this.flightBrief.enquiryId]);
          } else {
            this.router.navigate(['/admin/flight-briefs']);
          }

          alert('La suppression a été effectuée avec succès.');
        } catch (err) {
          console.log(err);
        }
      }
    }
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  loadAirport(airportId: string): void {
    if (!this.airportsObj[airportId]) {
      this.subscriptions.add(
        this.airportService.getFromId(airportId).subscribe((airport: IAirport) => {
          this.airportsObj[airportId] = airport;
        })
      );
    }
  }

  formatTripDate(dateStr: string): string {
    dateStr = format(new Date(dateStr), 'EEEE dd MMMM yyyy', {
      locale: this.flightBrief?.language === EnumLanguage.fr ? fr : enUS
    });

    const dateSplitted: string[] = dateStr.split(' ');

    for (let i in dateSplitted) {
      dateSplitted[i] = this.capitalizeFirstLetter(dateSplitted[i]);
    }

    return dateSplitted.join(' ');
  }

  async loadAllImgBase64(): Promise<void> {
    try {
      for (const imgFieldName in this.imgsToBase64) {
        if (imgFieldName === 'googleMapStaticUrls') {
          this.imgsToBase64.googleMapStaticUrls = {};
          for (const trip of this.flightBrief.trips) {
            if (trip.googleMapStaticUrl) {
              this.imgsToBase64.googleMapStaticUrls[trip.id] =
                await this.loadImgBase64FromRemoteUrl(trip.googleMapStaticUrl);
            }
          }
        } else {
          if (this.flightBrief[imgFieldName]) {
            this.imgsToBase64[imgFieldName] = await this.loadImgBase64FromRemoteUrl(
              this.flightBrief[imgFieldName]
            );
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  loadImgBase64FromRemoteUrl(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      var xhr = new XMLHttpRequest();

      xhr.responseType = 'arraybuffer';
      xhr.open('GET', url);

      xhr.onload = () => {
        var base64, binary, bytes, mediaType;

        bytes = new Uint8Array(xhr.response);
        //NOTE String.fromCharCode.apply(String, ...
        //may cause "Maximum call stack size exceeded"
        binary = [].map
          .call(bytes, byte => {
            return String.fromCharCode(byte);
          })
          .join('');
        mediaType = xhr.getResponseHeader('content-type');
        base64 = ['data:', mediaType ? mediaType + ';' : '', 'base64,', btoa(binary)].join('');
        resolve(base64);
      };
      xhr.onerror = err => {
        reject(err);
      };
      xhr.send();
    });
  }

  generatePdf(): void {
    this.generatingPdf = true;

    setTimeout(() => {
      let filenameArr: string[] = ['FLIGHT BRIEF'];

      for (const trip of this.flightBrief.trips) {
        if (this.flightBriefsTripIndexDisplayed.includes(trip.id)) {
          filenameArr.push(
            this.airportsObj[trip.airportDepartId]?.title +
              '/' +
              this.airportsObj[trip.airportArrivalId]?.title
          );

          filenameArr.push(
            format(new Date(trip.date), 'dd MMMM', {
              locale: this.flightBrief?.language === EnumLanguage.fr ? fr : enUS
            })
          );
        }
      }

      filenameArr.push(this.slugify(this.flightBrief.aircraftModelTitle));

      const element = document.getElementById('pdf-content');

      html2pdf()
        .set({
          margin: [5, 0, 15, 0],
          pagebreak: { after: '.page-break' },
          html2canvas: {
            dpi: 192,
            scale: navigator.userAgent.match('iPhone') ? 2 : 3,
            letterRendering: true
          },
          jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
        })
        .from(element)
        .toPdf()
        .get('pdf', pdf => {
          var totalPages = pdf.internal.getNumberOfPages();

          pdf.setFontSize(6);

          const footerHeight: number = 15;
          const footerTop: number = pdf.internal.pageSize.getHeight() - footerHeight;
          const footerRight: number = pdf.internal.pageSize.getWidth() - 25;

          let headerContact: string = [
            'Artheau Aviation',
            '16, Boulevard Saint-Germain – 75237 Paris Cedex',
            'executive@flyaa.eu | +33(0) 1 82 28 52 80'
          ].join('\n');

          if (this.enquiry?.type === EnumEnquiryType.cargo) {
            headerContact = [
              'Artheau Aviation',
              '16, Boulevard Saint-Germain – 75237 Paris Cedex',
              'cargo@flyaa.eu | +33(0) 6 21 55 96 71'
            ].join('\n');
          }

          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);

            pdf.text(pdf.internal.pageSize.getWidth() / 2, footerTop + 5, headerContact, {
              align: 'center'
            });
          }
        })
        .save(filenameArr.join(' - ').toLocaleUpperCase() + '.pdf');

      setTimeout(() => {
        this.generatingPdf = false;
      }, 1000);
    }, 1000);
  }

  private slugify(...args: (string | number)[]): string {
    const value = args.join(' ');

    return value
      .normalize('NFD') // split an accented letter in the base letter and the acent
      .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
      .toLowerCase()
      .trim()
      .replace(/[^a-z0-9 +]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
      .replace(/\s+/g, '-'); // separator
  }

  async saveMarkAsSent(): Promise<void> {
    this.formMarkAsSent.markAsTouched();

    if (this.formMarkAsSent.status == 'VALID' && this.flightBriefId) {
      this.savingMarkAsSent = true;

      this.formMarkAsSent.disable();

      try {
        const data: IFlightBrief = {
          id: this.flightBriefId,
          isSent: this.formMarkAsSent.value.isSent,
          sentBy: this.formMarkAsSent.value.sentBy,
          sentDate: this.formMarkAsSent.value.sentDate,
          sentByFirstname: null,
          sentByLastname: null,
          sentByInitials: null,
          sentByFullname: null
        } as IFlightBrief;

        if (data.isSent) {
          for (const user of this.users) {
            if (user.id === data.sentBy) {
              data.sentByFirstname = user.firstname;
              data.sentByLastname = user.lastname;
              data.sentByInitials = user.initials;
              data.sentByFullname = getUserFullname(user);
              break;
            }
          }
        }

        this.flightBriefId = await this.flightBriefService.update(data);

        this.savingMarkAsSent = false;
        this.formMarkAsSent.enable();

        window['$'](this.modalMarkAsSentElement.nativeElement).modal('hide');
      } catch (err) {
        console.log(err);

        this.savingMarkAsSent = false;
        this.formMarkAsSent.enable();

        alert(err.message);
      }
    }
  }

  updatedIsSent(): void {
    for (const field of ['sentDate', 'sentBy']) {
      if (this.formMarkAsSent.value.isSent) {
        this.formMarkAsSent.get(field).setValidators(Validators.required);

        if (!this.formMarkAsSent.value[field]) {
          switch (field) {
            case 'sentDate':
              this.formMarkAsSent.get(field).setValue(format(new Date(), 'yyyy-MM-dd'));
              break;
            case 'sentBy':
              this.formMarkAsSent.get(field).setValue(this.currentUser?.id || null);
              break;
          }
        }
      } else {
        this.formMarkAsSent.get(field).clearValidators();
        this.formMarkAsSent.get(field).setValue(null);
      }

      this.formMarkAsSent.get(field).updateValueAndValidity();
    }
  }

  loadUsers(): void {
    this.loading = true;

    this.subscriptions.add(
      this.userService.getAll().subscribe((users: IUser[]) => {
        this.users = users;

        this.users.sort((a, b) => (getUserFullname(a) < getUserFullname(b) ? -1 : 1));

        this.loading = false;
      })
    );
  }

  removeModal(): void {
    window['$'](this.modalMarkAsSentElement.nativeElement).modal('hide');
    window['$']('body').removeClass('modal-open');
    window['$']('.modal-backdrop').remove();
  }

  private capitalizeFirstLetter(text: string): string {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
}
