import moment from 'moment';
import { IStoreData } from './store-data.interface';
import { EnumAircraftStatus } from '../enums/aircraft-status.enum';

export interface IAircraft extends IStoreData {
  url: string;
  airlineTitle: string | null;
  airlineId: string | null;
  airlineContinentCode: string | null;
  airlineContinentTitle: string | null;
  airlineCountryCode: string | null;
  airlineCountryTitle: string | null;
  status: EnumAircraftStatus;
  registration: string;
  serialNumber: string;
  ln: string;
  aircraftModelTitle: string;
  model: string;
  type: string;
  firstFlightDate: string | null;
  testRegistration: string;
  classes: EnumAircraftClass[];
  seatTotal: number;
  seatFirst: number;
  seatBusiness: number;
  seatPremiumEconomy: number;
  seatEconomy: number;
  engines: string;
  history: IAircraftHistory[];
  weight: number;
  volume: number;
  isCargo: boolean;
  isPassengers: boolean;
  aircraftModelId: string;
  homebase: string;
  imageOutsideUrl: string;
  imageInsideUrl: string;
  imageUrls: string[];
  importId?: string;
  importDate?: Date;
  dateOfManufacture: string | null;
  dateOfRefurbishment?: string | null;
  numberOfCrewMembers?: number | null;
  hasStewardess?: EnumAircraftHasStewardess | null;
  isSmokingAllowed?: boolean | null;
  sleepingConfiguration?: string | null;
  hasToilet?: boolean | null;
  hasHotCatering?: EnumAircraftHasHotCatering | null;
  arePetsAllowed?: boolean | null;
  wifi?: EnumAircraftWifi | null;
  hasSatellitePhone?: boolean | null;
  updatedFromSource?: string;
  updatedFromSourceWhen?: Date;
}

export enum EnumAircraftClass {
  first = 'F',
  business = 'C',
  premiumEconomy = 'W',
  economy = 'Y'
}

export const getAircraftAge = (aircraft: IAircraft): number | null => {
  if (aircraft.firstFlightDate) {
    return Math.round(moment().diff(moment(aircraft.firstFlightDate), 'years', true) * 10) / 10;
  } else if (aircraft.dateOfManufacture) {
    return Math.round(moment().diff(moment(aircraft.dateOfManufacture), 'years', true) * 10) / 10;
  } else if (aircraft.history?.length && aircraft.history[0].deliveryDate) {
    return (
      Math.round(moment().diff(moment(aircraft.history[0].deliveryDate), 'years', true) * 10) / 10
    );
  }
  return null;
};

export const getAircraftYom = (aircraft: IAircraft): number | null => {
  if (aircraft.firstFlightDate) {
    return moment(aircraft.firstFlightDate).get('year');
  }

  return null;
};

export enum EnumAircraftHasStewardess {
  yes = 'yes',
  no = 'no',
  onRequest = 'onRequest'
}

export const getAircraftHasStewardessLabel = (hasStewardess: EnumAircraftHasStewardess): string => {
  switch (hasStewardess) {
    default:
      return hasStewardess;
    case EnumAircraftHasStewardess.yes:
      return 'Oui';
    case EnumAircraftHasStewardess.no:
      return 'No';
    case EnumAircraftHasStewardess.onRequest:
      return 'Sur demande';
  }
};

export enum EnumAircraftHasHotCatering {
  yes = 'yes',
  no = 'no',
  fullGalley = 'fullGalley'
}

export const getAircraftHasHotCateringLabel = (
  hasHotCatering: EnumAircraftHasHotCatering
): string => {
  switch (hasHotCatering) {
    default:
      return hasHotCatering;
    case EnumAircraftHasHotCatering.yes:
      return 'Oui';
    case EnumAircraftHasHotCatering.no:
      return 'No';
    case EnumAircraftHasHotCatering.fullGalley:
      return 'Full Galley';
  }
};

export enum EnumAircraftWifi {
  yes = 'yes',
  no = 'no',
  unspecified = 'unspecified',
  yesSatelliteBased = 'yesSatelliteBased',
  yesGroundBasedAndSatelliteBased = ' yesGroundBasedAndSatelliteBased'
}

export const getAircraftWifiLabel = (wifi: EnumAircraftWifi): string => {
  switch (wifi) {
    default:
      return wifi;
    case EnumAircraftWifi.yes:
      return 'Oui';
    case EnumAircraftWifi.no:
      return 'Non';
    case EnumAircraftWifi.unspecified:
      return 'Non spécifié';
    case EnumAircraftWifi.yesSatelliteBased:
      return 'Oui (Satellite based)';
    case EnumAircraftWifi.yesGroundBasedAndSatelliteBased:
      return 'Oui (Ground based, Satellite based)';
  }
};

export interface IAircraftHistory {
  registration: string;
  deliveryDate: string | null;
  airlineTitle: string | null;
  airlineId: string | null;
  airlineContinentCode: string | null;
  airlineContinentTitle: string | null;
  airlineCountryCode: string | null;
  airlineCountryTitle: string | null;
  remark: string | null;
}
