import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { format } from 'date-fns';
import { Subscription } from 'rxjs';
import { EnumEnquiryStatus } from 'src/app/enums/enquiry-status.enum';
import { getDisplayedEnquiryRefTitle, IEnquiry } from 'src/app/interfaces/enquiry.interface';
import { IQuotation } from 'src/app/interfaces/quotation.interface';
import { getUserFullname, IUser } from 'src/app/interfaces/user.interface';
import { EnquiryService } from 'src/app/services/enquiry/enquiry.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { PipedriveService } from 'src/app/services/pipedrive.service';
import { QuotationService } from 'src/app/services/quotations/quotations.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-enquiry-quotations-sent-to-client',
  templateUrl: './enquiry-quotations-sent-to-client.component.html',
  styleUrls: ['./enquiry-quotations-sent-to-client.component.scss']
})
export class EnquiryQuotationsSentToClientComponent implements OnInit, OnChanges, OnDestroy {
  @Input() enquiry: IEnquiry | null = null;
  @Input() inModal: boolean = false;

  @ViewChild('btnCloseModal') btnCloseModalEl: ElementRef;

  getDisplayedEnquiryRefTitle = getDisplayedEnquiryRefTitle;
  getUserFullname = getUserFullname;

  form: FormGroup = this.resetForm();

  loadingQuotationByEnquiry: { [enquiryId: string]: boolean } = {};
  quotationsByEnquiry: { [enquiryId: string]: IQuotation[] } = {};

  users: IUser[] = [];
  loadingUsers: boolean = false;

  todayStr: string = format(new Date(), 'yyyy-MM-dd');

  private subscriptions = new Subscription();

  get quotationSentToClient(): FormControl {
    return this.form.get('quotationSentToClient') as FormControl;
  }

  get quotationSentToClientId(): FormControl {
    return this.form.get('quotationSentToClientId') as FormControl;
  }

  get quotationSentToClientDate(): FormControl {
    return this.form.get('quotationSentToClientDate') as FormControl;
  }

  get quotationSentToClientBy(): FormControl {
    return this.form.get('quotationSentToClientBy') as FormControl;
  }

  constructor(
    private enquiryService: EnquiryService,
    private loaderService: LoaderService,
    private router: Router,
    private quotationService: QuotationService,
    private userService: UserService,
    private pipedriveService: PipedriveService
  ) {}

  ngOnInit(): void {
    this.setValues();

    this.loadUsers();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['enquiry']) {
      this.setValues();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  resetForm(): FormGroup {
    return new FormGroup({
      quotationSentToClient: new FormControl(true, Validators.required),
      quotationSentToClientId: new FormControl(null),
      quotationSentToClientDate: new FormControl(null, Validators.required),
      quotationSentToClientBy: new FormControl(null, Validators.required)
    });
  }

  setValues(): void {
    if (this.form && this.enquiry) {
      this.form = this.resetForm();

      for (const field in this.form.value) {
        if (typeof this.enquiry[field] !== 'undefined') {
          this.form.get(field).setValue(this.enquiry[field]);
        }
      }

      this.updatedQuotationSentToClient();

      this.form.enable();

      this.loadQuotations(this.enquiry.id);
    }
  }

  updatedQuotationSentToClient(): void {
    for (const field of [
      'quotationSentToClientId',
      'quotationSentToClientDate',
      'quotationSentToClientBy'
    ]) {
      if (this.quotationSentToClient.value) {
        if (field !== 'quotationSentToClientId') {
          this.form.get(field).setValidators(Validators.required);
        }
      } else {
        this.form.get(field).clearValidators();
        this.form.get(field).setValue(null);
      }

      this.form.get(field).updateValueAndValidity();
    }
  }

  closeModal(): void {
    if (this.btnCloseModalEl) {
      this.btnCloseModalEl.nativeElement.click();
    }
  }

  submitForm(): void {
    this.form.markAsTouched();

    if (this.form.valid && this.enquiry) {
      this.loaderService.presentLoader();

      let data: IEnquiry = Object.assign({}, this.form.value);

      for (const field in data) {
        if (typeof data[field] == 'undefined') {
          data[field] = null;
        }
      }

      this.form.enable();

      data.id = this.enquiry.id;

      this.enquiryService
        .update(data)
        .then(async id => {
          if (data.quotationSentToClient && this.enquiry?.status === EnumEnquiryStatus.draft) {
            await this.pipedriveService.quotationSentToClient(this.enquiry);
          }

          await this.loaderService.hideLoaderOnSuccess();

          if (this.inModal) {
            this.closeModal();
          }

          if (!this.inModal) {
            this.redirectAfterSaving();
          }
        })
        .catch(async (err: any) => {
          await this.loaderService.hideLoaderOnFailure(err.message);
        })
        .finally(() => {
          this.form.enable();
        });
    }
  }

  redirectAfterSaving(): void {
    this.router.navigate([this.enquiry?.id ? '/admin/enquiries/' + this.enquiry.id : '/admin']);
  }

  loadQuotations(enquiryId: string): void {
    if (typeof this.quotationsByEnquiry[enquiryId] === 'undefined') {
      this.quotationsByEnquiry[enquiryId] = [];
      this.loadingQuotationByEnquiry[enquiryId] = true;

      this.subscriptions.add(
        this.quotationService.getAllForEnquiry(enquiryId).subscribe((quotations: IQuotation[]) => {
          this.quotationsByEnquiry[enquiryId] = quotations;

          this.loadingQuotationByEnquiry[enquiryId] = false;
        })
      );
    }
  }

  loadUsers(): void {
    this.loadingUsers = true;

    this.subscriptions.add(
      this.userService.getAll().subscribe((users: IUser[]) => {
        this.users = [];

        for (const user of users) {
          if (!user.isDisabled || this.quotationSentToClientBy.value === user.id) {
            this.users.push(user);
          }
        }

        this.users.sort((a, b) => (getUserFullname(a) < getUserFullname(b) ? -1 : 1));

        this.loadingUsers = false;
      })
    );
  }

  updatedQuotationSentToClientId(): void {
    if (this.enquiry && this.quotationSentToClientId.value) {
      for (const quotation of this.quotationsByEnquiry[this.enquiry.id]) {
        if (quotation.id === this.quotationSentToClientId.value) {
          this.quotationSentToClientDate.setValue(quotation.date);
          this.quotationSentToClientBy.setValue(quotation.agentId);

          this.quotationSentToClientDate.updateValueAndValidity();
          this.quotationSentToClientBy.updateValueAndValidity();

          break;
        }
      }
    }
  }
}
