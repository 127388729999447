import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  faArrowLeft,
  faArrowRight,
  faCalendar,
  faChevronDown,
  faChevronRight,
  faClock,
  faEdit,
  faHome,
  faPlane,
  faPlaneUp
} from '@fortawesome/free-solid-svg-icons';
import { addDays } from 'date-fns';
import { format } from 'date-fns-tz';
import { Subscription } from 'rxjs';
import { EnumEnquiryType, getEnumEnquiryTypeLabel } from 'src/app/enums/enquiry-type.enum';
import { IEnquiryFlight } from 'src/app/interfaces/enquiry-flight.interface';
import { IFlightBrief } from 'src/app/interfaces/flight-brief.interface';
import { convertMinutesToTime, convertUtcDateTimeToLocal } from 'src/app/misc.utils';
import { EnquiryFlightService } from 'src/app/services/enquiry-flights/enquiry-flights.service';
import { FlightBriefService } from 'src/app/services/flight-briefs/flight-briefs.service';
import countries from '../../countries_fr.json';
import { getFboFullAddress, IFbo } from 'src/app/interfaces/fbo.interface';
import { ICrew } from 'src/app/interfaces/crew.interface';
import { IAirlineSupervisor } from 'src/app/interfaces/airline-supervisor.interface';
import { FboService } from 'src/app/services/fbos/fbos.service';
import { CrewService } from 'src/app/services/crews/crews.service';
import { AirlineSupervisorService } from 'src/app/services/airline-supervisors/airline-supervisors.service';
import { IEnquiry } from 'src/app/interfaces/enquiry.interface';
import { EnquiryService } from 'src/app/services/enquiry/enquiry.service';
import {
  getFinishedCheckOnTotalForFlight,
  IFlightChecklist
} from 'src/app/interfaces/flight-checklist.interface';
import { IFlightChecklistTemplate } from 'src/app/interfaces/flight-checklist-template.interface';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { AclService } from 'src/app/services/acl.service';
import { FlightChecklistTemplateService } from 'src/app/services/flight-checklist-templates/flight-checklist-templates.service';
import { FlightChecklistService } from 'src/app/services/flight-checklists/flight-checklists.service';

enum EnumTimezonePredefined {
  utc = 'utc',
  paris = 'paris'
}

const getTimezonePredefinedLabel = (timezone: EnumTimezonePredefined): string => {
  switch (timezone) {
    default:
      return timezone;
    case EnumTimezonePredefined.utc:
      return 'Heure UTC';
    case EnumTimezonePredefined.paris:
      return 'Heure locale Paris';
  }
};

interface IFlightItinerary {
  enquiryType: EnumEnquiryType;
  enquiryId: string;
  aircraftRegistration: string | null;
  aircraftModelTitle: string | null;
  airlineTitle: string | null;
  pax: number | null;
  weight: number | null;
  volume: number | null;
  flights: {
    x1: number | null;
    x2: number | null;
    enquiryFlight: IEnquiryFlight;
    tooltipHtml: string;
  }[];
}

interface IFlightForDate {
  date: string;
  nbFerry: number;
  nbNonFerry: number;
  flights: IEnquiryFlight[];
}

interface IFlightForEnquiryType {
  enquiryType: EnumEnquiryType;
  nbFerry: number;
  nbNonFerry: number;
  itineraries: IFlightItinerary[];
  flights: IEnquiryFlight[];
  flightByDate: { [date: string]: IFlightForDate };
}

@Component({
  selector: 'app-flights-calendar',
  templateUrl: './flights-calendar.component.html',
  styleUrls: ['./flights-calendar.component.scss']
})
export class FlightsCalendarComponent implements OnInit, OnDestroy {
  @ViewChild('offcanvasFlight') offcanvasFlightEl: ElementRef | null = null;
  @ViewChild('modalEnquiryFlightEdit', { static: false }) modalEnquiryFlightEditElement: ElementRef;

  EnumEnquiryType = EnumEnquiryType;
  EnumAcl = EnumAcl;

  getTimezonePredefinedLabel = getTimezonePredefinedLabel;
  getEnumEnquiryTypeLabel = getEnumEnquiryTypeLabel;
  convertMinutesToTime = convertMinutesToTime;
  getFboFullAddress = getFboFullAddress;
  getFinishedCheckOnTotalForFlight = getFinishedCheckOnTotalForFlight;

  predefinedTimezone: EnumTimezonePredefined = EnumTimezonePredefined.paris;

  countries: { [countryCode: string]: string } = countries;

  todayStr: string = format(new Date(), 'yyyy-MM-dd');
  firstDateStr: string = format(new Date(), 'yyyy-MM-dd');

  faArrowLeft = faArrowLeft;
  faArrowRight = faArrowRight;
  faHome = faHome;
  faPlaneUp = faPlaneUp;
  faChevronDown = faChevronDown;
  faChevronRight = faChevronRight;
  faPlane = faPlane;
  faClock = faClock;
  faEdit = faEdit;
  faCalendar = faCalendar;

  calendarDates: string[] = [];

  enquiryFlights: IEnquiryFlight[] = [];
  loadingEnquiryFlights: boolean = false;

  nbDaysDisplayed: number = 5;

  flightByDates: { [date: string]: IFlightForDate } = {};
  flightByEnquiryTypes: { [enquiryType: string]: IFlightForEnquiryType } = {};

  fbosObj: { [id: string]: IFbo | null } = {};
  crewsObj: { [id: string]: ICrew | null } = {};
  supervisorsObj: { [id: string]: IAirlineSupervisor | null } = {};
  enquiriesObj: { [id: string]: IEnquiry | null } = {};

  enquiryTypesOpened: { [enquiryType: string]: boolean } = {};

  currentDate: string;
  currentTime: string;
  currentTimeIndicatorLeftPercent: number | null = null;

  intervalCurrentTime: any;

  selectedItinerary: IFlightItinerary | null = null;
  selectedEnquiryFlight: IEnquiryFlight | null = null;
  selectedFlightIndex: number | null = null;
  selectedTotalFlightsForItinerary: number | null = null;

  flightBriefsForEnquiry: { [enquiryId: string]: IFlightBrief[] } = {};
  loadingFlightBriefsForEnquiry: { [enquiryId: string]: boolean } = {};

  flightChecklistTemplateByEnquiryType: { [enquiryType: string]: IFlightChecklistTemplate | null } =
    {};
  flightChecklistByEnquiryFlight: { [enquiryFlightId: string]: IFlightChecklist | null } = {};
  flightChecklistTemplate: IFlightChecklistTemplate | null = null;
  loadingFlightChecklistTemplate: boolean = false;
  flightChecklist: IFlightChecklist | null = null;
  loadingFlightChecklist: boolean = false;

  private subscriptions = new Subscription();
  private subscriptionsFlights = new Subscription();
  private subscriptionFlightChecklist = new Subscription();

  constructor(
    private enquiryFlightService: EnquiryFlightService,
    private flightBriefService: FlightBriefService,
    private fboService: FboService,
    private crewService: CrewService,
    private airlineSupervisorService: AirlineSupervisorService,
    private enquiryService: EnquiryService,
    private aclService: AclService,
    private flightChecklistTemplateService: FlightChecklistTemplateService,
    private flightChecklistService: FlightChecklistService
  ) {
    for (const enquiryType of Object.values(EnumEnquiryType)) {
      this.enquiryTypesOpened[enquiryType] = false;
    }
  }

  getEnquiryTypes(): EnumEnquiryType[] {
    return Object.values(EnumEnquiryType);
  }

  ngOnInit(): void {
    this.refreshCalendarDate();

    this.refreshCurrentTime();
    this.intervalCurrentTime = setInterval(() => {
      this.refreshCurrentTime();
    }, 60000);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.subscriptionsFlights.unsubscribe();
    this.subscriptionFlightChecklist.unsubscribe();

    window['$']('.tooltip').remove();

    clearInterval(this.intervalCurrentTime);

    this.removeModal();
  }

  getAllTimezonePredefined(): EnumTimezonePredefined[] {
    return Object.values(EnumTimezonePredefined);
  }

  removeModal(): void {
    window['$'](this.modalEnquiryFlightEditElement.nativeElement).modal('hide');
    window['$']('body').removeClass('modal-open');
    window['$']('.modal-backdrop').remove();
  }

  refreshCalendarDate(): void {
    this.calendarDates = [];

    for (let i = 0; i < this.nbDaysDisplayed; i++) {
      this.calendarDates.push(format(addDays(new Date(this.firstDateStr), i), 'yyyy-MM-dd'));
    }

    this.loadEnquiryFlights();

    this.updatedFlightByDates();
    this.refreshCurrentTime();
  }

  loadEnquiryFlights(): void {
    if (this.calendarDates) {
      this.loadingEnquiryFlights = true;

      if (this.subscriptionsFlights) {
        this.subscriptionsFlights.unsubscribe();
      }

      this.subscriptionsFlights = new Subscription();

      this.subscriptionsFlights.add(
        this.enquiryFlightService
          .getAllForDates(this.calendarDates)
          .subscribe((enquiryFlights: IEnquiryFlight[]) => {
            this.enquiryFlights = enquiryFlights;

            this.enquiryFlights.sort((a: IEnquiryFlight, b: IEnquiryFlight) => {
              if (a.departDateLocal < b.departDateLocal) {
                return -1;
              } else if (a.departDateLocal > b.departDateLocal) {
                return 1;
              } else {
                if (a.departTimeLocal < b.departTimeLocal) {
                  return -1;
                } else if (a.departTimeLocal > b.departTimeLocal) {
                  return 1;
                }
              }

              return 0;
            });

            this.updatedFlightByDates();
            this.refreshFbosForAllEnquiryFlights();
            this.refreshCrewsAndSupervisorsForAllEnquiryFlights();

            for (const enquiryFlight of this.enquiryFlights) {
              this.loadEnquiry(enquiryFlight.enquiryId);

              if (this.selectedEnquiryFlight?.id === enquiryFlight.id) {
                this.selectedEnquiryFlight = enquiryFlight;
              }
            }

            this.loadingEnquiryFlights = false;
          })
      );
    }
  }

  goPrevPage(): void {
    this.firstDateStr = format(
      addDays(new Date(this.firstDateStr), this.nbDaysDisplayed * -1),
      'yyyy-MM-dd'
    );

    this.refreshCalendarDate();
  }

  goNextPage(): void {
    this.firstDateStr = format(
      addDays(new Date(this.firstDateStr), this.nbDaysDisplayed),
      'yyyy-MM-dd'
    );

    this.refreshCalendarDate();
  }

  goBackToday(): void {
    this.firstDateStr = this.todayStr;

    this.refreshCalendarDate();
  }

  updatedDate(dateStr: string): void {
    this.firstDateStr = dateStr;

    this.refreshCalendarDate();
  }

  updateTooltip(): void {
    window['$']('.tooltip').remove();

    window['$']('[rel="tooltip"]').tooltip({
      html: true,
      boundary: 'window'
    });
  }

  updatedPredefinedTimezone(): void {
    this.updatedFlightByDates();
    this.refreshCurrentTime();
  }

  updatedFlightByDates(): void {
    this.flightByDates = {};
    this.flightByEnquiryTypes = {};

    for (const enquiryType of Object.values(EnumEnquiryType)) {
      this.flightByEnquiryTypes[enquiryType] = {
        enquiryType,
        nbFerry: 0,
        nbNonFerry: 0,
        itineraries: [],
        flights: [],
        flightByDate: {}
      };
      this.enquiryTypesOpened[enquiryType] = false;
    }

    for (const calendarDate of this.calendarDates) {
      if (typeof this.flightByDates[calendarDate] === 'undefined') {
        this.flightByDates[calendarDate] = {
          date: calendarDate,
          nbFerry: 0,
          nbNonFerry: 0,
          flights: []
        };

        for (const enquiryType of Object.values(EnumEnquiryType)) {
          this.flightByEnquiryTypes[enquiryType].flightByDate[calendarDate] = {
            date: calendarDate,
            nbFerry: 0,
            nbNonFerry: 0,
            flights: []
          };
        }
      }
    }

    for (const enquiryFlight of this.enquiryFlights) {
      let itineraryIndex: number | null = null;
      for (
        let i = 0;
        i < this.flightByEnquiryTypes[enquiryFlight.enquiryType].itineraries.length;
        i++
      ) {
        if (
          this.flightByEnquiryTypes[enquiryFlight.enquiryType].itineraries[i].enquiryId ===
          enquiryFlight.enquiryId
        ) {
          itineraryIndex = i;
          break;
        }
      }

      if (itineraryIndex === null) {
        this.flightByEnquiryTypes[enquiryFlight.enquiryType].itineraries.push({
          enquiryType: enquiryFlight.enquiryType,
          enquiryId: enquiryFlight.enquiryId,
          aircraftRegistration: enquiryFlight.aircraftRegistration,
          aircraftModelTitle: enquiryFlight.aircraftModelTitle,
          airlineTitle: enquiryFlight.airlineTitle,
          pax: enquiryFlight.pax,
          weight: enquiryFlight.weight,
          volume: enquiryFlight.volume,
          flights: []
        });

        itineraryIndex =
          this.flightByEnquiryTypes[enquiryFlight.enquiryType].itineraries.length - 1;
      }

      let departDate: string = enquiryFlight.departDateUtc;
      let departTime: string = enquiryFlight.departTimeUtc;
      let arrivalDate: string = enquiryFlight.arrivalDateUtc;
      let arrivalTime: string = enquiryFlight.arrivalTimeUtc;

      if (this.predefinedTimezone === EnumTimezonePredefined.paris) {
        departDate = convertUtcDateTimeToLocal(
          enquiryFlight.departDateUtc,
          enquiryFlight.departTimeUtc,
          'Europe/Paris',
          'yyyy-MM-dd'
        );
        departTime = convertUtcDateTimeToLocal(
          enquiryFlight.departDateUtc,
          enquiryFlight.departTimeUtc,
          'Europe/Paris',
          'HH:mm'
        );
        arrivalDate = convertUtcDateTimeToLocal(
          enquiryFlight.arrivalDateUtc,
          enquiryFlight.arrivalTimeUtc,
          'Europe/Paris',
          'yyyy-MM-dd'
        );
        arrivalTime = convertUtcDateTimeToLocal(
          enquiryFlight.arrivalDateUtc,
          enquiryFlight.arrivalTimeUtc,
          'Europe/Paris',
          'HH:mm'
        );
      }

      const x1: number = this.getIndicatorHorizontalPositionFromDateTime(departDate, departTime);
      const x2: number = this.getIndicatorHorizontalPositionFromDateTime(arrivalDate, arrivalTime);

      let tooltipHtml: string = '<div class="row">';

      tooltipHtml +=
        '<div class="col-6 text-start text-nowrap">' + enquiryFlight.airportDepartIataCode + '<br>';

      if (this.predefinedTimezone === EnumTimezonePredefined.paris) {
        tooltipHtml += departTime + ' Heure locale Paris';
      } else {
        tooltipHtml += enquiryFlight.departTimeUtc + ' UTC';
      }

      tooltipHtml += '</div>';

      tooltipHtml +=
        '<div class="col-6 text-end text-nowrap">' + enquiryFlight.airportArrivalIataCode + '<br>';

      if (enquiryFlight.arrivalTimeUtc === enquiryFlight.departTimeUtc) {
        tooltipHtml += '<em>Heure à définir</em>';
      } else {
        if (this.predefinedTimezone === EnumTimezonePredefined.paris) {
          tooltipHtml += arrivalTime + ' Heure locale Paris';
        } else {
          tooltipHtml += enquiryFlight.arrivalTimeUtc + ' UTC';
        }
      }

      tooltipHtml += '</div>';

      tooltipHtml += '</div>';

      tooltipHtml += enquiryFlight.clientName;

      this.flightByEnquiryTypes[enquiryFlight.enquiryType].itineraries[itineraryIndex].flights.push(
        {
          x1,
          x2: x2 - x1 > 1 ? x2 : x1 + 1,
          enquiryFlight: enquiryFlight,
          tooltipHtml
        }
      );

      this.enquiryTypesOpened[enquiryFlight.enquiryType] = true;
      this.flightByEnquiryTypes[enquiryFlight.enquiryType].flights.push(enquiryFlight);

      const indicatorField: string = enquiryFlight.isFerryFlight ? 'nbFerry' : 'nbNonFerry';

      if (this.flightByDates[departDate]) {
        this.flightByDates[departDate].flights.push(enquiryFlight);
        this.flightByDates[departDate][indicatorField]++;
      }

      if (this.flightByEnquiryTypes[enquiryFlight.enquiryType].flightByDate[departDate]) {
        this.flightByEnquiryTypes[enquiryFlight.enquiryType].flightByDate[departDate].flights.push(
          enquiryFlight
        );
        this.flightByEnquiryTypes[enquiryFlight.enquiryType].flightByDate[departDate][
          indicatorField
        ]++;
      }

      this.flightByEnquiryTypes[enquiryFlight.enquiryType][indicatorField]++;
    }

    setTimeout(() => {
      this.loadingEnquiryFlights = false;

      this.updateTooltip();
    }, 1000);
  }

  refreshCurrentTime(): void {
    const isoDate: string = new Date().toISOString();

    let date: string = isoDate.substring(0, 10);
    let time: string = isoDate.substring(11, 16);

    if (this.predefinedTimezone === EnumTimezonePredefined.paris) {
      const localDateTimeSplitted: string[] = convertUtcDateTimeToLocal(
        date,
        time,
        'Europe/Paris',
        'yyyy-MM-dd HH:mm'
      ).split(' ');

      date = localDateTimeSplitted[0];
      time = localDateTimeSplitted[1];
    }

    this.currentTimeIndicatorLeftPercent = this.getIndicatorHorizontalPositionFromDateTime(
      date,
      time
    );
  }

  getIndicatorHorizontalPositionFromDateTime(date: string, time: string): number | null {
    const index: number = this.calendarDates.indexOf(date);

    if (index === -1) {
      return null;
    } else {
      const splittedTime: string[] = time.split(':');
      const timeToMinutes: number = parseInt(splittedTime[0]) * 60 + parseInt(splittedTime[1]);

      return index * 20 + (timeToMinutes * 20) / (24 * 60);
    }
  }

  toggleAccordion(enquiryType: EnumEnquiryType): void {
    if (this.flightByEnquiryTypes[enquiryType].flights.length) {
      this.enquiryTypesOpened[enquiryType] = !this.enquiryTypesOpened[enquiryType];
    }
  }

  openFlight(itinerary: IFlightItinerary, i: number): void {
    if (this.offcanvasFlightEl.nativeElement) {
      this.selectedFlightIndex = i;
      this.selectedItinerary = itinerary;
      this.selectedTotalFlightsForItinerary = itinerary.flights.length;
      this.selectedEnquiryFlight = itinerary.flights[i].enquiryFlight;

      this.loadFlightBriefForEnquiry(this.selectedEnquiryFlight.enquiryId);

      this.loadFlightChecklistAndTemplate();

      window['$'](this.offcanvasFlightEl.nativeElement).offcanvas('show');
    }
  }

  loadFlightChecklistAndTemplate(): void {
    if (this.selectedEnquiryFlight) {
      if (this.subscriptionFlightChecklist) {
        this.subscriptionFlightChecklist.unsubscribe();
      }

      this.subscriptionFlightChecklist = new Subscription();

      this.loadFlightChecklistTemplate();
      this.loadFlightChecklist();
    }
  }

  private loadFlightChecklistTemplate(): void {
    if (this.selectedEnquiryFlight) {
      this.loadingFlightChecklistTemplate = true;

      this.subscriptionFlightChecklist.add(
        this.flightChecklistTemplateService
          .getOneForEnquiryType(this.selectedEnquiryFlight.enquiryType)
          .subscribe((flightChecklistTemplate: IFlightChecklistTemplate) => {
            this.flightChecklistTemplate = flightChecklistTemplate;

            if (this.flightChecklistTemplate) {
              this.flightChecklistTemplateByEnquiryType[this.flightChecklistTemplate.enquiryType] =
                this.flightChecklistTemplate;
            }

            this.loadingFlightChecklistTemplate = false;
          })
      );
    }
  }

  private loadFlightChecklist(): void {
    if (this.selectedEnquiryFlight) {
      this.loadingFlightChecklist = true;

      this.subscriptionFlightChecklist.add(
        this.flightChecklistService
          .getForFlight(this.selectedEnquiryFlight.id)
          .subscribe((flightChecklist: IFlightChecklist) => {
            this.flightChecklist = flightChecklist;

            if (this.flightChecklist) {
              this.flightChecklistByEnquiryFlight[this.selectedEnquiryFlight.id] =
                this.flightChecklist;
            }

            this.loadingFlightChecklist = false;
          })
      );
    }
  }

  loadFlightBriefForEnquiry(enquiryId: string): void {
    if (typeof this.flightBriefsForEnquiry[enquiryId] === 'undefined') {
      this.flightBriefsForEnquiry[enquiryId] = [];
      this.loadingFlightBriefsForEnquiry[enquiryId] = true;

      this.subscriptions.add(
        this.flightBriefService
          .getAllForEnquiry(enquiryId)
          .subscribe((flightBriefs: IFlightBrief[]) => {
            this.flightBriefsForEnquiry[enquiryId] = flightBriefs;

            this.flightBriefsForEnquiry[enquiryId].sort((a, b) => (a.created < b.created ? -1 : 1));

            this.loadingFlightBriefsForEnquiry[enquiryId] = false;
          })
      );
    }
  }

  loadFbo(fboId: string): void {
    if (typeof this.fbosObj[fboId] === 'undefined') {
      this.fbosObj[fboId] = null;

      this.subscriptions.add(
        this.fboService.getFromId(fboId).subscribe((fbo: IFbo | null) => {
          this.fbosObj[fboId] = fbo;
        })
      );
    }
  }

  loadCrewsForAirline(airlineId: string): void {
    this.subscriptions.add(
      this.crewService.getForAirline(airlineId).subscribe((crews: ICrew[]) => {
        for (const crew of crews) {
          this.crewsObj[crew.id] = crew;
        }
      })
    );
  }

  loadSupervisorsForAirline(airlineId: string): void {
    this.subscriptions.add(
      this.airlineSupervisorService
        .getForAirline(airlineId)
        .subscribe((supervisors: IAirlineSupervisor[]) => {
          for (const supervisor of supervisors) {
            this.supervisorsObj[supervisor.id] = supervisor;
          }
        })
    );
  }

  loadEnquiry(enquiryId: string): void {
    if (typeof this.enquiriesObj[enquiryId] === 'undefined') {
      this.enquiriesObj[enquiryId] = null;

      this.subscriptions.add(
        this.enquiryService.getFromId(enquiryId).subscribe((enquiry: IEnquiry | null) => {
          this.enquiriesObj[enquiryId] = enquiry;
        })
      );
    }
  }

  refreshFbosForAllEnquiryFlights(): void {
    for (const enquiryFlight of this.enquiryFlights) {
      for (const field of ['departFboId', 'arrivalFboId']) {
        if (enquiryFlight[field]) {
          this.loadFbo(enquiryFlight[field]);
        }
      }
    }
  }

  refreshCrewsAndSupervisorsForAllEnquiryFlights(): void {
    for (const enquiryFlight of this.enquiryFlights) {
      if (enquiryFlight.airlineId) {
        this.loadCrewsForAirline(enquiryFlight.airlineId);
        this.loadSupervisorsForAirline(enquiryFlight.airlineId);
      }
    }
  }

  editCurrentSelectedFlight(): void {
    if (this.selectedEnquiryFlight && this.modalEnquiryFlightEditElement.nativeElement) {
      window['$'](this.modalEnquiryFlightEditElement.nativeElement).modal('show');
    }
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }
}
