import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import {
  faArrowLeft,
  faArrowRight,
  faBox,
  faComment,
  faPlane,
  faPlaneArrival,
  faPlaneDeparture,
  faTicket,
  faUsers,
  faUserTie,
  IconDefinition
} from '@fortawesome/free-solid-svg-icons';
import { IEnquiryFlight } from 'src/app/interfaces/enquiry-flight.interface';
import {
  getDisplayedEnquiryRefTitle,
  getEnquiryRefTitleWithoutPrefix,
  IEnquiry
} from 'src/app/interfaces/enquiry.interface';
import { EnquiryFlightService } from 'src/app/services/enquiry-flights/enquiry-flights.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { EnumEnquiryType } from 'src/app/enums/enquiry-type.enum';
import { PipedriveService } from 'src/app/services/pipedrive.service';
import { IPipedriveOrganization } from 'src/app/interfaces/pipedrive.interface';
import { Subscription } from 'rxjs';
import { isBefore } from 'date-fns';
import { getUserFullname, IUser } from 'src/app/interfaces/user.interface';
import { UserService } from 'src/app/services/user/user.service';
import { AlertService } from 'src/app/services/alert/alert.service';

export enum EnumEnquiryFlightWizardStep {
  aircraft = 'aircraft',
  departure = 'departure',
  arrival = 'arrival',
  flightDetail = 'flightDetail',
  passengers = 'passengers',
  cargo = 'cargo',
  crew = 'crew',
  comment = 'comment'
}

const getWizardStepLabel = (
  step: EnumEnquiryFlightWizardStep,
  enquiryType: EnumEnquiryType
): string => {
  switch (step) {
    default:
      return step;
    case EnumEnquiryFlightWizardStep.aircraft:
      return 'Appareil';
    case EnumEnquiryFlightWizardStep.departure:
      return 'Départ';
    case EnumEnquiryFlightWizardStep.arrival:
      return 'Arrivé';
    case EnumEnquiryFlightWizardStep.flightDetail:
      return 'Détails du vol';
    case EnumEnquiryFlightWizardStep.passengers:
      return 'Passagers';
    case EnumEnquiryFlightWizardStep.cargo:
      return 'Cargo';
    case EnumEnquiryFlightWizardStep.crew:
      return [EnumEnquiryType.cargo, EnumEnquiryType.commercial].includes(enquiryType)
        ? 'Opérateur'
        : 'Équipage';
    case EnumEnquiryFlightWizardStep.comment:
      return 'Remarque';
  }
};

const getWizardStepIcon = (step: EnumEnquiryFlightWizardStep): IconDefinition => {
  switch (step) {
    default:
      return faPlaneDeparture;
    case EnumEnquiryFlightWizardStep.aircraft:
      return faPlane;
    case EnumEnquiryFlightWizardStep.departure:
      return faPlaneDeparture;
    case EnumEnquiryFlightWizardStep.arrival:
      return faPlaneArrival;
    case EnumEnquiryFlightWizardStep.flightDetail:
      return faTicket;
    case EnumEnquiryFlightWizardStep.passengers:
      return faUsers;
    case EnumEnquiryFlightWizardStep.cargo:
      return faBox;
    case EnumEnquiryFlightWizardStep.crew:
      return faUserTie;
    case EnumEnquiryFlightWizardStep.comment:
      return faComment;
  }
};

@Component({
  selector: 'app-enquiry-flight-edit',
  templateUrl: './enquiry-flight-edit.component.html',
  styleUrls: ['./enquiry-flight-edit.component.scss']
})
export class EnquiryFlightEditComponent implements OnInit, OnChanges, OnDestroy {
  @Input() enquiry: IEnquiry | null = null;
  @Input() enquiryFlightId: string | null = null;
  @Input() inModal: boolean = false;
  @Input() currentStep: EnumEnquiryFlightWizardStep = EnumEnquiryFlightWizardStep.aircraft;

  @Output() updatedSelectedEnquiryFlight: EventEmitter<string> = new EventEmitter();

  @ViewChild('btnCloseModal') btnCloseModalEl: ElementRef;

  EnumEnquiryFlightWizardStep = EnumEnquiryFlightWizardStep;

  flightsForEnquiry: IEnquiryFlight[] = [];
  loadingFlightsForEnquiry: boolean = false;
  currentEnquiryFlightIndex: number = 0;

  enquiryFlight: IEnquiryFlight | null = null;

  faArrowLeft = faArrowLeft;
  faArrowRight = faArrowRight;

  steps: EnumEnquiryFlightWizardStep[] = [];

  clientsObj: { [id: string]: IPipedriveOrganization | null } = {};
  usersObj: { [id: string]: IUser | null } = {};

  getWizardStepLabel = getWizardStepLabel;
  getWizardStepIcon = getWizardStepIcon;

  private subscriptions = new Subscription();
  private subscriptionsEnquiryFlight = new Subscription();

  constructor(
    private router: Router,
    private loaderService: LoaderService,
    private enquiryFlightService: EnquiryFlightService,
    private pipedriveService: PipedriveService,
    private userService: UserService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.updatedEnquiry();
    this.loadEnquiryFlight();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['enquiry'] &&
      (!changes['enquiry'].previousValue ||
        (changes['enquiry'].currentValue &&
          changes['enquiry'].previousValue.id !== changes['enquiry'].currentValue.id))
    ) {
      this.updatedEnquiry();
    }

    if (
      changes['enquiryFlightId'] &&
      changes['enquiryFlightId'].previousValue !== changes['enquiryFlightId'].currentValue
    ) {
      this.loadEnquiryFlight();

      if (!this.enquiryFlightId) {
        this.currentStep = EnumEnquiryFlightWizardStep.aircraft;
      }
    }
  }

  ngOnDestroy(): void {
    window['$']('.tooltip').remove();

    this.subscriptions.unsubscribe();
    this.subscriptionsEnquiryFlight.unsubscribe();
  }

  updateTooltip(): void {
    if (window['$']('[rel="tooltip"]').length) {
      window['$']('[rel="tooltip"]').tooltip({
        html: true,
        boundary: 'window',
        trigger: 'hover'
      });
    }
  }

  redirectAfterSaving(): void {
    this.router.navigate(['/admin/enquiries/' + this.enquiry.id]);
  }

  updateCurrentStep(step: EnumEnquiryFlightWizardStep): void {
    this.currentStep = step;
  }

  goPrev(): void {
    let newStep: EnumEnquiryFlightWizardStep | null = null;

    switch (this.currentStep) {
      case EnumEnquiryFlightWizardStep.departure:
        newStep = EnumEnquiryFlightWizardStep.aircraft;
        break;
      case EnumEnquiryFlightWizardStep.arrival:
        newStep = EnumEnquiryFlightWizardStep.departure;
        break;
      case EnumEnquiryFlightWizardStep.flightDetail:
        newStep = EnumEnquiryFlightWizardStep.arrival;
        break;
      case EnumEnquiryFlightWizardStep.passengers:
        newStep = EnumEnquiryFlightWizardStep.flightDetail;
        break;
      case EnumEnquiryFlightWizardStep.cargo:
        newStep = EnumEnquiryFlightWizardStep.flightDetail;
        break;
      case EnumEnquiryFlightWizardStep.crew:
        if (this.enquiryFlight.enquiryType === EnumEnquiryType.cargo) {
          newStep = EnumEnquiryFlightWizardStep.cargo;
        } else {
          newStep = EnumEnquiryFlightWizardStep.passengers;
        }
        break;
      case EnumEnquiryFlightWizardStep.comment:
        newStep = EnumEnquiryFlightWizardStep.crew;
        break;
    }

    if (newStep) {
      this.updateCurrentStep(newStep);
    }
  }

  async saveAndGoNext(data: IEnquiryFlight): Promise<void> {
    this.loaderService.presentLoader();

    try {
      await this.save(data);

      await this.loaderService.hideLoaderOnSuccess();

      await this.updateOtherLegsIfConfirmed(data);

      let newStep: EnumEnquiryFlightWizardStep | null = null;

      switch (this.currentStep) {
        case EnumEnquiryFlightWizardStep.aircraft:
          newStep = EnumEnquiryFlightWizardStep.departure;
          break;
        case EnumEnquiryFlightWizardStep.departure:
          newStep = EnumEnquiryFlightWizardStep.arrival;
          break;
        case EnumEnquiryFlightWizardStep.arrival:
          newStep = EnumEnquiryFlightWizardStep.flightDetail;
          break;
        case EnumEnquiryFlightWizardStep.flightDetail:
          if (this.enquiryFlight.enquiryType === EnumEnquiryType.cargo) {
            newStep = EnumEnquiryFlightWizardStep.cargo;
          } else {
            newStep = EnumEnquiryFlightWizardStep.passengers;
          }
          break;
        case EnumEnquiryFlightWizardStep.passengers:
          newStep = EnumEnquiryFlightWizardStep.crew;
          break;
        case EnumEnquiryFlightWizardStep.cargo:
          newStep = EnumEnquiryFlightWizardStep.crew;
          break;
        case EnumEnquiryFlightWizardStep.crew:
          newStep = EnumEnquiryFlightWizardStep.comment;
          break;
      }

      if (newStep) {
        this.updateCurrentStep(newStep);
      } else if (this.currentStep === EnumEnquiryFlightWizardStep.comment) {
        if (this.inModal) {
          if (this.btnCloseModalEl) {
            this.btnCloseModalEl.nativeElement.click();
          }
        } else {
          this.redirectAfterSaving();
        }
      }
    } catch (err) {
      await this.loaderService.hideLoaderOnFailure(err.message);
    }
  }

  async updateOtherLegsIfConfirmed(data: IEnquiryFlight): Promise<void> {
    const stepsToIgnore: EnumEnquiryFlightWizardStep[] = [
      EnumEnquiryFlightWizardStep.departure,
      EnumEnquiryFlightWizardStep.arrival
    ];

    if (!stepsToIgnore.includes(this.currentStep) && this.flightsForEnquiry.length > 1) {
      this.alertService.presentConfirm(
        'Souhaitez-vous également mettre à jour les autres legs ?',
        async () => {
          this.loaderService.presentLoader();

          const dataToUpdate: IEnquiryFlight = Object.assign({}, data);
          delete dataToUpdate.id;

          for (const enquiryFlight of this.flightsForEnquiry) {
            if (enquiryFlight.id !== data.id) {
              dataToUpdate.id = enquiryFlight.id;
              await this.enquiryFlightService.update(dataToUpdate);
            }
          }

          await this.loaderService.hideLoaderOnSuccess();
        }
      );
    }
  }

  private async save(data: IEnquiryFlight): Promise<void> {
    try {
      for (const field in data) {
        if (typeof data[field] == 'undefined') {
          data[field] = null;
        }
      }

      if (this.enquiry) {
        data.enquiryId = this.enquiry.id;
        data.enquiryType = this.enquiry.type;
        data.enquiryRefContractTitle = getDisplayedEnquiryRefTitle(this.enquiry, 'refContract');
        data.enquiryRefContractTitleWithoutPrefix = getEnquiryRefTitleWithoutPrefix(
          this.enquiry.refContractYear,
          this.enquiry.refContractMonth,
          this.enquiry.refContractNumber,
          2
        );
        data.enquiryRefContractYear = this.enquiry.refContractYear;
        data.enquiryRefContractMonth = this.enquiry.refContractMonth;
        data.enquiryRefContractMonth = this.enquiry.refContractMonth;
        data.clientId = this.enquiry.clientId.toString();
        data.clientName = this.clientsObj[data.clientId]?.name || null;
        data.clientContactId = this.enquiry.contactId.toString();
        data.clientContactTitle = this.enquiry.contactTitle;
        data.enquiryProcessedBy = this.enquiry.processedBy;
        data.userFullName = getUserFullname(this.usersObj[this.enquiry.processedBy]);
        data.userInitials = this.usersObj[this.enquiry.processedBy]?.initials || '';
      }

      let promise;
      if (this.enquiryFlightId) {
        data.id = this.enquiryFlightId;
        promise = () => this.enquiryFlightService.update(data);
      } else {
        promise = () => this.enquiryFlightService.create(data);
      }

      const id: string = await promise();

      if (!this.enquiryFlightId) {
        this.enquiryFlightId = id;

        this.updatedSelectedEnquiryFlight.emit(this.enquiryFlightId);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }

  updatedEnquiry(): void {
    this.updateSteps();

    if (this.enquiry?.clientId) {
      this.loadClient(this.enquiry.clientId.toString());
    }

    this.loadUser(this.enquiry?.processedBy);

    this.loadFlightsForEnquiry();
  }

  async loadClient(clientId: string): Promise<void> {
    if (typeof this.clientsObj[clientId] === 'undefined') {
      this.clientsObj[clientId] = null;

      this.clientsObj[clientId] = await this.pipedriveService.getOrganization(clientId);
    }
  }

  loadUser(userId: string): void {
    if (typeof this.usersObj[userId] === 'undefined') {
      this.usersObj[userId] = null;

      this.subscriptions.add(
        this.userService.getFromId(userId).subscribe((user: IUser | null) => {
          this.usersObj[userId] = user;
        })
      );
    }
  }

  stepIsDisabled(step: EnumEnquiryFlightWizardStep): boolean {
    if (this.enquiryFlight) {
      let conditions: boolean[] = [];

      switch (step) {
        case EnumEnquiryFlightWizardStep.departure:
          conditions.push(!this.enquiryFlight.airlineId);
          conditions.push(!this.enquiryFlight.aircraftModelId);
          break;
        case EnumEnquiryFlightWizardStep.arrival:
          conditions.push(!this.enquiryFlight.airportDepartId);
          break;
        case EnumEnquiryFlightWizardStep.flightDetail:
          conditions.push(!this.enquiryFlight.airportDepartId);
          conditions.push(!this.enquiryFlight.airportArrivalId);
          break;
        case EnumEnquiryFlightWizardStep.passengers:
          conditions.push(!this.enquiryFlight.airportDepartId);
          conditions.push(!this.enquiryFlight.airportArrivalId);
          break;
        case EnumEnquiryFlightWizardStep.cargo:
          conditions.push(!this.enquiryFlight.airportDepartId);
          conditions.push(!this.enquiryFlight.airportArrivalId);
          break;
        case EnumEnquiryFlightWizardStep.crew:
          conditions.push(!this.enquiryFlight.airportDepartId);
          conditions.push(!this.enquiryFlight.airportArrivalId);
          break;
        case EnumEnquiryFlightWizardStep.comment:
          conditions.push(!this.enquiryFlight.airportDepartId);
          conditions.push(!this.enquiryFlight.airportArrivalId);
          break;
      }

      return conditions.includes(true);
    }

    return step !== EnumEnquiryFlightWizardStep.aircraft;
  }

  updateSteps(): void {
    this.steps = [];

    if (this.enquiry) {
      for (const step of Object.values(EnumEnquiryFlightWizardStep)) {
        switch (step) {
          default:
            this.steps.push(step);
            break;
          case EnumEnquiryFlightWizardStep.passengers:
            if (this.enquiry.type !== EnumEnquiryType.cargo) {
              this.steps.push(step);
            }
            break;
          case EnumEnquiryFlightWizardStep.cargo:
            if (this.enquiry.type === EnumEnquiryType.cargo) {
              this.steps.push(step);
            }
            break;
        }
      }
    }
  }

  updateCurrentEnquiryFlightIndex(): void {
    this.currentEnquiryFlightIndex = 0;

    if (this.enquiryFlightId && this.flightsForEnquiry.length) {
      for (let i = 0; i < this.flightsForEnquiry.length; i++) {
        if (this.enquiryFlightId === this.flightsForEnquiry[i].id) {
          this.currentEnquiryFlightIndex = i;
          break;
        }
      }
    }
  }

  loadFlightsForEnquiry(): void {
    if (this.enquiry) {
      this.flightsForEnquiry = [];
      this.loadingFlightsForEnquiry = true;

      this.subscriptions.add(
        this.enquiryFlightService
          .getAllForEnquiry(this.enquiry.id)
          .subscribe((enquiryFlights: IEnquiryFlight[]) => {
            this.flightsForEnquiry = enquiryFlights;

            this.flightsForEnquiry.sort((a: IEnquiryFlight, b: IEnquiryFlight) => {
              if (a.departDateLocal < b.departDateLocal) {
                return -1;
              } else if (a.departDateLocal > b.departDateLocal) {
                return 1;
              } else {
                if (a.departTimeLocal < b.departTimeLocal) {
                  return -1;
                } else if (a.departTimeLocal > b.departTimeLocal) {
                  return 1;
                }
              }

              return 0;
            });

            this.updateCurrentEnquiryFlightIndex();

            this.loadingFlightsForEnquiry = false;
          })
      );
    }
  }

  prevEnquiryFlight(): void {
    if (this.enquiryFlightId && this.flightsForEnquiry && this.flightsForEnquiry?.length) {
      for (let i = 0; i < this.flightsForEnquiry.length; i++) {
        if (i > 0 && this.enquiryFlightId === this.flightsForEnquiry[i].id) {
          this.updatedSelectedEnquiryFlight.emit(this.flightsForEnquiry[i - 1].id);
          break;
        }
      }
    }
  }

  nextEnquiryFlight(): void {
    if (this.enquiryFlightId && this.flightsForEnquiry.length) {
      for (let i = 0; i < this.flightsForEnquiry.length; i++) {
        if (
          i < this.flightsForEnquiry.length - 1 &&
          this.enquiryFlightId === this.flightsForEnquiry[i].id
        ) {
          this.updatedSelectedEnquiryFlight.emit(this.flightsForEnquiry[i + 1].id);
          break;
        }
      }
    }
  }

  loadEnquiryFlight(): void {
    if (this.enquiryFlightId) {
      if (this.subscriptionsEnquiryFlight) {
        this.subscriptionsEnquiryFlight.unsubscribe();
      }

      this.subscriptionsEnquiryFlight = new Subscription();

      this.subscriptionsEnquiryFlight.add(
        this.enquiryFlightService
          .getFromId(this.enquiryFlightId)
          .subscribe((enquiryFlight: IEnquiryFlight | null) => {
            this.enquiryFlight = enquiryFlight;

            this.updateCurrentEnquiryFlightIndex();
          })
      );
    } else {
      this.enquiryFlight = null;
    }
  }
}
