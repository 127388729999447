import {
  Component,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
  ElementRef,
  ViewChild
} from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { RemoteService } from '../../../services/remote.service';

import { Subscription } from 'rxjs';
import { IFlightBrief, IFlightBriefTrip } from 'src/app/interfaces/flight-brief.interface';
import {
  IEnquiry,
  getDisplayedEnquiryRefTitle,
  getEnquiryBreadcrumbTitle
} from 'src/app/interfaces/enquiry.interface';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { AclService } from 'src/app/services/acl.service';
import { EnquiryService } from 'src/app/services/enquiry/enquiry.service';
import { EnumLanguage, getLanguageLabel } from 'src/app/enums/language.enum';
import { FlightBriefService } from 'src/app/services/flight-briefs/flight-briefs.service';
import { getUserFullname, IUser } from 'src/app/interfaces/user.interface';
import {
  faCircleCheck,
  faCirclePlus,
  faEdit,
  faExternalLink,
  faPlane,
  faToggleOff,
  faToggleOn,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import { finalize } from 'rxjs/operators';
import { IAircraft } from 'src/app/interfaces/aircraft.interface';
import { QuotationService } from 'src/app/services/quotations/quotations.service';
import { IQuotation } from 'src/app/interfaces/quotation.interface';
import { capitalize, generateRandomId } from 'src/app/misc.utils';
import { EnumEnquiryType } from 'src/app/enums/enquiry-type.enum';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs/breadcrumbs.service';
import { IBreadcrumbLink } from 'src/app/components/header-menu-breadcrumbs/header-menu-breadcrumbs.component';
import { EnquiryFlightService } from 'src/app/services/enquiry-flights/enquiry-flights.service';
import { IEnquiryFlight } from 'src/app/interfaces/enquiry-flight.interface';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { getFboFullAddress, IFbo } from 'src/app/interfaces/fbo.interface';
import { FboService } from 'src/app/services/fbos/fbos.service';
import { IAirport } from 'src/app/interfaces/airport.interface';
import { AirportService } from 'src/app/services/airports/airports.service';
import { IAircraftModel } from 'src/app/interfaces/aircraft-model.interface';
import { AircraftModelService } from 'src/app/services/aircraft-models/aircraft-models.service';
import { AircraftService } from 'src/app/services/aircrafts/aircrafts.service';

interface IAircraftModelOption {
  routing: string[];
  aircraftModelId: string;
  aircraftModel: IAircraftModel;
  aircrafts: IAircraft[];
}

@Component({
  selector: 'app-flight-brief-edit',
  templateUrl: './flight-brief-edit.component.html',
  styleUrls: ['./flight-brief-edit.component.scss']
})
export class FlightBriefEditComponent implements OnInit, OnDestroy {
  @ViewChild('modalImage', { static: false }) modalImageElement: ElementRef;

  getLanguageLabel = getLanguageLabel;

  faExternalLink = faExternalLink;
  faToggleOff = faToggleOff;
  faToggleOn = faToggleOn;

  EnumEnquiryType = EnumEnquiryType;

  EnumAcl = EnumAcl;

  faTrash = faTrash;
  faPlane = faPlane;
  faCircleCheck = faCircleCheck;
  faCircle = faCircle;
  faEdit = faEdit;
  faCirclePlus = faCirclePlus;

  isLogged: boolean = false;
  form: FormGroup = this.resetForm();
  flightBrief: IFlightBrief;
  flightBriefId: string;
  enquiryId: string;
  enquiry: IEnquiry;
  currentUser: IUser | null = null;

  quotationId: string;
  quotation: IQuotation;

  subscriptions = new Subscription();
  subscriptionsForEnquiry = new Subscription();

  enquiryFlights: IEnquiryFlight[] = [];
  loadingEnquiryFlights: boolean = false;

  fbosObj: { [id: string]: IFbo | null } = {};
  airportsObj: { [id: string]: IAirport | null } = {};
  aircraftModelsObj: { [id: string]: IAircraftModel | null } = {};
  aircraftsObj: { [id: string]: IAircraft | null } = {};

  aircraftModelOptions: IAircraftModelOption[] = [];
  aircraftOptions: IAircraft[] = [];

  selectedImageField: 'imageOutside' | 'imageInside' | null = null;
  selectedImageFieldForAircraftModel: 'imageUrl' | 'imageInsideUrl' | 'imagePlanUrl' | null = null;

  constructor(
    private remoteService: RemoteService,
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private aclService: AclService,
    private enquiryService: EnquiryService,
    private flightBriefService: FlightBriefService,
    private router: Router,
    private quotationService: QuotationService,
    private breadcrumbsService: BreadcrumbsService,
    private enquiryFlightService: EnquiryFlightService,
    private loaderService: LoaderService,
    private fboService: FboService,
    private airportService: AirportService,
    private aircraftModelService: AircraftModelService,
    private aircraftService: AircraftService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
    this.remoteService.userObservable.subscribe((currentUser: IUser) => {
      this.currentUser = currentUser;
    });
  }

  ngOnInit() {
    this.form.disable();

    this.activatedRoute.params.subscribe(async () => {
      this.flightBriefId = this.activatedRoute.snapshot.paramMap.get('flightBriefId');
      this.enquiryId = this.activatedRoute.snapshot.paramMap.get('enquiryId');
      this.quotationId = this.activatedRoute.snapshot.paramMap.get('quotationId');

      if (this.enquiryId) {
        this.form.get('enquiryId').setValue(this.enquiryId);
      }

      this.setBreadcrumbsItems();

      if (this.flightBriefId) {
        await this.aclService.checkAclAccess(EnumAcl.flightBriefsEdit);
      } else if (this.quotationId) {
        await this.aclService.checkAclAccess(EnumAcl.flightBriefsGenerateFromQuotation);
      } else {
        await this.aclService.checkAclAccess(EnumAcl.flightBriefsEdit);
      }

      this.loadData();
    });
  }

  resetForm(): FormGroup {
    return new FormGroup({
      enquiryId: new FormControl(null, [Validators.required]),
      clientId: new FormControl(null),
      language: new FormControl(EnumLanguage.fr, [Validators.required]),
      versionNumber: new FormControl(1, [Validators.required]),
      comments: new FormControl(null),
      internalNote: new FormControl(null),
      googleMapStaticUrl: new FormControl(null),
      aircraftModelId: new FormControl(null),
      aircraftModelTitle: new FormControl(null),
      aircraftId: new FormControl(null),
      aircraftRegistration: new FormControl(null),
      imageOutsideUrl: new FormControl(null),
      imageInsideUrl: new FormControl(null),
      imageOutsideZoomLevel: new FormControl(1),
      imageInsideZoomLevel: new FormControl(1)
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.subscriptionsForEnquiry.unsubscribe();

    this.removeModal();
  }

  removeModal(): void {
    window['$'](this.modalImageElement.nativeElement).modal('hide');
    window['$']('body').removeClass('modal-open');
    window['$']('.modal-backdrop').remove();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  get clientId(): FormControl {
    return this.form.get('clientId') as FormControl;
  }

  get aircraftModelId(): FormControl {
    return this.form.get('aircraftModelId') as FormControl;
  }

  get aircraftModelTitle(): FormControl {
    return this.form.get('aircraftModelTitle') as FormControl;
  }

  get aircraftId(): FormControl {
    return this.form.get('aircraftId') as FormControl;
  }

  get aircraftRegistration(): FormControl {
    return this.form.get('aircraftRegistration') as FormControl;
  }

  getLanguages(): EnumLanguage[] {
    return Object.values(EnumLanguage);
  }

  async loadData(): Promise<void> {
    if (this.isLogged) {
      if (this.flightBriefId) {
        this.loadFlightBrief();
      } else if (this.quotationId) {
        this.loadPreviousFlightBriefs();
        this.loadQuotation();

        this.form.enable();
      } else {
        this.loadPreviousFlightBriefs();
        this.loadEnquiry();
        this.loadEnquiryFlights();

        this.form.enable();
      }
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  loadEnquiry(): void {
    const enquiryId: string | null = this.getEnquiryId();

    if (enquiryId) {
      this.subscriptionsForEnquiry.add(
        this.enquiryService.getFromId(enquiryId).subscribe((enquiry: IEnquiry) => {
          this.enquiry = enquiry;

          if (this.enquiry) {
            this.clientId.setValue(this.enquiry.clientId);
          }

          this.setBreadcrumbsItems();

          // this.updateTripFromConfirmedCotationsAndEnquiry();
        })
      );
    }
  }

  getEnquiryId(): string | null {
    let enquiryId: string | null = null;

    if (this.flightBrief?.enquiryId) {
      enquiryId = this.flightBrief.enquiryId;
    } else if (this.form.value.enquiryId) {
      enquiryId = this.form.value.enquiryId;
    } else if (this.enquiryId) {
      enquiryId = this.enquiryId;
    }

    return enquiryId;
  }

  loadQuotation(): void {
    if (this.quotationId) {
      this.subscriptions.add(
        this.quotationService.getFromId(this.quotationId).subscribe((quotation: IQuotation) => {
          this.quotation = quotation;

          if (this.quotation) {
            for (const field of ['enquiryId', 'language']) {
              this.form.get(field).setValue(this.quotation[field]);
            }

            this.loadEnquiry();
            this.loadEnquiryFlights();
          }

          this.form.enable();
        })
      );
    }
  }

  loadFlightBrief(): void {
    if (this.flightBriefId) {
      this.subscriptions.add(
        this.flightBriefService
          .getFromId(this.flightBriefId)
          .subscribe((flightBrief: IFlightBrief) => {
            this.flightBrief = flightBrief;

            this.setFlightBrief();
          })
      );
    }
  }

  setFlightBrief(): void {
    if (this.form && this.flightBrief) {
      this.form = this.resetForm();

      this.setBreadcrumbsItems();

      for (const field in this.form.value) {
        if (typeof this.flightBrief[field] !== 'undefined') {
          this.form.get(field).setValue(this.flightBrief[field]);
        }
      }

      this.generateGoogleMapStaticUrl();

      this.loadEnquiry();
      this.loadEnquiryFlights();

      this.assignAircraftImagesIfAvailable();
      this.assignAircraftModelImagesIfAvailable();

      this.form.enable();
    }
  }

  assignAircraftImagesIfAvailable(): void {
    let selectedAircraft: IAircraft | null = null;

    if (this.aircraftId.value && this.aircraftOptions.length) {
      for (const aircraft of this.aircraftOptions) {
        if (aircraft.id === this.aircraftId.value) {
          selectedAircraft = aircraft;
          break;
        }
      }
    }

    if (selectedAircraft) {
      for (const field of [
        'imageOutsideUrl',
        'imageOutsideZoomLevel',
        'imageInsideUrl',
        'imageInsideZoomLevel'
      ]) {
        if (!this.form.value[field] && selectedAircraft[field]) {
          this.form.get(field).setValue(selectedAircraft[field]);
        }
      }
    }
  }

  assignAircraftModelImagesIfAvailable(): void {
    const aircraftModelImageFields: string[] = ['imageUrl', 'imageInsideUrl'];
    const flightBriefImageFields: string[] = ['imageOutsideUrl', 'imageInsideUrl'];

    if (this.aircraftModelId.value && this.aircraftModelsObj[this.aircraftModelId.value]) {
      for (let i = 0; i < aircraftModelImageFields.length; i++) {
        if (
          !this.form.value[flightBriefImageFields[i]] &&
          this.aircraftModelsObj[this.aircraftModelId.value][aircraftModelImageFields[i]]
        ) {
          this.form
            .get(flightBriefImageFields[i])
            .setValue(
              this.aircraftModelsObj[this.aircraftModelId.value][aircraftModelImageFields[i]]
            );
        }
      }
    }
  }

  clearAircraftImagesValues(): void {
    for (const field of [
      'imageOutsideUrl',
      'imageOutsideZoomLevel',
      'imageInsideUrl',
      'imageInsideZoomLevel'
    ]) {
      this.form.get(field).setValue(field.match('Zoom') ? 1 : null);
    }
  }

  async submitForm(): Promise<void> {
    this.form.markAsTouched();

    if (this.form.valid) {
      this.loaderService.presentLoader();

      this.form.disable();

      let data: IFlightBrief = Object.assign({}, this.form.value);

      for (const field in data) {
        if (typeof data[field] == 'undefined') {
          data[field] = null;
        }
      }

      data.enquiryFlights = [...this.enquiryFlights];

      data.title =
        'FB.' + getDisplayedEnquiryRefTitle(this.enquiry, 'refContract') + 'V' + data.versionNumber;

      data.airportsAddressDetails = [];
      data.googleMapStaticUrlForEnquiryFlight = {};
      for (const enquiryFlight of data.enquiryFlights) {
        for (const airportField of ['depart', 'arrival']) {
          if (enquiryFlight[airportField + 'FboEnabled'] && enquiryFlight[airportField + 'FboId']) {
            data.airportsAddressDetails.push({
              airportId: this.fbosObj[enquiryFlight[airportField + 'FboId']]?.airportId,
              airport:
                this.airportsObj[this.fbosObj[enquiryFlight[airportField + 'FboId']]?.airportId] ||
                null,
              fboId: enquiryFlight[airportField + 'FboId'],
              text: getFboFullAddress(this.fbosObj[enquiryFlight[airportField + 'FboId']]),
              fbo: this.fbosObj[enquiryFlight[airportField + 'FboId']] || null
            });
          }
        }

        data.googleMapStaticUrlForEnquiryFlight[enquiryFlight.id] =
          this.getGoogleMapStaticUrl(enquiryFlight);
      }

      try {
        if (this.flightBriefId) {
          data.id = this.flightBriefId;

          this.flightBriefId = await this.flightBriefService.update(data);
        } else {
          data.createdBy = this.currentUser?.id || null;
          data.createdByFirstname = this.currentUser?.firstname || null;
          data.createdByLastname = this.currentUser?.lastname || null;
          data.createdByInitials = this.currentUser?.initials || null;
          data.createdByFullname = this.currentUser ? getUserFullname(this.currentUser) : null;

          data.sentDate = null;
          data.sentBy = null;
          data.isSent = false;

          this.flightBriefId = await this.flightBriefService.create(data);
        }

        await this.updateAircraftWithUpdatedImages();

        await this.loaderService.hideLoaderOnSuccess();

        this.redirectAfterSaving();
      } catch (err) {
        await this.loaderService.hideLoaderOnFailure(err);

        this.form.enable();

        alert(err.message);
      }
    }
  }

  async updateAircraftWithUpdatedImages(): Promise<void> {
    // if (this.form.value.aircraftId && this.aircrafts.length) {
    //   for (const aircraft of this.aircrafts) {
    //     if (aircraft.id === this.form.value.aircraftId) {
    //       if (this.form.value.imageOutsideUrl || this.form.value.imageInsideUrl) {
    //         const data: IAircraft = {
    //           id: aircraft.id
    //         } as IAircraft;
    //         if (this.form.value.imageOutsideUrl) {
    //           data.imageOutsideUrl = this.form.value.imageOutsideUrl;
    //         }
    //         if (this.form.value.imageInsideUrl) {
    //           data.imageInsideUrl = this.form.value.imageInsideUrl;
    //         }
    //         await this.aircraftService.update(data);
    //       }
    //       break;
    //     }
    //   }
    // }
  }

  redirectAfterSaving(): void {
    this.router.navigate(['/admin/flight-briefs/' + this.flightBriefId]);
  }

  async setValueToFormControl($event: {
    fields: {
      name: string;
      value: any;
    }[];
  }): Promise<void> {
    for (let field of $event.fields) {
      const nameList = field.name.split('.');
      let formControl: AbstractControl = this.form;
      for (let name of nameList) {
        formControl = formControl.get(name);
      }

      if (field.name === 'enquiryId') {
        formControl.setValue(field.value);
        formControl.markAsTouched();
        formControl.updateValueAndValidity();

        this.enquiryId = field.value;
        this.form.get('enquiryId').setValue(field.value);

        if (this.enquiryId) {
          this.loadEnquiry();
          this.loadEnquiryFlights();
        }
      } else {
        formControl.setValue(field.value);
        formControl.markAsTouched();
        formControl.updateValueAndValidity();
      }
    }
  }

  async changedLanguage(): Promise<void> {}

  loadPreviousFlightBriefs(): void {
    if (this.form.value.enquiryId) {
      this.subscriptionsForEnquiry.add(
        this.flightBriefService
          .getLastVersionForEnquiry(this.form.value.enquiryId)
          .subscribe((flightBrief: IFlightBrief) => {
            if (flightBrief) {
              this.form.get('versionNumber').setValue(flightBrief.versionNumber + 1);
            }
          })
      );
    }
  }

  generateGoogleMapStaticUrl(): void {
    this.form.get('googleMapStaticUrl').setValue(this.getGoogleMapStaticUrl());
  }

  generateGoogleMapStaticUrlForTrip(trip: IFlightBriefTrip): string | null {
    return this.getGoogleMapStaticUrl(trip);
  }

  getGoogleMapStaticUrl(leg: IFlightBriefTrip | IEnquiryFlight | null = null): string | null {
    let url: string = 'https://maps.googleapis.com/maps/api/staticmap?';
    url += 'size=300x220&scale=2';
    url += '&language=' + this.form.value.language;
    url += '&key=AIzaSyDyPoaFu7WvRZjqmSlQq0QdWA1FS_RLMQw';
    url += '&style=feature:road|visibility:off|';
    url += '&path=color:0x1c4d6bff|weight:2|geodesic:true|';

    const coordinates: string[] = [];

    if (leg) {
      for (const airportField of ['airportDepartId', 'airportArrivalId']) {
        if (leg[airportField] && this.airportsObj[leg[airportField]]) {
          coordinates.push(
            this.airportsObj[leg[airportField]].latitude +
              ',' +
              this.airportsObj[leg[airportField]].longitude
          );
        }
      }
    } else {
      if (this.enquiryFlights) {
        for (const enquiryFlight of this.enquiryFlights) {
          if (!enquiryFlight.isFerryFlight) {
            for (const airportField of ['airportDepartId', 'airportArrivalId']) {
              if (enquiryFlight[airportField] && this.airportsObj[enquiryFlight[airportField]]) {
                coordinates.push(
                  this.airportsObj[enquiryFlight[airportField]].latitude +
                    ',' +
                    this.airportsObj[enquiryFlight[airportField]].longitude
                );
              }
            }
          }
        }
      }
    }

    if (coordinates.length) {
      return url + coordinates.join('|');
    } else {
      return null;
    }
  }

  changeInputFile(fileInput: any): void {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.upload(fileInput.target.files[0], 'flightBrief');
    }
  }

  changeInputFileForAircraftModel(fileInput: any): void {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.upload(fileInput.target.files[0], 'aircraftModel');
    }
  }

  upload(file: File, target: 'flightBrief' | 'aircraftModel'): Promise<void> {
    return new Promise((resolve, reject) => {
      this.loaderService.presentLoader();

      this.form.disable();

      let folder: string = 'images';
      switch (target) {
        case 'flightBrief':
          folder = 'flight-briefs/images';
          break;
        case 'aircraftModel':
          folder = 'aircraft-models/images';
          break;
      }

      const result = this.remoteService.upload(folder, generateRandomId(), file, 'file');

      result['task']
        .snapshotChanges()
        .pipe(
          finalize(() => {
            result['ref'].getDownloadURL().subscribe(async (downloadUrl: string) => {
              switch (target) {
                case 'flightBrief':
                  this.setCurrentImageFieldValue(downloadUrl);

                  (<HTMLInputElement>document.getElementById('imageUrlInput')).value = '';
                  break;
                case 'aircraftModel':
                  if (this.selectedImageFieldForAircraftModel) {
                    await this.updateImageFieldValueForAircraftModel(
                      this.selectedImageFieldForAircraftModel,
                      downloadUrl
                    );

                    if (!this.form.get(this.selectedImageField + 'Url')?.value) {
                      this.setCurrentImageFieldValue(downloadUrl);
                    }
                  }

                  (<HTMLInputElement>document.getElementById('aircraftModelImageInput')).value = '';
                  break;
              }

              await this.loaderService.hideLoaderOnSuccess();

              this.form.enable();

              this.form.updateValueAndValidity();
            });
          })
        )
        .subscribe();
    });
  }

  setCurrentImageFieldValue(value: string | null): void {
    if (this.selectedImageField) {
      this.form.get(this.selectedImageField + 'Url').setValue(value);
    }
  }

  deleteImage(fileFieldName: string): void {
    if (confirm('Êtes-vous sûr de vouloir supprimer cette image ?')) {
      this.form.get(fileFieldName).setValue(null);
    }
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  setBreadcrumbsItems(): void {
    const breadcrumbItems: IBreadcrumbLink[] = [];

    if (this.enquiry) {
      breadcrumbItems.push({
        text: 'Dossiers',
        url: '/admin/enquiries'
      });

      breadcrumbItems.push({
        text: getEnquiryBreadcrumbTitle(this.enquiry),
        url: '/admin/enquiries/' + this.enquiry.id
      });
      breadcrumbItems.push({
        text: 'Flight Briefs',
        url: '/admin/flight-briefs/enquiry/' + this.enquiry.id
      });
    } else if (this.form.value.enquiryId) {
      breadcrumbItems.push({
        text: 'Dossiers',
        url: '/admin/enquiries'
      });

      breadcrumbItems.push({
        isPlaceholder: true
      });
      breadcrumbItems.push({
        text: 'Flight Briefs',
        url: '/admin/flight-briefs/enquiry/' + this.form.value.enquiryId
      });
    } else {
      breadcrumbItems.push({
        text: 'Flight Briefs'
      });
    }

    if (this.flightBrief) {
      breadcrumbItems.push({
        text: this.flightBrief.title,
        url: '/admin/flight-briefs/' + this.flightBrief.id
      });
      breadcrumbItems.push({
        text: 'Édition',
        url: '/admin/flight-briefs/' + this.flightBrief.id + '/edit'
      });
    } else if (this.flightBriefId) {
      breadcrumbItems.push({
        isPlaceholder: true
      });
      breadcrumbItems.push({
        text: 'Édition',
        url: '/admin/flight-briefs/' + this.flightBriefId + '/edit'
      });
    } else {
      breadcrumbItems.push({
        text: 'Ajout'
      });
    }

    this.breadcrumbsService.setManualBreadcrumbItems(breadcrumbItems);
  }

  loadEnquiryFlights(): void {
    const enquiryId: string | null = this.getEnquiryId();

    if (enquiryId) {
      this.loadingEnquiryFlights = true;

      this.subscriptions.add(
        this.enquiryFlightService
          .getAllForEnquiry(enquiryId)
          .subscribe((enquiryFlights: IEnquiryFlight[]) => {
            this.enquiryFlights = enquiryFlights;

            this.enquiryFlights.sort((a: IEnquiryFlight, b: IEnquiryFlight) => {
              if (a.departDateLocal < b.departDateLocal) {
                return -1;
              } else if (a.departDateLocal > b.departDateLocal) {
                return 1;
              } else {
                if (a.departTimeLocal < b.departTimeLocal) {
                  return -1;
                } else if (a.departTimeLocal > b.departTimeLocal) {
                  return 1;
                }
              }

              return 0;
            });

            if (!this.aircraftModelId.value) {
              for (const enquiryFlight of this.enquiryFlights) {
                if (enquiryFlight.aircraftModelId) {
                  this.aircraftModelId.setValue(enquiryFlight.aircraftModelId);
                  this.aircraftModelId.updateValueAndValidity();

                  this.updatedAircraftModelId();

                  break;
                }
              }
            }

            if (!this.aircraftId.value) {
              for (const enquiryFlight of this.enquiryFlights) {
                if (
                  this.aircraftModelId.value === enquiryFlight.aircraftModelId &&
                  enquiryFlight.aircraftId
                ) {
                  this.aircraftId.setValue(enquiryFlight.aircraftId);
                  this.aircraftId.updateValueAndValidity();

                  this.updatedAircraftId();

                  break;
                }
              }
            }

            this.refreshAircraftModelOptions();
            this.loadAircraftDetailsForEnquiryFlights();
            this.loadAirportAndFbosOfEnquiryFlights();

            this.loadingEnquiryFlights = false;
          })
      );
    }
  }

  loadAirportAndFbosOfEnquiryFlights(): void {
    for (const enquiryFlight of this.enquiryFlights) {
      for (const airportField of ['depart', 'arrival']) {
        if (enquiryFlight['airport' + capitalize(airportField) + 'Id']) {
          this.loadAirport(enquiryFlight['airport' + capitalize(airportField) + 'Id']);
        }

        if (enquiryFlight[airportField + 'FboEnabled'] && enquiryFlight[airportField + 'FboId']) {
          this.loadFbo(enquiryFlight[airportField + 'FboId']);
        }
      }
    }
  }
  u;

  loadFbo(fboId: string): void {
    if (typeof this.fbosObj[fboId] === 'undefined') {
      this.fbosObj[fboId] = null;

      this.subscriptions.add(
        this.fboService.getFromId(fboId).subscribe((fbo: IFbo) => {
          this.fbosObj[fboId] = fbo;
        })
      );
    }
  }

  loadAirport(airportId: string): void {
    if (typeof this.airportsObj[airportId] === 'undefined') {
      this.airportsObj[airportId] = null;

      this.subscriptions.add(
        this.airportService.getFromId(airportId).subscribe((airport: IAirport) => {
          this.airportsObj[airportId] = airport;
        })
      );
    }
  }

  refreshAircraftModelOptions(): void {
    this.aircraftModelOptions = [];

    const optionsByAircraftModelId: {
      [aircraftModelId: string]: {
        routing: string[];
      };
    } = {};

    for (const enquiryFlight of this.enquiryFlights) {
      if (
        enquiryFlight.aircraftModelId &&
        typeof optionsByAircraftModelId[enquiryFlight.aircraftModelId] === 'undefined'
      ) {
        optionsByAircraftModelId[enquiryFlight.aircraftModelId] = {
          routing: []
        };
      }

      optionsByAircraftModelId[enquiryFlight.aircraftModelId].routing.push(
        (enquiryFlight.airportDepartIataCode || '?') +
          ' → ' +
          enquiryFlight.airportArrivalIataCode || '?'
      );
    }

    for (const aircraftModelId in optionsByAircraftModelId) {
      const aircrafts: IAircraft[] = [];

      for (const aircraftId in this.aircraftsObj) {
        if (this.aircraftsObj[aircraftId]?.aircraftModelId === aircraftModelId) {
          aircrafts.push(this.aircraftsObj[aircraftId]);
        }
      }

      this.aircraftModelOptions.push({
        routing: optionsByAircraftModelId[aircraftModelId].routing,
        aircraftModel: this.aircraftModelsObj[aircraftModelId],
        aircraftModelId: aircraftModelId,
        aircrafts
      });
    }

    this.refreshAircraftOptions();

    this.assignAircraftImagesIfAvailable();
    this.assignAircraftModelImagesIfAvailable();
  }

  updatedAircraftModelId(updatedManually: boolean = false): void {
    if (this.aircraftModelId.value) {
      for (const aircraftModelOption of this.aircraftModelOptions) {
        if (aircraftModelOption.aircraftModelId === this.aircraftModelId.value) {
          this.aircraftModelTitle.setValue(aircraftModelOption.aircraftModel?.title);
          break;
        }
      }
    } else {
      this.aircraftModelTitle.setValue(null);
    }
    this.aircraftModelTitle.updateValueAndValidity();

    this.refreshAircraftOptions();

    if (this.aircraftOptions.length) {
      this.aircraftId.setValue(this.aircraftOptions[0].id);
    } else if (updatedManually) {
      this.aircraftId.setValue(null);
    }

    this.aircraftId.updateValueAndValidity();

    this.updatedAircraftId();

    this.assignAircraftModelImagesIfAvailable();
  }

  updatedAircraftId(): void {
    if (this.aircraftId.value) {
      this.form.get('aircraftRegistration').setValue(null);

      for (const aircraft of this.aircraftOptions) {
        if (aircraft.id === this.aircraftId.value) {
          this.aircraftRegistration.setValue(
            aircraft.registration !== '' ? aircraft.registration : 'Inconnu'
          );
          break;
        }
      }
    } else {
      this.aircraftRegistration.setValue(null);
    }

    this.aircraftRegistration.updateValueAndValidity();

    this.clearAircraftImagesValues();

    this.assignAircraftImagesIfAvailable();
  }

  refreshAircraftOptions(): void {
    this.aircraftOptions = [];

    for (const aircraftModelOption of this.aircraftModelOptions) {
      if (aircraftModelOption.aircraftModelId === this.aircraftModelId.value) {
        this.aircraftOptions = [...aircraftModelOption.aircrafts];
        break;
      }
    }
  }

  loadAircraftDetailsForEnquiryFlights(): void {
    for (const enquiryFlight of this.enquiryFlights) {
      if (enquiryFlight.aircraftModelId) {
        this.loadAircraftModel(enquiryFlight.aircraftModelId);
      }

      if (enquiryFlight.aircraftId) {
        this.loadAircraft(enquiryFlight.aircraftId);
      }
    }
  }

  loadAircraftModel(aircraftModelId: string): void {
    if (typeof this.aircraftModelsObj[aircraftModelId] === 'undefined') {
      this.aircraftModelsObj[aircraftModelId] = null;

      this.subscriptions.add(
        this.aircraftModelService
          .getFromId(aircraftModelId)
          .subscribe((aircraftModel: IAircraftModel | null) => {
            this.aircraftModelsObj[aircraftModelId] = aircraftModel;

            this.refreshAircraftModelOptions();
          })
      );
    }
  }

  loadAircraft(aircraftId: string): void {
    if (typeof this.aircraftsObj[aircraftId] === 'undefined') {
      this.aircraftsObj[aircraftId] = null;

      this.subscriptions.add(
        this.aircraftService.getFromId(aircraftId).subscribe((aircraft: IAircraft | null) => {
          this.aircraftsObj[aircraftId] = aircraft;

          this.refreshAircraftModelOptions();
        })
      );
    }
  }

  openImageModal(field: 'imageOutside' | 'imageInside'): void {
    this.selectedImageField = field;

    window['$'](this.modalImageElement.nativeElement).modal('show');
  }

  editSelectedImage(): void {
    this.triggerInputFile();
  }

  deleteSelectedImage(): void {
    this.form.get(this.selectedImageField + 'Url').setValue(null);
  }

  triggerInputFile(): void {
    if (this.form.enabled) {
      document.getElementById('imageUrlInput').click();
    }
  }

  triggerInputFileForAircraftModel(): void {
    if (this.form.enabled) {
      document.getElementById('aircraftModelImageInput').click();
    }
  }

  toggleSelectedImageValue(value: string): void {
    if (this.selectedImageField) {
      this.form
        .get(this.selectedImageField + 'Url')
        .setValue(this.form.get(this.selectedImageField + 'Url').value === value ? null : value);
    }
  }

  async editImageToAircraftModel(
    field: 'imageUrl' | 'imageInsideUrl' | 'imagePlanUrl'
  ): Promise<void> {
    this.selectedImageFieldForAircraftModel = field;

    this.triggerInputFileForAircraftModel();
  }

  async deleteImageFromAircraftModel(field: string): Promise<void> {
    if (
      this.aircraftModelId.value &&
      this.aircraftModelsObj[this.aircraftModelId.value] &&
      confirm(
        'Êtes-vous sûr de vouloir cette image du modèle ' +
          this.aircraftModelsObj[this.aircraftModelId.value].title +
          ' ?'
      )
    ) {
      await this.updateImageFieldValueForAircraftModel(field, null);
    }
  }

  async updateImageFieldValueForAircraftModel(field: string, value: string | null): Promise<void> {
    if (this.aircraftModelId.value && this.aircraftModelsObj[this.aircraftModelId.value]) {
      this.loaderService.presentLoader();

      try {
        for (const imageField of ['imageOutsideUrl', 'imageInsideUrl']) {
          if (
            this.form.get(imageField).value ===
            this.aircraftModelsObj[this.aircraftModelId.value][field]
          ) {
            this.form.get(imageField).setValue(null);
          }
        }

        const data: IAircraftModel = {
          id: this.aircraftModelId.value
        } as IAircraftModel;

        data[field] = value;

        await this.aircraftModelService.update(data);

        await this.loaderService.hideLoaderOnSuccess();
      } catch (err) {
        await this.loaderService.hideLoaderOnFailure(err.message);
      }
    }
  }
}
