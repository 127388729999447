<form (submit)="saveAndNext()" [formGroup]="form" (keydown.enter)="saveAndNext()" class="main-form">
    <div class="card mb-4">
        <div class="card-header"><fa-icon [icon]="faTicket"></fa-icon> Détails du vol</div>
        <div class="card-body">
            <div
                class="form-check form-switch d-flex justify-content-center align-items-center gap-2"
            >
                <input
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="isFerryFlight"
                    formControlName="isFerryFlight"
                />
                <label class="form-check-label" for="isFerryFlight">Vol de positionnement</label>
            </div>
            <div class="form-group">
                <label for="flightNumber">Numéro de vol</label>
                <input
                    type="text"
                    id="flightNumber"
                    formControlName="flightNumber"
                    class="form-control"
                    [class.is-invalid]="
                        (form.touched || flightNumber.touched) && flightNumber.status === 'INVALID'
                            ? 'danger'
                            : ''
                    "
                />
                <div class="invalid-tooltip">
                    <div
                        *ngIf="
                            (form.touched || flightNumber.touched) &&
                            flightNumber.errors &&
                            flightNumber.errors['required']
                        "
                    >
                        Ce champ est obligatoire.
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label for="flightradar24Url">URL flightradar24</label>
                <input
                    type="url"
                    id="flightradar24Url"
                    formControlName="flightradar24Url"
                    class="form-control"
                    [class.is-invalid]="
                        (form.touched || flightradar24Url.touched) &&
                        flightradar24Url.status === 'INVALID'
                            ? 'danger'
                            : ''
                    "
                    placeholder="https://www.flightradar24.com/"
                />
                <div class="invalid-tooltip">
                    <div
                        *ngIf="
                            (form.touched || flightradar24Url.touched) &&
                            flightradar24Url.errors &&
                            flightradar24Url.errors['required']
                        "
                    >
                        Ce champ est obligatoire.
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label for="adsbexchangeUrl">URL adsbexchange</label>
                <input
                    type="url"
                    id="adsbexchangeUrl"
                    formControlName="adsbexchangeUrl"
                    class="form-control"
                    [class.is-invalid]="
                        (form.touched || adsbexchangeUrl.touched) &&
                        adsbexchangeUrl.status === 'INVALID'
                            ? 'danger'
                            : ''
                    "
                    placeholder="https://globe.adsbexchange.com/"
                />
                <div class="invalid-tooltip">
                    <div
                        *ngIf="
                            (form.touched || adsbexchangeUrl.touched) &&
                            adsbexchangeUrl.errors &&
                            adsbexchangeUrl.errors['required']
                        "
                    >
                        Ce champ est obligatoire.
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mb-4">
        <div class="col">
            <button
                type="button"
                class="btn btn-secondary"
                [disabled]="form.disabled"
                (click)="prev()"
            >
                <fa-icon [icon]="faArrowLeft"></fa-icon>
                Précédent
            </button>
        </div>
        <div class="col text-end">
            <button
                type="button"
                class="btn btn-primary"
                [disabled]="form.disabled || !form.valid"
                (click)="saveAndNext()"
            >
                Suivant
                <fa-icon [icon]="faArrowRight"></fa-icon>
            </button>
        </div>
    </div>
</form>
