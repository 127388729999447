<div
    class="modal fade"
    id="alert-modal"
    tabindex="-1"
    aria-labelledby="alert-modal-label"
    aria-hidden="true"
>
    <div
        class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
        [class.modal-sm]="!alertOptions?.size || alertOptions?.size === EnumAlertOptionSize.small"
        [class.modal-lg]="alertOptions?.size === EnumAlertOptionSize.large"
        [class.modal-xl]="alertOptions?.size === EnumAlertOptionSize.extraLarge"
    >
        <div class="modal-content">
            <div
                class="modal-header"
                *ngIf="alertOptions?.header?.display"
                [class.justify-content-center]="!alertOptions?.header?.closeBtn"
            >
                <h1
                    class="modal-title fs-5"
                    id="alert-modal-label"
                    [innerText]="alertOptions?.header?.title || '' | translate"
                ></h1>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    *ngIf="alertOptions?.header?.closeBtn"
                ></button>
            </div>
            <div
                class="modal-body"
                [class.text-left]="alertOptions?.body?.textAlign === EnumAlertOptionTextAlign.left"
                [class.text-center]="
                    !alertOptions?.body?.textAlign ||
                    alertOptions?.body?.textAlign === EnumAlertOptionTextAlign.center
                "
                [class.text-right]="
                    alertOptions?.body?.textAlign === EnumAlertOptionTextAlign.right
                "
                [class.text-justify]="
                    alertOptions?.body?.textAlign === EnumAlertOptionTextAlign.justify
                "
                [class.justify-content-start]="
                    alertOptions?.body?.align === EnumAlertOptionFlexAlign.start
                "
                [class.justify-content-end]="
                    alertOptions?.body?.align === EnumAlertOptionFlexAlign.end
                "
                [class.justify-content-center]="
                    !alertOptions?.body?.align ||
                    alertOptions?.body?.align === EnumAlertOptionFlexAlign.center
                "
                [class.justify-content-between]="
                    alertOptions?.body?.align === EnumAlertOptionFlexAlign.between
                "
                [class.justify-content-around]="
                    alertOptions?.body?.align === EnumAlertOptionFlexAlign.around
                "
                [class.justify-content-evenly]="
                    alertOptions?.body?.align === EnumAlertOptionFlexAlign.evenly
                "
                [innerHTML]="alertOptions!.body!.text! | translate"
                *ngIf="alertOptions?.body?.text"
            ></div>
            <div
                class="modal-body"
                [class.text-left]="alertOptions?.body?.textAlign === EnumAlertOptionTextAlign.left"
                [class.text-center]="
                    !alertOptions?.body?.textAlign ||
                    alertOptions?.body?.textAlign === EnumAlertOptionTextAlign.center
                "
                [class.text-right]="
                    alertOptions?.body?.textAlign === EnumAlertOptionTextAlign.right
                "
                [class.text-justify]="
                    alertOptions?.body?.textAlign === EnumAlertOptionTextAlign.justify
                "
                [class.justify-content-start]="
                    alertOptions?.body?.align === EnumAlertOptionFlexAlign.start
                "
                [class.justify-content-end]="
                    alertOptions?.body?.align === EnumAlertOptionFlexAlign.end
                "
                [class.justify-content-center]="
                    !alertOptions?.body?.align ||
                    alertOptions?.body?.align === EnumAlertOptionFlexAlign.center
                "
                [class.justify-content-between]="
                    alertOptions?.body?.align === EnumAlertOptionFlexAlign.between
                "
                [class.justify-content-around]="
                    alertOptions?.body?.align === EnumAlertOptionFlexAlign.around
                "
                [class.justify-content-evenly]="
                    alertOptions?.body?.align === EnumAlertOptionFlexAlign.evenly
                "
                *ngIf="alertOptions?.body?.dropdownOptions"
            >
                <div class="form-group mb-0">
                    <select [(ngModel)]="dropdownValue" class="form-select">
                        <option
                            [ngValue]="null"
                            [innerText]="'GENERAL.PLEASE_SELECT_ONE' | translate"
                        ></option>
                        <option
                            *ngFor="let option of alertOptions?.body?.dropdownOptions"
                            [innerText]="option.title | translate"
                            [value]="option.value"
                        ></option>
                    </select>
                </div>
            </div>
            <div
                class="modal-footer"
                *ngIf="alertOptions?.footer?.display"
                [class.justify-content-start]="
                    alertOptions?.footer?.align === EnumAlertOptionFlexAlign.start
                "
                [class.justify-content-end]="
                    alertOptions?.footer?.align === EnumAlertOptionFlexAlign.end
                "
                [class.justify-content-center]="
                    !alertOptions?.footer?.align ||
                    alertOptions?.footer?.align === EnumAlertOptionFlexAlign.center
                "
                [class.justify-content-between]="
                    alertOptions?.footer?.align === EnumAlertOptionFlexAlign.between
                "
                [class.justify-content-around]="
                    alertOptions?.footer?.align === EnumAlertOptionFlexAlign.around
                "
                [class.justify-content-evenly]="
                    alertOptions?.footer?.align === EnumAlertOptionFlexAlign.evenly
                "
            >
                <button
                    type="button"
                    class="btn {{ button.btnClass ?? 'btn-primary' }}"
                    *ngFor="let button of alertOptions?.footer?.buttons"
                    [attr.data-bs-dismiss]="button.closeModal ? 'modal' : ''"
                    (click)="clickFooterBtn(button)"
                >
                    <fa-icon [icon]="button.iconStart" *ngIf="button.iconStart"></fa-icon>
                    <span [innerText]="button.text | translate" *ngIf="button.text"></span>
                    <fa-icon [icon]="button.iconEnd" *ngIf="button.iconEnd"></fa-icon>
                </button>
            </div>
        </div>
    </div>
</div>
