import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import firebase from 'firebase/compat/app';
import { FlightChecklistFirestoreService } from './flight-checklists-firestore.service';
import { IFlightChecklist } from 'src/app/interfaces/flight-checklist.interface';
import { EnumEnquiryType } from 'src/app/enums/enquiry-type.enum';
import { IFindCondition } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class FlightChecklistService {
  constructor(private flightChecklistFirestoreService: FlightChecklistFirestoreService) {}

  create(flightChecklist: IFlightChecklist): Promise<string> {
    return this.flightChecklistFirestoreService.create(flightChecklist);
  }

  createWithId(id: string, flightChecklist: IFlightChecklist): Promise<string> {
    return this.flightChecklistFirestoreService.createWithId(id, flightChecklist);
  }

  update(flightChecklist: IFlightChecklist): Promise<string> {
    return this.flightChecklistFirestoreService.update(flightChecklist);
  }

  delete(id: string): Promise<void> {
    return this.flightChecklistFirestoreService.delete(id);
  }

  getFromId(id: string): Observable<IFlightChecklist> {
    return this.flightChecklistFirestoreService.docValue$(id);
  }

  getForFlight(enquiryFlightId: string): Observable<IFlightChecklist> {
    return this.flightChecklistFirestoreService
      .collectionValues$(ref => ref.where('enquiryFlightId', '==', enquiryFlightId))
      .pipe(
        map(results => {
          if (results && results.length) {
            for (const fieldData of results[0].fieldData) {
              for (let item of fieldData.history) {
                item = this.flightChecklistFirestoreService.timestampsToDate(item);
              }
            }

            return results[0];
          }

          return null;
        })
      );
  }

  getForEnquiry(enquiryId: string): Observable<IFlightChecklist[]> {
    return this.flightChecklistFirestoreService
      .collectionValues$(ref => ref.where('enquiryId', '==', enquiryId))
      .pipe(
        map(results => {
          if (results && results.length) {
            for (const item of results) {
              for (const fieldData of item.fieldData) {
                for (let i = 0; i < fieldData.history.length; i++) {
                  fieldData.history[i] = this.flightChecklistFirestoreService.timestampsToDate(
                    fieldData.history[i]
                  );
                }
              }
            }

            return results;
          }

          return [];
        })
      );
  }

  getAll(): Observable<IFlightChecklist[]> {
    return this.flightChecklistFirestoreService.collectionValues$();
  }

  resetPagination(paginationName: string = 'list'): void {
    this.flightChecklistFirestoreService.resetPaginate(paginationName);
  }

  getPaginatedResult(
    paginationName: string = 'list',
    conditions: IFindCondition[] = [],
    orderBy: {
      field: string;
      direction: firebase.firestore.OrderByDirection;
    } = {
      field: 'created',
      direction: 'desc'
    },
    itemsPerPage: number = 20,
    direction: 'prev' | 'next' = 'next'
  ): Observable<IFlightChecklist[]> {
    return this.flightChecklistFirestoreService.paginate(
      conditions,
      orderBy,
      itemsPerPage,
      paginationName,
      direction
    );
  }

  duplicateForEnquiryFlight(
    sourceEnquiryFlightId: string,
    targetEnquiryFlightId: string
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      this.getForFlight(sourceEnquiryFlightId).subscribe(
        async (flightChecklist: IFlightChecklist) => {
          if (flightChecklist) {
            flightChecklist.enquiryFlightId = targetEnquiryFlightId;

            await this.flightChecklistFirestoreService.create(flightChecklist);
          }

          resolve();
        }
      );
    });
  }
}
