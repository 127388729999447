import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';
import { EnumAcl } from 'src/app/enums/acl.enum';
import {
  EnumFlightChecklistTemplateFieldOptionStatus,
  EnumFlightChecklistTemplateFieldType,
  IFlightChecklistTemplate,
  IFlightChecklistTemplateField,
  IFlightChecklistTemplateFieldOption,
  IFlightChecklistTemplateModule,
  getFlightChecklistTemplateFieldOptionStatusLabel,
  getFlightChecklistTemplateFieldTypeLabel
} from 'src/app/interfaces/flight-checklist-template.interface';
import { Subscription } from 'rxjs';
import { FlightChecklistTemplateService } from 'src/app/services/flight-checklist-templates/flight-checklist-templates.service';
import { EnumEnquiryType, getEnumEnquiryTypeLabel } from 'src/app/enums/enquiry-type.enum';
import { faArrowDown, faArrowUp, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-flight-checklist-template-edit',
  templateUrl: './flight-checklist-template-edit.component.html',
  styleUrls: ['./flight-checklist-template-edit.component.scss']
})
export class FlightChecklistTemplateEditComponent implements OnInit, OnDestroy {
  EnumAcl = EnumAcl;
  EnumFlightChecklistTemplateFieldType = EnumFlightChecklistTemplateFieldType;
  EnumFlightChecklistTemplateFieldOptionStatus = EnumFlightChecklistTemplateFieldOptionStatus;

  getEnumEnquiryTypeLabel = getEnumEnquiryTypeLabel;
  getFlightChecklistTemplateFieldTypeLabel = getFlightChecklistTemplateFieldTypeLabel;
  getFlightChecklistTemplateFieldOptionStatusLabel =
    getFlightChecklistTemplateFieldOptionStatusLabel;

  faTrash = faTrash;
  faPlus = faPlus;
  faArrowUp = faArrowUp;
  faArrowDown = faArrowDown;

  isLogged: boolean = false;
  form: FormGroup = this.resetForm();
  sending: boolean = false;
  flightChecklistTemplate: IFlightChecklistTemplate;
  flightChecklistTemplateId: string;

  subscriptions = new Subscription();

  constructor(
    private remoteService: RemoteService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private aclService: AclService,
    private flightChecklistTemplateService: FlightChecklistTemplateService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
  }

  get enquiryType(): FormControl {
    return this.form.get('enquiryType') as FormControl;
  }

  get modules(): FormArray {
    return this.form.get('modules') as FormArray;
  }

  getModule(i: number): FormGroup {
    return this.modules.at(i) as FormGroup;
  }

  getModuleTitle(i: number): FormControl {
    return this.getModule(i).get('title') as FormControl;
  }

  getModuleFields(i: number): FormArray {
    return this.getModule(i).get('fields') as FormArray;
  }

  getModuleField(i: number, j: number): FormGroup {
    return this.getModuleFields(i).at(j) as FormGroup;
  }

  getModuleFieldId(i: number, j: number): FormControl {
    return this.getModuleField(i, j).get('id') as FormControl;
  }

  getModuleFieldText(i: number, j: number): FormControl {
    return this.getModuleField(i, j).get('text') as FormControl;
  }

  getModuleFieldType(i: number, j: number): FormControl {
    return this.getModuleField(i, j).get('type') as FormControl;
  }

  getModuleFieldOptions(i: number, j: number): FormArray {
    return this.getModuleField(i, j).get('options') as FormArray;
  }

  getModuleFieldDefaultOptionId(i: number, j: number): FormControl {
    return this.getModuleField(i, j).get('defaultOptionId') as FormControl;
  }

  getModuleFieldFinalOptionId(i: number, j: number): FormControl {
    return this.getModuleField(i, j).get('finalOptionId') as FormControl;
  }

  getModuleFieldOption(i: number, j: number, k: number): FormGroup {
    return this.getModuleFieldOptions(i, j).at(k) as FormGroup;
  }

  getModuleFieldOptionField(i: number, j: number, k: number, field: string): FormControl {
    return this.getModuleFieldOption(i, j, k).get(field) as FormControl;
  }

  ngOnInit() {
    this.form.disable();

    this.activatedRoute.url.subscribe(async () => {
      this.flightChecklistTemplateId = this.activatedRoute.snapshot.paramMap.get(
        'flightChecklistTemplateId'
      );

      if (this.flightChecklistTemplateId) {
        await this.aclService.checkAclAccess(EnumAcl.flightChecklistTemplatesEdit);
        this.loadData();
      } else {
        await this.aclService.checkAclAccess(EnumAcl.flightChecklistTemplatesAdd);
        this.form.enable();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getEnquiryTypes(): EnumEnquiryType[] {
    return Object.values(EnumEnquiryType);
  }

  get fieldTypes(): EnumFlightChecklistTemplateFieldType[] {
    return Object.values(EnumFlightChecklistTemplateFieldType);
  }

  get optionStatuses(): EnumFlightChecklistTemplateFieldOptionStatus[] {
    return Object.values(EnumFlightChecklistTemplateFieldOptionStatus);
  }

  loadData(): void {
    if (this.isLogged) {
      this.loadFlightChecklistTemplate();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  loadFlightChecklistTemplate(): void {
    this.subscriptions.add(
      this.flightChecklistTemplateService
        .getFromId(this.flightChecklistTemplateId)
        .subscribe((flightChecklistTemplate: IFlightChecklistTemplate) => {
          this.flightChecklistTemplate = flightChecklistTemplate;

          this.setFlightChecklistTemplate();
        })
    );
  }

  setFlightChecklistTemplate(): void {
    if (this.form && this.flightChecklistTemplate) {
      this.form = this.resetForm();

      for (const field in this.form.value) {
        if (typeof this.flightChecklistTemplate[field] !== 'undefined') {
          switch (field) {
            case 'modules':
              if (this.flightChecklistTemplate.modules) {
                for (const module of this.flightChecklistTemplate.modules) {
                  this.addModuleAndSet(module);
                }
              }
              break;
            default:
              this.form.get(field).setValue(this.flightChecklistTemplate[field]);
              break;
          }
        }
      }

      this.form.enable();
    }
  }

  submitForm(): void {
    this.form.markAsTouched();

    if (this.form.valid) {
      let data = Object.assign({}, this.form.value);

      for (const field in data) {
        if (typeof data[field] == 'undefined') {
          data[field] = null;
        }
      }

      this.sending = true;

      this.form.disable();

      let promise;
      if (this.flightChecklistTemplateId) {
        data.id = this.flightChecklistTemplateId;
        promise = () => this.flightChecklistTemplateService.update(data);
      } else {
        promise = () => this.flightChecklistTemplateService.create(data);
      }

      promise()
        .then(async id => {
          if (!this.flightChecklistTemplateId) {
            this.flightChecklistTemplateId = id;
          }

          this.form.reset();
          this.form.enable();
          this.sending = false;
          this.redirectAfterSaving();
        })
        .catch(err => {
          console.log(err);
          this.sending = false;

          alert(err.message);
        })
        .finally(() => {
          this.form.enable();
        });
    }
  }

  redirectAfterSaving(): void {
    this.router.navigate(['/admin/flight-checklist-templates/' + this.flightChecklistTemplateId]);
  }

  addModule(): void {
    this.modules.push(
      new FormGroup({
        title: new FormControl(null, Validators.required),
        fields: new FormArray([])
      })
    );
  }

  removeModule(i: number): void {
    this.modules.removeAt(i);
  }

  addModuleAndSet(module: IFlightChecklistTemplateModule): void {
    this.addModule();

    const i: number = this.modules.length - 1;

    for (const field in module) {
      switch (field) {
        case 'title':
          this.getModuleTitle(i).setValue(module.title);
          break;
        case 'fields':
          if (module.fields) {
            for (const field of module.fields) {
              this.addModuleFieldAndSet(i, field);
            }
          }
          break;
      }
    }
  }

  addModuleField(i: number): void {
    this.getModuleFields(i).push(
      new FormGroup({
        id: new FormControl(this.remoteService.generateRandomId(), Validators.required),
        text: new FormControl(null, Validators.required),
        type: new FormControl(EnumFlightChecklistTemplateFieldType.select, Validators.required),
        options: new FormArray([]),
        defaultOptionId: new FormControl(null),
        finalOptionId: new FormControl(null)
      })
    );
  }

  removeModuleField(i: number, j: number): void {
    this.getModuleFields(i).removeAt(j);
  }

  addModuleFieldAndSet(i: number, moduleField: IFlightChecklistTemplateField): void {
    this.addModuleField(i);

    const j: number = this.getModuleFields(i).length - 1;

    for (const field in moduleField) {
      switch (field) {
        case 'id':
          this.getModuleFieldId(i, j).setValue(moduleField.id);
          break;
        case 'text':
          this.getModuleFieldText(i, j).setValue(moduleField.text);
          break;
        case 'type':
          this.getModuleFieldType(i, j).setValue(moduleField.type);
          break;
        case 'defaultOptionId':
          this.getModuleFieldDefaultOptionId(i, j).setValue(moduleField.defaultOptionId);
          break;
        case 'finalOptionId':
          this.getModuleFieldFinalOptionId(i, j).setValue(moduleField.finalOptionId);
          break;
        case 'options':
          if (moduleField.options) {
            for (const option of moduleField.options) {
              this.addModuleFieldOptionAndSet(i, j, option);
            }
          }
          break;
      }
    }
  }

  addModuleFieldOption(i: number, j: number): void {
    this.getModuleFieldOptions(i, j).push(
      new FormGroup({
        id: new FormControl(this.remoteService.generateRandomId(), Validators.required),
        text: new FormControl(null, Validators.required),
        status: new FormControl(
          EnumFlightChecklistTemplateFieldOptionStatus.none,
          Validators.required
        )
      })
    );
  }

  removeModuleFieldOption(i: number, j: number, k: number): void {
    this.getModuleFieldOptions(i, j).removeAt(k);
  }

  addModuleFieldOptionAndSet(
    i: number,
    j: number,
    option: IFlightChecklistTemplateFieldOption
  ): void {
    this.addModuleFieldOption(i, j);

    const k: number = this.getModuleFieldOptions(i, j).length - 1;

    for (const field in option) {
      this.getModuleFieldOptionField(i, j, k, field).setValue(option[field]);
      this.getModuleFieldOptionField(i, j, k, field).updateValueAndValidity();
    }
  }

  resetForm(): FormGroup {
    return new FormGroup({
      enquiryType: new FormControl(null, [Validators.required]),
      modules: new FormArray([])
    });
  }

  moveModule(currentIndex: number, newIndex: number): void {
    const currentGroup: FormGroup = this.getModule(currentIndex);
    this.modules.removeAt(currentIndex);
    this.modules.insert(newIndex, currentGroup);
  }

  moveModuleUp(i: number): void {
    this.moveModule(i, i - 1);
  }

  moveModuleDown(i: number): void {
    this.moveModule(i, i + 1);
  }

  moveModuleField(moduleIndex: number, currentIndex: number, newIndex: number): void {
    const currentGroup: FormGroup = this.getModuleField(moduleIndex, currentIndex);
    this.getModuleFields(moduleIndex).removeAt(currentIndex);
    this.getModuleFields(moduleIndex).insert(newIndex, currentGroup);
  }

  moveModuleFieldUp(moduleIndex: number, i: number): void {
    this.moveModuleField(moduleIndex, i, i - 1);
  }

  moveModuleFieldDown(moduleIndex: number, i: number): void {
    this.moveModuleField(moduleIndex, i, i + 1);
  }
}
