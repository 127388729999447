<div class="row justify-content-center" *ngIf="!savingInvoice">
    <div class="col-md-6">
        <div class="card mb-4">
            <div class="card-header">Facture PDF</div>
            <div class="card-body">
                <div class="form-group text-center mb-0" *ngIf="!document?.url">
                    <input
                        type="file"
                        #inputFile
                        class="form-control"
                        accept="application/pdf"
                        (change)="changeInputFile($event)"
                        [disabled]="uploading"
                    />

                    <div class="text-center mt-4" *ngIf="uploading">
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden">Envoi au serveur...</span>
                        </div>
                        <br />
                        Envoi au serveur...
                    </div>
                </div>

                <div class="text-center" *ngIf="document?.url">
                    <button type="button" class="btn btn-danger mb-4" (click)="removeDocument()">
                        Suppression du document
                    </button>
                    <div class="pdf-embed-view" *ngIf="document?.url" #pdfViewerEmbed></div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6" *ngIf="document?.url">
        <form (submit)="saveReaderSettings()" [formGroup]="form" class="main-form" *ngIf="form">
            <div class="card mb-4">
                <div class="card-header">Paramètres liseur de PDF</div>
                <div class="card-body">
                    <div class="text-center" *ngIf="loadingPdfParser">
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden">Analyse du PDF en cours...</span>
                        </div>
                        <br />
                        Analyse du PDF en cours...
                    </div>
                    <div *ngIf="!loadingPdfParser">
                        <div class="form-group">
                            <select
                                class="form-select"
                                id="pdfParserSettingId"
                                [(ngModel)]="pdfParserSettingId"
                                [ngModelOptions]="{ standalone: true }"
                                (change)="updatePdfParserSettingId()"
                            >
                                <option [ngValue]="null">-- Nouveaux paramètres --</option>
                                <option
                                    [value]="pdfParserSetting.id"
                                    *ngFor="let pdfParserSetting of pdfParserSettings"
                                    [innerText]="pdfParserSetting.name"
                                ></option>
                            </select>
                        </div>

                        <div class="form-group" formGroupName="identifierText">
                            <label for="identifierText-matchingText"
                                >Texte unique sur la facture

                                <fa-icon
                                    [icon]="faCircleQuestion"
                                    rel="tooltip"
                                    data-bs-placement="bottom"
                                    data-bs-title="Cela permettra de reconnaitre les factures futures de la même entreprise. Par exemple : si le fichier PDF contient le mot 'Amazon', le PDF sera reconnu comme une facture de l'entreprise Amazon. <br><br>Pour être plus efficace, vous pouvez utiliser un identifiant de l'entreprise présent sur la facture tel que son siret."
                                ></fa-icon>
                            </label>
                            <input
                                class="form-control"
                                id="identifierText-matchingText"
                                formControlName="matchingText"
                                type="text"
                                [class.is-invalid]="
                                    getSelectorField('identifierText', 'matchingText').status ==
                                    'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                                (keyup)="updatedMatchingText('identifierText')"
                            />
                            <div
                                class="invalid-tooltip"
                                *ngIf="getSelectorField('identifierText', 'matchingText').errors"
                            >
                                <div
                                    *ngIf="
                                        getSelectorField('identifierText', 'matchingText').errors[
                                            'required'
                                        ]
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>

                                <div
                                    *ngIf="
                                        getSelectorField('identifierText', 'matchingText').errors[
                                            'notMatched'
                                        ]
                                    "
                                >
                                    Aucune correspondance trouvée dans le PDF.
                                </div>
                            </div>
                            <div
                                class="text-success mt-2"
                                *ngIf="getSelectorField('identifierText', 'nbOfHits').value > 0"
                            >
                                Présent dans le PDF
                                <strong
                                    >{{
                                        getSelectorField("identifierText", "nbOfHits").value
                                    }}
                                    fois</strong
                                >.
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="language">Langue de la facture</label>
                                    <select
                                        class="form-select"
                                        id="language"
                                        formControlName="language"
                                        [class.is-invalid]="
                                            (form.touched || form.controls['language'].touched) &&
                                            form.controls['language'].status == 'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                    >
                                        <option
                                            [value]="language"
                                            *ngFor="let language of getLanguages()"
                                            [innerHtml]="getInvoiceLanguageLabel(language)"
                                        ></option>
                                    </select>

                                    <div class="invalid-tooltip">
                                        <div
                                            *ngIf="
                                                (form.touched ||
                                                    form.controls['language'].touched) &&
                                                form.controls['language'].errors &&
                                                form.controls['language'].errors['required']
                                            "
                                        >
                                            Ce champ est obligatoire.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="currency">Devise</label>
                                    <select
                                        class="form-select"
                                        id="currency"
                                        formControlName="currency"
                                        [class.is-invalid]="
                                            (form.touched || form.controls['currency'].touched) &&
                                            form.controls['currency'].status == 'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                    >
                                        <option
                                            [value]="currency"
                                            *ngFor="let currency of getCurrencies()"
                                            [innerHtml]="
                                                getEnumCurrencySymbol(currency) +
                                                ' (' +
                                                getEnumCurrencyLabel(currency) +
                                                ')'
                                            "
                                        ></option>
                                    </select>

                                    <div class="invalid-tooltip">
                                        <div
                                            *ngIf="
                                                (form.touched ||
                                                    form.controls['currency'].touched) &&
                                                form.controls['currency'].errors &&
                                                form.controls['currency'].errors['required']
                                            "
                                        >
                                            Ce champ est obligatoire.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="accordion mb-4" id="accordion-parser-settings">
                            <div
                                class="accordion-item"
                                *ngFor="let selectorField of selectorFields"
                            >
                                <h2 class="accordion-header">
                                    <button
                                        class="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        [attr.data-bs-target]="'#field-' + selectorField.value"
                                        aria-expanded="false"
                                        [attr.aria-controls]="'field-' + selectorField.value"
                                    >
                                        <div class="accordion-button-inner">
                                            {{ selectorField.title }}
                                            <ng-container
                                                *ngIf="
                                                    ![
                                                        'countryCode',
                                                        'productsColumnsConfig'
                                                    ].includes(selectorField.value)
                                                "
                                            >
                                                <strong
                                                    *ngIf="
                                                        getSelectorField(
                                                            selectorField.value,
                                                            'result'
                                                        ).value
                                                    "
                                                    [innerText]="
                                                        selectorField.value === 'dueDate' &&
                                                        !getSelectorField(
                                                            selectorField.value,
                                                            'isValueInPdf'
                                                        ).value &&
                                                        getSelectorField(
                                                            selectorField.value,
                                                            'isValueInDays'
                                                        ).value
                                                            ? getSelectorField(
                                                                  selectorField.value,
                                                                  'result'
                                                              ).value + ' jours plus tard'
                                                            : getSelectorField(
                                                                  selectorField.value,
                                                                  'result'
                                                              ).value
                                                    "
                                                ></strong>
                                                <div
                                                    class="text-muted"
                                                    *ngIf="
                                                        !getSelectorField(
                                                            selectorField.value,
                                                            'result'
                                                        ).value
                                                    "
                                                >
                                                    Information inconnue
                                                </div>
                                            </ng-container>
                                            <ng-container
                                                *ngIf="selectorField.value === 'countryCode'"
                                            >
                                                <strong
                                                    *ngIf="
                                                        getSelectorField(
                                                            selectorField.value,
                                                            'result'
                                                        ).value
                                                    "
                                                    [innerText]="
                                                        countries[
                                                            getSelectorField(
                                                                selectorField.value,
                                                                'result'
                                                            ).value
                                                        ]
                                                    "
                                                ></strong>
                                            </ng-container>
                                            <ng-container
                                                *ngIf="
                                                    selectorField.value === 'productsColumnsConfig'
                                                "
                                            >
                                                <strong
                                                    >{{
                                                        temporaryProductsParsed.rows.length ?? 0
                                                    }}
                                                    éléments détéctés.</strong
                                                >
                                            </ng-container>
                                        </div>
                                        <fa-icon
                                            [icon]="faCheckCircle"
                                            *ngIf="getSelector(selectorField.value).valid"
                                            class="valid-indicator text-success"
                                        ></fa-icon>
                                        <fa-icon
                                            [icon]="faCircleXmark"
                                            *ngIf="!getSelector(selectorField.value).valid"
                                            class="valid-indicator text-danger"
                                        ></fa-icon>
                                    </button>
                                </h2>
                                <div
                                    [id]="'field-' + selectorField.value"
                                    class="accordion-collapse collapse"
                                    data-bs-parent="#accordion-parser-settings"
                                >
                                    <div
                                        class="accordion-body"
                                        [formGroupName]="selectorField.value"
                                    >
                                        <ng-container
                                            *ngIf="
                                                !['countryCode', 'productsColumnsConfig'].includes(
                                                    selectorField.value
                                                )
                                            "
                                        >
                                            <ng-container *ngIf="!selectorField.hideIsValueInPdf">
                                                <div
                                                    class="form-check"
                                                    *ngFor="let value of [false, true]"
                                                >
                                                    <input
                                                        type="radio"
                                                        class="form-check-input"
                                                        formControlName="isValueInPdf"
                                                        [id]="
                                                            selectorField.value +
                                                            '-isValueInPdf-' +
                                                            value
                                                        "
                                                        [value]="value"
                                                        autocomplete="off"
                                                        (change)="
                                                            updatedIsValueInPdf(selectorField.value)
                                                        "
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        [attr.for]="
                                                            selectorField.value +
                                                            '-isValueInPdf-' +
                                                            value
                                                        "
                                                        [innerText]="
                                                            value
                                                                ? 'L\'information est dans le pdf'
                                                                : 'Je connais l\'information'
                                                        "
                                                    >
                                                    </label>
                                                </div>
                                            </ng-container>

                                            <ng-container
                                                *ngIf="
                                                    selectorField.value === 'dueDate' &&
                                                    !getSelectorField(
                                                        selectorField.value,
                                                        'isValueInPdf'
                                                    )?.value
                                                "
                                            >
                                                <div class="form-check">
                                                    <input
                                                        type="checkbox"
                                                        class="form-check-input"
                                                        formControlName="isValueInDays"
                                                        [id]="
                                                            selectorField.value + '-isValueInDays'
                                                        "
                                                        (change)="
                                                            updatedIsValueInDays(
                                                                selectorField.value
                                                            )
                                                        "
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        [attr.for]="
                                                            selectorField.value + '-isValueInDays'
                                                        "
                                                        >Saisie manuel en nombre de jours
                                                    </label>
                                                </div>
                                            </ng-container>

                                            <ng-container
                                                *ngIf="
                                                    getSelectorField(
                                                        selectorField.value,
                                                        'isValueInPdf'
                                                    )?.value
                                                "
                                            >
                                                <div class="form-group">
                                                    <label
                                                        [attr.for]="
                                                            selectorField.value + '-matchingText'
                                                        "
                                                        >Texte à rechercher
                                                        <fa-icon
                                                            [icon]="faCircleQuestion"
                                                            rel="tooltip"
                                                            data-bs-placement="bottom"
                                                            data-bs-title="Texte à rechercher dans le PDF pour trouver l'information."
                                                        ></fa-icon
                                                    ></label>
                                                    <input
                                                        class="form-control"
                                                        [id]="selectorField.value + '-matchingText'"
                                                        formControlName="matchingText"
                                                        type="text"
                                                        [class.is-invalid]="
                                                            getSelectorField(
                                                                selectorField.value,
                                                                'matchingText'
                                                            ).status == 'INVALID'
                                                                ? 'danger'
                                                                : ''
                                                        "
                                                        (keyup)="
                                                            updatedMatchingText(selectorField.value)
                                                        "
                                                    />

                                                    <div
                                                        class="invalid-tooltip"
                                                        *ngIf="
                                                            getSelectorField(
                                                                selectorField.value,
                                                                'matchingText'
                                                            ).errors
                                                        "
                                                    >
                                                        <div
                                                            *ngIf="
                                                                getSelectorField(
                                                                    selectorField.value,
                                                                    'matchingText'
                                                                ).errors['required']
                                                            "
                                                        >
                                                            Ce champ est obligatoire.
                                                        </div>
                                                        <div
                                                            *ngIf="
                                                                getSelectorField(
                                                                    selectorField.value,
                                                                    'matchingText'
                                                                ).errors['notMatched']
                                                            "
                                                        >
                                                            Aucune correspondance trouvée dans le
                                                            PDF.
                                                        </div>
                                                    </div>

                                                    <div
                                                        class="text-success mt-2"
                                                        *ngIf="
                                                            getSelectorField(
                                                                selectorField.value,
                                                                'nbOfHits'
                                                            ).value > 0
                                                        "
                                                    >
                                                        Présent dans le PDF
                                                        <strong
                                                            >{{
                                                                getSelectorField(
                                                                    selectorField.value,
                                                                    "nbOfHits"
                                                                ).value
                                                            }}
                                                            fois</strong
                                                        >.
                                                        <div
                                                            *ngIf="
                                                                getSelectorField(
                                                                    selectorField.value,
                                                                    'nbOfHits'
                                                                ).value > 1
                                                            "
                                                        >
                                                            Seule la première occurence est
                                                            utilisée.
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group">
                                                    <label
                                                        [attr.for]="
                                                            selectorField.value +
                                                            '-valueSearchedPosition'
                                                        "
                                                        >Position de la valeur par rapport au texte
                                                        recherché
                                                    </label>
                                                    <select
                                                        class="form-select"
                                                        [id]="
                                                            selectorField.value +
                                                            '-valueSearchedPosition'
                                                        "
                                                        formControlName="valueSearchedPosition"
                                                        [class.is-invalid]="
                                                            getSelectorField(
                                                                selectorField.value,
                                                                'valueSearchedPosition'
                                                            ).status == 'INVALID'
                                                                ? 'danger'
                                                                : ''
                                                        "
                                                        (change)="
                                                            updatedMatchingText(selectorField.value)
                                                        "
                                                    >
                                                        <option
                                                            *ngFor="
                                                                let position of getPdfParserValueSearchedPositions()
                                                            "
                                                            [innerText]="
                                                                getPdfParserValueSearchedPositionLabel(
                                                                    position
                                                                )
                                                            "
                                                            [value]="position"
                                                        ></option>
                                                    </select>

                                                    <div
                                                        class="invalid-tooltip"
                                                        *ngIf="
                                                            getSelectorField(
                                                                selectorField.value,
                                                                'valueSearchedPosition'
                                                            ).errors
                                                        "
                                                    >
                                                        <div
                                                            *ngIf="
                                                                getSelectorField(
                                                                    selectorField.value,
                                                                    'valueSearchedPosition'
                                                                ).errors['required']
                                                            "
                                                        >
                                                            Ce champ est obligatoire.
                                                        </div>
                                                    </div>
                                                </div>

                                                <ul class="list-group mb-4" formArrayName="rules">
                                                    <li
                                                        class="list-group-item"
                                                        [formGroupName]="i"
                                                        *ngFor="
                                                            let ruleField of getSelectorRules(
                                                                selectorField.value
                                                            )['controls'];
                                                            let i = index
                                                        "
                                                    >
                                                        <strong>Règle {{ i + 1 }}</strong>

                                                        <button
                                                            type="button"
                                                            (click)="
                                                                deleteRule(selectorField.value, i)
                                                            "
                                                            class="btn btn-danger btn-sm float-end"
                                                            [disabled]="form.disabled"
                                                        >
                                                            X
                                                        </button>

                                                        <div class="clearfix"></div>

                                                        <div class="form-group">
                                                            <label
                                                                [attr.for]="
                                                                    selectorField.value +
                                                                    '-rule-' +
                                                                    i +
                                                                    '-matchingText'
                                                                "
                                                                >Texte à rechercher</label
                                                            >
                                                            <input
                                                                class="form-control"
                                                                [id]="
                                                                    selectorField.value +
                                                                    '-rule-' +
                                                                    i +
                                                                    '-matchingText'
                                                                "
                                                                formControlName="matchingText"
                                                                type="text"
                                                                [class.is-invalid]="
                                                                    (form.touched ||
                                                                        getSelectorRuleField(
                                                                            selectorField.value,
                                                                            i,
                                                                            'matchingText'
                                                                        ).touched) &&
                                                                    getSelectorRuleField(
                                                                        selectorField.value,
                                                                        i,
                                                                        'matchingText'
                                                                    ).status == 'INVALID'
                                                                        ? 'danger'
                                                                        : ''
                                                                "
                                                                (keyup)="
                                                                    updatedMatchingText(
                                                                        selectorField.value
                                                                    )
                                                                "
                                                            />

                                                            <div class="invalid-tooltip">
                                                                <div
                                                                    *ngIf="
                                                                        (form.touched ||
                                                                            getSelectorRuleField(
                                                                                selectorField.value,
                                                                                i,
                                                                                'matchingText'
                                                                            ).touched) &&
                                                                        getSelectorRuleField(
                                                                            selectorField.value,
                                                                            i,
                                                                            'matchingText'
                                                                        ).errors &&
                                                                        getSelectorRuleField(
                                                                            selectorField.value,
                                                                            i,
                                                                            'matchingText'
                                                                        ).errors['required']
                                                                    "
                                                                >
                                                                    Ce champ est obligatoire.
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="form-group">
                                                            <select
                                                                class="form-select"
                                                                [id]="
                                                                    selectorField.value +
                                                                    '-rule-' +
                                                                    i +
                                                                    '-ruleType'
                                                                "
                                                                formControlName="ruleType"
                                                                [class.is-invalid]="
                                                                    (form.touched ||
                                                                        getSelectorRuleField(
                                                                            selectorField.value,
                                                                            i,
                                                                            'ruleType'
                                                                        ).touched) &&
                                                                    getSelectorRuleField(
                                                                        selectorField.value,
                                                                        i,
                                                                        'ruleType'
                                                                    ).status == 'INVALID'
                                                                        ? 'danger'
                                                                        : ''
                                                                "
                                                                (change)="
                                                                    updatedRuleType(
                                                                        selectorField.value,
                                                                        i
                                                                    )
                                                                "
                                                            >
                                                                <option
                                                                    [value]="ruleType"
                                                                    *ngFor="
                                                                        let ruleType of getRuleTypes()
                                                                    "
                                                                    [innerText]="
                                                                        getPdfParserRuleByTypeLabel(
                                                                            ruleType
                                                                        )
                                                                    "
                                                                ></option>
                                                            </select>

                                                            <div class="invalid-tooltip">
                                                                <div
                                                                    *ngIf="
                                                                        (form.touched ||
                                                                            getSelectorRuleField(
                                                                                selectorField.value,
                                                                                i,
                                                                                'ruleType'
                                                                            ).touched) &&
                                                                        getSelectorRuleField(
                                                                            selectorField.value,
                                                                            i,
                                                                            'ruleType'
                                                                        ).errors &&
                                                                        getSelectorRuleField(
                                                                            selectorField.value,
                                                                            i,
                                                                            'ruleType'
                                                                        ).errors['required']
                                                                    "
                                                                >
                                                                    Ce champ est obligatoire.
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div
                                                            class="form-group"
                                                            *ngIf="
                                                                getSelectorRuleField(
                                                                    selectorField.value,
                                                                    i,
                                                                    'ruleType'
                                                                ).value ===
                                                                EnumPdfParserRuleType.replaceByText
                                                            "
                                                        >
                                                            <label
                                                                [attr.for]="
                                                                    selectorField.value +
                                                                    '-rule-' +
                                                                    i +
                                                                    '-replaceValue'
                                                                "
                                                                >Valeur à remplacer</label
                                                            >
                                                            <input
                                                                class="form-control"
                                                                [id]="
                                                                    selectorField.value +
                                                                    '-rule-' +
                                                                    i +
                                                                    '-replaceValue'
                                                                "
                                                                formControlName="replaceValue"
                                                                type="text"
                                                                [class.is-invalid]="
                                                                    (form.touched ||
                                                                        getSelectorRuleField(
                                                                            selectorField.value,
                                                                            i,
                                                                            'replaceValue'
                                                                        ).touched) &&
                                                                    getSelectorRuleField(
                                                                        selectorField.value,
                                                                        i,
                                                                        'replaceValue'
                                                                    ).status == 'INVALID'
                                                                        ? 'danger'
                                                                        : ''
                                                                "
                                                                (keyup)="
                                                                    updatedMatchingText(
                                                                        selectorField.value
                                                                    )
                                                                "
                                                            />

                                                            <div class="invalid-tooltip">
                                                                <div
                                                                    *ngIf="
                                                                        (form.touched ||
                                                                            getSelectorRuleField(
                                                                                selectorField.value,
                                                                                i,
                                                                                'replaceValue'
                                                                            ).touched) &&
                                                                        getSelectorRuleField(
                                                                            selectorField.value,
                                                                            i,
                                                                            'replaceValue'
                                                                        ).errors &&
                                                                        getSelectorRuleField(
                                                                            selectorField.value,
                                                                            i,
                                                                            'replaceValue'
                                                                        ).errors['required']
                                                                    "
                                                                >
                                                                    Ce champ est obligatoire.
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li class="list-group-item text-center">
                                                        <button
                                                            type="button"
                                                            class="btn btn-secondary btn-sm"
                                                            (click)="
                                                                addRuleToSelector(
                                                                    selectorField.value
                                                                )
                                                            "
                                                        >
                                                            + Ajout règle
                                                        </button>
                                                    </li>
                                                </ul>

                                                <div class="form-group mb-0">
                                                    <label
                                                        [attr.for]="selectorField.value + '-result'"
                                                        >Résultat
                                                        <fa-icon
                                                            [icon]="faCircleQuestion"
                                                            rel="tooltip"
                                                            data-bs-placement="bottom"
                                                            data-bs-title="Valeur extraite"
                                                        ></fa-icon
                                                    ></label>
                                                    <div
                                                        class="text-muted"
                                                        *ngIf="
                                                            !getSelectorField(
                                                                selectorField.value,
                                                                'result'
                                                            ).value
                                                        "
                                                    >
                                                        Pas de résultat
                                                    </div>
                                                    <div
                                                        *ngIf="
                                                            getSelectorField(
                                                                selectorField.value,
                                                                'result'
                                                            ).value
                                                        "
                                                    >
                                                        <strong
                                                            [innerText]="
                                                                getSelectorField(
                                                                    selectorField.value,
                                                                    'result'
                                                                ).value
                                                            "
                                                        ></strong>
                                                    </div>
                                                </div>
                                            </ng-container>

                                            <ng-container
                                                *ngIf="
                                                    !getSelectorField(
                                                        selectorField.value,
                                                        'isValueInPdf'
                                                    )?.value
                                                "
                                            >
                                                <div class="form-group">
                                                    <label
                                                        [attr.for]="selectorField.value + '-result'"
                                                        >Valeur
                                                    </label>
                                                    <input
                                                        class="form-control"
                                                        [id]="selectorField.value + '-result'"
                                                        formControlName="result"
                                                        [type]="
                                                            getSelectorField(
                                                                selectorField.value,
                                                                'isValueInDays'
                                                            )?.value
                                                                ? 'number'
                                                                : 'text'
                                                        "
                                                        [class.is-invalid]="
                                                            getSelectorField(
                                                                selectorField.value,
                                                                'result'
                                                            ).status == 'INVALID'
                                                                ? 'danger'
                                                                : ''
                                                        "
                                                    />

                                                    <div
                                                        class="invalid-tooltip"
                                                        *ngIf="
                                                            getSelectorField(
                                                                selectorField.value,
                                                                'result'
                                                            ).errors
                                                        "
                                                    >
                                                        <div
                                                            *ngIf="
                                                                getSelectorField(
                                                                    selectorField.value,
                                                                    'result'
                                                                ).errors['required']
                                                            "
                                                        >
                                                            Ce champ est obligatoire.
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                        <ng-container *ngIf="selectorField.value === 'countryCode'">
                                            <div class="form-group">
                                                <label [attr.for]="selectorField.value + '-result'"
                                                    >Pays
                                                </label>
                                                <select
                                                    class="form-select"
                                                    [id]="selectorField.value + '-result'"
                                                    formControlName="result"
                                                    [class.is-invalid]="
                                                        getSelectorField(
                                                            selectorField.value,
                                                            'result'
                                                        ).touched &&
                                                        getSelectorField(
                                                            selectorField.value,
                                                            'result'
                                                        ).status == 'INVALID'
                                                            ? 'danger'
                                                            : ''
                                                    "
                                                >
                                                    <option
                                                        [value]="country.value"
                                                        *ngFor="let country of countriesList"
                                                        [innerHtml]="country.title"
                                                    ></option>
                                                </select>

                                                <div
                                                    class="invalid-tooltip"
                                                    *ngIf="
                                                        getSelectorField(
                                                            selectorField.value,
                                                            'result'
                                                        ).errors
                                                    "
                                                >
                                                    <div
                                                        *ngIf="
                                                            getSelectorField(
                                                                selectorField.value,
                                                                'result'
                                                            ).errors['required']
                                                        "
                                                    >
                                                        Ce champ est obligatoire.
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>

                                        <ng-container
                                            *ngIf="selectorField.value === 'productsColumnsConfig'"
                                        >
                                            <div
                                                class="alert alert-warning text-center"
                                                *ngIf="!pdfExtractDataTables.length"
                                            >
                                                Aucun tableau trouvé dans le PDF.
                                            </div>
                                            <ng-container *ngIf="pdfExtractDataTables.length">
                                                <div class="form-group">
                                                    <label
                                                        [attr.for]="
                                                            selectorField.value + '-tableIndex'
                                                        "
                                                        >Tableau utilisé
                                                    </label>
                                                    <select
                                                        class="form-select"
                                                        [id]="selectorField.value + '-tableIndex'"
                                                        formControlName="tableIndex"
                                                        [class.is-invalid]="
                                                            getSelectorField(
                                                                selectorField.value,
                                                                'tableIndex'
                                                            ).touched &&
                                                            getSelectorField(
                                                                selectorField.value,
                                                                'tableIndex'
                                                            ).status == 'INVALID'
                                                                ? 'danger'
                                                                : ''
                                                        "
                                                        (change)="updatedTemporaryProductsParsed()"
                                                    >
                                                        <option
                                                            [value]="indexTable"
                                                            *ngFor="
                                                                let pdfExtractDataTable of pdfExtractDataTables;
                                                                let indexTable = index
                                                            "
                                                            [innerText]="pdfExtractDataTable.title"
                                                        ></option>
                                                    </select>

                                                    <div
                                                        class="invalid-tooltip"
                                                        *ngIf="
                                                            getSelectorField(
                                                                selectorField.value,
                                                                'tableIndex'
                                                            ).errors
                                                        "
                                                    >
                                                        <div
                                                            *ngIf="
                                                                getSelectorField(
                                                                    selectorField.value,
                                                                    'tableIndex'
                                                                ).errors['required']
                                                            "
                                                        >
                                                            Ce champ est obligatoire.
                                                        </div>
                                                    </div>
                                                </div>

                                                <fieldset
                                                    *ngIf="
                                                        getSelectorField(
                                                            selectorField.value,
                                                            'tableIndex'
                                                        ).value !== null
                                                    "
                                                >
                                                    <legend>Affecter les colonnes</legend>
                                                    <div class="form-group">
                                                        <label
                                                            [attr.for]="
                                                                selectorField.value +
                                                                '-descriptionTitle'
                                                            "
                                                            >Libellé
                                                        </label>
                                                        <select
                                                            class="form-select"
                                                            [id]="
                                                                selectorField.value +
                                                                '-descriptionTitle'
                                                            "
                                                            formControlName="descriptionTitle"
                                                            [class.is-invalid]="
                                                                getSelectorField(
                                                                    selectorField.value,
                                                                    'descriptionTitle'
                                                                ).touched &&
                                                                getSelectorField(
                                                                    selectorField.value,
                                                                    'descriptionTitle'
                                                                ).status == 'INVALID'
                                                                    ? 'danger'
                                                                    : ''
                                                            "
                                                            (change)="
                                                                updatedTemporaryProductsParsed()
                                                            "
                                                        >
                                                            <option [ngValue]="null">
                                                                -- Colonne à affecter --
                                                            </option>
                                                            <option
                                                                [value]="header"
                                                                *ngFor="
                                                                    let header of pdfExtractDataTables[
                                                                        getSelectorField(
                                                                            selectorField.value,
                                                                            'tableIndex'
                                                                        ).value
                                                                    ]?.data.headers;
                                                                    let indexTable = index
                                                                "
                                                                [innerText]="header"
                                                            ></option>
                                                        </select>

                                                        <div
                                                            class="invalid-tooltip"
                                                            *ngIf="
                                                                getSelectorField(
                                                                    selectorField.value,
                                                                    'descriptionTitle'
                                                                ).errors
                                                            "
                                                        >
                                                            <div
                                                                *ngIf="
                                                                    getSelectorField(
                                                                        selectorField.value,
                                                                        'descriptionTitle'
                                                                    ).errors['required']
                                                                "
                                                            >
                                                                Ce champ est obligatoire.
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="form-group">
                                                        <label
                                                            [attr.for]="
                                                                selectorField.value + '-amountHt'
                                                            "
                                                            >Montant hors taxes
                                                        </label>
                                                        <select
                                                            class="form-select"
                                                            [id]="selectorField.value + '-amountHt'"
                                                            formControlName="amountHt"
                                                            [class.is-invalid]="
                                                                getSelectorField(
                                                                    selectorField.value,
                                                                    'amountHt'
                                                                ).touched &&
                                                                getSelectorField(
                                                                    selectorField.value,
                                                                    'amountHt'
                                                                ).status == 'INVALID'
                                                                    ? 'danger'
                                                                    : ''
                                                            "
                                                            (change)="
                                                                updatedTemporaryProductsParsed()
                                                            "
                                                        >
                                                            <option [ngValue]="null">
                                                                -- Colonne à affecter --
                                                            </option>
                                                            <option
                                                                [value]="header"
                                                                *ngFor="
                                                                    let header of pdfExtractDataTables[
                                                                        getSelectorField(
                                                                            selectorField.value,
                                                                            'tableIndex'
                                                                        ).value
                                                                    ]?.data.headers;
                                                                    let indexTable = index
                                                                "
                                                                [innerText]="header"
                                                            ></option>
                                                        </select>

                                                        <div
                                                            class="invalid-tooltip"
                                                            *ngIf="
                                                                getSelectorField(
                                                                    selectorField.value,
                                                                    'amountHt'
                                                                ).errors
                                                            "
                                                        >
                                                            <div
                                                                *ngIf="
                                                                    getSelectorField(
                                                                        selectorField.value,
                                                                        'amountHt'
                                                                    ).errors['required']
                                                                "
                                                            >
                                                                Ce champ est obligatoire.
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <label
                                                        [attr.for]="
                                                            selectorField.value + '-tvaRate'
                                                        "
                                                        >Pourcentage TVA
                                                    </label>
                                                    <div
                                                        class="form-check"
                                                        *ngFor="let value of [false, true]"
                                                    >
                                                        <input
                                                            type="radio"
                                                            class="form-check-input"
                                                            formControlName="isTvaValueInPdf"
                                                            [id]="
                                                                selectorField.value +
                                                                '-isTvaValueInPdf-' +
                                                                value
                                                            "
                                                            [value]="value"
                                                            autocomplete="off"
                                                            (change)="
                                                                updatedTemporaryProductsParsed()
                                                            "
                                                        />
                                                        <label
                                                            class="form-check-label"
                                                            [attr.for]="
                                                                selectorField.value +
                                                                '-isTvaValueInPdf-' +
                                                                value
                                                            "
                                                            [innerText]="
                                                                value
                                                                    ? 'L\'information est dans le pdf'
                                                                    : 'Je connais l\'information'
                                                            "
                                                        >
                                                        </label>
                                                    </div>
                                                    <div
                                                        class="form-group mb-0"
                                                        *ngIf="
                                                            getSelectorField(
                                                                selectorField.value,
                                                                'isTvaValueInPdf'
                                                            ).value
                                                        "
                                                    >
                                                        <select
                                                            class="form-select"
                                                            [id]="selectorField.value + '-tvaRate'"
                                                            formControlName="tvaRate"
                                                            [class.is-invalid]="
                                                                getSelectorField(
                                                                    selectorField.value,
                                                                    'tvaRate'
                                                                ).touched &&
                                                                getSelectorField(
                                                                    selectorField.value,
                                                                    'tvaRate'
                                                                ).status == 'INVALID'
                                                                    ? 'danger'
                                                                    : ''
                                                            "
                                                            (change)="
                                                                updatedTemporaryProductsParsed()
                                                            "
                                                        >
                                                            <option [ngValue]="null">
                                                                -- Colonne à affecter --
                                                            </option>
                                                            <option
                                                                [value]="header"
                                                                *ngFor="
                                                                    let header of pdfExtractDataTables[
                                                                        getSelectorField(
                                                                            selectorField.value,
                                                                            'tableIndex'
                                                                        ).value
                                                                    ]?.data.headers;
                                                                    let indexTable = index
                                                                "
                                                                [innerText]="header"
                                                            ></option>
                                                        </select>

                                                        <div
                                                            class="invalid-tooltip"
                                                            *ngIf="
                                                                getSelectorField(
                                                                    selectorField.value,
                                                                    'tvaRate'
                                                                ).errors
                                                            "
                                                        >
                                                            <div
                                                                *ngIf="
                                                                    getSelectorField(
                                                                        selectorField.value,
                                                                        'tvaRate'
                                                                    ).errors['required']
                                                                "
                                                            >
                                                                Ce champ est obligatoire.
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div
                                                        class="form-group mb-0"
                                                        *ngIf="
                                                            !getSelectorField(
                                                                selectorField.value,
                                                                'isTvaValueInPdf'
                                                            ).value
                                                        "
                                                    >
                                                        <select
                                                            class="form-select"
                                                            [id]="
                                                                selectorField.value +
                                                                '-tvaRateSelected'
                                                            "
                                                            formControlName="tvaRateSelected"
                                                            [class.is-invalid]="
                                                                getSelectorField(
                                                                    selectorField.value,
                                                                    'tvaRateSelected'
                                                                ).touched &&
                                                                getSelectorField(
                                                                    selectorField.value,
                                                                    'tvaRateSelected'
                                                                ).status == 'INVALID'
                                                                    ? 'danger'
                                                                    : ''
                                                            "
                                                            (change)="
                                                                updatedTemporaryProductsParsed()
                                                            "
                                                        >
                                                            <option
                                                                [value]="tvaRate"
                                                                *ngFor="
                                                                    let tvaRate of getTvaRates()
                                                                "
                                                                [innerHtml]="
                                                                    getEnumTvaRateLabel(tvaRate)
                                                                "
                                                            ></option>
                                                        </select>

                                                        <div
                                                            class="text-danger"
                                                            *ngIf="
                                                                getSelectorField(
                                                                    selectorField.value,
                                                                    'tvaRateSelected'
                                                                ).errors &&
                                                                getSelectorField(
                                                                    selectorField.value,
                                                                    'tvaRateSelected'
                                                                ).errors['required']
                                                            "
                                                        >
                                                            Ce champ est obligatoire.
                                                        </div>
                                                    </div>

                                                    <ul
                                                        class="list-group mt-4"
                                                        formArrayName="rulesForRows"
                                                    >
                                                        <li
                                                            class="list-group-item"
                                                            [formGroupName]="i"
                                                            *ngFor="
                                                                let ruleField of getProductsColumnsConfigRulesForRows()[
                                                                    'controls'
                                                                ];
                                                                let i = index
                                                            "
                                                        >
                                                            <strong>Règle {{ i + 1 }}</strong>

                                                            <button
                                                                type="button"
                                                                (click)="
                                                                    deleteRuleRowsForToProductsColumnsConfig(
                                                                        i
                                                                    )
                                                                "
                                                                class="btn btn-danger btn-sm float-end"
                                                                [disabled]="form.disabled"
                                                            >
                                                                X
                                                            </button>

                                                            <div class="clearfix"></div>

                                                            <div class="form-group">
                                                                <label
                                                                    [attr.for]="
                                                                        selectorField.value +
                                                                        '-rulesForRows-' +
                                                                        i +
                                                                        '-matchingText'
                                                                    "
                                                                    >Texte à rechercher</label
                                                                >
                                                                <input
                                                                    class="form-control"
                                                                    [id]="
                                                                        selectorField.value +
                                                                        '-rulesForRows-' +
                                                                        i +
                                                                        '-matchingText'
                                                                    "
                                                                    formControlName="matchingText"
                                                                    type="text"
                                                                    [class.is-invalid]="
                                                                        (form.touched ||
                                                                            getProductsColumnsConfigRulesForRowsRuleField(
                                                                                i,
                                                                                'matchingText'
                                                                            ).touched) &&
                                                                        getProductsColumnsConfigRulesForRowsRuleField(
                                                                            i,
                                                                            'matchingText'
                                                                        ).status == 'INVALID'
                                                                            ? 'danger'
                                                                            : ''
                                                                    "
                                                                    (keyup)="
                                                                        updatedTemporaryProductsParsed()
                                                                    "
                                                                />

                                                                <div class="invalid-tooltip">
                                                                    <div
                                                                        *ngIf="
                                                                            (form.touched ||
                                                                                getProductsColumnsConfigRulesForRowsRuleField(
                                                                                    i,
                                                                                    'matchingText'
                                                                                ).touched) &&
                                                                            getProductsColumnsConfigRulesForRowsRuleField(
                                                                                i,
                                                                                'matchingText'
                                                                            ).errors &&
                                                                            getProductsColumnsConfigRulesForRowsRuleField(
                                                                                i,
                                                                                'matchingText'
                                                                            ).errors['required']
                                                                        "
                                                                    >
                                                                        Ce champ est obligatoire.
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="form-group">
                                                                <select
                                                                    class="form-select"
                                                                    [id]="
                                                                        selectorField.value +
                                                                        '-rulesForRow-' +
                                                                        i +
                                                                        '-ruleType'
                                                                    "
                                                                    formControlName="ruleType"
                                                                    [class.is-invalid]="
                                                                        (form.touched ||
                                                                            getProductsColumnsConfigRulesForRowsRuleField(
                                                                                i,
                                                                                'ruleType'
                                                                            ).touched) &&
                                                                        getProductsColumnsConfigRulesForRowsRuleField(
                                                                            i,
                                                                            'ruleType'
                                                                        ).status == 'INVALID'
                                                                            ? 'danger'
                                                                            : ''
                                                                    "
                                                                    (change)="
                                                                        updatedRuleForRowsType(i)
                                                                    "
                                                                >
                                                                    <option
                                                                        [value]="ruleType"
                                                                        *ngFor="
                                                                            let ruleType of getRuleForRowsTypes()
                                                                        "
                                                                        [innerText]="
                                                                            getProductsColumnsConfigTypeLabel(
                                                                                ruleType
                                                                            )
                                                                        "
                                                                    ></option>
                                                                </select>

                                                                <div class="invalid-tooltip">
                                                                    <div
                                                                        *ngIf="
                                                                            (form.touched ||
                                                                                getProductsColumnsConfigRulesForRowsRuleField(
                                                                                    i,
                                                                                    'ruleType'
                                                                                ).touched) &&
                                                                            getProductsColumnsConfigRulesForRowsRuleField(
                                                                                i,
                                                                                'ruleType'
                                                                            ).errors &&
                                                                            getProductsColumnsConfigRulesForRowsRuleField(
                                                                                i,
                                                                                'ruleType'
                                                                            ).errors['required']
                                                                        "
                                                                    >
                                                                        Ce champ est obligatoire.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li class="list-group-item text-center">
                                                            <button
                                                                type="button"
                                                                class="btn btn-secondary btn-sm"
                                                                (click)="
                                                                    addRuleToProductsColumnsConfig()
                                                                "
                                                            >
                                                                + Ajout règle
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </fieldset>

                                                <fieldset class="mt-4">
                                                    <legend>Aperçu</legend>
                                                    <div class="table-responsive">
                                                        <table class="table">
                                                            <thead>
                                                                <tr>
                                                                    <th>Libellé</th>
                                                                    <th>Montant HT</th>
                                                                    <th>TVA</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody
                                                                *ngIf="
                                                                    !temporaryProductsParsed.rows
                                                                        ?.length
                                                                "
                                                            >
                                                                <tr>
                                                                    <td
                                                                        colspan="3"
                                                                        class="table-warning text-center"
                                                                    >
                                                                        Aucun produit détécté
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody
                                                                *ngIf="
                                                                    temporaryProductsParsed.rows
                                                                        ?.length
                                                                "
                                                            >
                                                                <tr
                                                                    *ngFor="
                                                                        let row of temporaryProductsParsed.rows
                                                                    "
                                                                >
                                                                    <td
                                                                        *ngFor="
                                                                            let cell of row;
                                                                            let i = index
                                                                        "
                                                                        [innerText]="
                                                                            i === 2
                                                                                ? cell + '%'
                                                                                : cell
                                                                        "
                                                                    ></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </fieldset>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="name"
                                >Nom de la configuration
                                <fa-icon
                                    [icon]="faCircleQuestion"
                                    rel="tooltip"
                                    data-bs-placement="bottom"
                                    data-bs-title="Ce nom est interne à Simplyfly et vous permettra de la retrouver plus facilement. Il est recommendé de mettre le nom de l'entreprise qui a fourni la facture."
                                ></fa-icon
                            ></label>
                            <input
                                class="form-control"
                                id="name"
                                formControlName="name"
                                type="text"
                                [class.is-invalid]="
                                    form.controls['name'].status == 'INVALID' ? 'danger' : ''
                                "
                            />
                            <div class="invalid-tooltip" *ngIf="form.controls['name'].errors">
                                <div *ngIf="form.controls['name'].errors['required']">
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer text-center">
                    <button
                        type="submit"
                        class="btn btn-primary btn-lg"
                        [innerHtml]="
                            processFromReader ? 'Sauvegarde des paramètres ...' : 'Suivant'
                        "
                        [disabled]="form.disabled || !form.valid || processFromReader"
                    ></button>
                </div>
            </div>
        </form>
    </div>
</div>

<div class="text-center" *ngIf="savingInvoice">
    <div class="spinner-border" role="status">
        <span class="visually-hidden">Sauvegarde de la facture d'achat...</span>
    </div>
    <br />
    Sauvegarde de la facture d'achat...
</div>
