<form (submit)="submitForm()" [formGroup]="form" class="main-form">
    <div class="row">
        <div class="col-md-4">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="form-group">
                        <label for="type">Nature de la demande</label>
                        <select
                            class="form-select"
                            id="type"
                            formControlName="type"
                            [class.is-invalid]="
                                (form.touched || type.touched) && type.status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                            (change)="setTypeFields()"
                        >
                            <option
                                [value]="enquiryType"
                                *ngFor="let enquiryType of getEnquiryTypes()"
                                [innerHtml]="getEnumEnquiryTypeLabel(enquiryType)"
                            ></option>
                        </select>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || type.touched) &&
                                    type.errors &&
                                    type.errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="enquiry">
                        <div *ngFor="let refField of refFields">
                            <div class="form-group enquiry-ref-edit">
                                <label *ngIf="refField === 'refEnquiry'">N° demande</label>
                                <label *ngIf="refField === 'refContract'">
                                    <strong>N° contrat</strong>
                                </label>

                                <div class="form-inline">
                                    {{ form.get(refField + "Prefix").value }}

                                    <select
                                        class="form-select ms-2"
                                        id="ref-enquiry-year"
                                        [formControlName]="refField + 'Year'"
                                        [class.is-invalid]="
                                            (form.touched || form.get(refField + 'Year').touched) &&
                                            form.get(refField + 'Year').status === 'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                    >
                                        <option
                                            [value]="year"
                                            *ngFor="let year of listYears"
                                            [innerText]="year"
                                        ></option>
                                    </select>

                                    <div class="invalid-tooltip">
                                        <div
                                            *ngIf="
                                                (form.touched ||
                                                    form.get(refField + 'Year').touched) &&
                                                form.get(refField + 'Year').errors &&
                                                form.get(refField + 'Year').errors['required']
                                            "
                                        >
                                            Ce champ est obligatoire.
                                        </div>
                                    </div>

                                    .

                                    <select
                                        class="form-select"
                                        id="ref-enquiry-month"
                                        [formControlName]="refField + 'Month'"
                                        [class.is-invalid]="
                                            (form.touched ||
                                                form.get(refField + 'Month').touched) &&
                                            form.get(refField + 'Month').status === 'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                    >
                                        <option
                                            [value]="month"
                                            *ngFor="let month of listMonths"
                                            [innerText]="addZeroToDigit(month)"
                                        ></option>
                                    </select>

                                    <div class="invalid-tooltip">
                                        <div
                                            *ngIf="
                                                (form.touched ||
                                                    form.get(refField + 'Month').touched) &&
                                                form.get(refField + 'Month').errors &&
                                                form.get(refField + 'Month').errors['required']
                                            "
                                        >
                                            Ce champ est obligatoire.
                                        </div>
                                    </div>

                                    <input
                                        class="form-control"
                                        id="ref-enquiry-number"
                                        [formControlName]="refField + 'Number'"
                                        type="number"
                                        min="1"
                                        [class.is-invalid]="
                                            (form.touched ||
                                                form.get(refField + 'Number').touched) &&
                                            form.get(refField + 'Number').status === 'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                    />

                                    <div class="invalid-tooltip">
                                        <div
                                            *ngIf="
                                                (form.touched ||
                                                    form.get(refField + 'Number').touched) &&
                                                form.get(refField + 'Number').errors &&
                                                form.get(refField + 'Number').errors['required']
                                            "
                                        >
                                            Ce champ est obligatoire.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <div class="form-group">
                        <label for="receivedBy">Reçu par</label>
                        <select
                            class="form-select"
                            id="receivedBy"
                            formControlName="receivedBy"
                            [class.is-invalid]="
                                (form.touched || form.controls['receivedBy'].touched) &&
                                form.controls['receivedBy'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        >
                            <option
                                [value]="user.value"
                                *ngFor="let user of usersList"
                                [innerHtml]="user.title"
                            ></option>
                        </select>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['receivedBy'].touched) &&
                                    form.controls['receivedBy'].errors &&
                                    form.controls['receivedBy'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="processedBy">Traité par</label>
                        <select
                            class="form-select"
                            id="processedBy"
                            formControlName="processedBy"
                            [class.is-invalid]="
                                (form.touched || form.controls['processedBy'].touched) &&
                                form.controls['processedBy'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        >
                            <option
                                [value]="user.value"
                                *ngFor="let user of usersList"
                                [innerHtml]="user.title"
                            ></option>
                        </select>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['processedBy'].touched) &&
                                    form.controls['processedBy'].errors &&
                                    form.controls['processedBy'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="clientId" class="d-flex justify-content-between"
                            >Entreprise
                            <a
                                [href]="
                                    'https://artheauaviation2.pipedrive.com/organization/' +
                                    form.value.clientId
                                "
                                target="_blank"
                                *ngIf="form.value.clientId"
                                [class.disabled]="form.disabled"
                                >Pipedrive <fa-icon [icon]="faExternalLink"></fa-icon></a
                        ></label>
                        <pipedrive-search-organization-input
                            (setValueToFormControl)="setValueToFormControl($event)"
                            inputName="clientId"
                            [defaultValue]="form.value['clientId']"
                            [disabled]="form.disabled"
                            [isInvalid]="
                                (form.touched || form.controls['clientId'].touched) &&
                                form.controls['clientId'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        ></pipedrive-search-organization-input>
                    </div>
                    <div class="form-group" *ngIf="form.value.clientId">
                        <label for="contactId" class="d-flex justify-content-between"
                            >Contact
                            <a
                                [href]="
                                    'https://artheauaviation2.pipedrive.com/person/' +
                                    form.value.contactId
                                "
                                target="_blank"
                                *ngIf="form.value.contactId"
                                [class.disabled]="form.disabled"
                                >Pipedrive <fa-icon [icon]="faExternalLink"></fa-icon></a
                        ></label>
                        <pipedrive-search-person-input
                            (setValueToFormControl)="setValueToFormControl($event)"
                            inputName="contactId"
                            [organizationId]="form.value.clientId"
                            [defaultValue]="form.value.contactId"
                            [disabled]="form.disabled"
                            [isInvalid]="
                                (form.touched || form.controls['contactId'].touched) &&
                                form.controls['contactId'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        ></pipedrive-search-person-input>
                    </div>
                    <div class="form-group">
                        <label for="comments"
                            >Commentaire sur la demande, besoins particuliers, attentes du client
                            ...</label
                        >
                        <textarea
                            formControlName="comments"
                            class="form-control"
                            id="comments"
                            rows="3"
                            [class.is-invalid]="
                                (form.touched || form.controls['comments'].touched) &&
                                form.controls['comments'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        ></textarea>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['comments'].touched) &&
                                    form.controls['comments'].errors &&
                                    form.controls['comments'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mb-4">
                <div class="card-body">
                    <div class="form-group">
                        <label for="noteForAirlines"
                            >Note à destination des compagnies aériennes</label
                        >
                        <textarea
                            formControlName="noteForAirlines"
                            class="form-control"
                            id="noteForAirlines"
                            rows="5"
                            [class.is-invalid]="
                                (form.touched || form.controls['noteForAirlines'].touched) &&
                                form.controls['noteForAirlines'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        ></textarea>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['noteForAirlines'].touched) &&
                                    form.controls['noteForAirlines'].errors &&
                                    form.controls['noteForAirlines'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="list-group-header">
                        Document attachés aux emails pour les compagnies aériennes
                    </div>

                    <ul class="list-group mb-2" formArrayName="emailAttachments">
                        <li
                            class="list-group-item"
                            *ngFor="let emailAttachment of emailAttachments.controls; let i = index"
                            [formGroupName]="i"
                        >
                            <button
                                type="button"
                                class="btn btn-danger btn-sm float-end"
                                (click)="deleteEmailAttachment(emailAttachment.value.id)"
                            >
                                <fa-icon [icon]="faTrash"></fa-icon>
                            </button>
                            <a target="_blank" class="mb-2" [href]="emailAttachment.value.url">
                                {{
                                    emailAttachment.value.name +
                                        " (" +
                                        formatBytes(emailAttachment.value.size) +
                                        ")"
                                }}
                                <fa-icon [icon]="faExternalLink"></fa-icon>
                            </a>
                            <div class="form-group">
                                <label for="language">Pour quelle langue ?</label>
                                <select
                                    class="form-select"
                                    id="language"
                                    formControlName="language"
                                    [class.is-invalid]="
                                        (form.touched ||
                                            getEmailAttachmentField(i, 'language').touched) &&
                                        getEmailAttachmentField(i, 'language').status === 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                >
                                    <option value="">Toutes</option>
                                    <option
                                        [value]="language"
                                        *ngFor="let language of getLanguages()"
                                        [innerHtml]="getLanguageLabel(language) + ' uniquement'"
                                    ></option>
                                </select>
                            </div>
                        </li>
                    </ul>

                    <div class="text-center">
                        <button type="button" class="btn btn-primary" (click)="openModalUpload()">
                            + Ajouter un document
                        </button>
                    </div>
                </div>
            </div>
            <div class="form-check form-switch mb-4" *ngIf="!enquiryId">
                <input
                    class="form-check-input"
                    type="checkbox"
                    id="will-create-pipedrive-deal"
                    [(ngModel)]="willCreatePipedriveDeal"
                    [ngModelOptions]="{ standalone: true }"
                />
                <label class="form-check-label" for="will-create-pipedrive-deal"
                    >Créer une offre dans Pipedrive automatiquement</label
                >
            </div>
        </div>
        <div class="col-md-8">
            <div class="card card-with-tabs mb-4">
                <div class="card-header d-flex align-items-center justify-content-between">
                    <ul class="nav nav-tabs" role="tablist">
                        <li
                            class="nav-item"
                            role="presentation"
                            *ngFor="let itinerary of itineraries.controls; let i = index"
                        >
                            <button
                                class="nav-link"
                                [class.active]="i === currentTabIndex"
                                [id]="i + '-tab'"
                                type="button"
                                role="tab"
                                [attr.aria-controls]="i + '-tab-pane'"
                                [attr.aria-selected]="i === currentTabIndex ? 'true' : 'false'"
                                [innerText]="itinerary.value.title || 'Itinéraire sans nom'"
                                (click)="selectItinerary(i)"
                            ></button>
                        </li>
                    </ul>

                    <button
                        type="button"
                        class="btn btn-primary btn-sm me-1 text-nowrap"
                        (click)="addItineraryAndSelect()"
                    >
                        + Ajout itinéraire
                    </button>
                </div>
                <div class="card-body">
                    <div class="tab-content" formArrayName="itineraries">
                        <div
                            class="tab-pane fade"
                            [class.show]="i === currentTabIndex"
                            [class.active]="i === currentTabIndex"
                            [id]="i + '-tab-pane'"
                            role="tabpanel"
                            [attr.aria-labelledby]="i + '-tab'"
                            *ngFor="let itinerary of itineraries.controls; let i = index"
                            [formGroupName]="i"
                        >
                            <div class="form-group">
                                <label>Nom itinéraire</label>
                                <div [class.input-group]="itineraries.length > 1">
                                    <input
                                        type="text"
                                        formControlName="title"
                                        class="form-control"
                                        [class.is-invalid]="
                                            (form.touched ||
                                                getItinerary(i).get('title').touched) &&
                                            getItinerary(i).get('title').status === 'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                    />
                                    <div class="invalid-tooltip">
                                        <div
                                            *ngIf="
                                                (form.touched ||
                                                    getItinerary(i).get('title').touched) &&
                                                getItinerary(i).get('title').errors &&
                                                getItinerary(i).get('title').errors['required']
                                            "
                                        >
                                            Ce champ est obligatoire.
                                        </div>
                                    </div>

                                    <button
                                        type="button"
                                        (click)="deleteItinerary(i)"
                                        class="btn btn-danger"
                                        *ngIf="itineraries.length > 1"
                                    >
                                        <fa-icon [icon]="faTrash"></fa-icon>
                                    </button>
                                </div>
                            </div>

                            <div class="text-center">
                                <div
                                    class="btn-group btn-group-toggle mb-2"
                                    role="group"
                                    data-bs-toggle="buttons"
                                >
                                    <ng-container *ngFor="let itineraryType of getItineraryTypes()">
                                        <input
                                            type="radio"
                                            name="itineraries"
                                            id="one-way"
                                            class="btn-check"
                                            [value]="itineraryType"
                                            [checked]="
                                                form && getItineraryType(i).value === itineraryType
                                            "
                                        />

                                        <label
                                            class="btn btn-primary"
                                            [class.active]="
                                                form && getItineraryType(i).value === itineraryType
                                            "
                                            (click)="updateCurrentType(i, itineraryType)"
                                        >
                                            <fa-icon
                                                [icon]="getEnumItineraryTypeIconUrl(itineraryType)"
                                            ></fa-icon>
                                            {{ getEnumItineraryTypeLabel(itineraryType) }}
                                        </label>
                                    </ng-container>
                                </div>
                            </div>

                            <ul class="list-group mb-2" formArrayName="trips">
                                <li
                                    class="list-group-item"
                                    [formGroupName]="j"
                                    *ngFor="let trip of getTrips(i).controls; let j = index"
                                >
                                    <div class="row justify-content-center">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label
                                                    for="date"
                                                    *ngIf="
                                                        getItineraryType(i).value ===
                                                        EnumItineraryType.oneWay
                                                    "
                                                    >Date</label
                                                >
                                                <label
                                                    for="date"
                                                    *ngIf="
                                                        getItineraryType(i).value ===
                                                            EnumItineraryType.roundTrip && j === 0
                                                    "
                                                    >Date de départ</label
                                                >
                                                <label
                                                    for="date"
                                                    *ngIf="
                                                        getItineraryType(i).value ===
                                                            EnumItineraryType.roundTrip && j === 1
                                                    "
                                                    >Date de retour</label
                                                >
                                                <label
                                                    for="date"
                                                    *ngIf="
                                                        getItineraryType(i).value ===
                                                        EnumItineraryType.multipleDestinations
                                                    "
                                                    >Date du vol {{ j + 1 }}</label
                                                >
                                                <input
                                                    class="form-control"
                                                    id="date"
                                                    formControlName="date"
                                                    type="date"
                                                    [max]="
                                                        getTrip(i, j + 1) &&
                                                        getTripField(i, j + 1, 'date')
                                                            ? getTripField(i, j + 1, 'date').value
                                                            : ''
                                                    "
                                                    [min]="
                                                        j > 0 && getTrip(i, j - 1).value.date
                                                            ? getTrip(i, j - 1).value.date
                                                            : '2000-01-01'
                                                    "
                                                    [class.is-invalid]="
                                                        (form.touched ||
                                                            getTripField(i, j, 'date').touched) &&
                                                        getTripField(i, j, 'date').status ===
                                                            'INVALID'
                                                            ? 'danger'
                                                            : ''
                                                    "
                                                />

                                                <div class="invalid-tooltip">
                                                    <div
                                                        *ngIf="
                                                            (form.touched ||
                                                                getTripField(i, j, 'date')
                                                                    .touched) &&
                                                            getTripField(i, j, 'date').errors &&
                                                            getTripField(i, j, 'date').errors[
                                                                'required'
                                                            ]
                                                        "
                                                    >
                                                        Ce champ est obligatoire.
                                                    </div>
                                                    <div
                                                        *ngIf="
                                                            (form.touched ||
                                                                getTripField(i, j, 'date')
                                                                    .touched) &&
                                                            getTripField(i, j, 'date').errors &&
                                                            getTripField(i, j, 'date').errors[
                                                                'dateBelowMinimum'
                                                            ]
                                                        "
                                                    >
                                                        La date doit être supérieure à
                                                        {{
                                                            getTripField(i, j, "date").errors[
                                                                "dateMinimum"
                                                            ] | date : "dd/MM/yyyy"
                                                        }}.
                                                    </div>
                                                </div>

                                                <div
                                                    *ngIf="
                                                        getItineraryType(i).value ===
                                                            EnumItineraryType.roundTrip &&
                                                        j === 1 &&
                                                        getTrip(i, j).value.date &&
                                                        getTrip(i, j - 1).value.date ===
                                                            getTrip(i, j).value.date
                                                    "
                                                    class="text-warning"
                                                >
                                                    <fa-icon [icon]="faWarning"></fa-icon>
                                                    Aller/Retour ont lieu le même jour
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="col-md-6"
                                            *ngIf="type.value !== EnumEnquiryType.cargo"
                                        >
                                            <div class="form-group">
                                                <label
                                                    for="end"
                                                    *ngIf="
                                                        getItineraryType(i).value ===
                                                        EnumItineraryType.oneWay
                                                    "
                                                    >Heure (LT)</label
                                                >
                                                <label
                                                    for="end"
                                                    *ngIf="
                                                        form &&
                                                        getItineraryType(i).value ===
                                                            EnumItineraryType.roundTrip &&
                                                        j === 0
                                                    "
                                                    >Heure de départ (LT)</label
                                                >
                                                <label
                                                    for="end"
                                                    *ngIf="
                                                        getItineraryType(i).value ===
                                                            EnumItineraryType.roundTrip && j === 1
                                                    "
                                                    >Heure de retour (LT)</label
                                                >
                                                <label
                                                    for="end"
                                                    *ngIf="
                                                        getItineraryType(i).value ===
                                                        EnumItineraryType.multipleDestinations
                                                    "
                                                    >Heure (LT)</label
                                                >

                                                <input
                                                    class="form-control"
                                                    id="time"
                                                    formControlName="time"
                                                    type="time"
                                                    [class.is-invalid]="
                                                        (form.touched ||
                                                            getTripField(i, j, 'time').touched) &&
                                                        getTripField(i, j, 'time').status ===
                                                            'INVALID'
                                                            ? 'danger'
                                                            : ''
                                                    "
                                                />

                                                <div class="invalid-tooltip">
                                                    <div
                                                        *ngIf="
                                                            (form.touched ||
                                                                getTripField(i, j, 'time')
                                                                    .touched) &&
                                                            getTripField(i, j, 'time').errors &&
                                                            getTripField(i, j, 'time').errors[
                                                                'required'
                                                            ]
                                                        "
                                                    >
                                                        Ce champ est obligatoire.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row justify-content-center">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label
                                                    for="airportDepart"
                                                    *ngIf="
                                                        !(
                                                            form &&
                                                            getItineraryType(i).value ===
                                                                EnumItineraryType.roundTrip &&
                                                            j > 0
                                                        )
                                                    "
                                                    >Départ</label
                                                >
                                                <label
                                                    for="airportDepart"
                                                    *ngIf="
                                                        form &&
                                                        getItineraryType(i).value ===
                                                            EnumItineraryType.roundTrip &&
                                                        j > 0
                                                    "
                                                    >Arrivée</label
                                                >
                                                <app-airport-picker
                                                    [inputName]="
                                                        'itineraries.' +
                                                        i +
                                                        '.trips.' +
                                                        j +
                                                        '.airportDepart'
                                                    "
                                                    [defaultValue]="
                                                        form && getTrip(i, j).value['airportDepart']
                                                    "
                                                    [disabled]="form.disabled"
                                                    [isInvalid]="
                                                        (form.touched ||
                                                            getTripField(i, j, 'airportDepart')
                                                                .touched) &&
                                                        getTripField(i, j, 'airportDepart')
                                                            .status === 'INVALID'
                                                            ? 'danger'
                                                            : ''
                                                    "
                                                    (setValueToFormControl)="
                                                        setValueToFormControl($event)
                                                    "
                                                >
                                                </app-airport-picker>

                                                <div
                                                    *ngIf="
                                                        (form.touched ||
                                                            getTripField(i, j, 'airportDepart')
                                                                .touched) &&
                                                        getTripField(i, j, 'airportDepart')
                                                            .errors &&
                                                        getTripField(i, j, 'airportDepart').errors[
                                                            'required'
                                                        ]
                                                    "
                                                    class="text-danger"
                                                >
                                                    Ce champ est obligatoire.
                                                </div>
                                            </div>
                                            <div class="form-check mb-2">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    [id]="i + '-' + j + 'has-fuel-stop-over'"
                                                    formControlName="hasFuelStopOver"
                                                    (change)="updatedFuelStopOver(i, j)"
                                                />
                                                <label
                                                    class="form-check-label"
                                                    [for]="i + '-' + j + 'has-fuel-stop-over'"
                                                    >A un arrêt carburant</label
                                                >
                                            </div>
                                            <div
                                                class="form-group has-borders"
                                                *ngIf="getTrip(i, j).value.hasFuelStopOver"
                                            >
                                                <label for="commercialStopOverAirport"
                                                    >Aéroport arrêt carburant</label
                                                >
                                                <app-airport-picker
                                                    [inputName]="
                                                        'itineraries.' +
                                                        i +
                                                        '.trips.' +
                                                        j +
                                                        '.fuelStopOverAirport'
                                                    "
                                                    [defaultValue]="
                                                        form &&
                                                        getTrip(i, j).value['fuelStopOverAirport']
                                                    "
                                                    [disabled]="form.disabled"
                                                    [isInvalid]="
                                                        (form.touched ||
                                                            getTripField(
                                                                i,
                                                                j,
                                                                'fuelStopOverAirport'
                                                            ).touched) &&
                                                        getTripField(i, j, 'fuelStopOverAirport')
                                                            .status === 'INVALID'
                                                            ? 'danger'
                                                            : ''
                                                    "
                                                    (setValueToFormControl)="
                                                        setValueToFormControl($event)
                                                    "
                                                >
                                                </app-airport-picker>
                                                <p class="text-muted">
                                                    Laissez vide si non précisé
                                                </p>

                                                <div
                                                    *ngIf="
                                                        (form.touched ||
                                                            getTripField(
                                                                i,
                                                                j,
                                                                'fuelStopOverAirport'
                                                            ).touched) &&
                                                        getTripField(i, j, 'fuelStopOverAirport')
                                                            .errors &&
                                                        getTripField(i, j, 'fuelStopOverAirport')
                                                            .errors['required']
                                                    "
                                                    class="text-danger"
                                                >
                                                    Ce champ est obligatoire.
                                                </div>
                                            </div>
                                            <div class="form-check mb-2">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    [id]="i + '-' + j + 'has-commercial-stop-over'"
                                                    formControlName="hasCommercialStopOver"
                                                    (change)="updatedCommercialStopOver(i, j)"
                                                />
                                                <label
                                                    class="form-check-label"
                                                    [for]="i + '-' + j + 'has-commercial-stop-over'"
                                                    >A une escale commerciale</label
                                                >
                                            </div>
                                            <div
                                                class="form-group has-borders"
                                                *ngIf="getTrip(i, j).value.hasCommercialStopOver"
                                            >
                                                <label for="commercialStopOverAirport"
                                                    >Aéroport escale commerciale</label
                                                >
                                                <app-airport-picker
                                                    [inputName]="
                                                        'itineraries.' +
                                                        i +
                                                        '.trips.' +
                                                        j +
                                                        '.commercialStopOverAirport'
                                                    "
                                                    [defaultValue]="
                                                        form &&
                                                        getTrip(i, j).value[
                                                            'commercialStopOverAirport'
                                                        ]
                                                    "
                                                    [disabled]="form.disabled"
                                                    [isInvalid]="
                                                        (form.touched ||
                                                            getTripField(
                                                                i,
                                                                j,
                                                                'commercialStopOverAirport'
                                                            ).touched) &&
                                                        getTripField(
                                                            i,
                                                            j,
                                                            'commercialStopOverAirport'
                                                        ).status === 'INVALID'
                                                            ? 'danger'
                                                            : ''
                                                    "
                                                    (setValueToFormControl)="
                                                        setValueToFormControl($event)
                                                    "
                                                >
                                                </app-airport-picker>
                                                <p class="text-muted">
                                                    Laissez vide si non précisé
                                                </p>

                                                <div
                                                    *ngIf="
                                                        (form.touched ||
                                                            getTripField(
                                                                i,
                                                                j,
                                                                'commercialStopOverAirport'
                                                            ).touched) &&
                                                        getTripField(
                                                            i,
                                                            j,
                                                            'commercialStopOverAirport'
                                                        ).errors &&
                                                        getTripField(
                                                            i,
                                                            j,
                                                            'commercialStopOverAirport'
                                                        ).errors['required']
                                                    "
                                                    class="text-danger"
                                                >
                                                    Ce champ est obligatoire.
                                                </div>
                                            </div>
                                            <div
                                                class="form-group d-none"
                                                *ngIf="type.value !== EnumEnquiryType.sport"
                                            >
                                                <label for="airportDepartTax"
                                                    >Taxes d'aéroport
                                                    <span class="text-muted">{{
                                                        getTrip(i, j).value.airportDepart
                                                    }}</span></label
                                                >
                                                <input
                                                    class="form-control"
                                                    id="airportDepartTax"
                                                    formControlName="airportDepartTax"
                                                    type="number"
                                                    min="0"
                                                    [class.is-invalid]="
                                                        (form.touched ||
                                                            getTripField(i, j, 'airportDepartTax')
                                                                .touched) &&
                                                        getTripField(i, j, 'airportDepartTax')
                                                            .status === 'INVALID'
                                                            ? 'danger'
                                                            : ''
                                                    "
                                                    (keyup)="updateAirportDestination(i)"
                                                />

                                                <div class="invalid-tooltip">
                                                    <div
                                                        *ngIf="
                                                            (form.touched ||
                                                                getTripField(
                                                                    i,
                                                                    j,
                                                                    'airportDepartTax'
                                                                ).touched) &&
                                                            getTripField(i, j, 'airportDepartTax')
                                                                .errors &&
                                                            getTripField(i, j, 'airportDepartTax')
                                                                .errors['required']
                                                        "
                                                    >
                                                        Ce champ est obligatoire.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="col-md-6"
                                            *ngIf="
                                                form &&
                                                getItineraryType(i).value !==
                                                    EnumItineraryType.roundTrip
                                            "
                                        >
                                            <div class="form-group">
                                                <label for="airportDestination">Arrivée</label>
                                                <app-airport-picker
                                                    [inputName]="
                                                        'itineraries.' +
                                                        i +
                                                        '.trips.' +
                                                        j +
                                                        '.airportDestination'
                                                    "
                                                    [defaultValue]="
                                                        form &&
                                                        getTrip(i, j).value['airportDestination']
                                                    "
                                                    [disabled]="form.disabled"
                                                    [isInvalid]="
                                                        (form.touched ||
                                                            getTripField(i, j, 'airportDestination')
                                                                .touched) &&
                                                        getTripField(i, j, 'airportDestination')
                                                            .status === 'INVALID'
                                                            ? 'danger'
                                                            : ''
                                                    "
                                                    (setValueToFormControl)="
                                                        setValueToFormControl($event)
                                                    "
                                                >
                                                </app-airport-picker>

                                                <div
                                                    *ngIf="
                                                        (form.touched ||
                                                            getTripField(i, j, 'airportDestination')
                                                                .touched) &&
                                                        getTripField(i, j, 'airportDestination')
                                                            .errors &&
                                                        getTripField(i, j, 'airportDestination')
                                                            .errors['required']
                                                    "
                                                    class="text-danger"
                                                >
                                                    Ce champ est obligatoire.
                                                </div>
                                            </div>
                                            <div
                                                class="form-group d-none"
                                                *ngIf="type.value !== EnumEnquiryType.sport"
                                            >
                                                <label for="airportDestinationTax"
                                                    >Taxes d'aéroport
                                                    <span class="text-muted">{{
                                                        getTrip(i, j).value.airportDestination
                                                    }}</span></label
                                                >
                                                <input
                                                    class="form-control"
                                                    id="airportDestinationTax"
                                                    formControlName="airportDestinationTax"
                                                    type="number"
                                                    min="0"
                                                    [class.is-invalid]="
                                                        (form.touched ||
                                                            getTripField(
                                                                i,
                                                                j,
                                                                'airportDestinationTax'
                                                            ).touched) &&
                                                        getTripField(i, j, 'airportDestinationTax')
                                                            .status === 'INVALID'
                                                            ? 'danger'
                                                            : ''
                                                    "
                                                    (keyup)="updateAirportDestination(i)"
                                                />

                                                <div class="invalid-tooltip">
                                                    <div
                                                        *ngIf="
                                                            (form.touched ||
                                                                getTripField(
                                                                    i,
                                                                    j,
                                                                    'airportDestinationTax'
                                                                ).touched) &&
                                                            getTripField(
                                                                i,
                                                                j,
                                                                'airportDestinationTax'
                                                            ).errors &&
                                                            getTripField(
                                                                i,
                                                                j,
                                                                'airportDestinationTax'
                                                            ).errors['required']
                                                        "
                                                    >
                                                        Ce champ est obligatoire.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="col-md-6"
                                            *ngIf="type.value !== EnumEnquiryType.cargo"
                                        >
                                            <div class="form-group">
                                                <label for="passengersTotal">Passagers</label>
                                                <input
                                                    class="form-control"
                                                    id="passengersTotal"
                                                    formControlName="passengersTotal"
                                                    type="number"
                                                    min="1"
                                                    [class.is-invalid]="
                                                        (form.touched ||
                                                            getTripField(i, j, 'passengersTotal')
                                                                .touched) &&
                                                        getTripField(i, j, 'passengersTotal')
                                                            .status === 'INVALID'
                                                            ? 'danger'
                                                            : ''
                                                    "
                                                />

                                                <div class="invalid-tooltip">
                                                    <div
                                                        *ngIf="
                                                            (form.touched ||
                                                                getTripField(
                                                                    i,
                                                                    j,
                                                                    'passengersTotal'
                                                                ).touched) &&
                                                            getTripField(i, j, 'passengersTotal')
                                                                .errors &&
                                                            getTripField(i, j, 'passengersTotal')
                                                                .errors['required']
                                                        "
                                                    >
                                                        Ce champ est obligatoire.
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                class="form-group"
                                                *ngIf="hasBusinessPassengerField(type.value)"
                                            >
                                                <label for="passengersBusiness"
                                                    >dont business</label
                                                >
                                                <input
                                                    class="form-control"
                                                    id="passengersBusiness"
                                                    formControlName="passengersBusiness"
                                                    type="number"
                                                    min="0"
                                                    [class.is-invalid]="
                                                        (form.touched ||
                                                            getTripField(i, j, 'passengersBusiness')
                                                                .touched) &&
                                                        getTripField(i, j, 'passengersBusiness')
                                                            .status === 'INVALID'
                                                            ? 'danger'
                                                            : ''
                                                    "
                                                />

                                                <div class="invalid-tooltip">
                                                    <div
                                                        *ngIf="
                                                            (form.touched ||
                                                                getTripField(
                                                                    i,
                                                                    j,
                                                                    'passengersBusiness'
                                                                ).touched) &&
                                                            getTripField(i, j, 'passengersBusiness')
                                                                .errors &&
                                                            getTripField(i, j, 'passengersBusiness')
                                                                .errors['required']
                                                        "
                                                    >
                                                        Ce champ est obligatoire.
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                class="form-group"
                                                *ngIf="getTrip(i, j).value.hasCommercialStopOver"
                                            >
                                                <fieldset>
                                                    <legend>
                                                        Passagers EC
                                                        <span
                                                            class="float-end"
                                                            [innerText]="
                                                                getTripField(
                                                                    i,
                                                                    j,
                                                                    'passengersCommercialStop'
                                                                ).value
                                                            "
                                                        ></span>
                                                    </legend>

                                                    <div class="form-group">
                                                        <label for="passengersCommercialStopEmbark"
                                                            >Nombre qui embarquent</label
                                                        >
                                                        <input
                                                            (keyup)="passengersCommercialStop(i, j)"
                                                            class="form-control"
                                                            id="passengersCommercialStopEmbark"
                                                            formControlName="passengersCommercialStopEmbark"
                                                            type="number"
                                                            [class.is-invalid]="
                                                                (form.touched ||
                                                                    getTripField(
                                                                        i,
                                                                        j,
                                                                        'passengersCommercialStopEmbark'
                                                                    ).touched) &&
                                                                getTripField(
                                                                    i,
                                                                    j,
                                                                    'passengersCommercialStopEmbark'
                                                                ).status === 'INVALID'
                                                                    ? 'danger'
                                                                    : ''
                                                            "
                                                        />
                                                    </div>

                                                    <div class="form-group">
                                                        <label
                                                            for="passengersCommercialStopDisembark"
                                                            >Nombre qui débarquent</label
                                                        >
                                                        <input
                                                            (keyup)="passengersCommercialStop(i, j)"
                                                            class="form-control"
                                                            id="passengersCommercialStopDisembark"
                                                            formControlName="passengersCommercialStopDisembark"
                                                            type="number"
                                                            [class.is-invalid]="
                                                                (form.touched ||
                                                                    getTripField(
                                                                        i,
                                                                        j,
                                                                        'passengersCommercialStopDisembark'
                                                                    ).touched) &&
                                                                getTripField(
                                                                    i,
                                                                    j,
                                                                    'passengersCommercialStopDisembark'
                                                                ).status === 'INVALID'
                                                                    ? 'danger'
                                                                    : ''
                                                            "
                                                        />
                                                    </div>
                                                </fieldset>

                                                <div class="invalid-tooltip">
                                                    <div
                                                        *ngIf="
                                                            (form.touched ||
                                                                getTripField(
                                                                    i,
                                                                    j,
                                                                    'passengersCommercialStop'
                                                                ).touched) &&
                                                            getTripField(
                                                                i,
                                                                j,
                                                                'passengersCommercialStop'
                                                            ).errors &&
                                                            getTripField(
                                                                i,
                                                                j,
                                                                'passengersCommercialStop'
                                                            ).errors['required']
                                                        "
                                                    >
                                                        Ce champ est obligatoire.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="col-md-6"
                                            *ngIf="type.value === EnumEnquiryType.cargo"
                                        >
                                            <div class="form-group">
                                                <label for="weight">Poids</label>
                                                <div class="input-group">
                                                    <input
                                                        class="form-control"
                                                        id="weight"
                                                        formControlName="weight"
                                                        type="number"
                                                        min="1"
                                                        [class.is-invalid]="
                                                            (form.touched ||
                                                                getTripField(i, j, 'weight')
                                                                    .touched) &&
                                                            getTripField(i, j, 'weight').status ===
                                                                'INVALID'
                                                                ? 'danger'
                                                                : ''
                                                        "
                                                    />

                                                    <div class="invalid-tooltip">
                                                        <div
                                                            *ngIf="
                                                                (form.touched ||
                                                                    getTripField(i, j, 'weight')
                                                                        .touched) &&
                                                                getTripField(i, j, 'weight')
                                                                    .errors &&
                                                                getTripField(i, j, 'weight').errors[
                                                                    'required'
                                                                ]
                                                            "
                                                        >
                                                            Ce champ est obligatoire.
                                                        </div>
                                                    </div>
                                                    <span class="input-group-text">kg</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="col-md-6"
                                            *ngIf="type.value === EnumEnquiryType.cargo"
                                        >
                                            <div class="form-group">
                                                <label for="volume">Volume</label>
                                                <div class="input-group">
                                                    <input
                                                        class="form-control"
                                                        id="volume"
                                                        formControlName="volume"
                                                        type="number"
                                                        min="1"
                                                        [class.is-invalid]="
                                                            (form.touched ||
                                                                getTripField(i, j, 'volume')
                                                                    .touched) &&
                                                            getTripField(i, j, 'volume').status ===
                                                                'INVALID'
                                                                ? 'danger'
                                                                : ''
                                                        "
                                                    />

                                                    <div class="invalid-tooltip">
                                                        <div
                                                            *ngIf="
                                                                (form.touched ||
                                                                    getTripField(i, j, 'volume')
                                                                        .touched) &&
                                                                getTripField(i, j, 'volume')
                                                                    .errors &&
                                                                getTripField(i, j, 'volume').errors[
                                                                    'required'
                                                                ]
                                                            "
                                                        >
                                                            Ce champ est obligatoire.
                                                        </div>
                                                    </div>
                                                    <span class="input-group-text"
                                                        >m<sup>3</sup></span
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="col-md-6"
                                            *ngIf="type.value === EnumEnquiryType.cargo"
                                        >
                                            <div class="form-group">
                                                <label for="natureFret">Nature du fret</label>
                                                <input
                                                    class="form-control"
                                                    id="natureFret"
                                                    formControlName="natureFret"
                                                    type="text"
                                                    [class.is-invalid]="
                                                        (form.touched ||
                                                            getTripField(i, j, 'natureFret')
                                                                .touched) &&
                                                        getTripField(i, j, 'natureFret').status ===
                                                            'INVALID'
                                                            ? 'danger'
                                                            : ''
                                                    "
                                                />

                                                <div class="invalid-tooltip">
                                                    <div
                                                        *ngIf="
                                                            (form.touched ||
                                                                getTripField(i, j, 'natureFret')
                                                                    .touched) &&
                                                            getTripField(i, j, 'natureFret')
                                                                .errors &&
                                                            getTripField(i, j, 'natureFret').errors[
                                                                'required'
                                                            ]
                                                        "
                                                    >
                                                        Ce champ est obligatoire.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="col-md-6"
                                            *ngIf="type.value === EnumEnquiryType.cargo"
                                        >
                                            <div class="form-group">
                                                <label for="dimensions">Dimensions</label>
                                                <input
                                                    class="form-control"
                                                    id="dimensions"
                                                    formControlName="dimensions"
                                                    type="text"
                                                    [class.is-invalid]="
                                                        (form.touched ||
                                                            getTripField(i, j, 'dimensions')
                                                                .touched) &&
                                                        getTripField(i, j, 'dimensions').status ===
                                                            'INVALID'
                                                            ? 'danger'
                                                            : ''
                                                    "
                                                />

                                                <div class="invalid-tooltip">
                                                    <div
                                                        *ngIf="
                                                            (form.touched ||
                                                                getTripField(i, j, 'dimensions')
                                                                    .touched) &&
                                                            getTripField(i, j, 'dimensions')
                                                                .errors &&
                                                            getTripField(i, j, 'dimensions').errors[
                                                                'required'
                                                            ]
                                                        "
                                                    >
                                                        Ce champ est obligatoire.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        class="text-center"
                                        *ngIf="
                                            form &&
                                            getItineraryType(i).value ===
                                                EnumItineraryType.multipleDestinations
                                        "
                                    >
                                        <button
                                            type="button"
                                            (click)="deleteTrip(i, j)"
                                            class="btn btn-danger btn-sm"
                                            [disabled]="form.disabled"
                                            *ngIf="
                                                form &&
                                                getItineraryType(i).value ===
                                                    EnumItineraryType.multipleDestinations &&
                                                j > 1
                                            "
                                        >
                                            <fa-icon [icon]="faTrash"></fa-icon>
                                            Supprimer leg
                                        </button>
                                    </div>
                                </li>
                            </ul>

                            <div
                                class="text-center"
                                *ngIf="
                                    form &&
                                    getItineraryType(i).value ===
                                        EnumItineraryType.multipleDestinations
                                "
                            >
                                <button type="button" class="btn btn-primary" (click)="addTrip(i)">
                                    + nouvelle destination
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mb-4">
                <div class="card-header">Filtres compagnies aériennes</div>
                <div class="card-body">
                    <div class="row">
                        <div
                            class="col-md-6 col-lg-4"
                            *ngIf="doesEnquiryPrefillAirlinesSelection(type.value)"
                        >
                            <div class="form-group">
                                <label for="geographicalAreasCode">Zone géographique</label>
                                <div class="form-check">
                                    <input
                                        type="checkbox"
                                        id="worldwide"
                                        class="form-check-input"
                                        [checked]="filterWorldwide"
                                        (click)="toggleWorldwide()"
                                    />
                                    <label for="worldwide">Monde entier</label>
                                </div>

                                <div
                                    class="form-check"
                                    *ngFor="let geographicalArea of getContinentCodes()"
                                >
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        [attr.id]="geographicalArea"
                                        [checked]="
                                            form.value.geographicalAreasCode?.includes(
                                                geographicalArea
                                            )
                                        "
                                        (click)="
                                            toggleCheckboxField(
                                                'geographicalAreasCode',
                                                geographicalArea
                                            )
                                        "
                                    />
                                    <label
                                        class="form-check-label"
                                        [attr.for]="geographicalArea"
                                        [innerHtml]="getContinentLabel(geographicalArea)"
                                    ></label>
                                </div>

                                <div
                                    *ngIf="
                                        form.controls['geographicalAreasCode'].errors &&
                                        form.controls['geographicalAreasCode'].errors['required']
                                    "
                                    class="text-danger"
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>
                        <div
                            [class.col-12]="!doesEnquiryPrefillAirlinesSelection(type.value)"
                            [class.col-md-6]="doesEnquiryPrefillAirlinesSelection(type.value)"
                            [class.col-lg-8]="doesEnquiryPrefillAirlinesSelection(type.value)"
                        >
                            <div class="row justify-content-center">
                                <div class="col-lg-6" *ngIf="type.value !== EnumEnquiryType.cargo">
                                    <div class="form-group">
                                        <label for="capacityMin">Capacité Min</label>
                                        <div class="input-group">
                                            <input
                                                class="form-control"
                                                id="capacityMin"
                                                formControlName="capacityMin"
                                                type="number"
                                                min="0"
                                                [class.is-invalid]="
                                                    (form.touched ||
                                                        form.controls['capacityMin'].touched) &&
                                                    form.controls['capacityMin'].status ===
                                                        'INVALID'
                                                        ? 'danger'
                                                        : ''
                                                "
                                            />
                                            <div class="invalid-tooltip">
                                                <div
                                                    *ngIf="
                                                        (form.touched ||
                                                            form.controls['capacityMin'].touched) &&
                                                        form.controls['capacityMin'].errors &&
                                                        form.controls['capacityMin'].errors[
                                                            'required'
                                                        ]
                                                    "
                                                >
                                                    Ce champ est obligatoire.
                                                </div>
                                            </div>
                                            <span class="input-group-text">pax</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6" *ngIf="type.value !== EnumEnquiryType.cargo">
                                    <div class="form-group">
                                        <label for="capacityMax">Capacité Max</label>
                                        <div class="input-group">
                                            <input
                                                class="form-control"
                                                id="capacityMax"
                                                formControlName="capacityMax"
                                                type="number"
                                                min="0"
                                                [class.is-invalid]="
                                                    (form.touched ||
                                                        form.controls['capacityMax'].touched) &&
                                                    form.controls['capacityMax'].status ===
                                                        'INVALID'
                                                        ? 'danger'
                                                        : ''
                                                "
                                            />

                                            <div class="invalid-tooltip">
                                                <div
                                                    *ngIf="
                                                        (form.touched ||
                                                            form.controls['capacityMax'].touched) &&
                                                        form.controls['capacityMax'].errors &&
                                                        form.controls['capacityMax'].errors[
                                                            'required'
                                                        ]
                                                    "
                                                >
                                                    Ce champ est obligatoire.
                                                </div>
                                            </div>
                                            <span class="input-group-text">pax</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6" *ngIf="type.value === EnumEnquiryType.cargo">
                                    <div class="form-group">
                                        <label for="weightMin">Poids Min</label>
                                        <div class="input-group">
                                            <input
                                                class="form-control"
                                                id="weightMin"
                                                formControlName="weightMin"
                                                type="number"
                                                min="0"
                                                [class.is-invalid]="
                                                    (form.touched ||
                                                        form.controls['weightMin'].touched) &&
                                                    form.controls['weightMin'].status === 'INVALID'
                                                        ? 'danger'
                                                        : ''
                                                "
                                            />

                                            <div class="invalid-tooltip">
                                                <div
                                                    *ngIf="
                                                        (form.touched ||
                                                            form.controls['weightMin'].touched) &&
                                                        form.controls['weightMin'].errors &&
                                                        form.controls['weightMin'].errors[
                                                            'required'
                                                        ]
                                                    "
                                                >
                                                    Ce champ est obligatoire.
                                                </div>
                                            </div>
                                            <span class="input-group-text">kg</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6" *ngIf="type.value === EnumEnquiryType.cargo">
                                    <div class="form-group">
                                        <label for="weightMax">Poids Max</label>
                                        <div class="input-group">
                                            <input
                                                class="form-control"
                                                id="weightMax"
                                                formControlName="weightMax"
                                                type="number"
                                                min="0"
                                                [class.is-invalid]="
                                                    (form.touched ||
                                                        form.controls['weightMax'].touched) &&
                                                    form.controls['weightMax'].status === 'INVALID'
                                                        ? 'danger'
                                                        : ''
                                                "
                                            />

                                            <div class="invalid-tooltip">
                                                <div
                                                    *ngIf="
                                                        (form.touched ||
                                                            form.controls['weightMax'].touched) &&
                                                        form.controls['weightMax'].errors &&
                                                        form.controls['weightMax'].errors[
                                                            'required'
                                                        ]
                                                    "
                                                >
                                                    Ce champ est obligatoire.
                                                </div>
                                            </div>
                                            <span class="input-group-text">kg</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6" *ngIf="type.value === EnumEnquiryType.cargo">
                                    <div class="form-group">
                                        <label for="volumeMin">Volume Min</label>
                                        <div class="input-group">
                                            <input
                                                class="form-control"
                                                id="volumeMin"
                                                formControlName="volumeMin"
                                                type="number"
                                                min="0"
                                                [class.is-invalid]="
                                                    (form.touched ||
                                                        form.controls['volumeMin'].touched) &&
                                                    form.controls['volumeMin'].status === 'INVALID'
                                                        ? 'danger'
                                                        : ''
                                                "
                                            />

                                            <div class="invalid-tooltip">
                                                <div
                                                    *ngIf="
                                                        (form.touched ||
                                                            form.controls['volumeMin'].touched) &&
                                                        form.controls['volumeMin'].errors &&
                                                        form.controls['volumeMin'].errors[
                                                            'required'
                                                        ]
                                                    "
                                                >
                                                    Ce champ est obligatoire.
                                                </div>
                                            </div>
                                            <span class="input-group-text">m<sup>3</sup></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6" *ngIf="type.value === EnumEnquiryType.cargo">
                                    <div class="form-group">
                                        <label for="volumeMax">Volume Max</label>
                                        <div class="input-group">
                                            <input
                                                class="form-control"
                                                id="volumeMax"
                                                formControlName="volumeMax"
                                                type="number"
                                                min="0"
                                                [class.is-invalid]="
                                                    (form.touched ||
                                                        form.controls['volumeMax'].touched) &&
                                                    form.controls['volumeMax'].status === 'INVALID'
                                                        ? 'danger'
                                                        : ''
                                                "
                                            />

                                            <div class="invalid-tooltip">
                                                <div
                                                    *ngIf="
                                                        (form.touched ||
                                                            form.controls['volumeMax'].touched) &&
                                                        form.controls['volumeMax'].errors &&
                                                        form.controls['volumeMax'].errors[
                                                            'required'
                                                        ]
                                                    "
                                                >
                                                    Ce champ est obligatoire.
                                                </div>
                                            </div>
                                            <span class="input-group-text">m<sup>3</sup></span>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="col-lg-6"
                                    *ngIf="
                                        type.value !== EnumEnquiryType.helico &&
                                        doesEnquiryPrefillAirlinesSelection(type.value)
                                    "
                                >
                                    <div class="form-group">
                                        <label for="filterBy">Filtré par</label>
                                        <select
                                            class="form-select"
                                            id="filterBy"
                                            formControlName="filterBy"
                                            [class.is-invalid]="
                                                (form.touched ||
                                                    form.controls['filterBy'].touched) &&
                                                form.controls['filterBy'].status === 'INVALID'
                                                    ? 'danger'
                                                    : ''
                                            "
                                            (change)="updateFilterBy()"
                                        >
                                            <option
                                                [value]="filterBy"
                                                *ngFor="
                                                    let filterBy of getEnquiryPrefilterAirlines()
                                                "
                                                [innerHtml]="
                                                    getEnumEnquiryPrefilterAirlineLabel(filterBy)
                                                "
                                            ></option>
                                        </select>

                                        <div
                                            *ngIf="
                                                form.controls['filterBy'].errors &&
                                                form.controls['filterBy'].errors['required']
                                            "
                                            class="text-danger"
                                        >
                                            Ce champ est obligatoire.
                                        </div>
                                    </div>
                                    <div
                                        class="form-group"
                                        *ngIf="form.value.filterBy === 'target'"
                                    >
                                        <label for="target">Cible</label>
                                        <div
                                            class="form-check"
                                            *ngFor="let target of getEnquiryTargets()"
                                        >
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                [attr.id]="target"
                                                [checked]="form.value.targets?.includes(target)"
                                                (click)="toggleCheckboxField('targets', target)"
                                            />
                                            <label
                                                class="form-check-label"
                                                [attr.for]="target"
                                                [innerText]="getEnquiryTargetLabel(target)"
                                            ></label>
                                        </div>

                                        <div
                                            *ngIf="
                                                form.controls['targets'].errors &&
                                                form.controls['targets'].errors['required']
                                            "
                                            class="text-danger"
                                        >
                                            Ce champ est obligatoire.
                                        </div>
                                    </div>

                                    <div
                                        class="form-group"
                                        *ngIf="form.value.filterBy === 'aircraftModel'"
                                    >
                                        <label for="aircraftModelId">Modèle</label>
                                        <select
                                            class="form-select"
                                            id="aircraftModelId"
                                            formControlName="aircraftModelId"
                                            [class.is-invalid]="
                                                (form.touched ||
                                                    form.controls['aircraftModelId'].touched) &&
                                                form.controls['aircraftModelId'].status ===
                                                    'INVALID'
                                                    ? 'danger'
                                                    : ''
                                            "
                                        >
                                            <option
                                                [value]="aircraftModel.id"
                                                *ngFor="let aircraftModel of aircraftModels"
                                                [innerHtml]="aircraftModel.title"
                                            ></option>
                                        </select>

                                        <div class="invalid-tooltip">
                                            <div
                                                *ngIf="
                                                    (form.touched ||
                                                        form.controls['aircraftModelId'].touched) &&
                                                    form.controls['aircraftModelId'].errors &&
                                                    form.controls['aircraftModelId'].errors[
                                                        'required'
                                                    ]
                                                "
                                            >
                                                Ce champ est obligatoire.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mb-4">
        <div class="col">
            <button
                type="button"
                [routerLink]="enquiryId ? '/admin/enquiries/' + enquiryId : '/admin'"
                class="btn btn-secondary"
                [disabled]="form.disabled"
            >
                Annuler
            </button>
        </div>
        <div class="col text-end">
            <button type="submit" class="btn btn-primary" [disabled]="form.disabled || !form.valid">
                Sauvegarder
            </button>
        </div>
    </div>
</form>

<div class="modal" tabindex="-1" id="modal-fly-information" #modalFlyInformation>
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Explication du calcul du temps de vol</h5>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <div class="modal-body" [innerHtml]="flyInformationContent"></div>
        </div>
    </div>
</div>

<div class="modal" tabindex="-1" role="dialog" #modalUpload>
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Envoie d'un document au serveur</h5>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <input
                        type="file"
                        #inputFile
                        class="form-control"
                        (change)="changeInputFile($event)"
                    />
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                    Annuler
                </button>
                <button
                    type="button"
                    class="btn btn-primary"
                    (click)="upload()"
                    [disabled]="form.disabled"
                >
                    Envoyer
                </button>
            </div>
        </div>
    </div>
</div>
