<div class="accordion" id="accordion-checklist" *ngIf="loadingFlightChecklistTemplate">
    <div class="accordion-item" *ngFor="let number of [].constructor(3); let i = index">
        <h2 class="accordion-header">
            <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                [attr.data-bs-target]="'#collapse-' + i"
                aria-expanded="true"
                [attr.aria-controls]="'collapse-' + i"
            >
                <div class="skeleton-text"></div>
            </button>
        </h2>
        <div [id]="'collapse-' + i" class="accordion-collapse collapse show">
            <ul class="list-group list-group-flush">
                <li class="list-group-item" *ngFor="let number2 of [].constructor(3)">
                    <div class="skeleton-text"></div>
                </li>
            </ul>
        </div>
    </div>
</div>
<div
    class="alert alert-warning text-center mb-0"
    *ngIf="!loadingFlightChecklistTemplate && !flightChecklistTemplate"
>
    Aucun modèle de checklist trouvé pour ce département.
</div>
<div
    class="accordion"
    id="accordion-checklist"
    *ngIf="!loadingFlightChecklistTemplate && flightChecklistTemplate"
>
    <div
        class="accordion-item"
        *ngFor="let module of flightChecklistTemplate?.modules; let i = index"
    >
        <h2 class="accordion-header">
            <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                [attr.data-bs-target]="'#collapse-' + i"
                aria-expanded="true"
                [attr.aria-controls]="'collapse-' + i"
                [innerText]="module.title"
            ></button>
        </h2>
        <div [id]="'collapse-' + i" class="accordion-collapse collapse show">
            <ul class="list-group list-group-flush">
                <ng-container *ngFor="let field of module.fields">
                    <li
                        class="list-group-item"
                        *ngIf="flightChecklistFieldValuesById[field.id]"
                        [class.list-group-item-warning]="
                            flightChecklistFieldValuesById[field.id].selectedOption?.status ===
                            EnumFlightChecklistTemplateFieldOptionStatus.warning
                        "
                        [class.list-group-item-danger]="
                            flightChecklistFieldValuesById[field.id].selectedOption?.status ===
                            EnumFlightChecklistTemplateFieldOptionStatus.danger
                        "
                        [class.list-group-item-success]="
                            flightChecklistFieldValuesById[field.id].selectedOption?.status ===
                            EnumFlightChecklistTemplateFieldOptionStatus.success
                        "
                        [class.list-group-item-info]="
                            flightChecklistFieldValuesById[field.id].selectedOption?.status ===
                            EnumFlightChecklistTemplateFieldOptionStatus.info
                        "
                        [class.list-group-item-primary]="
                            flightChecklistFieldValuesById[field.id].selectedOption?.status ===
                            EnumFlightChecklistTemplateFieldOptionStatus.primary
                        "
                        [class.list-group-item-secondary]="
                            flightChecklistFieldValuesById[field.id].selectedOption?.status ===
                            EnumFlightChecklistTemplateFieldOptionStatus.secondary
                        "
                    >
                        <div class="checklist-field" *ngIf="editingChecklistFieldId !== field.id">
                            <div class="btn-toolbar">
                                <div class="btn-group">
                                    <button
                                        type="button"
                                        class="btn btn-light btn-sm"
                                        rel="tooltip"
                                        data-bs-title="Historique"
                                        (click)="showFieldHistory(field.id)"
                                        *ngIf="
                                            flightChecklistFieldValuesById[field.id].history.length
                                        "
                                    >
                                        <fa-icon [icon]="faClockRotateLeft"></fa-icon>
                                    </button>
                                    <button
                                        type="button"
                                        class="btn btn-light btn-sm"
                                        rel="tooltip"
                                        data-bs-title="Éditer"
                                        (click)="editChecklistField(field.id)"
                                        *ngIf="hasAclAccess(EnumAcl.flightChecklistsEdit)"
                                    >
                                        <fa-icon [icon]="faEdit"></fa-icon>
                                    </button>
                                </div>
                            </div>
                            <div
                                class="checklist-field-label"
                                [innerText]="flightChecklistFieldValuesById[field.id].field.text"
                            ></div>
                            <div
                                class="checklist-field-value"
                                *ngIf="
                                    flightChecklistFieldValuesById[field.id].field.type !==
                                    EnumFlightChecklistTemplateFieldType.select
                                "
                            >
                                <span
                                    *ngIf="flightChecklistFieldValuesById[field.id].value?.value"
                                    [innerText]="
                                        flightChecklistFieldValuesById[field.id].value.value
                                    "
                                ></span>
                                <em
                                    class="text-muted"
                                    *ngIf="!flightChecklistFieldValuesById[field.id].value?.value"
                                    >Vide</em
                                >
                            </div>
                            <div
                                class="checklist-field-value"
                                *ngIf="
                                    flightChecklistFieldValuesById[field.id].field.type ===
                                    EnumFlightChecklistTemplateFieldType.select
                                "
                            >
                                <span
                                    *ngIf="flightChecklistFieldValuesById[field.id].selectedOption"
                                    [innerText]="
                                        flightChecklistFieldValuesById[field.id].selectedOption
                                            ?.text
                                    "
                                ></span>
                                <em
                                    class="text-muted"
                                    *ngIf="!flightChecklistFieldValuesById[field.id].selectedOption"
                                    >Vide</em
                                >
                            </div>
                        </div>
                        <ul
                            class="list-group mt-2"
                            *ngIf="
                                editingChecklistFieldId !== field.id &&
                                showHistoryFieldId === field.id
                            "
                        >
                            <li
                                class="list-group-item"
                                *ngFor="
                                    let item of flightChecklistFieldValuesById[field.id].history
                                "
                                [class.list-group-item-warning]="
                                    getFieldStatusFromOption(field.id, item.value) ===
                                    EnumFlightChecklistTemplateFieldOptionStatus.warning
                                "
                                [class.list-group-item-danger]="
                                    getFieldStatusFromOption(field.id, item.value) ===
                                    EnumFlightChecklistTemplateFieldOptionStatus.danger
                                "
                                [class.list-group-item-success]="
                                    getFieldStatusFromOption(field.id, item.value) ===
                                    EnumFlightChecklistTemplateFieldOptionStatus.success
                                "
                                [class.list-group-item-info]="
                                    getFieldStatusFromOption(field.id, item.value) ===
                                    EnumFlightChecklistTemplateFieldOptionStatus.info
                                "
                                [class.list-group-item-primary]="
                                    getFieldStatusFromOption(field.id, item.value) ===
                                    EnumFlightChecklistTemplateFieldOptionStatus.primary
                                "
                                [class.list-group-item-secondary]="
                                    getFieldStatusFromOption(field.id, item.value) ===
                                    EnumFlightChecklistTemplateFieldOptionStatus.secondary
                                "
                            >
                                <strong
                                    [innerText]="getFieldValueFromOption(field.id, item.value)"
                                ></strong>
                                par
                                <a
                                    [routerLink]="'/admin/users/' + item.userId"
                                    target="_blank"
                                    [innerText]="item.userFullName"
                                ></a>
                                le {{ item.date | date : "dd/MM/yyyy à HH:mm" }}
                            </li>
                        </ul>
                        <div class="checklist-field" *ngIf="editingChecklistFieldId === field.id">
                            <div
                                class="checklist-field-label"
                                [innerText]="flightChecklistFieldValuesById[field.id].field.text"
                            ></div>
                            <div class="checklist-field-value">
                                <div
                                    class="form-group mb-0"
                                    *ngIf="
                                        field.type === EnumFlightChecklistTemplateFieldType.select
                                    "
                                >
                                    <div class="input-group">
                                        <select
                                            class="form-select"
                                            [id]="'checklist-field-' + field.id"
                                            [(ngModel)]="
                                                flightChecklistFieldValuesById[field.id].value.value
                                            "
                                        >
                                            <option [ngValue]="null">-- Choisissez --</option>
                                            <option
                                                *ngFor="let option of field.options"
                                                [value]="option.id"
                                                [innerText]="option.text"
                                            ></option>
                                        </select>
                                        <button
                                            type="button"
                                            class="btn btn-danger"
                                            rel="tooltip"
                                            data-bs-title="Annuler"
                                            (click)="clearEditChecklistField()"
                                        >
                                            <fa-icon [icon]="faCancel"></fa-icon>
                                        </button>
                                        <button
                                            type="button"
                                            class="btn btn-success"
                                            rel="tooltip"
                                            data-bs-title="Sauvegarder"
                                            (click)="saveEditChecklistField()"
                                        >
                                            <fa-icon [icon]="faCheck"></fa-icon>
                                        </button>
                                    </div>
                                </div>
                                <div
                                    class="form-group mb-0"
                                    *ngIf="
                                        field.type !== EnumFlightChecklistTemplateFieldType.select
                                    "
                                >
                                    <div class="input-group">
                                        <input
                                            type="text"
                                            class="form-control"
                                            [id]="'checklist-field-' + field.id"
                                            [(ngModel)]="
                                                flightChecklistFieldValuesById[field.id].value.value
                                            "
                                        />
                                        <button
                                            type="button"
                                            class="btn btn-danger"
                                            rel="tooltip"
                                            data-bs-title="Annuler"
                                            (click)="clearEditChecklistField()"
                                        >
                                            <fa-icon [icon]="faCancel"></fa-icon>
                                        </button>
                                        <button
                                            type="button"
                                            class="btn btn-success"
                                            rel="tooltip"
                                            data-bs-title="Sauvegarder"
                                            (click)="saveEditChecklistField()"
                                        >
                                            <fa-icon [icon]="faCheck"></fa-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ng-container>
            </ul>
        </div>
    </div>
</div>
