<div class="row justify-content-center">
    <div class="col-md-6">
        <div class="card mb-4">
            <div class="card-body" *ngIf="loading">
                <h3><div class="skeleton-text"></div></h3>
            </div>
            <div class="card-body" *ngIf="!loading && exportRequest">
                <ul class="list-unstyled">
                    <li>
                        <strong>Type</strong> :
                        {{ getExportRequestLabel(exportRequest) }}
                    </li>
                    <li>
                        <strong>Status</strong> :
                        <span
                            class="badge text-bg-primary"
                            [class.text-bg-warning]="
                                exportRequest.status === EnumExportRequestStatus.requested
                            "
                            [class.text-bg-primary]="
                                exportRequest.status === EnumExportRequestStatus.ongoing
                            "
                            [class.text-bg-success]="
                                exportRequest.status === EnumExportRequestStatus.completed
                            "
                            [innerText]="getExportRequestStatusLabel(exportRequest.status)"
                        ></span>
                    </li>
                    <li>
                        <strong>Date de création</strong> :
                        {{ exportRequest.created | date : "dd/MM/yyyy HH:mm" }}
                    </li>
                    <li
                        *ngIf="
                            exportRequest.filePath &&
                            (true || exportRequest.status === EnumExportRequestStatus.completed)
                        "
                    >
                        <strong>Fichier</strong>
                        <button
                            type="button"
                            (click)="download()"
                            class="btn btn-primary ms-2 btn-sm"
                        >
                            Télécharger
                        </button>
                    </li>
                </ul>

                <div class="text-center mt-4">
                    <a
                        [routerLink]="'/admin/export-requests/' + exportRequest.id + '/edit'"
                        class="btn btn-primary mr-4"
                        *ngIf="hasAclAccess(EnumAcl.exportRequestsEdit)"
                        >Éditer</a
                    >
                    <button
                        type="button"
                        (click)="delete()"
                        class="btn btn-primary ml-4"
                        *ngIf="hasAclAccess(EnumAcl.exportRequestsDelete)"
                    >
                        Supprimer
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
