import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { PaginationService } from '../../../services/pagination.service';
import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';
import { EnumEnquiryType, getDepartmentList } from 'src/app/enums/enquiry-type.enum';
import { faEdit, faEye, faRefresh, faTrash } from '@fortawesome/free-solid-svg-icons';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { Subscription } from 'rxjs';
import { IAirline, getAirlineAddress } from 'src/app/interfaces/airline.interface';
import { getContinentLabel, getCountryLabel } from 'src/app/enums/continent-code.enum';
import { AirlineService } from 'src/app/services/airlines/airlines.service';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs/breadcrumbs.service';

enum EnumTab {
  fleet = 'fleet',
  airlineSupervisors = 'airlineSupervisors',
  crews = 'crews',
  enquiries = 'enquiries',
  invoices = 'invoices',
  documents = 'documents',
  contacts = 'contacts'
}

@Component({
  selector: 'app-airline-view',
  templateUrl: './airline-view.component.html',
  styleUrls: ['./airline-view.component.scss']
})
export class AirlineViewComponent implements OnInit {
  EnumEnquiryType = EnumEnquiryType;
  EnumAcl = EnumAcl;
  EnumTab = EnumTab;

  faEye = faEye;
  faEdit = faEdit;
  faTrash = faTrash;
  faRefresh = faRefresh;

  getAirlineAddress = getAirlineAddress;
  getCountryLabel = getCountryLabel;
  getContinentLabel = getContinentLabel;
  getDepartmentList = getDepartmentList;

  loading: boolean = false;
  airlineId: string;
  airline: IAirline | null = null;
  isLogged: boolean = false;

  tabs: {
    title: string;
    value: EnumTab;
  }[] = [
    {
      title: 'Flottes',
      value: EnumTab.fleet
    },
    {
      title: 'Dossiers',
      value: EnumTab.enquiries
    },
    // {
    //   title: 'Factures',
    //   value: EnumTab.invoices
    // },
    {
      title: 'Superviseurs',
      value: EnumTab.airlineSupervisors
    },
    {
      title: 'Équipage',
      value: EnumTab.crews
    },
    {
      title: 'Contacts',
      value: EnumTab.contacts
    },
    {
      title: 'Documents',
      value: EnumTab.documents
    }
  ];
  currentTab: EnumTab = EnumTab.fleet;

  private subscriptions = new Subscription();

  constructor(
    private remoteService: RemoteService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public paginationService: PaginationService,
    private aclService: AclService,
    private airlineService: AirlineService,
    private breadcrumbsService: BreadcrumbsService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(() => {
      this.airlineId = this.activatedRoute.snapshot.paramMap.get('airlineId');

      this.loadData();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  loadData(): void {
    if (this.isLogged) {
      this.loadAirline();
      this.remoteService.loadAircraftModels();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  async loadAirline(): Promise<void> {
    this.loading = true;

    this.subscriptions.add(
      this.airlineService.getFromId(this.airlineId).subscribe((airline: IAirline | null) => {
        this.airline = airline;

        if (this.airline) {
          this.breadcrumbsService.setCurrentItem({
            id: this.airline.id,
            text: this.airline.title
          });
        }

        this.loading = false;
      })
    );
  }

  delete(): void {
    if (this.hasAclAccess(EnumAcl.airlinesDelete)) {
      const result = confirm(
        'La suppression de la compagnie aérienne sera permanent. Êtes-vous sûr de vouloir continuer?'
      );

      if (result) {
        this.remoteService
          .deleteDocumentInCollection('airlines', this.airlineId)
          .then(() => {
            this.router.navigate(['/admin/airlines']);

            alert('La suppression a été effectuée avec succès.');
          })
          .catch(err => {
            console.log(err);
          });
      }
    }
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  updateCurrentTab(tab: EnumTab): void {
    this.currentTab = tab;
  }
}
