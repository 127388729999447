import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { PaginationService } from '../../../services/pagination.service';
import { AclService } from '../../../services/acl.service';
import slugify from 'slugify';

import { EnumAcl } from 'src/app/enums/acl.enum';
import { getFboFullAddress, IFbo } from 'src/app/interfaces/fbo.interface';
import { FboService } from 'src/app/services/fbos/fbos.service';
import { faCancel, faCheckCircle, faEdit, faEye } from '@fortawesome/free-solid-svg-icons';
import { AlertService } from 'src/app/services/alert/alert.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { IAirport } from 'src/app/interfaces/airport.interface';
import { AirportService } from 'src/app/services/airports/airports.service';

enum EnumStatus {
  draft = 'draft',
  readyToMerge = 'readyToMerge'
}

interface IFbosToMerge {
  status: EnumStatus;
  fbos: IFbo[];
}

@Component({
  selector: 'app-duplicate-fbos',
  templateUrl: './duplicate-fbos.component.html',
  styleUrls: ['./duplicate-fbos.component.scss']
})
export class DuplicateFbosComponent implements OnInit, OnDestroy {
  EnumAcl = EnumAcl;

  getFboFullAddress = getFboFullAddress;

  faEye = faEye;
  faEdit = faEdit;
  faCheckCircle = faCheckCircle;
  faCancel = faCancel;

  loadingFbos: boolean = false;
  fbos: IFbo[] = [];
  fbosToMerge: IFbosToMerge[] = [];
  analysingDuplicateFbos: boolean = false;

  airportsObj: { [id: string]: IAirport | null } = {};

  EnumStatus = EnumStatus;

  private subscriptions = new Subscription();

  constructor(
    public paginationService: PaginationService,
    private aclService: AclService,
    private fboService: FboService,
    private alertService: AlertService,
    private loaderService: LoaderService,
    private airportService: AirportService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.aclService.checkAclAccess(EnumAcl.fbosDuplicate);

    this.loadFbos();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  loadFbos(): void {
    this.loadingFbos = true;

    this.subscriptions.add(
      this.fboService.getAll().subscribe((fbos: IFbo[]) => {
        this.fbos = fbos;

        this.fbos.sort((a, b) => (a.created < b.created ? -1 : 1));

        for (const fbo of this.fbos) {
          this.loadAirport(fbo.airportId);
        }

        this.loadingFbos = false;

        this.analyseDuplicateFbos();
      })
    );
  }

  analyseDuplicateFbos(): void {
    this.analysingDuplicateFbos = true;

    this.fbosToMerge = [];

    const fbosByKeys: { [key: string]: IFbo[] } = {};

    const slugifyOptions = {
      replacement: '-', // replace spaces with replacement character, defaults to `-`
      lower: true, // convert to lower case, defaults to `false`
      strict: true, // strip special characters except replacement, defaults to `false`
      trim: true
    };

    for (const fbo of this.fbos) {
      const key: string = [fbo.airportId, slugify(fbo.name, slugifyOptions)].join('_');

      if (typeof fbosByKeys[key] === 'undefined') {
        fbosByKeys[key] = [];
      }

      fbosByKeys[key].push(fbo);
    }

    for (const key in fbosByKeys) {
      if (fbosByKeys[key].length === 1) {
        delete fbosByKeys[key];
      } else {
        this.fbosToMerge.push({
          status: EnumStatus.draft,
          fbos: fbosByKeys[key]
        });
      }
    }

    this.analysingDuplicateFbos = false;
  }

  mergeFbos(i: number): void {
    this.fbosToMerge[i].status = EnumStatus.readyToMerge;
  }

  cancelMergeFbos(i: number): void {
    this.fbosToMerge[i].status = EnumStatus.draft;
  }

  async confirmMergeFbos(i: number, finalFboId: string): Promise<void> {
    this.alertService.presentConfirm(
      'Êtes-vous sûr de votre choix ? Cela supprimera les doublons.',
      async () => {
        console.log(this.fbosToMerge[i], finalFboId);

        this.loaderService.presentLoader();

        try {
          const fboIds: string[] = [];

          for (const fbo of this.fbosToMerge[i].fbos) {
            if (fbo.id !== finalFboId) {
              fboIds.push(fbo.id);
            }
          }

          await this.fboService.mergeFbos(fboIds, finalFboId);

          await this.loaderService.hideLoaderOnSuccess();
        } catch (err) {
          await this.loaderService.hideLoaderOnFailure(err);
        }
      }
    );
  }

  loadAirport(airportId: string): void {
    if (typeof this.airportsObj[airportId] === 'undefined') {
      this.airportsObj[airportId] = null;

      this.subscriptions.add(
        this.airportService.getFromId(airportId).subscribe((airport: IAirport) => {
          this.airportsObj[airportId] = airport;
        })
      );
    }
  }
}
