import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { faSuitcaseRolling, faUsers, faUtensils } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { format } from 'date-fns';
import { fr, enUS } from 'date-fns/locale';
import { Subscription } from 'rxjs';
import { EnumEnquiryType } from 'src/app/enums/enquiry-type.enum';
import { EnumLanguage } from 'src/app/enums/language.enum';
import { IAirlineSupervisor } from 'src/app/interfaces/airline-supervisor.interface';
import {
  IAirport,
  getAirportCity,
  getAirportUTCTimeString
} from 'src/app/interfaces/airport.interface';
import { ICrew } from 'src/app/interfaces/crew.interface';
import {
  getEnquiryFlightFlyTimeInMin,
  IEnquiryFlight
} from 'src/app/interfaces/enquiry-flight.interface';
import { IEnquiry } from 'src/app/interfaces/enquiry.interface';
import { getFboAddressDetails, IFbo } from 'src/app/interfaces/fbo.interface';
import { IFlightBriefCatering } from 'src/app/interfaces/flight-brief-catering.interface';
import {
  IFlightBrief,
  IFlightBriefAirportsAddressDetails,
  IFlightBriefTrip,
  getPassengerLabel,
  getTimeBoardingBeforeDepartureForEnquiryFlight,
  getTimeBoardingBeforeDepartureForTrip
} from 'src/app/interfaces/flight-brief.interface';
import { convertMinutesToTime } from 'src/app/misc.utils';
import { AirlineSupervisorService } from 'src/app/services/airline-supervisors/airline-supervisors.service';
import { CrewService } from 'src/app/services/crews/crews.service';
import { FboService } from 'src/app/services/fbos/fbos.service';
import { FlightBriefCateringService } from 'src/app/services/flight-brief-caterings/flight-brief-caterings.service';

@Component({
  selector: 'app-flight-brief-page',
  templateUrl: './flight-brief-page.component.html',
  styleUrls: ['./flight-brief-page.component.scss']
})
export class FlightBriefPageComponent implements OnInit, OnDestroy {
  @Input() flightBrief: IFlightBrief | null = null;
  @Input() enquiry: IEnquiry | null = null;
  @Input() trips: IFlightBriefTrip[] = [];
  @Input() enquiryFlights: IEnquiryFlight[] = [];
  @Input() airportsObj: { [key: string]: IAirport } = {};

  faUsers = faUsers;
  faUtensils = faUtensils;
  faSuitcaseRolling = faSuitcaseRolling;

  translationObj: { [key: string]: any } = {};

  getAirportUTCTimeString = getAirportUTCTimeString;
  getTimeBoardingBeforeDepartureForTrip = getTimeBoardingBeforeDepartureForTrip;
  getTimeBoardingBeforeDepartureForEnquiryFlight = getTimeBoardingBeforeDepartureForEnquiryFlight;
  getAirportCity = getAirportCity;
  getPassengerLabel = getPassengerLabel;
  getEnquiryFlightFlyTimeInMin = getEnquiryFlightFlyTimeInMin;
  convertMinutesToTime = convertMinutesToTime;
  getFboAddressDetails = getFboAddressDetails;

  EnumLanguage = EnumLanguage;
  EnumEnquiryType = EnumEnquiryType;

  imgsToBase64: {
    googleMapStaticUrls: { [key: string]: string };
    imageOutsideUrl: string | null;
    imageInsideUrl: string | null;
  } = {
    googleMapStaticUrls: {},
    imageOutsideUrl: null,
    imageInsideUrl: null
  };

  fbosObj: { [id: string]: IFbo | null } = {};
  crewsObj: { [id: string]: ICrew | null } = {};
  supervisorsObj: { [id: string]: IAirlineSupervisor | null } = {};
  flightBriefCateringsObj: { [id: string]: IFlightBriefCatering | null } = {};

  private subscriptions = new Subscription();
  subscriptionsTranslations = new Subscription();

  constructor(
    private translateService: TranslateService,
    private fboService: FboService,
    private crewService: CrewService,
    private airlineSupervisorService: AirlineSupervisorService,
    private flightBriefCateringService: FlightBriefCateringService
  ) {}

  ngOnInit(): void {
    this.loadFlightBriefCaterings();
    this.loadTranslation();
    this.refreshFbosForAllEnquiryFlights();
    this.refreshCrewsAndSupervisorsForAllEnquiryFlights();

    this.loadAllImgBase64();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.subscriptionsTranslations.unsubscribe();
  }

  refreshFbosForAllEnquiryFlights(): void {
    for (const enquiryFlight of this.enquiryFlights) {
      for (const field of ['departFboId', 'arrivalFboId']) {
        if (enquiryFlight[field]) {
          this.loadFbo(enquiryFlight[field]);
        }
      }
    }
  }

  refreshCrewsAndSupervisorsForAllEnquiryFlights(): void {
    for (const enquiryFlight of this.enquiryFlights) {
      if (enquiryFlight.airlineId) {
        this.loadCrewsForAirline(enquiryFlight.airlineId);
        this.loadSupervisorsForAirline(enquiryFlight.airlineId);
      }
    }
  }

  loadTranslation(): void {
    if (this.flightBrief) {
      if (this.subscriptionsTranslations) {
        this.subscriptionsTranslations.unsubscribe();
      }

      this.subscriptionsTranslations = new Subscription();

      this.subscriptionsTranslations.add(
        this.translateService
          .getTranslation(this.flightBrief.language)
          .subscribe((translationObj: any) => {
            this.translationObj[this.flightBrief.language] = translationObj;
          })
      );
    }
  }

  getDisplayedAirportsAddressDetailsForTrip(
    trip: IFlightBriefTrip
  ): IFlightBriefAirportsAddressDetails[] {
    const airportsId: string[] = [];
    const airportsAddressDetails: IFlightBriefAirportsAddressDetails[] = [];

    for (const field of ['airportDepartId', 'airportArrivalId']) {
      if (trip[field] && !airportsId.includes(trip[field])) {
        airportsId.push(trip[field]);
      }
    }

    for (const airportAddressDetails of this.flightBrief?.airportsAddressDetails) {
      if (airportsId.includes(airportAddressDetails.airportId)) {
        airportAddressDetails.text = airportAddressDetails.text.replaceAll(' Adresse :', '');

        airportsAddressDetails.push(airportAddressDetails);
      }
    }

    return airportsAddressDetails;
  }

  getDisplayedAirportsAddressDetailsForEnquiryFlight(
    enquiryFlight: IEnquiryFlight
  ): IFlightBriefAirportsAddressDetails[] {
    const airportsAddressDetails: IFlightBriefAirportsAddressDetails[] = [];

    const alreadyAddedFboIds: string[] = [];
    for (const field of ['depart', 'arrival']) {
      for (const airportAddressDetails of this.flightBrief?.airportsAddressDetails) {
        if (
          enquiryFlight[field + 'FboEnabled'] &&
          enquiryFlight[field + 'FboId'] &&
          enquiryFlight[field + 'FboId'] === airportAddressDetails.fboId &&
          !alreadyAddedFboIds.includes(airportAddressDetails.fboId)
        ) {
          airportAddressDetails.text = airportAddressDetails.text.replaceAll(' Adresse :', '');

          airportsAddressDetails.push(airportAddressDetails);
          alreadyAddedFboIds.push(airportAddressDetails.fboId);
        }
      }
    }

    return airportsAddressDetails;
  }

  formatTripDate(dateStr: string): string {
    dateStr = format(new Date(dateStr), 'EEEE dd MMMM yyyy', {
      locale: this.flightBrief?.language === 'fr' ? fr : enUS
    });

    const dateSplitted: string[] = dateStr.split(' ');

    for (let i in dateSplitted) {
      dateSplitted[i] = this.capitalizeFirstLetter(dateSplitted[i]);
    }

    return dateSplitted.join(' ');
  }

  private capitalizeFirstLetter(text: string): string {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  async loadAllImgBase64(): Promise<void> {
    try {
      for (const imgFieldName in this.imgsToBase64) {
        if (imgFieldName === 'googleMapStaticUrls') {
          this.imgsToBase64.googleMapStaticUrls = {};

          if (this.trips.length) {
            for (const trip of this.trips) {
              if (trip.googleMapStaticUrl) {
                this.imgsToBase64.googleMapStaticUrls[trip.id] =
                  await this.loadImgBase64FromRemoteUrl(trip.googleMapStaticUrl);
              }
            }
          }

          if (this.flightBrief.googleMapStaticUrlForEnquiryFlight) {
            for (const enquiryFlightId in this.flightBrief.googleMapStaticUrlForEnquiryFlight) {
              if (this.flightBrief.googleMapStaticUrlForEnquiryFlight[enquiryFlightId]) {
                this.imgsToBase64.googleMapStaticUrls[enquiryFlightId] =
                  await this.loadImgBase64FromRemoteUrl(
                    this.flightBrief.googleMapStaticUrlForEnquiryFlight[enquiryFlightId]
                  );
              }
            }
          }
        } else {
          if (this.flightBrief[imgFieldName]) {
            try {
              this.imgsToBase64[imgFieldName] = await this.loadImgBase64FromRemoteUrl(
                this.flightBrief[imgFieldName]
              );
            } catch (err) {
              this.imgsToBase64[imgFieldName] = this.flightBrief[imgFieldName];
            }
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  loadImgBase64FromRemoteUrl(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      var xhr = new XMLHttpRequest();

      xhr.responseType = 'arraybuffer';
      xhr.open('GET', url);

      xhr.onload = () => {
        var base64, binary, bytes, mediaType;

        bytes = new Uint8Array(xhr.response);
        //NOTE String.fromCharCode.apply(String, ...
        //may cause "Maximum call stack size exceeded"
        binary = [].map
          .call(bytes, byte => {
            return String.fromCharCode(byte);
          })
          .join('');
        mediaType = xhr.getResponseHeader('content-type');
        base64 = ['data:', mediaType ? mediaType + ';' : '', 'base64,', btoa(binary)].join('');
        resolve(base64);
      };
      xhr.onerror = err => {
        reject(err);
      };
      xhr.send();
    });
  }

  loadFbo(fboId: string): void {
    if (typeof this.fbosObj[fboId] === 'undefined') {
      this.fbosObj[fboId] = null;

      this.subscriptions.add(
        this.fboService.getFromId(fboId).subscribe((fbo: IFbo | null) => {
          this.fbosObj[fboId] = fbo;
        })
      );
    }
  }

  loadCrewsForAirline(airlineId: string): void {
    this.subscriptions.add(
      this.crewService.getForAirline(airlineId).subscribe((crews: ICrew[]) => {
        for (const crew of crews) {
          this.crewsObj[crew.id] = crew;
        }
      })
    );
  }

  loadSupervisorsForAirline(airlineId: string): void {
    this.subscriptions.add(
      this.airlineSupervisorService
        .getForAirline(airlineId)
        .subscribe((supervisors: IAirlineSupervisor[]) => {
          for (const supervisor of supervisors) {
            this.supervisorsObj[supervisor.id] = supervisor;
          }
        })
    );
  }

  loadFlightBriefCaterings(): void {
    this.subscriptions.add(
      this.flightBriefCateringService
        .getAll()
        .subscribe((flightBriefCaterings: IFlightBriefCatering[]) => {
          for (const flightBriefCatering of flightBriefCaterings) {
            this.flightBriefCateringsObj[flightBriefCatering.id] = flightBriefCatering;
          }
        })
    );
  }
}
