<div class="row justify-content-center">
    <div class="col-md-6">
        <div class="card">
            <div class="card-body">
                <ul *ngIf="loading" class="list-unstyled">
                    <li *ngFor="let number of [].constructor(4)">
                        <div class="skeleton-text"></div>
                    </li>
                </ul>
                <div *ngIf="!loading && aircraftModel">
                    <ul class="list-unstyled">
                        <li><strong>Id</strong> : {{ aircraftModel.slug }}</li>
                        <li><strong>Nom</strong> : {{ aircraftModel.title }}</li>
                        <li><strong>Famille</strong> : {{ aircraftModel.family.toUpperCase() }}</li>
                        <li>
                            <strong>Cible</strong> :
                            {{ getEnquiryTargetLabel(aircraftModel.target) }}
                        </li>
                        <li *ngIf="aircraftModel.speedInKts">
                            <strong>Vitesse</strong> :
                            {{
                                isNaN(aircraftModel.speedInKts)
                                    ? ""
                                    : aircraftModel.speedInKts + " kts"
                            }}
                        </li>
                        <li *ngIf="aircraftModel.rangeInKms">
                            <strong>Autonomie</strong> :
                            {{
                                isNaN(aircraftModel.rangeInKms)
                                    ? ""
                                    : aircraftModel.rangeInKms + " kms"
                            }}
                        </li>
                        <li><strong>Poids</strong> : {{ aircraftModel.weight }} kg</li>
                        <li><strong>Volume</strong> : {{ aircraftModel.volume }} m<sup>3</sup></li>
                        <li>
                            <strong>Créé le :</strong>
                            {{ aircraftModel.created | date : "dd/MM/yyyy" }}
                        </li>
                        <li>
                            <strong>Image extérieur : </strong>
                            <div *ngIf="aircraftModel.imageUrl">
                                <img [src]="aircraftModel.imageUrl" />
                            </div>
                            <span *ngIf="!aircraftModel.imageUrl">Aucune image</span>
                        </li>
                        <li>
                            <strong>Image intérieur : </strong>
                            <div *ngIf="aircraftModel.imageInsideUrl">
                                <img [src]="aircraftModel.imageInsideUrl" />
                            </div>
                            <span *ngIf="!aircraftModel.imageInsideUrl">Aucune image</span>
                        </li>
                        <li>
                            <strong>Image plan : </strong>
                            <div *ngIf="aircraftModel.imagePlanUrl">
                                <img [src]="aircraftModel.imagePlanUrl" />
                            </div>
                            <span *ngIf="!aircraftModel.imagePlanUrl">Aucune image</span>
                        </li>
                    </ul>
                    <div class="mb-4 text-center">
                        <a routerLink="/admin/aircraft-models" class="btn btn-primary"
                            >Retour liste</a
                        >
                        <a
                            [routerLink]="'/admin/aircraft-models/' + aircraftModel.id + '/edit'"
                            class="btn btn-primary"
                            *ngIf="hasAclAccess(EnumAcl.aircraftModelsEdit)"
                            >Modifier</a
                        ><br />
                        <br />
                        <button
                            type="button"
                            (click)="delete()"
                            class="btn btn-secondary"
                            *ngIf="hasAclAccess(EnumAcl.aircraftModelsDelete)"
                        >
                            Supprimer
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
