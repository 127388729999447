import { NgModule } from '@angular/core';
import { FlightsCalendarComponent } from './flights-calendar.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CalendarModule } from '../calendar/calendar.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EnquiryFlightsTableModule } from '../enquiry-flights-table/enquiry-flights-table.module';
import { EnquiryFlightEditModule } from '../enquiry-flight-edit/enquiry-flight-edit.module';
import { EnquiryFlightChecklistModule } from '../enquiry-flight-checklist/enquiry-flight-checklist.module';
import { EnquiryFlightsCardMiniModule } from '../enquiry-flights-card-mini/enquiry-flights-card-mini.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FontAwesomeModule,
    CalendarModule,
    EnquiryFlightsTableModule,
    EnquiryFlightEditModule,
    EnquiryFlightChecklistModule,
    EnquiryFlightsCardMiniModule
  ],
  declarations: [FlightsCalendarComponent],
  exports: [FlightsCalendarComponent]
})
export class FlightsCalendarModule {}
