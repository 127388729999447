<div class="row justify-content-between mt-4">
    <div class="col-sm-4 col-md-3">
        <div class="form-group">
            <label for="filterByPeriod">Filtrer par période</label>
            <select
                class="form-select"
                id="filterByPeriod"
                [(ngModel)]="filterByPeriod"
                (change)="refreshData()"
            >
                <option
                    [value]="filter.value"
                    *ngFor="let filter of filtersByPeriod"
                    [innerHtml]="filter.title"
                ></option>
            </select>
        </div>
    </div>
    <div class="col-sm-4 col-md-3">
        <div class="text-end mt-4">
            <a
                routerLink="/admin/employee-bonus-rules"
                class="btn btn-primary"
                *ngIf="hasAclAccess(EnumAcl.employeeBonusRulesList)"
            >
                <fa-icon [icon]="faMoneyBillTrendUp" class="me-2"></fa-icon> Règles des bonus
            </a>
        </div>
    </div>
</div>

<ng-container *ngIf="loadingRules">
    <div class="card mb-4" *ngFor="let number of [].constructor(2)">
        <div class="card-header">
            <div class="skeleton-text mb-0"></div>
        </div>
        <ul class="list-group list-group-flush">
            <li class="list-group-item" *ngFor="let number2 of [].constructor(5)">
                <div class="row">
                    <div class="col-md-3">
                        <div class="skeleton-text mb-0"></div>
                    </div>
                    <div class="col-md-9">
                        <div class="skeleton-text mb-0"></div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</ng-container>
<ng-container *ngIf="!loadingRules">
    <div class="alert alert-warning text-center" *ngIf="!rules.length">
        Aucun bonus pour ce mois
    </div>
    <div class="card mb-4" *ngFor="let rule of rules">
        <div class="card-header">
            {{ rule.name }}

            <div class="text-muted float-md-end">
                Montant du bonus : {{ formatPrice(rule.amountBonus, getDefaultCurrency()) }}
            </div>
        </div>
        <ng-container *ngIf="loadingEnquiryPaymentSummaries">
            <ul class="list-group list-group-flush">
                <li class="list-group-item" *ngFor="let number2 of [].constructor(5)">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="skeleton-text mb-0"></div>
                        </div>
                        <div class="col-md-9">
                            <div class="skeleton-text mb-0"></div>
                        </div>
                    </div>
                </li></ul
        ></ng-container>
        <ng-container *ngIf="!loadingEnquiryPaymentSummaries">
            <div class="card-body text-bg-warning" *ngIf="!bonusByRule[rule.id].length">
                <div class="text-center">Aucune donnée à afficher</div>
            </div>
            <ul class="list-group list-group-flush" *ngIf="bonusByRule[rule.id].length">
                <li class="list-group-item" *ngFor="let item of bonusByRule[rule.id]">
                    <div class="row">
                        <div class="col-md-3">
                            <a
                                [routerLink]="'/admin/users/' + item.userId"
                                [innerText]="item.userFullName"
                            ></a>
                        </div>
                        <div class="col-md-9">
                            <div class="progress-container">
                                <div
                                    class="progress"
                                    role="progressbar"
                                    [attr.aria-valuenow]="
                                        getPercentage(
                                            item.enquiriesIds.length,
                                            rule.minimumNbEnquiries
                                        )
                                    "
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                >
                                    <div
                                        [class.bg-success]="
                                            getPercentage(
                                                item.enquiriesIds.length,
                                                rule.minimumNbEnquiries
                                            ) >= 100
                                        "
                                        class="progress-bar"
                                        [style.width]="
                                            getPercentage(
                                                item.enquiriesIds.length,
                                                rule.minimumNbEnquiries
                                            ) + '%'
                                        "
                                    ></div>
                                </div>
                                <div class="progress-legend">
                                    {{ item.enquiriesIds.length }} / {{ rule.minimumNbEnquiries }}
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </ng-container>
    </div>
</ng-container>
