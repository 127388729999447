<div *ngIf="value" class="form-group">
    <div class="form-control fake-input" [class.disabled]="disabled">
        <span class="fake-input-text">
            <a [routerLink]="'/admin/invoices/' + value" target="_blank" *ngIf="selectedInvoice">
                {{ selectedInvoice.ref }}
            </a>
        </span>
        <button type="button" class="btn btn-sm btn-clear" (click)="clearValue()" *ngIf="!disabled">
            <fa-icon [icon]="faXmark"></fa-icon>
        </button>
    </div>
</div>
<div [style.display]="!value ? 'block' : 'none'" class="form-group">
    <div class="input-group">
        <span class="input-group-text">
            <fa-icon [icon]="faSearch"></fa-icon>
        </span>

        <input
            type="text"
            autocomplete="off"
            [name]="inputName"
            class="form-control"
            [class.list-displayed]="optionsSuggested.length"
            list="browsers"
            [(ngModel)]="query"
            (keyup)="updateAutocomplete()"
            debounce="500"
            placeholder="Recherche une facture ou un avoir"
            #searchInput
            (focus)="updateAutocomplete()"
            [class.is-invalid]="isInvalid"
            [disabled]="disabled"
        />

        <div class="invalid-tooltip" *ngIf="isInvalid">
            <div *ngIf="isInvalid">Ce champ est obligatoire.</div>
        </div>
    </div>

    <div *ngIf="optionsSuggested.length && !disabled" class="list-group">
        <button
            type="button"
            class="list-group-item list-group-item-action"
            *ngFor="let option of optionsSuggested"
            (click)="updateFormValue(option)"
        >
            <strong [innerText]="option.ref"></strong><br />
            {{ formatPrice(option.amountTtcTotal, option.currency) }}
        </button>
    </div>
</div>
