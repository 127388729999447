import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { IUser, getUserFullname } from 'src/app/interfaces/user.interface';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/user/user.service';
import { PipedriveService } from 'src/app/services/pipedrive.service';
import { IPipedriveUser } from 'src/app/interfaces/pipedrive-user.interface';
import {
  faArrowLeft,
  faChartSimple,
  faEdit,
  faExternalLinkAlt,
  faKey,
  faTrash,
  faUserCheck,
  faUserXmark
} from '@fortawesome/free-solid-svg-icons';
import { IUserGroup } from 'src/app/interfaces/user-group.interface';

@Component({
  selector: 'app-users-view',
  templateUrl: './users-view.component.html',
  styleUrls: ['./users-view.component.scss']
})
export class UsersViewComponent implements OnInit, OnDestroy {
  faExternalLinkAlt = faExternalLinkAlt;
  faArrowLeft = faArrowLeft;
  faEdit = faEdit;
  faTrash = faTrash;
  faChartSimple = faChartSimple;
  faKey = faKey;
  faUserXmark = faUserXmark;
  faUserCheck = faUserCheck;

  getUserFullname = getUserFullname;

  loading: boolean = false;
  userId: string;
  user: IUser;
  userGroup: IUserGroup | null = null;
  isLogged: boolean = false;
  sendingEmail: boolean = false;
  loadingPipedriveUser: boolean = false;
  pipedriveUser: IPipedriveUser | null = null;
  disabling: boolean = false;

  EnumAcl = EnumAcl;

  subscription = new Subscription();

  constructor(
    private remoteService: RemoteService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private aclService: AclService,
    private userService: UserService,
    private pipedriveService: PipedriveService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
  }

  ngOnInit(): void {
    this.activatedRoute.url.subscribe(() => {
      this.userId = this.activatedRoute.snapshot.paramMap.get('userId');

      this.loadData();
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  loadData(): void {
    if (this.isLogged) {
      this.loadUser();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  async loadUser(): Promise<void> {
    this.loading = true;

    this.subscription.add(
      this.userService.getFromId(this.userId).subscribe(async (user: IUser) => {
        this.user = user;

        await this.loadPipedriveUser();
        await this.loadUserGroup();

        this.loading = false;
      })
    );
  }

  async loadUserGroup(): Promise<void> {
    this.loading = true;

    try {
      const doc = await this.remoteService.getDocument('userGroups', this.user.userGroupId);

      if (doc) {
        this.userGroup = doc as IUserGroup;
      }
    } catch (err) {}

    this.loading = false;
  }

  delete(): void {
    if (this.hasAclAccess(EnumAcl.usersDelete)) {
      const result: boolean = confirm(
        "La suppression de l'utilisateur sera permanent. Êtes-vous sûr de vouloir continuer?"
      );

      if (result) {
        this.userService
          .deleteFromAdmin(this.userId)
          .then(() => {
            alert('La suppression a été effectuée avec succès.');

            this.router.navigate(['/admin/users']);
          })
          .catch(err => {
            console.log(err);
          });
      }
    }
  }

  resendEmailSetPassword(): void {
    if (this.hasAclAccess(EnumAcl.usersSendEmailResetPassword)) {
      this.sendingEmail = true;

      this.userService
        .sendSignInLinkToEmail(this.user.email)
        .then(() => {
          this.sendingEmail = false;

          alert("L'email a été envoyé à l'utilisateur afin qu'il puisse se connecter.");
        })
        .catch(err => {
          this.sendingEmail = false;
          console.log(err);

          alert(err.message);
        });
    }
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  async loadPipedriveUser(): Promise<void> {
    if (this.user && this.user.pipedriveId) {
      this.pipedriveUser = await this.pipedriveService.getUser(this.user.pipedriveId);
    }
  }

  async toggleDisable(): Promise<void> {
    this.disabling = true;

    try {
      if (this.user.isDisabled) {
        await this.userService.enableFromAdmin(this.userId);
      } else {
        await this.userService.disableFromAdmin(this.userId);
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.disabling = false;
    }
  }
}
