<form (submit)="submitForm()" [formGroup]="form" (keydown.enter)="submitForm()" class="main-form">
    <div [class.row]="!inModal" class="justify-content-center mb-4">
        <div [class.col-md-5]="!inModal">
            <div [class.card]="!inModal">
                <div [class.card-body]="!inModal">
                    <div class="form-group">
                        <label for="title">Nom du modèle</label>
                        <input
                            class="form-control"
                            id="title"
                            formControlName="title"
                            type="text"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['title'].touched) &&
                                form.controls['title'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                            (keyup)="updateTitle()"
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['title'].touched) &&
                                    form.controls['title'].errors &&
                                    form.controls['title'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="slug">ID</label>
                        <input
                            [readonly]="aircraftModelId"
                            class="form-control"
                            id="slug"
                            formControlName="slug"
                            type="text"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['slug'].touched) &&
                                form.controls['slug'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['slug'].touched) &&
                                    form.controls['slug'].errors &&
                                    form.controls['slug'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                        <p class="text-muted">
                            Une fois sauvegardé, l'id ne peut plus être changé. Celui-ci est utilisé
                            pour les scripts d'importation automatique d'airfleets.
                        </p>
                    </div>

                    <div class="form-group">
                        <label for="family">Famille</label>
                        <input
                            [readonly]="aircraftModelId"
                            class="form-control"
                            id="family"
                            formControlName="family"
                            type="text"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['family'].touched) &&
                                form.controls['family'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['family'].touched) &&
                                    form.controls['family'].errors &&
                                    form.controls['family'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="target">Cible</label>
                        <select
                            class="form-select"
                            id="target"
                            formControlName="target"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['target'].touched) &&
                                form.controls['target'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        >
                            <option
                                [value]="target"
                                *ngFor="let target of getEnquiryTargets()"
                                [innerHtml]="getEnquiryTargetLabel(target)"
                            ></option>
                        </select>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['target'].touched) &&
                                    form.controls['target'].errors &&
                                    form.controls['target'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="speedInKts">Vitesse en nœuds (kts)</label>
                        <input
                            class="form-control"
                            id="speedInKts"
                            formControlName="speedInKts"
                            min="0"
                            type="number"
                            [class.is-invalid]="
                                (form.touched || form.controls['speedInKts'].touched) &&
                                form.controls['speedInKts'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />
                    </div>

                    <div class="form-group">
                        <label for="rangeInKms">Autonomie en kilomètres (kms)</label>
                        <input
                            class="form-control"
                            id="rangeInKms"
                            formControlName="rangeInKms"
                            min="0"
                            type="number"
                            [class.is-invalid]="
                                (form.touched || form.controls['rangeInKms'].touched) &&
                                form.controls['rangeInKms'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />
                    </div>

                    <div class="form-group">
                        <label for="weight">Poids (kg)</label>
                        <input
                            class="form-control"
                            id="weight"
                            formControlName="weight"
                            type="number"
                            min="0"
                        />
                    </div>

                    <div class="form-group">
                        <label for="volume">Volume (m<sup>3</sup>)</label>
                        <input
                            class="form-control"
                            id="volume"
                            formControlName="volume"
                            type="number"
                            min="0"
                        />
                    </div>

                    <label for="imageUrlInput">Image extérieur</label>
                    <div class="image-upload-container">
                        <div
                            class="image-upload-content"
                            [class.no-image]="!form.value['imageUrl']"
                            (click)="triggerInputFile('imageUrl')"
                        >
                            <div class="image-container" *ngIf="form.value['imageUrl']">
                                <img [src]="form.value.imageUrl" />
                            </div>
                            <div class="image-upload-legend">
                                <span *ngIf="!form.disabled">
                                    {{
                                        form.value.imageUrl ? "Éditer l'image" : "Ajouter une image"
                                    }}
                                </span>
                                <span *ngIf="form.disabled">...</span>
                            </div>
                            <input
                                type="file"
                                id="imageUrlInput"
                                class="form-control"
                                (change)="changeInputFile('imageUrl', $event)"
                                accept="image/*"
                            />
                        </div>

                        <button
                            type="button"
                            class="btn btn-danger btn-sm"
                            (click)="deleteImage('imageUrl')"
                            [disabled]="form.disabled"
                            *ngIf="form.value.imageUrl"
                        >
                            Supprimer
                        </button>
                    </div>

                    <label for="imageInsideUrlInput">Image intérieur</label>
                    <div class="image-upload-container">
                        <div
                            class="image-upload-content"
                            [class.no-image]="!form.value['imageInsideUrl']"
                            (click)="triggerInputFile('imageInsideUrl')"
                        >
                            <div class="image-container" *ngIf="form.value['imageInsideUrl']">
                                <img [src]="form.value.imageInsideUrl" />
                            </div>
                            <div class="image-upload-legend">
                                <span *ngIf="!form.disabled">
                                    {{
                                        form.value.imageInsideUrl
                                            ? "Éditer l'image"
                                            : "Ajouter une image"
                                    }}
                                </span>
                                <span *ngIf="form.disabled">...</span>
                            </div>
                            <input
                                type="file"
                                id="imageInsideUrlInput"
                                class="form-control"
                                (change)="changeInputFile('imageInsideUrl', $event)"
                                accept="image/*"
                            />
                        </div>

                        <button
                            type="button"
                            class="btn btn-danger btn-sm"
                            (click)="deleteImage('imageInsideUrl')"
                            [disabled]="form.disabled"
                            *ngIf="form.value.imageInsideUrl"
                        >
                            Supprimer
                        </button>
                    </div>

                    <label for="imagePlanUrlInput">Image plan</label>
                    <div class="image-upload-container">
                        <div
                            class="image-upload-content"
                            [class.no-image]="!form.value['imagePlanUrl']"
                            (click)="triggerInputFile('imagePlanUrl')"
                        >
                            <div class="image-container" *ngIf="form.value['imagePlanUrl']">
                                <img [src]="form.value.imagePlanUrl" />
                            </div>
                            <div class="image-upload-legend">
                                <span *ngIf="!form.disabled">
                                    {{
                                        form.value.imagePlanUrl
                                            ? "Éditer l'image"
                                            : "Ajouter une image"
                                    }}
                                </span>
                                <span *ngIf="form.disabled">...</span>
                            </div>
                            <input
                                type="file"
                                id="imagePlanUrlInput"
                                class="form-control"
                                (change)="changeInputFile('imagePlanUrl', $event)"
                                accept="image/*"
                            />
                        </div>

                        <button
                            type="button"
                            class="btn btn-danger btn-sm"
                            (click)="deleteImage('imagePlanUrl')"
                            [disabled]="form.disabled"
                            *ngIf="form.value.imagePlanUrl"
                        >
                            Supprimer
                        </button>
                    </div>

                    <div class="row mb-4" *ngIf="!inModal">
                        <div class="col">
                            <button
                                type="button"
                                [routerLink]="
                                    aircraftModelId
                                        ? '/admin/aircraft-models/' + aircraftModelId
                                        : '/admin/aircraft-models'
                                "
                                class="btn btn-secondary"
                                [disabled]="form.disabled || sending"
                            >
                                Annuler
                            </button>
                        </div>
                        <div class="col text-end">
                            <button
                                type="submit"
                                class="btn btn-primary"
                                [innerHtml]="sending ? 'Sauvegarde ...' : 'Sauvegarder'"
                                [disabled]="form.disabled || !form.valid || sending"
                            ></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
