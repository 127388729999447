import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { PaginationService } from '../../../services/pagination.service';
import { AclService } from '../../../services/acl.service';
import slugify from 'slugify';

import countries from '../../../countries_fr.json';

import { EnumAcl } from 'src/app/enums/acl.enum';
import { IAirline } from 'src/app/interfaces/airline.interface';
import { getCountryLabel } from 'src/app/enums/continent-code.enum';
import { AirlineService } from 'src/app/services/airlines/airlines.service';
import { faCancel, faCheckCircle, faEdit, faEye } from '@fortawesome/free-solid-svg-icons';
import { AlertService } from 'src/app/services/alert/alert.service';
import { LoaderService } from 'src/app/services/loader/loader.service';

enum EnumStatus {
  draft = 'draft',
  readyToMerge = 'readyToMerge'
}

interface IAirlinesToMerge {
  status: EnumStatus;
  airlines: IAirline[];
}

@Component({
  selector: 'app-duplicate-airlines',
  templateUrl: './duplicate-airlines.component.html',
  styleUrls: ['./duplicate-airlines.component.scss']
})
export class DuplicateAirlinesComponent implements OnInit, OnDestroy {
  EnumAcl = EnumAcl;

  faEye = faEye;
  faEdit = faEdit;
  faCheckCircle = faCheckCircle;
  faCancel = faCancel;

  countriesList: Array<{
    title: string;
    value: string;
  }> = [];

  loadingAirlines: boolean = false;
  airlines: IAirline[] = [];
  airlinesToMerge: IAirlinesToMerge[] = [];
  analysingDuplicateAirlines: boolean = false;

  EnumStatus = EnumStatus;

  getCountryLabel = getCountryLabel;

  private subscriptions = new Subscription();

  constructor(
    public paginationService: PaginationService,
    private aclService: AclService,
    private airlineService: AirlineService,
    private alertService: AlertService,
    private loaderService: LoaderService
  ) {
    for (const code in countries) {
      this.countriesList.push({
        title: countries[code],
        value: code
      });
    }
  }

  async ngOnInit(): Promise<void> {
    await this.aclService.checkAclAccess(EnumAcl.airlinesDuplicate);

    this.loadAirlines();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  loadAirlines(): void {
    this.loadingAirlines = true;

    this.subscriptions.add(
      this.airlineService.getAll().subscribe((airlines: IAirline[]) => {
        this.airlines = airlines;

        this.airlines.sort((a, b) => (a.created < b.created ? -1 : 1));

        this.loadingAirlines = false;

        this.analyseDuplicateAirlines();
      })
    );
  }

  analyseDuplicateAirlines(): void {
    this.analysingDuplicateAirlines = true;

    this.airlinesToMerge = [];

    const airlinesByKeys: { [key: string]: IAirline[] } = {};

    const slugifyOptions = {
      replacement: '-', // replace spaces with replacement character, defaults to `-`
      lower: true, // convert to lower case, defaults to `false`
      strict: true, // strip special characters except replacement, defaults to `false`
      trim: true
    };

    for (const airline of this.airlines) {
      const key: string = [airline.countryCode, slugify(airline.title, slugifyOptions)].join('_');

      if (typeof airlinesByKeys[key] === 'undefined') {
        airlinesByKeys[key] = [];
      }

      airlinesByKeys[key].push(airline);
    }

    for (const key in airlinesByKeys) {
      if (airlinesByKeys[key].length === 1) {
        delete airlinesByKeys[key];
      } else {
        this.airlinesToMerge.push({
          status: EnumStatus.draft,
          airlines: airlinesByKeys[key]
        });
      }
    }

    this.analysingDuplicateAirlines = false;
  }

  mergeAirlines(i: number): void {
    this.airlinesToMerge[i].status = EnumStatus.readyToMerge;
  }

  cancelMergeAirlines(i: number): void {
    this.airlinesToMerge[i].status = EnumStatus.draft;
  }

  async confirmMergeAirlines(i: number, finalAirlineId: string): Promise<void> {
    this.alertService.presentConfirm(
      'Êtes-vous sûr de votre choix ? Cela supprimera les autres fiches doublons et transférera leur flotte, dossiers, superviseurs et équipages vers la fiche finale.',
      async () => {
        console.log(this.airlinesToMerge[i], finalAirlineId);

        this.loaderService.presentLoader();

        try {
          const airlineIds: string[] = [];

          for (const airline of this.airlinesToMerge[i].airlines) {
            if (airline.id !== finalAirlineId) {
              airlineIds.push(airline.id);
            }
          }

          await this.airlineService.mergeAirlines(airlineIds, finalAirlineId);

          await this.loaderService.hideLoaderOnSuccess();
        } catch (err) {
          await this.loaderService.hideLoaderOnFailure(err);
        }
      }
    );
  }
}
