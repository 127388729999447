<div class="row">
    <div class="col-md-6">
        <div class="card mb-4">
            <div class="card-body" *ngIf="loading">
                <h3><div class="skeleton-text"></div></h3>
            </div>
            <div class="card-body" *ngIf="!loading && flightChecklistTemplate">
                <h3
                    [innerHtml]="getEnumEnquiryTypeLabel(flightChecklistTemplate.enquiryType)"
                    class="text-center"
                ></h3>

                <div class="text-center mt-4">
                    <a
                        [routerLink]="
                            '/admin/flight-checklist-templates/' +
                            flightChecklistTemplate.id +
                            '/edit'
                        "
                        class="btn btn-primary mr-4"
                        *ngIf="hasAclAccess(EnumAcl.flightChecklistTemplatesEdit)"
                        >Éditer</a
                    >
                    <button
                        type="button"
                        (click)="delete()"
                        class="btn btn-primary ml-4"
                        *ngIf="hasAclAccess(EnumAcl.flightChecklistTemplatesDelete)"
                    >
                        Supprimer
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-6">
        <ul class="list-group" *ngIf="loading">
            <li class="list-group-item" *ngFor="let number of [].constructor(5)">
                <div class="skeleton-text"></div>
            </li>
        </ul>
        <ng-container *ngIf="!loading && flightChecklistTemplate">
            <div class="card mb-2" *ngFor="let module of flightChecklistTemplate.modules">
                <div class="card-header" [innerText]="module.title"></div>

                <ul class="list-group list-group-flush">
                    <li class="list-group-item" *ngFor="let field of module.fields">
                        <div class="row">
                            <div class="col-sm-6" [innerText]="field.text"></div>
                            <div
                                class="col-sm-6"
                                [innerText]="getFlightChecklistTemplateFieldTypeLabel(field.type)"
                            ></div>
                        </div>

                        <ul
                            class="list-group mt-2"
                            *ngIf="field.type === EnumFlightChecklistTemplateFieldType.select"
                        >
                            <li
                                class="list-group-item"
                                [class.list-group-item-warning]="
                                    option.status ===
                                    EnumFlightChecklistTemplateFieldOptionStatus.warning
                                "
                                [class.list-group-item-danger]="
                                    option.status ===
                                    EnumFlightChecklistTemplateFieldOptionStatus.danger
                                "
                                [class.list-group-item-success]="
                                    option.status ===
                                    EnumFlightChecklistTemplateFieldOptionStatus.success
                                "
                                [class.list-group-item-info]="
                                    option.status ===
                                    EnumFlightChecklistTemplateFieldOptionStatus.info
                                "
                                [class.list-group-item-primary]="
                                    option.status ===
                                    EnumFlightChecklistTemplateFieldOptionStatus.primary
                                "
                                [class.list-group-item-secondary]="
                                    option.status ===
                                    EnumFlightChecklistTemplateFieldOptionStatus.secondary
                                "
                                *ngFor="let option of field.options"
                            >
                                {{ option.text }}
                                <div *ngIf="field.defaultOptionId === option.id">
                                    <em>(Valeur par défaut)</em>
                                </div>
                                <div *ngIf="field.finalOptionId === option.id">
                                    <em>(Finale)</em>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </ng-container>
    </div>
</div>
