import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  faArrowLeft,
  faArrowRight,
  faBox,
  faEdit,
  faPlusCircle,
  faTicket,
  faUsers,
  faUserTie
} from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { EnumEnquiryType } from 'src/app/enums/enquiry-type.enum';
import { IAirlineSupervisor } from 'src/app/interfaces/airline-supervisor.interface';
import { IAirline } from 'src/app/interfaces/airline.interface';
import { EnumCrewType, ICrew } from 'src/app/interfaces/crew.interface';
import { IEnquiryFlight } from 'src/app/interfaces/enquiry-flight.interface';
import { IEnquiry } from 'src/app/interfaces/enquiry.interface';
import { AclService } from 'src/app/services/acl.service';
import { AirlineSupervisorService } from 'src/app/services/airline-supervisors/airline-supervisors.service';
import { AirlineService } from 'src/app/services/airlines/airlines.service';
import { CrewService } from 'src/app/services/crews/crews.service';

@Component({
  selector: 'app-enquiry-flight-edit-crew',
  templateUrl: './enquiry-flight-edit-crew.component.html',
  styleUrls: ['./enquiry-flight-edit-crew.component.scss']
})
export class EnquiryFlightEditCrewComponent implements OnInit, OnChanges, OnDestroy {
  @Input() enquiry: IEnquiry | null = null;
  @Input() enquiryFlight: IEnquiryFlight | null = null;
  @Output() goPrev: EventEmitter<void> = new EventEmitter();
  @Output() saveAndGoNext: EventEmitter<IEnquiryFlight> = new EventEmitter();

  @ViewChild('modalCaptain', { static: false }) modalCaptainElement: ElementRef;
  @ViewChild('modalCoPilot', { static: false }) modalCoPilotElement: ElementRef;
  @ViewChild('modalStewardess', { static: false }) modalStewardessElement: ElementRef;
  @ViewChild('modalSupervisor', { static: false }) modalSupervisorElement: ElementRef;

  form: FormGroup = this.resetForm();

  faArrowLeft = faArrowLeft;
  faArrowRight = faArrowRight;
  faUserTie = faUserTie;
  faPlusCircle = faPlusCircle;
  faEdit = faEdit;

  EnumEnquiryType = EnumEnquiryType;
  EnumCrewType = EnumCrewType;
  EnumAcl = EnumAcl;

  loadingCrewsForAirline: boolean = false;
  loadingSupervisorsForAirline: boolean = false;
  editingCaptain: ICrew | null = null;
  editingCoPilot: ICrew | null = null;
  editingStewardess: ICrew | null = null;
  editingSupervisor: IAirlineSupervisor | null = null;
  crewsByType: { [type: string]: ICrew[] } = {};
  airlinesObj: { [id: string]: IAirline | null } = {};
  supervisors: IAirlineSupervisor[] = [];

  private subscriptions = new Subscription();

  constructor(
    private crewService: CrewService,
    private airlineSupervisorService: AirlineSupervisorService,
    private airlineService: AirlineService,
    private aclService: AclService
  ) {}

  ngOnInit(): void {
    this.applyEnquiryFlight();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['enquiryFlight'] &&
      (!changes['enquiryFlight'].previousValue ||
        changes['enquiryFlight'].previousValue !== changes['enquiryFlight'].currentValue)
    ) {
      this.applyEnquiryFlight();
    }
  }

  ngOnDestroy(): void {
    window['$']('.tooltip').remove();

    this.subscriptions.unsubscribe();
  }

  get opsH24Phone(): FormControl {
    return this.form.get('opsH24Phone') as FormControl;
  }
  get captainId(): FormControl {
    return this.form.get('captainId') as FormControl;
  }
  get coPilotId(): FormControl {
    return this.form.get('coPilotId') as FormControl;
  }
  get stewardessEnabled(): FormControl {
    return this.form.get('stewardessEnabled') as FormControl;
  }
  get stewardessIds(): FormControl {
    return this.form.get('stewardessIds') as FormControl;
  }
  get supervisorEnabled(): FormControl {
    return this.form.get('supervisorEnabled') as FormControl;
  }
  get supervisorIds(): FormControl {
    return this.form.get('supervisorIds') as FormControl;
  }

  applyEnquiryFlight(): void {
    this.form = this.resetForm();

    if (this.enquiryFlight) {
      for (const field in this.form.value) {
        if (typeof this.enquiryFlight[field] !== 'undefined') {
          switch (field) {
            default:
              this.form.get(field).setValue(this.enquiryFlight[field]);
              break;
          }
        }
      }

      if (this.enquiryFlight.airlineId) {
        this.loadAirline(this.enquiryFlight.airlineId);

        this.loadAirlineCrewsAndSupervisors();
      }

      this.updatedStewardessEnabled();
      this.updatedSupervisorEnabled();

      this.form.enable();
    }
  }

  resetForm(): FormGroup {
    return new FormGroup({
      opsH24Phone: new FormControl(null),
      captainId: new FormControl(null),
      coPilotId: new FormControl(null),
      stewardessEnabled: new FormControl(false),
      stewardessIds: new FormControl([]),
      supervisorEnabled: new FormControl(false),
      supervisorIds: new FormControl([])
    });
  }

  prev(): void {
    this.goPrev.emit();
  }

  saveAndNext(): void {
    if (this.form.valid) {
      this.saveAndGoNext.emit(this.form.value);
    }
  }

  updatedStewardessEnabled(): void {
    if (this.stewardessEnabled.value) {
      this.stewardessIds.addValidators(Validators.required);
    } else {
      this.stewardessIds.clearValidators();
      this.stewardessIds.setValue([]);
    }

    this.stewardessIds.updateValueAndValidity();
  }

  updatedSupervisorEnabled(): void {
    if (this.supervisorEnabled.value) {
      this.supervisorIds.addValidators(Validators.required);
    } else {
      this.supervisorIds.clearValidators();
      this.supervisorIds.setValue([]);
    }

    this.supervisorIds.updateValueAndValidity();
  }

  loadAirline(airlineId: string): void {
    if (typeof this.airlinesObj[airlineId] === 'undefined') {
      this.airlinesObj[airlineId] = null;

      this.subscriptions.add(
        this.airlineService.getFromId(airlineId).subscribe((airline: IAirline | null) => {
          this.airlinesObj[airlineId] = airline;
        })
      );
    }
  }

  loadAirlineCrewsAndSupervisors(): void {
    if (this.enquiryFlight.airlineId) {
      if (this.subscriptions) {
        this.subscriptions.unsubscribe();
      }

      this.subscriptions = new Subscription();

      this.loadingCrewsForAirline = true;

      this.subscriptions.add(
        this.crewService.getForAirline(this.enquiryFlight.airlineId).subscribe((crews: ICrew[]) => {
          this.crewsByType = {};

          for (const crew of crews) {
            if (typeof this.crewsByType[crew.type] === 'undefined') {
              this.crewsByType[crew.type] = [];
            }

            this.crewsByType[crew.type].push(crew);
          }

          this.loadingCrewsForAirline = false;
        })
      );

      this.loadingSupervisorsForAirline = true;
      this.supervisors = [];

      this.subscriptions.add(
        this.airlineSupervisorService
          .getForAirline(this.enquiryFlight.airlineId)
          .subscribe((supervisors: IAirlineSupervisor[]) => {
            this.supervisors = supervisors;

            this.loadingSupervisorsForAirline = false;
          })
      );
    }
  }

  toggleStewardessId(crewId: string): void {
    const values: string[] = [...this.stewardessIds.value];
    const index: number = values.indexOf(crewId);

    if (index === -1) {
      values.push(crewId);
    } else {
      values.splice(index, 1);
    }

    this.stewardessIds.setValue(values);
    this.stewardessIds.updateValueAndValidity();
  }

  toggleSupervisorId(supervisorId: string): void {
    const values: string[] = [...this.supervisorIds.value];
    const index: number = values.indexOf(supervisorId);

    if (index === -1) {
      values.push(supervisorId);
    } else {
      values.splice(index, 1);
    }

    this.supervisorIds.setValue(values);
    this.supervisorIds.updateValueAndValidity();
  }

  addCaptain(): void {
    this.editingCaptain = null;

    window['$'](this.modalCaptainElement.nativeElement).modal('show');
  }

  editCaptain(): void {
    if (this.captainId.value && this.crewsByType[EnumCrewType.captain].length) {
      for (const captain of this.crewsByType[EnumCrewType.captain]) {
        if (captain.id === this.captainId.value) {
          this.editingCaptain = captain;
          break;
        }
      }
    }

    window['$'](this.modalCaptainElement.nativeElement).modal('show');
  }

  dismissCaptainModal(newId: string | null = null): void {
    if (newId) {
      this.captainId.setValue(newId);
      this.captainId.updateValueAndValidity();
    }

    window['$'](this.modalCaptainElement.nativeElement).modal('hide');
  }

  addCoPilot(): void {
    this.editingCoPilot = null;

    window['$'](this.modalCoPilotElement.nativeElement).modal('show');
  }

  editCoPilot(): void {
    if (this.coPilotId.value && this.crewsByType[EnumCrewType.coPilot].length) {
      for (const coPilot of this.crewsByType[EnumCrewType.coPilot]) {
        if (coPilot.id === this.coPilotId.value) {
          this.editingCoPilot = coPilot;
          break;
        }
      }
    }

    window['$'](this.modalCoPilotElement.nativeElement).modal('show');
  }

  dismissCoPilotModal(newId: string | null = null): void {
    if (newId) {
      this.coPilotId.setValue(newId);
      this.coPilotId.updateValueAndValidity();
    }

    window['$'](this.modalCoPilotElement.nativeElement).modal('hide');
  }

  addStewardess(): void {
    this.editingStewardess = null;

    window['$'](this.modalStewardessElement.nativeElement).modal('show');
  }

  editStewardess(stewardessId: string): void {
    if (this.crewsByType[EnumCrewType.stewardess].length) {
      for (const stewardess of this.crewsByType[EnumCrewType.stewardess]) {
        if (stewardess.id === stewardessId) {
          this.editingStewardess = stewardess;
          break;
        }
      }
    }

    window['$'](this.modalStewardessElement.nativeElement).modal('show');
  }

  dismissStewardessModal(newId: string | null = null): void {
    const stewardessIds: string[] = [...this.stewardessIds.value];

    if (newId) {
      stewardessIds.push(newId);

      this.stewardessIds.setValue(stewardessIds);
      this.stewardessIds.updateValueAndValidity();
    }

    window['$'](this.modalStewardessElement.nativeElement).modal('hide');
  }

  addSupervisor(): void {
    this.editingSupervisor = null;

    window['$'](this.modalSupervisorElement.nativeElement).modal('show');
  }

  editSupervisor(supervisorId: string): void {
    if (this.supervisors.length) {
      for (const supervisor of this.supervisors) {
        if (supervisor.id === supervisorId) {
          this.editingSupervisor = supervisor;
          break;
        }
      }
    }

    window['$'](this.modalSupervisorElement.nativeElement).modal('show');
  }

  dismissSupervisorModal(newId: string | null = null): void {
    const supervisorIds: string[] = [...this.supervisorIds.value];

    if (newId) {
      supervisorIds.push(newId);

      this.supervisorIds.setValue(supervisorIds);
      this.supervisorIds.updateValueAndValidity();
    }

    window['$'](this.modalSupervisorElement.nativeElement).modal('hide');
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }
}
