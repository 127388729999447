<nav aria-label="breadcrumb" class="nav-breadcrumbs">
    <ol class="breadcrumb mb-0">
        <li class="breadcrumb-item">
            <a routerLink="/admin"
                ><fa-icon [icon]="faHouse"></fa-icon>
                <span class="ms-2" *ngIf="!breadcrumbLinks.length">Tableau de bord</span></a
            >
        </li>
        <li
            class="breadcrumb-item"
            [class.active]="i === breadcrumbLinks.length - 1"
            aria-current="page"
            *ngFor="let breadcrumbLink of breadcrumbLinks; let i = index"
        >
            <a
                [routerLink]="breadcrumbLink.url"
                [innerHtml]="breadcrumbLink.text | translate"
                *ngIf="i < breadcrumbLinks.length - 1"
            ></a>
            <span
                *ngIf="i === breadcrumbLinks.length - 1"
                [innerHtml]="breadcrumbLink.text | translate"
            ></span>
        </li>
    </ol>
</nav>
