<form (submit)="saveAndNext()" [formGroup]="form" (keydown.enter)="saveAndNext()" class="main-form">
    <div class="card mb-4">
        <div class="card-header">
            <fa-icon [icon]="faUserTie"></fa-icon>
            {{
                [EnumEnquiryType.cargo, EnumEnquiryType.commercial].includes(enquiry?.type)
                    ? "Opérateur"
                    : "Équipage"
            }}
        </div>
        <div class="card-body">
            <div class="row justify-content-center">
                <div class="col-md-6 col-lg-4">
                    <div class="form-group">
                        <label for="opsH24Phone">OPS H24</label>
                        <input
                            id="opsH24Phone"
                            class="form-control"
                            type="text"
                            formControlName="opsH24Phone"
                            [class.is-invalid]="
                                (form.touched || opsH24Phone.touched) &&
                                opsH24Phone.status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || opsH24Phone.touched) &&
                                    opsH24Phone.errors &&
                                    opsH24Phone.errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="[EnumEnquiryType.business].includes(enquiry?.type)">
                    <div class="col-md-6 col-lg-4" *ngIf="enquiryFlight?.airlineId">
                        <div class="form-group placeholder-glow">
                            <label for="captainId" class="d-flex justify-content-between"
                                >Commandant de bord

                                <button type="button" class="btn btn-icon" (click)="addCaptain()">
                                    <fa-icon [icon]="faPlusCircle"></fa-icon>
                                </button>
                            </label>
                            <div class="placeholder w-100" *ngIf="loadingCrewsForAirline"></div>
                            <div
                                class="alert alert-warning alert-sm text-center"
                                *ngIf="
                                    !loadingCrewsForAirline &&
                                    !crewsByType[EnumCrewType.captain]?.length
                                "
                            >
                                Aucun commandant
                            </div>
                            <div
                                class="input-group"
                                *ngIf="
                                    !loadingCrewsForAirline &&
                                    crewsByType[EnumCrewType.captain]?.length
                                "
                            >
                                <select
                                    id="captainId"
                                    class="form-select"
                                    formControlName="captainId"
                                    [class.is-invalid]="
                                        (form.touched || captainId.touched) &&
                                        captainId.status === 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                >
                                    <option [ngValue]="null">-- Choisissez --</option>
                                    <option
                                        [value]="item.id"
                                        [innerText]="item.lastname + ' ' + item.firstname"
                                        *ngFor="let item of crewsByType[EnumCrewType.captain]"
                                    ></option>
                                </select>

                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched || captainId.touched) &&
                                            captainId.errors &&
                                            captainId.errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                                <button
                                    type="button"
                                    class="btn btn-primary"
                                    (click)="editCaptain()"
                                    *ngIf="hasAclAccess(EnumAcl.crewsEdit) && captainId.value"
                                    rel="tooltip"
                                    title="Éditer commandant de bord"
                                >
                                    <fa-icon [icon]="faEdit"></fa-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4" *ngIf="enquiryFlight?.airlineId">
                        <div class="form-group placeholder-glow">
                            <label for="coPilotId" class="d-flex justify-content-between"
                                >Copilote

                                <button type="button" class="btn btn-icon" (click)="addCoPilot()">
                                    <fa-icon [icon]="faPlusCircle"></fa-icon>
                                </button>
                            </label>
                            <div class="placeholder w-100" *ngIf="loadingCrewsForAirline"></div>
                            <div
                                class="alert alert-warning alert-sm text-center"
                                *ngIf="
                                    !loadingCrewsForAirline &&
                                    !crewsByType[EnumCrewType.coPilot]?.length
                                "
                            >
                                Aucun copilote
                            </div>
                            <div
                                class="input-group"
                                *ngIf="
                                    !loadingCrewsForAirline &&
                                    crewsByType[EnumCrewType.coPilot]?.length
                                "
                            >
                                <select
                                    id="coPilotId"
                                    class="form-select"
                                    formControlName="coPilotId"
                                    [class.is-invalid]="
                                        (form.touched || coPilotId.touched) &&
                                        coPilotId.status === 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                >
                                    <option [ngValue]="null">-- Choisissez --</option>
                                    <option
                                        [value]="item.id"
                                        [innerText]="item.lastname + ' ' + item.firstname"
                                        *ngFor="let item of crewsByType[EnumCrewType.coPilot]"
                                    ></option>
                                </select>

                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched || coPilotId.touched) &&
                                            coPilotId.errors &&
                                            coPilotId.errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                                <button
                                    type="button"
                                    class="btn btn-primary"
                                    (click)="editCoPilot()"
                                    *ngIf="hasAclAccess(EnumAcl.crewsEdit) && coPilotId.value"
                                    rel="tooltip"
                                    title="Éditer copilote"
                                >
                                    <fa-icon [icon]="faEdit"></fa-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3" *ngIf="enquiryFlight?.airlineId">
                        <div class="form-group placeholder-glow">
                            <div class="d-flex gap-4">
                                <div class="form-check form-switch">
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        id="stewardessEnabled"
                                        formControlName="stewardessEnabled"
                                        (change)="updatedStewardessEnabled()"
                                    />
                                    <label class="form-check-label" for="stewardessEnabled"
                                        >Hôtesse(s)</label
                                    >
                                </div>
                                <button
                                    type="button"
                                    class="btn btn-icon"
                                    (click)="addStewardess()"
                                    *ngIf="stewardessEnabled.value"
                                >
                                    <fa-icon [icon]="faPlusCircle"></fa-icon>
                                </button>
                            </div>
                            <ng-container *ngIf="stewardessEnabled.value">
                                <div class="placeholder w-100" *ngIf="loadingCrewsForAirline"></div>

                                <div
                                    class="alert alert-warning alert-sm text-center mb-1"
                                    *ngIf="
                                        !loadingCrewsForAirline &&
                                        !crewsByType[EnumCrewType.stewardess]?.length
                                    "
                                >
                                    Aucune hôtesse
                                </div>

                                <div
                                    class="form-check"
                                    *ngFor="let crew of crewsByType[EnumCrewType.stewardess]"
                                >
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        [id]="'crew-' + crew.id"
                                        [checked]="stewardessIds.value.includes(crew.id)"
                                        (change)="toggleStewardessId(crew.id)"
                                    />
                                    <label
                                        class="form-check-label"
                                        [attr.for]="'crew-' + crew.id"
                                        [innerText]="crew.firstname + ' ' + crew.lastname"
                                    >
                                    </label>

                                    <button
                                        type="button"
                                        class="btn btn-icon"
                                        (click)="editStewardess(crew.id)"
                                    >
                                        <fa-icon [icon]="faEdit"></fa-icon>
                                    </button>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
                <div class="col-md-6 col-lg-3" *ngIf="enquiryFlight?.airlineId">
                    <div class="form-group placeholder-glow">
                        <div class="d-flex gap-4">
                            <div class="form-check form-switch">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id="supervisorEnabled"
                                    formControlName="supervisorEnabled"
                                    (change)="updatedSupervisorEnabled()"
                                />
                                <label class="form-check-label" for="supervisorEnabled"
                                    >Superviseur(s)</label
                                >
                            </div>
                            <button
                                type="button"
                                class="btn btn-icon"
                                (click)="addSupervisor()"
                                *ngIf="supervisorEnabled.value"
                            >
                                <fa-icon [icon]="faPlusCircle"></fa-icon>
                            </button>
                        </div>

                        <ng-container *ngIf="supervisorEnabled.value">
                            <div
                                class="placeholder w-100"
                                *ngIf="loadingSupervisorsForAirline"
                            ></div>

                            <div
                                class="alert alert-warning alert-sm text-center"
                                *ngIf="!loadingSupervisorsForAirline && !supervisors.length"
                            >
                                Aucun superviseur
                            </div>

                            <div class="form-check" *ngFor="let supervisor of supervisors">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    [id]="'supervisor-' + supervisor.id"
                                    [checked]="supervisorIds.value.includes(supervisor.id)"
                                    (change)="toggleSupervisorId(supervisor.id)"
                                />
                                <label
                                    class="form-check-label"
                                    [attr.for]="'supervisor-' + supervisor.id"
                                    [innerText]="supervisor.firstname + ' ' + supervisor.lastname"
                                >
                                </label>

                                <button
                                    type="button"
                                    class="btn btn-icon"
                                    (click)="editSupervisor(supervisor.id)"
                                >
                                    <fa-icon [icon]="faEdit"></fa-icon>
                                </button>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mb-4">
        <div class="col">
            <button
                type="button"
                class="btn btn-secondary"
                [disabled]="form.disabled"
                (click)="prev()"
            >
                <fa-icon [icon]="faArrowLeft"></fa-icon>
                Précédent
            </button>
        </div>
        <div class="col text-end">
            <button
                type="button"
                class="btn btn-primary"
                [disabled]="form.disabled || !form.valid"
                (click)="saveAndNext()"
            >
                Suivant
                <fa-icon [icon]="faArrowRight"></fa-icon>
            </button>
        </div>
    </div>
</form>

<div class="modal" tabindex="-1" role="dialog" #modalCaptain>
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5
                    class="modal-title"
                    [innerText]="
                        editingCaptain
                            ? 'Édition d\'un commandant de bord'
                            : 'Ajout d\'un commandant de bord'
                    "
                ></h5>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <app-crew-edit
                [inModal]="true"
                [crewId]="editingCaptain?.id || null"
                [defaultAirlineId]="enquiryFlight?.airlineId"
                [defaultCrewType]="EnumCrewType.captain"
                *ngIf="enquiryFlight?.airlineId"
                (dismissModal)="dismissCaptainModal($event)"
            ></app-crew-edit>
        </div>
    </div>
</div>

<div class="modal" tabindex="-1" role="dialog" #modalCoPilot>
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5
                    class="modal-title"
                    [innerText]="editingCoPilot ? 'Édition d\'un copilote' : 'Ajout d\'un copilote'"
                ></h5>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <app-crew-edit
                [inModal]="true"
                [crewId]="editingCoPilot?.id || null"
                [defaultAirlineId]="enquiryFlight?.airlineId"
                [defaultCrewType]="EnumCrewType.coPilot"
                *ngIf="enquiryFlight?.airlineId"
                (dismissModal)="dismissCoPilotModal($event)"
            ></app-crew-edit>
        </div>
    </div>
</div>

<div class="modal" tabindex="-1" role="dialog" #modalStewardess>
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5
                    class="modal-title"
                    [innerText]="
                        editingStewardess ? 'Édition d\'une hôtesse' : 'Ajout d\'une hôtesse'
                    "
                ></h5>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <app-crew-edit
                [inModal]="true"
                [crewId]="editingStewardess?.id || null"
                [defaultAirlineId]="enquiryFlight?.airlineId"
                [defaultCrewType]="EnumCrewType.stewardess"
                *ngIf="enquiryFlight?.airlineId"
                (dismissModal)="dismissStewardessModal($event)"
            ></app-crew-edit>
        </div>
    </div>
</div>

<div class="modal" tabindex="-1" role="dialog" #modalSupervisor>
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5
                    class="modal-title"
                    [innerText]="
                        editingSupervisor ? 'Édition d\'un superviseur' : 'Ajout d\'un superviseur'
                    "
                ></h5>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <app-airline-supervisor-edit
                [inModal]="true"
                [airlineSupervisorId]="editingSupervisor?.id || null"
                [defaultAirlineId]="enquiryFlight?.airlineId"
                *ngIf="enquiryFlight?.airlineId"
                (dismissModal)="dismissSupervisorModal($event)"
            ></app-airline-supervisor-edit>
        </div>
    </div>
</div>
