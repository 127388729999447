<div class="row justify-content-center">
    <div class="col-md-8 col-lg-6">
        <p class="text-center">
            Veuillez importer le fichier d'import en se basant sur le fichier d'exemple
            <a href="assets/exemple_import_aircrafts.xlsx" target="_blank">ici</a>.
        </p>

        <div class="card mb-4">
            <div class="card-body text-center">
                <div class="form-group mb-0">
                    <input
                        type="file"
                        id="fileInput"
                        class="form-control"
                        (change)="changeInputFile($event)"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    />
                </div>
            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="fileContentLoaded">
    <ng-container *ngIf="!dataValid">
        <div
            class="alert alert-danger text-center"
            *ngIf="errorInvalidData === EnumImportDataErrorMsg.empty"
        >
            Erreur : impossible de récupérer les données.
        </div>
        <div
            class="alert alert-danger text-center"
            *ngIf="errorInvalidData === EnumImportDataErrorMsg.numberOfHeadersDifferent"
        >
            Erreur : le nombre de colonne du fichier ne correspond pas.
        </div>
        <div
            class="alert alert-danger text-center"
            *ngIf="errorInvalidData === EnumImportDataErrorMsg.columnsTitleAreDifferent"
        >
            Erreur : le libellé des colonnes du fichier ne correspondent pas.
        </div>

        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th class="text-center">Status</th>
                        <th
                            class="text-center"
                            *ngFor="let header of data.headers"
                            [innerText]="header"
                        ></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let line of data.lines; let i = index">
                        <td
                            class="text-center"
                            [class.text-danger]="data.errorsByLine[i].length"
                            [class.text-success]="!data.errorsByLine[i].length"
                        >
                            <fa-icon
                                [icon]="faCheck"
                                *ngIf="!data.errorsByLine[i].length"
                            ></fa-icon>
                            <fa-icon
                                [icon]="faCancel"
                                *ngIf="data.errorsByLine[i].length"
                            ></fa-icon>
                            <div *ngFor="let errorMsg of data.errorsByLine[i]">
                                <span *ngIf="errorMsg === EnumImportDataErrorMsg.registrationEmpty"
                                    >Immatriculation manquante</span
                                >
                                <span *ngIf="errorMsg === EnumImportDataErrorMsg.builderMissing"
                                    >Constructeur manquant</span
                                >
                                <span
                                    *ngIf="errorMsg === EnumImportDataErrorMsg.aircraftModelMissing"
                                    >Type d'avion manquant</span
                                >
                                <span *ngIf="errorMsg === EnumImportDataErrorMsg.airlineMissing"
                                    >Compagnie aérienne manquante</span
                                >
                            </div>
                        </td>
                        <td
                            *ngFor="let header of data.headers; let j = index"
                            class="text-center"
                            [innerText]="line[j]"
                        ></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-container>

    <div class="card mb-4" *ngIf="dataValid">
        <div class="card-header d-flex justify-content-between align-items-center">
            Aperçu avant import ({{ aircraftsToImport.length }} appareils)

            <button
                type="button"
                class="btn btn-primary"
                [disabled]="
                    !aircraftsReadyToImport || aircraftsReadyToImport !== aircraftsToImport.length
                "
                [innerText]="
                    fetchingRelatedData
                        ? 'Récupération en cours ... ' +
                          '(' +
                          aircraftsReadyToImport +
                          ' / ' +
                          aircraftsToImport.length +
                          ')'
                        : aircraftsReadyToImport !== aircraftsToImport.length
                        ? 'Importer ' +
                          '(' +
                          aircraftsReadyToImport +
                          ' valides / ' +
                          aircraftsToImport.length +
                          ')'
                        : 'Importer'
                "
                (click)="importAircrafts()"
            ></button>
        </div>
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th class="text-center">Immatriculation</th>
                        <th class="text-center">Modèle</th>
                        <th class="text-center">Compagnie aérienne</th>
                        <th class="text-center">Nombre de passagers maximum</th>
                        <th class="text-center">Année de fabrication</th>
                        <th class="text-center">Année de refurbishment</th>
                        <th class="text-center">Base de l'avion</th>
                        <th class="text-center">Nombre de membres d'équipage</th>
                        <th class="text-center">Hotesse</th>
                        <th class="text-center">Fumeur</th>
                        <th class="text-center">Sleeping Configuration</th>
                        <th class="text-center">Toilette</th>
                        <th class="text-center">Catering Chaud</th>
                        <th class="text-center">Pets</th>
                        <th class="text-center">Wifi</th>
                        <th class="text-center">Satellite Phone</th>
                        <th class="text-center">Photos</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let aircraft of aircraftsToImport"
                        [class.table-warning]="
                            (airlinesByTitleLoaded[aircraft.airlineTitle] && !aircraft.airlineId) ||
                            (aircraftModelsByTitleLoaded[aircraft.aircraftModelTitle] &&
                                !aircraft.aircraftModelId)
                        "
                    >
                        <td class="text-center placeholder-glow">
                            <div [innerText]="aircraft.registration" *ngIf="!aircraft.id"></div>
                            <div *ngIf="aircraft.id">
                                <a [routerLink]="'/admin/aircrafts/' + aircraft.id" target="_blank">
                                    {{ aircraft.registration }}
                                    <fa-icon [icon]="faExternalLink"></fa-icon>
                                </a>
                            </div>
                            <div
                                class="placeholder w-100"
                                *ngIf="!aircraftsByRegistrationLoaded[aircraft.registration]"
                            ></div>
                            <div
                                class="badge"
                                [class.text-bg-success]="!aircraft.id"
                                [class.text-bg-primary]="aircraft.id"
                                *ngIf="aircraftsByRegistrationLoaded[aircraft.registration]"
                                [innerText]="aircraft.id ? 'À mettre à jour' : 'À ajouter'"
                            ></div>
                            <div
                                class="badge text-bg-warning"
                                *ngIf="aircraftsByRegistration[aircraft.registration]?.url"
                            >
                                Présent dans Airfleet
                            </div>
                        </td>
                        <td class="text-center placeholder-glow">
                            <div
                                class="placeholder w-100"
                                *ngIf="!aircraftModelsByTitleLoaded[aircraft.aircraftModelTitle]"
                            ></div>
                            <a
                                [routerLink]="'/admin/aircraft-models/' + aircraft.aircraftModelId"
                                *ngIf="aircraft.aircraftModelId"
                                target="_blank"
                            >
                                {{ aircraft.aircraftModelTitle }}
                                <fa-icon [icon]="faExternalLink"></fa-icon>
                            </a>
                            <div
                                *ngIf="
                                    aircraftModelsByTitleLoaded[aircraft.aircraftModelTitle] &&
                                    !aircraft.aircraftModelId
                                "
                            >
                                <strong [innerText]="aircraft.aircraftModelTitle"></strong> n'existe
                                pas dans Simplyfly. Veuillez corriger le fichier d'import OU créer
                                la compagnie manquante dans Simplyfly.
                            </div>

                            <a
                                [routerLink]="
                                    '/admin/aircraft-models/' +
                                    aircraftModelsByTitle[aircraft.aircraftModelTitle].id
                                "
                                target="_blank"
                                class="badge text-bg-warning"
                                *ngIf="
                                    aircraftModelsByTitle[aircraft.aircraftModelTitle] &&
                                    aircraft.aircraftModelTitle !==
                                        aircraftModelsByTitle[aircraft.aircraftModelTitle].title
                                "
                            >
                                Le modèle d'avion dans Simplyfly est différent :
                                {{ aircraftModelsByTitle[aircraft.aircraftModelTitle].title }}
                                <fa-icon [icon]="faExternalLink"></fa-icon>
                            </a>
                        </td>
                        <td class="text-center placeholder-glow">
                            <div
                                class="placeholder w-100"
                                *ngIf="!airlinesByTitleLoaded[aircraft.airlineTitle]"
                            ></div>
                            <div *ngIf="aircraft.airlineId">
                                <a
                                    [routerLink]="'/admin/airlines/' + aircraft.airlineId"
                                    target="_blank"
                                >
                                    {{ aircraft.airlineTitle }}
                                    <fa-icon [icon]="faExternalLink"></fa-icon>
                                </a>
                            </div>
                            <div
                                *ngIf="
                                    airlinesByTitleLoaded[aircraft.airlineTitle] &&
                                    !aircraft.airlineId
                                "
                            >
                                <strong [innerText]="aircraft.airlineTitle"></strong> n'existe pas
                                dans Simplyfly. Veuillez corriger le fichier d'import OU créer la
                                compagnie manquante dans Simplyfly.
                            </div>

                            <a
                                [routerLink]="
                                    '/admin/airlines/' +
                                    aircraftsByRegistration[aircraft.registration].id
                                "
                                target="_blank"
                                class="badge text-bg-warning"
                                *ngIf="
                                    aircraftsByRegistration[aircraft.registration] &&
                                    aircraft.airlineTitle !==
                                        aircraftsByRegistration[aircraft.registration].airlineTitle
                                "
                            >
                                La compagnie aérienne dans Simplyfly est différente :
                                {{ aircraftsByRegistration[aircraft.registration].airlineTitle }}
                                <fa-icon [icon]="faExternalLink"></fa-icon>
                            </a>
                        </td>
                        <td class="text-center" [innerText]="aircraft.seatTotal"></td>
                        <td class="text-center" [innerText]="aircraft.dateOfManufacture"></td>
                        <td class="text-center" [innerText]="aircraft.dateOfRefurbishment"></td>
                        <td class="text-center" [innerText]="aircraft.homebase"></td>
                        <td class="text-center" [innerText]="aircraft.numberOfCrewMembers"></td>
                        <td
                            class="text-center"
                            [innerText]="getAircraftHasStewardessLabel(aircraft.hasStewardess)"
                        ></td>
                        <td
                            class="text-center"
                            [innerText]="aircraft.isSmokingAllowed ? 'Oui' : 'Non'"
                        ></td>
                        <td class="text-center" [innerText]="aircraft.sleepingConfiguration"></td>
                        <td
                            class="text-center"
                            [innerText]="aircraft.hasToilet ? 'Oui' : 'Non'"
                        ></td>
                        <td
                            class="text-center"
                            [innerText]="getAircraftHasHotCateringLabel(aircraft.hasHotCatering)"
                        ></td>
                        <td
                            class="text-center"
                            [innerText]="aircraft.arePetsAllowed ? 'Oui' : 'Non'"
                        ></td>
                        <td
                            class="text-center"
                            [innerText]="getAircraftWifiLabel(aircraft.wifi)"
                        ></td>
                        <td
                            class="text-center"
                            [innerText]="aircraft.hasSatellitePhone ? 'Oui' : 'Non'"
                        ></td>
                        <td class="text-center">
                            <div *ngFor="let url of aircraft.imageUrls; let i = index">
                                <a
                                    [href]="url"
                                    target="_blank"
                                    [innerText]="'Photo ' + (i + 1)"
                                ></a>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</ng-container>
