<div class="text-center" *ngIf="loadingAirlines">
    <div class="spinner-border" role="status"></div>
    <div>Récupération des compagnies aériennes ...</div>
</div>

<div class="text-center" *ngIf="analysingDuplicateAirlines">
    <div class="spinner-border" role="status"></div>
    <div>Recherche de doublons ...</div>
</div>
<ng-container *ngIf="!loadingAirlines && !analysingDuplicateAirlines">
    <div class="alert alert-success text-center" *ngIf="!airlinesToMerge.length">
        Aucune compagnie aérienne en doublon
    </div>

    <ng-container *ngIf="airlinesToMerge.length">
        <div class="alert alert-warning text-center">
            <strong [innerText]="airlinesToMerge.length"></strong> compagnies aériennes ont des
            doublons détectées.
        </div>
        <div class="accordion mb-4" id="accordion-airlines">
            <div class="accordion-item" *ngFor="let item of airlinesToMerge; let i = index">
                <h2 class="accordion-header">
                    <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        [attr.data-bs-target]="'#airlines-' + i"
                        aria-expanded="false"
                        [attr.aria-controls]="'airlines-' + i"
                    >
                        <div class="w-100 d-flex justify-content-between me-2">
                            <div
                                [innerText]="
                                    getCountryLabel(item.airlines[0].countryCode) +
                                    ' - ' +
                                    item.airlines[0].title
                                "
                            ></div>
                            <div
                                class="float-end"
                                [innerText]="item.airlines.length + ' doublons'"
                            ></div>
                        </div>
                    </button>
                </h2>
                <div
                    [id]="'airlines-' + i"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordion-airlines"
                >
                    <div class="accordion-body">
                        <div class="row justify-content-center">
                            <div class="col-md-6" *ngFor="let airline of item.airlines">
                                <div class="card mb-4">
                                    <div
                                        class="card-header text-center"
                                        [innerText]="airline.title"
                                    ></div>
                                    <div class="card-body text-center">
                                        Fiche crée le {{ airline.created | date : "dd/MM/YYYY" }}

                                        <div class="mt-2">
                                            <a
                                                [routerLink]="'/admin/airlines/' + airline.id"
                                                class="btn btn-primary"
                                                target="_blank"
                                                ><fa-icon [icon]="faEye"></fa-icon> Voir la fiche</a
                                            >
                                        </div>
                                    </div>
                                    <div
                                        class="card-footer text-center"
                                        *ngIf="item.status === EnumStatus.readyToMerge"
                                    >
                                        <button
                                            type="button"
                                            class="btn btn-success"
                                            (click)="confirmMergeAirlines(i, airline.id)"
                                        >
                                            <fa-icon [icon]="faCheckCircle"></fa-icon> Ceci est la
                                            fiche finale
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            class="alert alert-info text-center"
                            *ngIf="item.status === EnumStatus.draft"
                        >
                            Vous devez manuellement corriger l'une des deux fiches avant de les
                            fusionner. Ceci inclut les contacts et documents de la compagnie
                            aérienne. Une fois fait, cliquez sur le bouton ci-dessous :<br />
                            <button
                                type="button"
                                class="btn btn-primary btn-sm"
                                (click)="mergeAirlines(i)"
                            >
                                <fa-icon [icon]="faCheckCircle"></fa-icon> Je suis prêt à fusionner
                            </button>
                        </div>

                        <div
                            class="alert alert-warning text-center"
                            *ngIf="item.status === EnumStatus.readyToMerge"
                        >
                            Veuillez sélectionner la fiche finale ci-dessus.<br />
                            <button
                                type="button"
                                class="btn btn-warning btn-sm"
                                (click)="cancelMergeAirlines(i)"
                            >
                                <fa-icon [icon]="faCancel"></fa-icon>
                                Annuler
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>
