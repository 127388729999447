import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormGroup, FormControl, AbstractControl, Validators } from '@angular/forms';
import {
  faArrowLeft,
  faArrowRight,
  faCirclePlus,
  faPlane
} from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { EnumAircraftStatus, getAircraftStatusLabel } from 'src/app/enums/aircraft-status.enum';
import { getContinentLabel, getCountryLabel } from 'src/app/enums/continent-code.enum';
import { EnumEnquiryType } from 'src/app/enums/enquiry-type.enum';
import {
  IAircraftCompiled,
  getAircraftCompiledModelTitle
} from 'src/app/interfaces/aircraft-compiled.interface';
import { IAircraftModel } from 'src/app/interfaces/aircraft-model.interface';
import { IAircraft } from 'src/app/interfaces/aircraft.interface';
import { IAirline } from 'src/app/interfaces/airline.interface';
import { IEnquiryFlight } from 'src/app/interfaces/enquiry-flight.interface';
import { IEnquiry } from 'src/app/interfaces/enquiry.interface';
import { AclService } from 'src/app/services/acl.service';
import { AircraftCompiledService } from 'src/app/services/aircraft-compiled/aircraft-compiled.service';
import { AircraftService } from 'src/app/services/aircrafts/aircrafts.service';
import { AirlineService } from 'src/app/services/airlines/airlines.service';
import { LoaderService } from 'src/app/services/loader/loader.service';

@Component({
  selector: 'app-enquiry-flight-edit-aircraft',
  templateUrl: './enquiry-flight-edit-aircraft.component.html',
  styleUrls: ['./enquiry-flight-edit-aircraft.component.scss']
})
export class EnquiryFlightEditAircraftComponent implements OnInit, OnChanges, OnDestroy {
  @Input() enquiry: IEnquiry | null = null;
  @Input() enquiryFlight: IEnquiryFlight | null = null;
  @Output() goPrev: EventEmitter<void> = new EventEmitter();
  @Output() saveAndGoNext: EventEmitter<IEnquiryFlight> = new EventEmitter();

  @ViewChild('modalAircraftAdd', { static: false }) modalAircraftAddElement: ElementRef;

  EnumEnquiryType = EnumEnquiryType;

  form: FormGroup = this.resetForm();

  formAircraft: FormGroup = new FormGroup({
    serialNumber: new FormControl(null),
    status: new FormControl(EnumAircraftStatus.active, Validators.required),
    registration: new FormControl(null, Validators.required)
  });

  EnumAcl = EnumAcl;

  faArrowLeft = faArrowLeft;
  faArrowRight = faArrowRight;
  faPlane = faPlane;
  faCirclePlus = faCirclePlus;

  loadingAircraftsCompiled: boolean = false;
  aircraftsCompiled: IAircraftCompiled[] = [];
  aircraftModels: IAircraftModel[] = [];
  loadingAircrafts: boolean = false;
  aircrafts: IAircraft[] = [];

  airlinesObj: { [id: string]: IAirline | null } = {};

  getAircraftStatusLabel = getAircraftStatusLabel;

  private subscriptions = new Subscription();

  constructor(
    private aircraftCompiledService: AircraftCompiledService,
    private aircraftService: AircraftService,
    private airlineService: AirlineService,
    private aclService: AclService,
    private loaderService: LoaderService
  ) {}

  ngOnInit(): void {
    this.applyEnquiryFlight();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['enquiryFlight'] &&
      (!changes['enquiryFlight'].previousValue ||
        changes['enquiryFlight'].previousValue !== changes['enquiryFlight'].currentValue)
    ) {
      this.applyEnquiryFlight();
    }
  }

  ngOnDestroy(): void {
    this.hideAircraftModal();

    window['$']('.tooltip').remove();
  }

  hideAircraftModal(): void {
    window['$'](this.modalAircraftAddElement.nativeElement).modal('hide');
  }

  openAircraftAddModal(): void {
    window['$'](this.modalAircraftAddElement.nativeElement).modal('show');
  }

  get countryCode(): FormControl {
    return this.form.get('countryCode') as FormControl;
  }
  get airlineId(): FormControl {
    return this.form.get('airlineId') as FormControl;
  }
  get airlineTitle(): FormControl {
    return this.form.get('airlineTitle') as FormControl;
  }
  get aircraftModelId(): FormControl {
    return this.form.get('aircraftModelId') as FormControl;
  }
  get aircraftModelTitle(): FormControl {
    return this.form.get('aircraftModelTitle') as FormControl;
  }
  get aircraftId(): FormControl {
    return this.form.get('aircraftId') as FormControl;
  }
  get aircraftRegistration(): FormControl {
    return this.form.get('aircraftRegistration') as FormControl;
  }
  get displayAirlineOnFlightBrief(): FormControl {
    return this.form.get('displayAirlineOnFlightBrief') as FormControl;
  }
  get aircraftHasToilet(): FormControl {
    return this.form.get('aircraftHasToilet') as FormControl;
  }
  get smokingIsAllowed(): FormControl {
    return this.form.get('smokingIsAllowed') as FormControl;
  }

  getAircraftStatuses(): EnumAircraftStatus[] {
    return Object.values(EnumAircraftStatus);
  }

  applyEnquiryFlight(): void {
    this.form = this.resetForm();

    if (this.enquiryFlight) {
      for (const field in this.form.value) {
        if (typeof this.enquiryFlight[field] !== 'undefined') {
          switch (field) {
            default:
              this.form.get(field).setValue(this.enquiryFlight[field]);
              break;
          }
        }
      }

      if (this.airlineId.value) {
        this.loadAirline(this.airlineId.value);

        this.loadAircraftCompiled();
      }

      if (this.aircraftModelId.value) {
        this.updatedAircraftModelId();
      }

      this.form.enable();
    }
  }

  resetForm(): FormGroup {
    return new FormGroup({
      countryCode: new FormControl(null),
      airlineId: new FormControl(null, Validators.required),
      airlineTitle: new FormControl(null),
      aircraftModelId: new FormControl(null, Validators.required),
      aircraftModelTitle: new FormControl(null),
      aircraftId: new FormControl(null),
      aircraftRegistration: new FormControl(null),
      displayAirlineOnFlightBrief: new FormControl(true),
      aircraftHasToilet: new FormControl(true),
      smokingIsAllowed: new FormControl(false)
    });
  }

  prev(): void {
    this.goPrev.emit();
  }

  saveAndNext(): void {
    if (this.form.valid) {
      this.saveAndGoNext.emit(this.form.value);
    }
  }

  applyAirline(airline: IAirline | null): void {
    if (airline) {
      this.countryCode.setValue(airline.countryCode);
      this.airlineId.setValue(airline.id);
      this.airlineTitle.setValue(airline.title);

      this.loadAircraftCompiled();
    } else {
      for (const field of [
        'countryCode',
        'airlineId',
        'airlineTitle',
        'aircraftModelId',
        'aircraftModelTitle',
        'aircraftId',
        'aircraftRegistration'
      ]) {
        this[field].setValue(null);
        this[field].updateValueAndValidity();
      }
    }
  }

  loadAircraftCompiled(): void {
    if (this.airlineId.value) {
      this.loadingAircraftsCompiled = true;

      this.subscriptions.add(
        this.aircraftCompiledService
          .getAllForAirline(this.airlineId.value)
          .subscribe((aircraftsCompiled: IAircraftCompiled[]) => {
            this.loadingAircraftsCompiled = true;

            this.aircraftsCompiled = aircraftsCompiled;

            this.updateAircraftModelsFromAircraftsCompiled();

            this.loadingAircraftsCompiled = false;
          })
      );
    }
  }

  updateAircraftModelsFromAircraftsCompiled(): void {
    const aircraftModelsObj: { [key: string]: IAircraftModel } = {};

    for (const aircraftCompiled of this.aircraftsCompiled) {
      aircraftModelsObj[aircraftCompiled.type] = {
        id: aircraftCompiled.aircraftModelId,
        family: aircraftCompiled.family,
        title: getAircraftCompiledModelTitle(aircraftCompiled),
        weight: aircraftCompiled.weight,
        volume: aircraftCompiled.volume,
        target: aircraftCompiled.target
      } as IAircraftModel;
    }

    this.aircraftModels = Object.values(aircraftModelsObj);
    this.aircraftModels.sort((a, b) => (a.title > b.title ? 1 : -1));

    // this.setEstimatedArrivalTimeIfNotProvided();

    this.updateAircraftsFromAircraftsCompiled();
  }

  updatedAircraftModelId(): void {
    if (this.aircraftModelId.value) {
      for (const aircraftModel of this.aircraftModels) {
        if (aircraftModel.id === this.aircraftModelId.value) {
          this.aircraftModelTitle.setValue(aircraftModel.title);

          break;
        }
      }
    } else {
      this.aircraftModelTitle.setValue(null);
    }

    this.aircraftModelTitle.updateValueAndValidity();

    this.updateAircraftsFromAircraftsCompiled();
  }

  updateAircraftsFromAircraftsCompiled(): void {
    this.aircrafts = [];

    if (this.airlineId.value && this.aircraftModelId.value) {
      this.loadingAircrafts = true;
      this.subscriptions.add(
        this.aircraftService
          .getAllForAirlineAndModel(this.airlineId.value, this.aircraftModelId.value)
          .subscribe((aircrafts: IAircraft[]) => {
            this.loadingAircrafts = true;

            this.aircrafts = aircrafts;

            this.aircrafts.sort((a, b) => (a.registration > b.registration ? 1 : -1));

            let aircraftSelectedIsInList: boolean = false;
            for (const aircraft of this.aircrafts) {
              if (aircraft.id === this.aircraftId.value) {
                aircraftSelectedIsInList = true;
                break;
              }
            }

            if (!aircraftSelectedIsInList) {
              this.aircraftId.setValue(null);
              this.aircraftId.updateValueAndValidity();
            }

            this.loadingAircrafts = false;
          })
      );

      this.aircrafts.sort((a, b) => (a.registration > b.registration ? 1 : -1));
    }
  }

  updatedAircraftId(): void {
    if (this.aircraftId.value) {
      for (const aircraft of this.aircrafts) {
        if (aircraft.id === this.aircraftId.value) {
          this.applyAircraftInfoToForm(aircraft);
          break;
        }
      }
    } else {
      this.applyAircraftInfoToForm(null);
    }
  }

  applyAircraftInfoToForm(aircraft: IAircraft | null): void {
    if (aircraft) {
      this.aircraftRegistration.setValue(
        aircraft.registration !== '' ? aircraft.registration : 'Inconnu'
      );
    } else {
      this.aircraftRegistration.setValue(null);
    }

    this.aircraftRegistration.updateValueAndValidity();
  }

  loadAirline(airlineId: string): void {
    if (typeof this.airlinesObj[airlineId] === 'undefined') {
      this.airlinesObj[airlineId] = null;

      this.subscriptions.add(
        this.airlineService.getFromId(airlineId).subscribe((airline: IAirline | null) => {
          this.airlinesObj[airlineId] = airline;
        })
      );
    }
  }

  async setValueToFormControl($event: {
    fields: {
      name: string;
      value: any;
    }[];
  }): Promise<void> {
    for (let field of $event.fields) {
      const nameList = field.name.split('.');

      let formControl: AbstractControl = this.form;
      for (let name of nameList) {
        formControl = formControl.get(name);
      }

      if (field.name === 'airlineId') {
        this.applyAirline(field.value as IAirline | null);
      } else {
        formControl.setValue(field.value);
      }

      formControl.markAsTouched();
      formControl.updateValueAndValidity();
    }
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  async saveAircraft(): Promise<void> {
    this.formAircraft.markAsTouched();

    if (this.formAircraft.status == 'VALID') {
      let data: IAircraft = Object.assign({}, this.formAircraft.value);

      for (const field in data) {
        if (typeof data[field] == 'undefined') {
          data[field] = null;
        }
      }

      this.loaderService.presentLoader();

      this.formAircraft.disable();

      data.aircraftModelId = this.form.value.aircraftModelId ?? null;

      for (const aircraftModel of this.aircraftModels) {
        if (aircraftModel.id === data.aircraftModelId) {
          data.model = aircraftModel.family ?? null;
          data.type = aircraftModel.slug ?? data.model;

          break;
        }
      }

      data.airlineContinentCode = this.airlinesObj[this.airlineId.value]?.continentCode ?? null;
      data.airlineCountryCode = this.airlinesObj[this.airlineId.value]?.countryCode ?? null;
      data.airlineContinentTitle = this.airlinesObj[this.airlineId.value]?.continentCode
        ? getContinentLabel(this.airlinesObj[this.airlineId.value]?.continentCode)
        : null;
      data.airlineCountryTitle = this.airlinesObj[this.airlineId.value]?.countryCode
        ? getCountryLabel(this.airlinesObj[this.airlineId.value]?.countryCode)
        : null;
      data.airlineId = this.form.value.airlineId;
      data.airlineTitle = this.airlinesObj[this.airlineId.value]?.title ?? null;

      try {
        data.id = await this.aircraftService.create(data);

        if (data.id) {
          this.form.get('aircraftId').setValue(data.id);

          this.applyAircraftInfoToForm(data);
        }

        this.formAircraft.reset();

        this.hideAircraftModal();

        await this.loaderService.hideLoaderOnSuccess();
      } catch (err) {
        await this.loaderService.hideLoaderOnFailure(err);

        this.formAircraft.enable();
      }
    }
  }
}
