import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { PaginationService } from '../../../services/pagination.service';
import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';

import { getEnquiryBreadcrumbTitle, IEnquiry } from 'src/app/interfaces/enquiry.interface';
import { Subscription } from 'rxjs';
import { EnquiryService } from 'src/app/services/enquiry/enquiry.service';
import { EnumEnquiryStatus } from 'src/app/enums/enquiry-status.enum';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { IUser } from 'src/app/interfaces/user.interface';
import { getLanguageLabel } from 'src/app/enums/language.enum';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs/breadcrumbs.service';

@Component({
  selector: 'app-list-flight-briefs',
  templateUrl: './list-flight-briefs.component.html',
  styleUrls: ['./list-flight-briefs.component.scss']
})
export class ListFlightBriefsComponent implements OnInit, OnDestroy {
  getEnquiryBreadcrumbTitle = getEnquiryBreadcrumbTitle;
  getLanguageLabel = getLanguageLabel;

  EnumEnquiryStatus = EnumEnquiryStatus;
  EnumAcl = EnumAcl;

  rootUrl: string = './';
  currentPagination: string = 'flight-briefs-list';
  enquiryId: string;
  enquiry: IEnquiry;
  usersObj: { [key: string]: IUser } = {};

  subscriptions = new Subscription();

  constructor(
    public paginationService: PaginationService,
    private aclService: AclService,
    private activatedRoute: ActivatedRoute,
    private enquiryService: EnquiryService,
    private breadcrumbsService: BreadcrumbsService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.aclService.checkAclAccess(EnumAcl.flightBriefsList);

    this.activatedRoute.url.subscribe(async () => {
      this.enquiryId = this.activatedRoute.snapshot.paramMap.get('enquiryId');

      this.loadEnquiry();

      this.fetchData();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  async fetchData(): Promise<void> {
    if (this.enquiryId) {
      this.paginationService.pagination[this.currentPagination].conditions = [
        {
          field: 'enquiryId',
          operator: '==',
          value: this.enquiryId
        }
      ];
    }

    await this.paginationService.fetchData(this.currentPagination);
  }

  loadEnquiry(): void {
    if (this.enquiryId) {
      this.subscriptions.add(
        this.enquiryService.getFromId(this.enquiryId).subscribe((enquiry: IEnquiry) => {
          this.enquiry = enquiry;

          if (this.enquiry) {
            this.breadcrumbsService.setCurrentItem({
              id: this.enquiry.id,
              text: getEnquiryBreadcrumbTitle(this.enquiry)
            });
          }
        })
      );
    }
  }
}
