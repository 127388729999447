import {
  Component,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
  ViewChild,
  ElementRef
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { RemoteService } from '../../../services/remote.service';

import { Subscription } from 'rxjs';
import {
  EnumFlightBriefPassengerCivility,
  IFlightBrief,
  IFlightBriefPassengerInfo,
  IFlightBriefTrip,
  getFlightBriefPassengerCivilityLabel
} from 'src/app/interfaces/flight-brief.interface';
import {
  IEnquiry,
  getDisplayedEnquiryRefTitle,
  getEnquiryBreadcrumbTitle
} from 'src/app/interfaces/enquiry.interface';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { AclService } from 'src/app/services/acl.service';
import { EnquiryService } from 'src/app/services/enquiry/enquiry.service';
import { EnumLanguage, getLanguageLabel } from 'src/app/enums/language.enum';
import { FlightBriefService } from 'src/app/services/flight-briefs/flight-briefs.service';
import { IUser, getUserFullname } from 'src/app/interfaces/user.interface';
import { addMinutes, differenceInDays, differenceInMinutes, format, parseISO } from 'date-fns';
import { faCircleCheck, faEdit, faPlane, faTrash } from '@fortawesome/free-solid-svg-icons';
import {
  IAirport,
  getAirportCity,
  getAirportLocalTimeFromUTC,
  getAirportUTCTime,
  getAirportUTCTimeString,
  getDefaultAirportAddressDetails
} from 'src/app/interfaces/airport.interface';
import { AirportService } from 'src/app/services/airports/airports.service';
import { finalize } from 'rxjs/operators';
import { IAirline } from 'src/app/interfaces/airline.interface';
import { AirlineService } from 'src/app/services/airlines/airlines.service';
import { AircraftCompiledService } from 'src/app/services/aircraft-compiled/aircraft-compiled.service';
import {
  IAircraftCompiled,
  getAircraftCompiledModelTitle
} from 'src/app/interfaces/aircraft-compiled.interface';
import { IAircraftModel } from 'src/app/interfaces/aircraft-model.interface';
import { IAircraft } from 'src/app/interfaces/aircraft.interface';
import { AircraftService } from 'src/app/services/aircrafts/aircrafts.service';
import { fr } from 'date-fns/locale';
import { QuotationService } from 'src/app/services/quotations/quotations.service';
import { IQuotation } from 'src/app/interfaces/quotation.interface';
import { FlightBriefCateringService } from 'src/app/services/flight-brief-caterings/flight-brief-caterings.service';
import { IFlightBriefCatering } from 'src/app/interfaces/flight-brief-catering.interface';
import { IEnquiryCotation } from 'src/app/interfaces/enquiry-cotation.interface';
import { EnquiryCotationService } from 'src/app/services/enquiry-cotation/enquiry-cotation.service';
import { EnquiryOfferService } from 'src/app/services/enquiry-offer/enquiry-offer.service';
import { IEnquiryOffer } from 'src/app/interfaces/enquiry-offer.interface';
import { AirportAddressService } from 'src/app/services/airport-addresses/airport-addresses.service';
import { IAirportAddress } from 'src/app/interfaces/airport-address.interface';
import { EnumAircraftStatus, getAircraftStatusLabel } from 'src/app/enums/aircraft-status.enum';
import { getCountryLabel, getContinentLabel } from 'src/app/enums/continent-code.enum';
import { TranslateService } from '@ngx-translate/core';
import { addZeroToDigit, estimatedFlyTimeInMinutes } from 'src/app/misc.utils';
import { EnumEnquiryType } from 'src/app/enums/enquiry-type.enum';
import { formatInTimeZone } from 'date-fns-tz';
import { IFbo, getFboAddressDetails, getFboFullAddress } from 'src/app/interfaces/fbo.interface';
import { FboService } from 'src/app/services/fbos/fbos.service';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs/breadcrumbs.service';

@Component({
  selector: 'app-flight-brief-edit',
  templateUrl: './flight-brief-edit.component.html',
  styleUrls: ['./flight-brief-edit.component.scss']
})
export class FlightBriefEditComponent implements OnInit, OnDestroy {
  @ViewChild('modalAircraftAdd', { static: false }) modalAircraftAddElement: ElementRef;
  @ViewChild('modalFbo', { static: false }) modalFboElement: ElementRef;

  getLanguageLabel = getLanguageLabel;
  getFlightBriefPassengerCivilityLabel = getFlightBriefPassengerCivilityLabel;
  getAirportCity = getAirportCity;
  getAirportUTCTimeString = getAirportUTCTimeString;
  getAircraftStatusLabel = getAircraftStatusLabel;
  getFboAddressDetails = getFboAddressDetails;

  EnumEnquiryType = EnumEnquiryType;

  EnumAcl = EnumAcl;

  faTrash = faTrash;
  faPlane = faPlane;
  faCircleCheck = faCircleCheck;
  faCircle = faCircle;
  faEdit = faEdit;

  isLogged: boolean = false;
  form: FormGroup;
  sending: boolean = false;
  flightBrief: IFlightBrief;
  flightBriefId: string;
  enquiryId: string;
  enquiry: IEnquiry;
  enquiryOffers: IEnquiryOffer[] = [];
  enquiryCotations: IEnquiryCotation[] = [];
  currentUser: IUser | null = null;
  airportsObj: { [key: string]: IAirport } = {};
  airline: IAirline | null = null;

  loadingAircraftsCompiled: boolean = false;
  aircraftsCompiled: IAircraftCompiled[] = [];
  aircraftModels: IAircraftModel[] = [];
  loadingAircrafts: boolean = false;
  aircrafts: IAircraft[] = [];
  quotationId: string;
  quotation: IQuotation;
  fbosByAirportsObj: { [airportId: string]: IFbo[] } = {};
  defaultAirportAddressObj: { [key: string]: IAirportAddress } = {};

  flightBriefCaterings: IFlightBriefCatering[] = [];

  formAircraft: FormGroup = new FormGroup({
    serialNumber: new FormControl(''),
    status: new FormControl(EnumAircraftStatus.active, Validators.required),
    registration: new FormControl('', Validators.required)
  });
  savingAircraft: boolean = false;

  subscriptions = new Subscription();
  subscriptionsForCatering = new Subscription();
  subscriptionsForEnquiry = new Subscription();
  subscriptionsTranslations = new Subscription();

  translationObj: { [key: string]: any } = {};

  airportAddressDetailsCurrentIndex: number | null = null;
  editingFbo: IFbo | null = null;

  constructor(
    private formBuilder: FormBuilder,
    private remoteService: RemoteService,
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private aclService: AclService,
    private enquiryService: EnquiryService,
    private enquiryOfferService: EnquiryOfferService,
    private enquiryCotationService: EnquiryCotationService,
    private flightBriefService: FlightBriefService,
    private router: Router,
    private airportService: AirportService,
    private airlineService: AirlineService,
    private aircraftCompiledService: AircraftCompiledService,
    private aircraftService: AircraftService,
    private quotationService: QuotationService,
    private flightBriefCateringService: FlightBriefCateringService,
    private fboService: FboService,
    private translateService: TranslateService,
    private breadcrumbsService: BreadcrumbsService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
    this.remoteService.userObservable.subscribe((currentUser: IUser) => {
      this.currentUser = currentUser;
    });
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      enquiryId: [null, [Validators.required]],
      language: [EnumLanguage.fr, [Validators.required]],
      versionNumber: [1, [Validators.required]],
      trips: new FormArray([]),
      airlineId: [null],
      airlineTitle: [null],
      aircraftModelId: [null],
      aircraftModelTitle: [null],
      aircraftId: [null],
      aircraftRegistration: [null],
      aircraftHasToilet: [true, Validators.required],
      smokingIsAllowed: [false, Validators.required],
      displayAirlineOnFlightBrief: [true, Validators.required],
      pilot: [null],
      coPilot: [null],
      cabinCrew: [null],
      comments: [null],
      internalNote: [null],
      googleMapStaticUrl: [''],
      imageOutsideUrl: [''],
      imageInsideUrl: [''],
      imageOutsideZoomLevel: [1],
      imageInsideZoomLevel: [1],
      airportsAddressDetails: new FormArray([]),
      typeOfCargo: [''],
      weightInKg: [],
      volumeInM3: [],
      nbPackages: [],
      awbNumber: [],
      customDeclaration: [],
      dgd: [false]
    });

    this.form.disable();

    this.activatedRoute.url.subscribe(async () => {
      this.flightBriefId = this.activatedRoute.snapshot.paramMap.get('flightBriefId');
      this.enquiryId = this.activatedRoute.snapshot.paramMap.get('enquiryId');
      this.quotationId = this.activatedRoute.snapshot.paramMap.get('quotationId');

      if (this.enquiryId) {
        this.form.get('enquiryId').setValue(this.enquiryId);
      }

      if (this.flightBriefId) {
        await this.aclService.checkAclAccess(EnumAcl.flightBriefsEdit);
      } else if (this.quotationId) {
        await this.aclService.checkAclAccess(EnumAcl.flightBriefsGenerateFromQuotation);
      } else {
        await this.aclService.checkAclAccess(EnumAcl.flightBriefsEdit);
      }

      this.loadData();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.subscriptionsForEnquiry.unsubscribe();
    this.subscriptionsForCatering.unsubscribe();
    this.subscriptionsTranslations.unsubscribe();

    this.removeModal();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  getLanguages(): EnumLanguage[] {
    return Object.values(EnumLanguage);
  }

  getCivilities(): EnumFlightBriefPassengerCivility[] {
    return Object.values(EnumFlightBriefPassengerCivility);
  }

  getAircraftStatuses(): EnumAircraftStatus[] {
    return Object.values(EnumAircraftStatus);
  }

  get trips(): FormArray {
    return this.form.get('trips') as FormArray;
  }

  getTrip(i: number): FormGroup {
    return this.trips.at(i) as FormGroup;
  }

  getTripField(i: number, field: string): FormControl {
    return this.getTrip(i).get(field) as FormControl;
  }

  getPassengers(tripIndex: number): FormArray {
    return this.getTrip(tripIndex).get('passengers') as FormArray;
  }

  getPassenger(tripIndex: number, i: number): FormGroup {
    return this.getPassengers(tripIndex).at(i) as FormGroup;
  }

  get airportsAddressDetails(): FormArray {
    return this.form.get('airportsAddressDetails') as FormArray;
  }

  getAirportAddressDetails(i: number): FormGroup {
    return this.airportsAddressDetails.at(i) as FormGroup;
  }

  getAirportAddressDetailsField(i: number, field: string): FormControl {
    return this.getAirportAddressDetails(i).get(field) as FormControl;
  }

  get aircraftModelId(): FormControl {
    return this.form.get('aircraftModelId') as FormControl;
  }

  get typeOfCargo(): FormControl {
    return this.form.get('typeOfCargo') as FormControl;
  }

  get weightInKg(): FormControl {
    return this.form.get('weightInKg') as FormControl;
  }

  get volumeInM3(): FormControl {
    return this.form.get('volumeInM3') as FormControl;
  }

  get nbPackages(): FormControl {
    return this.form.get('nbPackages') as FormControl;
  }

  get awbNumber(): FormControl {
    return this.form.get('awbNumber') as FormControl;
  }

  get customDeclaration(): FormControl {
    return this.form.get('customDeclaration') as FormControl;
  }

  get dgd(): FormControl {
    return this.form.get('dgd') as FormControl;
  }

  async loadData(): Promise<void> {
    if (this.isLogged) {
      if (this.flightBriefId) {
        this.loadFlightBrief();
      } else if (this.quotationId) {
        this.loadPreviousFlightBriefs();
        this.loadQuotation();
        this.loadTranslation();

        this.form.enable();
      } else {
        this.loadPreviousFlightBriefs();
        this.loadEnquiry();
        this.loadEnquiryConfirmedCotations();
        this.loadEnquiryConfirmedOffers();
        this.loadFlightBriefCaterings();
        this.loadTranslation();

        this.form.enable();
      }
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  loadEnquiry(): void {
    let enquiryId: string = this.getEnquiryId();

    if (enquiryId) {
      this.subscriptionsForEnquiry.add(
        this.enquiryService.getFromId(enquiryId).subscribe((enquiry: IEnquiry) => {
          this.enquiry = enquiry;

          if (this.enquiry) {
            this.breadcrumbsService.setCurrentItem({
              id: this.enquiry.id,
              text: getEnquiryBreadcrumbTitle(this.enquiry)
            });
          }

          this.updateTripFromConfirmedCotationsAndEnquiry();
        })
      );
    }
  }

  loadEnquiryConfirmedOffers(): void {
    let enquiryId: string = this.getEnquiryId();

    if (enquiryId) {
      this.subscriptionsForEnquiry.add(
        this.enquiryOfferService
          .getAllConfirmedForEnquiry(enquiryId)
          .subscribe((enquiryOffers: IEnquiryOffer[]) => {
            this.enquiryOffers = enquiryOffers;

            for (const enquiryOffer of this.enquiryOffers) {
              if (enquiryOffer.aircraftCompiled && !this.form.value.aircraftId) {
                this.form
                  .get('airlineId')
                  .setValue(this.enquiryOffers[0].aircraftCompiled.airlineId);
                this.form
                  .get('airlineTitle')
                  .setValue(this.enquiryOffers[0].aircraftCompiled.airlineTitle);
                this.form
                  .get('aircraftModelId')
                  .setValue(this.enquiryOffers[0].aircraftCompiled.aircraftModelId);
                this.form
                  .get('aircraftModelTitle')
                  .setValue(this.enquiryOffers[0].aircraftCompiled.modelTitle);

                this.loadAirline();
                this.loadAircraftCompiled();
              }
            }
          })
      );
    }
  }

  loadEnquiryConfirmedCotations(): void {
    let enquiryId: string = this.getEnquiryId();

    if (enquiryId) {
      this.subscriptionsForEnquiry.add(
        this.enquiryCotationService
          .getAllConfirmedForEnquiry(enquiryId)
          .subscribe((enquiryCotations: IEnquiryCotation[]) => {
            this.enquiryCotations = enquiryCotations;

            this.updateTripFromConfirmedCotationsAndEnquiry();
          })
      );
    }
  }

  updateTripFromConfirmedCotationsAndEnquiry(): void {
    if (this.enquiry && !this.trips.length) {
      for (const enquiryCotation of this.enquiryCotations) {
        for (const itinerary of this.enquiry.itineraries) {
          for (const trip of itinerary.trips) {
            if (enquiryCotation.legsId.includes(trip.id)) {
              const passengers: IFlightBriefPassengerInfo[] = [];

              for (let i = 0; i < enquiryCotation.nbPax; i++) {
                passengers.push({
                  civility: EnumFlightBriefPassengerCivility.mr
                } as IFlightBriefPassengerInfo);
              }

              this.addTripAndSet({
                id: trip.id,
                date: trip.date,
                departureTime: trip.time,
                arrivalTime: trip.arrivalTime,
                flyTimeInHours: '00:00',
                airportDepartId: trip.airportDepart,
                airportArrivalId: trip.airportDestination,
                passengers
              } as IFlightBriefTrip);

              for (const field of ['airportDepart', 'airportDestination']) {
                if (trip[field]) {
                  this.loadAirport(trip[field]);
                }
              }
            }
          }
        }
      }

      this.setEstimatedArrivalTimeIfNotProvided();
    }

    this.setMandatoryFieldsAccordingToEnquiryType();
  }

  getEnquiryId(): string {
    let enquiryId: string;

    if (this.flightBrief?.enquiryId) {
      enquiryId = this.flightBrief.enquiryId;
    } else if (this.form.value.enquiryId) {
      enquiryId = this.form.value.enquiryId;
    } else if (this.enquiryId) {
      enquiryId = this.enquiryId;
    }

    return enquiryId;
  }

  loadQuotation(): void {
    if (this.quotationId) {
      this.subscriptions.add(
        this.quotationService.getFromId(this.quotationId).subscribe((quotation: IQuotation) => {
          this.quotation = quotation;

          if (this.quotation) {
            for (const field of ['enquiryId', 'language']) {
              this.form.get(field).setValue(this.quotation[field]);
            }

            for (const trip of this.quotation.trips) {
              const passengers: IFlightBriefPassengerInfo[] = [];

              for (let i = 0; i < trip.passengersTotal; i++) {
                passengers.push({
                  civility: EnumFlightBriefPassengerCivility.mr
                } as IFlightBriefPassengerInfo);
              }

              this.addTripAndSet({
                id: trip.id,
                date: trip.date,
                departureTime: trip.time,
                arrivalTime: trip.arrivalTime,
                flyTimeInHours: '00:00',
                airportDepartId: trip.airportDepart,
                airportArrivalId: trip.airportDestination,
                passengers
              } as IFlightBriefTrip);

              for (const field of ['airportDepart', 'airportDestination']) {
                if (trip[field]) {
                  this.loadAirport(trip[field]);
                }
              }
            }

            this.loadEnquiry();
            this.loadEnquiryConfirmedOffers();
          }

          this.form.enable();
        })
      );
    }
  }

  loadFlightBrief(): void {
    if (this.flightBriefId) {
      this.subscriptions.add(
        this.flightBriefService
          .getFromId(this.flightBriefId)
          .subscribe((flightBrief: IFlightBrief) => {
            this.flightBrief = flightBrief;

            this.setFlightBrief();
          })
      );
    }
  }

  loadFbosForAirport(airportId: string): void {
    if (typeof this.fbosByAirportsObj[airportId] === 'undefined') {
      this.subscriptions.add(
        this.fboService.getForAirport(airportId).subscribe((fbos: IFbo[]) => {
          this.fbosByAirportsObj[airportId] = fbos;

          this.refreshAirportsAddressDetails();
        })
      );
    }
  }

  setFlightBrief(): void {
    if (this.flightBrief) {
      for (const field in this.form.value) {
        if (
          !['trips', 'passengers', 'airportsAddressDetails'].includes(field) &&
          typeof this.flightBrief[field] !== 'undefined'
        ) {
          this.form.get(field).setValue(this.flightBrief[field]);
        }
      }

      this.loadTranslation();
      this.loadAirline();
      this.loadAircraftCompiled();
      this.loadFlightBriefCaterings();

      this.trips.clear();
      if (this.flightBrief.trips?.length) {
        for (const trip of this.flightBrief.trips) {
          this.addTripAndSet(trip);

          for (const field of ['airportDepartId', 'airportArrivalId']) {
            if (trip[field]) {
              this.loadAirport(trip[field]);
            }
          }

          this.isFerryFlightUpdated(this.trips.length - 1);
        }
      }

      this.setEstimatedArrivalTimeIfNotProvided();

      this.assignAirportsAddressDetails();

      this.generateGoogleMapStaticUrl();

      this.loadEnquiry();

      this.form.enable();
    }
  }

  loadAirline(): void {
    if (this.form.value.airlineId) {
      this.subscriptions.add(
        this.airlineService.getFromId(this.form.value.airlineId).subscribe((airline: IAirline) => {
          this.airline = airline;
        })
      );
    }
  }

  loadAircraftCompiled(): void {
    if (this.form.value.airlineId) {
      this.loadingAircraftsCompiled = true;

      this.subscriptions.add(
        this.aircraftCompiledService
          .getAllForAirline(this.form.value.airlineId)
          .subscribe((aircraftsCompiled: IAircraftCompiled[]) => {
            this.loadingAircraftsCompiled = true;

            this.aircraftsCompiled = aircraftsCompiled;

            this.updateAircraftModelsFromAircraftsCompiled();

            this.loadingAircraftsCompiled = false;
          })
      );
    }
  }

  setEstimatedArrivalTimeIfNotProvided(): void {
    if (this.trips.length && this.aircraftModelId.value && this.aircraftsCompiled.length) {
      for (let i = 0; i < this.trips.length; i++) {
        this.setEstimatedArrivalTimeIfNotProvidedForTrip(i);
      }
    }
  }

  setEstimatedArrivalTimeIfNotProvidedForTrip(
    tripIndex: number,
    forceUpdateArrivalTime: boolean = false
  ): void {
    const trip: IFlightBriefTrip = this.getTrip(tripIndex).value;

    if (
      (!trip.arrivalTime || trip.arrivalTime === '' || forceUpdateArrivalTime) &&
      trip.airportDepartId &&
      trip.airportArrivalId &&
      this.airportsObj[trip.airportDepartId] &&
      this.airportsObj[trip.airportArrivalId]
    ) {
      this.getTrip(tripIndex)
        .get('arrivalTime')
        .setValue(this.getEstimatedArrivalTimeForTrip(tripIndex));

      this.tripTimesUpdated(tripIndex);
    }
  }

  getEstimatedArrivalTimeForTrip(tripIndex: number): string | null {
    let selectedAircraftModel: IAircraftModel | null = null;

    for (const aircraftModel of this.aircraftModels) {
      if (aircraftModel.id === this.aircraftModelId.value) {
        selectedAircraftModel = aircraftModel;
        break;
      }
    }

    const trip: IFlightBriefTrip = this.getTrip(tripIndex).value;

    if (
      selectedAircraftModel &&
      trip.date &&
      trip.departureTime &&
      this.airportsObj[trip.airportDepartId] &&
      this.airportsObj[trip.airportArrivalId]
    ) {
      // Set estimated arrival time
      const flyTimeInMinutes: number = estimatedFlyTimeInMinutes(
        selectedAircraftModel,
        this.airportsObj[trip.airportDepartId],
        this.airportsObj[trip.airportArrivalId]
      );

      if (flyTimeInMinutes) {
        const departureTimeUtc: Date =
          this.enquiry?.type === EnumEnquiryType.cargo
            ? new Date(trip.date + 'T' + trip.departureTime + ':00Z')
            : getAirportUTCTime(
                this.airportsObj[trip.airportDepartId],
                trip.date,
                trip.departureTime
              );
        const arrivalTimeUtc: Date = addMinutes(departureTimeUtc, flyTimeInMinutes);

        if (this.enquiry?.type === EnumEnquiryType.cargo) {
          return formatInTimeZone(arrivalTimeUtc, 'utc', 'HH:mm');
        } else {
          return getAirportLocalTimeFromUTC(this.airportsObj[trip.airportDepartId], arrivalTimeUtc);
        }
      }
    }

    return null;
  }

  updateAircraftModelsFromAircraftsCompiled(): void {
    const aircraftModelsObj: { [key: string]: IAircraftModel } = {};

    for (const aircraftCompiled of this.aircraftsCompiled) {
      aircraftModelsObj[aircraftCompiled.type] = {
        id: aircraftCompiled.aircraftModelId,
        family: aircraftCompiled.family,
        title: getAircraftCompiledModelTitle(aircraftCompiled),
        weight: aircraftCompiled.weight,
        volume: aircraftCompiled.volume,
        target: aircraftCompiled.target
      } as IAircraftModel;
    }

    this.aircraftModels = Object.values(aircraftModelsObj);
    this.aircraftModels.sort((a, b) => (a.title > b.title ? 1 : -1));

    this.setEstimatedArrivalTimeIfNotProvided();

    this.updateAircraftsFromAircraftsCompiled();
  }

  updateAircraftsFromAircraftsCompiled(): void {
    this.aircrafts = [];

    if (this.form.value.airlineId && this.form.value.aircraftModelId) {
      this.loadingAircrafts = true;
      this.subscriptions.add(
        this.aircraftService
          .getAllForAirlineAndModel(this.form.value.airlineId, this.form.value.aircraftModelId)
          .subscribe((aircrafts: IAircraft[]) => {
            this.loadingAircrafts = true;

            this.aircrafts = aircrafts;

            this.aircrafts.sort((a, b) => (a.registration > b.registration ? 1 : -1));

            this.assignAircraftImagesIfAvailable();

            this.loadingAircrafts = false;
          })
      );

      this.aircrafts.sort((a, b) => (a.registration > b.registration ? 1 : -1));
    }
  }

  assignAircraftImagesIfAvailable(selectedAircraft: IAircraft | null = null): void {
    if (this.form.value.aircraftId && this.aircrafts.length && !selectedAircraft) {
      for (const aircraft of this.aircrafts) {
        if (aircraft.id === this.form.value.aircraftId) {
          selectedAircraft = aircraft;

          break;
        }
      }
    }

    if (selectedAircraft) {
      for (const field of [
        'imageOutsideUrl',
        'imageOutsideZoomLevel',
        'imageInsideUrl',
        'imageInsideZoomLevel'
      ]) {
        if (!this.form.value[field] && selectedAircraft[field]) {
          this.form.get(field).setValue(selectedAircraft[field]);
        }
      }
    }
  }

  clearAircraftImagesValues(): void {
    for (const field of [
      'imageOutsideUrl',
      'imageOutsideZoomLevel',
      'imageInsideUrl',
      'imageInsideZoomLevel'
    ]) {
      this.form.get(field).setValue(field.match('Zoom') ? 1 : null);
    }
  }

  updatedAircraftModelId(): void {
    if (this.form.value.aircraftModelId) {
      for (const aircraftModel of this.aircraftModels) {
        if (aircraftModel.id === this.form.value.aircraftModelId) {
          this.form.get('aircraftModelTitle').setValue(aircraftModel.title);

          break;
        }
      }
    } else {
      this.form.get('aircraftModelTitle').setValue(null);
    }

    this.form.get('aircraftModelTitle').updateValueAndValidity();

    this.updateAircraftsFromAircraftsCompiled();
  }

  updatedAircraftId(): void {
    if (this.form.value.aircraftId) {
      for (const aircraft of this.aircrafts) {
        if (aircraft.id === this.form.value.aircraftId) {
          this.applyAircraftInfoToForm(aircraft);
          break;
        }
      }
    } else {
      this.applyAircraftInfoToForm(null);
    }
  }

  applyAircraftInfoToForm(aircraft: IAircraft | null): void {
    if (aircraft) {
      this.form
        .get('aircraftRegistration')
        .setValue(aircraft.registration !== '' ? aircraft.registration : 'Inconnu');
    } else {
      this.form.get('aircraftRegistration').setValue(null);
    }

    this.form.get('aircraftRegistration').updateValueAndValidity();

    this.clearAircraftImagesValues();

    this.assignAircraftImagesIfAvailable(aircraft);
  }

  assignAirportsAddressDetails(): void {
    const airportsAddressDetailsObj: {
      [key: string]: {
        airportId: string;
        text: string;
        fboId: string;
      };
    } = {};

    for (const airportAddressDetails of this.flightBrief?.airportsAddressDetails) {
      airportsAddressDetailsObj[airportAddressDetails.airportId] = {
        airportId: airportAddressDetails.airportId,
        text: airportAddressDetails.text,
        fboId: airportAddressDetails.fboId
      };
    }

    for (let i = 0; i < this.airportsAddressDetails.length; i++) {
      if (
        typeof airportsAddressDetailsObj[this.getAirportAddressDetails(i).value.airportId] !==
        'undefined'
      ) {
        for (const field in airportsAddressDetailsObj[
          this.getAirportAddressDetails(i).value.airportId
        ]) {
          this.getAirportAddressDetails(i)
            .get(field)
            .setValue(
              airportsAddressDetailsObj[this.getAirportAddressDetails(i).value.airportId][field]
            );
        }
      }
    }
  }

  refreshAirportsAddressDetails(): void {
    const existingAirportsId: string[] = [];

    for (const airportAddressDetails of this.airportsAddressDetails.value) {
      if (!existingAirportsId.includes(airportAddressDetails.airportId)) {
        existingAirportsId.push(airportAddressDetails.airportId);
      }
    }

    // All existing airports at first, we remove them while looping
    const airportsToRemove: string[] = [...existingAirportsId];

    for (const trip of this.trips.value) {
      if (!trip.isFerryFlight) {
        for (const field of ['airportDepartId', 'airportArrivalId']) {
          if (!existingAirportsId.includes(trip[field]) && trip[field]) {
            this.airportsAddressDetails.push(
              new FormGroup({
                airportId: new FormControl(trip[field], Validators.required),
                text: new FormControl(
                  this.defaultAirportAddressObj[trip[field]]
                    ? this.defaultAirportAddressObj[trip[field]].text
                    : '',
                  Validators.required
                ),
                fboId: new FormControl(null, Validators.required)
              })
            );
          }

          const currentIndexAirportToRemove: number = airportsToRemove.indexOf(trip[field]);

          if (currentIndexAirportToRemove !== -1) {
            airportsToRemove.splice(currentIndexAirportToRemove, 1);
          }
        }
      }
    }

    for (let i = this.airportsAddressDetails.length - 1; i >= 0; i--) {
      if (airportsToRemove.includes(this.airportsAddressDetails.at(i).get('airportId').value)) {
        this.airportsAddressDetails.removeAt(i);
      }
    }

    for (let i = this.airportsAddressDetails.length - 1; i >= 0; i--) {
      if (!this.airportsAddressDetails.at(i).get('text').value) {
        this.airportsAddressDetails
          .at(i)
          .get('text')
          .setValue(
            this.defaultAirportAddressObj[this.airportsAddressDetails.at(i).get('airportId').value]
              ? this.defaultAirportAddressObj[
                  this.airportsAddressDetails.at(i).get('airportId').value
                ].text
              : ''
          );
        this.airportsAddressDetails.at(i).get('text').updateValueAndValidity();
      }
    }
  }

  async submitForm(): Promise<void> {
    this.form.markAsTouched();

    if (this.form.valid) {
      this.sending = true;

      this.form.disable();

      await this.updateAirportAddresses();

      let data: IFlightBrief = Object.assign({}, this.form.value);

      for (const field in data) {
        if (typeof data[field] == 'undefined') {
          data[field] = null;
        }
      }

      data.title =
        'FB.' + getDisplayedEnquiryRefTitle(this.enquiry, 'refContract') + 'V' + data.versionNumber;

      for (const trip of data.trips) {
        trip.googleMapStaticUrl = this.generateGoogleMapStaticUrlForTrip(trip);
      }

      try {
        if (this.flightBriefId) {
          data.id = this.flightBriefId;

          this.flightBriefId = await this.flightBriefService.update(data);
        } else {
          data.createdBy = this.currentUser?.id || null;
          data.createdByFirstname = this.currentUser?.firstname || null;
          data.createdByLastname = this.currentUser?.lastname || null;
          data.createdByInitials = this.currentUser?.initials || null;
          data.createdByFullname = this.currentUser ? getUserFullname(this.currentUser) : null;

          data.sentDate = null;
          data.sentBy = null;
          data.isSent = false;

          this.flightBriefId = await this.flightBriefService.create(data);
        }

        await this.updateAircraftWithUpdatedImages();

        this.sending = false;
        this.redirectAfterSaving();
      } catch (err) {
        console.log(err);

        this.sending = false;
        this.form.enable();

        alert(err.message);
      }
    }
  }

  async updateAirportAddresses(): Promise<void> {
    for (let i = 0; i < this.airportsAddressDetails.length; i++) {
      const formGroup: FormGroup = this.getAirportAddressDetails(i);

      if (formGroup.value.airportId && formGroup.value.text) {
        let airportAddressId: string;
        // if (formGroup.value.airportAddressId) {
        //   airportAddressId = await this.airportAddressService.update({
        //     id: formGroup.value.airportAddressId,
        //     airportId: formGroup.value.airportId,
        //     text: formGroup.value.text
        //   } as IAirportAddress);
        // } else {
        //   airportAddressId = await this.airportAddressService.create({
        //     airportId: formGroup.value.airportId,
        //     title:
        //       this.airportsObj[formGroup.value.airportId].title +
        //       ' #' +
        //       (this.airportAddressesObj[formGroup.value.airportId]?.length + 1),
        //     text: formGroup.value.text
        //   } as IAirportAddress);
        // }

        if (airportAddressId) {
          this.getAirportAddressDetails(i).get('airportAddressId').setValue(airportAddressId);
          this.getAirportAddressDetails(i).get('airportAddressId').updateValueAndValidity();
        }
      }
    }
  }

  async updateAircraftWithUpdatedImages(): Promise<void> {
    if (this.form.value.aircraftId && this.aircrafts.length) {
      for (const aircraft of this.aircrafts) {
        if (aircraft.id === this.form.value.aircraftId) {
          if (this.form.value.imageOutsideUrl || this.form.value.imageInsideUrl) {
            const data: IAircraft = {
              id: aircraft.id
            } as IAircraft;

            if (this.form.value.imageOutsideUrl) {
              data.imageOutsideUrl = this.form.value.imageOutsideUrl;
            }

            if (this.form.value.imageInsideUrl) {
              data.imageInsideUrl = this.form.value.imageInsideUrl;
            }

            await this.aircraftService.update(data);
          }
          break;
        }
      }
    }
  }

  redirectAfterSaving(): void {
    this.router.navigate(['/admin/flight-briefs/' + this.flightBriefId]);
  }

  async setValueToFormControl($event: {
    fields: {
      name: string;
      value: any;
    }[];
  }): Promise<void> {
    for (let field of $event.fields) {
      const nameList = field.name.split('.');
      let formControl: AbstractControl = this.form;
      for (let name of nameList) {
        formControl = formControl.get(name);
      }

      if (['airportDepartId', 'airportArrivalId'].includes(nameList[nameList.length - 1])) {
        formControl.setValue(field.value);
        formControl.markAsTouched();
        formControl.updateValueAndValidity();

        if (field.value) {
          this.loadAirport(field.value);

          this.refreshAirportsAddressDetails();
        }

        this.generateGoogleMapStaticUrl();
      } else if (field.name === 'airline') {
        const airline: IAirline | null = field.value as IAirline | null;

        if (airline) {
          this.form.get('airlineId').setValue(airline.id);
          this.form.get('airlineTitle').setValue(airline.title);

          this.airline = airline;

          this.loadAircraftCompiled();
        } else {
          for (const field of [
            'airlineId',
            'airlineTitle',
            'aircraftModelId',
            'aircraftModelTitle',
            'aircraftId',
            'aircraftRegistration'
          ]) {
            this.form.get(field)?.setValue(null);
          }
        }
      } else if (field.name === 'enquiryId') {
        formControl.setValue(field.value);
        formControl.markAsTouched();
        formControl.updateValueAndValidity();

        this.enquiryId = field.value;
        this.form.get('enquiryId').setValue(field.value);

        if (this.enquiryId) {
          this.loadEnquiry();
        }
      } else {
        formControl.setValue(field.value);
        formControl.markAsTouched();
        formControl.updateValueAndValidity();
      }
    }
  }

  async changedLanguage(): Promise<void> {
    for (let i = 0; i < this.trips.length; i++) {
      if (!this.trips.value[i].luggageIsCustom) {
        this.getTrip(i).get('luggages').setValue(null);
        this.getTrip(i).get('luggages').updateValueAndValidity();
      }
    }

    this.loadFlightBriefCaterings();
    this.loadTranslation();
  }

  loadFlightBriefCaterings(): void {
    if (this.subscriptionsForCatering) {
      this.subscriptionsForCatering.unsubscribe();
    }

    this.subscriptionsForCatering = new Subscription();

    if (this.form.value.language) {
      this.subscriptionsForCatering.add(
        this.flightBriefCateringService
          .getAllForLanguage(this.form.value.language)
          .subscribe((flightBriefCaterings: IFlightBriefCatering[]) => {
            this.flightBriefCaterings = flightBriefCaterings;
          })
      );
    }
  }

  loadPreviousFlightBriefs(): void {
    if (this.form.value.enquiryId) {
      this.subscriptionsForEnquiry.add(
        this.flightBriefService
          .getLastVersionForEnquiry(this.form.value.enquiryId)
          .subscribe((flightBrief: IFlightBrief) => {
            if (flightBrief) {
              this.form.get('versionNumber').setValue(flightBrief.versionNumber + 1);
            }
          })
      );
    }
  }

  addTrip(): void {
    this.trips.push(
      new FormGroup({
        id: new FormControl(this.remoteService.generateRandomId(), [Validators.required]),
        date: new FormControl('', [Validators.required, this.dateMinimum('2000-01-01')]),
        flightNumber: new FormControl('', [Validators.required]),
        departureTime: new FormControl('', [Validators.required]),
        arrivalTime: new FormControl('', [Validators.required]),
        flyTimeInHours: new FormControl('', [Validators.required]),
        boardingMinutesBeforeDeparture: new FormControl(20, [Validators.required]),
        deliveryCargoHoursBeforeDeparture: new FormControl(2),
        airportDepartId: new FormControl('', [Validators.required]),
        airportArrivalId: new FormControl('', [Validators.required]),
        passengers: new FormArray([]),
        flightBriefCateringId: new FormControl(null, Validators.required),
        catering: new FormControl('', Validators.required),
        luggageQty: new FormControl(1, Validators.required),
        luggagesWeight: new FormControl(12, Validators.required),
        luggageIsCustom: new FormControl(false, Validators.required),
        luggages: new FormControl('', Validators.required),
        isFerryFlight: new FormControl(false)
      })
    );

    this.refreshLuggageText(this.trips.length - 1);
  }

  addTripAndSet(trip: IFlightBriefTrip): void {
    this.addTrip();

    const i: number = this.trips.length - 1;
    for (const field in this.getTrip(i).value) {
      if (!['passengers'].includes(field) && typeof trip[field] !== 'undefined') {
        this.trips.at(i).get(field).setValue(trip[field]);
      }

      this.getPassengers(i).clear();
      if (trip.passengers?.length) {
        for (const passenger of trip.passengers) {
          this.addPassengerAndSet(i, passenger);
        }
      }
    }

    this.tripTimesUpdated(i);
    this.refreshLuggageText(i);

    this.generateGoogleMapStaticUrl();

    this.refreshAirportsAddressDetails();
  }

  removeTrip(i: number): void {
    if (confirm('Êtes-vous sûr de vouloir supprimer cette ligne ?')) {
      this.trips.removeAt(i);

      this.generateGoogleMapStaticUrl();
      this.refreshAirportsAddressDetails();
    }
  }

  addPassenger(tripIndex: number): void {
    this.getPassengers(tripIndex).push(
      new FormGroup({
        id: new FormControl(this.remoteService.generateRandomId(), [Validators.required]),
        civility: new FormControl(EnumFlightBriefPassengerCivility.mr, Validators.required),
        firstname: new FormControl('', [Validators.required]),
        lastname: new FormControl('', [Validators.required])
      })
    );
  }

  addPassengerAndSet(tripIndex: number, passenger: IFlightBriefPassengerInfo): void {
    this.addPassenger(tripIndex);

    const i: number = this.getPassengers(tripIndex).length - 1;

    for (const field in this.getPassenger(tripIndex, i).value) {
      if (typeof passenger[field] !== 'undefined') {
        this.getPassenger(tripIndex, i).get(field).setValue(passenger[field]);
      }
    }
  }

  removePassenger(tripIndex: number, i: number): void {
    if (confirm('Êtes-vous sûr de vouloir supprimer cette ligne ?')) {
      this.getPassengers(tripIndex).removeAt(i);
    }
  }

  dateMinimum(date: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      if (differenceInDays(new Date(control.value), new Date(date)) < 0) {
        return {
          dateBelowMinimum: true,
          dateMinimum: new Date(date)
        };
      } else {
        return null;
      }

      return null;
    };
  }

  generateGoogleMapStaticUrl(): void {
    this.form.get('googleMapStaticUrl').setValue(this.getGoogleMapStaticUrl());
  }

  generateGoogleMapStaticUrlForTrip(trip: IFlightBriefTrip): string | null {
    return this.getGoogleMapStaticUrl(trip);
  }

  getGoogleMapStaticUrl(trip: IFlightBriefTrip | null = null): string {
    let url: string = 'https://maps.googleapis.com/maps/api/staticmap?';

    url += 'size=300x220&scale=2';
    url += '&language=' + this.form.value.language;
    url += '&key=AIzaSyDyPoaFu7WvRZjqmSlQq0QdWA1FS_RLMQw';
    url += '&style=feature:road|visibility:off|';
    url += '&path=color:0x1c4d6bff|weight:2|geodesic:true|';

    const coordinates: string[] = [];

    if (trip) {
      for (const airportField of ['airportDepartId', 'airportArrivalId']) {
        if (trip[airportField] && this.airportsObj[trip[airportField]]) {
          coordinates.push(
            this.airportsObj[trip[airportField]].latitude +
              ',' +
              this.airportsObj[trip[airportField]].longitude
          );
        }
      }
    } else {
      if (this.form.value.trips) {
        for (const trip of this.form.value.trips) {
          if (!trip.isFerryFlight) {
            for (const airportField of ['airportDepartId', 'airportArrivalId']) {
              if (trip[airportField] && this.airportsObj[trip[airportField]]) {
                coordinates.push(
                  this.airportsObj[trip[airportField]].latitude +
                    ',' +
                    this.airportsObj[trip[airportField]].longitude
                );
              }
            }
          }
        }
      }
    }

    if (coordinates.length) {
      return url + coordinates.join('|');
    } else {
      return null;
    }
  }

  loadAirport(airportId: string): void {
    if (airportId && !this.airportsObj[airportId]) {
      this.subscriptions.add(
        this.airportService.getFromId(airportId).subscribe((airport: IAirport) => {
          this.airportsObj[airportId] = airport;

          if (this.airportsObj[airportId]) {
            this.defaultAirportAddressObj[airportId] = {
              airportId,
              title: this.airportsObj[airportId].title + ' #1',
              text: getDefaultAirportAddressDetails(
                this.airportsObj[airportId],
                this.form.value.language
              )
            } as IAirportAddress;
          }

          for (let i = 0; i < this.trips.value.length; i++) {
            if (
              this.trips.value[i].airportDepartId === airportId ||
              this.trips.value[i].airportArrivalId === airportId
            ) {
              this.tripTimesUpdated(i);
            }
          }

          this.generateGoogleMapStaticUrl();
        })
      );

      this.loadFbosForAirport(airportId);
    }
  }

  triggerInputFile(fileFieldName: string): void {
    if (this.form.enabled) {
      document.getElementById(fileFieldName + 'Input').click();
    }
  }

  changeInputFile(fileFieldName: string, fileInput: any): void {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.upload(fileFieldName, fileInput.target.files[0]);
    }
  }

  upload(fileFieldName: string, file: File): Promise<void> {
    return new Promise((resolve, reject) => {
      this.form.disable();

      const result = this.remoteService.upload(
        'flightBriefs/images',
        this.remoteService.generateRandomId(),
        file,
        'file'
      );

      result['task']
        .snapshotChanges()
        .pipe(
          finalize(() => {
            result['ref'].getDownloadURL().subscribe((downloadUrl: string) => {
              this.form.get(fileFieldName).setValue(downloadUrl);

              (<HTMLInputElement>document.getElementById(fileFieldName + 'Input')).value = '';

              this.form.enable();

              this.form.updateValueAndValidity();
            });
          })
        )
        .subscribe();
    });
  }

  deleteImage(fileFieldName: string): void {
    if (confirm('Êtes-vous sûr de vouloir supprimer cette image ?')) {
      this.form.get(fileFieldName).setValue(null);
    }
  }

  getDayOfWeek(date: string): string {
    return date ? format(parseISO(date), 'EEEE', { locale: fr }) : null;
  }

  tripTimesUpdated(i: number): void {
    const tripFormGroup: FormGroup = this.getTrip(i);

    if (
      tripFormGroup.value.airportDepartId &&
      this.airportsObj[tripFormGroup.value.airportDepartId] &&
      tripFormGroup.value.airportArrivalId &&
      this.airportsObj[tripFormGroup.value.airportArrivalId] &&
      tripFormGroup.value.departureTime &&
      tripFormGroup.value.arrivalTime
    ) {
      const departureTimeUtc: Date =
        this.enquiry?.type === EnumEnquiryType.cargo
          ? new Date(tripFormGroup.value.date + 'T' + tripFormGroup.value.departureTime + ':00Z')
          : getAirportUTCTime(
              this.airportsObj[tripFormGroup.value.airportDepartId],
              tripFormGroup.value.date,
              tripFormGroup.value.departureTime
            );

      const arrivalTimeUtc: Date =
        this.enquiry?.type === EnumEnquiryType.cargo
          ? new Date(tripFormGroup.value.date + 'T' + tripFormGroup.value.arrivalTime + ':00Z')
          : getAirportUTCTime(
              this.airportsObj[tripFormGroup.value.airportArrivalId],
              tripFormGroup.value.date,
              tripFormGroup.value.arrivalTime
            );

      let diffInMinutes: number = differenceInMinutes(arrivalTimeUtc, departureTimeUtc);

      const flyTime: {
        hours: number;
        minutes: number;
      } = {
        hours: 0,
        minutes: 0
      };

      if (diffInMinutes < 0) {
        // Arrive the next day, we take this into account
        diffInMinutes = 24 * 60 - differenceInMinutes(departureTimeUtc, arrivalTimeUtc);
      }

      flyTime.hours = Math.floor(diffInMinutes / 60);
      flyTime.minutes = diffInMinutes - flyTime.hours * 60;

      tripFormGroup
        .get('flyTimeInHours')
        .setValue(addZeroToDigit(flyTime.hours) + ':' + addZeroToDigit(flyTime.minutes));
    }
  }

  updatedFlightBriefCateringId(i: number): void {
    const tripFormGroup: FormGroup = this.getTrip(i);

    if (tripFormGroup.value.flightBriefCateringId === 'custom') {
      tripFormGroup.get('catering').setValue('');
    } else {
      tripFormGroup.get('catering').setValue(tripFormGroup.value.flightBriefCateringId);
    }

    tripFormGroup.get('catering').updateValueAndValidity();
  }

  updateLuggageQty(i: number, inc: number = 1): void {
    const tripFormGroup: FormGroup = this.getTrip(i);

    tripFormGroup.get('luggageQty').setValue(tripFormGroup.value.luggageQty + inc);

    this.refreshLuggageText(i);
  }

  updateLuggageWeight(i: number, inc: number = 1): void {
    const tripFormGroup: FormGroup = this.getTrip(i);

    tripFormGroup.get('luggagesWeight').setValue(tripFormGroup.value.luggagesWeight + inc);

    this.refreshLuggageText(i);
  }

  refreshLuggageText(i: number): void {
    const tripFormGroup: FormGroup = this.getTrip(i);

    if (!tripFormGroup.value.luggageIsCustom) {
      tripFormGroup
        .get('luggages')
        .setValue(
          tripFormGroup.value.luggageQty +
            'X' +
            tripFormGroup.value.luggagesWeight +
            this.translationObj[this.form.value.language]?.FLIGHT_BRIEF.KG_BY_PAX
        );
    }
  }

  updateLuggageIsCustom(i: number): void {
    const tripFormGroup: FormGroup = this.getTrip(i);

    if (!tripFormGroup.value.luggageIsCustom) {
      this.refreshLuggageText(i);
    }
  }

  toggleFbo(i: number, fboId: string): void {
    this.getAirportAddressDetails(i).get('fboId').setValue(fboId);

    let selectedFbo: IFbo | null = null;

    for (const fbo of this.fbosByAirportsObj[this.getAirportAddressDetails(i).value.airportId]) {
      if (fboId === fbo.id) {
        selectedFbo = fbo;
        break;
      }
    }

    this.getAirportAddressDetails(i)
      .get('text')
      .setValue(getFboAddressDetails(selectedFbo, this.form.value.language));
  }

  addNewAirportAddress(i: number): void {
    this.getAirportAddressDetails(i).get('airportAddressId').setValue(null);
    this.getAirportAddressDetails(i)
      .get('text')
      .setValue(
        getDefaultAirportAddressDetails(
          this.airportsObj[this.getAirportAddressDetails(i).value.airportId],
          this.form.value.language
        )
      );
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  async saveAircraft(): Promise<void> {
    this.formAircraft.markAsTouched();

    if (this.formAircraft.status == 'VALID') {
      let data: IAircraft = Object.assign({}, this.formAircraft.value);

      for (const field in data) {
        if (typeof data[field] == 'undefined') {
          data[field] = null;
        }
      }

      this.savingAircraft = true;

      this.formAircraft.disable();

      data.aircraftModelId = this.form.value.aircraftModelId ?? null;

      for (const aircraftModel of this.aircraftModels) {
        if (aircraftModel.id === data.aircraftModelId) {
          data.model = aircraftModel.family ?? null;
          data.type = aircraftModel.slug ?? data.model;

          break;
        }
      }
      data.airlineContinentCode = this.airline?.continentCode ?? null;
      data.airlineCountryCode = this.airline?.countryCode ?? null;
      data.airlineContinentTitle = this.airline?.continentCode
        ? getContinentLabel(this.airline?.continentCode)
        : null;
      data.airlineCountryTitle = this.airline?.countryCode
        ? getCountryLabel(this.airline?.countryCode)
        : null;
      data.airlineId = this.form.value.airlineId;
      data.airlineTitle = this.airline?.title ?? null;

      try {
        data.id = await this.aircraftService.create(data);

        if (data.id) {
          this.form.get('aircraftId').setValue(data.id);

          this.applyAircraftInfoToForm(data);
        }

        this.formAircraft.reset();

        this.removeModal();

        this.savingAircraft = false;
      } catch (err) {
        console.log(err);

        this.savingAircraft = false;
        this.formAircraft.enable();

        alert(err.message);
      }
    }
  }

  removeModal(): void {
    window['$'](this.modalAircraftAddElement.nativeElement).modal('hide');
    window['$'](this.modalFboElement.nativeElement).modal('hide');
    window['$']('body').removeClass('modal-open');
    window['$']('.modal-backdrop').remove();
  }

  loadTranslation(): void {
    if (this.form.value.language) {
      if (this.subscriptionsTranslations) {
        this.subscriptionsTranslations.unsubscribe();
      }

      this.subscriptionsTranslations = new Subscription();

      this.subscriptionsTranslations.add(
        this.translateService
          .getTranslation(this.form.value.language)
          .subscribe((translationObj: any) => {
            this.translationObj[this.form.value.language] = translationObj;

            for (let i = 0; i < this.trips.length; i++) {
              this.refreshLuggageText(i);
            }
          })
      );
    }
  }

  setMandatoryFieldsAccordingToEnquiryType(): void {
    if (this.enquiry?.type) {
      let mandatoryFields: string[] = [];
      let hiddenFields: string[] = [];

      switch (this.enquiry.type) {
        default:
          mandatoryFields = [
            'trips.i.boardingMinutesBeforeDeparture',
            'trips.i.flightBriefCateringId',
            'trips.i.catering',
            'trips.i.luggageQty',
            'trips.i.luggagesWeight',
            'trips.i.luggageIsCustom',
            'trips.i.luggages'
          ];
          hiddenFields = [
            'typeOfCargo',
            'weightInKg',
            'volumeInM3',
            'nbPackages',
            'awbNumber',
            'customDeclaration',
            'dgd'
          ];
          break;
        case EnumEnquiryType.cargo:
          mandatoryFields = [
            'typeOfCargo',
            'weightInKg',
            'volumeInM3',
            'nbPackages',
            'awbNumber',
            'customDeclaration',
            'dgd'
          ];
          hiddenFields = [
            'trips.i.boardingMinutesBeforeDeparture',
            'trips.i.flightBriefCateringId',
            'trips.i.catering',
            'trips.i.luggageQty',
            'trips.i.luggagesWeight',
            'trips.i.luggageIsCustom',
            'trips.i.luggages'
          ];
          break;
      }

      for (const mandatoryField of mandatoryFields) {
        if (mandatoryField.match('trips.i.')) {
          const field: string = mandatoryField.replace('trips.i.', '');

          for (let i = 0; i < this.trips.length; i++) {
            this.getTripField(i, field).setValidators(Validators.required);
            this.getTripField(i, field).updateValueAndValidity();
          }
        }
      }

      for (const hiddenField of hiddenFields) {
        if (hiddenField.match('trips.i.')) {
          const field: string = hiddenField.replace('trips.i.', '');

          for (let i = 0; i < this.trips.length; i++) {
            this.getTripField(i, field).clearValidators();
            this.getTripField(i, field).setValue(null);
            this.getTripField(i, field).updateValueAndValidity();
          }
        }
      }
    }

    for (let i = 0; i < this.trips.length; i++) {
      this.isFerryFlightUpdated(i);
    }
  }

  isFerryFlightUpdated(tripIndex: number): void {
    const deliveryCargoHoursBeforeDepartureFormControl: FormControl = this.getTripField(
      tripIndex,
      'deliveryCargoHoursBeforeDeparture'
    );

    if (
      this.enquiry?.type === EnumEnquiryType.cargo &&
      !this.getTripField(tripIndex, 'isFerryFlight').value
    ) {
      deliveryCargoHoursBeforeDepartureFormControl.setValidators(Validators.required);
    } else {
      deliveryCargoHoursBeforeDepartureFormControl.setValue(null);
      deliveryCargoHoursBeforeDepartureFormControl.clearValidators();
    }

    deliveryCargoHoursBeforeDepartureFormControl.updateValueAndValidity();

    this.refreshAirportsAddressDetails();
    this.generateGoogleMapStaticUrl();
  }

  addFbo(airportAddressDetailsIndex: number): void {
    this.airportAddressDetailsCurrentIndex = airportAddressDetailsIndex;
    this.editingFbo = null;

    window['$'](this.modalFboElement.nativeElement).modal('show');
  }

  editFbo(airportAddressDetailsIndex: number, fbo: IFbo): void {
    this.airportAddressDetailsCurrentIndex = airportAddressDetailsIndex;
    this.editingFbo = fbo;

    window['$'](this.modalFboElement.nativeElement).modal('show');
  }

  dismissFboModal(newId: string | null = null): void {
    if (newId) {
      this.toggleFbo(this.airportAddressDetailsCurrentIndex, newId);
    }

    window['$'](this.modalFboElement.nativeElement).modal('hide');
  }
}
