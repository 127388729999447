<div class="row justify-content-center">
    <div class="col-md-8 col-lg-6">
        <p class="text-center">
            Veuillez importer le fichier d'import en se basant sur le fichier d'exemple
            <a href="assets/exemple_import_airline_contacts.xlsx" target="_blank">ici</a>.
        </p>

        <div class="card mb-4">
            <div class="card-body text-center">
                <div class="form-group mb-0">
                    <input
                        type="file"
                        id="fileInput"
                        class="form-control"
                        (change)="changeInputFile($event)"
                        accept=".csv"
                    />
                </div>
            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="fileContentLoaded">
    <ng-container *ngIf="!dataValid">
        <div
            class="alert alert-danger text-center"
            *ngIf="errorInvalidData === EnumImportDataErrorMsg.empty"
        >
            Erreur : impossible de récupérer les données.
        </div>
        <div
            class="alert alert-danger text-center"
            *ngIf="errorInvalidData === EnumImportDataErrorMsg.numberOfHeadersDifferent"
        >
            Erreur : le nombre de colonne du fichier ne correspond pas.
        </div>
        <div
            class="alert alert-danger text-center"
            *ngIf="errorInvalidData === EnumImportDataErrorMsg.columnsTitleAreDifferent"
        >
            Erreur : le libellé des colonnes du fichier ne correspondent pas.
        </div>

        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th class="text-center">Status</th>
                        <th
                            class="text-center"
                            *ngFor="let header of data.headers"
                            [innerText]="header"
                        ></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let line of data.lines; let i = index">
                        <td
                            class="text-center"
                            [class.text-danger]="data.errorsByLine[i].length"
                            [class.text-success]="!data.errorsByLine[i].length"
                        >
                            <fa-icon
                                [icon]="faCheck"
                                *ngIf="!data.errorsByLine[i].length"
                            ></fa-icon>
                            <fa-icon
                                [icon]="faCancel"
                                *ngIf="data.errorsByLine[i].length"
                            ></fa-icon>
                            <div *ngFor="let errorMsg of data.errorsByLine[i]">
                                <span *ngIf="errorMsg === EnumImportDataErrorMsg.airlineNameEmpty"
                                    >Compagnie aérienne manquante</span
                                >
                                <span
                                    *ngIf="
                                        errorMsg === EnumImportDataErrorMsg.airlineCountryMissing
                                    "
                                    >Pays manquant</span
                                >
                                <span
                                    *ngIf="
                                        errorMsg === EnumImportDataErrorMsg.contactGroupNameMissing
                                    "
                                    >Nom du groupe de contact manquant</span
                                >
                                <span *ngIf="errorMsg === EnumImportDataErrorMsg.emailMissing"
                                    >Adresse email manquante</span
                                >
                            </div>
                        </td>
                        <td
                            *ngFor="let header of data.headers; let j = index"
                            class="text-center"
                            [innerText]="line[j]"
                        ></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-container>

    <div class="row justify-content-center" *ngIf="dataValid">
        <div class="col-md-6">
            <div class="card mb-4">
                <div class="card-header">Restreindre au(x) département(s) concerné(s)</div>
                <div class="card-body">
                    <div class="form-group mb-0">
                        <div class="form-check">
                            <input
                                type="checkbox"
                                name="enquiryTypes"
                                class="form-check-input"
                                id="enquiryType-all"
                                [checked]="!selectedEnquiryTypes.length"
                                (change)="toggleAllEnquiryType()"
                            />
                            <label class="form-check-label" for="enquiryType-all"
                                >Disponible pour tous les départements
                            </label>
                        </div>

                        <div class="form-check" *ngFor="let enquiryType of getEnquiryTypes()">
                            <input
                                type="checkbox"
                                name="enquiryTypes"
                                class="form-check-input"
                                [id]="'enquiryType-' + enquiryType"
                                [checked]="selectedEnquiryTypes.includes(enquiryType)"
                                (change)="toggleEnquiryType(enquiryType)"
                            />
                            <label
                                class="form-check-label"
                                [attr.for]="'enquiryType-' + enquiryType"
                                [innerText]="getEnumEnquiryTypeLabel(enquiryType)"
                            >
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card mb-4" *ngIf="dataValid">
        <div class="card-header d-flex justify-content-between align-items-center">
            Aperçu avant import ({{ contactsToImport.length }} contacts)

            <button
                type="button"
                class="btn btn-primary"
                [disabled]="
                    false &&
                    (!contactsReadyToImport || contactsReadyToImport !== contactsToImport.length)
                "
                [innerText]="
                    fetchingRelatedData
                        ? 'Récupération en cours ... ' +
                          '(' +
                          contactsReadyToImport +
                          ' / ' +
                          contactsToImport.length +
                          ')'
                        : contactsReadyToImport !== contactsToImport.length
                        ? 'Importer ' +
                          '(' +
                          contactsReadyToImport +
                          ' valides / ' +
                          contactsToImport.length +
                          ')'
                        : 'Importer'
                "
                (click)="importAirlines()"
            ></button>
        </div>
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th class="text-center">Compagnie aérienne</th>
                        <th class="text-center">Pays</th>
                        <th class="text-center">Groupe de contact</th>
                        <th class="text-center">Prénom</th>
                        <th class="text-center">Nom</th>
                        <th class="text-center">Fonction</th>
                        <th class="text-center">Email</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let contactToImport of contactsToImport"
                        [class.table-warning]="
                            airlinesByTitleAndCountryLoaded[
                                contactToImport.airlineTitleAndCountryCode
                            ] && !contactToImport.airlineId
                        "
                    >
                        <td class="text-center placeholder-glow">
                            <div
                                class="placeholder w-100"
                                *ngIf="
                                    !airlinesByTitleAndCountryLoaded[
                                        contactToImport.airlineTitleAndCountryCode
                                    ]
                                "
                            ></div>
                            <div *ngIf="contactToImport.airlineId">
                                <a
                                    [routerLink]="'/admin/airlines/' + contactToImport.airlineId"
                                    target="_blank"
                                >
                                    {{ contactToImport.airlineTitle }}
                                    <fa-icon [icon]="faExternalLink"></fa-icon>
                                </a>
                            </div>
                            <div
                                *ngIf="
                                    airlinesByTitleAndCountryLoaded[
                                        contactToImport.airlineTitleAndCountryCode
                                    ] && !contactToImport.airlineId
                                "
                            >
                                <strong [innerText]="contactToImport.airlineTitle"></strong>
                                n'existe pas dans Simplyfly. Veuillez corriger le fichier d'import
                                OU créer la compagnie manquante dans Simplyfly.
                            </div>
                        </td>
                        <td
                            class="text-center"
                            [innerText]="
                                countries[contactToImport.airlineCountryCode] ||
                                contactToImport.airlineCountryCode
                            "
                        ></td>
                        <td
                            class="text-center"
                            [innerText]="contactToImport.companyContactName"
                        ></td>
                        <td
                            class="text-center"
                            [innerText]="contactToImport.contact.firstname"
                        ></td>
                        <td class="text-center" [innerText]="contactToImport.contact.lastname"></td>
                        <td class="text-center" [innerText]="contactToImport.contact.fonction"></td>
                        <td class="text-center" [innerText]="contactToImport.contact.email"></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</ng-container>
