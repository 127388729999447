import { Component, OnInit, NgZone, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { PaginationService } from '../../../services/pagination.service';
import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { IEncaissementType } from 'src/app/interfaces/encaissement-type.interface';

@Component({
  selector: 'app-encaissement-type-view',
  templateUrl: './encaissement-type-view.component.html',
  styleUrls: ['./encaissement-type-view.component.scss']
})
export class EncaissementTypeViewComponent implements OnInit {
  loading: boolean = false;
  encaissementTypeId: string;
  encaissementType: IEncaissementType | null = null;
  isLogged: boolean = false;

  EnumAcl = EnumAcl;

  constructor(
    private remoteService: RemoteService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public paginationService: PaginationService,
    private aclService: AclService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
  }

  ngOnInit(): void {
    this.activatedRoute.url.subscribe(() => {
      this.encaissementTypeId = this.activatedRoute.snapshot.paramMap.get('encaissementTypeId');

      this.loadData();
    });
  }

  loadData(): void {
    if (this.isLogged) {
      this.loadEncaissementType();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  async loadEncaissementType(): Promise<void> {
    this.loading = true;

    const doc = await this.remoteService.getDocument('encaissementTypes', this.encaissementTypeId);

    this.encaissementType = doc as IEncaissementType;

    this.loading = false;
  }

  delete(): void {
    if (this.hasAclAccess(EnumAcl.encaissementTypesDelete)) {
      const result = confirm(
        "La suppression d'un type d'encaissement sera permanente. Êtes-vous sûr de vouloir continuer?"
      );

      if (result) {
        this.remoteService
          .deleteDocumentInCollection('encaissementTypes', this.encaissementTypeId)
          .then(() => {
            this.router.navigate(['/admin/encaissement-types']);

            alert('La suppression a été effectuée avec succès.');
          })
          .catch(err => {
            console.log(err);
          });
      }
    }
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }
}
