<div class="centered-content">
    <div class="card mb-4">
        <div class="card-header">
            <a routerLink="/login" class="btn btn-light btn-sm"
                ><fa-icon [icon]="faArrowLeft"></fa-icon> Retour</a
            >
        </div>
        <div class="card-body">
            <h4>Mot de passe oublié ?</h4>
            <p class="mb-4">Nous vous enverrons un email pour réinitialiser votre mot de passe.</p>

            <form (ngSubmit)="submitForm()" [formGroup]="form" (keydown.enter)="submitForm()">
                <div class="form-group">
                    <label class="form-label">Email</label>
                    <input
                        tabindex="1"
                        type="email"
                        class="form-control"
                        formControlName="email"
                        placeholder="email@email.com"
                        [class.is-invalid]="email.invalid && (email.dirty || email.touched)"
                    />
                    <div class="invalid-tooltip" *ngIf="email.dirty && email.errors">
                        <div *ngIf="email.errors && email.errors['required']">
                            Ce champ est obligatoire.
                        </div>
                        <div *ngIf="email.errors && email.errors['email']">
                            Ce champ doit être un email valide.
                        </div>
                    </div>
                </div>

                <button
                    class="w-100 btn btn-lg btn-primary mt-2"
                    type="submit"
                    [disabled]="form.disabled || !form.valid"
                    (click)="submitForm()"
                    tabindex="5"
                >
                    Envoyer
                </button>
            </form>
        </div>
    </div>
</div>
