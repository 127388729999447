<div class="row">
    <div class="col-md-6 mb-4">
        <a
            routerLink="/admin/countries/add"
            class="btn btn-primary"
            *ngIf="hasAclAccess(EnumAcl.countriesAdd)"
            >+ Nouveau pays</a
        >
    </div>
    <div class="col-md-6">
        <div class="input-group mb-3">
            <select class="form-select" id="code" [(ngModel)]="countrySelectedId">
                <option [value]="null">Rechercher pays</option>
                <option
                    *ngFor="let country of countriesList"
                    [value]="country.code"
                    [innerText]="country.name"
                ></option>
            </select>
            <button
                type="button"
                class="btn btn-primary"
                [disabled]="!countrySelectedId"
                (click)="goToSelectedCountry()"
            >
                <fa-icon [icon]="faSearch"></fa-icon>
            </button>
        </div>
    </div>
</div>

<div class="card mb-4">
    <div class="table-responsive no-border">
        <table class="table table-striped table-hover">
            <thead>
                <tr>
                    <th
                        scope="col"
                        class="sortable"
                        *ngFor="let field of paginationService.pagination[currentPagination].fields"
                        [class.is-sorted]="
                            paginationService.pagination[currentPagination].orderBy.field ==
                            field.code
                        "
                        [class.asc]="
                            paginationService.pagination[currentPagination].orderBy.field ==
                                field.code &&
                            paginationService.pagination[currentPagination].orderBy.direction ==
                                'asc'
                        "
                        [class.desc]="
                            paginationService.pagination[currentPagination].orderBy.field ==
                                field.code &&
                            paginationService.pagination[currentPagination].orderBy.direction ==
                                'desc'
                        "
                        (click)="paginationService.sortField(currentPagination, field.code)"
                        [innerHtml]="field.title"
                    ></th>
                </tr>
            </thead>
            <tbody *ngIf="paginationService.loading">
                <tr *ngFor="let number of [].constructor(10)">
                    <td
                        *ngFor="let field of paginationService.pagination[currentPagination].fields"
                    >
                        <div class="skeleton-text"></div>
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="!paginationService.loading">
                <tr
                    *ngIf="
                        !paginationService.pagination[currentPagination].data ||
                        !paginationService.pagination[currentPagination].data.length
                    "
                    class="table-warning text-center"
                >
                    <td
                        [attr.colspan]="
                            paginationService.pagination[currentPagination].fields.length
                        "
                    >
                        Aucun pays n'a été trouvé.
                    </td>
                </tr>
                <tr
                    *ngFor="let country of paginationService.pagination[currentPagination].data"
                    [routerLink]="'/admin/countries/' + country.id"
                    class="clickable"
                >
                    <td [innerHtml]="countriesFr[country.code] ?? country.code"></td>
                    <td [innerHtml]="country.hasDiplomaticDifficulties ? 'Oui' : 'Non'"></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<nav aria-label="Liste navigation" class="mt-4 mb-4" *ngIf="!paginationService.loading">
    <ul class="pagination justify-content-center">
        <li
            class="page-item"
            [class.disabled]="paginationService.pagination[currentPagination].currentPage === 0"
        >
            <button
                type="button"
                class="page-link"
                (click)="paginationService.loadPreviousPage(currentPagination)"
            >
                Précédent
            </button>
        </li>
        <li class="page-item disabled">
            <span
                class="page-link"
                [innerHtml]="paginationService.pagination[currentPagination].currentPage + 1"
            ></span>
        </li>
        <li
            class="page-item"
            [class.disabled]="
                paginationService.pagination[currentPagination].data &&
                (!paginationService.pagination[currentPagination].data.length ||
                    paginationService.pagination[currentPagination].data.length <
                        paginationService.pagination[currentPagination].limit)
            "
        >
            <button
                type="button"
                class="page-link"
                (click)="paginationService.loadNextPage(currentPagination)"
            >
                Suivant
            </button>
        </li>
    </ul>
</nav>
