<ul class="nav nav-tabs" role="tablist">
    <li class="nav-item" role="presentation" *ngFor="let tab of tabs">
        <button
            class="nav-link"
            [class.active]="currentTab === tab"
            [id]="tab + '-tab'"
            type="button"
            role="tab"
            [attr.aria-controls]="tab + '-tab-pane'"
            [attr.aria-selected]="currentTab === tab"
            [innerText]="getTabLabel(tab)"
            (click)="changeCurrentTab(tab)"
        ></button>
    </li>
</ul>
<div class="text-center mt-4" *ngIf="!isLogged">
    <div class="spinner-border" role="status">
        <span class="visually-hidden">Chargement...</span>
    </div>
</div>

<div class="tab-content">
    <div
        class="tab-pane fade"
        *ngFor="let tab of tabs"
        [class.show]="currentTab === tab"
        [class.active]="currentTab === tab"
        [id]="tab + '-tab-pane'"
        role="tabpanel"
        [attr.aria-labelledby]="tab + '-tab-pane'"
        tabindex="0"
    >
        <app-enquiry-payment-summaries-table
            *ngIf="tab === EnumTab.enquiryPayments"
        ></app-enquiry-payment-summaries-table>

        <app-forecast-reports *ngIf="tab === EnumTab.forecastReports"></app-forecast-reports>

        <ng-container *ngIf="tab === EnumTab.myEnquiryPayments">
            <div class="text-center mt-4" *ngIf="!currentUser">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Chargement...</span>
                </div>
            </div>
            <app-enquiry-payment-summaries-table
                [userId]="currentUser.id"
                *ngIf="currentUser"
            ></app-enquiry-payment-summaries-table>
        </ng-container>

        <app-financial-reports *ngIf="tab === EnumTab.financialReports"></app-financial-reports>

        <app-employee-bonus-dashboard
            *ngIf="tab === EnumTab.employeeBonus"
        ></app-employee-bonus-dashboard>

        <app-list-export-requests
            *ngIf="tab === EnumTab.exportRequestDashboard"
            [embed]="true"
        ></app-list-export-requests>
    </div>
</div>
