<div class="text-center" *ngIf="loadingHandlings">
    <div class="spinner-border" role="status"></div>
    <div>Récupération des handlings ...</div>
</div>

<div class="text-center" *ngIf="analysingDuplicateHandlings">
    <div class="spinner-border" role="status"></div>
    <div>Recherche de doublons ...</div>
</div>
<ng-container *ngIf="!loadingHandlings && !analysingDuplicateHandlings">
    <div class="alert alert-success text-center" *ngIf="!handlingsToMerge.length">
        Aucun handling en doublon
    </div>

    <ng-container *ngIf="handlingsToMerge.length">
        <div class="alert alert-warning text-center">
            <strong [innerText]="handlingsToMerge.length"></strong> handlings ont des doublons
            détectés.
        </div>
        <div class="accordion mb-4" id="accordion-handlings">
            <div class="accordion-item" *ngFor="let item of handlingsToMerge; let i = index">
                <h2 class="accordion-header">
                    <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        [attr.data-bs-target]="'#handlings-' + i"
                        aria-expanded="false"
                        [attr.aria-controls]="'handlings-' + i"
                    >
                        <div class="w-100 d-flex justify-content-between me-2">
                            <div
                                [innerText]="airportsObj[item.handlings[0].airportId]?.title"
                            ></div>
                            <div
                                class="float-end"
                                [innerText]="item.handlings.length + ' doublons'"
                            ></div>
                        </div>
                    </button>
                </h2>
                <div
                    [id]="'handlings-' + i"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordion-handlings"
                >
                    <div class="accordion-body">
                        <div class="row justify-content-center">
                            <div class="col-md-6" *ngFor="let handling of item.handlings">
                                <div class="card mb-4">
                                    <div
                                        class="card-header text-center"
                                        [innerText]="handling.name"
                                    ></div>
                                    <div class="card-body text-center">
                                        Fiche crée le {{ handling.created | date : "dd/MM/YYYY"
                                        }}<br />
                                        Adresse :
                                        <span [innerHTML]="getHandlingFullAddress(handling)"></span>

                                        <div class="mt-2">
                                            <a
                                                [routerLink]="'/admin/handlings/' + handling.id"
                                                class="btn btn-primary"
                                                target="_blank"
                                                ><fa-icon [icon]="faEye"></fa-icon> Voir la fiche</a
                                            >
                                        </div>
                                    </div>
                                    <div
                                        class="card-footer text-center"
                                        *ngIf="item.status === EnumStatus.readyToMerge"
                                    >
                                        <button
                                            type="button"
                                            class="btn btn-success"
                                            (click)="confirmMergeHandlings(i, handling.id)"
                                        >
                                            <fa-icon [icon]="faCheckCircle"></fa-icon> Ceci est la
                                            fiche finale
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            class="alert alert-info text-center"
                            *ngIf="item.status === EnumStatus.draft"
                        >
                            Vous devez manuellement corriger l'une des deux fiches avant de les
                            fusionner. Une fois fait, cliquez sur le bouton ci-dessous :<br />
                            <button
                                type="button"
                                class="btn btn-primary btn-sm"
                                (click)="mergeHandlings(i)"
                            >
                                <fa-icon [icon]="faCheckCircle"></fa-icon> Je suis prêt à fusionner
                            </button>
                        </div>

                        <div
                            class="alert alert-warning text-center"
                            *ngIf="item.status === EnumStatus.readyToMerge"
                        >
                            Veuillez sélectionner la fiche finale ci-dessus.<br />
                            <button
                                type="button"
                                class="btn btn-warning btn-sm"
                                (click)="cancelMergeHandlings(i)"
                            >
                                <fa-icon [icon]="faCancel"></fa-icon>
                                Annuler
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>
