<form (submit)="submitForm()" [formGroup]="form" (keydown.enter)="submitForm()" class="main-form">
    <div [class]="inModal ? 'modal-body' : 'row justify-content-center'">
        <div [class.col-md-6]="!inModal">
            <div [class.card]="!inModal">
                <div [class.card-body]="!inModal">
                    <div class="form-group">
                        <label for="position">Position</label>
                        <input
                            class="form-control"
                            id="position"
                            formControlName="position"
                            type="number"
                            min="0"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['position'].touched) &&
                                form.controls['position'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['position'].touched) &&
                                    form.controls['position'].errors &&
                                    form.controls['position'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="list-group-header">Texte</div>
                    <ul class="list-group mb-4" formGroupName="textByLanguage">
                        <li class="list-group-item" *ngFor="let language of getLanguages()">
                            <div class="form-group mb-0">
                                <label
                                    [attr.for]="'text-' + language"
                                    [innerText]="getLanguageLabel(language)"
                                ></label>
                                <textarea
                                    class="form-control"
                                    [id]="'text-' + language"
                                    [formControlName]="language"
                                    [class.is-invalid]="
                                        (form.touched || getTextForLanguage(language).touched) &&
                                        getTextForLanguage(language).status === 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                    rows="2"
                                ></textarea>

                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched ||
                                                getTextForLanguage(language).touched) &&
                                            getTextForLanguage(language).errors &&
                                            getTextForLanguage(language).errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>

                    <div class="form-group">
                        <label>Département(s) concerné(s)</label>
                        <div class="form-check">
                            <input
                                type="checkbox"
                                name="enquiryTypes"
                                class="form-check-input"
                                id="enquiryType-all"
                                [checked]="
                                    form.value.enquiryTypes.length === getEnquiryTypes().length
                                "
                                (change)="toggleAllEnquiryType()"
                            />
                            <label class="form-check-label" for="enquiryType-all"
                                >Tous les départements
                            </label>
                        </div>
                        <ng-container
                            *ngIf="form.value.enquiryTypes.length !== getEnquiryTypes().length"
                        >
                            <div class="form-check" *ngFor="let enquiryType of getEnquiryTypes()">
                                <input
                                    type="checkbox"
                                    name="enquiryTypes"
                                    class="form-check-input"
                                    [id]="'enquiryType-' + enquiryType"
                                    [checked]="form.value.enquiryTypes.includes(enquiryType)"
                                    (change)="toggleEnquiryType(enquiryType)"
                                />
                                <label
                                    class="form-check-label"
                                    [attr.for]="'enquiryType-' + enquiryType"
                                    [innerText]="getEnumEnquiryTypeLabel(enquiryType)"
                                >
                                </label>
                            </div>
                        </ng-container>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['enquiryTypes'].touched) &&
                                    form.controls['enquiryTypes'].errors &&
                                    form.controls['enquiryTypes'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-center text-center mt-4" *ngIf="!inModal">
        <div class="col-md-4">
            <button
                type="button"
                [routerLink]="
                    flightBriefCateringId
                        ? '/admin/flight-brief-caterings/' + flightBriefCateringId
                        : '/admin/flight-brief-caterings'
                "
                class="btn btn-secondary btn-block mb-4"
                [disabled]="form.disabled"
            >
                Annuler
            </button>
        </div>
        <div class="col-md-4">
            <button
                type="submit"
                class="btn btn-primary btn-block mb-4"
                [disabled]="form.disabled || !form.valid"
            >
                Sauvegarder
            </button>
        </div>
    </div>

    <div class="modal-footer" *ngIf="inModal">
        <button type="button" class="btn btn-secondary" (click)="closeModal()">Annuler</button>
        <button type="button" class="btn btn-primary" (click)="submitForm()">Sauvegarder</button>
    </div>
</form>
