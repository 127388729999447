<div class="btn-toolbar justify-content-center mb-4">
    <div class="btn-group">
        <a
            [routerLink]="'/admin/crews/' + crewId + '/edit'"
            class="btn btn-primary mr-4"
            *ngIf="hasAclAccess(EnumAcl.crewsEdit)"
            >Éditer</a
        >
        <button
            type="button"
            (click)="delete()"
            class="btn btn-danger ml-4"
            *ngIf="hasAclAccess(EnumAcl.crewsDelete)"
        >
            Supprimer
        </button>
    </div>
</div>

<div class="row justify-content-center">
    <div class="col-md-8">
        <div class="card mb-4">
            <div class="card-body" *ngIf="loading">
                <h3><div class="skeleton-text"></div></h3>
            </div>
            <div class="card-body" *ngIf="!loading && crew">
                <h3 [innerHtml]="getCrewFullname(crew)" class="text-center"></h3>
                <dl class="row mb-0">
                    <dt class="col-md-6">Compagnie aérienne</dt>
                    <dd class="col-md-6">
                        <a
                            [routerLink]="'/admin/airlines/' + crew.airlineId"
                            [innerText]="airlinesObj[crew.airlineId].title"
                            *ngIf="airlinesObj[crew.airlineId]"
                        ></a>
                        <div class="skeleton-text" *ngIf="!airlinesObj[crew.airlineId]"></div>
                    </dd>
                    <dt class="col-md-6">Type</dt>
                    <dd class="col-md-6" [innerText]="getCrewTypeLabel(crew.type)"></dd>
                    <dt class="col-md-6">Genre</dt>
                    <dd class="col-md-6" [innerText]="getCrewGenderLabel(crew.gender)"></dd>
                    <dt class="col-md-6">Numéro de téléphone</dt>
                    <dd
                        class="col-md-6"
                        *ngIf="crew.phoneNumber"
                        [innerText]="crew.phoneNumber"
                    ></dd>
                    <dd class="col-md-6 text-muted" *ngIf="!crew.phoneNumber">Vide</dd>
                    <dt class="col-md-6">Observation</dt>
                    <dd class="col-md-6" [innerText]="crew.comment"></dd>
                </dl>
            </div>
        </div>
    </div>
</div>
