import { Component, OnInit, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';
import { EnumLanguage, getLanguageLabel } from 'src/app/enums/language.enum';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { IEmailTemplate } from 'src/app/interfaces/email-template.interface';

@Component({
  selector: 'app-email-template-edit',
  templateUrl: './email-template-edit.component.html',
  styleUrls: ['./email-template-edit.component.scss']
})
export class EmailTemplateEditComponent implements OnInit {
  EnumLanguage = EnumLanguage;
  getLanguageLabel = getLanguageLabel;

  isLogged: boolean = false;
  form: FormGroup;
  sending: boolean = false;
  emailTemplate: IEmailTemplate | null = null;
  emailTemplateId: string;

  emailTemplateVariables: { title: string; value: string }[] = [
    { value: '{{enquiry.refAndContractRef}}', title: '№ contrat / référence de la demande' },
    { value: '{{enquiry.ref}}', title: 'Référence de la demande' },
    { value: '{{enquiry.contractRef}}', title: '№ contrat de la demande' },
    { value: '{{enquiry.routing}}', title: 'Routing de la demande' },
    { value: '{{enquiry.emailAttachments}}', title: 'Pièces-jointes de la demande' },
    { value: '{{enquiry.dates}}', title: 'Dates de la demande' },
    { value: '{{airline.name}}', title: 'Nom de la compagnie aérienne' },
    { value: '{{enquiry.details}}', title: 'Détails de la demande' },
    { value: '{{user.emailSignature}}', title: "Signature de l'utilisateur" },
    { value: '{{client.name}}', title: 'Nom du client' },
    { value: '{{invoice.ref}}', title: 'Référence de la facture' },
    { value: '{{invoice.userRequest}}', title: 'Agent demandant la facture' },
    { value: '{{invoice.dates}}', title: 'Dates dans la facture' },
    { value: '{{invoice.routing}}', title: 'Routing dans la facture' },
    { value: '{{invoice.airlines}}', title: 'Compagnie aérienne de la facture' },
    { value: '{{invoice.aircrafts}}', title: 'Appareil de la facture' },
    { value: '{{invoice.url}}', title: 'Url de la facture dans Simplyfly' },
    { value: '{{invoice.dueDate}}', title: "Date échéance règlement client d'une facture" },
    { value: '{{invoice.products}}', title: "Produits d'une facture" },
    {
      value: '{{invoice.buyingPriceSupplierPriceInfo}}',
      title: "Prix achat facturé(s) par le(s) fournisseur(s) d'une facture"
    },
    {
      value: '{{invoice.buyingPriceSupplierPaymentsInfo}}',
      title: "Informations factures fournisseurs d'une facture"
    }
  ];

  constructor(
    private formBuilder: FormBuilder,
    private remoteService: RemoteService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private aclService: AclService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      title: ['', [Validators.required]],
      language: [EnumLanguage.fr, [Validators.required]],
      subject: ['', [Validators.required]],
      messageHtml: ['', [Validators.required]]
    });

    this.form.disable();

    this.activatedRoute.url.subscribe(async () => {
      this.emailTemplateId = this.activatedRoute.snapshot.paramMap.get('emailTemplateId');

      // this.setEditorVariables();

      if (this.emailTemplateId) {
        await this.aclService.checkAclAccess(EnumAcl.emailTemplatesEdit);
        this.loadData();
      } else {
        await this.aclService.checkAclAccess(EnumAcl.emailTemplatesAdd);
        this.form.enable();
      }
    });
  }

  getLanguages(): EnumLanguage[] {
    return Object.values(EnumLanguage);
  }

  loadData(): void {
    if (this.isLogged) {
      this.loadEmailTemplate();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  async loadEmailTemplate(): Promise<void> {
    const doc = await this.remoteService.getDocument('emailTemplates', this.emailTemplateId);

    this.emailTemplate = doc as IEmailTemplate;

    this.setEmailTemplate();
  }

  setEmailTemplate(): void {
    if (this.form && this.emailTemplate) {
      this.form.get('title').setValue(this.emailTemplate.title);
      this.form.get('language').setValue(this.emailTemplate.language);
      this.form.get('subject').setValue(this.emailTemplate.subject);
      this.form.get('messageHtml').setValue(this.emailTemplate.messageHtml);

      this.form.enable();
    }
  }

  submitForm(): void {
    this.form.markAsTouched();

    if (this.form.valid) {
      let data = Object.assign({}, this.form.value);

      for (const field in data) {
        if (typeof data[field] == 'undefined') {
          data[field] = null;
        }
      }

      this.sending = true;

      this.form.disable();

      if (this.emailTemplateId) {
        this.remoteService
          .updateDocumentToCollection('emailTemplates', this.emailTemplateId, data)
          .then(() => {
            this.sending = false;
            this.redirectAfterSaving();
          })
          .catch(err => {
            this.sending = false;
            this.form.enable();

            alert(err.message);
          });
      } else {
        this.remoteService
          .addDocumentToCollection('emailTemplates', data)
          .then((docId: string) => {
            this.emailTemplateId = docId;

            this.sending = false;
            this.redirectAfterSaving();
          })
          .catch(err => {
            this.sending = false;
            this.form.enable();

            alert(err.message);
          });
      }
    }
  }

  redirectAfterSaving(): void {
    this.router.navigate(['/admin/email-templates/' + this.emailTemplateId]);
  }
}
