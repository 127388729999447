import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EnquiryCotationsComponent } from './enquiry-cotations.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, FontAwesomeModule, RouterModule],
  declarations: [EnquiryCotationsComponent],
  exports: [EnquiryCotationsComponent]
})
export class EnquiryCotationsModule {}
