<div class="row justify-content-center">
    <div class="col-md-6">
        <div class="card mb-4">
            <div class="card-body" *ngIf="loading">
                <h3><div class="skeleton-text"></div></h3>
            </div>
            <div class="card-body" *ngIf="!loading && flightBriefCatering">
                <h3 [innerHtml]="flightBriefCatering.text" class="text-center"></h3>

                <ul class="list-unstyled">
                    <li>
                        <strong>Langue</strong> :
                        {{ getLanguageLabel(flightBriefCatering.language) }}
                    </li>
                    <li><strong>Texte</strong> : {{ flightBriefCatering.text }}</li>
                    <li><strong>Position</strong> : {{ flightBriefCatering.position }}</li>
                </ul>

                <div class="text-center mt-4">
                    <a
                        [routerLink]="
                            '/admin/flight-brief-caterings/' + flightBriefCatering.id + '/edit'
                        "
                        class="btn btn-primary mr-4"
                        *ngIf="hasAclAccess(EnumAcl.flightBriefCateringsEdit)"
                        >Éditer</a
                    >
                    <button
                        type="button"
                        (click)="delete()"
                        class="btn btn-primary ml-4"
                        *ngIf="hasAclAccess(EnumAcl.flightBriefCateringsDelete)"
                    >
                        Supprimer
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
