import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { EnquiryFlightEditAircraftComponent } from './enquiry-flight-edit-aircraft.component';
import { AirlineAutocompleteModule } from '../../airline-autocomplete/airline-autocomplete.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    AirlineAutocompleteModule
  ],
  declarations: [EnquiryFlightEditAircraftComponent],
  exports: [EnquiryFlightEditAircraftComponent]
})
export class EnquiryFlightEditAircraftModule {}
