import { Injectable } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import firebase from 'firebase/compat/app';
import { IFlightBriefCatering } from 'src/app/interfaces/flight-brief-catering.interface';
import { map } from 'rxjs/operators';
import { FlightBriefCateringFirestoreService } from './flight-brief-caterings-firestore.service';
import { EnumLanguage } from 'src/app/enums/language.enum';
import { IFindCondition } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class FlightBriefCateringService {
  constructor(private flightBriefCateringFirestoreService: FlightBriefCateringFirestoreService) {}

  create(flightBriefCatering: IFlightBriefCatering): Promise<string> {
    return this.flightBriefCateringFirestoreService.create(flightBriefCatering);
  }

  createWithId(id: string, flightBriefCatering: IFlightBriefCatering): Promise<string> {
    return this.flightBriefCateringFirestoreService.createWithId(id, flightBriefCatering);
  }

  update(flightBriefCatering: IFlightBriefCatering): Promise<string> {
    return this.flightBriefCateringFirestoreService.update(flightBriefCatering);
  }

  delete(id: string): Promise<void> {
    return this.flightBriefCateringFirestoreService.delete(id);
  }

  getFromId(id: string): Observable<IFlightBriefCatering> {
    return this.flightBriefCateringFirestoreService.docValue$(id);
  }

  getAll(): Observable<IFlightBriefCatering[]> {
    return this.flightBriefCateringFirestoreService.collectionValues$();
  }

  resetPagination(paginationName: string = 'list'): void {
    this.flightBriefCateringFirestoreService.resetPaginate(paginationName);
  }

  getPaginatedResult(
    paginationName: string = 'list',
    conditions: IFindCondition[] = [],
    orderBy: {
      field: string;
      direction: firebase.firestore.OrderByDirection;
    } = {
      field: 'created',
      direction: 'desc'
    },
    itemsPerPage: number = 20,
    direction: 'prev' | 'next' = 'next'
  ): Observable<IFlightBriefCatering[]> {
    return this.flightBriefCateringFirestoreService.paginate(
      conditions,
      orderBy,
      itemsPerPage,
      paginationName,
      direction
    );
  }
}
