import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnquiryFlightsCardMiniComponent } from './enquiry-flights-card-mini.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [CommonModule, FontAwesomeModule],
  declarations: [EnquiryFlightsCardMiniComponent],
  exports: [EnquiryFlightsCardMiniComponent]
})
export class EnquiryFlightsCardMiniModule {}
