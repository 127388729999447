<div class="text-center" *ngIf="loadingFbos">
    <div class="spinner-border" role="status"></div>
    <div>Récupération des FBOs ...</div>
</div>

<div class="text-center" *ngIf="analysingDuplicateFbos">
    <div class="spinner-border" role="status"></div>
    <div>Recherche de doublons ...</div>
</div>
<ng-container *ngIf="!loadingFbos && !analysingDuplicateFbos">
    <div class="alert alert-success text-center" *ngIf="!fbosToMerge.length">
        Aucun fbo en doublon
    </div>

    <ng-container *ngIf="fbosToMerge.length">
        <div class="alert alert-warning text-center">
            <strong [innerText]="fbosToMerge.length"></strong> fbos ont des doublons détectés.
        </div>
        <div class="accordion mb-4" id="accordion-fbos">
            <div class="accordion-item" *ngFor="let item of fbosToMerge; let i = index">
                <h2 class="accordion-header">
                    <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        [attr.data-bs-target]="'#fbos-' + i"
                        aria-expanded="false"
                        [attr.aria-controls]="'fbos-' + i"
                    >
                        <div class="w-100 d-flex justify-content-between me-2">
                            <div [innerText]="airportsObj[item.fbos[0].airportId]?.title"></div>
                            <div
                                class="float-end"
                                [innerText]="item.fbos.length + ' doublons'"
                            ></div>
                        </div>
                    </button>
                </h2>
                <div
                    [id]="'fbos-' + i"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordion-fbos"
                >
                    <div class="accordion-body">
                        <div class="row justify-content-center">
                            <div class="col-md-6" *ngFor="let fbo of item.fbos">
                                <div class="card mb-4">
                                    <div
                                        class="card-header text-center"
                                        [innerText]="fbo.name"
                                    ></div>
                                    <div class="card-body text-center">
                                        Fiche crée le {{ fbo.created | date : "dd/MM/YYYY" }}<br />
                                        Adresse :
                                        <span [innerHTML]="getFboFullAddress(fbo)"></span>

                                        <div class="mt-2">
                                            <a
                                                [routerLink]="'/admin/fbos/' + fbo.id"
                                                class="btn btn-primary"
                                                target="_blank"
                                                ><fa-icon [icon]="faEye"></fa-icon> Voir la fiche</a
                                            >
                                        </div>
                                    </div>
                                    <div
                                        class="card-footer text-center"
                                        *ngIf="item.status === EnumStatus.readyToMerge"
                                    >
                                        <button
                                            type="button"
                                            class="btn btn-success"
                                            (click)="confirmMergeFbos(i, fbo.id)"
                                        >
                                            <fa-icon [icon]="faCheckCircle"></fa-icon> Ceci est la
                                            fiche finale
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            class="alert alert-info text-center"
                            *ngIf="item.status === EnumStatus.draft"
                        >
                            Vous devez manuellement corriger l'une des deux fiches avant de les
                            fusionner. Une fois fait, cliquez sur le bouton ci-dessous :<br />
                            <button
                                type="button"
                                class="btn btn-primary btn-sm"
                                (click)="mergeFbos(i)"
                            >
                                <fa-icon [icon]="faCheckCircle"></fa-icon> Je suis prêt à fusionner
                            </button>
                        </div>

                        <div
                            class="alert alert-warning text-center"
                            *ngIf="item.status === EnumStatus.readyToMerge"
                        >
                            Veuillez sélectionner la fiche finale ci-dessus.<br />
                            <button
                                type="button"
                                class="btn btn-warning btn-sm"
                                (click)="cancelMergeFbos(i)"
                            >
                                <fa-icon [icon]="faCancel"></fa-icon>
                                Annuler
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>
