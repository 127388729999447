<form (submit)="saveAndNext()" [formGroup]="form" (keydown.enter)="saveAndNext()" class="main-form">
    <div class="card mb-4">
        <div class="card-header"><fa-icon [icon]="faBox"></fa-icon> Cargo</div>
        <div class="card-body">
            <div class="alert alert-info text-center mb-0" *ngIf="enquiryFlight?.isFerryFlight">
                Cette section ne s'applique pas à ce vol car celui-ci est un vol de positionnement.
            </div>
            <div
                class="alert alert-info text-center mb-0"
                *ngIf="enquiry?.type !== EnumEnquiryType.cargo"
            >
                Cette section ne s'applique pas à ce vol car celui-ci n'est pas un cargo.
            </div>
            <div
                class="row justify-content-center"
                *ngIf="!enquiryFlight?.isFerryFlight && enquiry?.type === EnumEnquiryType.cargo"
            >
                <div class="col-md-6 col-lg-4">
                    <div class="form-group">
                        <label for="weight">Poids</label>

                        <div class="input-group">
                            <input
                                type="number"
                                class="form-control"
                                id="weight"
                                formControlName="weight"
                                [class.is-invalid]="
                                    (form.touched || weight.touched) && weight.status === 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                                min="0"
                            />

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched || weight.touched) &&
                                        weight.errors &&
                                        weight.errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>

                            <span class="input-group-text">kg</span>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="volume">Volume</label>
                        <div class="input-group">
                            <input
                                type="number"
                                class="form-control"
                                id="volume"
                                formControlName="volume"
                                [class.is-invalid]="
                                    (form.touched || volume.touched) && volume.status === 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                                min="0"
                            />

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched || volume.touched) &&
                                        volume.errors &&
                                        volume.errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>

                            <span class="input-group-text">m³</span>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="awbNumber">Numéro(s) AWB</label>
                        <input
                            id="awbNumber"
                            type="text"
                            class="form-control"
                            formControlName="awbNumber"
                            [class.is-invalid]="
                                (form.touched || awbNumber.touched) &&
                                awbNumber.status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />
                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || awbNumber.touched) &&
                                    awbNumber.errors &&
                                    awbNumber.errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4">
                    <div class="form-group">
                        <label for="typeOfCargo">Type of cargo</label>
                        <input
                            id="typeOfCargo"
                            type="text"
                            class="form-control"
                            formControlName="typeOfCargo"
                            [class.is-invalid]="
                                (form.touched || typeOfCargo.touched) &&
                                typeOfCargo.status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />
                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || typeOfCargo.touched) &&
                                    typeOfCargo.errors &&
                                    typeOfCargo.errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="nbPackages">Nombre de paquets</label>
                        <div class="input-group">
                            <input
                                id="nbPackages"
                                type="number"
                                min="0"
                                class="form-control"
                                formControlName="nbPackages"
                                [class.is-invalid]="
                                    (form.touched || nbPackages.touched) &&
                                    nbPackages.status === 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched || nbPackages.touched) &&
                                        nbPackages.errors &&
                                        nbPackages.errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                            <span class="input-group-text">pièces</span>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="customDeclaration">Déclaration douane</label>
                        <input
                            id="customDeclaration"
                            type="text"
                            class="form-control"
                            formControlName="customDeclaration"
                            [class.is-invalid]="
                                (form.touched || customDeclaration.touched) &&
                                customDeclaration.status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />
                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || customDeclaration.touched) &&
                                    customDeclaration.errors &&
                                    customDeclaration.errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4">
                    <div class="form-group">
                        <label for="deliveryCargoHoursBeforeDeparture"
                            >Limite livraison cargo</label
                        >
                        <div class="input-group">
                            <input
                                id="deliveryCargoHoursBeforeDeparture"
                                type="number"
                                min="0"
                                class="form-control"
                                formControlName="deliveryCargoHoursBeforeDeparture"
                                [class.is-invalid]="
                                    (form.touched || deliveryCargoHoursBeforeDeparture.touched) &&
                                    deliveryCargoHoursBeforeDeparture.status === 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />
                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched ||
                                            deliveryCargoHoursBeforeDeparture.touched) &&
                                        deliveryCargoHoursBeforeDeparture.errors &&
                                        deliveryCargoHoursBeforeDeparture.errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                            <span class="input-group-text">heures</span>
                        </div>
                    </div>
                    <div class="form-check form-switch">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            formControlName="dgd"
                            id="dgd"
                        />
                        <label class="form-check-label" for="dgd">DGD</label>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mb-4">
        <div class="col">
            <button
                type="button"
                class="btn btn-secondary"
                [disabled]="form.disabled"
                (click)="prev()"
            >
                <fa-icon [icon]="faArrowLeft"></fa-icon>
                Précédent
            </button>
        </div>
        <div class="col text-end">
            <button
                type="button"
                class="btn btn-primary"
                [disabled]="form.disabled || !form.valid"
                (click)="saveAndNext()"
            >
                Suivant
                <fa-icon [icon]="faArrowRight"></fa-icon>
            </button>
        </div>
    </div>
</form>
