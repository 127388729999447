<form (submit)="saveAndNext()" [formGroup]="form" (keydown.enter)="saveAndNext()" class="main-form">
    <div class="card mb-4">
        <div class="card-header"><fa-icon [icon]="faComment"></fa-icon> Remarque</div>
        <div class="card-body">
            <div class="form-group mb-0">
                <textarea
                    rows="3"
                    class="form-control"
                    id="comment"
                    formControlName="comment"
                    [class.is-invalid]="
                        (form.touched || comment.touched) && comment.status === 'INVALID'
                            ? 'danger'
                            : ''
                    "
                ></textarea>

                <div class="invalid-tooltip">
                    <div
                        *ngIf="
                            (form.touched || comment.touched) &&
                            comment.errors &&
                            comment.errors['required']
                        "
                    >
                        Ce champ est obligatoire.
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mb-4">
        <div class="col">
            <button
                type="button"
                class="btn btn-secondary"
                [disabled]="form.disabled"
                (click)="prev()"
            >
                <fa-icon [icon]="faArrowLeft"></fa-icon>
                Précédent
            </button>
        </div>
        <div class="col text-end">
            <button
                type="button"
                class="btn btn-primary"
                [disabled]="form.disabled || !form.valid"
                (click)="saveAndNext()"
            >
                Sauvegarder
            </button>
        </div>
    </div>
</form>
