<form (submit)="saveAndNext()" [formGroup]="form" (keydown.enter)="saveAndNext()" class="main-form">
    <div class="card mb-4">
        <div class="card-header"><fa-icon [icon]="faUsers"></fa-icon> Passagers</div>
        <div class="card-body">
            <div class="alert alert-info text-center mb-0" *ngIf="enquiryFlight?.isFerryFlight">
                Cette section ne s'applique pas à ce vol car celui-ci est un vol de positionnement.
            </div>
            <div
                class="alert alert-info text-center mb-0"
                *ngIf="enquiry?.type === EnumEnquiryType.cargo"
            >
                Cette section ne s'applique pas à ce vol car celui-ci est un cargo.
            </div>
            <div
                class="row justify-content-center"
                *ngIf="!enquiryFlight?.isFerryFlight && enquiry?.type !== EnumEnquiryType.cargo"
            >
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="boardingMinutesBeforeDeparture"
                            >Limite convocation passager</label
                        >
                        <div class="input-group">
                            <input
                                id="boardingMinutesBeforeDeparture"
                                type="number"
                                min="0"
                                class="form-control"
                                formControlName="boardingMinutesBeforeDeparture"
                                [class.is-invalid]="
                                    (form.touched || boardingMinutesBeforeDeparture.touched) &&
                                    boardingMinutesBeforeDeparture.status == 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />
                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched || boardingMinutesBeforeDeparture.touched) &&
                                        boardingMinutesBeforeDeparture.errors &&
                                        boardingMinutesBeforeDeparture.errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                            <span class="input-group-text">mins</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="pax">Nombre total de passagers</label>

                        <input
                            type="number"
                            class="form-control"
                            id="pax"
                            formControlName="pax"
                            [class.is-invalid]="
                                (form.touched || pax.touched) && pax.status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || pax.touched) &&
                                    pax.errors &&
                                    pax.errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-6">
            <div class="card mb-4">
                <div class="card-header">
                    <div
                        class="card-title mb-0 text-center"
                        [innerText]="passengers.length > 1 ? 'Passagers' : 'Passager'"
                    ></div>
                </div>
                <ul class="list-group list-group-flush" formArrayName="passengers">
                    <li
                        class="list-group-item"
                        *ngFor="let passenger of passengers.controls; let i = index"
                        [formGroupName]="i"
                    >
                        <div class="row justify-content-center">
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label [attr.for]="'passenger-' + i + '-civility'"
                                        >Civilité</label
                                    >
                                    <select
                                        class="form-select"
                                        [id]="'passenger-' + i + '-civility'"
                                        formControlName="civility"
                                        [class.is-invalid]="
                                            (form.touched ||
                                                getPassengerField(i, 'civility').touched) &&
                                            getPassengerField(i, 'civility').status == 'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                    >
                                        <option
                                            [value]="civility"
                                            *ngFor="let civility of getCivilities()"
                                            [innerHtml]="
                                                getFlightBriefPassengerCivilityLabel(
                                                    civility,
                                                    form.value.language
                                                )
                                            "
                                        ></option>
                                    </select>

                                    <div class="invalid-tooltip">
                                        <div
                                            *ngIf="
                                                (form.touched ||
                                                    getPassengerField(i, 'civility').touched) &&
                                                getPassengerField(i, 'civility').errors &&
                                                getPassengerField(i, 'civility').errors['required']
                                            "
                                        >
                                            Ce champ est obligatoire.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label [attr.for]="'passenger-' + i + '-firstname'"
                                        >Prénom</label
                                    >
                                    <input
                                        [id]="'passenger-' + i + '-firstname'"
                                        type="text"
                                        class="form-control"
                                        formControlName="firstname"
                                        [class.is-invalid]="
                                            (form.touched ||
                                                getPassengerField(i, 'firstname').touched) &&
                                            getPassengerField(i, 'firstname').status == 'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                    />
                                    <div class="invalid-tooltip">
                                        <div
                                            *ngIf="
                                                (form.touched ||
                                                    getPassengerField(i, 'firstname').touched) &&
                                                getPassengerField(i, 'firstname').errors &&
                                                getPassengerField(i, 'firstname').errors['required']
                                            "
                                        >
                                            Ce champ est obligatoire.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label [attr.for]="'passenger-' + i + '-lastname'">Nom</label>
                                    <input
                                        [id]="'passenger-' + i + '-lastname'"
                                        type="text"
                                        class="form-control"
                                        formControlName="lastname"
                                        [class.is-invalid]="
                                            (form.touched ||
                                                getPassengerField(i, 'lastname').touched) &&
                                            getPassengerField(i, 'lastname').status == 'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                    />
                                    <div class="invalid-tooltip">
                                        <div
                                            *ngIf="
                                                (form.touched ||
                                                    getPassengerField(i, 'lastname').touched) &&
                                                getPassengerField(i, 'lastname').errors &&
                                                getPassengerField(i, 'lastname').errors['required']
                                            "
                                        >
                                            Ce champ est obligatoire.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 d-flex align-self-center justify-content-end">
                                <button
                                    type="button"
                                    (click)="removePassenger(i)"
                                    class="btn btn-danger"
                                >
                                    <fa-icon [icon]="faTrash"></fa-icon>
                                </button>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="card-footer text-center" *ngIf="passengers.length <= pax.value">
                    <button type="button" class="btn btn-primary" (click)="addPassenger()">
                        + Ajouter
                    </button>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="form-group placeholder-glow">
                        <div class="placeholder w-100" *ngIf="loadingFlightBriefCaterings"></div>
                        <label for="flightBriefCateringId"
                            >Catering
                            <button
                                type="button"
                                (click)="addFlightBriefCatering()"
                                class="btn btn-icon"
                                *ngIf="hasAclAccess(EnumAcl.flightBriefCateringsAdd)"
                                [disabled]="loadingFlightBriefCaterings"
                            >
                                <fa-icon [icon]="faCirclePlus"></fa-icon>
                            </button>
                        </label>
                        <div
                            [class.input-group]="flightBriefCateringId.value"
                            *ngIf="!loadingFlightBriefCaterings"
                        >
                            <select
                                class="form-select"
                                id="flightBriefCateringId"
                                formControlName="flightBriefCateringId"
                                [class.is-invalid]="
                                    (form.touched || flightBriefCateringId.touched) &&
                                    flightBriefCateringId.status === 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                                (change)="updatedFlightBriefCateringId()"
                            >
                                <option [ngValue]="null">-- Choisissez --</option>
                                <option
                                    [value]="flightBriefCatering.id"
                                    *ngFor="
                                        let flightBriefCatering of displayedFlightBriefCaterings
                                    "
                                    [innerHtml]="
                                        flightBriefCatering.textByLanguage
                                            ? flightBriefCatering.textByLanguage[EnumLanguage.fr]
                                            : ''
                                    "
                                ></option>
                                <option value="custom">Catering personnalisé</option>
                            </select>

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched || flightBriefCateringId.touched) &&
                                        flightBriefCateringId.errors &&
                                        flightBriefCateringId.errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                            <button
                                type="button"
                                class="btn btn-primary"
                                (click)="editFlightBriefCatering()"
                                *ngIf="
                                    hasAclAccess(EnumAcl.flightBriefCateringsEdit) &&
                                    flightBriefCateringId.value
                                "
                                rel="tooltip"
                                title="Éditer FBO"
                            >
                                <fa-icon [icon]="faEdit"></fa-icon>
                            </button>
                        </div>

                        <textarea
                            class="form-control"
                            rows="2"
                            id="catering"
                            formControlName="catering"
                            [class.is-invalid]="
                                (form.touched || catering.touched) && catering.status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                            placeholder="Saisissez le catering"
                            *ngIf="flightBriefCateringId.value === 'custom'"
                        ></textarea>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || catering.touched) &&
                                    catering.errors &&
                                    catering.errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                    <div class="form-group mb-0">
                        <label for="luggages" class="d-block mb-1"
                            >Bagages par passager

                            <div class="form-check form-switch float-end">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    formControlName="luggageIsCustom"
                                    (change)="updateLuggageIsCustom()"
                                    id="luggageIsCustom"
                                />
                                <label class="form-check-label" for="luggageIsCustom"
                                    >Personnalisé</label
                                >
                            </div>
                        </label>

                        <div class="clearfix"></div>

                        <ul class="list-group" *ngIf="!luggageIsCustom.value">
                            <li class="list-group-item">
                                <label>Nombre de bagage</label>
                                <div class="selectors-increment-number">
                                    <button
                                        type="button"
                                        class="btn btn-primary btn-sm"
                                        [disabled]="luggageQty.value <= 0"
                                        (click)="updateLuggageQty(-1)"
                                    >
                                        -
                                    </button>
                                    <div
                                        class="selector-number-value"
                                        [innerText]="luggageQty.value"
                                    ></div>
                                    <button
                                        type="button"
                                        class="btn btn-primary btn-sm"
                                        (click)="updateLuggageQty(1)"
                                    >
                                        +
                                    </button>
                                </div>
                            </li>

                            <li class="list-group-item">
                                <label>Poids par bagage</label>

                                <div class="selectors-increment-number">
                                    <button
                                        type="button"
                                        class="btn btn-primary btn-sm"
                                        [disabled]="luggagesWeight.value <= 0"
                                        (click)="updateLuggageWeight(-1)"
                                    >
                                        -
                                    </button>
                                    <div
                                        class="selector-number-value"
                                        [innerText]="
                                            luggagesWeight.value > 1
                                                ? luggagesWeight.value + ' kgs'
                                                : luggagesWeight.value + ' kg'
                                        "
                                    ></div>
                                    <button
                                        type="button"
                                        class="btn btn-primary btn-sm"
                                        (click)="updateLuggageWeight(1)"
                                    >
                                        +
                                    </button>
                                </div>
                            </li>
                        </ul>

                        <ng-container *ngIf="luggageIsCustom.value">
                            <textarea
                                class="form-control"
                                rows="1"
                                id="luggages"
                                formControlName="luggages"
                                [class.is-invalid]="
                                    (form.touched || luggages.touched) &&
                                    luggages.status === 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            ></textarea>

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched || luggages.touched) &&
                                        luggages.errors &&
                                        luggages.errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mb-4">
        <div class="col">
            <button
                type="button"
                class="btn btn-secondary"
                [disabled]="form.disabled"
                (click)="prev()"
            >
                <fa-icon [icon]="faArrowLeft"></fa-icon>
                Précédent
            </button>
        </div>
        <div class="col text-end">
            <button
                type="button"
                class="btn btn-primary"
                [disabled]="form.disabled || !form.valid"
                (click)="saveAndNext()"
            >
                Suivant
                <fa-icon [icon]="faArrowRight"></fa-icon>
            </button>
        </div>
    </div>
</form>

<div class="modal" tabindex="-1" role="dialog" #modalFlightBriefCatering>
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5
                    class="modal-title"
                    [innerText]="
                        editingFlightBriefCatering
                            ? 'Édition d\'un catering'
                            : 'Ajout d\'un catering'
                    "
                ></h5>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <app-flight-brief-catering-edit
                [inModal]="true"
                [flightBriefCateringId]="editingFlightBriefCatering?.id || null"
                (dismissModal)="dismissFlightBriefCateringModal($event)"
            ></app-flight-brief-catering-edit>
        </div>
    </div>
</div>
