import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EnquiryFlightEditComponent } from './enquiry-flight-edit.component';
import { AirportPickerModule } from '../airport-picker/airport-picker.module';
import { RouterModule } from '@angular/router';
import { FboEditModule } from 'src/app/admin/fbos/fbo-edit/fbo-edit.module';
import { AirlineAutocompleteModule } from '../airline-autocomplete/airline-autocomplete.module';
import { HandlingEditModule } from 'src/app/admin/handlings/handling-edit/handling-edit.module';
import { CrewEditModule } from 'src/app/admin/crews/crew-edit/crew-edit.module';
import { AirlineSupervisorEditModule } from 'src/app/admin/airline-supervisors/airline-supervisor-edit/airline-supervisor-edit.module';
import { EnquiryFlightEditAirportModule } from './enquiry-flight-edit-airport/enquiry-flight-edit-airport.module';
import { EnquiryFlightEditDetailsModule } from './enquiry-flight-edit-details/enquiry-flight-edit-details.module';
import { EnquiryFlightsCardMiniModule } from '../enquiry-flights-card-mini/enquiry-flights-card-mini.module';
import { EnquiryFlightEditAircraftModule } from './enquiry-flight-edit-aircraft/enquiry-flight-edit-aircraft.module';
import { EnquiryFlightEditPassengersModule } from './enquiry-flight-edit-passengers/enquiry-flight-edit-passengers.module';
import { EnquiryFlightEditCargoModule } from './enquiry-flight-edit-cargo/enquiry-flight-edit-cargo.module';
import { EnquiryFlightEditCrewModule } from './enquiry-flight-edit-crew/enquiry-flight-edit-crew.module';
import { EnquiryFlightEditCommentModule } from './enquiry-flight-edit-comment/enquiry-flight-edit-comment.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    AirportPickerModule,
    FboEditModule,
    HandlingEditModule,
    CrewEditModule,
    AirlineSupervisorEditModule,
    AirlineAutocompleteModule,
    EnquiryFlightEditAirportModule,
    EnquiryFlightEditDetailsModule,
    EnquiryFlightsCardMiniModule,
    EnquiryFlightEditAircraftModule,
    EnquiryFlightEditPassengersModule,
    EnquiryFlightEditCargoModule,
    EnquiryFlightEditCrewModule,
    EnquiryFlightEditCommentModule
  ],
  declarations: [EnquiryFlightEditComponent],
  exports: [EnquiryFlightEditComponent]
})
export class EnquiryFlightEditModule {}
