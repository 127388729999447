import {
  Component,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef
} from '@angular/core';
import { AlgoliaService } from 'src/app/services/algolia.service';
import { RemoteService } from 'src/app/services/remote.service';

import countries from '../../countries_fr.json';
import { IAirport } from 'src/app/interfaces/airport.interface';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-airport-picker',
  templateUrl: './airport-picker.component.html',
  styleUrls: ['./airport-picker.component.scss']
})
export class AirportPickerComponent implements OnChanges {
  @Input('isInvalid') isInvalid: boolean;
  @Input('inputName') inputName: string;
  @Input('disabled') disabled: boolean = false;
  @Input('defaultValue') defaultValue: string;
  @Input('noMargin') noMargin: boolean = false;
  @Output() setValueToFormControl: EventEmitter<{
    fields: Array<{
      name: string;
      value: string;
    }>;
  }> = new EventEmitter();

  faXmark = faXmark;

  value: string;
  selectedAirport: IAirport | null = null;
  airports: IAirport[] = [];
  optionsSuggested: IAirport[] = [];
  @ViewChild('searchInput', { static: false }) searchInput: ElementRef;
  // initiated: boolean = false

  countries = countries;

  query: string;

  constructor(private algoliaService: AlgoliaService, private remoteService: RemoteService) {}

  ngOnChanges() {
    // if (!this.initiated && this.defaultId) {
    this.value = this.defaultValue;

    // this.initiated = true
    // }

    this.updateSelectedAirport();
  }

  async updateSelectedAirport(): Promise<void> {
    if (this.value) {
      const airportDoc = await this.remoteService.getDocument('airports', this.value);

      if (airportDoc) {
        const airport: IAirport = airportDoc as IAirport;

        this.airports.push(airport);
      }
    }

    for (const airport of this.airports) {
      if (airport.id == this.value) {
        this.selectedAirport = airport;

        break;
      }
    }
  }

  updateFormValue(selectedAirport: IAirport | null = null): void {
    this.value = selectedAirport ? selectedAirport.id : null;

    this.updateSelectedAirport();

    this.setValueToFormControl.emit({
      fields: [
        {
          name: this.inputName,
          value: this.value
        }
      ]
    });

    this.optionsSuggested = [];
  }

  async updateAutocomplete(): Promise<void> {
    this.optionsSuggested = [];

    if (this.query) {
      try {
        this.airports = await this.algoliaService.searchAirports(this.query);

        this.optionsSuggested = this.airports;
      } catch (err) {
        console.error(err);
      }
    }
  }

  clearValue(): void {
    this.query = '';

    this.updateAutocomplete();
    this.updateFormValue(null);

    setTimeout(() => {
      if (this.searchInput) {
        this.searchInput.nativeElement.focus();
      }
    });
  }
}
