<div class="d-flex justify-content-center gap-2 mt-4 mb-4" *ngIf="quotation">
    <a
        [routerLink]="'/admin/quotations/' + quotation.id + '/edit'"
        class="btn btn-primary ml-4 mr-4"
        *ngIf="hasAclAccess(EnumAcl.quotationsEdit)"
        >Éditer</a
    >
    <button
        type="button"
        (click)="generatePdf()"
        class="btn btn-primary ml-4 mr-4"
        [disabled]="loading || generatingPdf"
    >
        PDF
    </button>
    <a
        [routerLink]="'/admin/flight-briefs/add/from-quotation/' + quotation.id"
        class="btn btn-primary ml-4 mr-4"
        *ngIf="hasAclAccess(EnumAcl.flightBriefsGenerateFromQuotation)"
        >Générer flight brief</a
    >
    <div class="dropdown">
        <button
            class="btn btn-primary dropdown-toggle"
            type="button"
            id="dropdownMenuButton-status"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            [class.disabled]="!hasAclAccess(EnumAcl.quotationsSwitchStatus)"
        >
            Statut : {{ getQuotationStatusLabel(quotation.status) }}
        </button>
        <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton-status">
            <button
                type="button"
                class="dropdown-item"
                *ngFor="let status of getQuotationStatuses()"
                [innerHtml]="'Changer le statut en \'' + getQuotationStatusLabel(status) + '\''"
                (click)="changeStatus(status)"
                [class.disabled]="quotation.status === status"
            ></button>
        </div>
    </div>
    <button
        type="button"
        (click)="delete()"
        class="btn btn-primary ml-4"
        *ngIf="hasAclAccess(EnumAcl.quotationsDelete)"
    >
        Supprimer
    </button>
</div>

<div class="row justify-content-center" *ngIf="loading">Chargement ...</div>

<div *ngIf="!loading && quotation" class="container mb-4">
    <div
        class="alert alert-info text-center"
        *ngIf="quotation.status === EnumQuotationStatus.draft"
    >
        Avez-vous envoyé le devis au client ? Si oui, cliquez sur le bouton ci-dessous. <br />
        La demande passera alors en "Proposition envoyée" dans Pipedrive.<br />
        <br />
        <button
            type="button"
            class="btn btn-success"
            (click)="changeStatus(EnumQuotationStatus.sent)"
        >
            <fa-icon [icon]="faCheck"></fa-icon>
            Devis envoyé au client
        </button>
    </div>
    <div class="card mb-4">
        <div class="card-body">
            <div id="pdf-content" [class.generating-pdf]="generatingPdf">
                <div class="pdf-content">
                    <div class="row justify-content-center">
                        <div class="col-md-4">
                            <strong [innerHtml]="quotation.clientTitle"></strong><br />
                            <strong [innerHtml]="quotation.contactTitle">Prénom Nom</strong><br />
                            <div *ngIf="quotation.contactPhone">{{ quotation.contactPhone }}</div>
                            <a
                                [href]="'mailto:' + quotation.contactEmail"
                                [innerHtml]="quotation.contactEmail"
                                *ngIf="quotation.contactEmail"
                            ></a>
                        </div>
                        <div class="col-md-4">
                            <strong [innerHtml]="quotation.companyTitle"></strong><br />
                            <strong [innerHtml]="quotation.agentTitle"></strong><br />
                            <div *ngIf="quotation.agentPhone">{{ quotation.agentPhone }}</div>
                            <a
                                [href]="'mailto:' + quotation.agentEmail"
                                [innerHtml]="quotation.agentEmail"
                                *ngIf="quotation.agentEmail"
                            ></a>
                        </div>
                        <div class="col-md-4">
                            <strong
                                >{{ getDefaultStringTranslation("QUOTATION.DATE") }} :
                                {{
                                    quotation.date
                                        | date : "dd MMMM yyyy" : undefined : quotation.language
                                }}</strong
                            ><br />
                            <strong
                                >{{ getDefaultStringTranslation("QUOTATION.QUOTATION_NUMBER") }} :
                                {{ quotation.title }}</strong
                            ><br />
                            <strong
                                >{{ getDefaultStringTranslation("QUOTATION.VERSION") }} :
                                {{ quotation.versionNumber }}</strong
                            >
                        </div>
                    </div>

                    <div id="plan-de-vol">
                        <div class="header-with-line">
                            <span
                                [innerHtml]="getDefaultStringTranslation('QUOTATION.FLIGHT_PLAN')"
                            ></span>
                        </div>

                        <div class="table-responsive">
                            <table class="table table-bordered table-sm">
                                <thead>
                                    <tr>
                                        <th
                                            [innerHtml]="
                                                getDefaultStringTranslation('QUOTATION.DATE')
                                            "
                                        ></th>
                                        <th
                                            [innerHtml]="
                                                getDefaultStringTranslation('QUOTATION.FLIGHT_PLAN')
                                            "
                                        ></th>
                                        <th
                                            [innerHtml]="
                                                getDefaultStringTranslation(
                                                    'QUOTATION.TIME_DEPARTURE'
                                                )
                                            "
                                            *ngIf="quotation.enquiryType !== EnumEnquiryType.cargo"
                                        ></th>
                                        <th
                                            [innerHtml]="
                                                getDefaultStringTranslation(
                                                    'QUOTATION.TIME_ARRIVAL'
                                                )
                                            "
                                            *ngIf="quotation.enquiryType !== EnumEnquiryType.cargo"
                                        ></th>
                                        <th
                                            [innerHtml]="
                                                getDefaultStringTranslation('QUOTATION.FLIGHT_TIME')
                                            "
                                            *ngIf="quotation.enquiryType !== EnumEnquiryType.cargo"
                                        ></th>
                                        <th
                                            [innerHtml]="
                                                getDefaultStringTranslation('QUOTATION.PASSENGERS')
                                            "
                                            *ngIf="quotation.enquiryType !== EnumEnquiryType.cargo"
                                        ></th>
                                        <th
                                            [innerHtml]="
                                                getDefaultStringTranslation('QUOTATION.CARGO') +
                                                ' (kg)'
                                            "
                                            *ngIf="quotation.enquiryType === EnumEnquiryType.cargo"
                                        ></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let trip of quotation.trips">
                                        <td
                                            [innerHtml]="formatTripDate(trip.date)"
                                            class="trip-date"
                                        ></td>
                                        <td>
                                            <span>{{
                                                removeAirportStr(
                                                    airportsObj[trip.airportDepart]?.title
                                                )
                                            }}</span>
                                            -
                                            <span>{{
                                                removeAirportStr(
                                                    airportsObj[trip.airportDestination]?.title
                                                )
                                            }}</span>
                                        </td>
                                        <td
                                            [innerHtml]="trip.time"
                                            *ngIf="quotation.enquiryType !== EnumEnquiryType.cargo"
                                        ></td>
                                        <td
                                            [innerHtml]="trip.arrivalTime"
                                            *ngIf="quotation.enquiryType !== EnumEnquiryType.cargo"
                                        ></td>
                                        <td
                                            [innerHtml]="trip.flyTimeInHours"
                                            *ngIf="quotation.enquiryType !== EnumEnquiryType.cargo"
                                        ></td>
                                        <td
                                            [innerHtml]="trip.passengersTotal"
                                            *ngIf="quotation.enquiryType !== EnumEnquiryType.cargo"
                                        ></td>
                                        <td
                                            [innerHtml]="trip.weight"
                                            *ngIf="quotation.enquiryType === EnumEnquiryType.cargo"
                                        ></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div
                            class="mt-2 mb-4"
                            id="observations-trips"
                            [innerHtml]="quotation.observationsTrips"
                        ></div>

                        <div
                            class="mb-4"
                            [innerHtml]="getListOfAirportsGmt()"
                            id="airports-timezone"
                        ></div>

                        <div id="images">
                            <div id="images-col-1">
                                <img
                                    [src]="imgsToBase64['googleMapStaticUrl']"
                                    *ngIf="imgsToBase64['googleMapStaticUrl']"
                                    height="114"
                                />
                            </div>
                            <div id="images-col-2">
                                <div class="image-container" *ngIf="imgsToBase64['image1Url']">
                                    <div
                                        class="image"
                                        id="image1"
                                        [style.background-image]="
                                            'url(' + imgsToBase64['image1Url'] + ')'
                                        "
                                        [style.webkitTransform]="
                                            'scale(' + quotation.image1ZoomLevel + ')'
                                        "
                                        [style.transform]="
                                            'scale(' + quotation.image1ZoomLevel + ')'
                                        "
                                    ></div>
                                </div>
                                <div class="image-container" *ngIf="imgsToBase64['image2Url']">
                                    <div
                                        class="image"
                                        id="image2"
                                        [style.background-image]="
                                            'url(' + imgsToBase64['image2Url'] + ')'
                                        "
                                        [style.webkitTransform]="
                                            'scale(' + quotation.image2ZoomLevel + ')'
                                        "
                                        [style.transform]="
                                            'scale(' + quotation.image2ZoomLevel + ')'
                                        "
                                    ></div>
                                </div>
                            </div>
                            <div id="images-col-3">
                                <div class="image-container" *ngIf="imgsToBase64['image3Url']">
                                    <div
                                        class="image"
                                        id="image3"
                                        [style.background-image]="
                                            'url(' + imgsToBase64['image3Url'] + ')'
                                        "
                                        [style.webkitTransform]="
                                            'scale(' + quotation.image3ZoomLevel + ')'
                                        "
                                        [style.transform]="
                                            'scale(' + quotation.image3ZoomLevel + ')'
                                        "
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="cotation">
                        <div class="header-with-line">
                            <span
                                [innerHtml]="getDefaultStringTranslation('QUOTATION.QUOTATION')"
                            ></span>
                        </div>

                        <div class="table-responsive">
                            <table class="table table-bordered table-sm">
                                <colgroup>
                                    <col width="165" />
                                    <col width="250" />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th
                                            [innerHtml]="
                                                getDefaultStringTranslation(
                                                    'QUOTATION.OBSERVATIONS'
                                                )
                                            "
                                        ></th>
                                        <th
                                            [innerHtml]="
                                                getDefaultStringTranslation(
                                                    'QUOTATION.AIRCRAFT.TITLE'
                                                )
                                            "
                                        ></th>
                                        <th
                                            [innerHtml]="
                                                getDefaultStringTranslation('QUOTATION.PRICE.TITLE')
                                            "
                                        ></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th class="pre-line">
                                            {{ quotation.notes }}
                                        </th>
                                        <td>
                                            <div
                                                class="row mb-1"
                                                *ngFor="let cotationItem of quotation.aircraftInfos"
                                            >
                                                <div
                                                    class="col-md-6"
                                                    [innerHtml]="cotationItem.title"
                                                ></div>
                                                <div class="col-md-6">
                                                    <strong
                                                        *ngIf="cotationItem.type === 'price'"
                                                        [innerHtml]="cotationItem.value"
                                                    ></strong>
                                                    <span
                                                        *ngIf="cotationItem.type !== 'price'"
                                                        [innerHtml]="cotationItem.value"
                                                    ></span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div
                                                class="row mb-1"
                                                *ngFor="let cotationItem of quotation.priceInfos"
                                            >
                                                <div
                                                    class="col-md-6"
                                                    [innerHtml]="cotationItem.title"
                                                ></div>
                                                <div class="col-md-6 text-end">
                                                    <strong
                                                        *ngIf="cotationItem.type === 'price'"
                                                        [innerHtml]="cotationItem.value"
                                                    ></strong>
                                                    <span
                                                        *ngIf="cotationItem.type !== 'price'"
                                                        [innerHtml]="cotationItem.value"
                                                    ></span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="page-break"></div>

                <div class="pdf-content">
                    <div class="row justify-content-center">
                        <div class="col-md-4">
                            <strong [innerHtml]="quotation.clientTitle"></strong><br />
                            <strong [innerHtml]="quotation.contactTitle">Prénom Nom</strong><br />
                            <div *ngIf="quotation.contactPhone">{{ quotation.contactPhone }}</div>
                            <a
                                [href]="'mailto:' + quotation.contactEmail"
                                [innerHtml]="quotation.contactEmail"
                                *ngIf="quotation.contactEmail"
                            ></a>
                        </div>
                        <div class="col-md-4">
                            <strong [innerHtml]="quotation.companyTitle"></strong><br />
                            <strong [innerHtml]="quotation.agentTitle"></strong><br />
                            <div *ngIf="quotation.agentPhone">{{ quotation.agentPhone }}</div>
                            <a
                                [href]="'mailto:' + quotation.agentEmail"
                                [innerHtml]="quotation.agentEmail"
                                *ngIf="quotation.agentEmail"
                            ></a>
                        </div>
                        <div class="col-md-4">
                            <br />
                            <strong
                                >{{ getDefaultStringTranslation("QUOTATION.DATE") }} :
                                {{
                                    quotation.date
                                        | date : "dd MMMM yyyy" : undefined : quotation.language
                                }}</strong
                            ><br />
                            <strong
                                >{{ getDefaultStringTranslation("QUOTATION.QUOTATION_NUMBER") }} :
                                {{ quotation.title }}</strong
                            ><br />
                            <strong
                                >{{ getDefaultStringTranslation("QUOTATION.VERSION") }} :
                                {{ quotation.versionNumber }}</strong
                            >
                        </div>
                    </div>

                    <div id="services">
                        <div *ngFor="let servicesSection of quotation.servicesSections">
                            <div class="header-with-line">
                                <span [innerHtml]="servicesSection.title"></span>
                            </div>

                            <div
                                class="form-check"
                                *ngFor="let service of servicesSection.services"
                            >
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="defaultCheck1"
                                    onclick="return false;"
                                    [checked]="service.checked"
                                />
                                <label class="form-check-label" for="defaultCheck1">
                                    {{ service.title }}
                                    <span class="float-lg-end"
                                        ><span class="d-lg-none">- </span
                                        >{{ service.comment }}</span
                                    >
                                    <div class="clearfix"></div>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div
                        id="reservation"
                        *ngIf="
                            [EnumEnquiryType.business, EnumEnquiryType.helico].indexOf(
                                quotation.enquiryType
                            ) !== -1
                        "
                    >
                        <div class="header-with-line">
                            <span
                                [innerHtml]="getDefaultStringTranslation('QUOTATION.BOOKING')"
                            ></span>
                        </div>

                        <div class="row">
                            <div class="col-md-8 mb-4">
                                <div class="payment-conditions">
                                    <div *ngIf="quotation.paymentConditions">
                                        <strong
                                            >{{
                                                getDefaultStringTranslation(
                                                    "QUOTATION.PAYMENT_CONDITIONS_TITLE"
                                                )
                                            }}
                                            :</strong
                                        ><br />
                                        {{ quotation.paymentConditions }}
                                    </div>
                                    <br />
                                    <div *ngIf="quotation.cancellingConditions">
                                        <strong
                                            >{{
                                                getDefaultStringTranslation(
                                                    "QUOTATION.CANCELLING_CONDITIONS_TITLE"
                                                )
                                            }}
                                            :</strong
                                        ><br />
                                        {{ quotation.cancellingConditions }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="signature-square">
                                    <strong>{{
                                        getDefaultStringTranslation("QUOTATION.GOOD_FOR_AGREEMENT")
                                    }}</strong
                                    ><br />
                                    {{
                                        getDefaultStringTranslation(
                                            "QUOTATION.GOOD_FOR_AGREEMENT_TEXT"
                                        )
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        id="garanties-and-engagements"
                        *ngIf="[EnumEnquiryType.commercial].indexOf(quotation.enquiryType) !== -1"
                    >
                        <div class="header-with-line">
                            <span
                                [innerHtml]="
                                    getDefaultStringTranslation(
                                        'QUOTATION.OUR_GUARANTIES_AND_ENGAGEMENTS'
                                    )
                                "
                            ></span>
                        </div>

                        <div
                            id="garanties-and-engagements-text"
                            [innerHtml]="quotation.garantiesAndEngagements"
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
