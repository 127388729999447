import { Component, OnInit } from '@angular/core';

import { PaginationService } from '../../../services/pagination.service';
import { AclService } from '../../../services/acl.service';

import countries from '../../../countries_fr.json';

import { EnumAcl } from 'src/app/enums/acl.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { ICrew, getCrewTypeLabel } from 'src/app/interfaces/crew.interface';
import { IAirline } from 'src/app/interfaces/airline.interface';
import { Subscription } from 'rxjs';
import { AirlineService } from 'src/app/services/airlines/airlines.service';

@Component({
  selector: 'app-list-crews',
  templateUrl: './list-crews.component.html',
  styleUrls: ['./list-crews.component.scss']
})
export class ListCrewsComponent implements OnInit {
  EnumAcl = EnumAcl;

  getCrewTypeLabel = getCrewTypeLabel;

  currentPagination: string = 'crews-list';
  countriesList: Array<{
    title: string;
    value: string;
  }> = [];
  crews: ICrew[] = [];
  airlineId: string | null = null;
  airlinesObj: { [id: string]: IAirline | null } = {};

  private subscriptions = new Subscription();

  constructor(
    public paginationService: PaginationService,
    private aclService: AclService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private airlineService: AirlineService
  ) {
    for (const code in countries) {
      this.countriesList.push({
        title: countries[code],
        value: code
      });
    }
  }

  async ngOnInit(): Promise<void> {
    this.activatedRoute.url.subscribe(async () => {
      this.airlineId = this.activatedRoute.snapshot.paramMap.get('airlineId');

      await this.aclService.checkAclAccess(EnumAcl.crewsList);

      if (this.airlineId) {
        this.loadAirline(this.airlineId);
      }

      this.paginationService.pagination[this.currentPagination].currentPage = 0;

      this.fetchData();
    });
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  async fetchData(): Promise<void> {
    this.paginationService.pagination[this.currentPagination].conditions = [];

    if (this.airlineId) {
      this.paginationService.pagination[this.currentPagination].conditions.push({
        field: 'airlineId',
        operator: '==',
        value: this.airlineId
      });
    }

    await this.paginationService.fetchData(this.currentPagination);
  }

  async setValueToFormControl($event: {
    fields: {
      name: string;
      value: any;
    }[];
  }): Promise<void> {
    for (let field of $event.fields) {
      if (field.name === 'crewId') {
        this.router.navigateByUrl('/admin/crews/' + field.value);
      }
    }
  }

  loadAirline(airlineId: string): void {
    if (typeof this.airlinesObj[airlineId] === 'undefined') {
      this.airlinesObj[airlineId] = null;

      this.subscriptions.add(
        this.airlineService.getFromId(airlineId).subscribe((airline: IAirline | null) => {
          this.airlinesObj[airlineId] = airline;
        })
      );
    }
  }
}
