import { Component, OnInit, NgZone, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { PaginationService } from '../../../services/pagination.service';
import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';
import { EnumEnquiryType } from 'src/app/enums/enquiry-type.enum';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { Subscription } from 'rxjs';
import { IAirline, getStreetNumberAndRoute } from 'src/app/interfaces/airline.interface';
import { getContinentLabel, getCountryLabel } from 'src/app/enums/continent-code.enum';
import { IAircraft } from 'src/app/interfaces/aircraft.interface';
import { IAircraftModel } from 'src/app/interfaces/aircraft-model.interface';

@Component({
  selector: 'app-airline-view',
  templateUrl: './airline-view.component.html',
  styleUrls: ['./airline-view.component.scss']
})
export class AirlineViewComponent implements OnInit {
  EnumEnquiryType = EnumEnquiryType;
  EnumAcl = EnumAcl;

  faEye = faEye;

  @ViewChild('modalAircraftCompiled', { static: false }) modalAircraftCompiledElement: ElementRef;
  getStreetNumberAndRoute = getStreetNumberAndRoute;
  getCountryLabel = getCountryLabel;
  getContinentLabel = getContinentLabel;

  loading: boolean = false;
  airlineId: string;
  airline: IAirline | null = null;
  isLogged: boolean = false;
  paginationsType: Array<{
    title: string;
    id: string;
    pagination: string;
    active: boolean;
  }> = [
    {
      title: 'Appareils pour passagers',
      id: 'passengers',
      pagination: 'aircrafts-compiled-passengers-list',
      active: true
    },
    {
      title: 'Appareils pour cargo',
      id: 'cargo',
      pagination: 'aircrafts-compiled-cargo-list',
      active: false
    },
    {
      title: 'Tous les appareils aériens',
      id: 'all-aircrafts',
      pagination: 'aircrafts-simplified-list',
      active: false
    }
  ];
  aircraftModelsObj: object = {};
  listAircraftsOfCompiledAircrafts: IAircraft[] = [];

  private subscriptions = new Subscription();

  constructor(
    private remoteService: RemoteService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public paginationService: PaginationService,
    private aclService: AclService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );

    this.remoteService.aircraftModelsObservable.subscribe((aircraftModels: IAircraftModel[]) => {
      if (aircraftModels) {
        for (const aircraftModel of aircraftModels) {
          this.aircraftModelsObj[aircraftModel.id] = aircraftModel;
        }
      }
    });
  }

  ngOnInit(): void {
    this.activatedRoute.url.subscribe(() => {
      this.airlineId = this.activatedRoute.snapshot.paramMap.get('airlineId');

      this.loadData();
    });
  }

  ngOnDestroy(): void {
    for (const type of this.paginationsType) {
      this.paginationService.reset(type.pagination, true);
    }

    this.removeModal();

    this.subscriptions.unsubscribe();
  }

  removeModal(): void {
    window['$'](this.modalAircraftCompiledElement.nativeElement).modal('hide');
    window['$']('body').removeClass('modal-open');
    window['$']('.modal-backdrop').remove();
  }

  loadData(): void {
    if (this.isLogged) {
      this.loadAirline();
      this.refreshData();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  async loadAirline(): Promise<void> {
    this.loading = true;

    const doc = await this.remoteService.getDocument('airlines', this.airlineId);

    this.airline = doc as IAirline;

    await this.remoteService.loadAircraftModels();

    this.loading = false;
  }

  async refreshData(): Promise<void> {
    for (const type of this.paginationsType) {
      if (
        type.active &&
        (!this.paginationService.pagination[type.pagination].data ||
          !this.paginationService.pagination[type.pagination].data.length)
      ) {
        this.paginationService.pagination[type.pagination].conditions.push({
          field: 'airlineId',
          operator: '==',
          value: this.airlineId
        });

        await this.paginationService.fetchData(type.pagination);
      }
    }
  }

  changeTab(id: string): void {
    for (const type of this.paginationsType) {
      type.active = type.id === id;
    }

    this.refreshData();
  }

  delete(): void {
    if (this.hasAclAccess(EnumAcl.airlinesDelete)) {
      const result = confirm(
        'La suppression de la compagnie aérienne sera permanent. Êtes-vous sûr de vouloir continuer?'
      );

      if (result) {
        this.remoteService
          .deleteDocumentInCollection('airlines', this.airlineId)
          .then(() => {
            this.router.navigate(['/admin/airlines']);

            alert('La suppression a été effectuée avec succès.');
          })
          .catch(err => {
            console.log(err);
          });
      }
    }
  }

  async openAircraftCompiledModal(aircraftsId: Array<string>): Promise<void> {
    this.listAircraftsOfCompiledAircrafts = [];

    window['$'](this.modalAircraftCompiledElement.nativeElement).modal('show');

    if (aircraftsId.length) {
      const aircrafts: Array<object> = await this.remoteService.getDocumentsFromDocId(
        'aircrafts',
        aircraftsId
      );

      for (const aircraft of aircrafts) {
        this.listAircraftsOfCompiledAircrafts.push(aircraft as IAircraft);
      }
    }
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }
}
