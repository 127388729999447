<form (submit)="submitForm()" [formGroup]="form" class="main-form">
    <div class="row">
        <div class="col-md-6 col-lg-8">
            <div class="card">
                <div class="card-body">
                    <div class="form-group">
                        <label for="title">Nom</label>
                        <input
                            class="form-control"
                            id="title"
                            formControlName="title"
                            type="text"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['title'].touched) &&
                                form.controls['title'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['title'].touched) &&
                                    form.controls['title'].errors &&
                                    form.controls['title'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-check mb-2">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="is-lieu-dit"
                            formControlName="isLieuDit"
                        />
                        <label class="form-check-label" for="is-lieu-dit">Est un lieu-dit ?</label>
                    </div>

                    <div class="form-group">
                        <label for="iataCode">Code IATA</label>
                        <input
                            class="form-control"
                            id="iataCode"
                            formControlName="iataCode"
                            type="text"
                            [class.is-invalid]="
                                (form.touched || form.controls['iataCode'].touched) &&
                                form.controls['iataCode'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['iataCode'].touched) &&
                                    form.controls['iataCode'].errors &&
                                    form.controls['iataCode'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="countryCode">Pays</label>
                        <autocomplete-input
                            (setValueToFormControl)="setValueToFormControl($event)"
                            inputName="countryCode"
                            [defaultValue]="form.value['countryCode']"
                            [itineraries]="countriesList"
                            [disabled]="form.disabled || sending || !countriesList.length"
                            *ngIf="countriesList.length"
                        ></autocomplete-input>

                        <div
                            *ngIf="
                                (form.touched || form.controls['countryCode'].touched) &&
                                form.controls['countryCode'].errors &&
                                form.controls['countryCode'].errors['required']
                            "
                            class="text-danger"
                        >
                            Ce champ est obligatoire.
                        </div>
                    </div>

                    <div class="form-group" *ngIf="form.value['countryCode']">
                        <label for="continentCode">Continent</label>
                        <input
                            class="form-control"
                            id="continentCode"
                            type="text"
                            [readonly]="
                                !(form.value['countryCode'] && !form.value['continentCode'])
                            "
                            [value]="getContinentLabel(form.value['continentCode'])"
                        />
                    </div>

                    <div class="form-group">
                        <label for="latitude">Latitude</label>
                        <input
                            class="form-control"
                            id="latitude"
                            formControlName="latitude"
                            type="number"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['latitude'].touched) &&
                                form.controls['latitude'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['latitude'].touched) &&
                                    form.controls['latitude'].errors &&
                                    form.controls['latitude'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="longitude">Longitude</label>
                        <input
                            class="form-control"
                            id="longitude"
                            formControlName="longitude"
                            type="number"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['longitude'].touched) &&
                                form.controls['longitude'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['longitude'].touched) &&
                                    form.controls['longitude'].errors &&
                                    form.controls['longitude'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="mb-4">
                        <a
                            href="https://support.google.com/maps/answer/18539?co=GENIE.Platform%3DDesktop&hl=fr"
                            target="_blank"
                            >Comment obtenir la latitude et longitude d'un lieu</a
                        >
                    </div>

                    <div class="form-group">
                        <label for="city">Ville</label>
                        <input
                            class="form-control"
                            id="city"
                            formControlName="city"
                            type="text"
                            [class.is-invalid]="
                                (form.touched || form.controls['city'].touched) &&
                                form.controls['city'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['city'].touched) &&
                                    form.controls['city'].errors &&
                                    form.controls['city'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="distancefromCity">Distance de la ville</label>
                        <input
                            class="form-control"
                            id="distancefromCity"
                            formControlName="distancefromCity"
                            type="text"
                            [class.is-invalid]="
                                (form.touched || form.controls['distancefromCity'].touched) &&
                                form.controls['distancefromCity'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['distancefromCity'].touched) &&
                                    form.controls['distancefromCity'].errors &&
                                    form.controls['distancefromCity'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="fireCategory">Catégorie incendie</label>
                        <input
                            class="form-control"
                            id="fireCategory"
                            formControlName="fireCategory"
                            type="text"
                            [class.is-invalid]="
                                (form.touched || form.controls['fireCategory'].touched) &&
                                form.controls['fireCategory'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['fireCategory'].touched) &&
                                    form.controls['fireCategory'].errors &&
                                    form.controls['fireCategory'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="longestHardSurfaceRunwayInFt">Longueur de piste maximale</label>
                        <input
                            class="form-control"
                            id="longestHardSurfaceRunwayInFt"
                            formControlName="longestHardSurfaceRunwayInFt"
                            type="text"
                            [class.is-invalid]="
                                (form.touched ||
                                    form.controls['longestHardSurfaceRunwayInFt'].touched) &&
                                form.controls['longestHardSurfaceRunwayInFt'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched ||
                                        form.controls['longestHardSurfaceRunwayInFt'].touched) &&
                                    form.controls['longestHardSurfaceRunwayInFt'].errors &&
                                    form.controls['longestHardSurfaceRunwayInFt'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-lg-4">
            <div class="card mb-4">
                <div class="card-header text-center">Simulateur de prix</div>
                <div class="card-body">
                    <div class="form-check mb-2">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="is-in-simulateur-prix"
                            formControlName="isInSimulateurPrix"
                            (change)="updatedIsInSimulateurPrix()"
                        />
                        <label class="form-check-label" for="is-in-simulateur-prix"
                            >Visible dans le simulateur de prix ?</label
                        >
                    </div>

                    <ng-container *ngIf="form.value.isInSimulateurPrix">
                        <div class="form-check mt-4 mb-2">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                id="visibleInSimulateurPrixMap"
                                formControlName="visibleInSimulateurPrixMap"
                            />
                            <label class="form-check-label" for="visibleInSimulateurPrixMap"
                                >Visible sur la carte ?</label
                            >
                        </div>

                        <div class="form-check mt-4 mb-2">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                id="isJetAirport"
                                formControlName="isJetAirport"
                            />
                            <label class="form-check-label" for="isJetAirport"
                                >Est un aéroport JET ?</label
                            >
                        </div>

                        <div class="form-check mt-4 mb-2">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                id="isHighDemand"
                                formControlName="isHighDemand"
                            />
                            <label class="form-check-label" for="isHighDemand"
                                >Est très sollicité ?</label
                            >
                        </div>
                        <p class="text-muted">
                            Le calcul de prix ne s'affichera pas sur le simulateur de prix si très
                            sollicité. L'utilisateur ne pourra alors que contacter l'équipe.
                        </p>

                        <div class="form-group">
                            <label for="simulateurInformation"
                                >Information dans le simulateur</label
                            >
                            <quill-editor
                                formControlName="simulateurInformation"
                                [class.is-invalid]="
                                    (form.touched ||
                                        form.controls['simulateurInformation'].touched) &&
                                    form.controls['simulateurInformation'].status == 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            ></quill-editor>

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched ||
                                            form.controls['simulateurInformation'].touched) &&
                                        form.controls['simulateurInformation'].errors &&
                                        form.controls['simulateurInformation'].errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>

            <div class="card mb-4">
                <div class="card-header">Transferts d'hélicoptère</div>
                <ul class="list-group list-group-flush" formArrayName="helicopterTransfers">
                    <li
                        class="list-group-item text-bg-warning text-center"
                        *ngIf="!helicopterTransfers.length"
                    >
                        Aucun transfert
                    </li>
                    <li
                        class="list-group-item"
                        *ngFor="
                            let helicopterTransfer of helicopterTransfers.controls;
                            let i = index
                        "
                        [formGroupName]="i"
                    >
                        <div class="form-group">
                            <label for="airportDestinationId">Aéroport</label>
                            <app-airport-picker
                                [inputName]="'airportDestinationId'"
                                [defaultValue]="
                                    getHelicopterTransferField(i, 'airportDestinationId').value
                                "
                                (setValueToFormControl)="updateAirportDestinationId($event, i)"
                                [isInvalid]="
                                    (form.touched ||
                                        getHelicopterTransferField(i, 'airportDestinationId')
                                            .touched) &&
                                    getHelicopterTransferField(i, 'airportDestinationId').status ==
                                        'INVALID'
                                "
                            ></app-airport-picker>

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched ||
                                            getHelicopterTransferField(i, 'airportDestinationId')
                                                .touched) &&
                                        getHelicopterTransferField(i, 'airportDestinationId')
                                            .errors &&
                                        getHelicopterTransferField(i, 'airportDestinationId')
                                            .errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="timeInMin">Temps en minutes</label>
                            <input
                                class="form-control"
                                id="timeInMin"
                                formControlName="timeInMin"
                                type="number"
                                min="0"
                                [class.is-invalid]="
                                    (form.touched ||
                                        getHelicopterTransferField(i, 'timeInMin').touched) &&
                                    getHelicopterTransferField(i, 'timeInMin').status == 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched ||
                                            getHelicopterTransferField(i, 'timeInMin').touched) &&
                                        getHelicopterTransferField(i, 'timeInMin').errors &&
                                        getHelicopterTransferField(i, 'timeInMin').errors[
                                            'required'
                                        ]
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="price">Prix transfert en euros</label>
                            <input
                                class="form-control"
                                id="price"
                                formControlName="price"
                                type="number"
                                min="0"
                                [class.is-invalid]="
                                    (form.touched ||
                                        getHelicopterTransferField(i, 'price').touched) &&
                                    getHelicopterTransferField(i, 'price').status == 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched ||
                                            getHelicopterTransferField(i, 'price').touched) &&
                                        getHelicopterTransferField(i, 'price').errors &&
                                        getHelicopterTransferField(i, 'price').errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>

                        <div class="text-center">
                            <button
                                type="button"
                                class="btn btn-danger"
                                (click)="deleteHelicopterTransfer(i)"
                            >
                                Supprimer
                            </button>
                        </div>
                    </li>
                </ul>
                <div class="card-footer text-center">
                    <button type="button" class="btn btn-primary" (click)="addHelicopterTransfer()">
                        Ajout
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-center text-center mt-4">
        <div class="col-md-4">
            <button
                type="button"
                [routerLink]="
                    isLieuDit
                        ? airportId
                            ? '/admin/lieux-dits/' + airportId
                            : '/admin/lieux-dits'
                        : airportId
                        ? '/admin/airports/' + airportId
                        : '/admin/airports/'
                "
                class="btn btn-secondary btn-block mb-4"
                [disabled]="form.disabled || sending"
            >
                Annuler
            </button>
        </div>
        <div class="col-md-4">
            <button
                type="submit"
                class="btn btn-primary btn-block mb-4"
                [innerHtml]="sending ? 'Sauvegarde ...' : 'Sauvegarder'"
                [disabled]="form.disabled || !form.valid || sending"
            ></button>
        </div>
    </div>
</form>
