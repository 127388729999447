<div class="modal-header" *ngIf="inModal">
    <h5 class="modal-title">Ajout d'un leg</h5>
    <button
        type="button"
        class="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        #btnCloseModal
    ></button>
</div>
<div [class.modal-body]="inModal" class="text-center">
    Comment souhaitez-vous ajouter le nouveau leg ?

    <div class="form-check justify-content-center">
        <input
            class="form-check-input"
            type="radio"
            name="add-type"
            id="add-type-scratch"
            [(ngModel)]="addType"
            [value]="EnumAddType.scratch"
        />
        <label class="form-check-label" for="add-type-scratch">Je pars de zéro</label>
    </div>
    <div class="form-check justify-content-center">
        <input
            class="form-check-input"
            type="radio"
            name="add-type"
            id="add-type-duplicate"
            [(ngModel)]="addType"
            [value]="EnumAddType.duplicate"
        />
        <label class="form-check-label" for="add-type-duplicate"
            >Je duplique à partir d'un leg existant</label
        >
    </div>

    <div class="form-group mb-0" *ngIf="addType === EnumAddType.duplicate">
        <select class="form-select" id="legId" [(ngModel)]="enquiryFlightId">
            <option [ngValue]="null">-- Choisissez --</option>
            <option
                [value]="enquiryFlight.id"
                *ngFor="let enquiryFlight of enquiryFlights"
                [innerText]="
                    (enquiryFlight.departDateTimeLocal | date : 'dd/MM/yyyy HH:mm') +
                    ' : ' +
                    enquiryFlight.airportDepartIataCode +
                    ' → ' +
                    enquiryFlight.airportArrivalIataCode
                "
            ></option>
        </select>
    </div>
</div>

<div [class.modal-footer]="inModal" class="justify-content-center">
    <button
        type="button"
        class="btn btn-primary"
        (click)="save()"
        [disabled]="!addType || (addType === EnumAddType.duplicate && !enquiryFlightId)"
    >
        Sauvegarder
    </button>
</div>
