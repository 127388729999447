import { finalize } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  AbstractControl,
  FormControl
} from '@angular/forms';

import { RemoteService } from '../../../services/remote.service';
import { PipedriveService } from '../../../services/pipedrive.service';
import { AclService } from '../../../services/acl.service';

import moment from 'moment';
import {
  getDisplayedEnquiryRefTitle,
  getEnquiryBreadcrumbTitle,
  IEnquiry
} from 'src/app/interfaces/enquiry.interface';
import { Subscription } from 'rxjs';
import { EnquiryService } from 'src/app/services/enquiry/enquiry.service';
import { EnquiryOfferService } from 'src/app/services/enquiry-offer/enquiry-offer.service';
import { EnumEnquiryType, getEnumEnquiryTypeLabel } from 'src/app/enums/enquiry-type.enum';
import { IEnquiryOffer } from 'src/app/interfaces/enquiry-offer.interface';
import { EnumEnquiryOfferStatus } from 'src/app/enums/enquiry-offer-status.enum';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { IUser } from 'src/app/interfaces/user.interface';
import { IAirline } from 'src/app/interfaces/airline.interface';
import { IPipedriveOrganization, IPipedrivePerson } from 'src/app/interfaces/pipedrive.interface';
import { IAircraftCompiled } from 'src/app/interfaces/aircraft-compiled.interface';
import { getContactTitle, IContact } from 'src/app/interfaces/contact.interface';
import { IAirport } from 'src/app/interfaces/airport.interface';
import { IEmailTemplate } from 'src/app/interfaces/email-template.interface';
import { EnumLanguage } from 'src/app/enums/language.enum';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs/breadcrumbs.service';

@Component({
  selector: 'app-relance-airline',
  templateUrl: './relance-airline.component.html',
  styleUrls: ['./relance-airline.component.scss']
})
export class RelanceAirlineComponent implements OnInit, OnDestroy {
  getEnumEnquiryTypeLabel = getEnumEnquiryTypeLabel;
  getEnquiryBreadcrumbTitle = getEnquiryBreadcrumbTitle;
  getDisplayedEnquiryRefTitle = getDisplayedEnquiryRefTitle;
  getContactTitle = getContactTitle;

  EnumEnquiryType = EnumEnquiryType;

  loading: boolean = false;
  enquiryId: string;
  enquiry: IEnquiry;
  enquiryOffers: IEnquiryOffer[] = [];
  airlineId: string;
  isLogged: boolean = false;
  sending: boolean = false;
  client: IPipedriveOrganization | null = null;
  contact: IPipedrivePerson | null = null;
  aircraftsCompiled: IAircraftCompiled[] = [];
  currentAirlineContacts: IContact[] = [];
  airlines: IAirline[] = [];
  currentAirlineIndex: number = 0;
  form: FormGroup;
  currentUser: IUser;
  airportsObj: { [key: string]: IAirport | null } = {};
  airportsToLoad: string[] = [];
  emailTemplateIds: { [key: string]: { [key: string]: string } } = {
    default: {
      fr: '2YDidANlydNzbq5ROdlV',
      en: 'yJIPQ5lswrktCNoXZ40W'
    },
    sport: {
      fr: '4qaGU0wuUDB4B0wrekHQ',
      en: 'SfywXU5ExP1oxNGVoST5'
    },
    cargo: {
      fr: 'crW2qO1IqkZpysygy0EC',
      en: 'WJcJ2eUvhuqvWw2oKsh6'
    }
  };

  subscriptions = new Subscription();

  constructor(
    private remoteService: RemoteService,
    private pipedriveService: PipedriveService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private aclService: AclService,
    private enquiryService: EnquiryService,
    private enquiryOfferService: EnquiryOfferService,
    private breadcrumbsService: BreadcrumbsService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
    this.remoteService.userObservable.subscribe((user: IUser) => {
      if (user) {
        this.currentUser = user;
      }
    });
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      enquiryId: ['', [Validators.required]],
      airlineId: ['', [Validators.required]],
      subject: ['', [Validators.required]],
      email: ['', [Validators.required]],
      cc: [''],
      cci: [''],
      messageHtml: ['', [Validators.required]],
      language: ['', [Validators.required]],
      emailAttachments: new FormArray([])
    });

    this.form.disable();

    window['enquiryId'] = null;

    this.activatedRoute.url.subscribe(async () => {
      this.enquiryId = this.activatedRoute.snapshot.paramMap.get('enquiryId');
      this.airlineId = this.activatedRoute.snapshot.paramMap.get('airlineId');

      await this.aclService.checkAclAccess(EnumAcl.enquiriesRelanceAirline);

      this.loadData();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  loadData(): void {
    if (this.isLogged) {
      this.loadEnquiry();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  addEmailAttachment(): void {
    const emailAttachmentsFormArray = this.form.get('emailAttachments') as FormArray;

    emailAttachmentsFormArray.push(
      new FormGroup({
        id: new FormControl(this.remoteService.generateRandomId(), [Validators.required]),
        name: new FormControl('', [Validators.required]),
        url: new FormControl('', [Validators.required]),
        size: new FormControl(0),
        type: new FormControl('', [Validators.required])
      })
    );

    this.form.updateValueAndValidity();
  }

  loadEnquiry(): void {
    this.loading = true;

    this.subscriptions.add(
      this.enquiryService.getFromId(this.enquiryId).subscribe(async (enquiry: IEnquiry) => {
        this.enquiry = enquiry;

        if (this.enquiry) {
          this.breadcrumbsService.setCurrentItem({
            id: this.enquiry.id,
            text: getEnquiryBreadcrumbTitle(this.enquiry)
          });
        }

        this.loadEnquiryOffers();

        await this.loadClient();
        await this.loadAirportsOfEnquiry();

        this.loading = false;
      })
    );
  }

  loadEnquiryOffers(): void {
    this.subscriptions.add(
      this.enquiryOfferService
        .getAllForEnquiry(this.enquiryId)
        .subscribe(async (enquiryOffers: IEnquiryOffer[]) => {
          this.enquiryOffers = enquiryOffers;

          await this.fetchAircraftsCompiled();
        })
    );
  }

  async loadClient(): Promise<void> {
    if (this.enquiry.clientId) {
      this.loading = true;

      this.client = await this.pipedriveService.getOrganization(
        typeof this.enquiry.clientId === 'number'
          ? this.enquiry.clientId.toString()
          : this.enquiry.clientId
      );

      this.loading = false;
    }

    if (this.enquiry.contactId) {
      this.loading = true;

      this.contact = await this.pipedriveService.getPerson(this.enquiry.contactId);

      this.loading = false;
    }
  }

  async fetchAircraftsCompiled(): Promise<void> {
    if (this.enquiryOffers.length) {
      this.loading = true;

      const aircraftsCompiledId: string[] = [];
      for (const offer of this.enquiryOffers) {
        if (offer.status === EnumEnquiryOfferStatus.waitingForAnswer) {
          aircraftsCompiledId.push(offer.aircraftCompiledId);
        }
      }

      const docs = await this.remoteService.getDocumentsFromDocId(
        'aircraftsCompiled',
        aircraftsCompiledId
      );

      this.aircraftsCompiled = [];
      for (const doc of docs) {
        const aircraftCompiled: IAircraftCompiled = doc as IAircraftCompiled;

        this.aircraftsCompiled.push(aircraftCompiled);
      }

      await this.fetchAirlines();

      this.loading = false;
    }
  }

  async fetchAirlines(): Promise<void> {
    if (this.aircraftsCompiled && this.aircraftsCompiled.length) {
      this.loading = true;

      const airlinesId: string[] = [];
      for (const aircraftCompiled of this.aircraftsCompiled) {
        if (!airlinesId.includes(aircraftCompiled.airlineId)) {
          airlinesId.push(aircraftCompiled.airlineId);
        }
      }

      const docs = await this.remoteService.getDocumentsFromDocId('airlines', airlinesId);

      this.airlines = [];
      for (const doc of docs) {
        this.airlines.push(doc as IAirline);
      }

      if (this.airlineId) {
        for (const airlineIndex in this.airlines) {
          if (this.airlines[airlineIndex].id === this.airlineId) {
            this.airlines = [this.airlines[airlineIndex]];
            break;
          }
        }
      }

      await this.setCurrentAirline(this.currentAirlineIndex);

      await this.setMessageTemplate();

      this.form.enable();

      this.loading = false;
    }
  }

  getDatesForSubject(): string {
    const datesDepartForSubject: string[] = [];
    for (const itinerary of this.enquiry.itineraries) {
      for (const trip of itinerary.trips) {
        const date: string = trip.date;

        if (datesDepartForSubject.indexOf(date) === -1) {
          datesDepartForSubject.push(date);
        }
      }
    }
    datesDepartForSubject.sort();

    for (const i in datesDepartForSubject) {
      datesDepartForSubject[i] = moment(datesDepartForSubject[i])
        .format('ddd DD MMMM YYYY')
        .toUpperCase();
    }

    return datesDepartForSubject.join(' - ');
  }

  getAirportsCodeForSubject(): string {
    const airportsCode: string[] = [];
    for (const itinerary of this.enquiry.itineraries) {
      for (const trip of itinerary.trips) {
        for (const field of [
          'airportDepart',
          'airportDestination',
          'commercialStopOverAirport',
          'fuelStopOverAirport'
        ]) {
          if (airportsCode[airportsCode.length - 1] !== trip[field] && trip[field]) {
            airportsCode.push(this.airportsObj[trip[field]].iataCode);
          }
        }
      }
    }

    return airportsCode.join(' / ');
  }

  async setMessageTemplate(): Promise<void> {
    if (this.enquiry) {
      let language: EnumLanguage = EnumLanguage.en;

      if (
        this.airlines[this.currentAirlineIndex] &&
        this.airlines[this.currentAirlineIndex].countryCode === 'FR'
      ) {
        language = EnumLanguage.fr;
      } else {
        language = EnumLanguage.en;
      }

      this.form.get('language').setValue(language);
      moment.locale(language);

      const emailTemplate: IEmailTemplate = await this.getEmailTemplate(
        this.enquiry.type,
        language
      );

      let subject: string = this.setEmailVariable(emailTemplate.subject, language);

      let html: string = this.setEmailVariable(emailTemplate.messageHtml, language);

      this.form.get('cc').setValue(this.currentUser.emailInCc ? this.currentUser.email : '');
      this.form.get('cci').setValue('backup2@flyaa.eu');
      this.form.get('subject').setValue(subject);
      this.form.get('messageHtml').setValue(html);
    }
  }

  async getEmailTemplate(
    type: string = 'default',
    language: EnumLanguage = EnumLanguage.en
  ): Promise<IEmailTemplate> {
    try {
      if (!this.emailTemplateIds[type]) {
        type = 'default';
      }

      const doc: object = await this.remoteService.getDocument(
        'emailTemplates',
        this.emailTemplateIds[type][language]
      );

      if (doc) {
        return doc as IEmailTemplate;
      }
    } catch (err) {
      alert(err.message);
    }
  }

  setEmailVariable(text: string, language: EnumLanguage = EnumLanguage.en): string {
    const variables: object = {
      'enquiry.ref': getDisplayedEnquiryRefTitle(this.enquiry, 'refEnquiry'),
      'enquiry.routing': this.getAirportsCodeForSubject(),
      'enquiry.dates': this.getDatesForSubject(),
      'airline.name': this.airlines[this.currentAirlineIndex]?.title,
      'enquiry.details': '',
      'user.emailSignature': this.currentUser.emailSignatureHtml
    };

    switch (language) {
      case EnumLanguage.fr:
        for (const itineraryIndex in this.enquiry.itineraries) {
          if (this.enquiry.itineraries.length > 1) {
            variables['enquiry.details'] +=
              '<strong>OPTION ' + (parseInt(itineraryIndex) + 1) + '</strong><br>';
          }

          for (let i = 0; i < this.enquiry.itineraries[itineraryIndex].trips.length; i++) {
            const trip = this.enquiry.itineraries[itineraryIndex].trips[i];
            const isLastTrip: boolean =
              i === this.enquiry.itineraries[itineraryIndex].trips.length - 1;

            variables['enquiry.details'] += moment(trip.date)
              .format('ddd DD MMMM YYYY')
              .toUpperCase();
            variables['enquiry.details'] += '&nbsp;&nbsp;&nbsp;&nbsp;';
            variables['enquiry.details'] += '&nbsp;&nbsp;&nbsp;&nbsp;';
            variables['enquiry.details'] += '&nbsp;&nbsp;&nbsp;&nbsp;';

            variables['enquiry.details'] += this.airportsObj[trip.airportDepart].iataCode;

            if (trip.hasCommercialStopOver) {
              variables['enquiry.details'] +=
                ' / STOP ' + this.airportsObj[trip.commercialStopOverAirport].iataCode + ' - ';
            }

            variables['enquiry.details'] += ' / ';

            variables['enquiry.details'] += this.airportsObj[trip.airportDestination].iataCode;

            variables['enquiry.details'] += '&nbsp;&nbsp;&nbsp;&nbsp;';
            if (trip.time) {
              variables['enquiry.details'] += 'DEP ' + trip.time + ' local';
              variables['enquiry.details'] += '&nbsp;&nbsp;&nbsp;&nbsp;';
            }

            if (trip.passengersTotal) {
              variables['enquiry.details'] += trip.passengersTotal + 'Y';

              if (trip.hasCommercialStopOver) {
                variables['enquiry.details'] +=
                  ' / STOP ' + Math.abs(trip.passengersCommercialStop) + 'Y - ';
                variables['enquiry.details'] +=
                  trip.passengersTotal + trip.passengersCommercialStop;
                variables['enquiry.details'] += 'Y';
              }

              if (trip.passengersBusiness) {
                variables['enquiry.details'] +=
                  '&nbsp;&nbsp;&nbsp;&nbsp; inclus ' + trip.passengersBusiness + 'C';
              }
            }

            if (this.enquiry.type === EnumEnquiryType.cargo) {
              variables['enquiry.details'] += '<br>';
              variables['enquiry.details'] += 'Cargo : ';
              variables['enquiry.details'] += trip.natureFret ? trip.natureFret : 'Inconnu';
              variables['enquiry.details'] += '<br>';

              if (trip.dimensions) {
                variables['enquiry.details'] += 'Dimensions : ' + trip.dimensions;
                variables['enquiry.details'] += '<br>';
              }
              if (trip.weight) {
                variables['enquiry.details'] += 'Poids : ' + trip.weight + ' kg';
                variables['enquiry.details'] += '<br>';
              }
              if (trip.volume) {
                variables['enquiry.details'] += 'Volume : ' + trip.volume + ' m3';
              }
              variables['enquiry.details'] += '<br>';
            }

            variables['enquiry.details'] += '<br>';
          }

          if (parseInt(itineraryIndex) < this.enquiry.itineraries.length - 1) {
            variables['enquiry.details'] += '<br>';
            variables['enquiry.details'] += 'OU' + '<br>';
            variables['enquiry.details'] += '<br>';
          }
        }

        if (this.enquiry.noteForAirlines && this.enquiry.noteForAirlines !== '') {
          variables['enquiry.details'] += '<br>';
          variables['enquiry.details'] += this.enquiry.noteForAirlines.replace(
            new RegExp('\r?\n', 'g'),
            '<br>'
          );
          variables['enquiry.details'] += '<br>';
        }

        if (this.enquiry.emailAttachments.length) {
          variables['enquiry.details'] += '<br><strong>Pièces-jointes :</strong><br>';
          variables['enquiry.details'] += this.getEnquiryEmailAttachements(language);
        }
        break;
      case EnumLanguage.en:
      default:
        for (const itineraryIndex in this.enquiry.itineraries) {
          if (this.enquiry.itineraries.length > 1) {
            variables['enquiry.details'] +=
              '<strong>OPTION ' + (parseInt(itineraryIndex) + 1) + '</strong><br>';
          }

          for (let i = 0; i < this.enquiry.itineraries[itineraryIndex].trips.length; i++) {
            const trip = this.enquiry.itineraries[itineraryIndex].trips[i];
            const isLastTrip: boolean =
              i === this.enquiry.itineraries[itineraryIndex].trips.length - 1;

            variables['enquiry.details'] += moment(trip.date)
              .format('ddd DD MMMM YYYY')
              .toUpperCase();
            variables['enquiry.details'] += '&nbsp;&nbsp;&nbsp;&nbsp;';
            variables['enquiry.details'] += '&nbsp;&nbsp;&nbsp;&nbsp;';
            variables['enquiry.details'] += '&nbsp;&nbsp;&nbsp;&nbsp;';

            variables['enquiry.details'] += this.airportsObj[trip.airportDepart].iataCode;

            if (trip.hasCommercialStopOver) {
              variables['enquiry.details'] +=
                ' / STOP ' + this.airportsObj[trip.commercialStopOverAirport].iataCode + ' - ';
            }

            variables['enquiry.details'] += ' / ';

            variables['enquiry.details'] += this.airportsObj[trip.airportDestination].iataCode;

            variables['enquiry.details'] += '&nbsp;&nbsp;&nbsp;&nbsp;';
            if (trip.time) {
              variables['enquiry.details'] += 'DEP ' + trip.time + ' local';
              variables['enquiry.details'] += '&nbsp;&nbsp;&nbsp;&nbsp;';
            }

            if (trip.passengersTotal) {
              variables['enquiry.details'] += trip.passengersTotal + 'Y';

              if (trip.hasCommercialStopOver) {
                variables['enquiry.details'] +=
                  ' / STOP ' + Math.abs(trip.passengersCommercialStop) + 'Y - ';
                variables['enquiry.details'] +=
                  trip.passengersTotal + trip.passengersCommercialStop;
                variables['enquiry.details'] += 'Y';
              }

              if (trip.passengersBusiness) {
                variables['enquiry.details'] +=
                  '&nbsp;&nbsp;&nbsp;&nbsp; including ' + trip.passengersBusiness + 'C';
              }
            }

            if (this.enquiry.type === EnumEnquiryType.cargo) {
              variables['enquiry.details'] += '<br>';
              variables['enquiry.details'] += 'Cargo : ';
              variables['enquiry.details'] += trip.natureFret ? trip.natureFret : 'Unknown';
              variables['enquiry.details'] += '<br>';

              if (trip.dimensions) {
                variables['enquiry.details'] += 'Dimensions : ' + trip.dimensions;
                variables['enquiry.details'] += '<br>';
              }
              if (trip.weight) {
                variables['enquiry.details'] += 'Weight : ' + trip.weight + ' kg';
                variables['enquiry.details'] += '<br>';
              }
              if (trip.volume) {
                variables['enquiry.details'] += 'Volume : ' + trip.volume + ' m3';
              }
              variables['enquiry.details'] += '<br>';
            }

            variables['enquiry.details'] += '<br>';
          }

          if (parseInt(itineraryIndex) < this.enquiry.itineraries.length - 1) {
            variables['enquiry.details'] += '<br>';
            variables['enquiry.details'] += 'OR' + '<br>';
            variables['enquiry.details'] += '<br>';
          }
        }

        if (this.enquiry.noteForAirlines && this.enquiry.noteForAirlines !== '') {
          variables['enquiry.details'] += '<br>';
          variables['enquiry.details'] += this.enquiry.noteForAirlines.replace(
            new RegExp('\r?\n', 'g'),
            '<br>'
          );
          variables['enquiry.details'] += '<br>';
        }

        if (this.enquiry.emailAttachments.length) {
          variables['enquiry.details'] += '<br><strong>Attachments :</strong><br>';
          variables['enquiry.details'] += this.getEnquiryEmailAttachements(language);
        }
        break;
    }

    for (const variable in variables) {
      text = text.replace(new RegExp('{{' + variable + '}}', 'g'), variables[variable]);
    }

    return text;
  }

  async setCurrentAirline(index: number): Promise<void> {
    this.currentAirlineIndex = index;

    if (this.airlines[this.currentAirlineIndex]) {
      await this.resetForm();

      this.currentAirlineContacts = [];

      for (const companyContact of this.airlines[this.currentAirlineIndex].companyContacts) {
        for (const contact of companyContact.contacts) {
          if (contact.email) {
            if (!this.form.value.email) {
              this.form.get('email').setValue(contact.email);
            }

            this.currentAirlineContacts.push(contact);
          }
        }
      }
    }
  }

  async resetForm(): Promise<void> {
    this.form.reset();

    if (this.airlines.length) {
      this.form.get('enquiryId').setValue(this.enquiryId);
      this.form.get('airlineId').setValue(this.airlines[this.currentAirlineIndex].id);

      await this.setMessageTemplate();

      this.addEmailAttachmentsToForm();
    }
  }

  addEmailAttachmentsToForm(): void {
    const language: string = this.form.value.language;

    const emailAttachmentsFormArray = this.form.get('emailAttachments') as FormArray;

    while (emailAttachmentsFormArray.length !== 0) {
      emailAttachmentsFormArray.removeAt(0);
    }

    for (const emailAttachment of this.enquiry.emailAttachments) {
      if (!emailAttachment.language || language === emailAttachment.language) {
        this.addEmailAttachment();

        for (const field of ['id', 'name', 'type', 'size', 'url']) {
          emailAttachmentsFormArray
            .at(emailAttachmentsFormArray.controls.length - 1)
            .get(field)
            .setValue(emailAttachment[field]);
        }
      }
    }
  }

  async sendMessageToCompany(): Promise<void> {
    this.form.markAsTouched();

    if (this.form.valid && this.form.value.email) {
      await this.emailCompany();
    } else if (!this.form.value.email) {
      alert(
        "Impossible d'envoyer un message car la compagnie aérienne n'a aucun contact avec un email, veuillez en ajouter un pour pouvoir continuer."
      );
    }
  }

  ignoreAndGoNext(): void {
    if (this.currentAirlineIndex < this.airlines.length - 1) {
      this.setCurrentAirline(this.currentAirlineIndex + 1);
      this.form.enable();

      window.scrollTo(0, 0);
    } else {
      alert('Toutes les compagnies aériennes sélectionnées ont été traitées.');
      this.router.navigate(['/admin/enquiries/' + this.enquiryId]);
    }
  }

  async emailCompany(): Promise<void> {
    this.sending = true;

    this.form.disable();

    const data: {
      subject: string;
      content: string;
      contentHtml: string;
      replyTo: {
        email: string;
        name: string;
      };
      to: {
        email: string;
        name: string;
      };
      cc: string[];
      cci: string[];
      params: {
        enquiryId: string;
        airlineId: string;
        contactedBy: string;
        language: string;
      };
      sent: boolean;
      emailAttachments: {
        id: string;
        name: string;
        type: string;
        size: number;
        url: string;
      }[];
    } = {
      subject: this.form.value.subject,
      content: this.remoteService.convertHtmlToPlainText(this.form.value.messageHtml),
      contentHtml: this.form.value.messageHtml,
      replyTo: {
        email: this.currentUser.email,
        name: this.currentUser.firstname + ' ' + this.currentUser.lastname
      },
      to: {
        email: this.form.value.email,
        name: null
      },
      cc: this.form.value.cc.split(';'),
      cci: this.form.value.cci.split(';'),
      params: {
        enquiryId: this.form.value.enquiryId,
        airlineId: this.form.value.airlineId,
        contactedBy: this.currentUser.id,
        language: this.form.value.language
      },
      sent: false,
      emailAttachments: this.form.value.emailAttachments
    };

    try {
      const docId: string = await this.remoteService.addDocumentToCollection('emailPool', data);

      if (docId) {
        for (const offer of this.enquiryOffers) {
          if (offer.aircraftCompiled.airlineId === data.params.airlineId) {
            await this.enquiryOfferService.update({
              id: offer.id,
              status: EnumEnquiryOfferStatus.waitingForAnswer,
              followedUp: true
            } as IEnquiryOffer);
          }
        }

        this.sending = false;

        this.ignoreAndGoNext();
      }
    } catch (err) {
      console.log(err);
      alert(err.message);

      this.sending = false;
    }
  }

  getEnquiryEmailAttachements(language: EnumLanguage = EnumLanguage.en): string {
    let html = '';

    if (this.enquiry.emailAttachments) {
      const links: string[] = [];
      for (const emailAttachments of this.enquiry.emailAttachments) {
        links.push("<a href='" + emailAttachments.url + "'>" + emailAttachments.name + '</a>');
      }

      html += links.join('<br>');
    }

    return html;
  }

  reviver(key: string, value: string): any {
    if (
      ['selectedDatetime', 'contactDatetime', 'created', 'modified'].indexOf(key) !== -1 &&
      !isNaN(Date.parse(value))
    ) {
      return new Date(value);
    }

    return value;
  }

  formatPrice(value: number): string {
    const formatter = new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2
    });

    return formatter.format(value);
  }

  addAirlineContacts(airlineId: string): void {
    window['enquiryId'] = this.enquiry.id;

    this.router.navigate(['/admin/airlines/' + airlineId + '/edit']);
  }

  async loadAirportsOfEnquiry(): Promise<void> {
    for (const itinerary of this.enquiry.itineraries) {
      for (const trip of itinerary.trips) {
        for (const field of [
          'airportDepart',
          'airportDestination',
          'commercialStopOverAirport',
          'fuelStopOverAirport'
        ]) {
          if (trip[field]) {
            this.addAirportToLoad(trip[field]);
          }
        }
      }
    }

    await this.loadAirports();
  }

  addAirportToLoad(airportId: string) {
    if (
      this.airportsToLoad.indexOf(airportId) === -1 &&
      typeof this.airportsObj[airportId] === 'undefined'
    ) {
      this.airportsToLoad.push(airportId);
    }
  }

  async loadAirports(): Promise<void> {
    if (this.airportsToLoad.length) {
      const docs: object[] = await this.remoteService.getDocumentsFromDocId(
        'airports',
        this.airportsToLoad
      );

      for (const doc of docs) {
        const airport = doc as IAirport;

        this.airportsObj[airport.id] = airport;
      }

      this.airportsToLoad = [];
    }
  }
}
