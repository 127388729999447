<div class="row mb-4" *ngIf="loading">
    <div class="col-lg-3 order-lg-last">
        <div class="skeleton-text"></div>

        <h4>Détails vol</h4>
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-sm col-lg-12">
                        <div class="skeleton-text"></div>
                        <div class="skeleton-text"></div>
                        <div class="skeleton-text"></div>
                        <div class="skeleton-text"></div>
                    </div>

                    <div class="col-sm col-lg-12">
                        <div class="skeleton-text"></div>
                        <div class="skeleton-text"></div>
                        <div class="skeleton-text"></div>
                        <div class="skeleton-text"></div>
                    </div>

                    <div class="col-md col-lg-12">
                        <div class="skeleton-text"></div>
                        <div class="skeleton-text"></div>
                        <div class="skeleton-text"></div>
                        <div class="skeleton-text"></div>
                        <div class="skeleton-text"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg">
        <div class="row mb-4">
            <div class="col-sm-4"><div class="skeleton-text"></div></div>
            <div class="col-sm-4"><div class="skeleton-text"></div></div>
        </div>

        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <label for="cc">Cc (email en copie)</label>
                    <input class="form-control" id="cc" name="cc" type="text" disabled />
                    <p class="text-muted">Séparé les emails par un ;</p>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label for="cci">Cci (email en copie caché)</label>
                    <input class="form-control" id="cci" name="cci" type="text" disabled />
                    <p class="text-muted">Séparé les emails par un ;</p>
                </div>
            </div>
        </div>

        <div class="form-group">
            <label for="subject">Sujet</label>
            <input class="form-control" id="subject" name="subject" type="text" disabled />
        </div>

        <div class="form-group">
            <label for="message">Contenu</label>
            <textarea
                class="form-control"
                id="message"
                name="message"
                rows="15"
                disabled
            ></textarea>
        </div>
    </div>
</div>

<div class="row mb-4" *ngIf="!loading && enquiry">
    <div class="col-lg-3 order-lg-last">
        <div class="card text-center" *ngIf="enquiry">
            <div class="card-body">
                <h4 *ngIf="enquiry.refContractTitle">
                    <strong
                        [innerHtml]="
                            'N° contrat <br>' + getDisplayedEnquiryRefTitle(enquiry, 'refContract')
                        "
                    ></strong>
                </h4>
                <h4
                    [innerHtml]="
                        'N° demande <br>' + getDisplayedEnquiryRefTitle(enquiry, 'refEnquiry')
                    "
                    *ngIf="enquiry.refEnquiryTitle"
                ></h4>
            </div>
        </div>

        <h4>Détails vol</h4>
        <div class="card">
            <div class="card-body">
                <app-enquiry-card [enquiry]="enquiry"></app-enquiry-card>
            </div>
        </div>
    </div>
    <div class="col-lg" *ngIf="!airlines.length">
        <div class="alert alert-info text-center">
            Il n'y a aucune compagnie aérienne à relancer.<br />
            <br />
            <a [routerLink]="'/admin/enquiries/' + enquiryId" class="btn btn-primary mb-1"
                >Aller au suivi des cotations</a
            >
        </div>
    </div>
    <div class="col-lg" *ngIf="airlines.length">
        <form [formGroup]="form" class="main-form">
            <p class="text-muted text-center mb-4">
                {{ currentAirlineIndex + 1 }} / {{ airlines.length }} compagnies aériennes à
                relancer
            </p>
            <div class="row mb-4">
                <div class="col-md-5">
                    <a
                        [routerLink]="'/admin/airlines/' + airlines[currentAirlineIndex].id"
                        *ngIf="airlines[currentAirlineIndex]"
                        >{{ airlines[currentAirlineIndex].title }}</a
                    >
                </div>
                <div class="col-md-7">
                    <div class="form-group">
                        <div
                            class="alert alert-danger text-center"
                            *ngIf="!currentAirlineContacts.length"
                        >
                            La compagnie aérienne n'a aucun contact avec un email, veuillez en
                            ajouter un pour pouvoir la contacter.<br />
                            <br />
                            <button
                                type="button"
                                class="btn btn-primary"
                                (click)="addAirlineContacts(airlines[currentAirlineIndex].id)"
                            >
                                Ajouter une adresse email
                            </button>
                        </div>
                        <select
                            class="form-select"
                            id="email"
                            formControlName="email"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['email'].touched) &&
                                form.controls['email'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                            *ngIf="currentAirlineContacts.length"
                        >
                            <option
                                [value]="contact.email"
                                *ngFor="let contact of currentAirlineContacts"
                                [innerHtml]="
                                    getContactTitle(contact)
                                        ? contact.email + ' (' + getContactTitle(contact) + ')'
                                        : contact.email
                                "
                            ></option>
                        </select>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['email'].touched) &&
                                    form.controls['email'].errors &&
                                    form.controls['email'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label for="cc">Cc (email en copie)</label>
                        <input
                            class="form-control"
                            id="cc"
                            formControlName="cc"
                            type="text"
                            [readonly]="!currentAirlineContacts.length"
                        />
                    </div>
                    <p class="text-muted">Séparer les emails par un ;</p>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label for="cci">Cci (email en copie caché)</label>
                        <input
                            class="form-control"
                            id="cci"
                            formControlName="cci"
                            type="text"
                            [readonly]="!currentAirlineContacts.length"
                        />
                    </div>
                    <p class="text-muted">Séparer les emails par un ;</p>
                </div>
            </div>

            <div class="form-group">
                <label for="subject">Sujet</label>
                <input
                    class="form-control"
                    id="subject"
                    formControlName="subject"
                    type="text"
                    required
                    [class.is-invalid]="
                        (form.touched || form.controls['subject'].touched) &&
                        form.controls['subject'].status == 'INVALID'
                            ? 'danger'
                            : ''
                    "
                    [readonly]="!currentAirlineContacts.length"
                    maxlength="255"
                />

                <div class="invalid-tooltip">
                    <div
                        *ngIf="
                            (form.touched || form.controls['subject'].touched) &&
                            form.controls['subject'].errors &&
                            form.controls['subject'].errors['required']
                        "
                    >
                        Ce champ est obligatoire.
                    </div>
                    <div
                        *ngIf="
                            (form.touched || form.controls['subject'].touched) &&
                            form.controls['subject'].errors &&
                            form.controls['subject'].errors['maxlength']
                        "
                    >
                        Ce champ doit être maximum 255 caractères.
                    </div>
                </div>
            </div>

            <div class="form-group">
                <label for="message">Contenu</label>
                <quill-editor
                    formControlName="messageHtml"
                    [readOnly]="!currentAirlineContacts.length"
                ></quill-editor>
            </div>

            <div class="row">
                <div class="col-md">
                    <button
                        type="button"
                        class="btn btn-primary btn-block mb-2"
                        (click)="ignoreAndGoNext()"
                    >
                        Ignorer et passer au suivant
                    </button>
                </div>
                <div class="col-md">
                    <button
                        type="button"
                        class="btn btn-primary btn-block mb-2"
                        (click)="sendMessageToCompany()"
                        [disabled]="!currentAirlineContacts.length"
                    >
                        Envoyer l'email
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
