import { Component, OnInit, NgZone, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { PaginationService } from '../../../services/pagination.service';
import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { Subscription } from 'rxjs';
import { EmployeeBonusRulesService } from 'src/app/services/employee-bonus-rules/employee-bonus-rules.service';
import {
  IEmployeeBonusRule,
  getEmployeeBonusRuleDepartmentList
} from 'src/app/interfaces/employee-bonus-rule.interface';
import { formatPrice } from 'src/app/misc.utils';
import { getDefaultCurrency } from 'src/app/enums/currency.enum';

@Component({
  selector: 'app-employee-bonus-rule-view',
  templateUrl: './employee-bonus-rule-view.component.html',
  styleUrls: ['./employee-bonus-rule-view.component.scss']
})
export class EmployeeBonusRuleViewComponent implements OnInit, OnDestroy {
  EnumAcl = EnumAcl;

  getEmployeeBonusRuleDepartmentList = getEmployeeBonusRuleDepartmentList;
  formatPrice = formatPrice;
  getDefaultCurrency = getDefaultCurrency;

  loading: boolean = false;
  employeeBonusRuleId: string;
  employeeBonusRule: IEmployeeBonusRule;
  isLogged: boolean = false;

  subscriptions = new Subscription();

  constructor(
    private remoteService: RemoteService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public paginationService: PaginationService,
    private aclService: AclService,
    private employeeBonusRulesService: EmployeeBonusRulesService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
  }

  ngOnInit(): void {
    this.activatedRoute.url.subscribe(() => {
      this.employeeBonusRuleId = this.activatedRoute.snapshot.paramMap.get('employeeBonusRuleId');

      this.loadData();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  loadData(): void {
    if (this.isLogged) {
      this.loadEmployeeBonusRule();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  loadEmployeeBonusRule(): void {
    this.loading = true;

    this.subscriptions.add(
      this.employeeBonusRulesService
        .getFromId(this.employeeBonusRuleId)
        .subscribe((employeeBonusRule: IEmployeeBonusRule) => {
          this.employeeBonusRule = employeeBonusRule;

          this.loading = false;
        })
    );
  }

  delete(): void {
    if (this.hasAclAccess(EnumAcl.employeeBonusRulesDelete)) {
      const result = confirm('La suppression sera permanente. Êtes-vous sûr de vouloir continuer?');

      if (result) {
        this.employeeBonusRulesService
          .delete(this.employeeBonusRuleId)
          .then(() => {
            this.router.navigate(['/admin/employee-bonus-rules']);

            alert('La suppression a été effectuée avec succès.');
          })
          .catch(err => {
            console.log(err);
          });
      }
    }
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }
}
