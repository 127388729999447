<form (submit)="submitForm()" [formGroup]="form" (keydown.enter)="submitForm()" class="main-form">
    <div [class]="inModal ? 'modal-body' : 'row justify-content-center'">
        <div [class.col-md-6]="!inModal">
            <div [class.card]="!inModal">
                <div [class.card-body]="!inModal">
                    <div class="form-group">
                        <label for="airportId">Aéroport</label>
                        <app-airport-picker
                            [inputName]="'airportId'"
                            [defaultValue]="airportId.value"
                            (setValueToFormControl)="setValueToFormControl($event)"
                            [isInvalid]="
                                (form.touched || airportId.touched) && airportId.status == 'INVALID'
                            "
                            [disabled]="form.disabled || sending || defaultAirportId"
                        ></app-airport-picker>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || airportId.touched) &&
                                    airportId.errors &&
                                    airportId.errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <ng-container *ngIf="airportId.value">
                        <div class="form-group">
                            <label for="name">Nom</label>
                            <input
                                class="form-control"
                                id="name"
                                formControlName="name"
                                type="text"
                                [class.is-invalid]="
                                    (form.touched || name.touched) && name.status == 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched || name.touched) &&
                                        name.errors &&
                                        name.errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="phoneNumber">Numéro de téléphone</label>
                            <input
                                class="form-control"
                                id="phoneNumber"
                                formControlName="phoneNumber"
                                type="tel"
                                [class.is-invalid]="
                                    (form.touched || phoneNumber.touched) &&
                                    phoneNumber.status == 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched || phoneNumber.touched) &&
                                        phoneNumber.errors &&
                                        phoneNumber.errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="street">Rue</label>
                            <input
                                class="form-control"
                                id="street"
                                formControlName="street"
                                type="text"
                                [class.is-invalid]="
                                    (form.touched || street.touched) && street.status == 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched || street.touched) &&
                                        street.errors &&
                                        street.errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="street">Ville</label>
                            <input
                                class="form-control"
                                id="city"
                                formControlName="city"
                                type="text"
                                [class.is-invalid]="
                                    (form.touched || city.touched) && city.status == 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched || city.touched) &&
                                        city.errors &&
                                        city.errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="postalCode">Code postal</label>
                            <input
                                class="form-control"
                                id="postalCode"
                                formControlName="postalCode"
                                type="text"
                                [class.is-invalid]="
                                    (form.touched || postalCode.touched) &&
                                    postalCode.status == 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched || postalCode.touched) &&
                                        postalCode.errors &&
                                        postalCode.errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="countryCode">Pays</label>
                            <autocomplete-input
                                (setValueToFormControl)="setValueToFormControl($event)"
                                inputName="countryCode"
                                [defaultValue]="form.value['countryCode']"
                                [itineraries]="countriesList"
                                [disabled]="form.disabled || sending || !countriesList.length"
                                *ngIf="countriesList.length"
                            ></autocomplete-input>

                            <div
                                *ngIf="
                                    (form.touched || form.controls['countryCode'].touched) &&
                                    form.controls['countryCode'].errors &&
                                    form.controls['countryCode'].errors['required']
                                "
                                class="text-danger"
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="accessPlanUrl">URL Plan d'accès</label>
                            <input
                                class="form-control"
                                id="accessPlanUrl"
                                formControlName="accessPlanUrl"
                                type="url"
                                [class.is-invalid]="
                                    (form.touched || accessPlanUrl.touched) &&
                                    accessPlanUrl.status == 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched || accessPlanUrl.touched) &&
                                        accessPlanUrl.errors &&
                                        accessPlanUrl.errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-center text-center mt-4" *ngIf="!inModal">
        <div class="col-md-4">
            <button
                type="button"
                [routerLink]="fboId ? '/admin/fbos/' + fboId : '/admin/fbos'"
                class="btn btn-secondary btn-block mb-4"
                [disabled]="form.disabled || sending"
            >
                Annuler
            </button>
        </div>
        <div class="col-md-4">
            <button
                type="submit"
                class="btn btn-primary btn-block mb-4"
                [innerHtml]="sending ? 'Sauvegarde ...' : 'Sauvegarder'"
                [disabled]="form.disabled || !form.valid || sending"
            ></button>
        </div>
    </div>
    <div class="modal-footer" *ngIf="inModal">
        <button type="button" class="btn btn-secondary" (click)="closeModal()">Annuler</button>
        <button type="button" class="btn btn-primary" (click)="submitForm()">Sauvegarder</button>
    </div>
</form>
