<div class="btn-toolbar justify-content-center mb-4">
    <div class="btn-group">
        <a
            [routerLink]="'/admin/handlings/' + handlingId + '/edit'"
            class="btn btn-primary mr-4"
            *ngIf="hasAclAccess(EnumAcl.handlingsEdit)"
            >Éditer</a
        >
        <button
            type="button"
            (click)="delete()"
            class="btn btn-danger ml-4"
            *ngIf="hasAclAccess(EnumAcl.handlingsDelete)"
        >
            Supprimer
        </button>
    </div>
</div>

<div class="row justify-content-center">
    <div class="col-md-8">
        <div class="card mb-4">
            <div class="card-body" *ngIf="loading">
                <h3><div class="placeholder w-100"></div></h3>
            </div>
            <div class="card-body" *ngIf="!loading && handling">
                <h3 [innerHtml]="handling.name" class="text-center"></h3>
                <dl class="row mb-0">
                    <dt class="col-md-6">Aéroport</dt>
                    <dd class="col-md-6">
                        <a
                            [routerLink]="'/admin/airports/' + handling.airportId"
                            [innerText]="airportsObj[handling.airportId].title"
                            *ngIf="airportsObj[handling.airportId]"
                        ></a>
                        <div
                            class="placeholder w-100"
                            *ngIf="!airportsObj[handling.airportId]"
                        ></div>
                    </dd>
                    <dt class="col-md-6">Numéro de téléphone</dt>
                    <dd
                        class="col-md-6"
                        *ngIf="handling.phoneNumber"
                        [innerText]="handling.phoneNumber"
                    ></dd>
                    <dd class="col-md-6 text-muted" *ngIf="!handling.phoneNumber">Vide</dd>
                    <dt class="col-md-6">Email</dt>
                    <dd class="col-md-6" *ngIf="handling.email" [innerText]="handling.email"></dd>
                    <dd class="col-md-6 text-muted" *ngIf="!handling.email">Vide</dd>
                    <dt class="col-md-6">Adresse</dt>
                    <dd class="col-md-6">{{ getHandlingFullAddress(handling) }}</dd>
                </dl>
            </div>
        </div>
    </div>
</div>
