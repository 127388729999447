import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  addMonths,
  addYears,
  endOfYear,
  format,
  getQuarter,
  getYear,
  isAfter,
  startOfMonth
} from 'date-fns';
import { fr } from 'date-fns/locale';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { EnumAcl } from 'src/app/enums/acl.enum';
import {
  EnumEnquiryCancelledReason,
  getEnumEnquiryCancelledReasonLabel
} from 'src/app/enums/enquiry-cancelled-reason.enum';
import { EnumEnquiryStatus, getEnumEnquiryStatusLabel } from 'src/app/enums/enquiry-status.enum';
import { EnumEnquiryType, getEnumEnquiryTypeLabel } from 'src/app/enums/enquiry-type.enum';
import { IUserGroup } from 'src/app/interfaces/user-group.interface';
import { IUser, getUserFullname } from 'src/app/interfaces/user.interface';
import { AclService } from 'src/app/services/acl.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { RemoteService } from 'src/app/services/remote.service';
import { UserService } from 'src/app/services/user/user.service';

enum EnumLocalStorageVariable {
  status = 'simplyfly.dashboard.status',
  enquiryType = 'simplyfly.dashboard.enquiryType',
  userId = 'simplyfly.dashboard.userId',
  filterByPeriod = 'simplyfly.dashboard.filterByPeriod',
  filterByPeriodStart = 'simplyfly.dashboard.filterByPeriodStart',
  filterByPeriodEnd = 'simplyfly.dashboard.filterByPeriodEnd',
  cancelledReason = 'simplyfly.dashboard.cancelledReason'
}

@Component({
  selector: 'app-enquiries-list-with-filters',
  templateUrl: './enquiries-list-with-filters.component.html',
  styleUrls: ['./enquiries-list-with-filters.component.scss']
})
export class EnquiriesListWithFiltersComponent implements OnInit, OnDestroy {
  @Input('userId') userId: string | null = null;
  @Input('status') status: EnumEnquiryStatus | string = 'all';
  @Input('filterByPeriod') filterByPeriod: string | null = null;
  @Input('forceSettings') forceSettings: boolean = false;
  @Input('enquiryType') enquiryType: EnumEnquiryType | string = 'all';
  @Input('cancelledReason') cancelledReason: EnumEnquiryCancelledReason | string = 'all';

  EnumEnquiryStatus = EnumEnquiryStatus;
  EnumEnquiryType = EnumEnquiryType;
  EnumEnquiryCancelledReason = EnumEnquiryCancelledReason;
  EnumAcl = EnumAcl;

  getEnumEnquiryStatusLabel = getEnumEnquiryStatusLabel;
  getEnumEnquiryTypeLabel = getEnumEnquiryTypeLabel;
  getEnumEnquiryCancelledReasonLabel = getEnumEnquiryCancelledReasonLabel;

  filterByPersonOrDepartment: 'person' | 'department' = 'person';
  usersList: {
    title: string;
    value: string;
  }[] = [];
  usersObj: { [key: string]: IUser } = {};
  currentUser: IUser;
  currentPagination: string = 'enquiries-list';
  filtersByPeriod: {
    title: string;
    value: string;
  }[] = [];
  start: string;
  end: string;
  today: string;
  userGroup: IUserGroup | null = null;

  private subscriptions = new Subscription();

  constructor(
    private router: Router,
    public paginationService: PaginationService,
    private aclService: AclService,
    private remoteService: RemoteService,
    private userService: UserService
  ) {
    this.today = format(new Date(), 'yyyy-MM-dd');

    this.generateFilterPeriodOptions();
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.remoteService.userGroupObservable.subscribe((userGroup: IUserGroup | null) => {
        this.userGroup = userGroup;

        this.loadData();
      })
    );

    if (this.userId === null) {
      this.userId = this.getLocalVariable(EnumLocalStorageVariable.userId);
    }

    this.subscriptions.add(
      this.remoteService.userObservable.subscribe((user: IUser) => {
        this.currentUser = user;

        if (this.currentUser && !this.forceSettings && !this.userId) {
          this.userId = this.currentUser.id;
        }
      })
    );

    if (!this.forceSettings) {
      if (this.status === 'all') {
        this.status =
          (this.getLocalVariable(EnumLocalStorageVariable.status) as EnumEnquiryStatus) || 'all';
      }
      if (this.enquiryType === 'all') {
        this.enquiryType =
          (this.getLocalVariable(EnumLocalStorageVariable.enquiryType) as EnumEnquiryType) || 'all';
      }

      if (!this.enquiryType) {
        this.filterByPersonOrDepartment = 'person';
      }
      if (!this.filterByPeriod) {
        let period: string = this.getLocalVariable(EnumLocalStorageVariable.filterByPeriod);

        if (period) {
          const periodSplitted: string[] = period.split('-');

          if (periodSplitted.length === 2) {
            periodSplitted.push('01');
          }

          period = periodSplitted.join('-');

          this.filterByPeriod = period;
        } else {
          this.filterByPeriod = format(new Date(), 'yyyy-MM') + '-01';
        }
      }
    }

    this.start = this.getLocalVariable(EnumLocalStorageVariable.filterByPeriodStart);
    this.end = this.getLocalVariable(EnumLocalStorageVariable.filterByPeriodEnd);

    this.loadData();
  }

  ngOnDestroy(): void {
    window['$']('.table-responsive').floatingScroll('destroy');

    this.subscriptions.unsubscribe();
  }

  getEnquiryTypes(): EnumEnquiryType[] {
    return Object.values(EnumEnquiryType);
  }

  getEnquiryCancelledReason(): EnumEnquiryCancelledReason[] {
    return Object.values(EnumEnquiryCancelledReason);
  }

  async loadData(): Promise<void> {
    if (this.userGroup) {
      // await this.remoteService.parseAndSaveCompaniesAndAircraft()

      this.loadUsers();
      this.fetchEnquiries();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  getEnquiryStatuses(): EnumEnquiryStatus[] {
    return Object.values(EnumEnquiryStatus);
  }

  setValueToFormControl($event: {
    fields: {
      name: string;
      value: string;
    }[];
  }): void {
    for (let field of $event.fields) {
      const nameList = field.name.split('.');

      if (field.name === 'enquiryId') {
        this.router.navigate(['/admin/enquiries/' + field.value]);
      }
    }
  }

  userChanged(): void {
    this.setLocalVariable(EnumLocalStorageVariable.userId, this.userId);

    this.fetchEnquiries();
  }

  statusChanged(): void {
    this.setLocalVariable(EnumLocalStorageVariable.status, this.status);

    if (this.status === EnumEnquiryStatus.cancelled) {
      this.cancelledReason = 'all';
    } else {
      this.cancelledReason = null;
    }
    this.setLocalVariable(EnumLocalStorageVariable.cancelledReason, this.cancelledReason);

    this.fetchEnquiries();
  }

  enquiryTypeChanged(): void {
    this.setLocalVariable(EnumLocalStorageVariable.enquiryType, this.enquiryType);

    this.fetchEnquiries();
  }

  cancelledReasonChanged(): void {
    this.setLocalVariable(EnumLocalStorageVariable.cancelledReason, this.cancelledReason);

    this.fetchEnquiries();
  }

  customDatesChanged(): void {
    this.fetchEnquiries();
  }

  setLocalVariable(name: EnumLocalStorageVariable, value: string): void {
    window.localStorage.setItem(name, value);
  }

  getLocalVariable(name: EnumLocalStorageVariable): string {
    let val: string | null = window.localStorage.getItem(name);

    if (val === 'null') {
      val = null;
    }
    return val;
  }

  deleteLocalVariable(name: EnumLocalStorageVariable): void {
    window.localStorage.removeItem(name);
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  updateFilterPeriod(): void {
    if (!this.filterByPeriod) {
      this.start = null;
      this.end = null;
    } else if (this.filterByPeriod !== 'custom') {
      if (this.filterByPeriod.match('quarter')) {
        const filterByPeriodSplitted: string[] = this.filterByPeriod
          .replace('quarter-', '')
          .split('-');
        const year: number = parseInt(filterByPeriodSplitted[0]);
        const quarter: number = parseInt(filterByPeriodSplitted[1]);

        this.start = moment(year + '-' + this.addZeroToDigit((quarter - 1) * 3 + 1) + '-01').format(
          'YYYY-MM-DD'
        );
        this.end = moment(this.start).endOf('quarter').format('YYYY-MM-DD');
      } else {
        this.start = this.filterByPeriod;

        this.end = moment(this.start).endOf('month').format('YYYY-MM-DD');
      }
    }

    this.setLocalVariable(EnumLocalStorageVariable.filterByPeriod, this.filterByPeriod);
    this.setLocalVariable(EnumLocalStorageVariable.filterByPeriodStart, this.start);
    this.setLocalVariable(EnumLocalStorageVariable.filterByPeriodEnd, this.end);

    this.fetchEnquiries();
  }

  async fetchEnquiries(): Promise<void> {
    if (this.hasAclAccess(EnumAcl.enquiriesList)) {
      this.paginationService.reset(this.currentPagination);

      this.paginationService.pagination[this.currentPagination].conditions = [];

      if (this.userId && this.userId !== 'all') {
        this.paginationService.pagination[this.currentPagination].conditions.push({
          field: 'processedBy',
          operator: '==',
          value: this.userId
        });
      }

      if (this.status && this.status !== 'all') {
        this.paginationService.pagination[this.currentPagination].conditions.push({
          field: 'status',
          operator: '==',
          value: this.status
        });
      }

      if (this.enquiryType && this.enquiryType !== 'all') {
        this.filterByPersonOrDepartment = 'department';

        this.paginationService.pagination[this.currentPagination].conditions.push({
          field: 'type',
          operator: '==',
          value: this.enquiryType
        });
      }

      if (
        this.status === EnumEnquiryStatus.cancelled &&
        this.cancelledReason &&
        this.cancelledReason !== 'all'
      ) {
        this.paginationService.pagination[this.currentPagination].conditions.push({
          field: 'reasonCancelled.reason',
          operator: '==',
          value: this.cancelledReason
        });
      }

      const field: string =
        this.status === EnumEnquiryStatus.confirmed ? 'refContract' : 'refEnquiry';

      this.paginationService.pagination[this.currentPagination].orderBy.field =
        this.status === EnumEnquiryStatus.confirmed
          ? 'refContractTitleWithoutPrefix'
          : 'refEnquiryTitleWithoutPrefix';

      if (this.filterByPeriod) {
        const splittedFilterByPeriod: string[] = this.filterByPeriod.split('-');

        if (splittedFilterByPeriod[0] && splittedFilterByPeriod[0] === 'quarter') {
          this.paginationService.pagination[this.currentPagination].conditions.push({
            field: field + 'Year',
            operator: '==',
            value: parseInt(splittedFilterByPeriod[1]) - 2000 // We use the last 2 digits (ex: 21)
          });
          this.paginationService.pagination[this.currentPagination].conditions.push({
            field: field + 'Month',
            operator: 'in',
            value: [
              1 + (parseInt(splittedFilterByPeriod[2]) - 1) * 3,
              2 + (parseInt(splittedFilterByPeriod[2]) - 1) * 3,
              3 + (parseInt(splittedFilterByPeriod[2]) - 1) * 3
            ]
          });
        } else if (splittedFilterByPeriod[0] && splittedFilterByPeriod[0] === 'year') {
          this.paginationService.pagination[this.currentPagination].conditions.push({
            field: field + 'Year',
            operator: '==',
            value: parseInt(splittedFilterByPeriod[1]) - 2000 // We use the last 2 digits (ex: 21)
          });
        } else {
          if (splittedFilterByPeriod[0]) {
            this.paginationService.pagination[this.currentPagination].conditions.push({
              field: field + 'Year',
              operator: '==',
              value: parseInt(splittedFilterByPeriod[0]) - 2000 // We use the last 2 digits (ex: 21)
            });
          }
          if (splittedFilterByPeriod[1]) {
            this.paginationService.pagination[this.currentPagination].conditions.push({
              field: field + 'Month',
              operator: '==',
              value: parseInt(splittedFilterByPeriod[1])
            });
          }
        }
      }

      await this.paginationService.fetchData(this.currentPagination);

      this.sortEnquiries();

      setTimeout(() => {
        this.updateFloatingScroll();
      }, 1000);
    }
  }

  sortEnquiries(): void {
    this.paginationService.pagination[this.currentPagination].data.sort((a, b) => {
      if (this.status === EnumEnquiryStatus.confirmed) {
        return a.refContractTitleWithoutPrefix > b.refContractTitleWithoutPrefix ? -1 : 1;
      } else {
        return a.refEnquiryTitleWithoutPrefix > b.refEnquiryTitleWithoutPrefix ? -1 : 1;
      }
    });
  }

  async loadPrevPage(paginationName: string): Promise<void> {
    await this.paginationService.loadPreviousPage(paginationName);

    this.sortEnquiries();

    setTimeout(() => {
      this.updateFloatingScroll();
    }, 1000);
  }

  async loadNextPage(paginationName: string): Promise<void> {
    await this.paginationService.loadNextPage(paginationName);

    this.sortEnquiries();

    setTimeout(() => {
      this.updateFloatingScroll();
    }, 1000);
  }

  updateFloatingScroll(): void {
    window['$']('.table-responsive').floatingScroll('init', {
      orientation: 'horizontal'
    });
  }

  generateFilterPeriodOptions(): void {
    let currentYear: number = getYear(new Date());

    const maxDec: number = currentYear - 2021;
    const beginningOfMonth: Date = startOfMonth(new Date());
    const startDate: Date = endOfYear(addYears(beginningOfMonth, -maxDec - 1));

    for (var m = beginningOfMonth; isAfter(m, startDate); m = addMonths(m, -1)) {
      let title: string = format(m, 'MMMM yy', {
        locale: fr
      });
      title = title.charAt(0).toUpperCase() + title.slice(1);
      this.filtersByPeriod.push({
        title: title,
        value: format(m, 'yyyy-MM-01')
      });
    }
    for (let dec = 0; dec <= maxDec; dec++) {
      const lastQuarter: number = dec === 0 ? getQuarter(new Date()) : 4;
      const year: number = currentYear - dec;
      for (let i = lastQuarter; i >= 1; i--) {
        this.filtersByPeriod.push({
          title: 'Q' + i + ' ' + year,
          value: 'quarter-' + year + '-' + i
        });
      }
    }
    for (let year = currentYear; year >= 2021; year--) {
      this.filtersByPeriod.push({
        title: 'Année ' + year,
        value: 'year-' + year
      });
    }
  }

  async loadUsers(): Promise<void> {
    this.subscriptions.add(
      this.userService.getAll().subscribe((users: IUser[]) => {
        this.usersList = [];

        for (const user of users) {
          if (!user.isDisabled) {
            this.usersList.push({
              title: getUserFullname(user) + ' (' + user.initials + ')',
              value: user.id
            });
          }

          this.usersObj[user.id] = user;
        }

        this.usersList.sort((a, b) =>
          a.title.toLocaleLowerCase() < b.title.toLocaleLowerCase() ? -1 : 1
        );
      })
    );
  }

  updatedFilterByPersonOrDepartment(): void {
    switch (this.filterByPersonOrDepartment) {
      case 'person':
        this.enquiryType = 'all';
        break;
      case 'department':
        this.userId = 'all';
        break;
    }

    this.setLocalVariable(EnumLocalStorageVariable.enquiryType, this.enquiryType);
    this.setLocalVariable(EnumLocalStorageVariable.userId, this.userId);

    this.fetchEnquiries();
  }

  private addZeroToDigit(digit: number): string {
    return digit < 10 ? '0' + digit : digit.toString();
  }
}
