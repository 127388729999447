<div class="card card-statistics mb-4">
    <div class="card-header">
        Équipe pour {{ currentYear }}
        <button type="button" class="btn btn-secondary btn-sm" (click)="showStaffModal()">
            <fa-icon [icon]="faEdit"></fa-icon> Éditer
        </button>
    </div>
    <div class="card-body big-number">
        <div class="row">
            <div class="col-sm-6">
                <h5>Nombre de Sales</h5>
                <div *ngIf="loadingFinancialReportStaff" class="skeleton-text"></div>
                <div
                    *ngIf="!loadingFinancialReportStaff"
                    [innerText]="
                        financialReportStaffsByYear[this.currentYear]?.nbSales || 'À définir'
                    "
                ></div>
            </div>
            <div class="col-sm-6">
                <h5>Nombre d'Employés</h5>
                <div *ngIf="loadingFinancialReportStaff" class="skeleton-text"></div>
                <div
                    *ngIf="!loadingFinancialReportStaff"
                    [innerText]="
                        financialReportStaffsByYear[this.currentYear]?.nbEmployes || 'À définir'
                    "
                ></div>
            </div>
        </div>
    </div>
</div>

<ul class="nav nav-pills mb-3 justify-content-center" role="tablist">
    <li class="nav-item" role="presentation" *ngFor="let period of getPeriods()">
        <button
            class="nav-link"
            [class.active]="currentPeriod === period"
            [id]="'team-period-' + period"
            type="button"
            [attr.aria-selected]="currentPeriod === period"
            [innerText]="getPeriodLabel(period)"
            (click)="changeCurrentPeriod(period)"
        ></button>
    </li>
</ul>

<div class="tab-content">
    <div
        class="tab-pane fade"
        *ngFor="let period of getPeriods()"
        [class.show]="currentPeriod === period"
        [class.active]="currentPeriod === period"
        [id]="'team-period-' + period"
        role="tabpanel"
        [attr.aria-labelledby]="'team-period-' + period"
        tabindex="0"
    >
        <div class="card mb-4" *ngIf="loading || loadingYearsOnly">
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th></th>
                                <th class="text-center">Nb contrats</th>
                                <th class="text-center">Nb clotûrés</th>
                                <th class="text-end">Marge HT</th>
                                <th class="text-center">Part</th>
                                <th class="text-end" *ngIf="currentPeriod === EnumPeriod.months">
                                    Objectif / mois
                                </th>
                                <th class="text-end" *ngIf="currentPeriod === EnumPeriod.quarters">
                                    Objectif / trimestre
                                </th>
                                <th class="text-end" *ngIf="currentPeriod === EnumPeriod.years">
                                    Objectif / année
                                </th>
                                <th class="text-center">Objectif %</th>
                                <th class="text-end">Y2Y</th>
                                <th class="text-center">Gap</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="loading">
                            <tr *ngFor="let row of [].constructor(12)">
                                <td *ngFor="let number of [].constructor(9)">
                                    <div class="skeleton-text"></div>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot *ngIf="loading">
                            <tr>
                                <td *ngFor="let number of [].constructor(9)">
                                    <div class="skeleton-text"></div>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>

        <ng-container *ngIf="!(loading || loadingYearsOnly)">
            <div class="card mb-4" *ngFor="let financialReportUserAllAndTypes of getData()">
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th [innerText]="financialReportUserAllAndTypes.label"></th>
                                    <th class="text-center">Nb contrats</th>
                                    <th class="text-center">Nb clotûrés</th>
                                    <th class="text-end">Marge HT</th>
                                    <th class="text-center">Part</th>
                                    <th
                                        class="text-end"
                                        *ngIf="currentPeriod === EnumPeriod.months"
                                    >
                                        Objectif / mois
                                    </th>
                                    <th
                                        class="text-end"
                                        *ngIf="currentPeriod === EnumPeriod.quarters"
                                    >
                                        Objectif / trimestre
                                    </th>
                                    <th class="text-end" *ngIf="currentPeriod === EnumPeriod.years">
                                        Objectif / année
                                    </th>
                                    <th class="text-center">Objectif %</th>
                                    <th class="text-end">Y2Y</th>
                                    <th class="text-center">Gap</th>
                                    <th
                                        class="text-end"
                                        *ngIf="currentPeriod !== EnumPeriod.months"
                                    >
                                        MyS M
                                    </th>
                                </tr>
                            </thead>
                            <tbody
                                *ngIf="
                                    !financialReportUserAllAndTypes.financialReportUsersByUsers
                                        .length
                                "
                            >
                                <tr class="text-muted">
                                    <td
                                        [attr.colspan]="
                                            currentPeriod !== EnumPeriod.months ? 12 : 11
                                        "
                                        class="text-center"
                                    >
                                        Aucune donnée à afficher
                                    </td>
                                </tr>
                            </tbody>
                            <tbody
                                *ngIf="
                                    financialReportUserAllAndTypes.financialReportUsersByUsers
                                        .length
                                "
                            >
                                <tr
                                    *ngFor="
                                        let financialReportUser of financialReportUserAllAndTypes.financialReportUsersByUsers
                                    "
                                >
                                    <td>
                                        <a
                                            [href]="'/admin/users/' + financialReportUser.userId"
                                            target="_blank"
                                            [innerText]="financialReportUser.userFullName"
                                        ></a>
                                    </td>
                                    <td
                                        class="text-center"
                                        [innerText]="financialReportUser.nbContracts || 0"
                                    ></td>
                                    <td class="text-center">
                                        {{ financialReportUser?.nbCompleted || 0 }}
                                        ({{ financialReportUser?.completedPercent || 0 }}%)
                                    </td>
                                    <td
                                        class="text-end"
                                        [innerText]="
                                            formatPrice(
                                                financialReportUser.marginHT || 0,
                                                currentCurrency ?? getDefaultCurrency()
                                            )
                                        "
                                    ></td>
                                    <td
                                        *ngIf="
                                            !financialReportUserAllAndTypes.virtualFields[
                                                financialReportUser.userId
                                            ]
                                        "
                                    ></td>
                                    <td
                                        class="text-center"
                                        *ngIf="
                                            financialReportUserAllAndTypes.virtualFields[
                                                financialReportUser.userId
                                            ]
                                        "
                                        [innerText]="
                                            financialReportUserAllAndTypes.virtualFields[
                                                financialReportUser.userId
                                            ][EnumFinancialReportUserVirtualField.marginHTPercent] +
                                            '%'
                                        "
                                    ></td>
                                    <td class="text-end">
                                        <span
                                            *ngIf="currentPeriod === EnumPeriod.months"
                                            [innerText]="
                                                formatPrice(
                                                    financialGoalsByUserId[
                                                        financialReportUser.userId
                                                    ]?.monthlyGoal || 0,
                                                    currentCurrency ?? getDefaultCurrency()
                                                )
                                            "
                                        ></span>
                                        <span
                                            *ngIf="currentPeriod === EnumPeriod.quarters"
                                            [innerText]="
                                                formatPrice(
                                                    financialGoalsByUserId[
                                                        financialReportUser.userId
                                                    ]?.quarterlyGoal || 0,
                                                    currentCurrency ?? getDefaultCurrency()
                                                )
                                            "
                                        ></span>
                                        <span
                                            *ngIf="currentPeriod === EnumPeriod.years"
                                            [innerText]="
                                                formatPrice(
                                                    financialGoalsByUserId[
                                                        financialReportUser.userId
                                                    ]?.yearlyGoal || 0,
                                                    currentCurrency ?? getDefaultCurrency()
                                                )
                                            "
                                        ></span>
                                        <button
                                            type="button"
                                            class="btn btn-light btn-sm ms-1"
                                            (click)="
                                                showYearlyGoalModal(financialReportUser.userId)
                                            "
                                        >
                                            <fa-icon [icon]="faEdit"></fa-icon>
                                        </button>
                                    </td>
                                    <td
                                        *ngIf="
                                            !financialReportUserAllAndTypes.virtualFields[
                                                financialReportUser.userId
                                            ]
                                        "
                                    ></td>
                                    <td
                                        class="text-center"
                                        *ngIf="
                                            financialReportUserAllAndTypes.virtualFields[
                                                financialReportUser.userId
                                            ]
                                        "
                                        [class.text-success]="
                                            financialReportUserAllAndTypes.virtualFields[
                                                financialReportUser.userId
                                            ][EnumFinancialReportUserVirtualField.goalPercent] >=
                                            100
                                        "
                                        [class.text-danger]="
                                            financialReportUserAllAndTypes.virtualFields[
                                                financialReportUser.userId
                                            ][EnumFinancialReportUserVirtualField.goalPercent] < 80
                                        "
                                        [class.text-warning]="
                                            financialReportUserAllAndTypes.virtualFields[
                                                financialReportUser.userId
                                            ][EnumFinancialReportUserVirtualField.goalPercent] <
                                                100 &&
                                            financialReportUserAllAndTypes.virtualFields[
                                                financialReportUser.userId
                                            ][EnumFinancialReportUserVirtualField.goalPercent] >= 80
                                        "
                                        [innerText]="
                                            roundNumber(
                                                financialReportUserAllAndTypes.virtualFields[
                                                    financialReportUser.userId
                                                ][EnumFinancialReportUserVirtualField.goalPercent]
                                            ) + '%'
                                        "
                                    ></td>
                                    <td
                                        class="text-end"
                                        *ngIf="
                                            financialReportUserAllAndTypes.virtualFields[
                                                financialReportUser.userId
                                            ]
                                        "
                                        [class.text-danger]="
                                            financialReportUserAllAndTypes.virtualFields[
                                                financialReportUser.userId
                                            ][EnumFinancialReportUserVirtualField.y2y] < 0
                                        "
                                        [class.text-success]="
                                            financialReportUserAllAndTypes.virtualFields[
                                                financialReportUser.userId
                                            ][EnumFinancialReportUserVirtualField.y2y] > 0
                                        "
                                        [innerText]="
                                            formatPrice(
                                                financialReportUserAllAndTypes.virtualFields[
                                                    financialReportUser.userId
                                                ][EnumFinancialReportUserVirtualField.y2y],
                                                currentCurrency ?? getDefaultCurrency(),
                                                2,
                                                true
                                            )
                                        "
                                    ></td>
                                    <td
                                        *ngIf="
                                            !financialReportUserAllAndTypes.virtualFields[
                                                financialReportUser.userId
                                            ]
                                        "
                                    ></td>
                                    <td
                                        *ngIf="
                                            !financialReportUserAllAndTypes.virtualFields[
                                                financialReportUser.userId
                                            ]
                                        "
                                    ></td>
                                    <td
                                        class="text-center"
                                        *ngIf="
                                            financialReportUserAllAndTypes.virtualFields[
                                                financialReportUser.userId
                                            ]
                                        "
                                        [class.text-success]="
                                            financialReportUserAllAndTypes.virtualFields[
                                                financialReportUser.userId
                                            ][EnumFinancialReportUserVirtualField.gap] > 0
                                        "
                                        [class.text-danger]="
                                            financialReportUserAllAndTypes.virtualFields[
                                                financialReportUser.userId
                                            ][EnumFinancialReportUserVirtualField.gap] < 0
                                        "
                                        [innerText]="
                                            roundNumberWithPlusSign(
                                                financialReportUserAllAndTypes.virtualFields[
                                                    financialReportUser.userId
                                                ][EnumFinancialReportUserVirtualField.gap]
                                            ) + '%'
                                        "
                                    ></td>
                                    <td
                                        *ngIf="
                                            !financialReportUserAllAndTypes.virtualFields[
                                                financialReportUser.userId
                                            ] && currentPeriod !== EnumPeriod.months
                                        "
                                    ></td>
                                    <td
                                        class="text-end"
                                        *ngIf="
                                            financialReportUserAllAndTypes.virtualFields[
                                                financialReportUser.userId
                                            ] && currentPeriod !== EnumPeriod.months
                                        "
                                        [innerText]="
                                            formatPrice(
                                                financialReportUserAllAndTypes.virtualFields[
                                                    financialReportUser.userId
                                                ][EnumFinancialReportUserVirtualField.mysM],
                                                currentCurrency ?? getDefaultCurrency(),
                                                2
                                            )
                                        "
                                    ></td>
                                </tr>
                            </tbody>
                            <tfoot *ngIf="financialReportUserAllAndTypes.all">
                                <tr>
                                    <th>Total</th>
                                    <th
                                        class="text-center"
                                        [innerText]="
                                            financialReportUserAllAndTypes.all.nbContracts || 0
                                        "
                                    ></th>
                                    <th class="text-center">
                                        {{ financialReportUserAllAndTypes.all?.nbCompleted || 0 }}
                                        ({{
                                            financialReportUserAllAndTypes.all?.completedPercent ||
                                                0
                                        }}%)
                                    </th>
                                    <th
                                        class="text-end"
                                        [innerText]="
                                            formatPrice(
                                                financialReportUserAllAndTypes.all.marginHT || 0,
                                                currentCurrency ?? getDefaultCurrency()
                                            )
                                        "
                                    ></th>
                                    <th
                                        class="text-center"
                                        [innerText]="
                                            roundNumber(
                                                financialReportUserAllAndTypes.virtualFields.all[
                                                    EnumFinancialReportUserVirtualField
                                                        .marginHTPercent
                                                ],
                                                0
                                            ) + '%'
                                        "
                                    ></th>
                                    <th
                                        class="text-end total-goal-padding"
                                        [innerText]="
                                            formatPrice(
                                                companyFinancialGoal?.monthlyGoal || 0,
                                                currentCurrency ?? getDefaultCurrency()
                                            )
                                        "
                                        *ngIf="currentPeriod === EnumPeriod.months"
                                    ></th>
                                    <th
                                        class="text-end total-goal-padding"
                                        [innerText]="
                                            formatPrice(
                                                companyFinancialGoal?.quarterlyGoal || 0,
                                                currentCurrency ?? getDefaultCurrency()
                                            )
                                        "
                                        *ngIf="currentPeriod === EnumPeriod.quarters"
                                    ></th>
                                    <th
                                        class="text-end total-goal-padding"
                                        [innerText]="
                                            formatPrice(
                                                companyFinancialGoal?.yearlyGoal || 0,
                                                currentCurrency ?? getDefaultCurrency()
                                            )
                                        "
                                        *ngIf="currentPeriod === EnumPeriod.years"
                                    ></th>
                                    <th
                                        class="text-center"
                                        [class.text-success]="
                                            financialReportUserAllAndTypes.virtualFields.all[
                                                EnumFinancialReportUserVirtualField.goalPercent
                                            ] >= 100
                                        "
                                        [class.text-danger]="
                                            financialReportUserAllAndTypes.virtualFields.all[
                                                EnumFinancialReportUserVirtualField.goalPercent
                                            ] < 80
                                        "
                                        [class.text-warning]="
                                            financialReportUserAllAndTypes.virtualFields.all[
                                                EnumFinancialReportUserVirtualField.goalPercent
                                            ] < 100 &&
                                            financialReportUserAllAndTypes.virtualFields.all[
                                                EnumFinancialReportUserVirtualField.goalPercent
                                            ] >= 80
                                        "
                                        [innerText]="
                                            roundNumber(
                                                financialReportUserAllAndTypes.virtualFields.all[
                                                    EnumFinancialReportUserVirtualField.goalPercent
                                                ]
                                            ) + '%'
                                        "
                                    ></th>
                                    <th
                                        class="text-end"
                                        [class.text-danger]="
                                            financialReportUserAllAndTypes.virtualFields.all[
                                                EnumFinancialReportUserVirtualField.y2y
                                            ] < 0
                                        "
                                        [class.text-success]="
                                            financialReportUserAllAndTypes.virtualFields.all[
                                                EnumFinancialReportUserVirtualField.y2y
                                            ] > 0
                                        "
                                        [innerText]="
                                            formatPrice(
                                                financialReportUserAllAndTypes.virtualFields.all[
                                                    EnumFinancialReportUserVirtualField.y2y
                                                ],
                                                currentCurrency ?? getDefaultCurrency(),
                                                2,
                                                true
                                            )
                                        "
                                    ></th>
                                    <th
                                        class="text-center"
                                        [class.text-success]="
                                            financialReportUserAllAndTypes.virtualFields.all[
                                                EnumFinancialReportUserVirtualField.gap
                                            ] > 0
                                        "
                                        [class.text-danger]="
                                            financialReportUserAllAndTypes.virtualFields.all[
                                                EnumFinancialReportUserVirtualField.gap
                                            ] < 0
                                        "
                                        [innerText]="
                                            roundNumberWithPlusSign(
                                                financialReportUserAllAndTypes.virtualFields.all[
                                                    EnumFinancialReportUserVirtualField.gap
                                                ]
                                            ) + '%'
                                        "
                                    ></th>
                                    <th
                                        class="text-end"
                                        *ngIf="currentPeriod !== EnumPeriod.months"
                                        [innerText]="
                                            formatPrice(
                                                financialReportUserAllAndTypes.virtualFields.all[
                                                    EnumFinancialReportUserVirtualField.mysM
                                                ],
                                                currentCurrency ?? getDefaultCurrency(),
                                                2
                                            )
                                        "
                                    ></th>
                                </tr>
                                <ng-container *ngIf="currentPeriod !== EnumPeriod.months">
                                    <tr>
                                        <th>MyE CA et M</th>
                                        <th class="text-center">
                                            {{
                                                financialReportStaffsByYear[this.currentYear]
                                                    ?.nbEmployes || 0
                                            }}
                                            employés
                                        </th>
                                        <th></th>
                                        <th class="text-center" colspan="2">
                                            {{
                                                formatPrice(
                                                    financialReportUserAllAndTypes.virtualFields
                                                        .all[
                                                        EnumFinancialReportUserVirtualField
                                                            .averageCaForAllEmployes
                                                    ],
                                                    currentCurrency ?? getDefaultCurrency(),
                                                    2
                                                )
                                            }}
                                        </th>
                                        <th class="text-center" colspan="2">
                                            {{
                                                formatPrice(
                                                    financialReportUserAllAndTypes.virtualFields
                                                        .all[
                                                        EnumFinancialReportUserVirtualField
                                                            .averageCaForAllEmployesByMonth
                                                    ],
                                                    currentCurrency ?? getDefaultCurrency(),
                                                    2
                                                )
                                            }}
                                            / mois
                                        </th>
                                        <th class="text-center">
                                            {{
                                                roundNumberWithPlusSign(
                                                    financialReportUserAllAndTypes.virtualFields
                                                        .all[
                                                        EnumFinancialReportUserVirtualField
                                                            .averageCaForAllEmployesByMonthY2Y
                                                    ]
                                                )
                                            }}%
                                        </th>
                                        <th *ngFor="let number of [].constructor(2)"></th>
                                    </tr>
                                    <tr>
                                        <th>MyS CA et M</th>
                                        <th class="text-center">
                                            {{
                                                financialReportStaffsByYear[this.currentYear]
                                                    ?.nbSales || 0
                                            }}
                                            Sales
                                        </th>
                                        <th></th>
                                        <th class="text-center" colspan="2">
                                            {{
                                                formatPrice(
                                                    financialReportUserAllAndTypes.virtualFields
                                                        .all[
                                                        EnumFinancialReportUserVirtualField
                                                            .averageCaForAllSales
                                                    ],
                                                    currentCurrency ?? getDefaultCurrency(),
                                                    2
                                                )
                                            }}
                                        </th>
                                        <th class="text-center" colspan="2">
                                            {{
                                                formatPrice(
                                                    financialReportUserAllAndTypes.virtualFields
                                                        .all[
                                                        EnumFinancialReportUserVirtualField
                                                            .averageCaForAllSalesByMonth
                                                    ],
                                                    currentCurrency ?? getDefaultCurrency(),
                                                    2
                                                )
                                            }}
                                            / mois
                                        </th>
                                        <th class="text-center">
                                            {{
                                                roundNumberWithPlusSign(
                                                    financialReportUserAllAndTypes.virtualFields
                                                        .all[
                                                        EnumFinancialReportUserVirtualField
                                                            .averageCaForAllSalesByMonthY2Y
                                                    ]
                                                )
                                            }}%
                                        </th>
                                        <th *ngFor="let number of [].constructor(2)"></th>
                                    </tr>
                                </ng-container>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<div
    class="modal fade"
    id="modal-yearly-goal"
    tabindex="-1"
    aria-labelledby="modal-yearly-goal-label"
    aria-hidden="true"
    #modalYearlyGoal
>
    <div class="modal-dialog modal-md modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="modal-yearly-goal-label">
                    Objectif {{ currentYear }}
                    <span
                        *ngIf="formGoal.value.userId"
                        [innerText]="'pour ' + getUserFullname(usersObj[formGoal.value.userId])"
                    ></span>
                </h1>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <div class="modal-body">
                <form
                    (submit)="submitGoalForm()"
                    (keydown.enter)="submitGoalForm()"
                    [formGroup]="formGoal"
                    class="main-form"
                >
                    <div class="form-group">
                        <label for="yearlyGoal">Objectif annuel {{ currentYear }}</label>

                        <div class="input-group">
                            <input
                                class="form-control"
                                id="yearlyGoal"
                                formControlName="yearlyGoal"
                                type="number"
                                [class.is-invalid]="
                                    (formGoal.touched || formGoal.controls['yearlyGoal'].touched) &&
                                    formGoal.controls['yearlyGoal'].status == 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />

                            <span
                                class="input-group-text"
                                [innerText]="
                                    getEnumCurrencySymbol(currentCurrency ?? getDefaultCurrency())
                                "
                            ></span>
                        </div>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (formGoal.touched || formGoal.controls['yearlyGoal'].touched) &&
                                    formGoal.controls['yearlyGoal'].errors &&
                                    formGoal.controls['yearlyGoal'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="quarterlyGoal">Objectif trimestriel {{ currentYear }}</label>

                        <div class="input-group">
                            <input
                                class="form-control"
                                id="quarterlyGoal"
                                formControlName="quarterlyGoal"
                                type="number"
                                [class.is-invalid]="
                                    (formGoal.touched ||
                                        formGoal.controls['quarterlyGoal'].touched) &&
                                    formGoal.controls['quarterlyGoal'].status == 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />

                            <span
                                class="input-group-text"
                                [innerText]="
                                    getEnumCurrencySymbol(currentCurrency ?? getDefaultCurrency())
                                "
                            ></span>
                        </div>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (formGoal.touched ||
                                        formGoal.controls['quarterlyGoal'].touched) &&
                                    formGoal.controls['quarterlyGoal'].errors &&
                                    formGoal.controls['quarterlyGoal'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                    <div class="form-group mb-0">
                        <label for="monthlyGoal">Objectif mensuel {{ currentYear }}</label>

                        <div class="input-group">
                            <input
                                class="form-control"
                                id="monthlyGoal"
                                formControlName="monthlyGoal"
                                type="number"
                                [class.is-invalid]="
                                    (formGoal.touched ||
                                        formGoal.controls['monthlyGoal'].touched) &&
                                    formGoal.controls['monthlyGoal'].status == 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />

                            <span
                                class="input-group-text"
                                [innerText]="
                                    getEnumCurrencySymbol(currentCurrency ?? getDefaultCurrency())
                                "
                            ></span>
                        </div>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (formGoal.touched ||
                                        formGoal.controls['monthlyGoal'].touched) &&
                                    formGoal.controls['monthlyGoal'].errors &&
                                    formGoal.controls['monthlyGoal'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer justify-content-between">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                    Annuler
                </button>
                <button
                    type="button"
                    class="btn btn-primary"
                    [innerText]="savingGoal ? 'Sauvegarde ...' : 'Sauvegarder'"
                    (click)="submitGoalForm()"
                    [disabled]="formGoal.disabled || !formGoal.valid"
                ></button>
            </div>
        </div>
    </div>
</div>

<div
    class="modal fade"
    id="modal-staff"
    tabindex="-1"
    aria-labelledby="modal-staff-label"
    aria-hidden="true"
    #modalStaff
>
    <div class="modal-dialog modal-md modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="modal-staff-label">
                    Équipe pour {{ currentYear }}
                </h1>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <div class="modal-body">
                <form
                    (submit)="submitStaffForm()"
                    (keydown.enter)="submitStaffForm()"
                    [formGroup]="formStaff"
                    class="main-form"
                >
                    <div class="form-group">
                        <label for="nbSales">Nombre de Sales pour {{ currentYear }}</label>

                        <input
                            class="form-control"
                            id="nbSales"
                            formControlName="nbSales"
                            min="0"
                            type="number"
                            [class.is-invalid]="
                                (formStaff.touched || formStaff.controls['nbSales'].touched) &&
                                formStaff.controls['nbSales'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (formStaff.touched || formStaff.controls['nbSales'].touched) &&
                                    formStaff.controls['nbSales'].errors &&
                                    formStaff.controls['nbSales'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group mb-0">
                        <label for="nbEmployes">Nombre d'employés pour {{ currentYear }}</label>

                        <input
                            class="form-control"
                            id="nbEmployes"
                            formControlName="nbEmployes"
                            min="0"
                            type="number"
                            [class.is-invalid]="
                                (formStaff.touched || formStaff.controls['nbEmployes'].touched) &&
                                formStaff.controls['nbEmployes'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (formStaff.touched ||
                                        formStaff.controls['nbEmployes'].touched) &&
                                    formStaff.controls['nbEmployes'].errors &&
                                    formStaff.controls['nbEmployes'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer justify-content-between">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                    Annuler
                </button>
                <button
                    type="button"
                    class="btn btn-primary"
                    [innerText]="savingStaff ? 'Sauvegarde ...' : 'Sauvegarder'"
                    (click)="submitStaffForm()"
                    [disabled]="formStaff.disabled || !formStaff.valid"
                ></button>
            </div>
        </div>
    </div>
</div>
