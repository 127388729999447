import { EnumLanguage } from '../enums/language.enum';
import { IStoreData } from './store-data.interface';
import { getCountryLabel } from '../enums/continent-code.enum';

export interface IFbo extends IStoreData {
  airportId: string;
  name: string;
  phoneNumber: string | null;
  street: string | null;
  city: string | null;
  postalCode: string | null;
  countryCode: string;
  accessPlanUrl: string | null;
}

export const getFboFullAddress = (fbo: IFbo, language: EnumLanguage = EnumLanguage.en): string => {
  let fullAddressArr: string[] = [];

  if (fbo.street) {
    fullAddressArr.push(fbo.street);
  }

  if (fbo.city && fbo.postalCode) {
    fullAddressArr.push(fbo.city + ' ' + fbo.postalCode);
  } else {
    if (fbo.city) {
      fullAddressArr.push(fbo.city);
    }

    if (fbo.postalCode) {
      fullAddressArr.push(fbo.postalCode);
    }
  }

  if (fbo.countryCode) {
    fullAddressArr.push(getCountryLabel(fbo.countryCode));
  }

  return fullAddressArr.join(', ');
};

export const getFboAddressDetails = (fbo: IFbo, language: EnumLanguage): string => {
  const arr: string[] = [];

  arr.push('<strong><u>' + fbo.name + '</u></strong>');

  if (fbo.phoneNumber) {
    arr.push(
      '<strong>' +
        (language === EnumLanguage.fr ? 'Tél.' : 'Phone') +
        ' :</strong> ' +
        fbo.phoneNumber
    );
  }

  if (getFboFullAddress(fbo)) {
    arr.push(
      '<strong>' +
        (language === EnumLanguage.fr ? 'Adresse' : 'Address') +
        ' :</strong> ' +
        getFboFullAddress(fbo)
    );
  }

  if (fbo.accessPlanUrl) {
    arr.push(
      '<strong>' +
        (language === EnumLanguage.fr ? 'Plan d’accès' : 'Access map') +
        ' :</strong> <a href="' +
        fbo.accessPlanUrl +
        '" target="_blank">' +
        (language === EnumLanguage.fr ? 'ici' : 'here') +
        '</a>'
    );
  }

  return arr.join('<br>');
};
