import { finalize } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ValidationErrors,
  AbstractControl,
  FormControl
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { RemoteService } from '../../../services/remote.service';
import { IUser } from 'src/app/interfaces/user.interface';

@Component({
  selector: 'app-my-account-edit',
  templateUrl: './my-account-edit.component.html',
  styleUrls: ['./my-account-edit.component.scss']
})
export class MyAccountEditComponent implements OnInit {
  form: FormGroup;
  sending: boolean = false;
  user: IUser;
  originalEmail: string;

  constructor(
    private formBuilder: FormBuilder,
    private remoteService: RemoteService,
    private router: Router
  ) {
    this.remoteService.userObservable.subscribe((user: IUser) => {
      if (user) {
        this.user = user;

        this.setUser();
      }
    });
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      lastname: ['', [Validators.required]],
      firstname: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      emailSignature: [''],
      emailSignatureHtml: [''],
      emailInCc: [false, [Validators.required]]
    });

    this.form.disable();

    this.setUser();
  }

  setUser(): void {
    if (this.form && this.user) {
      if (!this.originalEmail) {
        this.originalEmail = this.user.email;
      }

      this.form.get('lastname').setValue(this.user.lastname);
      this.form.get('firstname').setValue(this.user.firstname);
      this.form.get('email').setValue(this.user.email);
      this.form.get('emailSignature').setValue(this.user.emailSignature);
      this.form.get('emailSignatureHtml').setValue(this.user.emailSignatureHtml);
      this.form.get('emailInCc').setValue(this.user.emailInCc);

      this.form.enable();
    }
  }

  submitForm(): void {
    this.form.markAsTouched();

    if (this.form.valid) {
      let data = Object.assign({}, this.form.value);

      this.sending = true;

      this.form.disable();

      if (!data.emailSignature) {
        delete data.emailSignature;
      }
      if (!data.emailSignatureHtml) {
        data.emailSignatureHtml = '';
        data.emailSignature = '';
      } else {
        data.emailSignatureHtml = data.emailSignatureHtml;
        data.emailSignature = this.remoteService.convertHtmlToPlainText(data.emailSignatureHtml);
      }

      this.remoteService
        .updateCurrentUser(this.originalEmail, data)
        .then(() => {
          this.sending = false;
          this.router.navigate(['/admin/my-account']);
        })
        .catch(err => {
          this.sending = false;
          this.form.enable();

          alert(err.message);
        });
    }
  }
}
