<nav class="navbar navbar-expand-lg">
    <button
        class="navbar-toggler d-lg-none"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvas-admin-menu"
        aria-controls="offcanvas-admin-menu"
    >
        <fa-icon [icon]="faBars"></fa-icon>
    </button>

    <div class="header-content">
        <app-header-menu-breadcrumbs *ngIf="displayBreadcrumbs"></app-header-menu-breadcrumbs>
    </div>

    <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
            <li class="nav-item dropdown">
                <button
                    class="nav-link btn-user"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    *ngIf="currentUser"
                >
                    <fa-icon [icon]="faCircleUser"></fa-icon>
                </button>
                <ul class="dropdown-menu dropdown-menu-end">
                    <li *ngIf="currentUser">
                        <a class="dropdown-item" routerLink="/admin/my-account">
                            <fa-icon [icon]="faCircleUser"></fa-icon>
                            {{ getUserFullname(currentUser) }}
                        </a>
                    </li>
                    <li><hr class="dropdown-divider" /></li>
                    <li>
                        <a class="dropdown-item" routerLink="/admin/my-account">
                            <fa-icon [icon]="faUser"></fa-icon> Mon profil</a
                        >
                    </li>
                    <li><hr class="dropdown-divider" /></li>
                    <li>
                        <button type="button" class="dropdown-item" (click)="signOut()">
                            <fa-icon [icon]="faPowerOff"></fa-icon>
                            Déconnexion
                        </button>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</nav>
