import { Injectable } from '@angular/core';
import { ISetting } from 'src/app/interfaces/setting.interface';
import { FirestoreService } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class SettingFirestoreService extends FirestoreService<ISetting> {
  protected basePath = 'settings';
}
