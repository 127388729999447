import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EnquiryQuotationsSentToClientComponent } from './enquiry-quotations-sent-to-client.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, FontAwesomeModule, RouterModule],
  declarations: [EnquiryQuotationsSentToClientComponent],
  exports: [EnquiryQuotationsSentToClientComponent]
})
export class EnquiryQuotationsSentToClientModule {}
