import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import firebase from 'firebase/compat/app';

import { PaginationService } from '../../../services/pagination.service';
import { RemoteService } from '../../../services/remote.service';
import { PipedriveService } from '../../../services/pipedrive.service';
import { AclService } from '../../../services/acl.service';
import {
  doesEnquiryPrefillAirlinesSelection,
  getDisplayedEnquiryRefTitle,
  getEnquiryBreadcrumbTitle,
  getEnquiryGeographicalAreasTitles,
  getEnquiryTargetsTitles,
  IEnquiry
} from 'src/app/interfaces/enquiry.interface';
import { Subscription } from 'rxjs';
import { EnquiryService } from 'src/app/services/enquiry/enquiry.service';
import { EnumEnquiryTarget } from 'src/app/enums/enquiry-target.enum';
import { EnumEnquiryType, getEnumEnquiryTypeLabel } from 'src/app/enums/enquiry-type.enum';
import { IEnquiryOffer } from 'src/app/interfaces/enquiry-offer.interface';
import { EnumEnquiryOfferStatus } from 'src/app/enums/enquiry-offer-status.enum';
import { EnquiryOfferService } from 'src/app/services/enquiry-offer/enquiry-offer.service';
import { faCheck, faSearch, faWarning } from '@fortawesome/free-solid-svg-icons';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { AirlineService } from 'src/app/services/airlines/airlines.service';
import { IAirline } from 'src/app/interfaces/airline.interface';
import { IPipedriveOrganization, IPipedrivePerson } from 'src/app/interfaces/pipedrive.interface';
import { IAircraftCompiled } from 'src/app/interfaces/aircraft-compiled.interface';
import { IAircraftModel } from 'src/app/interfaces/aircraft-model.interface';
import { IAirport } from 'src/app/interfaces/airport.interface';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs/breadcrumbs.service';

@Component({
  selector: 'app-select-airlines',
  templateUrl: './select-airlines.component.html',
  styleUrls: ['./select-airlines.component.scss']
})
export class SelectAirlinesComponent implements OnInit, OnDestroy {
  faSearch = faSearch;
  faCheck = faCheck;
  faWarning = faWarning;

  getEnquiryGeographicalAreasTitles = getEnquiryGeographicalAreasTitles;
  getEnquiryTargetsTitles = getEnquiryTargetsTitles;
  getEnquiryBreadcrumbTitle = getEnquiryBreadcrumbTitle;
  getEnumEnquiryTypeLabel = getEnumEnquiryTypeLabel;
  getDisplayedEnquiryRefTitle = getDisplayedEnquiryRefTitle;

  EnumEnquiryType = EnumEnquiryType;

  loading: boolean = false;
  enquiryId: string;
  enquiry: IEnquiry;
  enquiryOffers: IEnquiryOffer[] = [];
  client: IPipedriveOrganization | null = null;
  contact: IPipedrivePerson | null = null;
  isLogged: boolean = false;
  currentPagination: string = 'aircrafts-compiled-list';
  selectedAircraftsCompiled: string[] = [];
  aircraftsCompiledAlreadyContactedId: string[] = [];
  usersList: object = {};
  sending: boolean = false;
  aircraftModel: IAircraftModel | null;
  aircraftModelsObj: { [key: string]: IAircraftModel | null } = {};
  enquiryTypesToIgnoreEmailStep: EnumEnquiryType[] = [
    EnumEnquiryType.business,
    EnumEnquiryType.helico
  ];
  aircraftsCompiled: IAircraftCompiled[] = [];
  aircraftsCompiledSorted: IAircraftCompiled[] = [];
  aircraftsCompiledSortedAndGrouped: {
    isParent: boolean;
    opened: boolean;
    hide: boolean;
    last: boolean;
    single: boolean;
    aircraftCompiled: IAircraftCompiled;
    countChildren: number;
  }[] = [];
  aircraftsCompiledSortedAndGroupedFiltered: {
    isParent: boolean;
    opened: boolean;
    hide: boolean;
    last: boolean;
    single: boolean;
    aircraftCompiled: IAircraftCompiled;
    countChildren: number;
  }[] = [];
  orderBy: {
    field: string;
    direction: 'asc' | 'desc';
  } = {
    field: 'airlineCountryTitle',
    direction: 'asc'
  };
  fields: {
    title: string;
    code: string;
  }[] = [];
  airportsObj: object = {};
  airportsToLoad: string[] = [];

  subscriptions = new Subscription();

  query: string | null = null;

  prefillListWithoutSearch: boolean = true;
  selectedAirline: IAirline | null = null;

  constructor(
    private remoteService: RemoteService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public paginationService: PaginationService,
    private pipedriveService: PipedriveService,
    private aclService: AclService,
    private enquiryService: EnquiryService,
    private enquiryOfferService: EnquiryOfferService,
    private breadcrumbsService: BreadcrumbsService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );

    this.remoteService.aircraftModelsObservable.subscribe((aircraftModels: IAircraftModel[]) => {
      if (aircraftModels) {
        for (const aircraftModel of aircraftModels) {
          this.aircraftModelsObj[aircraftModel.id] = aircraftModel;
        }
      }
    });
  }

  ngOnInit(): void {
    this.activatedRoute.url.subscribe(async () => {
      this.enquiryId = this.activatedRoute.snapshot.paramMap.get('enquiryId');

      await this.aclService.checkAclAccess(EnumAcl.enquiriesSelectAirlines);

      this.loadData();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();

    window['$']('.tooltip').remove();
  }

  updateTooltip(): void {
    window['$']('[rel="tooltip"]').tooltip({
      html: true,
      boundary: 'window',
      trigger: 'hover'
    });
  }

  loadData(): void {
    if (this.isLogged) {
      this.loadEnquiry();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  loadEnquiry(): void {
    this.loading = true;

    this.subscriptions.add(
      this.enquiryService.getFromId(this.enquiryId).subscribe(async (enquiry: IEnquiry) => {
        this.enquiry = enquiry;

        if (this.enquiry) {
          this.breadcrumbsService.setCurrentItem({
            id: this.enquiry.id,
            text: getEnquiryBreadcrumbTitle(this.enquiry)
          });

          this.prefillListWithoutSearch = doesEnquiryPrefillAirlinesSelection(this.enquiry.type);

          this.loadEnquiryOffers();

          await this.loadClient();
          await this.fetchAircrafts();
          await this.loadAirportsOfEnquiry();
          await this.loadAircraftModel();
        }

        this.loading = false;
      })
    );
  }

  loadEnquiryOffers(): void {
    this.subscriptions.add(
      this.enquiryOfferService
        .getAllForEnquiry(this.enquiryId)
        .subscribe(async (enquiryOffers: IEnquiryOffer[]) => {
          this.enquiryOffers = enquiryOffers;

          for (const enquiryOffer of this.enquiryOffers) {
            if (
              !this.aircraftsCompiledAlreadyContactedId.includes(enquiryOffer.aircraftCompiledId)
            ) {
              this.aircraftsCompiledAlreadyContactedId.push(enquiryOffer.aircraftCompiledId);
            }
          }
        })
    );
  }

  async loadClient(): Promise<void> {
    if (this.enquiry?.clientId) {
      this.loading = true;

      this.client = await this.pipedriveService.getOrganization(
        typeof this.enquiry.clientId === 'number'
          ? this.enquiry.clientId.toString()
          : this.enquiry.clientId
      );

      this.loading = false;
    }

    if (this.enquiry?.contactId) {
      this.loading = true;

      this.contact = await this.pipedriveService.getPerson(this.enquiry.contactId);

      this.loading = false;
    }
  }

  async fetchAircrafts(): Promise<void> {
    this.loading = true;

    const conditions: {
      field: string;
      operator: firebase.firestore.WhereFilterOp;
      value: any;
    }[] = [
      {
        field: 'airlineIsActive',
        operator: '==',
        value: true
      }
    ];

    if (this.enquiry.type === EnumEnquiryType.cargo) {
      this.fields = [
        {
          title: 'Compagnie aérienne',
          code: 'airlineTitle'
        },
        {
          title: 'Pays',
          code: 'airlineCountryTitle'
        },
        {
          title: 'Appareil',
          code: 'type'
        },
        {
          title: 'N°',
          code: 'count'
        },
        {
          title: 'Poids (kg)',
          code: 'weight'
        },
        {
          title: 'Volume (m<sup>3</sup>)',
          code: 'volume'
        },
        {
          title: 'Âge moyen (an)',
          code: 'ageAverage'
        }
      ];

      conditions.push({
        field: 'airlineGeographicalArea',
        operator: 'in',
        value: this.enquiry.geographicalAreasCode
      });

      conditions.push({
        field: 'isCargo',
        operator: '==',
        value: true
      });
    } else if (this.enquiry.type === EnumEnquiryType.helico) {
      this.fields = [
        {
          title: 'Compagnie aérienne',
          code: 'airlineTitle'
        },
        {
          title: 'Pays',
          code: 'airlineCountryTitle'
        },
        {
          title: 'Appareil',
          code: 'type'
        },
        {
          title: 'N°',
          code: 'count'
        },
        {
          title: 'Capacité',
          code: 'seatTotal'
        },
        {
          title: 'Classes',
          code: 'classes'
        }
      ];

      conditions.push({
        field: 'target',
        operator: '==',
        value: EnumEnquiryType.helico
      });
      conditions.push({
        field: 'airlineGeographicalArea',
        operator: 'in',
        value: this.enquiry.geographicalAreasCode
      });
      conditions.push({
        field: 'seatTotal',
        operator: '>=',
        value: this.enquiry.capacityMin
      });
      conditions.push({
        field: 'seatTotal',
        operator: '<=',
        value: this.enquiry.capacityMax
      });
    } else {
      this.fields = [
        {
          title: 'Compagnie aérienne',
          code: 'airlineTitle'
        },
        {
          title: 'Pays',
          code: 'airlineCountryTitle'
        },
        {
          title: 'Appareil',
          code: 'type'
        },
        {
          title: 'N°',
          code: 'count'
        },
        {
          title: 'Capacité',
          code: 'seatTotal'
        },
        {
          title: 'Classes',
          code: 'classes'
        },
        {
          title: 'Cible',
          code: 'targets'
        },
        {
          title: 'Âge moyen (an)',
          code: 'ageAverage'
        }
      ];

      conditions.push({
        field: 'airlineGeographicalArea',
        operator: 'in',
        value: this.enquiry.geographicalAreasCode
      });
      conditions.push({
        field: 'seatTotal',
        operator: '>=',
        value: this.enquiry.capacityMin
      });
      conditions.push({
        field: 'seatTotal',
        operator: '<=',
        value: this.enquiry.capacityMax
      });
    }

    if (this.enquiry.aircraftModelId) {
      conditions.push({
        field: 'model',
        operator: '==',
        value: this.enquiry.aircraftModelId
      });
    }

    if (this.prefillListWithoutSearch) {
      if (conditions.length) {
        this.aircraftsCompiled = await this.remoteService.getAllAircraftsCompiled(conditions);
      }

      this.sortingAircraftsCompiled();

      for (const aircraftCompiled of this.aircraftsCompiled) {
        if (this.aircraftsCompiledAlreadyContactedId.includes(aircraftCompiled.id)) {
        }

        // if (aircraftCompiled.airlineMustBeContacted) {
        //   this.selectIAircraftCompiled(aircraftCompiled.id)
        // }
      }
    }

    setTimeout(() => {
      this.updateTooltip();
    }, 1000);

    this.loading = false;
  }

  sortingAircraftsCompiled(): void {
    this.loading = true;

    this.aircraftsCompiledSorted = [];

    if (this.enquiry.type === EnumEnquiryType.cargo) {
      // Because of firebase limitation, we filter by weight and volume on client side (only 1 '<' condition by request)
      for (const aircraftCompiled of this.aircraftsCompiled) {
        if (
          (aircraftCompiled.weight >= this.enquiry.weightMin &&
            aircraftCompiled.weight <= this.enquiry.weightMax) ||
          (aircraftCompiled.volume >= this.enquiry.volumeMin &&
            aircraftCompiled.volume <= this.enquiry.volumeMax)
        ) {
          this.aircraftsCompiledSorted.push(aircraftCompiled);
        }
      }
    } else if (this.enquiry.type === EnumEnquiryType.helico) {
      for (const aircraftCompiled of this.aircraftsCompiled) {
        this.aircraftsCompiledSorted.push(aircraftCompiled);
      }
    } else {
      // Because of firebase limitation, we filter by target on client side (only 1 'in' condition by request)
      for (const aircraftCompiled of this.aircraftsCompiled) {
        if (
          !this.enquiry.targets.length ||
          this.enquiry.targets.includes(aircraftCompiled.target as EnumEnquiryTarget)
        ) {
          this.aircraftsCompiledSorted.push(aircraftCompiled);
        }
      }
    }

    this.aircraftsCompiledSorted.sort((a, b) => {
      let result: number = a[this.orderBy.field].localeCompare(b[this.orderBy.field]);

      if (result === 0) {
        result = a.airlineTitle.localeCompare(b.airlineTitle);
      }

      return this.orderBy.direction === 'asc' ? result * 1 : result * -1;
    });

    this.aircraftsCompiledSortedAndGrouped = [];
    for (const aircraftCompiledSorted of this.aircraftsCompiledSorted) {
      const previous: {
        isParent: boolean;
        opened: boolean;
        hide: boolean;
        aircraftCompiled: IAircraftCompiled;
      } =
        typeof this.aircraftsCompiledSortedAndGrouped[
          this.aircraftsCompiledSortedAndGrouped.length - 1
        ] === 'undefined'
          ? null
          : this.aircraftsCompiledSortedAndGrouped[
              this.aircraftsCompiledSortedAndGrouped.length - 1
            ];

      if (
        !previous ||
        previous.aircraftCompiled.airlineId !== aircraftCompiledSorted.airlineId ||
        previous.aircraftCompiled.airlineCountryCode !==
          aircraftCompiledSorted.airlineCountryCode ||
        previous.aircraftCompiled.aircraftModelId !== aircraftCompiledSorted.aircraftModelId
      ) {
        this.aircraftsCompiledSortedAndGrouped.push({
          isParent: true,
          opened: false,
          hide: false,
          last: false,
          single: false,
          countChildren: 0,
          aircraftCompiled: aircraftCompiledSorted
        });
      }

      this.aircraftsCompiledSortedAndGrouped.push({
        isParent: false,
        opened: false,
        hide: true,
        last: false,
        single: false,
        countChildren: 0,
        aircraftCompiled: aircraftCompiledSorted
      });
    }

    for (let i = 0; i < this.aircraftsCompiledSortedAndGrouped.length; i++) {
      if (this.aircraftsCompiledSortedAndGrouped[i].isParent) {
        let j = 1;
        while (
          this.aircraftsCompiledSortedAndGrouped[i + j] &&
          !this.aircraftsCompiledSortedAndGrouped[i + j].isParent
        ) {
          this.aircraftsCompiledSortedAndGrouped[i].countChildren++;

          j++;
        }
      }
    }

    const linesToRemove: number[] = [];
    let nbChildren = 0;
    for (let i = 0; i < this.aircraftsCompiledSortedAndGrouped.length; i++) {
      if (!this.aircraftsCompiledSortedAndGrouped[i].isParent) {
        nbChildren++;
      } else {
        if (i > 0) {
          if (nbChildren == 1) {
            // We delete parent line because there is only one child: it is useless to keep it
            linesToRemove.push(i - 2);
            this.aircraftsCompiledSortedAndGrouped[i - 1].hide = false;
            this.aircraftsCompiledSortedAndGrouped[i - 1].single = true;
          } else {
            this.aircraftsCompiledSortedAndGrouped[i - 1].last = true;
          }
        }

        nbChildren = 0;
      }
    }

    if (nbChildren == 1) {
      // We delete parent line because there is only one child: it is useless to keep it
      linesToRemove.push(this.aircraftsCompiledSortedAndGrouped.length - 2);
      this.aircraftsCompiledSortedAndGrouped[
        this.aircraftsCompiledSortedAndGrouped.length - 1
      ].hide = false;
    } else if (this.aircraftsCompiledSortedAndGrouped.length) {
      this.aircraftsCompiledSortedAndGrouped[
        this.aircraftsCompiledSortedAndGrouped.length - 1
      ].last = true;
    }

    // We delete starting from the end to keep the index before consistent
    for (let i = linesToRemove.length - 1; i >= 0; i--) {
      this.aircraftsCompiledSortedAndGrouped.splice(linesToRemove[i], 1);
    }

    this.filterData();
  }

  async sortField(field: string): Promise<void> {
    this.loading = true;

    if (this.orderBy.field == field) {
      if (this.orderBy.direction === 'asc') {
        this.orderBy.direction = 'desc';
      } else {
        this.orderBy.direction = 'asc';
      }
    } else {
      this.orderBy.field = field;
      this.orderBy.direction = 'asc';
    }

    await this.sortingAircraftsCompiled();

    this.loading = false;
  }

  selectAll(): void {
    if (
      this.selectedAircraftsCompiled.length ==
      this.aircraftsCompiledSorted.length - this.aircraftsCompiledAlreadyContactedId.length
    ) {
      this.selectedAircraftsCompiled = [];
    } else {
      for (const aircraftCompiled of this.aircraftsCompiledSorted) {
        this.selectIAircraftCompiled(aircraftCompiled.id, true);
      }
    }
  }

  selectIAircraftCompiled(aircraftCompiledId: string, forceAdd: boolean = false): void {
    if (this.aircraftsCompiledAlreadyContactedId.indexOf(aircraftCompiledId) === -1) {
      let currentIndex: number = -1;

      for (let i = 0; i < this.selectedAircraftsCompiled.length; i++) {
        if (this.selectedAircraftsCompiled[i] === aircraftCompiledId) {
          currentIndex = i;
          break;
        }
      }

      if (currentIndex === -1) {
        // We check if airline is national company
        for (const aircraftCompiled of this.aircraftsCompiledSorted) {
          if (aircraftCompiled.id === aircraftCompiledId) {
            if (aircraftCompiled.isAirlineNationalCompany) {
              const result = confirm(
                'La compagnie aérienne ' +
                  aircraftCompiled.airlineTitle +
                  ' est une compagnie nationale. Êtes-vous sûr de vouloir la contacter ?'
              );

              if (result) {
                this.selectedAircraftsCompiled.push(aircraftCompiledId);
              }
            } else {
              this.selectedAircraftsCompiled.push(aircraftCompiledId);
            }

            break;
          }
        }
      } else if (!forceAdd) {
        this.selectedAircraftsCompiled.splice(currentIndex, 1);
      }
    }
  }

  isAircraftCompiledSelected(aircraftCompiledId: string): boolean {
    let isSelected: boolean = false;
    for (const selectedIAircraftCompiled of this.selectedAircraftsCompiled) {
      if (selectedIAircraftCompiled === aircraftCompiledId) {
        isSelected = true;
        break;
      }
    }

    return isSelected;
  }

  async submitForm(): Promise<void> {
    if (this.enquiry && this.selectedAircraftsCompiled.length) {
      this.sending = true;

      const enquiryOffers: IEnquiryOffer[] = [];

      for (const aircraftCompiledId of this.selectedAircraftsCompiled) {
        for (const aircraftCompiled of this.aircraftsCompiled) {
          if (aircraftCompiled.id === aircraftCompiledId) {
            enquiryOffers.push({
              aircraftCompiledId: aircraftCompiledId,
              selectedDatetime: new Date(),
              status: EnumEnquiryOfferStatus.toContact,
              aircraftCompiled: JSON.parse(JSON.stringify(aircraftCompiled), this.reviver),
              enquiryId: this.enquiryId
            } as IEnquiryOffer);
            break;
          }
        }
      }

      try {
        for (const enquiryOffer of enquiryOffers) {
          await this.enquiryOfferService.create(enquiryOffer);
        }

        this.sending = false;

        this.goToNextStep();
      } catch (err) {
        console.log(err);
        this.sending = false;

        alert(err.message);
      }
    } else {
      this.goToNextStep();
    }
  }

  reviver(key: string, value: string): any {
    if (
      ['selectedDatetime', 'contactDatetime', 'created', 'modified'].indexOf(key) !== -1 &&
      !isNaN(Date.parse(value))
    ) {
      return new Date(value);
    }

    return value;
  }

  goToNextStep(): void {
    let url: string;
    if (this.enquiryTypesToIgnoreEmailStep.includes(this.enquiry.type)) {
      url = '/admin/enquiries/' + this.enquiryId;
    } else {
      url = '/admin/enquiries/' + this.enquiryId + '/send-message-to-airlines';
    }

    this.router.navigate([url]);
  }

  formatPrice(value: number): string {
    const formatter = new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2
    });

    return formatter.format(value);
  }

  clickOnTr(clickedIndex: number): void {
    if (!this.aircraftsCompiledSortedAndGroupedFiltered[clickedIndex].isParent) {
      this.selectIAircraftCompiled(
        this.aircraftsCompiledSortedAndGroupedFiltered[clickedIndex].aircraftCompiled.id
      );
    } else {
      this.aircraftsCompiledSortedAndGroupedFiltered[clickedIndex].opened =
        !this.aircraftsCompiledSortedAndGroupedFiltered[clickedIndex].opened;

      for (
        let i = clickedIndex + 1;
        i < this.aircraftsCompiledSortedAndGroupedFiltered.length;
        i++
      ) {
        if (
          this.aircraftsCompiledSortedAndGroupedFiltered[i].isParent ||
          this.aircraftsCompiledSortedAndGroupedFiltered[i].single
        ) {
          break;
        } else {
          this.aircraftsCompiledSortedAndGroupedFiltered[i].hide =
            !this.aircraftsCompiledSortedAndGroupedFiltered[clickedIndex].opened;
        }
      }
    }
  }

  async loadAirportsOfEnquiry(): Promise<void> {
    for (const itinerary of this.enquiry.itineraries) {
      for (const trip of itinerary.trips) {
        for (const field of [
          'airportDepart',
          'airportDestination',
          'commercialStopOverAirport',
          'fuelStopOverAirport'
        ]) {
          if (trip[field]) {
            this.addAirportToLoad(trip[field]);
          }
        }
      }
    }

    await this.loadAirports();
  }

  async loadAircraftModel(): Promise<void> {
    // For helicotere enquiries
    if (this.enquiry.aircraftModelId) {
      const doc: object = await this.remoteService.getDocument(
        'aircraftModels',
        this.enquiry.aircraftModelId
      );

      if (doc) {
        this.aircraftModel = doc as IAircraftModel;
      }
    }
  }

  addAirportToLoad(airportId: string) {
    if (
      this.airportsToLoad.indexOf(airportId) === -1 &&
      typeof this.airportsObj[airportId] === 'undefined'
    ) {
      this.airportsToLoad.push(airportId);
    }
  }

  async loadAirports(): Promise<void> {
    if (this.airportsToLoad.length) {
      const docs: object[] = await this.remoteService.getDocumentsFromDocId(
        'airports',
        this.airportsToLoad
      );

      for (const doc of docs) {
        const airport = doc as IAirport;

        this.airportsObj[airport.id] = airport;
      }

      this.airportsToLoad = [];
    }
  }

  filterData(event: any = null): void {
    let newQuery: string = '';

    if (event?.srcElement.value) {
      newQuery = event.srcElement.value.toLocaleLowerCase();
    }

    if (newQuery !== this.query) {
      this.query = newQuery;

      if (this.prefillListWithoutSearch) {
        if (this.query === '') {
          this.aircraftsCompiledSortedAndGroupedFiltered = this.aircraftsCompiledSortedAndGrouped;
        } else {
          this.aircraftsCompiledSortedAndGroupedFiltered = [];
          for (const item of this.aircraftsCompiledSortedAndGrouped) {
            if (
              item.aircraftCompiled.airlineTitle.toLocaleLowerCase().match(this.query) ||
              item.aircraftCompiled.modelTitle.toLocaleLowerCase().match(this.query)
            ) {
              this.aircraftsCompiledSortedAndGroupedFiltered.push(item);
            }
          }
        }
      }
    }
  }

  async setValueToFormControl($event: {
    fields: Array<{
      name: string;
      value: IAirline;
    }>;
  }): Promise<void> {
    this.aircraftsCompiledSortedAndGroupedFiltered = [];

    for (let field of $event.fields) {
      if (field.name === 'airline') {
        this.selectedAirline = field.value || null;

        if (field.value?.id) {
          this.loading = true;

          const conditions: {
            field: string;
            operator: firebase.firestore.WhereFilterOp;
            value: any;
          }[] = [
            {
              field: 'airlineIsActive',
              operator: '==',
              value: true
            },
            {
              field: 'airlineId',
              operator: '==',
              value: field.value?.id
            }
          ];

          conditions.push({
            field: 'seatTotal',
            operator: '>=',
            value: this.enquiry.capacityMin
          });
          conditions.push({
            field: 'seatTotal',
            operator: '<=',
            value: this.enquiry.capacityMax
          });

          if (conditions.length) {
            this.aircraftsCompiled = await this.remoteService.getAllAircraftsCompiled(conditions);
          }

          this.sortingAircraftsCompiled();

          this.aircraftsCompiledSortedAndGroupedFiltered = this.aircraftsCompiledSortedAndGrouped;

          for (const aircraftCompiled of this.aircraftsCompiled) {
            if (this.aircraftsCompiledAlreadyContactedId.includes(aircraftCompiled.id)) {
            }

            // if (aircraftCompiled.airlineMustBeContacted) {
            //   this.selectIAircraftCompiled(aircraftCompiled.id)
            // }
          }

          this.loading = false;
        } else {
          this.aircraftsCompiled = [];
        }

        setTimeout(() => {
          this.updateTooltip();
        }, 1000);
      }
    }
  }
}
