<div class="row justify-content-center" id="flight-header">
    <div class="col-md-4">
        <img src="/assets/img/logo-menu.png" alt="Artheau Aviation" width="200" />
    </div>
    <div class="col-md-4 d-flex align-items-center justify-content-center">
        <h1>Flight Brief</h1>
    </div>
    <div
        class="col-md-4 text-end artheau-contact-details"
        *ngIf="enquiry?.type !== EnumEnquiryType.cargo"
    >
        Contact Artheau Aviation<br />
        <a href="mailto:executive@flyaa.eu">executive&#64;flyaa.eu</a><br />
        <a href="tel:+33182285280">+33 (0) 1 82 28 52 80</a>
    </div>
    <div
        class="col-md-4 text-end artheau-contact-details"
        *ngIf="enquiry?.type === EnumEnquiryType.cargo"
    >
        Contact Artheau Aviation<br />
        Alexandra Gobalraja<br />
        <a href="mailto:cargo@flyaa.eu">cargo&#64;flyaa.eu</a><br />
        <a href="tel:+33621559671">+33 (0) 6 21 55 96 71</a>
    </div>
</div>

<div class="card mb-2">
    <div class="card-body">
        <div class="row header-cells text-center">
            <div
                class="col"
                [innerText]="translationObj[flightBrief.language]?.FLIGHT_BRIEF.DATE"
            ></div>
            <div
                class="col"
                [innerText]="translationObj[flightBrief.language]?.FLIGHT_BRIEF.FLIGHT_NUMBER"
            ></div>
            <div
                class="col"
                [innerText]="translationObj[flightBrief.language]?.FLIGHT_BRIEF.DEPARTURE"
            ></div>
            <div
                class="col"
                [innerText]="translationObj[flightBrief.language]?.FLIGHT_BRIEF.DEPARTURE_TIME"
            ></div>
            <div
                class="col"
                [innerText]="translationObj[flightBrief.language]?.FLIGHT_BRIEF.ARRIVAL"
            ></div>
            <div
                class="col"
                [innerText]="translationObj[flightBrief.language]?.FLIGHT_BRIEF.ARRIVAL_TIME"
            ></div>
            <div
                class="col"
                [innerText]="translationObj[flightBrief.language]?.FLIGHT_BRIEF.FLY_TIME"
            ></div>
        </div>
        <ul class="list-group mb-2">
            <ng-container *ngIf="!enquiryFlights?.length">
                <li class="list-group-item cell" *ngFor="let trip of trips">
                    <div class="row">
                        <div class="col" [innerText]="formatTripDate(trip.date)"></div>
                        <div class="col" [innerText]="trip.flightNumber"></div>
                        <div class="col">
                            <div class="airport">
                                {{ airportsObj[trip.airportDepartId]?.title }},
                                {{ airportsObj[trip.airportDepartId]?.iataCode }}
                            </div>
                        </div>
                        <div class="col">
                            <div *ngIf="enquiry?.type === EnumEnquiryType.cargo">
                                <div class="flight-time">{{ trip.departureTime }} GMT/UTC</div>
                            </div>
                            <div *ngIf="enquiry?.type !== EnumEnquiryType.cargo">
                                <div class="flight-time">
                                    {{ trip.departureTime }}
                                    {{ translationObj[flightBrief.language]?.FLIGHT_BRIEF.LOCAL }}
                                </div>
                                <div
                                    class="utc-time"
                                    *ngIf="
                                        trip.airportDepartId &&
                                        airportsObj[trip.airportDepartId] &&
                                        trip.departureTime
                                    "
                                >
                                    {{
                                        getAirportUTCTimeString(
                                            airportsObj[trip.airportDepartId],
                                            trip.date,
                                            trip.departureTime
                                        )
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="airport">
                                {{ airportsObj[trip.airportArrivalId]?.title }},
                                {{ airportsObj[trip.airportArrivalId]?.iataCode }}
                            </div>
                        </div>
                        <div class="col">
                            <div *ngIf="enquiry?.type === EnumEnquiryType.cargo">
                                <div class="flight-time">{{ trip.arrivalTime }} GMT/UTC</div>
                            </div>
                            <div *ngIf="enquiry?.type !== EnumEnquiryType.cargo">
                                <div class="flight-time">
                                    {{ trip.arrivalTime }}
                                    {{ translationObj[flightBrief.language]?.FLIGHT_BRIEF.LOCAL }}
                                </div>
                                <div
                                    class="utc-time"
                                    *ngIf="
                                        trip.airportArrivalId &&
                                        airportsObj[trip.airportArrivalId] &&
                                        trip.arrivalTime
                                    "
                                >
                                    {{
                                        getAirportUTCTimeString(
                                            airportsObj[trip.airportArrivalId],
                                            trip.date,
                                            trip.arrivalTime
                                        )
                                    }}
                                </div>
                            </div>
                        </div>
                        <div
                            class="col"
                            [innerText]="
                                trip.flyTimeInHours === 'NaNh NaNmin'
                                    ? trip.flyTimeInHours
                                    : '??h ??min'
                            "
                        ></div>
                    </div>
                </li>
            </ng-container>
            <ng-container *ngIf="enquiryFlights?.length">
                <li class="list-group-item cell" *ngFor="let enquiryFlight of enquiryFlights">
                    <div class="row">
                        <div
                            class="col"
                            [innerText]="formatTripDate(enquiryFlight.departDateLocal)"
                        ></div>
                        <div
                            class="col"
                            [class.text-muted]="!enquiryFlight.flightNumber"
                            [innerText]="enquiryFlight.flightNumber || 'N/A'"
                        ></div>
                        <div class="col">
                            <div class="airport">
                                {{ enquiryFlight.airportDepartTitle }},
                                {{ enquiryFlight.airportDepartIataCode }}
                            </div>
                        </div>
                        <div class="col">
                            <div *ngIf="enquiryFlight.enquiryType === EnumEnquiryType.cargo">
                                <div class="flight-time">
                                    {{ enquiryFlight.departTimeUtc }} GMT/UTC
                                </div>
                            </div>
                            <div *ngIf="enquiryFlight.enquiryType !== EnumEnquiryType.cargo">
                                <div class="flight-time">
                                    {{ enquiryFlight.departTimeLocal }}
                                    {{ translationObj[flightBrief.language]?.FLIGHT_BRIEF.LOCAL }}
                                </div>
                                <div
                                    class="utc-time"
                                    *ngIf="
                                        enquiryFlight.airportDepartId && enquiryFlight.departTimeUtc
                                    "
                                    [innerText]="enquiryFlight.departTimeUtc + ' GMT/UTC'"
                                ></div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="airport">
                                {{ enquiryFlight.airportArrivalTitle }},
                                {{ enquiryFlight.airportArrivalIataCode }}
                            </div>
                        </div>
                        <div class="col">
                            <div *ngIf="enquiryFlight.enquiryType === EnumEnquiryType.cargo">
                                <div class="flight-time">
                                    {{ enquiryFlight.arrivalTimeUtc }} GMT/UTC
                                </div>
                            </div>
                            <div *ngIf="enquiryFlight.enquiryType !== EnumEnquiryType.cargo">
                                <div class="flight-time">
                                    {{ enquiryFlight.arrivalTimeLocal }}
                                    {{ translationObj[flightBrief.language]?.FLIGHT_BRIEF.LOCAL }}
                                </div>
                                <div
                                    class="utc-time"
                                    *ngIf="
                                        enquiryFlight.airportArrivalId &&
                                        enquiryFlight.arrivalTimeUtc
                                    "
                                    [innerText]="enquiryFlight.arrivalTimeUtc + ' GMT/UTC'"
                                ></div>
                            </div>
                        </div>
                        <div
                            class="col"
                            [innerText]="
                                convertMinutesToTime(
                                    getEnquiryFlightFlyTimeInMin(enquiryFlight),
                                    'h ',
                                    'min'
                                )
                            "
                        ></div>
                    </div>
                </li>
            </ng-container>
        </ul>

        <div class="row justify-content-center">
            <ng-container *ngIf="!enquiryFlights?.length">
                <div class="col-12 col-md-6" *ngFor="let trip of trips; let i = index">
                    <div
                        class="boarding-passenger-before-departure"
                        *ngIf="trip.isFerryFlight"
                        [innerText]="
                            translationObj[flightBrief.language]?.FLIGHT_BRIEF.FLIGHT_IS_FERRY
                        "
                    ></div>

                    <div class="boarding-passenger-before-departure" *ngIf="!trip.isFerryFlight">
                        {{
                            enquiry?.type === EnumEnquiryType.cargo
                                ? translationObj[flightBrief.language]?.FLIGHT_BRIEF.DELIVERY_CARGO
                                : translationObj[flightBrief.language]?.FLIGHT_BRIEF
                                      .CHECKIN_PASSENGERS
                        }}
                        <span
                            *ngIf="trips.length > 1 && enquiry?.type !== EnumEnquiryType.cargo"
                            [innerText]="
                                translationObj[flightBrief.language]?.FLIGHT_BRIEF[
                                    i === 0 ? 'OUTBOUND_FLIGHT' : 'RETURN_FLIGHT'
                                ]
                            "
                        ></span>
                        :
                        {{
                            enquiry?.type === EnumEnquiryType.cargo
                                ? trip.deliveryCargoHoursBeforeDeparture || 2
                                : trip.boardingMinutesBeforeDeparture || 20
                        }}
                        {{
                            enquiry?.type === EnumEnquiryType.cargo
                                ? translationObj[flightBrief.language]?.FLIGHT_BRIEF
                                      .DELIVERY_HOURS_BEFORE
                                : translationObj[flightBrief.language]?.FLIGHT_BRIEF
                                      .BOARDING_MINUTES_BEFORE
                        }}
                        {{ getTimeBoardingBeforeDepartureForTrip(trip, enquiry?.type) }}
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="enquiryFlights?.length">
                <div
                    class="col-12 col-md-6"
                    *ngFor="let enquiryFlight of enquiryFlights; let i = index"
                >
                    <div
                        class="boarding-passenger-before-departure"
                        *ngIf="enquiryFlight.isFerryFlight"
                        [innerText]="
                            translationObj[flightBrief.language]?.FLIGHT_BRIEF.FLIGHT_IS_FERRY
                        "
                    ></div>

                    <div
                        class="boarding-passenger-before-departure"
                        *ngIf="!enquiryFlight.isFerryFlight"
                    >
                        {{
                            enquiryFlight.enquiryType === EnumEnquiryType.cargo
                                ? translationObj[flightBrief.language]?.FLIGHT_BRIEF.DELIVERY_CARGO
                                : translationObj[flightBrief.language]?.FLIGHT_BRIEF
                                      .CHECKIN_PASSENGERS
                        }}
                        <span
                            *ngIf="
                                enquiryFlights.length > 1 &&
                                enquiryFlight.enquiryType !== EnumEnquiryType.cargo
                            "
                            [innerText]="
                                translationObj[flightBrief.language]?.FLIGHT_BRIEF[
                                    i === 0 ? 'OUTBOUND_FLIGHT' : 'RETURN_FLIGHT'
                                ]
                            "
                        ></span>
                        :
                        {{
                            enquiryFlight.enquiryType === EnumEnquiryType.cargo
                                ? enquiryFlight.deliveryCargoHoursBeforeDeparture || 2
                                : enquiryFlight.boardingMinutesBeforeDeparture || 20
                        }}
                        {{
                            enquiryFlight.enquiryType === EnumEnquiryType.cargo
                                ? translationObj[flightBrief.language]?.FLIGHT_BRIEF
                                      .DELIVERY_HOURS_BEFORE
                                : translationObj[flightBrief.language]?.FLIGHT_BRIEF
                                      .BOARDING_MINUTES_BEFORE
                        }}
                        {{ getTimeBoardingBeforeDepartureForEnquiryFlight(enquiryFlight) }}
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<div class="card mb-2" *ngIf="enquiry?.type !== EnumEnquiryType.cargo">
    <div class="card-body">
        <div class="header-cells">
            <fa-icon [icon]="faUsers"></fa-icon>
            {{ translationObj[flightBrief.language]?.FLIGHT_BRIEF.PASSENGERS }}
        </div>
        <ng-container *ngIf="!enquiryFlights?.length && trips.length">
            <ul class="list-group mb-2">
                <li class="list-group-item cell">
                    <div
                        class="text-warning"
                        *ngIf="!trips[0].passengers.length"
                        [innerText]="
                            translationObj[flightBrief.language]?.FLIGHT_BRIEF.NO_PASSENGER
                        "
                    ></div>
                    <div class="passengers-list" *ngIf="trips[0].passengers.length">
                        <div class="row">
                            <div
                                class="col-sm-6 col-lg-4 col-xl-3"
                                *ngFor="let passenger of trips[0].passengers"
                            >
                                <ul>
                                    <li
                                        [innerText]="
                                            getPassengerLabel(
                                                passenger,
                                                flightBrief?.language ?? EnumLanguage.en
                                            )
                                        "
                                    ></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>

            <div class="row">
                <div class="col-md-6 col-lg-8">
                    <div class="header-cells">
                        <fa-icon [icon]="faUtensils"></fa-icon>
                        {{ translationObj[flightBrief.language]?.FLIGHT_BRIEF.CATERING }}
                    </div>
                    <ul class="list-group mb-2">
                        <li
                            class="list-group-item cell one-cell"
                            [class.text-muted]="!trips[0].catering"
                            [innerText]="trips[0].catering || 'N/A'"
                        ></li>
                    </ul>
                </div>
                <div class="col-md-6 col-lg-4">
                    <div class="header-cells">
                        <fa-icon [icon]="faSuitcaseRolling"></fa-icon>
                        {{ translationObj[flightBrief.language]?.FLIGHT_BRIEF.LUGGAGES }}
                    </div>
                    <ul class="list-group">
                        <li
                            class="list-group-item cell one-cell"
                            [class.text-muted]="!trips[0].luggages"
                            [innerText]="trips[0].luggages || 'N/A'"
                        ></li>
                    </ul>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="enquiryFlights.length">
            <ul class="list-group mb-2">
                <li class="list-group-item cell">
                    <div
                        class="text-warning"
                        *ngIf="!enquiryFlights[0].passengers?.length"
                        [innerText]="
                            translationObj[flightBrief.language]?.FLIGHT_BRIEF.NO_PASSENGER
                        "
                    ></div>
                    <div class="passengers-list" *ngIf="enquiryFlights[0].passengers?.length">
                        <div class="row">
                            <div
                                class="col-sm-6 col-lg-4 col-xl-3"
                                *ngFor="let passenger of enquiryFlights[0].passengers"
                            >
                                <ul>
                                    <li
                                        [innerText]="
                                            getPassengerLabel(
                                                passenger,
                                                flightBrief?.language ?? EnumLanguage.en
                                            )
                                        "
                                    ></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            <div class="row">
                <div class="col-md-6 col-lg-8">
                    <div class="header-cells">
                        <fa-icon [icon]="faUtensils"></fa-icon>
                        {{ translationObj[flightBrief.language]?.FLIGHT_BRIEF.CATERING }}
                    </div>
                    <ul class="list-group mb-2">
                        <li
                            class="list-group-item cell one-cell"
                            [class.text-muted]="
                                !enquiryFlights[0].flightBriefCateringId &&
                                !enquiryFlights[0].catering
                            "
                            [innerText]="
                                enquiryFlights[0].flightBriefCateringId &&
                                flightBriefCateringsObj[enquiryFlights[0].flightBriefCateringId]
                                    ? flightBriefCateringsObj[
                                          enquiryFlights[0].flightBriefCateringId
                                      ]?.textByLanguage[flightBrief.language]
                                    : enquiryFlights[0].catering || 'N/A'
                            "
                        ></li>
                    </ul>
                </div>
                <div class="col-md-6 col-lg-4">
                    <div class="header-cells">
                        <fa-icon [icon]="faSuitcaseRolling"></fa-icon>
                        {{ translationObj[flightBrief.language]?.FLIGHT_BRIEF.LUGGAGES }}
                    </div>
                    <ul class="list-group">
                        <li
                            class="list-group-item cell one-cell"
                            [class.text-muted]="!enquiryFlights[0].luggages"
                            [innerText]="enquiryFlights[0].luggages || 'N/A'"
                            *ngIf="enquiryFlights[0].luggageIsCustom"
                        ></li>
                        <li
                            class="list-group-item cell one-cell"
                            *ngIf="!enquiryFlights[0].luggageIsCustom"
                            [innerText]="
                                enquiryFlights[0].luggageQty +
                                'X' +
                                enquiryFlights[0].luggagesWeight +
                                translationObj[flightBrief.language]?.FLIGHT_BRIEF.KG_BY_PAX
                            "
                        ></li>
                    </ul>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<div
    class="card mb-2"
    *ngIf="
        enquiry?.type === EnumEnquiryType.cargo &&
        ((trips.length && !trips[0].isFerryFlight) ||
            (enquiryFlights.length && !enquiryFlights[0].isFerryFlight))
    "
>
    <div class="card-body" *ngIf="enquiryFlights.length">
        <div class="row">
            <div class="col-md-6">
                <div class="header-cells">
                    {{ translationObj[flightBrief.language]?.FLIGHT_BRIEF.CARGO_DETAILS }}
                </div>

                <ul class="list-group mb-2">
                    <li class="list-group-item cell">
                        <dl class="mb-0 row">
                            <dt
                                class="col-6"
                                [innerText]="
                                    translationObj[flightBrief.language]?.FLIGHT_BRIEF.CARGO_TYPE
                                "
                            ></dt>
                            <dd
                                class="col-6"
                                [class.text-muted]="!enquiryFlights[0].typeOfCargo"
                                [innerText]="enquiryFlights[0].typeOfCargo || 'N/A'"
                            ></dd>
                            <dt
                                class="col-6"
                                [innerText]="
                                    translationObj[flightBrief.language]?.FLIGHT_BRIEF.WEIGHT
                                "
                            ></dt>
                            <dd
                                class="col-6"
                                [class.text-muted]="!enquiryFlights[0].weight"
                                [innerText]="
                                    enquiryFlights[0].weight
                                        ? enquiryFlights[0].weight + ' Kgs'
                                        : 'N/A'
                                "
                            ></dd>
                            <dt
                                class="col-6"
                                [innerText]="
                                    translationObj[flightBrief.language]?.FLIGHT_BRIEF.VOLUME
                                "
                            ></dt>
                            <dd
                                class="col-6"
                                [class.text-muted]="!enquiryFlights[0].volume"
                                [innerHtml]="
                                    enquiryFlights[0].volume
                                        ? enquiryFlights[0].volume + ' m<sup>3</sup>'
                                        : 'N/A'
                                "
                            ></dd>
                            <dt
                                class="col-6 m-0"
                                [innerText]="
                                    translationObj[flightBrief.language]?.FLIGHT_BRIEF.NB_PACKAGES
                                "
                            ></dt>
                            <dd
                                class="col-6 m-0"
                                [class.text-muted]="!enquiryFlights[0].nbPackages"
                                [innerText]="
                                    enquiryFlights[0].nbPackages
                                        ? enquiryFlights[0].nbPackages +
                                          ' ' +
                                          translationObj[flightBrief.language]?.FLIGHT_BRIEF.ITEMS
                                        : 'N/A'
                                "
                            ></dd>
                        </dl>
                    </li>
                </ul>
            </div>
            <div class="col-md-6">
                <div class="header-cells">
                    {{ translationObj[flightBrief.language]?.FLIGHT_BRIEF.AWB_NUMBERS }}
                </div>
                <ul class="list-group mb-2">
                    <li
                        class="list-group-item cell"
                        [class.text-muted]="!enquiryFlights[0].awbNumber"
                        [innerText]="enquiryFlights[0].awbNumber ?? 'N/A'"
                    ></li>
                </ul>
                <div class="header-cells">
                    {{ translationObj[flightBrief.language]?.FLIGHT_BRIEF.CUSTOMS_DECLARATION }}
                </div>
                <ul class="list-group mb-2">
                    <li
                        class="list-group-item cell"
                        [class.text-muted]="!enquiryFlights[0].customDeclaration"
                        [innerText]="enquiryFlights[0].customDeclaration ?? 'N/A'"
                    ></li>
                </ul>
                <div class="header-cells">
                    {{ translationObj[flightBrief.language]?.FLIGHT_BRIEF.DGD }}
                </div>
                <ul class="list-group mb-2">
                    <li
                        class="list-group-item cell"
                        [innerText]="
                            enquiryFlights[0].dgd
                                ? translationObj[flightBrief.language]?.FLIGHT_BRIEF.YES
                                : translationObj[flightBrief.language]?.FLIGHT_BRIEF.NO
                        "
                    ></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="card-body" *ngIf="!enquiryFlights.length && flightBrief">
        <div class="row">
            <div class="col-md-6">
                <div class="header-cells">
                    {{ translationObj[flightBrief.language]?.FLIGHT_BRIEF.CARGO_DETAILS }}
                </div>

                <ul class="list-group mb-2">
                    <li class="list-group-item cell">
                        <dl class="mb-0 row">
                            <dt
                                class="col-6"
                                [innerText]="
                                    translationObj[flightBrief.language]?.FLIGHT_BRIEF.CARGO_TYPE
                                "
                            ></dt>
                            <dd
                                class="col-6"
                                [class.text-muted]="!flightBrief.typeOfCargo"
                                [innerText]="flightBrief.typeOfCargo || 'N/A'"
                            ></dd>
                            <dt
                                class="col-6"
                                [innerText]="
                                    translationObj[flightBrief.language]?.FLIGHT_BRIEF.WEIGHT
                                "
                            ></dt>
                            <dd
                                class="col-6"
                                [class.text-muted]="!flightBrief.weightInKg"
                                [innerText]="
                                    flightBrief.weightInKg ? flightBrief.weightInKg + ' Kgs' : 'N/A'
                                "
                            ></dd>
                            <dt
                                class="col-6"
                                [innerText]="
                                    translationObj[flightBrief.language]?.FLIGHT_BRIEF.VOLUME
                                "
                            ></dt>
                            <dd
                                class="col-6"
                                [class.text-muted]="!flightBrief.volumeInM3"
                                [innerHtml]="
                                    flightBrief.volumeInM3
                                        ? flightBrief.volumeInM3 + ' m<sup>3</sup>'
                                        : 'N/A'
                                "
                            ></dd>
                            <dt
                                class="col-6 m-0"
                                [innerText]="
                                    translationObj[flightBrief.language]?.FLIGHT_BRIEF.NB_PACKAGES
                                "
                            ></dt>
                            <dd
                                class="col-6 m-0"
                                [class.text-muted]="!flightBrief.nbPackages"
                                [innerText]="
                                    flightBrief.nbPackages
                                        ? flightBrief.nbPackages +
                                          ' ' +
                                          translationObj[flightBrief.language]?.FLIGHT_BRIEF.ITEMS
                                        : 'N/A'
                                "
                            ></dd>
                        </dl>
                    </li>
                </ul>
            </div>
            <div class="col-md-6">
                <div class="header-cells">
                    {{ translationObj[flightBrief.language]?.FLIGHT_BRIEF.AWB_NUMBERS }}
                </div>
                <ul class="list-group mb-2">
                    <li
                        class="list-group-item cell"
                        [class.text-muted]="!flightBrief.awbNumber"
                        [innerText]="flightBrief.awbNumber ?? 'N/A'"
                    ></li>
                </ul>
                <div class="header-cells">
                    {{ translationObj[flightBrief.language]?.FLIGHT_BRIEF.CUSTOMS_DECLARATION }}
                </div>
                <ul class="list-group mb-2">
                    <li
                        class="list-group-item cell"
                        [class.text-muted]="!flightBrief.customDeclaration"
                        [innerText]="flightBrief.customDeclaration ?? 'N/A'"
                    ></li>
                </ul>
                <div class="header-cells">
                    {{ translationObj[flightBrief.language]?.FLIGHT_BRIEF.DGD }}
                </div>
                <ul class="list-group mb-2">
                    <li
                        class="list-group-item cell"
                        [innerText]="
                            flightBrief.dgd
                                ? translationObj[flightBrief.language]?.FLIGHT_BRIEF.YES
                                : translationObj[flightBrief.language]?.FLIGHT_BRIEF.NO
                        "
                    ></li>
                </ul>
            </div>
        </div>
    </div>
</div>
<div class="card mb-2" *ngIf="!enquiryFlights?.length && trips.length && !trips[0].isFerryFlight">
    <div class="card-body">
        <div class="row">
            <div class="col-md-6">
                <ng-container
                    *ngFor="
                        let airportAddressDetails of getDisplayedAirportsAddressDetailsForTrip(
                            trips[0]
                        )
                    "
                >
                    <div class="header-cells">
                        {{ getAirportCity(airportsObj[airportAddressDetails.airportId]) }}
                    </div>
                    <ul class="list-group mb-2">
                        <li
                            class="list-group-item cell one-cell"
                            [innerHTML]="airportAddressDetails.text"
                        ></li>
                    </ul>
                </ng-container>
            </div>
            <div class="col-md-6">
                <div class="header-cells">
                    {{ translationObj[flightBrief.language]?.FLIGHT_BRIEF.ROUTING }}
                </div>
                <div class="google-container">
                    <img
                        [src]="imgsToBase64.googleMapStaticUrls[trips[0].id]"
                        *ngIf="imgsToBase64.googleMapStaticUrls[trips[0].id]"
                        height="240"
                    />
                </div>
            </div>
        </div>
    </div>
</div>
<div class="card mb-2" *ngIf="enquiryFlights.length && !enquiryFlights[0].isFerryFlight">
    <div class="card-body">
        <div class="row">
            <div class="col-md-6">
                <ng-container
                    *ngFor="
                        let airportAddressDetails of getDisplayedAirportsAddressDetailsForEnquiryFlight(
                            enquiryFlights[0]
                        )
                    "
                >
                    <div class="header-cells">
                        {{ getAirportCity(airportAddressDetails.airport) }}
                    </div>
                    <ul class="list-group mb-2">
                        <li
                            class="list-group-item cell one-cell"
                            [innerHTML]="
                                getFboAddressDetails(
                                    airportAddressDetails.fbo,
                                    flightBrief.language
                                )
                            "
                        ></li>
                    </ul>
                </ng-container>
            </div>
            <div class="col-md-6">
                <div class="header-cells">
                    {{ translationObj[flightBrief.language]?.FLIGHT_BRIEF.ROUTING }}
                </div>
                <div class="google-container">
                    <img
                        [src]="imgsToBase64.googleMapStaticUrls[enquiryFlights[0].id]"
                        *ngIf="imgsToBase64.googleMapStaticUrls[enquiryFlights[0].id]"
                        height="240"
                    />
                </div>
            </div>
        </div>
    </div>
</div>

<div class="card mb-2" *ngIf="!enquiryFlights?.length && trips.length">
    <div class="card-body">
        <div class="row header-cells text-center">
            <div
                class="col-3"
                *ngIf="flightBrief.displayAirlineOnFlightBrief"
                [innerText]="translationObj[flightBrief.language]?.FLIGHT_BRIEF.AIRLINE"
            ></div>
            <div
                class="col-3"
                [innerText]="translationObj[flightBrief.language]?.FLIGHT_BRIEF.AIRCRAFT"
            ></div>
            <div
                [class.col-2]="enquiry?.type !== EnumEnquiryType.cargo"
                [class.col-3]="enquiry?.type === EnumEnquiryType.cargo"
                [innerText]="translationObj[flightBrief.language]?.FLIGHT_BRIEF.REGISTRATION"
            ></div>
            <ng-container *ngIf="enquiry?.type !== EnumEnquiryType.cargo">
                <div
                    class="col-2"
                    [innerText]="translationObj[flightBrief.language]?.FLIGHT_BRIEF.WC"
                ></div>
                <div
                    class="col-2"
                    [innerText]="translationObj[flightBrief.language]?.FLIGHT_BRIEF.SMOKING"
                ></div>
            </ng-container>
            <ng-container *ngIf="enquiry?.type === EnumEnquiryType.cargo">
                <div
                    class="col-3"
                    [innerText]="translationObj[flightBrief.language]?.FLIGHT_BRIEF.STATUS"
                ></div>
            </ng-container>
        </div>
        <ul class="list-group mb-2">
            <li class="list-group-item cell">
                <div class="row justify-content-center">
                    <div
                        class="col-3"
                        *ngIf="flightBrief.displayAirlineOnFlightBrief"
                        [innerText]="flightBrief.airlineTitle"
                    ></div>
                    <div class="col-3" [innerText]="flightBrief.aircraftModelTitle"></div>
                    <div
                        [class.col-2]="enquiry?.type !== EnumEnquiryType.cargo"
                        [class.col-3]="enquiry?.type === EnumEnquiryType.cargo"
                        [class.text-muted]="!flightBrief.aircraftRegistration"
                        [innerText]="flightBrief.aircraftRegistration || 'N/A'"
                    ></div>
                    <ng-container *ngIf="enquiry?.type !== EnumEnquiryType.cargo">
                        <div
                            class="col-2"
                            [innerText]="
                                flightBrief.aircraftHasToilet
                                    ? translationObj[flightBrief.language]?.FLIGHT_BRIEF.YES
                                    : translationObj[flightBrief.language]?.FLIGHT_BRIEF.NO
                            "
                        ></div>
                        <div
                            class="col-2"
                            [innerText]="
                                flightBrief.smokingIsAllowed
                                    ? translationObj[flightBrief.language]?.FLIGHT_BRIEF.YES
                                    : translationObj[flightBrief.language]?.FLIGHT_BRIEF.NO
                            "
                        ></div>
                    </ng-container>
                    <ng-container *ngIf="enquiry?.type === EnumEnquiryType.cargo">
                        <div
                            class="col-3"
                            [innerText]="translationObj[flightBrief.language]?.FLIGHT_BRIEF.LOADED"
                        ></div>
                    </ng-container>
                </div>
            </li>
        </ul>

        <div class="row header-cells text-center" *ngIf="enquiry?.type !== EnumEnquiryType.cargo">
            <div
                class="col-4"
                [innerText]="translationObj[flightBrief.language]?.FLIGHT_BRIEF.CAPTAIN"
            ></div>
            <div
                class="col-4"
                [innerText]="translationObj[flightBrief.language]?.FLIGHT_BRIEF.FIRST_OFFICER"
            ></div>
            <div
                class="col-4"
                [innerText]="translationObj[flightBrief.language]?.FLIGHT_BRIEF.FLIGHT_ATTENDANT"
            ></div>
        </div>
        <ul class="list-group mb-2" *ngIf="enquiry?.type !== EnumEnquiryType.cargo">
            <li class="list-group-item cell">
                <div class="row">
                    <div
                        class="col-4"
                        [class.text-muted]="!flightBrief.pilot"
                        [innerText]="flightBrief.pilot"
                    ></div>
                    <div
                        class="col-4"
                        [class.text-muted]="!flightBrief.coPilot"
                        [innerText]="flightBrief.coPilot"
                    ></div>
                    <div
                        class="col-4"
                        [class.text-muted]="!flightBrief.cabinCrew"
                        [innerText]="flightBrief.cabinCrew"
                    ></div>
                </div>
            </li>
        </ul>

        <div class="images-containers">
            <ng-container *ngFor="let imageField of ['imageOutside', 'imageInside']">
                <div class="image-container" *ngIf="imgsToBase64[imageField + 'Url']">
                    <div
                        class="image"
                        [style.background-image]="'url(' + imgsToBase64[imageField + 'Url'] + ')'"
                        [style.webkitTransform]="
                            'scale(' + flightBrief[imageField + 'ZoomLevel'] + ')'
                        "
                        [style.transform]="'scale(' + flightBrief[imageField + 'ZoomLevel'] + ')'"
                    ></div>
                </div>
            </ng-container>
        </div>

        <div
            class="header-cells"
            [innerText]="translationObj[flightBrief.language]?.FLIGHT_BRIEF.COMMENTS"
        ></div>
        <ul class="list-group">
            <li
                class="list-group-item cell one-cell"
                [class.text-muted]="!flightBrief.comments || flightBrief.comments.trim() === ''"
                [innerText]="
                    flightBrief.comments && flightBrief.comments.trim() !== ''
                        ? flightBrief.comments
                        : translationObj[flightBrief.language]?.FLIGHT_BRIEF.NO_COMMENT
                "
            ></li>
        </ul>
    </div>
</div>

<div class="card mb-2" *ngIf="enquiryFlights.length">
    <div class="card-body">
        <div class="row header-cells text-center">
            <div
                class="col-3"
                *ngIf="enquiryFlights[0].displayAirlineOnFlightBrief"
                [innerText]="translationObj[flightBrief.language]?.FLIGHT_BRIEF.AIRLINE"
            ></div>
            <div
                class="col-3"
                [innerText]="translationObj[flightBrief.language]?.FLIGHT_BRIEF.AIRCRAFT"
            ></div>
            <div
                [class.col-2]="enquiryFlights[0].enquiryType !== EnumEnquiryType.cargo"
                [class.col-3]="enquiryFlights[0].enquiryType === EnumEnquiryType.cargo"
                [innerText]="translationObj[flightBrief.language]?.FLIGHT_BRIEF.REGISTRATION"
            ></div>
            <ng-container *ngIf="enquiryFlights[0].enquiryType !== EnumEnquiryType.cargo">
                <div
                    class="col-2"
                    [innerText]="translationObj[flightBrief.language]?.FLIGHT_BRIEF.WC"
                ></div>
                <div
                    class="col-2"
                    [innerText]="translationObj[flightBrief.language]?.FLIGHT_BRIEF.SMOKING"
                ></div>
            </ng-container>
            <ng-container *ngIf="enquiryFlights[0].enquiryType === EnumEnquiryType.cargo">
                <div
                    class="col-3"
                    [innerText]="translationObj[flightBrief.language]?.FLIGHT_BRIEF.STATUS"
                ></div>
            </ng-container>
        </div>
        <ul class="list-group mb-2">
            <li class="list-group-item cell">
                <div class="row justify-content-center">
                    <div
                        class="col-3"
                        *ngIf="enquiryFlights[0].displayAirlineOnFlightBrief"
                        [innerText]="enquiryFlights[0].airlineTitle"
                    ></div>
                    <div class="col-3" [innerText]="enquiryFlights[0].aircraftModelTitle"></div>
                    <div
                        [class.col-2]="enquiryFlights[0].enquiryType !== EnumEnquiryType.cargo"
                        [class.col-3]="enquiryFlights[0].enquiryType === EnumEnquiryType.cargo"
                        [class.text-muted]="!enquiryFlights[0].aircraftRegistration"
                        [innerText]="enquiryFlights[0].aircraftRegistration || 'N/A'"
                    ></div>
                    <ng-container *ngIf="enquiryFlights[0].enquiryType !== EnumEnquiryType.cargo">
                        <div
                            class="col-2"
                            [innerText]="
                                enquiryFlights[0].aircraftHasToilet
                                    ? translationObj[flightBrief.language]?.FLIGHT_BRIEF.YES
                                    : translationObj[flightBrief.language]?.FLIGHT_BRIEF.NO
                            "
                        ></div>
                        <div
                            class="col-2"
                            [innerText]="
                                enquiryFlights[0].smokingIsAllowed
                                    ? translationObj[flightBrief.language]?.FLIGHT_BRIEF.YES
                                    : translationObj[flightBrief.language]?.FLIGHT_BRIEF.NO
                            "
                        ></div>
                    </ng-container>
                    <ng-container *ngIf="enquiryFlights[0].enquiryType === EnumEnquiryType.cargo">
                        <div
                            class="col-3"
                            [innerText]="translationObj[flightBrief.language]?.FLIGHT_BRIEF.LOADED"
                        ></div>
                    </ng-container>
                </div>
            </li>
        </ul>

        <div
            class="row header-cells text-center"
            *ngIf="enquiryFlights[0].enquiryType !== EnumEnquiryType.cargo"
        >
            <div
                class="col-4"
                [innerText]="translationObj[flightBrief.language]?.FLIGHT_BRIEF.CAPTAIN"
            ></div>
            <div
                class="col-4"
                [innerText]="translationObj[flightBrief.language]?.FLIGHT_BRIEF.FIRST_OFFICER"
            ></div>
            <div
                class="col-4"
                [innerText]="translationObj[flightBrief.language]?.FLIGHT_BRIEF.FLIGHT_ATTENDANT"
            ></div>
        </div>
        <ul class="list-group mb-2" *ngIf="enquiryFlights[0].enquiryType !== EnumEnquiryType.cargo">
            <li class="list-group-item cell">
                <div class="row">
                    <div class="col-4" [class.text-muted]="!enquiryFlights[0].captainId">
                        <span *ngIf="!enquiryFlights[0].captainId">N/A</span>
                        <span *ngIf="enquiryFlights[0].captainId">
                            {{
                                crewsObj[enquiryFlights[0].captainId]?.lastname +
                                    " " +
                                    crewsObj[enquiryFlights[0].captainId]?.firstname
                            }}<br />
                            {{ crewsObj[enquiryFlights[0].captainId]?.phoneNumber }}
                        </span>
                    </div>
                    <div class="col-4" [class.text-muted]="!enquiryFlights[0].coPilotId">
                        <span *ngIf="!enquiryFlights[0].coPilotId">N/A</span>
                        <span *ngIf="enquiryFlights[0].coPilotId">
                            {{
                                crewsObj[enquiryFlights[0].coPilotId]?.lastname +
                                    " " +
                                    crewsObj[enquiryFlights[0].coPilotId]?.firstname
                            }}<br />
                            {{ crewsObj[enquiryFlights[0].coPilotId]?.phoneNumber }}
                        </span>
                    </div>
                    <div
                        class="col-4"
                        [class.text-muted]="!enquiryFlights[0].stewardessIds?.length"
                    >
                        <div *ngIf="!enquiryFlights[0].stewardessIds?.length">N/A</div>
                        <div *ngFor="let stewardessId of enquiryFlights[0].stewardessIds">
                            {{
                                crewsObj[stewardessId]?.lastname +
                                    " " +
                                    crewsObj[stewardessId]?.firstname
                            }}<br />
                            {{ crewsObj[stewardessId]?.phoneNumber }}
                        </div>
                    </div>
                </div>
            </li>
        </ul>

        <div class="images-containers">
            <ng-container *ngFor="let imageField of ['imageOutside', 'imageInside']">
                <div class="image-container" *ngIf="imgsToBase64[imageField + 'Url']">
                    <div
                        class="image"
                        [style.background-image]="'url(' + imgsToBase64[imageField + 'Url'] + ')'"
                        [style.webkitTransform]="
                            'scale(' + flightBrief[imageField + 'ZoomLevel'] + ')'
                        "
                        [style.transform]="'scale(' + flightBrief[imageField + 'ZoomLevel'] + ')'"
                    ></div>
                </div>
            </ng-container>
        </div>

        <div
            class="header-cells"
            [innerText]="translationObj[flightBrief.language]?.FLIGHT_BRIEF.COMMENTS"
        ></div>
        <ul class="list-group">
            <li
                class="list-group-item cell one-cell"
                [class.text-muted]="!flightBrief.comments || flightBrief.comments.trim() === ''"
                [innerText]="
                    flightBrief.comments && flightBrief.comments.trim() !== ''
                        ? flightBrief.comments
                        : translationObj[flightBrief.language]?.FLIGHT_BRIEF.NO_COMMENT
                "
            ></li>
        </ul>
    </div>
</div>

<div class="page-break"></div>
