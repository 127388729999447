import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { RemoteService } from '../../../services/remote.service';
import { IUser } from 'src/app/interfaces/user.interface';

@Component({
  selector: 'app-edit-password',
  templateUrl: './edit-password.component.html',
  styleUrls: ['./edit-password.component.scss']
})
export class EditPasswordComponent implements OnInit {
  form: FormGroup;
  sending: boolean = false;
  user: IUser;
  originalEmail: string;

  constructor(
    private formBuilder: FormBuilder,
    private remoteService: RemoteService,
    private router: Router
  ) {
    this.remoteService.userObservable.subscribe((user: IUser) => {
      if (user) {
        this.user = user;

        this.setuser();
      }
    });
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      oldPassword: ['', [Validators.required, Validators.minLength(6)]],
      newPassword: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(6)]]
    });

    this.form.disable();

    this.setuser();
  }

  setuser(): void {
    if (this.form && this.user) {
      if (!this.originalEmail) {
        this.originalEmail = this.user.email;
      }

      this.form.enable();
    }
  }

  submitForm(): void {
    this.form.markAsTouched();

    if (!this.checkPasswords()) {
      alert('Les mots de passe ne doivent pas être différents.');
    } else {
      if (this.form.valid) {
        this.sending = true;
        this.form.disable();

        this.remoteService
          .updateCurrentUserPassword(
            this.originalEmail,
            this.form.value['oldPassword'],
            this.form.value['newPassword']
          )
          .then(() => {
            alert('Le mot de passe a été modifié avec succès');
            this.router.navigate(['/admin/my-account']);
          })
          .catch(err => {
            console.log(err);

            this.sending = false;
            this.form.enable();

            let errorMessage = err.message;
            switch (errorMessage) {
              case 'auth/wrong-password':
                errorMessage = "L'ancien mot de passe est incorrect.";
                break;
              case 'auth/weak-password':
                errorMessage = 'Le nouveau mot de passe doit faire au moins 6 caractères.';
                break;
              case 'auth/email-already-exists':
                errorMessage = "L'adresse email est déjà utilisé par un autre compte.";
                break;
            }

            alert(errorMessage);
          });
      }
    }
  }

  private checkPasswords(): boolean {
    let pass = this.form.get('newPassword').value;
    let confirmPass = this.form.get('confirmPassword').value;

    return pass === confirmPass;
  }
}
