import { Injectable } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import firebase from 'firebase/compat/app';
import { AirportFirestoreService } from './airports-firestore.service';
import { IAirport } from 'src/app/interfaces/airport.interface';

@Injectable({
  providedIn: 'root'
})
export class AirportService {
  constructor(private airportFirestoreService: AirportFirestoreService) {}

  create(Airport: IAirport): Promise<string> {
    return this.airportFirestoreService.create(Airport);
  }

  createWithId(id: string, Airport: IAirport): Promise<string> {
    return this.airportFirestoreService.createWithId(id, Airport);
  }

  update(Airport: IAirport): Promise<string> {
    return this.airportFirestoreService.update(Airport);
  }

  delete(id: string): Promise<void> {
    return this.airportFirestoreService.delete(id);
  }

  getFromId(id: string): Observable<IAirport | null> {
    return this.airportFirestoreService.docValue$(id);
  }

  getAll(): Observable<IAirport[]> {
    return this.airportFirestoreService.collectionValues$();
  }

  resetPagination(paginationName: string = 'list'): void {
    this.airportFirestoreService.resetPaginate(paginationName);
  }

  getAllMostRecentFirst(
    paginationName: string = 'list',
    conditions: {
      field: string;
      operator: firebase.firestore.WhereFilterOp;
      value: any;
    }[] = []
  ): Observable<IAirport[]> {
    const orderBy: {
      field: string;
      direction: 'asc' | 'desc';
    } = {
      field: 'modified',
      direction: 'desc'
    };

    return this.airportFirestoreService.paginate(conditions, orderBy, 20, paginationName);
  }
}
