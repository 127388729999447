import { finalize } from 'rxjs/operators';
import {
  Component,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  AbstractControl,
  FormControl
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { RemoteService } from '../../../../services/remote.service';
import { AclService } from '../../../../services/acl.service';
import { EnumEnquiryTarget, getEnquiryTargetLabel } from 'src/app/enums/enquiry-target.enum';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { IAircraftModel } from 'src/app/interfaces/aircraft-model.interface';
import { generateRandomId } from 'src/app/misc.utils';

@Component({
  selector: 'app-aircraft-model-edit',
  templateUrl: './aircraft-model-edit.component.html',
  styleUrls: ['./aircraft-model-edit.component.scss']
})
export class AircraftModelEditComponent implements OnChanges {
  @ViewChild('imageUrlInput', { static: false }) imageUrlInputElement: ElementRef;
  @ViewChild('imageInsideUrlInput', { static: false }) imageInsideUrlInputElement: ElementRef;
  @ViewChild('imagePlanUrlInput', { static: false }) imagePlanUrlInputElement: ElementRef;

  EnumEnquiryTarget = EnumEnquiryTarget;

  getEnquiryTargetLabel = getEnquiryTargetLabel;

  isLogged: boolean = false;
  form: FormGroup;
  sending: boolean = false;
  aircraftModel: IAircraftModel | null = null;
  aircraftModelId: string;

  @Input('inModal') inModal: boolean = false;
  private oldTriggerSaveAicraftModal: boolean = true;
  @Input('triggerSaveAicraftModal') triggerSaveAicraftModal: boolean = false;
  @Output() getCreatedAircraftModel: EventEmitter<string> = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private remoteService: RemoteService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private aclService: AclService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );

    this.form = this.formBuilder.group({
      title: ['', [Validators.required]],
      slug: ['', [Validators.required]],
      family: ['', [Validators.required]],
      target: ['', [Validators.required]],
      speedInKts: [''],
      rangeInKms: [''],
      weight: [0],
      volume: [0],
      imageUrl: [''],
      imageInsideUrl: [''],
      imagePlanUrl: ['']
    });

    this.form.disable();

    this.activatedRoute.url.subscribe(async () => {
      this.aircraftModelId = this.activatedRoute.snapshot.paramMap.get('aircraftModelId');

      if (!this.aircraftModelId) {
        await this.aclService.checkAclAccess(EnumAcl.aircraftModelsAdd);
        this.form.enable();
      } else {
        await this.aclService.checkAclAccess(EnumAcl.aircraftModelsEdit);
      }

      this.loadData();
    });
  }

  ngOnChanges() {
    if (this.oldTriggerSaveAicraftModal !== this.triggerSaveAicraftModal) {
      this.oldTriggerSaveAicraftModal = this.triggerSaveAicraftModal;

      this.submitForm();
    }
  }

  getEnquiryTargets(): EnumEnquiryTarget[] {
    return Object.values(EnumEnquiryTarget);
  }

  async loadData(): Promise<void> {
    if (this.isLogged) {
      if (this.aircraftModelId) {
        this.loadAircraftModel();
      }
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  async loadAircraftModel(): Promise<void> {
    const doc = await this.remoteService.getDocument('aircraftModels', this.aircraftModelId);

    this.aircraftModel = doc as IAircraftModel;

    this.setAircraftModel();
  }

  setAircraftModel(): void {
    if (this.form && this.aircraftModel) {
      this.form.get('slug').setValue(this.aircraftModel.slug);
      this.form.get('title').setValue(this.aircraftModel.title);
      this.form.get('family').setValue(this.aircraftModel.family);
      this.form.get('target').setValue(this.aircraftModel.target);
      this.form.get('speedInKts').setValue(this.aircraftModel.speedInKts);
      this.form.get('rangeInKms').setValue(this.aircraftModel.rangeInKms);
      this.form.get('weight').setValue(this.aircraftModel.weight);
      this.form.get('volume').setValue(this.aircraftModel.volume);
      this.form.get('imageUrl').setValue(this.aircraftModel.imageUrl);
      this.form.get('imageInsideUrl').setValue(this.aircraftModel.imageInsideUrl);
      this.form.get('imagePlanUrl').setValue(this.aircraftModel.imagePlanUrl);

      this.form.enable();
    }
  }

  async submitForm(): Promise<void> {
    this.form.markAsTouched();

    if (this.form.valid) {
      let data = Object.assign({}, this.form.value);

      for (const field in data) {
        if (typeof data[field] == 'undefined') {
          data[field] = null;
        }
      }

      this.sending = true;

      this.form.disable();

      if (this.aircraftModelId) {
        this.remoteService
          .updateDocumentToCollection('aircraftModels', this.aircraftModelId, data)
          .then(async () => {
            await this.remoteService.loadAircraftModels();

            this.sending = false;
            await this.afterSave();
          })
          .catch(err => {
            this.sending = false;
            this.form.enable();

            alert(err.message);
          });
      } else {
        this.remoteService
          .addDocumentToCollection('aircraftModels', data)
          .then(async (docId: string) => {
            this.aircraftModelId = docId;

            await this.remoteService.loadAircraftModels();

            this.sending = false;
            await this.afterSave();
          })
          .catch(err => {
            this.sending = false;
            this.form.enable();

            alert(err.message);
          });
      }
    }
  }

  private async afterSave(): Promise<void> {
    if (this.inModal) {
      this.form.reset();
      this.getCreatedAircraftModel.emit(this.aircraftModelId);
    } else {
      this.router.navigate(['/admin/aircraft-models/' + this.aircraftModelId]);
    }
  }

  updateTitle(): void {
    if (!this.aircraftModelId) {
      // Only for new aircraft model
      this.form.get('slug').setValue(this.slugify(this.form.value.title));
    }
  }

  triggerInputFile(fileFieldName: string): void {
    if (this.form.enabled) {
      document.getElementById(fileFieldName + 'Input').click();
    }
  }

  changeInputFile(fileFieldName: string, fileInput: any): void {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.upload(fileFieldName, fileInput.target.files[0]);
    }
  }

  upload(fileFieldName: string, file: File): Promise<void> {
    return new Promise((resolve, reject) => {
      this.form.disable();

      const result = this.remoteService.upload(
        'aircraft-models/images',
        generateRandomId(),
        file,
        'file'
      );

      result['task']
        .snapshotChanges()
        .pipe(
          finalize(() => {
            result['ref'].getDownloadURL().subscribe((downloadUrl: string) => {
              this.form.get(fileFieldName).setValue(downloadUrl);

              (<HTMLInputElement>document.getElementById(fileFieldName + 'Input')).value = '';

              this.form.enable();

              this.form.updateValueAndValidity();
            });
          })
        )
        .subscribe();
    });
  }

  deleteImage(fileFieldName: string): void {
    if (confirm('Êtes-vous sûr de vouloir supprimer cette image ?')) {
      this.form.get(fileFieldName).setValue(null);
    }
  }

  private slugify(...args: (string | number)[]): string {
    const value = args.join(' ');

    return value
      .normalize('NFD') // split an accented letter in the base letter and the acent
      .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
      .toLowerCase()
      .trim()
      .replace(/[^a-z0-9 +]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
      .replace(/\s+/g, '-'); // separator
  }
}
