<form (submit)="saveAndNext()" [formGroup]="form" (keydown.enter)="saveAndNext()" class="main-form">
    <div class="card mb-4">
        <div class="card-header"><fa-icon [icon]="faPlane"></fa-icon> Appareil</div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group mb-0">
                        <label for="countryCode">Compagnie</label>
                        <app-airline-autocomplete
                            (setValueToFormControl)="setValueToFormControl($event)"
                            inputName="airlineId"
                            [defaultValue]="
                                airlineId.value && airlinesObj[airlineId.value]
                                    ? airlinesObj[airlineId.value]
                                    : null
                            "
                            [disabled]="form.disabled"
                            placeholder="Recherchez une compagnie aérienne"
                            [noMargin]="true"
                        ></app-airline-autocomplete>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || airlineId.touched) &&
                                    airlineId.errors &&
                                    airlineId.errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                    <div class="form-check mb-2" *ngIf="form.value.airlineId">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="displayAirlineOnFlightBrief"
                            formControlName="displayAirlineOnFlightBrief"
                        />
                        <label class="form-check-label" for="displayAirlineOnFlightBrief"
                            >Afficher sur le flight brief</label
                        >
                    </div>
                </div>
                <div class="col-md-4" *ngIf="airlineId.value">
                    <div class="form-group">
                        <label for="aircraftModelId">Appareil</label>

                        <div class="placeholder w-100" *ngIf="loadingAircraftsCompiled"></div>
                        <div
                            *ngIf="!loadingAircraftsCompiled && !aircraftModels.length"
                            class="alert alert-warning alert-sm text-center"
                        >
                            Aucun appareil trouvé
                        </div>
                        <select
                            class="form-select"
                            id="aircraftModelId"
                            formControlName="aircraftModelId"
                            [class.is-invalid]="
                                (form.touched || aircraftModelId.touched) &&
                                aircraftModelId.status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                            (change)="updatedAircraftModelId()"
                            *ngIf="!loadingAircraftsCompiled && aircraftModels.length"
                        >
                            <option [ngValue]="null">-- Choisissez --</option>
                            <option
                                *ngFor="let aircraftModel of aircraftModels"
                                [value]="aircraftModel.id"
                                [innerText]="aircraftModel.title"
                            ></option>
                        </select>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || aircraftModelId.touched) &&
                                    aircraftModelId.errors &&
                                    aircraftModelId.errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4" *ngIf="aircraftModelId.value">
                    <div class="form-group">
                        <label for="aircraftId">
                            Immatriculation
                            <button
                                type="button"
                                (click)="openAircraftAddModal()"
                                class="btn btn-icon"
                                *ngIf="hasAclAccess(EnumAcl.aircraftsAdd)"
                                [disabled]="loadingAircrafts"
                            >
                                <fa-icon [icon]="faCirclePlus"></fa-icon>
                            </button>
                        </label>

                        <div class="placeholder w-100" *ngIf="loadingAircrafts"></div>
                        <div
                            *ngIf="!loadingAircrafts && !aircrafts.length"
                            class="alert alert-warning alert-sm text-center"
                        >
                            Aucun appareil trouvé
                        </div>
                        <select
                            class="form-select"
                            id="aircraftId"
                            formControlName="aircraftId"
                            [class.is-invalid]="
                                (form.touched || aircraftId.touched) &&
                                aircraftId.status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                            (change)="updatedAircraftId()"
                            *ngIf="!loadingAircrafts && aircrafts.length"
                        >
                            <option [ngValue]="null">-- Choisissez --</option>
                            <option
                                *ngFor="let aircraft of aircrafts"
                                [value]="aircraft.id"
                                [innerText]="
                                    aircraft.registration !== ''
                                        ? aircraft.registration
                                        : 'Immatriculation inconnu (id : ' + aircraft.id + ')'
                                "
                            ></option>
                        </select>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || aircraftId.touched) &&
                                    aircraftId.errors &&
                                    aircraftId.errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="row justify-content-center"
                *ngIf="enquiry?.type !== EnumEnquiryType.cargo && aircraftId.value"
            >
                <div class="col-md-2">
                    <div class="form-group mb-md-0">
                        <label for="aircraftHasToilet">WC</label>
                        <select
                            class="form-select"
                            id="aircraftHasToilet"
                            formControlName="aircraftHasToilet"
                            [class.is-invalid]="
                                (form.touched || aircraftHasToilet.touched) &&
                                aircraftHasToilet.status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        >
                            <option [ngValue]="true">Oui</option>
                            <option [ngValue]="false">Non</option>
                        </select>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || aircraftHasToilet.touched) &&
                                    aircraftHasToilet.errors &&
                                    aircraftHasToilet.errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-group mb-md-0">
                        <label for="smokingIsAllowed">Vol fumeur</label>
                        <select
                            class="form-select"
                            id="smokingIsAllowed"
                            formControlName="smokingIsAllowed"
                            [class.is-invalid]="
                                (form.touched || smokingIsAllowed.touched) &&
                                smokingIsAllowed.status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        >
                            <option [ngValue]="true">Oui</option>
                            <option [ngValue]="false">Non</option>
                        </select>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || smokingIsAllowed.touched) &&
                                    smokingIsAllowed.errors &&
                                    smokingIsAllowed.errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mb-4">
        <div class="col"></div>
        <div class="col text-end">
            <button
                type="button"
                class="btn btn-primary"
                [disabled]="form.disabled || !form.valid"
                (click)="saveAndNext()"
            >
                Suivant
                <fa-icon [icon]="faArrowRight"></fa-icon>
            </button>
        </div>
    </div>
</form>

<form (submit)="saveAircraft()" [formGroup]="formAircraft" (keydown.enter)="saveAircraft()">
    <div class="modal" tabindex="-1" role="dialog" id="modal-aircraft-add" #modalAircraftAdd>
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Ajout d'un nouveau appareil</h5>
                    <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label for="serialNumber">MSN (Numéro de série)</label>
                        <input
                            class="form-control"
                            id="serialNumber"
                            formControlName="serialNumber"
                            type="text"
                            [class.is-invalid]="
                                (formAircraft.touched ||
                                    formAircraft.controls['serialNumber'].touched) &&
                                formAircraft.controls['serialNumber'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (formAircraft.touched ||
                                        formAircraft.controls['serialNumber'].touched) &&
                                    formAircraft.controls['serialNumber'].errors &&
                                    formAircraft.controls['serialNumber'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="status">Statut</label>
                        <select
                            class="form-select"
                            id="status"
                            formControlName="status"
                            required
                            [class.is-invalid]="
                                (formAircraft.touched || formAircraft.controls['status'].touched) &&
                                formAircraft.controls['status'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        >
                            <option
                                [value]="status"
                                *ngFor="let status of getAircraftStatuses()"
                                [innerText]="getAircraftStatusLabel(status)"
                            ></option>
                        </select>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (formAircraft.touched ||
                                        formAircraft.controls['status'].touched) &&
                                    formAircraft.controls['status'].errors &&
                                    formAircraft.controls['status'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="registration">Immatriculation</label>
                        <input
                            class="form-control"
                            id="registration"
                            formControlName="registration"
                            type="text"
                            [class.is-invalid]="
                                (formAircraft.touched ||
                                    formAircraft.controls['registration'].touched) &&
                                formAircraft.controls['registration'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (formAircraft.touched ||
                                        formAircraft.controls['registration'].touched) &&
                                    formAircraft.controls['registration'].errors &&
                                    formAircraft.controls['registration'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        Annuler
                    </button>
                    <button
                        type="button"
                        class="btn btn-primary"
                        (click)="saveAircraft()"
                        [disabled]="formAircraft.disabled || !formAircraft.valid"
                    >
                        Sauvegarder
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>
