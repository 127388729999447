import { Injectable } from '@angular/core';
import { IHandling } from 'src/app/interfaces/handling.interface';
import { FirestoreService } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class HandlingFirestoreService extends FirestoreService<IHandling> {
  protected basePath = 'handlings';
}
