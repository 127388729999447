import { differenceInBusinessDays } from 'date-fns';
import { EnumInvoiceType } from '../enums/invoice-type.enum';
import { IStoreData } from './store-data.interface';
import { EnumInvoiceStatus } from '../enums/invoice-status.enum';
import { IBankAccount } from './bank-account.interface';
import { EnumInvoiceLanguage } from '../enums/language.enum';
import { EnumCurrency } from '../enums/currency.enum';
import { EnumInvoiceProductDescription } from '../enums/invoice-product-description.enum';
import { EnumTvaRate } from '../enums/tva-rates.enum';
import { IClientBillingInfos } from './client-billing-infos.interface';

export interface IInvoiceProduct {
  id: string;
  descriptionType: EnumInvoiceProductDescription;
  descriptionPercent: string;
  descriptionTitle: string;
  amountHt: number;
  amountHtPositive: number;
  tvaRateSelected: EnumTvaRate;
  tvaRate: number;
  amountTva: number;
  amountTvaPositive: number;
  amountTtc: number;
  amountTtcPositive: number;
  currency: EnumCurrency;
}

export interface IInvoice extends IStoreData {
  language: EnumInvoiceLanguage;
  invoiceType: EnumInvoiceType;
  ref: string;
  refYear: number;
  refMonth: number;
  refNumber: number;
  enquiryId: string | null;
  clientId: string;
  status: EnumInvoiceStatus;
  versionNumber: number;
  products: IInvoiceProduct[];
  requestDate: Date;
  requestedBy: string;
  generatedDate: Date;
  generatedBy: string;
  dueDate: Date;
  buyingPriceSupplierPriceInfo: string;
  buyingPriceSupplierPaymentsInfo: string;
  currency: EnumCurrency;
  bankAccount: string;
  bankAccountData: IBankAccount | null;
  cotationsId: string[];
  displayCotationAirlineAndAircraft: boolean;
  cotationsCachedInfos: {
    cotationId: string;
    dates: string;
    routing: string;
    airlineId: string;
    airlineTitle: string;
    aircraftModel: string;
    aircraftCompiledId: string;
  }[];
  clientBillingInfosId: string;
  clientBillingInfos: IClientBillingInfos;
  internalNote: string;
  referalInvoiceId?: string;
  followingInvoiceId?: string;
  referalInvoiceIds: string[];
  followingInvoiceIds: string[];
  sentToClient: boolean;
  invoiceObject: string;
  definitiveInvoice: boolean;

  amountHtTotal: number;
  amountTvaTotal: number;
  amountTtcTotal: number;

  amountSoldePaid: number;
  amountSoldeLeft: number;
  isFullyPaid: boolean;
  soldedDate: string | null;

  issueDate: Date;

  document: {
    name: string;
    size: number;
    type: string;
    url: string;
  } | null;

  isImported?: boolean;
  dateImported?: Date;
  generatedPdfPath?: string;
}

export const getInvoiceTitle = (invoice: IInvoice | null): string | null => {
  if (invoice) {
    switch (invoice.invoiceType) {
      default:
        return invoice.ref;
      case EnumInvoiceType.proforma:
        return 'Proforma ' + invoice.ref;
      case EnumInvoiceType.definitive:
        return 'Facture ' + invoice.ref;
      case EnumInvoiceType.creditNote:
        return 'Avoir ' + invoice.ref;
      case EnumInvoiceType.purchaseCreditNote:
        return 'Avoir reçu ' + invoice.clientBillingInfos.clientName + ' - ' + invoice.ref;
      case EnumInvoiceType.purchase:
        return "Facture d'achat " + invoice.clientBillingInfos.clientName + ' - ' + invoice.ref;
    }
  }

  return null;
};

export const invoiceDiffDueDate = (invoice: IInvoice): number => {
  return differenceInBusinessDays(
    new Date(),
    typeof invoice.dueDate === 'string' ? new Date(invoice.dueDate) : invoice.dueDate
  );
};

export const invoiceGetAmountSoldePaid = (invoice: IInvoice): number => {
  return invoice.amountSoldePaid ?? 0;
};

export const invoiceGetAmountSoldeLeft = (invoice: IInvoice): number => {
  return invoice.amountSoldeLeft ?? invoice.amountTtcTotal;
};

export const invoiceGetProductsLabel = (invoice: IInvoice): string[] => {
  const labels: string[] = [];

  for (const product of invoice.products) {
    labels.push(product.descriptionTitle);
  }

  return labels;
};

export const getTvaRateTitle = (tvaRate: number): string => {
  for (const oneTvaRate of [0, 5.5, 10, 20]) {
    if (oneTvaRate === tvaRate) {
      return oneTvaRate + '%';
    }
  }

  return tvaRate.toString();
};

export const getInvoiceDaysLate = (invoice: IInvoice): number => {
  return differenceInBusinessDays(
    new Date(),
    typeof invoice.dueDate === 'string' ? new Date(invoice.dueDate) : invoice.dueDate
  );
};

export const isInvoiceAmountPositive = (invoiceType: EnumInvoiceType): boolean => {
  return [EnumInvoiceType.proforma, EnumInvoiceType.definitive, EnumInvoiceType.purchase].includes(
    invoiceType
  );
};
