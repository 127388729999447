<div class="centered-content">
    <div class="card mb-4">
        <div class="card-header">
            <img src="assets/img/logo-artheau-transparent.png" height="40" /> <em>SIMPLY</em>fly
        </div>
        <div class="card-body">
            <h4>Votre mot de passe</h4>

            <form (ngSubmit)="submitForm()" [formGroup]="form" (keydown.enter)="submitForm()">
                <div class="form-group" *ngIf="!currentEmail">
                    <label class="form-label">Email</label>
                    <input
                        tabindex="1"
                        type="email"
                        class="form-control"
                        formControlName="email"
                        placeholder="email@email.com"
                        [class.is-invalid]="email.invalid && (email.dirty || email.touched)"
                    />
                    <div class="invalid-tooltip" *ngIf="email.dirty && email.errors">
                        <div *ngIf="email.errors && email.errors['required']">
                            Ce champ est obligatoire.
                        </div>
                        <div *ngIf="email.errors && email.errors['email']">
                            Ce champ doit être un email valide.
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-label">Mot de passe</label>
                    <div class="input-group">
                        <input
                            tabindex="2"
                            [type]="showPassword ? 'text' : 'password'"
                            class="form-control"
                            placeholder="Mot de passe"
                            formControlName="password"
                            [class.is-invalid]="
                                password.invalid && (password.dirty || password.touched)
                            "
                        />
                        <button class="btn" type="button" (click)="toggleType()" tabindex="3">
                            <fa-icon [icon]="faEye" *ngIf="!showPassword"></fa-icon>
                            <fa-icon [icon]="faEyeSlash" *ngIf="showPassword"></fa-icon>
                        </button>
                        <div class="invalid-tooltip" *ngIf="password.dirty && password.errors">
                            <div *ngIf="password.errors && password.errors['required']">
                                Ce champ est obligatoire.
                            </div>
                            <div *ngIf="password.errors && password.errors['minlength']">
                                Ce champ doit faire au moins
                                {{ password.errors["minlength"]["requiredLength"] }}
                                caractères.
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label for="confirmPassword">Confirmer mot de passe</label>
                    <div class="input-group">
                        <input
                            formControlName="confirmPassword"
                            id="confirmPassword"
                            class="form-control"
                            [type]="showPassword ? 'text' : 'password'"
                            placeholder="Confirmer mot de passe"
                            required
                            [class.is-invalid]="
                                confirmPassword.invalid &&
                                (confirmPassword.dirty || confirmPassword.touched)
                            "
                        />
                        <button class="btn" type="button" (click)="toggleType()" tabindex="3">
                            <fa-icon [icon]="faEye" *ngIf="!showPassword"></fa-icon>
                            <fa-icon [icon]="faEyeSlash" *ngIf="showPassword"></fa-icon>
                        </button>
                        <div
                            *ngIf="
                                confirmPassword.invalid &&
                                (confirmPassword.dirty || confirmPassword.touched) &&
                                confirmPassword.errors.required
                            "
                            class="invalid-tooltip"
                        >
                            Ce champ est obligatoire.
                        </div>
                        <div
                            *ngIf="
                                confirmPassword.invalid &&
                                (confirmPassword.dirty || confirmPassword.touched) &&
                                confirmPassword.errors['isMatching'] === false
                            "
                            class="invalid-tooltip"
                        >
                            Les mots de passe ne sont pas identiques.
                        </div>
                    </div>
                </div>

                <button
                    class="w-100 btn btn-lg btn-primary mt-2"
                    type="submit"
                    [disabled]="form.disabled || !form.valid"
                    (click)="submitForm()"
                    tabindex="5"
                >
                    Sauvegarder
                </button>
            </form>
        </div>
    </div>
</div>
