import { differenceInMinutes } from 'date-fns';
import { EnumEnquiryType } from '../enums/enquiry-type.enum';
import { IFlightBriefPassengerInfo } from './flight-brief.interface';
import { IStoreData } from './store-data.interface';

export interface IEnquiryFlight extends IStoreData {
  enquiryId: string;
  enquiryType: EnumEnquiryType;
  enquiryRefContractTitle: string;
  enquiryRefContractTitleWithoutPrefix: string;
  enquiryRefContractYear: number;
  enquiryRefContractMonth: number;
  clientId: string;
  clientName: string;
  clientContactId: string;
  clientContactTitle: string;
  enquiryProcessedBy: string;
  userInitials: string;
  userFullName: string;
  aircraftCompiledId: string | null;
  aircraftCompiledModel: string | null;
  countryCode: string | null;
  airlineId: string | null;
  airlineTitle: string | null;
  aircraftModelId: string | null;
  aircraftModelTitle: string | null;
  aircraftId: string | null;
  aircraftRegistration: string | null;
  airportDepartId: string;
  airportDepartTitle: string;
  airportDepartIataCode: string;
  departTimezone: string;
  departDateTimeLocal: string;
  departDateLocal: string;
  departTimeLocal: string;
  departDateTimeUtc: string;
  departDateUtc: string;
  departTimeUtc: string;
  airportArrivalId: string;
  airportArrivalTitle: string;
  airportArrivalIataCode: string;
  arrivalTimezone: string;
  arrivalDateTimeLocal: string;
  arrivalDateLocal: string;
  arrivalTimeLocal: string;
  arrivalDateTimeUtc: string;
  arrivalDateUtc: string;
  arrivalTimeUtc: string;
  flyTimeInMin: number | null;
  flightNumber: string | null;
  flightradar24Url: string | null;
  adsbexchangeUrl: string | null;
  departFboEnabled: boolean;
  departFboId: string | null;
  arrivalFboEnabled: boolean;
  arrivalFboId: string | null;
  departHandlingEnabled: boolean;
  departHandlingId: string | null;
  arrivalHandlingEnabled: boolean;
  arrivalHandlingId: string | null;
  departSlotEnabled: boolean;
  departSlotTime: string | null;
  arrivalSlotEnabled: boolean;
  arrivalSlotTime: string | null;
  departTerminalEnabled: boolean;
  departTerminal: string | null;
  arrivalTerminalEnabled: boolean;
  arrivalTerminal: string | null;
  departAgentEnabled: boolean;
  departAgentType: EnumEnquiryFlightAgentType | null;
  departAgentUserId: string | null;
  departAgentUserInitials: string | null;
  departAgentUserFullName: string | null;
  departAgentCompany: string | null;
  departAgentPhone: string | null;
  departAgentEmail: string | null;
  arrivalAgentEnabled: boolean;
  arrivalAgentType: EnumEnquiryFlightAgentType | null;
  arrivalAgentUserId: string | null;
  arrivalAgentUserInitials: string | null;
  arrivalAgentUserFullName: string | null;
  arrivalAgentCompany: string | null;
  arrivalAgentPhone: string | null;
  arrivalAgentEmail: string | null;
  pax: number;
  weight: number;
  volume: number;
  comment: string | null;
  opsH24Phone: string;
  captainId: string;
  coPilotId: string;
  stewardessEnabled: boolean;
  stewardessIds: string[];
  supervisorEnabled: boolean;
  supervisorIds: string[];
  isFerryFlight: boolean;
  isAircraftGroundedOnArrival: boolean;
  boardingMinutesBeforeDeparture: number;
  deliveryCargoHoursBeforeDeparture: number;
  displayAirlineOnFlightBrief: boolean;
  aircraftHasToilet: boolean;
  smokingIsAllowed: boolean;
  typeOfCargo: string;
  weightInKg: number;
  volumeInM3: number;
  nbPackages: number;
  awbNumber: string;
  customDeclaration: string;
  dgd: boolean;
  passengers: IFlightBriefPassengerInfo[];
  flightBriefCateringId: string | null;
  catering: string;
  luggages: string;
  luggageQty: number;
  luggagesWeight: number;
  luggageIsCustom: boolean;
}

export enum EnumEnquiryFlightAgentType {
  internal = 'internal',
  external = 'external'
}

export const getEnquiryFlightAgentTypeLabel = (type: EnumEnquiryFlightAgentType): string => {
  switch (type) {
    default:
      return type;
    case EnumEnquiryFlightAgentType.internal:
      return 'Interne';
    case EnumEnquiryFlightAgentType.external:
      return 'Externe';
  }
};

export const getEnquiryFlightFlyTimeInMin = (enquiryFlight: IEnquiryFlight): number => {
  if (enquiryFlight.flyTimeInMin) {
    return enquiryFlight.flyTimeInMin;
  } else {
    if (enquiryFlight.departDateTimeUtc && enquiryFlight.arrivalDateTimeUtc) {
      const arrivalDateTimeUtc: Date = new Date(enquiryFlight.arrivalDateTimeUtc + ':00Z');
      const departDateTimeUtc: Date = new Date(enquiryFlight.departDateTimeUtc + ':00Z');

      if (!isNaN(arrivalDateTimeUtc.getTime()) && !isNaN(departDateTimeUtc.getTime())) {
        return differenceInMinutes(arrivalDateTimeUtc, departDateTimeUtc);
      }
    }

    return 0;
  }
};
