import { Injectable } from '@angular/core';
import { FirestoreService } from '../firestore/firestore.service';
import { INotification } from 'src/app/interfaces/notification.interface';

@Injectable({
  providedIn: 'root'
})
export class NotificationFirestoreService extends FirestoreService<INotification> {
  protected basePath = 'notifications';
}
