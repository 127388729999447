import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { PaginationService } from '../../../services/pagination.service';
import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';

import { getEnquiryBreadcrumbTitle, IEnquiry } from 'src/app/interfaces/enquiry.interface';
import { EnquiryService } from 'src/app/services/enquiry/enquiry.service';
import { Subscription } from 'rxjs';
import { getLanguageLabel } from 'src/app/enums/language.enum';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { getQuotationStatusLabel } from 'src/app/interfaces/quotation.interface';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs/breadcrumbs.service';

@Component({
  selector: 'app-list-quotations',
  templateUrl: './list-quotations.component.html',
  styleUrls: ['./list-quotations.component.scss']
})
export class ListQuotationsComponent implements OnInit, OnDestroy {
  getLanguageLabel = getLanguageLabel;
  getEnquiryBreadcrumbTitle = getEnquiryBreadcrumbTitle;
  getQuotationStatusLabel = getQuotationStatusLabel;

  EnumAcl = EnumAcl;

  currentPagination: string = 'quotations-list';
  enquiryId: string;
  enquiry: IEnquiry;

  subscriptions = new Subscription();

  constructor(
    public paginationService: PaginationService,
    private aclService: AclService,
    private activatedRoute: ActivatedRoute,
    private enquiryService: EnquiryService,
    private breadcrumbsService: BreadcrumbsService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.aclService.checkAclAccess(EnumAcl.quotationsList);

    this.activatedRoute.url.subscribe(async () => {
      this.enquiryId = this.activatedRoute.snapshot.paramMap.get('enquiryId');

      if (this.enquiryId) {
        this.loadEnquiry();
      }
    });

    this.paginationService.pagination[this.currentPagination].currentPage = 0;

    this.fetchData();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  loadEnquiry(): void {
    if (this.enquiryId) {
      this.subscriptions.add(
        this.enquiryService.getFromId(this.enquiryId).subscribe((enquiry: IEnquiry) => {
          this.enquiry = enquiry;

          if (this.enquiry) {
            this.breadcrumbsService.setCurrentItem({
              id: this.enquiry.id,
              text: getEnquiryBreadcrumbTitle(this.enquiry)
            });
          }
        })
      );
    }
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  async fetchData(): Promise<void> {
    this.paginationService.pagination[this.currentPagination].conditions = [];

    if (this.enquiryId) {
      this.paginationService.pagination[this.currentPagination].conditions.push({
        field: 'enquiryId',
        operator: '==',
        value: this.enquiryId
      });
    }

    await this.paginationService.fetchData(this.currentPagination);
  }
}
