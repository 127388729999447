<form (submit)="saveAndNext()" [formGroup]="form" (keydown.enter)="saveAndNext()" class="main-form">
    <div class="card mb-4">
        <div class="card-header" *ngIf="airportField === 'depart'">
            <fa-icon [icon]="faPlaneDeparture"></fa-icon> Informations départ
        </div>
        <div class="card-header" *ngIf="airportField === 'arrival'">
            <fa-icon [icon]="faPlaneArrival"></fa-icon> Informations arrivée
        </div>
        <div class="card-body">
            <div class="form-group">
                <label>Aéroport</label>
                <app-airport-picker
                    [inputName]="'airport' + capitalize(airportField) + 'Id'"
                    [defaultValue]="airportId.value"
                    [disabled]="form.disabled"
                    [isInvalid]="
                        (form.touched || airportId.touched) && airportId.status === 'INVALID'
                            ? 'danger'
                            : ''
                    "
                    (setValueToFormControl)="setValueToFormControl($event)"
                >
                </app-airport-picker>

                <div
                    *ngIf="
                        (form.touched || airportId.touched) &&
                        airportId.errors &&
                        airportId.errors['required']
                    "
                    class="text-danger"
                >
                    Ce champ est obligatoire.
                </div>
            </div>

            <ng-container *ngIf="airportId.value">
                <label [attr.for]="airportField + 'DateTimeLocal'">Date et heure</label>

                <div class="row">
                    <div class="col-md-6" *ngFor="let timeField of ['local', 'utc']">
                        <div class="form-group">
                            <div class="input-group">
                                <input
                                    class="form-control"
                                    [id]="airportField + 'DateTime' + capitalize(timeField)"
                                    [formControlName]="
                                        airportField + 'DateTime' + capitalize(timeField)
                                    "
                                    type="datetime-local"
                                    [max]="
                                        airportField === 'depart' &&
                                        getDateTimeFieldForAirport(
                                            airportField === 'depart' ? 'arrival' : 'depart',
                                            timeField
                                        ).value
                                            ? getDateTimeFieldForAirport(
                                                  airportField === 'depart' ? 'arrival' : 'depart',
                                                  timeField
                                              ).value
                                            : ''
                                    "
                                    [min]="
                                        airportField === 'arrival' &&
                                        getDateTimeFieldForAirport('depart', timeField).value
                                            ? getDateTimeFieldForAirport('depart', timeField).value
                                            : '2000-01-01'
                                    "
                                    [class.is-invalid]="
                                        (form.touched || getDateTimeField(timeField).touched) &&
                                        getDateTimeField(timeField).status === 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                    (change)="updatedDateTime(timeField)"
                                />

                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched || getDateTimeField(timeField).touched) &&
                                            getDateTimeField(timeField).errors &&
                                            getDateTimeField(timeField).errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                    <div
                                        *ngIf="
                                            (form.touched || getDateTimeField(timeField).touched) &&
                                            getDateTimeField(timeField).errors &&
                                            getDateTimeField(timeField).errors['dateBelowMinimum']
                                        "
                                    >
                                        La date doit être supérieure à
                                        {{
                                            getDateTimeField(timeField).errors["dateMinimum"]
                                                | date : "dd/MM/yyyy"
                                        }}.
                                    </div>
                                </div>

                                <span
                                    class="input-group-text"
                                    rel="tooltip"
                                    title="Heure locale"
                                    *ngIf="timeField === 'local'"
                                    >LT</span
                                >
                                <span class="input-group-text" *ngIf="timeField === 'utc'"
                                    >UTC</span
                                >
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="alert alert-info text-center"
                    *ngIf="airportField === 'arrival' && getEstimatedArrivalTime()"
                >
                    {{
                        enquiry?.type === EnumEnquiryType.cargo
                            ? "Heure UTC d'arrivée estimée pour"
                            : "Heure locale d'arrivée estimée pour"
                    }}
                    {{ enquiryFlight.aircraftModelTitle }} :
                    <div [innerHTML]="getEstimatedArrivalTimeForInfo()"></div>

                    <button
                        type="button"
                        class="btn btn-info btn-sm"
                        (click)="applyEstimatedArrivalTime()"
                        *ngIf="getEstimatedArrivalTime().utc !== getDateTimeField('utc').value"
                    >
                        Appliquer
                    </button>
                </div>

                <div class="d-flex justify-content-center" *ngIf="airportField === 'arrival'">
                    <div class="form-check form-switch">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="isAircraftGroundedOnArrival"
                            formControlName="isAircraftGroundedOnArrival"
                        />
                        <label class="form-check-label" for="isAircraftGroundedOnArrival"
                            >Reste en immobilisation</label
                        >
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="row" *ngIf="airportId.value">
        <div class="col-md-6">
            <div class="card mb-4">
                <div class="card-header">
                    <div class="form-check form-switch">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            [id]="airportField + 'SlotEnabled'"
                            [formControlName]="airportField + 'SlotEnabled'"
                            (change)="updatedSlotEnabled()"
                        />
                        <label class="form-check-label" [attr.for]="airportField + 'SlotEnabled'"
                            >Slot</label
                        >
                    </div>
                </div>
                <div class="card-body" *ngIf="slotEnabled.value">
                    <div class="form-group placeholder-glow mb-0">
                        <div class="input-group">
                            <input
                                type="time"
                                class="form-control"
                                [formControlName]="airportField + 'SlotTime'"
                                [class.is-invalid]="
                                    (form.touched || slotTime.touched) &&
                                    slotTime.status === 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />
                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched || slotTime.touched) &&
                                        slotTime.errors &&
                                        slotTime.errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>

                            <span class="input-group-text" rel="tooltip" title="Heure locale"
                                >LT</span
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="col-md-6"
            *ngIf="![EnumEnquiryType.business, EnumEnquiryType.helico].includes(enquiry?.type)"
        >
            <div class="card mb-4">
                <div class="card-header">
                    <div class="form-check form-switch">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            [id]="airportField + 'TerminalEnabled'"
                            [formControlName]="airportField + 'TerminalEnabled'"
                            (change)="(terminalEnabled)"
                        />
                        <label
                            class="form-check-label"
                            [attr.for]="airportField + 'TerminalEnabled'"
                            >Terminal</label
                        >
                    </div>
                </div>
                <div class="card-body" *ngIf="terminalEnabled.value">
                    <div class="form-group placeholder-glow mb-0">
                        <input
                            type="text"
                            class="form-control"
                            [formControlName]="airportField + 'Terminal'"
                            [class.is-invalid]="
                                (form.touched || terminal.touched) && terminal.status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />
                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || terminal.touched) &&
                                    terminal.errors &&
                                    terminal.errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="col-md-6"
            *ngIf="![EnumEnquiryType.business, EnumEnquiryType.helico].includes(enquiry?.type)"
        >
            <div class="card mb-4">
                <div class="card-header">
                    <div class="handling-switch">
                        <div class="form-check form-switch">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                role="switch"
                                [id]="airportField + 'HandlingEnabled'"
                                [formControlName]="airportField + 'HandlingEnabled'"
                                (change)="updatedHandlingEnabled()"
                            />
                            <label
                                class="form-check-label"
                                [attr.for]="airportField + 'HandlingEnabled'"
                                >Handling</label
                            >
                        </div>
                        <button
                            type="button"
                            class="btn btn-icon"
                            (click)="addHandling()"
                            *ngIf="hasAclAccess(EnumAcl.handlingsAdd) && handlingEnabled.value"
                            rel="tooltip"
                            title="Ajout handling"
                        >
                            <fa-icon [icon]="faPlusCircle"></fa-icon>
                        </button>
                    </div>
                </div>
                <div class="card-body" *ngIf="handlingEnabled.value">
                    <div class="form-group placeholder-glow mb-0">
                        <div
                            class="placeholder w-100"
                            *ngIf="loadingHandlingsForAirport[airportId.value]"
                        ></div>
                        <div
                            [class.input-group]="handlingId.value"
                            *ngIf="!loadingHandlingsForAirport[airportId.value]"
                        >
                            <select
                                class="form-select"
                                [formControlName]="airportField + 'HandlingId'"
                                [class.is-invalid]="
                                    (form.touched || handlingId.touched) &&
                                    handlingId.status === 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            >
                                <option [ngValue]="null">-- Choisissez --</option>
                                <option
                                    [value]="item.id"
                                    [innerText]="item.name"
                                    *ngFor="let item of handlingsObj[airportId.value]"
                                ></option>
                            </select>
                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched || handlingId.touched) &&
                                        handlingId.errors &&
                                        handlingId.errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                            <button
                                type="button"
                                class="btn btn-primary"
                                (click)="editHandling()"
                                *ngIf="hasAclAccess(EnumAcl.handlingsEdit) && handlingId.value"
                                rel="tooltip"
                                title="Éditer handling"
                            >
                                <fa-icon [icon]="faEdit"></fa-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="col-md-6"
            *ngIf="
                true || [EnumEnquiryType.business, EnumEnquiryType.helico].includes(enquiry?.type)
            "
        >
            <div class="card mb-4">
                <div class="card-header">
                    <div class="fbo-switch">
                        <div class="form-check form-switch">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                role="switch"
                                [id]="airportField + 'FboEnabled'"
                                [formControlName]="airportField + 'FboEnabled'"
                                (change)="updatedFboEnabled()"
                            />
                            <label class="form-check-label" [attr.for]="airportField + 'FboEnabled'"
                                >FBO</label
                            >
                        </div>
                        <button
                            type="button"
                            class="btn btn-icon"
                            (click)="addFbo()"
                            *ngIf="hasAclAccess(EnumAcl.fbosAdd) && fboEnabled.value"
                            rel="tooltip"
                            title="Ajout FBO"
                        >
                            <fa-icon [icon]="faPlusCircle"></fa-icon>
                        </button>
                    </div>
                </div>
                <div class="card-body" *ngIf="fboEnabled.value">
                    <div class="form-group placeholder-glow mb-0">
                        <div
                            class="placeholder w-100"
                            *ngIf="loadingFbosForAirport[airportId.value]"
                        ></div>
                        <div
                            [class.input-group]="fboId.value"
                            *ngIf="!loadingFbosForAirport[airportId.value]"
                        >
                            <select
                                class="form-select"
                                [formControlName]="airportField + 'FboId'"
                                [class.is-invalid]="
                                    (form.touched || fboId.touched) && fboId.status === 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            >
                                <option [ngValue]="null">-- Choisissez --</option>
                                <option
                                    [value]="item.id"
                                    [innerText]="item.name"
                                    *ngFor="let item of fbosObj[airportId.value]"
                                ></option>
                            </select>
                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched || fboId.touched) &&
                                        fboId.errors &&
                                        fboId.errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                            <button
                                type="button"
                                class="btn btn-primary"
                                (click)="editFbo()"
                                *ngIf="hasAclAccess(EnumAcl.fbosEdit) && fboId.value"
                                rel="tooltip"
                                title="Éditer FBO"
                            >
                                <fa-icon [icon]="faEdit"></fa-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="card mb-4">
                <div class="card-header">
                    <div class="form-check form-switch">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            [id]="airportField + 'AgentEnabled'"
                            [formControlName]="airportField + 'AgentEnabled'"
                            (change)="updatedAgentEnabled()"
                        />
                        <label class="form-check-label" [attr.for]="airportField + 'AgentEnabled'"
                            >Représentant</label
                        >
                    </div>
                </div>
                <div class="card-body" *ngIf="agentEnabled.value">
                    <div class="form-group mt-2">
                        <select
                            class="form-select"
                            [formControlName]="airportField + 'AgentType'"
                            [class.is-invalid]="
                                (form.touched || agentType.touched) &&
                                agentType.status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                            (change)="updatedAgentType()"
                        >
                            <option [ngValue]="null">-- Choisissez --</option>
                            <option
                                [value]="agentType"
                                [innerText]="getEnquiryFlightAgentTypeLabel(agentType)"
                                *ngFor="let agentType of agentTypes"
                            ></option>
                        </select>
                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || agentType.touched) &&
                                    agentType.errors &&
                                    agentType.errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                    <div
                        class="form-group placeholder-glow"
                        *ngIf="agentType.value === EnumEnquiryFlightAgentType.internal"
                    >
                        <div class="placeholder w-100" *ngIf="loadingUsers"></div>
                        <select
                            class="form-select"
                            [formControlName]="airportField + 'AgentUserId'"
                            [class.is-invalid]="
                                (form.touched || agentUserId.touched) &&
                                agentUserId.status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                            (change)="updatedAgentUserId()"
                            *ngIf="!loadingUsers"
                        >
                            <option [ngValue]="null">-- Choisissez --</option>
                            <option
                                [value]="user.id"
                                [innerText]="getUserFullname(user) + ' (' + user.initials + ')'"
                                *ngFor="let user of users"
                            ></option>
                        </select>
                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || agentUserId.touched) &&
                                    agentUserId.errors &&
                                    agentUserId.errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <ng-container *ngIf="agentType.value === EnumEnquiryFlightAgentType.external">
                        <div class="form-group">
                            <label [attr.for]="airportField + 'AgentUserFullName'">Nom</label>
                            <input
                                type="text"
                                class="form-control"
                                [id]="airportField + 'AgentUserFullName'"
                                [formControlName]="airportField + 'AgentUserFullName'"
                                [class.is-invalid]="
                                    (form.touched || agentUserFullName.touched) &&
                                    agentUserFullName.status === 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />
                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched || agentUserFullName.touched) &&
                                        agentUserFullName.errors &&
                                        agentUserFullName.errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label [attr.for]="airportField + 'AgentCompany'">Société</label>
                            <input
                                type="text"
                                class="form-control"
                                [id]="airportField + 'AgentCompany'"
                                [formControlName]="airportField + 'AgentCompany'"
                                [class.is-invalid]="
                                    (form.touched || agentCompany.touched) &&
                                    agentCompany.status === 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />
                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched || agentCompany.touched) &&
                                        agentCompany.errors &&
                                        agentCompany.errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label [attr.for]="airportField + 'AgentPhone'">Téléphone</label>
                            <input
                                type="text"
                                class="form-control"
                                [id]="airportField + 'AgentPhone'"
                                [formControlName]="airportField + 'AgentPhone'"
                                [class.is-invalid]="
                                    (form.touched || agentPhone.touched) &&
                                    agentPhone.status === 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />
                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched || agentPhone.touched) &&
                                        agentPhone.errors &&
                                        agentPhone.errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label [attr.for]="airportField + 'AgentEmail'">Email</label>
                            <input
                                type="text"
                                class="form-control"
                                [id]="airportField + 'AgentEmail'"
                                [formControlName]="airportField + 'AgentEmail'"
                                [class.is-invalid]="
                                    (form.touched || agentEmail.touched) &&
                                    agentEmail.status === 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />
                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched || agentEmail.touched) &&
                                        agentEmail.errors &&
                                        agentEmail.errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <div class="row mb-4">
        <div class="col">
            <button
                type="button"
                class="btn btn-secondary"
                [disabled]="form.disabled"
                (click)="prev()"
            >
                <fa-icon [icon]="faArrowLeft"></fa-icon>
                Précédent
            </button>
        </div>
        <div class="col text-end">
            <button
                type="button"
                class="btn btn-primary"
                [disabled]="form.disabled || !form.valid"
                (click)="saveAndNext()"
            >
                Suivant
                <fa-icon [icon]="faArrowRight"></fa-icon>
            </button>
        </div>
    </div>
</form>

<div class="modal" tabindex="-1" role="dialog" #modalFbo>
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5
                    class="modal-title"
                    [innerText]="editingFbo ? 'Édition d\'un FBO' : 'Ajout d\'un FBO'"
                ></h5>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <app-fbo-edit
                [inModal]="true"
                [defaultAirportId]="form.get('airport' + capitalize(airportField) + 'Id').value"
                [fboId]="editingFbo?.id || null"
                *ngIf="airportField"
                (dismissModal)="dismissFboModal($event)"
            ></app-fbo-edit>
        </div>
    </div>
</div>

<div class="modal" tabindex="-1" role="dialog" #modalHandling>
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5
                    class="modal-title"
                    [innerText]="editingFbo ? 'Édition d\'un handling' : 'Ajout d\'un handling'"
                ></h5>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <app-handling-edit
                [inModal]="true"
                [defaultAirportId]="form.get('airport' + capitalize(airportField) + 'Id').value"
                [handlingId]="editingHandling?.id || null"
                *ngIf="airportField"
                (dismissModal)="dismissHandlingModal($event)"
            ></app-handling-edit>
        </div>
    </div>
</div>
