<h1 class="text-center" [innerHtml]="clientId ? 'Édition d\'un client' : 'Ajout d\'un client'"></h1>
<form (submit)="submitForm()" [formGroup]="form" (keydown.enter)="submitForm()" class="main-form">
    <div class="row justify-content-center">
        <div class="col-md-6">
            <div class="card">
                <div class="card-body">
                    <div class="form-group">
                        <label for="title">Nom</label>
                        <input
                            class="form-control"
                            id="title"
                            formControlName="title"
                            type="text"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['title'].touched) &&
                                form.controls['title'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['title'].touched) &&
                                    form.controls['title'].errors &&
                                    form.controls['title'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="countryCode">Pays</label>
                        <autocomplete-input
                            (setValueToFormControl)="setValueToFormControl($event)"
                            inputName="countryCode"
                            [defaultValue]="form.value['countryCode']"
                            [itineraries]="countriesList"
                            [disabled]="form.disabled || sending || !countriesList.length"
                            *ngIf="countriesList.length"
                        ></autocomplete-input>

                        <div
                            *ngIf="
                                (form.touched || form.controls['countryCode'].touched) &&
                                form.controls['countryCode'].errors &&
                                form.controls['countryCode'].errors['required']
                            "
                            class="text-danger"
                        >
                            Ce champ est obligatoire.
                        </div>
                    </div>

                    <div class="form-group" *ngIf="form.value['countryCode']">
                        <label for="continentCode">Continent</label>
                        <input
                            class="form-control"
                            id="continentCode"
                            type="text"
                            readonly
                            [value]="getContinentLabel(form.value['continentCode'])"
                        />
                    </div>

                    <div class="form-group">
                        <label for="website">Site internet</label>
                        <input
                            class="form-control"
                            id="website"
                            formControlName="website"
                            type="text"
                            [class.is-invalid]="
                                (form.touched || form.controls['website'].touched) &&
                                form.controls['website'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                            placeholder="https://..."
                            pattern="https?://.+"
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['website'].touched) &&
                                    form.controls['website'].errors &&
                                    form.controls['website'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                            <div
                                *ngIf="
                                    (form.touched || form.controls['website'].touched) &&
                                    form.controls['website'].errors &&
                                    form.controls['website'].errors['pattern']
                                "
                            >
                                Ce champ doit être une URL commançant par http or https.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="tvaNumber">Numéro de TVA</label>
                        <input
                            class="form-control"
                            id="tvaNumber"
                            formControlName="tvaNumber"
                            type="text"
                            [class.is-invalid]="
                                (form.touched || form.controls['tvaNumber'].touched) &&
                                form.controls['tvaNumber'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['tvaNumber'].touched) &&
                                    form.controls['tvaNumber'].errors &&
                                    form.controls['tvaNumber'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mt-4" *ngIf="form">
                <div class="card-body" formArrayName="documentsUrl">
                    <h5 class="card-title text-center">Documentation client</h5>

                    <ul class="list-group">
                        <li
                            class="list-group-item"
                            [formGroupName]="i"
                            *ngFor="
                                let documentsUrl of form.get('documentsUrl')['controls'];
                                let i = index
                            "
                        >
                            <strong>Document {{ i + 1 }}</strong>

                            <button
                                type="button"
                                (click)="deleteDocument(i)"
                                class="btn btn-danger float-end"
                                [disabled]="form.disabled || sending"
                            >
                                X
                            </button>

                            <div class="form-group">
                                <label for="title">Nom</label>
                                <input
                                    class="form-control"
                                    id="title"
                                    formControlName="title"
                                    type="text"
                                    required
                                    [class.is-invalid]="
                                        (form.touched ||
                                            form.get('documentsUrl').get(i.toString()).get('title')
                                                .touched) &&
                                        form.get('documentsUrl').get(i.toString()).get('title')
                                            .status == 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                />

                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched ||
                                                form
                                                    .get('documentsUrl')
                                                    .get(i.toString())
                                                    .get('title').touched) &&
                                            form.get('documentsUrl').get(i.toString()).get('title')
                                                .errors &&
                                            form.get('documentsUrl').get(i.toString()).get('title')
                                                .errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                            </div>

                            <div class="text-center">
                                <a
                                    [href]="form.value.documentsUrl[i].url"
                                    target="_blank"
                                    *ngIf="form.value.documentsUrl[i].url"
                                    >Lien du document</a
                                ><br />
                                <br />
                                <button
                                    type="button"
                                    (click)="showUploadModal(i)"
                                    class="btn btn-secondary"
                                    [disabled]="form.disabled || sending"
                                    [innerHtml]="
                                        uploading && documentIndex !== null
                                            ? uploadingProgress + '% envoyé'
                                            : 'Éditer le document'
                                    "
                                ></button>
                            </div>
                        </li>
                        <li class="list-group-item text-center">
                            <button
                                type="button"
                                (click)="showUploadModal()"
                                class="btn btn-secondary"
                                [disabled]="form.disabled || sending"
                                [innerHtml]="
                                    uploading && documentIndex === null
                                        ? uploadingProgress + '% envoyé'
                                        : '+ Nouveau document'
                                "
                            ></button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title text-center">Adresse</h5>
                    <div class="form-group">
                        <label for="streetNumber">Numéro</label>
                        <input
                            class="form-control"
                            id="streetNumber"
                            formControlName="streetNumber"
                            type="text"
                            [class.is-invalid]="
                                (form.touched || form.controls['streetNumber'].touched) &&
                                form.controls['streetNumber'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['streetNumber'].touched) &&
                                    form.controls['streetNumber'].errors &&
                                    form.controls['streetNumber'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="route">Rue</label>
                        <input
                            class="form-control"
                            id="route"
                            formControlName="route"
                            type="text"
                            [class.is-invalid]="
                                (form.touched || form.controls['route'].touched) &&
                                form.controls['route'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['route'].touched) &&
                                    form.controls['route'].errors &&
                                    form.controls['route'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="postalCode">Code postal</label>
                        <input
                            class="form-control"
                            id="postalCode"
                            formControlName="postalCode"
                            type="text"
                            [class.is-invalid]="
                                (form.touched || form.controls['postalCode'].touched) &&
                                form.controls['postalCode'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['postalCode'].touched) &&
                                    form.controls['postalCode'].errors &&
                                    form.controls['postalCode'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="city">Ville</label>
                        <input
                            class="form-control"
                            id="city"
                            formControlName="city"
                            type="text"
                            [class.is-invalid]="
                                (form.touched || form.controls['city'].touched) &&
                                form.controls['city'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />
                    </div>
                </div>
            </div>

            <div class="card mt-4" *ngIf="form">
                <div class="card-body" formArrayName="companyContacts">
                    <h5 class="card-title text-center">Contacts</h5>

                    <ul class="list-group">
                        <li
                            class="list-group-item"
                            [formGroupName]="i"
                            *ngFor="
                                let clientContact of form.get('companyContacts')['controls'];
                                let i = index
                            "
                        >
                            <strong>Service {{ i + 1 }}</strong>

                            <button
                                type="button"
                                (click)="deleteContactCategory(i)"
                                class="btn btn-danger float-end"
                                [disabled]="form.disabled || sending"
                            >
                                X
                            </button>

                            <div class="form-group">
                                <label for="title">Nom du service</label>
                                <input
                                    class="form-control"
                                    id="title"
                                    formControlName="title"
                                    type="text"
                                    required
                                    [class.is-invalid]="
                                        (form.touched ||
                                            form
                                                .get('companyContacts')
                                                .get(i.toString())
                                                .get('title').touched) &&
                                        form.get('companyContacts').get(i.toString()).get('title')
                                            .status == 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                    placeholder="Ex: Service généraux, Service commercial, etc"
                                />

                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched ||
                                                form
                                                    .get('companyContacts')
                                                    .get(i.toString())
                                                    .get('title').touched) &&
                                            form
                                                .get('companyContacts')
                                                .get(i.toString())
                                                .get('title').errors &&
                                            form
                                                .get('companyContacts')
                                                .get(i.toString())
                                                .get('title').errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                            </div>

                            <ul class="list-group" formArrayName="contacts">
                                <li
                                    class="list-group-item"
                                    [formGroupName]="j"
                                    *ngFor="
                                        let contact of form
                                            .get('companyContacts')
                                            .get(i.toString())
                                            .get('contacts')['controls'];
                                        let j = index
                                    "
                                >
                                    <strong>Contact {{ j + 1 }}</strong>

                                    <button
                                        type="button"
                                        (click)="deleteContactFromCategory(i, j)"
                                        class="btn btn-danger float-end"
                                        [disabled]="form.disabled || sending"
                                    >
                                        X
                                    </button>

                                    <div class="form-group">
                                        <label for="lastname">Nom</label>
                                        <input
                                            class="form-control"
                                            id="lastname"
                                            formControlName="lastname"
                                            type="text"
                                            [class.is-invalid]="
                                                (form.touched ||
                                                    form
                                                        .get('companyContacts')
                                                        .get(i.toString())
                                                        .get('contacts')
                                                        .get(j.toString())
                                                        .get('lastname').touched) &&
                                                form
                                                    .get('companyContacts')
                                                    .get(i.toString())
                                                    .get('contacts')
                                                    .get(j.toString())
                                                    .get('lastname').status == 'INVALID'
                                                    ? 'danger'
                                                    : ''
                                            "
                                        />
                                    </div>

                                    <div class="form-group">
                                        <label for="firstname">Prénom</label>
                                        <input
                                            class="form-control"
                                            id="firstname"
                                            formControlName="firstname"
                                            type="text"
                                            [class.is-invalid]="
                                                (form.touched ||
                                                    form
                                                        .get('companyContacts')
                                                        .get(i.toString())
                                                        .get('contacts')
                                                        .get(j.toString())
                                                        .get('firstname').touched) &&
                                                form
                                                    .get('companyContacts')
                                                    .get(i.toString())
                                                    .get('contacts')
                                                    .get(j.toString())
                                                    .get('firstname').status == 'INVALID'
                                                    ? 'danger'
                                                    : ''
                                            "
                                        />
                                    </div>

                                    <div class="form-group">
                                        <label for="fonction">Fonction</label>
                                        <input
                                            class="form-control"
                                            id="fonction"
                                            formControlName="fonction"
                                            type="text"
                                            [class.is-invalid]="
                                                (form.touched ||
                                                    form
                                                        .get('companyContacts')
                                                        .get(i.toString())
                                                        .get('contacts')
                                                        .get(j.toString())
                                                        .get('fonction').touched) &&
                                                form
                                                    .get('companyContacts')
                                                    .get(i.toString())
                                                    .get('contacts')
                                                    .get(j.toString())
                                                    .get('fonction').status == 'INVALID'
                                                    ? 'danger'
                                                    : ''
                                            "
                                        />
                                    </div>

                                    <div class="form-group">
                                        <label for="phone">Téléphone</label>
                                        <input
                                            class="form-control"
                                            id="phone"
                                            formControlName="phone"
                                            type="tel"
                                        />
                                    </div>

                                    <div class="form-group">
                                        <label for="mobile">Portable</label>
                                        <input
                                            class="form-control"
                                            id="mobile"
                                            formControlName="mobile"
                                            type="tel"
                                        />
                                    </div>

                                    <div class="form-group">
                                        <label for="email">Email</label>
                                        <input
                                            class="form-control"
                                            id="email"
                                            formControlName="email"
                                            type="email"
                                            [class.is-invalid]="
                                                (form.touched ||
                                                    form
                                                        .get('companyContacts')
                                                        .get(i.toString())
                                                        .get('contacts')
                                                        .get(j.toString())
                                                        .get('email').touched) &&
                                                form
                                                    .get('companyContacts')
                                                    .get(i.toString())
                                                    .get('contacts')
                                                    .get(j.toString())
                                                    .get('email').status == 'INVALID'
                                                    ? 'danger'
                                                    : ''
                                            "
                                        />

                                        <div class="invalid-tooltip">
                                            <div
                                                *ngIf="
                                                    (form.touched ||
                                                        form
                                                            .get('companyContacts')
                                                            .get(i.toString())
                                                            .get('contacts')
                                                            .get(j.toString())
                                                            .get('email').touched) &&
                                                    form
                                                        .get('companyContacts')
                                                        .get(i.toString())
                                                        .get('contacts')
                                                        .get(j.toString())
                                                        .get('email').errors &&
                                                    form
                                                        .get('companyContacts')
                                                        .get(i.toString())
                                                        .get('contacts')
                                                        .get(j.toString())
                                                        .get('email').errors['email']
                                                "
                                            >
                                                Ce champ doit être un email valide.
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item text-center">
                                    <button
                                        type="button"
                                        (click)="addContactToCategory(i)"
                                        class="btn btn-secondary"
                                        [disabled]="form.disabled || sending"
                                    >
                                        + Nouveau contact
                                    </button>
                                </li>
                            </ul>
                        </li>
                        <li class="list-group-item text-center">
                            <button
                                type="button"
                                (click)="addContactCategory()"
                                class="btn btn-secondary"
                                [disabled]="form.disabled || sending"
                            >
                                + Nouveau service
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-center text-center mt-4">
        <div class="col-md-4">
            <button
                type="button"
                [routerLink]="clientId ? '/admin/clients/' + clientId : '/admin/clients'"
                class="btn btn-secondary btn-block mb-4"
                [disabled]="form.disabled || sending"
            >
                Annuler
            </button>
        </div>
        <div class="col-md-4">
            <button
                type="submit"
                class="btn btn-primary btn-block mb-4"
                [innerHtml]="sending ? 'Sauvegarde ...' : 'Sauvegarder'"
                [disabled]="form.disabled || !form.valid || sending"
            ></button>
        </div>
    </div>
</form>

<div class="modal" tabindex="-1" role="dialog" #modalUpload>
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Envoie d'un document au serveur</h5>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <input
                        type="file"
                        #inputFile
                        class="form-control"
                        (change)="changeInputFile($event)"
                    />
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                    Annuler
                </button>
                <button type="button" class="btn btn-primary" (click)="upload()">Envoie</button>
            </div>
        </div>
    </div>
</div>
