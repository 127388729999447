import { Component, OnDestroy, OnInit } from '@angular/core';
import { Event, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { faBars, faCircleUser, faPowerOff, faUser } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { getUserFullname, IUser } from 'src/app/interfaces/user.interface';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { RemoteService } from 'src/app/services/remote.service';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss']
})
export class HeaderMenuComponent implements OnInit, OnDestroy {
  faUser = faUser;
  faPowerOff = faPowerOff;
  faBars = faBars;
  faCircleUser = faCircleUser;

  getUserFullname = getUserFullname;

  displayBreadcrumbs: boolean = false;

  currentUser: IUser | null = null;

  currentRoute: string = '/';

  displayShopSelect: boolean = false;

  private subscriptions = new Subscription();

  constructor(
    private router: Router,
    private remoteService: RemoteService,
    private loaderService: LoaderService
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.remoteService.userObservable.subscribe((user: IUser) => {
        this.currentUser = user;
      })
    );

    this.currentRoute = window.location.pathname;
    this.updatedCurrentUrl();

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // Show progress spinner or progress bar
        // console.log('Route change detected');
      }

      if (event instanceof NavigationEnd) {
        // Hide progress spinner or progress bar
        this.currentRoute = event.url;
      }

      if (event instanceof NavigationError) {
        // Hide progress spinner or progress bar

        // Present error to user
        console.log(event.error);
      }

      this.updatedCurrentUrl();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  updatedCurrentUrl(): void {
    this.displayBreadcrumbs = false;
    this.displayShopSelect = false;

    if (this.currentRoute.match('/admin')) {
      this.displayBreadcrumbs = true;
    }
  }

  async signOut(): Promise<void> {
    this.loaderService.presentLoader();

    try {
      // await this.usersService.signOut();
      await this.remoteService.signOut();

      this.loaderService.hideLoaderOnSuccess();

      this.router.navigateByUrl('/');
    } catch (err: any) {
      this.loaderService.hideLoaderOnFailure(err.message);
    }
  }
}
