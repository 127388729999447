import { finalize } from 'rxjs/operators';
import { Component, OnInit, ViewChild, NgZone, ElementRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  AbstractControl,
  FormControl,
  ValidatorFn,
  ValidationErrors
} from '@angular/forms';
import { FormCanDeactivate } from '../../../components/form-can-deactivate/form-can-deactivate';

import { RemoteService } from '../../../services/remote.service';
import { PipedriveService } from '../../../services/pipedrive.service';
import { AclService } from '../../../services/acl.service';

import { differenceInDays, format, getMonth } from 'date-fns';
import {
  EnumEnquiryType,
  getEnumEnquiryPrefix,
  getEnumEnquiryTypeLabel
} from 'src/app/enums/enquiry-type.enum';
import { EnumLanguage, getLanguageLabel } from 'src/app/enums/language.enum';
import {
  doesEnquiryPrefillAirlinesSelection,
  getEnquiryBreadcrumbTitle,
  getEnquiryRefTitle,
  getEnquiryRefTitleWithoutPrefix,
  hasBusinessPassengerField,
  IEnquiry,
  IEnquiryEmailAttachment
} from 'src/app/interfaces/enquiry.interface';
import {
  EnumItineraryType,
  getEnumItineraryTypeIconUrl,
  getEnumItineraryTypeLabel,
  getEnumItineraryTypeMaxTrips
} from 'src/app/enums/itinerary-type.enum';
import { EnumEnquiryTarget, getEnquiryTargetLabel } from 'src/app/enums/enquiry-target.enum';
import {
  EnumEnquiryPrefilterAirline,
  getEnumEnquiryPrefilterAirlineLabel
} from 'src/app/enums/enquiry-prefilter-airline.enum';
import { EnquiryService } from 'src/app/services/enquiry/enquiry.service';
import { IEnquiryItinerary } from 'src/app/interfaces/enquiry-itinerary.interface';
import { EnumEnquiryStatus } from 'src/app/enums/enquiry-status.enum';
import { Subscription } from 'rxjs';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { EnumContinentCode, getContinentLabel } from 'src/app/enums/continent-code.enum';
import { IUser, getUserFullname } from 'src/app/interfaces/user.interface';
import { addZeroToDigit } from 'src/app/misc.utils';
import { environment } from '../../../../environments/environment';
import { IPipedriveOrganization, IPipedrivePerson } from 'src/app/interfaces/pipedrive.interface';
import { IAircraftModel } from 'src/app/interfaces/aircraft-model.interface';
import { IAirport } from 'src/app/interfaces/airport.interface';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs/breadcrumbs.service';
import { UserService } from 'src/app/services/user/user.service';
import {
  faArrowLeft,
  faArrowRight,
  faCircleXmark,
  faCogs,
  faExternalLink,
  faFilter,
  faPlane,
  faTrash,
  faWarning,
  IconDefinition
} from '@fortawesome/free-solid-svg-icons';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { IEnquiryTrip } from 'src/app/interfaces/enquiry-trip.interface';

interface ITab {
  id: string;
  icon: IconDefinition;
  text: string;
}

@Component({
  selector: 'app-enquiry-edit',
  templateUrl: './enquiry-edit.component.html',
  styleUrls: ['./enquiry-edit.component.scss']
})
export class EnquiryEditComponent extends FormCanDeactivate implements OnInit, OnDestroy {
  @ViewChild('modalUpload', { static: false }) modalUploadElement: ElementRef;
  @ViewChild('inputFile', { static: false }) inputFileElement: ElementRef;

  getEnumEnquiryTypeLabel = getEnumEnquiryTypeLabel;
  getEnumEnquiryPrefix = getEnumEnquiryPrefix;
  getLanguageLabel = getLanguageLabel;
  getEnquiryBreadcrumbTitle = getEnquiryBreadcrumbTitle;
  getContinentLabel = getContinentLabel;

  getEnumItineraryTypeLabel = getEnumItineraryTypeLabel;
  getEnumItineraryTypeIconUrl = getEnumItineraryTypeIconUrl;
  getEnumItineraryTypeMaxTrips = getEnumItineraryTypeMaxTrips;

  getEnumEnquiryPrefilterAirlineLabel = getEnumEnquiryPrefilterAirlineLabel;
  getEnquiryTargetLabel = getEnquiryTargetLabel;
  doesEnquiryPrefillAirlinesSelection = doesEnquiryPrefillAirlinesSelection;
  hasBusinessPassengerField = hasBusinessPassengerField;

  addZeroToDigit = addZeroToDigit;

  faExternalLink = faExternalLink;
  faCircleXmark = faCircleXmark;
  faTrash = faTrash;
  faWarning = faWarning;
  faArrowLeft = faArrowLeft;
  faArrowRight = faArrowRight;

  EnumItineraryType = EnumItineraryType;
  EnumEnquiryType = EnumEnquiryType;

  rootUrl: string = './';
  isLogged: boolean = false;
  form: FormGroup = this.resetForm();
  enquiry: IEnquiry;
  enquiryId: string;
  clientId: string;
  client: IPipedriveOrganization | null = null;
  users: IUser[] = [];
  usersList: {
    title: string;
    value: string;
  }[] = [];
  contactsList: {
    title: string;
    value: string;
  }[] = [];
  airportsList: {
    title: string;
    value: string;
  }[] = [];
  airportsObj: { [key: string]: IAirport | null } = {};

  currentUser: IUser;
  @ViewChild('modalFlyInformation', { static: false }) modalFlyInformation: ElementRef;
  flyInformationContent: string = '';
  filterWorldwide: boolean = false;
  pipedriveOrganization: IPipedriveOrganization | null = null;
  pipedrivePerson: IPipedrivePerson | null = null;
  listYears: number[] = [];
  listMonths: number[] = [];
  currentMonth: number;
  currentYear: number;
  refFields: string[] = [];
  aircraftModels: IAircraftModel[] = [];
  willCreatePipedriveDeal: boolean = false;

  uploading: boolean = false;
  uploadingProgress: number;
  currentFile: File;

  lastEnquiry: IEnquiry | null = null;

  currentTabIndex: number = 0;

  subscriptions = new Subscription();

  constructor(
    private enquiryService: EnquiryService,
    private formBuilder: FormBuilder,
    private remoteService: RemoteService,
    private pipedriveService: PipedriveService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private aclService: AclService,
    private zone: NgZone,
    private breadcrumbsService: BreadcrumbsService,
    private userService: UserService,
    private loaderService: LoaderService
  ) {
    super();

    this.rootUrl = window['rootUrl'];

    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
    this.remoteService.userObservable.subscribe((user: IUser) => {
      this.currentUser = user;

      this.currentUserLoaded();
    });

    this.remoteService.aircraftModelsObservable.subscribe(
      (aircraftModels: IAircraftModel[]) => (this.aircraftModels = aircraftModels)
    );

    let year: number = 13; // Start in 2013
    this.currentYear = parseInt(format(new Date(), 'yy'));

    while (year <= this.currentYear + 5) {
      this.listYears.push(year);

      year++;
    }

    this.currentMonth = getMonth(new Date()) + 1;

    for (let i = 1; i <= 12; i++) {
      this.listMonths.push(i);
    }
  }

  getLanguages(): EnumLanguage[] {
    return Object.values(EnumLanguage);
  }

  getEnquiryTypes(): EnumEnquiryType[] {
    return Object.values(EnumEnquiryType);
  }

  getItineraryTypes(): EnumItineraryType[] {
    return Object.values(EnumItineraryType);
  }

  getEnquiryTargets(): EnumEnquiryTarget[] {
    return Object.values(EnumEnquiryTarget);
  }

  getEnquiryPrefilterAirlines(): EnumEnquiryPrefilterAirline[] {
    return Object.values(EnumEnquiryPrefilterAirline);
  }

  getContinentCodes(): EnumContinentCode[] {
    return Object.values(EnumContinentCode);
  }

  get type(): FormControl {
    return this.form.get('type') as FormControl;
  }

  get emailAttachments(): FormArray {
    return this.form.get('emailAttachments') as FormArray;
  }

  getEmailAttachment(i: number): FormGroup {
    return this.emailAttachments.at(i) as FormGroup;
  }

  getEmailAttachmentField(i: number, field: string): FormControl {
    return this.getEmailAttachment(i).get(field) as FormControl;
  }

  get itineraries(): FormArray {
    return this.form.get('itineraries') as FormArray;
  }

  getItinerary(i: number): FormGroup {
    return this.itineraries.at(i) as FormGroup;
  }

  getItineraryField(i: number, field: string): FormControl {
    return this.getItinerary(i).get(field) as FormControl;
  }

  getItineraryType(i: number): FormControl {
    return this.getItinerary(i).get('type') as FormControl;
  }

  getTrips(i: number): FormArray {
    return this.getItinerary(i).get('trips') as FormArray;
  }

  getTrip(i: number, j: number): FormGroup {
    return this.getTrips(i).at(j) as FormGroup;
  }

  getTripField(i: number, j: number, field: string): FormControl {
    return this.getTrip(i, j).get(field) as FormControl;
  }

  ngOnInit(): void {
    this.form = this.resetForm();

    this.form.disable();

    this.form.valueChanges.subscribe(() => {
      if (this.itineraries.value) {
        for (let i = 0; i < this.itineraries.value.length; i++) {
          for (let j = 0; j < this.itineraries.value[i].trips.length; j++) {
            if (
              this.getTripField(i, j, 'date').value === '' &&
              typeof this.getTrip(i, j - 1) !== 'undefined' &&
              this.getTripField(i, j - 1, 'date').value !== ''
            ) {
              this.getTripField(i, j, 'date').setValue(this.getTripField(i, j - 1, 'date').value);
            }
          }
        }
      }
    });

    this.activatedRoute.url.subscribe(async () => {
      this.enquiryId = this.activatedRoute.snapshot.paramMap.get('enquiryId');
      this.clientId = this.activatedRoute.snapshot.paramMap.get('clientId');

      if (this.clientId) {
        this.form.get('clientId').setValue(this.clientId);

        this.loadClient();
      }

      if (!this.enquiryId) {
        await this.aclService.checkAclAccess(EnumAcl.enquiriesAdd);

        this.willCreatePipedriveDeal = environment.automaticallyCreatePipedriveDealForNewEnquiry;

        this.addItinerary();

        this.currentUserLoaded();

        this.setRefFields();

        this.form.enable();
      } else {
        this.willCreatePipedriveDeal = false;
      }

      this.loadData();
    });
  }

  ngOnDestroy(): void {
    this.removeModal();

    this.subscriptions.unsubscribe();
  }

  async loadClient(): Promise<void> {
    this.client = await this.pipedriveService.getOrganization(this.clientId);
  }

  removeModal(): void {
    window['$'](this.modalFlyInformation.nativeElement).modal('hide');
    window['$'](this.modalUploadElement.nativeElement).modal('hide');
    window['$']('body').removeClass('modal-open');
    window['$']('.modal-backdrop').remove();
  }

  setRefFields(): void {
    this.refFields.push('refEnquiry');

    if (this.enquiry && this.enquiry.status === EnumEnquiryStatus.confirmed) {
      this.refFields.push('refContract');
    }
  }

  currentUserLoaded(): void {
    if (this.form && !this.enquiryId && this.currentUser) {
      this.form.get('receivedBy').setValue(this.currentUser.id);
      this.form.get('processedBy').setValue(this.currentUser.id);
    }
  }

  loadData(): void {
    if (this.isLogged) {
      if (this.enquiryId) {
        this.loadEnquiry();
      }

      this.loadLastEnquiryOfMonth();

      this.updateFilterBy();
      // this.loadClients()
      this.loadUsers();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  loadLastEnquiryOfMonth(): void {
    this.subscriptions.add(
      this.enquiryService
        .getLatestEnquiryOfMonth(false, this.currentYear, this.currentMonth)
        .subscribe((lastEnquiry: IEnquiry) => {
          this.lastEnquiry = lastEnquiry;
        })
    );
  }

  async loadClients(): Promise<void> {
    // const clientsObj: object[] = await this.remoteService.getAllDocuments('clients')
    // this.clientsList = []
    // for (const clientObj of clientsObj) {
    //   const client = new Client(clientObj)
    //   this.clientsList.push({
    //     title: client.title,
    //     value: client.id
    //   })
    //   this.clientsData[client.id] = client
    // }
    // this.populateContactsList()
  }

  async loadUsers(): Promise<void> {
    this.subscriptions.add(
      this.userService.getAll().subscribe((users: IUser[]) => {
        this.users = users;

        this.refreshUsersList();
      })
    );
  }

  refreshUsersList(): void {
    this.usersList = [];

    for (const user of this.users) {
      if (
        !user.isDisabled ||
        [this.form.value.receivedBy, this.form.value.processedBy].includes(user.id) // In case a disabled user is assigned to this enquiry
      ) {
        this.usersList.push({
          title: getUserFullname(user) + ' (' + user.initials + ')',
          value: user.id
        });
      }
    }

    this.usersList.sort((a, b) =>
      a.title.toLocaleLowerCase() < b.title.toLocaleLowerCase() ? -1 : 1
    );
  }

  loadEnquiry(): void {
    const sub = this.enquiryService
      .getFromId(this.enquiryId)
      .subscribe(async (enquiry: IEnquiry) => {
        sub.unsubscribe();

        this.enquiry = enquiry;

        if (this.enquiry) {
          this.breadcrumbsService.setCurrentItem({
            id: this.enquiry.id,
            text: getEnquiryBreadcrumbTitle(this.enquiry)
          });
        }

        if (this.enquiry.status === EnumEnquiryStatus.confirmed) {
          await this.aclService.checkAclAccess(EnumAcl.enquiriesEditForConfirmedEnquiry);
        } else {
          await this.aclService.checkAclAccess(EnumAcl.enquiriesEdit);
        }

        this.setRefFields();

        this.setEnquiry();
      });
  }

  setEnquiry(): void {
    if (this.form && this.enquiry) {
      this.form = this.resetForm();

      for (const field in this.form.value) {
        if (typeof this.enquiry[field] !== 'undefined') {
          switch (field) {
            case 'itineraries':
              this.itineraries.clear();

              for (const itinerary of this.enquiry.itineraries) {
                this.addItineraryAndSet(itinerary);
              }
              break;
            case 'emailAttachments':
              this.emailAttachments.clear();

              for (const emailAttachment of this.enquiry.emailAttachments) {
                this.addEmailAttachmentAndSet(emailAttachment);
              }
              break;
            default:
              this.form.get(field).setValue(this.enquiry[field]);
              break;
          }
        }
      }

      this.setRefPrefix();

      this.toggleWorldwide();

      setTimeout(async () => {
        await this.setTypeFields();
        this.refreshUsersList();
      }, 1000);

      this.form.enable();
    }
  }

  updatedFuelStopOver(i: number, j: number): void {
    if (!this.getTripField(i, j, 'hasFuelStopOver').value) {
      this.emptyFieldInTrip(i, j, 'fuelStopOverAirport');
    }
  }

  updatedCommercialStopOver(i: number, j: number): void {
    if (!this.getTripField(i, j, 'hasCommercialStopOver').value) {
      for (const field of [
        'commercialStopOverAirport',
        'passengersCommercialStop',
        'passengersCommercialStopEmbark',
        'passengersCommercialStopDismbark'
      ]) {
        this.emptyFieldInTrip(i, j, field);
      }
    }
  }

  emptyFieldInTrip(i: number, j: number, field: string): void {
    this.getTripField(i, j, field).setValue('');
  }

  setRefPrefix(): void {
    for (const enquiryType of Object.values(EnumEnquiryType)) {
      if (this.type.value === enquiryType) {
        for (const reField of ['refEnquiry', 'refContract']) {
          this.form.get(reField + 'Prefix').setValue(getEnumEnquiryPrefix(enquiryType));
        }
        break;
      }
    }
  }

  updateCurrentType(itineraryIndex: number, newType: EnumItineraryType): void {
    this.getItineraryType(itineraryIndex).setValue(newType);

    for (const type of this.getItineraryTypes()) {
      if (type === newType) {
        const maxTrips: number | null = getEnumItineraryTypeMaxTrips(type);
        if (maxTrips !== null) {
          // If missing trips, we add
          for (let i = this.getTrips(itineraryIndex).length; i < maxTrips; i++) {
            this.addTrip(itineraryIndex);
          }

          // If too many trips, we remove
          for (let i = this.getTrips(itineraryIndex).length; i >= maxTrips; i--) {
            this.removeTrip(itineraryIndex, i);
          }
        }
        break;
      }
    }
  }

  async setTypeFields(): Promise<void> {
    let fieldsRequired: string[] = [];
    let fieldsToHide: string[] = [];
    let tripFieldsRequired: string[] = [];
    let tripFieldsToHide: string[] = [];

    if (this.type.value === EnumEnquiryType.cargo) {
      fieldsRequired = ['weightMin', 'weightMax', 'volumeMin', 'volumeMax'];
      fieldsToHide = ['capacityMin', 'capacityMax'];

      tripFieldsRequired = [
        'weight',
        'volume',
        'natureFret',
        'dimensions',
        'airportDepartTax',
        'airportDestinationTax'
      ];
      tripFieldsToHide = ['passengersTotal', 'passengersBusiness', 'time'];
    } else if (this.type.value === EnumEnquiryType.sport) {
      fieldsRequired = ['capacityMin', 'capacityMax'];
      fieldsToHide = ['weightMin', 'weightMax', 'volumeMin', 'volumeMax'];

      tripFieldsRequired = ['passengersTotal', 'time'];
      tripFieldsToHide = [
        'weight',
        'volume',
        'natureFret',
        'dimensions',
        'airportDepartTax',
        'airportDestinationTax',
        'passengersBusiness'
      ];
    } else {
      fieldsRequired = ['capacityMin', 'capacityMax'];
      fieldsToHide = ['weightMin', 'weightMax', 'volumeMin', 'volumeMax'];

      tripFieldsRequired = [
        'passengersTotal',
        'passengersBusiness',
        'time',
        'airportDepartTax',
        'airportDestinationTax'
      ];
      tripFieldsToHide = ['weight', 'volume', 'natureFret', 'dimensions'];
    }

    if (hasBusinessPassengerField(this.type.value)) {
      tripFieldsRequired = tripFieldsRequired.concat(['passengersBusiness']);
    } else {
      tripFieldsToHide = tripFieldsToHide.concat(['passengersBusiness']);

      for (const field of ['passengersBusiness']) {
        if (tripFieldsRequired.includes(field)) {
          tripFieldsRequired.splice(tripFieldsRequired.indexOf(field), 1);
        }
      }
    }

    if (doesEnquiryPrefillAirlinesSelection(this.type.value)) {
      fieldsRequired = fieldsRequired.concat(['filterBy', 'targets', 'geographicalAreasCode']);

      if (this.form.get('filterBy').value === 'aircraftModel') {
        fieldsRequired.push('aircraftModelId');
      } else {
        fieldsToHide.push('aircraftModelId');
      }
    } else {
      fieldsToHide = fieldsToHide.concat([
        'filterBy',
        'targets',
        'aircraftModelId',
        'geographicalAreasCode',
        'weightMin',
        'weightMax',
        'volumeMin',
        'volumeMax'
      ]);

      for (const field of ['weightMin', 'weightMax', 'volumeMin', 'volumeMax']) {
        if (fieldsRequired.includes(field)) {
          fieldsRequired.splice(fieldsRequired.indexOf(field), 1);
        }
      }
    }

    for (let i = 0; i < this.itineraries.length; i++) {
      for (let j = 0; j < this.getTrips(i).length; j++) {
        for (const field of tripFieldsRequired) {
          this.getTripField(i, j, field).setValidators([Validators.required]);
          this.getTripField(i, j, field).updateValueAndValidity();
        }

        for (const field of tripFieldsToHide) {
          this.getTripField(i, j, field).setValue('');
          this.getTripField(i, j, field).clearValidators();
          this.getTripField(i, j, field).updateValueAndValidity();
        }
      }
    }

    if (!this.form.value.geographicalAreasCode || !this.form.value.geographicalAreasCode.length) {
      if (this.type.value !== EnumEnquiryType.cargo) {
        this.form.get('geographicalAreasCode').setValue([EnumContinentCode.EU]);
      }
    } else {
      if (
        !this.enquiryId &&
        this.type.value === EnumEnquiryType.cargo &&
        this.form.value.geographicalAreasCode.length === 1 &&
        this.form.value.geographicalAreasCode[0] == EnumContinentCode.EU
      ) {
        this.form.get('geographicalAreasCode').setValue([]);
      }
    }

    for (const field of fieldsRequired) {
      this.form.get(field).setValidators([Validators.required]);
      this.form.get(field).updateValueAndValidity();
    }

    for (const field of fieldsToHide) {
      this.form.get(field).setValue('');
      this.form.get(field).clearValidators();
      this.form.get(field).updateValueAndValidity();
    }

    this.updateWorlwideChecked();

    this.setRefPrefix();
  }

  clearAllAirports(): void {
    for (let i = 0; i < this.itineraries.length; i++) {
      for (let j = 0; j < this.getTrips(i).length; j++) {
        this.getTripField(i, j, 'airportDepart').setValue('');
        this.getTripField(i, j, 'airportDestination').setValue('');
      }
    }
  }

  addTrip(itineraryIndex: number): void {
    this.getTrips(itineraryIndex).push(
      new FormGroup({
        id: new FormControl(this.remoteService.generateRandomId(), [Validators.required]),
        date: new FormControl('', [Validators.required, this.dateMinimum('2000-01-01')]),
        time: new FormControl('', [Validators.required]),
        airportDepart: new FormControl('', [Validators.required]),
        airportDepartTax: new FormControl(0, [Validators.required]),
        airportDestination: new FormControl('', [Validators.required]),
        airportDestinationTax: new FormControl(0, [Validators.required]),
        passengersTotal: new FormControl('', [Validators.required]),
        passengersBusiness: new FormControl('', [Validators.required]),
        weight: new FormControl(''),
        volume: new FormControl(''),
        natureFret: new FormControl(''),
        dimensions: new FormControl(''),
        hasFuelStopOver: new FormControl(false),
        fuelStopOverAirport: new FormControl(''),
        hasCommercialStopOver: new FormControl(false),
        commercialStopOverAirport: new FormControl(''),
        passengersCommercialStop: new FormControl(''),
        passengersCommercialStopEmbark: new FormControl(''),
        passengersCommercialStopDisembark: new FormControl(''),
        distanceInKm: new FormControl(null),
        speedInKts: new FormControl(null)
      })
    );

    this.updateAirportDestination(itineraryIndex);

    this.form.updateValueAndValidity();

    setTimeout(async () => {
      await this.setTypeFields();
    }, 1000);
  }

  addTripAndSet(i: number, trip: IEnquiryTrip): void {
    this.addTrip(i);

    const j: number = this.getTrips(i).length - 1;

    for (const field in this.getTrip(i, j).value) {
      if (typeof trip[field] !== 'undefined') {
        switch (field) {
          default:
            this.getTripField(i, j, field).setValue(trip[field]);
            this.getTripField(i, j, field).updateValueAndValidity();
            break;
        }
      }
    }
  }

  addEmailAttachment(): void {
    this.emailAttachments.push(
      new FormGroup({
        id: new FormControl(this.remoteService.generateRandomId(), [Validators.required]),
        name: new FormControl('', [Validators.required]),
        size: new FormControl(0),
        type: new FormControl('', [Validators.required]),
        url: new FormControl('', [Validators.required]),
        language: new FormControl('')
      })
    );

    this.form.updateValueAndValidity();
  }

  addEmailAttachmentAndSet(emailAttachment: IEnquiryEmailAttachment): void {
    this.addEmailAttachment();

    const i: number = this.emailAttachments.length - 1;

    for (const field in this.getEmailAttachment(i).value) {
      if (typeof emailAttachment[field] !== 'undefined') {
        switch (field) {
          default:
            this.getEmailAttachmentField(i, field).setValue(emailAttachment[field]);
            this.getEmailAttachmentField(i, field).updateValueAndValidity();
            break;
        }
      }
    }
  }

  removeTrip(itineraryIndex: number, i: number): void {
    const tripsFormArray = (this.form.get('itineraries') as FormArray)
      .at(itineraryIndex)
      .get('trips') as FormArray;

    tripsFormArray.removeAt(i);

    this.updateAirportDestination(itineraryIndex);

    this.form.updateValueAndValidity();
  }

  deleteTrip(itineraryIndex: number, i: number): void {
    const result = confirm(
      'La suppression de la destination sera permanente. Êtes-vous sûr de vouloir continuer?'
    );

    if (result) {
      this.removeTrip(itineraryIndex, i);
    }
  }

  selectItinerary(i: number): void {
    this.currentTabIndex = i;
  }

  addItineraryAndSelect(): void {
    this.addItinerary(true);

    this.selectItinerary(this.form.value.itineraries.length - 1);
  }

  addItinerary(prefill: boolean = false): void {
    // Pre-fill new itinerary with previous one
    let prevValues: IEnquiryItinerary;
    if (this.itineraries.length) {
      prevValues = this.itineraries.at(this.itineraries.length - 1).value;
    }

    this.itineraries.push(
      new FormGroup({
        id: new FormControl(this.remoteService.generateRandomId(), [Validators.required]),
        title: new FormControl('Itinéraire ' + (this.itineraries.length + 1), [
          Validators.required
        ]),
        type: new FormControl('round-trip', [Validators.required]),
        trips: new FormArray([])
      })
    );

    if (prefill) {
      if (prevValues && prevValues.trips) {
        for (let j = 0; j < prevValues.trips.length; j++) {
          this.addTrip(this.itineraries.length - 1);

          for (const field in prevValues.trips[j]) {
            if (
              !['id', 'airportDestination', 'airportDestinationTax', 'distanceInKm'].includes(field)
            ) {
              this.getTripField(this.itineraries.length - 1, j, field).setValue(
                prevValues.trips[j][field]
              );
            }
          }
        }
      }

      for (const field in prevValues) {
        if (['id', 'trips', 'title'].indexOf(field) === -1) {
          this.itineraries
            .at(this.itineraries.length - 1)
            .get(field)
            .setValue(prevValues[field]);
        }
      }
    }

    this.updateCurrentType(
      this.form.value.itineraries.length - 1,
      this.form.value.itineraries[this.form.value.itineraries.length - 1].type
    );

    this.form.updateValueAndValidity();
  }

  addItineraryAndSet(itinerary: IEnquiryItinerary): void {
    this.addItinerary();

    const i: number = this.itineraries.length - 1;

    for (const field in this.getItinerary(i).value) {
      if (typeof itinerary[field] !== 'undefined') {
        switch (field) {
          case 'trips':
            this.getTrips(i).clear();

            for (const trip of itinerary.trips) {
              this.addTripAndSet(i, trip);
            }
            break;
          default:
            this.getItineraryField(i, field).setValue(itinerary[field]);
            this.getItineraryField(i, field).updateValueAndValidity();
            break;
        }
      }
    }

    this.updateAirportDestination(i);
  }

  deleteItinerary(i: number): void {
    const result = confirm('Êtes-vous sûr de vouloir supprimer cet itinéraire ?');

    if (result) {
      this.itineraries.removeAt(i);

      if (i > 0) {
        this.selectItinerary(i - 1);
      }

      this.form.updateValueAndValidity();
    }
  }

  updateAirportDestination(itineraryIndex: number): void {
    if (['round-trip'].indexOf(this.form.value.itineraries[itineraryIndex].type) !== -1) {
      for (let i = this.form.value.itineraries[itineraryIndex].trips.length - 1; i >= 0; i--) {
        if (i > 0) {
          this.getTripField(itineraryIndex, i - 1, 'airportDestination').setValue(
            this.form.value.itineraries[itineraryIndex].trips[i].airportDepart
          );
          this.getTripField(itineraryIndex, i - 1, 'airportDestinationTax').setValue(
            this.form.value.itineraries[itineraryIndex].trips[i].airportDepartTax
          );
        }
      }

      if (this.form.value.itineraries[itineraryIndex].trips.length > 1) {
        this.getTripField(
          itineraryIndex,
          this.form.value.itineraries[itineraryIndex].trips.length - 1,
          'airportDestination'
        ).setValue(this.form.value.itineraries[itineraryIndex].trips[0].airportDepart);
        this.getTripField(
          itineraryIndex,
          this.form.value.itineraries[itineraryIndex].trips.length - 1,
          'airportDestinationTax'
        ).setValue(this.form.value.itineraries[itineraryIndex].trips[0].airportDepartTax);
      }
    }
  }

  async submitForm(): Promise<void> {
    this.form.markAsTouched();

    if (this.form.valid) {
      this.loaderService.presentLoader();

      let data: IEnquiry = Object.assign({}, this.form.value) as IEnquiry;

      this.form.disable();

      await this.savePipedriveOrgaAndPersonInDatabase(
        typeof data['clientId'] === 'number' ? data['clientId'].toString() : data['clientId'],
        data['contactId']
      );

      data = await this.setFieldDataAutomaticallyBeforeSubmit(data);

      this.form.enable();

      data = this.fixUndefinedValues(data);

      let promise;
      if (this.enquiryId) {
        data.id = this.enquiryId;
        promise = () => this.enquiryService.update(data);
      } else {
        promise = () => this.enquiryService.create(data);
      }
      promise()
        .then(async id => {
          if (!this.enquiryId) {
            this.enquiryId = id;
          }

          await this.createPipedriveDeal();

          this.form.reset();
          this.form.enable();

          await this.loaderService.hideLoaderOnSuccess();

          this.router.navigate(['/admin/enquiries/' + this.enquiryId + '/select-airlines']);
        })
        .catch(err => {
          this.loaderService.hideLoaderOnFailure(err.message);
        })
        .finally(() => {
          this.form.enable();
        });
    }
  }

  fixUndefinedValues(data: IEnquiry): IEnquiry {
    for (const field in data) {
      if (Array.isArray(data[field]) || typeof data[field] === 'object') {
        data[field] = this.fixUndefinedValues(data[field]);
      } else if (typeof data[field] === 'undefined') {
        data[field] = null;
      }
    }

    return data;
  }

  async createPipedriveDeal(): Promise<void> {
    if (this.willCreatePipedriveDeal) {
      const sub = this.enquiryService
        .getFromId(this.enquiryId)
        .subscribe(async (enquiry: IEnquiry) => {
          sub.unsubscribe();

          const pipedriveDealId: number = await this.pipedriveService.createDealFromEnquiry(
            enquiry
          );

          await this.remoteService.updateDocumentToCollection('enquiries', enquiry.id, {
            pipedriveDealId: pipedriveDealId
          });
        });
    }
  }

  async savePipedriveOrgaAndPersonInDatabase(
    organizationId: string,
    personId: string
  ): Promise<void> {
    this.pipedriveOrganization = await this.pipedriveService.getOrganization(organizationId);
    this.pipedrivePerson = await this.pipedriveService.getPerson(personId);

    await this.remoteService.setDocumentToCollection(
      'pipedriveOrganizations',
      organizationId.toString(),
      JSON.parse(JSON.stringify(this.pipedriveOrganization))
    );
    await this.remoteService.setDocumentToCollection(
      'pipedrivePersons',
      personId.toString(),
      JSON.parse(JSON.stringify(this.pipedrivePerson))
    );
  }

  async loadAirport(airportId: string): Promise<void> {
    if (airportId && !this.airportsObj[airportId]) {
      const doc: object = await this.remoteService.getDocument('airports', airportId);

      const airport = doc as IAirport;

      this.airportsObj[airport.id] = airport;
    }
  }

  async setFieldDataAutomaticallyBeforeSubmit(data: IEnquiry): Promise<IEnquiry> {
    if (!data.status) {
      data.status = EnumEnquiryStatus.draft;
    }

    if (!this.enquiry && data.status !== EnumEnquiryStatus.confirmed) {
      // No ref field for contract because enquiry is not confirmed
      data.refContractPrefix = null;
      data.refContractYear = null;
      data.refContractMonth = null;
      data.refContractNumber = null;
    }

    if (!data.refEnquiryNumber) {
      if (this.lastEnquiry) {
        data.refEnquiryNumber = this.lastEnquiry.refEnquiryNumber + 1;
      } else {
        data.refEnquiryNumber = 1;
      }
    }

    for (const refField of ['refEnquiry', 'refContract']) {
      data = this.setRefTitle(refField, data);
    }

    if (data.clientId && this.pipedriveOrganization) {
      data.clientTitle = this.pipedriveOrganization.name;
    }

    if (data.contactId && this.pipedrivePerson) {
      data.contactTitle = this.pipedrivePerson.name;
    }

    for (const i in data.itineraries) {
      const routingArr: string[] = [];
      const datesLabelArr: string[] = [];
      const passengersTotalLabelArr: string[] = [];

      for (const j in data.itineraries[i].trips) {
        if (typeof this.airportsObj[data.itineraries[i].trips[j].airportDepart] === 'undefined') {
          await this.loadAirport(data.itineraries[i].trips[j].airportDepart);
        }

        routingArr.push(this.airportsObj[data.itineraries[i].trips[j].airportDepart].iataCode);

        datesLabelArr.push(data.itineraries[i].trips[j].date);
        passengersTotalLabelArr.push(data.itineraries[i].trips[j].passengersTotal.toString());

        for (const airportField of ['airportDepart', 'airportDestination']) {
          try {
            let legDate: string = data.itineraries[i].trips[j].date;

            if (data.itineraries[i].trips[j].time) {
              legDate += 'T' + data.itineraries[i].trips[j].time;
            } else {
              legDate += 'T12:00';
            }

            if (
              typeof this.airportsObj[data.itineraries[i].trips[j][airportField]] === 'undefined'
            ) {
              await this.loadAirport(data.itineraries[i].trips[j][airportField]);
            }

            const result: object = await this.remoteService.getTimezoneFromCoordinates(
              this.airportsObj[data.itineraries[i].trips[j][airportField]].latitude,
              this.airportsObj[data.itineraries[i].trips[j][airportField]].longitude,
              new Date(legDate).getTime() / 1000
            );

            if (result) {
              const timezoneRawOffset: number = result['rawOffset'] + result['dstOffset'];
              const timezoneGmt: number = timezoneRawOffset / 3600;

              data.itineraries[i].trips[j][airportField + 'TimezoneId'] = result['timeZoneId'];
              data.itineraries[i].trips[j][airportField + 'TimezoneRawOffset'] = timezoneRawOffset;
              data.itineraries[i].trips[j][airportField + 'TimezoneGmt'] =
                timezoneGmt >= 0 ? '+' + timezoneGmt : timezoneGmt.toString();
            }
          } catch (err) {
            console.log(err);
          }
        }
      }

      if (
        typeof this.airportsObj[
          data.itineraries[i].trips[data.itineraries[i].trips.length - 1].airportDestination
        ] === 'undefined'
      ) {
        await this.loadAirport(
          data.itineraries[i].trips[data.itineraries[i].trips.length - 1].airportDestination
        );
      }

      routingArr.push(
        this.airportsObj[
          data.itineraries[i].trips[data.itineraries[i].trips.length - 1].airportDestination
        ].iataCode
      );

      data.itineraries[i].routing = routingArr.join('-');
      data.itineraries[i].datesLabel = datesLabelArr.join(' ');
      data.itineraries[i].passengersTotalLabel = passengersTotalLabelArr.join('/');
    }

    return data;
  }

  setRefTitle(refField: string, data: IEnquiry): IEnquiry {
    if (data[refField + 'Number']) {
      data[refField + 'TitleWithoutPrefix'] = getEnquiryRefTitleWithoutPrefix(
        data[refField + 'Year'],
        data[refField + 'Month'],
        data[refField + 'Number']
      );

      data[refField + 'Title'] = getEnquiryRefTitle(
        data[refField + 'Prefix'],
        data[refField + 'Year'],
        data[refField + 'Month'],
        data[refField + 'Number']
      );

      data[refField + 'TitleWithoutPrefixDisplayed'] = getEnquiryRefTitleWithoutPrefix(
        data[refField + 'Year'],
        data[refField + 'Month'],
        data[refField + 'Number'],
        2
      );

      data[refField + 'TitleDisplayed'] = getEnquiryRefTitle(
        data[refField + 'Prefix'],
        data[refField + 'Year'],
        data[refField + 'Month'],
        data[refField + 'Number'],
        2
      );
    }

    return data;
  }

  getRefTitleFromForm(refField: string): string {
    return getEnquiryRefTitle(
      this.form.value[refField + 'Prefix'],
      this.form.value[refField + 'Year'],
      this.form.value[refField + 'Month'],
      this.form.value[refField + 'Number'],
      2
    );
  }

  async setValueToFormControl($event: {
    fields: {
      name: string;
      value: string;
    }[];
  }): Promise<void> {
    for (let field of $event.fields) {
      const nameList = field.name.split('.');

      let formControl: AbstractControl = this.form;
      for (let name of nameList) {
        formControl = formControl.get(name);
      }

      formControl.setValue(field.value);

      if (
        [
          'airportDepart',
          'airportDestination',
          'fuelStopOverAirport',
          'commercialStopOverAirport'
        ].indexOf(nameList[nameList.length - 1]) !== -1
      ) {
        await this.loadAirport(field.value);
      }

      if (['airportDepart', 'airportDestination'].indexOf(nameList[nameList.length - 1]) !== -1) {
        this.updateAirportDestination(parseInt(nameList[1]));
      }

      formControl.markAsTouched();
      formControl.updateValueAndValidity();
    }
  }

  convertHourToTime(value: number): string {
    let totalMinutes = value * 60;
    let hours = totalMinutes / 60;
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);

    return addZeroToDigit(rhours) + ':' + addZeroToDigit(rminutes);
  }

  private convertKtsToKmH(value: number): number {
    return value * 1.852;
  }

  private degreesToRadians(degrees: number): number {
    return (degrees * Math.PI) / 180;
  }

  private getDistanceBetweenPoints(lat1: number, lng1: number, lat2: number, lng2: number): number {
    // The radius of the planet earth in meters
    const R = 6371; // Radius of the earth in km
    const dLat = this.degreesToRadians(lat2 - lat1); // deg2rad below
    const dLon = this.degreesToRadians(lng2 - lng1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.degreesToRadians(lat1)) *
        Math.cos(this.degreesToRadians(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km

    return d;
  }

  roundNumber(value: number): number {
    return Math.round(value * 100 + Number.EPSILON) / 100;
  }

  toggleCheckboxField(field: string, value: string): void {
    const index: number = this.form.value[field].indexOf(value);

    let newValue: string[] = [...this.form.value[field]];

    if (index === -1) {
      newValue.push(value);
    } else {
      newValue.splice(index, 1);
    }

    this.form.get(field).setValue(newValue);

    this.updateWorlwideChecked();
  }

  toggleWorldwide(): void {
    const geographicalAreasCodes: string[] = [];

    if (!this.filterWorldwide) {
      for (const geographicalAreasCode of this.getContinentCodes()) {
        geographicalAreasCodes.push(geographicalAreasCode);
      }
    }

    this.form.get('geographicalAreasCode').setValue(geographicalAreasCodes);

    this.updateWorlwideChecked();
  }

  updateWorlwideChecked(): void {
    this.filterWorldwide =
      this.form.value.geographicalAreasCode.length === Object.values(EnumContinentCode).length;
  }

  updateFilterBy(): void {
    let fieldSelected: string;

    switch (this.form.value.filterBy) {
      case EnumEnquiryPrefilterAirline.target:
        fieldSelected = 'targets';
        break;
      case EnumEnquiryPrefilterAirline.aircraftModel:
        fieldSelected = 'aircraftModelId';

        if (!this.aircraftModels || !this.aircraftModels.length) {
          this.remoteService.loadAircraftModels();
        }
        break;
    }

    for (const field of ['targets', 'aircraftModelId']) {
      if (field === fieldSelected) {
        this.form.get(field).setValidators([Validators.required]);
        this.form.get(field).updateValueAndValidity();
      } else {
        this.form.get(field).setValue('');
        this.form.get(field).clearValidators();
        this.form.get(field).updateValueAndValidity();
      }
    }
  }

  deleteDocument(i: number): void {
    const result = confirm(
      'La suppression du document sera permanent. Êtes-vous sûr de vouloir continuer?'
    );

    if (result) {
      const documentsUrlFormArray = this.form.get('documentsUrl') as FormArray;

      documentsUrlFormArray.removeAt(i);

      this.form.updateValueAndValidity();
    }
  }

  changeInputFile(fileInput: any): void {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.currentFile = fileInput.target.files[0];
    }
  }

  showUploadModal(): void {
    window['$'](this.modalUploadElement.nativeElement).modal('show');
  }

  upload(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.form.disable();
      this.uploading = true;
      this.uploadingProgress = 0;

      let result = this.remoteService.upload(
        'enquiries/emailAttachments',
        null,
        this.currentFile,
        'file'
      );

      result['task'].percentageChanges().subscribe((progress: number) => {
        this.zone.run(() => {
          this.uploadingProgress = Math.round(progress);
        });
      });

      result['task']
        .snapshotChanges()
        .pipe(
          finalize(() => {
            result['ref'].getDownloadURL().subscribe((downloadUrl: string) => {
              this.form.enable();

              this.addEmailAttachmentAndSet({
                name: this.currentFile['name'],
                type: this.currentFile['type'],
                size: this.currentFile['size'],
                url: downloadUrl
              } as IEnquiryEmailAttachment);

              this.inputFileElement.nativeElement.value = '';

              window['$'](this.modalUploadElement.nativeElement).modal('hide');

              this.uploading = false;
            });
          })
        )
        .subscribe();
    });
  }

  openModalUpload(): void {
    window['$'](this.modalUploadElement.nativeElement).modal('show');
  }

  deleteEmailAttachment(emailAttachmentId: string): void {
    const result = confirm(
      'La suppression du document sera permanente. Êtes-vous sûr de vouloir continuer?'
    );

    if (result) {
      for (const i in this.form.value.emailAttachments) {
        if (this.form.value.emailAttachments[i].id === emailAttachmentId) {
          (this.form.get('emailAttachments') as FormArray).removeAt(parseInt(i, 10));

          this.form.updateValueAndValidity();
          break;
        }
      }
    }
  }

  formatBytes(bytes: number, decimals: number = 2): string {
    return this.remoteService.formatBytes(bytes, decimals);
  }

  passengersCommercialStop(itineraryIndex: number, tripIndex: number): void {
    let nbPassengersCommercialStop: number = 0;

    if (
      this.form.value.itineraries[itineraryIndex].trips[tripIndex].passengersCommercialStopEmbark
    ) {
      nbPassengersCommercialStop += parseInt(
        this.form.value.itineraries[itineraryIndex].trips[tripIndex].passengersCommercialStopEmbark
      );
    }

    if (
      this.form.value.itineraries[itineraryIndex].trips[tripIndex].passengersCommercialStopDisembark
    ) {
      nbPassengersCommercialStop -= parseInt(
        this.form.value.itineraries[itineraryIndex].trips[tripIndex]
          .passengersCommercialStopDisembark
      );
    }

    ((this.form.get('itineraries') as FormArray).at(itineraryIndex).get('trips') as FormArray)
      .at(tripIndex)
      .get('passengersCommercialStop')
      .setValue(nbPassengersCommercialStop);
  }

  dateMinimum(date: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      if (differenceInDays(new Date(control.value), new Date(date)) < 0) {
        return {
          dateBelowMinimum: true,
          dateMinimum: new Date(date)
        };
      } else {
        return null;
      }
    };
  }

  resetForm(): FormGroup {
    return new FormGroup({
      refEnquiryPrefix: new FormControl('', [Validators.required]),
      refEnquiryYear: new FormControl(this.currentYear, [Validators.required]),
      refEnquiryMonth: new FormControl(this.currentMonth, [Validators.required]),
      refEnquiryNumber: new FormControl(null),
      refContractPrefix: new FormControl(''),
      refContractYear: new FormControl(this.currentYear),
      refContractMonth: new FormControl(this.currentMonth),
      refContractNumber: new FormControl(null),
      clientId: new FormControl('', [Validators.required]),
      contactId: new FormControl('', [Validators.required]),
      type: new FormControl('', [Validators.required]),
      receivedBy: new FormControl('', [Validators.required]),
      processedBy: new FormControl('', [Validators.required]),
      noteForAirlines: new FormControl(''),
      comments: new FormControl(''),
      itineraries: new FormArray([]),
      capacityMin: new FormControl('', [Validators.required]),
      capacityMax: new FormControl('', [Validators.required]),
      filterBy: new FormControl(EnumEnquiryPrefilterAirline.target, [Validators.required]),
      targets: new FormControl([EnumEnquiryTarget.jet], [Validators.required]),
      aircraftModelId: new FormControl('', [Validators.required]),
      geographicalAreasCode: new FormControl([], [Validators.required]),
      weightMin: new FormControl(''),
      weightMax: new FormControl(''),
      volumeMin: new FormControl(''),
      volumeMax: new FormControl(''),
      emailAttachments: new FormArray([]),
      status: new FormControl(EnumEnquiryStatus.draft, [Validators.required])
    });
  }
}
