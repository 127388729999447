<div class="row justify-content-center" *ngIf="!displayEncaissementForm">
    <div [class.col-lg-4]="data" [class.col-lg-6]="!data">
        <div class="card mb-4">
            <div class="card-body">
                <p class="text-center">
                    Veuillez importer le fichier extrait du compte bancaire. Vous pouvez télécharger
                    un exemple
                    <a href="assets/exemple_import_encaissements.csv" target="_blank">ici</a>.
                </p>
                <div class="form-group mb-0">
                    <input
                        type="file"
                        id="fileInput"
                        class="form-control"
                        (change)="changeInputFile($event)"
                        accept="text/csv"
                    />
                </div>
            </div>
        </div>

        <div class="text-center mb-4" *ngIf="data">
            <div class="alert alert-danger text-center" *ngIf="!data.operations.length">
                Aucune opération à importer. Veuillez vérifier le fichier.
            </div>
            <ng-container *ngIf="data.operations.length">
                <p>
                    Si tout est bon dans l'aperçu des données, cliquez ci-dessous pour générer les
                    <strong [innerText]="data.operations.length"></strong> encaissements.
                </p>

                <button
                    type="button"
                    class="btn btn-primary"
                    (click)="generateEncaissementsFromImportedData()"
                >
                    Générer
                </button>
            </ng-container>
        </div>
    </div>

    <div class="col-lg-8" *ngIf="data && data.operations.length">
        <div class="card mb-4">
            <div class="card-header">Aperçu des données importées</div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-9" *ngIf="data.ibanAccount">
                        <div class="form-group">
                            <label>Compte IBAN</label>
                            <div class="form-control fake-input disabled">
                                <span class="fake-input-text" [innerText]="data.ibanAccount"></span>
                            </div>
                        </div>
                    </div>
                    <div [class.col-md-3]="data.ibanAccount" [class.col-md-4]="!data.ibanAccount">
                        <div class="form-group">
                            <label>Devise</label>
                            <div class="form-control fake-input disabled">
                                <span class="fake-input-text" [innerText]="data.currency"></span>
                            </div>
                        </div>
                    </div>
                    <div [class.col-md-6]="data.ibanAccount" [class.col-md-4]="!data.ibanAccount">
                        <div class="form-group">
                            <label>Date de début</label>
                            <div class="form-control fake-input disabled">
                                <span class="fake-input-text" [innerText]="data.dateStart"></span>
                            </div>
                        </div>
                    </div>
                    <div [class.col-md-6]="data.ibanAccount" [class.col-md-4]="!data.ibanAccount">
                        <div class="form-group">
                            <label>Date de fin</label>
                            <div class="form-control fake-input disabled">
                                <span class="fake-input-text" [innerText]="data.dateEnd"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Libellé</th>
                                <th>DEBIT EUR</th>
                                <th>CREDIT EUR</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let operation of data.operations">
                                <td [innerText]="operation.date"></td>
                                <td [innerText]="operation.title"></td>
                                <td [innerText]="operation.debit"></td>
                                <td [innerText]="operation.credit"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<form (submit)="submitForm()" [formGroup]="form" (keydown.enter)="submitForm()" class="main-form">
    <div class="card mb-4" *ngIf="displayEncaissementForm" formArrayName="encaissements">
        <div class="card-header">
            Encaissements à valider
            <span
                class="float-end"
                [innerText]="
                    currentEncaissementIndex + 1 + '/' + encaissements.length + ' encaissements'
                "
            ></span>
        </div>
        <ng-container *ngFor="let encaissement of encaissements.controls; let i = index">
            <div class="card-body" [formGroupName]="i" *ngIf="i === currentEncaissementIndex">
                <div class="form-group">
                    <label for="name">Libellé</label>
                    <input
                        type="text"
                        class="form-control"
                        id="libelle"
                        formControlName="libelle"
                        (keyup)="getSuggestionInvoices(i)"
                        rows="3"
                        [class.is-invalid]="
                            (encaissements.touched || getEncaissementField(i, 'libelle').touched) &&
                            getEncaissementField(i, 'libelle').status === 'INVALID'
                                ? 'danger'
                                : ''
                        "
                    />

                    <div class="invalid-tooltip">
                        <div
                            *ngIf="
                                (encaissements.touched ||
                                    getEncaissementField(i, 'libelle').touched) &&
                                getEncaissementField(i, 'libelle').errors &&
                                getEncaissementField(i, 'libelle').errors['required']
                            "
                        >
                            Ce champ est obligatoire.
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="encaissementTypeId">Type d'encaissement</label>
                            <select
                                class="form-select"
                                id="encaissementTypeId"
                                formControlName="encaissementTypeId"
                                required
                                [class.is-invalid]="
                                    (encaissements.touched ||
                                        getEncaissementField(i, 'encaissementTypeId').touched) &&
                                    getEncaissementField(i, 'encaissementTypeId').status ==
                                        'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            >
                                <option
                                    [value]="encaissementType.id"
                                    *ngFor="let encaissementType of encaissementTypesList"
                                    [innerHtml]="encaissementType.name"
                                ></option>
                            </select>

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (encaissements.touched ||
                                            getEncaissementField(i, 'encaissementTypeId')
                                                .touched) &&
                                        getEncaissementField(i, 'encaissementTypeId').errors &&
                                        getEncaissementField(i, 'encaissementTypeId').errors[
                                            'required'
                                        ]
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="currency">Devise</label>
                            <select
                                class="form-select"
                                id="currency"
                                formControlName="currency"
                                required
                                (keyup)="getSuggestionInvoices(i)"
                                [class.is-invalid]="
                                    (encaissements.touched ||
                                        getEncaissementField(i, 'currency').touched) &&
                                    getEncaissementField(i, 'currency').status === 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            >
                                <option
                                    [value]="currency"
                                    *ngFor="let currency of getCurrencies()"
                                    [innerHtml]="
                                        getEnumCurrencySymbol(currency) +
                                        ' (' +
                                        getEnumCurrencyLabel(currency) +
                                        ')'
                                    "
                                ></option>
                            </select>

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (encaissements.touched ||
                                            getEncaissementField(i, 'currency').touched) &&
                                        getEncaissementField(i, 'currency').errors &&
                                        getEncaissementField(i, 'currency').errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="name">Montant</label>
                            <div class="input-group">
                                <input
                                    class="form-control"
                                    id="amount"
                                    formControlName="amount"
                                    type="number"
                                    required
                                    (keyup)="getSuggestionInvoices(i)"
                                    [class.is-invalid]="
                                        (encaissements.touched ||
                                            getEncaissementField(i, 'amount').touched) &&
                                        getEncaissementField(i, 'amount').status === 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                />

                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (encaissements.touched ||
                                                getEncaissementField(i, 'amount').touched) &&
                                            getEncaissementField(i, 'amount').errors &&
                                            getEncaissementField(i, 'amount').errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                                <span
                                    class="input-group-text"
                                    [innerText]="
                                        getEnumCurrencySymbol(
                                            getEncaissementField(i, 'currency').value
                                        )
                                    "
                                ></span>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="date">Date</label>
                            <input
                                class="form-control"
                                id="date"
                                formControlName="date"
                                type="date"
                                (keyup)="getSuggestionInvoices(i)"
                                (change)="getSuggestionInvoices(i)"
                                [class.is-invalid]="
                                    (encaissements.touched ||
                                        getEncaissementField(i, 'date').touched) &&
                                    getEncaissementField(i, 'date').status === 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (encaissements.touched ||
                                            getEncaissementField(i, 'date').touched) &&
                                        getEncaissementField(i, 'date').errors &&
                                        getEncaissementField(i, 'date').errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="bankAccountId">Compte bancaire</label>
                            <select
                                class="form-select"
                                id="bankAccountId"
                                formControlName="bankAccountId"
                                required
                                [class.is-invalid]="
                                    (encaissements.touched ||
                                        getEncaissementField(i, 'bankAccountId').touched) &&
                                    getEncaissementField(i, 'bankAccountId').status === 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            >
                                <option
                                    [value]="bankAccount.id"
                                    *ngFor="let bankAccount of bankAccountsList"
                                    [innerHtml]="bankAccount.name"
                                ></option>
                            </select>

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (encaissements.touched ||
                                            getEncaissementField(i, 'bankAccountId').touched) &&
                                        getEncaissementField(i, 'bankAccountId').errors &&
                                        getEncaissementField(i, 'bankAccountId').errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>
                        <div class="form-check mb-2">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                id="is-reconciled"
                                formControlName="isReconciled"
                            />
                            <label class="form-check-label" for="is-reconciled">Est pointé ?</label>
                        </div>

                        <div class="form-group">
                            <label for="comment">Commentaire</label>
                            <textarea
                                class="form-control"
                                id="comment"
                                formControlName="comment"
                                [class.is-invalid]="
                                    (form.touched || getEncaissementField(i, 'comment').touched) &&
                                    getEncaissementField(i, 'comment').status === 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            ></textarea>

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched ||
                                            getEncaissementField(i, 'comment').touched) &&
                                        getEncaissementField(i, 'comment').errors &&
                                        getEncaissementField(i, 'comment').errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="invoiceIds">Facture / Avoir (facultatif)</label>
                            <app-invoice-ref-picker
                                inputName="invoiceIds"
                                (setValueToFormControl)="setValueToFormControl($event)"
                                [clearOnSelect]="true"
                                [disabled]="form.disabled"
                                [hidePaidInvoice]="true"
                            ></app-invoice-ref-picker>

                            <div class="suggestion-invoices" *ngIf="suggestionInvoices[i]?.length">
                                <label *ngIf="suggestionInvoices[i].length <= 1"
                                    >Facture suggérée</label
                                >
                                <label *ngIf="suggestionInvoices[i].length > 1"
                                    >Factures suggérées</label
                                >
                                <div class="list-group">
                                    <button
                                        type="button"
                                        class="list-group-item list-group-item-info list-group-item-action"
                                        *ngFor="let suggestionInvoice of suggestionInvoices[i]"
                                        (click)="selectSuggestedInvoice(i, suggestionInvoice)"
                                    >
                                        <span class="icon">+</span>
                                        Facture {{ suggestionInvoice.invoice.ref }} :
                                        {{
                                            formatPrice(
                                                suggestionInvoice.invoice.amountTtcTotal,
                                                suggestionInvoice.invoice.currency
                                            )
                                        }}<br />
                                        Raison(s) :
                                        <ul *ngIf="suggestionInvoice.reasons.length" class="mb-0">
                                            <li
                                                *ngFor="let reason of suggestionInvoice.reasons"
                                                class="reason"
                                                [innerText]="
                                                    getSuggestionInvoiceReasonLabel(reason)
                                                "
                                            ></li>
                                        </ul>
                                    </button>
                                </div>
                            </div>

                            <ul
                                class="list-group"
                                *ngIf="getEncaissementField(i, 'invoiceIds').value.length"
                            >
                                <li
                                    class="list-group-item placeholder-glow d-flex justify-content-between align-items-center gap-2"
                                    *ngFor="
                                        let invoiceId of getEncaissementField(i, 'invoiceIds').value
                                    "
                                >
                                    <div
                                        *ngIf="!invoicesObj[invoiceId]"
                                        class="placeholder w-100"
                                    ></div>
                                    <div *ngIf="invoicesObj[invoiceId]">
                                        <a
                                            [routerLink]="'/admin/invoices/' + invoiceId"
                                            target="_blank"
                                        >
                                            {{ invoicesObj[invoiceId].ref }}
                                            <fa-icon [icon]="faExternalLink"></fa-icon> </a
                                        ><br />
                                        {{
                                            formatPrice(
                                                invoicesObj[invoiceId].amountTtcTotal,
                                                invoicesObj[invoiceId].currency
                                            )
                                        }}
                                    </div>
                                    <button
                                        type="button"
                                        class="btn btn-danger btn-sm"
                                        (click)="removeInvoiceId(i, invoiceId)"
                                    >
                                        <fa-icon [icon]="faTrash"></fa-icon>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>

    <div class="row" *ngIf="displayEncaissementForm">
        <div class="col">
            <button
                type="button"
                [routerLink]="'/admin/encaissements'"
                class="btn btn-secondary btn-block mb-4"
                [disabled]="form.disabled"
                *ngIf="currentEncaissementIndex === 0"
            >
                Annuler
            </button>
            <button
                type="button"
                (click)="goPrev()"
                class="btn btn-secondary btn-block mb-4"
                [disabled]="form.disabled"
                *ngIf="currentEncaissementIndex > 0"
            >
                <fa-icon [icon]="faArrowLeft"></fa-icon> Précédent
            </button>
        </div>
        <div class="col text-end">
            <button
                type="button"
                class="btn btn-primary btn-block mb-4 me-2"
                [disabled]="getEncaissement(currentEncaissementIndex).disabled"
                (click)="goNext()"
            >
                Ignorer
                <fa-icon
                    [icon]="
                        currentEncaissementIndex >= encaissements.length - 1
                            ? faCheck
                            : faArrowRight
                    "
                ></fa-icon>
            </button>
            <button
                type="submit"
                class="btn btn-primary btn-block mb-4"
                [disabled]="
                    getEncaissement(currentEncaissementIndex).disabled ||
                    !getEncaissement(currentEncaissementIndex).valid
                "
            >
                Sauvegarder
                <fa-icon
                    [icon]="
                        currentEncaissementIndex >= encaissements.length - 1
                            ? faCheck
                            : faArrowRight
                    "
                ></fa-icon>
            </button>
        </div>
    </div>
</form>
