import { Injectable } from '@angular/core';

import { RemoteService } from './remote.service';
import moment from 'moment';
import { IEnquiry, getDisplayedEnquiryRefTitle } from '../interfaces/enquiry.interface';
import { EnumEnquiryStatus } from '../enums/enquiry-status.enum';
import { getEnumEnquiryCancelledReasonLabel } from '../enums/enquiry-cancelled-reason.enum';
import { IEnquiryCotation } from '../interfaces/enquiry-cotation.interface';
import { IUser } from '../interfaces/user.interface';
import { IPipedriveUser } from '../interfaces/pipedrive-user.interface';
import { IIPipedrivePerson } from '../interfaces/pipedrive-person.interface';
import { IPipedriveOrganization, IPipedrivePerson } from '../interfaces/pipedrive.interface';
import { SettingService } from './settings/settings.service';
import {
  IPipedriveSettings,
  IPipedriveSettingsByEnquiryType
} from '../interfaces/setting.interface';
import { EnumEnquiryType } from '../enums/enquiry-type.enum';

export interface IPipedriveResult {
  additional_data: any;
  data: any[];
  success: boolean;
}

export interface IPipedrivePersonToAdd {
  name: string;
  org_id?: string;
  email?: { value: string; primary?: true; label?: string }[];
  phone?: { value: string; primary?: true; label?: string }[];
  job_title?: string;
}

export interface IPipedrivePipeline {
  id: number;
  name: string;
  url_title: string;
  order_nr: number;
  active: boolean;
  deal_probability: boolean;
  add_time: string;
  update_time: string;
  selected: boolean;
}

export interface IPipedriveStage {
  active_flag: boolean;
  add_time: string;
  company_id: number;
  deal_probability: number;
  id: number;
  name: string;
  order_nr: number;
  pipeline_deal_probability: boolean;
  pipeline_id: number;
  pipeline_name: string;
  rotten_days: number;
  rotten_flag: boolean;
  update_time: string;
}

@Injectable({
  providedIn: 'root'
})
export class PipedriveService {
  urlBase: string = 'https://api.pipedrive.com/v1/';
  apiToken: string = '1b1d7e2b1c56ac76fcffd9442b7ef84d36093565';

  constructor(private remoteService: RemoteService, private settingService: SettingService) {}

  private getUrl(
    path: string,
    parameters: {
      variableName: string;
      value: string;
    }[] = []
  ): string {
    let url: string = this.urlBase + path;

    url += '?api_token=' + this.apiToken;

    if (parameters.length) {
      for (const parameter of parameters) {
        url += '&' + parameter.variableName + '=' + parameter.value;
      }
    }

    return url;
  }

  async searchOrganization(term: string): Promise<IPipedriveOrganization[]> {
    try {
      const result: any = await this.remoteService.httpGet(
        this.getUrl('organizations/search', [
          {
            variableName: 'term',
            value: term
          },
          {
            variableName: 'start',
            value: '0'
          },
          {
            variableName: 'limit',
            value: '15'
          },
          {
            variableName: 'fields',
            value: 'name'
          }
        ])
      );

      const organizations: IPipedriveOrganization[] = [];

      if (result.data.items) {
        for (const item of result.data.items) {
          organizations.push(item.item as IPipedriveOrganization);
        }
      }

      return organizations;
    } catch (err) {
      Promise.reject(err);
    }
  }

  async searchPersonInOrganization(
    term: string,
    organizationId: string
  ): Promise<IIPipedrivePerson[]> {
    try {
      const result: any = await this.remoteService.httpGet(
        this.getUrl('persons/search', [
          {
            variableName: 'organization_id',
            value: organizationId
          },
          {
            variableName: 'term',
            value: term
          },
          {
            variableName: 'start',
            value: '0'
          },
          {
            variableName: 'limit',
            value: '15'
          }
        ])
      );

      const persons: IIPipedrivePerson[] = [];

      if (result.data.items) {
        for (const item of result.data.items) {
          persons.push(item.item as IIPipedrivePerson);
        }
      }

      return persons;
    } catch (err) {
      Promise.reject(err);
    }
  }

  async getAllPersonsOfOrganizations(organizationId: string): Promise<IPipedrivePerson[]> {
    try {
      const result: any = await this.remoteService.httpGet(
        this.getUrl('organizations/' + organizationId + '/persons', [
          {
            variableName: 'start',
            value: '0'
          }
        ])
      );

      const persons: IPipedrivePerson[] = [];

      if (result.data) {
        for (const person of result.data) {
          persons.push(person as IPipedrivePerson);
        }
      }

      return persons;
    } catch (err) {
      Promise.reject(err);
    }
  }

  async getOrganization(
    organizationId: string,
    forceRefresh: boolean = false
  ): Promise<IPipedriveOrganization> {
    try {
      const pipedriveOrganizationCached: object = await this.remoteService.getDocument(
        'pipedriveOrganizations',
        organizationId
      );

      if (pipedriveOrganizationCached) {
        const pipedriveOrganization: IPipedriveOrganization =
          pipedriveOrganizationCached as IPipedriveOrganization;

        if (
          forceRefresh ||
          pipedriveOrganization.modified < moment().subtract(1, 'week').toDate()
        ) {
          // If cached data older than 1 month, we refresh
          return await this.getPipedriveOrganizationFromId(organizationId);
        } else {
          return pipedriveOrganization;
        }
      }
    } catch (err1) {
      return await this.getPipedriveOrganizationFromId(organizationId);
    }
  }

  private async getPipedriveOrganizationFromId(
    organizationId: string
  ): Promise<IPipedriveOrganization> {
    try {
      const result: any = await this.remoteService.httpGet(
        this.getUrl('organizations/' + organizationId)
      );

      if (result.data) {
        const pipedriveOrganization: IPipedriveOrganization = result.data as IPipedriveOrganization;

        await this.updateOrganizationCache(pipedriveOrganization);

        return pipedriveOrganization;
      } else {
        return null;
      }
    } catch (err2) {
      Promise.reject(err2);
    }
  }

  async updateOrganizationCache(pipedriveOrganization: IPipedriveOrganization): Promise<void> {
    await this.remoteService.setDocumentToCollection(
      'pipedriveOrganizations',
      pipedriveOrganization.id.toString(),
      Object.assign({}, pipedriveOrganization)
    );
  }

  async getPerson(personId: string): Promise<IPipedrivePerson> {
    try {
      const pipedrivePersonCached: object = await this.remoteService.getDocument(
        'pipedrivePersons',
        personId
      );

      if (pipedrivePersonCached) {
        return pipedrivePersonCached as IPipedrivePerson;
      }
    } catch (err1) {
      try {
        const result: any = await this.remoteService.httpGet(this.getUrl('persons/' + personId));

        if (result.data) {
          return result.data as IPipedrivePerson;
        } else {
          return null;
        }
      } catch (err2) {
        Promise.reject(err2);
      }
    }
  }

  async getPersonsFromOrganization(organizationId: string): Promise<IPipedrivePerson[]> {
    try {
      const result: any = await this.remoteService.httpGet(
        this.getUrl('organizations/' + organizationId + '/persons')
      );

      const persons: IPipedrivePerson[] = [];
      if (result.data?.length) {
        for (const item of result.data) {
          persons.push(item as IPipedrivePerson);
        }
      }

      return persons;
    } catch (err2) {
      Promise.reject(err2);
    }
  }

  async addOrganization(data: any): Promise<number> {
    try {
      const result: any = await this.remoteService.httpPost(this.getUrl('organizations'), data);

      if (result.data.id) {
        return result.data.id;
      } else {
        return null;
      }
    } catch (err) {
      Promise.reject(err);
    }
  }

  async addPerson(personToAdd: IPipedrivePersonToAdd): Promise<number> {
    try {
      const result: any = await this.remoteService.httpPost(this.getUrl('persons'), personToAdd);

      if (result.data.id) {
        return result.data.id;
      } else {
        return null;
      }
    } catch (err) {
      Promise.reject(err);
    }
  }

  async updatePerson(id: string, personToAdd: IPipedrivePersonToAdd): Promise<number> {
    try {
      const result: any = await this.remoteService.httpPut(
        this.getUrl('persons/' + id),
        personToAdd
      );

      if (result.data.id) {
        return result.data.id;
      } else {
        return null;
      }
    } catch (err) {
      Promise.reject(err);
    }
  }

  async createDealFromEnquiry(enquiry: IEnquiry): Promise<number> {
    try {
      const pipedriveOrganization: IPipedriveOrganization = await this.getOrganization(
        typeof enquiry.clientId === 'number' ? enquiry.clientId.toString() : enquiry.clientId
      );

      let dealTitle: string = getDisplayedEnquiryRefTitle(enquiry, 'refEnquiry') + ' - ';

      if (pipedriveOrganization) {
        dealTitle += pipedriveOrganization.name;
      } else {
        dealTitle += enquiry.clientTitle;
      }

      const params: object = {
        title: dealTitle,
        person_id: enquiry.contactId,
        org_id: enquiry.clientId,
        '287f0715819d3df9eb78c7f7878a2f90c70c61ce':
          window.location.origin + '/public/enquiry/' + enquiry.id
      };

      if (enquiry.type) {
        const settingByEnquiryType: IPipedriveSettingsByEnquiryType | null =
          await this.getPipedriveSettingsForEnquiryType(enquiry.type);

        if (settingByEnquiryType?.initStageId) {
          params['stage_id'] = settingByEnquiryType.initStageId;
        }
      }

      if (enquiry.processedBy) {
        try {
          const docUser: object = await this.remoteService.getDocument(
            'users',
            enquiry.processedBy
          );

          if (docUser) {
            const user: IUser = docUser as IUser;

            params['user_id'] = user.pipedriveId;
          }
        } catch (err) {
          console.log(err);
        }
      }

      const result: any = await this.remoteService.httpPost(this.getUrl('deals'), params);

      if (result.data.id) {
        return result.data.id;
      } else {
        return null;
      }
    } catch (err) {
      Promise.reject(err);
    }
  }

  async wonDeal(enquiry: IEnquiry, confirmedCotations: IEnquiryCotation[] = []): Promise<void> {
    try {
      if (enquiry.pipedriveDealId && enquiry.status === EnumEnquiryStatus.confirmed) {
        let buyingPrice: number = null;
        let sellingPrice: number = null;

        for (const confirmedCotation of confirmedCotations) {
          buyingPrice += confirmedCotation.priceNetTTC;
          sellingPrice += confirmedCotation.sellingPrice;
        }

        const margin: number =
          buyingPrice !== null && sellingPrice !== null ? sellingPrice - buyingPrice : null;

        const result: any = await this.remoteService.httpPost(
          this.getUrl('deals/' + enquiry.pipedriveDealId + '/products'),
          {
            product_id: 3,
            item_price: sellingPrice,
            quantity: 1
          }
        );

        await this.remoteService.httpPut(this.getUrl('deals/' + enquiry.pipedriveDealId), {
          '3a0676752ed207cbb02944879ebb9ebde2d3fe69': margin,
          status: 'won'
        });

        if (result.data.id) {
          Promise.resolve();
        } else {
          Promise.reject('No result');
          return null;
        }
      }
    } catch (err) {
      Promise.reject(err);
    }
  }

  async lostDeal(enquiry: IEnquiry): Promise<void> {
    try {
      if (enquiry.pipedriveDealId && enquiry.status === EnumEnquiryStatus.cancelled) {
        await this.deleteDealProducts(enquiry.pipedriveDealId);

        const result: any = await this.remoteService.httpPut(
          this.getUrl('deals/' + enquiry.pipedriveDealId),
          {
            '3a0676752ed207cbb02944879ebb9ebde2d3fe69': null,
            status: 'lost',
            lost_reason: getEnumEnquiryCancelledReasonLabel(enquiry.reasonCancelled?.reason)
          }
        );

        if (result.data.id) {
          Promise.resolve();
        } else {
          Promise.reject('No result');
          return null;
        }
      }
    } catch (err) {
      Promise.reject(err);
    }
  }

  async deleteDealProducts(pipedriveDealId: number): Promise<void> {
    const listProductsOfDeal = await this.remoteService.httpGet(
      this.getUrl('deals/' + pipedriveDealId + '/products')
    );

    if (listProductsOfDeal.success && listProductsOfDeal.data) {
      for (const item of listProductsOfDeal.data) {
        await this.remoteService.httpDelete(
          this.getUrl('deals/' + pipedriveDealId + '/products/' + item.id)
        );
      }
    }
  }

  async openDeal(enquiry: IEnquiry): Promise<void> {
    try {
      if (enquiry.pipedriveDealId && enquiry.status === EnumEnquiryStatus.draft) {
        await this.deleteDealProducts(enquiry.pipedriveDealId);

        const result: any = await this.remoteService.httpPut(
          this.getUrl('deals/' + enquiry.pipedriveDealId),
          {
            '3a0676752ed207cbb02944879ebb9ebde2d3fe69': null,
            status: 'open'
          }
        );

        if (result.data.id) {
          Promise.resolve();
        } else {
          Promise.reject('No result');
          return null;
        }
      }
    } catch (err) {
      Promise.reject(err);
    }
  }

  async getUser(pipedriveUserId: number): Promise<IPipedriveUser | null> {
    try {
      const result: any = await this.remoteService.httpGet(this.getUrl('users/' + pipedriveUserId));

      if (result.data.id) {
        return this.setDateFieldToCorrectType(result.data) as IPipedriveUser;
      } else {
        Promise.reject('No result');
        return null;
      }
    } catch (err) {
      Promise.reject(err);
    }
  }

  async getAllUsers(): Promise<IPipedriveUser[]> {
    try {
      const result: any = await this.remoteService.httpGet(this.getUrl('users'));

      if (result.data) {
        for (let item of result.data) {
          item = this.setDateFieldToCorrectType(item);
        }

        return result.data as IPipedriveUser[];
      } else {
        Promise.reject('No result');
        return [];
      }
    } catch (err) {
      Promise.reject(err);
    }
  }

  private setDateFieldToCorrectType(item: any): IPipedriveUser {
    for (const field of ['created', 'modified', 'last_login']) {
      item[field] = new Date(item[field]);
    }

    return item as IPipedriveUser;
  }

  async getAllPipelines(): Promise<IPipedrivePipeline[]> {
    try {
      const result: IPipedriveResult = await this.remoteService.httpGet(this.getUrl('pipelines'));

      if (result.success) {
        return result.data as IPipedrivePipeline[];
      }

      return [];
    } catch (err) {
      console.log(
        '🚀 ~ file: pipedrive.service.ts:538 ~ PipedriveService ~ getAllPipelines ~ err:',
        err
      );
    }
  }

  async getPipelineStages(pipelineId: number): Promise<IPipedriveStage[]> {
    try {
      const result: IPipedriveResult = await this.remoteService.httpGet(
        this.getUrl('stages', [
          {
            variableName: 'pipeline_id',
            value: pipelineId.toString()
          }
        ])
      );

      if (result.success) {
        return result.data as IPipedriveStage[];
      }

      return [];
    } catch (err) {
      console.log(
        '🚀 ~ file: pipedrive.service.ts:538 ~ PipedriveService ~ getAllPipelines ~ err:',
        err
      );
    }
  }

  getPipedriveSettings(): Promise<IPipedriveSettings | null> {
    return new Promise((resolve, reject) => {
      const sub = this.settingService
        .getPipedriveSettings()
        .subscribe(async (pipedriveSettings: IPipedriveSettings | null) => {
          sub.unsubscribe();

          resolve(pipedriveSettings);
        });
    });
  }

  async getPipedriveSettingsForEnquiryType(
    enquiryType: EnumEnquiryType
  ): Promise<IPipedriveSettingsByEnquiryType | null> {
    const pipedriveSettings: IPipedriveSettings | null = await this.getPipedriveSettings();

    if (pipedriveSettings) {
      for (const settingByEnquiryType of pipedriveSettings.settingsByEnquiryType) {
        if (
          enquiryType === settingByEnquiryType.enquiryType &&
          settingByEnquiryType.quotationSentStageId
        ) {
          return settingByEnquiryType;
          break;
        }
      }
    }

    return null;
  }

  quotationSentToClient(enquiry: IEnquiry): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if (enquiry.type && enquiry.pipedriveDealId) {
        const settingByEnquiryType: IPipedriveSettingsByEnquiryType | null =
          await this.getPipedriveSettingsForEnquiryType(enquiry.type);

        if (settingByEnquiryType?.quotationSentStageId) {
          await this.remoteService.httpPatch(this.getUrl('deals/' + enquiry.pipedriveDealId), {
            stage_id: settingByEnquiryType.quotationSentStageId
          });
        }

        resolve();
      } else {
        resolve();
      }
    });
  }
}
