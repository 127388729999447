<ul>
    <li
        *ngFor="let menuLink of menuLinks; let i = index"
        [class.opened]="i === currentlyOpenedLinkIndex"
        [class.active]="isLinkActive(menuLink)"
    >
        <a
            [routerLink]="menuLink.href"
            *ngIf="menuLink.href"
            class="menu-link"
            data-bs-dismiss="offcanvas"
        >
            <div class="btn-container">
                <span class="bullet bullet-dot" *ngIf="!menuLink.iconLeft"></span>
                <fa-icon
                    [icon]="menuLink.iconLeft"
                    *ngIf="menuLink.iconLeft"
                    class="icon-start"
                ></fa-icon>
                <span class="menu-link-text">{{ menuLink.text | translate }}</span>
                <fa-icon
                    [icon]="menuLink.iconRight"
                    *ngIf="menuLink.iconRight && !menuLink.children?.length"
                    class="icon-end"
                ></fa-icon>
            </div>

            <fa-icon
                [icon]="faChevronRight"
                class="icon-end"
                *ngIf="menuLink.children?.length"
            ></fa-icon>
        </a>
        <button type="button" (click)="openMenuLink(i)" *ngIf="!menuLink.href" class="menu-link">
            <div class="btn-container">
                <span class="bullet bullet-dot" *ngIf="!menuLink.iconLeft"></span>
                <fa-icon
                    [icon]="menuLink.iconLeft"
                    *ngIf="menuLink.iconLeft"
                    class="icon-start"
                ></fa-icon>
                <span class="menu-link-text">{{ menuLink.text | translate }}</span>
                <fa-icon
                    [icon]="menuLink.iconRight"
                    *ngIf="menuLink.iconRight && !menuLink.children?.length"
                    class="icon-end"
                ></fa-icon>
            </div>

            <fa-icon
                [icon]="faChevronRight"
                class="icon-end"
                *ngIf="menuLink.children?.length"
            ></fa-icon>
        </button>

        <div
            class="collapse"
            [class.show]="i === currentlyOpenedLinkIndex"
            *ngIf="menuLink.children?.length"
        >
            <div class="menu-sublinks">
                <app-sidebar-menu [menuLinks]="menuLink.children || []"></app-sidebar-menu>
            </div>
        </div>
    </li>
</ul>
