export enum EnumGroupAcl {
  enquiries = 'enquiries',
  users = 'users',
  aircraftModels = 'aircraft-models',
  simulateurAircraftModels = 'simulateur-aircraft-models',
  userGroups = 'user-groups',
  emailTemplates = 'email-templates',
  airports = 'airports',
  lieuxDits = 'lieux-dits',
  airlines = 'airlines',
  aircrafts = 'aircrafts',
  quotations = 'quotations',
  invoices = 'invoices',
  reports = 'reports',
  enquiryPaymentsSummary = 'enquiry-payments-summary',
  clients = 'clients',
  encaissementTypes = 'encaissement-types',
  encaissements = 'encaissements',
  bankAccounts = 'bank-accounts',
  crawlerUrls = 'crawler-urls',
  flightBriefs = 'flight-briefs',
  flightBriefCaterings = 'flight-brief-caterings',
  backups = 'backups',
  countries = 'countries',
  employeeBonusRules = 'employeeBonusRules',
  exportRequests = 'exportRequests',
  flightsCalendar = 'flightsCalendar',
  flightChecklistTemplates = 'flightChecklistTemplates',
  flightChecklists = 'flightChecklists',
  fbos = 'fbos',
  handlings = 'handlings',
  crews = 'crews',
  airlineSupervisors = 'airlineSupervisors',
  settings = 'settings'
}

export const getGroupAclTitle = (groupAcl: EnumGroupAcl): string => {
  switch (groupAcl) {
    default:
      return groupAcl;
    case EnumGroupAcl.enquiries:
      return 'Demandes';
    case EnumGroupAcl.users:
      return 'Utilisateurs';
    case EnumGroupAcl.aircraftModels:
      return "Modèles d'avions";
    case EnumGroupAcl.simulateurAircraftModels:
      return "Modèles d'avions du simulateur de prix";
    case EnumGroupAcl.userGroups:
      return "Groupes d'utilisateurs";
    case EnumGroupAcl.emailTemplates:
      return "Templates d'email";
    case EnumGroupAcl.airports:
      return 'Aéroports';
    case EnumGroupAcl.lieuxDits:
      return 'Lieux-dits';
    case EnumGroupAcl.airlines:
      return 'Compagnies aériennes';
    case EnumGroupAcl.aircrafts:
      return 'Appareils aériens';
    case EnumGroupAcl.quotations:
      return 'Devis';
    case EnumGroupAcl.invoices:
      return 'Factures';
    case EnumGroupAcl.reports:
      return 'Rapports';
    case EnumGroupAcl.enquiryPaymentsSummary:
      return 'Paiements des dossiers';
    case EnumGroupAcl.clients:
      return 'Clients';
    case EnumGroupAcl.encaissementTypes:
      return "Types d'encaissements";
    case EnumGroupAcl.encaissements:
      return 'Encaissements';
    case EnumGroupAcl.bankAccounts:
      return 'Comptes bancaires';
    case EnumGroupAcl.crawlerUrls:
      return 'Scripts extracteur de données externes';
    case EnumGroupAcl.flightBriefs:
      return 'Flight briefs';
    case EnumGroupAcl.flightBriefCaterings:
      return 'Caterings des flight briefs';
    case EnumGroupAcl.backups:
      return 'Sauvegardes';
    case EnumGroupAcl.countries:
      return 'Pays';
    case EnumGroupAcl.employeeBonusRules:
      return 'Règles des bonus des commerciaux';
    case EnumGroupAcl.exportRequests:
      return 'Exports des factures';
    case EnumGroupAcl.flightsCalendar:
      return 'Calendrier des vols';
    case EnumGroupAcl.flightChecklistTemplates:
      return 'Modèle de checklist des vols';
    case EnumGroupAcl.flightChecklists:
      return 'Checklist des vols';
    case EnumGroupAcl.fbos:
      return 'FBOs';
    case EnumGroupAcl.handlings:
      return 'Handlings';
    case EnumGroupAcl.crews:
      return 'Équipage';
    case EnumGroupAcl.airlineSupervisors:
      return 'Superviseurs de compagnies aeriennes';
    case EnumGroupAcl.settings:
      return 'Configuration Simplyfly';
  }
};

export enum EnumAcl {
  enquiriesList = 'enquiries.list',
  enquiriesAdd = 'enquiries.add',
  enquiriesEdit = 'enquiries.edit',
  enquiriesEditForConfirmedEnquiry = 'enquiries.edit-for-confirmed-enquiry',
  enquiriesSelectAirlines = 'enquiries.select-airlines',
  enquirieSendMessageToAirlines = 'enquiries.send-message-to-airlines',
  enquiriesRelanceAirline = 'enquiries.relance-airline',
  enquiriesCancelledEmailToAirline = 'enquiries.cancelled-email-to-airline',
  enquiriesSwitchStatus = 'enquiries.switch-status',
  enquiriesSwitchStatusFromConfirmed = 'enquiries.switch-status-from-confirmed',
  enquiriesAddEditCotation = 'enquiries.add-edit-cotation',
  enquiriesAddEditCotationForConfirmedEnquiry = 'enquiries.add-edit-cotation-for-confirmed-enquiry',
  enquiriesSwitchCotationStatus = 'enquiries.switch-cotation-status',
  enquiriesViewAttachedDocumentsToCotation = 'enquiries.viewAttachedDocumentsToCotation',
  enquiriesEditAttachedDocumentsToCotation = 'enquiries.editAttachedDocumentsToCotation',
  enquiriesDelete = 'enquiries.delete',
  enquiriesDashboardList = 'enquiries.dashboard-list',
  enquiriesFilterByDepartment = 'enquiries.filterByDepartment',
  enquiriesSetCommission = 'enquiries.setCommission',
  enquiriesSetIsCompleted = 'enquiries.setIsCompleted',

  usersList = 'users.list',
  usersAdd = 'users.add',
  usersEdit = 'users.edit',
  usersDisable = 'users.disable',
  usersSendEmailResetPassword = 'users.send-email-reset-password',
  usersStatistics = 'users.statistics',
  usersStatisticsExport = 'users.statistics-export',
  usersUserOwnStatistics = 'users.user-own-statistics',
  usersUserGoals = 'users.user-goals',
  usersUserEnquiries = 'users.user-enquiries',
  usersDelete = 'users.delete',

  aircraftModelsList = 'aircraft-models.list',
  aircraftModelsAdd = 'aircraft-models.add',
  aircraftModelsEdit = 'aircraft-models.edit',
  aircraftModelsDelete = 'aircraft-models.delete',
  aircraftModelsStatistics = 'aircraft-models.statistics',

  simulateurAircraftModelsList = 'simulateur-aircraft-models.list',
  simulateurAircraftModelsAdd = 'simulateur-aircraft-models.add',
  simulateurAircraftModelsEdit = 'simulateur-aircraft-models.edit',
  simulateurAircraftModelsDelete = 'simulateur-aircraft-models.delete',

  userGroupsList = 'user-groups.list',
  userGroupsAdd = 'user-groups.add',
  userGroupsEdit = 'user-groups.edit',
  userGroupsDelete = 'user-groups.delete',

  emailTemplatesList = 'email-templates.list',
  emailTemplatesAdd = 'email-templates.add',
  emailTemplatesEdit = 'email-templates.edit',
  emailTemplatesDelete = 'email-templates.delete',

  airportsList = 'airports.list',
  airportsAdd = 'airports.add',
  airportsEdit = 'airports.edit',
  airportsDelete = 'airports.delete',

  lieuxDitsList = 'lieux-dits.list',
  lieuxDitsAdd = 'lieux-dits.add',
  lieuxDitsEdit = 'lieux-dits.edit',
  lieuxDitsDelete = 'lieux-dits.delete',

  airlinesList = 'airlines.list',
  airlinesListFilters = 'airlines.listFilters',
  airlinesSearch = 'airlines.search',
  airlinesAdd = 'airlines.add',
  airlinesEdit = 'airlines.edit',
  airlinesDelete = 'airlines.delete',
  airlinesRefreshFleet = 'airlines.refreshFleet',

  aircraftsList = 'aircrafts.list',
  aircraftsAdd = 'aircrafts.add',
  aircraftsEdit = 'aircrafts.edit',
  aircraftsDelete = 'aircrafts.delete',
  aircraftsNoCapacity = 'aircrafts.noCapacity',
  aircraftsImport = 'aircrafts.import',

  quotationsList = 'quotations.list',
  quotationsSwitchStatus = 'quotations.switch-status',
  quotationsAdd = 'quotations.add',
  quotationsEdit = 'quotations.edit',
  quotationsDelete = 'quotations.delete',

  invoicesList = 'invoices.list',
  invoicesEditProforma = 'invoices.edit-proforma',
  invoicesEditProformaCreditNote = 'invoices.edit-proforma-credit-note',
  invoicesEditDefinitive = 'invoices.edit-definitive',
  invoicesEditCreditNote = 'invoices.edit-credit-note',
  invoicesEditCreditNoteProforma = 'invoices.edit-credit-note-proforma',
  invoicesEditPurchase = 'invoices.edit-purchase',
  invoicesAddProforma = 'invoices.add-proforma',
  invoicesAddCreditNoteProforma = 'invoices.add-credit-note-proforma',
  invoicesAddCreditNoteFinal = 'invoices.add-credit-note-final',
  invoicesAddFinalInvoice = 'invoices.add-final-invoice',
  invoicesRequestFinalInvoice = 'invoices.request-final-invoice',
  invoicesRequestFinalCreditNoteInvoice = 'invoices.request-final-credit-note-invoice',
  invoicesAddPurchaseInvoice = 'invoices.add-purchase-invoice',
  invoicesAddPurchaseCreditNote = 'invoices.add-purchase-credit-note',
  invoicesEditPurchaseCreditNote = 'invoices.edit-purchase-credit-note',
  invoicesSwitchStatus = 'invoices.switch-status',
  invoicesDelete = 'invoices.delete',
  invoicesDashboardList = 'invoices.dashboard-list',
  invoicesSendInvoiceToClients = 'invoices.send-invoice-to-clients',
  invoicesImport = 'invoices.import',

  reportsDisplay = 'reports.display',
  reportsSwitchYears = 'reports.switch-years',
  reportsDisplayByMonth = 'reports.display-by-month',
  reportsDisplayByTrimester = 'reports.display-by-trimester',
  reportsDisplayByYear = 'reports.display-by-year',
  reportsGoal = 'reports.goal',

  enquiryPaymentsSummaryDisplay = 'enquiry-payments-summary.display',
  enquiryPaymentsSummaryOwnedEnquiries = 'enquiry-payments-summary.display-own-enquiries',
  enquiryPaymentsSummaryExport = 'enquiry-payments-summary.export',
  enquiryPaymentsSummaryImport = 'enquiry-payments-summary.import',
  enquiryPaymentsSummaryFinancialReports = 'enquiry-payments-summary.display-financial-reports',
  enquiryPaymentsSummarySetComments = 'enquiry-payments-summary.set-comments',
  enquiryPaymentsSummaryAddTemporaryRows = 'enquiry-payments-summary.add-temporary-rows',

  clientsList = 'clients.list',
  clientsAdd = 'clients.add',
  clientsEdit = 'clients.edit',
  clientsStatistics = 'clients.statistics',
  clientsDelete = 'clients.delete',

  encaissementTypesList = 'encaissement-types.list',
  encaissementTypesAdd = 'encaissement-types.add',
  encaissementTypesEdit = 'encaissement-types.edit',
  encaissementTypesDelete = 'encaissement-types.delete',

  encaissementsList = 'encaissements.list',
  encaissementsAdd = 'encaissements.add',
  encaissementsEdit = 'encaissements.edit',
  encaissementsDelete = 'encaissements.delete',
  encaissementsImport = 'encaissements.import',

  bankAccountsList = 'bank-accounts.list',
  bankAccountsAdd = 'bank-accounts.add',
  bankAccountsEdit = 'bank-accounts.edit',
  bankAccountsDelete = 'bank-accounts.delete',

  crawlerUrlsList = 'crawler-urls.list',
  crawlerUrlsAdd = 'crawler-urls.add',
  crawlerUrlsDelete = 'crawler-urls.delete',
  crawlerUrlsAlert = 'crawler-urls.alert',

  flightBriefsList = 'flight-briefs.list',
  flightBriefsAdd = 'flight-briefs.add',
  flightBriefsEdit = 'flight-briefs.edit',
  flightBriefsDelete = 'flight-briefs.delete',
  flightBriefsMarkedAsSent = 'flight-briefs.markedAsSent',
  flightBriefsGenerateFromQuotation = 'flight-briefs.generateFromQuotation',

  flightBriefCateringsList = 'flight-brief-caterings.list',
  flightBriefCateringsAdd = 'flight-brief-caterings.add',
  flightBriefCateringsEdit = 'flight-brief-caterings.edit',
  flightBriefCateringsDelete = 'flight-brief-caterings.delete',

  backupsList = 'backups.list',
  backupsRestore = 'backups.restore',

  countriesList = 'countries.list',
  countriesAdd = 'countries.add',
  countriesEdit = 'countries.edit',
  countriesDelete = 'countries.delete',

  employeeBonusRulesList = 'employeeBonusRules.list',
  employeeBonusRulesAdd = 'employeeBonusRules.add',
  employeeBonusRulesEdit = 'employeeBonusRules.edit',
  employeeBonusRulesDelete = 'employeeBonusRules.delete',

  exportRequestsList = 'exportRequests.list',
  exportRequestsAdd = 'exportRequests.add',
  exportRequestsEdit = 'exportRequests.edit',
  exportRequestsDelete = 'exportRequests.delete',

  flightsCalendarDashboard = 'flightsCalendar.dashboard',

  flightChecklistTemplatesList = 'flightChecklistTemplates.list',
  flightChecklistTemplatesAdd = 'flightChecklistTemplates.add',
  flightChecklistTemplatesEdit = 'flightChecklistTemplates.edit',
  flightChecklistTemplatesDelete = 'flightChecklistTemplates.delete',

  flightChecklistsView = 'flightChecklists.view',
  flightChecklistsEdit = 'flightChecklists.edit',

  fbosList = 'fbos.list',
  fbosAdd = 'fbos.add',
  fbosEdit = 'fbos.edit',
  fbosDelete = 'fbos.delete',

  handlingsList = 'handlings.list',
  handlingsAdd = 'handlings.add',
  handlingsEdit = 'handlings.edit',
  handlingsDelete = 'handlings.delete',

  crewsList = 'crews.list',
  crewsAdd = 'crews.add',
  crewsEdit = 'crews.edit',
  crewsDelete = 'crews.delete',

  airlineSupervisorsList = 'airlineSupervisors.list',
  airlineSupervisorsAdd = 'airlineSupervisors.add',
  airlineSupervisorsEdit = 'airlineSupervisors.edit',
  airlineSupervisorsDelete = 'airlineSupervisors.delete',

  settingsPipedrive = 'settings.pipedrive'
}

export const getAclTitle = (acl: EnumAcl): string => {
  switch (acl) {
    default:
      return acl;
    case EnumAcl.enquiriesList:
      return 'Affichage de la liste';
    case EnumAcl.enquiriesAdd:
      return 'Ajout';
    case EnumAcl.enquiriesEdit:
      return "Édition d'une demande non-confirmée";
    case EnumAcl.enquiriesEditForConfirmedEnquiry:
      return "Édition d'une demande confirmée";
    case EnumAcl.enquiriesSelectAirlines:
      return 'Sélection des compagnies aériennes';
    case EnumAcl.enquirieSendMessageToAirlines:
      return 'Envoi des messages aux compagnies aériennes';
    case EnumAcl.enquiriesCancelledEmailToAirline:
      return "Envoi d'un email d'annulation aux compagnies aériennes";
    case EnumAcl.enquiriesRelanceAirline:
      return 'Relancez les compagnies aériennes';
    case EnumAcl.enquiriesSwitchStatus:
      return "Modification du status d'une demande";
    case EnumAcl.enquiriesSwitchStatusFromConfirmed:
      return "Changer le status d'une demande confirmée";
    case EnumAcl.enquiriesAddEditCotation:
      return "Ajout/édition des cotations d'une demande non-confirmée";
    case EnumAcl.enquiriesAddEditCotationForConfirmedEnquiry:
      return "Ajout/édition des cotations d'une demande confirmée";
    case EnumAcl.enquiriesViewAttachedDocumentsToCotation:
      return 'Voir les documents attachés à une cotation';
    case EnumAcl.enquiriesEditAttachedDocumentsToCotation:
      return "Ajout/édition d'un document attaché à une cotation";
    case EnumAcl.enquiriesSwitchCotationStatus:
      return 'Changement de status des cotations';
    case EnumAcl.enquiriesDelete:
      return 'Suppression';
    case EnumAcl.enquiriesDashboardList:
      return 'Affichage des demandes sur le tableau de bord';
    case EnumAcl.enquiriesFilterByDepartment:
      return 'Filtrage des demandes par département';
    case EnumAcl.enquiriesSetCommission:
      return "Assignation d'une commission sur un dossier";
    case EnumAcl.enquiriesSetIsCompleted:
      return 'Marquer un dossier comme complêté ou non';

    case EnumAcl.usersList:
      return 'Affichage de la liste';
    case EnumAcl.usersAdd:
      return 'Ajout';
    case EnumAcl.usersEdit:
      return 'Édition';
    case EnumAcl.usersDisable:
      return 'Désactivation/activation des comptes';
    case EnumAcl.usersSendEmailResetPassword:
      return "Envoie d'email de réinitialisation de mot de passe";
    case EnumAcl.usersStatistics:
      return 'Statistiques';
    case EnumAcl.usersStatisticsExport:
      return 'Export statistiques';
    case EnumAcl.usersUserOwnStatistics:
      return 'Affichage de ses propres statistiques';
    case EnumAcl.usersUserGoals:
      return "Édition des objectifs d'un utilisateur";
    case EnumAcl.usersUserEnquiries:
      return "Affichage des demandes d'un utilisateur";
    case EnumAcl.usersDelete:
      return 'Suppression';

    case EnumAcl.aircraftModelsList:
      return 'Affichage de la liste';
    case EnumAcl.aircraftModelsAdd:
      return 'Ajout';
    case EnumAcl.aircraftModelsEdit:
      return 'Édition';
    case EnumAcl.aircraftModelsDelete:
      return 'Suppression';
    case EnumAcl.aircraftModelsStatistics:
      return 'Statistiques';

    case EnumAcl.simulateurAircraftModelsList:
      return 'Affichage de la liste';
    case EnumAcl.simulateurAircraftModelsAdd:
      return 'Ajout';
    case EnumAcl.simulateurAircraftModelsEdit:
      return 'Édition';
    case EnumAcl.simulateurAircraftModelsDelete:
      return 'Suppression';

    case EnumAcl.userGroupsList:
      return 'Affichage de la liste';
    case EnumAcl.userGroupsAdd:
      return 'Ajout';
    case EnumAcl.userGroupsEdit:
      return 'Édition';
    case EnumAcl.userGroupsDelete:
      return 'Suppression';

    case EnumAcl.emailTemplatesList:
      return 'Affichage de la liste';
    case EnumAcl.emailTemplatesAdd:
      return 'Ajout';
    case EnumAcl.emailTemplatesEdit:
      return 'Édition';
    case EnumAcl.emailTemplatesDelete:
      return 'Suppression';

    case EnumAcl.airportsList:
      return 'Affichage de la liste';
    case EnumAcl.airportsAdd:
      return 'Ajout';
    case EnumAcl.airportsEdit:
      return 'Édition';
    case EnumAcl.airportsDelete:
      return 'Suppression';

    case EnumAcl.lieuxDitsList:
      return 'Affichage de la liste';
    case EnumAcl.lieuxDitsAdd:
      return 'Ajout';
    case EnumAcl.lieuxDitsEdit:
      return 'Édition';
    case EnumAcl.lieuxDitsDelete:
      return 'Suppression';

    case EnumAcl.airlinesList:
      return 'Affichage de la liste';
    case EnumAcl.airlinesListFilters:
      return 'Filtre de la liste';
    case EnumAcl.airlinesSearch:
      return 'Recherche';
    case EnumAcl.airlinesAdd:
      return 'Ajout';
    case EnumAcl.airlinesEdit:
      return 'Édition';
    case EnumAcl.airlinesDelete:
      return 'Suppression';
    case EnumAcl.airlinesRefreshFleet:
      return 'Rafaîchissement de la flotte';

    case EnumAcl.aircraftsList:
      return 'Affichage de la liste';
    case EnumAcl.aircraftsAdd:
      return 'Ajout';
    case EnumAcl.aircraftsEdit:
      return 'Édition';
    case EnumAcl.aircraftsDelete:
      return 'Suppression';
    case EnumAcl.aircraftsNoCapacity:
      return 'Liste sans capacité';
    case EnumAcl.aircraftsImport:
      return 'Import';

    case EnumAcl.quotationsList:
      return 'Affichage de la liste';
    case EnumAcl.quotationsAdd:
      return 'Ajout';
    case EnumAcl.quotationsSwitchStatus:
      return "Modification du status d'un devis";
    case EnumAcl.quotationsEdit:
      return 'Édition';
    case EnumAcl.quotationsDelete:
      return 'Suppression';

    case EnumAcl.invoicesList:
      return 'Affichage de la liste';
    case EnumAcl.invoicesEditProforma:
      return "Édition d'un proforma";
    case EnumAcl.invoicesEditProformaCreditNote:
      return "Édition d'un proforma avoir";
    case EnumAcl.invoicesEditDefinitive:
      return "Édition d'une facture définitive";
    case EnumAcl.invoicesEditCreditNote:
      return "Édition d'un avoir";
    case EnumAcl.invoicesEditCreditNoteProforma:
      return "Édition d'un proforma d'avoir";
    case EnumAcl.invoicesEditPurchase:
      return "Édition d'une facture d'achat";
    case EnumAcl.invoicesAddProforma:
      return "Ajout d'une proforma";
    case EnumAcl.invoicesAddCreditNoteProforma:
      return "Création d'un avoir proforma";
    case EnumAcl.invoicesAddCreditNoteFinal:
      return "Création d'un avoir";
    case EnumAcl.invoicesAddFinalInvoice:
      return "Création d'une facture définitif";
    case EnumAcl.invoicesRequestFinalInvoice:
      return "Création d'une facture définitive";
    case EnumAcl.invoicesRequestFinalCreditNoteInvoice:
      return "Faire une demande d'avoir définitif";
    case EnumAcl.invoicesAddPurchaseInvoice:
      return "Création d'une facture d'achat";
    case EnumAcl.invoicesAddPurchaseCreditNote:
      return "Création d'un avoir reçu d'un fournisseur/prestataire";
    case EnumAcl.invoicesEditPurchaseCreditNote:
      return "Édition d'un avoir reçu d'un fournisseur/prestataire";
    case EnumAcl.invoicesSwitchStatus:
      return "Modification du status d'une facture";
    case EnumAcl.invoicesDelete:
      return 'Suppression';
    case EnumAcl.invoicesDashboardList:
      return 'Affichage des factures à traiter sur le tableau de bord';
    case EnumAcl.invoicesSendInvoiceToClients:
      return 'Peut envoyer une facture au client';
    case EnumAcl.invoicesImport:
      return 'Importation des factures';

    case EnumAcl.reportsDisplay:
      return 'Affichage';
    case EnumAcl.reportsSwitchYears:
      return 'Affichage des années précédentes';
    case EnumAcl.reportsDisplayByMonth:
      return 'Affichage par mois';
    case EnumAcl.reportsDisplayByTrimester:
      return 'Affichage par trimestre';
    case EnumAcl.reportsDisplayByYear:
      return 'Affichage par année';
    case EnumAcl.reportsGoal:
      return 'Modification des objectifs';

    case EnumAcl.enquiryPaymentsSummaryDisplay:
      return 'Affichage de tous les dossiers';
    case EnumAcl.enquiryPaymentsSummaryOwnedEnquiries:
      return 'Affichage de ses dossiers uniquement';
    case EnumAcl.enquiryPaymentsSummaryExport:
      return 'Export du paiement des dossiers';
    case EnumAcl.enquiryPaymentsSummaryImport:
      return 'Import du paiement des dossiers';
    case EnumAcl.enquiryPaymentsSummaryFinancialReports:
      return 'Affichage du rapport financier';
    case EnumAcl.enquiryPaymentsSummarySetComments:
      return 'Mettre des commentaires sur une ligne de paiement des dossiers';
    case EnumAcl.enquiryPaymentsSummaryAddTemporaryRows:
      return 'Ajouter des lignes temporaires une ligne de paiement des dossiers';

    case EnumAcl.clientsList:
      return 'Affichage de la liste';
    case EnumAcl.clientsAdd:
      return 'Ajout';
    case EnumAcl.clientsEdit:
      return 'Edition';
    case EnumAcl.clientsStatistics:
      return 'Statistiques';
    case EnumAcl.clientsDelete:
      return 'Suppression';

    case EnumAcl.encaissementTypesList:
      return 'Affichage de la liste';
    case EnumAcl.encaissementTypesAdd:
      return 'Ajout';
    case EnumAcl.encaissementTypesEdit:
      return 'Edition';
    case EnumAcl.encaissementTypesDelete:
      return 'Suppression';

    case EnumAcl.encaissementsList:
      return 'Affichage de la liste';
    case EnumAcl.encaissementsAdd:
      return 'Ajout';
    case EnumAcl.encaissementsEdit:
      return 'Édition';
    case EnumAcl.encaissementsDelete:
      return 'Suppression';
    case EnumAcl.encaissementsImport:
      return 'Import';

    case EnumAcl.bankAccountsList:
      return 'Affichage de la liste';
    case EnumAcl.bankAccountsAdd:
      return 'Ajout';
    case EnumAcl.bankAccountsEdit:
      return 'Édition';
    case EnumAcl.bankAccountsDelete:
      return 'Suppression';

    case EnumAcl.crawlerUrlsList:
      return 'Affichage de la liste';
    case EnumAcl.crawlerUrlsAdd:
      return 'Ajout';
    case EnumAcl.crawlerUrlsDelete:
      return 'Suppression';
    case EnumAcl.crawlerUrlsAlert:
      return "Message d'alerte sur l'accueil si problème rencontré";

    case EnumAcl.flightBriefsList:
      return 'Affichage de la liste';
    case EnumAcl.flightBriefsAdd:
      return 'Ajout';
    case EnumAcl.flightBriefsEdit:
      return 'Édition';
    case EnumAcl.flightBriefsDelete:
      return 'Suppression';
    case EnumAcl.flightBriefsMarkedAsSent:
      return 'Marqué comme envoyé';
    case EnumAcl.flightBriefsGenerateFromQuotation:
      return "Généré un flight brief à partir d'un devis";

    case EnumAcl.flightBriefCateringsList:
      return 'Affichage de la liste';
    case EnumAcl.flightBriefCateringsAdd:
      return 'Ajout';
    case EnumAcl.flightBriefCateringsEdit:
      return 'Édition';
    case EnumAcl.flightBriefCateringsDelete:
      return 'Suppression';

    case EnumAcl.backupsList:
      return 'Liste';
    case EnumAcl.backupsRestore:
      return 'Restauration de la sauvegarde';

    case EnumAcl.countriesList:
      return 'Affichage de la liste';
    case EnumAcl.countriesAdd:
      return 'Ajout';
    case EnumAcl.countriesEdit:
      return 'Édition';
    case EnumAcl.countriesDelete:
      return 'Suppression';

    case EnumAcl.employeeBonusRulesList:
      return 'Affichage de la liste';
    case EnumAcl.employeeBonusRulesAdd:
      return 'Ajout';
    case EnumAcl.employeeBonusRulesEdit:
      return 'Édition';
    case EnumAcl.employeeBonusRulesDelete:
      return 'Suppression';

    case EnumAcl.exportRequestsList:
      return 'Affichage de la liste';
    case EnumAcl.exportRequestsAdd:
      return 'Ajout';
    case EnumAcl.exportRequestsEdit:
      return 'Édition';
    case EnumAcl.exportRequestsDelete:
      return 'Suppression';

    case EnumAcl.flightsCalendarDashboard:
      return 'Affichage du calendrier sur le tableau de bord';

    case EnumAcl.flightChecklistTemplatesList:
      return 'Affichage de la liste';
    case EnumAcl.flightChecklistTemplatesAdd:
      return 'Ajout';
    case EnumAcl.flightChecklistTemplatesEdit:
      return 'Édition';
    case EnumAcl.flightChecklistTemplatesDelete:
      return 'Suppression';

    case EnumAcl.flightChecklistsView:
      return "Peut voir la checklist d'un vol";
    case EnumAcl.flightChecklistsEdit:
      return "Peut modifier la checklist d'un vol";

    case EnumAcl.fbosList:
      return 'Affichage de la liste';
    case EnumAcl.fbosAdd:
      return 'Ajout';
    case EnumAcl.fbosEdit:
      return 'Édition';
    case EnumAcl.fbosDelete:
      return 'Suppression';

    case EnumAcl.handlingsList:
      return 'Affichage de la liste';
    case EnumAcl.handlingsAdd:
      return 'Ajout';
    case EnumAcl.handlingsEdit:
      return 'Édition';
    case EnumAcl.handlingsDelete:
      return 'Suppression';

    case EnumAcl.crewsList:
      return 'Affichage de la liste';
    case EnumAcl.crewsAdd:
      return 'Ajout';
    case EnumAcl.crewsEdit:
      return 'Édition';
    case EnumAcl.crewsDelete:
      return 'Suppression';

    case EnumAcl.airlineSupervisorsList:
      return 'Affichage de la liste';
    case EnumAcl.airlineSupervisorsAdd:
      return 'Ajout';
    case EnumAcl.airlineSupervisorsEdit:
      return 'Édition';
    case EnumAcl.airlineSupervisorsDelete:
      return 'Suppression';

    case EnumAcl.settingsPipedrive:
      return 'Pipedrive';
  }
};

export const getGroupFromAcl = (acl: EnumAcl): EnumGroupAcl => {
  const splittedAcl: string[] = acl.split('.');

  return splittedAcl[0] as EnumGroupAcl;
};

export const getChildrenAclForGroup = (groupAcl: EnumGroupAcl): EnumAcl[] => {
  const list: EnumAcl[] = [];

  for (const acl of Object.values(EnumAcl)) {
    const group: EnumGroupAcl = getGroupFromAcl(acl);

    if (group === groupAcl) {
      list.push(acl);
    }
  }

  return list;
};
