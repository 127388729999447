import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';

import { RemoteService } from '../../../services/remote.service';
import { PipedriveService } from '../../../services/pipedrive.service';
import { AclService } from '../../../services/acl.service';

import moment from 'moment';
import {
  getDisplayedEnquiryRefTitle,
  getEnquiryBreadcrumbTitle,
  IEnquiry
} from 'src/app/interfaces/enquiry.interface';
import { EnumLanguage, getLanguageLabel } from 'src/app/enums/language.enum';
import { Subscription } from 'rxjs';
import { EnquiryService } from 'src/app/services/enquiry/enquiry.service';
import { IEnquiryOffer } from 'src/app/interfaces/enquiry-offer.interface';
import { EnquiryOfferService } from 'src/app/services/enquiry-offer/enquiry-offer.service';
import { EnumEnquiryOfferStatus } from 'src/app/enums/enquiry-offer-status.enum';
import { EnumEnquiryType, getEnumEnquiryTypeLabel } from 'src/app/enums/enquiry-type.enum';
import { faBan, faPaperPlane, faPlus } from '@fortawesome/free-solid-svg-icons';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { IUser } from 'src/app/interfaces/user.interface';
import { IAirline } from 'src/app/interfaces/airline.interface';
import { IPipedriveOrganization, IPipedrivePerson } from 'src/app/interfaces/pipedrive.interface';
import { getContactTitle, IContact } from 'src/app/interfaces/contact.interface';
import { IAirport } from 'src/app/interfaces/airport.interface';
import { IEmailTemplate } from 'src/app/interfaces/email-template.interface';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs/breadcrumbs.service';

enum EnumEmailToSend {
  unknown = 'unknown',
  toSend = 'toSend',
  toIgnore = 'toIgnore'
}

enum EnumEmailType {
  contactAirlineForCotation = 'contactAirlineForCotation',
  followUpAirlineForCotation = 'followUpAirlineForCotation',
  cancelAirlineForCotation = 'cancelAirlineForCotation'
}

@Component({
  selector: 'app-send-message-to-airlines',
  templateUrl: './send-message-to-airlines.component.html',
  styleUrls: ['./send-message-to-airlines.component.scss']
})
export class SendMessageToAirlinesComponent implements OnInit, OnDestroy {
  getEnumEnquiryTypeLabel = getEnumEnquiryTypeLabel;
  getEnquiryBreadcrumbTitle = getEnquiryBreadcrumbTitle;
  getDisplayedEnquiryRefTitle = getDisplayedEnquiryRefTitle;
  getLanguageLabel = getLanguageLabel;
  getContactTitle = getContactTitle;

  EnumEmailToSend = EnumEmailToSend;

  faPlus = faPlus;
  faBan = faBan;
  faPaperPlane = faPaperPlane;

  EnumEnquiryType = EnumEnquiryType;

  loading: boolean = false;
  enquiryId: string;
  enquiry: IEnquiry;
  enquiryOffers: IEnquiryOffer[] = [];
  airlineId: string;
  isLogged: boolean = false;
  sending: boolean = false;
  client: IPipedriveOrganization | null = null;
  contact: IPipedrivePerson | null = null;
  currentAirlineContacts: IContact[] = [];
  airlines: IAirline[] = [];
  airlinesObj: { [key: string]: IAirline | null } = {};
  airlinesContactsObj: { [key: string]: IContact[] } = {};
  currentAirlineIndex: number = 0;
  form: FormArray;
  currentUser: IUser;
  airportsObj: { [key: string]: IAirport | null } = {};
  airportsToLoad: string[] = [];
  emailTemplateIds: {
    contactAirlineForCotation: {
      default: {
        [key: string]: string;
      };
      sport: {
        [key: string]: string;
      };
      cargo: {
        [key: string]: string;
      };
    };
    followUpAirlineForCotation: {
      default: {
        [key: string]: string;
      };
      sport: {
        [key: string]: string;
      };
      cargo: {
        [key: string]: string;
      };
    };
    cancelAirlineForCotation: {
      default: {
        [key: string]: string;
      };
      sport: {
        [key: string]: string;
      };
      cargo: {
        [key: string]: string;
      };
    };
  } = {
    contactAirlineForCotation: {
      default: {
        fr: 'IVJDfWmjTahOP7LkmnTU',
        en: 'EzxGhVQ9YK7EuXpsKfaz'
      },
      sport: {
        fr: 'VwtifTK24tCreUJVLPYk',
        en: 'OPShH8YucpKFJdQV3uzz'
      },
      cargo: {
        fr: 'V7uywaoiO8jfkqJkaDbz',
        en: 'ZqnmeNgjk3DTJFn3xjVR'
      }
    },
    followUpAirlineForCotation: {
      default: {
        fr: '2YDidANlydNzbq5ROdlV',
        en: 'yJIPQ5lswrktCNoXZ40W'
      },
      sport: {
        fr: '4qaGU0wuUDB4B0wrekHQ',
        en: 'SfywXU5ExP1oxNGVoST5'
      },
      cargo: {
        fr: 'crW2qO1IqkZpysygy0EC',
        en: 'WJcJ2eUvhuqvWw2oKsh6'
      }
    },
    cancelAirlineForCotation: {
      default: {
        fr: 'oY652BvgoTH55X18YRZo',
        en: 'y03esE3RgTUCnfFbNXRm'
      },
      sport: {
        fr: 'oY652BvgoTH55X18YRZo',
        en: 'y03esE3RgTUCnfFbNXRm'
      },
      cargo: {
        fr: 'oY652BvgoTH55X18YRZo',
        en: 'y03esE3RgTUCnfFbNXRm'
      }
    }
  };

  currentlyOpenedAccordionIndex: number = 0;

  emailType: EnumEmailType = EnumEmailType.contactAirlineForCotation;

  EnumEmailType = EnumEmailType;

  subscriptions = new Subscription();

  constructor(
    private remoteService: RemoteService,
    private pipedriveService: PipedriveService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private aclService: AclService,
    private enquiryService: EnquiryService,
    private enquiryOfferService: EnquiryOfferService,
    private zone: NgZone,
    private breadcrumbsService: BreadcrumbsService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
    this.remoteService.userObservable.subscribe((user: IUser) => {
      if (user) {
        this.currentUser = user;
      }
    });
  }

  ngOnInit(): void {
    this.form = this.formBuilder.array([]);

    this.form.disable();

    window['enquiryId'] = null;

    this.activatedRoute.url.subscribe(async () => {
      this.enquiryId = this.activatedRoute.snapshot.paramMap.get('enquiryId');
      this.airlineId = this.activatedRoute.snapshot.paramMap.get('airlineId');

      if (!!window.location.href.match('relance-airline')) {
        this.emailType = EnumEmailType.followUpAirlineForCotation;
      } else if (!!window.location.href.match('cancel-email-airline')) {
        this.emailType = EnumEmailType.cancelAirlineForCotation;
      } else {
        this.emailType = EnumEmailType.contactAirlineForCotation;
      }

      switch (this.emailType) {
        case EnumEmailType.contactAirlineForCotation:
          await this.aclService.checkAclAccess(EnumAcl.enquirieSendMessageToAirlines);
          break;
        case EnumEmailType.followUpAirlineForCotation:
          await this.aclService.checkAclAccess(EnumAcl.enquiriesRelanceAirline);
          break;
        case EnumEmailType.cancelAirlineForCotation:
          await this.aclService.checkAclAccess(EnumAcl.enquiriesCancelledEmailToAirline);
          break;
      }

      this.loadData();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getLanguages(): EnumLanguage[] {
    return Object.values(EnumLanguage);
  }

  addFormGroupAirlineToForm(): void {
    this.form.push(
      new FormGroup({
        status: new FormControl(EnumEmailToSend.unknown, [Validators.required]),
        enquiryId: new FormControl('', [Validators.required]),
        airlineId: new FormControl('', [Validators.required]),
        subject: new FormControl('', [Validators.required]),
        emails: new FormControl([], [Validators.required]),
        cc: new FormControl(''),
        cci: new FormControl(''),
        messageHtml: new FormControl('', [Validators.required]),
        language: new FormControl('', [Validators.required]),
        emailAttachments: new FormArray([])
      })
    );
  }

  async addFormGroupAirlineToFormAndSetValues(airline: IAirline): Promise<void> {
    this.addFormGroupAirlineToForm();

    const formGroup: FormGroup = this.form.at(this.form.length - 1) as FormGroup;

    formGroup.get('enquiryId').setValue(this.enquiryId);
    formGroup.get('airlineId').setValue(airline.id);

    for (const companyContact of airline.companyContacts) {
      for (const contact of companyContact.contacts) {
        if (contact.email) {
          if (!formGroup.value.emails?.length) {
            formGroup.get('emails').setValue([contact.email]);
          }

          if (typeof this.airlinesContactsObj[airline.id] === 'undefined') {
            this.airlinesContactsObj[airline.id] = [];
          }

          this.airlinesContactsObj[airline.id].push(contact);
        }
      }
    }

    await this.setMessageTemplate(formGroup);

    this.addEmailAttachmentsToForm(formGroup);
  }

  loadData(): void {
    if (this.isLogged) {
      this.loadEnquiry();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  addEmailAttachment(formGroup: FormGroup): void {
    const emailAttachmentsFormArray = formGroup.get('emailAttachments') as FormArray;

    emailAttachmentsFormArray.push(
      new FormGroup({
        id: new FormControl(this.remoteService.generateRandomId(), [Validators.required]),
        name: new FormControl('', [Validators.required]),
        url: new FormControl('', [Validators.required]),
        type: new FormControl('', [Validators.required]),
        size: new FormControl(0)
      })
    );

    formGroup.updateValueAndValidity();
  }

  loadEnquiry(): void {
    this.loading = true;

    this.subscriptions.add(
      this.enquiryService.getFromId(this.enquiryId).subscribe(async (enquiry: IEnquiry) => {
        this.enquiry = enquiry;

        if (this.enquiry) {
          this.breadcrumbsService.setCurrentItem({
            id: this.enquiry.id,
            text: getEnquiryBreadcrumbTitle(this.enquiry)
          });
        }

        await this.loadClient();
        await this.loadAirportsOfEnquiry();

        await this.loadEnquiryOffers();

        this.form.enable();

        this.loading = false;
      })
    );
  }

  loadEnquiryOffers(): void {
    this.subscriptions.add(
      this.enquiryOfferService
        .getAllForEnquiry(this.enquiryId)
        .subscribe(async (enquiryOffers: IEnquiryOffer[]) => {
          this.enquiryOffers = enquiryOffers;

          await this.fetchAircraftsCompiled();
        })
    );
  }

  async loadClient(): Promise<void> {
    if (this.enquiry.clientId) {
      this.loading = true;

      this.client = await this.pipedriveService.getOrganization(
        typeof this.enquiry.clientId === 'number'
          ? this.enquiry.clientId.toString()
          : this.enquiry.clientId
      );

      this.loading = false;
    }

    if (this.enquiry.contactId) {
      this.loading = true;

      this.contact = await this.pipedriveService.getPerson(this.enquiry.contactId);

      this.loading = false;
    }
  }

  async fetchAircraftsCompiled(): Promise<void> {
    if (this.enquiryOffers.length) {
      this.loading = true;

      const airlinesId: string[] = [];

      if (this.airlineId) {
        airlinesId.push(this.airlineId);
      } else {
        for (const offer of this.enquiryOffers) {
          let addAirline: boolean = false;
          switch (this.emailType) {
            case EnumEmailType.contactAirlineForCotation:
              if (offer.status === EnumEnquiryOfferStatus.toContact) {
                addAirline = true;
              }
              break;
            case EnumEmailType.followUpAirlineForCotation:
              if (offer.status === EnumEnquiryOfferStatus.waitingForAnswer) {
                addAirline = true;
              }
              break;
            case EnumEmailType.cancelAirlineForCotation:
              if (offer.status === EnumEnquiryOfferStatus.answered) {
                addAirline = true;
              }
              break;
          }

          if (addAirline) {
            const aircraftCompiledIdSplitted: string[] = offer.aircraftCompiledId.split('-');

            if (aircraftCompiledIdSplitted[0]) {
              airlinesId.push(aircraftCompiledIdSplitted[0]);
            }
          }
        }
      }

      await this.fetchAirlines(airlinesId);

      this.loading = false;
    }
  }

  async fetchAirlines(airlinesId: string[]): Promise<void> {
    if (airlinesId.length) {
      this.loading = true;

      const docs = await this.remoteService.getDocumentsFromDocId('airlines', airlinesId);

      this.airlines = [];
      for (const doc of docs) {
        this.airlines.push(doc as IAirline);
      }

      this.zone.run(async () => {
        for (const airline of this.airlines) {
          if (!this.airlinesObj[airline.id]) {
            this.airlinesObj[airline.id] = airline;

            await this.addFormGroupAirlineToFormAndSetValues(airline);
          }
        }
      });

      this.form.enable();

      this.loading = false;
    }
  }

  getDatesForSubject(): string {
    const datesDepartForSubject: string[] = [];
    for (const itinerary of this.enquiry.itineraries) {
      for (const trip of itinerary.trips) {
        const date: string = trip.date;

        if (datesDepartForSubject.indexOf(date) === -1) {
          datesDepartForSubject.push(date);
        }
      }
    }
    datesDepartForSubject.sort();

    for (const i in datesDepartForSubject) {
      datesDepartForSubject[i] = moment(datesDepartForSubject[i])
        .format('ddd DD MMMM YYYY')
        .toUpperCase();
    }

    return datesDepartForSubject.join(' - ');
  }

  getAirportsCodeForSubject(): string {
    const airportsCode: string[] = [];
    for (const itinerary of this.enquiry.itineraries) {
      for (const trip of itinerary.trips) {
        for (const field of [
          'airportDepart',
          'airportDestination',
          'commercialStopOverAirport',
          'fuelStopOverAirport'
        ]) {
          if (airportsCode[airportsCode.length - 1] !== trip[field] && trip[field]) {
            airportsCode.push(this.airportsObj[trip[field]]?.iataCode);
          }
        }
      }
    }

    return airportsCode.join(' / ');
  }

  async setMessageTemplate(
    formGroup: FormGroup,
    setLanguageAccordingToAirlineCountry: boolean = true
  ): Promise<void> {
    if (this.enquiry) {
      if (setLanguageAccordingToAirlineCountry) {
        let language: EnumLanguage = EnumLanguage.en;

        if (this.airlinesObj[formGroup.value.airlineId]?.countryCode === 'FR') {
          language = EnumLanguage.fr;
        } else {
          language = EnumLanguage.en;
        }

        formGroup.get('language').setValue(language);
      }

      moment.locale(formGroup.value.language);

      const emailTemplate: IEmailTemplate = await this.getEmailTemplate(
        this.enquiry.type,
        formGroup.value.language
      );

      let subject: string = this.setEmailVariable(
        formGroup,
        emailTemplate.subject,
        formGroup.value.language
      );

      let html: string = this.setEmailVariable(
        formGroup,
        emailTemplate.messageHtml,
        formGroup.value.language
      );

      formGroup.get('cc').setValue(this.currentUser.emailInCc ? this.currentUser.email : '');
      formGroup.get('cci').setValue('backup2@flyaa.eu');
      formGroup.get('subject').setValue(subject);
      formGroup.get('messageHtml').setValue(html);
    }
  }

  async getEmailTemplate(
    type: string = 'default',
    language: EnumLanguage = EnumLanguage.en
  ): Promise<IEmailTemplate> {
    try {
      if (!this.emailTemplateIds[this.emailType][type]) {
        type = 'default';
      }
      const doc: object = await this.remoteService.getDocument(
        'emailTemplates',
        this.emailTemplateIds[this.emailType][type][language]
      );

      if (doc) {
        return doc as IEmailTemplate;
      }
    } catch (err) {
      alert(err.message);
    }
  }

  setEmailVariable(
    formGroup: FormGroup,
    text: string,
    language: EnumLanguage = EnumLanguage.en
  ): string {
    const variables: object = {
      'enquiry.ref': getDisplayedEnquiryRefTitle(this.enquiry, 'refEnquiry'),
      'enquiry.routing': this.getAirportsCodeForSubject(),
      'enquiry.dates': this.getDatesForSubject(),
      'airline.name': this.airlinesObj[formGroup.value.airlineId]?.title,
      'enquiry.details': '',
      'user.emailSignature': this.currentUser.emailSignatureHtml
    };

    switch (language) {
      case EnumLanguage.fr:
        for (const itineraryIndex in this.enquiry.itineraries) {
          if (this.enquiry.itineraries.length > 1) {
            variables['enquiry.details'] +=
              '<strong>OPTION ' + (parseInt(itineraryIndex) + 1) + '</strong><br>';
          }

          for (let i = 0; i < this.enquiry.itineraries[itineraryIndex].trips.length; i++) {
            const trip = this.enquiry.itineraries[itineraryIndex].trips[i];
            const isLastTrip: boolean =
              i === this.enquiry.itineraries[itineraryIndex].trips.length - 1;

            variables['enquiry.details'] += moment(trip.date)
              .format('ddd DD MMMM YYYY')
              .toUpperCase();
            variables['enquiry.details'] += '&nbsp;&nbsp;&nbsp;&nbsp;';
            variables['enquiry.details'] += '&nbsp;&nbsp;&nbsp;&nbsp;';
            variables['enquiry.details'] += '&nbsp;&nbsp;&nbsp;&nbsp;';

            variables['enquiry.details'] += this.airportsObj[trip.airportDepart]?.iataCode;

            if (trip.hasCommercialStopOver) {
              variables['enquiry.details'] +=
                ' / STOP ' + this.airportsObj[trip.commercialStopOverAirport]?.iataCode + ' - ';
            }

            variables['enquiry.details'] += ' / ';

            variables['enquiry.details'] += this.airportsObj[trip.airportDestination]?.iataCode;

            variables['enquiry.details'] += '&nbsp;&nbsp;&nbsp;&nbsp;';
            if (trip.time) {
              variables['enquiry.details'] += 'DEP ' + trip.time + ' local';
              variables['enquiry.details'] += '&nbsp;&nbsp;&nbsp;&nbsp;';
            }

            if (trip.passengersTotal) {
              variables['enquiry.details'] += trip.passengersTotal + 'Y';

              if (trip.hasCommercialStopOver) {
                variables['enquiry.details'] +=
                  ' / STOP ' + Math.abs(trip.passengersCommercialStop) + 'Y - ';
                variables['enquiry.details'] +=
                  trip.passengersTotal + trip.passengersCommercialStop;
                variables['enquiry.details'] += 'Y';
              }

              if (trip.passengersBusiness) {
                variables['enquiry.details'] +=
                  '&nbsp;&nbsp;&nbsp;&nbsp; inclus ' + trip.passengersBusiness + 'C';
              }
            }

            if (this.enquiry.type === EnumEnquiryType.cargo) {
              variables['enquiry.details'] += '<br>';
              variables['enquiry.details'] += 'Cargo : ';
              variables['enquiry.details'] += trip.natureFret ? trip.natureFret : 'Inconnu';
              variables['enquiry.details'] += '<br>';

              if (trip.dimensions) {
                variables['enquiry.details'] += 'Dimensions : ' + trip.dimensions;
                variables['enquiry.details'] += '<br>';
              }
              if (trip.weight) {
                variables['enquiry.details'] += 'Poids : ' + trip.weight + ' kg';
                variables['enquiry.details'] += '<br>';
              }
              if (trip.volume) {
                variables['enquiry.details'] += 'Volume : ' + trip.volume + ' m3';
              }
              variables['enquiry.details'] += '<br>';
            }

            variables['enquiry.details'] += '<br>';
          }

          if (parseInt(itineraryIndex) < this.enquiry.itineraries.length - 1) {
            variables['enquiry.details'] += '<br>';
            variables['enquiry.details'] += 'OU' + '<br>';
            variables['enquiry.details'] += '<br>';
          }
        }

        if (this.enquiry.noteForAirlines && this.enquiry.noteForAirlines !== '') {
          variables['enquiry.details'] += '<br>';
          variables['enquiry.details'] += this.enquiry.noteForAirlines.replace(
            new RegExp('\r?\n', 'g'),
            '<br>'
          );
          variables['enquiry.details'] += '<br>';
        }

        if (this.enquiry.emailAttachments.length) {
          variables['enquiry.details'] += '<br><strong>Pièces-jointes :</strong><br>';
          variables['enquiry.details'] += this.getEnquiryEmailAttachements(language);
        }
        break;
      case EnumLanguage.en:
      default:
        for (const itineraryIndex in this.enquiry.itineraries) {
          if (this.enquiry.itineraries.length > 1) {
            variables['enquiry.details'] +=
              '<strong>OPTION ' + (parseInt(itineraryIndex) + 1) + '</strong><br>';
          }

          for (let i = 0; i < this.enquiry.itineraries[itineraryIndex].trips.length; i++) {
            const trip = this.enquiry.itineraries[itineraryIndex].trips[i];
            const isLastTrip: boolean =
              i === this.enquiry.itineraries[itineraryIndex].trips.length - 1;

            variables['enquiry.details'] += moment(trip.date)
              .format('ddd DD MMMM YYYY')
              .toUpperCase();
            variables['enquiry.details'] += '&nbsp;&nbsp;&nbsp;&nbsp;';
            variables['enquiry.details'] += '&nbsp;&nbsp;&nbsp;&nbsp;';
            variables['enquiry.details'] += '&nbsp;&nbsp;&nbsp;&nbsp;';

            variables['enquiry.details'] += this.airportsObj[trip.airportDepart].iataCode;

            if (trip.hasCommercialStopOver) {
              variables['enquiry.details'] +=
                ' / STOP ' + this.airportsObj[trip.commercialStopOverAirport].iataCode + ' - ';
            }

            variables['enquiry.details'] += ' / ';

            variables['enquiry.details'] += this.airportsObj[trip.airportDestination].iataCode;

            variables['enquiry.details'] += '&nbsp;&nbsp;&nbsp;&nbsp;';
            if (trip.time) {
              variables['enquiry.details'] += 'DEP ' + trip.time + ' local';
              variables['enquiry.details'] += '&nbsp;&nbsp;&nbsp;&nbsp;';
            }

            if (trip.passengersTotal) {
              variables['enquiry.details'] += trip.passengersTotal + 'Y';

              if (trip.hasCommercialStopOver) {
                variables['enquiry.details'] +=
                  ' / STOP ' + Math.abs(trip.passengersCommercialStop) + 'Y - ';
                variables['enquiry.details'] +=
                  trip.passengersTotal + trip.passengersCommercialStop;
                variables['enquiry.details'] += 'Y';
              }

              if (trip.passengersBusiness) {
                variables['enquiry.details'] +=
                  '&nbsp;&nbsp;&nbsp;&nbsp; including ' + trip.passengersBusiness + 'C';
              }
            }

            if (this.enquiry.type === EnumEnquiryType.cargo) {
              variables['enquiry.details'] += '<br>';
              variables['enquiry.details'] += 'Cargo : ';
              variables['enquiry.details'] += trip.natureFret ? trip.natureFret : 'Unknown';
              variables['enquiry.details'] += '<br>';

              if (trip.dimensions) {
                variables['enquiry.details'] += 'Dimensions : ' + trip.dimensions;
                variables['enquiry.details'] += '<br>';
              }
              if (trip.weight) {
                variables['enquiry.details'] += 'Weight : ' + trip.weight + ' kg';
                variables['enquiry.details'] += '<br>';
              }
              if (trip.volume) {
                variables['enquiry.details'] += 'Volume : ' + trip.volume + ' m3';
              }
              variables['enquiry.details'] += '<br>';
            }

            variables['enquiry.details'] += '<br>';
          }

          if (parseInt(itineraryIndex) < this.enquiry.itineraries.length - 1) {
            variables['enquiry.details'] += '<br>';
            variables['enquiry.details'] += 'OR' + '<br>';
            variables['enquiry.details'] += '<br>';
          }
        }

        if (this.enquiry.noteForAirlines && this.enquiry.noteForAirlines !== '') {
          variables['enquiry.details'] += '<br>';
          variables['enquiry.details'] += this.enquiry.noteForAirlines.replace(
            new RegExp('\r?\n', 'g'),
            '<br>'
          );
          variables['enquiry.details'] += '<br>';
        }

        if (this.enquiry.emailAttachments.length) {
          variables['enquiry.details'] += '<br><strong>Attachments :</strong><br>';
          variables['enquiry.details'] += this.getEnquiryEmailAttachements(language);
        }

        break;
    }

    for (const variable in variables) {
      text = text.replace(new RegExp('{{' + variable + '}}', 'g'), variables[variable]);
    }

    return text;
  }

  getEnquiryEmailAttachements(language: EnumLanguage = EnumLanguage.en): string {
    let html = '';

    if (this.enquiry.emailAttachments) {
      const links: string[] = [];
      for (const emailAttachments of this.enquiry.emailAttachments) {
        links.push("<a href='" + emailAttachments.url + "'>" + emailAttachments.name + '</a>');
      }

      html += links.join('<br>');
    }

    return html;
  }

  addEmailAttachmentsToForm(formGroup: FormGroup): void {
    const language: string = formGroup.value.language;

    const emailAttachmentsFormArray = formGroup.get('emailAttachments') as FormArray;

    while (emailAttachmentsFormArray.length !== 0) {
      emailAttachmentsFormArray.removeAt(0);
    }

    for (const emailAttachment of this.enquiry.emailAttachments) {
      if (!emailAttachment.language || language === emailAttachment.language) {
        this.addEmailAttachment(formGroup);

        for (const field of ['id', 'name', 'url', 'size', 'type']) {
          emailAttachmentsFormArray
            .at(emailAttachmentsFormArray.controls.length - 1)
            .get(field)
            .setValue(emailAttachment[field]);
        }
      }
    }
  }

  ignoreAndGoNext(i: number): void {
    this.form.at(i).get('status').setValue(EnumEmailToSend.toIgnore);

    this.goNext(i);
  }

  getAllEmailAdded(): number {
    let count: number = 0;

    for (const values of this.form.value) {
      if (values.status === EnumEmailToSend.toSend) {
        count++;
      }
    }

    return count;
  }

  async sendAllEmails(): Promise<void> {
    this.sending = true;

    this.form.disable();

    for (const values of this.form.value) {
      if (values.status === EnumEmailToSend.toSend) {
        for (const email of values.emails) {
          const data: {
            subject: string;
            content: string;
            contentHtml: string;
            replyTo: {
              email: string;
              name: string;
            };
            to: {
              email: string;
              name: string;
            };
            cc: string[];
            cci: string[];
            params: {
              enquiryId: string;
              airlineId: string;
              contactedBy: string;
              language: string;
            };
            sent: boolean;
            emailAttachments: {
              id: string;
              name: string;
              type: string;
              size: number;
              url: string;
            }[];
          } = {
            subject: values.subject,
            content: this.remoteService.convertHtmlToPlainText(values.messageHtml),
            contentHtml: values.messageHtml,
            replyTo: {
              email: this.currentUser.email,
              name: this.currentUser.firstname + ' ' + this.currentUser.lastname
            },
            to: {
              email: email,
              name: null
            },
            cc: values.cc.split(';'),
            cci: values.cci.split(';'),
            params: {
              enquiryId: values.enquiryId,
              airlineId: values.airlineId,
              contactedBy: this.currentUser.id,
              language: values.language
            },
            sent: false,
            emailAttachments: values.emailAttachments
          };

          try {
            const docId: string = await this.remoteService.addDocumentToCollection(
              'emailPool',
              data
            );

            if (docId) {
              for (const offer of this.enquiryOffers) {
                if (offer.aircraftCompiled.airlineId === data.params.airlineId) {
                  let data: IEnquiryOffer = {
                    id: offer.id,
                    status: EnumEnquiryOfferStatus.waitingForAnswer
                  } as IEnquiryOffer;

                  switch (this.emailType) {
                    case EnumEmailType.followUpAirlineForCotation:
                      data.followedUp = true;
                      break;
                    case EnumEmailType.cancelAirlineForCotation:
                      data.status = EnumEnquiryOfferStatus.cancelled;
                      break;
                  }

                  await this.enquiryOfferService.update(data);

                  break;
                }
              }
            }
          } catch (err) {
            console.log(err);
            alert(err.message);
          }
        }
      }
    }

    alert(this.getAllEmailAdded() + ' emails ont été envoyés.');

    this.sending = false;

    this.router.navigate(['/admin/enquiries/' + this.enquiryId]);
  }

  addAndGoNext(i: number): void {
    this.form.at(i).markAsTouched();
    if (this.form.at(i).status == 'VALID' && this.form.at(i).value.emails.length) {
      this.form.at(i).get('status').setValue(EnumEmailToSend.toSend);

      this.goNext(i);
    }
  }

  addAndSend(i: number): void {
    this.form.at(i).markAsTouched();
    if (this.form.at(i).status == 'VALID' && this.form.at(i).value.emails.length) {
      this.form.at(i).get('status').setValue(EnumEmailToSend.toSend);
    }

    this.sendAllEmails();
  }

  goNext(i: number): void {
    this.currentlyOpenedAccordionIndex = i;
    if (this.form.length > this.currentlyOpenedAccordionIndex) {
      this.currentlyOpenedAccordionIndex++;
    }

    const accordionEl: HTMLElement = document.getElementById('accordion-airlines');

    window.scrollTo(0, accordionEl.offsetTop + this.currentlyOpenedAccordionIndex * 52);
  }

  reviver(key: string, value: string): any {
    if (
      ['selectedDatetime', 'contactDatetime', 'created', 'modified'].indexOf(key) !== -1 &&
      !isNaN(Date.parse(value))
    ) {
      return new Date(value);
    }

    return value;
  }

  formatPrice(value: number): string {
    const formatter = new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2
    });

    return formatter.format(value);
  }

  addAirlineContacts(airlineId: string): void {
    window['enquiryId'] = this.enquiry.id;

    this.router.navigate(['/admin/airlines/' + airlineId + '/edit']);
  }

  async loadAirportsOfEnquiry(): Promise<void> {
    for (const itinerary of this.enquiry.itineraries) {
      for (const trip of itinerary.trips) {
        for (const field of [
          'airportDepart',
          'airportDestination',
          'commercialStopOverAirport',
          'fuelStopOverAirport'
        ]) {
          if (trip[field]) {
            this.addAirportToLoad(trip[field]);
          }
        }
      }
    }

    await this.loadAirports();
  }

  addAirportToLoad(airportId: string) {
    if (
      this.airportsToLoad.indexOf(airportId) === -1 &&
      typeof this.airportsObj[airportId] === 'undefined'
    ) {
      this.airportsToLoad.push(airportId);
    }
  }

  async loadAirports(): Promise<void> {
    if (this.airportsToLoad.length) {
      const docs: object[] = await this.remoteService.getDocumentsFromDocId(
        'airports',
        this.airportsToLoad
      );

      for (const doc of docs) {
        const airport = doc as IAirport;

        this.airportsObj[airport.id] = airport;
      }

      this.airportsToLoad = [];
    }
  }

  updateEmailCheckbox(formGroup: FormGroup, value: string): void {
    const values: string[] = [...formGroup.value.emails];

    const index: number = values.indexOf(value);

    if (index === -1) {
      values.push(value);
    } else {
      values.splice(index, 1);
    }

    formGroup.get('emails').setValue(values);
  }

  async updatedLanguage(formGroup: FormGroup): Promise<void> {
    await this.setMessageTemplate(formGroup, false);
  }
}
