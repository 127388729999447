<form (submit)="submitForm()" [formGroup]="form" (keydown.enter)="submitForm()" class="main-form">
    <div class="row justify-content-center">
        <div class="col-md-6">
            <div class="card">
                <div class="card-body">
                    <div class="form-group">
                        <label for="title">Nom</label>
                        <input
                            class="form-control"
                            id="title"
                            formControlName="title"
                            type="text"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['title'].touched) &&
                                form.controls['title'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['title'].touched) &&
                                    form.controls['title'].errors &&
                                    form.controls['title'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="countryCode">Pays</label>
                        <autocomplete-input
                            (setValueToFormControl)="setValueToFormControl($event)"
                            inputName="countryCode"
                            [defaultValue]="form.value['countryCode']"
                            [itineraries]="countriesList"
                            [disabled]="form.disabled || !countriesList.length"
                            *ngIf="countriesList.length"
                        ></autocomplete-input>

                        <div
                            *ngIf="
                                (form.touched || form.controls['countryCode'].touched) &&
                                form.controls['countryCode'].errors &&
                                form.controls['countryCode'].errors['required']
                            "
                            class="text-danger"
                        >
                            Ce champ est obligatoire.
                        </div>
                    </div>

                    <div class="form-group" *ngIf="form.value['countryCode']">
                        <label for="continentCode">Continent</label>
                        <input
                            class="form-control"
                            id="continentCode"
                            type="text"
                            [readonly]="
                                !(form.value['countryCode'] && !form.value['continentCode'])
                            "
                            [value]="getContinentLabel(form.value['continentCode'])"
                        />
                    </div>

                    <div class="form-check mb-2">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="is-national-company"
                            formControlName="isNationalCompany"
                        />
                        <label class="form-check-label" for="is-national-company"
                            >Est une compagnie nationale</label
                        >
                    </div>

                    <div class="form-check mb-2">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="must-be-contacted"
                            formControlName="mustBeContacted"
                        />
                        <label class="form-check-label" for="must-be-contacted"
                            >Doit être absolument contactée</label
                        >
                    </div>

                    <div class="form-check mb-2">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="isBlacklisted"
                            formControlName="isBlacklisted"
                        />
                        <label class="form-check-label" for="isBlacklisted">Est blacklistée</label>
                    </div>

                    <div class="form-group" *ngIf="form.value.isBlacklisted">
                        <label for="reason-blackListed">Raison du black listage</label>
                        <textarea
                            formControlName="reasonBlackListed"
                            class="form-control"
                            id="reason-blackListed"
                        ></textarea>
                    </div>

                    <div class="form-group">
                        <label for="website">Site internet</label>
                        <input
                            class="form-control"
                            id="website"
                            formControlName="website"
                            type="text"
                            [class.is-invalid]="
                                (form.touched || form.controls['website'].touched) &&
                                form.controls['website'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                            placeholder="https://..."
                            pattern="https?://.+"
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['website'].touched) &&
                                    form.controls['website'].errors &&
                                    form.controls['website'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                            <div
                                *ngIf="
                                    (form.touched || form.controls['website'].touched) &&
                                    form.controls['website'].errors &&
                                    form.controls['website'].errors['pattern']
                                "
                            >
                                Ce champ doit être une URL commançant par http or https.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="companyCreationYear">Date de création de la compagnie</label>
                        <input
                            class="form-control"
                            id="companyCreationYear"
                            formControlName="companyCreationYear"
                            type="number"
                            min="1900"
                            [class.is-invalid]="
                                (form.touched || form.controls['companyCreationYear'].touched) &&
                                form.controls['companyCreationYear'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched ||
                                        form.controls['companyCreationYear'].touched) &&
                                    form.controls['companyCreationYear'].errors &&
                                    form.controls['companyCreationYear'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="companyClosureYear">Date de fermeture de la compagnie</label>
                        <input
                            class="form-control"
                            id="companyClosureYear"
                            formControlName="companyClosureYear"
                            type="number"
                            min="1900"
                            [class.is-invalid]="
                                (form.touched || form.controls['companyClosureYear'].touched) &&
                                form.controls['companyClosureYear'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['companyClosureYear'].touched) &&
                                    form.controls['companyClosureYear'].errors &&
                                    form.controls['companyClosureYear'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-check mb-2">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="is-active"
                            formControlName="isActive"
                        />
                        <label class="form-check-label" for="is-active">Est active ?</label>
                    </div>

                    <div class="form-group">
                        <label for="codes">Codes</label>
                        <input
                            class="form-control"
                            id="codes"
                            formControlName="codes"
                            type="text"
                        />
                    </div>

                    <div class="form-group">
                        <label for="url">Airfleets URL</label>
                        <input
                            class="form-control"
                            id="url"
                            formControlName="url"
                            type="url"
                            [class.is-invalid]="
                                (form.touched || form.controls['url'].touched) &&
                                form.controls['url'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                            placeholder="https://..."
                            pattern="https?://.+"
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['url'].touched) &&
                                    form.controls['url'].errors &&
                                    form.controls['url'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                            <div
                                *ngIf="
                                    (form.touched || form.controls['url'].touched) &&
                                    form.controls['url'].errors &&
                                    form.controls['url'].errors['pattern']
                                "
                            >
                                Ce champ doit être une URL commançant par http or https.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="airfleetTitle">Nom dans Airfleets</label>
                        <p class="text-muted">
                            Ce champ est utilisé pour le script récupérant les informations depuis
                            Airfleets.net
                        </p>
                        <input
                            class="form-control"
                            id="airfleetTitle"
                            formControlName="airfleetTitle"
                            type="text"
                            placeholder="Laissez vide si le nom dans Simplyfly est identique."
                        />
                    </div>

                    <div class="form-group">
                        <label for="callsign">Callsign</label>
                        <input
                            class="form-control"
                            id="callsign"
                            formControlName="callsign"
                            type="text"
                        />
                    </div>

                    <div class="form-group">
                        <label for="tvaNumber">Numéro de TVA</label>
                        <input
                            class="form-control"
                            id="tvaNumber"
                            formControlName="tvaNumber"
                            type="text"
                            [class.is-invalid]="
                                (form.touched || form.controls['tvaNumber'].touched) &&
                                form.controls['tvaNumber'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['tvaNumber'].touched) &&
                                    form.controls['tvaNumber'].errors &&
                                    form.controls['tvaNumber'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="description">Description de la compagnie</label>
                        <textarea
                            formControlName="description"
                            class="form-control"
                            id="description"
                            [class.is-invalid]="
                                (form.touched || form.controls['description'].touched) &&
                                form.controls['description'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        ></textarea>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['description'].touched) &&
                                    form.controls['description'].errors &&
                                    form.controls['description'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mt-4" *ngIf="form">
                <div class="card-header text-center">
                    <div class="card-title">Documentation compagnie</div>
                </div>
                <ul class="list-group list-group-flush" formArrayName="documentsUrl">
                    <li
                        class="list-group-item list-group-item-warning text-center"
                        *ngIf="!form.value.documentsUrl.length"
                    >
                        Aucun document
                    </li>
                    <li
                        class="list-group-item"
                        [formGroupName]="i"
                        *ngFor="
                            let documentsUrl of form.get('documentsUrl')['controls'];
                            let i = index
                        "
                    >
                        <strong>Document {{ i + 1 }}</strong>

                        <button
                            type="button"
                            (click)="deleteDocument(i)"
                            class="btn btn-danger btn-sm float-end"
                            [disabled]="form.disabled"
                        >
                            <fa-icon [icon]="faTrash"></fa-icon>
                        </button>

                        <div class="form-group">
                            <label for="title">Nom</label>
                            <input
                                class="form-control"
                                id="title"
                                formControlName="title"
                                type="text"
                                required
                                [class.is-invalid]="
                                    (form.touched || getDocumentUrlField(i, 'title').touched) &&
                                    getDocumentUrlField(i, 'title').status === 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched || getDocumentUrlField(i, 'title').touched) &&
                                        getDocumentUrlField(i, 'title').errors &&
                                        getDocumentUrlField(i, 'title').errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>

                        <div class="text-center">
                            <a
                                [href]="form.value.documentsUrl[i].url"
                                target="_blank"
                                *ngIf="form.value.documentsUrl[i].url"
                                >Lien du document</a
                            ><br />
                            <br />
                            <button
                                type="button"
                                (click)="showUploadModal(i)"
                                class="btn btn-secondary"
                                [disabled]="form.disabled"
                                [innerHtml]="
                                    uploading && documentIndex !== null
                                        ? uploadingProgress + '% envoyé'
                                        : 'Éditer le document'
                                "
                            ></button>
                        </div>
                    </li>
                    <li class="list-group-item text-center">
                        <button
                            type="button"
                            (click)="showUploadModal()"
                            class="btn btn-secondary"
                            [disabled]="form.disabled"
                            [innerHtml]="
                                uploading && documentIndex === null
                                    ? uploadingProgress + '% envoyé'
                                    : '+ Nouveau document'
                            "
                        ></button>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-md-6">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title text-center">Adresse</h5>
                    <div class="form-group">
                        <label for="streetNumber">Numéro</label>
                        <input
                            class="form-control"
                            id="streetNumber"
                            formControlName="streetNumber"
                            type="text"
                            [class.is-invalid]="
                                (form.touched || form.controls['streetNumber'].touched) &&
                                form.controls['streetNumber'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['streetNumber'].touched) &&
                                    form.controls['streetNumber'].errors &&
                                    form.controls['streetNumber'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="route">Rue</label>
                        <input
                            class="form-control"
                            id="route"
                            formControlName="route"
                            type="text"
                            [class.is-invalid]="
                                (form.touched || form.controls['route'].touched) &&
                                form.controls['route'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['route'].touched) &&
                                    form.controls['route'].errors &&
                                    form.controls['route'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="postalCode">Code postal</label>
                        <input
                            class="form-control"
                            id="postalCode"
                            formControlName="postalCode"
                            type="text"
                            [class.is-invalid]="
                                (form.touched || form.controls['postalCode'].touched) &&
                                form.controls['postalCode'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['postalCode'].touched) &&
                                    form.controls['postalCode'].errors &&
                                    form.controls['postalCode'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="city">Ville</label>
                        <input
                            class="form-control"
                            id="city"
                            formControlName="city"
                            type="text"
                            [class.is-invalid]="
                                (form.touched || form.controls['city'].touched) &&
                                form.controls['city'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['city'].touched) &&
                                    form.controls['city'].errors &&
                                    form.controls['city'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mt-4" *ngIf="form">
                <div class="card-header text-center"><div class="card-title">Contacts</div></div>
                <ul class="list-group list-group-flush" formArrayName="companyContacts">
                    <li
                        class="list-group-item list-group-item-warning text-center"
                        *ngIf="!companyContacts.length"
                    >
                        Aucun contact
                    </li>
                    <li
                        class="list-group-item"
                        [formGroupName]="i"
                        *ngFor="let companyContact of companyContacts['controls']; let i = index"
                    >
                        <strong>Service {{ i + 1 }}</strong>

                        <button
                            type="button"
                            (click)="deleteContactCategory(i)"
                            class="btn btn-danger btn-sm float-end"
                            [disabled]="form.disabled"
                        >
                            <fa-icon [icon]="faTrash"></fa-icon>
                        </button>

                        <div class="clearfix"></div>

                        <div class="form-group">
                            <label for="title">Nom du service</label>
                            <input
                                class="form-control"
                                id="title"
                                formControlName="title"
                                type="text"
                                required
                                [class.is-invalid]="
                                    (form.touched || getCompanyContactField(i, 'title').touched) &&
                                    getCompanyContactField(i, 'title').status === 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                                placeholder="Ex: Service généraux, Service commercial, etc"
                            />

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched ||
                                            getCompanyContactField(i, 'title').touched) &&
                                        getCompanyContactField(i, 'title').errors &&
                                        getCompanyContactField(i, 'title').errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>Département(s) concerné(s)</label>
                            <div class="form-check">
                                <input
                                    type="checkbox"
                                    name="enquiryTypes"
                                    class="form-check-input"
                                    [id]="i + '-enquiryType-all'"
                                    [checked]="
                                        !getCompanyContactField(i, 'enquiryTypes').value.length
                                    "
                                    (change)="toggleAllEnquiryType(i)"
                                />
                                <label class="form-check-label" [attr.for]="i + '-enquiryType-all'"
                                    >Tous les départements
                                </label>
                            </div>

                            <div class="form-check" *ngFor="let enquiryType of getEnquiryTypes()">
                                <input
                                    type="checkbox"
                                    name="enquiryTypes"
                                    class="form-check-input"
                                    [id]="i + '-enquiryType-' + enquiryType"
                                    [checked]="
                                        getCompanyContactField(i, 'enquiryTypes').value.includes(
                                            enquiryType
                                        )
                                    "
                                    (change)="toggleEnquiryType(i, enquiryType)"
                                />
                                <label
                                    class="form-check-label"
                                    [attr.for]="i + '-enquiryType-' + enquiryType"
                                    [innerText]="getEnumEnquiryTypeLabel(enquiryType)"
                                >
                                </label>
                            </div>

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched ||
                                            getCompanyContactField(i, 'enquiryTypes').touched) &&
                                        getCompanyContactField(i, 'enquiryTypes').errors &&
                                        getCompanyContactField(i, 'enquiryTypes').errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>

                        <ul class="list-group" formArrayName="contacts">
                            <li
                                class="list-group-item list-group-item-warning text-center"
                                *ngIf="!getCompanyContactsInSection(i).length"
                            >
                                Aucun contact
                            </li>
                            <li
                                class="list-group-item"
                                [formGroupName]="j"
                                *ngFor="
                                    let contact of getCompanyContactsInSection(i)['controls'];
                                    let j = index
                                "
                            >
                                <strong>Contact {{ j + 1 }}</strong>

                                <button
                                    type="button"
                                    (click)="deleteContactFromCategory(i, j)"
                                    class="btn btn-danger btn-sm float-end"
                                    [disabled]="form.disabled"
                                >
                                    <fa-icon [icon]="faTrash"></fa-icon>
                                </button>

                                <div class="form-group">
                                    <label for="lastname">Nom</label>
                                    <input
                                        class="form-control"
                                        id="lastname"
                                        formControlName="lastname"
                                        type="text"
                                        [class.is-invalid]="
                                            (form.touched ||
                                                getCompanyContactOfSectionField(i, j, 'lastname')
                                                    .touched) &&
                                            getCompanyContactOfSectionField(i, j, 'lastname')
                                                .status === 'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                    />
                                </div>

                                <div class="form-group">
                                    <label for="firstname">Prénom</label>
                                    <input
                                        class="form-control"
                                        id="firstname"
                                        formControlName="firstname"
                                        type="text"
                                        [class.is-invalid]="
                                            (form.touched ||
                                                getCompanyContactOfSectionField(i, j, 'firstname')
                                                    .touched) &&
                                            getCompanyContactOfSectionField(i, j, 'firstname')
                                                .status === 'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                    />
                                </div>

                                <div class="form-group">
                                    <label for="fonction">Fonction</label>
                                    <input
                                        class="form-control"
                                        id="fonction"
                                        formControlName="fonction"
                                        type="text"
                                        [class.is-invalid]="
                                            (form.touched ||
                                                getCompanyContactOfSectionField(i, j, 'fonction')
                                                    .touched) &&
                                            getCompanyContactOfSectionField(i, j, 'fonction')
                                                .status === 'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                    />
                                </div>

                                <div class="form-group">
                                    <label for="phone">Téléphone</label>
                                    <input
                                        class="form-control"
                                        id="phone"
                                        formControlName="phone"
                                        type="tel"
                                    />
                                </div>

                                <div class="form-group">
                                    <label for="mobile">Portable</label>
                                    <input
                                        class="form-control"
                                        id="mobile"
                                        formControlName="mobile"
                                        type="tel"
                                    />
                                </div>

                                <div class="form-group">
                                    <label for="fax">Fax</label>
                                    <input
                                        class="form-control"
                                        id="fax"
                                        formControlName="fax"
                                        type="tel"
                                    />
                                </div>

                                <div class="form-group">
                                    <label for="email">Email</label>
                                    <input
                                        class="form-control"
                                        id="email"
                                        formControlName="email"
                                        type="email"
                                        [class.is-invalid]="
                                            (form.touched ||
                                                getCompanyContactOfSectionField(i, j, 'email')
                                                    .touched) &&
                                            getCompanyContactOfSectionField(i, j, 'email')
                                                .status === 'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                    />

                                    <div class="invalid-tooltip">
                                        <div
                                            *ngIf="
                                                (form.touched ||
                                                    getCompanyContactOfSectionField(i, j, 'email')
                                                        .touched) &&
                                                getCompanyContactOfSectionField(i, j, 'email')
                                                    .errors &&
                                                getCompanyContactOfSectionField(i, j, 'email')
                                                    .errors['email']
                                            "
                                        >
                                            Ce champ doit être un email valide.
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="list-group-item text-center">
                                <button
                                    type="button"
                                    (click)="addContactToCategory(i)"
                                    class="btn btn-secondary"
                                    [disabled]="form.disabled"
                                >
                                    + Nouveau contact
                                </button>
                            </li>
                        </ul>
                    </li>
                    <li class="list-group-item text-center">
                        <button
                            type="button"
                            (click)="addContactCategory()"
                            class="btn btn-secondary"
                            [disabled]="form.disabled"
                        >
                            + Nouveau service
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-between mt-4">
        <button
            type="button"
            [routerLink]="airlineId ? '/admin/airlines/' + airlineId : '/admin/airlines'"
            class="btn btn-secondary btn-block mb-4"
            [disabled]="form.disabled"
        >
            Annuler
        </button>
        <button
            type="submit"
            class="btn btn-primary btn-block mb-4"
            [disabled]="form.disabled || !form.valid"
        >
            Sauvegarder
        </button>
    </div>
</form>

<div class="modal" tabindex="-1" role="dialog" #modalUpload>
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Envoie d'un document au serveur</h5>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <input
                        type="file"
                        #inputFile
                        class="form-control"
                        (change)="changeInputFile($event)"
                    />
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                    Annuler
                </button>
                <button type="button" class="btn btn-primary" (click)="upload()">Envoie</button>
            </div>
        </div>
    </div>
</div>
