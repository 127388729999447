import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EnquiryViewComponent } from './admin/enquiries/enquiry-view/enquiry-view.component';
import { Error404Component } from './error404/error404.component';
import { LoginComponent } from './login/login.component';
import { NotAuthorisedComponent } from './not-authorised/not-authorised.component';
import { PasswordForgottenComponent } from './password-forgotten/password-forgotten.component';
import { AuthGuardService } from './services/auth-guard.service';
import { SetPasswordComponent } from './set-password/set-password.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'password-forgotten', component: PasswordForgottenComponent },
  { path: 'set-password', component: SetPasswordComponent },
  { path: 'public', children: [{ path: 'enquiry/:enquiryId', component: EnquiryViewComponent }] },
  {
    path: 'admin',
    loadChildren: () => import('./admin-routing.module').then(m => m.AdminRoutingModule),
    canActivate: [AuthGuardService]
  },
  { path: 'not-authorised', component: NotAuthorisedComponent },
  {
    path: '**',
    component: Error404Component
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
