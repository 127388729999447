import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { faSuitcaseRolling, faUsers, faUtensils } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { format } from 'date-fns';
import { fr, enUS } from 'date-fns/locale';
import { Subscription } from 'rxjs';
import { EnumEnquiryType } from 'src/app/enums/enquiry-type.enum';
import { EnumLanguage } from 'src/app/enums/language.enum';
import {
  IAirport,
  getAirportCity,
  getAirportUTCTimeString
} from 'src/app/interfaces/airport.interface';
import { IEnquiry } from 'src/app/interfaces/enquiry.interface';
import {
  IFlightBrief,
  IFlightBriefAirportsAddressDetails,
  IFlightBriefTrip,
  getPassengerLabel,
  getTimeBoardingBeforeDeparture
} from 'src/app/interfaces/flight-brief.interface';

@Component({
  selector: 'app-flight-brief-page',
  templateUrl: './flight-brief-page.component.html',
  styleUrls: ['./flight-brief-page.component.scss']
})
export class FlightBriefPageComponent implements OnInit, OnDestroy {
  @Input() flightBrief: IFlightBrief | null = null;
  @Input() enquiry: IEnquiry | null = null;
  @Input() trips: IFlightBriefTrip[] = [];
  @Input() airportsObj: { [key: string]: IAirport } = {};

  faUsers = faUsers;
  faUtensils = faUtensils;
  faSuitcaseRolling = faSuitcaseRolling;

  translationObj: { [key: string]: any } = {};

  getAirportUTCTimeString = getAirportUTCTimeString;
  getTimeBoardingBeforeDeparture = getTimeBoardingBeforeDeparture;
  getAirportCity = getAirportCity;
  getPassengerLabel = getPassengerLabel;

  EnumLanguage = EnumLanguage;
  EnumEnquiryType = EnumEnquiryType;

  imgsToBase64: {
    googleMapStaticUrls: { [key: string]: string };
    imageOutsideUrl: string | null;
    imageInsideUrl: string | null;
  } = {
    googleMapStaticUrls: {},
    imageOutsideUrl: null,
    imageInsideUrl: null
  };

  subscriptionsTranslations = new Subscription();

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.loadTranslation();

    this.loadAllImgBase64();
  }

  ngOnDestroy(): void {
    this.subscriptionsTranslations.unsubscribe();
  }

  loadTranslation(): void {
    if (this.flightBrief) {
      if (this.subscriptionsTranslations) {
        this.subscriptionsTranslations.unsubscribe();
      }

      this.subscriptionsTranslations = new Subscription();

      this.subscriptionsTranslations.add(
        this.translateService
          .getTranslation(this.flightBrief.language)
          .subscribe((translationObj: any) => {
            this.translationObj[this.flightBrief.language] = translationObj;
          })
      );
    }
  }

  getDisplayedAirportsAddressDetails(trip: IFlightBriefTrip): IFlightBriefAirportsAddressDetails[] {
    const airportsId: string[] = [];
    const airportsAddressDetails: IFlightBriefAirportsAddressDetails[] = [];

    for (const field of ['airportDepartId', 'airportArrivalId']) {
      if (trip[field] && !airportsId.includes(trip[field])) {
        airportsId.push(trip[field]);
      }
    }

    for (const airportAddressDetails of this.flightBrief?.airportsAddressDetails) {
      if (airportsId.includes(airportAddressDetails.airportId)) {
        airportAddressDetails.text = airportAddressDetails.text.replaceAll(' Adresse :', '');

        airportsAddressDetails.push(airportAddressDetails);
      }
    }

    return airportsAddressDetails;
  }

  formatTripDate(dateStr: string): string {
    dateStr = format(new Date(dateStr), 'EEEE dd MMMM yyyy', {
      locale: this.flightBrief?.language === 'fr' ? fr : enUS
    });

    const dateSplitted: string[] = dateStr.split(' ');

    for (let i in dateSplitted) {
      dateSplitted[i] = this.capitalizeFirstLetter(dateSplitted[i]);
    }

    return dateSplitted.join(' ');
  }

  private capitalizeFirstLetter(text: string): string {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  async loadAllImgBase64(): Promise<void> {
    try {
      for (const imgFieldName in this.imgsToBase64) {
        if (imgFieldName === 'googleMapStaticUrls') {
          this.imgsToBase64.googleMapStaticUrls = {};
          for (const trip of this.flightBrief.trips) {
            if (trip.googleMapStaticUrl) {
              this.imgsToBase64.googleMapStaticUrls[trip.id] =
                await this.loadImgBase64FromRemoteUrl(trip.googleMapStaticUrl);
            }
          }
        } else {
          if (this.flightBrief[imgFieldName]) {
            this.imgsToBase64[imgFieldName] = await this.loadImgBase64FromRemoteUrl(
              this.flightBrief[imgFieldName]
            );
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  loadImgBase64FromRemoteUrl(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      var xhr = new XMLHttpRequest();

      xhr.responseType = 'arraybuffer';
      xhr.open('GET', url);

      xhr.onload = () => {
        var base64, binary, bytes, mediaType;

        bytes = new Uint8Array(xhr.response);
        //NOTE String.fromCharCode.apply(String, ...
        //may cause "Maximum call stack size exceeded"
        binary = [].map
          .call(bytes, byte => {
            return String.fromCharCode(byte);
          })
          .join('');
        mediaType = xhr.getResponseHeader('content-type');
        base64 = ['data:', mediaType ? mediaType + ';' : '', 'base64,', btoa(binary)].join('');
        resolve(base64);
      };
      xhr.onerror = err => {
        reject(err);
      };
      xhr.send();
    });
  }
}
