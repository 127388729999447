import {
  Component,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef
} from '@angular/core';

import { RemoteService } from '../../services/remote.service';

import countries from '../../countries_fr.json';
import countriesFull from '../../countries_full.json';
import { IAirline } from 'src/app/interfaces/airline.interface';
import { getCountryLabel, getContinentLabel } from 'src/app/enums/continent-code.enum';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'airline-input',
  templateUrl: './airline-input.component.html',
  styleUrls: ['./airline-input.component.scss']
})
export class AirlineInputComponent implements OnChanges {
  @Input('disabled') disabled: boolean = false;
  @Input('label') label: string;
  @Input('airlineId') airlineId: string;
  @Input('airlineTitle') airlineTitle: string;
  @Input('airlineCountryCode') airlineCountryCode: string;
  @Input('airlineContinentCode') airlineContinentCode: string;
  @Input('airlineCountryTitle') airlineCountryTitle: string;
  @Input('airlineContinentTitle') airlineContinentTitle: string;

  airlinesList: IAirline[] = [];
  countriesList: Array<{
    title: string;
    value: string;
  }> = [];
  loadingAirlines: boolean = false;
  @Output() setValueToFormControl: EventEmitter<{
    fields: Array<{
      name: string;
      value: string;
    }>;
  }> = new EventEmitter();
  @Input('inputNameAirlineId') inputNameAirlineId: string;
  @Input('inputNameAirlineTitle') inputNameAirlineTitle: string;
  @Input('inputNameAirlineCountryCode') inputNameAirlineCountryCode: string;
  @Input('inputNameAirlineContinentCode') inputNameAirlineContinentCode: string;
  @Input('inputNameAirlineCountryTitle') inputNameAirlineCountryTitle: string;
  @Input('inputNameAirlineContinentTitle') inputNameAirlineContinentTitle: string;

  faXmark = faXmark;

  constructor(private remoteService: RemoteService) {
    for (const code in countries) {
      this.countriesList.push({
        title: countries[code],
        value: code
      });
    }
  }

  ngOnChanges() {}

  async loadActiveAirlineFromCountry(countryCode: string): Promise<void> {
    this.loadingAirlines = true;

    this.airlinesList = await this.remoteService.getActiveAirlinesFromCountry(countryCode);

    this.loadingAirlines = false;
  }

  updateAirlineCountry(): void {
    for (const country of countriesFull) {
      if (country['ISO3166-1-Alpha-2'] === this.airlineCountryCode) {
        this.airlineContinentCode = country.Continent;
      }
    }

    this.updateFormValue();

    this.loadActiveAirlineFromCountry(this.airlineCountryCode);
  }

  updatedAirline(): void {
    if (this.airlineId) {
      for (const airline of this.airlinesList) {
        if (airline.id === this.airlineId) {
          this.airlineTitle = airline.title;
          this.airlineCountryCode = airline.countryCode;
          this.airlineContinentCode = airline.continentCode;
          this.airlineCountryTitle = getCountryLabel(airline.countryCode);
          this.airlineContinentTitle = getContinentLabel(airline.continentCode);

          this.updateFormValue();

          break;
        }
      }
    }
  }

  updateFormValue(): void {
    this.setValueToFormControl.emit({
      fields: [
        {
          name: this.inputNameAirlineId,
          value: this.airlineId
        },
        {
          name: this.inputNameAirlineTitle,
          value: this.airlineTitle
        },
        {
          name: this.inputNameAirlineCountryCode,
          value: this.airlineCountryCode
        },
        {
          name: this.inputNameAirlineContinentCode,
          value: this.airlineContinentCode
        },
        {
          name: this.inputNameAirlineCountryTitle,
          value: this.airlineCountryTitle
        },
        {
          name: this.inputNameAirlineContinentTitle,
          value: this.airlineContinentTitle
        }
      ]
    });
  }

  clearValue(): void {
    this.airlineId = null;
    this.airlineTitle = null;
    this.airlineCountryCode = null;
    this.airlineContinentCode = null;
    this.airlineCountryTitle = null;
    this.airlineContinentTitle = null;

    this.updateFormValue();
  }
}
