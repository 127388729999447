import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FboEditModule } from 'src/app/admin/fbos/fbo-edit/fbo-edit.module';
import { HandlingEditModule } from 'src/app/admin/handlings/handling-edit/handling-edit.module';
import { CrewEditModule } from 'src/app/admin/crews/crew-edit/crew-edit.module';
import { AirlineSupervisorEditModule } from 'src/app/admin/airline-supervisors/airline-supervisor-edit/airline-supervisor-edit.module';
import { AirportPickerModule } from '../../airport-picker/airport-picker.module';
import { AirlineAutocompleteModule } from '../../airline-autocomplete/airline-autocomplete.module';
import { EnquiryFlightEditCargoComponent } from './enquiry-flight-edit-cargo.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    AirportPickerModule,
    FboEditModule,
    HandlingEditModule,
    CrewEditModule,
    AirlineSupervisorEditModule,
    AirlineAutocompleteModule
  ],
  declarations: [EnquiryFlightEditCargoComponent],
  exports: [EnquiryFlightEditCargoComponent]
})
export class EnquiryFlightEditCargoModule {}
