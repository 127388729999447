import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import firebase from 'firebase/compat/app';
import { FboFirestoreService } from './fbos-firestore.service';
import { IFbo } from 'src/app/interfaces/fbo.interface';

@Injectable({
  providedIn: 'root'
})
export class FboService {
  constructor(private fboFirestoreService: FboFirestoreService) {}

  create(Fbo: IFbo): Promise<string> {
    return this.fboFirestoreService.create(Fbo);
  }

  createWithId(id: string, Fbo: IFbo): Promise<string> {
    return this.fboFirestoreService.createWithId(id, Fbo);
  }

  update(Fbo: IFbo): Promise<string> {
    return this.fboFirestoreService.update(Fbo);
  }

  delete(id: string): Promise<void> {
    return this.fboFirestoreService.delete(id);
  }

  getFromId(id: string): Observable<IFbo> {
    return this.fboFirestoreService.docValue$(id);
  }

  getAll(): Observable<IFbo[]> {
    return this.fboFirestoreService.collectionValues$();
  }

  resetPagination(paginationName: string = 'list'): void {
    this.fboFirestoreService.resetPaginate(paginationName);
  }

  getAllMostRecentFirst(
    paginationName: string = 'list',
    conditions: {
      field: string;
      operator: firebase.firestore.WhereFilterOp;
      value: any;
    }[] = []
  ): Observable<IFbo[]> {
    const orderBy: {
      field: string;
      direction: 'asc' | 'desc';
    } = {
      field: 'modified',
      direction: 'desc'
    };

    return this.fboFirestoreService.paginate(conditions, orderBy, 20, paginationName);
  }

  getForAirport(airportId: string): Observable<IFbo[]> {
    return this.fboFirestoreService.collectionValues$(ref =>
      ref.where('airportId', '==', airportId)
    );
  }
}
