import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  faEdit,
  faPlus,
  faPlusCircle,
  faTableColumns,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import { format, getMonth, getYear, isAfter, isBefore, isFuture, isSameDay, parse } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Subscription } from 'rxjs';
import { EnumAcl } from 'src/app/enums/acl.enum';
import {
  EnumCurrency,
  getDefaultCurrency,
  getEnumCurrencyLabel,
  getEnumCurrencySymbol,
  getEnumCurrencySymbolForExcel
} from 'src/app/enums/currency.enum';
import {
  EnumEnquiryCommissionStatus,
  getEnumEnquiryCommissionStatusLabel
} from 'src/app/enums/enquiry-commission.enum';
import {
  EnumEnquiryPaymentStatus,
  getEnumEnquiryPaymentStatusLabel
} from 'src/app/enums/enquiry-payment-summary-status.enum';
import {
  EnumEnquiryType,
  getEnquiryTypeFromEnquiryRef,
  getEnumEnquiryTypeLabel
} from 'src/app/enums/enquiry-type.enum';
import { IEnquiryPaymentSummary } from 'src/app/interfaces/enquiry-payment-summary.interface';
import { IEnquiry } from 'src/app/interfaces/enquiry.interface';
import { IFinancialReport } from 'src/app/interfaces/financial-report.interface';
import { IUser, getUserFullname } from 'src/app/interfaces/user.interface';
import { AclService } from 'src/app/services/acl.service';
import { EnquiryPaymentSummaryService } from 'src/app/services/enquiry-payment-summaries/enquiry-payment-summaries.service';
import { EnquiryService } from 'src/app/services/enquiry/enquiry.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { RemoteService } from 'src/app/services/remote.service';
import { UserService } from 'src/app/services/user/user.service';
import slugify from 'slugify';
import { saveAs } from 'file-saver';

import Excel, { Cell, CellValue, Column, Worksheet } from 'exceljs';
import { addZeroToDigit, getQuarterNumberAccordingToMonth } from 'src/app/misc.utils';
import {
  IEnquiryPaymentSummaryDetail,
  IEnquiryPaymentSummaryDetailTemporaryRow
} from 'src/app/interfaces/enquiry-payment-summary-detail.interface';
import { EnquiryPaymentSummaryDetailService } from 'src/app/services/enquiry-payment-summary-details/enquiry-payment-summary-details.service';
import { IEncaissementType } from 'src/app/interfaces/encaissement-type.interface';
import { EncaissementTypeService } from 'src/app/services/encaissement-types/encaissement-types.service';

enum EnumLocalStorageVariable {
  columnsSettings = 'simplyfly.enquiryPaymentSummaries.columnsSettings'
}

enum EnumColumnSearch {
  text = 'text',
  date = 'date',
  number = 'number',
  select = 'select',
  users = 'users',
  enquiryTypes = 'enquiryTypes',
  boolean = 'boolean'
}
enum EnumColumnSearchOperator {
  equal = '=',
  superiorAndEqual = '>=',
  superiorOnly = '>',
  inferiorAndEqual = '<=',
  inferiorOnly = '<'
}
enum EnumExportColumnType {
  text = 'text',
  number = 'number',
  currency = 'currency',
  percentage = 'percentage',
  date = 'date',
  boolean = 'boolean'
}

const getColumnSearchOperatorLabel = (searchOperator: EnumColumnSearchOperator): string => {
  switch (searchOperator) {
    default:
      return searchOperator;
  }
};

interface IEnquiryPaymentRow {
  enquiryPaymentSummary: IEnquiryPaymentSummary;
  enquiryPaymentSummaryDetail: IEnquiryPaymentSummaryDetail | null;
  previousIsSameEnquiry: boolean;
  lastRowForEnquiry: boolean;
  rowspan: number;
  mainStatus: {
    status: EnumEnquiryPaymentStatus;
    amount: number;
  };
  statuses: {
    status: EnumEnquiryPaymentStatus;
    currency: EnumCurrency | null;
    amount: number;
  }[];
  invoiceAmountHtTotal: {
    defaultCurrency: number;
    values: { currency: EnumCurrency; value: number }[];
  };
}

interface IColumn {
  title: string;
  field: string;
  searchType?: EnumColumnSearch;
  query?: string | number | null;
  operator?: EnumColumnSearchOperator;
  options?: {
    label: string;
    value: string;
  }[];
  hide?: boolean;
  exportType?: EnumExportColumnType;
  mergeIfSameEnquiry?: boolean;
  visibleOnlyExport?: boolean;
}

@Component({
  selector: 'app-enquiry-payment-summaries-table',
  templateUrl: './enquiry-payment-summaries-table.component.html',
  styleUrls: ['./enquiry-payment-summaries-table.component.scss']
})
export class EnquiryPaymentSummariesTableComponent implements OnInit, OnDestroy {
  @Input() userId: string | null = null;
  @ViewChild('modalCommission', { static: false }) modalCommissionElement: ElementRef;
  @ViewChild('modalDetail', { static: false }) modalDetailElement: ElementRef;
  @ViewChild('modalTemporaryRow', { static: false }) modalTemporaryRowElement: ElementRef;

  loading: boolean = false;
  loadingTotal: boolean = false;
  EnumAcl = EnumAcl;
  faEdit = faEdit;
  faPlus = faPlus;
  faPlusCircle = faPlusCircle;
  faTableColumns = faTableColumns;
  faTrash = faTrash;
  currentUser: IUser | null = null;
  user: IUser | null = null;
  users: IUser[] = [];
  loadingAllUsers: boolean = false;

  columns: IColumn[] = [
    {
      title: 'N° contrat',
      field: 'enquiryPaymentSummary.enquiryRefContractTitle',
      searchType: EnumColumnSearch.text,
      query: null,
      mergeIfSameEnquiry: true
    },
    {
      title: 'Commentaire',
      field: 'enquiryPaymentSummaryDetail.comment',
      searchType: EnumColumnSearch.text,
      exportType: EnumExportColumnType.text,
      query: null,
      mergeIfSameEnquiry: false
    },
    {
      title: 'Dossier clotûré ?',
      field: 'enquiryPaymentSummary.enquiryIsCompleted',
      searchType: EnumColumnSearch.boolean,
      exportType: EnumExportColumnType.boolean,
      query: null,
      mergeIfSameEnquiry: true
    },
    {
      title: 'Commission',
      field: 'enquiryPaymentSummary.enquiryCommissionFee',
      searchType: EnumColumnSearch.number,
      query: null,
      operator: EnumColumnSearchOperator.equal,
      exportType: EnumExportColumnType.percentage,
      mergeIfSameEnquiry: true
    },
    {
      title: 'Montant commission',
      field: 'enquiryPaymentSummary.enquiryCommissionFeeAmount',
      searchType: EnumColumnSearch.number,
      query: null,
      operator: EnumColumnSearchOperator.equal,
      exportType: EnumExportColumnType.currency,
      mergeIfSameEnquiry: true,
      visibleOnlyExport: true
    },
    {
      title: 'Dates de départ',
      field: 'enquiryPaymentSummary.legDates',
      searchType: EnumColumnSearch.date,
      query: null,
      operator: EnumColumnSearchOperator.equal,
      exportType: EnumExportColumnType.date,
      mergeIfSameEnquiry: true
    },
    {
      title: 'Courtiers',
      field: 'enquiryPaymentSummary.userInitials',
      searchType: EnumColumnSearch.users,
      query: null,
      mergeIfSameEnquiry: true
    },
    {
      title: 'Département',
      field: 'enquiryPaymentSummary.enquiryType',
      searchType: EnumColumnSearch.enquiryTypes,
      query: null,
      mergeIfSameEnquiry: true
    },
    {
      title: 'Clients',
      field: 'enquiryPaymentSummary.clientName',
      searchType: EnumColumnSearch.text,
      query: null,
      mergeIfSameEnquiry: true
    },
    {
      title: 'Compagnies aériennes',
      field: 'enquiryPaymentSummary.airlineTitles',
      searchType: EnumColumnSearch.text,
      query: null,
      mergeIfSameEnquiry: true
    },
    {
      title: 'N° factures',
      field: 'enquiryPaymentSummary.invoiceRef',
      searchType: EnumColumnSearch.text,
      query: null
    },
    {
      title: 'Entreprise',
      field: 'enquiryPaymentSummary.invoiceClientName',
      searchType: EnumColumnSearch.text,
      query: null
    },
    {
      title: 'Date Emission Facture',
      field: 'enquiryPaymentSummary.invoiceIssueDate',
      searchType: EnumColumnSearch.date,
      query: null,
      operator: EnumColumnSearchOperator.equal,
      exportType: EnumExportColumnType.date
    },
    {
      title: 'Montants HT',
      field: 'enquiryPaymentSummary.invoiceAmountHtTotal',
      searchType: EnumColumnSearch.number,
      query: null,
      operator: EnumColumnSearchOperator.equal,
      exportType: EnumExportColumnType.currency
    },
    {
      title: 'TVA',
      field: 'enquiryPaymentSummary.invoiceAmountTvaTotal',
      searchType: EnumColumnSearch.number,
      query: null,
      operator: EnumColumnSearchOperator.equal,
      exportType: EnumExportColumnType.currency
    },
    {
      title: 'Montants TTC',
      field: 'enquiryPaymentSummary.invoiceAmountTtcTotal',
      searchType: EnumColumnSearch.number,
      query: null,
      operator: EnumColumnSearchOperator.equal,
      exportType: EnumExportColumnType.currency
    },
    {
      title: "Prix d'achat TC et CC",
      field: 'enquiryPaymentSummary.enquiryPriceNetTTC',
      searchType: EnumColumnSearch.number,
      query: null,
      operator: EnumColumnSearchOperator.equal,
      exportType: EnumExportColumnType.currency,
      mergeIfSameEnquiry: true
    },
    {
      title: 'Prix de vente TC et CC',
      field: 'enquiryPaymentSummary.enquirySellingPrice',
      searchType: EnumColumnSearch.number,
      query: null,
      operator: EnumColumnSearchOperator.equal,
      exportType: EnumExportColumnType.currency,
      mergeIfSameEnquiry: true
    },
    {
      title: 'Marges prévisionnelles HT',
      field: 'enquiryPaymentSummary.enquiryMargin',
      searchType: EnumColumnSearch.number,
      query: null,
      operator: EnumColumnSearchOperator.equal,
      exportType: EnumExportColumnType.currency,
      mergeIfSameEnquiry: true
    },
    {
      title: 'Marges réelles HT',
      field: 'invoiceAmountHtTotal',
      searchType: EnumColumnSearch.number,
      query: null,
      operator: EnumColumnSearchOperator.equal,
      exportType: EnumExportColumnType.currency,
      mergeIfSameEnquiry: true
    },
    {
      title: 'Dénomination',
      field: 'enquiryPaymentSummary.invoiceProductsLabel',
      searchType: EnumColumnSearch.text,
      query: null
    },
    {
      title: 'Routing',
      field: 'enquiryPaymentSummary.enquiryRouting',
      searchType: EnumColumnSearch.text,
      query: null
    },
    {
      title: 'Echéancié',
      field: 'enquiryPaymentSummary.invoiceDueDate',
      searchType: EnumColumnSearch.date,
      query: null,
      operator: EnumColumnSearchOperator.equal,
      exportType: EnumExportColumnType.date
    },
    {
      title: 'Paiement',
      field: 'enquiryPaymentSummary.encaissementsAmount',
      searchType: EnumColumnSearch.number,
      query: null,
      operator: EnumColumnSearchOperator.equal,
      exportType: EnumExportColumnType.currency
    },
    {
      title: 'MOD',
      field: 'enquiryPaymentSummary.encaissementsTypeName',
      searchType: EnumColumnSearch.text,
      query: null
    },
    {
      title: 'Date de paiement',
      field: 'enquiryPaymentSummary.encaissementsDate',
      searchType: EnumColumnSearch.date,
      query: null,
      operator: EnumColumnSearchOperator.equal,
      exportType: EnumExportColumnType.date
    }
  ];

  enquiryPaymentSummaries: IEnquiryPaymentSummary[] = [];

  filtersByPeriod: {
    title: string;
    value: string;
  }[] = [];
  filterByPeriod: string = format(new Date(), 'yyyy-MM') + '-01';

  enquiryPaymentSummaryDetails: IEnquiryPaymentSummaryDetail[] = [];
  enquiryPaymentSummaryDetailsObj: {
    [enquiryId: string]: IEnquiryPaymentSummaryDetail;
  } = {};
  loadingEnquiryPaymentSummaryDetails: boolean = false;

  EnumEnquiryPaymentStatus = EnumEnquiryPaymentStatus;
  EnumEnquiryType = EnumEnquiryType;
  EnumColumnSearch = EnumColumnSearch;
  EnumEnquiryCommissionStatus = EnumEnquiryCommissionStatus;

  getEnumEnquiryPaymentStatusLabel = getEnumEnquiryPaymentStatusLabel;
  getColumnSearchOperatorLabel = getColumnSearchOperatorLabel;
  getDefaultCurrency = getDefaultCurrency;
  getEnumCurrencySymbol = getEnumCurrencySymbol;
  getEnumEnquiryCommissionStatusLabel = getEnumEnquiryCommissionStatusLabel;
  getUserFullname = getUserFullname;
  getEnumCurrencyLabel = getEnumCurrencyLabel;
  getEnumEnquiryTypeLabel = getEnumEnquiryTypeLabel;

  data: IEnquiryPaymentRow[] = [];

  dataDisplayed: IEnquiryPaymentRow[] = [];

  subscriptions = new Subscription();
  subscriptionsDetails = new Subscription();
  subscriptionsFirstAndLast = new Subscription();
  subscriptionsTotal = new Subscription();

  selectedEnquiryPaymentSummary: IEnquiryPaymentSummary | null = null;

  formCommission: FormGroup = new FormGroup({
    commissionFee: new FormControl(null),
    commissionStatus: new FormControl(null),
    commissionComment: new FormControl(null),
    isCommissionFeePercentage: new FormControl(true),
    commissionFeeAmount: new FormControl(null)
  });
  sendingCommission: boolean = false;

  formDetail: FormGroup = new FormGroup({
    comment: new FormControl(null)
  });

  formTemporaryRow: FormGroup = new FormGroup({
    invoiceRef: new FormControl(null),
    invoiceClientName: new FormControl(null),
    invoiceIssueDate: new FormControl(null),
    invoiceAmountHtTotal: new FormControl(null),
    invoiceAmountTvaTotal: new FormControl(null),
    invoiceAmountTtcTotal: new FormControl(null),
    invoiceProductsLabel: new FormControl(null),
    invoiceCurrency: new FormControl(getDefaultCurrency()),
    invoiceDueDate: new FormControl(null),
    encaissementsAmount: new FormControl(null),
    encaissementsTypeId: new FormControl(null),
    encaissementsDate: new FormControl(null)
  });

  columnsSettings: { allColumns: boolean; columnSelected: string[] } = {
    allColumns: true,
    columnSelected: []
  };

  firstEnquiryPaymentSummary: IEnquiryPaymentSummary | null = null;
  lastEnquiryPaymentSummary: IEnquiryPaymentSummary | null = null;

  selectedRow: IEnquiryPaymentRow | null = null;
  selectedTemporaryRowIndex: number | null = null;

  encaissementTypes: IEncaissementType[] = [];
  encaissementTypesObj: { [id: string]: IEncaissementType } = {};
  loadingEncaissementTypes: boolean = false;

  constructor(
    private enquiryPaymentSummaryService: EnquiryPaymentSummaryService,
    private enquiryPaymentSummaryDetailService: EnquiryPaymentSummaryDetailService,
    private aclService: AclService,
    private enquiryService: EnquiryService,
    private loaderService: LoaderService,
    private remoteService: RemoteService,
    private userService: UserService,
    private encaissementTypeService: EncaissementTypeService
  ) {
    const statusesOptions: {
      label: string;
      value: string;
    }[] = [];

    for (const value of Object.values(EnumEnquiryPaymentStatus)) {
      statusesOptions.push({
        label: getEnumEnquiryPaymentStatusLabel(value),
        value
      });
    }

    this.columns.push({
      title: 'Status paiement',
      field: 'statuses.status',
      searchType: EnumColumnSearch.select,
      query: null,
      options: statusesOptions,
      mergeIfSameEnquiry: true
    });

    this.generateFilterPeriodOptions();
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.remoteService.userObservable.subscribe((user: IUser) => {
        this.currentUser = user;
      })
    );

    for (const column of this.columns) {
      if (column.field === 'enquiryPaymentSummary.userInitials') {
        column.hide = !!this.userId;
      }
    }

    this.loadData();

    if (this.userId) {
      this.loadUser(this.userId);
    }

    this.updateColumnSettingsFromCache();
    this.loadAllUsers();
  }

  getCurrencies(): EnumCurrency[] {
    return Object.values(EnumCurrency);
  }

  getEnquiryTypes(): EnumEnquiryType[] {
    return Object.values(EnumEnquiryType);
  }

  updateTooltip(): void {
    window['$']('.tooltip').remove();

    setTimeout(() => {
      window['$']('[rel="tooltip"]').tooltip({
        html: true,
        boundary: 'window'
      });
    }, 1000);
  }

  ngOnDestroy(): void {
    window['$']('.tooltip').remove();
    window['$']('.table-responsive').floatingScroll('destroy');

    this.subscriptions.unsubscribe();
    this.subscriptionsDetails.unsubscribe();
    this.subscriptionsTotal.unsubscribe();
    this.subscriptionsFirstAndLast.unsubscribe();

    this.removeModal();
  }

  getSearchOperators(): EnumColumnSearchOperator[] {
    return Object.values(EnumColumnSearchOperator);
  }

  getEnquiryCommissionStatuses(): EnumEnquiryCommissionStatus[] {
    return Object.values(EnumEnquiryCommissionStatus);
  }

  updateFloatingScroll(): void {
    window['$']('.table-responsive').floatingScroll('init', {
      orientation: 'horizontal'
    });
  }

  loadAllUsers(): void {
    this.loadingAllUsers = true;

    this.subscriptions.add(
      this.userService.getAll().subscribe((users: IUser[]) => {
        this.users = users;

        this.users.sort((a, b) => (a.initials < b.initials ? -1 : 1));

        this.loadingAllUsers = false;
      })
    );
  }

  generateFilterPeriodOptions(): void {
    this.filtersByPeriod = [];

    const currentMonth: number = getMonth(new Date()) + 1;
    const minMonth: number = this.firstEnquiryPaymentSummary
      ? getMonth(new Date(this.firstEnquiryPaymentSummary.periodMonth)) + 1
      : 1;
    const maxMonth: number = this.lastEnquiryPaymentSummary
      ? getMonth(new Date(this.lastEnquiryPaymentSummary.periodMonth)) + 1
      : currentMonth;

    const currentYear: number = getYear(new Date());
    const minYear: number = this.firstEnquiryPaymentSummary
      ? this.firstEnquiryPaymentSummary.periodYear
      : 2021;
    const maxYear: number = this.lastEnquiryPaymentSummary
      ? this.lastEnquiryPaymentSummary.periodYear
      : currentYear;

    const currentQuarter: number = getQuarterNumberAccordingToMonth(getMonth(new Date()) + 1);
    const minQuarter: number = this.firstEnquiryPaymentSummary
      ? parseInt(
          this.firstEnquiryPaymentSummary.periodQuarter.replace(
            this.firstEnquiryPaymentSummary.periodYear + '-q',
            ''
          )
        )
      : 1;
    const maxQuarter: number = this.lastEnquiryPaymentSummary
      ? parseInt(
          this.lastEnquiryPaymentSummary.periodQuarter.replace(
            this.lastEnquiryPaymentSummary.periodYear + '-q',
            ''
          )
        )
      : currentQuarter;

    for (let year = maxYear; year >= minYear; year--) {
      for (let i = 12; i >= 1; i--) {
        if (!(year === maxYear && i > maxMonth) && !(year === minYear && i < minMonth)) {
          const date: Date = new Date(year + '-' + addZeroToDigit(i) + '-01');
          let title: string = format(date, 'MMMM yy', {
            locale: fr
          });
          title = title.charAt(0).toUpperCase() + title.slice(1);

          this.filtersByPeriod.push({
            title,
            value: format(date, 'yyyy-MM-01')
          });
        }
      }
    }

    for (let year = maxYear; year >= minYear; year--) {
      for (let i = 4; i >= 1; i--) {
        if (!(year === maxYear && i > maxQuarter) && !(year === minYear && i < minQuarter)) {
          this.filtersByPeriod.push({
            title: 'Q' + i + ' ' + year,
            value: 'quarter-' + year + '-' + i
          });
        }
      }
    }

    for (let year = maxYear; year >= minYear; year--) {
      this.filtersByPeriod.push({
        title: 'Année ' + year,
        value: 'year-' + year
      });
    }
  }

  loadData(): void {
    this.loadEnquiryPaymentSummaries();
    this.loadEnquiryPaymentSummaryDetails();
    this.getFirstAndLastEnquiryPaymentSummary();
    this.loadEncaissementTypes();
    // this.resetData();
  }

  loadEnquiryPaymentSummaries(): void {
    this.loading = true;

    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }

    this.subscriptions = new Subscription();

    const splittedFilterByPeriod: string[] = this.filterByPeriod.split('-');

    let promise;

    if (this.userId) {
      switch (splittedFilterByPeriod[0]) {
        case 'quarter':
          promise = () =>
            this.enquiryPaymentSummaryService.getAllOfQuarterForUser(
              parseInt(splittedFilterByPeriod[1]),
              parseInt(splittedFilterByPeriod[2]),
              this.userId
            );

          break;
        case 'year':
          promise = () =>
            this.enquiryPaymentSummaryService.getAllOfYearForUser(
              parseInt(splittedFilterByPeriod[1]),
              this.userId
            );
          break;
        default:
          promise = () =>
            this.enquiryPaymentSummaryService.getAllOfMonthForUser(
              parseInt(splittedFilterByPeriod[0]),
              parseInt(splittedFilterByPeriod[1]),
              this.userId
            );
          break;
      }
    } else {
      switch (splittedFilterByPeriod[0]) {
        case 'quarter':
          promise = () =>
            this.enquiryPaymentSummaryService.getAllOfQuarter(
              parseInt(splittedFilterByPeriod[1]),
              parseInt(splittedFilterByPeriod[2])
            );

          break;
        case 'year':
          promise = () =>
            this.enquiryPaymentSummaryService.getAllOfYear(parseInt(splittedFilterByPeriod[1]));
          break;
        default:
          promise = () =>
            this.enquiryPaymentSummaryService.getAllOfMonth(
              parseInt(splittedFilterByPeriod[0]),
              parseInt(splittedFilterByPeriod[1])
            );
          break;
      }
    }

    if (promise) {
      this.subscriptions.add(
        promise().subscribe((enquiryPaymentSummaries: IEnquiryPaymentSummary[]) => {
          this.enquiryPaymentSummaries = enquiryPaymentSummaries;

          this.processEnquiryPaymentSummaries();
        })
      );
    }
  }

  processEnquiryPaymentSummaries(): void {
    this.data = [];

    const enquiryPaymentSummariesByEnquiry: { [key: string]: IEnquiryPaymentSummary[] } = {};

    for (const enquiryPaymentSummary of this.enquiryPaymentSummaries) {
      if (
        typeof enquiryPaymentSummariesByEnquiry[enquiryPaymentSummary.enquiryId] === 'undefined'
      ) {
        enquiryPaymentSummariesByEnquiry[enquiryPaymentSummary.enquiryId] = [];
      }

      enquiryPaymentSummariesByEnquiry[enquiryPaymentSummary.enquiryId].push(enquiryPaymentSummary);
    }

    let previousEnquiryId: string | null = null;
    for (const enquiryId in enquiryPaymentSummariesByEnquiry) {
      let firstIndexForEnquiry: number | null = null;
      let invoiceAmountHtTotal: { [key: string]: number } = {
        defaultCurrency: 0
      };
      let totalInvoiced: { [key: string]: number } = { defaultCurrency: 0 };
      let totalPayed: { [key: string]: number } = { defaultCurrency: 0 };

      for (let i = 0; i < enquiryPaymentSummariesByEnquiry[enquiryId].length; i++) {
        const previousIsSameEnquiry: boolean = previousEnquiryId === enquiryId;

        this.data.push({
          enquiryPaymentSummary: enquiryPaymentSummariesByEnquiry[enquiryId][i],
          enquiryPaymentSummaryDetail: this.enquiryPaymentSummaryDetailsObj[enquiryId] ?? null,
          previousIsSameEnquiry: previousIsSameEnquiry,
          lastRowForEnquiry: i === enquiryPaymentSummariesByEnquiry[enquiryId].length - 1,
          rowspan: previousIsSameEnquiry ? 1 : enquiryPaymentSummariesByEnquiry[enquiryId].length,
          mainStatus: {
            status: EnumEnquiryPaymentStatus.noInvoice,
            amount: 0
          },
          statuses: [],
          invoiceAmountHtTotal: {
            defaultCurrency: 0,
            values: []
          }
        });

        if (enquiryPaymentSummariesByEnquiry[enquiryId][i].invoiceCurrency) {
          if (
            typeof totalInvoiced[enquiryPaymentSummariesByEnquiry[enquiryId][i].invoiceCurrency] ===
            'undefined'
          ) {
            totalInvoiced[enquiryPaymentSummariesByEnquiry[enquiryId][i].invoiceCurrency] = 0;
          }
          if (
            typeof totalPayed[enquiryPaymentSummariesByEnquiry[enquiryId][i].invoiceCurrency] ===
            'undefined'
          ) {
            totalPayed[enquiryPaymentSummariesByEnquiry[enquiryId][i].invoiceCurrency] = 0;
          }
          if (
            typeof invoiceAmountHtTotal[
              enquiryPaymentSummariesByEnquiry[enquiryId][i].invoiceCurrency
            ] === 'undefined'
          ) {
            invoiceAmountHtTotal[
              enquiryPaymentSummariesByEnquiry[enquiryId][i].invoiceCurrency
            ] = 0;
          }

          totalInvoiced[enquiryPaymentSummariesByEnquiry[enquiryId][i].invoiceCurrency] +=
            enquiryPaymentSummariesByEnquiry[enquiryId][i].totalInvoicedTtc;
          totalPayed[enquiryPaymentSummariesByEnquiry[enquiryId][i].invoiceCurrency] +=
            enquiryPaymentSummariesByEnquiry[enquiryId][i].totalPayed;
          invoiceAmountHtTotal[enquiryPaymentSummariesByEnquiry[enquiryId][i].invoiceCurrency] +=
            enquiryPaymentSummariesByEnquiry[enquiryId][i].invoiceAmountHtTotal;

          totalInvoiced.defaultCurrency +=
            enquiryPaymentSummariesByEnquiry[enquiryId][i].totalInvoicedTtcInDefaultCurrency;
          totalPayed.defaultCurrency +=
            enquiryPaymentSummariesByEnquiry[enquiryId][i].totalPayedInDefaultCurrency;
          invoiceAmountHtTotal.defaultCurrency +=
            enquiryPaymentSummariesByEnquiry[enquiryId][i].invoiceAmountHtTotalInDefaultCurrency;
        }

        if (!previousIsSameEnquiry) {
          firstIndexForEnquiry = this.data.length - 1;
        }

        previousEnquiryId = enquiryId;
      }

      if (firstIndexForEnquiry !== null) {
        if (this.areAllLegsPast(this.data[firstIndexForEnquiry].enquiryPaymentSummary.legDates)) {
          for (const currency in invoiceAmountHtTotal) {
            if (currency === 'defaultCurrency') {
              this.data[firstIndexForEnquiry].invoiceAmountHtTotal.defaultCurrency =
                invoiceAmountHtTotal[currency];
            } else {
              this.data[firstIndexForEnquiry].invoiceAmountHtTotal.values.push({
                currency: currency as EnumCurrency,
                value: invoiceAmountHtTotal[currency]
              });
            }
          }
        }

        for (const currency in totalInvoiced) {
          if (currency === 'defaultCurrency') {
            if (totalInvoiced.defaultCurrency !== 0) {
              if (totalInvoiced.defaultCurrency === totalPayed.defaultCurrency) {
                this.data[firstIndexForEnquiry].mainStatus.status = EnumEnquiryPaymentStatus.payed;
              } else if (totalInvoiced.defaultCurrency > totalPayed.defaultCurrency) {
                this.data[firstIndexForEnquiry].mainStatus.status =
                  EnumEnquiryPaymentStatus.paymentsMissing;
                this.data[firstIndexForEnquiry].mainStatus.amount =
                  totalInvoiced.defaultCurrency - totalPayed.defaultCurrency;
              } else if (totalInvoiced[currency] === totalPayed[currency]) {
                this.data[firstIndexForEnquiry].mainStatus.status =
                  EnumEnquiryPaymentStatus.overPayments;
                this.data[firstIndexForEnquiry].mainStatus.amount =
                  totalPayed.defaultCurrency - totalInvoiced.defaultCurrency;
              }
            }
          } else {
            if (
              totalInvoiced[currency] === 0 &&
              !this.data[firstIndexForEnquiry].enquiryPaymentSummary.invoiceId
            ) {
              this.data[firstIndexForEnquiry].statuses.push({
                status: EnumEnquiryPaymentStatus.noInvoice,
                currency: currency as EnumCurrency,
                amount: 0
              });
            } else {
              if (totalInvoiced[currency] === totalPayed[currency]) {
                this.data[firstIndexForEnquiry].statuses.push({
                  status: EnumEnquiryPaymentStatus.payed,
                  currency: currency as EnumCurrency,
                  amount: 0
                });
              } else if (totalInvoiced[currency] > totalPayed[currency]) {
                this.data[firstIndexForEnquiry].statuses.push({
                  status: EnumEnquiryPaymentStatus.paymentsMissing,
                  currency: currency as EnumCurrency,
                  amount: totalInvoiced[currency] - totalPayed[currency]
                });
              } else if (totalInvoiced[currency] === totalPayed[currency]) {
                this.data[firstIndexForEnquiry].statuses.push({
                  status: EnumEnquiryPaymentStatus.overPayments,
                  currency: currency as EnumCurrency,
                  amount: totalPayed[currency] - totalInvoiced[currency]
                });
              }
            }
          }
        }

        if (!this.data[firstIndexForEnquiry].statuses.length) {
          this.data[firstIndexForEnquiry].statuses.push({
            status: EnumEnquiryPaymentStatus.noInvoice,
            currency: null,
            amount: 0
          });
        }
      }
    }

    this.refreshDataDisplayed();

    this.loading = false;

    this.updateTooltip();
  }

  changePeriod(): void {
    this.loadEnquiryPaymentSummaries();
  }

  formatPrice(value: number, currency: string = 'EUR'): string {
    const formatter = new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2
    });

    return formatter.format(value);
  }

  getNbInvoices(): number {
    let count: number = 0;

    for (const item of this.dataDisplayed) {
      if (item.enquiryPaymentSummary.invoiceId) {
        count++;
      }
    }

    return count;
  }

  getInvoiceTotalAmount(field: string): string {
    const amountByCurrency: { [key: string]: number } = {};
    let amountByCurrencyInDefaultCurrency: number = 0;

    let hasDifferentCurrencyToDefault: boolean = false;

    for (const item of this.dataDisplayed) {
      if (item.enquiryPaymentSummary.invoiceCurrency && item.enquiryPaymentSummary[field]) {
        if (typeof amountByCurrency[item.enquiryPaymentSummary.invoiceCurrency] === 'undefined') {
          amountByCurrency[item.enquiryPaymentSummary.invoiceCurrency] = 0;
        }

        if (item.enquiryPaymentSummary.invoiceCurrency !== getDefaultCurrency()) {
          hasDifferentCurrencyToDefault = true;
        }

        amountByCurrency[item.enquiryPaymentSummary.invoiceCurrency] +=
          item.enquiryPaymentSummary[field];

        amountByCurrencyInDefaultCurrency +=
          item.enquiryPaymentSummary[field + 'InDefaultCurrency'];
      }
    }

    const amountFormatted: string[] = [];
    for (const currency in amountByCurrency) {
      amountFormatted.push(this.formatPrice(amountByCurrency[currency], currency));
    }

    let result: string =
      amountByCurrencyInDefaultCurrency !== 0
        ? '<strong>' +
          this.formatPrice(amountByCurrencyInDefaultCurrency, getDefaultCurrency()) +
          '</strong>'
        : '';

    if (hasDifferentCurrencyToDefault && amountFormatted.length) {
      result += '<br><em>= ' + amountFormatted.join('<br>+ ') + '</em>';
    }

    return result;
  }

  getFieldTotalAmount(field: string): string {
    const amountByCurrency: { [currency: string]: number } = {};
    let amountByCurrencyInDefaultCurrency: number = 0;

    let hasDifferentCurrencyToDefault: boolean = false;

    for (const item of this.dataDisplayed) {
      amountByCurrencyInDefaultCurrency += item.enquiryPaymentSummary[field];

      for (const currency in item.enquiryPaymentSummary[field + 'InCurrency']) {
        if (typeof amountByCurrency[currency] === 'undefined') {
          amountByCurrency[currency] = 0;
        }

        amountByCurrency[currency] += item.enquiryPaymentSummary[field + 'InCurrency'][currency];

        if (currency !== getDefaultCurrency()) {
          hasDifferentCurrencyToDefault = true;
        }
      }
    }

    const amountFormatted: string[] = [];
    for (const currency in amountByCurrency) {
      amountFormatted.push(this.formatPrice(amountByCurrency[currency], currency));
    }

    let result: string =
      amountByCurrencyInDefaultCurrency !== 0
        ? '<strong>' +
          this.formatPrice(amountByCurrencyInDefaultCurrency, getDefaultCurrency()) +
          '</strong>'
        : '';

    if (hasDifferentCurrencyToDefault && amountFormatted.length) {
      result += '<br><em>= ' + amountFormatted.join('<br>+ ') + '</em>';
    }

    return result;
  }

  getLeftToPay(): {
    currency: EnumCurrency;
    amount: number;
  }[] {
    const amountByCurrency: { [key: string]: number } = {};
    const amountFormatted: {
      currency: EnumCurrency;
      amount: number;
    }[] = [];

    for (const item of this.dataDisplayed) {
      for (const status of item.statuses) {
        if (status.amount > 0) {
          if (typeof amountByCurrency[status.currency] === 'undefined') {
            amountByCurrency[status.currency] = 0;
          }

          amountByCurrency[status.currency] += status.amount;
        }
      }
    }

    for (const currency in amountByCurrency) {
      amountFormatted.push({
        currency: currency as EnumCurrency,
        amount: amountByCurrency[currency]
      });
    }

    return amountFormatted;
  }

  updateColumnSearch(): void {
    this.refreshDataDisplayed();
  }

  refreshDataDisplayed(): void {
    this.dataDisplayed = [];

    for (const item of this.data) {
      const matchedConditions: {
        column: IColumn;
        isMatching: boolean;
      }[] = [];

      for (const column of this.columns) {
        if (typeof column.query !== 'undefined' && column.query !== null && column.query !== '') {
          matchedConditions.push({
            column,
            isMatching: false
          });
        }
      }

      let addItem: boolean = false;

      if (matchedConditions.length) {
        for (const matchedCondition of matchedConditions) {
          let dataValue: any = item;

          for (const key of matchedCondition.column.field.split('.')) {
            if (typeof dataValue[key] !== 'undefined') {
              dataValue = dataValue[key];
            }
          }

          if (dataValue !== null) {
            let values: any[] = [];
            if (Array.isArray(dataValue)) {
              values = [...dataValue];
            } else {
              values.push(dataValue);
            }
            // Easier to consider first that it does not match
            for (let value of values) {
              switch (matchedCondition.column.searchType) {
                case EnumColumnSearch.text:
                case EnumColumnSearch.users:
                case EnumColumnSearch.enquiryTypes:
                  if (
                    (value as string)
                      .toLocaleLowerCase()
                      .match(matchedCondition.column.query.toString().toLocaleLowerCase())
                  ) {
                    matchedCondition.isMatching = true;
                    break;
                  }
                  break;
                case EnumColumnSearch.date:
                  const searchedDate: Date = parse(
                    matchedCondition.column.query.toString(),
                    'yyyy-MM-dd',
                    new Date()
                  );
                  const dateValue: Date = value instanceof Date ? value : new Date(value);
                  switch (matchedCondition.column.operator) {
                    case EnumColumnSearchOperator.inferiorAndEqual:
                      if (isSameDay(dateValue, searchedDate) || isBefore(dateValue, searchedDate)) {
                        matchedCondition.isMatching = true;
                        break;
                      }
                      break;
                    case EnumColumnSearchOperator.inferiorOnly:
                      if (
                        !isSameDay(dateValue, searchedDate) &&
                        isBefore(dateValue, searchedDate)
                      ) {
                        matchedCondition.isMatching = true;
                        break;
                      }
                      break;
                    case EnumColumnSearchOperator.superiorAndEqual:
                      if (isSameDay(dateValue, searchedDate) || isAfter(dateValue, searchedDate)) {
                        matchedCondition.isMatching = true;
                        break;
                      }
                      break;
                    case EnumColumnSearchOperator.superiorOnly:
                      if (!isSameDay(dateValue, searchedDate) && isAfter(dateValue, searchedDate)) {
                        matchedCondition.isMatching = true;
                        break;
                      }
                      break;
                    case EnumColumnSearchOperator.equal:
                    default:
                      if (isSameDay(dateValue, searchedDate)) {
                        matchedCondition.isMatching = true;
                        break;
                      }
                      break;
                  }
                  break;
                case EnumColumnSearch.number:
                  value = isNaN(value) ? value.amount : value;
                  switch (matchedCondition.column.operator) {
                    case EnumColumnSearchOperator.inferiorAndEqual:
                      if (value <= matchedCondition.column.query) {
                        matchedCondition.isMatching = true;
                        break;
                      }
                      break;
                    case EnumColumnSearchOperator.inferiorOnly:
                      if (value < matchedCondition.column.query) {
                        matchedCondition.isMatching = true;
                        break;
                      }
                      break;
                    case EnumColumnSearchOperator.superiorAndEqual:
                      if (value >= matchedCondition.column.query) {
                        matchedCondition.isMatching = true;
                        break;
                      }
                      break;
                    case EnumColumnSearchOperator.superiorOnly:
                      if (value > matchedCondition.column.query) {
                        matchedCondition.isMatching = true;
                        break;
                      }
                      break;
                    case EnumColumnSearchOperator.equal:
                    default:
                      if (value === matchedCondition.column.query) {
                        matchedCondition.isMatching = true;
                        break;
                      }
                      break;
                  }
                  break;
                case EnumColumnSearch.select:
                  if (value.status === matchedCondition.column.query) {
                    matchedCondition.isMatching = true;
                    break;
                  }
                  break;
                case EnumColumnSearch.boolean:
                  if (value === matchedCondition.column.query) {
                    matchedCondition.isMatching = true;
                    break;
                  }
                  break;
              }
            }
          } else {
            matchedCondition.isMatching = false;
          }
        }

        addItem = true;
        for (const matchedCondition of matchedConditions) {
          if (!matchedCondition.isMatching) {
            addItem = false;
            break;
          }
        }
      } else {
        addItem = true;
      }

      if (addItem) {
        this.dataDisplayed.push(Object.assign({}, item));
      }
    }

    this.updateTooltip();
  }

  changeSearchOperator(column: IColumn, searchOperator: EnumColumnSearchOperator): void {
    column.operator = searchOperator;

    this.updateColumnSearch();
  }

  dateStrToDate(dateStr: string): Date {
    return parse(dateStr, 'yyyy-MM-dd', new Date());
  }

  getSumInvoiceAmountWithDifferentCurrencies(
    values: { currency: EnumCurrency; value: number }[]
  ): string {
    const items: string[] = [];

    for (const value of values) {
      items.push(this.formatPrice(value.value, value.currency));
    }

    return items.join('<br>+ ');
  }

  getValueByCurrency(values: { [currency: string]: number }): string {
    const items: string[] = [];

    for (const currency in values) {
      if (currency !== getDefaultCurrency()) {
        items.push(this.formatPrice(values[currency], currency));
      }
    }

    return items.join('<br>+ ');
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  removeModal(): void {
    window['$'](this.modalCommissionElement.nativeElement).modal('hide');
    window['$'](this.modalDetailElement.nativeElement).modal('hide');
    window['$'](this.modalTemporaryRowElement.nativeElement).modal('hide');
    window['$']('body').removeClass('modal-open');
    window['$']('.modal-backdrop').remove();
  }

  openModalCommission(enquiryPaymentSummary: IEnquiryPaymentSummary): void {
    this.formCommission.reset();

    this.selectedEnquiryPaymentSummary = enquiryPaymentSummary;

    this.formCommission
      .get('commissionFee')
      .setValue(this.selectedEnquiryPaymentSummary.enquiryCommissionFee);
    this.formCommission
      .get('commissionStatus')
      .setValue(this.selectedEnquiryPaymentSummary.enquiryCommissionStatus);
    this.formCommission
      .get('commissionComment')
      .setValue(this.selectedEnquiryPaymentSummary.enquiryCommissionComment);
    this.formCommission
      .get('isCommissionFeePercentage')
      .setValue(
        typeof this.selectedEnquiryPaymentSummary.enquiryIsCommissionFeePercentage !== 'undefined'
          ? this.selectedEnquiryPaymentSummary.enquiryIsCommissionFeePercentage
          : true
      );
    this.formCommission
      .get('commissionFeeAmount')
      .setValue(this.selectedEnquiryPaymentSummary.enquiryCommissionFeeAmount);

    this.formCommission.updateValueAndValidity();

    window['$'](this.modalCommissionElement.nativeElement).modal('show');
  }

  submitFormCommission(): void {
    this.formCommission.markAsTouched();

    if (this.formCommission.status == 'VALID' && this.selectedEnquiryPaymentSummary.enquiryId) {
      this.sendingCommission = true;

      this.formCommission.disable();

      this.enquiryService
        .update({
          id: this.selectedEnquiryPaymentSummary.enquiryId,
          commissionFee: this.formCommission.value.commissionFee,
          commissionStatus: this.formCommission.value.commissionStatus,
          commissionComment: this.formCommission.value.commissionComment,
          isCommissionFeePercentage: this.formCommission.value.isCommissionFeePercentage,
          commissionFeeAmount: this.formCommission.value.commissionFeeAmount
        } as IEnquiry)
        .then(id => {
          // We update locally until the server update the enquiryPaymentSummaries
          for (const enquiryPaymentSummary of this.enquiryPaymentSummaries) {
            if (enquiryPaymentSummary.enquiryId === id) {
              enquiryPaymentSummary.enquiryCommissionFee = this.formCommission.value.commissionFee;
              enquiryPaymentSummary.enquiryCommissionStatus =
                this.formCommission.value.commissionStatus;
              enquiryPaymentSummary.enquiryCommissionComment =
                this.formCommission.value.commissionComment;
              enquiryPaymentSummary.enquiryIsCommissionFeePercentage =
                this.formCommission.value.isCommissionFeePercentage;
              enquiryPaymentSummary.enquiryCommissionFeeAmount =
                this.formCommission.value.commissionFeeAmount;
            }
          }

          this.formCommission.reset();
          this.formCommission.enable();
          this.sendingCommission = false;

          this.processEnquiryPaymentSummaries();

          window['$'](this.modalCommissionElement.nativeElement).modal('hide');
        })
        .catch(err => {
          console.log(err);
          this.sendingCommission = false;

          alert(err.message);
        });
    }
  }

  updatedIsCommissionFeePercentage(): void {
    let otherField: string = 'commissionFeeAmount';
    if (!this.formCommission.value.isCommissionFeePercentage) {
      otherField = 'commissionFee';
    }

    this.formCommission.get(otherField).setValue(null);
    this.formCommission.get(otherField).updateValueAndValidity();
  }

  updatedExportAllColumns(): void {
    this.columnsSettings.columnSelected = [];

    if (!this.columnsSettings.allColumns) {
      for (const column of this.columns) {
        if (!column.hide) {
          this.columnsSettings.columnSelected.push(column.field);
        }
      }
    }

    this.refreshCachedColumnsSettings();
  }

  toggleColumnToExport(columnField: string): void {
    const index: number = this.columnsSettings.columnSelected.indexOf(columnField);

    if (index === -1) {
      this.columnsSettings.columnSelected.push(columnField);
    } else {
      this.columnsSettings.columnSelected.splice(index, 1);
    }

    this.refreshCachedColumnsSettings();
  }

  toggleAllColumnsToExport(): void {
    if (!this.columnsSettings.columnSelected.length) {
      for (const column of this.columns) {
        if (!column.hide) {
          this.columnsSettings.columnSelected.push(column.field);
        }
      }
    } else {
      this.columnsSettings.columnSelected = [];
    }

    this.refreshCachedColumnsSettings();
  }

  refreshCachedColumnsSettings(): void {
    window.localStorage.setItem(
      EnumLocalStorageVariable.columnsSettings,
      JSON.stringify(this.columnsSettings)
    );
  }

  updateColumnSettingsFromCache(): void {
    const value: string = window.localStorage.getItem(EnumLocalStorageVariable.columnsSettings);

    if (value) {
      this.columnsSettings = JSON.parse(value);
    } else {
      this.columnsSettings = {
        allColumns: true,
        columnSelected: []
      };
    }
  }

  resetColumnSettings(): void {
    this.columnsSettings = {
      allColumns: true,
      columnSelected: []
    };

    this.refreshCachedColumnsSettings();
  }

  loadUser(userId: string): void {
    this.subscriptions.add(
      this.userService.getFromId(userId).subscribe((user: IUser) => {
        this.user = user;
      })
    );
  }

  async export(): Promise<void> {
    await this.loaderService.presentLoader('Export en cours...');

    let filterPeriod: string = this.filterByPeriod;

    for (const period of this.filtersByPeriod) {
      if (period.value === this.filterByPeriod) {
        filterPeriod = period.title;
        break;
      }
    }

    const fileName: string = slugify(
      this.user ? filterPeriod + ' ' + getUserFullname(this.user) : filterPeriod,
      {
        replacement: '-', // replace spaces with replacement character, defaults to `-`
        lower: true, // convert to lower case, defaults to `false`
        strict: true, // strip special characters except replacement, defaults to `false`
        trim: true
      }
    );

    const workbook = new Excel.Workbook();
    workbook.creator = getUserFullname(this.currentUser);
    let worksheet: Excel.Worksheet = workbook.addWorksheet(fileName);

    const header: Column[] = this.getExcelHeader();

    worksheet.columns = header;

    worksheet.columns.forEach(sheetColumn => {
      sheetColumn.font = {
        size: 12
      };
      sheetColumn.width = 22;

      sheetColumn.alignment = {
        wrapText: true,
        horizontal: sheetColumn.alignment?.horizontal ? sheetColumn.alignment.horizontal : 'center',
        vertical: sheetColumn.alignment?.vertical ? sheetColumn.alignment.vertical : 'middle'
      };
    });

    // Header style
    worksheet.getRow(1).font = {
      bold: true
    };

    worksheet = this.addRowsToWorksheet(worksheet, header);

    worksheet.views = [{ state: 'frozen', xSplit: 0, ySplit: 1 }];

    const sameEnquiryRows: { [key: string]: number[] } = {};
    worksheet.eachRow((row, rowNumber) => {
      // Ignore header label
      if (rowNumber > 1) {
        const valueRefEnquiry: CellValue = row.getCell(1).value;

        const valueStr: string | null = valueRefEnquiry?.toString() || null;

        if (valueStr) {
          if (typeof sameEnquiryRows[valueStr] === 'undefined') {
            sameEnquiryRows[valueStr] = [];
          }

          sameEnquiryRows[valueStr].push(rowNumber);

          const enquiryType: EnumEnquiryType | null = getEnquiryTypeFromEnquiryRef(valueStr);

          if (enquiryType) {
            let bgColor: string | null = null;

            switch (enquiryType) {
              case EnumEnquiryType.business:
                bgColor = 'fffea6';
                break;
              case EnumEnquiryType.commercial:
                bgColor = 'b1cf95';
                break;
              case EnumEnquiryType.cargo:
                bgColor = '93a9d7';
                break;
              case EnumEnquiryType.sport:
                bgColor = 'ebb38a';
                break;
              case EnumEnquiryType.helico:
                bgColor = 'f9665a';
                break;
            }

            if (bgColor) {
              row.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                  argb: bgColor
                }
              };
            }
          }
        }

        worksheet.columns.forEach(sheetColumn => {
          const column: IColumn | null = this.getColumnFromHeaderKey(sheetColumn.key);
          const cell: Cell = row.getCell(sheetColumn.number);
          let cellValue: any = cell.value;

          if (column.exportType) {
            switch (column.exportType) {
              case EnumExportColumnType.currency:
                if (cellValue && typeof cellValue.currency !== 'undefined') {
                  cell.numFmt = '#,##0.00' + getEnumCurrencySymbolForExcel(cellValue.currency);

                  cell.value = cellValue.value;
                }
                break;
            }
          }

          if (cellValue && cellValue !== null && cellValue !== '') {
            if (Array.isArray(cellValue)) {
              cell.value = cellValue.join(' + ');
            }
          }
        });
      }
    });

    worksheet.columns.forEach(sheetColumn => {
      for (const column of this.columns) {
        if (column.field === sheetColumn.key) {
          if (column.mergeIfSameEnquiry) {
            for (const enquiryRef in sameEnquiryRows) {
              worksheet.mergeCells(
                sheetColumn.letter +
                  sameEnquiryRows[enquiryRef][0] +
                  ':' +
                  sheetColumn.letter +
                  sameEnquiryRows[enquiryRef][sameEnquiryRows[enquiryRef].length - 1]
              );
            }
            break;
          }
        }
      }
    });

    if (
      !this.columnsSettings.allColumns &&
      !this.columnsSettings.columnSelected.includes('enquiryPaymentSummary.enquiryRefContractTitle')
    ) {
      // Remove first column for enquiry contract number
      worksheet.spliceColumns(1, 1);
      header.splice(1, 1);
    }

    this.addFooter(worksheet, header);

    // Generate Excel file
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      saveAs(blob, 'simplyfly-paiement-dossiers-' + fileName + '.xlsx');
    });

    setTimeout(async () => {
      await this.loaderService.hideLoaderOnSuccess();
    }, 1000);
  }

  getColumnFromHeaderKey(key: string): IColumn | null {
    for (const column of this.columns) {
      if (column.field === key) {
        return column;
      }
    }

    return null;
  }

  getExcelHeader(): Column[] {
    const headers: Column[] = [];

    for (const column of this.columns) {
      if (
        this.columnsSettings.allColumns ||
        (this.columnsSettings.columnSelected.includes(column.field) && !column.hide) ||
        column.field === 'enquiryPaymentSummary.enquiryRefContractTitle' // Have to be mandatory to remove later in the file
      ) {
        const header: Column = {
          key: column.field,
          header: column.title
        } as Column;

        if (column.exportType) {
          switch (column.exportType) {
            case EnumExportColumnType.text:
              break;
            case EnumExportColumnType.number:
              break;
            case EnumExportColumnType.percentage:
              header.style = {
                numFmt: '0.00%'
              };
              break;
            case EnumExportColumnType.currency:
              header.style = {
                // numFmt: '#,##0.00€',
                alignment: {
                  horizontal: 'right'
                }
              };
              break;
          }
        }

        headers.push(header);
      }
    }

    return headers;
  }

  addRowsToWorksheet(worksheet: Excel.Worksheet, header: Column[]): Excel.Worksheet {
    this.dataDisplayed.forEach((row: IEnquiryPaymentRow) => {
      const rowObj: { [key: string]: any } = {};

      for (const cell of header) {
        const fieldSplitted: string[] = cell.key.split('.');

        let value: any = null;
        switch (fieldSplitted[0]) {
          case 'enquiryPaymentSummary':
            value = row.enquiryPaymentSummary[fieldSplitted[1]];

            switch (fieldSplitted[1]) {
              case 'enquiryCommissionFeeAmount':
              case 'enquiryPriceNetTTC':
              case 'enquirySellingPrice':
              case 'enquiryMargin':
                if (value !== null) {
                  value = {
                    value: value,
                    currency: getDefaultCurrency(),
                    formatPrice: this.formatPrice(value, getDefaultCurrency())
                  };
                }
                break;
              case 'invoiceAmountHtTotal':
              case 'invoiceAmountTvaTotal':
              case 'invoiceAmountTtcTotal':
                if (value !== null && row.enquiryPaymentSummary.invoiceCurrency) {
                  value = {
                    value: value,
                    currency: row.enquiryPaymentSummary.invoiceCurrency ?? getDefaultCurrency(),
                    formatPrice: this.formatPrice(value, row.enquiryPaymentSummary.invoiceCurrency)
                  };
                }
                break;
              case 'encaissementsAmount':
                if (Array.isArray(value) && value.length === 1) {
                  value = {
                    value: value[0],
                    currency: row.enquiryPaymentSummary.invoiceCurrency ?? getDefaultCurrency(),
                    formatPrice: this.formatPrice(
                      value[0],
                      row.enquiryPaymentSummary.encaissementsCurrency[0]
                    )
                  };
                } else {
                  for (let i = 0; i < value.length; i++) {
                    value[i] = this.formatPrice(
                      value[i],
                      row.enquiryPaymentSummary.encaissementsCurrency[i]
                    );
                  }
                }
                break;
              case 'enquiryType':
                value = getEnumEnquiryTypeLabel(value);
                break;
            }
            break;
          case 'enquiryPaymentSummaryDetail':
            value =
              row.enquiryPaymentSummaryDetail && row.enquiryPaymentSummaryDetail[fieldSplitted[1]]
                ? row.enquiryPaymentSummaryDetail[fieldSplitted[1]]
                : null;
            break;
          case 'statuses':
            value = [];
            for (const status of row.statuses) {
              if (
                [EnumEnquiryPaymentStatus.noInvoice, EnumEnquiryPaymentStatus.payed].includes(
                  status.status
                )
              ) {
                value.push(getEnumEnquiryPaymentStatusLabel(status.status));
              } else {
                value.push(
                  getEnumEnquiryPaymentStatusLabel(status.status) +
                    ' - ' +
                    this.formatPrice(
                      status.amount,
                      status.currency === getDefaultCurrency()
                        ? getDefaultCurrency()
                        : status.currency
                    )
                );
              }
            }
            break;
        }

        rowObj[cell.key] = value;
      }

      worksheet.addRow(rowObj);
    });

    return worksheet;
  }

  addFooter(worksheet: Worksheet, header: Column[]): void {
    const rowValues: any[] = [];

    for (const column of header) {
      switch (column.key) {
        case 'enquiryPaymentSummary.enquiryRefContractTitle':
          rowValues.push(this.getTotalValue('nbContracts'));
          break;
        case 'enquiryPaymentSummary.invoiceRef':
          rowValues.push(this.getTotalValue('nbInvoices'));
          break;
        case 'invoiceAmountHtTotal':
          rowValues.push(
            this.getRealMarginHt()
              .replaceAll('<strong>', '')
              .replaceAll('</strong>', '')
              .replaceAll('</strong><br><em>', ' ')
              .replaceAll('<br>', ' ')
              .replaceAll('</em>', '')
              .replaceAll('<em>', '')
          );
          break;
        case 'enquiryPaymentSummary.enquiryIsCompleted':
          const nbCompleted: number = this.getNbCompleted();
          const nbNotCompleted: number = this.getNbNotCompleted();

          let text: string = nbNotCompleted + ' actifs\n';
          text += nbCompleted + ' cloturés';

          rowValues.push(text);
          break;
        case 'enquiryPaymentSummary.invoiceAmountHtTotal':
          rowValues.push(
            this.getInvoiceTotalAmount('invoiceAmountHtTotal')
              .replaceAll('<strong>', '')
              .replaceAll('</strong>', '')
              .replaceAll('</strong><br><em>', ' ')
              .replaceAll('<br>', ' ')
              .replaceAll('</em>', '')
              .replaceAll('<em>', '')
          );
          break;
        case 'enquiryPaymentSummary.invoiceAmountTvaTotal':
          rowValues.push(
            this.getInvoiceTotalAmount('invoiceAmountTvaTotal')
              .replaceAll('<strong>', '')
              .replaceAll('</strong>', '')
              .replaceAll('</strong><br><em>', ' ')
              .replaceAll('<br>', ' ')
              .replaceAll('</em>', '')
              .replaceAll('<em>', '')
          );
          break;
        case 'enquiryPaymentSummary.invoiceAmountTtcTotal':
          rowValues.push(
            this.getInvoiceTotalAmount('invoiceAmountTtcTotal')
              .replaceAll('<strong>', '')
              .replaceAll('</strong>', '')
              .replaceAll('</strong><br><em>', ' ')
              .replaceAll('<br>', ' ')
              .replaceAll('</em>', '')
              .replaceAll('<em>', '')
          );
          break;
        case 'enquiryPaymentSummary.enquiryPriceNetTTC':
          rowValues.push(
            this.getFieldTotalAmount('enquiryPriceNetTTC')
              .replaceAll('<strong>', '')
              .replaceAll('</strong>', '')
              .replaceAll('</strong><br><em>', ' ')
              .replaceAll('<br>', ' ')
              .replaceAll('</em>', '')
              .replaceAll('<em>', '')
          );
          break;
        case 'enquiryPaymentSummary.enquirySellingPrice':
          rowValues.push(
            this.getFieldTotalAmount('enquirySellingPrice')
              .replaceAll('<strong>', '')
              .replaceAll('</strong>', '')
              .replaceAll('</strong><br><em>', ' ')
              .replaceAll('<br>', ' ')
              .replaceAll('</em>', '')
              .replaceAll('<em>', '')
          );
          break;
        case 'enquiryPaymentSummary.enquiryMargin':
          rowValues.push(
            this.getFieldTotalAmount('enquiryMargin')
              .replaceAll('<strong>', '')
              .replaceAll('</strong>', '')
              .replaceAll('</strong><br><em>', ' ')
              .replaceAll('<br>', ' ')
              .replaceAll('</em>', '')
              .replaceAll('<em>', '')
          );
          break;
        case 'enquiryPaymentSummary.encaissementsAmount':
          rowValues.push(
            this.getInvoiceTotalAmount('totalPayed')
              .replaceAll('<strong>', '')
              .replaceAll('</strong>', '')
              .replaceAll('</strong><br><em>', ' ')
              .replaceAll('<br>', ' ')
              .replaceAll('</em>', '')
              .replaceAll('<em>', '')
          );
          break;
        case 'statuses.status':
          let value: string = '';
          if (this.getLeftToPay().length) {
            value += getEnumEnquiryPaymentStatusLabel(EnumEnquiryPaymentStatus.paymentsMissing);
          }
          let amounts: string[] = [];
          for (let item of this.getLeftToPay()) {
            amounts.push(
              this.formatPrice(
                item.amount,
                item.currency === getDefaultCurrency() ? getDefaultCurrency() : item.currency
              )
            );
          }
          if (amounts.length) {
            value += ' ' + amounts.join(', ');
          }
          rowValues.push(value);
          break;
        default:
          rowValues.push(null);
          break;
      }
    }

    worksheet.addRow(rowValues);

    worksheet.getRow(worksheet.rowCount).font = {
      bold: true
    };
  }

  areAllLegsPast(legDates: string[]): boolean {
    for (const legDate of legDates) {
      const date: Date = new Date(legDate);

      if (isFuture(date)) {
        return false;
      }
    }

    return true;
  }

  async toggleEnquiryIsCompleted(row: IEnquiryPaymentRow): Promise<void> {
    if (row.enquiryPaymentSummaryDetail?.temporaryRows.length) {
      alert(
        'Impossible de clotûrer un dossier qui a des lignes temporaires. Veuillez les supprimer.'
      );
    } else if (row.enquiryPaymentSummary.enquiryId) {
      try {
        await this.loaderService.presentLoader('Envoi en cours...');

        const newIsCompleted: boolean = !row.enquiryPaymentSummary.enquiryIsCompleted;

        await this.enquiryService.update({
          id: row.enquiryPaymentSummary.enquiryId,
          isCompleted: newIsCompleted,
          dateCompleted: newIsCompleted ? new Date() : null
        } as IEnquiry);

        // We update locally until the server update the enquiryPaymentSummaries
        for (const enquiryPaymentSummary of this.enquiryPaymentSummaries) {
          if (enquiryPaymentSummary.enquiryId === row.enquiryPaymentSummary.enquiryId) {
            enquiryPaymentSummary.enquiryIsCompleted = newIsCompleted;
          }
        }

        this.processEnquiryPaymentSummaries();

        await this.loaderService.hideLoaderOnSuccess();
      } catch (err: any) {
        await this.loaderService.hideLoaderOnFailure(err.message);
      }
    }
  }

  getFirstAndLastEnquiryPaymentSummary(): void {
    this.subscriptionsFirstAndLast.add(
      this.enquiryPaymentSummaryService
        .getFirst()
        .subscribe((firstEnquiryPaymentSummary: IEnquiryPaymentSummary) => {
          this.firstEnquiryPaymentSummary = firstEnquiryPaymentSummary;

          this.generateFilterPeriodOptions();
        })
    );
    this.subscriptionsFirstAndLast.add(
      this.enquiryPaymentSummaryService
        .getLast()
        .subscribe((lastEnquiryPaymentSummary: IEnquiryPaymentSummary) => {
          this.lastEnquiryPaymentSummary = lastEnquiryPaymentSummary;

          this.generateFilterPeriodOptions();
        })
    );
  }

  loadEnquiryPaymentSummaryDetails(): void {
    this.loadingEnquiryPaymentSummaryDetails = true;

    if (this.subscriptionsDetails) {
      this.subscriptionsDetails.unsubscribe();
    }

    this.subscriptionsDetails = new Subscription();

    const splittedFilterByPeriod: string[] = this.filterByPeriod.split('-');

    let promise;

    switch (splittedFilterByPeriod[0]) {
      case 'quarter':
        promise = () =>
          this.enquiryPaymentSummaryDetailService.getAllOfQuarter(
            parseInt(splittedFilterByPeriod[1]),
            parseInt(splittedFilterByPeriod[2])
          );

        break;
      case 'year':
        promise = () =>
          this.enquiryPaymentSummaryDetailService.getAllOfYear(parseInt(splittedFilterByPeriod[1]));
        break;
      default:
        promise = () =>
          this.enquiryPaymentSummaryDetailService.getAllOfMonth(
            parseInt(splittedFilterByPeriod[0]),
            parseInt(splittedFilterByPeriod[1])
          );
        break;
    }

    if (promise) {
      this.subscriptionsDetails.add(
        promise().subscribe((enquiryPaymentSummaryDetails: IEnquiryPaymentSummaryDetail[]) => {
          this.enquiryPaymentSummaryDetails = enquiryPaymentSummaryDetails;

          for (const enquiryPaymentSummaryDetail of this.enquiryPaymentSummaryDetails) {
            this.enquiryPaymentSummaryDetailsObj[enquiryPaymentSummaryDetail.enquiryId] =
              enquiryPaymentSummaryDetail;
          }

          this.processEnquiryPaymentSummaries();

          this.loadingEnquiryPaymentSummaryDetails = false;
        })
      );
    }
  }

  setComment(row: IEnquiryPaymentRow): void {
    this.selectedRow = row;

    if (this.selectedRow.enquiryPaymentSummaryDetail) {
      this.formDetail
        .get('comment')
        .setValue(this.selectedRow.enquiryPaymentSummaryDetail.comment || null);
      this.formDetail.get('comment').updateValueAndValidity();
    }

    window['$'](this.modalDetailElement.nativeElement).modal('show');
  }

  async submitFormComment(): Promise<void> {
    this.formDetail.markAsTouched();

    if (this.formDetail.status == 'VALID' && this.selectedRow) {
      this.formDetail.disable();

      this.loaderService.presentLoader();

      try {
        const data: IEnquiryPaymentSummaryDetail = {
          enquiryPaymentSummaryId: this.selectedRow.enquiryPaymentSummary.id,
          enquiryId: this.selectedRow.enquiryPaymentSummary.enquiryId,
          comment: this.formDetail.value.comment,
          periodMonth: this.selectedRow.enquiryPaymentSummary.periodMonth,
          periodQuarter: this.selectedRow.enquiryPaymentSummary.periodQuarter,
          periodYear: this.selectedRow.enquiryPaymentSummary.periodYear,
          temporaryRows: []
        } as IEnquiryPaymentSummaryDetail;

        if (this.selectedRow.enquiryPaymentSummaryDetail) {
          data.id = this.selectedRow.enquiryPaymentSummaryDetail.id;
          data.temporaryRows = this.selectedRow.enquiryPaymentSummaryDetail.temporaryRows;

          await this.enquiryPaymentSummaryDetailService.update(data);
        } else {
          await this.enquiryPaymentSummaryDetailService.create(data);
        }

        this.formDetail.reset();
        this.formDetail.enable();

        this.processEnquiryPaymentSummaries();

        window['$'](this.modalDetailElement.nativeElement).modal('hide');

        this.loaderService.hideLoaderOnSuccess();
      } catch (err: any) {
        this.loaderService.hideLoaderOnFailure(err.message);
      }
    }
  }

  async submitFormTemporaryRow(): Promise<void> {
    this.formTemporaryRow.markAsTouched();

    if (this.formTemporaryRow.status == 'VALID' && this.selectedRow?.enquiryPaymentSummaryDetail) {
      this.formTemporaryRow.disable();

      this.loaderService.presentLoader();

      try {
        this.selectedRow.enquiryPaymentSummaryDetail.temporaryRows[this.selectedTemporaryRowIndex] =
          Object.assign({}, this.formTemporaryRow.value);

        await this.enquiryPaymentSummaryDetailService.update(
          this.selectedRow.enquiryPaymentSummaryDetail
        );

        this.formTemporaryRow.reset();
        this.formTemporaryRow.enable();

        this.processEnquiryPaymentSummaries();

        window['$'](this.modalTemporaryRowElement.nativeElement).modal('hide');

        this.loaderService.hideLoaderOnSuccess();
      } catch (err: any) {
        this.loaderService.hideLoaderOnFailure(err.message);
      }
    }
  }

  async addTemporaryLine(row: IEnquiryPaymentRow): Promise<void> {
    await this.loaderService.presentLoader();

    const detail: IEnquiryPaymentSummaryDetail =
      row.enquiryPaymentSummaryDetail ??
      ({
        enquiryPaymentSummaryId: row.enquiryPaymentSummary.id,
        enquiryId: row.enquiryPaymentSummary.enquiryId,
        comment: null,
        periodMonth: row.enquiryPaymentSummary.periodMonth,
        periodQuarter: row.enquiryPaymentSummary.periodQuarter,
        periodYear: row.enquiryPaymentSummary.periodYear,
        temporaryRows: []
      } as IEnquiryPaymentSummaryDetail);

    const temporaryRow: IEnquiryPaymentSummaryDetailTemporaryRow = {
      invoiceRef: null,
      invoiceClientName: null,
      invoiceIssueDate: null,
      invoiceAmountHtTotal: null,
      invoiceAmountTvaTotal: null,
      invoiceAmountTtcTotal: null,
      invoiceProductsLabel: null,
      invoiceCurrency: null,
      invoiceDueDate: null,
      encaissementsAmount: null,
      encaissementsTypeId: null,
      encaissementsDate: null
    };

    detail.temporaryRows.push(temporaryRow);

    try {
      if (row.enquiryPaymentSummaryDetail) {
        detail.id = row.enquiryPaymentSummaryDetail.id;
        await this.enquiryPaymentSummaryDetailService.update(detail);
      } else {
        await this.enquiryPaymentSummaryDetailService.create(detail);
      }

      this.processEnquiryPaymentSummaries();

      await this.loaderService.hideLoaderOnSuccess();
    } catch (err: any) {
      await this.loaderService.hideLoaderOnFailure(err.message);
    }
  }

  editTemporaryRow(row: IEnquiryPaymentRow, index: number): void {
    this.selectedRow = row;
    this.selectedTemporaryRowIndex = index;

    if (
      this.selectedRow.enquiryPaymentSummaryDetail &&
      this.selectedRow.enquiryPaymentSummaryDetail.temporaryRows[index]
    ) {
      for (const field in this.formTemporaryRow.value) {
        this.formTemporaryRow
          .get(field)
          .setValue(
            this.selectedRow.enquiryPaymentSummaryDetail.temporaryRows[index][field] || null
          );
        this.formTemporaryRow.get(field).updateValueAndValidity();
      }
    }

    window['$'](this.modalTemporaryRowElement.nativeElement).modal('show');
  }

  async deleteTemporaryRow(row: IEnquiryPaymentRow, index: number): Promise<void> {
    if (
      row.enquiryPaymentSummaryDetail?.temporaryRows[index] &&
      confirm('Êtes-vous sûr de vouloir supprimer cette ligne ?')
    ) {
      row.enquiryPaymentSummaryDetail.temporaryRows.splice(index, 1);

      this.updateTooltip();

      await this.loaderService.presentLoader();

      try {
        await this.enquiryPaymentSummaryDetailService.update(row.enquiryPaymentSummaryDetail);

        this.processEnquiryPaymentSummaries();

        await this.loaderService.hideLoaderOnSuccess();
      } catch (err: any) {
        await this.loaderService.hideLoaderOnFailure(err.message);
      }
    }
  }

  loadEncaissementTypes(): void {
    this.loadingEncaissementTypes = true;

    this.subscriptions.add(
      this.encaissementTypeService.getAll().subscribe((encaissementTypes: IEncaissementType[]) => {
        this.encaissementTypes = encaissementTypes;

        for (const encaissementType of this.encaissementTypes) {
          this.encaissementTypesObj[encaissementType.id] = encaissementType;
        }

        this.loadingEncaissementTypes = false;
      })
    );
  }

  getTotalValue(field: string): string {
    let text: string = '';

    switch (field) {
      case 'nbContracts':
        const value: number = this.getNbContracts();

        text += value + ' dossiers';
        break;
      case 'nbCompleted':
        const nbCompleted: number = this.getNbCompleted();
        const nbNotCompleted: number = this.getNbNotCompleted();

        text += nbNotCompleted + ' actifs\n';
        text += nbCompleted + ' cloturés';
        break;
      case 'nbInvoices':
        const nbInvoices: number = this.getNbInvoices();

        text += nbInvoices + ' factures';
        break;
      case 'invoicesTotalAmount':
      case 'invoiceAmountHtTotal':
      case 'invoiceAmountTvaTotal':
      case 'invoiceAmountTtcTotal':
      case 'totalPayed':
        text += this.getInvoiceTotalAmount(field);
        break;
      case 'enquiryPriceNetTTC':
      case 'enquirySellingPrice':
      case 'enquiryMargin':
        text += this.getFieldTotalAmount(field);
        break;
      case 'realMarginHt':
        text += this.getRealMarginHt();
        break;
    }

    return text;
  }

  getNbContracts(): number {
    let count: number = 0;

    for (const item of this.dataDisplayed) {
      if (!item.previousIsSameEnquiry) {
        count++;
      }
    }

    return count;
  }

  getNbCompleted(): number {
    let count: number = 0;

    for (const item of this.dataDisplayed) {
      if (!item.previousIsSameEnquiry && item.enquiryPaymentSummary.enquiryIsCompleted) {
        count++;
      }
    }

    return count;
  }

  getNbNotCompleted(): number {
    let count: number = 0;

    for (const item of this.dataDisplayed) {
      if (!item.previousIsSameEnquiry && !item.enquiryPaymentSummary.enquiryIsCompleted) {
        count++;
      }
    }

    return count;
  }

  getRealMarginHt(): string {
    const amountByCurrency: { [key: string]: number } = {};
    let amountByCurrencyInDefaultCurrency: number = 0;

    let hasDifferentCurrencyToDefault: boolean = false;

    for (const item of this.dataDisplayed) {
      if (item.invoiceAmountHtTotal.values.length) {
        for (const value of item.invoiceAmountHtTotal.values) {
          if (typeof amountByCurrency[value.currency] === 'undefined') {
            amountByCurrency[value.currency] = 0;
          }

          if (value.currency !== getDefaultCurrency()) {
            hasDifferentCurrencyToDefault = true;
          }
          amountByCurrency[value.currency] += value.value;
        }
      }

      amountByCurrencyInDefaultCurrency += item.invoiceAmountHtTotal.defaultCurrency;
    }

    const amountFormatted: string[] = [];
    for (const currency in amountByCurrency) {
      amountFormatted.push(this.formatPrice(amountByCurrency[currency], currency));
    }

    let result: string =
      amountByCurrencyInDefaultCurrency !== 0
        ? '<strong>' +
          this.formatPrice(amountByCurrencyInDefaultCurrency, getDefaultCurrency()) +
          '</strong>'
        : '';

    if (hasDifferentCurrencyToDefault && amountFormatted.length) {
      result += '<br><em>= ' + amountFormatted.join('<br>+ ') + '</em>';
    }

    return result;
  }
}
