import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IEnquiryFlight } from 'src/app/interfaces/enquiry-flight.interface';
import { IEnquiry } from 'src/app/interfaces/enquiry.interface';
import { EnquiryFlightService } from 'src/app/services/enquiry-flights/enquiry-flights.service';
import { FlightChecklistService } from 'src/app/services/flight-checklists/flight-checklists.service';
import { LoaderService } from 'src/app/services/loader/loader.service';

enum EnumAddType {
  scratch = 'scratch',
  duplicate = 'duplicate'
}

@Component({
  selector: 'app-enquiry-flight-add',
  templateUrl: './enquiry-flight-add.component.html',
  styleUrls: ['./enquiry-flight-add.component.scss']
})
export class EnquiryFlightAddComponent {
  @Input() enquiry: IEnquiry | null = null;
  @Input() enquiryFlights: IEnquiryFlight[] = [];
  @Input() inModal: boolean = false;

  @Output() editEnquiryFlight: EventEmitter<IEnquiryFlight | null> = new EventEmitter();

  @ViewChild('btnCloseModal') btnCloseModalEl: ElementRef;

  EnumAddType = EnumAddType;

  addType: EnumAddType = EnumAddType.scratch;
  enquiryFlightId: string | null = null;

  constructor(
    private loaderService: LoaderService,
    private enquiryFlightService: EnquiryFlightService,
    private flightChecklistService: FlightChecklistService
  ) {}

  async save(): Promise<void> {
    let newEnquiryFlight: IEnquiryFlight | null = null;

    if (this.addType === EnumAddType.duplicate) {
      this.loaderService.presentLoader('Copie des informations du leg sélectionné ...');

      for (const enquiryFlight of this.enquiryFlights) {
        if (enquiryFlight.id === this.enquiryFlightId) {
          newEnquiryFlight = await this.enquiryFlightService.duplicateEnquiryFlight(enquiryFlight);

          await this.flightChecklistService.duplicateForEnquiryFlight(
            enquiryFlight.id,
            newEnquiryFlight.id
          );

          break;
        }
      }

      await this.loaderService.hideLoaderOnSuccess();
    }

    if (this.btnCloseModalEl) {
      this.btnCloseModalEl.nativeElement.click();
    }

    this.editEnquiryFlight.emit(newEnquiryFlight);
  }
}
