import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { EnquiryFlightChecklistComponent } from './enquiry-flight-checklist.component';

@NgModule({
  imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, FontAwesomeModule],
  declarations: [EnquiryFlightChecklistComponent],
  exports: [EnquiryFlightChecklistComponent]
})
export class EnquiryFlightChecklistModule {}
