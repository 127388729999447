import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { RemoteService } from '../../../../services/remote.service';
import { AclService } from '../../../../services/acl.service';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { IAircraftModel } from 'src/app/interfaces/aircraft-model.interface';
import { getEnquiryTargetLabel } from 'src/app/enums/enquiry-target.enum';

@Component({
  selector: 'app-aircraft-model-view',
  templateUrl: './aircraft-model-view.component.html',
  styleUrls: ['./aircraft-model-view.component.scss']
})
export class AircraftModelViewComponent implements OnInit {
  loading: boolean = false;
  aircraftModelId: string;
  aircraftModel: IAircraftModel | null = null;
  isLogged: boolean = false;

  getEnquiryTargetLabel = getEnquiryTargetLabel;

  EnumAcl = EnumAcl;

  constructor(
    private remoteService: RemoteService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private aclService: AclService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
  }

  ngOnInit(): void {
    this.activatedRoute.url.subscribe(() => {
      this.aircraftModelId = this.activatedRoute.snapshot.paramMap.get('aircraftModelId');

      this.loadData();
    });
  }

  loadData(): void {
    if (this.isLogged) {
      this.loadAircraftModel();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  async loadAircraftModel(): Promise<void> {
    this.loading = true;

    const doc = await this.remoteService.getDocument('aircraftModels', this.aircraftModelId);

    this.aircraftModel = doc as IAircraftModel;

    this.loading = false;
  }

  delete(): void {
    if (this.hasAclAccess(EnumAcl.aircraftModelsDelete)) {
      const result = confirm(
        "La suppression du modèle d'avion sera permanente. Êtes-vous sûr de vouloir continuer?"
      );

      if (result) {
        this.remoteService
          .deleteDocumentInCollection('aircraftModels', this.aircraftModelId)
          .then(() => {
            alert('La suppression a été effectuée avec succès.');

            this.router.navigate(['/admin/aircraft-models']);
          })
          .catch(err => {
            console.log(err);
          });
      }
    }
  }

  isNaN(val: any): boolean {
    return isNaN(val);
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }
}
