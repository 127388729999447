import { Component, OnInit, NgZone, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { PaginationService } from '../../../services/pagination.service';
import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';
import { EnumAcl } from 'src/app/enums/acl.enum';
import {
  ICrew,
  getCrewFullname,
  getCrewGenderLabel,
  getCrewTypeLabel
} from 'src/app/interfaces/crew.interface';
import { Subscription } from 'rxjs';
import { CrewService } from 'src/app/services/crews/crews.service';
import { AirlineService } from 'src/app/services/airlines/airlines.service';
import { IAirline } from 'src/app/interfaces/airline.interface';

@Component({
  selector: 'app-crew-view',
  templateUrl: './crew-view.component.html',
  styleUrls: ['./crew-view.component.scss']
})
export class CrewViewComponent implements OnInit, OnDestroy {
  EnumAcl = EnumAcl;

  getCrewFullname = getCrewFullname;
  getCrewTypeLabel = getCrewTypeLabel;
  getCrewGenderLabel = getCrewGenderLabel;

  loading: boolean = false;
  crewId: string;
  crew: ICrew | null = null;
  isLogged: boolean = false;

  crewsObj: { [id: string]: ICrew | null } = {};
  airlinesObj: { [id: string]: IAirline | null } = {};

  subscriptions = new Subscription();

  constructor(
    private remoteService: RemoteService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public paginationService: PaginationService,
    private aclService: AclService,
    private crewService: CrewService,
    private airlineService: AirlineService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
  }

  ngOnInit(): void {
    this.activatedRoute.url.subscribe(() => {
      this.crewId = this.activatedRoute.snapshot.paramMap.get('crewId');

      this.loadData();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async loadData(): Promise<void> {
    if (this.isLogged) {
      this.loadCrew();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  loadCrew(): void {
    this.subscriptions.add(
      this.crewService.getFromId(this.crewId).subscribe((crew: ICrew) => {
        this.crew = crew;

        if (this.crew?.airlineId) {
          this.loadAirline(this.crew.airlineId);
        }
      })
    );
  }

  delete(): void {
    if (this.hasAclAccess(EnumAcl.crewsDelete)) {
      const result = confirm(
        "La suppression d'un membre de l'équipage sera permanente. Êtes-vous sûr de vouloir continuer?"
      );

      if (result) {
        const redirectUrl: string = '/admin/crews/airline/' + this.crew.airlineId;

        this.crewService
          .delete(this.crewId)
          .then(() => {
            this.router.navigate([redirectUrl]);

            alert('La suppression a été effectuée avec succès.');
          })
          .catch(err => {
            console.log(err);
          });
      }
    }
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  loadAirline(airlineId: string): void {
    if (typeof this.airlinesObj[airlineId] === 'undefined') {
      this.airlinesObj[airlineId] = null;

      this.subscriptions.add(
        this.airlineService.getFromId(airlineId).subscribe((airline: IAirline | null) => {
          this.airlinesObj[airlineId] = airline;
        })
      );
    }
  }
}
