import { Component, OnInit, NgZone, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { PaginationService } from '../../../services/pagination.service';
import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { Subscription } from 'rxjs';
import { CountryService } from 'src/app/services/countries/countries.service';
import { ICountry } from 'src/app/interfaces/country.interface';

import countriesFr from '../../../countries_fr.json';

@Component({
  selector: 'app-country-view',
  templateUrl: './country-view.component.html',
  styleUrls: ['./country-view.component.scss']
})
export class CountryViewComponent implements OnInit, OnDestroy {
  EnumAcl = EnumAcl;

  loading: boolean = false;
  countryId: string;
  country: ICountry;
  isLogged: boolean = false;

  countriesFr = countriesFr;

  subscriptions = new Subscription();

  constructor(
    private remoteService: RemoteService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public paginationService: PaginationService,
    private aclService: AclService,
    private countryService: CountryService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
  }

  ngOnInit(): void {
    this.activatedRoute.url.subscribe(() => {
      this.countryId = this.activatedRoute.snapshot.paramMap.get('countryId');

      this.loadData();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  loadData(): void {
    if (this.isLogged) {
      this.loadCountry();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  loadCountry(): void {
    this.loading = true;

    this.subscriptions.add(
      this.countryService.getFromId(this.countryId).subscribe((country: ICountry) => {
        this.country = country;

        this.loading = false;
      })
    );
  }

  delete(): void {
    if (this.hasAclAccess(EnumAcl.countriesDelete)) {
      const result = confirm(
        "La suppression d'un pays sera permanente. Êtes-vous sûr de vouloir continuer?"
      );

      if (result) {
        this.countryService
          .delete(this.countryId)
          .then(() => {
            this.router.navigate(['/admin/countries']);

            alert('La suppression a été effectuée avec succès.');
          })
          .catch(err => {
            console.log(err);
          });
      }
    }
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }
}
