import { Injectable } from '@angular/core';

import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ICrawlerUrl } from '../interfaces/crawler-url.interface';
import { IEnquiry } from '../interfaces/enquiry.interface';
import firebase from 'firebase/compat/app';

import { RemoteService } from '../services/remote.service';
import { EnumInvoiceType } from '../enums/invoice-type.enum';
import { IUser } from '../interfaces/user.interface';
import { IFlightBrief } from '../interfaces/flight-brief.interface';
import { ICountry } from '../interfaces/country.interface';
import { IEmployeeBonusRule } from '../interfaces/employee-bonus-rule.interface';
import { IExportRequest } from '../interfaces/export-request.interface';
import { IAirline } from '../interfaces/airline.interface';
import { IPipedriveOrganization, IPipedrivePerson } from '../interfaces/pipedrive.interface';
import { IClient } from '../interfaces/client.interface';
import { IAircraft } from '../interfaces/aircraft.interface';
import { IAircraftCompiled } from '../interfaces/aircraft-compiled.interface';
import { IAircraftModel } from '../interfaces/aircraft-model.interface';
import { IAirport } from '../interfaces/airport.interface';
import { IEmailTemplate } from '../interfaces/email-template.interface';
import { IQuotation } from '../interfaces/quotation.interface';
import { IInvoice } from '../interfaces/invoice.interface';
import { IEncaissementType } from '../interfaces/encaissement-type.interface';
import { IEncaissement } from '../interfaces/encaissement.interface';
import { IFbo } from '../interfaces/fbo.interface';
import { ICrew } from '../interfaces/crew.interface';
import { IAirlineSupervisor } from '../interfaces/airline-supervisor.interface';
import { IHandling } from '../interfaces/handling.interface';

@Injectable({
  providedIn: 'root'
})
export class PaginationService {
  isLogged: boolean = false;
  loading: boolean = false;
  pagination: object = {
    'users-list': {
      collectionName: 'users',
      fields: [
        {
          title: 'Prénom',
          code: 'firstname'
        },
        {
          title: 'Nom',
          code: 'lastname'
        },
        {
          title: 'Initiales',
          code: 'initials'
        },
        {
          title: 'Email',
          code: 'email'
        },
        {
          title: 'Groupe',
          code: 'userGroupId'
        },
        {
          title: 'Date de création',
          code: 'created'
        },
        {
          title: 'Désactivé ?',
          code: 'isDisabled'
        }
      ],
      orderBy: {
        field: 'firstname',
        direction: 'asc'
      },
      defaultConditions: [],
      conditions: [],
      currentPage: 0,
      snapshotChanges: null,
      firstDoc: null,
      lastDoc: null,
      limit: 15
    },
    'user-groups-list': {
      collectionName: 'userGroups',
      fields: [
        {
          title: 'Nom',
          code: 'title'
        }
      ],
      orderBy: {
        field: 'title',
        direction: 'asc'
      },
      defaultConditions: [],
      conditions: [],
      currentPage: 0,
      snapshotChanges: null,
      firstDoc: null,
      lastDoc: null,
      limit: 15
    },
    'airlines-list': {
      collectionName: 'airlines',
      fields: [
        {
          title: 'Nom',
          code: 'title'
        },
        {
          title: 'Continent',
          code: 'continentCode'
        },
        {
          title: 'Pays',
          code: 'countryCode'
        },
        {
          title: 'En activitée ?',
          code: 'isActive'
        },
        {
          title: 'A des contacts ?',
          code: 'hasContact'
        }
      ],
      orderBy: {
        field: 'title',
        direction: 'asc'
      },
      defaultConditions: [],
      conditions: [],
      currentPage: 0,
      snapshotChanges: null,
      firstDoc: null,
      lastDoc: null,
      limit: 15
    },
    'airports-list': {
      collectionName: 'airports',
      fields: [
        {
          title: 'Code IATA',
          code: 'iataCode'
        },
        {
          title: 'Nom',
          code: 'title'
        },
        {
          title: 'Continent',
          code: 'continentCode'
        },
        {
          title: 'Pays',
          code: 'countryCode'
        }
      ],
      orderBy: {
        field: 'title',
        direction: 'asc'
      },
      defaultConditions: [],
      conditions: [],
      currentPage: 0,
      snapshotChanges: null,
      firstDoc: null,
      lastDoc: null,
      limit: 15
    },
    'email-templates-list': {
      collectionName: 'emailTemplates',
      fields: [
        {
          title: 'Nom',
          code: 'title'
        },
        {
          title: 'Langue',
          code: 'language'
        }
      ],
      orderBy: {
        field: 'title',
        direction: 'asc'
      },
      defaultConditions: [],
      conditions: [],
      currentPage: 0,
      snapshotChanges: null,
      firstDoc: null,
      lastDoc: null,
      limit: 15
    },
    'aircrafts-compiled-list': {
      collectionName: 'aircraftsCompiled',
      fields: [
        {
          title: 'Compagnie aérienne',
          code: 'airlineTitle'
        },
        {
          title: 'Pays',
          code: 'airlineCountryTitle'
        },
        {
          title: 'Appareil',
          code: 'type'
        },
        {
          title: 'N°',
          code: 'count'
        },
        {
          title: 'Capacité',
          code: 'seatTotal'
        },
        {
          title: 'Classes',
          code: 'classes'
        },
        {
          title: 'Cible',
          code: 'targets'
        },
        {
          title: 'Âge moyen (an)',
          code: 'ageAverage'
        }
      ],
      orderBy: {
        field: 'airlineTitle',
        direction: 'asc'
      },
      defaultConditions: [],
      conditions: [],
      currentPage: 0,
      snapshotChanges: null,
      firstDoc: null,
      lastDoc: null,
      limit: 15
    },
    'aircrafts-list': {
      collectionName: 'aircrafts',
      fields: [
        {
          title: 'Compagnie',
          code: 'airlineTitle'
        },
        {
          title: 'MSN',
          code: 'serialNumber'
        },
        {
          title: 'Modèle',
          code: 'type'
        },
        {
          title: 'Immatriculation',
          code: 'registration'
        },
        {
          title: 'Date de livraison',
          code: 'firstFlightDate'
        }
      ],
      orderBy: {
        field: 'serialNumber',
        direction: 'asc'
      },
      defaultConditions: [],
      conditions: [],
      currentPage: 0,
      snapshotChanges: null,
      firstDoc: null,
      lastDoc: null,
      limit: 15
    },
    'aircraft-models-list': {
      collectionName: 'aircraftModels',
      fields: [
        {
          title: 'Nom',
          code: 'title'
        },
        {
          title: 'Famille',
          code: 'family'
        },
        {
          title: 'Cible',
          code: 'target'
        },
        {
          title: 'Vitesse (kts)',
          code: 'speedInKts'
        },
        {
          title: 'Autonomie (kms)',
          code: 'rangeInKms'
        },
        {
          title: 'Poids (kg)',
          code: 'weight'
        },
        {
          title: 'Volume (m<sup>3</sup>)',
          code: 'volume'
        }
      ],
      orderBy: {
        field: 'title',
        direction: 'asc'
      },
      defaultConditions: [],
      conditions: [],
      currentPage: 0,
      snapshotChanges: null,
      firstDoc: null,
      lastDoc: null,
      limit: 15
    },
    'simulateur-aircraft-models-list': {
      collectionName: 'simulateurAircraftModels',
      fields: [
        {
          title: 'Nom',
          code: 'title'
        },
        {
          title: 'Passagers maximum',
          code: 'paxMax'
        },
        {
          title: 'Vitesse (km/h)',
          code: 'speedInKmh'
        },
        {
          title: 'Autonomie maximum (kms)',
          code: 'rangeMaxInKms'
        },
        {
          title: 'Durée de vol maximum (h)',
          code: 'flyTimeInHours'
        },
        {
          title: 'Prix par heure (euros)',
          code: 'priceByHour'
        },
        {
          title: 'Prix par passager (euros)',
          code: 'priceByPax'
        },
        {
          title: 'Catégorie incendie',
          code: 'fireCategories'
        },
        {
          title: 'Longueur de piste minimum',
          code: 'minHardSurfaceRunwayInMeters'
        }
      ],
      orderBy: {
        field: 'title',
        direction: 'asc'
      },
      defaultConditions: [],
      conditions: [],
      currentPage: 0,
      snapshotChanges: null,
      firstDoc: null,
      lastDoc: null,
      limit: 15
    },
    'aircrafts-compiled-passengers-list': {
      collectionName: 'aircraftsCompiled',
      fields: [
        {
          title: 'Modèle',
          code: 'aircraftModelId'
        },
        {
          title: 'N°',
          code: 'count'
        },
        {
          title: 'Capacité',
          code: 'seatTotal'
        },
        {
          title: 'Classes',
          code: 'classes'
        },
        {
          title: 'Cible',
          code: 'target'
        },
        {
          title: 'Âge moyen (an)',
          code: 'ageAverage'
        }
      ],
      orderBy: {
        field: 'model',
        direction: 'asc'
      },
      defaultConditions: [
        {
          field: 'isCargo',
          operator: '==',
          value: false
        }
      ],
      conditions: [],
      currentPage: 0,
      snapshotChanges: null,
      firstDoc: null,
      lastDoc: null,
      limit: 15
    },
    'aircrafts-compiled-cargo-list': {
      collectionName: 'aircraftsCompiled',
      fields: [
        {
          title: 'Modèle',
          code: 'aircraftModelId'
        },
        {
          title: 'N°',
          code: 'count'
        },
        {
          title: 'Poids',
          code: 'weight'
        },
        {
          title: 'Volume',
          code: 'volume'
        },
        {
          title: 'Cible',
          code: 'target'
        },
        {
          title: 'Âge moyen (an)',
          code: 'ageAverage'
        }
      ],
      orderBy: {
        field: 'model',
        direction: 'asc'
      },
      defaultConditions: [
        {
          field: 'isCargo',
          operator: '==',
          value: true
        }
      ],
      conditions: [],
      currentPage: 0,
      snapshotChanges: null,
      firstDoc: null,
      lastDoc: null,
      limit: 15
    },
    'aircrafts-simplified-list': {
      collectionName: 'aircrafts',
      fields: [
        {
          title: 'MSN',
          code: 'serialNumber'
        },
        {
          title: 'Modèle',
          code: 'model'
        },
        {
          title: 'Immatriculation',
          code: 'registration'
        },
        {
          title: 'Date de livraison',
          code: 'firstFlightDate'
        },
        {
          title: 'Capacité',
          code: 'seatTotal'
        },
        {
          title: 'Cargo ?',
          code: 'isCargo'
        },
        {
          title: "Date d'ajout",
          code: 'created'
        }
      ],
      defaultConditions: [],
      conditions: [],
      currentPage: 0,
      snapshotChanges: null,
      firstDoc: null,
      lastDoc: null,
      limit: 15
    },
    'pipedriveOrganizations-list': {
      collectionName: 'pipedriveOrganizations',
      fields: [
        {
          title: 'Nom',
          code: 'name'
        },
        {
          title: 'Adresse',
          code: 'address'
        },
        {
          title: 'Dernière mise à jour',
          code: 'update_time'
        }
      ],
      orderBy: {
        field: 'name',
        direction: 'asc'
      },
      defaultConditions: [],
      conditions: [],
      currentPage: 0,
      snapshotChanges: null,
      firstDoc: null,
      lastDoc: null,
      limit: 15
    },
    'enquiries-list': {
      collectionName: 'enquiries',
      fields: [],
      orderBy: {
        field: 'created',
        direction: 'desc'
      },
      defaultConditions: [],
      conditions: [],
      currentPage: 0,
      snapshotChanges: null,
      firstDoc: null,
      lastDoc: null,
      limit: 15
    },
    'client-enquiries-list': {
      collectionName: 'enquiries',
      fields: [
        {
          title: 'Dossier',
          code: 'refEnquiryTitle'
        }
      ],
      orderBy: {
        field: 'refEnquiryTitle',
        direction: 'desc'
      },
      defaultConditions: [],
      conditions: [],
      currentPage: 0,
      snapshotChanges: null,
      firstDoc: null,
      lastDoc: null,
      limit: 15
    },
    'quotations-list': {
      collectionName: 'quotations',
      fields: [
        {
          title: 'Nom',
          code: 'title'
        },
        {
          title: 'Statut',
          code: 'status'
        },
        {
          title: 'Langue',
          code: 'language'
        },
        {
          title: 'Date de création',
          code: 'created'
        }
      ],
      orderBy: {
        field: 'created',
        direction: 'desc'
      },
      defaultConditions: [],
      conditions: [],
      currentPage: 0,
      snapshotChanges: null,
      firstDoc: null,
      lastDoc: null,
      limit: 15
    },
    'invoices-all-list': {
      collectionName: 'invoices',
      fields: [
        {
          title: 'Numéro de facture',
          code: 'ref'
        },
        {
          title: 'Type',
          code: 'status'
        },
        {
          title: 'Date de facture',
          code: 'created'
        },
        {
          title: "Date d'échéance",
          code: 'dueDate'
        },
        {
          title: 'Retard',
          code: 'late'
        },
        {
          title: 'Date du vol',
          code: 'firstFlightDate'
        },
        {
          title: 'Numéro de dossier',
          code: 'refContractTitle'
        },
        {
          title: 'Client / Fournisseur',
          code: 'clientTitle'
        },
        {
          title: 'Libellé',
          code: 'libelle'
        },
        {
          title: 'Envoyé ?',
          code: 'sent'
        },
        {
          title: 'Montant HT',
          code: 'amountHtTotal'
        },
        {
          title: 'Montant TVA',
          code: 'amountTvaTotal'
        },
        {
          title: 'Montant TTC',
          code: 'amountTtcTotal'
        },
        {
          title: 'Date du réglement',
          code: 'paymentsDate'
        },
        {
          title: 'Compte bancaire',
          code: 'bankAccount'
        },
        {
          title: 'Montant reglé',
          code: 'amountPaid'
        },
        {
          title: 'Montant restant',
          code: 'amountLeft'
        },
        {
          title: 'Notes internes',
          code: 'comment'
        }
      ],
      orderBy: {
        field: 'created',
        direction: 'desc'
      },
      defaultConditions: [],
      conditions: [],
      currentPage: 0,
      snapshotChanges: null,
      firstDoc: null,
      lastDoc: null,
      limit: 15
    },
    'invoices-proforma-list': {
      collectionName: 'invoices',
      fields: [
        {
          title: 'Numéro de facture',
          code: 'ref'
        },
        {
          title: 'Date de facture',
          code: 'created'
        },
        {
          title: "Date d'échéance",
          code: 'dueDate'
        },
        {
          title: 'Retard',
          code: 'late'
        },
        {
          title: 'Date du vol',
          code: 'firstFlightDate'
        },
        {
          title: 'Numéro de dossier',
          code: 'refContractTitle'
        },
        {
          title: 'Client',
          code: 'clientTitle'
        },
        {
          title: 'Libellé',
          code: 'libelle'
        },
        {
          title: 'Envoyé ?',
          code: 'sent'
        },
        {
          title: 'Montant HT',
          code: 'amountHtTotal'
        },
        {
          title: 'Montant TVA',
          code: 'amountTvaTotal'
        },
        {
          title: 'Montant TTC',
          code: 'amountTtcTotal'
        },
        {
          title: 'Date du réglement',
          code: 'paymentsDate'
        },
        {
          title: 'Compte bancaire',
          code: 'bankAccount'
        },
        {
          title: 'Montant reglé',
          code: 'amountPaid'
        },
        {
          title: 'Montant restant',
          code: 'amountLeft'
        },
        {
          title: 'Notes internes',
          code: 'comment'
        }
      ],
      orderBy: {
        field: 'created',
        direction: 'desc'
      },
      defaultConditions: [
        {
          field: 'invoiceType',
          operator: '==',
          value: EnumInvoiceType.proforma
        }
      ],
      conditions: [],
      currentPage: 0,
      snapshotChanges: null,
      firstDoc: null,
      lastDoc: null,
      limit: 15
    },
    'invoices-credit-note-proforma-credit-note-list': {
      collectionName: 'invoices',
      fields: [
        {
          title: 'Numéro de facture',
          code: 'ref'
        },
        {
          title: 'Date de facture',
          code: 'created'
        },
        {
          title: "Date d'échéance",
          code: 'dueDate'
        },
        {
          title: 'Retard',
          code: 'late'
        },
        {
          title: 'Date du vol',
          code: 'firstFlightDate'
        },
        {
          title: 'Numéro de dossier',
          code: 'refContractTitle'
        },
        {
          title: 'Client',
          code: 'clientTitle'
        },
        {
          title: 'Libellé',
          code: 'libelle'
        },
        {
          title: 'Envoyé ?',
          code: 'sent'
        },
        {
          title: 'Montant HT',
          code: 'amountHtTotal'
        },
        {
          title: 'Montant TVA',
          code: 'amountTvaTotal'
        },
        {
          title: 'Montant TTC',
          code: 'amountTtcTotal'
        },
        {
          title: 'Date du réglement',
          code: 'paymentsDate'
        },
        {
          title: 'Compte bancaire',
          code: 'bankAccount'
        },
        {
          title: 'Montant reglé',
          code: 'amountPaid'
        },
        {
          title: 'Montant restant',
          code: 'amountLeft'
        },
        {
          title: 'Notes internes',
          code: 'comment'
        }
      ],
      orderBy: {
        field: 'created',
        direction: 'desc'
      },
      defaultConditions: [
        {
          field: 'invoiceType',
          operator: 'in',
          value: [EnumInvoiceType.creditNote, EnumInvoiceType.proformaCreditNote]
        }
      ],
      conditions: [],
      currentPage: 0,
      snapshotChanges: null,
      firstDoc: null,
      lastDoc: null,
      limit: 15
    },
    'invoices-definitive-list': {
      collectionName: 'invoices',
      fields: [
        {
          title: 'Numéro de facture',
          code: 'ref'
        },
        {
          title: 'Date de facture',
          code: 'created'
        },
        {
          title: "Date d'échéance",
          code: 'dueDate'
        },
        {
          title: 'Retard',
          code: 'late'
        },
        {
          title: 'Date du vol',
          code: 'firstFlightDate'
        },
        {
          title: 'Numéro de dossier',
          code: 'refContractTitle'
        },
        {
          title: 'Client',
          code: 'clientTitle'
        },
        {
          title: 'Libellé',
          code: 'libelle'
        },
        {
          title: 'Envoyé ?',
          code: 'sent'
        },
        {
          title: 'Montant HT',
          code: 'amountHtTotal'
        },
        {
          title: 'Montant TVA',
          code: 'amountTvaTotal'
        },
        {
          title: 'Montant TTC',
          code: 'amountTtcTotal'
        },
        {
          title: 'Date du réglement',
          code: 'paymentsDate'
        },
        {
          title: 'Compte bancaire',
          code: 'bankAccount'
        },
        {
          title: 'Montant reglé',
          code: 'amountPaid'
        },
        {
          title: 'Montant restant',
          code: 'amountLeft'
        },
        {
          title: 'Notes internes',
          code: 'comment'
        }
      ],
      orderBy: {
        field: 'created',
        direction: 'desc'
      },
      defaultConditions: [
        {
          field: 'invoiceType',
          operator: '==',
          value: EnumInvoiceType.definitive
        }
      ],
      conditions: [],
      currentPage: 0,
      snapshotChanges: null,
      firstDoc: null,
      lastDoc: null,
      limit: 15
    },
    'invoices-purchase-list': {
      collectionName: 'invoices',
      fields: [
        {
          title: 'Numéro de facture',
          code: 'ref'
        },
        {
          title: 'Type',
          code: 'status'
        },
        {
          title: 'Date de facture',
          code: 'created'
        },
        {
          title: "Date d'échéance",
          code: 'dueDate'
        },
        {
          title: 'Retard',
          code: 'late'
        },
        {
          title: 'Numéro de dossier',
          code: 'refContractTitle'
        },
        {
          title: 'Fournisseur',
          code: 'clientTitle'
        },
        {
          title: 'Libellé',
          code: 'libelle'
        },
        {
          title: 'Envoyé ?',
          code: 'sent'
        },
        {
          title: 'Montant HT',
          code: 'amountHtTotal'
        },
        {
          title: 'Montant TVA',
          code: 'amountTvaTotal'
        },
        {
          title: 'Montant TTC',
          code: 'amountTtcTotal'
        },
        {
          title: 'Date du réglement',
          code: 'paymentsDate'
        },
        {
          title: 'Compte bancaire',
          code: 'bankAccount'
        },
        {
          title: 'Montant reglé',
          code: 'amountPaid'
        },
        {
          title: 'Montant restant',
          code: 'amountLeft'
        },
        {
          title: 'Notes internes',
          code: 'comment'
        }
      ],
      orderBy: {
        field: 'created',
        direction: 'desc'
      },
      defaultConditions: [
        {
          field: 'invoiceType',
          operator: 'in',
          value: [EnumInvoiceType.purchase, EnumInvoiceType.purchaseCreditNote]
        }
      ],
      conditions: [],
      currentPage: 0,
      snapshotChanges: null,
      firstDoc: null,
      lastDoc: null,
      limit: 15
    },
    'invoices-all-without-proforma-list': {
      collectionName: 'invoices',
      fields: [
        {
          title: 'Numéro de facture',
          code: 'ref'
        },
        {
          title: 'Date de facture',
          code: 'created'
        },
        {
          title: "Date d'échéance",
          code: 'dueDate'
        },
        {
          title: 'Retard',
          code: 'late'
        },
        {
          title: 'Date du vol',
          code: 'firstFlightDate'
        },
        {
          title: 'Numéro de dossier',
          code: 'refContractTitle'
        },
        {
          title: 'Client / Fournisseur',
          code: 'clientTitle'
        },
        {
          title: 'Libellé',
          code: 'libelle'
        },
        {
          title: 'Envoyé ?',
          code: 'sent'
        },
        {
          title: 'Montant HT',
          code: 'amountHtTotal'
        },
        {
          title: 'Montant TVA',
          code: 'amountTvaTotal'
        },
        {
          title: 'Montant TTC',
          code: 'amountTtcTotal'
        },
        {
          title: 'Date du réglement',
          code: 'paymentsDate'
        },
        {
          title: 'Compte bancaire',
          code: 'bankAccount'
        },
        {
          title: 'Montant reglé',
          code: 'amountPaid'
        },
        {
          title: 'Montant restant',
          code: 'amountLeft'
        },
        {
          title: 'Notes internes',
          code: 'comment'
        }
      ],
      orderBy: [
        {
          field: 'created',
          direction: 'desc'
        }
      ],
      defaultConditions: [
        {
          field: 'definitiveInvoice',
          operator: '==',
          value: true
        }
      ],
      conditions: [],
      currentPage: 0,
      snapshotChanges: null,
      firstDoc: null,
      lastDoc: null,
      limit: 15
    },
    'invoices-credit-note-list': {
      collectionName: 'invoices',
      fields: [
        {
          title: 'Numéro de facture',
          code: 'ref'
        },
        {
          title: 'Date de facture',
          code: 'created'
        },
        {
          title: "Date d'échéance",
          code: 'dueDate'
        },
        {
          title: 'Retard',
          code: 'late'
        },
        {
          title: 'Date du vol',
          code: 'firstFlightDate'
        },
        {
          title: 'Numéro de dossier',
          code: 'refContractTitle'
        },
        {
          title: 'Client',
          code: 'clientTitle'
        },
        {
          title: 'Libellé',
          code: 'libelle'
        },
        {
          title: 'Envoyé ?',
          code: 'sent'
        },
        {
          title: 'Montant HT',
          code: 'amountHtTotal'
        },
        {
          title: 'Montant TVA',
          code: 'amountTvaTotal'
        },
        {
          title: 'Montant TTC',
          code: 'amountTtcTotal'
        },
        {
          title: 'Date du réglement',
          code: 'paymentsDate'
        },
        {
          title: 'Compte bancaire',
          code: 'bankAccount'
        },
        {
          title: 'Montant reglé',
          code: 'amountPaid'
        },
        {
          title: 'Montant restant',
          code: 'amountLeft'
        },
        {
          title: 'Notes internes',
          code: 'comment'
        }
      ],
      orderBy: {
        field: 'created',
        direction: 'desc'
      },
      defaultConditions: [
        {
          field: 'invoiceType',
          operator: '==',
          value: EnumInvoiceType.creditNote
        }
      ],
      conditions: [],
      currentPage: 0,
      snapshotChanges: null,
      firstDoc: null,
      lastDoc: null,
      limit: 15
    },
    'invoices-cancelled-list': {
      collectionName: 'invoices',
      fields: [
        {
          title: 'Numéro de facture',
          code: 'ref'
        },
        {
          title: 'Date de facture',
          code: 'created'
        },
        {
          title: "Date d'échéance",
          code: 'dueDate'
        },
        {
          title: 'Retard',
          code: 'late'
        },
        {
          title: 'Date du vol',
          code: 'firstFlightDate'
        },
        {
          title: 'Numéro de dossier',
          code: 'refContractTitle'
        },
        {
          title: 'Client',
          code: 'clientTitle'
        },
        {
          title: 'Libellé',
          code: 'libelle'
        },
        {
          title: 'Envoyé ?',
          code: 'sent'
        },
        {
          title: 'Montant HT',
          code: 'amountHtTotal'
        },
        {
          title: 'Montant TVA',
          code: 'amountTvaTotal'
        },
        {
          title: 'Montant TTC',
          code: 'amountTtcTotal'
        },
        {
          title: 'Date du réglement',
          code: 'paymentsDate'
        },
        {
          title: 'Compte bancaire',
          code: 'bankAccount'
        },
        {
          title: 'Montant reglé',
          code: 'amountPaid'
        },
        {
          title: 'Montant restant',
          code: 'amountLeft'
        },
        {
          title: 'Notes internes',
          code: 'comment'
        }
      ],
      orderBy: {
        field: 'created',
        direction: 'desc'
      },
      defaultConditions: [
        {
          field: 'status',
          operator: '==',
          value: 'cancelled'
        }
      ],
      conditions: [],
      currentPage: 0,
      snapshotChanges: null,
      firstDoc: null,
      lastDoc: null,
      limit: 15
    },
    'encaissement-types-list': {
      collectionName: 'encaissementTypes',
      fields: [
        {
          title: 'Nom',
          code: 'name'
        }
      ],
      orderBy: {
        field: 'name',
        direction: 'asc'
      },
      defaultConditions: [],
      conditions: [],
      currentPage: 0,
      snapshotChanges: null,
      firstDoc: null,
      lastDoc: null,
      limit: 15
    },
    'encaissements-list': {
      collectionName: 'encaissements',
      fields: [
        {
          title: 'Libellé',
          code: 'libelle'
        },
        {
          title: "Type d'encaissement",
          code: 'encaissementTypeId'
        },
        {
          title: 'Montant',
          code: 'amount'
        },
        {
          title: 'Devise',
          code: 'currency'
        },
        {
          title: 'Compte bancaire',
          code: 'bankAccountId'
        },
        {
          title: 'Facture',
          code: 'invoiceId'
        },
        {
          title: 'Date',
          code: 'date'
        },
        {
          title: 'Pointé ?',
          code: 'isReconciled'
        },
        {
          title: 'Ajouté par',
          code: 'createdBy'
        },
        {
          title: 'Ajouté le',
          code: 'created'
        }
      ],
      orderBy: {
        field: 'created',
        direction: 'desc'
      },
      defaultConditions: [],
      conditions: [],
      currentPage: 0,
      snapshotChanges: null,
      firstDoc: null,
      lastDoc: null,
      limit: 15
    },
    'bank-accounts-list': {
      collectionName: 'bankAccounts',
      fields: [
        {
          title: 'Nom',
          code: 'name'
        },
        {
          title: 'IBAN',
          code: 'iban'
        },
        {
          title: 'Devise principale',
          code: 'defaultCurrency'
        },
        {
          title: 'Activé pour la facturation ?',
          code: 'enableForInvoicing'
        }
      ],
      orderBy: {
        field: 'name',
        direction: 'asc'
      },
      defaultConditions: [],
      conditions: [],
      currentPage: 0,
      snapshotChanges: null,
      firstDoc: null,
      lastDoc: null,
      limit: 15
    },
    'flight-checklist-templates-list': {
      collectionName: 'flightChecklistTemplates',
      fields: [
        {
          title: 'Département',
          code: 'enquiryType'
        }
      ],
      orderBy: {
        field: 'enquiryType',
        direction: 'asc'
      },
      defaultConditions: [],
      conditions: [],
      currentPage: 0,
      snapshotChanges: null,
      firstDoc: null,
      lastDoc: null,
      limit: 15
    },
    'crawler-urls-list': {
      collectionName: 'crawlerUrls',
      fields: [
        {
          title: 'URL',
          code: 'url'
        },
        {
          title: 'Statut',
          code: 'status'
        },
        {
          title: 'Date',
          code: 'dateStart'
        },
        {
          title: 'Source',
          code: 'source'
        },
        {
          title: "Valide jusqu'au",
          code: 'nextDateToUpdate'
        },
        {
          title: "Date d'ajout",
          code: 'created'
        }
      ],
      orderBy: {
        field: 'dateStart',
        direction: 'desc'
      },
      defaultConditions: [],
      conditions: [],
      currentPage: 0,
      snapshotChanges: null,
      firstDoc: null,
      lastDoc: null,
      limit: 15
    },
    'flight-briefs-list': {
      collectionName: 'flightBriefs',
      fields: [
        {
          title: 'Version',
          code: 'title'
        },
        {
          title: 'Langue',
          code: 'language'
        },
        {
          title: 'Date de création',
          code: 'created'
        },
        {
          title: 'Créé par qui ?',
          code: 'createdBy'
        },
        {
          title: "Date d'envoi",
          code: 'sendDate'
        },
        {
          title: 'Si envoyé, par qui ?',
          code: 'sentBy'
        }
      ],
      orderBy: {
        field: 'title',
        direction: 'desc'
      },
      defaultConditions: [],
      conditions: [],
      currentPage: 0,
      snapshotChanges: null,
      firstDoc: null,
      lastDoc: null,
      limit: 15
    },
    'flight-brief-caterings-list': {
      collectionName: 'flightBriefCaterings',
      fields: [
        {
          title: 'Langue',
          code: 'language'
        },
        {
          title: 'Texte',
          code: 'text'
        },
        {
          title: 'Position',
          code: 'position'
        }
      ],
      orderBy: {
        field: 'language',
        direction: 'asc'
      },
      defaultConditions: [],
      conditions: [],
      currentPage: 0,
      snapshotChanges: null,
      firstDoc: null,
      lastDoc: null,
      limit: 15
    },
    'countries-list': {
      collectionName: 'countries',
      fields: [
        {
          title: 'Pays',
          code: 'code'
        },
        {
          title: 'Est incident diplomatique ?',
          code: 'hasDiplomaticDifficulties'
        }
      ],
      orderBy: {
        field: 'code',
        direction: 'asc'
      },
      defaultConditions: [],
      conditions: [],
      currentPage: 0,
      snapshotChanges: null,
      firstDoc: null,
      lastDoc: null,
      limit: 15
    },
    'employee-bonus-rules-list': {
      collectionName: 'employeeBonusRules',
      fields: [
        {
          title: 'Nom',
          code: 'name'
        },
        {
          title: 'Département(s) concerné(s)',
          code: 'enquiryTypes'
        },
        {
          title: 'Montant bonus',
          code: 'amountBonus'
        },
        {
          title: 'Mois début',
          code: 'dateStart'
        },
        {
          title: 'Mois de fin',
          code: 'dateEnd'
        }
      ],
      orderBy: {
        field: 'name',
        direction: 'asc'
      },
      defaultConditions: [],
      conditions: [],
      currentPage: 0,
      snapshotChanges: null,
      firstDoc: null,
      lastDoc: null,
      limit: 15
    },
    'export-requests-list': {
      collectionName: 'exportRequests',
      fields: [
        {
          title: 'Type',
          code: 'type'
        },
        {
          title: 'Status',
          code: 'status'
        },
        {
          title: 'Date de création',
          code: 'created'
        }
      ],
      orderBy: {
        field: 'created',
        direction: 'desc'
      },
      defaultConditions: [],
      conditions: [],
      currentPage: 0,
      snapshotChanges: null,
      firstDoc: null,
      lastDoc: null,
      limit: 15
    },
    'fbos-list': {
      collectionName: 'fbos',
      fields: [
        {
          title: 'Name',
          code: 'name'
        },
        {
          title: 'Adresse',
          code: 'street'
        },
        {
          title: 'Date de création',
          code: 'created'
        }
      ],
      orderBy: null,
      defaultConditions: [],
      conditions: [],
      currentPage: 0,
      snapshotChanges: null,
      firstDoc: null,
      lastDoc: null,
      limit: 15
    },
    'handlings-list': {
      collectionName: 'handlings',
      fields: [
        {
          title: 'Name',
          code: 'name'
        },
        {
          title: 'Adresse',
          code: 'street'
        },
        {
          title: 'Date de création',
          code: 'created'
        }
      ],
      orderBy: null,
      defaultConditions: [],
      conditions: [],
      currentPage: 0,
      snapshotChanges: null,
      firstDoc: null,
      lastDoc: null,
      limit: 15
    },
    'crews-list': {
      collectionName: 'crews',
      fields: [
        {
          title: 'Prénom',
          code: 'firstname'
        },
        {
          title: 'Nom',
          code: 'lastname'
        },
        {
          title: 'Type',
          code: 'type'
        },
        {
          title: 'Date de création',
          code: 'created'
        }
      ],
      orderBy: null,
      defaultConditions: [],
      conditions: [],
      currentPage: 0,
      snapshotChanges: null,
      firstDoc: null,
      lastDoc: null,
      limit: 15
    },
    'airline-supervisors-list': {
      collectionName: 'airlineSupervisors',
      fields: [
        {
          title: 'Prénom',
          code: 'firstname'
        },
        {
          title: 'Nom',
          code: 'lastname'
        },
        {
          title: 'Date de création',
          code: 'created'
        }
      ],
      orderBy: null,
      defaultConditions: [],
      conditions: [],
      currentPage: 0,
      snapshotChanges: null,
      firstDoc: null,
      lastDoc: null,
      limit: 15
    }
  };

  constructor(private afFirestore: AngularFirestore, private remoteService: RemoteService) {
    this.remoteService.isLoggedObservable.subscribe((isLogged: boolean) => {
      this.isLogged = isLogged;

      if (!this.isLogged) {
        for (const model in this.pagination) {
          if (this.pagination[model].snapshotChanges) {
            this.pagination[model].snapshotChanges.unsubscribe();
          }
        }
      }
    });

    for (const model in this.pagination) {
      this.setDefaultsSettings(model);
    }
  }

  fetchData(model: string, directionPagination: string = ''): Promise<number> {
    return new Promise((resolve, reject) => {
      if (!this.isLogged) {
        setTimeout(() => {
          this.fetchData(model, directionPagination)
            .then((numberRecords: number) => {
              resolve(numberRecords);
            })
            .catch(err => {
              if (directionPagination == 'next') {
                alert("Il n'y a plus rien à afficher");

                this.pagination[model].currentPage--;

                resolve(0);
              } else {
                reject(err);
              }
            });
        }, 500);
      } else {
        this.loading = true;

        switch (directionPagination) {
          case 'next':
            this.pagination[model].currentPage++;
            break;
          case 'prev':
            this.pagination[model].currentPage--;
            break;
        }

        let orderBy: Array<{
          field: string;
          direction: string;
        }> = [];

        if (this.pagination[model]) {
          if (this.pagination[model].orderBy) {
            if (Array.isArray(this.pagination[model].orderBy)) {
              for (const oneOrderBy of this.pagination[model].orderBy) {
                orderBy.push(oneOrderBy);
              }
            } else {
              orderBy.push(this.pagination[model].orderBy);
            }
          }

          this.getSnapshotPaginated(
            model,
            directionPagination,
            orderBy,
            this.pagination[model].limit,
            this.pagination[model].conditions
          )
            .then((numberRecords: number) => {
              this.loading = false;

              resolve(numberRecords);
            })
            .catch(err => {
              this.loading = false;

              if (directionPagination == 'next') {
                alert("Il n'y a plus rien à afficher");

                this.pagination[model].currentPage--;

                resolve(0);
              } else {
                reject(err);
              }
            });
        } else {
          resolve(0);
        }
      }
    });
  }

  private getSnapshotPaginated(
    model: string,
    directionPagination: string = null,
    sortsBy: Array<{
      field: string;
      direction: string;
    }>,
    limit: number = 15,
    conditions: Array<{
      field: string;
      operator: any;
      value: any;
    }> = []
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      if (this.pagination[model].snapshotChanges) {
        this.pagination[model].snapshotChanges.unsubscribe();
      }

      this.pagination[model].snapshotChanges = this.afFirestore
        .collection(
          this.pagination[model].collectionName,
          (ref: firebase.firestore.Query<firebase.firestore.DocumentData>) => {
            let docRef = ref;

            for (const sortBy of sortsBy) {
              docRef = docRef.orderBy(sortBy.field, sortBy.direction == 'asc' ? 'asc' : 'desc');
            }

            if (
              this.pagination[model].currentPage > 0 ||
              (this.pagination[model].currentPage === 0 && directionPagination == 'prev')
            ) {
              if (directionPagination == 'next') {
                docRef = docRef.startAfter(this.pagination[model].lastDoc).limit(limit);
              } else if (directionPagination == 'prev') {
                docRef = docRef.endBefore(this.pagination[model].firstDoc).limitToLast(limit);
              } else if (this.pagination[model].firstDoc) {
                docRef = docRef.startAt(this.pagination[model].firstDoc).limit(limit);
              } else if (limit > 0) {
                docRef = docRef.limit(limit);
              }
            } else if (limit > 0) {
              docRef = docRef.limit(limit);
            }

            if (conditions.length) {
              for (let condition of conditions) {
                docRef = docRef.where(
                  condition['field'],
                  condition['operator'],
                  condition['value']
                );
              }
            }

            return docRef;
          }
        )
        .snapshotChanges()
        .subscribe(documentChanges => {
          if (documentChanges.length) {
            this.pagination[model].firstDoc = documentChanges[0].payload.doc;
            this.pagination[model].lastDoc =
              documentChanges[documentChanges.length - 1].payload.doc;

            let data = [];

            documentChanges.forEach(documentChange => {
              let documentData: any = documentChange.payload.doc.data();
              documentData['id'] = documentChange.payload.doc.id;

              const obj = this.convertDocObjToClass(
                this.pagination[model].collectionName,
                documentData
              );

              data.push(obj);
            });

            this.pagination[model].data = data;

            resolve(documentChanges.length);
          } else if (directionPagination == 'next') {
            reject();
          } else {
            this.pagination[model].data = [];

            resolve(0);
          }
        });
    });
  }

  private convertDocObjToClass(collection: string, data: object): any {
    switch (collection) {
      case 'users':
        return this.timestampsToDate(data) as IUser;
      case 'airlines':
        return this.timestampsToDate(data) as IAirline;
      case 'aircrafts':
        return this.timestampsToDate(data) as IAircraft;
      case 'aircraftsCompiled':
        return this.timestampsToDate(data) as IAircraftCompiled;
      case 'aircraftModels':
        return this.timestampsToDate(data) as IAircraftModel;
      case 'clients':
        return this.timestampsToDate(data) as IClient;
      case 'enquiries':
        return this.timestampsToDate(data) as IEnquiry;
      case 'pipedriveOrganizations':
        return this.timestampsToDate(data) as IPipedriveOrganization;
      case 'pipedrivePersons':
        return this.timestampsToDate(data) as IPipedrivePerson;
      case 'airports':
        return this.timestampsToDate(data) as IAirport;
      case 'emailTemplates':
        return this.timestampsToDate(data) as IEmailTemplate;
      case 'quotations':
        return this.timestampsToDate(data) as IQuotation;
      case 'invoices':
        return this.timestampsToDate(data) as IInvoice;
      case 'encaissementTypes':
        return this.timestampsToDate(data) as IEncaissementType;
      case 'encaissements':
        return this.timestampsToDate(data) as IEncaissement;
      case 'crawlerUrls':
        return this.timestampsToDate(data) as ICrawlerUrl;
      case 'flightBriefs':
        return this.timestampsToDate(data) as IFlightBrief;
      case 'countries':
        return this.timestampsToDate(data) as ICountry;
      case 'employeeBonusRules':
        return this.timestampsToDate(data) as IEmployeeBonusRule;
      case 'exportRequests':
        return this.timestampsToDate(data) as IExportRequest;
      case 'fbos':
        return this.timestampsToDate(data) as IFbo;
      case 'handlings':
        return this.timestampsToDate(data) as IHandling;
      case 'crews':
        return this.timestampsToDate(data) as ICrew;
      case 'airlineSupervisors':
        return this.timestampsToDate(data) as IAirlineSupervisor;
      default:
        return this.timestampsToDate(data);
    }
  }

  async sortField(model: string, fieldCode: string): Promise<void> {
    if (this.pagination[model].orderBy) {
      if (this.pagination[model].orderBy.field == fieldCode) {
        this.pagination[model].orderBy.direction =
          this.pagination[model].orderBy.direction == 'asc' ? 'desc' : 'asc';
      } else {
        this.pagination[model].orderBy.field = fieldCode;
        this.pagination[model].orderBy.direction = 'asc';
      }
    }

    this.reset(model);

    await this.fetchData(model);
  }

  async loadPreviousPage(model: string): Promise<number> {
    return await this.fetchData(model, 'prev');
  }

  async loadNextPage(model: string): Promise<number> {
    return await this.fetchData(model, 'next');
  }

  reset(model: string, resetDefaultSettings: boolean = false): void {
    if (this.pagination[model].snapshotChanges) {
      this.pagination[model].snapshotChanges.unsubscribe();
    }
    this.pagination[model].snapshotChanges = null;
    this.pagination[model].firstDoc = null;
    this.pagination[model].lastDoc = null;
    this.pagination[model].currentPage = 0;
    this.pagination[model].data = [];

    if (resetDefaultSettings) {
      this.setDefaultsSettings(model);
    }
  }

  setDefaultsSettings(model): void {
    this.pagination[model].conditions = Object.assign([], this.pagination[model].defaultConditions);
  }

  private timestampsToDate(data: any): any {
    for (const field in data) {
      if (data[field] && typeof data[field].seconds !== 'undefined') {
        data[field] = (data[field] as unknown as firebase.firestore.Timestamp).toDate();
      }
    }

    return data;
  }
}
