import { Component, Input } from '@angular/core';
import {
  IAlertOptions,
  EnumAlertOptionFlexAlign,
  EnumAlertOptionTextAlign,
  EnumAlertOptionSize,
  AlertService,
  IAlertOptionsButton
} from '../../services/alert/alert.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {
  alertOptions: IAlertOptions | null = null;

  EnumAlertOptionFlexAlign = EnumAlertOptionFlexAlign;
  EnumAlertOptionTextAlign = EnumAlertOptionTextAlign;
  EnumAlertOptionSize = EnumAlertOptionSize;

  dropdownValue: any = null;

  constructor(private alertService: AlertService) {
    this.alertService.alertOptionsObservable.subscribe((alertOptions: IAlertOptions | null) => {
      this.alertOptions = alertOptions;

      this.dropdownValue = null;
    });
  }

  clickFooterBtn(button: IAlertOptionsButton): void {
    if (button.callback) {
      button.callback(this.dropdownValue);
    }
  }
}
