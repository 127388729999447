import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { EnquiryFlightsAccordionComponent } from './enquiry-flights-accordion.component';
import { EnquiryFlightEditModule } from '../enquiry-flight-edit/enquiry-flight-edit.module';
import { RouterModule } from '@angular/router';
import { EnquiryFlightAddModule } from '../enquiry-flight-add/enquiry-flight-add.module';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    EnquiryFlightEditModule,
    RouterModule,
    EnquiryFlightAddModule
  ],
  declarations: [EnquiryFlightsAccordionComponent],
  exports: [EnquiryFlightsAccordionComponent]
})
export class EnquiryFlightsAccordionModule {}
