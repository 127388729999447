import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { PaginationService } from '../../../services/pagination.service';
import { AclService } from '../../../services/acl.service';

import { EnumEnquiryStatus, getEnumEnquiryStatusLabel } from 'src/app/enums/enquiry-status.enum';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { EnumEnquiryType } from 'src/app/enums/enquiry-type.enum';
import { EnumEnquiryCancelledReason } from 'src/app/enums/enquiry-cancelled-reason.enum';

@Component({
  selector: 'app-list-enquiries',
  templateUrl: './list-enquiries.component.html',
  styleUrls: ['./list-enquiries.component.scss']
})
export class ListEnquiriesComponent implements OnInit {
  filterByPeriod: string;
  userId: string | null = null;
  status: EnumEnquiryStatus | null = null;
  enquiryType: EnumEnquiryType | null = null;
  cancelledReason: EnumEnquiryCancelledReason | null = null;
  period: string;

  constructor(
    public paginationService: PaginationService,
    private aclService: AclService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.activatedRoute.url.subscribe(async () => {
      this.userId = this.activatedRoute.snapshot.paramMap.get('userId');
      const status = this.activatedRoute.snapshot.paramMap.get('status');
      this.period = this.activatedRoute.snapshot.paramMap.get('period');
      const enquiryType = this.activatedRoute.snapshot.paramMap.get('enquiryType');
      const cancelledReason = this.activatedRoute.snapshot.paramMap.get('cancelledReason');

      if (status !== 'all') {
        this.status = status as EnumEnquiryStatus;
      } else {
        this.status = null;
      }
      if (enquiryType !== 'all') {
        this.enquiryType = enquiryType as EnumEnquiryType;
      } else {
        this.enquiryType = null;
      }
      if (cancelledReason !== 'all') {
        this.cancelledReason = cancelledReason as EnumEnquiryCancelledReason;
      } else {
        this.cancelledReason = null;
      }

      this.filterByPeriod = this.period + '-01';

      await this.aclService.checkAclAccess(EnumAcl.usersUserEnquiries);
    });
  }

  ngOnDestroy(): void {
    window['$']('.table-responsive').floatingScroll('destroy');
  }
}
