import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { PaginationService } from '../../../services/pagination.service';
import { RemoteService } from '../../../services/remote.service';
import { PipedriveService } from '../../../services/pipedrive.service';
import { AclService } from '../../../services/acl.service';

import { EnumAcl } from 'src/app/enums/acl.enum';
import { IUser } from 'src/app/interfaces/user.interface';
import { IPipedriveOrganization, IPipedrivePerson } from 'src/app/interfaces/pipedrive.interface';
import { IClientBillingInfos } from 'src/app/interfaces/client-billing-infos.interface';

import countries from '../../../countries_fr.json';

@Component({
  selector: 'app-client-view',
  templateUrl: './client-view.component.html',
  styleUrls: ['./client-view.component.scss']
})
export class ClientViewComponent implements OnInit {
  rootUrl: string = './';
  loading: boolean = false;
  loadingUsers: boolean = false;
  clientId: string;
  client: IPipedriveOrganization | null = null;
  isLogged: boolean = false;
  currentPagination: string = 'client-enquiries-list';
  usersObj: object = {};

  refreshingFromPipedrive: boolean = false;
  deleting: boolean = false;

  loadingClientBillingInfos: boolean = false;
  clientBillingInfos: IClientBillingInfos | null = null;

  EnumAcl = EnumAcl;
  countries = countries;

  constructor(
    private remoteService: RemoteService,
    private pipedriveService: PipedriveService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public paginationService: PaginationService,
    private aclService: AclService
  ) {
    this.rootUrl = window['rootUrl'];

    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
  }

  ngOnInit(): void {
    this.activatedRoute.url.subscribe(() => {
      this.clientId = this.activatedRoute.snapshot.paramMap.get('clientId');

      this.loadData();
    });
  }

  loadData(): void {
    if (this.isLogged) {
      this.loadClient();
      this.loadClientBillingInfos();
      this.fetchEnquiries();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  async loadClient(): Promise<void> {
    this.loading = true;

    try {
      this.client = await this.pipedriveService.getOrganization(this.clientId);
    } catch (err) {
      console.log(
        '🚀 ~ file: client-view.component.ts:77 ~ ClientViewComponent ~ loadClient ~ err:',
        err
      );
    } finally {
      this.loading = false;
    }
  }

  async loadClientBillingInfos(): Promise<void> {
    this.loadingClientBillingInfos = true;

    try {
      this.clientBillingInfos = (await this.remoteService.getDocument(
        'clientBillingInfos',
        this.clientId
      )) as IClientBillingInfos | null;
    } catch (err) {
      console.log(
        '🚀 ~ file: client-view.component.ts:95 ~ ClientViewComponent ~ loadClientBillingInfos ~ err:',
        err
      );
    } finally {
      this.loadingClientBillingInfos = false;
    }
  }

  async fetchEnquiries(): Promise<void> {
    this.paginationService.pagination[this.currentPagination].conditions = [
      {
        field: 'clientId',
        operator: '==',
        value: this.clientId
      }
    ];

    await this.paginationService.fetchData(this.currentPagination);

    this.loadUsers();
  }

  async loadUsers(): Promise<void> {
    if (this.paginationService.pagination[this.currentPagination].data) {
      this.loadingUsers = true;

      const usersId: string[] = [];

      for (const enquiry of this.paginationService.pagination[this.currentPagination].data) {
        for (const field of ['receivedBy', 'processedBy']) {
          if (usersId.indexOf(enquiry[field]) === -1) {
            usersId.push(enquiry[field]);
          }
        }
      }

      const docs: Array<object> = await this.remoteService.getDocumentsFromDocId('users', usersId);

      for (const doc of docs) {
        this.usersObj[doc['id']] = doc as IUser;
      }

      this.loadingUsers = false;
    }
  }

  delete(): void {
    if (this.hasAclAccess(EnumAcl.clientsDelete)) {
      const result = confirm(
        'La suppression du client sera permanent. Il sera toujours sauvegardé dans Pipedrive. Êtes-vous sûr de vouloir continuer?'
      );

      if (result) {
        this.deleting = true;

        this.remoteService
          .deleteDocumentInCollection('pipedriveOrganizations', this.clientId)
          .then(() => {
            this.router.navigate(['/admin/clients']);

            this.deleting = false;

            alert('La suppression a été effectuée avec succès.');
          })
          .catch(err => {
            console.log(err);

            this.deleting = false;
          });
      }
    }
  }

  async importFromPipedrive(): Promise<void> {
    if (this.clientId) {
      this.refreshingFromPipedrive = true;

      this.client = await this.pipedriveService.getOrganization(this.clientId.toString(), true);

      if (this.client) {
        await this.remoteService.setDocumentToCollection(
          'pipedriveOrganizations',
          this.clientId.toString(),
          JSON.parse(JSON.stringify(this.client))
        );

        const clientBillingInfosDoc: object = await this.remoteService.getDocument(
          'clientBillingInfos',
          this.clientId.toString()
        );

        if (clientBillingInfosDoc) {
          await this.remoteService.updateDocumentToCollection(
            'clientBillingInfos',
            this.clientId.toString(),
            {
              clientName: this.client.name
            }
          );
        }

        const persons: IPipedrivePerson[] = await this.pipedriveService.getPersonsFromOrganization(
          this.clientId.toString()
        );

        for (const person of persons) {
          await this.remoteService.setDocumentToCollection(
            'pipedrivePersons',
            person.id.toString(),
            JSON.parse(JSON.stringify(person))
          );
        }

        window.location.reload();
      }

      this.refreshingFromPipedrive = false;
    }
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }
}
