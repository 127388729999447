import { Component, OnDestroy, OnInit } from '@angular/core';

import { PaginationService } from '../../../services/pagination.service';
import { AclService } from '../../../services/acl.service';

import countries from '../../../countries_fr.json';

import { RemoteService } from 'src/app/services/remote.service';
import { ActivatedRoute } from '@angular/router';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { IUser, getUserFullname } from 'src/app/interfaces/user.interface';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/user/user.service';
import { IInvoice, getInvoiceTitle } from 'src/app/interfaces/invoice.interface';
import { InvoiceService } from 'src/app/services/invoices/invoices.service';
import { IEncaissementType } from 'src/app/interfaces/encaissement-type.interface';
import { IBankAccount } from 'src/app/interfaces/bank-account.interface';
import { IEncaissement } from 'src/app/interfaces/encaissement.interface';
import { faFileImport } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-list-encaissements',
  templateUrl: './list-encaissements.component.html',
  styleUrls: ['./list-encaissements.component.scss']
})
export class ListEncaissementsComponent implements OnInit, OnDestroy {
  EnumAcl = EnumAcl;

  faFileImport = faFileImport;

  currentPagination: string = 'encaissements-list';
  countriesList: Array<{
    title: string;
    value: string;
  }> = [];
  encaissements: IEncaissement[] = [];

  bankAccountsList: IBankAccount[] = [];
  bankAccountsObj = {};

  encaissementTypesList: IEncaissementType[] = [];
  encaissementTypesObj = {};

  invoicesObj: { [id: string]: IInvoice | null } = {};
  usersObj: { [id: string]: IUser } = {};

  invoiceId: string;

  getUserFullname = getUserFullname;
  getInvoiceTitle = getInvoiceTitle;

  subscriptions = new Subscription();

  constructor(
    public paginationService: PaginationService,
    private aclService: AclService,
    private remoteService: RemoteService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private invoiceService: InvoiceService
  ) {
    for (const code in countries) {
      this.countriesList.push({
        title: countries[code],
        value: code
      });
    }
  }

  async ngOnInit(): Promise<void> {
    await this.aclService.checkAclAccess(EnumAcl.encaissementsList);

    this.activatedRoute.url.subscribe(async () => {
      this.invoiceId = this.activatedRoute.snapshot.paramMap.get('invoiceId');
    });

    this.paginationService.pagination[this.currentPagination].currentPage = 0;

    this.fetchData();

    this.loadBankAccounts();
    this.loadEncaissementTypes();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  async fetchData(): Promise<void> {
    this.paginationService.pagination[this.currentPagination].conditions = [];

    if (this.invoiceId) {
      this.paginationService.pagination[this.currentPagination].conditions.push({
        field: 'invoiceId',
        operator: '==',
        value: this.invoiceId
      });
    }

    await this.paginationService.fetchData(this.currentPagination);

    const invoiceIds: string[] = [];
    for (const encaissement of this.paginationService.pagination[this.currentPagination].data) {
      if (encaissement.invoiceIds?.length) {
        for (const invoiceId of encaissement.invoiceIds) {
          invoiceIds.push(invoiceId);
        }
      }

      if (encaissement.createdBy) {
        this.loadUser(encaissement.createdBy);
      }
    }

    if (invoiceIds.length) {
      this.loadInvoices(invoiceIds);
    }
  }

  async loadBankAccounts(): Promise<void> {
    const docs = await this.remoteService.getAllDocuments('bankAccounts', {
      field: 'name',
      direction: 'asc'
    });

    this.bankAccountsList = [];
    for (const doc of docs) {
      const bankAccount = doc as IBankAccount;

      this.bankAccountsList.push(bankAccount);
      this.bankAccountsObj[bankAccount.id] = bankAccount;
    }
  }

  async loadEncaissementTypes(): Promise<void> {
    const docs = await this.remoteService.getAllDocuments('encaissementTypes', {
      field: 'name',
      direction: 'asc'
    });

    this.encaissementTypesList = [];
    for (const doc of docs) {
      const encaissementType = doc as IEncaissementType;

      this.encaissementTypesList.push(encaissementType);
      this.encaissementTypesObj[encaissementType.id] = encaissementType;
    }
  }

  async loadInvoices(invoiceIds: string[]): Promise<void> {
    for (const invoiceId of invoiceIds) {
      if (typeof this.invoicesObj[invoiceId] === 'undefined') {
        this.invoicesObj[invoiceId] = null;
        this.subscriptions.add(
          this.invoiceService.getFromId(invoiceId).subscribe((invoice: IInvoice) => {
            this.invoicesObj[invoiceId] = invoice;
          })
        );
      }
    }
  }

  loadUser(userId: string): void {
    if (typeof this.usersObj[userId] === 'undefined') {
      this.usersObj[userId] = null;

      this.subscriptions.add(
        this.userService.getFromId(userId).subscribe((user: IUser) => {
          this.usersObj[userId] = user;
        })
      );
    }
  }
}
