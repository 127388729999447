<div class="row justify-content-center">
    <div class="col-md-6">
        <div class="card mb-4">
            <div class="card-body" *ngIf="loading">
                <h3><div class="skeleton-text"></div></h3>
            </div>
            <div class="card-body" *ngIf="!loading && employeeBonusRule">
                <ul class="list-unstyled">
                    <li>
                        <strong>Nom</strong> :
                        {{ employeeBonusRule.name }}
                    </li>
                    <li>
                        <strong>Département(s) concerné(s)</strong> :
                        {{ getEmployeeBonusRuleDepartmentList(employeeBonusRule).join(", ") }}
                    </li>
                    <li>
                        <strong>Nombre de demandes minimum par mois</strong> :
                        {{ employeeBonusRule.minimumNbEnquiries }}
                    </li>
                    <li>
                        <strong>Marge minimum par contrat par mois</strong> :
                        {{ formatPrice(employeeBonusRule.minimumMarginHT, getDefaultCurrency()) }}
                    </li>

                    <li>
                        <strong>Montant bonus</strong> :
                        {{ formatPrice(employeeBonusRule.amountBonus, getDefaultCurrency()) }}
                    </li>
                    <li>
                        <strong>Mois de début</strong> :
                        {{ employeeBonusRule.dateStart | date : "MM/yyyy" }}
                    </li>
                    <li>
                        <strong>Mois de fin</strong> :
                        <em *ngIf="!employeeBonusRule.hasDateEnd">En cours</em>
                        <span
                            *ngIf="employeeBonusRule.hasDateEnd"
                            [innerText]="employeeBonusRule.dateEnd | date : 'MM/yyyy'"
                        ></span>
                    </li>
                </ul>

                <div class="text-center mt-4">
                    <a
                        [routerLink]="
                            '/admin/employee-bonus-rules/' + employeeBonusRule.id + '/edit'
                        "
                        class="btn btn-primary mr-4"
                        *ngIf="hasAclAccess(EnumAcl.employeeBonusRulesEdit)"
                        >Éditer</a
                    >
                    <button
                        type="button"
                        (click)="delete()"
                        class="btn btn-primary ml-4"
                        *ngIf="hasAclAccess(EnumAcl.employeeBonusRulesDelete)"
                    >
                        Supprimer
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
