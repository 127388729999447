<div class="modal-header" *ngIf="inModal">
    <div class="modal-title-wrapper">
        <h1
            class="modal-title fs-5"
            id="enquiry-flight-edit-modalLabel"
            [innerText]="enquiryFlight ? 'Édition d\'un leg' : 'Ajout d\'un leg'"
        ></h1>
        <div class="legs-nav" *ngIf="enquiryFlight">
            <button
                type="button"
                class="btn btn-sm"
                *ngIf="flightsForEnquiry.length > 1"
                [disabled]="currentEnquiryFlightIndex === 0"
                (click)="prevEnquiryFlight()"
            >
                <fa-icon [icon]="faArrowLeft"></fa-icon>
            </button>
            Leg {{ currentEnquiryFlightIndex + 1 }} /
            {{ flightsForEnquiry.length }}
            <button
                type="button"
                class="btn btn-sm"
                *ngIf="flightsForEnquiry.length > 1"
                [disabled]="currentEnquiryFlightIndex >= flightsForEnquiry.length - 1"
                (click)="nextEnquiryFlight()"
            >
                <fa-icon [icon]="faArrowRight"></fa-icon>
            </button>
        </div>
    </div>
    <button
        type="button"
        class="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        #btnCloseModal
    ></button>
</div>
<div [class.modal-body]="inModal">
    <div class="card-with-side-tabs">
        <div class="card-sidebar">
            <ul class="nav nav-pills flex-column" role="tablist">
                <li class="nav-item" role="presentation" *ngFor="let step of steps">
                    <button
                        class="nav-link"
                        [class.active]="step === currentStep"
                        type="button"
                        role="tab"
                        [attr.aria-controls]="step + '-tab-pane'"
                        [attr.aria-selected]="step === currentStep ? 'true' : 'false'"
                        (click)="updateCurrentStep(step)"
                        [disabled]="stepIsDisabled(step)"
                    >
                        <span class="nav-indicator">
                            <fa-icon [icon]="getWizardStepIcon(step)"></fa-icon>
                        </span>
                        {{
                            getWizardStepLabel(
                                step,
                                enquiryFlight ? enquiryFlight.enquiryType : enquiry?.type
                            )
                        }}
                    </button>
                </li>
            </ul>
        </div>
        <div class="tab-content-container">
            <div class="tab-content">
                <div
                    class="tab-pane fade"
                    [class.show]="EnumEnquiryFlightWizardStep.departure === currentStep"
                    [class.active]="EnumEnquiryFlightWizardStep.departure === currentStep"
                    [id]="EnumEnquiryFlightWizardStep.departure + '-tab-pane'"
                    role="tabpanel"
                    [attr.aria-labelledby]="EnumEnquiryFlightWizardStep.departure + '-tab'"
                    tabindex="0"
                >
                    <app-enquiry-flights-card-mini
                        [enquiryFlight]="enquiryFlight"
                    ></app-enquiry-flights-card-mini>

                    <app-enquiry-flight-edit-airport
                        [airportField]="'depart'"
                        [enquiry]="enquiry"
                        [enquiryFlight]="enquiryFlight"
                        (goPrev)="goPrev()"
                        (saveAndGoNext)="saveAndGoNext($event)"
                    ></app-enquiry-flight-edit-airport>
                </div>
                <div
                    class="tab-pane fade"
                    [class.show]="EnumEnquiryFlightWizardStep.arrival === currentStep"
                    [class.active]="EnumEnquiryFlightWizardStep.arrival === currentStep"
                    [id]="EnumEnquiryFlightWizardStep.arrival + '-tab-pane'"
                    role="tabpanel"
                    [attr.aria-labelledby]="EnumEnquiryFlightWizardStep.arrival + '-tab'"
                    tabindex="0"
                >
                    <app-enquiry-flights-card-mini
                        [enquiryFlight]="enquiryFlight"
                    ></app-enquiry-flights-card-mini>

                    <app-enquiry-flight-edit-airport
                        [airportField]="'arrival'"
                        [enquiry]="enquiry"
                        [enquiryFlight]="enquiryFlight"
                        (goPrev)="goPrev()"
                        (saveAndGoNext)="saveAndGoNext($event)"
                    ></app-enquiry-flight-edit-airport>
                </div>
                <div
                    class="tab-pane fade"
                    [class.show]="EnumEnquiryFlightWizardStep.flightDetail === currentStep"
                    [class.active]="EnumEnquiryFlightWizardStep.flightDetail === currentStep"
                    [id]="EnumEnquiryFlightWizardStep.flightDetail + '-tab-pane'"
                    role="tabpanel"
                    [attr.aria-labelledby]="EnumEnquiryFlightWizardStep.flightDetail + '-tab'"
                    tabindex="0"
                >
                    <app-enquiry-flights-card-mini
                        [enquiryFlight]="enquiryFlight"
                    ></app-enquiry-flights-card-mini>

                    <app-enquiry-flight-edit-details
                        [enquiry]="enquiry"
                        [enquiryFlight]="enquiryFlight"
                        (goPrev)="goPrev()"
                        (saveAndGoNext)="saveAndGoNext($event)"
                    ></app-enquiry-flight-edit-details>
                </div>
                <div
                    class="tab-pane fade"
                    [class.show]="EnumEnquiryFlightWizardStep.aircraft === currentStep"
                    [class.active]="EnumEnquiryFlightWizardStep.aircraft === currentStep"
                    [id]="EnumEnquiryFlightWizardStep.aircraft + '-tab-pane'"
                    role="tabpanel"
                    [attr.aria-labelledby]="EnumEnquiryFlightWizardStep.aircraft + '-tab'"
                    tabindex="0"
                >
                    <app-enquiry-flights-card-mini
                        [enquiryFlight]="enquiryFlight"
                    ></app-enquiry-flights-card-mini>

                    <app-enquiry-flight-edit-aircraft
                        [enquiry]="enquiry"
                        [enquiryFlight]="enquiryFlight"
                        (goPrev)="goPrev()"
                        (saveAndGoNext)="saveAndGoNext($event)"
                    ></app-enquiry-flight-edit-aircraft>
                </div>
                <div
                    class="tab-pane fade"
                    [class.show]="EnumEnquiryFlightWizardStep.passengers === currentStep"
                    [class.active]="EnumEnquiryFlightWizardStep.passengers === currentStep"
                    [id]="EnumEnquiryFlightWizardStep.passengers + '-tab-pane'"
                    role="tabpanel"
                    [attr.aria-labelledby]="EnumEnquiryFlightWizardStep.passengers + '-tab'"
                    tabindex="0"
                >
                    <app-enquiry-flights-card-mini
                        [enquiryFlight]="enquiryFlight"
                    ></app-enquiry-flights-card-mini>

                    <app-enquiry-flight-edit-passengers
                        [enquiry]="enquiry"
                        [enquiryFlight]="enquiryFlight"
                        (goPrev)="goPrev()"
                        (saveAndGoNext)="saveAndGoNext($event)"
                    ></app-enquiry-flight-edit-passengers>
                </div>
                <div
                    class="tab-pane fade"
                    [class.show]="EnumEnquiryFlightWizardStep.cargo === currentStep"
                    [class.active]="EnumEnquiryFlightWizardStep.cargo === currentStep"
                    [id]="EnumEnquiryFlightWizardStep.cargo + '-tab-pane'"
                    role="tabpanel"
                    [attr.aria-labelledby]="EnumEnquiryFlightWizardStep.cargo + '-tab'"
                    tabindex="0"
                >
                    <app-enquiry-flights-card-mini
                        [enquiryFlight]="enquiryFlight"
                    ></app-enquiry-flights-card-mini>

                    <app-enquiry-flight-edit-cargo
                        [enquiry]="enquiry"
                        [enquiryFlight]="enquiryFlight"
                        (goPrev)="goPrev()"
                        (saveAndGoNext)="saveAndGoNext($event)"
                    ></app-enquiry-flight-edit-cargo>
                </div>
                <div
                    class="tab-pane fade"
                    [class.show]="EnumEnquiryFlightWizardStep.crew === currentStep"
                    [class.active]="EnumEnquiryFlightWizardStep.crew === currentStep"
                    [id]="EnumEnquiryFlightWizardStep.crew + '-tab-pane'"
                    role="tabpanel"
                    [attr.aria-labelledby]="EnumEnquiryFlightWizardStep.crew + '-tab'"
                    tabindex="0"
                >
                    <app-enquiry-flights-card-mini
                        [enquiryFlight]="enquiryFlight"
                    ></app-enquiry-flights-card-mini>

                    <app-enquiry-flight-edit-crew
                        [enquiry]="enquiry"
                        [enquiryFlight]="enquiryFlight"
                        (goPrev)="goPrev()"
                        (saveAndGoNext)="saveAndGoNext($event)"
                    ></app-enquiry-flight-edit-crew>
                </div>
                <div
                    class="tab-pane fade"
                    [class.show]="EnumEnquiryFlightWizardStep.comment === currentStep"
                    [class.active]="EnumEnquiryFlightWizardStep.comment === currentStep"
                    [id]="EnumEnquiryFlightWizardStep.comment + '-tab-pane'"
                    role="tabpanel"
                    [attr.aria-labelledby]="EnumEnquiryFlightWizardStep.comment + '-tab'"
                    tabindex="0"
                >
                    <app-enquiry-flights-card-mini
                        [enquiryFlight]="enquiryFlight"
                    ></app-enquiry-flights-card-mini>

                    <app-enquiry-flight-edit-comment
                        [enquiry]="enquiry"
                        [enquiryFlight]="enquiryFlight"
                        (goPrev)="goPrev()"
                        (saveAndGoNext)="saveAndGoNext($event)"
                    ></app-enquiry-flight-edit-comment>
                </div>
            </div>
        </div>
    </div>
</div>
