import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface ICurrentBreadcrumbItem {
  id: string;
  text: string;
}

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService {
  currentItem: ICurrentBreadcrumbItem | null = null;
  currentItemBehavior = new BehaviorSubject<ICurrentBreadcrumbItem | null>(null);
  currentItemObservable: Observable<ICurrentBreadcrumbItem | null> =
    this.currentItemBehavior.asObservable();

  constructor() {
    this.currentItemObservable.subscribe(
      (currentItem: ICurrentBreadcrumbItem | null) => (this.currentItem = currentItem)
    );
  }

  setCurrentItem(currentItem: ICurrentBreadcrumbItem | null): void {
    this.currentItemBehavior.next(currentItem);
  }
}
