<ul class="nav nav-tabs" id="dashboard-tab" role="tablist" *ngIf="tabs.length > 1">
    <ng-container *ngFor="let tab of tabs; let i = index">
        <li
            class="nav-item"
            role="presentation"
            *ngIf="
                !enquiry ||
                (enquiry &&
                    ((enquiry?.status !== EnumEnquiryStatus.confirmed &&
                        !tab.confirmedEnquiryOnly) ||
                        enquiry?.status === EnumEnquiryStatus.confirmed))
            "
        >
            <button
                type="button"
                [class.active]="i === activeTabIndex"
                class="nav-link"
                [attr.id]="tab.value + '-tab'"
                data-bs-toggle="tab"
                (click)="selectActiveTab(i)"
                role="tab"
                [attr.aria-controls]="tab.value"
                [attr.aria-selected]="i === activeTabIndex"
                [innerText]="tab.title"
            ></button>
        </li>
    </ng-container>
</ul>

<div class="tab-content" id="dashboard-tab-content" *ngIf="tabs.length">
    <div
        [class.active]="tabs[activeTabIndex].value === 'invoices-proforma'"
        class="tab-pane fade show"
        id="invoices"
        role="tabpanel"
        aria-labelledby="invoices-tab"
    >
        <button
            type="button"
            (click)="addInvoice(EnumInvoiceType.proforma)"
            [disabled]="loadingEnquiryInvoices"
            class="btn btn-primary"
            *ngIf="hasAclAccess(EnumAcl.invoicesAddProforma)"
        >
            + Nouvelle facture proforma
        </button>

        <app-invoices-list
            [enquiryId]="enquiryId"
            [invoiceType]="EnumInvoiceType.proforma"
            [coloredTr]="false"
        ></app-invoices-list>
    </div>

    <div
        [class.active]="tabs[activeTabIndex].value === 'invoices-definitive'"
        class="tab-pane fade show"
        id="definitive"
        role="tabpanel"
        aria-labelledby="definitive-tab"
    >
        <button
            type="button"
            (click)="requestInvoice(EnumInvoiceType.definitive)"
            [disabled]="loadingEnquiryInvoices"
            class="btn btn-primary"
            *ngIf="hasAclAccess(EnumAcl.invoicesRequestFinalInvoice) && enquiryId"
        >
            Demande d'une facture définitive
        </button>
        <button
            type="button"
            (click)="addInvoice(EnumInvoiceType.definitive)"
            [disabled]="loadingEnquiryInvoices"
            class="btn btn-primary"
            *ngIf="hasAclAccess(EnumAcl.invoicesAddFinalInvoice)"
        >
            + Nouvelle facture définitive
        </button>

        <app-invoices-list
            [enquiryId]="enquiryId"
            invoiceType="definitive"
            [coloredTr]="false"
        ></app-invoices-list>
    </div>

    <div
        [class.active]="tabs[activeTabIndex].value === 'invoices-credit-note'"
        class="tab-pane fade show"
        id="credit-note"
        role="tabpanel"
        aria-labelledby="credit-note-tab"
    >
        <button
            type="button"
            (click)="requestInvoice(EnumInvoiceType.creditNote)"
            [disabled]="loadingEnquiryInvoices"
            class="btn btn-primary"
            *ngIf="hasAclAccess(EnumAcl.invoicesRequestFinalCreditNoteInvoice) && enquiryId"
        >
            Demande d'un avoir définitif
        </button>

        <button
            type="button"
            (click)="addInvoice(EnumInvoiceType.proformaCreditNote)"
            [disabled]="loadingEnquiryInvoices"
            class="btn btn-primary"
            *ngIf="hasAclAccess(EnumAcl.invoicesAddCreditNoteProforma)"
        >
            + Nouvel avoir proforma
        </button>

        <button
            type="button"
            (click)="addInvoice(EnumInvoiceType.creditNote)"
            [disabled]="loadingEnquiryInvoices"
            class="btn btn-primary"
            *ngIf="hasAclAccess(EnumAcl.invoicesAddCreditNoteFinal)"
        >
            + Nouvel avoir définitif
        </button>

        <app-invoices-list
            [enquiryId]="enquiryId"
            [invoiceType]="[EnumInvoiceType.creditNote, EnumInvoiceType.proformaCreditNote]"
            [coloredTr]="false"
        ></app-invoices-list>
    </div>

    <div
        [class.active]="tabs[activeTabIndex].value === 'invoices-cancelled'"
        class="tab-pane fade show"
        id="cancelled"
        role="tabpanel"
        aria-labelledby="cancelled-tab"
    >
        <app-invoices-list
            [enquiryId]="enquiryId"
            invoiceType="cancelled"
            [coloredTr]="false"
        ></app-invoices-list>
    </div>

    <div
        [class.active]="tabs[activeTabIndex].value === 'invoices-overview'"
        class="tab-pane fade show"
        id="overview"
        role="tabpanel"
        aria-labelledby="overview-tab"
    >
        <app-invoices-list [enquiryId]="enquiryId" [coloredTr]="false"></app-invoices-list>
    </div>

    <div
        [class.active]="tabs[activeTabIndex].value === 'invoices-purchase'"
        class="tab-pane fade show"
        id="purchase"
        role="tabpanel"
        aria-labelledby="purchase-tab"
    >
        <div class="btn-group">
            <button
                type="button"
                (click)="addInvoice(EnumInvoiceType.purchase)"
                [disabled]="loadingEnquiryInvoices"
                class="btn btn-primary"
                *ngIf="hasAclAccess(EnumAcl.invoicesAddPurchaseInvoice)"
            >
                + Nouvelle facture d'achat
            </button>
            <button
                type="button"
                (click)="addInvoice(EnumInvoiceType.purchaseCreditNote)"
                [disabled]="loadingEnquiryInvoices"
                class="btn btn-primary"
                *ngIf="hasAclAccess(EnumAcl.invoicesAddPurchaseCreditNote)"
            >
                + Nouvel avoir reçu
            </button>
        </div>

        <app-invoices-list
            [enquiryId]="enquiryId"
            invoiceType="purchase"
            [coloredTr]="false"
        ></app-invoices-list>
    </div>
</div>
