import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { EnquiryFlightAddComponent } from './enquiry-flight-add.component';

@NgModule({
  imports: [CommonModule, FormsModule],
  declarations: [EnquiryFlightAddComponent],
  exports: [EnquiryFlightAddComponent]
})
export class EnquiryFlightAddModule {}
