import { finalize } from 'rxjs/operators';
import { Component, OnInit, NgZone, ViewChild, ElementRef } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  AbstractControl,
  FormControl
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';

import countries from '../../../countries_fr.json';
import countriesFull from '../../../countries_full.json';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { getContinentLabel } from 'src/app/enums/continent-code.enum';
import { AirlineService } from 'src/app/services/airlines/airlines.service';
import { IAirline } from 'src/app/interfaces/airline.interface';
import { generateRandomId } from 'src/app/misc.utils';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { EnumEnquiryType, getEnumEnquiryTypeLabel } from 'src/app/enums/enquiry-type.enum';

@Component({
  selector: 'app-airline-edit',
  templateUrl: './airline-edit.component.html',
  styleUrls: ['./airline-edit.component.scss']
})
export class AirlineEditComponent implements OnInit {
  @ViewChild('modalUpload', { static: false }) modalUploadElement: ElementRef;

  faTrash = faTrash;

  isLogged: boolean = false;
  form: FormGroup;
  airline: IAirline | null = null;
  airlineId: string;
  countriesList: Array<{
    title: string;
    value: string;
  }> = [];
  uploading: boolean = false;
  uploadingProgress: number;
  currentFile: File;
  documentIndex: number;

  getContinentLabel = getContinentLabel;
  getEnumEnquiryTypeLabel = getEnumEnquiryTypeLabel;

  constructor(
    private formBuilder: FormBuilder,
    private remoteService: RemoteService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private zone: NgZone,
    private aclService: AclService,
    private airlineService: AirlineService,
    private loaderService: LoaderService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );

    for (const code in countries) {
      this.countriesList.push({
        title: countries[code],
        value: code
      });
    }
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      title: ['', [Validators.required]],
      airfleetTitle: [''],
      countryCode: ['', [Validators.required]],
      continentCode: ['', [Validators.required]],
      website: [''],
      streetNumber: [''],
      route: [''],
      postalCode: [''],
      city: [''],
      companyCreationYear: [''],
      companyClosureYear: [''],
      description: [''],
      tvaNumber: [''],
      codes: [''],
      callsign: [''],
      url: [''],
      isNationalCompany: [false],
      mustBeContacted: [false],
      isBlacklisted: [false],
      isActive: [true],
      reasonBlackListed: [''],
      companyContacts: new FormArray([]),
      documentsUrl: new FormArray([])
    });

    this.form.disable();

    this.activatedRoute.params.subscribe(async () => {
      this.airlineId = this.activatedRoute.snapshot.paramMap.get('airlineId');

      if (this.airlineId) {
        await this.aclService.checkAclAccess(EnumAcl.airlinesEdit);
        this.loadData();
      } else {
        await this.aclService.checkAclAccess(EnumAcl.airlinesAdd);
        this.form.enable();
      }
    });
  }

  ngOnDestroy(): void {
    this.removeModal();
  }

  getEnquiryTypes(): EnumEnquiryType[] {
    return Object.values(EnumEnquiryType);
  }

  get documentsUrl(): FormArray {
    return this.form.get('documentsUrl') as FormArray;
  }

  getDocumentUrl(i: number): FormGroup {
    return this.documentsUrl.at(i) as FormGroup;
  }

  getDocumentUrlField(i: number, field: string): FormControl {
    return this.getDocumentUrl(i).get(field) as FormControl;
  }

  get companyContacts(): FormArray {
    return this.form.get('companyContacts') as FormArray;
  }

  getCompanyContact(i: number): FormGroup {
    return this.companyContacts.at(i) as FormGroup;
  }

  getCompanyContactField(i: number, field: string): FormControl {
    return this.getCompanyContact(i).get(field) as FormControl;
  }

  getCompanyContactsInSection(i: number): FormArray {
    return this.getCompanyContact(i).get('contacts') as FormArray;
  }

  getCompanyContactOfSection(i: number, j: number): FormGroup {
    return this.getCompanyContactsInSection(i).at(j) as FormGroup;
  }

  getCompanyContactOfSectionField(i: number, j: number, field: string): FormControl {
    return this.getCompanyContactOfSection(i, j).get(field) as FormControl;
  }

  removeModal(): void {
    window['$'](this.modalUploadElement.nativeElement).modal('hide');
    window['$']('body').removeClass('modal-open');
    window['$']('.modal-backdrop').remove();
  }

  loadData(): void {
    if (this.isLogged) {
      this.loadAirline();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  async loadAirline(): Promise<void> {
    const doc = await this.remoteService.getDocument('airlines', this.airlineId);

    this.airline = doc as IAirline;

    this.setAirline();
  }

  setAirline(): void {
    if (this.form && this.airline) {
      this.form.get('title').setValue(this.airline.title);
      this.form.get('airfleetTitle').setValue(this.airline.airfleetTitle);
      this.form.get('countryCode').setValue(this.airline.countryCode);
      this.form.get('continentCode').setValue(this.airline.continentCode);
      this.form.get('website').setValue(this.airline.website);
      this.form.get('streetNumber').setValue(this.airline.streetNumber);
      this.form.get('route').setValue(this.airline.route);
      this.form.get('postalCode').setValue(this.airline.postalCode);
      this.form.get('city').setValue(this.airline.city);
      this.form.get('companyCreationYear').setValue(this.airline.companyCreationYear);
      this.form.get('companyClosureYear').setValue(this.airline.companyClosureYear);
      this.form.get('description').setValue(this.airline.description);
      this.form.get('tvaNumber').setValue(this.airline.tvaNumber);
      this.form.get('codes').setValue(this.airline.codes);
      this.form.get('callsign').setValue(this.airline.callsign);
      this.form.get('url').setValue(this.airline.url);
      this.form.get('isNationalCompany').setValue(this.airline.isNationalCompany);
      this.form.get('mustBeContacted').setValue(this.airline.mustBeContacted);
      this.form.get('isBlacklisted').setValue(this.airline.isBlacklisted);
      this.form.get('reasonBlackListed').setValue(this.airline.reasonBlackListed);

      for (const i in this.airline.companyContacts) {
        this.addContactCategory();

        const companyContactsFormArray = this.form.get('companyContacts') as FormArray;

        companyContactsFormArray
          .at(parseInt(i))
          .get('title')
          .setValue(this.airline.companyContacts[i].title);

        companyContactsFormArray
          .at(parseInt(i))
          .get('enquiryTypes')
          .setValue(this.airline.companyContacts[i].enquiryTypes || []);

        for (const j in this.airline.companyContacts[i].contacts) {
          this.addContactToCategory(parseInt(i));

          const contactsFormArray = this.form
            .get('companyContacts')
            .get(i)
            .get('contacts') as FormArray;

          contactsFormArray
            .at(parseInt(j))
            .get('id')
            .setValue(this.airline.companyContacts[i].contacts[j].id);
          contactsFormArray
            .at(parseInt(j))
            .get('lastname')
            .setValue(this.airline.companyContacts[i].contacts[j].lastname);
          contactsFormArray
            .at(parseInt(j))
            .get('firstname')
            .setValue(this.airline.companyContacts[i].contacts[j].firstname);
          contactsFormArray
            .at(parseInt(j))
            .get('fonction')
            .setValue(this.airline.companyContacts[i].contacts[j].fonction);
          contactsFormArray
            .at(parseInt(j))
            .get('phone')
            .setValue(this.airline.companyContacts[i].contacts[j].phone);
          contactsFormArray
            .at(parseInt(j))
            .get('mobile')
            .setValue(this.airline.companyContacts[i].contacts[j].mobile);
          contactsFormArray
            .at(parseInt(j))
            .get('fax')
            .setValue(this.airline.companyContacts[i].contacts[j].fax);
          contactsFormArray
            .at(parseInt(j))
            .get('email')
            .setValue(this.airline.companyContacts[i].contacts[j].email);
        }
      }

      for (const i in this.airline.documentsUrl) {
        this.addDocument();

        const documentsUrlFormArray = this.form.get('documentsUrl') as FormArray;

        documentsUrlFormArray
          .at(parseInt(i))
          .get('title')
          .setValue(this.airline.documentsUrl[i].title);
        documentsUrlFormArray.at(parseInt(i)).get('url').setValue(this.airline.documentsUrl[i].url);
      }

      this.form.enable();
    }
  }

  submitForm(): void {
    this.form.markAsTouched();

    if (this.form.valid) {
      this.loaderService.presentLoader();

      let data = Object.assign({}, this.form.value);

      for (const field in data) {
        if (typeof data[field] == 'undefined') {
          data[field] = null;
        }
      }

      for (const contactsIndex in data['companyContacts']) {
        for (const contactIndex in data['companyContacts'][contactsIndex].contacts) {
          for (const field in data['companyContacts'][contactsIndex].contacts[contactIndex]) {
            if (
              typeof data['companyContacts'][contactsIndex].contacts[contactIndex][field] ==
              'undefined'
            ) {
              data['companyContacts'][contactsIndex].contacts[contactIndex][field] = null;
            }
          }
        }
      }

      const routes: Array<string> = [];
      if (data['streetNumber']) {
        routes.push(data['streetNumber']);
      }
      if (data['route']) {
        routes.push(data['route']);
      }

      data['formattedAddress'] = '';

      if (routes.length) {
        data['formattedAddress'] += routes.join(' ') + ', ';
      }
      data['formattedAddress'] += [data['postalCode'], data['city']].join(' ');

      this.form.disable();

      if (this.airlineId) {
        this.remoteService
          .updateDocumentToCollection('airlines', this.airlineId, data)
          .then(async () => {
            await this.airlineService.syncAirlineContactsWithPipedrive(data);

            await this.loaderService.hideLoaderOnSuccess();

            this.redirectAfterSaving();
          })
          .catch(async err => {
            await this.loaderService.hideLoaderOnFailure(err);

            this.form.enable();
          });
      } else {
        this.remoteService
          .addDocumentToCollection('airlines', data)
          .then(async (docId: string) => {
            this.airlineId = docId;

            await this.airlineService.syncAirlineContactsWithPipedrive(data);

            await this.loaderService.hideLoaderOnSuccess();

            this.redirectAfterSaving();
          })
          .catch(async err => {
            await this.loaderService.hideLoaderOnFailure(err);
            this.form.enable();
          });
      }
    }
  }

  redirectAfterSaving(): void {
    if (window['enquiryId']) {
      this.router.navigate([
        '/admin/enquiries/' + window['enquiryId'] + '/send-message-to-airlines'
      ]);
    } else {
      this.router.navigate(['/admin/airlines/' + this.airlineId]);
    }
  }

  setValueToFormControl($event: {
    fields: Array<{
      name: string;
      value: string;
    }>;
  }): void {
    for (let field of $event.fields) {
      const nameList = field.name.split('.');

      let formControl: AbstractControl = this.form;
      for (let name of nameList) {
        formControl = formControl.get(name);
      }

      formControl.setValue(field.value);

      if (field.name == 'countryCode') {
        this.assignContinents();
      }

      formControl.markAsTouched();
      formControl.updateValueAndValidity();
    }
  }

  assignContinents(): void {
    for (const country of countriesFull) {
      if (country['ISO3166-1-Alpha-2'] == this.form.value.countryCode) {
        this.form.get('continentCode').setValue(country.Continent);
      }
    }
  }

  addContactCategory(): void {
    const companyContactsFormArray = this.form.get('companyContacts') as FormArray;

    companyContactsFormArray.push(
      new FormGroup({
        title: new FormControl('', [Validators.required]),
        enquiryTypes: new FormControl([]),
        contacts: new FormArray([])
      })
    );

    this.form.updateValueAndValidity();
  }

  deleteContactCategory(i: number): void {
    const result = confirm(
      'La suppression de la catégorie de contact sera permanente. Êtes-vous sûr de vouloir continuer?'
    );

    if (result) {
      const companyContactsFormArray = this.form.get('companyContacts') as FormArray;

      companyContactsFormArray.removeAt(i);

      this.form.updateValueAndValidity();
    }
  }

  addContactToCategory(i: number): void {
    const contactsFormArray = this.form
      .get('companyContacts')
      .get(i.toString())
      .get('contacts') as FormArray;

    contactsFormArray.push(
      new FormGroup({
        id: new FormControl(generateRandomId(), [Validators.required]),
        lastname: new FormControl(''),
        firstname: new FormControl(''),
        fonction: new FormControl(''),
        phone: new FormControl(''),
        mobile: new FormControl(''),
        fax: new FormControl(''),
        email: new FormControl('')
      })
    );

    this.form.updateValueAndValidity();
  }

  deleteContactFromCategory(i: number, j: number): void {
    const result = confirm(
      'La suppression du contact sera permanent. Êtes-vous sûr de vouloir continuer?'
    );

    if (result) {
      const contactsFormArray = this.form
        .get('companyContacts')
        .get(i.toString())
        .get('contacts') as FormArray;

      contactsFormArray.removeAt(j);

      this.form.updateValueAndValidity();
    }
  }

  addDocument(): void {
    const documentsUrlFormArray = this.form.get('documentsUrl') as FormArray;

    documentsUrlFormArray.push(
      new FormGroup({
        title: new FormControl('', [Validators.required]),
        url: new FormControl('', [Validators.required])
      })
    );

    this.form.updateValueAndValidity();
  }

  deleteDocument(i: number): void {
    const result = confirm(
      'La suppression du document sera permanent. Êtes-vous sûr de vouloir continuer?'
    );

    if (result) {
      const documentsUrlFormArray = this.form.get('documentsUrl') as FormArray;

      documentsUrlFormArray.removeAt(i);

      this.form.updateValueAndValidity();
    }
  }

  changeInputFile(fileInput: any): void {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.currentFile = fileInput.target.files[0];
    }
  }

  showUploadModal(i: number = null): void {
    this.documentIndex = i;

    window['$'](this.modalUploadElement.nativeElement).modal('show');
  }

  upload(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.form.disable();
      this.uploading = true;
      this.uploadingProgress = 0;

      window['$'](this.modalUploadElement.nativeElement).modal('hide');

      let result = this.remoteService.upload('airlines', null, this.currentFile, 'file');

      result['task'].percentageChanges().subscribe((progress: number) => {
        this.zone.run(() => {
          this.uploadingProgress = Math.round(progress);
        });
      });

      result['task']
        .snapshotChanges()
        .pipe(
          finalize(() => {
            result['ref'].getDownloadURL().subscribe((downloadUrl: string) => {
              this.form.enable();

              if (this.documentIndex === null) {
                this.addDocument();

                const documentsUrlFormArray = this.form.get('documentsUrl') as FormArray;

                this.documentIndex = documentsUrlFormArray.controls.length - 1;
              }

              this.form
                .get('documentsUrl')
                .get(this.documentIndex.toString())
                .get('title')
                .setValue(this.currentFile['name']);
              this.form
                .get('documentsUrl')
                .get(this.documentIndex.toString())
                .get('url')
                .setValue(downloadUrl);

              this.form.updateValueAndValidity();

              this.uploading = false;
            });
          })
        )
        .subscribe();
    });
  }

  toggleEnquiryType(i: number, enquiryType: EnumEnquiryType): void {
    let values: EnumEnquiryType[] = [...this.getCompanyContactField(i, 'enquiryTypes').value];
    const index: number = values.indexOf(enquiryType);

    if (index === -1) {
      values.push(enquiryType);
    } else {
      values.splice(index, 1);
    }

    this.getCompanyContactField(i, 'enquiryTypes').setValue(values);
    this.getCompanyContactField(i, 'enquiryTypes').updateValueAndValidity();
  }

  toggleAllEnquiryType(i: number): void {
    this.getCompanyContactField(i, 'enquiryTypes').setValue([]);
    this.getCompanyContactField(i, 'enquiryTypes').updateValueAndValidity();
  }
}
