import { Component, OnDestroy, OnInit } from '@angular/core';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import { Subscription } from 'rxjs';
import { EnumCurrency } from 'src/app/enums/currency.enum';
import { EnumInvoiceProductDescription } from 'src/app/enums/invoice-product-description.enum';
import { EnumInvoiceStatus } from 'src/app/enums/invoice-status.enum';
import { EnumInvoiceType } from 'src/app/enums/invoice-type.enum';
import { EnumInvoiceLanguage, EnumLanguage } from 'src/app/enums/language.enum';
import { EnumTvaRate } from 'src/app/enums/tva-rates.enum';
import { IClientBillingInfos } from 'src/app/interfaces/client-billing-infos.interface';
import { IEncaissementType } from 'src/app/interfaces/encaissement-type.interface';
import { IEncaissement } from 'src/app/interfaces/encaissement.interface';
import { IEnquiryPaymentSummaryDetail } from 'src/app/interfaces/enquiry-payment-summary-detail.interface';
import { IEnquiryPaymentSummary } from 'src/app/interfaces/enquiry-payment-summary.interface';
import {
  IInvoice,
  IInvoiceProduct,
  invoiceGetProductsLabel
} from 'src/app/interfaces/invoice.interface';
import { cleanString, formatDateFromStr, roundNumber } from 'src/app/misc.utils';
import { EncaissementTypeService } from 'src/app/services/encaissement-types/encaissement-types.service';
import { EncaissementService } from 'src/app/services/encaissements/encaissements.service';
import { EnquiryPaymentSummaryService } from 'src/app/services/enquiry-payment-summaries/enquiry-payment-summaries.service';
import { EnquiryPaymentSummaryDetailService } from 'src/app/services/enquiry-payment-summary-details/enquiry-payment-summary-details.service';
import { InvoiceService } from 'src/app/services/invoices/invoices.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import * as XLSX from 'xlsx';

interface IDataToImportLine {
  legDates: string[];
  enquiryRefContractTitle: string;
  userInitials: string;
  clientName: string | null;
  airlineTitles: string[];
  invoiceRef: string;
  invoiceClientName: string | null;
  invoiceIssueDate: string | null;
  invoiceCurrency: EnumCurrency | null;
  invoiceAmountHtTotal: number | null;
  invoiceAmountTvaTotal: number | null;
  invoiceAmountTtcTotal: number | null;
  enquiryMargin: number;
  invoiceProductsLabel: string[];
  enquiryRouting: string[];
  invoiceDueDate: Date | null;
  encaissementsAmount: number | null;
  encaissementsTypeId: string | null;
  encaissementsDate: Date | null;
  marginHT: string;
  tva: string;
  marginTtc: string;
  status: EnumDataToImportLineStatus;
  validationMsgs: IDataToImportLineValidationMsg[];
  invoiceId: string | null;
}

enum EnumDataToImportLineStatus {
  validating = 'validating',
  valid = 'valid',
  issueToFix = 'issueToFix',
  warning = 'warning',
  info = 'info'
}

interface IDataToImportLineValidationMsg {
  status: EnumValidationMsgStatus;
  comment?: string;
}
enum EnumValidationMsgStatus {
  enquiryNotFound = 'enquiryNotFound',
  mandatoryFieldsEmpty = 'mandatoryFieldsEmpty',
  courtierIncorrect = 'courtierIncorrect',
  clientIncorrect = 'clientIncorrect',
  airlineIncorrect = 'airlineIncorrect',
  definitiveInvoiceNotFound = 'definitiveInvoiceNotFound',
  purchaseInvoiceNotFound = 'purchaseInvoiceNotFound',
  addTemporaryLine = 'addTemporaryLine',
  invoiceAmountIncorrect = 'invoiceAmountIncorrect',
  encaissementMissing = 'encaissementMissing',
  modNotValid = 'modNotValid'
}

interface IDataToImport {
  headers: string[];
  lines: IDataToImportLine[];
}

@Component({
  selector: 'app-enquiry-payments-summary-import',
  templateUrl: './enquiry-payments-summary-import.component.html',
  styleUrls: ['./enquiry-payments-summary-import.component.scss']
})
export class EnquiryPaymentsSummaryImportComponent implements OnInit, OnDestroy {
  subscriptions = new Subscription();

  faQuestionCircle = faQuestionCircle;

  fileContentLoaded = false;
  currentFile: File;
  dataToImportValid: boolean = true;
  dataToImport: IDataToImport = {
    headers: [],
    lines: []
  };
  fieldsIgnoredWhenImported: string[] = [
    'Dates de départ',
    'Marges HT',
    'Routing',
    'Marge HT',
    'TVA',
    'Marge TTC'
  ];
  importing: boolean = false;

  EnumDataToImportLineStatus = EnumDataToImportLineStatus;
  EnumValidationMsgStatus = EnumValidationMsgStatus;

  enquiryPaymentSummariesObj: { [key: string]: IEnquiryPaymentSummary[] } = {};
  encaissementsTypes: IEncaissementType[] = [];

  enquiryPaymentSummaryDetailsObj: {
    [enquiryId: string]: IEnquiryPaymentSummaryDetail;
  } = {};

  constructor(
    private enquiryPaymentSummaryService: EnquiryPaymentSummaryService,
    private encaissementTypeService: EncaissementTypeService,
    private loaderService: LoaderService,
    private invoiceService: InvoiceService,
    private encaissementService: EncaissementService,
    private enquiryPaymentSummaryDetailService: EnquiryPaymentSummaryDetailService
  ) {}

  ngOnInit(): void {
    this.loadAllEncaissementTypes();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  changeInputFile(fileInput: any): void {
    this.dataToImportValid = true;

    if (fileInput.target.files && fileInput.target.files[0]) {
      this.currentFile = fileInput.target.files[0];

      const reader = new FileReader();
      reader.onload = async (e: any) => {
        this.fileContentLoaded = false;

        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellDates: true });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        this.dataToImport = this.excelContentToJson(
          XLSX.utils.sheet_to_csv(ws, {
            FS: ';;',
            RS: '<br>'
          })
        );

        this.dataToImportValid = this.checkIfFileCorrectFormat();

        this.fileContentLoaded = true;

        setTimeout(() => {
          this.updateTooltip();
        }, 1000);

        await this.validationDataToImport();
      };

      reader.readAsBinaryString(this.currentFile);
    }
  }

  private excelContentToJson(data: string): IDataToImport {
    const lines: string[] = data.split('<br>');

    const result: IDataToImport = {
      headers: [],
      lines: []
    };

    for (let i = 0; i < lines.length; i++) {
      if (i === 0) {
        result.headers = lines[i].split(';;');

        for (let j = 0; j < result.headers.length; j++) {
          result.headers[j] = result.headers[j].replaceAll('\n', ' ').replaceAll('  ', ' ').trim();
        }
      } else if (i >= 1) {
        const cells: string[] = lines[i].split(';;');

        if (cells[0]) {
          let currency: EnumCurrency | null = null;
          let currencySymbol: string | null = null;

          if (cells[7].match('€')) {
            currency = EnumCurrency.EUR;
            currencySymbol = '€';
          } else if (cells[7].match('$')) {
            currency = EnumCurrency.USD;
            currencySymbol = '$';
          }

          if (currency) {
            // Cleanup empty price
            for (let i = 0; i < cells.length; i++) {
              if (
                cells[i].match(currencySymbol) &&
                cells[i].replaceAll(currencySymbol, '').replaceAll('-', '').trim() === ''
              ) {
                cells[i] = '';
              }
            }
          }

          const line: IDataToImportLine = {
            legDates: [format(formatDateFromStr(cells[0]), 'yyyy-MM-dd')],
            enquiryRefContractTitle: cells[1],
            userInitials: cells[2],
            clientName: null,
            airlineTitles: [cells[4] !== '' ? cells[4] : null],
            invoiceClientName: cells[3],
            invoiceRef: cells[5],
            invoiceIssueDate:
              cells[6] !== '' ? format(formatDateFromStr(cells[6]), 'yyyy-MM-dd') : null,
            invoiceCurrency: currency,
            invoiceAmountHtTotal: cells[7] !== '' ? this.formatPriceFromExcel(cells[7]) : null,
            invoiceAmountTvaTotal: cells[8] !== '' ? this.formatPriceFromExcel(cells[8]) : null,
            invoiceAmountTtcTotal: cells[9] !== '' ? this.formatPriceFromExcel(cells[9]) : null,
            enquiryMargin: cells[10] !== '' ? this.formatPriceFromExcel(cells[10]) : null,
            invoiceProductsLabel: [cells[11]],
            enquiryRouting: [cells[12]],
            invoiceDueDate:
              cells[13] !== '' && formatDateFromStr(cells[13])
                ? formatDateFromStr(cells[13])
                : null,
            encaissementsAmount: cells[14] !== '' ? this.formatPriceFromExcel(cells[14]) : null,
            encaissementsTypeId: cells[15] !== '' ? cells[15] : null,
            encaissementsDate:
              cells[16] !== '' && formatDateFromStr(cells[16])
                ? formatDateFromStr(cells[16])
                : null,
            marginHT: cells[17],
            tva: cells[18],
            marginTtc: cells[19],
            status: EnumDataToImportLineStatus.validating,
            validationMsgs: []
          } as IDataToImportLine;

          result.lines.push(line);
        }
      }
    }

    return result;
  }

  checkIfFileCorrectFormat(): boolean {
    const templateColumns: string[] = [
      'Dates de départ',
      'N° Dossier',
      'Courtiers',
      'Clients',
      'Compagnies et autres',
      'N° factures',
      'Date Emission Facture',
      'Montants HT',
      'TVA',
      'Montants TTC',
      'Marges HT',
      'dénomination',
      'Routing',
      'Echéancié',
      'Paiement',
      'MOD',
      'Date de paiement',
      'Marge HT',
      'TVA',
      'Marge TTC'
    ];

    if (this.dataToImport.headers.length !== templateColumns.length) {
      return false;
    }

    for (let i = 0; i < templateColumns.length; i++) {
      if (templateColumns[i] !== this.dataToImport.headers[i]) {
        return false;
      }
    }

    return true;
  }

  formatPriceFromExcel(value: string): number {
    return parseFloat(value.replaceAll(',', ''));
  }

  async import(): Promise<void> {
    this.importing = true;

    this.loaderService.presentLoader();

    try {
      for (let i = 0; i < this.dataToImport.lines.length; i++) {
        if (
          [EnumDataToImportLineStatus.warning, EnumDataToImportLineStatus.info].includes(
            this.dataToImport.lines[i].status
          )
        ) {
          // Something to update
          for (const validationMsg of this.dataToImport.lines[i].validationMsgs) {
            switch (validationMsg.status) {
              case EnumValidationMsgStatus.purchaseInvoiceNotFound:
                await this.loaderService.updateLoaderMessage(
                  i +
                    1 +
                    '/' +
                    this.dataToImport.lines.length +
                    " : Ajout de la facture d'achat " +
                    this.dataToImport.lines[i].invoiceRef +
                    '.'
                );

                this.dataToImport.lines[i].invoiceId = await this.addPurchaseInvoice(
                  this.dataToImport.lines[i]
                );
                break;
              case EnumValidationMsgStatus.addTemporaryLine:
                await this.loaderService.updateLoaderMessage(
                  i +
                    1 +
                    '/' +
                    this.dataToImport.lines.length +
                    ' : Ajout de la ligne temporaire pour ' +
                    this.dataToImport.lines[i].enquiryRefContractTitle +
                    '.'
                );

                await this.addTemporaryLine(this.dataToImport.lines[i]);
                break;
              case EnumValidationMsgStatus.encaissementMissing:
                await this.loaderService.updateLoaderMessage(
                  i +
                    1 +
                    '/' +
                    this.dataToImport.lines.length +
                    " : Ajout de l'encaissement pour " +
                    this.dataToImport.lines[i].invoiceRef +
                    '.'
                );

                await this.addEncaissement(this.dataToImport.lines[i]);
                break;
            }
          }
        }
      }

      await this.loaderService.hideLoaderOnSuccess();

      this.importing = false;
    } catch (err) {
      await this.loaderService.hideLoaderOnFailure(err);
    }
  }

  async addTemporaryLine(line: IDataToImportLine): Promise<string> {
    let enquiryPaymentSummaries: IEnquiryPaymentSummary[] = [];

    if (
      line.enquiryRefContractTitle &&
      this.enquiryPaymentSummariesObj[line.enquiryRefContractTitle]?.length
    ) {
      enquiryPaymentSummaries = this.enquiryPaymentSummariesObj[line.enquiryRefContractTitle];
    }

    if (enquiryPaymentSummaries.length) {
      if (
        typeof this.enquiryPaymentSummaryDetailsObj[enquiryPaymentSummaries[0].enquiryId] ===
        'undefined'
      ) {
        this.enquiryPaymentSummaryDetailsObj[enquiryPaymentSummaries[0].enquiryId] = {
          id: null,
          enquiryPaymentSummaryId: null,
          enquiryId: enquiryPaymentSummaries[0].enquiryId,
          comment: '',
          periodMonth: enquiryPaymentSummaries[0].periodMonth,
          periodQuarter: enquiryPaymentSummaries[0].periodQuarter,
          periodYear: enquiryPaymentSummaries[0].periodYear,
          temporaryRows: []
        } as IEnquiryPaymentSummaryDetail;
      }

      let selectedEncaissementType: IEncaissementType | null = null;

      if (line.encaissementsTypeId) {
        for (const encaissementType of this.encaissementsTypes) {
          if (
            encaissementType.name
              .toLocaleLowerCase()
              .match(line.encaissementsTypeId.toLocaleLowerCase())
          ) {
            selectedEncaissementType = encaissementType;
            break;
          }
        }
      }

      this.enquiryPaymentSummaryDetailsObj[enquiryPaymentSummaries[0].enquiryId].temporaryRows.push(
        {
          invoiceRef: line.invoiceRef || null,
          invoiceClientName: line.invoiceClientName || null,
          invoiceIssueDate: line.invoiceIssueDate || null,
          invoiceAmountHtTotal: line.invoiceAmountHtTotal || null,
          invoiceAmountTvaTotal: line.invoiceAmountTvaTotal || null,
          invoiceAmountTtcTotal: line.invoiceAmountTtcTotal || null,
          invoiceProductsLabel: line.invoiceProductsLabel.join(',') || null,
          invoiceCurrency: line.invoiceCurrency || null,
          invoiceDueDate: line.invoiceDueDate || null,
          encaissementsAmount: line.encaissementsAmount || null,
          encaissementsTypeId: selectedEncaissementType?.id || null,
          encaissementsDate: line.encaissementsDate || null
        }
      );

      if (this.enquiryPaymentSummaryDetailsObj[enquiryPaymentSummaries[0].enquiryId].id) {
        await this.enquiryPaymentSummaryDetailService.update(
          this.enquiryPaymentSummaryDetailsObj[enquiryPaymentSummaries[0].enquiryId]
        );
      } else {
        this.enquiryPaymentSummaryDetailsObj[enquiryPaymentSummaries[0].enquiryId].id =
          await this.enquiryPaymentSummaryDetailService.create(
            this.enquiryPaymentSummaryDetailsObj[enquiryPaymentSummaries[0].enquiryId]
          );
      }

      return this.enquiryPaymentSummaryDetailsObj[enquiryPaymentSummaries[0].enquiryId].id;
    }
  }

  async addPurchaseInvoice(line: IDataToImportLine): Promise<string> {
    let enquiryId: string | null = null;

    if (
      line.enquiryRefContractTitle &&
      this.enquiryPaymentSummariesObj[line.enquiryRefContractTitle]?.length
    ) {
      enquiryId = this.enquiryPaymentSummariesObj[line.enquiryRefContractTitle][0].enquiryId;
    }

    const tvaRatePercent: number = line.invoiceAmountTvaTotal
      ? Math.round((line.invoiceAmountTvaTotal / line.invoiceAmountHtTotal) * 100)
      : 0;
    let tvaRateSelected: EnumTvaRate = EnumTvaRate.other;

    switch (tvaRatePercent) {
      case 0:
        tvaRateSelected = EnumTvaRate.zero;
        break;
      case 5.5:
        tvaRateSelected = EnumTvaRate.five;
        break;
      case 10:
        tvaRateSelected = EnumTvaRate.ten;
        break;
      case 20:
        tvaRateSelected = EnumTvaRate.twenty;
        break;
    }

    const products: IInvoiceProduct[] = [
      {
        descriptionType: EnumInvoiceProductDescription.other,
        descriptionPercent: null,
        descriptionTitle: line.invoiceProductsLabel.join(', '),
        amountHt:
          line.invoiceAmountHtTotal < 0
            ? line.invoiceAmountHtTotal * -1
            : line.invoiceAmountHtTotal,
        amountHtPositive:
          line.invoiceAmountHtTotal < 0
            ? line.invoiceAmountHtTotal * -1
            : line.invoiceAmountHtTotal,
        tvaRateSelected: tvaRateSelected,
        tvaRate: tvaRatePercent,
        amountTva:
          line.invoiceAmountTvaTotal < 0
            ? line.invoiceAmountTvaTotal * -1
            : line.invoiceAmountTvaTotal,
        amountTvaPositive:
          line.invoiceAmountTvaTotal < 0
            ? line.invoiceAmountTvaTotal * -1
            : line.invoiceAmountTvaTotal,
        amountTtc:
          line.invoiceAmountTtcTotal < 0
            ? line.invoiceAmountTtcTotal * -1
            : line.invoiceAmountTtcTotal,
        amountTtcPositive:
          line.invoiceAmountTtcTotal < 0
            ? line.invoiceAmountTtcTotal * -1
            : line.invoiceAmountTtcTotal,
        currency: line.invoiceCurrency
      } as IInvoiceProduct
    ];

    const purchaseInvoice: IInvoice = {
      language:
        line.invoiceAmountTvaTotal !== null ? EnumInvoiceLanguage.fr : EnumInvoiceLanguage.en,
      invoiceType: EnumInvoiceType.purchase,
      ref: line.invoiceRef,
      refYear: null,
      refMonth: null,
      refNumber: null,
      enquiryId: enquiryId,
      clientId: null,
      status: EnumInvoiceStatus.sent,
      isFullyPaid: false,
      versionNumber: null,
      products,
      dueDate: line.invoiceDueDate ? line.invoiceDueDate : new Date(line.invoiceIssueDate),
      currency: line.invoiceCurrency,
      bankAccount: null,
      bankAccountData: null,
      cotationsId: [],
      displayCotationAirlineAndAircraft: false,
      cotationsCachedInfos: [],
      clientBillingInfosId: null,
      clientBillingInfos: {
        clientName: line.airlineTitles.join(', ') ?? line.clientName
      } as IClientBillingInfos,
      internalNote: null,
      referalInvoiceIds: [],
      followingInvoiceIds: [],
      sentToClient: false,
      invoiceObject: null,
      definitiveInvoice: true,
      amountHtTotal:
        line.invoiceAmountHtTotal < 0 ? line.invoiceAmountHtTotal * -1 : line.invoiceAmountHtTotal,
      amountTvaTotal:
        line.invoiceAmountTvaTotal < 0
          ? line.invoiceAmountTvaTotal * -1
          : line.invoiceAmountTvaTotal,
      amountTtcTotal:
        line.invoiceAmountTtcTotal < 0
          ? line.invoiceAmountTtcTotal * -1
          : line.invoiceAmountTtcTotal,
      amountSoldePaid: 0,
      amountSoldeLeft:
        line.invoiceAmountTtcTotal < 0
          ? line.invoiceAmountTtcTotal * -1
          : line.invoiceAmountTtcTotal,
      issueDate: new Date(line.invoiceIssueDate),
      isImported: true,
      dateImported: new Date()
    } as IInvoice;

    purchaseInvoice.invoiceObject = invoiceGetProductsLabel(purchaseInvoice).join(' / ');

    return this.invoiceService.create(purchaseInvoice);
  }

  async addEncaissement(line: IDataToImportLine): Promise<string[]> {
    const encaissementIds: string[] = [];

    let enquiryId: string | null = null;

    if (
      line.enquiryRefContractTitle &&
      this.enquiryPaymentSummariesObj[line.enquiryRefContractTitle]?.length
    ) {
      enquiryId = this.enquiryPaymentSummariesObj[line.enquiryRefContractTitle][0].enquiryId;
    }

    if (line.invoiceId) {
      for (const enquiryPaymentSummary of this.enquiryPaymentSummariesObj[
        line.enquiryRefContractTitle
      ]) {
        if (enquiryPaymentSummary.invoiceId === line.invoiceId) {
          if (!enquiryPaymentSummary.encaissementsAmount.includes(line.encaissementsAmount)) {
            let encaissementTypeSelected: IEncaissementType | null = null;

            for (const encaissementType of this.encaissementsTypes) {
              if (
                encaissementType.name
                  .toLocaleLowerCase()
                  .match(line.encaissementsTypeId.toLocaleLowerCase())
              ) {
                encaissementTypeSelected = encaissementType;
                break;
              }
            }

            const libelleArr: string[] = [];

            if (encaissementTypeSelected) {
              libelleArr.push(encaissementTypeSelected.name);
            }

            if (line.invoiceRef) {
              libelleArr.push(line.invoiceRef);
            }

            if (line.encaissementsDate) {
              libelleArr.push(format(line.encaissementsDate, 'yyyy-MM-dd'));
            }

            const encaissement: IEncaissement = {
              libelle: libelleArr.join(' '),
              encaissementTypeId: encaissementTypeSelected?.id,
              isCredit: line.encaissementsAmount >= 0,
              isDebit: line.encaissementsAmount < 0,
              amount: line.encaissementsAmount,
              currency: line.invoiceCurrency,
              bankAccountId: null,
              invoiceIds: [line.invoiceId],
              date: line.encaissementsDate ? format(line.encaissementsDate, 'yyyy-MM-dd') : null,
              isReconciled: false,
              comment: '',
              isImported: true,
              dateImported: new Date()
            } as IEncaissement;

            const id: string = await this.encaissementService.create(encaissement);

            encaissementIds.push(id);
          }
        }
      }
    }

    return encaissementIds;
  }

  async validationDataToImport(): Promise<void> {
    for (const line of this.dataToImport.lines) {
      if (
        line.enquiryRefContractTitle &&
        typeof this.enquiryPaymentSummariesObj[line.enquiryRefContractTitle] === 'undefined'
      ) {
        this.loadEnquiryPaymentSummariesForEnquiries(line.enquiryRefContractTitle);
      }
    }
  }

  loadEnquiryPaymentSummariesForEnquiries(enquiryRefContractTitle: string): void {
    this.enquiryPaymentSummariesObj[enquiryRefContractTitle] = [];

    this.subscriptions.add(
      this.enquiryPaymentSummaryService
        .getAllForEnquiryRefContractTitle(enquiryRefContractTitle)
        .subscribe((enquiryPaymentSummaries: IEnquiryPaymentSummary[]) => {
          this.enquiryPaymentSummariesObj[enquiryRefContractTitle] = enquiryPaymentSummaries;

          this.checkDataToImportValidation();
        })
    );
  }

  checkDataToImportValidation(): void {
    const mandatoryFields: string[] = [
      'legDates',
      'enquiryRefContractTitle',
      'userInitials',
      'invoiceAmountHtTotal',
      'invoiceAmountTtcTotal',
      'invoiceProductsLabel',
      'enquiryRouting',
      'invoiceCurrency'
    ];

    const mandatoryFieldsLabel: { [field: string]: string } = {
      legDates: 'Dates de départ',
      enquiryRefContractTitle: 'N° contrat',
      userInitials: 'Courtiers',
      invoiceAmountHtTotal: 'Montants HT',
      invoiceAmountTtcTotal: 'Montants TTC',
      invoiceProductsLabel: 'dénomination',
      enquiryRouting: 'Routing',
      invoiceCurrency: 'Devise'
    };

    for (let line of this.dataToImport.lines) {
      line.validationMsgs = [];
      line.status = EnumDataToImportLineStatus.validating;

      for (const field of mandatoryFields) {
        if (!line[field]) {
          line = this.addValidationMsgIfNotExist(line, {
            status: EnumValidationMsgStatus.mandatoryFieldsEmpty,
            comment: mandatoryFieldsLabel[field]
          });
        }
      }

      if (!this.enquiryPaymentSummariesObj[line.enquiryRefContractTitle]?.length) {
        line = this.addValidationMsgIfNotExist(line, {
          status: EnumValidationMsgStatus.enquiryNotFound
        });
      }

      let invoiceNotFound: boolean = true;
      if (this.enquiryPaymentSummariesObj[line.enquiryRefContractTitle]) {
        for (const enquiryPaymentSummary of this.enquiryPaymentSummariesObj[
          line.enquiryRefContractTitle
        ]) {
          if (line.userInitials !== enquiryPaymentSummary.userInitials) {
            line = this.addValidationMsgIfNotExist(line, {
              status: EnumValidationMsgStatus.courtierIncorrect,
              comment: enquiryPaymentSummary.userInitials
            });
          }

          if (
            line.invoiceRef &&
            line.clientName &&
            cleanString(line.clientName).toLocaleLowerCase() !==
              cleanString(enquiryPaymentSummary.clientName).toLocaleLowerCase()
          ) {
            line = this.addValidationMsgIfNotExist(line, {
              status: EnumValidationMsgStatus.clientIncorrect,
              comment: enquiryPaymentSummary.clientName
            });
          }

          if (line.airlineTitles && line.invoiceAmountTtcTotal > 0) {
            for (const airlineTitle of line.airlineTitles) {
              if (airlineTitle && !enquiryPaymentSummary.airlineTitles.includes(airlineTitle)) {
                line = this.addValidationMsgIfNotExist(line, {
                  status: EnumValidationMsgStatus.airlineIncorrect,
                  comment: enquiryPaymentSummary.airlineTitles.join(', ')
                });
              }
            }
          }

          if (line.invoiceRef && line.invoiceRef === enquiryPaymentSummary.invoiceRef) {
            line.invoiceId = enquiryPaymentSummary.invoiceId;

            invoiceNotFound = false;

            if (
              roundNumber(line.invoiceAmountHtTotal) !==
              roundNumber(enquiryPaymentSummary.invoiceAmountHtTotal)
            ) {
              line = this.addValidationMsgIfNotExist(line, {
                status: EnumValidationMsgStatus.invoiceAmountIncorrect,
                comment: this.formatPrice(
                  enquiryPaymentSummary.invoiceAmountHtTotal,
                  enquiryPaymentSummary.invoiceCurrency
                )
              });
            }

            if (
              line.encaissementsAmount &&
              !enquiryPaymentSummary.encaissementsAmount.includes(line.encaissementsAmount)
            ) {
              line = this.addValidationMsgIfNotExist(line, {
                status: EnumValidationMsgStatus.encaissementMissing
              });
            }
          }
        }
      }

      if (!line.invoiceRef) {
        line = this.addValidationMsgIfNotExist(line, {
          status: EnumValidationMsgStatus.addTemporaryLine
        });
      }

      if (line.invoiceRef && invoiceNotFound) {
        line = this.addValidationMsgIfNotExist(line, {
          status:
            line.invoiceAmountHtTotal > 0 && line.invoiceRef.match('F-')
              ? EnumValidationMsgStatus.definitiveInvoiceNotFound
              : EnumValidationMsgStatus.addTemporaryLine
        });
      } else if (line.invoiceRef && line.encaissementsAmount) {
        line = this.addValidationMsgIfNotExist(line, {
          status: EnumValidationMsgStatus.encaissementMissing
        });
      }

      if (line.encaissementsTypeId) {
        let encaissementTypeMatch: boolean = false;

        for (const encaissementType of this.encaissementsTypes) {
          if (
            encaissementType.name
              .toLocaleLowerCase()
              .match(line.encaissementsTypeId.toLocaleLowerCase())
          ) {
            encaissementTypeMatch = true;
            break;
          }
        }

        if (!encaissementTypeMatch) {
          line = this.addValidationMsgIfNotExist(line, {
            status: EnumValidationMsgStatus.modNotValid,
            comment: line.encaissementsTypeId
          });
        }
      }

      if (line.validationMsgs.length) {
        for (const validationMsg of line.validationMsgs) {
          if (this.getValidationStatusLevel(validationMsg.status) === 'error') {
            line.status = EnumDataToImportLineStatus.issueToFix;
            break;
          } else if (this.getValidationStatusLevel(validationMsg.status) === 'info') {
            line.status = EnumDataToImportLineStatus.info;
            break;
          }
        }

        if (line.status === EnumDataToImportLineStatus.validating) {
          line.status = EnumDataToImportLineStatus.warning;
        }
      } else {
        line.status = EnumDataToImportLineStatus.valid;
      }
    }
  }

  allDataToImportAreValid(): boolean {
    let nbValid: number = 0;

    for (const line of this.dataToImport.lines) {
      if (
        ![EnumDataToImportLineStatus.issueToFix, EnumDataToImportLineStatus.validating].includes(
          line.status
        )
      ) {
        nbValid++;
      }
    }

    return this.dataToImport.lines.length === nbValid;
  }

  getValidationStatusLevel(status: EnumValidationMsgStatus): 'error' | 'warning' | 'info' {
    if (
      [
        EnumValidationMsgStatus.encaissementMissing,
        EnumValidationMsgStatus.purchaseInvoiceNotFound
      ].includes(status)
    ) {
      return 'warning';
    } else if ([EnumValidationMsgStatus.addTemporaryLine].includes(status)) {
      return 'info';
    } else {
      return 'error';
    }
  }

  updateTooltip(): void {
    window['$']('[rel="tooltip"]').tooltip({
      html: true,
      boundary: 'window',
      trigger: 'hover'
    });
  }

  loadAllEncaissementTypes(): void {
    this.subscriptions.add(
      this.encaissementTypeService.getAll().subscribe((encaissementsTypes: IEncaissementType[]) => {
        this.encaissementsTypes = encaissementsTypes;
      })
    );
  }

  formatPrice(value: number, currency: string = 'EUR'): string {
    const formatter = new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2
    });

    return formatter.format(value);
  }

  addValidationMsgIfNotExist(
    line: IDataToImportLine,
    msg: IDataToImportLineValidationMsg
  ): IDataToImportLine {
    let alreadyExist: boolean = false;
    for (const validationMsg of line.validationMsgs) {
      if (validationMsg.status === msg.status) {
        alreadyExist = true;
      }
    }

    if (!alreadyExist) {
      line.validationMsgs.push(msg);
    }

    return line;
  }
}
