import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FlightBriefCateringEditComponent } from 'src/app/admin/flight-brief-caterings/flight-brief-catering-edit/flight-brief-catering-edit.component';

@NgModule({
  imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule],
  declarations: [FlightBriefCateringEditComponent],
  exports: [FlightBriefCateringEditComponent]
})
export class FlightBriefCateringEditModule {}
