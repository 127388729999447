import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

export interface IMenuLink {
  iconLeft?: IconProp;
  iconRight?: IconProp;
  text: string;
  children?: IMenuLink[];
  href?: string;
}

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss']
})
export class SidebarMenuComponent {
  @Input('menuLinks') menuLinks: IMenuLink[] = [];
  faChevronRight = faChevronRight;

  currentlyOpenedLinkIndex: number | undefined;

  constructor(private router: Router) {}

  openMenuLink(index: number): void {
    if (this.menuLinks[index].children?.length) {
      if (this.currentlyOpenedLinkIndex === index) {
        this.currentlyOpenedLinkIndex = undefined;
      } else {
        this.currentlyOpenedLinkIndex = index;
      }
    } else if (this.menuLinks[index].href) {
      this.router.navigateByUrl(this.menuLinks[index].href ?? '/');
    }
  }

  isLinkActive(menuLink: IMenuLink): boolean {
    if (menuLink.href) {
      switch (menuLink.href) {
        case '/admin':
          return window.location.pathname === menuLink.href;
        default:
          return !!window.location.pathname.match(menuLink.href);
      }
    } else if (menuLink.children?.length) {
      for (const subMenuLink of menuLink.children) {
        if (this.isLinkActive(subMenuLink)) {
          return true;
        }
      }
    }

    return false;
  }
}
