import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import firebase from 'firebase/compat/app';
import { IFinancialReport } from 'src/app/interfaces/financial-report.interface';
import { FinancialReportsFirestoreService } from './financial-reports-firestore.service';
import { EnumCurrency } from 'src/app/enums/currency.enum';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FinancialReportsService {
  constructor(private financialReportsFirestoreService: FinancialReportsFirestoreService) {}

  create(stats: IFinancialReport): Promise<string> {
    return this.financialReportsFirestoreService.create(stats);
  }

  update(stats: IFinancialReport): Promise<string> {
    return this.financialReportsFirestoreService.update(stats);
  }

  delete(id: string): Promise<void> {
    return this.financialReportsFirestoreService.delete(id);
  }

  getFromId(id: string): Observable<IFinancialReport> {
    return this.financialReportsFirestoreService.docValue$(id);
  }

  getAll(): Observable<IFinancialReport[]> {
    return this.financialReportsFirestoreService.collectionValues$();
  }

  getAllForCurrencyForYear(currency: EnumCurrency, year: number): Observable<IFinancialReport[]> {
    return this.financialReportsFirestoreService.collectionValues$(ref =>
      ref.where('currency', '==', currency).where('year', '==', year).where('userId', '==', null)
    );
  }

  getAllForCurrencyOnlyYears(currency: EnumCurrency): Observable<IFinancialReport[]> {
    return this.financialReportsFirestoreService.collectionValues$(ref =>
      ref
        .where('currency', '==', currency)
        .where('quarter', '==', null)
        .where('month', '==', null)
        .where('userId', '==', null)
    );
  }

  getOneForYear(year: number): Observable<IFinancialReport> {
    return this.financialReportsFirestoreService
      .collectionValues$(ref =>
        ref
          .where('currency', '==', EnumCurrency.EUR)
          .where('year', '==', year)
          .where('quarter', '==', null)
          .where('month', '==', null)
          .where('userId', '==', null)
          .limit(1)
      )
      .pipe(map(results => (results && results.length ? results[0] : null)));
  }

  getOneForYearForUser(year: number, userId: string): Observable<IFinancialReport> {
    return this.financialReportsFirestoreService
      .collectionValues$(ref =>
        ref
          .where('currency', '==', EnumCurrency.EUR)
          .where('year', '==', year)
          .where('quarter', '==', null)
          .where('month', '==', null)
          .where('userId', '==', userId)
          .limit(1)
      )
      .pipe(map(results => (results && results.length ? results[0] : null)));
  }

  getOneForQuarter(year: number, quarter: number): Observable<IFinancialReport> {
    return this.financialReportsFirestoreService
      .collectionValues$(ref =>
        ref
          .where('currency', '==', EnumCurrency.EUR)
          .where('year', '==', year)
          .where('quarter', '==', quarter)
          .where('month', '==', null)
          .where('userId', '==', null)
          .limit(1)
      )
      .pipe(map(results => (results && results.length ? results[0] : null)));
  }

  getOneForQuarterForUser(
    year: number,
    quarter: number,
    userId: string
  ): Observable<IFinancialReport> {
    return this.financialReportsFirestoreService
      .collectionValues$(ref =>
        ref
          .where('currency', '==', EnumCurrency.EUR)
          .where('year', '==', year)
          .where('quarter', '==', quarter)
          .where('month', '==', null)
          .where('userId', '==', userId)
          .limit(1)
      )
      .pipe(map(results => (results && results.length ? results[0] : null)));
  }

  getOneForMonth(year: number, month: number): Observable<IFinancialReport> {
    return this.financialReportsFirestoreService
      .collectionValues$(ref =>
        ref
          .where('currency', '==', EnumCurrency.EUR)
          .where('year', '==', year)
          .where('month', '==', month)
          .where('userId', '==', null)
          .limit(1)
      )
      .pipe(map(results => (results && results.length ? results[0] : null)));
  }

  getOneForMonthForUser(year: number, month: number, userId: string): Observable<IFinancialReport> {
    return this.financialReportsFirestoreService
      .collectionValues$(ref =>
        ref
          .where('currency', '==', EnumCurrency.EUR)
          .where('year', '==', year)
          .where('month', '==', month)
          .where('userId', '==', userId)
          .limit(1)
      )
      .pipe(map(results => (results && results.length ? results[0] : null)));
  }

  getAllForYear(year: number): Observable<IFinancialReport[]> {
    return this.financialReportsFirestoreService.collectionValues$(ref =>
      ref.where('year', '==', year).where('userId', '==', null)
    );
  }

  resetPagination(paginationName: string = 'list'): void {
    this.financialReportsFirestoreService.resetPaginate(paginationName);
  }

  getAllMostRecentFirst(
    paginationName: string = 'list',
    conditions: {
      field: string;
      operator: firebase.firestore.WhereFilterOp;
      value: any;
    }[] = []
  ): Observable<IFinancialReport[]> {
    const orderBy: {
      field: string;
      direction: 'asc' | 'desc';
    } = {
      field: 'modified',
      direction: 'desc'
    };

    return this.financialReportsFirestoreService.paginate(conditions, orderBy, 20, paginationName);
  }
}
