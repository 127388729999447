import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import firebase from 'firebase/compat/app';
import { SettingFirestoreService } from './settings-firestore.service';
import { IPipedriveSettings, ISetting } from 'src/app/interfaces/setting.interface';
import { IBatchOperationsParams } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class SettingService {
  constructor(private settingFirestoreService: SettingFirestoreService) {}

  create(setting: ISetting): Promise<string> {
    return this.settingFirestoreService.create(setting);
  }

  createWithId(id: string, setting: ISetting): Promise<string> {
    return this.settingFirestoreService.createWithId(id, setting);
  }

  update(setting: ISetting): Promise<string> {
    return this.settingFirestoreService.update(setting);
  }

  delete(id: string): Promise<void> {
    return this.settingFirestoreService.delete(id);
  }

  getFromId(id: string): Observable<ISetting> {
    return this.settingFirestoreService.docValue$(id);
  }

  getPipedriveSettings(): Observable<IPipedriveSettings> {
    return this.settingFirestoreService.docValue$('pipedrive') as Observable<IPipedriveSettings>;
  }

  getAll(): Observable<ISetting[]> {
    return this.settingFirestoreService.collectionValues$();
  }

  resetPagination(paginationName: string = 'list'): void {
    this.settingFirestoreService.resetPaginate(paginationName);
  }

  getAllMostRecentFirst(
    paginationName: string = 'list',
    conditions: {
      field: string;
      operator: firebase.firestore.WhereFilterOp;
      value: any;
    }[] = [],
    orderBy: {
      field: string;
      direction: 'asc' | 'desc';
    } = {
      field: 'modified',
      direction: 'desc'
    },
    itemsPerPage: number = 20
  ): Observable<ISetting[]> {
    return this.settingFirestoreService.paginate(conditions, orderBy, itemsPerPage, paginationName);
  }

  batchOperations(batchOperationsParams: IBatchOperationsParams): Promise<void> {
    return this.settingFirestoreService.batchOperations(batchOperationsParams);
  }
}
