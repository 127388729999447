import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import firebase from 'firebase/compat/app';
import { AirlineFirestoreService } from './airlines-firestore.service';
import { IAirline } from 'src/app/interfaces/airline.interface';
import { IIPipedrivePersonToAdd, PipedriveService } from '../pipedrive.service';
import { IIPipedrivePerson } from 'src/app/interfaces/pipedrive-person.interface';
import { IPipedriveOrganization } from 'src/app/interfaces/pipedrive.interface';

@Injectable({
  providedIn: 'root'
})
export class AirlineService {
  constructor(
    private airlineFirestoreService: AirlineFirestoreService,
    private pipedriveService: PipedriveService
  ) {}

  create(airline: IAirline): Promise<string> {
    return this.airlineFirestoreService.create(airline);
  }

  update(airline: IAirline): Promise<string> {
    return this.airlineFirestoreService.update(airline);
  }

  delete(id: string): Promise<void> {
    return this.airlineFirestoreService.delete(id);
  }

  getFromId(id: string): Observable<IAirline> {
    return this.airlineFirestoreService.docValue$(id);
  }

  getAll(): Observable<IAirline[]> {
    return this.airlineFirestoreService.collectionValues$();
  }

  getOneByTitle(airlineTitle: string): Observable<IAirline | null> {
    return this.airlineFirestoreService
      .collectionValues$(ref => ref.where('title', '==', airlineTitle))
      .pipe(map(results => (results && results.length ? results[0] : null)));
  }

  getAllForEnquiry(enquiryId: string): Observable<IAirline[]> {
    return this.airlineFirestoreService.collectionValues$(ref =>
      ref.where('enquiryId', '==', enquiryId).orderBy('created', 'desc')
    );
  }

  getAllWithContact(): Observable<IAirline[]> {
    return this.airlineFirestoreService.collectionValues$(ref =>
      ref.where('hasContact', '==', true)
    );
  }

  resetPagination(paginationName: string = 'list'): void {
    this.airlineFirestoreService.resetPaginate(paginationName);
  }

  getAllMostRecentFirst(
    paginationName: string = 'list',
    conditions: {
      field: string;
      operator: firebase.firestore.WhereFilterOp;
      value: any;
    }[] = []
  ): Observable<IAirline[]> {
    const orderBy: {
      field: string;
      direction: 'asc' | 'desc';
    } = {
      field: 'modified',
      direction: 'desc'
    };

    return this.airlineFirestoreService.paginate(conditions, orderBy, 20, paginationName);
  }

  async syncAirlineContactsWithPipedrive(airline: IAirline): Promise<void> {
    let hasContact: boolean = false;

    if (airline.companyContacts) {
      for (const companyContact of airline.companyContacts) {
        if (companyContact.contacts.length) {
          hasContact = true;

          break;
        }
      }
    }

    if (hasContact) {
      // Looking for organisation in Pipedrive
      const pipedriveOrganizationsSearched: IPipedriveOrganization[] =
        await this.pipedriveService.searchOrganization(airline.title);

      let currentAirlineInPipedriveId: string | null = null;

      if (pipedriveOrganizationsSearched.length) {
        currentAirlineInPipedriveId = pipedriveOrganizationsSearched[0].id;
      } else {
        currentAirlineInPipedriveId = (
          await this.pipedriveService.addOrganization({
            name: airline.title,
            '81fe4f60a08f0a3d21b562ab918dbea7553d02f3': 'COMPAGNIE AERIENNE'
          })
        ).toString();
      }

      if (airline.companyContacts) {
        for (const companyContact of airline.companyContacts) {
          if (companyContact.contacts) {
            for (const contact of companyContact.contacts) {
              if ((contact.email || contact.phone) && currentAirlineInPipedriveId) {
                const personsSearch: IIPipedrivePerson[] =
                  await this.pipedriveService.searchPersonInOrganization(
                    contact.email || contact.phone,
                    currentAirlineInPipedriveId
                  );

                const personNameArr: string[] = [];

                if (contact.firstname) {
                  personNameArr.push(contact.firstname);
                }

                if (contact.lastname) {
                  personNameArr.push(contact.lastname);
                }

                const personToAdd: IIPipedrivePersonToAdd = {
                  name: personNameArr.length ? personNameArr.join(' ') : companyContact.title,
                  org_id: currentAirlineInPipedriveId,
                  email: [],
                  phone: []
                };

                if (contact.email?.length) {
                  personToAdd.email.push({ value: contact.email });
                }

                if (contact.phone?.length) {
                  personToAdd.phone.push({ value: contact.phone });
                }

                if (contact.mobile?.length) {
                  personToAdd.phone.push({ value: contact.mobile });
                }

                if (contact.fonction?.length) {
                  personToAdd.job_title = contact.fonction;
                } else {
                  personToAdd.job_title = null;
                }

                if (!personsSearch.length) {
                  await this.pipedriveService.addPerson(personToAdd);
                } else if (personsSearch.length) {
                  await this.pipedriveService.updatePerson(personsSearch[0].id, personToAdd);
                }
              }
            }
          }
        }
      }
    }
  }

  async airlineContactsMissingFromWithPipedrive(
    airlines: IAirline[]
  ): Promise<IIPipedrivePersonToAdd[]> {
    const contactsToAddToPipedrive: IIPipedrivePersonToAdd[] = [];

    for (const airline of airlines) {
      let hasContact: boolean = false;

      if (airline.companyContacts) {
        for (const companyContact of airline.companyContacts) {
          if (companyContact.contacts.length) {
            hasContact = true;

            break;
          }
        }
      }

      if (hasContact) {
        // Looking for organisation in Pipedrive
        const pipedriveOrganizationsSearched: IPipedriveOrganization[] =
          await this.pipedriveService.searchOrganization(airline.title);

        let currentAirlineInPipedriveId: string | null = null;

        if (pipedriveOrganizationsSearched.length) {
          currentAirlineInPipedriveId = pipedriveOrganizationsSearched[0].id;
        }

        if (airline.companyContacts) {
          for (const companyContact of airline.companyContacts) {
            if (companyContact.contacts) {
              for (const contact of companyContact.contacts) {
                const personNameArr: string[] = [];

                if (contact.firstname) {
                  personNameArr.push(contact.firstname);
                }

                if (contact.lastname) {
                  personNameArr.push(contact.lastname);
                }

                const personToAdd: IIPipedrivePersonToAdd = {
                  name: personNameArr.length ? personNameArr.join(' ') : companyContact.title,
                  org_id: currentAirlineInPipedriveId,
                  email: [],
                  phone: []
                };

                if (contact.email?.length) {
                  personToAdd.email.push({ value: contact.email });
                }

                if (contact.phone?.length) {
                  personToAdd.phone.push({ value: contact.phone });
                }

                if (contact.mobile?.length) {
                  personToAdd.phone.push({ value: contact.mobile });
                }

                if (contact.fonction?.length) {
                  personToAdd.job_title = contact.fonction;
                } else {
                  personToAdd.job_title = null;
                }

                if (!currentAirlineInPipedriveId) {
                  contactsToAddToPipedrive.push(personToAdd);
                } else if ((contact.email || contact.phone) && currentAirlineInPipedriveId) {
                  const personsSearch: IIPipedrivePerson[] =
                    await this.pipedriveService.searchPersonInOrganization(
                      contact.email || contact.phone,
                      currentAirlineInPipedriveId
                    );

                  if (!personsSearch.length) {
                    contactsToAddToPipedrive.push(personToAdd);
                  }
                }
              }
            }
          }
        }
      }
    }

    return contactsToAddToPipedrive;
  }
}
