<div class="row mb-4" *ngIf="loading">
    <div class="col-lg">
        <div class="row mb-4">
            <div class="col-sm-4"><div class="skeleton-text"></div></div>
            <div class="col-sm-4"><div class="skeleton-text"></div></div>
        </div>

        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <label for="cc">Cc (email en copie)</label>
                    <input class="form-control" id="cc" name="cc" type="text" disabled />
                    <p class="text-muted">Séparé les emails par un ;</p>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label for="cci">Cci (email en copie caché)</label>
                    <input class="form-control" id="cci" name="cci" type="text" disabled />
                    <p class="text-muted">Séparé les emails par un ;</p>
                </div>
            </div>
        </div>

        <div class="form-group">
            <label for="subject">Sujet</label>
            <input class="form-control" id="subject" name="subject" type="text" disabled />
        </div>

        <div class="form-group">
            <label for="message">Contenu</label>
            <textarea
                class="form-control"
                id="message"
                name="message"
                rows="15"
                disabled
            ></textarea>
        </div>
    </div>
</div>

<div class="row mb-4" *ngIf="!loading">
    <div class="col-lg" *ngIf="!emailsToSend.length">
        <div class="alert alert-info text-center">
            Il n'y a aucun email à envoyer.<br />
            <br />
            <a
                [routerLink]="'/admin/enquiries/' + enquiry.id"
                class="btn btn-primary mb-1"
                *ngIf="enquiry"
                >Aller au suivi des cotations</a
            >
        </div>
    </div>
    <div class="col-lg" *ngIf="emailsToSend.length">
        <form [formGroup]="form" class="main-form">
            <p class="text-muted text-center mb-4" *ngIf="emailsToSend.length > 1">
                {{ currentEmailIndex + 1 }} / {{ emailsToSend.length }} emails à traiter
            </p>
            <div class="row mb-4">
                <div class="col-md-5">
                    <div
                        *ngIf="
                            emailsToSend[currentEmailIndex] &&
                            emailsToSend[currentEmailIndex].receiver
                        "
                    >
                        <a
                            [routerLink]="emailsToSend[currentEmailIndex].receiver.internalUrl"
                            *ngIf="emailsToSend[currentEmailIndex].receiver.internalUrl"
                            [innerText]="emailsToSend[currentEmailIndex].receiver.name"
                        ></a>
                        <span
                            *ngIf="!emailsToSend[currentEmailIndex].receiver.internalUrl"
                            [innerText]="emailsToSend[currentEmailIndex].receiver.name"
                        ></span>
                    </div>
                </div>
                <div class="col-md-7">
                    <div class="form-group">
                        <div
                            class="alert alert-danger text-center"
                            *ngIf="!emailsToSend[currentEmailIndex].contacts.length"
                        >
                            <div *ngIf="emailTemplateCode === 'invoice-requested'">
                                Impossible de trouver un comptable à contacter.<br />
                            </div>
                            <div *ngIf="emailTemplateCode === 'invoice-added'">
                                Impossible de trouver l'agent à contacter.<br />
                            </div>
                            <div
                                *ngIf="
                                    ['invoice-requested', 'invoice-added'].indexOf(
                                        emailTemplateCode
                                    ) === -1
                                "
                            >
                                La compagnie aérienne n'a aucun contact avec un email, veuillez en
                                ajouter un pour pouvoir la contacter.<br />
                                <br />
                                <button
                                    type="button"
                                    class="btn btn-primary"
                                    (click)="
                                        addAirlineContacts(
                                            emailsToSend[currentEmailIndex].foreignKey
                                        )
                                    "
                                >
                                    Ajouter une adresse email
                                </button>
                            </div>
                        </div>
                        <div *ngIf="emailsToSend[currentEmailIndex].contacts.length">
                            <div
                                class="form-group"
                                *ngIf="!emailsToSend[currentEmailIndex].oneContactOnly"
                            >
                                <input
                                    class="form-control"
                                    id="email"
                                    formControlName="email"
                                    required
                                    [class.is-invalid]="
                                        (form.touched || form.controls['email'].touched) &&
                                        form.controls['email'].status == 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                    type="text"
                                    readonly="true"
                                />
                            </div>

                            <select
                                class="form-control"
                                id="email"
                                formControlName="email"
                                required
                                [class.is-invalid]="
                                    (form.touched || form.controls['email'].touched) &&
                                    form.controls['email'].status == 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                                *ngIf="
                                    emailsToSend[currentEmailIndex].oneContactOnly &&
                                    emailsToSend[currentEmailIndex].contacts.length
                                "
                            >
                                <option
                                    [value]="receiver.email"
                                    *ngFor="
                                        let receiver of emailsToSend[currentEmailIndex].contacts
                                    "
                                    [innerHtml]="
                                        receiver.name
                                            ? receiver.email + ' (' + receiver.name + ')'
                                            : receiver.email
                                    "
                                ></option>
                            </select>

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched || form.controls['email'].touched) &&
                                        form.controls['email'].errors &&
                                        form.controls['email'].errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label for="cc">Cc (email en copie)</label>
                        <input
                            class="form-control"
                            id="cc"
                            formControlName="cc"
                            type="text"
                            [readonly]="!emailsToSend[currentEmailIndex].contacts.length"
                        />
                    </div>
                    <p class="text-muted">Séparer les emails par un ;</p>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label for="cci">Cci (email en copie caché)</label>
                        <input
                            class="form-control"
                            id="cci"
                            formControlName="cci"
                            type="text"
                            [readonly]="!emailsToSend[currentEmailIndex].contacts.length"
                        />
                    </div>
                    <p class="text-muted">Séparer les emails par un ;</p>
                </div>
            </div>

            <div class="form-group">
                <label for="subject">Sujet</label>
                <input
                    class="form-control"
                    id="subject"
                    formControlName="subject"
                    type="text"
                    required
                    [class.is-invalid]="
                        (form.touched || form.controls['subject'].touched) &&
                        form.controls['subject'].status == 'INVALID'
                            ? 'danger'
                            : ''
                    "
                    [readonly]="!emailsToSend[currentEmailIndex].contacts.length"
                    maxlength="255"
                />

                <div class="invalid-tooltip">
                    <div
                        *ngIf="
                            (form.touched || form.controls['subject'].touched) &&
                            form.controls['subject'].errors &&
                            form.controls['subject'].errors['required']
                        "
                    >
                        Ce champ est obligatoire.
                    </div>
                    <div
                        *ngIf="
                            (form.touched || form.controls['subject'].touched) &&
                            form.controls['subject'].errors &&
                            form.controls['subject'].errors['maxlength']
                        "
                    >
                        Ce champ doit être maximum 255 caractères.
                    </div>
                </div>
            </div>

            <div class="form-group">
                <label for="message">Contenu</label>
                <quill-editor
                    formControlName="contentHtml"
                    [readOnly]="!emailsToSend[currentEmailIndex].contacts.length"
                ></quill-editor>
            </div>

            <div class="row">
                <div class="col-md">
                    <button
                        type="button"
                        class="btn btn-primary btn-block mb-2"
                        (click)="ignoreAndGoNext()"
                        [innerText]="
                            emailsToSend.length > 1 ? 'Ignorer et passer au suivant' : 'Ignorer'
                        "
                    ></button>
                </div>
                <div class="col-md">
                    <button
                        type="button"
                        class="btn btn-primary btn-block mb-2"
                        (click)="sendMessage()"
                        [disabled]="!emailsToSend[currentEmailIndex].contacts.length"
                    >
                        Envoyer l'email
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
