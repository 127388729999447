import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import firebase from 'firebase/compat/app';
import { NotificationFirestoreService } from './notifications-firestore.service';
import { IFindCondition } from '../firestore/firestore.service';
import { INotification } from 'src/app/interfaces/notification.interface';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private notificationFirestoreService: NotificationFirestoreService) {}

  create(notification: INotification): Promise<string> {
    return this.notificationFirestoreService.create(notification);
  }

  createWithId(id: string, notification: INotification): Promise<string> {
    return this.notificationFirestoreService.createWithId(id, notification);
  }

  update(notification: INotification): Promise<string> {
    return this.notificationFirestoreService.update(notification);
  }

  delete(id: string): Promise<void> {
    return this.notificationFirestoreService.delete(id);
  }

  setAsRead(notificationId: string): Promise<string> {
    return this.notificationFirestoreService.update({
      id: notificationId,
      read: true
    } as INotification);
  }

  getFromId(id: string): Observable<INotification> {
    return this.notificationFirestoreService.docValue$(id);
  }

  getAll(): Observable<INotification[]> {
    return this.notificationFirestoreService.collectionValues$();
  }

  getAllForUser(userId: string): Observable<INotification[]> {
    return this.notificationFirestoreService.collectionValues$(ref =>
      ref.where('userId', '==', userId)
    );
  }

  getAllForUserUnread(userId: string): Observable<INotification[]> {
    return this.notificationFirestoreService.collectionValues$(ref =>
      ref.where('userId', '==', userId).where('read', '==', false)
    );
  }

  getLastXForUser(userId: string, limit: number = 20): Observable<INotification[]> {
    return this.notificationFirestoreService.collectionValues$(ref =>
      ref.where('userId', '==', userId).limit(limit).orderBy('created', 'desc')
    );
  }

  resetPagination(paginationName: string = 'list'): void {
    this.notificationFirestoreService.resetPaginate(paginationName);
  }

  getPaginatedResult(
    paginationName: string = 'list',
    conditions: IFindCondition[] = [],
    orderBy: {
      field: string;
      direction: firebase.firestore.OrderByDirection;
    } = {
      field: 'created',
      direction: 'desc'
    },
    itemsPerPage: number = 20,
    direction: 'prev' | 'next' = 'next'
  ): Observable<INotification[]> {
    return this.notificationFirestoreService.paginate(
      conditions,
      orderBy,
      itemsPerPage,
      paginationName,
      direction
    );
  }
}
