import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { PaginationService } from '../../../services/pagination.service';
import { RemoteService } from '../../../services/remote.service';

import { Subscription } from 'rxjs';

import * as XLSX from 'xlsx';
import {
  faCancel,
  faCircleCheck,
  faEdit,
  faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { EnumInvoiceType, getEnumInvoiceTypeLabel } from 'src/app/enums/invoice-type.enum';
import {
  EnumInvoiceLanguage,
  getInvoiceLanguageLabel,
  getLanguageLabel
} from 'src/app/enums/language.enum';
import {
  EnumCurrency,
  getEnumCurrencyLabel,
  getEnumCurrencySymbol
} from 'src/app/enums/currency.enum';

interface DataToImport {
  headers: string[];
  lines: string[][];
}

interface IInvoiceToImportProduct {
  descriptionTitle: string;
  amountHt: number;
  amountTtc: number;
}

interface IInvoiceToImport {
  ref: string;
  invoiceType: EnumInvoiceType;
  language: EnumInvoiceLanguage;
  enquiryRefContractTitle: string;
  enquiryId: string;
  currency: EnumCurrency;
  issueDate: Date;
  dueDate: Date;
  amountHtTotal: number;
  amountTtcTotal: number;
  clientBillingInfos: {
    clientName: string;
    street: string;
    postalCode: string;
    city: string;
    countryCode: string;
    tvaNumber: string;
    siret: string;
  };
  internalNote: string;
  products: IInvoiceToImportProduct[];
}
import countries from '../../../countries_fr.json';
import { chunk, formatPrice } from 'src/app/misc.utils';
import {
  IInvoice,
  IInvoiceProduct,
  invoiceGetProductsLabel
} from 'src/app/interfaces/invoice.interface';
import { EnumInvoiceProductDescription } from 'src/app/enums/invoice-product-description.enum';
import { getTvaRateSelected } from 'src/app/enums/tva-rates.enum';
import { EnquiryService } from 'src/app/services/enquiry/enquiry.service';
import { IEnquiry } from 'src/app/interfaces/enquiry.interface';
import { InvoiceService } from 'src/app/services/invoices/invoices.service';
import { IBatchOperationsParams } from 'src/app/services/firestore/firestore.service';
import slugify from 'slugify';
import { IUserGroup } from 'src/app/interfaces/user-group.interface';

@Component({
  selector: 'app-invoice-import',
  templateUrl: './invoice-import.component.html',
  styleUrls: ['./invoice-import.component.scss']
})
export class InvoiceImportComponent implements OnInit, OnDestroy {
  faEdit = faEdit;
  faCircleCheck = faCircleCheck;
  faTriangleExclamation = faTriangleExclamation;
  faCancel = faCancel;

  getEnumInvoiceTypeLabel = getEnumInvoiceTypeLabel;
  getInvoiceLanguageLabel = getInvoiceLanguageLabel;
  getEnumCurrencyLabel = getEnumCurrencyLabel;
  getEnumCurrencySymbol = getEnumCurrencySymbol;
  formatPrice = formatPrice;

  isLogged: boolean = false;
  userGroup: IUserGroup | null = null;

  fileContentLoaded = false;
  currentFile: File;
  dataValid = false;
  data: DataToImport = {
    headers: [],
    lines: []
  };

  columnsToAffect: {
    name: string;
    field: string;
    indexAffected: number | null;
  }[] = [
    {
      name: 'Reference',
      field: 'ref',
      indexAffected: null
    },
    {
      name: 'Type de facture',
      field: 'invoiceType',
      indexAffected: null
    },
    {
      name: 'Langue',
      field: 'language',
      indexAffected: null
    },
    {
      name: 'Dossier numéro contrat',
      field: 'enquiryRefContractTitle',
      indexAffected: null
    },
    {
      name: 'Devise',
      field: 'currency',
      indexAffected: null
    },
    {
      name: 'Date de facture',
      field: 'issueDate',
      indexAffected: null
    },
    {
      name: 'Date d’échéance',
      field: 'dueDate',
      indexAffected: null
    },
    {
      name: 'Montant total HT',
      field: 'amountHtTotal',
      indexAffected: null
    },
    {
      name: 'Montant total TTC',
      field: 'amountTtcTotal',
      indexAffected: null
    },
    {
      name: 'Client : Nom',
      field: 'clientBillingInfos.clientName',
      indexAffected: null
    },
    {
      name: 'Client : Rue',
      field: 'clientBillingInfos.street',
      indexAffected: null
    },
    {
      name: 'Client : Code postal',
      field: 'clientBillingInfos.postalCode',
      indexAffected: null
    },
    {
      name: 'Client : Ville',
      field: 'clientBillingInfos.city',
      indexAffected: null
    },
    {
      name: 'Client : Pays',
      field: 'clientBillingInfos.countryCode',
      indexAffected: null
    },
    {
      name: 'Client : TVA Intracommunautaire',
      field: 'clientBillingInfos.tvaNumber',
      indexAffected: null
    },
    {
      name: 'Client : Siret',
      field: 'clientBillingInfos.siret',
      indexAffected: null
    },
    {
      name: 'Notes internes',
      field: 'internalNote',
      indexAffected: null
    },
    {
      name: 'Produit libellé',
      field: 'products.descriptionTitle',
      indexAffected: null
    },
    {
      name: 'Montant HT',
      field: 'products.amountHt',
      indexAffected: null
    },
    {
      name: 'Montant TTC',
      field: 'products.amountTtc',
      indexAffected: null
    }
  ];
  displayColumnAffectation: boolean = true;
  form: FormArray = new FormArray([]);
  sending: boolean = false;
  imported: boolean = false;
  countriesList: {
    title: string;
    value: string;
  }[] = [];
  importingData: {
    index: number;
    total: number;
  } = {
    index: 0,
    total: 1
  };
  enquiriesObj: { [refContractTitle: string]: IEnquiry } = {};

  subscriptions = new Subscription();

  constructor(
    private remoteService: RemoteService,
    private activatedRoute: ActivatedRoute,
    public paginationService: PaginationService,
    private enquiryService: EnquiryService,
    private invoiceService: InvoiceService
  ) {
    for (const countryCode in countries) {
      this.countriesList.push({
        title: countries[countryCode],
        value: countryCode
      });
    }

    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
    this.remoteService.userGroupObservable.subscribe(
      (userGroup: IUserGroup | null) => (this.userGroup = userGroup)
    );
  }

  getItem(i: number): FormGroup {
    return this.form.at(i) as FormGroup;
  }

  getItemField(i: number, field: string): FormControl {
    return this.getItem(i).get(field) as FormControl;
  }

  getClientBillingInfosField(i: number, field: string): FormControl {
    return this.getItem(i).get('clientBillingInfos').get(field) as FormControl;
  }

  getProducts(i: number): FormArray {
    return this.getItem(i).get('products') as FormArray;
  }

  getProduct(i: number, j: number): FormGroup {
    return this.getProducts(i).at(j) as FormGroup;
  }

  getProductField(i: number, j: number, field: string): FormControl {
    return this.getProduct(i, j).get(field) as FormControl;
  }

  getInvoiceTypes(): EnumInvoiceType[] {
    return Object.values(EnumInvoiceType);
  }

  getLanguages(): EnumInvoiceLanguage[] {
    return Object.values(EnumInvoiceLanguage);
  }

  getCurrencies(): EnumCurrency[] {
    return Object.values(EnumCurrency);
  }

  ngOnInit(): void {
    this.activatedRoute.url.subscribe(() => {});
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  changeInputFile(fileInput: any): void {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.currentFile = fileInput.target.files[0];

      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.fileContentLoaded = false;

        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellDates: true });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        this.data = this.excelContentToJson(
          XLSX.utils.sheet_to_csv(ws, {
            FS: ';;'
          })
        );

        this.imported = false;
        this.displayColumnAffectation = true;

        this.automaticallyAffectColumns();

        this.fileContentLoaded = true;
      };

      reader.readAsBinaryString(this.currentFile);
    }
  }

  private excelContentToJson(data: string): DataToImport {
    const lines: string[] = data.split('\n');

    const result: DataToImport = {
      headers: [],
      lines: []
    };

    for (let i = 0; i < lines.length; i++) {
      if (i === 1) {
        result.headers = lines[i].split(';;');
      } else if (i > 1) {
        const cells: string[] = lines[i].split(';;');

        if (cells[0]) {
          result.lines.push(cells);
        }
      }
    }

    return result;
  }

  automaticallyAffectColumns(): void {
    let countAutomaticallyAffected: number = 0;
    for (let i = 0; i < this.columnsToAffect.length; i++) {
      this.columnsToAffect[i].indexAffected = null;

      for (let j = 0; j < this.data.headers.length; j++) {
        if (this.data.headers[j].match(this.columnsToAffect[i].name)) {
          this.columnsToAffect[i].indexAffected = j;
          countAutomaticallyAffected++;
        }
      }
    }

    if (countAutomaticallyAffected === this.columnsToAffect.length) {
      this.goPreview();
    }
  }

  editColumnAffectation(): void {
    this.displayColumnAffectation = true;
  }

  goPreview(): void {
    this.displayColumnAffectation = false;
    this.form = new FormArray([]);

    const invoicesToImport: { [ref: string]: IInvoiceToImport } = {};

    for (const line of this.data.lines) {
      const invoiceToImport: IInvoiceToImport = {
        products: []
      } as IInvoiceToImport;
      const invoiceProduct: IInvoiceToImportProduct = {
        descriptionTitle: null,
        amountHt: null,
        amountTtc: null
      };

      for (const columnToAffect of this.columnsToAffect) {
        const splittedField: string[] = columnToAffect.field.split('.');

        switch (splittedField[0]) {
          case 'invoiceType':
            switch (line[columnToAffect.indexAffected].toLocaleLowerCase()) {
              case 'proforma':
                invoiceToImport[splittedField[0]] = EnumInvoiceType.proforma;
                break;
              case 'proforma avoir':
                invoiceToImport[splittedField[0]] = EnumInvoiceType.proformaCreditNote;
                break;
              case 'avoir':
                invoiceToImport[splittedField[0]] = EnumInvoiceType.creditNote;
                break;
              case 'facture':
                invoiceToImport[splittedField[0]] = EnumInvoiceType.definitive;
                break;
              case 'facture achat':
                invoiceToImport[splittedField[0]] = EnumInvoiceType.purchase;
                break;
              case 'facture achat previsionnelle':
                invoiceToImport[splittedField[0]] = EnumInvoiceType.purchaseCreditNote;
                break;
            }
            break;
          case 'language':
            switch (line[columnToAffect.indexAffected].toLocaleLowerCase()) {
              case EnumInvoiceLanguage.en:
              case getInvoiceLanguageLabel(EnumInvoiceLanguage.en).toLocaleLowerCase():
                invoiceToImport[splittedField[0]] = EnumInvoiceLanguage.en;
                break;
              case EnumInvoiceLanguage.fr:
              case getInvoiceLanguageLabel(EnumInvoiceLanguage.fr).toLocaleLowerCase():
                invoiceToImport[splittedField[0]] = EnumInvoiceLanguage.fr;
                break;
            }
            break;
          case 'currency':
            if (
              Object.values(EnumCurrency).includes(
                line[columnToAffect.indexAffected].toUpperCase() as EnumCurrency
              )
            ) {
              invoiceToImport[splittedField[0]] = EnumCurrency.EUR;
            }
            break;
          case 'clientBillingInfos':
            if (typeof invoiceToImport[splittedField[0]] === 'undefined') {
              invoiceToImport.clientBillingInfos = {
                clientName: null,
                street: null,
                postalCode: null,
                city: null,
                countryCode: null,
                tvaNumber: null,
                siret: null
              };
            }

            if (splittedField[1] === 'countryCode') {
              for (const country of this.countriesList) {
                if (
                  country.value === line[columnToAffect.indexAffected].toUpperCase() ||
                  country.title.toLocaleUpperCase() ===
                    line[columnToAffect.indexAffected].toUpperCase()
                ) {
                  invoiceToImport[splittedField[0]][splittedField[1]] = country.value;
                  break;
                }
              }
            } else {
              invoiceToImport[splittedField[0]][splittedField[1]] =
                line[columnToAffect.indexAffected];
            }
            break;
          case 'products':
            switch (splittedField[1]) {
              case 'amountHt':
              case 'amountTtc':
                invoiceProduct[splittedField[1]] = parseFloat(line[columnToAffect.indexAffected]);
                break;
              default:
                invoiceProduct[splittedField[1]] = line[columnToAffect.indexAffected];
                break;
            }
            break;
          case 'amountHtTotal':
          case 'amountTtcTotal':
            invoiceToImport[splittedField[0]] = parseFloat(line[columnToAffect.indexAffected]);
            break;
          default:
            invoiceToImport[splittedField[0]] = line[columnToAffect.indexAffected];
            break;
        }
      }

      if (typeof invoicesToImport[invoiceToImport.ref] === 'undefined') {
        invoicesToImport[invoiceToImport.ref] = invoiceToImport;
      }

      invoicesToImport[invoiceToImport.ref].products.push(invoiceProduct);
    }

    for (const ref in invoicesToImport) {
      this.addInvoiceAndSet(invoicesToImport[ref]);
    }
  }

  async submitForm(): Promise<void> {
    this.form.markAsTouched();

    if (this.form.valid) {
      this.sending = true;

      this.form.disable();

      const invoices: IInvoice[] = [];

      for (const invoiceToImport of this.form.value as IInvoiceToImport[]) {
        const invoice: IInvoice = {
          ref: invoiceToImport.ref,
          invoiceType: invoiceToImport.invoiceType,
          language: invoiceToImport.language,
          enquiryId: invoiceToImport.enquiryId,
          currency: invoiceToImport.currency,
          issueDate: new Date(invoiceToImport.issueDate),
          dueDate: new Date(invoiceToImport.dueDate),
          amountHtTotal: invoiceToImport.amountHtTotal,
          amountTvaTotal: invoiceToImport.amountTtcTotal - invoiceToImport.amountHtTotal,
          amountTtcTotal: invoiceToImport.amountTtcTotal,
          clientBillingInfos: invoiceToImport.clientBillingInfos,
          internalNote: invoiceToImport.internalNote,
          definitiveInvoice: ![
            EnumInvoiceType.proforma,
            EnumInvoiceType.proformaCreditNote
          ].includes(invoiceToImport.invoiceType),
          products: [],
          created: new Date(),
          modified: new Date(),
          isImported: true,
          dateImported: new Date()
        } as IInvoice;

        const products: IInvoiceProduct[] = [];
        for (const productToImport of invoiceToImport.products) {
          const amountTva: number = productToImport.amountTtc - productToImport.amountHt;
          const tvaRate: number = (amountTva / productToImport.amountHt) * 100;

          products.push({
            id: this.remoteService.generateRandomId(),
            descriptionType: EnumInvoiceProductDescription.other,
            descriptionPercent: null,
            descriptionTitle: productToImport.descriptionTitle,
            amountHt: productToImport.amountHt,
            amountHtPositive:
              productToImport.amountHt < 0
                ? productToImport.amountHt * -1
                : productToImport.amountHt,
            tvaRateSelected: getTvaRateSelected(tvaRate),
            tvaRate,
            amountTva: productToImport.amountTtc - productToImport.amountHt,
            amountTvaPositive: amountTva < 0 ? amountTva * -1 : amountTva,
            amountTtc: productToImport.amountTtc,
            amountTtcPositive:
              productToImport.amountTtc < 0
                ? productToImport.amountTtc * -1
                : productToImport.amountTtc,
            currency: invoiceToImport.currency
          });
        }

        invoice.products = products;

        invoice.invoiceObject = invoiceGetProductsLabel(invoice).join(' / ');

        invoices.push(invoice);
      }

      try {
        await this.saveInvoices(invoices);

        this.sending = false;
        this.imported = true;
        this.form.enable();
      } catch (err) {
        console.log(err);

        this.sending = false;
        this.form.enable();

        alert(err.message);
      }
    }
  }

  async saveInvoices(invoices: IInvoice[]): Promise<void> {
    this.importingData = {
      index: 0,
      total: invoices.length
    };

    const batchOperationsParams: IBatchOperationsParams = {
      set: {}
    };

    const slugifyOptions = {
      replacement: '-', // replace spaces with replacement character, defaults to `-`
      lower: true, // convert to lower case, defaults to `false`
      strict: true, // strip special characters except replacement, defaults to `false`
      trim: true
    };
    for (let i = 0; i < invoices.length; i++) {
      const docId: string = [
        slugify(invoices[i].ref, slugifyOptions),
        slugify(invoices[i].invoiceType, slugifyOptions),
        slugify(invoices[i].clientBillingInfos.clientName, slugifyOptions)
      ].join('_');

      batchOperationsParams.set[docId] = invoices[i];
    }

    const sizeChunkBatch: number = 500;

    const chunkedData: string[][] = chunk(Object.keys(batchOperationsParams.set), sizeChunkBatch);

    for (let i = 0; i < chunkedData.length; i++) {
      this.importingData.index = sizeChunkBatch * i;

      const dataToBatch: { [key: string]: IInvoice } = {};
      for (const docId of chunkedData[i]) {
        dataToBatch[docId] = batchOperationsParams.set[docId];
      }

      await this.invoiceService.batchOperations({
        set: dataToBatch
      });
    }
  }

  addInvoice(): void {
    this.form.push(
      new FormGroup(
        {
          opened: new FormControl(true, Validators.required),
          ref: new FormControl(null, Validators.required),
          invoiceType: new FormControl(null, Validators.required),
          language: new FormControl(null, Validators.required),
          enquiryId: new FormControl(null),
          currency: new FormControl(null, Validators.required),
          issueDate: new FormControl(null, Validators.required),
          dueDate: new FormControl(null, Validators.required),
          internalNote: new FormControl(null),
          amountHtTotal: new FormControl(null, Validators.required),
          amountTtcTotal: new FormControl(null, Validators.required),
          clientBillingInfos: new FormGroup({
            clientName: new FormControl(null, Validators.required),
            street: new FormControl(null, Validators.required),
            postalCode: new FormControl(null, Validators.required),
            city: new FormControl(null, Validators.required),
            countryCode: new FormControl(null, Validators.required),
            tvaNumber: new FormControl(null),
            siret: new FormControl(null)
          }),
          products: new FormArray([])
        },
        this.checkIfInvoiceValid.bind(this)
      )
    );
  }

  addInvoiceAndSet(invoiceToImport: IInvoiceToImport): void {
    this.addInvoice();

    const formGroup: FormGroup = this.form.at(this.form.length - 1) as FormGroup;

    for (const field in formGroup.value) {
      if (typeof invoiceToImport[field] !== 'undefined') {
        switch (field) {
          case 'clientBillingInfos':
            for (const clientField in invoiceToImport[field]) {
              formGroup.get(field).get(clientField).setValue(invoiceToImport[field][clientField]);
            }
            break;
          case 'products':
            for (const product of invoiceToImport[field]) {
              this.addProductAndSet(this.form.length - 1, product);
            }
            break;
          default:
            formGroup.get(field).setValue(invoiceToImport[field]);
            break;
        }
      }
    }

    if (invoiceToImport.enquiryRefContractTitle) {
      if (typeof this.enquiriesObj[invoiceToImport.enquiryRefContractTitle] === 'undefined') {
        this.subscriptions.add(
          this.enquiryService
            .getFromRefContractTitle(invoiceToImport.enquiryRefContractTitle)
            .subscribe((enquiry: IEnquiry | null) => {
              this.enquiriesObj[invoiceToImport.enquiryRefContractTitle] = enquiry;

              if (this.enquiriesObj[invoiceToImport.enquiryRefContractTitle]) {
                formGroup
                  .get('enquiryId')
                  .setValue(this.enquiriesObj[invoiceToImport.enquiryRefContractTitle].id);
              }

              this.closeFormGroupIfValid(formGroup);
            })
        );
      } else if (this.enquiriesObj[invoiceToImport.enquiryRefContractTitle]) {
        formGroup
          .get('enquiryId')
          .setValue(this.enquiriesObj[invoiceToImport.enquiryRefContractTitle].id);
      }
    }

    this.closeFormGroupIfValid(formGroup);
  }

  closeFormGroupIfValid(formGroup: FormGroup): void {
    setTimeout(() => {
      if (formGroup.valid) {
        formGroup.get('opened').setValue(false);
      }
    }, 1000);
  }

  addProduct(i: number): void {
    this.getProducts(i).push(
      new FormGroup({
        descriptionTitle: new FormControl(null, Validators.required),
        amountHt: new FormControl(null, Validators.required),
        amountTtc: new FormControl(null, Validators.required)
      })
    );
  }

  addProductAndSet(i: number, product: IInvoiceToImportProduct): void {
    this.addProduct(i);

    const formGroup: FormGroup = this.getProducts(i).at(
      this.getProducts(i).length - 1
    ) as FormGroup;

    for (const field in formGroup.value) {
      if (typeof product[field] !== 'undefined') {
        formGroup.get(field).setValue(product[field]);
      }
    }
  }

  openInvoiceForm(i: number): void {
    this.getItemField(i, 'opened').setValue(true);
  }

  closeInvoiceForm(i: number): void {
    this.getItemField(i, 'opened').setValue(false);
  }

  checkIfInvoiceValid(formGroup: FormGroup): void {
    if (
      ![EnumInvoiceType.purchaseCreditNote, EnumInvoiceType.purchase].includes(
        formGroup.value.invoiceType
      ) &&
      !formGroup.value.enquiryId
    ) {
      formGroup.get('enquiryId').setErrors({
        required: true
      });
    }

    if (!formGroup.value.products.length) {
      formGroup.get('products').setErrors({
        missingProducts: true
      });
    } else {
      // Check if total amount match
      let amountHtTotal: number = 0;
      let amountTtcTotal: number = 0;

      for (const product of formGroup.value.products) {
        amountHtTotal += product.amountHt;
        amountTtcTotal += product.amountTtc;
      }

      if (formGroup.get('amountHtTotal').value !== amountHtTotal) {
        formGroup.get('amountHtTotal').setErrors({
          incorrectAmount: {
            sumAmount: amountHtTotal
          },
          shouldNotBeZero: formGroup.get('amountHtTotal').value === 0,
          shouldBeNegative:
            [EnumInvoiceType.creditNote, EnumInvoiceType.proformaCreditNote].includes(
              formGroup.value
            ) && formGroup.get('amountHtTotal').value > 0
        });
      }

      if (formGroup.get('amountTtcTotal').value !== amountTtcTotal) {
        formGroup.get('amountTtcTotal').setErrors({
          incorrectAmount: {
            sumAmount: amountTtcTotal
          },
          shouldNotBeZero: formGroup.get('amountTtcTotal').value === 0,
          shouldBeNegative:
            [EnumInvoiceType.creditNote, EnumInvoiceType.proformaCreditNote].includes(
              formGroup.value
            ) && formGroup.get('amountTtcTotal').value > 0
        });
      }
    }
  }

  async setValueToFormControl($event: {
    fields: {
      name: string;
      value: string;
    }[];
  }): Promise<void> {
    for (let field of $event.fields) {
      const nameList = field.name.split('.');

      let formControl: AbstractControl = this.form;
      for (let name of nameList) {
        formControl = formControl.get(name);
      }

      formControl.setValue(field.value);

      formControl.markAsTouched();
      formControl.updateValueAndValidity();
    }
  }
}
