import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';
import {
  EnumCurrency,
  getEnumCurrencyLabel,
  getEnumCurrencySymbol
} from 'src/app/enums/currency.enum';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { ICountry } from 'src/app/interfaces/country.interface';
import { Subscription } from 'rxjs';
import { CountryService } from 'src/app/services/countries/countries.service';

import countriesFr from '../../../countries_fr.json';

@Component({
  selector: 'app-country-edit',
  templateUrl: './country-edit.component.html',
  styleUrls: ['./country-edit.component.scss']
})
export class CountryEditComponent implements OnInit, OnDestroy {
  EnumCurrency = EnumCurrency;
  EnumAcl = EnumAcl;

  getEnumCurrencySymbol = getEnumCurrencySymbol;
  getEnumCurrencyLabel = getEnumCurrencyLabel;

  isLogged: boolean = false;
  form: FormGroup;
  sending: boolean = false;
  country: ICountry;
  countryId: string;

  countriesFr = countriesFr;
  countries: {
    code: string;
    name: string;
  }[] = [];

  subscriptions = new Subscription();

  constructor(
    private formBuilder: FormBuilder,
    private remoteService: RemoteService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private aclService: AclService,
    private countryService: CountryService
  ) {
    for (const countryCode in countriesFr) {
      this.countries.push({
        code: countryCode,
        name: countriesFr[countryCode]
      });
    }
    this.countries.sort((a, b) => (a.name < b.name ? -1 : 1));

    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      code: ['', [Validators.required]],
      hasDiplomaticDifficulties: [false, [Validators.required]]
    });

    this.form.disable();

    this.activatedRoute.url.subscribe(async () => {
      this.countryId = this.activatedRoute.snapshot.paramMap.get('countryId');

      if (this.countryId) {
        await this.aclService.checkAclAccess(EnumAcl.countriesEdit);
        this.loadData();
      } else {
        await this.aclService.checkAclAccess(EnumAcl.countriesAdd);
        this.form.enable();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getCurrencies(): EnumCurrency[] {
    return Object.values(EnumCurrency);
  }

  async loadData(): Promise<void> {
    if (this.isLogged) {
      this.loadCountry();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  loadCountry(): void {
    this.subscriptions.add(
      this.countryService.getFromId(this.countryId).subscribe((country: ICountry) => {
        this.country = country;

        this.setCountry();
      })
    );
  }

  setCountry(): void {
    if (this.form && this.country) {
      this.form.get('code').setValue(this.country.code);
      this.form.get('hasDiplomaticDifficulties').setValue(this.country.hasDiplomaticDifficulties);

      this.form.enable();
    }
  }

  submitForm(): void {
    this.form.markAsTouched();

    if (this.form.valid) {
      let data = Object.assign({}, this.form.value);

      for (const field in data) {
        if (typeof data[field] == 'undefined') {
          data[field] = null;
        }
      }

      this.sending = true;

      this.form.disable();

      let promise;
      if (this.countryId) {
        data.id = this.countryId;
        promise = () => this.countryService.update(data);
      } else {
        promise = () => this.countryService.create(data);
      }

      promise()
        .then(async id => {
          if (!this.countryId) {
            this.countryId = id;
          }

          this.form.reset();
          this.form.enable();
          this.sending = false;
          this.redirectAfterSaving();
        })
        .catch(err => {
          console.log(err);
          this.sending = false;

          alert(err.message);
        })
        .finally(() => {
          this.form.enable();
        });
    }
  }

  redirectAfterSaving(): void {
    this.router.navigate(['/admin/countries/' + this.countryId]);
  }
}
