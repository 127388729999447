<div
    class="alert alert-warning text-center"
    *ngIf="
        !invoiceId &&
        form.value.invoiceType &&
        enquiryInvoicesByType[form.value.invoiceType]?.length
    "
>
    <strong>Attention</strong> : il existe déjà au moins une facture du même type pour ce dossier.

    <ul class="list-unstyled mb-0">
        <li *ngFor="let enquiryInvoice of enquiryInvoicesByType[form.value.invoiceType]">
            <strong [innerText]="enquiryInvoice.ref"></strong> :
            {{ formatPrice(enquiryInvoice.amountTtcTotal, enquiryInvoice.currency) }}
        </li>
    </ul>
</div>

<form (submit)="submitForm()" [formGroup]="form" class="main-form">
    <div
        class="container"
        *ngIf="
            [EnumInvoiceType.purchase, EnumInvoiceType.purchaseCreditNote].includes(
                form.value.invoiceType
            )
        "
    >
        <div class="card mb-4">
            <div class="card-header">Upload de la facture d'achat</div>
            <div class="card-body">
                <div class="alert alert-info text-center">
                    Vous pouvez ajouter le document PDF de la facture d'achat pour référence.
                    Celui-ci n'est pas obligatoire mais aidera à remplir le formulaire ci-dessous.
                </div>
                <div class="form-group text-center mb-0" *ngIf="!form.value.document">
                    <input
                        type="file"
                        #inputFile
                        class="form-control"
                        accept="application/pdf"
                        (change)="changeInputFile($event)"
                        [disabled]="uploading"
                    />

                    <div class="text-center mt-4" *ngIf="uploading">
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden">Envoi au serveur...</span>
                        </div>
                        <br />
                        Envoi au serveur...
                    </div>
                </div>

                <div class="text-center" *ngIf="form.value.document">
                    <button type="button" class="btn btn-danger" (click)="removeDocument()">
                        Suppression du document
                    </button>
                    <div
                        class="pdf-embed-view"
                        *ngIf="form.value.document?.url"
                        #pdfViewerEmbed
                    ></div>
                </div>
            </div>
        </div>
    </div>
    <div
        class="container"
        *ngIf="
            ![EnumInvoiceType.purchase, EnumInvoiceType.purchaseCreditNote].includes(
                form.value.invoiceType
            ) ||
            invoiceId ||
            form.value.document?.url
        "
    >
        <div class="row">
            <div class="col-md-4">
                <div class="form-group">
                    <label>Dossier</label>
                    <app-enquiry-ref-picker
                        [contractRefOnly]="true"
                        [defaultValue]="form.value.enquiryId"
                        inputName="enquiryId"
                        (setValueToFormControl)="setValueToFormControl($event)"
                    ></app-enquiry-ref-picker>
                </div>
                <div class="form-group">
                    <label for="language">Langue</label>
                    <select
                        class="form-select"
                        id="language"
                        formControlName="language"
                        [class.is-invalid]="
                            (form.touched || form.controls['language'].touched) &&
                            form.controls['language'].status == 'INVALID'
                                ? 'danger'
                                : ''
                        "
                    >
                        <option
                            [value]="language"
                            *ngFor="let language of getLanguages()"
                            [innerHtml]="getInvoiceLanguageLabel(language)"
                        ></option>
                    </select>

                    <div class="invalid-tooltip">
                        <div
                            *ngIf="
                                (form.touched || form.controls['language'].touched) &&
                                form.controls['language'].errors &&
                                form.controls['language'].errors['required']
                            "
                        >
                            Ce champ est obligatoire.
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="currency">Devise</label>
                    <select
                        class="form-select"
                        id="currency"
                        formControlName="currency"
                        [class.is-invalid]="
                            (form.touched || form.controls['currency'].touched) &&
                            form.controls['currency'].status == 'INVALID'
                                ? 'danger'
                                : ''
                        "
                        (change)="updatedCurrency()"
                    >
                        <option
                            [value]="currency"
                            *ngFor="let currency of getCurrencies()"
                            [innerHtml]="
                                getEnumCurrencySymbol(currency) +
                                ' (' +
                                getEnumCurrencyLabel(currency) +
                                ')'
                            "
                        ></option>
                    </select>

                    <div class="invalid-tooltip">
                        <div
                            *ngIf="
                                (form.touched || form.controls['currency'].touched) &&
                                form.controls['currency'].errors &&
                                form.controls['currency'].errors['required']
                            "
                        >
                            Ce champ est obligatoire.
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label>Type de facture</label>
                    <div
                        class="form-control fake-input disabled"
                        *ngIf="invoiceId"
                        [innerText]="getInvoiceTypeTitle()"
                    ></div>

                    <select
                        formControlName="invoiceType"
                        class="form-select"
                        (change)="updateInvoiceType()"
                        *ngIf="!invoice || !invoiceId"
                    >
                        <option
                            [value]="invoiceType.value"
                            *ngFor="let invoiceType of invoiceTypesList"
                            [innerText]="invoiceType.title"
                        ></option>
                    </select>
                </div>

                <div
                    class="form-group"
                    *ngIf="
                        ![EnumInvoiceType.purchase, EnumInvoiceType.purchaseCreditNote].includes(
                            form.value.invoiceType
                        )
                    "
                >
                    <label for="bankAccount">Compte bancaire</label>
                    <select
                        class="form-select"
                        id="bankAccount"
                        formControlName="bankAccount"
                        [class.is-invalid]="
                            (form.touched || form.controls['bankAccount'].touched) &&
                            form.controls['bankAccount'].status == 'INVALID'
                                ? 'danger'
                                : ''
                        "
                    >
                        <option
                            [value]="bankAccount.id"
                            *ngFor="let bankAccount of bankAccountsList"
                            [innerHtml]="bankAccount.name"
                        ></option>
                    </select>

                    <div class="invalid-tooltip">
                        <div
                            *ngIf="
                                (form.touched || form.controls['bankAccount'].touched) &&
                                form.controls['bankAccount'].errors &&
                                form.controls['bankAccount'].errors['required']
                            "
                        >
                            Ce champ est obligatoire.
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="issueDate">Date facture</label>
                    <input
                        class="form-control"
                        id="issueDate"
                        formControlName="issueDate"
                        type="date"
                        [class.is-invalid]="
                            (form.touched || form.controls['issueDate'].touched) &&
                            form.controls['issueDate'].status == 'INVALID'
                                ? 'danger'
                                : ''
                        "
                    />

                    <div class="invalid-tooltip">
                        <div
                            *ngIf="
                                (form.touched || form.controls['issueDate'].touched) &&
                                form.controls['issueDate'].errors &&
                                form.controls['issueDate'].errors['required']
                            "
                        >
                            Ce champ est obligatoire.
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label
                        for="currency"
                        *ngIf="
                            ![
                                EnumInvoiceType.purchase,
                                EnumInvoiceType.purchaseCreditNote
                            ].includes(form.value.invoiceType)
                        "
                        >Échéance règlement client</label
                    >
                    <label
                        for="currency"
                        *ngIf="
                            [EnumInvoiceType.purchase, EnumInvoiceType.purchaseCreditNote].includes(
                                form.value.invoiceType
                            )
                        "
                        >Échéance paiement facture</label
                    >
                    <input
                        class="form-control"
                        id="dueDate"
                        formControlName="dueDate"
                        type="date"
                        [class.is-invalid]="
                            (form.touched || form.controls['dueDate'].touched) &&
                            form.controls['dueDate'].status == 'INVALID'
                                ? 'danger'
                                : ''
                        "
                    />

                    <div class="invalid-tooltip">
                        <div
                            *ngIf="
                                (form.touched || form.controls['dueDate'].touched) &&
                                form.controls['dueDate'].errors &&
                                form.controls['dueDate'].errors['required']
                            "
                        >
                            Ce champ est obligatoire.
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label *ngIf="form.value.invoiceType === EnumInvoiceType.creditNote"
                        >Numéro d'avoir</label
                    >
                    <label *ngIf="form.value.invoiceType === EnumInvoiceType.definitive"
                        >Numéro de facture</label
                    >
                    <label *ngIf="form.value.invoiceType === EnumInvoiceType.proforma"
                        >Numéro de proforma</label
                    >
                    <label *ngIf="form.value.invoiceType === EnumInvoiceType.proformaCreditNote"
                        >Numéro de proforma d'avoir</label
                    >
                    <label *ngIf="form.value.invoiceType === EnumInvoiceType.purchase"
                        >Référence facture d'achat</label
                    >
                    <label *ngIf="form.value.invoiceType === EnumInvoiceType.purchaseCreditNote"
                        >Référence avoir reçu</label
                    >
                    <input
                        class="form-control"
                        id="ref"
                        formControlName="ref"
                        type="text"
                        [class.is-invalid]="
                            (form.touched || form.controls['ref'].touched) &&
                            form.controls['ref'].status == 'INVALID'
                                ? 'danger'
                                : ''
                        "
                    />

                    <div class="invalid-tooltip">
                        <div
                            *ngIf="
                                (form.touched || form.controls['ref'].touched) &&
                                form.controls['ref'].errors &&
                                form.controls['ref'].errors['required']
                            "
                        >
                            Ce champ est obligatoire.
                        </div>
                    </div>
                </div>
                <div
                    class="form-group"
                    *ngIf="
                        ![EnumInvoiceType.purchase, EnumInvoiceType.purchaseCreditNote].includes(
                            form.value.invoiceType
                        )
                    "
                >
                    <label for="referalInvoiceId">Facture de référence</label>
                    <app-invoice-ref-picker
                        [defaultValue]="form.value.referalInvoiceId"
                        inputName="referalInvoiceId"
                        (setValueToFormControl)="setValueToFormControl($event)"
                    ></app-invoice-ref-picker>

                    <div class="invalid-tooltip">
                        <div
                            *ngIf="
                                (form.touched || form.controls['referalInvoiceId'].touched) &&
                                form.controls['referalInvoiceId'].errors &&
                                form.controls['referalInvoiceId'].errors['required']
                            "
                        >
                            Ce champ est obligatoire.
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-8">
                <div class="form-group">
                    <label
                        [innerText]="
                            [EnumInvoiceType.purchase, EnumInvoiceType.purchaseCreditNote].includes(
                                form.value.invoiceType
                            )
                                ? 'Compagnie aérienne/Fournisseur'
                                : 'Client'
                        "
                    ></label>
                    <pipedrive-search-organization-input
                        (setValueToFormControl)="setValueToFormControl($event)"
                        inputName="clientId"
                        [defaultValue]="form.value['clientId']"
                        [disabled]="form.disabled || sending"
                        [isInvalid]="
                            (form.touched || form.controls['clientId'].touched) &&
                            form.controls['clientId'].status == 'INVALID'
                                ? 'danger'
                                : ''
                        "
                    ></pipedrive-search-organization-input>
                </div>

                <fieldset formGroupName="clientBillingInfos">
                    <div class="form-group">
                        <label>Nom du client sur la facture</label>
                        <input
                            class="form-control"
                            id="clientName"
                            formControlName="clientName"
                            type="text"
                            [class.is-invalid]="
                                (form.touched ||
                                    form.get('clientBillingInfos').get('clientName').touched) &&
                                form.get('clientBillingInfos').get('clientName').status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched ||
                                        form.get('clientBillingInfos').get('clientName').touched) &&
                                    form.get('clientBillingInfos').get('clientName').errors &&
                                    form.get('clientBillingInfos').get('clientName').errors[
                                        'required'
                                    ]
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label>Rue</label>
                        <input
                            class="form-control"
                            id="street"
                            formControlName="street"
                            type="text"
                            [class.is-invalid]="
                                (form.touched ||
                                    form.get('clientBillingInfos').get('street').touched) &&
                                form.get('clientBillingInfos').get('street').status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched ||
                                        form.get('clientBillingInfos').get('street').touched) &&
                                    form.get('clientBillingInfos').get('street').errors &&
                                    form.get('clientBillingInfos').get('street').errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Code postal</label>
                                <input
                                    class="form-control"
                                    id="postalCode"
                                    formControlName="postalCode"
                                    type="text"
                                    [class.is-invalid]="
                                        (form.touched ||
                                            form.get('clientBillingInfos').get('postalCode')
                                                .touched) &&
                                        form.get('clientBillingInfos').get('postalCode').status ==
                                            'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                />

                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched ||
                                                form.get('clientBillingInfos').get('postalCode')
                                                    .touched) &&
                                            form.get('clientBillingInfos').get('postalCode')
                                                .errors &&
                                            form.get('clientBillingInfos').get('postalCode').errors[
                                                'required'
                                            ]
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-8">
                            <div class="form-group">
                                <label>Ville</label>
                                <input
                                    class="form-control"
                                    id="city"
                                    formControlName="city"
                                    type="text"
                                    [class.is-invalid]="
                                        (form.touched ||
                                            form.get('clientBillingInfos').get('city').touched) &&
                                        form.get('clientBillingInfos').get('city').status ==
                                            'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                />

                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched ||
                                                form.get('clientBillingInfos').get('city')
                                                    .touched) &&
                                            form.get('clientBillingInfos').get('city').errors &&
                                            form.get('clientBillingInfos').get('city').errors[
                                                'required'
                                            ]
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label>Pays</label>
                        <select
                            class="form-select"
                            id="countryCode"
                            formControlName="countryCode"
                            [class.is-invalid]="
                                (form.touched ||
                                    form.get('clientBillingInfos').get('countryCode').touched) &&
                                form.get('clientBillingInfos').get('countryCode').status ==
                                    'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        >
                            <option
                                [value]="country.value"
                                *ngFor="let country of countriesList"
                                [innerHtml]="country.title"
                            ></option>
                        </select>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched ||
                                        form.get('clientBillingInfos').get('countryCode')
                                            .touched) &&
                                    form.get('clientBillingInfos').get('countryCode').errors &&
                                    form.get('clientBillingInfos').get('countryCode').errors[
                                        'required'
                                    ]
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label>TVA Intracommunautaire</label>
                        <input
                            class="form-control"
                            id="tvaNumber"
                            formControlName="tvaNumber"
                            type="text"
                            [class.is-invalid]="
                                (form.touched ||
                                    form.get('clientBillingInfos').get('tvaNumber').touched) &&
                                form.get('clientBillingInfos').get('tvaNumber').status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched ||
                                        form.get('clientBillingInfos').get('tvaNumber').touched) &&
                                    form.get('clientBillingInfos').get('tvaNumber').errors &&
                                    form.get('clientBillingInfos').get('tvaNumber').errors[
                                        'required'
                                    ]
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label>Siret</label>
                        <input
                            class="form-control"
                            id="siret"
                            formControlName="siret"
                            type="text"
                            [class.is-invalid]="
                                (form.touched ||
                                    form.get('clientBillingInfos').get('siret').touched) &&
                                form.get('clientBillingInfos').get('siret').status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched ||
                                        form.get('clientBillingInfos').get('siret').touched) &&
                                    form.get('clientBillingInfos').get('siret').errors &&
                                    form.get('clientBillingInfos').get('siret').errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>

        <div
            class="card mb-4"
            *ngIf="
                enquiry &&
                ![EnumInvoiceType.purchase, EnumInvoiceType.purchaseCreditNote].includes(
                    form.value.invoiceType
                )
            "
        >
            <div class="card-header">
                Cotations de la facture

                <div class="form-check float-end">
                    <input
                        class="form-check-input"
                        type="checkbox"
                        id="displayCotationAirlineAndAircraft"
                        formControlName="displayCotationAirlineAndAircraft"
                    />
                    <label class="form-check-label" for="displayCotationAirlineAndAircraft"
                        >Affichage de la compagnie aérienne et de l'appareil</label
                    >
                </div>
            </div>
            <ul class="list-group list-group-flush" formGroupName="cotationsCachedInfos">
                <li
                    class="list-group-item list-group-item-warning text-center"
                    *ngIf="!confirmedCotations.length"
                >
                    Aucune cotation confirmée pour lier la facture.
                </li>
                <li
                    class="list-group-item"
                    [formGroupName]="i"
                    *ngFor="
                        let cotationsCachedInfo of form.get('cotationsCachedInfos')['controls'];
                        let i = index
                    "
                >
                    <div class="form-check">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            [value]="cotationsCachedInfo.value.cotationId"
                            [id]="'checkbox-cotation-' + cotationsCachedInfo.value.cotationId"
                            [checked]="
                                form.value.cotationsId?.indexOf(
                                    cotationsCachedInfo.value.cotationId
                                ) !== -1
                            "
                            (change)="
                                toggleCotationIdFromCotationsIdFormControl(
                                    cotationsCachedInfo.value.cotationId
                                )
                            "
                        />
                        <div class="form-group">
                            <label>Date(s)</label>
                            <div>
                                <div *ngIf="form.value.cotationsCachedInfos[i].dates.length">
                                    <span
                                        class="badge rounded-pill bg-light text-dark"
                                        *ngFor="
                                            let date of form.value.cotationsCachedInfos[
                                                i
                                            ].dates.split(' - ');
                                            let j = index
                                        "
                                    >
                                        {{ date }}
                                        <button
                                            type="button"
                                            class="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            (click)="
                                                removeValueFromCotationInfo(i, 'dates', j, ' - ')
                                            "
                                        >
                                            x
                                        </button>
                                    </span>
                                </div>
                                <button
                                    type="button"
                                    class="btn btn-primary btn-sm"
                                    (click)="addValueToCotationInfo(i, 'dates', ' - ')"
                                >
                                    + ajouter
                                </button>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Parcours</label>
                            <div>
                                <div *ngIf="form.value.cotationsCachedInfos[i].routing.length">
                                    <span
                                        class="badge rounded-pill bg-light text-dark"
                                        *ngFor="
                                            let routing of form.value.cotationsCachedInfos[
                                                i
                                            ].routing.split(' - ');
                                            let j = index
                                        "
                                    >
                                        {{ routing }}
                                        <button
                                            type="button"
                                            class="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            (click)="
                                                removeValueFromCotationInfo(i, 'routing', j, ' - ')
                                            "
                                        >
                                            x
                                        </button>
                                    </span>
                                </div>
                                <button
                                    type="button"
                                    class="btn btn-primary btn-sm"
                                    (click)="addValueToCotationInfo(i, 'routing', ' - ')"
                                >
                                    + ajouter
                                </button>
                            </div>
                        </div>

                        <div *ngIf="form.value.displayCotationAirlineAndAircraft">
                            <div class="form-group">
                                <label>Compagnie aérienne</label>
                                <div>
                                    <div
                                        *ngIf="
                                            form.value.cotationsCachedInfos[i].airlineTitle.length
                                        "
                                    >
                                        <span
                                            class="badge rounded-pill bg-light text-dark"
                                            *ngFor="
                                                let airlineTitle of form.value.cotationsCachedInfos[
                                                    i
                                                ].airlineTitle.split(' - ');
                                                let j = index
                                            "
                                        >
                                            {{ airlineTitle }}
                                            <button
                                                type="button"
                                                class="btn-close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                                (click)="
                                                    removeValueFromCotationInfo(
                                                        i,
                                                        'airlineTitle',
                                                        j,
                                                        ' - '
                                                    )
                                                "
                                            >
                                                x
                                            </button>
                                        </span>
                                    </div>
                                    <button
                                        type="button"
                                        class="btn btn-primary btn-sm"
                                        (click)="addValueToCotationInfo(i, 'airlineTitle', ' - ')"
                                        *ngIf="
                                            !form.value.cotationsCachedInfos[i].airlineTitle.length
                                        "
                                    >
                                        + ajouter
                                    </button>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Appareil</label>

                                <div>
                                    <div
                                        *ngIf="
                                            form.value.cotationsCachedInfos[i].aircraftModel.length
                                        "
                                    >
                                        <span
                                            class="badge rounded-pill bg-light text-dark"
                                            *ngFor="
                                                let aircraftModel of form.value.cotationsCachedInfos[
                                                    i
                                                ].aircraftModel.split(' - ');
                                                let j = index
                                            "
                                        >
                                            {{ aircraftModel }}
                                            <button
                                                type="button"
                                                class="btn-close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                                (click)="
                                                    removeValueFromCotationInfo(
                                                        i,
                                                        'aircraftModel',
                                                        j,
                                                        ' - '
                                                    )
                                                "
                                            >
                                                x
                                            </button>
                                        </span>
                                    </div>
                                    <button
                                        type="button"
                                        class="btn btn-primary btn-sm"
                                        (click)="addValueToCotationInfo(i, 'aircraftModel', ' - ')"
                                        *ngIf="
                                            !form.value.cotationsCachedInfos[i].aircraftModel.length
                                        "
                                    >
                                        + ajouter
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th>Libellé</th>
                        <th>Montant HT</th>
                        <th>TVA</th>
                        <th>Montant TVA</th>
                        <th>Montant net TTC</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody *ngIf="!form.value.products?.length">
                    <tr>
                        <td colspan="6" class="table-warning text-center">
                            Aucun produit ajouté. Veuillez en ajouter au moins un.
                        </td>
                    </tr>
                </tbody>
                <tbody formArrayName="products" *ngIf="form.value.products?.length">
                    <tr
                        [formGroupName]="i"
                        *ngFor="let product of form.get('products')['controls']; let i = index"
                    >
                        <td>
                            <div class="form-group">
                                <select
                                    formControlName="descriptionType"
                                    class="form-select"
                                    [class.is-invalid]="
                                        (form.touched ||
                                            getProductField(i, 'descriptionType').touched) &&
                                        getProductField(i, 'descriptionType').status == 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                    (change)="updateProductDescriptionTitle(i)"
                                >
                                    <option value="">Libellé à préciser</option>
                                    <option
                                        [value]="invoiceProductDescription"
                                        *ngFor="
                                            let invoiceProductDescription of getInvoiceProductDescriptions()
                                        "
                                        [innerText]="
                                            getEnumInvoiceProductDescriptionLabel(
                                                invoiceProductDescription
                                            )
                                        "
                                    ></option>
                                </select>

                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched ||
                                                getProductField(i, 'descriptionType').touched) &&
                                            getProductField(i, 'descriptionType').errors &&
                                            getProductField(i, 'descriptionType').errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                            </div>
                            <div
                                class="form-group"
                                *ngIf="
                                    product.value.descriptionType !==
                                    EnumInvoiceProductDescription.other
                                "
                            >
                                <select
                                    formControlName="descriptionPercent"
                                    class="form-select"
                                    [class.is-invalid]="
                                        (form.touched ||
                                            getProductField(i, 'descriptionPercent').touched) &&
                                        getProductField(i, 'descriptionPercent').status == 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                    (change)="updateProductDescriptionTitle(i)"
                                >
                                    <option value="">Pourcentage</option>
                                    <option
                                        [value]="percent"
                                        *ngFor="let percent of percentagesList"
                                        [innerHtml]="percent + '%'"
                                    ></option>
                                </select>

                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched ||
                                                getProductField(i, 'descriptionPercent').touched) &&
                                            getProductField(i, 'descriptionPercent').errors &&
                                            getProductField(i, 'descriptionPercent').errors[
                                                'required'
                                            ]
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                            </div>

                            <div
                                class="form-group"
                                *ngIf="
                                    product.value.descriptionType ===
                                    EnumInvoiceProductDescription.other
                                "
                            >
                                <input
                                    class="form-control"
                                    formControlName="descriptionTitle"
                                    type="text"
                                    [class.is-invalid]="
                                        (form.touched ||
                                            getProductField(i, 'descriptionTitle').touched) &&
                                        getProductField(i, 'descriptionTitle').status == 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                />

                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched ||
                                                getProductField(i, 'descriptionTitle').touched) &&
                                            getProductField(i, 'descriptionTitle').errors &&
                                            getProductField(i, 'descriptionTitle').errors[
                                                'required'
                                            ]
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="form-group">
                                <div class="input-group mb-3">
                                    <span
                                        class="input-group-text input-group-text-start"
                                        *ngIf="
                                            [
                                                EnumInvoiceType.creditNote,
                                                EnumInvoiceType.proformaCreditNote
                                            ].includes(form.value.invoiceType)
                                        "
                                        >-</span
                                    >
                                    <input
                                        placeholder="Montant HT"
                                        type="number"
                                        formControlName="amountHtPositive"
                                        (keyup)="updatedProductsAmounts()"
                                        (change)="updatedProductsAmounts()"
                                        class="form-control"
                                        [class.is-invalid]="
                                            (form.touched ||
                                                getProductField(i, 'amountHtPositive').touched) &&
                                            getProductField(i, 'amountHtPositive').status ==
                                                'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                    />
                                </div>

                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched ||
                                                getProductField(i, 'amountHtPositive').touched) &&
                                            getProductField(i, 'amountHtPositive').errors &&
                                            getProductField(i, 'amountHtPositive').errors[
                                                'required'
                                            ]
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="form-group">
                                <select
                                    class="form-select"
                                    id="tvaRateSelected"
                                    formControlName="tvaRateSelected"
                                    required
                                    [class.is-invalid]="
                                        (form.touched ||
                                            getProductField(i, 'tvaRateSelected').touched) &&
                                        getProductField(i, 'tvaRateSelected').status == 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                    (change)="updateTvaRateSelected(i)"
                                >
                                    <option
                                        [value]="tvaRate"
                                        *ngFor="let tvaRate of getTvaRates()"
                                        [innerHtml]="getEnumTvaRateLabel(tvaRate)"
                                    ></option>
                                </select>

                                <div
                                    class="text-danger"
                                    *ngIf="
                                        getProductField(i, 'tvaRateSelected').errors &&
                                        getProductField(i, 'tvaRateSelected').errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>

                            <div
                                class="form-group"
                                *ngIf="form.value.products[i].tvaRateSelected === 'other'"
                            >
                                <input
                                    type="number"
                                    formControlName="tvaRate"
                                    class="form-control"
                                    [class.is-invalid]="
                                        (form.touched || getProductField(i, 'tvaRate').touched) &&
                                        getProductField(i, 'tvaRate').status == 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                    (keyup)="updatedProductsAmounts()"
                                />

                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched ||
                                                getProductField(i, 'tvaRate').touched) &&
                                            getProductField(i, 'tvaRate').errors &&
                                            getProductField(i, 'tvaRate').errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="form-group">
                                <div class="input-group mb-3">
                                    <span
                                        class="input-group-text input-group-text-start"
                                        *ngIf="
                                            [
                                                EnumInvoiceType.creditNote,
                                                EnumInvoiceType.proformaCreditNote
                                            ].includes(form.value.invoiceType)
                                        "
                                        >-</span
                                    >
                                    <input
                                        type="number"
                                        formControlName="amountTvaPositive"
                                        readonly
                                        class="form-control"
                                        [class.is-invalid]="
                                            (form.touched ||
                                                getProductField(i, 'amountTvaPositive').touched) &&
                                            getProductField(i, 'amountTvaPositive').status ==
                                                'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                    />
                                </div>

                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched ||
                                                getProductField(i, 'amountTva').touched) &&
                                            getProductField(i, 'amountTva').errors &&
                                            getProductField(i, 'amountTva').errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="form-group">
                                <div class="input-group mb-3">
                                    <span
                                        class="input-group-text input-group-text-start"
                                        *ngIf="
                                            [
                                                EnumInvoiceType.creditNote,
                                                EnumInvoiceType.proformaCreditNote
                                            ].includes(form.value.invoiceType)
                                        "
                                        >-</span
                                    >
                                    <input
                                        type="number"
                                        formControlName="amountTtcPositive"
                                        readonly
                                        class="form-control"
                                        [class.is-invalid]="
                                            (form.touched ||
                                                getProductField(i, 'amountTtcPositive').touched) &&
                                            getProductField(i, 'amountTtcPositive').status ==
                                                'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                    />
                                </div>

                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched ||
                                                getProductField(i, 'amountTtcPositive').touched) &&
                                            getProductField(i, 'amountTtcPositive').errors &&
                                            getProductField(i, 'amountTtcPositive').errors[
                                                'required'
                                            ]
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <button type="button" (click)="removeProduct(i)" class="btn btn-danger">
                                X
                            </button>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colspan="6" class="text-center">
                            <button
                                type="button"
                                (click)="addProductFormGroup()"
                                class="btn btn-secondary"
                            >
                                + Ajouter une nouvelle ligne
                            </button>
                        </td>
                    </tr>

                    <tr>
                        <th></th>
                        <th></th>
                        <th colspan="2" class="text-end">Montant total</th>
                        <th
                            [innerText]="formatPrice(form.value.amountTtcTotal)"
                            class="text-end"
                        ></th>
                        <th></th>
                    </tr>
                </tfoot>
            </table>
        </div>

        <div
            class="alert alert-danger text-center"
            *ngIf="
                (form.touched || form.get('amountTtcTotal').touched) &&
                form.get('amountTtcTotal').errors &&
                form.get('amountTtcTotal').errors['errorTotalAmountCantBeZero']
            "
        >
            Une facture ne peut pas avoir un montant total égal à zéro.
        </div>

        <div
            class="alert alert-danger text-center"
            *ngIf="
                (form.touched || form.get('amountTtcTotal').touched) &&
                form.get('amountTtcTotal').errors &&
                form.get('amountTtcTotal').errors['errorTotalAmountShouldNotBeNegative']
            "
        >
            Seules les avoirs peuvent avoir un montant négatif.
        </div>

        <div
            class="alert alert-danger text-center"
            *ngIf="
                (form.touched || form.get('amountTtcTotal').touched) &&
                form.get('amountTtcTotal').errors &&
                form.get('amountTtcTotal').errors['errorTotalAmountShouldNotBePositive']
            "
        >
            Les avoirs doivent avoir un montant négatif.
        </div>

        <div
            class="form-group"
            *ngIf="
                ![EnumInvoiceType.purchase, EnumInvoiceType.purchaseCreditNote].includes(
                    form.value.invoiceType
                )
            "
        >
            <label for="buyingPriceSupplierPriceInfo"
                >Prix d'achat (en précisant la devise) (ne sera pas affichés sur la facture)</label
            >
            <input
                formControlName="buyingPriceSupplierPriceInfo"
                class="form-control"
                type="text"
                [class.is-invalid]="
                    (form.touched || form.get('buyingPriceSupplierPriceInfo').touched) &&
                    form.get('buyingPriceSupplierPriceInfo').status == 'INVALID'
                        ? 'danger'
                        : ''
                "
            />

            <div class="invalid-tooltip">
                <div
                    *ngIf="
                        (form.touched || form.get('buyingPriceSupplierPriceInfo').touched) &&
                        form.get('buyingPriceSupplierPriceInfo').errors &&
                        form.get('buyingPriceSupplierPriceInfo').errors['required']
                    "
                >
                    Ce champ est obligatoire.
                </div>
            </div>
        </div>

        <div
            class="form-group"
            *ngIf="
                ![EnumInvoiceType.purchase, EnumInvoiceType.purchaseCreditNote].includes(
                    form.value.invoiceType
                )
            "
        >
            <label for="buyingPriceSupplierPaymentsInfo"
                >Notes (sera affichés sur la facture)</label
            >
            <textarea
                formControlName="buyingPriceSupplierPaymentsInfo"
                class="form-control"
                rows="5"
                [class.is-invalid]="
                    (form.touched || form.get('buyingPriceSupplierPaymentsInfo').touched) &&
                    form.get('buyingPriceSupplierPaymentsInfo').status == 'INVALID'
                        ? 'danger'
                        : ''
                "
            ></textarea>

            <div class="invalid-tooltip">
                <div
                    *ngIf="
                        (form.touched || form.get('buyingPriceSupplierPaymentsInfo').touched) &&
                        form.get('buyingPriceSupplierPaymentsInfo').errors &&
                        form.get('buyingPriceSupplierPaymentsInfo').errors['required']
                    "
                >
                    Ce champ est obligatoire.
                </div>
            </div>
        </div>

        <div class="form-group">
            <label for="internalNote">Notes internes (ne sera pas affichés sur la facture)</label>
            <textarea
                formControlName="internalNote"
                class="form-control"
                rows="5"
                [class.is-invalid]="
                    (form.touched || form.get('internalNote').touched) &&
                    form.get('internalNote').status == 'INVALID'
                        ? 'danger'
                        : ''
                "
            ></textarea>

            <div class="invalid-tooltip">
                <div
                    *ngIf="
                        (form.touched || form.get('internalNote').touched) &&
                        form.get('internalNote').errors &&
                        form.get('internalNote').errors['required']
                    "
                >
                    Ce champ est obligatoire.
                </div>
            </div>
        </div>
    </div>

    <div class="row justify-content-center text-center mt-4">
        <div class="col-md-4">
            <button
                type="button"
                [routerLink]="
                    invoiceId
                        ? '/admin/invoices/' + invoiceId
                        : enquiryId
                        ? '/admin/invoices/enquiry/' + enquiryId
                        : '/admin/invoices'
                "
                class="btn btn-secondary btn-block mb-4"
                [disabled]="form.disabled || sending"
            >
                Annuler
            </button>
        </div>
        <div class="col-md-4">
            <button
                type="submit"
                class="btn btn-primary btn-block mb-4"
                [innerHtml]="sending ? 'Sauvegarde en cours ...' : 'Sauvegarder'"
                [disabled]="form.disabled || !form.valid || sending"
                *ngIf="!isRequesting"
            ></button>
            <button
                type="submit"
                class="btn btn-primary btn-block mb-4"
                [innerHtml]="sending ? 'Envoi en cours ...' : 'Envoyer'"
                [disabled]="form.disabled || !form.valid || sending"
                *ngIf="isRequesting"
            ></button>
        </div>
    </div>
</form>
