import { Component, OnInit } from '@angular/core';

import { PaginationService } from '../../../services/pagination.service';
import { AclService } from '../../../services/acl.service';

import { IFlightBriefCatering } from 'src/app/interfaces/flight-brief-catering.interface';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { getLanguageLabel } from 'src/app/enums/language.enum';

@Component({
  selector: 'app-list-flight-brief-caterings',
  templateUrl: './list-flight-brief-caterings.component.html',
  styleUrls: ['./list-flight-brief-caterings.component.scss']
})
export class ListFlightBriefCateringsComponent implements OnInit {
  getLanguageLabel = getLanguageLabel;

  EnumAcl = EnumAcl;

  currentPagination: string = 'flight-brief-caterings-list';
  flightBriefCaterings: IFlightBriefCatering[] = [];

  constructor(public paginationService: PaginationService, private aclService: AclService) {}

  async ngOnInit(): Promise<void> {
    await this.aclService.checkAclAccess(EnumAcl.flightBriefCateringsList);

    this.paginationService.pagination[this.currentPagination].currentPage = 0;

    this.fetchData();
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  async fetchData(): Promise<void> {
    this.paginationService.pagination[this.currentPagination].conditions = [];

    await this.paginationService.fetchData(this.currentPagination);
  }
}
