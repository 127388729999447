import { Component, OnInit, NgZone, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { PaginationService } from '../../../services/pagination.service';
import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';
import { EnumAcl } from 'src/app/enums/acl.enum';
import {
  IAirlineSupervisor,
  getAirlineSupervisorFullname
} from 'src/app/interfaces/airline-supervisor.interface';
import { Subscription } from 'rxjs';
import { AirlineSupervisorService } from 'src/app/services/airline-supervisors/airline-supervisors.service';
import { AirlineService } from 'src/app/services/airlines/airlines.service';
import { IAirline } from 'src/app/interfaces/airline.interface';

@Component({
  selector: 'app-airline-supervisor-view',
  templateUrl: './airline-supervisor-view.component.html',
  styleUrls: ['./airline-supervisor-view.component.scss']
})
export class AirlineSupervisorViewComponent implements OnInit, OnDestroy {
  EnumAcl = EnumAcl;

  getAirlineSupervisorFullname = getAirlineSupervisorFullname;

  loading: boolean = false;
  airlineSupervisorId: string;
  airlineSupervisor: IAirlineSupervisor | null = null;
  isLogged: boolean = false;

  airlineSupervisorsObj: { [id: string]: IAirlineSupervisor | null } = {};
  airlinesObj: { [id: string]: IAirline | null } = {};

  subscriptions = new Subscription();

  constructor(
    private remoteService: RemoteService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public paginationService: PaginationService,
    private aclService: AclService,
    private airlineSupervisorService: AirlineSupervisorService,
    private airlineService: AirlineService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
  }

  ngOnInit(): void {
    this.activatedRoute.url.subscribe(() => {
      this.airlineSupervisorId = this.activatedRoute.snapshot.paramMap.get('airlineSupervisorId');

      this.loadData();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async loadData(): Promise<void> {
    if (this.isLogged) {
      this.loadAirlineSupervisor();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  loadAirlineSupervisor(): void {
    this.subscriptions.add(
      this.airlineSupervisorService
        .getFromId(this.airlineSupervisorId)
        .subscribe((airlineSupervisor: IAirlineSupervisor) => {
          this.airlineSupervisor = airlineSupervisor;

          if (this.airlineSupervisor?.airlineId) {
            this.loadAirline(this.airlineSupervisor.airlineId);
          }
        })
    );
  }

  delete(): void {
    if (this.hasAclAccess(EnumAcl.airlineSupervisorsDelete)) {
      const result = confirm(
        "La suppression d'un superviseur sera permanente. Êtes-vous sûr de vouloir continuer?"
      );

      if (result) {
        const redirectUrl: string =
          '/admin/airline-supervisors/airline/' + this.airlineSupervisor.airlineId;

        this.airlineSupervisorService
          .delete(this.airlineSupervisorId)
          .then(() => {
            this.router.navigate([redirectUrl]);

            alert('La suppression a été effectuée avec succès.');
          })
          .catch(err => {
            console.log(err);
          });
      }
    }
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  loadAirline(airlineId: string): void {
    if (typeof this.airlinesObj[airlineId] === 'undefined') {
      this.airlinesObj[airlineId] = null;

      this.subscriptions.add(
        this.airlineService.getFromId(airlineId).subscribe((airline: IAirline | null) => {
          this.airlinesObj[airlineId] = airline;
        })
      );
    }
  }
}
