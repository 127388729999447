import { parse } from 'date-fns';
import { IEnquiryTrip } from './enquiry-trip.interface';
import { IStoreData } from './store-data.interface';

export interface IEnquiryItinerary extends IStoreData {
  title: string;
  type: string;
  routing: string;
  datesLabel: string;
  dateDepart: Date;
  dateReturn: Date;
  weightList: number[];
  volumeList: number[];
  passengersTotalLabel: string;
  passengersTotalLabelList: string[];
  trips: IEnquiryTrip[];
}

export const getItineraryWeightList = (itinerary: IEnquiryItinerary): number[] => {
  const weightList: number[] = [];

  for (const trip of itinerary.trips) {
    if (trip.weight && !weightList.includes(trip.weight)) {
      weightList.push(trip.weight);
    }
  }

  weightList.sort((a, b) => (a < b ? -1 : 1));

  return weightList;
};

export const getItineraryVolumeList = (itinerary: IEnquiryItinerary): number[] => {
  const volumeList: number[] = [];

  for (const trip of itinerary.trips) {
    if (trip.volume && !volumeList.includes(trip.volume)) {
      volumeList.push(trip.volume);
    }
  }

  volumeList.sort((a, b) => (a < b ? -1 : 1));

  return volumeList;
};

export const getDepartDate = (itinerary: IEnquiryItinerary): Date => {
  const datesLabelList: string[] = itinerary.datesLabel?.split(' ');

  if (datesLabelList.length) {
    return parse(datesLabelList[0], 'yyyy-MM-dd', new Date());
  }
};

export const getReturnDate = (itinerary: IEnquiryItinerary): Date => {
  const datesLabelList: string[] = itinerary.datesLabel?.split(' ');

  if (datesLabelList.length > 1) {
    return parse(datesLabelList[datesLabelList.length - 1], 'yyyy-MM-dd', new Date());
  }
};

export const getPassengersTotalLabelList = (itinerary: IEnquiryItinerary): number[] => {
  const paxList: number[] = [];

  for (const trip of itinerary.trips) {
    if (trip.passengersTotal && !paxList.includes(trip.passengersTotal)) {
      paxList.push(trip.passengersTotal);
    }
  }

  paxList.sort((a, b) => (a < b ? -1 : 1));

  return paxList;
};
