import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { IUser, getUserFullname } from 'src/app/interfaces/user.interface';
import { UserService } from 'src/app/services/user/user.service';
import { Subscription } from 'rxjs';
import { PipedriveService } from 'src/app/services/pipedrive.service';
import { IPipedriveUser } from 'src/app/interfaces/pipedrive-user.interface';
import { IUserGroup } from 'src/app/interfaces/user-group.interface';
import { convertHtmlToPlainText } from 'src/app/misc.utils';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs/breadcrumbs.service';

@Component({
  selector: 'app-users-edit',
  templateUrl: './users-edit.component.html',
  styleUrls: ['./users-edit.component.scss']
})
export class UsersEditComponent implements OnInit, OnDestroy {
  getUserFullname = getUserFullname;

  isLogged: boolean = false;
  form: FormGroup;
  sending: boolean = false;
  user: IUser;
  userId: string;
  userGroups: IUserGroup[] = [];
  pipedriveUsers: IPipedriveUser[] = [];
  loadingPipedriveUsers: boolean = false;

  subscription = new Subscription();

  constructor(
    private formBuilder: FormBuilder,
    private remoteService: RemoteService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private aclService: AclService,
    private userService: UserService,
    private pipedriveService: PipedriveService,
    private breadcrumbsService: BreadcrumbsService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      lastname: ['', [Validators.required]],
      firstname: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phone: [''],
      emailSignature: [''],
      emailSignatureHtml: [''],
      emailInCc: [false, [Validators.required]],
      userGroupId: ['', [Validators.required]],
      pipedriveId: [null],
      primaryColor: ['#000', Validators.required],
      initials: ['', Validators.required],
      displayInStatistics: [true, Validators.required]
    });

    this.form.disable();

    this.activatedRoute.url.subscribe(async () => {
      this.userId = this.activatedRoute.snapshot.paramMap.get('userId');

      if (this.userId) {
        await this.aclService.checkAclAccess(EnumAcl.usersEdit);
        this.loadData();
      } else {
        await this.aclService.checkAclAccess(EnumAcl.usersAdd);
        this.loadUserGroups();
        this.getAllPipedriveUsers();
        this.form.enable();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  updateInitials(): void {
    this.form
      .get('initials')
      .setValue(
        this.form.value.firstname.charAt(0).toUpperCase() +
          this.form.value.lastname.charAt(0).toUpperCase()
      );
  }

  loadData(): void {
    if (this.isLogged) {
      this.loadUser();
      this.loadUserGroups();
      this.getAllPipedriveUsers();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  async loadUser(): Promise<void> {
    this.subscription.add(
      this.userService.getFromId(this.userId).subscribe((user: IUser) => {
        this.user = user;

        if (this.user) {
          this.breadcrumbsService.setCurrentItem({
            id: this.user.id,
            text: getUserFullname(this.user)
          });
        }

        this.setUser();
      })
    );
  }

  setUser(): void {
    if (this.form && this.user) {
      this.form.get('lastname').setValue(this.user.lastname);
      this.form.get('firstname').setValue(this.user.firstname);
      this.form.get('initials').setValue(this.user.initials);
      this.form.get('email').setValue(this.user.email);
      this.form.get('phone').setValue(this.user.phone);
      this.form.get('emailSignature').setValue(this.user.emailSignature);
      this.form.get('emailSignatureHtml').setValue(this.user.emailSignatureHtml);
      this.form.get('emailInCc').setValue(this.user.emailInCc ?? false);
      this.form.get('userGroupId').setValue(this.user.userGroupId);
      this.form.get('pipedriveId').setValue(this.user.pipedriveId);
      this.form.get('primaryColor').setValue(this.user.primaryColor ?? '#000');
      this.form.get('displayInStatistics').setValue(this.user.displayInStatistics);

      this.form.enable();
    }
  }

  async loadUserGroups(): Promise<void> {
    const userGroupsObj: Array<object> = await this.remoteService.getAllDocuments('userGroups');

    this.userGroups = [];
    for (const userGroupObj of userGroupsObj) {
      this.userGroups.push(userGroupObj as IUserGroup);
    }
  }

  submitForm(): void {
    this.form.markAsTouched();

    if (this.form.valid) {
      let data = Object.assign({}, this.form.value);

      for (const field in data) {
        if (typeof data[field] == 'undefined') {
          data[field] = null;
        }
      }

      this.sending = true;

      this.form.disable();

      if (!data.emailSignature) {
        delete data.emailSignature;
      }
      if (!data.emailSignatureHtml) {
        data.emailSignatureHtml = '';
        data.emailSignature = '';
      } else {
        data.emailSignatureHtml = data.emailSignatureHtml;
        data.emailSignature = convertHtmlToPlainText(data.emailSignatureHtml);
      }

      if (this.userId) {
        this.remoteService
          .updateDocumentToCollection('users', this.userId, data)
          .then(() => {
            this.sending = false;
            this.router.navigate(['/admin/users/' + this.userId]);
          })
          .catch(err => {
            this.sending = false;
            this.form.enable();

            alert(err.message);
          });
      } else {
        this.remoteService
          .addDocumentToCollection('users', data)
          .then((docId: string) => {
            this.userId = docId;

            this.userService
              .sendSignInLinkToEmail(data['email'])
              .then(() => {
                this.sending = false;
                this.router.navigate(['/admin/users/' + this.userId]);
              })
              .catch(err => {
                this.sending = false;
                this.form.enable();

                alert(err.message);
              });
          })
          .catch(err => {
            this.sending = false;
            this.form.enable();

            alert(err.message);
          });
      }
    }
  }

  async getAllPipedriveUsers(): Promise<void> {
    this.loadingPipedriveUsers = true;

    this.pipedriveUsers = await this.pipedriveService.getAllUsers();

    this.loadingPipedriveUsers = false;
  }
}
