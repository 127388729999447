<div class="card-with-side-tabs">
    <div class="card-sidebar">
        <ul class="nav nav-pills flex-column" role="tablist">
            <li class="nav-item" role="presentation">
                <button
                    class="nav-link active"
                    id="invoice-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#invoice-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="invoice-tab-pane"
                    aria-selected="true"
                    [disabled]="loading"
                >
                    <span class="nav-indicator">
                        <fa-icon [icon]="faFileInvoice"></fa-icon>
                    </span>
                    Facture
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button
                    class="nav-link"
                    id="encaissements-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#encaissements-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="encaissements-tab-pane"
                    aria-selected="false"
                    [disabled]="loading"
                >
                    <span class="nav-indicator">
                        <fa-icon [icon]="faReceipt"></fa-icon>
                    </span>
                    Encaissements ({{ encaissements.length || 0 }})
                </button>
            </li>
        </ul>

        <div class="btn-toolbar" *ngIf="invoice">
            <div class="btn-group-vertical" role="group">
                <a
                    [routerLink]="'/admin/invoices/' + invoice.id + '/edit'"
                    class="btn btn-primary"
                    *ngIf="
                        (invoice.invoiceType === EnumInvoiceType.proforma &&
                            hasAclAccess(EnumAcl.invoicesEditProforma)) ||
                        (invoice.invoiceType === EnumInvoiceType.proformaCreditNote &&
                            hasAclAccess(EnumAcl.invoicesEditProformaCreditNote)) ||
                        (invoice.invoiceType === EnumInvoiceType.definitive &&
                            hasAclAccess(EnumAcl.invoicesEditDefinitive)) ||
                        (invoice.invoiceType === EnumInvoiceType.creditNote &&
                            hasAclAccess(EnumAcl.invoicesEditCreditNote)) ||
                        (invoice.invoiceType === EnumInvoiceType.proformaCreditNote &&
                            hasAclAccess(EnumAcl.invoicesEditCreditNoteProforma)) ||
                        (invoice.invoiceType === EnumInvoiceType.purchase &&
                            hasAclAccess(EnumAcl.invoicesEditPurchase)) ||
                        (invoice.invoiceType === EnumInvoiceType.purchaseCreditNote &&
                            hasAclAccess(EnumAcl.invoicesEditPurchaseCreditNote))
                    "
                >
                    <fa-icon [icon]="faEdit"></fa-icon> Édition</a
                >

                <a
                    [routerLink]="'/admin/invoices/' + invoice.id + '/duplicate'"
                    class="btn btn-primary"
                    *ngIf="
                        (invoice.invoiceType === EnumInvoiceType.proforma &&
                            hasAclAccess(EnumAcl.invoicesAddProforma)) ||
                        (invoice.invoiceType === EnumInvoiceType.creditNote &&
                            hasAclAccess(EnumAcl.invoicesAddCreditNoteFinal)) ||
                        (invoice.invoiceType === EnumInvoiceType.definitive &&
                            hasAclAccess(EnumAcl.invoicesAddFinalInvoice))
                    "
                    >Dupliquer</a
                >
                <a
                    [routerLink]="'/admin/send-emails/invoice-requested/' + invoice.id"
                    class="btn btn-primary"
                    *ngIf="
                        invoice.invoiceType === EnumInvoiceType.proforma &&
                        !hasAclAccess(EnumAcl.invoicesAddFinalInvoice)
                    "
                    >Demande d'une facture définitive</a
                >
                <a
                    [routerLink]="'/admin/send-emails/invoice-requested/' + invoice.id"
                    class="btn btn-primary"
                    *ngIf="
                        invoice.invoiceType === EnumInvoiceType.proformaCreditNote &&
                        !hasAclAccess(EnumAcl.invoicesAddCreditNoteFinal)
                    "
                    >Demande d'un avoir définitif</a
                >
                <a
                    [routerLink]="'/admin/send-emails/invoice-added/' + invoice.id"
                    class="btn btn-primary"
                    *ngIf="
                        invoice.ref &&
                        ![EnumInvoiceType.proforma, EnumInvoiceType.proformaCreditNote].includes(
                            invoice.invoiceType
                        )
                    "
                    >Envoyer au commercial</a
                >

                <a
                    [routerLink]="
                        '/admin/invoices/' + invoice.id + '/duplicate/proforma-to-final-invoice'
                    "
                    class="btn btn-primary"
                    *ngIf="
                        invoice.invoiceType === EnumInvoiceType.proforma &&
                        hasAclAccess(EnumAcl.invoicesAddFinalInvoice)
                    "
                    >Générer la facture définitive</a
                >
                <a
                    [routerLink]="
                        '/admin/invoices/' + invoice.id + '/duplicate/final-invoice-to-credit-note'
                    "
                    class="btn btn-primary"
                    *ngIf="
                        [EnumInvoiceType.definitive].includes(invoice.invoiceType) &&
                        hasAclAccess(EnumAcl.invoicesAddCreditNoteFinal)
                    "
                    >Annuler par un avoir</a
                >

                <a
                    [routerLink]="
                        '/admin/invoices/' + invoice.id + '/duplicate/final-invoice-to-credit-note'
                    "
                    class="btn btn-primary"
                    *ngIf="
                        [EnumInvoiceType.proformaCreditNote].includes(invoice.invoiceType) &&
                        hasAclAccess(EnumAcl.invoicesAddCreditNoteFinal)
                    "
                    >Générer un avoir définitif</a
                >

                <div
                    class="btn-group"
                    *ngIf="
                        ![EnumInvoiceType.proforma, EnumInvoiceType.proformaCreditNote].includes(
                            invoice.invoiceType
                        )
                    "
                >
                    <button
                        class="btn btn-primary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton-status"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        [class.disabled]="!hasAclAccess(EnumAcl.invoicesSwitchStatus)"
                    >
                        Statut : {{ getEnumInvoiceStatusLabel(invoice.status) }}
                    </button>
                    <div
                        class="dropdown-menu dropdown-menu-end"
                        aria-labelledby="dropdownMenuButton-status"
                    >
                        <button
                            type="button"
                            class="dropdown-item"
                            *ngFor="let status of getInvoiceStatuses()"
                            [innerHtml]="
                                'Changer le statut en \'' + getEnumInvoiceStatusLabel(status) + '\''
                            "
                            (click)="changeStatus(status)"
                            [class.disabled]="invoice.status === status"
                        ></button>
                    </div>
                </div>

                <div
                    class="btn-group"
                    *ngIf="
                        hasAclAccess(EnumAcl.invoicesSendInvoiceToClients) &&
                        invoice.invoiceType !== EnumInvoiceType.purchase
                    "
                >
                    <button
                        class="btn btn-primary dropdown-toggle"
                        type="button"
                        id="sent-client"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        Envoyé au client : {{ invoice.sentToClient ? "Oui" : "Non" }}
                    </button>
                    <div class="dropdown-menu dropdown-menu-end" aria-labelledby="sent-client">
                        <button
                            type="button"
                            class="dropdown-item"
                            *ngFor="let sentToClient of sentToClientList"
                            [innerHtml]="'Changer en \'' + sentToClient.title + '\''"
                            (click)="changeSentToClient(sentToClient.value)"
                            [class.disabled]="invoice.sentToClient === sentToClient.value"
                        ></button>
                    </div>
                </div>

                <button
                    *ngIf="
                        ![EnumInvoiceType.purchase, EnumInvoiceType.purchaseCreditNote].includes(
                            invoice.invoiceType
                        )
                    "
                    type="button"
                    (click)="downloadPdf()"
                    class="btn btn-primary ml-4 mr-4"
                    [disabled]="loading || !generatedPdfUrl"
                >
                    Télécharger
                </button>
            </div>

            <button
                type="button"
                (click)="delete()"
                class="btn btn-danger ml-4"
                *ngIf="hasAclAccess(EnumAcl.invoicesDelete)"
            >
                <fa-icon [icon]="faTrash"></fa-icon>

                Supprimer
            </button>
        </div>

        <div
            class="card mb-4"
            *ngIf="
                invoice &&
                (invoice.referalInvoiceIds?.length || invoice.followingInvoiceIds?.length)
            "
        >
            <div class="list-group list-group-flush related-invoices-quick-access placeholder-glow">
                <a
                    class="list-group-item list-group-item-action"
                    [routerLink]="'/admin/invoices/' + itemId"
                    *ngFor="let itemId of invoice.referalInvoiceIds"
                    target="_blank"
                    [class.disabled]="!invoicesObj[itemId]"
                >
                    <div>
                        <strong
                            [innerText]="
                                [
                                    EnumInvoiceType.proforma,
                                    EnumInvoiceType.proformaCreditNote
                                ].includes(invoicesObj[itemId]?.invoiceType)
                                    ? 'Facture proforma'
                                    : 'Facture de référence'
                            "
                        ></strong>
                        <div class="placeholder w-100" *ngIf="loadingReferalInvoice"></div>
                        <div
                            *ngIf="!loadingReferalInvoice && invoicesObj[itemId]"
                            [innerText]="getInvoiceTitle(invoicesObj[itemId])"
                        ></div>
                        <div *ngIf="!loadingReferalInvoice && !invoicesObj[itemId]">
                            Facture supprimée
                        </div>
                    </div>
                    <fa-icon [icon]="faExternalLink"></fa-icon
                ></a>
                <a
                    class="list-group-item list-group-item-action"
                    [routerLink]="'/admin/invoices/' + itemId"
                    *ngFor="let itemId of invoice.followingInvoiceIds?.length"
                    target="_blank"
                    [class.disabled]="!invoicesObj[itemId]"
                >
                    <div>
                        <strong
                            *ngIf="
                                [
                                    EnumInvoiceType.creditNote,
                                    EnumInvoiceType.purchaseCreditNote
                                ].includes(invoicesObj[itemId]?.invoiceType)
                            "
                            >Avoir</strong
                        >
                        <strong
                            *ngIf="
                                [EnumInvoiceType.definitive].includes(
                                    invoicesObj[itemId]?.invoiceType
                                )
                            "
                            >Facture définitive</strong
                        >
                        <strong
                            *ngIf="
                                ![
                                    EnumInvoiceType.creditNote,
                                    EnumInvoiceType.purchaseCreditNote,
                                    EnumInvoiceType.definitive
                                ].includes(invoicesObj[itemId]?.invoiceType)
                            "
                            >Facture suivante</strong
                        >
                        <div class="placeholder w-100" *ngIf="loadingFollowingInvoice"></div>
                        <div
                            *ngIf="!loadingFollowingInvoice && invoicesObj[itemId]"
                            [innerText]="getInvoiceTitle(invoicesObj[itemId])"
                        ></div>
                        <div *ngIf="!loadingFollowingInvoice && !invoicesObj[itemId]">
                            Facture supprimée
                        </div>
                    </div>
                    <fa-icon [icon]="faExternalLink"></fa-icon>
                </a>
            </div>
        </div>
    </div>
    <div class="tab-content-container">
        <div class="tab-content">
            <div
                class="tab-pane fade show active"
                id="invoice-tab-pane"
                role="tabpanel"
                aria-labelledby="invoice-tab"
                tabindex="0"
            >
                <div class="row justify-content-center" *ngIf="loading">Chargement ...</div>

                <ng-container *ngIf="!loading && invoice">
                    <div
                        class="card mb-4"
                        *ngIf="
                            invoice.id &&
                            ![
                                EnumInvoiceType.proforma,
                                EnumInvoiceType.proformaCreditNote,
                                EnumInvoiceType.purchase,
                                EnumInvoiceType.purchaseCreditNote
                            ].includes(invoice.invoiceType)
                        "
                    >
                        <div class="card-body">
                            <h5 class="card-title text-center">
                                Cette facture a été validée et n'est plus modifiable.
                            </h5>
                            Pour corriger une erreur sur la facture, la seule solution est :
                            <ol class="mb-0">
                                <li>D'annuler la facture en générant un avoir</li>
                                <li>En dupliquant la facture</li>
                            </ol>
                        </div>
                    </div>

                    <div
                        class="card mb-4"
                        *ngIf="
                            invoice.id && [EnumInvoiceType.purchase].includes(invoice.invoiceType)
                        "
                    >
                        <div class="card-body">
                            <h5 class="card-title text-center mb-0">
                                Cette facture est une facture d'achat
                            </h5>
                        </div>
                    </div>

                    <div
                        class="card mb-4"
                        *ngIf="
                            invoice.id &&
                            [EnumInvoiceType.purchaseCreditNote].includes(invoice.invoiceType)
                        "
                    >
                        <div class="card-body">
                            <h5 class="card-title text-center mb-0">
                                Cette facture est un avoir reçu d'un fournisseur/prestataire
                            </h5>
                        </div>
                    </div>

                    <div class="alert alert-success text-center" *ngIf="invoice.isFullyPaid">
                        <fa-icon [icon]="faCheck"></fa-icon> Facture soldée
                    </div>
                    <ng-container *ngIf="!invoice.isFullyPaid">
                        <div
                            class="alert alert-danger text-center"
                            *ngIf="getInvoiceDaysLate(invoice) > 0"
                        >
                            <fa-icon [icon]="faWarning"></fa-icon> Facture en retard de
                            <strong [innerText]="getInvoiceDaysLate(invoice)"></strong> jours.<br />

                            <button
                                class="btn btn-success mt-2"
                                [routerLink]="'/admin/encaissements/add/invoice/' + invoice.id"
                            >
                                Solder la facture
                            </button>
                        </div>

                        <div
                            class="alert alert-warning text-center"
                            *ngIf="getInvoiceDaysLate(invoice) <= 0"
                        >
                            <fa-icon [icon]="faWarning"></fa-icon> Facture à régler avant
                            <strong
                                [innerText]="
                                    getInvoiceDaysLate(invoice) !== 0
                                        ? getInvoiceDaysLate(invoice) * -1 + ' jours'
                                        : 'aujourd\'hui'
                                "
                            ></strong
                            >.
                            <br />

                            <button
                                class="btn btn-success mt-2"
                                [routerLink]="'/admin/encaissements/add/invoice/' + invoice.id"
                            >
                                Facture soldée
                            </button>
                        </div>
                    </ng-container>

                    <div
                        class="alert alert-info text-center"
                        *ngIf="
                            (invoice.internalNote && invoice.internalNote.trim() !== '') ||
                            invoice.buyingPriceSupplierPriceInfo
                        "
                    >
                        <div *ngIf="invoice.internalNote && invoice.internalNote.trim() !== ''">
                            <strong>Notes internes : </strong> {{ invoice.internalNote }}
                        </div>
                        <div *ngIf="invoice.buyingPriceSupplierPriceInfo">
                            <strong>Prix d'achat : </strong>
                            {{ invoice.buyingPriceSupplierPriceInfo }}
                        </div>
                    </div>

                    <div class="card mb-4">
                        <div class="text-center mt-4" *ngIf="loadingGeneratedPdf">
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Chargement...</span>
                            </div>
                        </div>

                        <div class="pdf-embed-view" #pdfViewerEmbed></div>
                    </div>
                    <div class="text-center" *ngIf="!invoice.document?.url">
                        <button
                            type="button"
                            class="btn btn-light btn-sm"
                            (click)="regenerateInvoicePdf()"
                        >
                            <fa-icon [icon]="faRefresh"></fa-icon> Regénérer le PDF
                        </button>
                    </div>
                </ng-container>
            </div>
            <div
                class="tab-pane fade"
                id="encaissements-tab-pane"
                role="tabpanel"
                aria-labelledby="encaissements-tab"
                tabindex="0"
            >
                <div class="card mb-4">
                    <div class="card-header">
                        Encaissements

                        <a
                            [routerLink]="
                                invoiceId
                                    ? '/admin/encaissements/add/invoice/' + invoiceId
                                    : '/admin/encaissements/add'
                            "
                            class="btn btn-primary btn-sm float-end"
                            *ngIf="hasAclAccess(EnumAcl.encaissementsAdd)"
                            ><fa-icon [icon]="faPlus"></fa-icon> Ajout</a
                        >
                    </div>
                    <div class="list-group list-group-flush">
                        <ng-container *ngIf="loadingEncaissements">
                            <li class="list-group-item placeholder-glow">
                                <div
                                    class="placeholder w-100"
                                    *ngFor="let number of [].constructor(5)"
                                ></div>
                            </li>
                        </ng-container>
                        <ng-container *ngIf="!loadingEncaissements">
                            <li
                                class="list-group-item list-group-item-warning text-center"
                                *ngIf="!encaissements.length"
                            >
                                Aucun encaissement liée
                            </li>
                            <a
                                [routerLink]="'/admin/encaissements/' + encaissement.id"
                                class="list-group-item list-group-item-action d-flex align-items-center justify-content-between gap-2 placeholder-glow"
                                *ngFor="let encaissement of encaissements"
                                target="_blank"
                            >
                                <div>
                                    <strong [innerText]="encaissement.libelle"></strong><br />
                                    {{ formatPrice(encaissement.amount, encaissement.currency)
                                    }}<br />
                                    {{ encaissement.date | date : "dd/MM/yyyy" }}
                                </div>
                                <fa-icon [icon]="faExternalLink"></fa-icon>
                            </a>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
