import { Component, OnDestroy, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import {
  BreadcrumbsService,
  ICurrentBreadcrumbItem
} from '../../services/breadcrumbs/breadcrumbs.service';
import { Title } from '@angular/platform-browser';

interface IBreadcrumbLink {
  text: string;
  url?: string;
}

@Component({
  selector: 'app-header-menu-breadcrumbs',
  templateUrl: './header-menu-breadcrumbs.component.html',
  styleUrls: ['./header-menu-breadcrumbs.component.scss']
})
export class HeaderMenuBreadcrumbsComponent implements OnInit, OnDestroy {
  faHouse = faHouse;

  breadcrumbLinks: IBreadcrumbLink[] = [];
  currentRoute: string = '/';

  itemLabels: {
    [itemId: string]: string;
  } = {};

  private subscriptions = new Subscription();

  constructor(
    private router: Router,
    private breadcrumbsService: BreadcrumbsService,
    private titleService: Title
  ) {
    this.updateLinks();

    this.router.events.subscribe((event: Event) => {
      this.currentRoute = window.location.pathname;

      if (event instanceof NavigationEnd) {
        // Hide progress spinner or progress bar
        this.currentRoute = event.url;
      }

      this.updateLinks();
    });
  }

  ngOnInit(): void {
    if (typeof window !== 'undefined') {
      this.subscriptions.add(
        this.breadcrumbsService.currentItemObservable.subscribe(
          (currentItem: ICurrentBreadcrumbItem | null) => {
            if (currentItem) {
              this.itemLabels[currentItem.id] = currentItem.text;
            }

            this.currentRoute = window.location.pathname;

            this.updateLinks();
          }
        )
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  updateLinks(): void {
    this.breadcrumbLinks = [];

    const splittedRoute: string[] = this.currentRoute.split('/');

    for (let i = 2; i < splittedRoute.length; i++) {
      let text: string | null = null;

      switch (splittedRoute[i]) {
        case 'dashboard':
          text = 'Tableau de bord';
          break;
        case 'flight-calendar':
          text = 'Calendrier des vols';
          break;
        case 'statistics':
          text = 'Statistiques';
          break;
        case 'my-account':
          text = 'Mon compte';
          break;
        case 'finance':
          text = 'Finance';
          break;
        case 'clients':
          text = 'Clients';
          break;
        case 'airlines':
          text = 'Compagnies aériennes';
          break;
        case 'encaissements':
          text = 'Encaissements';
          break;
        case 'aircraft-models':
          text = "Modèles d'avions";
          break;
        case 'aircrafts':
          text = 'Appareils aériens';
          break;
        case 'simulateur-aircraft-models':
          text = "Modèles d'avions pour le simulateur";
          break;
        case 'lieux-dits':
          text = 'Lieux-dits';
          break;
        case 'email-templates':
          text = "Templates d'email";
          break;
        case 'user-groups':
          text = "Groupes d'utilisateurs";
          break;
        case 'encaissement-types':
          text = "Types d'encaissement";
          break;
        case 'airports':
          text = 'Aéroports';
          break;
        case 'airport':
          text = 'Aéroport';
          break;
        case 'enquiries':
          text = 'Dossiers';
          break;
        case 'enquiry':
          text = 'Dossier';
          break;
        case 'bank-accounts':
          text = 'Comptes bancaires';
          break;
        case 'flight-brief-caterings':
          text = 'Caterings de flight briefs';
          break;
        case 'crawler-urls':
          text = 'Logs de scripts';
          break;
        case 'backups':
          text = 'Sauvegardes';
          break;
        case 'countries':
          text = 'Pays';
          break;
        case 'flight-checklist-templates':
          text = 'Modèles de checklist de vols';
          break;
        case 'airline-supervisors':
          text = 'Superviseurs compagnie aérienne';
          break;
        case 'crews':
          text = 'Équipage compagnie aérienne';
          break;
        case 'fbos':
          text = "FBOs d'aéroport";
          break;
        case 'handlings':
          text = "Handlings d'aéroport";
          break;
        case 'employee-bonus-rules':
          text = 'Règles des bonus des commerciaux';
          break;
        case 'flight-briefs':
          text = 'Flight Briefs';
          break;
        case 'users':
          text = 'Utilisateurs';
          break;
        case 'invoices':
          text = 'Factures';
          break;
        case 'quotations':
          text = 'Devis';
          break;
        case 'export-requests':
          text = "Requêtes d'export";
          break;
        case 'list':
          text = 'Liste';
          break;
        case 'add':
          text = 'Ajout';
          break;
        case 'view':
          text = 'Vue';
          break;
        case 'edit':
          text = 'Édition';
          break;
        case 'import':
          text = 'Import';
          break;
        case 'list-no-capacity':
          text = 'Appareils sans capacité';
          break;
        case 'edit-password':
          text = 'Modifier mot de passe';
          break;
        case 'relance-airline':
          text = 'Message de relance';
          break;
        case 'select-airlines':
          text = 'Sélection compagnies aériennes';
          break;
        case 'send-message-to-airlines':
          text = 'Envoi des messages aux compagnies aériennes';
          break;
        case 'cancel-email-airline':
          text = "Message d'annulation";
          break;
        case 'blank-email':
          text = 'Email sans contenu';
          break;
        case 'settings':
          text = 'Configuration Simplyfly';
          break;
        default:
          if (typeof this.itemLabels[splittedRoute[i]] === 'undefined') {
            text = splittedRoute[i];
          } else if (typeof this.itemLabels[splittedRoute[i]] !== 'undefined') {
            text = this.itemLabels[splittedRoute[i]];
          }

          break;
      }

      if (text) {
        let url: string = '';

        for (let j = 0; j <= i; j++) {
          url += '/' + splittedRoute[j];
        }

        this.breadcrumbLinks.push({
          text: text ?? splittedRoute[i],
          url
        });
      }
    }

    let titleArr: string[] = [];

    if (this.breadcrumbLinks.length) {
      for (const breadcrumbLink of this.breadcrumbLinks) {
        titleArr.push(breadcrumbLink.text);
      }
    }

    this.updateCurrentTitle(titleArr.join(' - '));
  }

  updateCurrentTitle(text: string | null): void {
    this.titleService.setTitle(text ? 'Simplyfly - ' + text : 'Simplyfly');
  }
}
