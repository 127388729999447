<div class="row justify-content-center">
    <div class="col-md-8 col-lg-6">
        <div class="card mb-4">
            <div
                class="card-header text-center"
                [innerHtml]="
                    flightBriefCatering &&
                    flightBriefCatering.textByLanguage &&
                    flightBriefCatering.textByLanguage[EnumLanguage.fr]
                        ? flightBriefCatering.textByLanguage[EnumLanguage.fr]
                        : ''
                "
            ></div>
            <div class="card-body" *ngIf="loading">
                <h3><div class="placeholder w-100"></div></h3>
            </div>
            <div class="card-body" *ngIf="!loading && flightBriefCatering">
                <ul class="list-unstyled">
                    <li><strong>Position</strong> : {{ flightBriefCatering.position }}</li>

                    <li>
                        <strong>Département(s) concerné(s)</strong> :
                        {{ getFlightBriefCateringDepartmentList(flightBriefCatering).join(", ") }}
                    </li>
                </ul>

                <fieldset>
                    <legend>Texte</legend>
                    <div *ngFor="let language of getLanguages()">
                        <strong [innerText]="getLanguageLabel(language)"></strong> :
                        <div
                            [innerText]="
                                flightBriefCatering.textByLanguage &&
                                flightBriefCatering.textByLanguage[language]
                                    ? flightBriefCatering.textByLanguage[language]
                                    : ''
                            "
                        ></div>
                    </div>
                </fieldset>

                <div class="text-center mt-4">
                    <div class="btn-group">
                        <button
                            type="button"
                            (click)="delete()"
                            class="btn btn-danger"
                            *ngIf="hasAclAccess(EnumAcl.flightBriefCateringsDelete)"
                        >
                            Supprimer
                        </button>
                        <a
                            [routerLink]="
                                '/admin/flight-brief-caterings/' + flightBriefCatering.id + '/edit'
                            "
                            class="btn btn-primary"
                            *ngIf="hasAclAccess(EnumAcl.flightBriefCateringsEdit)"
                            >Éditer</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
