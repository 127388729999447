<div class="text-center mt-4 mb-4" *ngIf="flightBrief">
    <a
        [routerLink]="'/admin/flight-briefs/' + flightBrief.id + '/edit'"
        class="btn btn-primary ml-4 mr-4"
        *ngIf="hasAclAccess(EnumAcl.flightBriefsEdit)"
        >Éditer</a
    >
    <button
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#modal-trips-to-export"
        class="btn btn-primary ml-4 mr-4"
        [disabled]="loading || generatingPdf"
        *ngIf="flightBrief?.trips.length > 1"
    >
        Télécharger
    </button>
    <button
        type="button"
        class="btn btn-primary ml-4 mr-4"
        (click)="generatePdf()"
        [disabled]="loading || generatingPdf"
        *ngIf="flightBrief?.trips.length === 1"
    >
        Télécharger
    </button>
    <button
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#modal-mark-as-sent"
        class="btn btn-primary ml-4"
        *ngIf="hasAclAccess(EnumAcl.flightBriefsMarkedAsSent) && !flightBrief?.isSent"
    >
        Envoyé au client ?
    </button>
    <button
        type="button"
        (click)="delete()"
        class="btn btn-primary ml-4"
        *ngIf="hasAclAccess(EnumAcl.flightBriefsDelete)"
    >
        Supprimer
    </button>
</div>

<div class="row justify-content-center" *ngIf="loading">Chargement ...</div>

<div *ngIf="!loading && flightBrief" class="container mb-4">
    <div class="alert alert-success" *ngIf="flightBrief.isSent">
        <div class="text-center text-md-start mb-2 d-md-flex justify-content-md-between">
            <strong>Flight brief envoyé au client</strong>
            <div>
                <button
                    type="button"
                    class="btn btn-secondary btn-sm"
                    data-bs-toggle="modal"
                    data-bs-target="#modal-mark-as-sent"
                    *ngIf="hasAclAccess(EnumAcl.flightBriefsMarkedAsSent)"
                >
                    <fa-icon [icon]="faEdit"></fa-icon> Modifier
                </button>
            </div>
        </div>
        <div class="row text-center text-md-start">
            <div class="col-sm-6">
                Date :
                <span [innerText]="flightBrief.sentDate"></span>
            </div>
            <div class="col-sm-6">
                Par :
                <a
                    [href]="'/admin/users/' + flightBrief.sentBy"
                    [innerText]="flightBrief.sentByFullname"
                    target="_blank"
                ></a>
            </div>
        </div>
    </div>

    <div
        class="alert alert-info text-center"
        *ngIf="flightBrief.internalNote && flightBrief.internalNote.trim() !== ''"
    >
        <strong>Notes internes : </strong>
        <div [innerText]="flightBrief.internalNote"></div>
    </div>

    <div class="trips-selectors" *ngIf="flightBrief?.trips.length > 1">
        Voir flight brief pour les vols suivants :
        <div
            class="form-check"
            *ngIf="flightBriefsTripIndexDisplayed.length !== flightBrief?.trips.length"
        >
            <input
                class="form-check-input"
                type="checkbox"
                id="select-trip-all"
                [checked]="flightBriefsTripIndexDisplayed.length === flightBrief?.trips.length"
                [disabled]="flightBriefsTripIndexDisplayed.length === flightBrief?.trips.length"
                (click)="selectAllTrips()"
            />
            <label class="form-check-label" for="select-trip-all">Tous les vols</label>
        </div>
        <div class="form-check" *ngFor="let trip of flightBrief?.trips">
            <input
                class="form-check-input"
                type="checkbox"
                [id]="'select-trip-' + trip.id"
                [checked]="flightBriefsTripIndexDisplayed.includes(trip.id)"
                (click)="toggleTrip(trip.id)"
                [disabled]="
                    flightBriefsTripIndexDisplayed.includes(trip.id) &&
                    flightBriefsTripIndexDisplayed.length === 1
                "
            />
            <label class="form-check-label" [for]="'select-trip-' + trip.id">
                {{ getAirportCity(airportsObj[trip.airportDepartId]) }}
                <fa-icon [icon]="faPlane"></fa-icon>
                {{ getAirportCity(airportsObj[trip.airportArrivalId]) }}
            </label>
        </div>
    </div>

    <div id="pdf-content" *ngIf="isRoundTrip">
        <div class="pdf-content">
            <app-flight-brief-page
                [trips]="getDisplayedTrips()"
                [flightBrief]="flightBrief"
                [airportsObj]="airportsObj"
                [enquiry]="enquiry"
            ></app-flight-brief-page>
        </div>
    </div>
    <div id="pdf-content" *ngIf="!isRoundTrip">
        <div class="pdf-content" *ngFor="let trip of getDisplayedTrips()">
            <app-flight-brief-page
                [trips]="[trip]"
                [flightBrief]="flightBrief"
                [airportsObj]="airportsObj"
                [enquiry]="enquiry"
            ></app-flight-brief-page>
        </div>
    </div>
</div>

<div class="modal" tabindex="-1" role="dialog" id="modal-trips-to-export">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Quel vol souhaitez-vous télécharger ?</h5>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <div class="modal-body">
                <div class="trips-selectors" *ngIf="flightBrief?.trips.length > 1">
                    <div
                        class="form-check"
                        *ngIf="flightBriefsTripIndexDisplayed.length !== flightBrief?.trips.length"
                    >
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="select-trip-all"
                            [checked]="
                                flightBriefsTripIndexDisplayed.length === flightBrief?.trips.length
                            "
                            [disabled]="
                                flightBriefsTripIndexDisplayed.length === flightBrief?.trips.length
                            "
                            (click)="selectAllTrips()"
                        />
                        <label class="form-check-label" for="select-trip-all">Tous les vols</label>
                    </div>
                    <div class="form-check" *ngFor="let trip of flightBrief?.trips">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            [id]="'select-trip-' + trip.id"
                            [checked]="flightBriefsTripIndexDisplayed.includes(trip.id)"
                            (click)="toggleTrip(trip.id)"
                            [disabled]="
                                flightBriefsTripIndexDisplayed.includes(trip.id) &&
                                flightBriefsTripIndexDisplayed.length === 1
                            "
                        />
                        <label class="form-check-label" [for]="'select-trip-' + trip.id">
                            {{ getAirportCity(airportsObj[trip.airportDepartId]) }}
                            <fa-icon [icon]="faPlane"></fa-icon>
                            {{ getAirportCity(airportsObj[trip.airportArrivalId]) }}
                        </label>
                    </div>
                </div>
            </div>
            <div class="modal-footer text-center">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                    Annuler
                </button>
                <button
                    type="button"
                    class="btn btn-primary"
                    (click)="generatePdf()"
                    [disabled]="!flightBriefsTripIndexDisplayed.length"
                    data-bs-dismiss="modal"
                >
                    Télécharger
                </button>
            </div>
        </div>
    </div>
</div>

<form [formGroup]="formMarkAsSent" (submit)="saveMarkAsSent()">
    <div class="modal" tabindex="-1" role="dialog" id="modal-mark-as-sent" #modalMarkAsSent>
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Envoi du flight brief ?</h5>
                    <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label for="isSent">A été envoyé au client ?</label>
                        <select
                            class="form-select"
                            id="isSent"
                            formControlName="isSent"
                            [class.is-invalid]="
                                (formMarkAsSent.touched || formMarkAsSent.get('isSent').touched) &&
                                formMarkAsSent.get('isSent').status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                            (change)="updatedIsSent()"
                        >
                            <option [ngValue]="false">Non</option>
                            <option [ngValue]="true">Oui</option>
                        </select>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (formMarkAsSent.touched ||
                                        formMarkAsSent.get('isSent').touched) &&
                                    formMarkAsSent.get('isSent').errors &&
                                    formMarkAsSent.get('isSent').errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <ng-container *ngIf="formMarkAsSent.value.isSent">
                        <div class="form-group">
                            <label for="sentDate">Date d'envoi ?</label>
                            <input
                                class="form-control"
                                id="sentDate"
                                formControlName="sentDate"
                                [class.is-invalid]="
                                    (formMarkAsSent.touched ||
                                        formMarkAsSent.get('sentDate').touched) &&
                                    formMarkAsSent.get('sentDate').status == 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                                type="date"
                            />

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (formMarkAsSent.touched ||
                                            formMarkAsSent.get('sentDate').touched) &&
                                        formMarkAsSent.get('sentDate').errors &&
                                        formMarkAsSent.get('sentDate').errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="sentBy">Envoyé par ?</label>
                            <select
                                class="form-select"
                                id="sentBy"
                                formControlName="sentBy"
                                [class.is-invalid]="
                                    (formMarkAsSent.touched ||
                                        formMarkAsSent.get('sentBy').touched) &&
                                    formMarkAsSent.get('sentBy').status == 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            >
                                <option [ngValue]="null">-- Choisissez --</option>
                                <option
                                    [value]="user.id"
                                    *ngFor="let user of users"
                                    [innerText]="getUserFullname(user)"
                                ></option>
                            </select>

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (formMarkAsSent.touched ||
                                            formMarkAsSent.get('sentBy').touched) &&
                                        formMarkAsSent.get('sentBy').errors &&
                                        formMarkAsSent.get('sentBy').errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>

                <div class="modal-footer text-center">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        Annuler
                    </button>
                    <button
                        type="submit"
                        class="btn btn-primary"
                        (click)="saveMarkAsSent()"
                        [disabled]="formMarkAsSent.disabled || !formMarkAsSent.valid"
                        [innerText]="savingMarkAsSent ? 'Sauvegarde ...' : 'Sauvegarder'"
                    ></button>
                </div>
            </div>
        </div>
    </div>
</form>
