<form (submit)="submitForm()" [formGroup]="form" (keydown.enter)="submitForm()" class="main-form">
    <div class="row justify-content-center">
        <div class="col-md-6">
            <div class="card">
                <div class="card-body">
                    <div class="form-group">
                        <label for="title">Nom</label>
                        <input
                            class="form-control"
                            id="title"
                            formControlName="title"
                            type="text"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['title'].touched) &&
                                form.controls['title'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['title'].touched) &&
                                    form.controls['title'].errors &&
                                    form.controls['title'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Autorisations</label>
                        <div *ngFor="let aclModule of aclList">
                            <div class="form-check">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    [(ngModel)]="aclModule.checked"
                                    [ngModelOptions]="{ standalone: true }"
                                    [id]="aclModule.id"
                                    (change)="updateModule(aclModule.id)"
                                />
                                <label
                                    class="form-check-label"
                                    [attr.for]="aclModule.id"
                                    [innerHtml]="aclModule.title"
                                ></label>
                            </div>
                            <div *ngIf="aclModule.children.length" class="acl-child">
                                <div *ngFor="let child of aclModule.children" class="form-check">
                                    <div class="form-check">
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            [(ngModel)]="child.checked"
                                            [ngModelOptions]="{ standalone: true }"
                                            [id]="aclModule.id + '.' + child.value"
                                            (change)="updateRule([aclModule.id, child.value])"
                                        />
                                        <label
                                            class="form-check-label"
                                            [attr.for]="aclModule.id + '.' + child.value"
                                            [innerHtml]="child.title"
                                        ></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-center text-center mt-4">
        <div class="col-md-4">
            <button
                type="button"
                [routerLink]="
                    userGroupId ? '/admin/user-groups/' + userGroupId : '/admin/user-groups'
                "
                class="btn btn-secondary btn-block mb-4"
                [disabled]="form.disabled || sending"
            >
                Annuler
            </button>
        </div>
        <div class="col-md-4">
            <button
                type="submit"
                class="btn btn-primary btn-block mb-4"
                [innerHtml]="sending ? 'Sauvegarde ...' : 'Sauvegarder'"
                [disabled]="form.disabled || !form.valid || sending"
            ></button>
        </div>
    </div>
</form>
