import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import firebase from 'firebase/compat/app';
import { FlightChecklistTemplateFirestoreService } from './flight-checklist-templates-firestore.service';
import { IFlightChecklistTemplate } from 'src/app/interfaces/flight-checklist-template.interface';
import { EnumEnquiryType } from 'src/app/enums/enquiry-type.enum';

@Injectable({
  providedIn: 'root'
})
export class FlightChecklistTemplateService {
  constructor(
    private flightChecklistTemplateFirestoreService: FlightChecklistTemplateFirestoreService
  ) {}

  create(flightChecklistTemplate: IFlightChecklistTemplate): Promise<string> {
    return this.flightChecklistTemplateFirestoreService.create(flightChecklistTemplate);
  }

  createWithId(id: string, flightChecklistTemplate: IFlightChecklistTemplate): Promise<string> {
    return this.flightChecklistTemplateFirestoreService.createWithId(id, flightChecklistTemplate);
  }

  update(flightChecklistTemplate: IFlightChecklistTemplate): Promise<string> {
    return this.flightChecklistTemplateFirestoreService.update(flightChecklistTemplate);
  }

  delete(id: string): Promise<void> {
    return this.flightChecklistTemplateFirestoreService.delete(id);
  }

  getFromId(id: string): Observable<IFlightChecklistTemplate> {
    return this.flightChecklistTemplateFirestoreService.docValue$(id);
  }

  getAll(): Observable<IFlightChecklistTemplate[]> {
    return this.flightChecklistTemplateFirestoreService.collectionValues$();
  }

  getOneForEnquiryType(enquiryType: EnumEnquiryType): Observable<IFlightChecklistTemplate> {
    return this.flightChecklistTemplateFirestoreService
      .collectionValues$(ref => ref.where('enquiryType', '==', enquiryType))
      .pipe(map(results => (results && results.length ? results[0] : null)));
  }

  resetPagination(paginationName: string = 'list'): void {
    this.flightChecklistTemplateFirestoreService.resetPaginate(paginationName);
  }

  getAllMostRecentFirst(
    paginationName: string = 'list',
    conditions: {
      field: string;
      operator: firebase.firestore.WhereFilterOp;
      value: any;
    }[] = []
  ): Observable<IFlightChecklistTemplate[]> {
    const orderBy: {
      field: string;
      direction: 'asc' | 'desc';
    } = {
      field: 'modified',
      direction: 'desc'
    };

    return this.flightChecklistTemplateFirestoreService.paginate(
      conditions,
      orderBy,
      20,
      paginationName
    );
  }
}
