<div class="row">
    <div class="col-md-6">
        <div class="card mb-4">
            <div class="card-body" *ngIf="loading">
                <h3><div class="skeleton-text"></div></h3>
            </div>
            <div class="card-body" *ngIf="!loading && userGroup">
                <h3 [innerHtml]="userGroup.title" class="text-center"></h3>
                <dl>
                    <dt>Autorisations</dt>
                    <dd>
                        <div *ngFor="let aclModule of aclList">
                            <div class="form-check">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    [(ngModel)]="aclModule.checked"
                                    [ngModelOptions]="{ standalone: true }"
                                    [id]="aclModule.id"
                                    disabled
                                />
                                <label
                                    class="form-check-label"
                                    [for]="aclModule.id"
                                    [innerHtml]="aclModule.title"
                                ></label>
                            </div>
                            <div *ngIf="aclModule.children.length" class="acl-child">
                                <div *ngFor="let child of aclModule.children" class="form-check">
                                    <div class="form-check">
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            [(ngModel)]="child.checked"
                                            [ngModelOptions]="{ standalone: true }"
                                            [id]="aclModule.id + '.' + child.value"
                                            disabled
                                        />
                                        <label
                                            class="form-check-label"
                                            [for]="aclModule.id + '.' + child.value"
                                            [innerHtml]="child.title"
                                        ></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </dd>
                </dl>

                <div class="text-center mt-4">
                    <a
                        [routerLink]="'/admin/user-groups/' + userGroup.id + '/edit'"
                        class="btn btn-primary mr-4"
                        *ngIf="hasAclAccess(EnumAcl.userGroupsEdit)"
                        >Éditer</a
                    >
                    <button
                        type="button"
                        (click)="delete()"
                        class="btn btn-primary ml-4"
                        *ngIf="hasAclAccess(EnumAcl.userGroupsDelete)"
                    >
                        Supprimer
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="card mb-4">
            <div class="card-header text-center">Utilisateurs</div>
            <ul class="list-group list-group-flush" *ngIf="loadingUsers">
                <li
                    class="list-group-item placeholder-glow"
                    *ngFor="let number of [].constructor(10)"
                >
                    <div class="placeholder w-100"></div>
                </li>
            </ul>

            <div class="list-group list-group-flush" *ngIf="!loadingUsers">
                <a
                    [routerLink]="'/admin/users/' + user.id"
                    class="list-group-item list-group-item-action"
                    *ngFor="let user of users"
                >
                    {{ getUserFullname(user) }} ({{ user.initials }})
                </a>
            </div>
        </div>
    </div>
</div>
