import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import firebase from 'firebase/compat/app';
import { FboFirestoreService } from './fbos-firestore.service';
import { IFbo } from 'src/app/interfaces/fbo.interface';
import { IFindCondition } from '../firestore/firestore.service';
import { FunctionsService } from '../functions/functions.service';

@Injectable({
  providedIn: 'root'
})
export class FboService {
  constructor(
    private fboFirestoreService: FboFirestoreService,
    private functionsService: FunctionsService
  ) {}

  create(Fbo: IFbo): Promise<string> {
    return this.fboFirestoreService.create(Fbo);
  }

  createWithId(id: string, Fbo: IFbo): Promise<string> {
    return this.fboFirestoreService.createWithId(id, Fbo);
  }

  update(Fbo: IFbo): Promise<string> {
    return this.fboFirestoreService.update(Fbo);
  }

  delete(id: string): Promise<void> {
    return this.fboFirestoreService.delete(id);
  }

  getFromId(id: string): Observable<IFbo> {
    return this.fboFirestoreService.docValue$(id);
  }

  getAll(): Observable<IFbo[]> {
    return this.fboFirestoreService.collectionValues$();
  }

  resetPagination(paginationName: string = 'list'): void {
    this.fboFirestoreService.resetPaginate(paginationName);
  }

  getPaginatedResult(
    paginationName: string = 'list',
    conditions: IFindCondition[] = [],
    orderBy: {
      field: string;
      direction: firebase.firestore.OrderByDirection;
    } = {
      field: 'created',
      direction: 'desc'
    },
    itemsPerPage: number = 20,
    direction: 'prev' | 'next' = 'next'
  ): Observable<IFbo[]> {
    return this.fboFirestoreService.paginate(
      conditions,
      orderBy,
      itemsPerPage,
      paginationName,
      direction
    );
  }

  getForAirport(airportId: string): Observable<IFbo[]> {
    return this.fboFirestoreService.collectionValues$(ref =>
      ref.where('airportId', '==', airportId)
    );
  }

  mergeFbos(fbosId: string[], finalFboId: string): Promise<void> {
    return this.functionsService.callFunctionAsPromise('mergeFbos', {
      fbosId,
      finalFboId
    });
  }
}
