import { EnumContinentCode, getContinentLabel } from '../enums/continent-code.enum';
import { EnumEnquiryCancelledReason } from '../enums/enquiry-cancelled-reason.enum';
import { EnumEnquiryCommissionStatus } from '../enums/enquiry-commission.enum';
import { EnumEnquiryPrefilterAirline } from '../enums/enquiry-prefilter-airline.enum';
import { EnumEnquiryStatus } from '../enums/enquiry-status.enum';
import { EnumEnquiryTarget } from '../enums/enquiry-target.enum';
import { EnumEnquiryType } from '../enums/enquiry-type.enum';
import { EnumLanguage } from '../enums/language.enum';
import { addZeroToDigit } from '../misc.utils';
import { IAirline } from './airline.interface';
import { IAirport, getAirportIataCode } from './airport.interface';
import { IEnquiryCotation } from './enquiry-cotation.interface';
import { IEnquiryItinerary } from './enquiry-itinerary.interface';
import { IEnquiryTrip } from './enquiry-trip.interface';
import { IFile } from './file.interface';
import { IStoreData } from './store-data.interface';

export interface IEnquiry extends IStoreData {
  refEnquiryPrefix: string;
  refEnquiryYear: number;
  refEnquiryMonth: number;
  refEnquiryNumber: number;
  refEnquiryTitle: string;
  refEnquiryTitleWithoutPrefix: string;
  refEnquiryTitleDisplayed: string;
  refEnquiryTitleWithoutPrefixDisplayed: string;
  refContractPrefix: string;
  refContractYear: number;
  refContractMonth: number;
  refContractNumber: number;
  refContractTitle: string;
  refContractTitleWithoutPrefix: string;
  refContractTitleDisplayed: string;
  refContractTitleWithoutPrefixDisplayed: string;
  clientId: string | number;
  clientTitle: string;
  contactId: string;
  contactTitle: string;
  comments: string;
  noteForAirlines: string;
  type: EnumEnquiryType;
  status: EnumEnquiryStatus;
  receivedBy: string;
  processedBy: string;
  itineraries: IEnquiryItinerary[];
  capacityMin: number;
  capacityMax: number;
  filterBy: EnumEnquiryPrefilterAirline;
  targets: EnumEnquiryTarget[];
  aircraftModelId: string;
  geographicalAreasCode: EnumContinentCode[];
  weightMin: number;
  weightMax: number;
  volumeMin: number;
  volumeMax: number;
  contactedAircraftsId: string[];
  reasonCancelled: {
    reason: EnumEnquiryCancelledReason;
    comment: string;
  };
  pipedriveDealId: number;
  emailAttachments: IEnquiryEmailAttachment[];
  cotationConfirmedIds: string[];
  dateConfirmed: Date;
  dateCancelled: Date;
  commissionFee?: number;
  commissionStatus?: EnumEnquiryCommissionStatus;
  commissionComment?: string;
  isCommissionFeePercentage?: boolean;
  commissionFeeAmount?: number;
  isCompleted?: boolean;
  dateCompleted?: Date | null;
}

export const getEnquiryBreadcrumbTitle = (enquiry: IEnquiry): string => {
  const titleArray: string[] = [];

  if (enquiry) {
    if (enquiry.refContractTitle) {
      titleArray.push(getDisplayedEnquiryRefTitle(enquiry, 'refContract'));
    }
    if (enquiry.refEnquiryTitle) {
      titleArray.push(getDisplayedEnquiryRefTitle(enquiry, 'refEnquiry'));
    }

    if (titleArray.length > 1) {
      titleArray[0] = '<strong>' + titleArray[0] + '</strong>';
    }
  }

  return titleArray.join(' | ');
};

export const getEnquiryGeographicalAreasTitles = (enquiry: IEnquiry): string[] => {
  const geographicalAreasTitles: string[] = [];

  for (const continentCode of enquiry.geographicalAreasCode) {
    geographicalAreasTitles.push(getContinentLabel(continentCode));
  }

  return geographicalAreasTitles;
};

export const getEnquiryTargetsTitles = (enquiry: IEnquiry): EnumEnquiryTarget[] => {
  const targetsTitles: EnumEnquiryTarget[] = [];

  for (const target of enquiry.targets) {
    targetsTitles.push(EnumEnquiryTarget[target] ?? target);
  }

  return targetsTitles;
};

export const getEnquiryLegsOfCotation = (
  enquiry: IEnquiry,
  cotation: IEnquiryCotation
): IEnquiryTrip[] => {
  const legs: Array<IEnquiryTrip> = [];

  for (const itinerary of enquiry.itineraries) {
    if (itinerary.id === cotation.itineraryId) {
      for (const trip of itinerary.trips) {
        if (cotation.legsId.includes(trip.id)) {
          legs.push(trip);
        }
      }
    }
  }

  return legs;
};

export const getRoutingOfCotation = (
  enquiry: IEnquiry,
  cotation: IEnquiryCotation,
  airportsObj: { [key: string]: IAirport | null }
): (string | null)[] => {
  const iataCodes: Array<string | null> = [];

  for (const itinerary of enquiry.itineraries) {
    if (itinerary.id === cotation.itineraryId) {
      for (const trip of itinerary.trips) {
        if (cotation.legsId.includes(trip.id)) {
          const iataCodeDepart: string | null = getAirportIataCode(airportsObj[trip.airportDepart]);
          const iataCodeDestination: string | null = getAirportIataCode(
            airportsObj[trip.airportDestination]
          );

          if (iataCodes[iataCodes.length - 1] !== iataCodeDepart) {
            // To avoid duplicate, we add if previous stop is not same than depart
            iataCodes.push(iataCodeDepart);
          }
          iataCodes.push(iataCodeDestination);
        }
      }
    }
  }

  return iataCodes;
};

export const getCotationAirline = (cotation: IEnquiryCotation): IAirline | null => {
  if (cotation.aircraftCompiled) {
    return {
      id: cotation.aircraftCompiled.airlineId,
      title: cotation.aircraftCompiled.airlineTitle
    } as IAirline;
  }

  return null;
};

export const doesEnquiryPrefillAirlinesSelection = (enquiryType: EnumEnquiryType): boolean => {
  return ![EnumEnquiryType.business, EnumEnquiryType.helico].includes(enquiryType);
};

export const hasBusinessPassengerField = (enquiryType: EnumEnquiryType): boolean => {
  return ![EnumEnquiryType.business, EnumEnquiryType.sport].includes(enquiryType);
};

export const getEnquiryRefTitle = (
  refPrefix: string,
  refYear: number,
  refMonth: number,
  refNumber: number,
  numberDigits: number = 6
): string => {
  return refNumber
    ? refPrefix + getEnquiryRefTitleWithoutPrefix(refYear, refMonth, refNumber, numberDigits)
    : '';
};

export const getEnquiryRefTitleWithoutPrefix = (
  refYear: number,
  refMonth: number,
  refNumber: number,
  numberDigits: number = 6
): string => {
  return (
    refYear.toString() + '.' + addZeroToDigit(refMonth) + addZeroToDigit(refNumber, numberDigits)
  );
};

export const getDisplayedEnquiryRefTitle = (
  enquiry: IEnquiry,
  refField: 'refEnquiry' | 'refContract'
): string => {
  return (
    enquiry[refField + 'TitleDisplayed'] ??
    getEnquiryRefTitle(
      enquiry[refField + 'Prefix'],
      enquiry[refField + 'Year'],
      enquiry[refField + 'Month'],
      enquiry[refField + 'Number'],
      2
    )
  );
};

export interface IEnquiryEmailAttachment extends IFile {
  language: EnumLanguage;
}
