<div class="card mb-4">
    <div class="card-header">
        <div
            class="card-title mb-0 text-center"
            [innerText]="enquiryFlights.length > 1 ? 'Itinéraires' : 'Itinéraire'"
        ></div>
    </div>
    <ul class="list-group list-group-flush" *ngIf="loading">
        <li class="list-group-item placeholder-glow" *ngFor="let number of [].constructor(2)">
            <div class="row">
                <div class="col-md-4">
                    <div class="placeholder w-100"></div>
                </div>
                <div class="col-md-4">
                    <div class="placeholder w-100"></div>
                </div>
                <div class="col-md-4">
                    <div class="placeholder w-100"></div>
                </div>
            </div>
        </li>
    </ul>
    <ul class="list-group list-group-flush" *ngIf="!loading && !enquiryFlights.length">
        <li
            class="list-group-item list-group-item-warning text-center"
            *ngIf="!enquiryFlights.length"
        >
            Aucun vol pour ce dossier. Veuillez en ajouter.
        </li>
    </ul>
    <div class="accordion accordion-flush" id="accordion-enquiry-flights">
        <div class="accordion-item" *ngFor="let enquiryFlight of enquiryFlights">
            <h2 class="accordion-header">
                <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    [attr.data-bs-target]="'#enquiry-flight-' + enquiryFlight.id"
                    aria-expanded="false"
                    [attr.aria-controls]="'enquiry-flight-' + enquiryFlight.id"
                >
                    <div class="accordion-button-content">
                        <div class="d-sm-flex gap-2">
                            <div>
                                {{
                                    enquiryFlight.airportDepartIataCode ||
                                        "Aéroport de départ à préciser"
                                }}
                                <fa-icon [icon]="faLongArrowRight" class="ms-1 me-1"></fa-icon>
                                {{ enquiryFlight.airportArrivalIataCode || "?" }}
                            </div>
                            <div class="text-muted" *ngIf="enquiryFlight.isFerryFlight">
                                Vol de positionnement
                            </div>
                            <div
                                class="text-muted"
                                *ngIf="enquiryFlight.isAircraftGroundedOnArrival"
                            >
                                Reste en immobilisation
                            </div>
                        </div>
                        <div
                            *ngIf="enquiryFlight.departDateLocal"
                            [innerText]="
                                enquiryFlight.departDateTimeLocal[
                                    enquiryFlight.departDateTimeLocal.length - 1
                                ] !== 'T'
                                    ? (enquiryFlight.departDateTimeLocal
                                      | date : 'EE dd MMM yyyy HH:mm' : undefined : 'fr')
                                    : (enquiryFlight.departDateLocal
                                      | date : 'EE dd MMM yyyy ' : undefined : 'fr')
                            "
                        ></div>
                    </div>
                </button>
            </h2>
            <div
                [id]="'enquiry-flight-' + enquiryFlight.id"
                class="accordion-collapse collapse"
                data-bs-parent="#accordion-enquiry-flights"
            >
                <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                        <div class="row enquiry-flight-content">
                            <div class="col-md-2 col-xl-1 d-md-flex align-items-center">
                                <fa-icon [icon]="faPlane" class="big-icon"></fa-icon>
                            </div>
                            <div class="col-md-5 col-xl-6">
                                <div>
                                    <strong [innerText]="enquiryFlight.airlineTitle"></strong>
                                </div>
                                <div *ngIf="enquiryFlight.displayAirlineOnFlightBrief">
                                    <fa-icon [icon]="faCircleCheck"></fa-icon> Afficher sur le
                                    flight brief
                                </div>
                                <div *ngIf="!enquiryFlight.displayAirlineOnFlightBrief">
                                    <fa-icon [icon]="faXmarkCircle"></fa-icon> Cacher sur le flight
                                    brief
                                </div>
                                <div>
                                    <strong>Nationalité</strong><br />
                                    {{
                                        enquiryFlight.countryCode
                                            ? countries[enquiryFlight.countryCode]
                                            : "À préciser"
                                    }}
                                </div>
                                <div>
                                    <strong>Appareil</strong><br />
                                    {{ enquiryFlight.aircraftModelTitle }}
                                </div>
                            </div>
                            <div class="col-md-5">
                                <div *ngIf="enquiryFlight.aircraftModelId">
                                    <strong>Immatriculation</strong>
                                    <div
                                        [class.text-muted]="!enquiryFlight.aircraftRegistration"
                                        [innerText]="
                                            enquiryFlight.aircraftRegistration ||
                                            'Immatriculation à préciser'
                                        "
                                    ></div>
                                </div>
                                <div *ngIf="enquiryFlight.aircraftRegistration">
                                    <strong>WC</strong>
                                    {{ enquiryFlight.aircraftHasToilet ? "Oui" : "Non" }}
                                </div>
                                <div *ngIf="enquiryFlight.aircraftRegistration">
                                    <strong>Vol fumeur</strong>
                                    {{ enquiryFlight.smokingIsAllowed ? "Oui" : "Non" }}
                                </div>
                            </div>
                        </div>

                        <button
                            type="button"
                            class="btn btn-primary btn-sm"
                            (click)="
                                editEnquiryFlight(
                                    enquiryFlight,
                                    EnumEnquiryFlightWizardStep.aircraft
                                )
                            "
                        >
                            <fa-icon [icon]="faEdit"></fa-icon>
                        </button>
                    </li>
                    <li class="list-group-item">
                        <div class="row enquiry-flight-content">
                            <div class="col-md-2 col-xl-1 d-md-flex align-items-center">
                                <fa-icon [icon]="faPlaneDeparture" class="big-icon"></fa-icon>
                            </div>
                            <ng-container *ngIf="!enquiryFlight.airportDepartId">
                                <div class="col-md-10 col-xl-11">Aéroport de départ à préciser</div>
                            </ng-container>
                            <ng-container *ngIf="enquiryFlight.airportDepartId">
                                <div class="col-md-5 col-xl-6">
                                    <div>
                                        <strong
                                            [innerText]="enquiryFlight.airportDepartIataCode"
                                        ></strong>
                                    </div>
                                    {{ enquiryFlight.airportDepartTitle }}<br />
                                </div>
                                <div class="col-md-5">
                                    {{
                                        enquiryFlight.departDateLocal
                                            | date : "EE dd MMM yyyy" : undefined : "fr"
                                    }}<br />
                                    {{ enquiryFlight.departTimeLocal || "?" }}
                                    <strong>LT</strong>
                                    {{ enquiryFlight.departTimeUtc || "?" }}
                                    <strong>UTC</strong>
                                    <div *ngIf="enquiryFlight.departSlotEnabled">
                                        <strong>Slot</strong>
                                        {{ enquiryFlight.departSlotTime }} LT
                                    </div>
                                    <div *ngIf="enquiryFlight.departTerminalEnabled">
                                        <strong>Terminal</strong>
                                        {{ enquiryFlight.departTerminal }}
                                    </div>
                                    <div
                                        class="placeholder-glow"
                                        *ngIf="enquiryFlight.departFboEnabled"
                                    >
                                        <strong class="me-1">FBO</strong>
                                        <span
                                            class="placeholder w-100"
                                            *ngIf="!fbosObj[enquiryFlight.departFboId]"
                                        ></span>
                                        <span
                                            *ngIf="fbosObj[enquiryFlight.departFboId]"
                                            [innerText]="fbosObj[enquiryFlight.departFboId].name"
                                        >
                                        </span>
                                    </div>
                                    <div
                                        class="placeholder-glow"
                                        *ngIf="enquiryFlight.departAgentEnabled"
                                    >
                                        <strong class="me-1">Représentant</strong>
                                        {{ enquiryFlight.departAgentUserFullName }}
                                    </div>
                                </div>
                            </ng-container>
                        </div>

                        <button
                            type="button"
                            class="btn btn-primary btn-sm"
                            (click)="
                                editEnquiryFlight(
                                    enquiryFlight,
                                    EnumEnquiryFlightWizardStep.departure
                                )
                            "
                        >
                            <fa-icon [icon]="faEdit"></fa-icon>
                        </button>
                    </li>
                    <li class="list-group-item">
                        <div class="row enquiry-flight-content">
                            <div class="col-md-2 col-xl-1 d-md-flex align-items-center">
                                <fa-icon [icon]="faPlaneArrival" class="big-icon"></fa-icon>
                            </div>
                            <ng-container *ngIf="!enquiryFlight.airportArrivalId">
                                <div class="col-md-10 col-xl-11">Aéroport d'arrivée à préciser</div>
                            </ng-container>
                            <ng-container *ngIf="enquiryFlight.airportArrivalId">
                                <div class="col-md-5 col-xl-6">
                                    <div>
                                        <strong
                                            [innerText]="enquiryFlight.airportArrivalIataCode"
                                        ></strong>
                                    </div>
                                    {{ enquiryFlight.airportArrivalTitle }}<br />
                                </div>
                                <div class="col-md-5">
                                    <span
                                        class="text-muted"
                                        *ngIf="
                                            enquiryFlight.departDateTimeUtc ===
                                            enquiryFlight.arrivalDateTimeUtc
                                        "
                                        >Date et heure d'arrivée à préciser</span
                                    >
                                    <ng-container
                                        *ngIf="
                                            enquiryFlight.departDateTimeUtc !==
                                            enquiryFlight.arrivalDateTimeUtc
                                        "
                                    >
                                        {{
                                            enquiryFlight.arrivalDateLocal
                                                | date : "EE dd MMM yyyy" : undefined : "fr"
                                        }}<br />
                                        {{ enquiryFlight.arrivalTimeLocal }}
                                        <strong>LT</strong>
                                        {{ enquiryFlight.arrivalTimeUtc }}
                                        <strong>UTC</strong>
                                    </ng-container>
                                    <div *ngIf="enquiryFlight.isAircraftGroundedOnArrival">
                                        Reste en immobilisation
                                    </div>
                                    <div *ngIf="enquiryFlight.arrivalSlotEnabled">
                                        <strong>Slot</strong>
                                        {{ enquiryFlight.arrivalSlotTime }} LT
                                    </div>
                                    <div *ngIf="enquiryFlight.arrivalTerminalEnabled">
                                        <strong>Terminal</strong>
                                        {{ enquiryFlight.arrivalTerminal }}
                                    </div>
                                    <div
                                        class="placeholder-glow"
                                        *ngIf="enquiryFlight.arrivalFboEnabled"
                                    >
                                        <strong class="me-1">FBO</strong>
                                        <span
                                            class="placeholder w-100"
                                            *ngIf="!fbosObj[enquiryFlight.arrivalFboId]"
                                        ></span>
                                        <span
                                            *ngIf="fbosObj[enquiryFlight.arrivalFboId]"
                                            [innerText]="fbosObj[enquiryFlight.arrivalFboId].name"
                                        >
                                        </span>
                                    </div>
                                    <div
                                        class="placeholder-glow"
                                        *ngIf="enquiryFlight.arrivalAgentEnabled"
                                    >
                                        <strong class="me-1">Représentant</strong>
                                        {{ enquiryFlight.arrivalAgentUserFullName }}
                                    </div>
                                </div>
                            </ng-container>
                        </div>

                        <button
                            type="button"
                            class="btn btn-primary btn-sm"
                            (click)="
                                editEnquiryFlight(
                                    enquiryFlight,
                                    EnumEnquiryFlightWizardStep.arrival
                                )
                            "
                        >
                            <fa-icon [icon]="faEdit"></fa-icon>
                        </button>
                    </li>
                    <ng-container *ngIf="enquiryFlight.airportArrivalId">
                        <li class="list-group-item">
                            <div class="row enquiry-flight-content">
                                <div class="col-md-2 col-xl-1 d-md-flex align-items-center">
                                    <fa-icon [icon]="faClock" class="big-icon"></fa-icon>
                                </div>
                                <div class="col-md-10 col-xl-11">
                                    <strong>Temps de vol</strong><br />
                                    <div
                                        [innerText]="
                                            convertMinutesToTime(
                                                getEnquiryFlightFlyTimeInMin(enquiryFlight),
                                                'h ',
                                                'min'
                                            )
                                        "
                                        *ngIf="getEnquiryFlightFlyTimeInMin(enquiryFlight) > 0"
                                    ></div>
                                    <div
                                        class="alert alert-danger text-center"
                                        *ngIf="getEnquiryFlightFlyTimeInMin(enquiryFlight) < 0"
                                    >
                                        Le temps de vol est impossible à calculer. Vérifiez les
                                        heures de départ et arrivée.
                                    </div>
                                    <div *ngIf="getEnquiryFlightFlyTimeInMin(enquiryFlight) === 0">
                                        x h x min
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item">
                            <div class="row enquiry-flight-content">
                                <div class="col-md-2 col-xl-1 d-md-flex align-items-center">
                                    <fa-icon [icon]="faTicket" class="big-icon"></fa-icon>
                                </div>
                                <div class="col-md-5 col-xl-6">
                                    <strong>Numéro de vol</strong>
                                    <div
                                        [class.text-muted]="!enquiryFlight.flightNumber"
                                        [innerText]="enquiryFlight.flightNumber || 'Inconnu'"
                                    ></div>
                                </div>
                                <div class="col-md-5">
                                    <strong>Vol de positionnement</strong>
                                    <div
                                        [class.text-muted]="!enquiryFlight.isFerryFlight"
                                        [innerText]="enquiryFlight.isFerryFlight ? 'Oui' : 'Non'"
                                    ></div>
                                </div>
                            </div>

                            <button
                                type="button"
                                class="btn btn-primary btn-sm"
                                (click)="
                                    editEnquiryFlight(
                                        enquiryFlight,
                                        EnumEnquiryFlightWizardStep.flightDetail
                                    )
                                "
                            >
                                <fa-icon [icon]="faEdit"></fa-icon>
                            </button>
                        </li>
                        <li
                            class="list-group-item"
                            *ngIf="
                                enquiry?.type !== EnumEnquiryType.cargo &&
                                !enquiryFlight.isFerryFlight
                            "
                        >
                            <div class="row enquiry-flight-content">
                                <div class="col-md-2 col-xl-1 d-md-flex align-items-center">
                                    <fa-icon [icon]="faUsers" class="big-icon"></fa-icon>
                                </div>
                                <div class="col-md-5 col-xl-6">
                                    <div>
                                        <strong>Passagers</strong><br />
                                        {{ enquiryFlight.pax }}
                                    </div>

                                    <div>
                                        <strong>Limite convocation passager</strong><br />
                                        {{ enquiryFlight.boardingMinutesBeforeDeparture || 20 }}
                                        mins
                                    </div>
                                </div>
                                <div class="col-md-5">
                                    <div>
                                        <strong>Catering</strong><br />
                                        {{
                                            enquiryFlight.flightBriefCateringId
                                                ? flightBriefCateringsObj[
                                                      enquiryFlight.flightBriefCateringId
                                                  ]?.textByLanguage[EnumLanguage.fr]
                                                : enquiryFlight.catering || "Vide"
                                        }}
                                    </div>
                                    <div>
                                        <strong>Bagages par passager </strong><br />
                                        {{ enquiryFlight.luggages || "Vide" }}
                                    </div>
                                </div>
                            </div>

                            <button
                                type="button"
                                class="btn btn-primary btn-sm"
                                (click)="
                                    editEnquiryFlight(
                                        enquiryFlight,
                                        EnumEnquiryFlightWizardStep.passengers
                                    )
                                "
                            >
                                <fa-icon [icon]="faEdit"></fa-icon>
                            </button>
                        </li>
                        <li
                            class="list-group-item"
                            *ngIf="
                                enquiry?.type === EnumEnquiryType.cargo &&
                                !enquiryFlight.isFerryFlight
                            "
                        >
                            <div class="row enquiry-flight-content">
                                <div class="col-md-2 col-xl-1 d-md-flex align-items-center">
                                    <fa-icon [icon]="faBox" class="big-icon"></fa-icon>
                                </div>
                                <div class="col-md-3 col-xl-3">
                                    <div>
                                        <strong>Poids</strong><br />
                                        {{ enquiryFlight.weight || "?" }} kg
                                    </div>
                                    <div>
                                        <strong>Volume</strong><br />
                                        {{ enquiryFlight.volume || "?" }}m³
                                    </div>
                                    <div *ngIf="enquiryFlight.awbNumber">
                                        <strong>Numéro(s) AWB</strong><br />
                                        {{ enquiryFlight.awbNumber }}
                                    </div>
                                </div>
                                <div class="col-md-3 col-xl-4">
                                    <div *ngIf="enquiryFlight.typeOfCargo">
                                        <strong>Type de cargo</strong><br />
                                        {{ enquiryFlight.typeOfCargo }}
                                    </div>
                                    <div *ngIf="enquiryFlight.nbPackages">
                                        <strong>Nombre de paquets</strong><br />
                                        {{ enquiryFlight.nbPackages }} pièces
                                    </div>
                                    <div *ngIf="enquiryFlight.customDeclaration">
                                        <strong>Déclaration douane</strong><br />
                                        {{ enquiryFlight.customDeclaration }}
                                    </div>
                                </div>
                                <div class="col-md-4 col-xl-4">
                                    <div>
                                        <strong>Limite livraison cargo</strong><br />
                                        {{ enquiryFlight.deliveryCargoHoursBeforeDeparture || 2 }}
                                        heures
                                    </div>
                                    <div *ngIf="enquiryFlight.dgd">
                                        <strong>DGD ?</strong><br />
                                        {{ enquiryFlight.dgd ? "Oui" : "Non" }}
                                    </div>
                                </div>
                            </div>

                            <button
                                type="button"
                                class="btn btn-primary btn-sm"
                                (click)="
                                    editEnquiryFlight(
                                        enquiryFlight,
                                        EnumEnquiryFlightWizardStep.cargo
                                    )
                                "
                            >
                                <fa-icon [icon]="faEdit"></fa-icon>
                            </button>
                        </li>
                        <li class="list-group-item">
                            <div class="row enquiry-flight-content">
                                <div class="col-md-2 col-xl-1 d-md-flex align-items-center">
                                    <fa-icon [icon]="faUserTie" class="big-icon"></fa-icon>
                                </div>
                                <div class="col-md-5 col-xl-6">
                                    OPS H24 :
                                    <span class="text-muted" *ngIf="!enquiryFlight.opsH24Phone"
                                        >À préciser</span
                                    >
                                    <a
                                        [href]="'tel:' + enquiryFlight.opsH24Phone"
                                        [innerText]="enquiryFlight.opsH24Phone"
                                        *ngIf="enquiryFlight.opsH24Phone"
                                    ></a
                                    ><br />
                                    SUP :
                                    <span
                                        class="text-muted"
                                        *ngIf="!enquiryFlight.supervisorIds?.length"
                                        >À préciser</span
                                    >
                                    <ng-container
                                        *ngFor="let supervisorId of enquiryFlight.supervisorIds"
                                    >
                                        <span
                                            class="placeholder w-100"
                                            *ngIf="!supervisorsObj[supervisorId]"
                                        ></span>
                                        <a
                                            *ngIf="supervisorsObj[supervisorId]"
                                            [routerLink]="
                                                '/admin/airline-supervisors/' + supervisorId
                                            "
                                            target="_blank"
                                            class="me-2"
                                        >
                                            {{
                                                supervisorsObj[supervisorId].lastname +
                                                    " " +
                                                    supervisorsObj[supervisorId].firstname
                                            }}
                                        </a>
                                    </ng-container>
                                </div>
                                <div
                                    class="col-md-5"
                                    *ngIf="[EnumEnquiryType.business].includes(enquiry?.type)"
                                >
                                    PIC :
                                    <span
                                        class="placeholder w-100"
                                        *ngIf="
                                            enquiryFlight.captainId &&
                                            !crewsObj[enquiryFlight.captainId]
                                        "
                                    ></span>
                                    <span class="text-muted" *ngIf="!enquiryFlight.captainId"
                                        >À préciser</span
                                    >
                                    <a
                                        *ngIf="
                                            enquiryFlight.captainId &&
                                            crewsObj[enquiryFlight.captainId]
                                        "
                                        [routerLink]="'/admin/crews/' + enquiryFlight.captainId"
                                        target="_blank"
                                    >
                                        {{
                                            crewsObj[enquiryFlight.captainId].lastname +
                                                " " +
                                                crewsObj[enquiryFlight.captainId].firstname
                                        }}
                                        <span
                                            *ngIf="crewsObj[enquiryFlight.captainId]?.phoneNumber"
                                            [innerText]="
                                                '(' +
                                                crewsObj[enquiryFlight.captainId].phoneNumber +
                                                ')'
                                            "
                                        ></span>
                                    </a>
                                    <br />
                                    SIC :
                                    <span
                                        class="placeholder w-100"
                                        *ngIf="
                                            enquiryFlight.coPilotId &&
                                            !crewsObj[enquiryFlight.coPilotId]
                                        "
                                    ></span>
                                    <span class="text-muted" *ngIf="!enquiryFlight.coPilotId"
                                        >À préciser</span
                                    >
                                    <a
                                        *ngIf="
                                            enquiryFlight.coPilotId &&
                                            crewsObj[enquiryFlight.coPilotId]
                                        "
                                        [routerLink]="'/admin/crews/' + enquiryFlight.coPilotId"
                                        target="_blank"
                                        class="me-2"
                                    >
                                        {{
                                            crewsObj[enquiryFlight.coPilotId].lastname +
                                                " " +
                                                crewsObj[enquiryFlight.coPilotId].firstname
                                        }}
                                        <span
                                            *ngIf="crewsObj[enquiryFlight.coPilotId]?.phoneNumber"
                                            [innerText]="
                                                '(' +
                                                crewsObj[enquiryFlight.coPilotId].phoneNumber +
                                                ')'
                                            "
                                        ></span> </a
                                    ><br />
                                    CC :
                                    <span
                                        class="text-muted"
                                        *ngIf="!enquiryFlight.stewardessIds?.length"
                                        >À préciser</span
                                    >
                                    <ng-container
                                        *ngFor="let stewardessId of enquiryFlight.stewardessIds"
                                    >
                                        <span
                                            class="placeholder w-100"
                                            *ngIf="!crewsObj[stewardessId]"
                                        ></span>
                                        <a
                                            *ngIf="crewsObj[stewardessId]"
                                            [routerLink]="'/admin/crews/' + stewardessId"
                                            target="_blank"
                                            class="me-2"
                                        >
                                            {{
                                                crewsObj[stewardessId].lastname +
                                                    " " +
                                                    crewsObj[stewardessId].firstname
                                            }}

                                            <span
                                                *ngIf="crewsObj[stewardessId]?.phoneNumber"
                                                [innerText]="
                                                    '(' + crewsObj[stewardessId].phoneNumber + ')'
                                                "
                                            ></span>
                                        </a>
                                    </ng-container>
                                </div>
                            </div>

                            <button
                                type="button"
                                class="btn btn-primary btn-sm"
                                (click)="
                                    editEnquiryFlight(
                                        enquiryFlight,
                                        EnumEnquiryFlightWizardStep.crew
                                    )
                                "
                            >
                                <fa-icon [icon]="faEdit"></fa-icon>
                            </button>
                        </li>
                        <li class="list-group-item">
                            <div class="row enquiry-flight-content">
                                <div class="col-md-2 col-xl-1 d-md-flex align-items-center">
                                    <fa-icon [icon]="faComment" class="big-icon"></fa-icon>
                                </div>
                                <div class="col-md-10 col-xl-11">
                                    <strong>Remarque</strong>
                                    <div
                                        [class.text-muted]="!enquiryFlight.comment"
                                        [innerText]="enquiryFlight.comment || 'Vide'"
                                    ></div>
                                </div>
                            </div>

                            <button
                                type="button"
                                class="btn btn-primary btn-sm"
                                (click)="
                                    editEnquiryFlight(
                                        enquiryFlight,
                                        EnumEnquiryFlightWizardStep.comment
                                    )
                                "
                            >
                                <fa-icon [icon]="faEdit"></fa-icon>
                            </button>
                        </li>
                    </ng-container>
                    <li class="list-group-item justify-content-center">
                        <button
                            type="button"
                            class="btn btn-danger btn-sm"
                            (click)="deleteEnquiryFlight(enquiryFlight)"
                        >
                            <fa-icon [icon]="faTrash"></fa-icon> Supprimer vol
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="card-footer text-center">
        <button type="button" class="btn btn-primary" (click)="addEnquiryFlight()">
            + Ajouter
        </button>
    </div>
</div>

<div
    class="modal fade"
    id="enquiry-flight-edit-modal"
    tabindex="-1"
    aria-labelledby="enquiry-flight-edit-modalLabel"
    aria-hidden="true"
    #modalEnquiryFlightEdit
>
    <div class="modal-dialog modal-xxl">
        <div class="modal-content">
            <app-enquiry-flight-edit
                [enquiry]="enquiry"
                [enquiryFlightId]="currentEnquiryFlight?.id || null"
                [inModal]="true"
                [currentStep]="currentEnquiryFlightStep"
                (updatedSelectedEnquiryFlight)="assignSelectedEnquiryFlight($event)"
            ></app-enquiry-flight-edit>
        </div>
    </div>
</div>

<div
    class="modal fade"
    id="enquiry-flight-add-modal"
    tabindex="-1"
    aria-labelledby="enquiry-flight-add-modalLabel"
    aria-hidden="true"
    #modalEnquiryFlightAdd
>
    <div class="modal-dialog">
        <div class="modal-content">
            <app-enquiry-flight-add
                [enquiry]="enquiry"
                [enquiryFlights]="enquiryFlights"
                [inModal]="true"
                (editEnquiryFlight)="editEnquiryFlight($event)"
            ></app-enquiry-flight-add>
        </div>
    </div>
</div>
