import { Component, OnInit } from '@angular/core';

import { PaginationService } from '../../../services/pagination.service';
import { AclService } from '../../../services/acl.service';

import countries from '../../../countries_fr.json';

import { EnumAcl } from 'src/app/enums/acl.enum';
import { IBankAccount } from 'src/app/interfaces/bank-account.interface';

@Component({
  selector: 'app-list-bank-accounts',
  templateUrl: './list-bank-accounts.component.html',
  styleUrls: ['./list-bank-accounts.component.scss']
})
export class ListBankAccountsComponent implements OnInit {
  EnumAcl = EnumAcl;

  currentPagination: string = 'bank-accounts-list';
  countriesList: Array<{
    title: string;
    value: string;
  }> = [];
  bankAccounts: IBankAccount[] = [];

  constructor(public paginationService: PaginationService, private aclService: AclService) {
    for (const code in countries) {
      this.countriesList.push({
        title: countries[code],
        value: code
      });
    }
  }

  async ngOnInit(): Promise<void> {
    await this.aclService.checkAclAccess(EnumAcl.bankAccountsList);

    this.paginationService.pagination[this.currentPagination].currentPage = 0;

    this.fetchData();
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  async fetchData(): Promise<void> {
    this.paginationService.pagination[this.currentPagination].conditions = [];

    await this.paginationService.fetchData(this.currentPagination);
  }
}
