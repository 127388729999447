<div *ngIf="value" class="form-group">
    <div class="form-control fake-input placeholder-glow" [class.disabled]="disabled">
        <div class="placeholder w-100" *ngIf="!title"></div>
        <a
            class="fake-input-text"
            [routerLink]="'/admin/clients/' + value"
            target="_blank"
            [innerText]="title"
            *ngIf="title"
        >
        </a>
        <button type="button" class="btn btn-sm btn-clear" (click)="clearValue()" *ngIf="!disabled">
            <fa-icon [icon]="faXmark"></fa-icon>
        </button>
    </div>
</div>
<div [style.display]="!value ? 'block' : 'none'" class="form-group">
    <input
        type="text"
        autocomplete="off"
        [name]="inputName"
        class="form-control"
        [class.list-displayed]="optionsSuggested.length"
        list="browsers"
        (keyup)="updateAutocomplete($event.srcElement.value)"
        debounce="500"
        placeholder="Recherche dans Pipedrive"
        #searchInput
        (focus)="updateAutocomplete($event.srcElement.value)"
        [class.is-invalid]="isInvalid"
        [disabled]="disabled"
    />

    <div class="invalid-tooltip" *ngIf="isInvalid">
        <div *ngIf="isInvalid">Ce champ est obligatoire.</div>
    </div>

    <div *ngIf="optionsSuggested.length && !disabled" class="list-group">
        <button
            type="button"
            class="list-group-item list-group-item-action"
            *ngFor="let option of optionsSuggested"
            [innerHtml]="option.title"
            (click)="updateFormValue(option.value)"
        ></button>
    </div>
</div>
