<div *ngIf="enquiryFlight?.airportDepartId" [class.card]="!clickable" [class.mb-4]="!clickable">
    <div [class.card-body]="!clickable">
        <div class="row justify-content-center text-center">
            <div class="col-lg-4">
                <fa-icon [icon]="faPlaneDeparture"></fa-icon>

                <div
                    class="airport-iata-code"
                    [innerText]="enquiryFlight.airportDepartIataCode"
                ></div>
                {{ enquiryFlight.airportDepartTitle }}<br />
                {{ enquiryFlight.departDateLocal | date : "EE dd MMM yyyy" : undefined : "fr"
                }}<br />
                {{ enquiryFlight.departTimeLocal }} <strong>LT</strong>
                {{ enquiryFlight.departTimeUtc }} <strong>UTC</strong>
            </div>
            <div class="col-lg-4" *ngIf="enquiryFlight.airportArrivalId">
                <div class="d-flex justify-content-center align-items-center flex-column h-100">
                    <fa-icon [icon]="faClock"></fa-icon>
                    <div
                        class="text-muted"
                        [innerText]="
                            convertMinutesToTime(
                                getEnquiryFlightFlyTimeInMin(enquiryFlight),
                                'h ',
                                'min'
                            )
                        "
                        *ngIf="getEnquiryFlightFlyTimeInMin(enquiryFlight) > 0"
                    ></div>
                    <div
                        class="alert alert-danger text-center"
                        *ngIf="getEnquiryFlightFlyTimeInMin(enquiryFlight) < 0"
                    >
                        Le temps de vol est impossible à calculer. Vérifiez les heures de départ et
                        arrivée.
                    </div>
                    <div
                        class="text-muted"
                        *ngIf="getEnquiryFlightFlyTimeInMin(enquiryFlight) === 0"
                    >
                        x h x min
                    </div>
                </div>
            </div>
            <div class="col-lg-4" *ngIf="enquiryFlight.airportArrivalId">
                <fa-icon [icon]="faPlaneArrival"></fa-icon>
                <div
                    class="airport-iata-code"
                    [innerText]="enquiryFlight.airportArrivalIataCode"
                ></div>
                {{ enquiryFlight.airportArrivalTitle }}<br />
                <span
                    class="text-muted"
                    *ngIf="enquiryFlight.departDateTimeUtc === enquiryFlight.arrivalDateTimeUtc"
                    >Date et heure d'arrivée à préciser</span
                >
                <ng-container
                    *ngIf="enquiryFlight.departDateTimeUtc !== enquiryFlight.arrivalDateTimeUtc"
                >
                    {{ enquiryFlight.arrivalDateLocal | date : "EE dd MMM yyyy" : undefined : "fr"
                    }}<br />
                    {{ enquiryFlight.arrivalTimeLocal }} <strong>LT</strong>
                    {{ enquiryFlight.arrivalTimeUtc }} <strong>UTC</strong>
                </ng-container>
                <div *ngIf="enquiryFlight.isAircraftGroundedOnArrival">Reste en immobilisation</div>
            </div>
        </div>
    </div>
</div>
