import { Component, OnInit, NgZone, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { PaginationService } from '../../../services/pagination.service';
import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';
import { EnumAcl } from 'src/app/enums/acl.enum';
import {
  getFlightBriefCateringDepartmentList,
  IFlightBriefCatering
} from 'src/app/interfaces/flight-brief-catering.interface';
import { EnumLanguage, getLanguageLabel } from 'src/app/enums/language.enum';
import { Subscription } from 'rxjs';
import { FlightBriefCateringService } from 'src/app/services/flight-brief-caterings/flight-brief-caterings.service';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs/breadcrumbs.service';

@Component({
  selector: 'app-flight-brief-catering-view',
  templateUrl: './flight-brief-catering-view.component.html',
  styleUrls: ['./flight-brief-catering-view.component.scss']
})
export class FlightBriefCateringViewComponent implements OnInit, OnDestroy {
  getLanguageLabel = getLanguageLabel;
  getFlightBriefCateringDepartmentList = getFlightBriefCateringDepartmentList;

  EnumAcl = EnumAcl;
  EnumLanguage = EnumLanguage;

  loading: boolean = false;
  flightBriefCateringId: string;
  flightBriefCatering: IFlightBriefCatering;
  isLogged: boolean = false;

  private subscriptions = new Subscription();

  constructor(
    private remoteService: RemoteService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public paginationService: PaginationService,
    private aclService: AclService,
    private flightBriefCateringService: FlightBriefCateringService,
    private breadcrumbsService: BreadcrumbsService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
  }

  getLanguages(): EnumLanguage[] {
    return Object.values(EnumLanguage);
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(() => {
      this.flightBriefCateringId =
        this.activatedRoute.snapshot.paramMap.get('flightBriefCateringId');

      this.loadData();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  loadData(): void {
    if (this.isLogged) {
      this.loadFlightBriefCatering();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  loadFlightBriefCatering(): void {
    this.loading = true;

    this.subscriptions.add(
      this.flightBriefCateringService
        .getFromId(this.flightBriefCateringId)
        .subscribe((flightBriefCatering: IFlightBriefCatering) => {
          this.flightBriefCatering = flightBriefCatering;

          if (this.flightBriefCatering) {
            this.breadcrumbsService.setCurrentItem({
              id: this.flightBriefCatering.id,
              text: this.flightBriefCatering.textByLanguage
                ? this.flightBriefCatering.textByLanguage[EnumLanguage.fr]
                : ''
            });
          }

          this.loading = false;
        })
    );
  }

  async delete(): Promise<void> {
    if (this.hasAclAccess(EnumAcl.flightBriefCateringsDelete)) {
      const result = confirm(
        "La suppression d'un catering sera permanente. Êtes-vous sûr de vouloir continuer ?"
      );

      if (result) {
        try {
          await this.flightBriefCateringService.delete(this.flightBriefCateringId);

          alert('La suppression a été effectuée avec succès.');

          this.router.navigate(['/admin/flight-brief-caterings']);
        } catch (err) {
          console.log(err);
        }
      }
    }
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }
}
