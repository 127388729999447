import {
  faArrowRight,
  faArrowsRotate,
  faArrowsSpin,
  IconDefinition
} from '@fortawesome/free-solid-svg-icons';

export enum EnumItineraryType {
  oneWay = 'one-way',
  roundTrip = 'round-trip',
  multipleDestinations = 'multiple-destinations'
}

export const getEnumItineraryTypeLabel = (itineraryType: EnumItineraryType): string => {
  switch (itineraryType) {
    default:
      return itineraryType;
    case EnumItineraryType.oneWay:
      return 'One Way';
    case EnumItineraryType.roundTrip:
      return 'Round Trip';
    case EnumItineraryType.multipleDestinations:
      return 'Multiple destinations';
  }
};

export const getEnumItineraryTypeIconUrl = (itineraryType: EnumItineraryType): IconDefinition => {
  switch (itineraryType) {
    default:
      return itineraryType;
    case EnumItineraryType.oneWay:
      return faArrowRight;
    case EnumItineraryType.roundTrip:
      return faArrowsRotate;
    case EnumItineraryType.multipleDestinations:
      return faArrowsSpin;
  }
};

export const getEnumItineraryTypeMaxTrips = (itineraryType: EnumItineraryType): number | null => {
  switch (itineraryType) {
    default:
      return null;
    case EnumItineraryType.oneWay:
      return 1;
    case EnumItineraryType.roundTrip:
      return 2;
    case EnumItineraryType.multipleDestinations:
      return null;
  }
};
