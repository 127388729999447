import { Injectable } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';

declare const window: any;

export enum EnumAlertOptionFlexAlign {
  start = 'start',
  end = 'end',
  center = 'center',
  between = 'between',
  around = 'around',
  evenly = 'evenly'
}

export enum EnumAlertOptionTextAlign {
  left = 'left',
  center = 'center',
  right = 'right',
  justify = 'justify'
}

export enum EnumAlertOptionSize {
  small = 'sm',
  medium = 'md',
  large = 'lg',
  extraLarge = 'xl'
}

export interface IAlertOptionsButton {
  text?: string;
  iconStart?: IconProp;
  iconEnd?: IconProp;
  btnClass?: string;
  closeModal?: boolean;
  callback?: any;
}

export interface IAlertOptions {
  size?: EnumAlertOptionSize;
  header?: {
    display: boolean;
    title?: string;
    closeBtn?: boolean;
  };
  body?: {
    text?: string;
    dropdownOptions?: IAlertOptionsDropdownOption[];
    align?: EnumAlertOptionFlexAlign;
    textAlign?: EnumAlertOptionTextAlign;
  };
  footer?: {
    display: boolean;
    align?: EnumAlertOptionFlexAlign;
    buttons?: IAlertOptionsButton[];
  };
}

export interface IAlertOptionsDropdownOption {
  title: string;
  value: any;
}

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  modalEl: HTMLElement | null = null;
  modal: any;

  alertOptions: IAlertOptions | null = {
    header: {
      display: true,
      closeBtn: true
    }
  };

  alertOptionsObservable: Observable<IAlertOptions | null>;

  private alertOptionsBehavior: BehaviorSubject<IAlertOptions | null>;

  constructor(private translate: TranslateService) {
    this.alertOptionsBehavior = new BehaviorSubject<IAlertOptions | null>(null);
    this.alertOptionsObservable = this.alertOptionsBehavior.asObservable();
    this.alertOptionsObservable.subscribe(
      (alertOptions: IAlertOptions | null) => (this.alertOptions = alertOptions)
    );
  }

  setAlertOptions(alertOptions: IAlertOptions | null): void {
    this.alertOptionsBehavior.next(alertOptions);
  }

  initModal(): void {
    if (typeof document !== 'undefined') {
      this.modalEl = document.getElementById('alert-modal');

      if (this.modalEl && !this.modal) {
        this.modal = new window.bootstrap.Modal(this.modalEl);
      }
    }
  }

  presentAlert(alertOptions: IAlertOptions): void {
    this.setAlertOptions(alertOptions);

    this.initModal();

    this.modal.show();
  }

  presentSimpleAlert(title: string, message: string): void {
    this.presentAlert({
      header: {
        display: true,
        title: title
      },
      body: {
        text: message
      },
      footer: {
        display: true,
        buttons: [
          {
            text: 'OK',
            closeModal: true
          }
        ]
      }
    } as IAlertOptions);
  }

  presentAlertError(errMessage: string): void {
    this.presentAlert({
      header: {
        display: true,
        title: 'GENERAL.ERROR'
      },
      body: {
        text: errMessage
      },
      footer: {
        display: true,
        buttons: [
          {
            text: 'OK',
            closeModal: true
          }
        ]
      }
    } as IAlertOptions);
  }

  presentConfirmDeleteMultiple(nbItemsDeleted: number, callback: any): void {
    let confirmText: string = this.translate.instant(
      nbItemsDeleted > 1 ? 'DELETE_MODAL.MSG_DELETE_ITEMS' : 'DELETE_MODAL.MSG_DELETE_ITEM',
      {
        number: nbItemsDeleted
      }
    );
    confirmText += '<br>' + this.translate.instant('DELETE_MODAL.DELETE_CANT_BE_UNDONE');

    this.presentConfirmDelete(confirmText, callback, EnumAlertOptionSize.medium);
  }

  presentConfirmDelete(
    text: string,
    callback: any,
    size: EnumAlertOptionSize = EnumAlertOptionSize.small
  ): void {
    this.presentAlert({
      size,
      header: {
        display: true,
        title: 'DELETE_MODAL.TITLE'
      },
      body: {
        text: text
      },
      footer: {
        display: true,
        align: EnumAlertOptionFlexAlign.between,
        buttons: [
          {
            text: 'GENERAL.CANCEL',
            closeModal: true,
            btnClass: 'btn-secondary'
          },
          {
            text: 'GENERAL.DELETE',
            closeModal: true,
            btnClass: 'btn-danger',
            callback: callback
          }
        ]
      }
    });
  }

  presentConfirm(
    text: string,
    callback: any,
    size: EnumAlertOptionSize = EnumAlertOptionSize.small
  ): void {
    this.presentAlert({
      size,
      header: {
        display: false
      },
      body: {
        text
      },
      footer: {
        display: true,
        align: EnumAlertOptionFlexAlign.between,
        buttons: [
          {
            text: 'GENERAL.CANCEL',
            closeModal: true,
            btnClass: 'btn-secondary'
          },
          {
            text: 'GENERAL.CONFIRM',
            closeModal: true,
            callback: callback
          }
        ]
      }
    });
  }

  presentSuccess(text: string): void {
    this.presentAlert({
      body: {
        text
      },
      footer: {
        display: true,
        buttons: [
          {
            text: 'GENERAL.OK',
            closeModal: true
          }
        ]
      }
    } as IAlertOptions);
  }

  hideAlert(): void {
    this.initModal();

    this.modal.hide();

    this.setAlertOptions({});
  }

  presentDropdown(
    title: string,
    dropdownOptions: IAlertOptionsDropdownOption[] = [],
    callback: any,
    size: EnumAlertOptionSize = EnumAlertOptionSize.small
  ): void {
    this.presentAlert({
      size,
      header: {
        display: true,
        title
      },
      body: {
        dropdownOptions
      },
      footer: {
        display: true,
        align: EnumAlertOptionFlexAlign.between,
        buttons: [
          {
            text: 'GENERAL.CANCEL',
            closeModal: true,
            btnClass: 'btn-secondary'
          },
          {
            text: 'GENERAL.SELECT',
            closeModal: true,
            btnClass: 'btn-primary',
            callback: callback
          }
        ]
      }
    });
  }
}
