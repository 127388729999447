import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';

import countries from '../../../countries_fr.json';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { IFbo } from 'src/app/interfaces/fbo.interface';
import { FboService } from 'src/app/services/fbos/fbos.service';
import { Subscription } from 'rxjs';
import { IAirport } from 'src/app/interfaces/airport.interface';
import { AirportService } from 'src/app/services/airports/airports.service';
import { LoaderService } from 'src/app/services/loader/loader.service';

@Component({
  selector: 'app-fbo-edit',
  templateUrl: './fbo-edit.component.html',
  styleUrls: ['./fbo-edit.component.scss']
})
export class FboEditComponent implements OnInit, OnChanges, OnDestroy {
  @Input() inModal: boolean = false;
  @Input() defaultAirportId: string | null = null;
  @Input() fboId: string | null = null;

  @Output() dismissModal: EventEmitter<string | null> = new EventEmitter();

  isLogged: boolean = false;
  form: FormGroup = this.resetForm();
  fbo: IFbo | null = null;
  countriesList: {
    title: string;
    value: string;
  }[] = [];
  airportsObj: { [id: string]: IAirport | null } = {};

  subscriptions = new Subscription();

  constructor(
    private fboService: FboService,
    private remoteService: RemoteService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private aclService: AclService,
    private airportService: AirportService,
    private loaderService: LoaderService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );

    for (const code in countries) {
      this.countriesList.push({
        title: countries[code],
        value: code
      });
    }
  }

  get airportId(): FormControl {
    return this.form.get('airportId') as FormControl;
  }

  get name(): FormControl {
    return this.form.get('name') as FormControl;
  }

  get phoneNumber(): FormControl {
    return this.form.get('phoneNumber') as FormControl;
  }

  get email(): FormControl {
    return this.form.get('email') as FormControl;
  }

  get street(): FormControl {
    return this.form.get('street') as FormControl;
  }

  get city(): FormControl {
    return this.form.get('city') as FormControl;
  }

  get postalCode(): FormControl {
    return this.form.get('postalCode') as FormControl;
  }

  get countryCode(): FormControl {
    return this.form.get('countryCode') as FormControl;
  }

  get accessPlanUrl(): FormControl {
    return this.form.get('accessPlanUrl') as FormControl;
  }

  ngOnInit() {
    this.updatedDefaultAirportId();

    this.form.disable();

    this.activatedRoute.params.subscribe(async () => {
      if (!this.fboId) {
        this.fboId = this.activatedRoute.snapshot.paramMap.get('fboId');
      }

      if (this.fboId) {
        if (!this.inModal) {
          await this.aclService.checkAclAccess(EnumAcl.fbosEdit);
        }
        this.loadData();
      } else {
        if (!this.inModal) {
          await this.aclService.checkAclAccess(EnumAcl.fbosAdd);
        }

        if (!this.defaultAirportId) {
          this.defaultAirportId = this.activatedRoute.snapshot.paramMap.get('airportId');
        }

        this.updatedDefaultAirportId();

        this.form.enable();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updatedDefaultAirportId();

    if (this.fboId) {
      this.loadData();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  updatedDefaultAirportId(): void {
    if (this.defaultAirportId) {
      this.airportId.setValue(this.defaultAirportId);
      this.airportId.updateValueAndValidity();
      this.loadAirport(this.defaultAirportId);
    }
  }

  loadData(): void {
    if (this.isLogged) {
      this.loadFbo();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  loadFbo(): void {
    this.subscriptions.add(
      this.fboService.getFromId(this.fboId).subscribe((fbo: IFbo) => {
        this.fbo = fbo;

        this.setFbo();
      })
    );
  }

  setFbo(): void {
    if (this.form && this.fbo) {
      this.form = this.resetForm();

      for (const field in this.form.value) {
        if (typeof this.fbo[field] !== 'undefined') {
          this.form.get(field).setValue(this.fbo[field]);
        }
      }

      if (this.airportId.value) {
        this.loadAirport(this.airportId.value);
      }

      this.form.enable();
    }
  }

  async submitForm(): Promise<void> {
    this.form.markAsTouched();

    if (this.form.valid) {
      this.loaderService.presentLoader();

      let data = Object.assign({}, this.form.value);

      for (const field in data) {
        if (typeof data[field] == 'undefined') {
          data[field] = null;
        }
      }

      this.form.disable();

      let promise;
      if (this.fboId) {
        data.id = this.fboId;
        promise = () => this.fboService.update(data);
      } else {
        promise = () => this.fboService.create(data);
      }

      promise()
        .then(async id => {
          await this.loaderService.hideLoaderOnSuccess();

          if (!this.fboId) {
            this.fboId = id;

            if (this.inModal) {
              this.closeModal(this.fboId);
            }
          } else {
            if (this.inModal) {
              this.closeModal();
            }
          }

          if (!this.inModal) {
            this.redirectAfterSaving();
          }
        })
        .catch(async (err: any) => {
          await this.loaderService.hideLoaderOnFailure(err.message);
        })
        .finally(() => {
          this.form.enable();
        });
    }
  }

  redirectAfterSaving(): void {
    this.router.navigate(['/admin/fbos/' + this.fboId]);
  }

  resetForm(): FormGroup {
    return new FormGroup({
      airportId: new FormControl(null, [Validators.required]),
      name: new FormControl(null, [Validators.required]),
      phoneNumber: new FormControl(null),
      email: new FormControl(null),
      street: new FormControl(null),
      city: new FormControl(null),
      postalCode: new FormControl(null),
      countryCode: new FormControl(null, [Validators.required]),
      accessPlanUrl: new FormControl(null)
    });
  }

  setValueToFormControl($event: {
    fields: Array<{
      name: string;
      value: string;
    }>;
  }): void {
    for (let field of $event.fields) {
      const nameList = field.name.split('.');

      let formControl: AbstractControl = this.form;
      for (let name of nameList) {
        formControl = formControl.get(name);
      }

      formControl.setValue(field.value);

      if (field.name === 'airportId') {
        this.loadAirport(field.value);
      }

      formControl.markAsTouched();
      formControl.updateValueAndValidity();
    }
  }

  loadAirport(airportId: string): void {
    if (typeof this.airportsObj[airportId] === 'undefined') {
      this.airportsObj[airportId] = null;

      this.subscriptions.add(
        this.airportService.getFromId(airportId).subscribe((airport: IAirport | null) => {
          this.airportsObj[airportId] = airport;

          this.applyValueAfterLoadingAirport(airportId);
        })
      );
    } else {
      this.applyValueAfterLoadingAirport(airportId);
    }
  }

  applyValueAfterLoadingAirport(airportId: string): void {
    if (this.airportsObj[airportId]) {
      if (!this.name.value) {
        this.name.setValue(this.airportsObj[airportId].title);
      }

      if (!this.city.value) {
        this.city.setValue(this.airportsObj[airportId].city);
      }

      if (!this.countryCode.value) {
        this.countryCode.setValue(this.airportsObj[airportId].countryCode);
      }

      if (
        !this.accessPlanUrl.value &&
        this.airportsObj[airportId].latitude &&
        this.airportsObj[airportId].longitude
      ) {
        this.accessPlanUrl.setValue(
          'http://www.google.com/maps/place/' +
            this.airportsObj[airportId].latitude +
            ',' +
            this.airportsObj[airportId].longitude
        );
      }

      this.form.updateValueAndValidity();
    }
  }

  closeModal(newId: string | null = null): void {
    this.dismissModal.emit(newId);
  }
}
