<form (submit)="submitForm()" [formGroup]="form" class="main-form">
    <div class="row">
        <div class="col-md-3">
            <div class="form-group">
                <label>Dossier</label>
                <app-enquiry-ref-picker
                    [contractRefOnly]="true"
                    [defaultValue]="form.value.enquiryId"
                    inputName="enquiryId"
                    (setValueToFormControl)="setValueToFormControl($event)"
                    [disabled]="flightBrief?.id"
                ></app-enquiry-ref-picker>
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-group">
                <label for="language">Langue</label>
                <select
                    class="form-select"
                    id="language"
                    formControlName="language"
                    [class.is-invalid]="
                        (form.touched || form.get('language').touched) &&
                        form.get('language').status === 'INVALID'
                            ? 'danger'
                            : ''
                    "
                    (change)="changedLanguage()"
                >
                    <option
                        [value]="language"
                        *ngFor="let language of getLanguages()"
                        [innerHtml]="getLanguageLabel(language)"
                    ></option>
                </select>

                <div class="invalid-tooltip">
                    <div
                        *ngIf="
                            (form.touched || form.get('language').touched) &&
                            form.get('language').errors &&
                            form.get('language').errors['required']
                        "
                    >
                        Ce champ est obligatoire.
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-group">
                <label for="versionNumber">Version</label>
                <input
                    class="form-control"
                    id="versionNumber"
                    formControlName="versionNumber"
                    type="number"
                    min="0"
                    [class.is-invalid]="
                        (form.touched || form.get('versionNumber').touched) &&
                        form.get('versionNumber').status === 'INVALID'
                            ? 'danger'
                            : ''
                    "
                />

                <div class="invalid-tooltip">
                    <div
                        *ngIf="
                            (form.touched || form.get('versionNumber').touched) &&
                            form.get('versionNumber').errors &&
                            form.get('versionNumber').errors['required']
                        "
                    >
                        Ce champ est obligatoire.
                    </div>
                </div>
            </div>
        </div>
    </div>

    <app-enquiry-flights-accordion
        [enquiry]="enquiry"
        [enquiryFlights]="enquiryFlights"
        [loading]="loadingEnquiryFlights"
    ></app-enquiry-flights-accordion>

    <div class="card mb-4">
        <div class="card-header text-center">Photos de l'appareil</div>
        <div class="card-body">
            <div class="row justify-content-center">
                <div class="col-md-6">
                    <div class="form-group mb-0">
                        <label for="aircraftModelId">Appareil</label>
                        <select
                            class="form-select"
                            id="aircraftModelId"
                            formControlName="aircraftModelId"
                            [class.is-invalid]="
                                (form.touched || aircraftModelId.touched) &&
                                aircraftModelId.status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                            (change)="updatedAircraftModelId(true)"
                        >
                            <option
                                *ngFor="let option of aircraftModelOptions"
                                [value]="option.aircraftModelId"
                                [innerText]="
                                    option.routing.join(' + ') +
                                    ' : ' +
                                    (option.aircraftModel?.title || 'Chargement ...')
                                "
                            ></option>
                        </select>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || aircraftModelId.touched) &&
                                    aircraftModelId.errors &&
                                    aircraftModelId.errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6" *ngIf="aircraftModelId.value && aircraftOptions.length">
                    <div class="form-group mb-0">
                        <label for="aircraftId">Immatriculation</label>
                        <select
                            class="form-select"
                            id="aircraftId"
                            formControlName="aircraftId"
                            [class.is-invalid]="
                                (form.touched || aircraftId.touched) &&
                                aircraftId.status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                            (change)="updatedAircraftId()"
                        >
                            <option [ngValue]="null">-- Choisissez --</option>
                            <option
                                *ngFor="let aircraft of aircraftOptions"
                                [value]="aircraft.id"
                                [innerText]="
                                    aircraft.registration !== ''
                                        ? aircraft.registration
                                        : 'Immatriculation inconnu (id : ' + aircraft.id + ')'
                                "
                            ></option>
                        </select>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || aircraftId.touched) &&
                                    aircraftId.errors &&
                                    aircraftId.errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-4" *ngIf="aircraftModelId.value">
                <div class="col-md-6" *ngFor="let imageField of ['imageOutside', 'imageInside']">
                    <div *ngIf="imageField === 'imageOutside'" class="text-center">
                        Photo extérieur
                    </div>
                    <div *ngIf="imageField === 'imageInside'" class="text-center">
                        Photo intérieur
                    </div>
                    <div class="image-upload-container">
                        <div
                            class="image-upload-content"
                            [class.no-image]="!form.value[imageField + 'Url']"
                            (click)="openImageModal(imageField)"
                        >
                            <div class="image-container" *ngIf="form.value[imageField + 'Url']">
                                <div
                                    class="image"
                                    [id]="imageField"
                                    [style.background-image]="
                                        'url(' + form.value[imageField + 'Url'] + ')'
                                    "
                                    [style.webkitTransform]="
                                        'scale(' + form.value[imageField + 'ZoomLevel'] + ')'
                                    "
                                    [style.transform]="
                                        'scale(' + form.value[imageField + 'ZoomLevel'] + ')'
                                    "
                                ></div>
                            </div>
                            <div class="image-upload-legend">
                                <span *ngIf="!form.disabled">
                                    {{
                                        form.value[imageField + "Url"]
                                            ? "Éditer l'image"
                                            : "Ajouter une image"
                                    }}
                                </span>
                                <span *ngIf="form.disabled">...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="form-group">
        <label for="comments">Commentaires (sera affichés sur le flight brief)</label>
        <textarea
            formControlName="comments"
            class="form-control"
            rows="5"
            [class.is-invalid]="
                (form.touched || form.get('comments').touched) &&
                form.get('comments').status === 'INVALID'
                    ? 'danger'
                    : ''
            "
        ></textarea>

        <div class="invalid-tooltip">
            <div
                *ngIf="
                    (form.touched || form.get('comments').touched) &&
                    form.get('comments').errors &&
                    form.get('comments').errors['required']
                "
            >
                Ce champ est obligatoire.
            </div>
        </div>
    </div>

    <div class="form-group">
        <label for="internalNote">Notes internes (ne sera pas affichés sur le flight brief)</label>
        <textarea
            formControlName="internalNote"
            class="form-control"
            rows="5"
            [class.is-invalid]="
                (form.touched || form.get('internalNote').touched) &&
                form.get('internalNote').status === 'INVALID'
                    ? 'danger'
                    : ''
            "
        ></textarea>

        <div class="invalid-tooltip">
            <div
                *ngIf="
                    (form.touched || form.get('internalNote').touched) &&
                    form.get('internalNote').errors &&
                    form.get('internalNote').errors['required']
                "
            >
                Ce champ est obligatoire.
            </div>
        </div>
    </div>

    <div class="row justify-content-center text-center mt-4">
        <div class="col-md-4">
            <button
                type="button"
                [routerLink]="
                    flightBriefId
                        ? '/admin/flight-briefs/' + flightBriefId
                        : enquiryId
                        ? '/admin/flight-briefs/enquiry/' + enquiryId
                        : '/admin/flight-briefs'
                "
                class="btn btn-secondary btn-block mb-4"
                [disabled]="form.disabled"
            >
                Annuler
            </button>
        </div>
        <div class="col-md-4">
            <button
                type="submit"
                class="btn btn-primary btn-block mb-4"
                [disabled]="form.disabled || !form.valid || loadingEnquiryFlights"
            >
                Sauvegarder
            </button>
        </div>
    </div>
</form>

<div class="modal" tabindex="-1" role="dialog" #modalImage>
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    Sélection image
                    <span *ngIf="selectedImageField === 'imageOutside'">extérieur</span
                    ><span *ngIf="selectedImageField === 'imageInside'">intérieur</span>
                </h5>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <div class="modal-body">
                <div class="card mb-4" *ngIf="selectedImageField">
                    <div class="card-header">Image utilisée</div>
                    <div class="card-body">
                        <div class="row justify-content-center align-items-center">
                            <div
                                class="col-md-6"
                                *ngIf="form.get(selectedImageField + 'Url').value"
                            >
                                <img [src]="form.get(selectedImageField + 'Url').value" />
                            </div>
                            <div class="col-md-6">
                                <div class="btn-group-vertical">
                                    <button
                                        type="button"
                                        class="btn btn-primary"
                                        [innerText]="
                                            form.get(selectedImageField + 'Url').value
                                                ? 'Éditer'
                                                : 'Ajouter'
                                        "
                                        (click)="editSelectedImage()"
                                    ></button>

                                    <button
                                        type="button"
                                        class="btn btn-danger"
                                        *ngIf="form.get(selectedImageField + 'Url').value"
                                        (click)="deleteSelectedImage()"
                                    >
                                        Supprimer
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mb-4" *ngIf="aircraftId.value">
                    <div class="card-header">Appareil</div>
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col-md-6">
                                <strong>Immatriculation :</strong>
                                {{ aircraftsObj[aircraftId.value]?.registration }}<br />
                                <strong>Opérateur :</strong>
                                {{ aircraftsObj[aircraftId.value]?.airlineTitle }}<br />
                                <strong>Modèle :</strong>
                                {{
                                    aircraftsObj[aircraftId.value]?.aircraftModelTitle ||
                                        aircraftsObj[aircraftId.value]?.model
                                }}
                            </div>
                            <div class="col-md-6 text-end">
                                <a
                                    [routerLink]="'/admin/aircrafts/' + aircraftId.value"
                                    target="_blank"
                                    class="btn btn-primary"
                                    >Ouvrir <fa-icon [icon]="faExternalLink"></fa-icon
                                ></a>
                            </div>
                        </div>
                    </div>

                    <ul class="list-group list-group-flush">
                        <li
                            class="list-group-item"
                            *ngFor="let imageUrl of aircraftsObj[aircraftId.value]?.imageUrls"
                        >
                            <div class="row align-items-center">
                                <div class="col-md-6">
                                    <img [src]="imageUrl" />
                                </div>
                                <div class="col-md-6">
                                    <button
                                        type="button"
                                        class="btn me-2 mb-2"
                                        [class.btn-primary]="
                                            form.get(selectedImageField + 'Url')?.value !== imageUrl
                                        "
                                        [class.btn-success]="
                                            form.get(selectedImageField + 'Url')?.value === imageUrl
                                        "
                                        [innerText]="
                                            form.get(selectedImageField + 'Url')?.value === imageUrl
                                                ? 'Déselectionner'
                                                : 'Sélectionner'
                                        "
                                        (click)="toggleSelectedImageValue(imageUrl)"
                                    ></button>

                                    <div
                                        class="badge me-2"
                                        [class.text-bg-secondary]="
                                            !aircraftsObj[
                                                aircraftId.value
                                            ]?.imageOutsideUrl?.includes(imageUrl)
                                        "
                                        [class.text-bg-success]="
                                            aircraftsObj[
                                                aircraftId.value
                                            ]?.imageOutsideUrl?.includes(imageUrl)
                                        "
                                    >
                                        <fa-icon
                                            [icon]="
                                                aircraftsObj[
                                                    aircraftId.value
                                                ]?.imageOutsideUrl?.includes(imageUrl)
                                                    ? faToggleOn
                                                    : faToggleOff
                                            "
                                        ></fa-icon>

                                        Image extérieure par défaut
                                    </div>
                                    <div
                                        class="badge"
                                        [class.text-bg-secondary]="
                                            !aircraftsObj[
                                                aircraftId.value
                                            ]?.imageInsideUrl?.includes(imageUrl)
                                        "
                                        [class.text-bg-success]="
                                            aircraftsObj[
                                                aircraftId.value
                                            ]?.imageInsideUrl?.includes(imageUrl)
                                        "
                                    >
                                        <fa-icon
                                            [icon]="
                                                aircraftsObj[
                                                    aircraftId.value
                                                ]?.imageInsideUrl?.includes(imageUrl)
                                                    ? faToggleOn
                                                    : faToggleOff
                                            "
                                        ></fa-icon>

                                        Image intérieure par défaut
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div
                    class="card mb-4"
                    *ngIf="aircraftModelId.value && aircraftModelsObj[aircraftModelId.value]"
                >
                    <div class="card-header">
                        Image pour le modèle {{ aircraftModelsObj[aircraftModelId.value].title }}
                    </div>
                    <ul class="list-group list-group-flush">
                        <li
                            class="list-group-item"
                            *ngFor="let field of ['imageUrl', 'imageInsideUrl', 'imagePlanUrl']"
                        >
                            <div class="row align-items-center">
                                <div class="col-md-6">
                                    <label *ngIf="field === 'imageUrl'">Image extérieur</label>
                                    <label *ngIf="field === 'imageInsideUrl'"
                                        >Image intérieur</label
                                    >
                                    <label *ngIf="field === 'imagePlanUrl'">Image plan</label>
                                    <img
                                        [src]="aircraftModelsObj[aircraftModelId.value][field]"
                                        *ngIf="aircraftModelsObj[aircraftModelId.value][field]"
                                    />
                                </div>
                                <div class="col-md-6">
                                    <button
                                        type="button"
                                        class="btn me-2 mb-2"
                                        [class.btn-primary]="
                                            form.get(selectedImageField + 'Url')?.value !==
                                            aircraftModelsObj[aircraftModelId.value][field]
                                        "
                                        [class.btn-success]="
                                            form.get(selectedImageField + 'Url')?.value ===
                                            aircraftModelsObj[aircraftModelId.value][field]
                                        "
                                        *ngIf="aircraftModelsObj[aircraftModelId.value][field]"
                                        [innerText]="
                                            form.get(selectedImageField + 'Url')?.value ===
                                            aircraftModelsObj[aircraftModelId.value][field]
                                                ? 'Déselectionner'
                                                : 'Sélectionner'
                                        "
                                        (click)="
                                            toggleSelectedImageValue(
                                                aircraftModelsObj[aircraftModelId.value][field]
                                            )
                                        "
                                    ></button>
                                    <div class="btn-group-vertical">
                                        <button
                                            type="button"
                                            class="btn btn-primary"
                                            [innerText]="
                                                aircraftModelsObj[aircraftModelId.value][field]
                                                    ? 'Éditer'
                                                    : 'Ajouter'
                                            "
                                            (click)="editImageToAircraftModel(field)"
                                        ></button>

                                        <button
                                            type="button"
                                            class="btn btn-danger"
                                            *ngIf="aircraftModelsObj[aircraftModelId.value][field]"
                                            (click)="deleteImageFromAircraftModel(field)"
                                        >
                                            Supprimer
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>

                <input
                    type="file"
                    id="aircraftModelImageInput"
                    class="form-control"
                    (change)="changeInputFileForAircraftModel($event)"
                    accept="image/*"
                />

                <input
                    type="file"
                    id="imageUrlInput"
                    class="form-control"
                    (change)="changeInputFile($event)"
                    accept="image/*"
                />
            </div>
        </div>
    </div>
</div>
