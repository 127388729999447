import { Component, OnInit, NgZone, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { PaginationService } from '../../../services/pagination.service';
import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';

import { EnumAcl } from 'src/app/enums/acl.enum';
import { Subscription } from 'rxjs';
import { IInvoice, getInvoiceTitle } from 'src/app/interfaces/invoice.interface';
import { InvoiceService } from 'src/app/services/invoices/invoices.service';
import { IEncaissementType } from 'src/app/interfaces/encaissement-type.interface';
import { IBankAccount } from 'src/app/interfaces/bank-account.interface';
import { IEncaissement } from 'src/app/interfaces/encaissement.interface';
import { EncaissementService } from 'src/app/services/encaissements/encaissements.service';
import { EncaissementTypeService } from 'src/app/services/encaissement-types/encaissement-types.service';
import { BankAccountService } from 'src/app/services/bank-accounts/bank-accounts.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs/breadcrumbs.service';
import { faEdit, faExternalLink, faTrash } from '@fortawesome/free-solid-svg-icons';
import { formatPrice } from 'src/app/misc.utils';

@Component({
  selector: 'app-encaissement-view',
  templateUrl: './encaissement-view.component.html',
  styleUrls: ['./encaissement-view.component.scss']
})
export class EncaissementViewComponent implements OnInit, OnDestroy {
  loading: boolean = false;
  encaissementId: string;
  encaissement: IEncaissement | null = null;
  isLogged: boolean = false;

  bankAccount: IBankAccount | null = null;
  invoicesObj: { [id: string]: IInvoice | null } = {};
  encaissementType: IEncaissementType | null = null;

  getInvoiceTitle = getInvoiceTitle;
  formatPrice = formatPrice;

  faTrash = faTrash;
  faEdit = faEdit;
  faExternalLink = faExternalLink;

  EnumAcl = EnumAcl;

  private subscriptions = new Subscription();

  constructor(
    private remoteService: RemoteService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public paginationService: PaginationService,
    private aclService: AclService,
    private invoiceService: InvoiceService,
    private encaissementService: EncaissementService,
    private encaissementTypeService: EncaissementTypeService,
    private bankAccountService: BankAccountService,
    private loaderService: LoaderService,
    private breadcrumbsService: BreadcrumbsService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
  }

  ngOnInit(): void {
    this.activatedRoute.url.subscribe(() => {
      this.encaissementId = this.activatedRoute.snapshot.paramMap.get('encaissementId');

      this.loadData();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  loadData(): void {
    if (this.isLogged) {
      this.loadEncaissement();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  async loadEncaissement(): Promise<void> {
    if (this.encaissementId) {
      this.loading = true;

      this.subscriptions.add(
        this.encaissementService
          .getFromId(this.encaissementId)
          .subscribe((encaissement: IEncaissement | null) => {
            this.encaissement = encaissement;

            if (this.encaissement) {
              this.breadcrumbsService.setCurrentItem({
                text: this.encaissement.libelle,
                id: this.encaissement.id
              });
            }

            this.loadBankAccount();
            this.loadInvoice();
            this.loadEncaissementType();
          })
      );

      this.loading = false;
    }
  }

  async delete(): Promise<void> {
    if (this.encaissementId && this.hasAclAccess(EnumAcl.encaissementsDelete)) {
      const result = confirm(
        "La suppression d'un compte bancaire sera permanente. Êtes-vous sûr de vouloir continuer?"
      );

      if (result) {
        this.loaderService.presentLoader();

        try {
          await this.encaissementService.delete(this.encaissementId);
          await this.loaderService.hideLoaderOnSuccess();

          this.router.navigate(['/admin/encaissements']);
        } catch (err) {
          await this.loaderService.hideLoaderOnFailure(err);
        }
      }
    }
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  async loadBankAccount(): Promise<void> {
    if (this.encaissement?.bankAccountId && !this.bankAccount) {
      this.subscriptions.add(
        this.bankAccountService
          .getFromId(this.encaissement.bankAccountId)
          .subscribe((bankAccount: IBankAccount | null) => {
            this.bankAccount = bankAccount;
          })
      );
    }
  }

  loadInvoice(): void {
    if (this.encaissement?.invoiceIds?.length) {
      for (const invoiceId of this.encaissement?.invoiceIds) {
        if (typeof this.invoicesObj[invoiceId] === 'undefined') {
          this.invoicesObj[invoiceId] = null;

          this.subscriptions.add(
            this.invoiceService.getFromId(invoiceId).subscribe((invoice: IInvoice) => {
              this.invoicesObj[invoiceId] = invoice;
            })
          );
        }
      }
    }
  }

  async loadEncaissementType(): Promise<void> {
    if (this.encaissement?.encaissementTypeId && !this.encaissementType) {
      this.subscriptions.add(
        this.encaissementTypeService
          .getFromId(this.encaissement.encaissementTypeId)
          .subscribe((encaissementType: IEncaissementType | null) => {
            this.encaissementType = encaissementType;
          })
      );
    }
  }
}
