<div class="row">
    <div class="col-md-3 mb-4">
        <a
            [routerLink]="airlineId ? '/admin/crews/add/airline/' + airlineId : '/admin/crews/add'"
            class="btn btn-primary"
            *ngIf="hasAclAccess(EnumAcl.crewsAdd)"
            [innerText]="'+ Nouveau équipage'"
        ></a>
    </div>
</div>

<div class="card mb-4">
    <div class="table-responsive no-border">
        <table class="table table-striped table-hover">
            <thead>
                <tr>
                    <th
                        scope="col"
                        *ngFor="let field of paginationService.pagination[currentPagination].fields"
                        [class.is-sorted]="
                            paginationService.pagination[currentPagination].orderBy?.field ==
                            field.code
                        "
                        [class.asc]="
                            paginationService.pagination[currentPagination].orderBy?.field ==
                                field.code &&
                            paginationService.pagination[currentPagination].orderBy?.direction ==
                                'asc'
                        "
                        [class.desc]="
                            paginationService.pagination[currentPagination].orderBy?.field ==
                                field.code &&
                            paginationService.pagination[currentPagination].orderBy?.direction ==
                                'desc'
                        "
                        [innerHtml]="field.title"
                    ></th>
                </tr>
            </thead>
            <tbody *ngIf="paginationService.loading">
                <tr *ngFor="let number of [].constructor(10)">
                    <td
                        *ngFor="let field of paginationService.pagination[currentPagination].fields"
                    >
                        <div class="skeleton-text"></div>
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="!paginationService.loading">
                <tr
                    *ngIf="
                        !paginationService.pagination[currentPagination].data ||
                        !paginationService.pagination[currentPagination].data.length
                    "
                    class="table-warning text-center"
                >
                    <td
                        [attr.colspan]="
                            paginationService.pagination[currentPagination].fields.length
                        "
                        [innerText]="'Aucun équipage n\'a été trouvé.'"
                    ></td>
                </tr>
                <tr
                    *ngFor="let crew of paginationService.pagination[currentPagination].data"
                    [routerLink]="'/admin/crews/' + crew.id"
                    class="clickable"
                >
                    <td [innerHtml]="crew.firstname"></td>
                    <td [innerHtml]="crew.lastname"></td>
                    <td [innerHtml]="getCrewTypeLabel(crew.type)"></td>
                    <td [innerHtml]="crew.created | date : 'dd/MM/yyyy HH:mm'"></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<nav aria-label="Liste navigation" class="mt-4 mb-4" *ngIf="!paginationService.loading">
    <ul class="pagination justify-content-center">
        <li
            class="page-item"
            [class.disabled]="paginationService.pagination[currentPagination].currentPage === 0"
        >
            <button
                type="button"
                class="page-link"
                (click)="paginationService.loadPreviousPage(currentPagination)"
            >
                Précédent
            </button>
        </li>
        <li class="page-item disabled">
            <span
                class="page-link"
                [innerHtml]="paginationService.pagination[currentPagination].currentPage + 1"
            ></span>
        </li>
        <li
            class="page-item"
            [class.disabled]="
                paginationService.pagination[currentPagination].data &&
                (!paginationService.pagination[currentPagination].data.length ||
                    paginationService.pagination[currentPagination].data.length <
                        paginationService.pagination[currentPagination].limit)
            "
        >
            <button
                type="button"
                class="page-link"
                (click)="paginationService.loadNextPage(currentPagination)"
            >
                Suivant
            </button>
        </li>
    </ul>
</nav>
