<div class="modal-header" *ngIf="inModal">
    <div class="modal-title">
        <strong
            *ngIf="enquiry?.refContractTitle"
            [innerHtml]="getDisplayedEnquiryRefTitle(enquiry, 'refContract')"
        ></strong>
        <span *ngIf="enquiry?.refContractTitle"> | </span>
        <span
            *ngIf="enquiry?.refEnquiryTitle"
            [innerHtml]="getDisplayedEnquiryRefTitle(enquiry, 'refEnquiry')"
        ></span>
        - Devis envoyé au client ?
    </div>
    <button
        type="button"
        class="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        #btnCloseModal
    ></button>
</div>
<form
    (submit)="submitForm()"
    [formGroup]="form"
    (keydown.enter)="submitForm()"
    class="main-form"
    *ngIf="enquiry"
>
    <div [class.modal-body]="inModal">
        <div class="form-group">
            <label for="quotationSentToClient">Devis envoyé au client ?</label>
            <select
                class="form-select"
                formControlName="quotationSentToClient"
                [class.is-invalid]="
                    (form.touched || quotationSentToClient.touched) &&
                    quotationSentToClient.status === 'INVALID'
                        ? 'danger'
                        : ''
                "
                (change)="updatedQuotationSentToClient()"
            >
                <option [ngValue]="false">Non</option>
                <option [ngValue]="true">Oui</option>
            </select>

            <div class="invalid-tooltip">
                <div
                    *ngIf="
                        (form.touched || quotationSentToClient.touched) &&
                        quotationSentToClient.errors &&
                        quotationSentToClient.errors['required']
                    "
                >
                    Ce champ est obligatoire.
                </div>
            </div>
        </div>

        <ng-container *ngIf="quotationSentToClient.value">
            <div class="form-group">
                <label for="quotationSentToClientId">Devis envoyé</label>
                <div class="placeholder w-100" *ngIf="loadingQuotationByEnquiry[enquiry.id]"></div>
                <div
                    class="alert alert-warning text-center"
                    *ngIf="
                        !loadingQuotationByEnquiry[enquiry.id] &&
                        !quotationsByEnquiry[enquiry.id].length
                    "
                >
                    Aucun devis sur ce dossier
                </div>
                <select
                    class="form-select"
                    formControlName="quotationSentToClientId"
                    [class.is-invalid]="
                        (form.touched || quotationSentToClientId.touched) &&
                        quotationSentToClientId.status === 'INVALID'
                            ? 'danger'
                            : ''
                    "
                    *ngIf="
                        !loadingQuotationByEnquiry[enquiry.id] &&
                        quotationsByEnquiry[enquiry.id].length
                    "
                    (change)="updatedQuotationSentToClientId()"
                >
                    <option [ngValue]="null">-- Choisissez --</option>

                    <option
                        [value]="quotation.id"
                        *ngFor="let quotation of quotationsByEnquiry[enquiry.id]"
                        [innerText]="quotation.title"
                    ></option>
                </select>

                <div class="invalid-tooltip">
                    <div
                        *ngIf="
                            (form.touched || quotationSentToClientId.touched) &&
                            quotationSentToClientId.errors &&
                            quotationSentToClientId.errors['required']
                        "
                    >
                        Ce champ est obligatoire.
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="quotationSentToClientDate">Date d'envoi</label>
                        <input
                            class="form-control"
                            id="quotationSentToClientDate"
                            formControlName="quotationSentToClientDate"
                            type="date"
                            [class.is-invalid]="
                                (form.touched || quotationSentToClientDate.touched) &&
                                quotationSentToClientDate.status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                            [max]="todayStr"
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || quotationSentToClientDate.touched) &&
                                    quotationSentToClientDate.errors &&
                                    quotationSentToClientDate.errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label for="quotationSentToClientBy">Envoyé par ?</label>
                        <div class="placeholder w-100" *ngIf="loadingUsers"></div>
                        <div
                            class="alert alert-warning text-center"
                            *ngIf="!loadingUsers && !users.length"
                        >
                            Aucun utilisateur
                        </div>
                        <select
                            class="form-select"
                            formControlName="quotationSentToClientBy"
                            [class.is-invalid]="
                                (form.touched || quotationSentToClientBy.touched) &&
                                quotationSentToClientBy.status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                            *ngIf="!loadingUsers && users.length"
                        >
                            <option [ngValue]="null">-- Choisissez --</option>

                            <option
                                [value]="user.id"
                                *ngFor="let user of users"
                                [innerText]="getUserFullname(user)"
                            ></option>
                        </select>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || quotationSentToClientBy.touched) &&
                                    quotationSentToClientBy.errors &&
                                    quotationSentToClientBy.errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="row justify-content-center text-center mt-4" *ngIf="!inModal">
        <div class="col-md-4">
            <button
                type="button"
                [routerLink]="enquiry?.id ? '/admin/enquiries/' + enquiry.id : '/admin/enquiries'"
                class="btn btn-secondary btn-block mb-4"
                [disabled]="form.disabled"
            >
                Annuler
            </button>
        </div>
        <div class="col-md-4">
            <button
                type="submit"
                class="btn btn-primary btn-block mb-4"
                [disabled]="form.disabled || !form.valid"
            >
                Sauvegarder
            </button>
        </div>
    </div>

    <div class="modal-footer" *ngIf="inModal">
        <button type="button" class="btn btn-secondary" (click)="closeModal()">Annuler</button>
        <button
            type="button"
            class="btn btn-primary"
            (click)="submitForm()"
            [disabled]="form.disabled || !form.valid"
        >
            Sauvegarder
        </button>
    </div>
</form>
