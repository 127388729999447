import {
  Component,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef
} from '@angular/core';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'autocomplete-input',
  templateUrl: './autocomplete-input.component.html',
  styleUrls: ['./autocomplete-input.component.scss']
})
export class AutocompleteInputComponent implements OnChanges {
  @Input('isInvalid') isInvalid: boolean;
  @Input('inputName') inputName: string;
  @Input('disabled') disabled: boolean = false;
  @Input('noMargin') noMargin: boolean = false;
  @Input('defaultValue') defaultValue: string;
  @Input('itineraries') itineraries: Array<{
    title: string;
    value: string;
  }> = [];
  @Output() setValueToFormControl: EventEmitter<{
    fields: Array<{
      name: string;
      value: string;
    }>;
  }> = new EventEmitter();
  value: string;
  title: string;
  optionsSuggested: Array<{
    title: string;
    value: string;
  }> = [];
  @ViewChild('searchInput', { static: false }) searchInput: ElementRef;

  faXmark = faXmark;

  // initiated: boolean = false

  constructor() {}

  ngOnChanges() {
    // if (!this.initiated && this.defaultValue) {
    this.value = this.defaultValue;

    // this.initiated = true
    // }

    this.updateTitle();
  }

  updateTitle(): void {
    if (this.itineraries) {
      for (let option of this.itineraries) {
        if (option.value == this.value) {
          this.title = option.title;

          break;
        }
      }
    }
  }

  updateFormValue(value: string): void {
    this.value = value;
    this.updateTitle();

    this.setValueToFormControl.emit({
      fields: [
        {
          name: this.inputName,
          value: this.value
        }
      ]
    });

    this.optionsSuggested = [];
  }

  updateAutocomplete(query: string): void {
    this.optionsSuggested = [];
    let count = 0;
    if (this.itineraries) {
      for (let option of this.itineraries) {
        if (option.value.match(new RegExp(query, 'i'))) {
          this.optionsSuggested.push({
            title: option.title,
            value: option.value
          });

          count++;

          if (count > 5) {
            break;
          }
        }
      }

      if (count <= 5) {
        // We had more options
        for (let option of this.itineraries) {
          if (option.title.match(new RegExp(query, 'i'))) {
            // We check if this option has not already been added
            let alreadyAdded: boolean = false;
            for (let optionSuggested of this.optionsSuggested) {
              if (optionSuggested.value === option.value) {
                alreadyAdded = true;
                break;
              }
            }

            if (!alreadyAdded) {
              this.optionsSuggested.push({
                title: option.title,
                value: option.value
              });

              count++;

              if (count > 5) {
                break;
              }
            }
          }
        }
      }
    }
  }

  clearValue(): void {
    this.updateFormValue('');
    this.updateAutocomplete('');

    setTimeout(() => {
      if (this.searchInput) {
        this.searchInput.nativeElement.focus();
      }
    });
  }
}
