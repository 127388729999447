import { Component, OnInit, NgZone } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  AbstractControl,
  FormControl
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';

import countries from '../../../countries_fr.json';
import countriesFull from '../../../countries_full.json';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { getContinentLabel } from 'src/app/enums/continent-code.enum';
import { IAirport, IHelicopterTransfer } from 'src/app/interfaces/airport.interface';

@Component({
  selector: 'app-airport-edit',
  templateUrl: './airport-edit.component.html',
  styleUrls: ['./airport-edit.component.scss']
})
export class AirportEditComponent implements OnInit {
  isLogged: boolean = false;
  form: FormGroup;
  sending: boolean = false;
  airport: IAirport | null = null;
  airportId: string;
  countriesList: Array<{
    title: string;
    value: string;
  }> = [];
  isLieuDit = false;

  getContinentLabel = getContinentLabel;

  constructor(
    private formBuilder: FormBuilder,
    private remoteService: RemoteService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private aclService: AclService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );

    for (const code in countries) {
      this.countriesList.push({
        title: countries[code],
        value: code
      });
    }
  }

  get helicopterTransfers(): FormArray {
    return this.form.get('helicopterTransfers') as FormArray;
  }

  getHelicopterTransfer(i: number): FormGroup {
    return this.helicopterTransfers.at(i) as FormGroup;
  }

  getHelicopterTransferField(i: number, field: string): FormControl {
    return this.getHelicopterTransfer(i).get(field) as FormControl;
  }

  ngOnInit() {
    this.isLieuDit = !!window.location.href.match('lieux-dits');

    this.form = this.formBuilder.group({
      title: ['', [Validators.required]],
      iataCode: [''],
      countryCode: ['', [Validators.required]],
      continentCode: ['', [Validators.required]],
      latitude: ['', [Validators.required]],
      longitude: ['', [Validators.required]],
      isLieuDit: [this.isLieuDit],
      isInSimulateurPrix: [false],
      visibleInSimulateurPrixMap: [false],
      isJetAirport: [false],
      isHighDemand: [false],
      city: [''],
      distancefromCity: [''],
      fireCategory: [''],
      longestHardSurfaceRunwayInFt: [''],
      helicopterTransfers: new FormArray([]),
      simulateurInformation: ['']
    });

    this.form.disable();

    this.activatedRoute.url.subscribe(async () => {
      this.airportId = this.activatedRoute.snapshot.paramMap.get('airportId');

      if (this.airportId) {
        await this.aclService.checkAclAccess(
          this.isLieuDit ? EnumAcl.lieuxDitsEdit : EnumAcl.airportsEdit
        );
        this.loadData();
      } else {
        await this.aclService.checkAclAccess(
          this.isLieuDit ? EnumAcl.lieuxDitsAdd : EnumAcl.airportsAdd
        );
        this.form.enable();
      }
    });
  }

  loadData(): void {
    if (this.isLogged) {
      this.loadAirport();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  async loadAirport(): Promise<void> {
    const doc = await this.remoteService.getDocument('airports', this.airportId);

    this.airport = doc as IAirport;

    this.setAirport();
  }

  setAirport(): void {
    if (this.form && this.airport) {
      this.form.get('title').setValue(this.airport.title);
      this.form.get('iataCode').setValue(this.airport.iataCode);
      this.form.get('countryCode').setValue(this.airport.countryCode);
      this.form.get('continentCode').setValue(this.airport.continentCode);
      this.form.get('latitude').setValue(this.airport.latitude);
      this.form.get('longitude').setValue(this.airport.longitude);
      this.form.get('isLieuDit').setValue(this.airport.isLieuDit);
      this.form.get('isInSimulateurPrix').setValue(this.airport.isInSimulateurPrix);
      this.form.get('visibleInSimulateurPrixMap').setValue(this.airport.visibleInSimulateurPrixMap);
      this.form.get('isJetAirport').setValue(this.airport.isJetAirport ?? false);
      this.form.get('city').setValue(this.airport.city);
      this.form.get('distancefromCity').setValue(this.airport.distancefromCity);
      this.form.get('fireCategory').setValue(this.airport.fireCategory);
      this.form
        .get('longestHardSurfaceRunwayInFt')
        .setValue(this.airport.longestHardSurfaceRunwayInFt);
      this.form.get('isHighDemand').setValue(this.airport.isHighDemand ?? false);
      this.form.get('simulateurInformation').setValue(this.airport.simulateurInformation);

      if (this.airport.helicopterTransfers) {
        for (const helicopterTransfer of this.airport.helicopterTransfers) {
          this.addHelicopterTransferAndSet(helicopterTransfer);
        }
      }

      this.form.enable();
    }
  }

  addHelicopterTransfer(): void {
    this.helicopterTransfers.push(
      new FormGroup({
        timeInMin: new FormControl(null, Validators.required),
        price: new FormControl(null, Validators.required),
        airportDestinationId: new FormControl(null, Validators.required)
      })
    );
  }

  addHelicopterTransferAndSet(helicopterTransfer: IHelicopterTransfer): void {
    this.addHelicopterTransfer();

    const i: number = this.helicopterTransfers.length - 1;

    for (const field in this.getHelicopterTransfer(i).value) {
      if (helicopterTransfer[field as keyof typeof helicopterTransfer]) {
        this.getHelicopterTransferField(i, field).setValue(
          helicopterTransfer[field as keyof typeof helicopterTransfer]
        );
      }
    }
  }

  deleteHelicopterTransfer(i: number): void {
    this.helicopterTransfers.removeAt(i);
  }

  submitForm(): void {
    this.form.markAsTouched();

    if (this.form.valid) {
      let data = Object.assign({}, this.form.value);

      for (const field in data) {
        if (typeof data[field] == 'undefined') {
          data[field] = null;
        }
      }

      this.sending = true;

      this.form.disable();

      if (this.airportId) {
        this.remoteService
          .updateDocumentToCollection('airports', this.airportId, data)
          .then(() => {
            this.sending = false;
            this.redirectAfterSaving();
          })
          .catch(err => {
            this.sending = false;
            this.form.enable();

            alert(err.message);
          });
      } else {
        this.remoteService
          .addDocumentToCollection('airports', data)
          .then((docId: string) => {
            this.airportId = docId;

            this.sending = false;
            this.redirectAfterSaving();
          })
          .catch(err => {
            this.sending = false;
            this.form.enable();

            alert(err.message);
          });
      }
    }
  }

  redirectAfterSaving(): void {
    if (this.isLieuDit) {
      this.router.navigate(['/admin/lieux-dits/' + this.airportId]);
    } else {
      this.router.navigate(['/admin/airports/' + this.airportId]);
    }
  }

  setValueToFormControl($event: {
    fields: Array<{
      name: string;
      value: string;
    }>;
  }): void {
    for (let field of $event.fields) {
      const nameList = field.name.split('.');

      let formControl: AbstractControl = this.form;
      for (let name of nameList) {
        formControl = formControl.get(name);
      }

      formControl.setValue(field.value);

      if (field.name == 'countryCode') {
        this.assignContinents();
      }

      formControl.markAsTouched();
      formControl.updateValueAndValidity();
    }
  }

  assignContinents(): void {
    for (const country of countriesFull) {
      if (country['ISO3166-1-Alpha-2'] == this.form.value.countryCode) {
        this.form.get('continentCode').setValue(country.Continent);
      }
    }
  }

  updatedIsInSimulateurPrix(): void {
    this.form.get('visibleInSimulateurPrixMap').setValue(this.form.value.isInSimulateurPrix);
    this.form.get('visibleInSimulateurPrixMap').updateValueAndValidity();
  }

  async updateAirportDestinationId(
    $event: {
      fields: {
        name: string;
        value: any;
      }[];
    },
    i: number
  ): Promise<void> {
    for (let field of $event.fields) {
      if (field.name === 'airportDestinationId') {
        this.getHelicopterTransferField(i, field.name).setValue(field.value);
      }
    }
  }
}
