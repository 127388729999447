import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { PaginationService } from '../../../services/pagination.service';
import { AclService } from '../../../services/acl.service';
import slugify from 'slugify';

import { EnumAcl } from 'src/app/enums/acl.enum';
import { getHandlingFullAddress, IHandling } from 'src/app/interfaces/handling.interface';
import { HandlingService } from 'src/app/services/handlings/handlings.service';
import { faCancel, faCheckCircle, faEdit, faEye } from '@fortawesome/free-solid-svg-icons';
import { AlertService } from 'src/app/services/alert/alert.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { IAirport } from 'src/app/interfaces/airport.interface';
import { AirportService } from 'src/app/services/airports/airports.service';

enum EnumStatus {
  draft = 'draft',
  readyToMerge = 'readyToMerge'
}

interface IHandlingsToMerge {
  status: EnumStatus;
  handlings: IHandling[];
}

@Component({
  selector: 'app-duplicate-handlings',
  templateUrl: './duplicate-handlings.component.html',
  styleUrls: ['./duplicate-handlings.component.scss']
})
export class DuplicateHandlingsComponent implements OnInit, OnDestroy {
  EnumAcl = EnumAcl;

  getHandlingFullAddress = getHandlingFullAddress;

  faEye = faEye;
  faEdit = faEdit;
  faCheckCircle = faCheckCircle;
  faCancel = faCancel;

  loadingHandlings: boolean = false;
  handlings: IHandling[] = [];
  handlingsToMerge: IHandlingsToMerge[] = [];
  analysingDuplicateHandlings: boolean = false;

  airportsObj: { [id: string]: IAirport | null } = {};

  EnumStatus = EnumStatus;

  private subscriptions = new Subscription();

  constructor(
    public paginationService: PaginationService,
    private aclService: AclService,
    private handlingService: HandlingService,
    private alertService: AlertService,
    private loaderService: LoaderService,
    private airportService: AirportService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.aclService.checkAclAccess(EnumAcl.handlingsDuplicate);

    this.loadHandlings();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  loadHandlings(): void {
    this.loadingHandlings = true;

    this.subscriptions.add(
      this.handlingService.getAll().subscribe((handlings: IHandling[]) => {
        this.handlings = handlings;

        this.handlings.sort((a, b) => (a.created < b.created ? -1 : 1));

        for (const handling of this.handlings) {
          this.loadAirport(handling.airportId);
        }

        this.loadingHandlings = false;

        this.analyseDuplicateHandlings();
      })
    );
  }

  analyseDuplicateHandlings(): void {
    this.analysingDuplicateHandlings = true;

    this.handlingsToMerge = [];

    const handlingsByKeys: { [key: string]: IHandling[] } = {};

    const slugifyOptions = {
      replacement: '-', // replace spaces with replacement character, defaults to `-`
      lower: true, // convert to lower case, defaults to `false`
      strict: true, // strip special characters except replacement, defaults to `false`
      trim: true
    };

    for (const handling of this.handlings) {
      const key: string = [handling.airportId, slugify(handling.name, slugifyOptions)].join('_');

      if (typeof handlingsByKeys[key] === 'undefined') {
        handlingsByKeys[key] = [];
      }

      handlingsByKeys[key].push(handling);
    }

    for (const key in handlingsByKeys) {
      if (handlingsByKeys[key].length === 1) {
        delete handlingsByKeys[key];
      } else {
        this.handlingsToMerge.push({
          status: EnumStatus.draft,
          handlings: handlingsByKeys[key]
        });
      }
    }

    this.analysingDuplicateHandlings = false;
  }

  mergeHandlings(i: number): void {
    this.handlingsToMerge[i].status = EnumStatus.readyToMerge;
  }

  cancelMergeHandlings(i: number): void {
    this.handlingsToMerge[i].status = EnumStatus.draft;
  }

  async confirmMergeHandlings(i: number, finalHandlingId: string): Promise<void> {
    this.alertService.presentConfirm(
      'Êtes-vous sûr de votre choix ? Cela supprimera les doublons.',
      async () => {
        console.log(this.handlingsToMerge[i], finalHandlingId);

        this.loaderService.presentLoader();

        try {
          const handlingIds: string[] = [];

          for (const handling of this.handlingsToMerge[i].handlings) {
            if (handling.id !== finalHandlingId) {
              handlingIds.push(handling.id);
            }
          }

          await this.handlingService.mergeHandlings(handlingIds, finalHandlingId);

          await this.loaderService.hideLoaderOnSuccess();
        } catch (err) {
          await this.loaderService.hideLoaderOnFailure(err);
        }
      }
    );
  }

  loadAirport(airportId: string): void {
    if (typeof this.airportsObj[airportId] === 'undefined') {
      this.airportsObj[airportId] = null;

      this.subscriptions.add(
        this.airportService.getFromId(airportId).subscribe((airport: IAirport) => {
          this.airportsObj[airportId] = airport;
        })
      );
    }
  }
}
