import {
  Component,
  OnInit,
  NgZone,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';
import { EnumCurrency } from 'src/app/enums/currency.enum';
import { IFlightBriefCatering } from 'src/app/interfaces/flight-brief-catering.interface';
import { Subscription } from 'rxjs';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { EnumLanguage, getLanguageLabel } from 'src/app/enums/language.enum';
import { FlightBriefCateringService } from 'src/app/services/flight-brief-caterings/flight-brief-caterings.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs/breadcrumbs.service';
import { EnumEnquiryType, getEnumEnquiryTypeLabel } from 'src/app/enums/enquiry-type.enum';

@Component({
  selector: 'app-flight-brief-catering-edit',
  templateUrl: './flight-brief-catering-edit.component.html',
  styleUrls: ['./flight-brief-catering-edit.component.scss']
})
export class FlightBriefCateringEditComponent implements OnInit, OnChanges, OnDestroy {
  @Input() inModal: boolean = false;
  @Input() flightBriefCateringId: string | null = null;

  @Output() dismissModal: EventEmitter<string | null> = new EventEmitter();

  getLanguageLabel = getLanguageLabel;
  getEnumEnquiryTypeLabel = getEnumEnquiryTypeLabel;

  isLogged: boolean = false;
  form: FormGroup = this.resetForm();
  flightBriefCatering: IFlightBriefCatering;

  private subscriptions = new Subscription();

  constructor(
    private remoteService: RemoteService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private aclService: AclService,
    private flightBriefCateringService: FlightBriefCateringService,
    private loaderService: LoaderService,
    private breadcrumbsService: BreadcrumbsService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
  }

  get textByLanguage(): FormGroup {
    return this.form.get('textByLanguage') as FormGroup;
  }

  getTextForLanguage(language: EnumLanguage): FormControl {
    return this.textByLanguage.get(language) as FormControl;
  }

  get enquiryTypes(): FormControl {
    return this.form.get('enquiryTypes') as FormControl;
  }

  getEnquiryTypes(): EnumEnquiryType[] {
    return Object.values(EnumEnquiryType);
  }

  ngOnInit() {
    this.form.disable();

    this.activatedRoute.params.subscribe(async () => {
      this.flightBriefCateringId =
        this.activatedRoute.snapshot.paramMap.get('flightBriefCateringId');

      if (this.flightBriefCateringId) {
        await this.aclService.checkAclAccess(EnumAcl.flightBriefCateringsEdit);
        this.loadData();
      } else {
        await this.aclService.checkAclAccess(EnumAcl.flightBriefCateringsAdd);

        this.toggleAllEnquiryType();

        this.form.enable();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.flightBriefCateringId) {
      this.loadData();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getCurrencies(): EnumCurrency[] {
    return Object.values(EnumCurrency);
  }

  getLanguages(): EnumLanguage[] {
    return Object.values(EnumLanguage);
  }

  loadData(): void {
    if (this.isLogged) {
      this.loadFlightBriefCatering();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  loadFlightBriefCatering(): void {
    this.subscriptions.add(
      this.flightBriefCateringService
        .getFromId(this.flightBriefCateringId)
        .subscribe((flightBriefCatering: IFlightBriefCatering) => {
          this.flightBriefCatering = flightBriefCatering;

          if (this.flightBriefCatering) {
            this.breadcrumbsService.setCurrentItem({
              id: this.flightBriefCatering.id,
              text: this.flightBriefCatering.textByLanguage
                ? this.flightBriefCatering.textByLanguage[EnumLanguage.fr]
                : ''
            });
          }

          this.setValueToForm();
        })
    );
  }

  setValueToForm(): void {
    if (this.form && this.flightBriefCatering) {
      this.form = this.resetForm();

      for (const field in this.form.value) {
        if (typeof this.flightBriefCatering[field] !== 'undefined') {
          this.form.get(field).setValue(this.flightBriefCatering[field]);
        }
      }

      this.form.enable();
    }
  }

  submitForm(): void {
    this.form.markAsTouched();

    if (this.form.valid) {
      this.loaderService.presentLoader();

      let data = Object.assign({}, this.form.value);

      for (const field in data) {
        if (typeof data[field] == 'undefined') {
          data[field] = null;
        }
      }

      this.form.enable();

      data = this.fixUndefinedValues(data);

      let promise;
      if (this.flightBriefCateringId) {
        data.id = this.flightBriefCateringId;
        promise = () => this.flightBriefCateringService.update(data);
      } else {
        promise = () => this.flightBriefCateringService.create(data);
      }

      promise()
        .then(async id => {
          await this.loaderService.hideLoaderOnSuccess();

          if (!this.flightBriefCateringId) {
            this.flightBriefCateringId = id;

            if (this.inModal) {
              this.closeModal(this.flightBriefCateringId);
            }
          } else {
            if (this.inModal) {
              this.closeModal();
            }
          }

          if (!this.inModal) {
            this.redirectAfterSaving();
          }
        })
        .catch(async (err: any) => {
          await this.loaderService.hideLoaderOnFailure(err.message);
        })
        .finally(() => {
          this.form.enable();
        });
    }
  }

  fixUndefinedValues(data: IFlightBriefCatering): IFlightBriefCatering {
    for (const field in data) {
      if (Array.isArray(data[field]) || typeof data[field] === 'object') {
        data[field] = this.fixUndefinedValues(data[field]);
      } else if (typeof data[field] === 'undefined') {
        data[field] = null;
      }
    }

    return data;
  }

  redirectAfterSaving(): void {
    this.router.navigate(['/admin/flight-brief-caterings/' + this.flightBriefCateringId]);
  }

  resetForm(): FormGroup {
    const textByLanguageFormGroup: FormGroup = new FormGroup({});

    for (const language of Object.values(EnumLanguage)) {
      textByLanguageFormGroup.addControl(language, new FormControl(null, Validators.required));
    }

    return new FormGroup({
      textByLanguage: textByLanguageFormGroup,
      position: new FormControl(1, [Validators.required]),
      enquiryTypes: new FormControl([])
    });
  }

  closeModal(newId: string | null = null): void {
    this.dismissModal.emit(newId);
  }

  toggleEnquiryType(enquiryType: EnumEnquiryType): void {
    const values: EnumEnquiryType[] = [...this.form.value.enquiryTypes];
    const index: number = values.indexOf(enquiryType);

    if (index === -1) {
      values.push(enquiryType);
    } else {
      values.splice(index, 1);
    }

    this.form.get('enquiryTypes').setValue(values);
    this.form.get('enquiryTypes').updateValueAndValidity();
  }

  toggleAllEnquiryType(): void {
    const values: EnumEnquiryType[] = [];

    if (this.form.value.enquiryTypes.length !== this.getEnquiryTypes().length) {
      for (const enquiryType of this.getEnquiryTypes()) {
        values.push(enquiryType);
      }
    }

    this.form.get('enquiryTypes').setValue(values);
    this.form.get('enquiryTypes').updateValueAndValidity();
  }
}
