import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  faArrowLeft,
  faArrowRight,
  faBox,
  faTicket,
  faUsers
} from '@fortawesome/free-solid-svg-icons';
import { EnumEnquiryType } from 'src/app/enums/enquiry-type.enum';
import { IEnquiryFlight } from 'src/app/interfaces/enquiry-flight.interface';
import { IEnquiry } from 'src/app/interfaces/enquiry.interface';

@Component({
  selector: 'app-enquiry-flight-edit-cargo',
  templateUrl: './enquiry-flight-edit-cargo.component.html',
  styleUrls: ['./enquiry-flight-edit-cargo.component.scss']
})
export class EnquiryFlightEditCargoComponent implements OnInit, OnChanges, OnDestroy {
  @Input() enquiry: IEnquiry | null = null;
  @Input() enquiryFlight: IEnquiryFlight | null = null;
  @Output() goPrev: EventEmitter<void> = new EventEmitter();
  @Output() saveAndGoNext: EventEmitter<IEnquiryFlight> = new EventEmitter();

  form: FormGroup = this.resetForm();

  faArrowLeft = faArrowLeft;
  faArrowRight = faArrowRight;
  faBox = faBox;

  EnumEnquiryType = EnumEnquiryType;

  ngOnInit(): void {
    this.applyEnquiryFlight();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['enquiryFlight'] &&
      (!changes['enquiryFlight'].previousValue ||
        changes['enquiryFlight'].previousValue !== changes['enquiryFlight'].currentValue)
    ) {
      this.applyEnquiryFlight();
    }
  }

  ngOnDestroy(): void {
    window['$']('.tooltip').remove();
  }

  get weight(): FormControl {
    return this.form.get('weight') as FormControl;
  }

  get volume(): FormControl {
    return this.form.get('volume') as FormControl;
  }

  get deliveryCargoHoursBeforeDeparture(): FormControl {
    return this.form.get('deliveryCargoHoursBeforeDeparture') as FormControl;
  }

  get typeOfCargo(): FormControl {
    return this.form.get('typeOfCargo') as FormControl;
  }

  get weightInKg(): FormControl {
    return this.form.get('weightInKg') as FormControl;
  }

  get volumeInM3(): FormControl {
    return this.form.get('volumeInM3') as FormControl;
  }

  get nbPackages(): FormControl {
    return this.form.get('nbPackages') as FormControl;
  }

  get awbNumber(): FormControl {
    return this.form.get('awbNumber') as FormControl;
  }

  get customDeclaration(): FormControl {
    return this.form.get('customDeclaration') as FormControl;
  }

  get dgd(): FormControl {
    return this.form.get('dgd') as FormControl;
  }

  applyEnquiryFlight(): void {
    this.form = this.resetForm();

    if (this.enquiryFlight) {
      for (const field in this.form.value) {
        if (typeof this.enquiryFlight[field] !== 'undefined') {
          switch (field) {
            default:
              this.form.get(field).setValue(this.enquiryFlight[field]);
              break;
          }
        }
      }

      this.form.enable();
    }
  }

  resetForm(): FormGroup {
    return new FormGroup({
      weight: new FormControl(null),
      volume: new FormControl(null),
      deliveryCargoHoursBeforeDeparture: new FormControl(2),
      typeOfCargo: new FormControl(null),
      weightInKg: new FormControl(null),
      volumeInM3: new FormControl(null),
      nbPackages: new FormControl(null),
      awbNumber: new FormControl(null),
      customDeclaration: new FormControl(null),
      dgd: new FormControl(false)
    });
  }

  prev(): void {
    this.goPrev.emit();
  }

  saveAndNext(): void {
    if (this.form.valid) {
      this.saveAndGoNext.emit(this.form.value);
    }
  }
}
