import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { faArrowLeft, faArrowRight, faTicket } from '@fortawesome/free-solid-svg-icons';
import { IEnquiryFlight } from 'src/app/interfaces/enquiry-flight.interface';
import { IEnquiry } from 'src/app/interfaces/enquiry.interface';

@Component({
  selector: 'app-enquiry-flight-edit-details',
  templateUrl: './enquiry-flight-edit-details.component.html',
  styleUrls: ['./enquiry-flight-edit-details.component.scss']
})
export class EnquiryFlightEditDetailsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() enquiry: IEnquiry | null = null;
  @Input() enquiryFlight: IEnquiryFlight | null = null;
  @Output() goPrev: EventEmitter<void> = new EventEmitter();
  @Output() saveAndGoNext: EventEmitter<IEnquiryFlight> = new EventEmitter();

  form: FormGroup = this.resetForm();

  faArrowLeft = faArrowLeft;
  faArrowRight = faArrowRight;
  faTicket = faTicket;

  ngOnInit(): void {
    this.applyEnquiryFlight();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['enquiryFlight'] &&
      (!changes['enquiryFlight'].previousValue ||
        changes['enquiryFlight'].previousValue !== changes['enquiryFlight'].currentValue)
    ) {
      this.applyEnquiryFlight();
    }
  }

  ngOnDestroy(): void {
    window['$']('.tooltip').remove();
  }

  get isFerryFlight(): FormControl {
    return this.form.get('isFerryFlight') as FormControl;
  }

  get flightNumber(): FormControl {
    return this.form.get('flightNumber') as FormControl;
  }

  get flightradar24Url(): FormControl {
    return this.form.get('flightradar24Url') as FormControl;
  }

  get adsbexchangeUrl(): FormControl {
    return this.form.get('adsbexchangeUrl') as FormControl;
  }

  applyEnquiryFlight(): void {
    this.form = this.resetForm();

    if (this.enquiryFlight) {
      for (const field in this.form.value) {
        if (typeof this.enquiryFlight[field] !== 'undefined') {
          switch (field) {
            default:
              this.form.get(field).setValue(this.enquiryFlight[field]);
              break;
          }
        }
      }

      this.form.enable();
    }
  }

  resetForm(): FormGroup {
    return new FormGroup({
      flightNumber: new FormControl(null),
      flightradar24Url: new FormControl(null),
      adsbexchangeUrl: new FormControl(null),
      isFerryFlight: new FormControl(false)
    });
  }

  prev(): void {
    this.goPrev.emit();
  }

  saveAndNext(): void {
    if (this.form.valid) {
      this.saveAndGoNext.emit(this.form.value);
    }
  }
}
