import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import html2pdf from 'html2pdf.js';

import { PaginationService } from '../../../services/pagination.service';
import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';
import { PipedriveService } from 'src/app/services/pipedrive.service';

import moment from 'moment';

import countries from '../../../countries_fr.json';
import { TranslateService } from '@ngx-translate/core';
import {
  getDisplayedEnquiryRefTitle,
  getEnquiryBreadcrumbTitle,
  IEnquiry
} from 'src/app/interfaces/enquiry.interface';
import { Subscription } from 'rxjs';
import { EnquiryService } from 'src/app/services/enquiry/enquiry.service';
import { EnumInvoiceType } from 'src/app/enums/invoice-type.enum';
import { IEncaissement } from 'src/app/interfaces/encaissement.interface';
import { EncaissementService } from 'src/app/services/encaissements/encaissements.service';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { EnumInvoiceStatus, getEnumInvoiceStatusLabel } from 'src/app/enums/invoice-status.enum';
import { IUser } from 'src/app/interfaces/user.interface';
import { UserService } from 'src/app/services/user/user.service';
import { InvoiceService } from 'src/app/services/invoices/invoices.service';
import { getInvoiceTitle, IInvoice } from 'src/app/interfaces/invoice.interface';
import { getEnumTvaRateLabel } from 'src/app/enums/tva-rates.enum';
import { EnumLanguage, getInvoiceLanguageForTranslation } from 'src/app/enums/language.enum';

import * as PDFObject from 'pdfobject';
import { IPipedriveOrganization } from 'src/app/interfaces/pipedrive.interface';
import { IAirport } from 'src/app/interfaces/airport.interface';
import { IUserGroup } from 'src/app/interfaces/user-group.interface';
import { IBankAccount } from 'src/app/interfaces/bank-account.interface';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs/breadcrumbs.service';

@Component({
  selector: 'app-invoice-view',
  templateUrl: './invoice-view.component.html',
  styleUrls: ['./invoice-view.component.scss']
})
export class InvoiceViewComponent implements OnInit, OnDestroy {
  @ViewChild('pdfViewerEmbed', { static: false }) pdfViewerEmbedElement: ElementRef;

  getEnquiryBreadcrumbTitle = getEnquiryBreadcrumbTitle;
  getEnumInvoiceStatusLabel = getEnumInvoiceStatusLabel;
  getDisplayedEnquiryRefTitle = getDisplayedEnquiryRefTitle;
  getInvoiceTitle = getInvoiceTitle;
  getEnumTvaRateLabel = getEnumTvaRateLabel;

  faRefresh = faRefresh;

  EnumInvoiceType = EnumInvoiceType;
  EnumInvoiceStatus = EnumInvoiceStatus;
  EnumAcl = EnumAcl;

  rootUrl: string = './';
  loading: boolean = false;
  invoiceId: string;
  invoice: IInvoice | null = null;
  isLogged: boolean = false;
  enquiry: IEnquiry;
  client: IPipedriveOrganization | null = null;
  requestingUser: IUser;

  generatingPdf: boolean = false;

  airportsObj: { [key: string]: IAirport | null } = {};
  airportsToLoad: string[] = [];
  usersObj: { [key: string]: IUser } = {};
  userGroup: IUserGroup | null = null;
  countries = countries;

  referalInvoice: IInvoice | null = null;

  sentToClientList: {
    title: string;
    value: boolean;
  }[] = [
    {
      title: 'Oui',
      value: true
    },
    {
      title: 'Non',
      value: false
    }
  ];
  sentToClientObj: object = {};
  translationObj: object = {};

  bankAccount: IBankAccount | null = null;
  encaissements: IEncaissement[] = [];

  loadingGeneratedPdf: boolean = false;

  generatedPdfUrl: string | null = null;

  subscriptions = new Subscription();

  constructor(
    private remoteService: RemoteService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public paginationService: PaginationService,
    private aclService: AclService,
    private pipedriveService: PipedriveService,
    private translateService: TranslateService,
    private enquiryService: EnquiryService,
    private encaissementService: EncaissementService,
    private userService: UserService,
    private invoiceService: InvoiceService,
    private loaderService: LoaderService,
    private breadcrumbsService: BreadcrumbsService
  ) {
    this.rootUrl = window['rootUrl'];

    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
    this.remoteService.userGroupObservable.subscribe(
      (userGroup: IUserGroup | null) => (this.userGroup = userGroup)
    );
  }

  ngOnInit(): void {
    this.activatedRoute.url.subscribe(() => {
      this.invoiceId = this.activatedRoute.snapshot.paramMap.get('invoiceId');

      this.loadData();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getInvoiceStatuses(): EnumInvoiceStatus[] {
    return Object.values(EnumInvoiceStatus);
  }

  loadData(): void {
    if (this.isLogged) {
      this.loadInvoice();
      this.loadEncaissements();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  async loadInvoice(): Promise<void> {
    if (this.invoiceId) {
      this.loading = true;

      this.subscriptions.add(
        this.invoiceService.getFromId(this.invoiceId).subscribe(async (invoice: IInvoice) => {
          this.invoice = invoice;

          if (this.invoice) {
            this.loadTranslations(getInvoiceLanguageForTranslation(this.invoice.language));

            await this.loadClient();

            if (this.invoice.enquiryId) {
              await this.loadEnquiry();
            }
            await this.loadRequestingUser();
            await this.loadReferalInvoice();

            await this.loadBankAccount();

            setTimeout(() => {
              this.initPDFViewer();
            }, 1000);
          }

          this.loading = false;
        })
      );
    }
  }

  loadTranslations(language: EnumLanguage): void {
    if (typeof this.translationObj[language] === 'undefined') {
      this.translateService.getTranslation(language).subscribe((translationObj: object) => {
        this.translationObj[language] = translationObj;
      });
    }
  }

  async loadReferalInvoice(): Promise<void> {
    if (this.invoice?.referalInvoiceId) {
      const doc = await this.remoteService.getDocument('invoices', this.invoice.referalInvoiceId);

      this.referalInvoice = doc as IInvoice;
    }
  }

  loadEnquiry(): void {
    if (this.invoice.enquiryId) {
      this.subscriptions.add(
        this.enquiryService
          .getFromId(this.invoice.enquiryId)
          .subscribe(async (enquiry: IEnquiry) => {
            this.enquiry = enquiry;

            if (this.enquiry) {
              this.breadcrumbsService.setCurrentItem({
                id: this.enquiry.id,
                text: getEnquiryBreadcrumbTitle(this.enquiry)
              });
            }

            await this.loadUsers();

            await this.loadAirportsOfEnquiry();
          })
      );
    }
  }

  async loadAirportsOfEnquiry(): Promise<void> {
    for (const itinerary of this.enquiry.itineraries) {
      for (const trip of itinerary.trips) {
        for (const field of [
          'airportDepart',
          'airportDestination',
          'commercialStopOverAirport',
          'fuelStopOverAirport'
        ]) {
          if (trip[field]) {
            this.addAirportToLoad(trip[field]);
          }
        }
      }
    }

    await this.loadAirports();
  }

  addAirportToLoad(airportId: string) {
    if (
      this.airportsToLoad.indexOf(airportId) === -1 &&
      typeof this.airportsObj[airportId] === 'undefined'
    ) {
      this.airportsToLoad.push(airportId);
    }
  }

  async loadAirports(): Promise<void> {
    if (this.airportsToLoad.length) {
      const docs: object[] = await this.remoteService.getDocumentsFromDocId(
        'airports',
        this.airportsToLoad
      );

      for (const doc of docs) {
        const airport = doc as IAirport;

        this.airportsObj[airport.id] = airport;
      }

      this.airportsToLoad = [];
    }
  }

  async loadUsers(): Promise<void> {
    const usersId: string[] = [];

    if (this.enquiry) {
      for (const field of ['receivedBy', 'processedBy']) {
        if (usersId.indexOf(this.enquiry[field]) === -1) {
          usersId.push(this.enquiry[field]);
        }
      }
    }

    if (usersId.length) {
      const docs: object[] = await this.remoteService.getDocumentsFromDocId('users', usersId);

      for (const doc of docs) {
        this.usersObj[doc['id']] = doc as IUser;
      }
    }
  }

  delete(): void {
    if (this.hasAclAccess(EnumAcl.invoicesDelete)) {
      const result = confirm(
        'La suppression de la facture sera permanente. Êtes-vous sûr de vouloir continuer ?'
      );

      if (result) {
        this.remoteService
          .deleteDocumentInCollection('invoices', this.invoiceId)
          .then(() => {
            if (this.invoice?.enquiryId) {
              this.router.navigate(['/admin/invoices/enquiry/' + this.invoice.enquiryId]);
            } else {
              this.router.navigate(['/admin/invoices']);
            }

            alert('La suppression a été effectuée avec succès.');
          })
          .catch(err => {
            console.log(err);
          });
      }
    }
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  changeStatus(newStatus: string): void {
    if (this.hasAclAccess(EnumAcl.invoicesSwitchStatus)) {
      this.loading = false;

      this.remoteService
        .updateDocumentToCollection('invoices', this.invoiceId, {
          status: newStatus
        })
        .then(async () => {
          await this.loadInvoice();

          this.loading = false;
        })
        .catch(err => {
          this.loading = false;

          alert(err.message);
        });
    }
  }

  changeSentToClient(sentToClient: boolean): void {
    this.loading = false;

    this.remoteService
      .updateDocumentToCollection('invoices', this.invoiceId, {
        sentToClient: sentToClient
      })
      .then(async () => {
        await this.loadInvoice();

        this.loading = false;
      })
      .catch(err => {
        this.loading = false;

        alert(err.message);
      });
  }

  getReadableSize(size: number): string {
    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let i = 0;
    while (size >= 1024) {
      size /= 1024;
      ++i;
    }

    return size.toFixed(1) + ' ' + units[i];
  }

  formatPrice(value: number): string {
    const formatter = new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: this.invoice.currency,
      minimumFractionDigits: 2
    });

    return formatter.format(value);
  }

  async loadClient(): Promise<void> {
    if (this.invoice.clientId) {
      this.loading = true;

      this.client = await this.pipedriveService.getOrganization(this.invoice.clientId);

      this.loading = false;
    }
  }

  async loadRequestingUser(): Promise<void> {
    if (this.invoice.requestedBy) {
      this.loading = true;

      this.subscriptions.add(
        this.userService.getFromId(this.invoice.requestedBy).subscribe(async (user: IUser) => {
          this.requestingUser = user;

          this.loading = false;
        })
      );
    }
  }

  async generatePdf(): Promise<void> {
    if (this.invoice.generatedPdfPath) {
      this.generatingPdf = true;

      if (!this.generatedPdfUrl) {
        this.loadingGeneratedPdf = true;

        this.generatedPdfUrl = await this.invoiceService.getInvoicePdfUrl(this.invoice.id);

        this.loadingGeneratedPdf = false;
      }

      if (this.generatedPdfUrl) {
        window.open(this.generatedPdfUrl, '_blank');
      }

      this.generatingPdf = false;
    } else {
      this.generatingPdf = true;

      setTimeout(() => {
        moment.locale('fr');

        let filename: string = '';
        if (this.invoice.ref) {
          filename += this.invoice.ref;
        }
        if (this.enquiry) {
          filename += ' - ' + getDisplayedEnquiryRefTitle(this.enquiry, 'refContract');
        }
        filename += ' - ' + this.client.name;

        filename += ' - ' + moment(this.invoice.created).format('YYYY-MM-DD');

        const element = document.getElementById('pdf-content');
        html2pdf()
          .set({
            margin: [15, 0, 0, 0],
            pagebreak: { after: '.page-break' },
            html2canvas: {
              dpi: 192,
              scale: 3,
              letterRendering: true
            },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
          })
          .from(element)
          .toPdf()
          .get('pdf', pdf => {
            var totalPages = pdf.internal.getNumberOfPages();

            pdf.setFontSize(10);

            const footerHeight: number = 15;
            const footerTop: number = pdf.internal.pageSize.getHeight() - footerHeight;
            const footerRight: number = pdf.internal.pageSize.getWidth() - 25;

            for (let i = 1; i <= totalPages; i++) {
              pdf.setPage(i);

              pdf.line(10, footerTop, pdf.internal.pageSize.getWidth() - 10, footerTop);

              pdf.text(footerRight, footerTop + 5, 'Page ' + i + '/' + totalPages);

              pdf.text(
                pdf.internal.pageSize.getWidth() / 2,
                footerTop + 5,
                this.getDefaultStringTranslation('INVOICE.FOOTER_TEXT.COMPANY_INFO'),
                { align: 'center' }
              );

              pdf.text(
                pdf.internal.pageSize.getWidth() / 2,
                footerTop + 5 * 2,
                this.getDefaultStringTranslation('INVOICE.FOOTER_TEXT.TVA_INTRA') +
                  ': FR14753883354',
                { align: 'center' }
              );
            }
          })
          .save(filename + '.pdf');

        setTimeout(() => {
          this.generatingPdf = false;
        }, 1000);
      }, 1000);
    }
  }

  getRouting(): string {
    const airportsCode: string[] = [];

    if (this.enquiry) {
      for (const itinerary of this.enquiry.itineraries) {
        for (const trip of itinerary.trips) {
          for (const field of ['airportDepart', 'airportDestination']) {
            if (airportsCode[airportsCode.length - 1] !== trip[field]) {
              airportsCode.push(this.airportsObj[trip[field]].title);
            }
          }
        }
      }
    }

    return airportsCode.join('-');
  }

  getDates(): string {
    moment.locale('fr');

    const datesDepart: string[] = [];
    if (this.enquiry) {
      for (const itinerary of this.enquiry.itineraries) {
        for (const trip of itinerary.trips) {
          const date: string = trip.date;

          if (datesDepart.indexOf(date) === -1) {
            datesDepart.push(date);
          }
        }
      }
      datesDepart.sort();

      for (const i in datesDepart) {
        datesDepart[i] = moment(datesDepart[i]).format('DD MMMM YYYY').toUpperCase();
      }
    }

    return datesDepart.join(' - ');
  }

  formatTripDate(date: Date): string {
    moment.locale('fr');

    return moment(date).format('ddd DD MMM YYYY').toUpperCase();
  }

  getCotationCacheDetail(): string {
    moment.locale('fr');

    let text = '';

    const dates: string[] = [];
    const routing: string[] = [];
    const airlineTitles: string[] = [];
    const aircraftModels: string[] = [];

    if (this.invoice.cotationsId) {
      for (const cotationId of this.invoice.cotationsId) {
        for (const cotationsCachedInfo of this.invoice.cotationsCachedInfos) {
          if (cotationsCachedInfo.cotationId === cotationId) {
            dates.push(cotationsCachedInfo.dates);
            routing.push(cotationsCachedInfo.routing);
            airlineTitles.push(cotationsCachedInfo.airlineTitle);
            aircraftModels.push(cotationsCachedInfo.aircraftModel);
          }
        }
      }
    }

    text +=
      this.getTranslation('INVOICE.COTATION_INFOS.DATE') + ' : ' + dates.join(' + ') + '<br />';
    text +=
      this.getTranslation('INVOICE.COTATION_INFOS.ROUTING') +
      ' : ' +
      routing.join(' + ') +
      '<br />';
    if (this.invoice.displayCotationAirlineAndAircraft) {
      text +=
        this.getTranslation('INVOICE.COTATION_INFOS.AIRLINE') +
        ' : ' +
        airlineTitles.join(' + ') +
        '<br />';
      text +=
        this.getTranslation('INVOICE.COTATION_INFOS.AIRCRAFT') + ' : ' + aircraftModels.join(' + ');
    }

    return text;
  }

  getTranslation(path: string, language: EnumLanguage = null): string {
    if (!language) {
      language = getInvoiceLanguageForTranslation(this.invoice.language);
    }

    if (language) {
      if (this.translationObj[language]) {
        return this.getStringTranslation(language, path);
      }
    } else {
      return path;
    }
  }

  loadLanguageTranslation(language: EnumLanguage): Promise<void> {
    return new Promise((resolve, reject) => {
      this.translateService.getTranslation(language).subscribe((translationObj: object) => {
        this.translationObj[language] = translationObj;

        resolve();
      });
    });
  }

  capitalizeFirstLetter(val: string): string {
    return val.charAt(0).toUpperCase() + val.slice(1);
  }

  getDefaultStringTranslation(path: string): string {
    return this.getStringTranslation(getInvoiceLanguageForTranslation(this.invoice.language), path);
  }

  getStringTranslation(language: EnumLanguage, path: string): string {
    if (this.translationObj[language]) {
      let translation: object | string = Object.assign({}, this.translationObj[language]);
      const splittedPath: string[] = path.split('.');

      for (const onePath of splittedPath) {
        if (translation[onePath]) {
          translation = translation[onePath];
        } else {
          translation = path;
          break;
        }
      }

      return translation.toString();
    }
  }

  async loadBankAccount(): Promise<void> {
    if (this.invoice?.bankAccount) {
      const doc = await this.remoteService.getDocument('bankAccounts', this.invoice.bankAccount);

      this.bankAccount = doc as IBankAccount;
    }
  }

  async loadEncaissements(): Promise<void> {
    if (this.invoiceId) {
      this.subscriptions.add(
        this.encaissementService
          .getAllForInvoice(this.invoiceId)
          .subscribe((encaissements: IEncaissement[]) => {
            this.encaissements = encaissements;
          })
      );
    }
  }

  async initPDFViewer(): Promise<void> {
    if (this.pdfViewerEmbedElement?.nativeElement && this.invoice) {
      if (this.invoice.document?.url) {
        PDFObject.embed(this.invoice.document.url, this.pdfViewerEmbedElement.nativeElement);
      } else if (this.invoice.generatedPdfPath) {
        this.loadingGeneratedPdf = true;

        this.generatedPdfUrl = await this.invoiceService.getInvoicePdfUrl(this.invoice.id);

        this.loadingGeneratedPdf = false;

        if (this.generatedPdfUrl && this.pdfViewerEmbedElement) {
          setTimeout(() => {
            PDFObject.embed(this.generatedPdfUrl, this.pdfViewerEmbedElement.nativeElement);
          }, 300);
        }
      }
    }
  }

  async regenerateInvoicePdf(): Promise<void> {
    await this.loaderService.presentLoader();

    this.generatedPdfUrl = await this.invoiceService.regenerateInvoicePdf(this.invoice.id);

    if (this.generatedPdfUrl) {
      setTimeout(() => {
        PDFObject.embed(this.generatedPdfUrl, this.pdfViewerEmbedElement.nativeElement);
      }, 300);
    }

    await this.loaderService.hideLoaderOnSuccess();
  }
}
