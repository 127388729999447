import { Component, OnInit } from '@angular/core';

import { PaginationService } from '../../../services/pagination.service';
import { AclService } from '../../../services/acl.service';

import countries from '../../../countries_fr.json';

import { EnumAcl } from 'src/app/enums/acl.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { IHandling, getHandlingFullAddress } from 'src/app/interfaces/handling.interface';
import { IAirport } from 'src/app/interfaces/airport.interface';
import { Subscription } from 'rxjs';
import { AirportService } from 'src/app/services/airports/airports.service';

@Component({
  selector: 'app-list-handlings',
  templateUrl: './list-handlings.component.html',
  styleUrls: ['./list-handlings.component.scss']
})
export class ListHandlingsComponent implements OnInit {
  EnumAcl = EnumAcl;

  getHandlingFullAddress = getHandlingFullAddress;

  currentPagination: string = 'handlings-list';
  countriesList: Array<{
    title: string;
    value: string;
  }> = [];
  handlings: IHandling[] = [];
  airportId: string | null = null;
  airportsObj: { [id: string]: IAirport | null } = {};

  private subscriptions = new Subscription();

  constructor(
    public paginationService: PaginationService,
    private aclService: AclService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private airportService: AirportService
  ) {
    for (const code in countries) {
      this.countriesList.push({
        title: countries[code],
        value: code
      });
    }
  }

  async ngOnInit(): Promise<void> {
    this.activatedRoute.url.subscribe(async () => {
      this.airportId = this.activatedRoute.snapshot.paramMap.get('airportId');

      await this.aclService.checkAclAccess(EnumAcl.handlingsList);

      if (this.airportId) {
        this.loadAirport(this.airportId);
      }

      this.paginationService.pagination[this.currentPagination].currentPage = 0;

      this.fetchData();
    });
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  async fetchData(): Promise<void> {
    this.paginationService.pagination[this.currentPagination].conditions = [];

    if (this.airportId) {
      this.paginationService.pagination[this.currentPagination].conditions.push({
        field: 'airportId',
        operator: '==',
        value: this.airportId
      });
    }

    await this.paginationService.fetchData(this.currentPagination);
  }

  async setValueToFormControl($event: {
    fields: {
      name: string;
      value: any;
    }[];
  }): Promise<void> {
    for (let field of $event.fields) {
      if (field.name === 'handlingId') {
        this.router.navigateByUrl('/admin/handlings/' + field.value);
      }
    }
  }

  loadAirport(airportId: string): void {
    if (typeof this.airportsObj[airportId] === 'undefined') {
      this.airportsObj[airportId] = null;

      this.subscriptions.add(
        this.airportService.getFromId(airportId).subscribe((airport: IAirport | null) => {
          this.airportsObj[airportId] = airport;
        })
      );
    }
  }
}
