import { Component, OnDestroy, OnInit } from '@angular/core';

import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';
import { EnumAcl } from 'src/app/enums/acl.enum';
import * as XLSX from 'xlsx';
import { faCancel, faCheck, faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { IAirline } from 'src/app/interfaces/airline.interface';
import { AirlineService } from 'src/app/services/airlines/airlines.service';
import { Subscription } from 'rxjs';
import { chunk, generateRandomId } from 'src/app/misc.utils';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { IBatchOperationsParams } from 'src/app/services/firestore/firestore.service';
import { NgxCsvParser } from 'ngx-csv-parser';
import { IContact } from 'src/app/interfaces/contact.interface';
import countries from '../../../countries_fr.json';
import { EnumEnquiryType, getEnumEnquiryTypeLabel } from 'src/app/enums/enquiry-type.enum';

const headers: string[] = [
  'Nom compagnie',
  'Pays',
  'Groupe de contact',
  'Prénom',
  'Nom',
  'Fonction',
  'Email'
];

enum EnumImportDataErrorMsg {
  empty = 'empty',
  numberOfHeadersDifferent = 'numberOfHeadersDifferent',
  columnsTitleAreDifferent = 'columnsTitleAreDifferent',
  airlineNameEmpty = 'airlineNameEmpty',
  airlineCountryMissing = 'airlineCountryMissing',
  contactGroupNameMissing = 'contactGroupNameMissing',
  emailMissing = 'emailMissing'
}

interface IDataToImport {
  headers: string[];
  lines: string[][];
  errorsByLine: { [lineIndex: string]: EnumImportDataErrorMsg[] };
}

interface IContactToImport {
  contact: IContact;
  airlineTitleAndCountryCode: string;
  airlineId: string | null;
  airlineTitle: string;
  airlineCountryCode: string;
  companyContactName: string;
}

@Component({
  selector: 'app-import-airline-contacts',
  templateUrl: './import-airline-contacts.component.html',
  styleUrls: ['./import-airline-contacts.component.scss']
})
export class ImportAirlineContactsComponent implements OnInit, OnDestroy {
  isLogged: boolean = false;

  faCheck = faCheck;
  faCancel = faCancel;
  faExternalLink = faExternalLink;

  EnumAcl = EnumAcl;
  EnumImportDataErrorMsg = EnumImportDataErrorMsg;

  getEnumEnquiryTypeLabel = getEnumEnquiryTypeLabel;

  countries = countries;

  fileContentLoaded = false;
  currentFile: File;
  dataValid: boolean = false;
  errorInvalidData: EnumImportDataErrorMsg | null = null;
  data: IDataToImport = {
    headers: [],
    lines: [],
    errorsByLine: {}
  };

  contactsReadyToImport: number = 0;
  contactsToImport: IContactToImport[] = [];
  fetchingRelatedData: boolean = false;

  airlinesByTitleAndCountry: { [titleAndCountry: string]: IAirline | null } = {};
  airlinesByTitleAndCountryLoaded: { [titleAndCountry: string]: boolean } = {};

  selectedEnquiryTypes: EnumEnquiryType[] = [];

  private subscriptions = new Subscription();

  constructor(
    public remoteService: RemoteService,
    private aclService: AclService,
    private airlineService: AirlineService,
    private loaderService: LoaderService,
    private ngxCsvParser: NgxCsvParser
  ) {}

  async ngOnInit(): Promise<void> {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );

    await this.aclService.checkAclAccess(EnumAcl.aircraftsImport);

    this.loadData();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getEnquiryTypes(): EnumEnquiryType[] {
    return Object.values(EnumEnquiryType);
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  async loadData(): Promise<void> {
    if (this.isLogged) {
      // this.fetchData()
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  changeInputFile(fileInput: any): void {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.currentFile = fileInput.target.files[0];

      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.fileContentLoaded = false;

        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellDates: true });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        this.data = this.excelContentToJson(XLSX.utils.sheet_to_csv(ws));

        this.checkIfDataAreValid();

        this.fileContentLoaded = true;

        if (this.dataValid) {
          this.getAirlinesToImportFromData();
        }
      };

      reader.readAsText(this.currentFile);
    }
  }

  private excelContentToJson(data: string): IDataToImport {
    const dataParsed: any[][] = this.ngxCsvParser.csvStringToArray(data, ',');

    const result: IDataToImport = {
      headers: [],
      lines: [],
      errorsByLine: {}
    };

    for (let i = 0; i < dataParsed.length; i++) {
      if (i === 0) {
        result.headers = dataParsed[i];
      } else if (i > 0) {
        const cells: string[] = dataParsed[i];

        if (cells[0]) {
          result.lines.push(cells);
        }
      }
    }

    return result;
  }

  checkIfDataAreValid(): void {
    this.dataValid = true;
    this.errorInvalidData = null;

    if (this.data?.headers?.length) {
      if (this.data.headers.length !== headers.length) {
        this.dataValid = false;
        this.errorInvalidData = EnumImportDataErrorMsg.numberOfHeadersDifferent;
      } else {
        for (let i = 0; i < this.data.headers.length; i++) {
          if (this.data.headers[i] !== headers[i]) {
            this.dataValid = false;
            this.errorInvalidData = EnumImportDataErrorMsg.columnsTitleAreDifferent;
            break;
          }
        }
      }
    } else {
      this.dataValid = false;
      this.errorInvalidData = EnumImportDataErrorMsg.empty;
    }

    if (this.dataValid) {
      // Check data now
      for (let i = 0; i < this.data.lines.length; i++) {
        if (typeof this.data.errorsByLine[i] === 'undefined') {
          this.data.errorsByLine[i] = [];
        }

        if (!this.data.lines[i][0]) {
          this.data.errorsByLine[i].push(EnumImportDataErrorMsg.airlineNameEmpty);
        }

        if (!this.data.lines[i][1]) {
          this.data.errorsByLine[i].push(EnumImportDataErrorMsg.airlineCountryMissing);
        }

        if (!this.data.lines[i][2]) {
          this.data.errorsByLine[i].push(EnumImportDataErrorMsg.contactGroupNameMissing);
        }

        if (!this.data.lines[i][6]) {
          this.data.errorsByLine[i].push(EnumImportDataErrorMsg.emailMissing);
        }

        if (this.data.errorsByLine[i].length) {
          this.dataValid = false;
        }
      }
    }
  }

  getAirlinesToImportFromData(): void {
    if (this.data) {
      const importId: string = generateRandomId();
      const importDate: Date = new Date();

      this.contactsToImport = [];

      for (const line of this.data.lines) {
        const titleAndCountryCode: string = [
          line[0].toLocaleLowerCase(),
          line[1].toLocaleLowerCase()
        ].join('_');

        const contactToImport: IContactToImport = {
          contact: {
            id: generateRandomId(),
            lastname: line[4] !== '' ? line[4] : null,
            firstname: line[3] !== '' ? line[3] : null,
            fonction: line[5] !== '' ? line[5] : null,
            phone: null,
            mobile: null,
            fax: null,
            email: line[6] !== '' ? line[6] : null
          },
          airlineId: null,
          airlineTitleAndCountryCode: titleAndCountryCode,
          airlineTitle: line[0],
          airlineCountryCode: line[1],
          companyContactName: line[2] !== '' ? line[2] : null
        };

        this.contactsToImport.push(contactToImport);

        this.loadAirlineWithTitleAndCountry(line[0], line[1]);
      }

      this.refreshAirlinesReadyToImport();
    }
  }

  refreshAirlinesReadyToImport(): void {
    this.fetchingRelatedData = Object.values(this.airlinesByTitleAndCountryLoaded).includes(false);

    this.contactsReadyToImport = 0;

    for (const contactToImport of this.contactsToImport) {
      if (
        contactToImport.airlineId &&
        this.airlinesByTitleAndCountryLoaded[contactToImport.airlineTitleAndCountryCode]
      ) {
        this.contactsReadyToImport++;
      }
    }
  }

  loadAirlineWithTitleAndCountry(airlineTitle: string, countryCode: string): void {
    const titleAndCountry: string = [
      airlineTitle.toLocaleLowerCase(),
      countryCode.toLocaleLowerCase()
    ].join('_');

    if (typeof this.airlinesByTitleAndCountry[titleAndCountry] === 'undefined') {
      this.airlinesByTitleAndCountry[titleAndCountry] = null;
      this.airlinesByTitleAndCountryLoaded[titleAndCountry] = false;

      this.subscriptions.add(
        this.airlineService
          .getOneByTitleAndCountryCode(airlineTitle, countryCode)
          .subscribe((airline: IAirline | null) => {
            this.airlinesByTitleAndCountry[titleAndCountry] = airline;
            this.airlinesByTitleAndCountryLoaded[titleAndCountry] = true;

            if (airline) {
              for (const contactToImport of this.contactsToImport) {
                if (contactToImport.airlineTitleAndCountryCode === titleAndCountry) {
                  contactToImport.airlineId = airline.id;
                }
              }
            }

            this.refreshAirlinesReadyToImport();
          })
      );
    }
  }

  async importAirlines(): Promise<void> {
    this.loaderService.presentLoader();

    try {
      const airlineIds: string[] = [];
      const batchOperationsParams: IBatchOperationsParams = {
        set: {},
        update: {}
      };

      const airlinesToUpdateById: { [id: string]: IAirline } = {};

      this.selectedEnquiryTypes.sort((a, b) =>
        a.toLocaleLowerCase() < b.toLocaleLowerCase() ? -1 : 1
      );

      for (const contactToImport of this.contactsToImport) {
        if (contactToImport.airlineId) {
          if (typeof airlinesToUpdateById[contactToImport.airlineId] === 'undefined') {
            airlinesToUpdateById[contactToImport.airlineId] = {
              id: contactToImport.airlineId,
              companyContacts: this.airlinesByTitleAndCountry[
                contactToImport.airlineTitleAndCountryCode
              ].companyContacts
                ? [
                    ...this.airlinesByTitleAndCountry[contactToImport.airlineTitleAndCountryCode]
                      .companyContacts
                  ]
                : []
            } as IAirline;
          }

          let companyContactIndex: number | null = null;

          for (
            let i = 0;
            i < airlinesToUpdateById[contactToImport.airlineId].companyContacts?.length;
            i++
          ) {
            if (!airlinesToUpdateById[contactToImport.airlineId].companyContacts[i].enquiryTypes) {
              airlinesToUpdateById[contactToImport.airlineId].companyContacts[i].enquiryTypes = [];
            }

            airlinesToUpdateById[contactToImport.airlineId].companyContacts[i].enquiryTypes.sort(
              (a, b) => (a.toLocaleLowerCase() < b.toLocaleLowerCase() ? -1 : 1)
            );

            if (
              airlinesToUpdateById[contactToImport.airlineId].companyContacts[i].title.trim() ===
                contactToImport.companyContactName.trim() &&
              JSON.stringify(
                airlinesToUpdateById[contactToImport.airlineId].companyContacts[i].enquiryTypes
              ) === JSON.stringify(this.selectedEnquiryTypes)
            ) {
              companyContactIndex = i;

              break;
            }
          }

          if (companyContactIndex === null) {
            airlinesToUpdateById[contactToImport.airlineId].companyContacts.push({
              title: contactToImport.companyContactName,
              enquiryTypes: [...this.selectedEnquiryTypes],
              contacts: []
            });

            companyContactIndex =
              airlinesToUpdateById[contactToImport.airlineId].companyContacts.length - 1;
          }

          if (companyContactIndex !== null) {
            let contactIndex: number | null = null;

            for (
              let i = 0;
              i <
              airlinesToUpdateById[contactToImport.airlineId].companyContacts[companyContactIndex]
                .contacts?.length;
              i++
            ) {
              if (
                airlinesToUpdateById[contactToImport.airlineId].companyContacts[companyContactIndex]
                  .contacts[i].email === contactToImport.contact.email
              ) {
                contactIndex = i;
                break;
              }
            }

            if (contactIndex === null) {
              airlinesToUpdateById[contactToImport.airlineId].companyContacts[
                companyContactIndex
              ].contacts.push({
                id: generateRandomId(),
                lastname: null,
                firstname: null,
                fonction: null,
                phone: null,
                mobile: null,
                fax: null,
                email: null
              });

              contactIndex =
                airlinesToUpdateById[contactToImport.airlineId].companyContacts[companyContactIndex]
                  .contacts.length - 1;
            }

            if (contactIndex !== null) {
              for (const field of ['lastname', 'firstname', 'fonction', 'email']) {
                airlinesToUpdateById[contactToImport.airlineId].companyContacts[
                  companyContactIndex
                ].contacts[contactIndex][field] = contactToImport.contact[field];
              }
            }
          }
        }
      }

      for (const airline of Object.values(airlinesToUpdateById)) {
        batchOperationsParams.update[airline.id] = airline;
      }

      console.log('airlinesToUpdateById', airlinesToUpdateById);

      const sizeChunkBatch: number = 500;

      const chunkedData: string[][] = chunk(
        Object.keys(batchOperationsParams.update),
        sizeChunkBatch
      );

      for (let i = 0; i < chunkedData.length; i++) {
        await this.loaderService.updateLoaderMessage(
          'Mise à jour par groupe de 500 : ' + (i + 1) + '/' + chunkedData.length
        );

        const dataToBatch: { [key: string]: IAirline } = {};
        for (const docId of chunkedData[i]) {
          dataToBatch[docId] = batchOperationsParams.update[docId];
        }

        await this.airlineService.batchOperations({
          update: dataToBatch
        });
      }

      await this.loaderService.hideLoaderOnSuccess();
    } catch (err: any) {
      await this.loaderService.hideLoaderOnFailure(err);
    }
  }

  toggleEnquiryType(enquiryType: EnumEnquiryType): void {
    let values: EnumEnquiryType[] = [...this.selectedEnquiryTypes];
    const index: number = values.indexOf(enquiryType);

    if (index === -1) {
      values.push(enquiryType);
    } else {
      values.splice(index, 1);
    }

    this.selectedEnquiryTypes = values;
  }

  toggleAllEnquiryType(): void {
    this.selectedEnquiryTypes = [];
  }
}
