import { Component, Input } from '@angular/core';
import { faClock, faPlaneArrival, faPlaneDeparture } from '@fortawesome/free-solid-svg-icons';
import {
  getEnquiryFlightFlyTimeInMin,
  IEnquiryFlight
} from 'src/app/interfaces/enquiry-flight.interface';
import { convertMinutesToTime } from 'src/app/misc.utils';

@Component({
  selector: 'app-enquiry-flights-card-mini',
  templateUrl: './enquiry-flights-card-mini.component.html',
  styleUrls: ['./enquiry-flights-card-mini.component.scss']
})
export class EnquiryFlightsCardMiniComponent {
  @Input() enquiryFlight: IEnquiryFlight | null = null;
  @Input() clickable: boolean = false;

  faPlaneDeparture = faPlaneDeparture;
  faPlaneArrival = faPlaneArrival;
  faClock = faClock;

  convertMinutesToTime = convertMinutesToTime;
  getEnquiryFlightFlyTimeInMin = getEnquiryFlightFlyTimeInMin;
}
