import { finalize } from 'rxjs/operators';
import { Component, OnInit, NgZone, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  AbstractControl,
  FormControl,
  ValidatorFn,
  ValidationErrors
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';
import { PipedriveService } from '../../../services/pipedrive.service';

import moment from 'moment';

import { addMinutes, differenceInDays, differenceInMinutes } from 'date-fns';
import {
  getDisplayedEnquiryRefTitle,
  getEnquiryBreadcrumbTitle,
  IEnquiry
} from 'src/app/interfaces/enquiry.interface';
import { Subscription } from 'rxjs';
import { EnquiryService } from 'src/app/services/enquiry/enquiry.service';
import { EnquiryOfferService } from 'src/app/services/enquiry-offer/enquiry-offer.service';
import { IEnquiryOffer } from 'src/app/interfaces/enquiry-offer.interface';
import { IEnquiryCotation } from 'src/app/interfaces/enquiry-cotation.interface';
import { EnquiryCotationService } from 'src/app/services/enquiry-cotation/enquiry-cotation.service';
import { EnumEnquiryCotationStatus } from 'src/app/enums/enquiry-cotation-status.enum';
import { EnumEnquiryType } from 'src/app/enums/enquiry-type.enum';
import { EnumLanguage, getLanguageLabel } from 'src/app/enums/language.enum';
import {
  EnumCurrency,
  getEnumCurrencyLabel,
  getEnumCurrencySymbol
} from 'src/app/enums/currency.enum';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { IUser } from 'src/app/interfaces/user.interface';
import { UserService } from 'src/app/services/user/user.service';
import { addZeroToDigit, estimatedFlyTimeInMinutes } from 'src/app/misc.utils';
import {
  IAirport,
  getAirportLocalTimeFromUTC,
  getAirportUTCTime
} from 'src/app/interfaces/airport.interface';
import { IEnquiryTrip } from 'src/app/interfaces/enquiry-trip.interface';
import { IAircraftModel } from 'src/app/interfaces/aircraft-model.interface';
import { AircraftModelService } from 'src/app/services/aircraft-models/aircraft-models.service';
import {
  IPipedriveOrganization,
  IPipedrivePerson,
  getPipedrivePersonPrimaryEmail,
  getPipedrivePersonPrimaryPhone
} from 'src/app/interfaces/pipedrive.interface';
import { IAircraftCompiled } from 'src/app/interfaces/aircraft-compiled.interface';
import { EnumQuotationStatus, IQuotation } from 'src/app/interfaces/quotation.interface';
import { LoaderService } from 'src/app/services/loader/loader.service';
import {
  faExternalLink,
  faSearch,
  faToggleOff,
  faToggleOn,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import { IAircraft } from 'src/app/interfaces/aircraft.interface';
import { AircraftService } from 'src/app/services/aircrafts/aircrafts.service';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs/breadcrumbs.service';

@Component({
  selector: 'app-quotation-edit',
  templateUrl: './quotation-edit.component.html',
  styleUrls: ['./quotation-edit.component.scss']
})
export class QuotationEditComponent implements OnInit, OnDestroy {
  @ViewChild('modalImage', { static: false }) modalImageElement: ElementRef;

  EnumEnquiryType = EnumEnquiryType;
  EnumCurrency = EnumCurrency;
  EnumLanguage = EnumLanguage;

  getEnumCurrencySymbol = getEnumCurrencySymbol;
  getEnumCurrencyLabel = getEnumCurrencyLabel;
  getLanguageLabel = getLanguageLabel;

  faSearch = faSearch;
  faTrash = faTrash;
  faExternalLink = faExternalLink;
  faToggleOn = faToggleOn;
  faToggleOff = faToggleOff;

  queryAircraftRegistration: string = '';

  isLogged: boolean = false;
  form: FormGroup;
  sending: boolean = false;
  quotation: IQuotation | null = null;
  quotationId: string;
  enquiryId: string;
  enquiry: IEnquiry;
  enquiryOffers: IEnquiryOffer[] = [];
  enquiryCotations: IEnquiryCotation[] = [];
  offerId: string;
  cotationId: string;
  client: IPipedriveOrganization | null = null;
  contact: IPipedrivePerson | null = null;
  quotations: IQuotation[] = [];
  user: IUser;
  airportsObj: { [key: string]: IAirport } = {};
  airportsToLoad: string[] = [];
  airportsList: {
    title: string;
    value: string;
  }[] = [];

  defaultAircraftInfos: {
    title: string;
    subTitle: string;
    hasTitle: boolean;
    hasSubTitle: boolean;
    type: string;
    placeholder: string;
    value: string | number;
  }[] = [];
  defaultPriceInfos: {
    title: string;
    subTitle: string;
    hasTitle: boolean;
    hasSubTitle: boolean;
    type: string;
    placeholder: string;
    value: string | number;
  }[] = [];
  defaultServicesSections: {
    title: string;
    services: {
      title: string;
      checked: boolean;
      comment: string;
    }[];
  }[] = [];

  translationObj: object = {};
  selectedItineraryId: string;

  selectedOffer: IEnquiryOffer;
  selectedCotation: IEnquiryCotation;

  aircraftCompiled: IAircraftCompiled | null = null;
  aircraftModel: IAircraftModel | null = null;

  aircraftModels: IAircraftModel[] = [];
  aircraftModelsObj: { [id: string]: IAircraftModel } = {};
  currentAircraftModelInitiated: boolean = false;

  selectedImageField: 'image1' | 'image2' | 'image3' | null = null;
  selectedImageFieldForAircraftModel: 'imageUrl' | 'imageInsideUrl' | 'imagePlanUrl' | null = null;

  loadingAircraft: boolean = false;
  searchedAircraft: boolean = false;
  aircraft: IAircraft | null = null;

  subscriptions = new Subscription();
  subscriptionsAircraft = new Subscription();

  constructor(
    private formBuilder: FormBuilder,
    private remoteService: RemoteService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private aclService: AclService,
    private pipedriveService: PipedriveService,
    private translateService: TranslateService,
    private enquiryService: EnquiryService,
    private enquiryOfferService: EnquiryOfferService,
    private enquiryCotationService: EnquiryCotationService,
    private userService: UserService,
    private aircraftModelService: AircraftModelService,
    private aircraftService: AircraftService,
    private loaderService: LoaderService,
    private breadcrumbsService: BreadcrumbsService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      enquiryId: ['', [Validators.required]],
      enquiryType: ['', [Validators.required]],
      date: [moment().format('YYYY-MM-DD'), [Validators.required, this.dateMinimum('2000-01-01')]],
      companyTitle: ['Artheau Aviation', [Validators.required]],
      title: ['', [Validators.required]],
      versionNumber: [1, [Validators.required]],
      language: [EnumLanguage.fr, [Validators.required]],
      clientId: ['', [Validators.required]],
      clientTitle: ['', [Validators.required]],
      contactId: ['', [Validators.required]],
      contactFirstname: ['', [Validators.required]],
      contactLastname: ['', [Validators.required]],
      contactPhone: [''],
      contactEmail: [''],
      agentId: ['', [Validators.required]],
      agentFirstname: ['', [Validators.required]],
      agentLastname: ['', [Validators.required]],
      agentPhone: [''],
      agentEmail: [''],
      trips: new FormArray([]),
      notes: [''],
      aircraftInfos: new FormArray([]),
      priceInfos: new FormArray([]),
      paymentConditionsSettings: new FormGroup({
        firstDeposit: new FormGroup({
          percent: new FormControl(30),
          text: new FormControl('')
        }),
        lastDeposit: new FormGroup({
          percent: new FormControl(70),
          daysBefore: new FormControl(2),
          text: new FormControl('')
        })
      }),
      cancellingConditionsSettings: new FormGroup({
        firstStep: new FormGroup({
          percent: new FormControl(30),
          daysBefore: new FormControl(15),
          text: new FormControl('')
        }),
        secondStep: new FormGroup({
          percent: new FormControl(50),
          daysBeforeStart: new FormControl(14),
          daysBeforeEnd: new FormControl(8),
          text: new FormControl('')
        }),
        thirdStep: new FormGroup({
          percent: new FormControl(75),
          daysBeforeStart: new FormControl(7),
          hoursBeforeEnd: new FormControl(49),
          text: new FormControl('')
        }),
        fourthStep: new FormGroup({
          percent: new FormControl(100),
          hoursBefore: new FormControl(48),
          text: new FormControl('')
        })
      }),
      servicesSections: new FormArray([]),
      googleMapStaticUrl: [''],
      image1Url: [''],
      image2Url: [''],
      image3Url: [''],
      image1ZoomLevel: [1],
      image2ZoomLevel: [1],
      image3ZoomLevel: [1],
      garantiesAndEngagements: [''],
      observationsTrips: [''],
      totalAmountPayment: [0],
      totalAmountPaymentCurrency: ['EUR']
    });

    this.form.disable();

    this.activatedRoute.url.subscribe(async () => {
      this.quotationId = this.activatedRoute.snapshot.paramMap.get('quotationId');
      this.enquiryId = this.activatedRoute.snapshot.paramMap.get('enquiryId');
      this.offerId = this.activatedRoute.snapshot.paramMap.get('offerId');
      this.cotationId = this.activatedRoute.snapshot.paramMap.get('cotationId');

      if (this.enquiryId) {
        this.form.get('enquiryId').setValue(this.enquiryId);

        await this.loadEnquiry();
      }

      if (this.quotationId) {
        await this.aclService.checkAclAccess(EnumAcl.quotationsEdit);
        await this.loadData();
      } else {
        await this.aclService.checkAclAccess(EnumAcl.quotationsAdd);
        if (this.enquiryId) {
          await this.loadEnquiryQuotations();
          await this.loadEnquiryCotations();
          this.form.get('versionNumber').setValue(this.quotations.length + 1);
          this.refreshQuotationTitle();
          this.loadAircraftModels();

          await this.setEnquiryFields();

          this.form.enable();
        } else {
          this.router.navigate(['/admin/quotations']);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.subscriptionsAircraft.unsubscribe();
  }

  getLanguages(): EnumLanguage[] {
    return Object.values(EnumLanguage);
  }

  getCurrencies(): EnumCurrency[] {
    return Object.values(EnumCurrency);
  }

  get trips(): FormArray {
    return this.form.get('trips') as FormArray;
  }

  getTrip(i: number): FormGroup {
    return this.trips.at(i) as FormGroup;
  }

  get priceInfos(): FormArray {
    return this.form.get('priceInfos') as FormArray;
  }

  getPriceInfoLine(i: number): FormGroup {
    return this.priceInfos.at(i) as FormGroup;
  }

  get servicesSections(): FormArray {
    return this.form.get('servicesSections') as FormArray;
  }

  getServicesInSection(serviceSectionIndex: number): FormArray {
    return this.servicesSections.at(serviceSectionIndex).get('services') as FormArray;
  }

  get aircraftInfos(): FormArray {
    return this.form.get('aircraftInfos') as FormArray;
  }

  addTrip(): void {
    this.trips.push(this.tripFormGroup());
  }

  tripFormGroup(): FormGroup {
    return new FormGroup({
      id: new FormControl(this.remoteService.generateRandomId(), [Validators.required]),
      date: new FormControl('', [Validators.required, this.dateMinimum('2000-01-01')]),
      time: new FormControl(''),
      aircraftModelId: new FormControl(null),
      arrivalTime: new FormControl(''),
      airportDepart: new FormControl(''),
      airportDepartTax: new FormControl(0),
      airportDepartTimezoneId: new FormControl(''),
      airportDepartTimezoneRawOffset: new FormControl(''),
      airportDepartTimezoneGmt: new FormControl(''),
      airportDestination: new FormControl('', [Validators.required]),
      airportDestinationTax: new FormControl(0, [Validators.required]),
      airportDestinationTimezoneId: new FormControl(''),
      airportDestinationTimezoneRawOffset: new FormControl(''),
      airportDestinationTimezoneGmt: new FormControl(''),
      passengersTotal: new FormControl(''),
      passengersBusiness: new FormControl(''),
      weight: new FormControl(''),
      volume: new FormControl(''),
      natureFret: new FormControl(''),
      dimensions: new FormControl(''),
      hasFuelStopOver: new FormControl(false),
      fuelStopOverAirport: new FormControl(''),
      hasCommercialStopOver: new FormControl(false),
      commercialStopOverAirport: new FormControl(''),
      passengersCommercialStop: new FormControl(''),
      distanceInKm: new FormControl(null),
      speedInKts: new FormControl(null),
      flyTimeInHours: new FormControl(null)
    });
  }

  async addTripAndPrefill(): Promise<void> {
    if (this.selectedItineraryId) {
      for (const itinerary of this.enquiry.itineraries) {
        if (itinerary.id === this.selectedItineraryId) {
          for (const trip of itinerary.trips) {
            this.addTrip();

            const tripFormGroup: FormGroup = this.trips.at(this.trips.length - 1) as FormGroup;

            tripFormGroup.get('id').setValue(trip.id);
            tripFormGroup.get('date').setValue(trip.date);
            tripFormGroup.get('time').setValue(trip.time);
            tripFormGroup.get('arrivalTime').setValue(trip.arrivalTime);

            await this.loadAirport(trip.airportDepart);
            await this.loadAirport(trip.airportDestination);

            tripFormGroup.get('airportDepart').setValue(trip.airportDepart);
            tripFormGroup.get('airportDepartTax').setValue(trip.airportDepartTax);
            tripFormGroup.get('airportDepartTimezoneId').setValue(trip.airportDepartTimezoneId);
            tripFormGroup
              .get('airportDepartTimezoneRawOffset')
              .setValue(trip.airportDepartTimezoneRawOffset);
            tripFormGroup.get('airportDepartTimezoneGmt').setValue(trip.airportDepartTimezoneGmt);
            tripFormGroup.get('airportDestination').setValue(trip.airportDestination);
            tripFormGroup
              .get('airportDestinationTimezoneId')
              .setValue(trip.airportDestinationTimezoneId);
            tripFormGroup
              .get('airportDestinationTimezoneRawOffset')
              .setValue(trip.airportDestinationTimezoneRawOffset);
            tripFormGroup
              .get('airportDestinationTimezoneGmt')
              .setValue(trip.airportDestinationTimezoneGmt);
            tripFormGroup.get('passengersTotal').setValue(trip.passengersTotal);
            tripFormGroup.get('passengersBusiness').setValue(trip.passengersBusiness);
            tripFormGroup.get('weight').setValue(trip.weight);
            tripFormGroup.get('volume').setValue(trip.volume);
            tripFormGroup.get('natureFret').setValue(trip.natureFret);
            tripFormGroup.get('dimensions').setValue(trip.dimensions);
            tripFormGroup.get('distanceInKm').setValue(trip.distanceInKm);
            tripFormGroup.get('speedInKts').setValue(trip.speedInKts);

            if (
              !tripFormGroup.get('airportDepartTimezoneId').value ||
              !tripFormGroup.get('airportDestinationTimezoneId').value
            ) {
              this.setTimezoneForAirports(this.trips.length - 1);
            }

            for (const field in (tripFormGroup as FormGroup).controls) {
              if (typeof tripFormGroup.value[field] === 'undefined') {
                tripFormGroup.get(field).setValue(null);
              }
            }

            if (trip.hasFuelStopOver) {
              tripFormGroup.get('airportDestination').setValue(trip.fuelStopOverAirport);
              tripFormGroup.get('airportDestinationTimezoneId').setValue(null);
              tripFormGroup.get('airportDestinationTimezoneRawOffset').setValue(null);
              tripFormGroup.get('airportDestinationTimezoneGmt').setValue(null);
              tripFormGroup.get('arrivalTime').setValue(null);

              this.setTimezoneForAirports(this.trips.length - 1);

              this.addTrip();

              const tripFormGroupFuelStopOver: FormGroup = this.trips.at(
                this.trips.length - 1
              ) as FormGroup;

              tripFormGroupFuelStopOver.get('airportDepart').setValue(trip.fuelStopOverAirport);
              tripFormGroupFuelStopOver.get('airportDestination').setValue(trip.airportDestination);
              tripFormGroupFuelStopOver.get('passengersTotal').setValue(trip.passengersTotal);
              tripFormGroupFuelStopOver.get('passengersBusiness').setValue(trip.passengersBusiness);
              tripFormGroupFuelStopOver.get('date').setValue(trip.date);

              await this.loadAirport(trip.fuelStopOverAirport);

              this.setTimezoneForAirports(this.trips.length - 1);
            } else if (trip.hasCommercialStopOver) {
              tripFormGroup.get('airportDestination').setValue(trip.commercialStopOverAirport);
              tripFormGroup.get('airportDestinationTimezoneId').setValue(null);
              tripFormGroup.get('airportDestinationTimezoneRawOffset').setValue(null);
              tripFormGroup.get('airportDestinationTimezoneGmt').setValue(null);
              tripFormGroup.get('arrivalTime').setValue(null);

              this.setTimezoneForAirports(this.trips.length - 1);

              this.addTrip();

              const tripFormGroupCommercialStopOver: FormGroup = this.trips.at(
                this.trips.length - 1
              ) as FormGroup;

              tripFormGroupCommercialStopOver
                .get('airportDepart')
                .setValue(trip.commercialStopOverAirport);
              tripFormGroupCommercialStopOver
                .get('airportDestination')
                .setValue(trip.airportDestination);

              tripFormGroupCommercialStopOver
                .get('passengersCommercialStop')
                .setValue(trip.passengersCommercialStop);
              tripFormGroupCommercialStopOver.get('passengersTotal').setValue(trip.passengersTotal);
              tripFormGroupCommercialStopOver
                .get('passengersBusiness')
                .setValue(trip.passengersBusiness);
              tripFormGroupCommercialStopOver.get('date').setValue(trip.date);

              this.setTimezoneForAirports(this.trips.length - 1);
            }

            this.tripTimesUpdated(this.trips.length - 1);
          }

          break;
        }
      }

      this.setEstimatedArrivalTimeIfNotProvided();

      this.prefillVatFieldValue();

      this.generateGoogleMapStaticUrl();

      this.selectedItineraryId = null;
    } else {
      this.addTrip();
    }
  }

  async prefillVatFieldValue(): Promise<void> {
    const isNationalFlight: boolean = this.tripsIsNationalFlight();

    let fieldIndex: number = null;
    switch (this.enquiry.type) {
      case EnumEnquiryType.business:
        fieldIndex = 3;

        if (isNationalFlight) {
          this.priceInfos
            .at(this.priceInfos.length - 1)
            .get('value')
            .setValue(await this.getTranslation('QUOTATION.PRICE.TO_FILL'));
        }
        break;
      case EnumEnquiryType.cargo:
        fieldIndex = 1;

        if (isNationalFlight) {
          this.priceInfos
            .at(this.priceInfos.length - 1)
            .get('value')
            .setValue(await this.getTranslation('QUOTATION.PRICE.TO_FILL'));
        }
        break;
      case EnumEnquiryType.commercial:
      default:
        fieldIndex = 3;

        if (isNationalFlight && this.priceInfos.length >= 2) {
          for (const index of [this.priceInfos.length - 2, this.priceInfos.length - 1]) {
            this.priceInfos
              .at(index)
              .get('value')
              .setValue(await this.getTranslation('QUOTATION.PRICE.TO_FILL'));
          }
        }
        break;
    }

    if (fieldIndex !== null && this.priceInfos.length) {
      this.priceInfos
        .at(fieldIndex)
        .get('value')
        .setValue(
          isNationalFlight
            ? await this.getTranslation('QUOTATION.PRICE.TO_FILL')
            : await this.getTranslation('QUOTATION.PRICE.EXEMPT')
        );
    }
  }

  tripsIsNationalFlight(): boolean {
    let isNationalFlight: boolean = false;

    if (this.form.value.trips) {
      for (const trip of this.form.value.trips) {
        if (trip.airportDepart && trip.airportDestination) {
          if (
            this.airportsObj[trip.airportDepart] &&
            this.airportsObj[trip.airportDestination] &&
            this.airportsObj[trip.airportDepart].countryCode ===
              this.airportsObj[trip.airportDestination].countryCode
          ) {
            isNationalFlight = true;
          }
        }
      }
    }

    return isNationalFlight;
  }

  removeTrip(i: number): void {
    if (confirm('Êtes-vous sûr de vouloir supprimer cette ligne ?')) {
      this.trips.removeAt(i);

      this.prefillVatFieldValue();
    }
  }

  refreshQuotationTitle(): void {
    this.form
      .get('title')
      .setValue(
        getDisplayedEnquiryRefTitle(this.enquiry, 'refEnquiry') +
          '.' +
          (this.form.value.versionNumber || 1)
      );
  }

  async setEnquiryFields(): Promise<void> {
    if (this.enquiry) {
      setTimeout(async () => {
        this.form.get('enquiryType').setValue(this.enquiry.type);

        if (!this.quotationId) {
          this.refreshQuotationTitle();
        }
        this.form.get('clientId').setValue(this.enquiry.clientId);
        this.form.get('contactId').setValue(this.enquiry.contactId);
        this.form.get('agentId').setValue(this.enquiry.processedBy);

        await this.loadClient();

        if (this.client) {
          this.form.get('clientTitle').setValue(this.client.name);
        }

        if (this.contact.first_name) {
          this.form.get('contactFirstname').setValue(this.contact.first_name);
        }
        if (this.contact.last_name) {
          this.form.get('contactLastname').setValue(this.contact.last_name);
        }
        if (getPipedrivePersonPrimaryPhone(this.contact)) {
          this.form.get('contactPhone').setValue(getPipedrivePersonPrimaryPhone(this.contact));
        }
        if (getPipedrivePersonPrimaryEmail(this.contact)) {
          this.form.get('contactEmail').setValue(getPipedrivePersonPrimaryEmail(this.contact));
        }

        this.loadUser();

        await this.setDefaultFields();

        if (this.enquiry.itineraries.length === 1 && !this.trips.length) {
          // We prefill because there is only one itinerary
          this.selectedItineraryId = this.enquiry.itineraries[0].id;

          this.addTripAndPrefill();
        }

        if (this.selectedCotation && this.selectedCotation?.aircraftCompiled) {
          this.aircraftCompiled = this.selectedCotation?.aircraftCompiled;
        } else if (this.selectedOffer && this.selectedOffer.aircraftCompiled) {
          this.aircraftCompiled = this.selectedOffer.aircraftCompiled;
        }

        if (this.aircraftCompiled) {
          this.aircraftInfos.at(0).get('value').setValue(this.aircraftCompiled.type.toUpperCase());

          if (this.aircraftCompiled.aircraftModelId) {
            for (let i = 0; i < this.trips.length; i++) {
              this.getTrip(i)
                .get('aircraftModelId')
                .setValue(this.aircraftCompiled.aircraftModelId);
              this.updateAircraftModelForTrip(i);
            }

            this.loadAircraftModel(this.aircraftCompiled.aircraftModelId);
          }

          if (
            ![EnumEnquiryType.helico, EnumEnquiryType.business, EnumEnquiryType.cargo].includes(
              this.enquiry.type
            )
          ) {
            const xSeatsStr: string = await this.getTranslation('QUOTATION.AIRCRAFT.X_SEATS');
            this.aircraftInfos
              .at(1)
              .get('value')
              .setValue(
                this.aircraftCompiled.seatTotal
                  ? xSeatsStr.replace('%s', this.aircraftCompiled.seatTotal?.toString())
                  : ''
              );
          }
        }

        if (this.selectedCotation) {
          const passengerStrSingular: string = await this.getTranslation(
            'QUOTATION.PRICE.PASSENGER'
          );
          const passengerStrPlural: string = await this.getTranslation(
            'QUOTATION.PRICE.PASSENGERS'
          );

          switch (this.enquiry.type) {
            case EnumEnquiryType.business:
              this.priceInfos
                .at(0)
                .get('title')
                .setValue(await this.getTranslation('QUOTATION.PRICE.PRICE_EXCLUDING_TAX_FOR'));
              this.priceInfos
                .at(0)
                .get('value')
                .setValue(
                  this.formatPrice(
                    this.selectedCotation?.sellingPriceInCurrency,
                    this.selectedCotation?.currency
                  )
                );
              this.priceInfos
                .at(1)
                .get('title')
                .setValue(
                  this.selectedCotation?.nbPax > 1 ? passengerStrPlural : passengerStrSingular
                );
              this.priceInfos
                .at(1)
                .get('value')
                .setValue(addZeroToDigit(this.selectedCotation?.nbPax));
              this.priceInfos
                .at(this.priceInfos.length - 1)
                .get('value')
                .setValue(
                  this.formatPrice(
                    this.selectedCotation?.sellingPriceInCurrency,
                    this.selectedCotation?.currency
                  )
                );
              break;
            case EnumEnquiryType.cargo:
              this.priceInfos
                .at(0)
                .get('title')
                .setValue(
                  await this.getTranslation('QUOTATION.PRICE.PRICE_EXCLUDING_TAX_FOR_AIRCRAFT')
                );
              this.priceInfos
                .at(0)
                .get('value')
                .setValue(
                  this.formatPrice(
                    this.selectedCotation?.sellingPriceInCurrency,
                    this.selectedCotation?.currency
                  )
                );
              this.priceInfos.at(0).get('hasSubTitle').setValue(false);
              this.priceInfos
                .at(this.priceInfos.length - 1)
                .get('value')
                .setValue(
                  this.formatPrice(
                    this.selectedCotation?.sellingPriceInCurrency,
                    this.selectedCotation?.currency
                  )
                );
              break;
            case EnumEnquiryType.commercial:
            default:
              this.priceInfos
                .at(0)
                .get('value')
                .setValue(
                  this.formatPrice(
                    this.selectedCotation?.sellingPriceInCurrency,
                    this.selectedCotation?.currency
                  )
                );
              this.priceInfos
                .at(1)
                .get('title')
                .setValue(
                  this.selectedCotation?.nbPax > 1 ? passengerStrPlural : passengerStrSingular
                );
              this.priceInfos
                .at(1)
                .get('value')
                .setValue(addZeroToDigit(this.selectedCotation?.nbPax));
              this.priceInfos
                .at(this.priceInfos.length - 2)
                .get('value')
                .setValue(
                  this.formatPrice(
                    this.selectedCotation?.sellingPriceInCurrency,
                    this.selectedCotation?.currency
                  )
                );
              this.priceInfos
                .at(this.priceInfos.length - 1)
                .get('value')
                .setValue(
                  this.formatPrice(
                    this.selectedCotation?.sellingPricePPInCurrency,
                    this.selectedCotation?.currency
                  )
                );
              break;
          }

          this.form
            .get('totalAmountPayment')
            .setValue(this.selectedCotation?.sellingPriceInCurrency);
          this.form.get('totalAmountPaymentCurrency').setValue(this.selectedCotation?.currency);
        }
      }, 300);
    }

    setTimeout(() => {
      this.changedLanguage();
    }, 1000);

    this.generateGoogleMapStaticUrl();
  }

  async setCurrentCotation(): Promise<void> {
    if (this.offerId || this.cotationId) {
      for (const offer of this.enquiryOffers) {
        if (!this.trips.length) {
          if (offer.id === this.offerId) {
            this.selectedOffer = offer;
          }

          if (this.cotationId) {
            for (const enquiryCotation of this.enquiryCotations) {
              if (enquiryCotation.id === this.cotationId) {
                this.selectedOffer = offer;
                this.selectedCotation = enquiryCotation;
                this.selectedItineraryId = enquiryCotation.itineraryId;
                this.addTripAndPrefill();

                break;
              }
            }
          }
        }
      }
    } else {
      for (const enquiryCotation of this.enquiryCotations) {
        if (enquiryCotation.status === EnumEnquiryCotationStatus.confirmed) {
          for (const enquiryOffer of this.enquiryOffers) {
            if (enquiryOffer.id === enquiryCotation.offerId) {
              this.selectedOffer = enquiryOffer;
            }
          }

          this.selectedCotation = enquiryCotation;
          this.selectedItineraryId = enquiryCotation.itineraryId;

          this.changedLanguage();
          break;
        }
      }
    }
  }

  async setDefaultFields(): Promise<void> {
    this.form.get('notes').setValue(await this.getTranslation('QUOTATION.NOTES'));

    switch (this.enquiry.type) {
      case EnumEnquiryType.helico:
      case EnumEnquiryType.business:
        this.defaultAircraftInfos = [
          {
            title: 'QUOTATION.AIRCRAFT.MODEL',
            subTitle: null,
            hasTitle: true,
            hasSubTitle: false,
            type: 'string',
            placeholder: 'Hawker 800A',
            value: ''
          },
          {
            title: 'QUOTATION.AIRCRAFT.CABIN_HEIGHT',
            subTitle: null,
            hasTitle: true,
            hasSubTitle: false,
            type: 'string',
            placeholder: '1m82',
            value: ''
          },
          {
            title: 'QUOTATION.AIRCRAFT.MANUFACTURE_YEAR',
            subTitle: null,
            hasTitle: true,
            hasSubTitle: false,
            type: 'year',
            placeholder: '1990',
            value: ''
          },
          {
            title: 'QUOTATION.AIRCRAFT.REFURBISHMENT',
            subTitle: null,
            hasTitle: true,
            hasSubTitle: false,
            type: 'year',
            placeholder: '2020',
            value: ''
          },
          {
            title: 'QUOTATION.AIRCRAFT.BAGGAGE_ALLOWANCE',
            subTitle: null,
            hasTitle: true,
            hasSubTitle: false,
            type: 'string',
            placeholder: 'QUOTATION.AIRCRAFT.TWO_LUGGAGES_23KG',
            value: ''
          },
          {
            title: 'QUOTATION.AIRCRAFT.CRUISE_SPEED',
            subTitle: null,
            hasTitle: true,
            hasSubTitle: false,
            type: 'string',
            placeholder: '850km/h',
            value: ''
          }
        ];

        this.defaultPriceInfos = [
          {
            title: 'QUOTATION.PRICE.PRICE_EXCLUDING_TAX_FOR',
            subTitle: null,
            hasTitle: true,
            hasSubTitle: false,
            type: 'price',
            placeholder: this.formatPrice(30750),
            value: ''
          },
          {
            title: 'QUOTATION.PRICE.PASSENGER',
            subTitle: null,
            hasTitle: true,
            hasSubTitle: false,
            type: 'string',
            placeholder: '0',
            value: 0
          },
          {
            title: 'QUOTATION.PRICE.FLIGHT_ATTENDANT',
            subTitle: null,
            hasTitle: true,
            hasSubTitle: false,
            type: 'string',
            placeholder: 'QUOTATION.PRICE.NOT_INCLUDED',
            value: 'QUOTATION.PRICE.NOT_INCLUDED'
          },
          {
            title: 'QUOTATION.PRICE.CATERING_VIP',
            subTitle: null,
            hasTitle: true,
            hasSubTitle: false,
            type: 'string',
            placeholder: 'QUOTATION.PRICE.SNACKS_BAR',
            value: 'QUOTATION.PRICE.SNACKS_BAR'
          },
          {
            title: 'QUOTATION.PRICE.VAT_NATIONAL_FLIGHT',
            subTitle: null,
            hasTitle: true,
            hasSubTitle: false,
            type: 'string',
            placeholder: 'QUOTATION.PRICE.EXEMPT',
            value: 'QUOTATION.PRICE.EXEMPT'
          },
          {
            title: 'QUOTATION.PRICE.SUPPLEMENTARY_SERVICES',
            subTitle: null,
            hasTitle: true,
            hasSubTitle: false,
            type: 'string',
            placeholder: 'QUOTATION.PRICE.SEE_PAGE_2',
            value: 'QUOTATION.PRICE.SEE_PAGE_2'
          },
          {
            title: null,
            subTitle: 'QUOTATION.PRICE.PRICE_NET_TTC',
            hasTitle: false,
            hasSubTitle: true,
            type: 'price',
            placeholder: this.formatPrice(30750),
            value: ''
          }
        ];
        this.defaultServicesSections = [
          {
            title: 'QUOTATION.SERVICES.BEFORE_FLIGHT.TITLE',
            services: [
              {
                title: 'QUOTATION.SERVICES.BEFORE_FLIGHT.PREPARATION_AND_FLIGHT_FOLLOW_UP',
                checked: true,
                comment: 'QUOTATION.SERVICES.INCLUDED'
              },
              {
                title: 'QUOTATION.SERVICES.BEFORE_FLIGHT.ARTHEAU_AVIATION_AGENT_ON_DEPARTURE',
                checked: false,
                comment: 'QUOTATION.SERVICES.UPON_REQUEST'
              },
              {
                title: 'QUOTATION.SERVICES.BEFORE_FLIGHT.DISPLAY_SCREEN_ACCORDING_TO_AIRPORT',
                checked: true,
                comment: 'QUOTATION.SERVICES.INCLUDED'
              },
              {
                title: 'QUOTATION.SERVICES.BEFORE_FLIGHT.VIP_CHECKIN',
                checked: true,
                comment: 'QUOTATION.SERVICES.INCLUDED'
              },
              {
                title: 'QUOTATION.SERVICES.BEFORE_FLIGHT.WAITING_LOUNGE',
                checked: true,
                comment: 'QUOTATION.SERVICES.INCLUDED'
              }
            ]
          },
          {
            title: 'QUOTATION.SERVICES.ABOARD.TITLE',
            services: [
              {
                title: 'QUOTATION.SERVICES.ABOARD.OPERATIONAL_SUPPORT_24H',
                checked: true,
                comment: 'QUOTATION.SERVICES.INCLUDED'
              },
              {
                title: 'QUOTATION.SERVICES.ABOARD.CATERING_STANDARD',
                checked: true,
                comment: 'QUOTATION.SERVICES.INCLUDED'
              },
              {
                title: 'QUOTATION.SERVICES.ABOARD.CATERING_SPECIFIC',
                checked: false,
                comment: 'QUOTATION.SERVICES.UPON_REQUEST'
              },
              {
                title: 'QUOTATION.SERVICES.ABOARD.WIFI',
                checked: false,
                comment: 'QUOTATION.SERVICES.UPON_REQUEST'
              }
            ]
          },
          {
            title: 'QUOTATION.SERVICES.AFTER_FLIGHT.TITLE',
            services: [
              {
                title: 'QUOTATION.SERVICES.AFTER_FLIGHT.LUGGAGES_PLUS',
                checked: false,
                comment: 'QUOTATION.SERVICES.UPON_REQUEST'
              },
              {
                title: 'QUOTATION.SERVICES.AFTER_FLIGHT.AIRPORT_TRANSFER',
                checked: false,
                comment: 'QUOTATION.SERVICES.UPON_REQUEST'
              },
              {
                title: 'QUOTATION.SERVICES.AFTER_FLIGHT.TERMINAL_VIP',
                checked: true,
                comment: 'QUOTATION.SERVICES.INCLUDED'
              }
            ]
          }
        ];
        break;
      case EnumEnquiryType.cargo:
        this.defaultAircraftInfos = [
          {
            title: 'QUOTATION.AIRCRAFT.MODEL',
            subTitle: null,
            hasTitle: true,
            hasSubTitle: false,
            type: 'string',
            placeholder: 'Hawker 800A',
            value: ''
          },
          {
            title: 'QUOTATION.AIRCRAFT.CONFIGURATION',
            subTitle: null,
            hasTitle: true,
            hasSubTitle: false,
            type: 'string',
            placeholder: '',
            value: ''
          },
          {
            title: 'QUOTATION.AIRCRAFT.TECHNICAL_LIMITATION',
            subTitle: null,
            hasTitle: true,
            hasSubTitle: false,
            type: 'string',
            placeholder: '',
            value: ''
          }
        ];

        this.defaultPriceInfos = [
          {
            title: 'QUOTATION.PRICE.NET_PRICE_PER_ROTATION',
            subTitle: null,
            hasTitle: true,
            hasSubTitle: false,
            type: 'price',
            placeholder: this.formatPrice(300000),
            value: ''
          },
          {
            title: 'QUOTATION.PRICE.VAT',
            subTitle: null,
            hasTitle: true,
            hasSubTitle: false,
            type: 'string',
            placeholder: 'QUOTATION.PRICE.EXEMPT',
            value: 'QUOTATION.PRICE.EXEMPT'
          },
          {
            title: 'QUOTATION.PRICE.ADDITIONAL_SERVICES_SEE_PAGE_2',
            subTitle: null,
            hasTitle: true,
            hasSubTitle: false,
            type: 'string',
            placeholder: 'QUOTATION.PRICE.UPON_REQUEST',
            value: 'QUOTATION.PRICE.UPON_REQUEST'
          },
          {
            title: null,
            subTitle: 'QUOTATION.PRICE.TOTAL_PRICE_ALL_INCLUDED',
            hasTitle: false,
            hasSubTitle: true,
            type: 'price',
            placeholder: this.formatPrice(300000),
            value: ''
          }
        ];
        this.defaultServicesSections = [
          {
            title: 'QUOTATION.SERVICES.FLIGHT_SERVICES.TITLE',
            services: [
              {
                title: 'QUOTATION.SERVICES.FLIGHT_SERVICES.AIRCRAFT_FULL_CHARTER',
                checked: true,
                comment: 'QUOTATION.SERVICES.INCLUDED'
              },
              {
                title: 'QUOTATION.SERVICES.FLIGHT_SERVICES.FUEL',
                checked: true,
                comment: 'QUOTATION.SERVICES.INCLUDED'
              },
              {
                title: 'QUOTATION.SERVICES.FLIGHT_SERVICES.AIRCRAFT_INSURANCE',
                checked: true,
                comment: 'QUOTATION.SERVICES.INCLUDED'
              },
              {
                title: 'QUOTATION.SERVICES.FLIGHT_SERVICES.LANDING_FEES',
                checked: true,
                comment: 'QUOTATION.SERVICES.INCLUDED'
              },
              {
                title: 'QUOTATION.SERVICES.FLIGHT_SERVICES.RAMP_HANDLING_FEES',
                checked: true,
                comment: 'QUOTATION.SERVICES.INCLUDED'
              },
              {
                title: 'QUOTATION.SERVICES.FLIGHT_SERVICES.PARKING_FEES',
                checked: true,
                comment: 'QUOTATION.SERVICES.INCLUDED'
              }
            ]
          },
          {
            title: 'QUOTATION.SERVICES.ADDITIONAL_SERVICES.TITLE',
            services: [
              {
                title:
                  'QUOTATION.SERVICES.FLIGHT_SERVICES.EXCEPTIONNAL_LOADING_OFFLOADING_EQUIPMENT',
                checked: false,
                comment: 'QUOTATION.SERVICES.UPON_REQUEST'
              },
              {
                title: 'QUOTATION.SERVICES.ADDITIONAL_SERVICES.PALLET_BUILD_AND_BREAK',
                checked: false,
                comment: 'QUOTATION.SERVICES.UPON_REQUEST'
              },
              {
                title: 'QUOTATION.SERVICES.ADDITIONAL_SERVICES.OFFLOADING_OF_TRUCKS',
                checked: false,
                comment: 'QUOTATION.SERVICES.UPON_REQUEST'
              },
              {
                title: 'QUOTATION.SERVICES.ADDITIONAL_SERVICES.WAREHOUSING',
                checked: false,
                comment: 'QUOTATION.SERVICES.UPON_REQUEST'
              },
              {
                title: 'QUOTATION.SERVICES.ADDITIONAL_SERVICES.SCREENING',
                checked: false,
                comment: 'QUOTATION.SERVICES.UPON_REQUEST'
              },
              {
                title: 'QUOTATION.SERVICES.ADDITIONAL_SERVICES.CUSTOMS_EXPORT_IMPORT',
                checked: false,
                comment: 'QUOTATION.SERVICES.UPON_REQUEST'
              },
              {
                title: 'QUOTATION.SERVICES.ADDITIONAL_SERVICES.TRUCKING',
                checked: false,
                comment: 'QUOTATION.SERVICES.UPON_REQUEST'
              },
              {
                title: 'QUOTATION.SERVICES.ADDITIONAL_SERVICES.LOCAL_TAXES_ACCORDING_TO_COUNTRIES',
                checked: false,
                comment: 'QUOTATION.SERVICES.UPON_REQUEST'
              },
              {
                title: 'QUOTATION.SERVICES.ADDITIONAL_SERVICES.TERMINAL_HANDLING_CHARGES',
                checked: false,
                comment: 'QUOTATION.SERVICES.UPON_REQUEST'
              },
              {
                title: 'QUOTATION.SERVICES.ADDITIONAL_SERVICES.WAR_RISK_INSURANCE',
                checked: false,
                comment: 'QUOTATION.SERVICES.UPON_REQUEST'
              },
              {
                title: 'QUOTATION.SERVICES.ADDITIONAL_SERVICES.ROYALTIES_NON_OBJECTION_FEES',
                checked: false,
                comment: 'QUOTATION.SERVICES.UPON_REQUEST'
              },
              {
                title: 'QUOTATION.SERVICES.ADDITIONAL_SERVICES.DE_ICING',
                checked: false,
                comment: 'QUOTATION.SERVICES.UPON_REQUEST'
              }
            ]
          }
        ];
        break;
      case EnumEnquiryType.commercial:
      default:
        this.defaultAircraftInfos = [
          {
            title: 'QUOTATION.AIRCRAFT.MODEL',
            subTitle: null,
            hasTitle: true,
            hasSubTitle: false,
            type: 'string',
            placeholder: 'Hawker 800A',
            value: ''
          },
          {
            title: 'QUOTATION.AIRCRAFT.CONFIGURATION',
            subTitle: null,
            hasTitle: true,
            hasSubTitle: false,
            type: 'string',
            placeholder: '',
            value: ''
          },
          {
            title: 'QUOTATION.AIRCRAFT.TECHNICAL_LIMITATION',
            subTitle: null,
            hasTitle: true,
            hasSubTitle: false,
            type: 'string',
            placeholder: '',
            value: ''
          },
          {
            title: 'QUOTATION.AIRCRAFT.BAGGAGE_ALLOWANCE',
            subTitle: null,
            hasTitle: true,
            hasSubTitle: false,
            type: 'string',
            placeholder: 'QUOTATION.AIRCRAFT.TWO_LUGGAGES_23KG',
            value: ''
          },
          {
            title: 'QUOTATION.AIRCRAFT.CRUISE_SPEED',
            subTitle: null,
            hasTitle: true,
            hasSubTitle: false,
            type: 'string',
            placeholder: '850km/h',
            value: ''
          }
        ];

        this.defaultPriceInfos = [
          {
            title: 'QUOTATION.PRICE.PRICE_EXCLUDING_TAX_FOR',
            subTitle: 'QUOTATION.PRICE.X_PASSENGERS',
            hasTitle: true,
            hasSubTitle: true,
            type: 'price',
            placeholder: this.formatPrice(30750),
            value: ''
          },
          {
            title: 'QUOTATION.PRICE.PASSENGER_TAXES',
            subTitle: null,
            hasTitle: true,
            hasSubTitle: false,
            type: 'string',
            placeholder: 'QUOTATION.PRICE.INCLUDED_FEMININE',
            value: 'QUOTATION.PRICE.INCLUDED_FEMININE'
          },
          {
            title: 'QUOTATION.PRICE.SERVICE_ON_BOARD',
            subTitle: null,
            hasTitle: true,
            hasSubTitle: false,
            type: 'string',
            placeholder: 'QUOTATION.PRICE.INCLUDED',
            value: 'QUOTATION.PRICE.INCLUDED'
          },
          {
            title: 'QUOTATION.PRICE.VAT_NATIONAL_FLIGHT',
            subTitle: null,
            hasTitle: true,
            hasSubTitle: false,
            type: 'string',
            placeholder: 'QUOTATION.PRICE.EXEMPT',
            value: 'QUOTATION.PRICE.EXEMPT'
          },
          {
            title: 'QUOTATION.PRICE.SUPPLEMENTARY_SERVICES',
            subTitle: null,
            hasTitle: true,
            hasSubTitle: false,
            type: 'string',
            placeholder: 'QUOTATION.PRICE.SEE_PAGE_2',
            value: 'QUOTATION.PRICE.SEE_PAGE_2'
          },
          {
            title: null,
            subTitle: 'QUOTATION.PRICE.TOTAL_PRICE_ALL_INCLUDED',
            hasTitle: false,
            hasSubTitle: true,
            type: 'price',
            placeholder: this.formatPrice(30750),
            value: ''
          },
          {
            title: 'QUOTATION.PRICE.PRICE_ALL_INCLUDED_PER_PASSENGER',
            subTitle: null,
            hasTitle: true,
            hasSubTitle: false,
            type: 'price',
            placeholder: this.formatPrice(200),
            value: ''
          }
        ];
        this.defaultServicesSections = [
          {
            title: 'QUOTATION.SERVICES.BEFORE_FLIGHT.TITLE',
            services: [
              {
                title: 'QUOTATION.SERVICES.BEFORE_FLIGHT.PREPARATION_AND_FLIGHT_FOLLOW_UP',
                checked: true,
                comment: 'QUOTATION.SERVICES.INCLUDED'
              },
              {
                title: 'QUOTATION.SERVICES.BEFORE_FLIGHT.ARTHEAU_AVIATION_AGENT_ON_DEPARTURE',
                checked: true,
                comment: 'QUOTATION.SERVICES.INCLUDED'
              },
              {
                title: 'QUOTATION.SERVICES.BEFORE_FLIGHT.DISPLAY_SCREEN_ACCORDING_TO_AIRPORT',
                checked: true,
                comment: 'QUOTATION.SERVICES.INCLUDED'
              },
              {
                title: 'QUOTATION.SERVICES.BEFORE_FLIGHT.MOBILE_CUSTOM_DESK',
                checked: false,
                comment: 'QUOTATION.SERVICES.UPON_REQUEST'
              },
              {
                title: 'QUOTATION.SERVICES.BEFORE_FLIGHT.AIRPORT_TRANSFERTS',
                checked: false,
                comment: 'QUOTATION.SERVICES.UPON_REQUEST'
              },
              {
                title: 'QUOTATION.SERVICES.BEFORE_FLIGHT.VIP_CHECKIN',
                checked: false,
                comment: 'QUOTATION.SERVICES.UPON_REQUEST'
              },
              {
                title: 'QUOTATION.SERVICES.BEFORE_FLIGHT.VIP_TERMINAL',
                checked: false,
                comment: 'QUOTATION.SERVICES.UPON_REQUEST'
              },
              {
                title: 'QUOTATION.SERVICES.BEFORE_FLIGHT.LABELS_LUGGAGE_PERSONALIZED',
                checked: false,
                comment: 'QUOTATION.SERVICES.UPON_REQUEST'
              },
              {
                title: 'QUOTATION.SERVICES.BEFORE_FLIGHT.HEADCOVERS',
                checked: false,
                comment: 'QUOTATION.SERVICES.UPON_REQUEST'
              }
            ]
          },
          {
            title: 'QUOTATION.SERVICES.ABOARD.TITLE',
            services: [
              {
                title: 'QUOTATION.SERVICES.ABOARD.ARTHEAU_AVIATION_REPRESENTATIVE_ON_BOARD',
                checked: false,
                comment: 'QUOTATION.SERVICES.UPON_REQUEST'
              },
              {
                title: 'QUOTATION.SERVICES.ABOARD.OPERATIONAL_SUPPORT_24H',
                checked: true,
                comment: 'QUOTATION.SERVICES.INCLUDED'
              },
              {
                title: 'QUOTATION.SERVICES.ABOARD.CATERING_STANDARD',
                checked: true,
                comment: 'QUOTATION.SERVICES.INCLUDED'
              },
              {
                title: 'QUOTATION.SERVICES.ABOARD.VIP_CATERING',
                checked: false,
                comment: 'QUOTATION.SERVICES.UPON_REQUEST'
              },
              {
                title: 'QUOTATION.SERVICES.ABOARD.HEADREST',
                checked: false,
                comment: 'QUOTATION.SERVICES.UPON_REQUEST'
              },
              {
                title: 'QUOTATION.SERVICES.ABOARD.CUSTOMIZATION_INSIDE',
                checked: false,
                comment: 'QUOTATION.SERVICES.UPON_REQUEST'
              },
              {
                title: 'QUOTATION.SERVICES.ABOARD.CUSTOMIZATION_OUTSIDE',
                checked: false,
                comment: 'QUOTATION.SERVICES.UPON_REQUEST'
              },
              {
                title: 'QUOTATION.SERVICES.ABOARD.NEWSPAPER',
                checked: false,
                comment: 'QUOTATION.SERVICES.UPON_REQUEST'
              }
            ]
          },
          {
            title: 'QUOTATION.SERVICES.AFTER_FLIGHT.TITLE',
            services: [
              {
                title: 'QUOTATION.SERVICES.AFTER_FLIGHT.LUGGAGES_PLUS',
                checked: false,
                comment: 'QUOTATION.SERVICES.UPON_REQUEST'
              },
              {
                title: 'QUOTATION.SERVICES.AFTER_FLIGHT.AIRPORT_TRANSFER',
                checked: false,
                comment: 'QUOTATION.SERVICES.UPON_REQUEST'
              },
              {
                title: 'QUOTATION.SERVICES.AFTER_FLIGHT.TERMINAL_VIP',
                checked: false,
                comment: 'QUOTATION.SERVICES.UPON_REQUEST'
              }
            ]
          }
        ];
        break;
    }

    switch (this.enquiry.type) {
      case EnumEnquiryType.business:
        this.form
          .get('observationsTrips')
          .setValue(await this.getTranslation('QUOTATION.OBSERVATIONS_TRIPS_TEXT_3'));
        break;
      case EnumEnquiryType.helico:
        this.form
          .get('observationsTrips')
          .setValue(await this.getTranslation('QUOTATION.OBSERVATIONS_TRIPS_TEXT_1'));
        break;
      case EnumEnquiryType.commercial:
        this.form
          .get('garantiesAndEngagements')
          .setValue(await this.getTranslation('QUOTATION.OUR_GUARANTIES_AND_ENGAGEMENTS_TEXT'));
        this.form
          .get('observationsTrips')
          .setValue(await this.getTranslation('QUOTATION.OBSERVATIONS_TRIPS_TEXT_2'));
        break;
      case EnumEnquiryType.cargo:
        this.form
          .get('garantiesAndEngagements')
          .setValue(await this.getTranslation('QUOTATION.OUR_GUARANTIES_AND_ENGAGEMENTS_TEXT'));
        this.form
          .get('observationsTrips')
          .setValue(await this.getTranslation('QUOTATION.OBSERVATIONS_TRIPS_TEXT_2'));
        break;
      default:
        this.form
          .get('observationsTrips')
          .setValue(await this.getTranslation('QUOTATION.OBSERVATIONS_TRIPS_TEXT_1'));
        break;
    }

    if (!this.aircraftInfos.length) {
      for (const defaultAircraftInfo of this.defaultAircraftInfos) {
        this.addCotationFormArrayItem('aircraftInfos');

        const lineFormGroup: FormGroup = this.aircraftInfos.at(
          this.aircraftInfos.length - 1
        ) as FormGroup;

        lineFormGroup.get('title').setValue(await this.getTranslation(defaultAircraftInfo?.title));
        lineFormGroup
          .get('subTitle')
          .setValue(await this.getTranslation(defaultAircraftInfo?.subTitle));
        lineFormGroup.get('hasTitle').setValue(defaultAircraftInfo?.hasTitle);
        lineFormGroup.get('hasSubTitle').setValue(defaultAircraftInfo?.hasSubTitle);
        lineFormGroup.get('type').setValue(defaultAircraftInfo?.type);
        lineFormGroup
          .get('placeholder')
          .setValue(await this.getTranslation(defaultAircraftInfo?.placeholder));
        lineFormGroup
          .get('value')
          .setValue(await this.getTranslation(defaultAircraftInfo?.value?.toString()));
      }
    }

    if (!this.priceInfos.length) {
      for (const defaultPriceInfo of this.defaultPriceInfos) {
        this.addCotationFormArrayItem('priceInfos');

        this.priceInfos
          .at(this.priceInfos.length - 1)
          .get('title')
          .setValue(await this.getTranslation(defaultPriceInfo.title));
        this.priceInfos
          .at(this.priceInfos.length - 1)
          .get('subTitle')
          .setValue(await this.getTranslation(defaultPriceInfo.subTitle));
        this.priceInfos
          .at(this.priceInfos.length - 1)
          .get('hasTitle')
          .setValue(defaultPriceInfo.hasTitle);
        this.priceInfos
          .at(this.priceInfos.length - 1)
          .get('hasSubTitle')
          .setValue(defaultPriceInfo.hasSubTitle);
        this.priceInfos
          .at(this.priceInfos.length - 1)
          .get('type')
          .setValue(defaultPriceInfo.type);
        this.priceInfos
          .at(this.priceInfos.length - 1)
          .get('placeholder')
          .setValue(await this.getTranslation(defaultPriceInfo.placeholder));
        this.priceInfos
          .at(this.priceInfos.length - 1)
          .get('value')
          .setValue(await this.getTranslation(defaultPriceInfo.value?.toString()));
      }
    }

    this.prefillVatFieldValue();

    setTimeout(async () => {
      if (!this.servicesSections.length) {
        for (const defaultServicesSection of this.defaultServicesSections) {
          this.addServicesSectionsFormArrayItem();

          this.servicesSections
            .at(this.servicesSections.length - 1)
            .get('title')
            .setValue(await this.getTranslation(defaultServicesSection.title));

          const servicesFormArray: FormArray = this.getServicesInSection(
            this.servicesSections.length - 1
          );

          for (const defaultService of defaultServicesSection.services) {
            this.addServiceItemToSectionsFormArray(this.servicesSections.length - 1);

            servicesFormArray
              .at(servicesFormArray.length - 1)
              .get('title')
              .setValue(await this.getTranslation(defaultService.title));
            servicesFormArray
              .at(servicesFormArray.length - 1)
              .get('checked')
              .setValue(defaultService.checked);
            servicesFormArray
              .at(servicesFormArray.length - 1)
              .get('comment')
              .setValue(await this.getTranslation(defaultService.comment));
          }
        }
      }
    }, 1000);
  }

  addCotationFormArrayItem(field: string): void {
    const formArray = this.form.get(field) as FormArray;

    formArray.push(
      new FormGroup({
        title: new FormControl(''),
        subTitle: new FormControl(''),
        hasTitle: new FormControl(true),
        hasSubTitle: new FormControl(false),
        type: new FormControl(''),
        placeholder: new FormControl(''),
        value: new FormControl('')
      })
    );
  }

  removeCotationFormArrayItem(field: string, i: number): void {
    if (confirm('Êtes-vous sûr de vouloir supprimer cette ligne ?')) {
      const formArray = this.form.get(field) as FormArray;

      formArray.removeAt(i);
    }
  }

  addServicesSectionsFormArrayItem(): void {
    this.servicesSections.push(
      new FormGroup({
        title: new FormControl(''),
        services: new FormArray([])
      })
    );
  }

  removeServiceItemFromSectionsFormArray(i: number, j: number): void {
    if (confirm('Êtes-vous sûr de vouloir supprimer ce service ?')) {
      this.getServicesInSection(i).removeAt(j);
    }
  }

  addServiceItemToSectionsFormArray(i: number): void {
    this.getServicesInSection(i).push(
      new FormGroup({
        title: new FormControl(''),
        checked: new FormControl(false),
        comment: new FormControl('')
      })
    );
  }

  removeServicesSectionsFormArrayItem(i: number): void {
    if (confirm('Êtes-vous sûr de vouloir supprimer cette section ?')) {
      this.servicesSections.removeAt(i);
    }
  }

  removeFormArrayItem(field: string, i: number): void {
    (this.form.get(field) as FormArray).removeAt(i);
  }

  updateAirport(legIndex: number, airportField: string) {
    this.setTimezoneForAirport(legIndex, airportField);
    this.generateGoogleMapStaticUrl();

    this.prefillVatFieldValue();
  }

  async setTimezoneForAirports(legIndex: number) {
    for (const airportField of ['airportDepart', 'airportDestination']) {
      this.setTimezoneForAirport(legIndex, airportField);
    }
  }

  async setTimezoneForAirport(i: number, airportField: string) {
    if (this.form.value.trips[i][airportField]) {
      let legDate: string = this.form.value.trips[i].date;

      if (this.form.value.trips[i].time) {
        legDate += 'T' + this.form.value.trips[i].time;
      } else {
        legDate += 'T12:00';
      }

      if (typeof this.airportsObj[this.form.value.trips[i][airportField]] === 'undefined') {
        await this.loadAirport(this.form.value.trips[i][airportField]);
      }

      const result: object = await this.remoteService.getTimezoneFromCoordinates(
        this.airportsObj[this.form.value.trips[i][airportField]].latitude,
        this.airportsObj[this.form.value.trips[i][airportField]].longitude,
        new Date(legDate).getTime() / 1000
      );

      if (result) {
        const timezoneRawOffset: number = result['rawOffset'] + result['dstOffset'];
        const timezoneGmt: number = timezoneRawOffset / 3600;

        this.getTrip(i)
          .get(airportField + 'TimezoneId')
          .setValue(result['timeZoneId']);
        this.getTrip(i)
          .get(airportField + 'TimezoneRawOffset')
          .setValue(timezoneRawOffset);
        this.getTrip(i)
          .get(airportField + 'TimezoneGmt')
          .setValue(timezoneGmt >= 0 ? '+' + timezoneGmt : timezoneGmt?.toString());
      }
    } else {
      this.getTrip(i)
        .get(airportField + 'TimezoneId')
        .setValue('');
      this.getTrip(i)
        .get(airportField + 'TimezoneRawOffset')
        .setValue('');
      this.getTrip(i)
        .get(airportField + 'TimezoneGmt')
        .setValue('');
    }
  }

  async loadClient(): Promise<void> {
    if (this.enquiry.clientId) {
      this.client = await this.pipedriveService.getOrganization(
        typeof this.enquiry.clientId === 'number'
          ? this.enquiry.clientId.toString()
          : this.enquiry.clientId
      );
    }

    if (this.enquiry.contactId) {
      this.contact = await this.pipedriveService.getPerson(this.enquiry.contactId);
    }
  }

  loadUser(): void {
    if (this.enquiry.processedBy) {
      this.subscriptions.add(
        this.userService.getFromId(this.enquiry.processedBy).subscribe((user: IUser) => {
          this.user = user;

          if (this.user?.firstname) {
            this.form.get('agentFirstname').setValue(this.user.firstname);
          }
          if (this.user?.lastname) {
            this.form.get('agentLastname').setValue(this.user.lastname);
          }
          if (this.user?.phone) {
            this.form.get('agentPhone').setValue(this.user.phone);
          }
          if (this.user?.email) {
            this.form.get('agentEmail').setValue(this.user.email);
          }
        })
      );
    }
  }

  loadAircraftModel(aircraftModelId: string): void {
    if (aircraftModelId) {
      this.subscriptions.add(
        this.aircraftModelService
          .getFromId(aircraftModelId)
          .subscribe((aircraftModel: IAircraftModel) => {
            this.aircraftModel = aircraftModel;

            if (this.aircraftModel && !this.currentAircraftModelInitiated) {
              this.currentAircraftModelInitiated = true;

              if (this.aircraftModel.imageUrl && !this.form.value.image1Url) {
                this.form.get('image1Url').setValue(this.aircraftModel.imageUrl);
              }
              if (this.aircraftModel.imagePlanUrl && !this.form.value.image2Url) {
                this.form.get('image2Url').setValue(this.aircraftModel.imagePlanUrl);
              }
              if (this.aircraftModel.imageInsideUrl && !this.form.value.image3Url) {
                this.form.get('image3Url').setValue(this.aircraftModel.imageInsideUrl);
              }
            }
          })
      );
    }
  }

  async loadData(): Promise<void> {
    if (this.isLogged) {
      await this.loadQuotation();
      await this.loadEnquiry();
      this.loadAircraftModels();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  async loadEnquiryQuotations(): Promise<void> {
    this.quotations = await this.remoteService.getEnquiryCotations(this.enquiryId);
  }

  loadEnquiry(): void {
    if (this.enquiryId) {
      this.subscriptions.add(
        this.enquiryService.getFromId(this.enquiryId).subscribe(async (enquiry: IEnquiry) => {
          this.enquiry = enquiry;

          if (this.enquiry) {
            this.breadcrumbsService.setCurrentItem({
              id: this.enquiry.id,
              text: getEnquiryBreadcrumbTitle(this.enquiry)
            });
          }
        })
      );
    }
  }

  loadEnquiryOffers(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.subscriptions.add(
        this.enquiryOfferService
          .getAllForEnquiry(this.enquiryId)
          .subscribe(async (enquiryOffers: IEnquiryOffer[]) => {
            this.enquiryOffers = enquiryOffers;

            this.setCurrentCotation();

            resolve();
          })
      );
    });
  }

  loadEnquiryCotations(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.subscriptions.add(
        this.enquiryCotationService
          .getAllForEnquiry(this.enquiryId)
          .subscribe(async (enquiryCotations: IEnquiryCotation[]) => {
            this.enquiryCotations = enquiryCotations;

            if (this.enquiryCotations.length && !this.enquiryOffers.length) {
              await this.loadEnquiryOffers();
            }

            resolve();
          })
      );
    });
  }

  async loadQuotation(): Promise<void> {
    const doc = await this.remoteService.getDocument('quotations', this.quotationId);

    this.quotation = doc as IQuotation;

    this.setQuotation();
  }

  async setQuotation(): Promise<void> {
    if (this.form && this.quotation) {
      this.enquiryId = this.quotation.enquiryId;

      await this.loadEnquiry();

      this.form.get('enquiryId').setValue(this.quotation.enquiryId);
      this.form.get('enquiryType').setValue(this.quotation.enquiryType);
      this.form.get('companyTitle').setValue(this.quotation.companyTitle);
      this.form.get('title').setValue(this.quotation.title);
      this.form.get('language').setValue(this.quotation.language);

      await this.loadLanguageTranslation(this.form.value.language);

      this.form.get('versionNumber').setValue(this.quotation.versionNumber);

      this.form.get('clientId').setValue(this.quotation.clientId);
      this.form.get('clientTitle').setValue(this.quotation.clientTitle);

      this.form.get('contactId').setValue(this.quotation.contactId);
      this.form.get('contactFirstname').setValue(this.quotation.contactFirstname);
      this.form.get('contactLastname').setValue(this.quotation.contactLastname);
      this.form.get('contactPhone').setValue(this.quotation.contactPhone);
      this.form.get('contactEmail').setValue(this.quotation.contactEmail);

      this.form.get('agentId').setValue(this.quotation.agentId);
      this.form.get('agentFirstname').setValue(this.quotation.agentFirstname);
      this.form.get('agentLastname').setValue(this.quotation.agentLastname);
      this.form.get('agentPhone').setValue(this.quotation.agentPhone);
      this.form.get('agentEmail').setValue(this.quotation.agentEmail);

      this.form.get('notes').setValue(this.quotation.notes);
      this.form
        .get('paymentConditionsSettings')
        .get('firstDeposit')
        .get('percent')
        .setValue(this.quotation.paymentConditionsSettings.firstDeposit.percent);
      this.form
        .get('paymentConditionsSettings')
        .get('firstDeposit')
        .get('text')
        .setValue(this.quotation.paymentConditionsSettings.firstDeposit.text);
      this.form
        .get('paymentConditionsSettings')
        .get('lastDeposit')
        .get('percent')
        .setValue(this.quotation.paymentConditionsSettings.lastDeposit.percent);
      this.form
        .get('paymentConditionsSettings')
        .get('lastDeposit')
        .get('daysBefore')
        .setValue(this.quotation.paymentConditionsSettings.lastDeposit.daysBefore);
      this.form
        .get('paymentConditionsSettings')
        .get('lastDeposit')
        .get('text')
        .setValue(this.quotation.paymentConditionsSettings.lastDeposit.text);

      this.form
        .get('cancellingConditionsSettings')
        .get('firstStep')
        .get('percent')
        .setValue(this.quotation.cancellingConditionsSettings.firstStep.percent);
      this.form
        .get('cancellingConditionsSettings')
        .get('firstStep')
        .get('text')
        .setValue(this.quotation.cancellingConditionsSettings.firstStep.text);
      this.form
        .get('cancellingConditionsSettings')
        .get('firstStep')
        .get('daysBefore')
        .setValue(this.quotation.cancellingConditionsSettings.firstStep.daysBefore);
      this.form
        .get('cancellingConditionsSettings')
        .get('secondStep')
        .get('percent')
        .setValue(this.quotation.cancellingConditionsSettings.secondStep.percent);
      this.form
        .get('cancellingConditionsSettings')
        .get('secondStep')
        .get('daysBeforeStart')
        .setValue(this.quotation.cancellingConditionsSettings.secondStep.daysBeforeStart);
      this.form
        .get('cancellingConditionsSettings')
        .get('secondStep')
        .get('daysBeforeEnd')
        .setValue(this.quotation.cancellingConditionsSettings.secondStep.daysBeforeEnd);
      this.form
        .get('cancellingConditionsSettings')
        .get('secondStep')
        .get('text')
        .setValue(this.quotation.cancellingConditionsSettings.secondStep.text);
      this.form
        .get('cancellingConditionsSettings')
        .get('thirdStep')
        .get('percent')
        .setValue(this.quotation.cancellingConditionsSettings.thirdStep.percent);
      this.form
        .get('cancellingConditionsSettings')
        .get('thirdStep')
        .get('daysBeforeStart')
        .setValue(this.quotation.cancellingConditionsSettings.thirdStep.daysBeforeStart);
      this.form
        .get('cancellingConditionsSettings')
        .get('thirdStep')
        .get('hoursBeforeEnd')
        .setValue(this.quotation.cancellingConditionsSettings.thirdStep.hoursBeforeEnd);
      this.form
        .get('cancellingConditionsSettings')
        .get('thirdStep')
        .get('text')
        .setValue(this.quotation.cancellingConditionsSettings.thirdStep.text);
      this.form
        .get('cancellingConditionsSettings')
        .get('fourthStep')
        .get('percent')
        .setValue(this.quotation.cancellingConditionsSettings.fourthStep.percent);
      this.form
        .get('cancellingConditionsSettings')
        .get('fourthStep')
        .get('hoursBefore')
        .setValue(this.quotation.cancellingConditionsSettings.fourthStep.hoursBefore);
      this.form
        .get('cancellingConditionsSettings')
        .get('fourthStep')
        .get('text')
        .setValue(this.quotation.cancellingConditionsSettings.fourthStep.text);

      this.form.get('image1Url').setValue(this.quotation.image1Url);
      this.form.get('image2Url').setValue(this.quotation.image2Url);
      this.form.get('image3Url').setValue(this.quotation.image3Url);

      this.form.get('image1ZoomLevel').setValue(this.quotation.image1ZoomLevel);
      this.form.get('image2ZoomLevel').setValue(this.quotation.image2ZoomLevel);
      this.form.get('image3ZoomLevel').setValue(this.quotation.image3ZoomLevel);

      this.form.get('garantiesAndEngagements').setValue(this.quotation.garantiesAndEngagements);
      this.form.get('observationsTrips').setValue(this.quotation.observationsTrips);
      this.form.get('totalAmountPayment').setValue(this.quotation.totalAmountPayment);
      this.form
        .get('totalAmountPaymentCurrency')
        .setValue(this.quotation.totalAmountPaymentCurrency);

      this.setPaymentAndCancellingTexts();

      for (let j = 0; j < this.quotation.trips.length; j++) {
        this.addTrip();

        const tripFormGroup = this.getTrip(j);

        tripFormGroup.get('date').setValue(this.quotation.trips[j].date);
        tripFormGroup.get('time').setValue(this.quotation.trips[j].time);
        tripFormGroup.get('arrivalTime').setValue(this.quotation.trips[j].arrivalTime);
        tripFormGroup.get('airportDepart').setValue(this.quotation.trips[j].airportDepart);
        tripFormGroup.get('airportDepartTax').setValue(this.quotation.trips[j].airportDepartTax);
        tripFormGroup
          .get('airportDepartTimezoneId')
          .setValue(this.quotation.trips[j].airportDepartTimezoneId);
        tripFormGroup
          .get('airportDepartTimezoneRawOffset')
          .setValue(this.quotation.trips[j].airportDepartTimezoneRawOffset);
        tripFormGroup
          .get('airportDepartTimezoneGmt')
          .setValue(this.quotation.trips[j].airportDepartTimezoneGmt);
        tripFormGroup
          .get('airportDestination')
          .setValue(this.quotation.trips[j].airportDestination);
        tripFormGroup
          .get('airportDestinationTimezoneId')
          .setValue(this.quotation.trips[j].airportDestinationTimezoneId);
        tripFormGroup
          .get('airportDestinationTimezoneRawOffset')
          .setValue(this.quotation.trips[j].airportDestinationTimezoneRawOffset);
        tripFormGroup
          .get('airportDestinationTimezoneGmt')
          .setValue(this.quotation.trips[j].airportDestinationTimezoneGmt);
        tripFormGroup.get('passengersTotal').setValue(this.quotation.trips[j].passengersTotal);
        tripFormGroup
          .get('passengersBusiness')
          .setValue(this.quotation.trips[j].passengersBusiness);
        tripFormGroup.get('weight').setValue(this.quotation.trips[j].weight);
        tripFormGroup.get('volume').setValue(this.quotation.trips[j].volume);
        tripFormGroup.get('natureFret').setValue(this.quotation.trips[j].natureFret);
        tripFormGroup.get('dimensions').setValue(this.quotation.trips[j].dimensions);
        tripFormGroup
          .get('hasFuelStopOver')
          .setValue(this.quotation.trips[j].hasFuelStopOver ? true : false);
        tripFormGroup
          .get('fuelStopOverAirport')
          .setValue(this.quotation.trips[j].fuelStopOverAirport);
        tripFormGroup
          .get('hasCommercialStopOver')
          .setValue(this.quotation.trips[j].hasCommercialStopOver ? true : false);
        tripFormGroup
          .get('commercialStopOverAirport')
          .setValue(this.quotation.trips[j].commercialStopOverAirport);
        tripFormGroup
          .get('passengersCommercialStop')
          .setValue(this.quotation.trips[j].passengersCommercialStop);
        tripFormGroup.get('distanceInKm').setValue(this.quotation.trips[j].distanceInKm);
        tripFormGroup.get('speedInKts').setValue(this.quotation.trips[j].speedInKts);
        tripFormGroup.get('flyTimeInHours').setValue(this.quotation.trips[j].flyTimeInHours);

        if (
          !tripFormGroup.get('airportDepartTimezoneId').value ||
          !tripFormGroup.get('airportDestinationTimezoneId').value
        ) {
          this.setTimezoneForAirports(j);
        }

        for (const field in (tripFormGroup as FormGroup).controls) {
          if (typeof tripFormGroup.value[field] === 'undefined') {
            tripFormGroup.get(field).setValue(null);
          }
        }
      }

      for (const fieldCotation of ['aircraftInfos', 'priceInfos']) {
        const formArray: FormArray = this.form.get(fieldCotation) as FormArray;

        for (const cotationItem of this.quotation[fieldCotation]) {
          this.addCotationFormArrayItem(fieldCotation);

          const titleArr: string[] = cotationItem.title.split('<br>');

          let title: string = '';
          let subTitle: string = '';
          let hasTitle: boolean = false;
          let hasSubTitle: boolean = false;

          for (const str of titleArr) {
            if (str.match('<strong>')) {
              subTitle = str.replace('<strong>', '').replace('</strong>', '');
              hasSubTitle = true;
            } else {
              title = str;
              hasTitle = true;
            }
          }

          formArray
            .at(formArray.length - 1)
            .get('title')
            .setValue(title);
          formArray
            .at(formArray.length - 1)
            .get('subTitle')
            .setValue(subTitle);
          formArray
            .at(formArray.length - 1)
            .get('hasTitle')
            .setValue(hasTitle);
          formArray
            .at(formArray.length - 1)
            .get('hasSubTitle')
            .setValue(hasSubTitle);
          formArray
            .at(formArray.length - 1)
            .get('type')
            .setValue(cotationItem.type);
          formArray
            .at(formArray.length - 1)
            .get('value')
            .setValue(cotationItem.value);
        }
      }

      for (const servicesSection of this.quotation.servicesSections) {
        this.addServicesSectionsFormArrayItem();

        this.servicesSections
          .at(this.servicesSections.length - 1)
          .get('title')
          .setValue(servicesSection.title);

        const servicesFormArray: FormArray = this.getServicesInSection(
          this.servicesSections.length - 1
        );

        for (const service of servicesSection.services) {
          this.addServiceItemToSectionsFormArray(this.servicesSections.length - 1);

          servicesFormArray
            .at(servicesFormArray.length - 1)
            .get('title')
            .setValue(service.title);
          servicesFormArray
            .at(servicesFormArray.length - 1)
            .get('checked')
            .setValue(service.checked);
          servicesFormArray
            .at(servicesFormArray.length - 1)
            .get('comment')
            .setValue(service.comment);
        }
      }

      this.generateGoogleMapStaticUrl();

      this.form.enable();
    }
  }

  async setPaymentAndCancellingTexts(force: boolean = false): Promise<void> {
    if (
      force ||
      !this.form.value.paymentConditionsSettings.firstDeposit.text ||
      this.form.value.paymentConditionsSettings.firstDeposit.text === ''
    ) {
      this.form
        .get('paymentConditionsSettings')
        .get('firstDeposit')
        .get('text')
        .setValue(await this.getTranslation('QUOTATION.PAYMENT_CONDITIONS_1'));
    }
    if (
      force ||
      !this.form.value.paymentConditionsSettings.lastDeposit.text ||
      this.form.value.paymentConditionsSettings.lastDeposit.text === ''
    ) {
      this.form
        .get('paymentConditionsSettings')
        .get('lastDeposit')
        .get('text')
        .setValue(await this.getTranslation('QUOTATION.PAYMENT_CONDITIONS_2'));
    }

    if (
      force ||
      !this.form.value.cancellingConditionsSettings.firstStep.text ||
      this.form.value.cancellingConditionsSettings.firstStep.text === ''
    ) {
      this.form
        .get('cancellingConditionsSettings')
        .get('firstStep')
        .get('text')
        .setValue(await this.getTranslation('QUOTATION.CANCELLING_CONDITIONS_1'));
    }
    if (
      force ||
      !this.form.value.cancellingConditionsSettings.secondStep.text ||
      this.form.value.cancellingConditionsSettings.secondStep.text === ''
    ) {
      this.form
        .get('cancellingConditionsSettings')
        .get('secondStep')
        .get('text')
        .setValue(await this.getTranslation('QUOTATION.CANCELLING_CONDITIONS_2'));
    }
    if (
      force ||
      !this.form.value.cancellingConditionsSettings.thirdStep.text ||
      this.form.value.cancellingConditionsSettings.thirdStep.text === ''
    ) {
      this.form
        .get('cancellingConditionsSettings')
        .get('thirdStep')
        .get('text')
        .setValue(await this.getTranslation('QUOTATION.CANCELLING_CONDITIONS_3'));
    }
    if (
      force ||
      !this.form.value.cancellingConditionsSettings.fourthStep.text ||
      this.form.value.cancellingConditionsSettings.fourthStep.text === ''
    ) {
      this.form
        .get('cancellingConditionsSettings')
        .get('fourthStep')
        .get('text')
        .setValue(await this.getTranslation('QUOTATION.CANCELLING_CONDITIONS_4'));
    }
  }

  async submitForm(): Promise<void> {
    this.form.markAsTouched();

    if (this.form.valid) {
      let data = Object.assign({}, this.form.value);

      for (const field in data) {
        if (typeof data[field] == 'undefined') {
          data[field] = null;
        }

        for (const subfield in data[field]) {
          if (typeof data[field][subfield] == 'undefined') {
            data[field][subfield] = null;
          }

          for (const subsubfield in data[field][subfield]) {
            if (typeof data[field][subfield][subsubfield] == 'undefined') {
              data[field][subfield][subsubfield] = null;
            }
          }
        }
      }

      data['contactTitle'] = data['contactFirstname'] + ' ' + data['contactLastname'];
      data['agentTitle'] = data['agentFirstname'] + ' ' + data['agentLastname'];

      for (const fieldCotation of ['aircraftInfos', 'priceInfos']) {
        for (const cotationItem of data[fieldCotation]) {
          // Format title field
          let titleArr: string[] = [];

          if (cotationItem.hasTitle) {
            titleArr.push(cotationItem.title);
          }
          if (cotationItem.hasSubTitle) {
            titleArr.push('<strong>' + cotationItem.subTitle + '</strong>');
          }

          cotationItem.title = titleArr.join('<br>');

          delete cotationItem.hasTitle;
          delete cotationItem.hasSubTitle;
          delete cotationItem.subTitle;
          delete cotationItem.placeholder;
        }
      }

      data.paymentConditions = '';

      if (data.paymentConditionsSettings.firstDeposit.percent) {
        let text = data.paymentConditionsSettings.firstDeposit.text;

        text = text.replace('%1', data.paymentConditionsSettings.firstDeposit.percent);
        text = text.replace(
          '%MONTANT_STEP_1',
          this.getValuePercentOfTotalAmount(data.paymentConditionsSettings.firstDeposit.percent)
        );

        data.paymentConditions += text + '\n';
      }
      if (data.paymentConditionsSettings.lastDeposit.percent) {
        let text = data.paymentConditionsSettings.lastDeposit.text;

        text = text.replace('%2', data.paymentConditionsSettings.lastDeposit.percent);
        text = text.replace('%3', data.paymentConditionsSettings.lastDeposit.daysBefore);
        text = text.replace(
          '%MONTANT_STEP_2',
          this.getValuePercentOfTotalAmount(data.paymentConditionsSettings.lastDeposit.percent)
        );

        data.paymentConditions += text + '\n';
      }

      const cancellingConditions: {
        firstStep: string;
        secondStep: string;
        thirdStep: string;
        fourthStep: string;
      } = {
        firstStep: await this.getTranslation('QUOTATION.CANCELLING_CONDITIONS_1'),
        secondStep: await this.getTranslation('QUOTATION.CANCELLING_CONDITIONS_2'),
        thirdStep: await this.getTranslation('QUOTATION.CANCELLING_CONDITIONS_3'),
        fourthStep: await this.getTranslation('QUOTATION.CANCELLING_CONDITIONS_4')
      };

      data.cancellingConditions = '';

      if (data.cancellingConditionsSettings.firstStep.percent) {
        let text = data.cancellingConditionsSettings.firstStep.text;

        text = text.replace('%1', data.cancellingConditionsSettings.firstStep.percent);
        text = text.replace('%2', data.cancellingConditionsSettings.firstStep.daysBefore);
        text = text.replace(
          '%MONTANT_STEP_1',
          this.getValuePercentOfTotalAmount(data.cancellingConditionsSettings.firstStep.percent)
        );

        data.cancellingConditions += text + '\n';
      }
      if (data.cancellingConditionsSettings.secondStep.percent) {
        let text = data.cancellingConditionsSettings.secondStep.text;

        text = text.replace('%3', data.cancellingConditionsSettings.secondStep.percent);
        text = text.replace('%4', data.cancellingConditionsSettings.secondStep.daysBeforeStart);
        text = text.replace('%5', data.cancellingConditionsSettings.secondStep.daysBeforeEnd);

        text = text.replace(
          '%MONTANT_STEP_2',
          this.getValuePercentOfTotalAmount(data.cancellingConditionsSettings.secondStep.percent)
        );

        data.cancellingConditions += text + '\n';
      }
      if (data.cancellingConditionsSettings.thirdStep.percent) {
        let text = data.cancellingConditionsSettings.thirdStep.text;

        text = text.replace('%6', data.cancellingConditionsSettings.thirdStep.percent);
        text = text.replace('%7', data.cancellingConditionsSettings.thirdStep.daysBeforeStart);
        text = text.replace('%8', data.cancellingConditionsSettings.thirdStep.hoursBeforeEnd);

        text = text.replace(
          '%MONTANT_STEP_3',
          this.getValuePercentOfTotalAmount(data.cancellingConditionsSettings.thirdStep.percent)
        );

        data.cancellingConditions += text + '\n';
      }
      if (data.cancellingConditionsSettings.fourthStep.percent) {
        let text = data.cancellingConditionsSettings.fourthStep.text;

        text = text.replace('%9', data.cancellingConditionsSettings.fourthStep.percent);
        text = text.replace('%10', data.cancellingConditionsSettings.fourthStep.hoursBefore);

        text = text.replace(
          '%MONTANT_STEP_4',
          this.getValuePercentOfTotalAmount(data.cancellingConditionsSettings.fourthStep.percent)
        );

        data.cancellingConditions += text + '\n';
      }

      this.sending = true;

      this.form.disable();

      if (this.quotationId) {
        this.remoteService
          .updateDocumentToCollection('quotations', this.quotationId, data)
          .then(() => {
            this.sending = false;
            this.redirectAfterSaving();
          })
          .catch(err => {
            this.sending = false;
            this.form.enable();

            alert(err.message);
          });
      } else {
        data.status = EnumQuotationStatus.draft;

        this.remoteService
          .addDocumentToCollection('quotations', data)
          .then((docId: string) => {
            this.quotationId = docId;

            this.sending = false;
            this.redirectAfterSaving();
          })
          .catch(err => {
            this.sending = false;
            this.form.enable();

            alert(err.message);
          });
      }
    }
  }

  redirectAfterSaving(): void {
    this.router.navigate(['/admin/quotations/' + this.quotationId]);
  }

  async setValueToFormControl($event: {
    fields: {
      name: string;
      value: string;
    }[];
  }): Promise<void> {
    for (let field of $event.fields) {
      const nameList = field.name.split('.');

      let formControl: AbstractControl = this.form;
      for (let name of nameList) {
        formControl = formControl.get(name);
      }

      formControl.setValue(field.value);

      if (
        [
          'airportDepart',
          'airportDestination',
          'fuelStopOverAirport',
          'commercialStopOverAirport'
        ].indexOf(nameList[nameList.length - 1]) !== -1
      ) {
        await this.loadAirport(field.value);
      }

      if (['airportDepart', 'airportDestination'].indexOf(nameList[nameList.length - 1]) !== -1) {
        this.updateAirport(parseInt(nameList[1]), nameList[nameList.length - 1]);
      }

      formControl.markAsTouched();
      formControl.updateValueAndValidity();
    }
  }

  async loadAirport(airportId: string): Promise<void> {
    if (airportId && !this.airportsObj[airportId]) {
      const doc: object = await this.remoteService.getDocument('airports', airportId);

      const airport = doc as IAirport;

      this.airportsObj[airport.id] = airport;

      for (let i = 0; i < this.trips.value.length; i++) {
        if (
          this.trips.value[i].airportDepart === airportId ||
          this.trips.value[i].airportDestination === airportId
        ) {
          this.tripTimesUpdated(i);
        }
      }
    }
  }

  updateLegDate(i: number): void {
    this.setTimezoneForAirports(i);
  }

  // updateFlyTime(i: number): void {
  //   const departureTime: number = this.convertTimeToHour(this.form.value.trips[i].time)
  //   let arrivalTime: number = this.convertTimeToHour(this.form.value.trips[i].arrivalTime)

  //   if (arrivalTime <= departureTime) {
  //     // Arrival is the day after
  //     arrivalTime += 24
  //   }

  //   const tripsFormArray = this.form.get('trips') as FormArray

  //   tripsFormArray
  //   .at(i)
  //   .get('flyTimeInHours')
  //   .setValue((isNaN(departureTime) || isNaN(arrivalTime)) ? null : this.convertHourToTime(arrivalTime - departureTime))
  // }

  convertTimeToHour(time: string): number {
    const timeSplitted: string[] = time.split(':');

    const hours = parseInt(timeSplitted[0]);
    const minutes = parseInt(timeSplitted[1]);

    return (hours * 60 + minutes) / 60;
  }

  convertHourToTime(value: number): string {
    const totalMinutes = value * 60;
    const hours = totalMinutes / 60;
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.round(minutes);

    return addZeroToDigit(rhours) + ':' + addZeroToDigit(rminutes);
  }

  async generateGoogleMapStaticUrl(): Promise<void> {
    let url: string = 'https://maps.googleapis.com/maps/api/staticmap?';

    url += 'size=300x220&scale=2';
    url += '&language=' + this.form.value.language;
    url += '&key=AIzaSyDyPoaFu7WvRZjqmSlQq0QdWA1FS_RLMQw';
    url += '&style=feature:road|visibility:off|';
    url += '&path=color:0x1c4d6bff|weight:2|geodesic:true|';

    const coordinates: string[] = [];

    if (this.form.value.trips) {
      for (const trip of this.form.value.trips) {
        for (const airportField of ['airportDepart', 'airportDestination']) {
          if (typeof this.airportsObj[trip[airportField]] === 'undefined') {
            await this.loadAirport(trip[airportField]);
          }

          if (trip[airportField] && this.airportsObj[trip[airportField]]) {
            coordinates.push(
              this.airportsObj[trip[airportField]].latitude +
                ',' +
                this.airportsObj[trip[airportField]].longitude
            );
          }
        }
      }
    }

    if (coordinates.length) {
      this.form.get('googleMapStaticUrl').setValue(url + coordinates.join('|'));
    } else {
      this.form.get('googleMapStaticUrl').setValue(null);
    }
  }

  formatPrice(value: number, currency: string = 'EUR'): string {
    const formatter = new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2
    });

    return formatter.format(value);
  }

  triggerInputFile(): void {
    if (this.form.enabled) {
      document.getElementById('imageUrlInput').click();
    }
  }

  triggerInputFileForAircraftModel(): void {
    if (this.form.enabled) {
      document.getElementById('aircraftModelImageInput').click();
    }
  }

  changeInputFile(fileInput: any): void {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.upload(fileInput.target.files[0], 'quotation');
    }
  }

  changeInputFileForAircraftModel(fileInput: any): void {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.upload(fileInput.target.files[0], 'aircraftModel');
    }
  }

  upload(file: File, target: 'quotation' | 'aircraftModel'): Promise<void> {
    return new Promise((resolve, reject) => {
      this.loaderService.presentLoader();

      this.form.disable();

      let folder: string = 'images';
      switch (target) {
        case 'quotation':
          folder = 'quotations/images';
          break;
        case 'aircraftModel':
          folder = 'aircraft-models/images';
          break;
      }

      const result = this.remoteService.upload(
        folder,
        this.remoteService.generateRandomId(),
        file,
        'file'
      );

      result['task']
        .snapshotChanges()
        .pipe(
          finalize(() => {
            result['ref'].getDownloadURL().subscribe(async (downloadUrl: string) => {
              switch (target) {
                case 'quotation':
                  this.setCurrentImageFieldValue(downloadUrl);

                  (<HTMLInputElement>document.getElementById('imageUrlInput')).value = '';
                  break;
                case 'aircraftModel':
                  if (this.selectedImageFieldForAircraftModel) {
                    await this.updateImageFieldValueForAircraftModel(
                      this.selectedImageFieldForAircraftModel,
                      downloadUrl
                    );

                    if (!this.form.get(this.selectedImageField + 'Url')?.value) {
                      this.setCurrentImageFieldValue(downloadUrl);
                    }
                  }

                  (<HTMLInputElement>document.getElementById('aircraftModelImageInput')).value = '';
                  break;
              }

              await this.loaderService.hideLoaderOnSuccess();

              this.form.enable();

              this.form.updateValueAndValidity();
            });
          })
        )
        .subscribe();
    });
  }

  deleteImage(fileFieldName: string): void {
    if (confirm('Êtes-vous sûr de vouloir supprimer cette image ?')) {
      this.form.get(fileFieldName).setValue(null);
    }
  }

  async changedLanguage(): Promise<void> {
    this.generateGoogleMapStaticUrl();

    this.form.get('notes').setValue(await this.getTranslation('QUOTATION.NOTES'));

    switch (this.enquiry.type) {
      case EnumEnquiryType.business:
        this.form
          .get('observationsTrips')
          .setValue(await this.getTranslation('QUOTATION.OBSERVATIONS_TRIPS_TEXT_3'));
        break;
      case EnumEnquiryType.helico:
        this.form
          .get('observationsTrips')
          .setValue(await this.getTranslation('QUOTATION.OBSERVATIONS_TRIPS_TEXT_1'));
        break;
      case EnumEnquiryType.commercial:
        this.form
          .get('garantiesAndEngagements')
          .setValue(await this.getTranslation('QUOTATION.OUR_GUARANTIES_AND_ENGAGEMENTS_TEXT'));
        this.form
          .get('observationsTrips')
          .setValue(await this.getTranslation('QUOTATION.OBSERVATIONS_TRIPS_TEXT_2'));
        break;
      case EnumEnquiryType.cargo:
        this.form
          .get('garantiesAndEngagements')
          .setValue(await this.getTranslation('QUOTATION.OUR_GUARANTIES_AND_ENGAGEMENTS_TEXT'));
        this.form
          .get('observationsTrips')
          .setValue(await this.getTranslation('QUOTATION.OBSERVATIONS_TRIPS_TEXT_2'));
        break;
      default:
        this.form
          .get('observationsTrips')
          .setValue(await this.getTranslation('QUOTATION.OBSERVATIONS_TRIPS_TEXT_1'));
        break;
    }

    if (this.form.value.language) {
      let aircraftCompiled: IAircraftCompiled;

      if (this.selectedCotation && this.selectedCotation?.aircraftCompiled) {
        aircraftCompiled = this.selectedCotation?.aircraftCompiled;
      } else if (this.selectedOffer && this.selectedOffer.aircraftCompiled) {
        aircraftCompiled = this.selectedOffer.aircraftCompiled;
      }

      for (let i = 0; i < this.defaultAircraftInfos.length; i++) {
        if (this.aircraftInfos.at(i)) {
          for (const field of ['title', 'subTitle', 'placeholder', 'value']) {
            if (
              ![EnumEnquiryType.helico, EnumEnquiryType.business, EnumEnquiryType.cargo].includes(
                this.enquiry.type
              ) &&
              i === 1 &&
              field === 'value'
            ) {
              const xSeatsStr: string = await this.getTranslation('QUOTATION.AIRCRAFT.X_SEATS');

              this.aircraftInfos
                .at(i)
                .get(field)
                .setValue(
                  aircraftCompiled
                    ? xSeatsStr.replace('%s', addZeroToDigit(aircraftCompiled.seatTotal))
                    : ''
                );
            } else if (this.defaultAircraftInfos[i][field]) {
              this.aircraftInfos
                .at(i)
                .get(field)
                .setValue(await this.getTranslation(this.defaultAircraftInfos[i][field]));
            }
          }
        }
      }

      const passengersStrPlural: string = await this.getTranslation('QUOTATION.PRICE.PASSENGERS');
      const passengersStrSingular: string = await this.getTranslation('QUOTATION.PRICE.PASSENGER');

      if (this.selectedCotation) {
        this.form.get('totalAmountPayment').setValue(this.selectedCotation?.sellingPriceInCurrency);
        this.form.get('totalAmountPaymentCurrency').setValue(this.selectedCotation?.currency);
      }

      for (let i = 0; i < this.defaultPriceInfos.length; i++) {
        if (this.priceInfos.at(i)) {
          for (const field of ['title', 'subTitle', 'placeholder', 'value']) {
            if (this.defaultPriceInfos[i][field]) {
              if (i === 0) {
                switch (this.enquiry.type) {
                  case EnumEnquiryType.business:
                    this.priceInfos
                      .at(0)
                      .get('value')
                      .setValue(
                        this.formatPrice(
                          this.selectedCotation?.sellingPriceInCurrency,
                          this.selectedCotation?.currency
                        )
                      );
                    this.priceInfos
                      .at(1)
                      .get('title')
                      .setValue(
                        this.selectedCotation?.nbPax > 1
                          ? passengersStrPlural
                          : passengersStrSingular
                      );
                    this.priceInfos
                      .at(1)
                      .get('value')
                      .setValue(addZeroToDigit(this.selectedCotation?.nbPax));
                    this.priceInfos
                      .at(this.priceInfos.length - 1)
                      .get('value')
                      .setValue(
                        this.formatPrice(
                          this.selectedCotation?.sellingPriceInCurrency,
                          this.selectedCotation?.currency
                        )
                      );
                    break;
                  case EnumEnquiryType.cargo:
                    this.priceInfos
                      .at(0)
                      .get('title')
                      .setValue(
                        await this.getTranslation(
                          'QUOTATION.PRICE.PRICE_EXCLUDING_TAX_FOR_AIRCRAFT'
                        )
                      );
                    this.priceInfos
                      .at(0)
                      .get('value')
                      .setValue(
                        this.formatPrice(
                          this.selectedCotation?.sellingPriceInCurrency,
                          this.selectedCotation?.currency
                        )
                      );
                    this.priceInfos.at(0).get('hasSubTitle').setValue(false);
                    this.priceInfos
                      .at(this.priceInfos.length - 1)
                      .get('value')
                      .setValue(
                        this.formatPrice(
                          this.selectedCotation?.sellingPriceInCurrency,
                          this.selectedCotation?.currency
                        )
                      );
                    break;
                  case EnumEnquiryType.commercial:
                  default:
                    this.priceInfos
                      .at(0)
                      .get('value')
                      .setValue(
                        this.formatPrice(
                          this.selectedCotation?.sellingPriceInCurrency,
                          this.selectedCotation?.currency
                        )
                      );
                    this.priceInfos
                      .at(1)
                      .get('title')
                      .setValue(
                        this.selectedCotation?.nbPax > 1
                          ? passengersStrPlural
                          : passengersStrSingular
                      );
                    this.priceInfos
                      .at(1)
                      .get('value')
                      .setValue(addZeroToDigit(this.selectedCotation?.nbPax));
                    this.priceInfos
                      .at(this.priceInfos.length - 2)
                      .get('value')
                      .setValue(
                        this.formatPrice(
                          this.selectedCotation?.sellingPriceInCurrency,
                          this.selectedCotation?.currency
                        )
                      );
                    this.priceInfos
                      .at(this.priceInfos.length - 1)
                      .get('value')
                      .setValue(
                        this.formatPrice(
                          this.selectedCotation?.sellingPricePPInCurrency,
                          this.selectedCotation?.currency
                        )
                      );
                    break;
                }

                if (field === 'title') {
                  this.priceInfos
                    .at(i)
                    .get(field)
                    .setValue(
                      await this.getTranslation(
                        this.enquiry.type === EnumEnquiryType.cargo
                          ? 'QUOTATION.PRICE.PRICE_EXCLUDING_TAX_FOR_AIRCRAFT'
                          : 'QUOTATION.PRICE.PRICE_EXCLUDING_TAX_FOR'
                      )
                    );
                }

                //   priceInfosArr
                //     .at(i)
                //     .get(field)
                //     .setValue(
                //       passengersStr.replace(
                //         '%s',
                //         addZeroToDigit(this.selectedCotation ? this.selectedCotation?.nbPax : 0)
                //       )
                //     );
                // }
              } else {
                this.priceInfos
                  .at(i)
                  .get(field)
                  .setValue(await this.getTranslation(this.defaultPriceInfos[i][field]));
              }
            }
          }
        }
      }

      this.prefillVatFieldValue();

      for (let i = 0; i < this.defaultServicesSections.length; i++) {
        if (this.servicesSections.at(i)) {
          for (const field of ['title']) {
            if (this.defaultServicesSections[i][field]) {
              this.servicesSections
                .at(i)
                .get(field)
                .setValue(await this.getTranslation(this.defaultServicesSections[i][field]));
            }

            for (let j = 0; j < this.defaultServicesSections[i].services.length; j++) {
              for (const fieldService of ['title', 'comment']) {
                if (this.defaultServicesSections[i].services[j][fieldService]) {
                  this.getServicesInSection(i)
                    .at(j)
                    .get(fieldService)
                    .setValue(
                      await this.getTranslation(
                        this.defaultServicesSections[i].services[j][fieldService]
                      )
                    );
                }
              }
            }
          }
        }
      }
    }

    this.setPaymentAndCancellingTexts(true);
  }

  async getTranslation(path: string, language: EnumLanguage | null = null): Promise<string> {
    if (!language) {
      language = this.form.value.language;
    }

    if (language) {
      if (this.translationObj[language]) {
        return await this.getStringTranslation(language, path);
      } else {
        await this.loadLanguageTranslation(language);

        return await this.getStringTranslation(language, path);
      }
    } else {
      return path;
    }
  }

  loadLanguageTranslation(language: EnumLanguage): Promise<void> {
    return new Promise((resolve, reject) => {
      this.translateService.getTranslation(language).subscribe((translationObj: object) => {
        this.translationObj[language] = translationObj;

        resolve();
      });
    });
  }

  getDefaultStringTranslation(path: string): string {
    return this.getStringTranslation(this.form.value.language, path);
  }

  getStringTranslation(language: EnumLanguage, path: string): string {
    if (this.translationObj[language]) {
      let translation: object | string = Object.assign({}, this.translationObj[language]);
      const splittedPath: string[] = path ? path.split('.') : [];

      for (const onePath of splittedPath) {
        if (translation[onePath]) {
          translation = translation[onePath];
        } else {
          translation = path;
          break;
        }
      }

      return path ? translation.toString() : '';
    }
  }

  getValuePercentOfTotalAmount(percent: number): string {
    return this.formatPrice(
      (percent * this.form.value.totalAmountPayment) / 100,
      this.form.value.totalAmountPaymentCurrency
    );
  }

  getListOfAirportsGmt(): string {
    let listAirports: string[] = [];

    const airportsList: string[] = [];

    for (const trip of this.form.value.trips) {
      for (const airportField of ['airportDepart', 'airportDestination']) {
        if (trip[airportField] && airportsList.indexOf(trip[airportField]) === -1) {
          airportsList.push(trip[airportField]); // to avoid duplicate

          listAirports.push(
            this.airportsObj[trip[airportField]]
              ? this.removeAirportStr(this.airportsObj[trip[airportField]]?.title) +
                  ' : GMT ' +
                  trip[airportField + 'TimezoneGmt']
              : trip[airportField] + ' : GMT ' + trip[airportField + 'TimezoneGmt']
          );
        }
      }
    }

    return listAirports.join(' | ');
  }

  removeAirportStr(name: string): string {
    return name.replace('airport', '').replace('Airport', '').trim();
  }

  dateMinimum(date: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      if (differenceInDays(new Date(control.value), new Date(date)) < 0) {
        return {
          dateBelowMinimum: true,
          dateMinimum: new Date(date)
        };
      } else {
        return null;
      }
    };
  }

  addTripBelow(i: number): void {
    this.trips.insert(i + 1, this.tripFormGroup());
  }

  tripTimesUpdated(i: number): void {
    const tripFormGroup: FormGroup = this.getTrip(i);

    if (
      tripFormGroup.value.airportDepart &&
      this.airportsObj[tripFormGroup.value.airportDepart] &&
      tripFormGroup.value.airportDestination &&
      this.airportsObj[tripFormGroup.value.airportDestination] &&
      tripFormGroup.value.time &&
      tripFormGroup.value.arrivalTime
    ) {
      const departureTimeUtc: Date = getAirportUTCTime(
        this.airportsObj[tripFormGroup.value.airportDepart],
        tripFormGroup.value.date,
        tripFormGroup.value.time
      );

      const arrivalTimeUtc: Date = getAirportUTCTime(
        this.airportsObj[tripFormGroup.value.airportDestination],
        tripFormGroup.value.date,
        tripFormGroup.value.arrivalTime
      );

      let diffInMinutes: number = differenceInMinutes(arrivalTimeUtc, departureTimeUtc);

      const flyTime: {
        hours: number;
        minutes: number;
      } = {
        hours: 0,
        minutes: 0
      };

      if (diffInMinutes < 0) {
        // Arrive the next day, we take this into account
        diffInMinutes = 24 * 60 - differenceInMinutes(departureTimeUtc, arrivalTimeUtc);
      }

      flyTime.hours = Math.floor(diffInMinutes / 60);
      flyTime.minutes = diffInMinutes - flyTime.hours * 60;

      tripFormGroup
        .get('flyTimeInHours')
        .setValue(addZeroToDigit(flyTime.hours) + ':' + addZeroToDigit(flyTime.minutes));
    }
  }

  setEstimatedArrivalTimeIfNotProvided(): void {
    if (this.trips.length && this.aircraftModel) {
      for (let i = 0; i < this.trips.length; i++) {
        this.setEstimatedArrivalTimeIfNotProvidedForTrip(i);
      }
    }
  }

  setEstimatedArrivalTimeIfNotProvidedForTrip(
    tripIndex: number,
    forceUpdateArrivalTime: boolean = false
  ): void {
    const trip: IEnquiryTrip = this.getTrip(tripIndex).value;
    if (
      (!trip.arrivalTime || trip.arrivalTime === '' || forceUpdateArrivalTime) &&
      trip.airportDepart &&
      trip.airportDestination &&
      this.airportsObj[trip.airportDepart] &&
      this.airportsObj[trip.airportDestination]
    ) {
      this.getTrip(tripIndex)
        .get('arrivalTime')
        .setValue(this.getEstimatedArrivalTimeForTrip(tripIndex));
      this.tripTimesUpdated(tripIndex);
    }
  }

  getEstimatedArrivalTimeForTrip(tripIndex: number): string | null {
    const trip: IEnquiryTrip = this.getTrip(tripIndex).value;

    if (
      trip.date &&
      trip.time &&
      this.airportsObj[trip.airportDepart] &&
      this.airportsObj[trip.airportDestination]
    ) {
      // Set estimated arrival time
      let selectedAircraftModel: IAircraftModel | null = null;

      if (trip.aircraftModelId && this.aircraftModelsObj[trip.aircraftModelId]) {
        selectedAircraftModel = this.aircraftModelsObj[trip.aircraftModelId];
      }

      if (selectedAircraftModel) {
        const flyTimeInMinutes: number = estimatedFlyTimeInMinutes(
          selectedAircraftModel,
          this.airportsObj[trip.airportDepart],
          this.airportsObj[trip.airportDestination]
        );

        if (flyTimeInMinutes) {
          const arrivalTimeUtc: Date = addMinutes(
            getAirportUTCTime(this.airportsObj[trip.airportDepart], trip.date, trip.time),
            flyTimeInMinutes
          );

          return getAirportLocalTimeFromUTC(this.airportsObj[trip.airportDepart], arrivalTimeUtc);
        }
      }
    }

    return null;
  }

  async updatedPriceValue(i: number): Promise<void> {
    const passengerStrSingular: string = await this.getTranslation('QUOTATION.PRICE.PASSENGER');
    const passengerStrPlural: string = await this.getTranslation('QUOTATION.PRICE.PASSENGERS');

    if ([passengerStrSingular, passengerStrPlural].includes(this.getPriceInfoLine(i).value.title)) {
      // We are on the field for passenger number

      this.getPriceInfoLine(i)
        .get('title')
        .setValue(
          this.getPriceInfoLine(i).value.value > 1 ? passengerStrPlural : passengerStrSingular
        );
      this.getPriceInfoLine(i).get('title').updateValueAndValidity();
    }
  }

  loadAircraftModels(): void {
    this.subscriptions.add(
      this.aircraftModelService.getAll().subscribe((aircraftModels: IAircraftModel[]) => {
        this.aircraftModels = aircraftModels;

        for (const aircraftModel of this.aircraftModels) {
          this.aircraftModelsObj[aircraftModel.id] = aircraftModel;
        }

        this.aircraftModels.sort((a, b) => (a.title < b.title ? -1 : 1));
      })
    );
  }

  updateAircraftModelForTrip(i: number): void {
    this.setEstimatedArrivalTimeIfNotProvidedForTrip(i);
  }

  openImageModal(field: 'image1' | 'image2' | 'image3'): void {
    this.selectedImageField = field;

    window['$'](this.modalImageElement.nativeElement).modal('show');
  }

  async editImageToAircraftModel(
    field: 'imageUrl' | 'imageInsideUrl' | 'imagePlanUrl'
  ): Promise<void> {
    this.selectedImageFieldForAircraftModel = field;

    this.triggerInputFileForAircraftModel();
  }

  async deleteImageFromAircraftModel(field: string): Promise<void> {
    if (
      this.aircraftModel &&
      confirm('Êtes-vous sûr de vouloir cette image du modèle ' + this.aircraftModel.title + ' ?')
    ) {
      await this.updateImageFieldValueForAircraftModel(field, null);
    }
  }

  async updateImageFieldValueForAircraftModel(field: string, value: string | null): Promise<void> {
    if (this.aircraftModel) {
      this.loaderService.presentLoader();

      try {
        for (const imageField of ['image1Url', 'image2Url', 'image3Url']) {
          if (this.form.get(imageField).value === this.aircraftModel[field]) {
            this.form.get(imageField).setValue(null);
          }
        }

        const data: IAircraftModel = {
          id: this.aircraftModel.id
        } as IAircraftModel;

        data[field] = value;

        await this.aircraftModelService.update(data);

        await this.loaderService.hideLoaderOnSuccess();
      } catch (err) {
        await this.loaderService.hideLoaderOnFailure(err.message);
      }
    }
  }

  setCurrentImageFieldValue(value: string | null): void {
    if (this.selectedImageField) {
      this.form.get(this.selectedImageField + 'Url').setValue(value);
    }
  }

  toggleSelectedImageValue(value: string): void {
    if (this.selectedImageField) {
      this.form
        .get(this.selectedImageField + 'Url')
        .setValue(this.form.get(this.selectedImageField + 'Url').value === value ? null : value);
    }
  }

  editSelectedImage(): void {
    this.triggerInputFile();
  }

  deleteSelectedImage(): void {
    this.form.get(this.selectedImageField + 'Url').setValue(null);
  }

  async searchAircraftByRegistration(): Promise<void> {
    if (this.queryAircraftRegistration) {
      this.loadingAircraft = true;
      this.searchedAircraft = true;

      this.subscriptionsAircraft.add(
        this.aircraftService
          .getOneByRegistration(this.queryAircraftRegistration.trim())
          .subscribe((aircraft: IAircraft | null) => {
            this.aircraft = aircraft;

            if (this.aircraft && this.selectedImageField) {
              for (const field of ['image1', 'image2']) {
                if (!this.form.get(this.selectedImageField + 'Url').value) {
                  switch (this.selectedImageField) {
                    case 'image1':
                      if (this.aircraft.imageOutsideUrl) {
                        this.toggleSelectedImageValue(this.aircraft.imageOutsideUrl);
                      }
                      break;
                    case 'image2':
                      if (this.aircraft.imageOutsideUrl) {
                        this.toggleSelectedImageValue(this.aircraft.imageInsideUrl);
                      }
                      break;
                  }
                }
              }
            }

            this.loadingAircraft = false;
          })
      );
    }
  }
}
