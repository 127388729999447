<div class="table-responsive main-table" *ngIf="loading">
    <table class="table table-striped table-hover">
        <thead>
            <tr>
                <th><div class="placeholder w-100"></div></th>
                <th scope="col" class="sortable" *ngFor="let number of [].constructor(11)">
                    <div class="placeholder w-100"></div>
                </th>
            </tr>
        </thead>
        <tbody *ngFor="let number of [].constructor(7)">
            <tr>
                <td><div class="placeholder w-100"></div></td>
                <td scope="col" class="sortable" *ngFor="let number of [].constructor(11)">
                    <div class="placeholder w-100"></div>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div *ngIf="!loading">
    <div class="alert alert-warning text-center" *ngIf="!enquiries || !enquiries.length">
        Aucun dossier n'a été trouvée.
    </div>

    <!-- <div
        *ngIf="enquiries && enquiries.length"
        class="text-muted"
        [innerHtml]="
            enquiries.length > 1 ? enquiries.length + ' demandes' : enquiries.length + ' demande'
        "
    ></div> -->

    <div class="table-responsive main-table" *ngIf="enquiries && enquiries.length">
        <table class="table table-striped table-hover table-sm">
            <thead>
                <tr>
                    <th class="font-normal"><strong>№ contrat</strong> /<br />№ demande</th>
                    <th *ngIf="!enquiryType">Nature</th>
                    <th *ngIf="!hideClientColumns">Contact</th>
                    <th *ngIf="!hideClientColumns">Client/Agence</th>
                    <th *ngIf="!status">Statut</th>

                    <th class="text-center">Devis ?</th>

                    <th *ngIf="status === EnumEnquiryStatus.cancelled">Raison d'annulation</th>

                    <th *ngIf="status !== EnumEnquiryStatus.confirmed">Routing</th>
                    <th *ngIf="status !== EnumEnquiryStatus.confirmed">Dates de vol</th>
                    <th *ngIf="status !== EnumEnquiryStatus.confirmed">
                        PAX ou kg / m<sup>3</sup>
                    </th>

                    <th *ngIf="status === EnumEnquiryStatus.confirmed">Compagnie / Nationalité</th>
                    <th *ngIf="status === EnumEnquiryStatus.confirmed">Routing confirmé</th>
                    <th *ngIf="status === EnumEnquiryStatus.confirmed">Appareil</th>
                    <th *ngIf="status === EnumEnquiryStatus.confirmed">Dates de vol</th>
                    <th *ngIf="status === EnumEnquiryStatus.confirmed">Nb PAX</th>
                    <th *ngIf="status === EnumEnquiryStatus.confirmed">Prix d'achat TC et CC</th>
                    <th *ngIf="status === EnumEnquiryStatus.confirmed">Prix de vente TC et CC</th>
                    <th *ngIf="status === EnumEnquiryStatus.confirmed">Marge</th>
                    <th *ngIf="status === EnumEnquiryStatus.confirmed">Prix vente pp</th>

                    <th>Reçu par</th>
                    <th>Traité par</th>
                    <th>Date de création</th>
                    <th *ngIf="status === EnumEnquiryStatus.confirmed">Date de confirmation</th>
                    <th *ngIf="status === EnumEnquiryStatus.cancelled">Date d'annulation</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let enquiry of enquiries">
                    <tr
                        [class.enquiry-business]="enquiry.type === EnumEnquiryType.business"
                        [class.enquiry-commercial]="enquiry.type === EnumEnquiryType.commercial"
                        [class.enquiry-cargo]="enquiry.type === EnumEnquiryType.cargo"
                        [class.enquiry-sport]="enquiry.type === EnumEnquiryType.sport"
                        [class.enquiry-helico]="enquiry.type === EnumEnquiryType.helico"
                        [class.enquiry-acmi]="enquiry.type === EnumEnquiryType.acmi"
                        class="clickable"
                    >
                        <td (click)="openEnquiry(enquiry.id)">
                            <div *ngIf="enquiry.refContractTitle">
                                <strong
                                    [innerHtml]="
                                        getDisplayedEnquiryRefTitle(enquiry, 'refContract')
                                    "
                                ></strong>
                            </div>
                            <div
                                *ngIf="enquiry.refEnquiryTitle"
                                [innerHtml]="getDisplayedEnquiryRefTitle(enquiry, 'refEnquiry')"
                            ></div>
                        </td>
                        <td
                            *ngIf="!enquiryType"
                            [innerHtml]="getEnumEnquiryTypeLabel(enquiry.type)"
                            (click)="openEnquiry(enquiry.id)"
                        ></td>
                        <td *ngIf="!hideClientColumns" (click)="openEnquiry(enquiry.id)">
                            <a
                                [href]="
                                    'https://artheauaviation2.pipedrive.com/person/' +
                                    enquiry.contactId
                                "
                                target="_blank"
                                [innerHtml]="
                                    personsObj[enquiry.contactId]
                                        ? personsObj[enquiry.contactId].name
                                        : enquiry.contactTitle
                                "
                            ></a>
                        </td>
                        <td *ngIf="!hideClientColumns">
                            <a
                                [routerLink]="'/admin/clients/' + enquiry.clientId"
                                [innerHtml]="
                                    organizationsObj[enquiry.clientId]
                                        ? organizationsObj[enquiry.clientId].name
                                        : enquiry.clientTitle
                                "
                            ></a>
                        </td>
                        <td *ngIf="!status" (click)="openEnquiry(enquiry.id)">
                            <span
                                class="badge"
                                [class.bg-primary]="enquiry.status === EnumEnquiryStatus.draft"
                                [class.bg-success]="enquiry.status === EnumEnquiryStatus.confirmed"
                                [class.bg-danger]="enquiry.status === EnumEnquiryStatus.cancelled"
                                [innerHtml]="getEnumEnquiryStatusLabel(enquiry.status)"
                            ></span>
                        </td>
                        <td
                            class="text-center interactive-cell"
                            [class.text-success]="enquiry.quotationSentToClient"
                            (click)="setQuotationSent(enquiry)"
                        >
                            <ng-container *ngIf="!enquiry.quotationSentToClient">
                                <fa-icon [icon]="faSquare"></fa-icon>
                            </ng-container>
                            <ng-container *ngIf="enquiry.quotationSentToClient">
                                <fa-icon [icon]="faSquareCheck"></fa-icon>
                                <div
                                    [innerText]="
                                        enquiry.quotationSentToClientDate | date : 'dd/MM/YY'
                                    "
                                ></div>
                            </ng-container>
                        </td>

                        <th
                            *ngIf="status === EnumEnquiryStatus.cancelled"
                            (click)="openEnquiry(enquiry.id)"
                        >
                            {{
                                getEnumEnquiryCancelledReasonLabel(enquiry.reasonCancelled?.reason)
                            }}
                            <p
                                *ngIf="enquiry.reasonCancelled.comment"
                                [innerHtml]="enquiry.reasonCancelled.comment"
                                class="mb-0"
                            ></p>
                        </th>

                        <td
                            *ngIf="status !== EnumEnquiryStatus.confirmed"
                            (click)="openEnquiry(enquiry.id)"
                        >
                            <div *ngFor="let itinerary of enquiry.itineraries">
                                <div
                                    *ngIf="enquiry.itineraries.length > 1"
                                    [innerHtml]="itinerary.title"
                                ></div>
                                {{ itinerary.routing }}
                                <div *ngIf="getReturnDate(itinerary)">&nbsp;</div>
                            </div>
                        </td>
                        <td
                            *ngIf="status !== EnumEnquiryStatus.confirmed"
                            (click)="openEnquiry(enquiry.id)"
                        >
                            <div *ngFor="let itinerary of enquiry.itineraries">
                                <div *ngIf="enquiry.itineraries.length > 1">&nbsp;</div>
                                <div
                                    *ngIf="getDepartDate(itinerary)"
                                    [innerHtml]="getDepartDate(itinerary) | date : 'dd/MM/yy'"
                                ></div>
                                <div
                                    *ngIf="getReturnDate(itinerary)"
                                    [innerHtml]="getReturnDate(itinerary) | date : 'dd/MM/yy'"
                                ></div>
                            </div>
                        </td>
                        <td
                            *ngIf="
                                enquiry.type === EnumEnquiryType.cargo &&
                                status !== EnumEnquiryStatus.confirmed
                            "
                            (click)="openEnquiry(enquiry.id)"
                        >
                            <div *ngFor="let itinerary of enquiry.itineraries">
                                <div *ngIf="enquiry.itineraries.length > 1">&nbsp;</div>
                                {{ getItineraryWeightList(itinerary)?.join("|") }} kg /
                                {{ getItineraryVolumeList(itinerary)?.join("|") }} m<sup>3</sup>
                                <div *ngIf="getReturnDate(itinerary)">&nbsp;</div>
                            </div>
                        </td>
                        <td
                            *ngIf="
                                enquiry.type !== EnumEnquiryType.cargo &&
                                status !== EnumEnquiryStatus.confirmed
                            "
                            (click)="openEnquiry(enquiry.id)"
                        >
                            <div *ngFor="let itinerary of enquiry.itineraries">
                                <div *ngIf="enquiry.itineraries.length > 1">&nbsp;</div>
                                {{ getPassengersTotalLabelList(itinerary)?.join("|") }} PAX
                                <div *ngIf="getReturnDate(itinerary)">&nbsp;</div>
                            </div>
                        </td>

                        <td
                            *ngIf="status === EnumEnquiryStatus.confirmed"
                            (click)="openEnquiry(enquiry.id)"
                        >
                            <div
                                *ngFor="
                                    let confirmedCotation of enquiriesWithCotations[enquiry.id]
                                        ?.confirmedCotations
                                "
                            >
                                <a
                                    [routerLink]="
                                        '/admin/airlines/' +
                                        aircraftsCompiledObj[confirmedCotation.aircraftCompiledId]
                                            ?.airlineId
                                    "
                                    [innerHtml]="
                                        aircraftsCompiledObj[confirmedCotation.aircraftCompiledId]
                                            ?.airlineTitle ?? '-'
                                    "
                                    *ngIf="
                                        aircraftsCompiledObj[confirmedCotation.aircraftCompiledId]
                                    "
                                    target="_blank"
                                ></a>
                                /
                                <span
                                    [innerHtml]="
                                        getCountryLabel(
                                            aircraftsCompiledObj[
                                                confirmedCotation.aircraftCompiledId
                                            ]?.airlineCountryCode
                                        ) ?? '-'
                                    "
                                    *ngIf="
                                        aircraftsCompiledObj[confirmedCotation.aircraftCompiledId]
                                    "
                                ></span>
                            </div>
                        </td>
                        <td
                            *ngIf="status === EnumEnquiryStatus.confirmed"
                            (click)="openEnquiry(enquiry.id)"
                        >
                            <div
                                *ngFor="
                                    let confirmedCotation of enquiriesWithCotations[enquiry.id]
                                        ?.confirmedCotations
                                "
                            >
                                <div
                                    *ngFor="
                                        let leg of getEnquiryLegsOfCotation(
                                            enquiry,
                                            confirmedCotation
                                        )
                                    "
                                >
                                    <span>{{
                                        airportsObj[leg.airportDepart]?.iataCode
                                            ? airportsObj[leg.airportDepart]?.iataCode
                                            : airportsObj[leg.airportDepart]?.title
                                    }}</span
                                    >-<span>{{
                                        airportsObj[leg.airportDestination]?.iataCode
                                            ? airportsObj[leg.airportDestination]?.iataCode
                                            : airportsObj[leg.airportDestination]?.title
                                    }}</span>
                                </div>
                            </div>
                        </td>
                        <td
                            *ngIf="status === EnumEnquiryStatus.confirmed"
                            (click)="openEnquiry(enquiry.id)"
                        >
                            <div
                                *ngFor="
                                    let confirmedCotation of enquiriesWithCotations[enquiry.id]
                                        ?.confirmedCotations
                                "
                            >
                                <span
                                    [innerHtml]="
                                        aircraftsCompiledObj[confirmedCotation.aircraftCompiledId]
                                            .type
                                            ? aircraftsCompiledObj[
                                                  confirmedCotation.aircraftCompiledId
                                              ].type
                                            : '-'
                                    "
                                    *ngIf="
                                        aircraftsCompiledObj[confirmedCotation.aircraftCompiledId]
                                    "
                                ></span>
                            </div>
                        </td>
                        <td
                            *ngIf="status === EnumEnquiryStatus.confirmed"
                            (click)="openEnquiry(enquiry.id)"
                        >
                            <div
                                *ngFor="
                                    let confirmedCotation of enquiriesWithCotations[enquiry.id]
                                        ?.confirmedCotations
                                "
                            >
                                <div
                                    *ngFor="
                                        let leg of getEnquiryLegsOfCotation(
                                            enquiry,
                                            confirmedCotation
                                        )
                                    "
                                    [innerHtml]="leg.date | date : 'dd/MM/yy'"
                                ></div>
                            </div>
                        </td>
                        <td
                            *ngIf="status === EnumEnquiryStatus.confirmed"
                            (click)="openEnquiry(enquiry.id)"
                        >
                            <div
                                *ngFor="
                                    let confirmedCotation of enquiriesWithCotations[enquiry.id]
                                        ?.confirmedCotations
                                "
                            >
                                <div
                                    [innerHtml]="
                                        confirmedCotation.nbPax ? confirmedCotation.nbPax : '-'
                                    "
                                    *ngIf="enquiry.type !== EnumEnquiryType.cargo"
                                ></div>
                                <div *ngIf="enquiry.type === EnumEnquiryType.cargo">-</div>
                            </div>
                        </td>
                        <td
                            *ngIf="status === EnumEnquiryStatus.confirmed"
                            (click)="openEnquiry(enquiry.id)"
                        >
                            <div
                                *ngFor="
                                    let confirmedCotation of enquiriesWithCotations[enquiry.id]
                                        ?.confirmedCotations
                                "
                                [innerHtml]="
                                    confirmedCotation.priceNetTTC
                                        ? formatPrice(confirmedCotation.priceNetTTC)
                                        : '-'
                                "
                            ></div>
                        </td>
                        <td
                            *ngIf="status === EnumEnquiryStatus.confirmed"
                            (click)="openEnquiry(enquiry.id)"
                        >
                            <div
                                *ngFor="
                                    let confirmedCotation of enquiriesWithCotations[enquiry.id]
                                        ?.confirmedCotations
                                "
                                [innerHtml]="
                                    confirmedCotation.sellingPrice
                                        ? formatPrice(confirmedCotation.sellingPrice)
                                        : '-'
                                "
                            ></div>
                        </td>
                        <td
                            *ngIf="status === EnumEnquiryStatus.confirmed"
                            (click)="openEnquiry(enquiry.id)"
                        >
                            <div
                                *ngFor="
                                    let confirmedCotation of enquiriesWithCotations[enquiry.id]
                                        ?.confirmedCotations
                                "
                                [innerHtml]="
                                    confirmedCotation.margin
                                        ? formatPrice(confirmedCotation.margin) +
                                          ' (' +
                                          confirmedCotation.marginPercent +
                                          ' %)'
                                        : '-'
                                "
                            >
                                -
                            </div>
                        </td>
                        <td
                            *ngIf="status === EnumEnquiryStatus.confirmed"
                            (click)="openEnquiry(enquiry.id)"
                        >
                            <div
                                *ngFor="
                                    let confirmedCotation of enquiriesWithCotations[enquiry.id]
                                        ?.confirmedCotations
                                "
                            >
                                <div
                                    [innerHtml]="
                                        confirmedCotation.sellingPricePP
                                            ? formatPrice(confirmedCotation.sellingPricePP)
                                            : '-'
                                    "
                                    *ngIf="enquiry.type !== EnumEnquiryType.cargo"
                                ></div>
                                <div *ngIf="enquiry.type === EnumEnquiryType.cargo">-</div>
                            </div>
                        </td>

                        <td class="text-center" (click)="openEnquiry(enquiry.id)">
                            <span
                                [innerHtml]="usersObj[enquiry.receivedBy].initials"
                                *ngIf="usersObj[enquiry.receivedBy]"
                            ></span>
                        </td>
                        <td class="text-center" (click)="openEnquiry(enquiry.id)">
                            <span
                                [innerHtml]="usersObj[enquiry.processedBy].initials"
                                *ngIf="usersObj[enquiry.processedBy]"
                            ></span>
                        </td>
                        <td
                            [innerText]="enquiry.created | date : 'dd/MM/yy HH:mm'"
                            (click)="openEnquiry(enquiry.id)"
                        ></td>
                        <td
                            *ngIf="status === EnumEnquiryStatus.confirmed"
                            [innerText]="enquiry.dateConfirmed | date : 'dd/MM/yy HH:mm'"
                            (click)="openEnquiry(enquiry.id)"
                        ></td>

                        <td
                            *ngIf="status === EnumEnquiryStatus.cancelled"
                            [innerText]="enquiry.dateCancelled | date : 'dd/MM/yy HH:mm'"
                            (click)="openEnquiry(enquiry.id)"
                        ></td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>

<div class="modal fade" tabindex="-1" aria-hidden="true" #modalEnquiryQuotationSentToClient>
    <div class="modal-dialog">
        <div class="modal-content">
            <app-enquiry-quotations-sent-to-client
                [enquiry]="selectedEnquiry"
                [inModal]="true"
            ></app-enquiry-quotations-sent-to-client>
        </div>
    </div>
</div>
