import { Component, OnInit } from '@angular/core';
import { of, Subject, Subscription } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, map, mergeMap } from 'rxjs/operators';

import { PaginationService } from '../../../services/pagination.service';
import { AlgoliaService } from '../../../services/algolia.service';
import { AclService } from '../../../services/acl.service';

import countries from '../../../countries_fr.json';

import { EnumAcl } from 'src/app/enums/acl.enum';
import { IAirline } from 'src/app/interfaces/airline.interface';
import { getContinentLabel, getCountryLabel } from 'src/app/enums/continent-code.enum';

enum EnumListFilter {
  activeOnly = 'activeOnly',
  inactiveOnly = 'inactiveOnly',
  noContactOnly = 'noContactOnly',
  hasContactOnly = 'hasContactOnly'
}

@Component({
  selector: 'app-list-airlines',
  templateUrl: './list-airlines.component.html',
  styleUrls: ['./list-airlines.component.scss']
})
export class ListAirlinesComponent implements OnInit {
  EnumAcl = EnumAcl;
  EnumListFilter = EnumListFilter;

  currentPagination: string = 'airlines-list';
  countriesList: Array<{
    title: string;
    value: string;
  }> = [];
  activesList: Array<{
    title: string;
    value: EnumListFilter;
  }> = [
    {
      title: 'Seulement en activité',
      value: EnumListFilter.activeOnly
    },
    {
      title: 'Seulement en inactive',
      value: EnumListFilter.inactiveOnly
    },
    {
      title: 'Sans contact',
      value: EnumListFilter.noContactOnly
    },
    {
      title: 'Avec contact',
      value: EnumListFilter.hasContactOnly
    }
  ];
  countryCode: string | null = null;
  isActiveFilter: EnumListFilter | null = EnumListFilter.activeOnly;
  isSearchListing: boolean = false;
  searchedAirlines: IAirline[] = [];
  keyUp = new Subject<string>();
  query: string = '';

  getContinentLabel = getContinentLabel;
  getCountryLabel = getCountryLabel;

  private subscription: Subscription;

  constructor(
    public paginationService: PaginationService,
    private algoliaService: AlgoliaService,
    private aclService: AclService
  ) {
    for (const code in countries) {
      this.countriesList.push({
        title: countries[code],
        value: code
      });
    }

    this.subscription = this.keyUp
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        mergeMap(search => of(search).pipe(delay(500)))
      )
      .subscribe((value: string) => {
        this.query = value;

        if (this.query == '') {
          this.fetchData();
        } else {
          this.search();
        }
      });
  }

  async ngOnInit(): Promise<void> {
    await this.aclService.checkAclAccess(EnumAcl.airlinesList);

    for (const field of ['countryCode', 'isActiveFilter']) {
      if (window[field + '-filter']) {
        this[field] = window[field + '-filter'];
      } else {
        this[field] = null;
      }
    }

    this.paginationService.pagination[this.currentPagination].currentPage = 0;

    this.saveFilterInGlobalVariable();

    this.fetchData();
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  async fetchData(): Promise<void> {
    this.paginationService.pagination[this.currentPagination].conditions = [];

    if (this.countryCode) {
      this.paginationService.pagination[this.currentPagination].conditions.push({
        field: 'countryCode',
        operator: '==',
        value: this.countryCode
      });
    }

    if (this.isActiveFilter) {
      switch (this.isActiveFilter) {
        case EnumListFilter.activeOnly:
          this.paginationService.pagination[this.currentPagination].conditions.push({
            field: 'isActive',
            operator: '==',
            value: true
          });
          break;
        case EnumListFilter.inactiveOnly:
          this.paginationService.pagination[this.currentPagination].conditions.push({
            field: 'isActive',
            operator: '==',
            value: false
          });
          break;
        case EnumListFilter.noContactOnly:
          this.paginationService.pagination[this.currentPagination].conditions.push({
            field: 'hasContact',
            operator: '==',
            value: false
          });
          break;
        case EnumListFilter.hasContactOnly:
          this.paginationService.pagination[this.currentPagination].conditions.push({
            field: 'hasContact',
            operator: '==',
            value: true
          });
          break;
      }
    }

    await this.paginationService.fetchData(this.currentPagination);

    this.search();
  }

  resetOrderAndfetchData(): void {
    this.paginationService.pagination[this.currentPagination].orderBy = {
      field: 'title',
      direction: 'asc'
    };

    this.saveFilterInGlobalVariable();

    this.fetchData();
  }

  saveFilterInGlobalVariable(): void {
    for (const field of ['countryCode', 'isActiveFilter']) {
      window[field + '-filter'] = this[field];
    }
  }

  search(): void {
    if (this.query.length) {
      this.isSearchListing = true;

      const conditions = [];

      if (this.countryCode) {
        conditions.push({
          field: 'countryCode',
          type: 'string',
          value: this.countryCode
        });
      }

      if (this.isActiveFilter) {
        switch (this.isActiveFilter) {
          case EnumListFilter.activeOnly:
            conditions.push({
              field: 'isActive',
              type: 'boolean',
              value: true
            });
            break;
          case EnumListFilter.inactiveOnly:
            conditions.push({
              field: 'isActive',
              type: 'boolean',
              value: false
            });
            break;
          case EnumListFilter.noContactOnly:
            conditions.push({
              field: 'hasContact',
              type: 'boolean',
              value: false
            });
            break;
          case EnumListFilter.hasContactOnly:
            conditions.push({
              field: 'hasContact',
              type: 'boolean',
              value: true
            });
            break;
        }
      }

      this.algoliaService
        .searchAirlines(this.query, conditions)
        .then((airlines: IAirline[]) => {
          this.searchedAirlines = airlines;
        })
        .catch(err => {
          console.log(err);
          this.isSearchListing = true;
        });
    } else {
      this.isSearchListing = false;
      this.searchedAirlines = [];
    }
  }
}
