<div class="btn-toolbar justify-content-center mb-4">
    <div class="btn-group">
        <a
            [routerLink]="'/admin/fbos/' + fboId + '/edit'"
            class="btn btn-primary mr-4"
            *ngIf="hasAclAccess(EnumAcl.fbosEdit)"
            >Éditer</a
        >
        <button
            type="button"
            (click)="delete()"
            class="btn btn-danger ml-4"
            *ngIf="hasAclAccess(EnumAcl.fbosDelete)"
        >
            Supprimer
        </button>
    </div>
</div>

<div class="row justify-content-center">
    <div class="col-md-8">
        <div class="card mb-4">
            <div class="card-body" *ngIf="loading">
                <h3><div class="placeholder w-100"></div></h3>
            </div>
            <div class="card-body" *ngIf="!loading && fbo">
                <h3 [innerHtml]="fbo.name" class="text-center"></h3>
                <dl class="row mb-0">
                    <dt class="col-md-6">Aéroport</dt>
                    <dd class="col-md-6">
                        <a
                            [routerLink]="'/admin/airports/' + fbo.airportId"
                            [innerText]="airportsObj[fbo.airportId].title"
                            *ngIf="airportsObj[fbo.airportId]"
                        ></a>
                        <div class="placeholder w-100" *ngIf="!airportsObj[fbo.airportId]"></div>
                    </dd>
                    <dt class="col-md-6">Numéro de téléphone</dt>
                    <dd class="col-md-6" *ngIf="fbo.phoneNumber" [innerText]="fbo.phoneNumber"></dd>
                    <dd class="col-md-6 text-muted" *ngIf="!fbo.phoneNumber">Vide</dd>
                    <dt class="col-md-6">Email</dt>
                    <dd class="col-md-6" *ngIf="fbo.email" [innerText]="fbo.email"></dd>
                    <dd class="col-md-6 text-muted" *ngIf="!fbo.email">Vide</dd>
                    <dt class="col-md-6">Adresse</dt>
                    <dd class="col-md-6" [innerHTML]="getFboFullAddress(fbo)"></dd>
                    <dt class="col-md-6">Plan d'accès</dt>
                    <dd class="col-md-6">
                        <a [href]="fbo.accessPlanUrl" *ngIf="fbo.accessPlanUrl" target="_blank"
                            >URL</a
                        >
                        <span class="text-muted" *ngIf="!fbo.accessPlanUrl">Vide</span>
                    </dd>
                </dl>
            </div>
        </div>
    </div>
</div>
