import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  faTrash,
  faPlane,
  faCircleCheck,
  faXmarkCircle,
  faCircle,
  faEdit,
  faCirclePlus,
  faPlaneDeparture,
  faClock,
  faTicket,
  faPlaneArrival,
  faLongArrowRight,
  faUsers,
  faBox,
  faUserTie,
  faComment
} from '@fortawesome/free-solid-svg-icons';
import {
  getEnquiryFlightFlyTimeInMin,
  IEnquiryFlight
} from 'src/app/interfaces/enquiry-flight.interface';
import { EnumEnquiryFlightWizardStep } from '../enquiry-flight-edit/enquiry-flight-edit.component';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { EnquiryFlightService } from 'src/app/services/enquiry-flights/enquiry-flights.service';
import { convertMinutesToTime } from 'src/app/misc.utils';
import { EnumEnquiryType } from 'src/app/enums/enquiry-type.enum';
import { IEnquiry } from 'src/app/interfaces/enquiry.interface';
import { IAirlineSupervisor } from 'src/app/interfaces/airline-supervisor.interface';
import { ICrew } from 'src/app/interfaces/crew.interface';
import { IFbo } from 'src/app/interfaces/fbo.interface';
import { AirlineSupervisorService } from 'src/app/services/airline-supervisors/airline-supervisors.service';
import { CrewService } from 'src/app/services/crews/crews.service';
import { FboService } from 'src/app/services/fbos/fbos.service';
import { Subscription } from 'rxjs';
import countries from '../../countries_fr.json';
import { IFlightBriefCatering } from 'src/app/interfaces/flight-brief-catering.interface';
import { FlightBriefCateringService } from 'src/app/services/flight-brief-caterings/flight-brief-caterings.service';
import { EnumLanguage } from 'src/app/enums/language.enum';
import { FlightChecklistService } from 'src/app/services/flight-checklists/flight-checklists.service';

@Component({
  selector: 'app-enquiry-flights-accordion',
  templateUrl: './enquiry-flights-accordion.component.html',
  styleUrls: ['./enquiry-flights-accordion.component.scss']
})
export class EnquiryFlightsAccordionComponent implements OnInit, OnDestroy {
  @Input() enquiry: IEnquiry | null = null;
  @Input() enquiryFlights: IEnquiryFlight[] = [];
  @Input() loading: boolean = false;

  @ViewChild('modalEnquiryFlightEdit', { static: false }) modalEnquiryFlightEditElement: ElementRef;
  @ViewChild('modalEnquiryFlightAdd', { static: false }) modalEnquiryFlightAddElement: ElementRef;

  faTrash = faTrash;
  faPlane = faPlane;
  faCircleCheck = faCircleCheck;
  faXmarkCircle = faXmarkCircle;
  faCircle = faCircle;
  faEdit = faEdit;
  faCirclePlus = faCirclePlus;
  faPlaneDeparture = faPlaneDeparture;
  faClock = faClock;
  faTicket = faTicket;
  faPlaneArrival = faPlaneArrival;
  faLongArrowRight = faLongArrowRight;
  faUsers = faUsers;
  faBox = faBox;
  faUserTie = faUserTie;
  faComment = faComment;

  countries: { [countryCode: string]: string } = countries;

  EnumEnquiryFlightWizardStep = EnumEnquiryFlightWizardStep;
  EnumEnquiryType = EnumEnquiryType;
  EnumLanguage = EnumLanguage;

  currentEnquiryFlight: IEnquiryFlight | null = null;
  currentEnquiryFlightStep: EnumEnquiryFlightWizardStep = EnumEnquiryFlightWizardStep.aircraft;

  convertMinutesToTime = convertMinutesToTime;
  getEnquiryFlightFlyTimeInMin = getEnquiryFlightFlyTimeInMin;

  fbosObj: { [id: string]: IFbo | null } = {};
  crewsObj: { [id: string]: ICrew | null } = {};
  supervisorsObj: { [id: string]: IAirlineSupervisor | null } = {};
  flightBriefCateringsObj: { [id: string]: IFlightBriefCatering | null } = {};

  private subscriptions = new Subscription();

  constructor(
    private loaderService: LoaderService,
    private enquiryFlightService: EnquiryFlightService,
    private fboService: FboService,
    private crewService: CrewService,
    private airlineSupervisorService: AirlineSupervisorService,
    private flightBriefCateringService: FlightBriefCateringService,
    private flightChecklistService: FlightChecklistService
  ) {}

  ngOnInit(): void {
    this.loadFlightBriefCaterings();

    this.refreshFbosForAllEnquiryFlights();
    this.refreshCrewsAndSupervisorsForAllEnquiryFlights();
    this.refreshFlightBriefCateringIdForAllEnquiryFlights();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['enquiryFlights']) {
      this.refreshFbosForAllEnquiryFlights();
      this.refreshFlightBriefCateringIdForAllEnquiryFlights();
    }

    this.refreshCurrentEnquiryFlight();

    this.refreshCrewsAndSupervisorsForAllEnquiryFlights();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  refreshCurrentEnquiryFlight(): void {
    if (this.currentEnquiryFlight) {
      for (const enquiryFlight of this.enquiryFlights) {
        if (this.currentEnquiryFlight.id === enquiryFlight.id) {
          this.currentEnquiryFlight = Object.assign({}, enquiryFlight);
          break;
        }
      }
    }
  }

  refreshFbosForAllEnquiryFlights(): void {
    for (const enquiryFlight of this.enquiryFlights) {
      for (const field of ['departFboId', 'arrivalFboId']) {
        if (enquiryFlight[field]) {
          this.loadFbo(enquiryFlight[field]);
        }
      }
    }
  }

  refreshCrewsAndSupervisorsForAllEnquiryFlights(): void {
    for (const enquiryFlight of this.enquiryFlights) {
      if (enquiryFlight.airlineId) {
        this.loadCrewsForAirline(enquiryFlight.airlineId);
        this.loadSupervisorsForAirline(enquiryFlight.airlineId);
      }
    }
  }

  async addEnquiryFlight(): Promise<void> {
    this.currentEnquiryFlight = null;
    this.currentEnquiryFlightStep = EnumEnquiryFlightWizardStep.aircraft;

    if (this.enquiryFlights.length) {
      if (this.modalEnquiryFlightAddElement.nativeElement) {
        window['$'](this.modalEnquiryFlightAddElement.nativeElement).modal('show');
      }
    } else {
      if (this.modalEnquiryFlightEditElement.nativeElement) {
        window['$'](this.modalEnquiryFlightEditElement.nativeElement).modal('show');
      }
    }
  }

  editEnquiryFlight(
    enquiryFlight: IEnquiryFlight | null,
    step: EnumEnquiryFlightWizardStep = EnumEnquiryFlightWizardStep.aircraft
  ): void {
    this.currentEnquiryFlight = enquiryFlight;
    this.currentEnquiryFlightStep = step;

    if (this.modalEnquiryFlightEditElement.nativeElement) {
      window['$'](this.modalEnquiryFlightEditElement.nativeElement).modal('show');
    }
  }

  async deleteEnquiryFlight(enquiryFlight: IEnquiryFlight): Promise<void> {
    if (
      confirm(
        'Êtes-vous sûr de vouloir supprimer ce vol ? Il sera supprimé définitivement du dossier.'
      )
    ) {
      this.loaderService.presentLoader();

      this.enquiryFlightService
        .delete(enquiryFlight.id)
        .then(async () => {
          await this.loaderService.hideLoaderOnSuccess();
        })
        .catch(async err => {
          await this.loaderService.hideLoaderOnFailure(err);
        });
    }
  }

  loadFbo(fboId: string): void {
    if (typeof this.fbosObj[fboId] === 'undefined') {
      this.fbosObj[fboId] = null;

      this.subscriptions.add(
        this.fboService.getFromId(fboId).subscribe((fbo: IFbo | null) => {
          this.fbosObj[fboId] = fbo;
        })
      );
    }
  }

  loadCrewsForAirline(airlineId: string): void {
    this.subscriptions.add(
      this.crewService.getForAirline(airlineId).subscribe((crews: ICrew[]) => {
        for (const crew of crews) {
          this.crewsObj[crew.id] = crew;
        }
      })
    );
  }

  loadSupervisorsForAirline(airlineId: string): void {
    this.subscriptions.add(
      this.airlineSupervisorService
        .getForAirline(airlineId)
        .subscribe((supervisors: IAirlineSupervisor[]) => {
          for (const supervisor of supervisors) {
            this.supervisorsObj[supervisor.id] = supervisor;
          }
        })
    );
  }

  loadFlightBriefCaterings(): void {
    this.subscriptions.add(
      this.flightBriefCateringService
        .getAll()
        .subscribe((flightBriefCaterings: IFlightBriefCatering[]) => {
          for (const flightBriefCatering of flightBriefCaterings) {
            this.flightBriefCateringsObj[flightBriefCatering.id] = flightBriefCatering;
          }

          this.refreshFlightBriefCateringIdForAllEnquiryFlights();
        })
    );
  }

  refreshFlightBriefCateringIdForAllEnquiryFlights(): void {
    for (let i = 0; i < this.enquiryFlights.length; i++) {
      this.enquiryFlights[i] = this.refreshFlightBriefCateringId(this.enquiryFlights[i]);
    }
  }

  refreshFlightBriefCateringId(enquiryFlight: IEnquiryFlight): IEnquiryFlight {
    // Fix for previous system

    if (Object.values(this.flightBriefCateringsObj).length) {
      for (const flightBriefCateringId in this.flightBriefCateringsObj) {
        if (flightBriefCateringId !== enquiryFlight.flightBriefCateringId) {
          for (const language in this.flightBriefCateringsObj[flightBriefCateringId]
            .textByLanguage) {
            if (
              this.flightBriefCateringsObj[flightBriefCateringId].textByLanguage[language] ===
              enquiryFlight.flightBriefCateringId
            ) {
              enquiryFlight.flightBriefCateringId = flightBriefCateringId;
              break;
            }
          }
        }
      }
    }

    return enquiryFlight;
  }

  assignSelectedEnquiryFlight(enquiryFlightId: string): void {
    for (const enquiryFlight of this.enquiryFlights) {
      if (enquiryFlight.id === enquiryFlightId) {
        this.currentEnquiryFlight = enquiryFlight;
        break;
      }
    }
  }
}
