import { Component, OnDestroy, OnInit } from '@angular/core';
import { AclService } from '../../../services/acl.service';

import * as XLSX from 'xlsx';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { RemoteService } from 'src/app/services/remote.service';
import { Router } from '@angular/router';
import { AlgoliaService, IAlgoliaFilter } from 'src/app/services/algolia.service';
import { IEnquiry } from 'src/app/interfaces/enquiry.interface';
import {
  EnumCurrency,
  getEnumCurrencyLabel,
  getEnumCurrencySymbol
} from 'src/app/enums/currency.enum';
import { EnumAcl } from 'src/app/enums/acl.enum';
import {
  EnumSuggestionInvoiceReason,
  ISuggestedInvoice,
  getSuggestionInvoiceReasonLabel
} from 'src/app/enums/suggested-invoice.enum';
import { NgxCsvParser } from 'ngx-csv-parser';
import { IInvoice } from 'src/app/interfaces/invoice.interface';
import { IEncaissementType } from 'src/app/interfaces/encaissement-type.interface';
import { IBankAccount } from 'src/app/interfaces/bank-account.interface';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { EncaissementService } from 'src/app/services/encaissements/encaissements.service';
import {
  faArrowLeft,
  faArrowRight,
  faCheck,
  faExternalLink,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import { formatPrice } from 'src/app/misc.utils';
import { Subscription } from 'rxjs';
import { InvoiceService } from 'src/app/services/invoices/invoices.service';
import { format, isAfter, isBefore, parse } from 'date-fns';

interface IOperation {
  date: string;
  title: string;
  debit: number;
  credit: number;
}

interface DataImport {
  ibanAccount: string;
  currency: string;
  dateStart: string;
  dateEnd: string;
  operations: IOperation[];
}

enum EnumDataType {
  bnp = 'bnp',
  quonto = 'quonto',
  quontoSimpliflied = 'quontoSimplified',
  palpatine = 'palpatine'
}

@Component({
  selector: 'app-encaissement-import',
  templateUrl: './encaissement-import.component.html',
  styleUrls: ['./encaissement-import.component.scss']
})
export class EncaissementImportComponent implements OnInit, OnDestroy {
  EnumCurrency = EnumCurrency;

  getEnumCurrencySymbol = getEnumCurrencySymbol;
  getEnumCurrencyLabel = getEnumCurrencyLabel;
  getSuggestionInvoiceReasonLabel = getSuggestionInvoiceReasonLabel;
  formatPrice = formatPrice;

  faArrowLeft = faArrowLeft;
  faArrowRight = faArrowRight;
  faCheck = faCheck;
  faExternalLink = faExternalLink;
  faTrash = faTrash;

  fileContentLoaded: boolean = false;
  currentFile: File;
  dataValid: boolean = false;
  data: DataImport;

  form: FormGroup = this.resetForm();

  bankAccountsList: IBankAccount[] = [];
  encaissementTypesList: IEncaissementType[] = [];

  displayEncaissementForm: boolean = false;
  currentEncaissementIndex: number = 0;

  suggestionInvoices: ISuggestedInvoice[][] = [];

  invoicesObj: { [id: string]: IInvoice | null } = {};

  private subscriptions = new Subscription();

  constructor(
    private remoteService: RemoteService,
    private router: Router,
    private aclService: AclService,
    private algoliaService: AlgoliaService,
    private ngxCsvParser: NgxCsvParser,
    private loaderService: LoaderService,
    private encaissementService: EncaissementService,
    private invoiceService: InvoiceService
  ) {}

  resetForm(): FormGroup {
    return new FormGroup({
      encaissements: new FormArray([])
    });
  }

  async ngOnInit(): Promise<void> {
    await this.aclService.checkAclAccess(EnumAcl.encaissementsImport);

    this.loadBankAccounts();
    this.loadEncaissementTypes();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getCurrencies(): EnumCurrency[] {
    return Object.values(EnumCurrency);
  }

  get encaissements(): FormArray {
    return this.form.get('encaissements') as FormArray;
  }

  getEncaissement(i: number): FormGroup {
    return this.encaissements.at(i) as FormGroup;
  }

  getEncaissementField(i: number, field: string): FormControl {
    return this.getEncaissement(i).get(field) as FormControl;
  }

  changeInputFile(fileInput: any): void {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.currentFile = fileInput.target.files[0];

      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.fileContentLoaded = false;

        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellDates: true });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        this.data = this.excelContentToJson(XLSX.utils.sheet_to_csv(ws));

        this.fileContentLoaded = true;
      };

      reader.readAsBinaryString(this.currentFile);
    }
  }

  addEncaissement(): void {
    this.encaissements.push(
      new FormGroup({
        id: new FormControl('', [Validators.required]),
        libelle: new FormControl('', [Validators.required]),
        encaissementTypeId: new FormControl('', [Validators.required]),
        isCredit: new FormControl(false),
        isDebit: new FormControl(false),
        amount: new FormControl(0, [Validators.required]),
        currency: new FormControl(EnumCurrency.EUR, [Validators.required]),
        bankAccountId: new FormControl('', [Validators.required]),
        invoiceIds: new FormControl([]),
        date: new FormControl('', [Validators.required]),
        isReconciled: new FormControl(true, [Validators.required]),
        comment: new FormControl('')
      })
    );

    this.form.updateValueAndValidity();
  }

  async submitForm(): Promise<void> {
    this.getEncaissement(this.currentEncaissementIndex).markAsTouched();

    if (this.getEncaissement(this.currentEncaissementIndex).valid) {
      this.loaderService.presentLoader();

      const data = Object.assign({}, this.getEncaissement(this.currentEncaissementIndex).value);

      data.isDebit = data.amount >= 0;
      data.isCredit = data.amount < 0;

      for (const field in data) {
        if (typeof data[field] == 'undefined') {
          data[field] = null;
        }
      }

      this.form.disable();

      try {
        await this.encaissementService.createWithId(data.id, data);

        await this.loaderService.hideLoaderOnSuccess();

        this.form.enable();

        if (this.currentEncaissementIndex >= this.encaissements.length - 1) {
          this.redirectAfterSaving();
        } else {
          this.goNext();
        }
      } catch (err) {
        await this.loaderService.hideLoaderOnSuccess(err);
        this.form.enable();
      }
    }
  }

  redirectAfterSaving(): void {
    this.router.navigate(['/admin/encaissements']);
  }

  async loadBankAccounts(): Promise<void> {
    const docs = await this.remoteService.getAllDocuments('bankAccounts', {
      field: 'name',
      direction: 'asc'
    });

    this.bankAccountsList = [];
    for (const doc of docs) {
      this.bankAccountsList.push(doc as IBankAccount);
    }
  }

  async loadEncaissementTypes(): Promise<void> {
    const docs = await this.remoteService.getAllDocuments('encaissementTypes', {
      field: 'name',
      direction: 'asc'
    });

    this.encaissementTypesList = [];
    for (const doc of docs) {
      this.encaissementTypesList.push(doc as IEncaissementType);
    }
  }

  async setValueToFormControl($event: {
    fields: Array<{
      name: string;
      value: string;
    }>;
  }): Promise<void> {
    for (let field of $event.fields) {
      const nameList = field.name.split('.');

      let formControl: AbstractControl = this.form;
      for (let name of nameList) {
        formControl = formControl.get(name);
      }

      if (field.name === 'invoiceIds') {
        if (field.value) {
          this.addInvoiceId(this.currentEncaissementIndex, field.value);
        }
      } else {
        formControl.setValue(field.value);

        formControl.markAsTouched();
        formControl.updateValueAndValidity();
      }
    }
  }

  generateEncaissementsFromImportedData(): void {
    for (const operation of this.data.operations) {
      this.addEncaissement();

      const i: number = this.encaissements.length - 1;

      this.getEncaissementField(i, 'libelle').setValue(operation.title);
      this.getEncaissementField(i, 'currency').setValue(this.data.currency);
      this.getEncaissementField(i, 'isReconciled').setValue(true);
      this.getEncaissementField(i, 'date').setValue(operation.date);

      if (operation.credit > 0) {
        this.getEncaissementField(i, 'isCredit').setValue(true);
        this.getEncaissementField(i, 'amount').setValue(operation.credit);
      } else if (operation.debit < 0) {
        this.getEncaissementField(i, 'isDebit').setValue(true);
        this.getEncaissementField(i, 'amount').setValue(operation.debit * -1);
      }

      const operationTypesToMatch: {
        sourceToMatch: string;
        encaissementTypeId: string;
      }[] = [
        {
          sourceToMatch: 'VIR',
          encaissementTypeId: 'Cm9kB9ZNaZbFnXo1Bx3Y'
        },
        {
          sourceToMatch: 'CHEQUE',
          encaissementTypeId: '3sMVVghuAnSNpM36QfCf'
        },
        {
          sourceToMatch: 'PRLV',
          encaissementTypeId: 'AZHyJ4DIvdcfwcXfLwZY'
        }
      ];

      for (const operationTypeToMatch of operationTypesToMatch) {
        if (operation.title.match(operationTypeToMatch.sourceToMatch)) {
          this.getEncaissementField(i, 'encaissementTypeId').setValue(
            operationTypeToMatch.encaissementTypeId
          );
        }
      }

      for (const bankAccount of this.bankAccountsList) {
        if (bankAccount.iban.split(' ').join('') === this.data.ibanAccount) {
          this.getEncaissementField(i, 'bankAccountId').setValue(bankAccount.id);
          break;
        }
      }

      this.getEncaissementField(i, 'id').setValue(
        this.getEncaissement(i).value.bankAccountId +
          '_' +
          this.getEncaissement(i).value.encaissementTypeId +
          '_' +
          this.getEncaissement(i).value.date +
          '_' +
          this.getEncaissement(i).value.currency +
          '_' +
          this.getEncaissement(i).value.amount
      );

      this.suggestionInvoices.push([]);
    }

    this.form.updateValueAndValidity();
    this.form.markAllAsTouched();

    this.currentEncaissementIndex = 0;

    this.getSuggestionInvoices(this.currentEncaissementIndex);

    this.displayEncaissementForm = true;
  }

  detectDataType(data: any[][]): EnumDataType | null {
    if (data[0].length === 5) {
      return EnumDataType.bnp;
    } else if (data[0].length === 3) {
      return EnumDataType.quontoSimpliflied;
    } else if (data[0].length === 106) {
      return EnumDataType.quonto;
    }

    return null;
  }

  private excelContentToJson(data: string): DataImport {
    const dataParsed: any[][] = this.ngxCsvParser.csvStringToArray(data, ',');

    const result: DataImport = {
      ibanAccount: null,
      currency: null,
      dateStart: null,
      dateEnd: null,
      operations: []
    };

    const dataType: EnumDataType | null = this.detectDataType(dataParsed);

    switch (dataType) {
      case EnumDataType.bnp:
        for (let i = 0; i < dataParsed.length; i++) {
          switch (i) {
            case 0:
              result.ibanAccount = dataParsed[i][1].replace('n° ', '');
              break;
            case 1:
              result.currency = dataParsed[i][1];
              break;
            case 3:
              const dates: string[] = dataParsed[i][1].split(' au  ');

              result.dateStart = format(parse(dates[0], 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd');
              result.dateEnd = format(parse(dates[1], 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd');
              break;
            default:
              if (i >= 5 && dataParsed[i][0]) {
                const dateSplitted: string[] = dataParsed[i][1].split('/');

                let date: string = '';
                if (dateSplitted[2] && parseInt(dateSplitted[2]) >= 100) {
                  date = format(parse(dataParsed[i][1], 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd');
                } else {
                  date = format(parse(dataParsed[i][1], 'dd/MM/yy', new Date()), 'yyyy-MM-dd');
                }

                result.operations.push({
                  date,
                  title: dataParsed[i][2],
                  debit: dataParsed[i][3] !== '' ? parseFloat(dataParsed[i][3]) : 0,
                  credit: dataParsed[i][4] !== '' ? parseFloat(dataParsed[i][4]) : 0
                });
              }
              break;
          }
        }

        break;
      case EnumDataType.quonto:
        if (dataParsed.length > 1) {
          for (let i = dataParsed.length - 1; i >= 1; i--) {
            const date: string = format(
              parse(dataParsed[i][4], 'dd-MM-yyyy HH:mm:ss', new Date()),
              'yyyy-MM-dd'
            );

            if (i === dataParsed.length - 1) {
              result.ibanAccount = dataParsed[i][89];
              result.currency = dataParsed[i][11];
              result.dateStart = date;
            } else if (i === 1) {
              result.dateEnd = date;
            }

            const titleArr: string[] = [];

            for (let fieldIndex of [90, 97, 103]) {
              if (dataParsed[i][fieldIndex]) {
                titleArr.push(dataParsed[i][fieldIndex]);
              }
            }

            const amount: number = parseFloat(dataParsed[i][5].replace(',', '.'));

            result.operations.push({
              date,
              title: titleArr.join(' | '),
              debit: amount < 0 ? amount : 0,
              credit: amount > 0 ? amount : 0
            });
          }
        }
        break;
      case EnumDataType.quontoSimpliflied:
        result.currency = EnumCurrency.EUR;

        if (dataParsed.length > 1) {
          for (let i = dataParsed.length - 1; i >= 1; i--) {
            const date: string = format(
              parse(dataParsed[i][0], 'dd-MM-yyyy HH:mm:ss', new Date()),
              'yyyy-MM-dd'
            );

            if (i === dataParsed.length - 1) {
              result.dateStart = date;
            } else if (i === 1) {
              result.dateEnd = date;
            }

            const amount: number = parseFloat(dataParsed[i][2].replace(',', '.'));

            result.operations.push({
              date,
              title: dataParsed[i][1],
              debit: amount < 0 ? amount : 0,
              credit: amount > 0 ? amount : 0
            });
          }
        }
        break;
      case EnumDataType.palpatine:
        break;
    }

    return result;
  }

  selectSuggestedInvoice(i: number, suggestionInvoice: ISuggestedInvoice): void {
    this.addInvoiceId(i, suggestionInvoice.invoice.id);
  }

  async getSuggestionInvoices(i: number): Promise<void> {
    this.suggestionInvoices[i] = [];

    if (!this.getEncaissementField(i, 'invoiceIds').value.length) {
      const invoiceRefs: string[] = this.extractInvoiceRefFromText(
        this.form.value.encaissements[i].libelle
      );

      if (invoiceRefs) {
        for (const invoiceRef of invoiceRefs) {
          const invoicesByRef: IInvoice[] = await this.searchInvoiceInAlgoliaByRef(invoiceRef);

          this.appendSuggestedInvoicesToList(
            invoicesByRef,
            EnumSuggestionInvoiceReason.matchingInvoiceRef
          );
        }
      }

      const invoicesByAmount: IInvoice[] = await this.searchInvoiceSameAmount(
        this.form.value.encaissements[i].amount,
        this.form.value.encaissements[i].currency
      );

      this.appendSuggestedInvoicesToList(
        invoicesByAmount,
        EnumSuggestionInvoiceReason.sameAmountTtcTotal
      );

      const enquiryRefs: string[] = this.extractEnquiryRefFromText(
        this.form.value.encaissements[i].libelle
      );

      if (enquiryRefs) {
        for (const enquiryRef of enquiryRefs) {
          const enquiries: IEnquiry[] = await this.searchEnquiryByRef(enquiryRef);

          if (enquiries.length) {
            for (const enquiry of enquiries) {
              const invoicesByEnquiry: IInvoice[] = await this.searchInvoiceByEnquiryId(enquiry.id);

              this.appendSuggestedInvoicesToList(
                invoicesByEnquiry,
                EnumSuggestionInvoiceReason.enquiryMatch
              );
            }
          }
        }
      }
    }

    this.sortSuggestionInvoicesByTotalReasons(i);
  }

  appendSuggestedInvoicesToList(invoices: IInvoice[], reason: EnumSuggestionInvoiceReason): void {
    for (const invoice of invoices) {
      this.invoicesObj[invoice.id] = invoice;

      if (
        this.getEncaissementField(this.currentEncaissementIndex, 'date').value &&
        invoice.issueDate &&
        !isBefore(
          new Date(this.getEncaissementField(this.currentEncaissementIndex, 'date').value),
          invoice.issueDate
        )
      ) {
        let existingIndex = -1;
        for (let i = 0; i < this.suggestionInvoices[this.currentEncaissementIndex].length; i++) {
          if (this.suggestionInvoices[this.currentEncaissementIndex][i].invoice.id === invoice.id) {
            existingIndex = i;
          }
        }

        if (existingIndex === -1) {
          this.suggestionInvoices[this.currentEncaissementIndex].push({
            reasons: [reason],
            invoice: invoice
          });
        } else {
          if (
            !this.suggestionInvoices[this.currentEncaissementIndex][existingIndex].reasons.includes(
              reason
            )
          ) {
            this.suggestionInvoices[this.currentEncaissementIndex][existingIndex].reasons.push(
              reason
            );
          }
        }
      }
    }
  }

  sortSuggestionInvoicesByTotalReasons(i: number): void {
    this.suggestionInvoices[i] = this.suggestionInvoices[i].sort((a, b) => {
      if (
        a.reasons.length > b.reasons.length ||
        (a.reasons.length === b.reasons.length &&
          a.reasons.indexOf(EnumSuggestionInvoiceReason.matchingInvoiceRef) !== -1)
      ) {
        return -1;
      } else if (a.reasons.length < b.reasons.length) {
        return 1;
      }
    });
  }

  extractInvoiceRefFromText(text: string): string[] {
    let refs: string[] = text.match(new RegExp(/([FA]-\d{6}-\d+)/g));

    if (!refs) {
      refs = text.match(new RegExp(/([PRO]+-[A-Z]+\d+.\d+.\d+)/g));
    }

    if (!refs) {
      refs = text.match(new RegExp(/([PRO]+ [A-Z]+\d+.\d+.\d+)/g));

      if (refs) {
        for (let i in refs) {
          refs[i].replaceAll('PRO ', 'PRO-');
        }
      }
    }

    return refs || [];
  }

  extractEnquiryRefFromText(text: string): string[] {
    return text.match(new RegExp(/([A-Z]{3}\d{2}\.\d+)/g));
  }

  async searchInvoiceInAlgoliaByRef(invoiceRef: string): Promise<IInvoice[]> {
    if (invoiceRef.length) {
      try {
        const invoices: IInvoice[] = await this.algoliaService.searchInvoices(
          invoiceRef,
          ['ref'],
          []
        );

        return invoices;
      } catch (err) {
        console.log(err);

        return [];
      }
    } else {
      return [];
    }
  }

  async searchEnquiryByRef(enquiryRef: string): Promise<IEnquiry[]> {
    if (enquiryRef.length) {
      try {
        const enquiries: IEnquiry[] = await this.remoteService.searchEnquiryByRef(enquiryRef);

        return enquiries;
      } catch (err) {
        console.log(err);

        return [];
      }
    } else {
      return [];
    }
  }

  async searchInvoiceInAlgoliaByEnquiryRef(enquiryRef: string): Promise<IInvoice[]> {
    if (enquiryRef.length) {
      try {
        const invoices: IInvoice[] = await this.algoliaService.searchInvoices(
          enquiryRef,
          ['ref'],
          []
        );

        return invoices;
      } catch (err) {
        console.log(err);

        return [];
      }
    } else {
      return [];
    }
  }

  async searchInvoiceByEnquiryId(enquiryId: string): Promise<IInvoice[]> {
    if (enquiryId !== null) {
      try {
        const filters: IAlgoliaFilter[] = [];

        filters.push({
          field: 'enquiryId',
          type: 'string',
          operator: '=',
          value: enquiryId
        });

        const invoices: IInvoice[] = await this.algoliaService.searchInvoices(
          '',
          ['ref', 'enquiryId'],
          filters
        );

        return invoices;
      } catch (err) {
        console.log(err);

        return [];
      }
    } else {
      return [];
    }
  }

  async searchInvoiceSameAmount(amount: number, currency: string): Promise<IInvoice[]> {
    if (amount !== null && currency !== null) {
      try {
        const filters: IAlgoliaFilter[] = [];

        filters.push({
          field: 'amountTtcTotal',
          type: 'number',
          operator: '=',
          value: amount
        });
        filters.push({
          field: 'currency',
          type: 'string',
          operator: '=',
          value: currency
        });

        const invoices: IInvoice[] = await this.algoliaService.searchInvoices(
          '',
          ['ref', 'amountTtcTotal', 'currency'],
          filters
        );

        return invoices;
      } catch (err) {
        console.log(err);

        return [];
      }
    } else {
      return [];
    }
  }

  goPrev(): void {
    this.currentEncaissementIndex--;

    this.form.updateValueAndValidity();

    this.getSuggestionInvoices(this.currentEncaissementIndex);
  }

  goNext(): void {
    this.currentEncaissementIndex++;

    this.form.updateValueAndValidity();

    this.getSuggestionInvoices(this.currentEncaissementIndex);
  }

  addInvoiceId(i: number, invoiceId: string): void {
    const invoiceIds: string[] = [...this.getEncaissementField(i, 'invoiceIds').value];

    if (!invoiceIds.includes(invoiceId)) {
      invoiceIds.push(invoiceId);

      this.loadInvoice(invoiceId);
    }

    this.getEncaissementField(i, 'invoiceIds').setValue(invoiceIds);
    this.getEncaissementField(i, 'invoiceIds').updateValueAndValidity();

    this.getSuggestionInvoices(this.currentEncaissementIndex);
  }

  async loadInvoice(invoiceId: string): Promise<void> {
    if (typeof this.invoicesObj[invoiceId] === 'undefined') {
      this.invoicesObj[invoiceId] = null;

      this.subscriptions.add(
        this.invoiceService.getFromId(invoiceId).subscribe((invoice: IInvoice) => {
          this.invoicesObj[invoiceId] = invoice;
        })
      );
    }
  }

  removeInvoiceId(i: number, invoiceId: string): void {
    const invoiceIds: string[] = [...this.getEncaissementField(i, 'invoiceIds').value];

    const index: number = invoiceIds.indexOf(invoiceId);
    if (index !== -1) {
      invoiceIds.splice(index, 1);
    }

    this.getEncaissementField(i, 'invoiceIds').setValue(invoiceIds);
    this.getEncaissementField(i, 'invoiceIds').updateValueAndValidity();

    this.getSuggestionInvoices(this.currentEncaissementIndex);
  }
}
