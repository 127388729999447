import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  faClock,
  faEdit,
  faExternalLink,
  faPlaneArrival,
  faPlaneDeparture,
  faPlus
} from '@fortawesome/free-solid-svg-icons';
import { differenceInDays, differenceInMinutes } from 'date-fns';
import { Subscription } from 'rxjs';
import { EnumAcl } from 'src/app/enums/acl.enum';
import {
  getAirportLocalTimeFullFromUTC,
  getAirportUTCFullTimeString,
  getAirportUTCTime,
  getAirportUTCTimeString,
  IAirport
} from 'src/app/interfaces/airport.interface';
import {
  EnumEnquiryFlightAgentType,
  getEnquiryFlightAgentTypeLabel,
  IEnquiryFlight
} from 'src/app/interfaces/enquiry-flight.interface';
import {
  getDisplayedEnquiryRefTitle,
  getEnquiryRefTitleWithoutPrefix,
  IEnquiry
} from 'src/app/interfaces/enquiry.interface';
import { IFbo } from 'src/app/interfaces/fbo.interface';
import { IPipedriveOrganization } from 'src/app/interfaces/pipedrive.interface';
import { capitalize, convertMinutesToTime } from 'src/app/misc.utils';
import { AclService } from 'src/app/services/acl.service';
import { AirportService } from 'src/app/services/airports/airports.service';
import { EnquiryFlightService } from 'src/app/services/enquiry-flights/enquiry-flights.service';
import { FboService } from 'src/app/services/fbos/fbos.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { PipedriveService } from 'src/app/services/pipedrive.service';

import countries from '../../countries_fr.json';
import { IAirline } from 'src/app/interfaces/airline.interface';
import { AirlineService } from 'src/app/services/airlines/airlines.service';
import {
  getAircraftCompiledModelTitle,
  IAircraftCompiled
} from 'src/app/interfaces/aircraft-compiled.interface';
import { IAircraftModel } from 'src/app/interfaces/aircraft-model.interface';
import { AircraftCompiledService } from 'src/app/services/aircraft-compiled/aircraft-compiled.service';
import { IAircraft } from 'src/app/interfaces/aircraft.interface';
import { AircraftService } from 'src/app/services/aircrafts/aircrafts.service';
import { EnumCrewType, ICrew } from 'src/app/interfaces/crew.interface';
import { CrewService } from 'src/app/services/crews/crews.service';
import { IAirlineSupervisor } from 'src/app/interfaces/airline-supervisor.interface';
import { AirlineSupervisorService } from 'src/app/services/airline-supervisors/airline-supervisors.service';
import { EnumEnquiryType } from 'src/app/enums/enquiry-type.enum';
import { getUserFullname, IUser } from 'src/app/interfaces/user.interface';
import { UserService } from 'src/app/services/user/user.service';
import { IHandling } from 'src/app/interfaces/handling.interface';
import { HandlingService } from 'src/app/services/handlings/handlings.service';

type TAirportField = 'depart' | 'arrival';
type TTimeField = 'local' | 'utc';

@Component({
  selector: 'app-enquiry-flight-edit',
  templateUrl: './enquiry-flight-edit.component.html',
  styleUrls: ['./enquiry-flight-edit.component.scss']
})
export class EnquiryFlightEditComponent implements OnInit, OnChanges, OnDestroy {
  @Input() enquiry: IEnquiry | null = null;
  @Input() enquiryFlight: IEnquiryFlight | null = null;
  @Input() isFirst: boolean = false;
  @Input() inModal: boolean = false;

  @ViewChild('btnCloseModal') btnCloseModalEl: ElementRef;
  @ViewChild('modalFbo', { static: false }) modalFboElement: ElementRef;
  @ViewChild('modalHandling', { static: false }) modalHandlingElement: ElementRef;

  faPlaneDeparture = faPlaneDeparture;
  faClock = faClock;
  faPlaneArrival = faPlaneArrival;
  faExternalLink = faExternalLink;
  faPlus = faPlus;
  faEdit = faEdit;

  EnumAcl = EnumAcl;
  EnumCrewType = EnumCrewType;
  EnumEnquiryType = EnumEnquiryType;
  EnumEnquiryFlightAgentType = EnumEnquiryFlightAgentType;

  capitalize = capitalize;
  convertMinutesToTime = convertMinutesToTime;
  getEnquiryFlightAgentTypeLabel = getEnquiryFlightAgentTypeLabel;
  getUserFullname = getUserFullname;

  form: FormGroup = this.resetForm();

  airportsObj: { [id: string]: IAirport | null } = {};
  airlinesObj: { [id: string]: IAirline | null } = {};
  clientsObj: { [id: string]: IPipedriveOrganization | null } = {};
  fbosObj: { [airportId: string]: IFbo[] } = {};
  handlingsObj: { [airportId: string]: IHandling[] } = {};
  crewsByType: { [type: string]: ICrew[] } = {};
  supervisors: IAirlineSupervisor[] = [];

  users: IUser[] = [];
  loadingUsers: boolean = false;

  loadingFbosForAirport: { [airportId: string]: boolean } = {};
  loadingHandlingsForAirport: { [airportId: string]: boolean } = {};
  loadingCrewsForAirline: boolean = false;
  loadingSupervisorsForAirline: boolean = false;

  currentAirportEdited: TAirportField | null = null;
  editingFbo: IFbo | null = null;
  editingHandling: IHandling | null = null;

  countriesList: {
    title: string;
    value: string;
  }[] = [];

  loadingAircraftsCompiled: boolean = false;
  aircraftsCompiled: IAircraftCompiled[] = [];
  aircraftModels: IAircraftModel[] = [];
  loadingAircrafts: boolean = false;
  aircrafts: IAircraft[] = [];

  private subscriptions = new Subscription();
  private subscriptionsForAirline = new Subscription();

  constructor(
    private router: Router,
    private airportService: AirportService,
    private loaderService: LoaderService,
    private enquiryFlightService: EnquiryFlightService,
    private pipedriveService: PipedriveService,
    private fboService: FboService,
    private handlingService: HandlingService,
    private aclService: AclService,
    private airlineService: AirlineService,
    private aircraftCompiledService: AircraftCompiledService,
    private aircraftService: AircraftService,
    private crewService: CrewService,
    private userService: UserService,
    private airlineSupervisorService: AirlineSupervisorService
  ) {
    for (const code in countries) {
      this.countriesList.push({
        title: countries[code],
        value: code
      });
    }
  }

  get airportDepartId(): FormControl {
    return this.form.get('airportDepartId') as FormControl;
  }

  get airportDepartTitle(): FormControl {
    return this.form.get('airportArrivalId') as FormControl;
  }

  get airportDepartIataCode(): FormControl {
    return this.form.get('airportDepartIataCode') as FormControl;
  }

  get airportArrivalId(): FormControl {
    return this.form.get('airportArrivalId') as FormControl;
  }

  get airportArrivalTitle(): FormControl {
    return this.form.get('airportArrivalTitle') as FormControl;
  }

  get airportArrivalIataCode(): FormControl {
    return this.form.get('airportArrivalIataCode') as FormControl;
  }

  getTimezoneField(airportField: TAirportField): FormControl {
    return this.form.get(airportField + 'Timezone') as FormControl;
  }

  getDateTimeField(airportField: TAirportField, timeField: TTimeField): FormControl {
    return this.form.get(airportField + 'DateTime' + capitalize(timeField)) as FormControl;
  }

  getDateField(airportField: TAirportField, timeField: TTimeField): FormControl {
    return this.form.get(airportField + 'Date' + capitalize(timeField)) as FormControl;
  }

  getTimeField(airportField: TAirportField, timeField: TTimeField): FormControl {
    return this.form.get(airportField + 'Time' + capitalize(timeField)) as FormControl;
  }

  get flyTimeInMin(): FormControl {
    return this.form.get('flyTimeInMin') as FormControl;
  }

  get flightNumber(): FormControl {
    return this.form.get('flightNumber') as FormControl;
  }

  getFboEnabled(airportField: TAirportField): FormControl {
    return this.form.get(airportField + 'FboEnabled') as FormControl;
  }

  getFboId(airportField: TAirportField): FormControl {
    return this.form.get(airportField + 'FboId') as FormControl;
  }

  getHandlingEnabled(airportField: TAirportField): FormControl {
    return this.form.get(airportField + 'HandlingEnabled') as FormControl;
  }

  getHandlingId(airportField: TAirportField): FormControl {
    return this.form.get(airportField + 'HandlingId') as FormControl;
  }

  getSlotEnabled(airportField: TAirportField): FormControl {
    return this.form.get(airportField + 'SlotEnabled') as FormControl;
  }

  getSlotTime(airportField: TAirportField): FormControl {
    return this.form.get(airportField + 'SlotTime') as FormControl;
  }

  getTerminalEnabled(airportField: TAirportField): FormControl {
    return this.form.get(airportField + 'TerminalEnabled') as FormControl;
  }

  getTerminal(airportField: TAirportField): FormControl {
    return this.form.get(airportField + 'Terminal') as FormControl;
  }

  get countryCode(): FormControl {
    return this.form.get('countryCode') as FormControl;
  }
  get airlineId(): FormControl {
    return this.form.get('airlineId') as FormControl;
  }
  get airlineTitle(): FormControl {
    return this.form.get('airlineTitle') as FormControl;
  }
  get aircraftModelId(): FormControl {
    return this.form.get('aircraftModelId') as FormControl;
  }
  get aircraftModelTitle(): FormControl {
    return this.form.get('aircraftModelTitle') as FormControl;
  }
  get aircraftId(): FormControl {
    return this.form.get('aircraftId') as FormControl;
  }
  get aircraftRegistration(): FormControl {
    return this.form.get('aircraftRegistration') as FormControl;
  }
  get opsH24Phone(): FormControl {
    return this.form.get('opsH24Phone') as FormControl;
  }
  get captainId(): FormControl {
    return this.form.get('captainId') as FormControl;
  }
  get coPilotId(): FormControl {
    return this.form.get('coPilotId') as FormControl;
  }
  get stewardessEnabled(): FormControl {
    return this.form.get('stewardessEnabled') as FormControl;
  }
  get stewardessIds(): FormControl {
    return this.form.get('stewardessIds') as FormControl;
  }
  get supervisorEnabled(): FormControl {
    return this.form.get('supervisorEnabled') as FormControl;
  }
  get supervisorIds(): FormControl {
    return this.form.get('supervisorIds') as FormControl;
  }

  get comment(): FormControl {
    return this.form.get('comment') as FormControl;
  }

  get isFerryFlight(): FormControl {
    return this.form.get('isFerryFlight') as FormControl;
  }

  get isAircraftGroundedOnArrival(): FormControl {
    return this.form.get('isAircraftGroundedOnArrival') as FormControl;
  }

  get pax(): FormControl {
    return this.form.get('pax') as FormControl;
  }

  get weight(): FormControl {
    return this.form.get('weight') as FormControl;
  }

  get volume(): FormControl {
    return this.form.get('volume') as FormControl;
  }

  getAgentEnabled(airportField: TAirportField): FormControl {
    return this.form.get(airportField + 'AgentEnabled') as FormControl;
  }
  getAgentType(airportField: TAirportField): FormControl {
    return this.form.get(airportField + 'AgentType') as FormControl;
  }
  getAgentUserId(airportField: TAirportField): FormControl {
    return this.form.get(airportField + 'AgentUserId') as FormControl;
  }
  getAgentUserInitials(airportField: TAirportField): FormControl {
    return this.form.get(airportField + 'AgentUserInitials') as FormControl;
  }
  getAgentUserFullName(airportField: TAirportField): FormControl {
    return this.form.get(airportField + 'AgentUserFullName') as FormControl;
  }
  getAgentCompany(airportField: TAirportField): FormControl {
    return this.form.get(airportField + 'AgentCompany') as FormControl;
  }
  getAgentPhone(airportField: TAirportField): FormControl {
    return this.form.get(airportField + 'AgentPhone') as FormControl;
  }
  getAgentEmail(airportField: TAirportField): FormControl {
    return this.form.get(airportField + 'AgentEmail') as FormControl;
  }

  get agentTypes(): EnumEnquiryFlightAgentType[] {
    return Object.values(EnumEnquiryFlightAgentType);
  }

  ngOnInit(): void {
    this.applyEnquiryFlight();
    this.updatedEnquiry();
    this.loadUsers();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['enquiryFlight'] &&
      (!changes['enquiryFlight'].previousValue ||
        changes['enquiryFlight'].previousValue !== changes['enquiryFlight'].currentValue)
    ) {
      this.applyEnquiryFlight();
    }

    if (
      changes['enquiry'] &&
      (!changes['enquiry'].previousValue ||
        changes['enquiry'].previousValue !== changes['enquiry'].currentValue)
    ) {
      this.updatedEnquiry();
    }
  }

  updatedEnquiry(): void {
    if (this.enquiry?.clientId) {
      this.loadClient(this.enquiry.clientId.toString());
    }
  }

  async loadClient(clientId: string): Promise<void> {
    if (typeof this.clientsObj[clientId] === 'undefined') {
      this.clientsObj[clientId] = null;

      this.clientsObj[clientId] = await this.pipedriveService.getOrganization(clientId);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.subscriptionsForAirline.unsubscribe();

    window['$']('.tooltip').remove();

    this.removeModal();
  }

  removeModal(): void {
    window['$'](this.modalFboElement.nativeElement).modal('hide');
    window['$']('body').removeClass('modal-open');
    window['$']('.modal-backdrop').remove();
  }

  updateTooltip(): void {
    if (window['$']('[rel="tooltip"]').length) {
      window['$']('[rel="tooltip"]').tooltip({
        html: true,
        boundary: 'window',
        trigger: 'hover'
      });
    }
  }

  resetForm(): FormGroup {
    return new FormGroup({
      airportDepartId: new FormControl(null, Validators.required),
      airportDepartTitle: new FormControl(null, Validators.required),
      airportDepartIataCode: new FormControl(null, Validators.required),
      airportArrivalId: new FormControl(null, Validators.required),
      airportArrivalTitle: new FormControl(null, Validators.required),
      airportArrivalIataCode: new FormControl(null, Validators.required),
      departTimezone: new FormControl(null, Validators.required),
      departDateTimeLocal: new FormControl(null, [
        Validators.required,
        this.dateMinimum('2000-01-01')
      ]),
      departDateLocal: new FormControl(null, [Validators.required, this.dateMinimum('2000-01-01')]),
      departTimeLocal: new FormControl(null, Validators.required),
      departDateTimeUtc: new FormControl(null, [
        Validators.required,
        this.dateMinimum('2000-01-01')
      ]),
      departDateUtc: new FormControl(null, Validators.required),
      departTimeUtc: new FormControl(null, Validators.required),
      arrivalTimezone: new FormControl(null, Validators.required),
      arrivalDateTimeLocal: new FormControl(null, [
        Validators.required,
        this.dateMinimum('2000-01-01')
      ]),
      arrivalDateLocal: new FormControl(null, [
        Validators.required,
        this.dateMinimum('2000-01-01')
      ]),
      arrivalTimeLocal: new FormControl(null, Validators.required),
      arrivalDateTimeUtc: new FormControl(null, [
        Validators.required,
        this.dateMinimum('2000-01-01')
      ]),
      arrivalDateUtc: new FormControl(null, Validators.required),
      arrivalTimeUtc: new FormControl(null, Validators.required),
      flyTimeInMin: new FormControl(null),
      flightNumber: new FormControl(null),
      departFboEnabled: new FormControl(false),
      departFboId: new FormControl(null),
      arrivalFboEnabled: new FormControl(false),
      arrivalFboId: new FormControl(null),
      departHandlingEnabled: new FormControl(false),
      departHandlingId: new FormControl(null),
      arrivalHandlingEnabled: new FormControl(false),
      arrivalHandlingId: new FormControl(null),
      departSlotEnabled: new FormControl(false),
      departSlotTime: new FormControl(null),
      arrivalSlotEnabled: new FormControl(false),
      arrivalSlotTime: new FormControl(null),
      departTerminalEnabled: new FormControl(false),
      departTerminal: new FormControl(null),
      arrivalTerminalEnabled: new FormControl(false),
      arrivalTerminal: new FormControl(null),
      countryCode: new FormControl(null),
      airlineId: new FormControl(null),
      airlineTitle: new FormControl(null),
      aircraftModelId: new FormControl(null),
      aircraftModelTitle: new FormControl(null),
      aircraftId: new FormControl(null),
      aircraftRegistration: new FormControl(null),
      opsH24Phone: new FormControl(null),
      captainId: new FormControl(null),
      coPilotId: new FormControl(null),
      stewardessEnabled: new FormControl(false),
      stewardessIds: new FormControl([]),
      supervisorEnabled: new FormControl(false),
      supervisorIds: new FormControl([]),
      comment: new FormControl(null),
      isFerryFlight: new FormControl(false),
      isAircraftGroundedOnArrival: new FormControl(false),
      pax: new FormControl(null),
      weight: new FormControl(null),
      volume: new FormControl(null),
      departAgentEnabled: new FormControl(false),
      departAgentType: new FormControl(null),
      departAgentUserId: new FormControl(null),
      departAgentUserInitials: new FormControl(null),
      departAgentUserFullName: new FormControl(null),
      departAgentCompany: new FormControl(null),
      departAgentPhone: new FormControl(null),
      departAgentEmail: new FormControl(null),
      arrivalAgentEnabled: new FormControl(false),
      arrivalAgentType: new FormControl(null),
      arrivalAgentUserId: new FormControl(null),
      arrivalAgentUserInitials: new FormControl(null),
      arrivalAgentUserFullName: new FormControl(null),
      arrivalAgentCompany: new FormControl(null),
      arrivalAgentPhone: new FormControl(null),
      arrivalAgentEmail: new FormControl(null)
    });
  }

  applyEnquiryFlight(): void {
    this.form = this.resetForm();

    this.updateTooltip();

    if (this.enquiryFlight) {
      for (const field in this.form.value) {
        if (typeof this.enquiryFlight[field] !== 'undefined') {
          switch (field) {
            default:
              this.form.get(field).setValue(this.enquiryFlight[field]);
              break;
          }
        }
      }

      for (const airportField of ['depart', 'arrival']) {
        for (const timeField of ['local', 'utc']) {
          if (
            this.getDateField(airportField as TAirportField, timeField as TTimeField).value &&
            this.getTimeField(airportField as TAirportField, timeField as TTimeField).value
          ) {
            this.getDateTimeField(airportField as TAirportField, timeField as TTimeField).setValue(
              this.getDateField(airportField as TAirportField, timeField as TTimeField).value +
                'T' +
                this.getTimeField(airportField as TAirportField, timeField as TTimeField).value
            );

            this.getDateTimeField(
              airportField as TAirportField,
              timeField as TTimeField
            ).updateValueAndValidity();
          }
        }
      }

      for (const airportField of ['depart', 'arrival']) {
        if (this.form.get('airport' + capitalize(airportField) + 'Id').value) {
          this.loadAirport(this.form.get('airport' + capitalize(airportField) + 'Id').value);
        }

        this.updatedFboEnabled(airportField as TAirportField);
        this.updatedHandlingEnabled(airportField as TAirportField);

        if (this.form.get('airport' + capitalize(airportField) + 'Id').value) {
          this.loadFbosForAirport(this.form.get('airport' + capitalize(airportField) + 'Id').value);
        }

        this.updatedSlotEnabled(airportField as TAirportField);
        this.updatedTerminalEnabled(airportField as TAirportField);
        this.updatedAgentEnabled(airportField as TAirportField);
        this.updatedAgentType(airportField as TAirportField, false);
      }

      if (this.airlineId.value) {
        this.loadAirline(this.airlineId.value);

        this.loadAircraftCompiled();
        this.loadAirlineCrewsAndSupervisors();
      }

      if (this.aircraftModelId.value) {
        this.updatedAircraftModelId();
      }

      this.updatedStewardessEnabled();
      this.updatedSupervisorEnabled();
      this.updateIsFerryFlight();

      if (!this.isFirst) {
        this.isFerryFlight.setValue(false);
      }

      this.form.enable();
    }
  }

  async setValueToFormControl($event: {
    fields: {
      name: string;
      value: any;
    }[];
  }): Promise<void> {
    for (let field of $event.fields) {
      const nameList = field.name.split('.');

      let formControl: AbstractControl = this.form;
      for (let name of nameList) {
        formControl = formControl.get(name);
      }

      if (field.name === 'airlineId') {
        this.applyAirline(field.value as IAirline | null);
      } else {
        formControl.setValue(field.value);
      }

      if (['airportDepartId', 'airportArrivalId'].includes(field.name)) {
        this.loadAirport(field.value);
      }

      formControl.markAsTouched();
      formControl.updateValueAndValidity();
    }
  }

  applyAirline(airline: IAirline | null): void {
    if (airline) {
      this.countryCode.setValue(airline.countryCode);
      this.airlineId.setValue(airline.id);
      this.airlineTitle.setValue(airline.title);

      this.loadAircraftCompiled();
      this.loadAirlineCrewsAndSupervisors();
    } else {
      for (const field of [
        'countryCode',
        'airlineId',
        'airlineTitle',
        'aircraftModelId',
        'aircraftModelTitle',
        'aircraftId',
        'aircraftRegistration'
      ]) {
        this[field].setValue(null);
        this[field].updateValueAndValidity();
      }
    }
  }

  loadAirport(airportId: string): void {
    if (typeof this.airportsObj[airportId] === 'undefined') {
      this.airportsObj[airportId] = null;

      this.subscriptions.add(
        this.airportService.getFromId(airportId).subscribe((airport: IAirport | null) => {
          this.airportsObj[airportId] = airport;

          this.applyAirportIfSelected(airportId);
        })
      );
    }
  }

  applyAirportIfSelected(airportId: string): void {
    if (this.airportsObj[airportId]) {
      for (const field of ['airportDepartId', 'airportArrivalId']) {
        if (this.form.get(field).value === airportId) {
          const fieldWithoutId: string = field.replace('Id', '');
          const airportField: TAirportField = fieldWithoutId
            .replace('airport', '')
            .toLowerCase() as TAirportField;

          this.form.get(fieldWithoutId + 'Title').setValue(this.airportsObj[airportId].title);
          this.form.get(fieldWithoutId + 'IataCode').setValue(this.airportsObj[airportId].iataCode);
          this.form.get(airportField + 'Timezone').setValue(this.airportsObj[airportId].timezoneId);

          this.updatedDateTime(airportField, 'local');
        }
      }
    }
  }

  dateMinimum(date: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      if (differenceInDays(new Date(control.value), new Date(date)) < 0) {
        return {
          dateBelowMinimum: true,
          dateMinimum: new Date(date)
        };
      } else {
        return null;
      }
    };
  }

  submitForm(): void {
    this.form.markAsTouched();

    if (this.form.valid) {
      let data = Object.assign({}, this.form.value);

      for (const field in data) {
        if (typeof data[field] == 'undefined') {
          data[field] = null;
        }
      }

      if (this.enquiry) {
        data.enquiryId = this.enquiry.id;
        data.enquiryType = this.enquiry.type;
        data.enquiryRefContractTitle = getDisplayedEnquiryRefTitle(this.enquiry, 'refContract');
        data.enquiryRefContractTitleWithoutPrefix = getEnquiryRefTitleWithoutPrefix(
          this.enquiry.refContractYear,
          this.enquiry.refContractMonth,
          this.enquiry.refContractNumber,
          2
        );
        data.enquiryRefContractYear = this.enquiry.refContractYear;
        data.enquiryRefContractMonth = this.enquiry.refContractMonth;
        data.clientId = this.enquiry.clientId.toString();
        data.clientName = this.clientsObj[data.clientId]?.name || null;
      }

      this.loaderService.presentLoader();

      this.form.disable();

      let promise;
      if (this.enquiryFlight?.id) {
        data.id = this.enquiryFlight.id;
        promise = () => this.enquiryFlightService.update(data);
      } else {
        promise = () => this.enquiryFlightService.create(data);
      }

      promise()
        .then(async id => {
          if (this.enquiryFlight) {
            this.enquiryFlight.id = id;
          }

          this.form.reset();
          this.form.enable();

          await this.loaderService.hideLoaderOnSuccess();

          if (this.inModal) {
            if (this.btnCloseModalEl) {
              this.btnCloseModalEl.nativeElement.click();
            }
          } else {
            this.redirectAfterSaving();
          }
        })
        .catch(async err => {
          await this.loaderService.hideLoaderOnFailure(err.message);
        })
        .finally(() => {
          this.form.enable();
        });
    }
  }

  redirectAfterSaving(): void {
    this.router.navigate(['/admin/enquiries/' + this.enquiry.id]);
  }

  updatedDateTime(airportField: TAirportField, timeField: TTimeField): void {
    if (
      this.getDateTimeField(airportField, timeField).valid &&
      this.getDateTimeField(airportField, timeField).value
    ) {
      const dateSplitted: string[] = this.getDateTimeField(airportField, timeField).value.split(
        'T'
      );

      if (dateSplitted.length === 2) {
        this.getDateField(airportField, timeField).setValue(dateSplitted[0]);
        this.getTimeField(airportField, timeField).setValue(dateSplitted[1]);

        if (
          this.form.get('airport' + capitalize(airportField) + 'Id').value &&
          this.airportsObj[this.form.get('airport' + capitalize(airportField) + 'Id').value]
        ) {
          switch (timeField) {
            case 'local':
              const departTimeUtc: string | null = getAirportUTCFullTimeString(
                this.airportsObj[this.form.get('airport' + capitalize(airportField) + 'Id').value],
                dateSplitted[0],
                dateSplitted[1]
              );

              if (departTimeUtc) {
                const departTimeUtcSplitted: string[] = departTimeUtc.split(' ');

                this.getDateTimeField(airportField, 'utc').setValue(departTimeUtc);
                this.getDateField(airportField, 'utc').setValue(departTimeUtcSplitted[0]);
                this.getTimeField(airportField, 'utc').setValue(departTimeUtcSplitted[1]);
              }
              break;
            case 'utc':
              const departTimeLocal: string | null = getAirportLocalTimeFullFromUTC(
                this.airportsObj[this.form.get('airport' + capitalize(airportField) + 'Id').value],
                dateSplitted[0],
                dateSplitted[1]
              );

              if (departTimeLocal) {
                const departTimeLocalSplitted: string[] = departTimeLocal.split(' ');

                this.getDateTimeField(airportField, 'local').setValue(departTimeLocal);
                this.getDateField(airportField, 'local').setValue(departTimeLocalSplitted[0]);
                this.getTimeField(airportField, 'local').setValue(departTimeLocalSplitted[1]);
              }
              break;
          }
        }
      }

      this.refreshFlyTimeInMin();
    }
  }

  refreshFlyTimeInMin(): void {
    if (
      this.getDateTimeField('depart', 'utc').value &&
      this.getDateTimeField('arrival', 'utc').value
    ) {
      const flyTimeInMin: number = differenceInMinutes(
        new Date(this.getDateTimeField('arrival', 'utc').value + ':00Z'),
        new Date(this.getDateTimeField('depart', 'utc').value + ':00Z')
      );

      this.flyTimeInMin.setValue(flyTimeInMin);
      this.flyTimeInMin.updateValueAndValidity();
    }
  }

  updatedFboEnabled(airportField: TAirportField): void {
    if (this.form.get('airport' + capitalize(airportField) + 'Id').value) {
      this.loadFbosForAirport(this.form.get('airport' + capitalize(airportField) + 'Id').value);
    }

    if (this.getFboEnabled(airportField).value) {
      this.getFboId(airportField).addValidators(Validators.required);
    } else {
      this.getFboId(airportField).clearValidators();
    }

    this.getFboId(airportField).updateValueAndValidity();
  }

  updatedHandlingEnabled(airportField: TAirportField): void {
    if (this.form.get('airport' + capitalize(airportField) + 'Id').value) {
      this.loadHandlingsForAirport(
        this.form.get('airport' + capitalize(airportField) + 'Id').value
      );
    }

    if (this.getHandlingEnabled(airportField).value) {
      this.getHandlingId(airportField).addValidators(Validators.required);
    } else {
      this.getHandlingId(airportField).clearValidators();
    }

    this.getHandlingId(airportField).updateValueAndValidity();
  }

  updatedSlotEnabled(airportField: TAirportField): void {
    if (this.getSlotEnabled(airportField).value) {
      this.getSlotTime(airportField).addValidators(Validators.required);
    } else {
      this.getSlotTime(airportField).clearValidators();
    }

    this.getSlotTime(airportField).updateValueAndValidity();
  }

  updatedTerminalEnabled(airportField: TAirportField): void {
    if (this.getTerminalEnabled(airportField).value) {
      this.getTerminal(airportField).addValidators(Validators.required);
    } else {
      this.getTerminal(airportField).clearValidators();
    }

    this.getTerminal(airportField).updateValueAndValidity();
  }

  updateIsFerryFlight(): void {}

  updatedStewardessEnabled(): void {
    if (this.stewardessEnabled.value) {
      this.stewardessIds.addValidators(Validators.required);
    } else {
      this.stewardessIds.clearValidators();
      this.stewardessIds.setValue([]);
    }

    this.stewardessIds.updateValueAndValidity();
  }

  updatedSupervisorEnabled(): void {
    if (this.supervisorEnabled.value) {
      this.supervisorIds.addValidators(Validators.required);
    } else {
      this.supervisorIds.clearValidators();
      this.supervisorIds.setValue([]);
    }

    this.supervisorIds.updateValueAndValidity();
  }

  updatedAgentEnabled(airportField: TAirportField): void {
    if (this.getAgentEnabled(airportField).value) {
      this.getAgentType(airportField).addValidators(Validators.required);
    } else {
      this.getAgentType(airportField).clearValidators();
      this.getAgentType(airportField).setValue(null);
    }
    this.getAgentType(airportField).updateValueAndValidity();
  }

  updatedAgentType(airportField: TAirportField, forceResetExternal: boolean = true): void {
    if (this.getAgentType(airportField).value) {
      switch (this.getAgentType(airportField).value) {
        case EnumEnquiryFlightAgentType.internal:
          this.getAgentUserId(airportField).addValidators(Validators.required);

          this.updatedAgentUserId(airportField);
          break;
        case EnumEnquiryFlightAgentType.external:
          if (forceResetExternal) {
            for (const field of [
              'UserId',
              'UserInitials',
              'UserFullName',
              'Company',
              'Phone',
              'Email'
            ]) {
              this.form.get(airportField + 'Agent' + field).clearValidators();
              this.form.get(airportField + 'Agent' + field).setValue(null);
            }
          }
          break;
      }
    } else {
      for (const field of ['UserId', 'UserInitials', 'UserFullName', 'Company', 'Phone', 'Email']) {
        this.form.get(airportField + 'Agent' + field).clearValidators();
        this.form.get(airportField + 'Agent' + field).setValue(null);
      }
    }
  }

  updatedAgentUserId(airportField: TAirportField): void {
    if (this.getAgentUserId(airportField).value) {
      for (const user of this.users) {
        if (user.id === this.getAgentUserId(airportField).value) {
          this.getAgentUserInitials(airportField).setValue(user.initials);
          this.getAgentUserFullName(airportField).setValue(getUserFullname(user));
          this.getAgentCompany(airportField).setValue('Artheau Aviation');
          this.getAgentPhone(airportField).setValue(user.phone);
          this.getAgentEmail(airportField).setValue(user.email);
          break;
        }
      }
    } else {
      for (const field of ['UserInitials', 'UserFullName', 'Company', 'Phone', 'Email']) {
        this.form.get(airportField + 'Agent' + field).clearValidators();
      }
    }
  }

  loadFbosForAirport(airportId: string): void {
    if (typeof this.fbosObj[airportId] === 'undefined') {
      this.loadingFbosForAirport[airportId] = true;

      this.fbosObj[airportId] = [];

      this.subscriptions.add(
        this.fboService.getForAirport(airportId).subscribe((fbos: IFbo[]) => {
          this.fbosObj[airportId] = fbos;

          this.fbosObj[airportId].sort((a, b) =>
            a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ? -1 : 1
          );

          this.loadingFbosForAirport[airportId] = false;
        })
      );
    }
  }

  loadHandlingsForAirport(airportId: string): void {
    if (typeof this.handlingsObj[airportId] === 'undefined') {
      this.loadingHandlingsForAirport[airportId] = true;

      this.handlingsObj[airportId] = [];

      this.subscriptions.add(
        this.handlingService.getForAirport(airportId).subscribe((fbos: IFbo[]) => {
          this.handlingsObj[airportId] = fbos;

          this.handlingsObj[airportId].sort((a, b) =>
            a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ? -1 : 1
          );

          this.loadingHandlingsForAirport[airportId] = false;
        })
      );
    }
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  addFbo(airportField: TAirportField): void {
    this.currentAirportEdited = airportField;

    window['$'](this.modalFboElement.nativeElement).modal('show');
  }

  editFbo(airportField: TAirportField): void {
    this.currentAirportEdited = airportField;

    if (
      this.getFboId(airportField).value &&
      this.fbosObj[this.form.get('airport' + capitalize(airportField) + 'Id').value]
    ) {
      for (const fbo of this.fbosObj[
        this.form.get('airport' + capitalize(airportField) + 'Id').value
      ]) {
        if (fbo.id === this.getFboId(airportField).value) {
          this.editingFbo = fbo;
          break;
        }
      }
    }

    window['$'](this.modalFboElement.nativeElement).modal('show');
  }

  dismissFboModal(newId: string | null = null): void {
    if (this.currentAirportEdited && newId) {
      this.getFboId(this.currentAirportEdited).setValue(newId);
      this.getFboId(this.currentAirportEdited).updateValueAndValidity();
    }

    window['$'](this.modalFboElement.nativeElement).modal('hide');
  }

  addHandling(airportField: TAirportField): void {
    this.currentAirportEdited = airportField;

    window['$'](this.modalHandlingElement.nativeElement).modal('show');
  }

  editHandling(airportField: TAirportField): void {
    this.currentAirportEdited = airportField;

    if (
      this.getHandlingId(airportField).value &&
      this.handlingsObj[this.form.get('airport' + capitalize(airportField) + 'Id').value]
    ) {
      for (const handling of this.handlingsObj[
        this.form.get('airport' + capitalize(airportField) + 'Id').value
      ]) {
        if (handling.id === this.getHandlingId(airportField).value) {
          this.editingHandling = handling;
          break;
        }
      }
    }

    window['$'](this.modalHandlingElement.nativeElement).modal('show');
  }

  dismissHandlingModal(newId: string | null = null): void {
    if (this.currentAirportEdited && newId) {
      this.getHandlingId(this.currentAirportEdited).setValue(newId);
      this.getHandlingId(this.currentAirportEdited).updateValueAndValidity();
    }

    window['$'](this.modalHandlingElement.nativeElement).modal('hide');
  }

  loadAirline(airlineId: string): void {
    if (typeof this.airlinesObj[airlineId] === 'undefined') {
      this.airlinesObj[airlineId] = null;

      this.subscriptions.add(
        this.airlineService.getFromId(airlineId).subscribe((airline: IAirline | null) => {
          this.airlinesObj[airlineId] = airline;
        })
      );
    }
  }

  loadAircraftCompiled(): void {
    if (this.airlineId.value) {
      this.loadingAircraftsCompiled = true;

      this.subscriptions.add(
        this.aircraftCompiledService
          .getAllForAirline(this.airlineId.value)
          .subscribe((aircraftsCompiled: IAircraftCompiled[]) => {
            this.loadingAircraftsCompiled = true;

            this.aircraftsCompiled = aircraftsCompiled;

            this.updateAircraftModelsFromAircraftsCompiled();

            this.loadingAircraftsCompiled = false;
          })
      );
    }
  }

  updateAircraftModelsFromAircraftsCompiled(): void {
    const aircraftModelsObj: { [key: string]: IAircraftModel } = {};

    for (const aircraftCompiled of this.aircraftsCompiled) {
      aircraftModelsObj[aircraftCompiled.type] = {
        id: aircraftCompiled.aircraftModelId,
        family: aircraftCompiled.family,
        title: getAircraftCompiledModelTitle(aircraftCompiled),
        weight: aircraftCompiled.weight,
        volume: aircraftCompiled.volume,
        target: aircraftCompiled.target
      } as IAircraftModel;
    }

    this.aircraftModels = Object.values(aircraftModelsObj);
    this.aircraftModels.sort((a, b) => (a.title > b.title ? 1 : -1));

    // this.setEstimatedArrivalTimeIfNotProvided();

    this.updateAircraftsFromAircraftsCompiled();
  }

  updatedAircraftModelId(): void {
    if (this.aircraftModelId.value) {
      for (const aircraftModel of this.aircraftModels) {
        if (aircraftModel.id === this.aircraftModelId.value) {
          this.aircraftModelTitle.setValue(aircraftModel.title);

          break;
        }
      }
    } else {
      this.aircraftModelTitle.setValue(null);
    }

    this.aircraftModelTitle.updateValueAndValidity();

    this.updateAircraftsFromAircraftsCompiled();
  }

  updateAircraftsFromAircraftsCompiled(): void {
    this.aircrafts = [];

    if (this.airlineId.value && this.aircraftModelId.value) {
      this.loadingAircrafts = true;
      this.subscriptions.add(
        this.aircraftService
          .getAllForAirlineAndModel(this.airlineId.value, this.aircraftModelId.value)
          .subscribe((aircrafts: IAircraft[]) => {
            this.loadingAircrafts = true;

            this.aircrafts = aircrafts;

            this.aircrafts.sort((a, b) => (a.registration > b.registration ? 1 : -1));

            let aircraftSelectedIsInList: boolean = false;
            for (const aircraft of this.aircrafts) {
              if (aircraft.id === this.aircraftId.value) {
                aircraftSelectedIsInList = true;
                break;
              }
            }

            if (!aircraftSelectedIsInList) {
              this.aircraftId.setValue(null);
              this.aircraftId.updateValueAndValidity();
            }

            this.loadingAircrafts = false;
          })
      );

      this.aircrafts.sort((a, b) => (a.registration > b.registration ? 1 : -1));
    }
  }

  updatedAircraftId(): void {
    if (this.aircraftId.value) {
      for (const aircraft of this.aircrafts) {
        if (aircraft.id === this.aircraftId.value) {
          this.applyAircraftInfoToForm(aircraft);
          break;
        }
      }
    } else {
      this.applyAircraftInfoToForm(null);
    }
  }

  applyAircraftInfoToForm(aircraft: IAircraft | null): void {
    if (aircraft) {
      this.aircraftRegistration.setValue(
        aircraft.registration !== '' ? aircraft.registration : 'Inconnu'
      );
    } else {
      this.aircraftRegistration.setValue(null);
    }

    this.aircraftRegistration.updateValueAndValidity();
  }

  loadAirlineCrewsAndSupervisors(): void {
    if (this.airlineId.value) {
      if (this.subscriptionsForAirline) {
        this.subscriptionsForAirline.unsubscribe();
      }

      this.subscriptionsForAirline = new Subscription();

      this.loadingCrewsForAirline = true;
      this.crewsByType = {};

      this.subscriptionsForAirline.add(
        this.crewService.getForAirline(this.airlineId.value).subscribe((crews: ICrew[]) => {
          for (const crew of crews) {
            if (typeof this.crewsByType[crew.type] === 'undefined') {
              this.crewsByType[crew.type] = [];
            }

            this.crewsByType[crew.type].push(crew);
          }

          this.loadingCrewsForAirline = false;
        })
      );

      this.loadingSupervisorsForAirline = true;
      this.supervisors = [];

      this.subscriptionsForAirline.add(
        this.airlineSupervisorService
          .getForAirline(this.airlineId.value)
          .subscribe((supervisors: IAirlineSupervisor[]) => {
            this.supervisors = supervisors;

            this.loadingSupervisorsForAirline = false;
          })
      );
    }
  }

  toggleStewardessId(crewId: string): void {
    const values: string[] = [...this.stewardessIds.value];
    const index: number = values.indexOf(crewId);

    if (index === -1) {
      values.push(crewId);
    } else {
      values.splice(index, 1);
    }

    this.stewardessIds.setValue(values);
    this.stewardessIds.updateValueAndValidity();
  }

  toggleSupervisorId(supervisorId: string): void {
    const values: string[] = [...this.supervisorIds.value];
    const index: number = values.indexOf(supervisorId);

    if (index === -1) {
      values.push(supervisorId);
    } else {
      values.splice(index, 1);
    }

    this.supervisorIds.setValue(values);
    this.supervisorIds.updateValueAndValidity();
  }

  loadUsers(): void {
    this.loadingUsers = true;

    this.subscriptions.add(
      this.userService.getAll().subscribe((users: IUser[]) => {
        this.users = users;

        this.users.sort((a, b) => (getUserFullname(a) < getUserFullname(b) ? -1 : 1));

        this.loadingUsers = false;
      })
    );
  }
}
