import { finalize } from 'rxjs/operators';
import { Component, OnInit, NgZone, ViewChild, ElementRef } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  AbstractControl,
  FormControl
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';

import countries from '../../../countries_fr.json';
import countriesFull from '../../../countries_full.json';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { EnumContinentCode, getContinentLabel } from 'src/app/enums/continent-code.enum';
import { IClient } from 'src/app/interfaces/client.interface';
import { generateRandomId } from 'src/app/misc.utils';

@Component({
  selector: 'app-client-edit',
  templateUrl: './client-edit.component.html',
  styleUrls: ['./client-edit.component.scss']
})
export class ClientEditComponent implements OnInit {
  @ViewChild('modalUpload', { static: false }) modalUploadElement: ElementRef;
  isLogged: boolean = false;
  form: FormGroup;
  sending: boolean = false;
  client: IClient | null = null;
  clientId: string;
  countriesList: Array<{
    title: string;
    value: string;
  }> = [];
  uploading: boolean = false;
  uploadingProgress: number;
  currentFile: File;
  documentIndex: number;

  getContinentLabel = getContinentLabel;

  constructor(
    private formBuilder: FormBuilder,
    private remoteService: RemoteService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private zone: NgZone,
    private aclService: AclService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );

    for (const code in countries) {
      this.countriesList.push({
        title: countries[code],
        value: code
      });
    }
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      title: ['', [Validators.required]],
      countryCode: ['', [Validators.required]],
      continentCode: ['', [Validators.required]],
      website: [''],
      streetNumber: [''],
      route: [''],
      postalCode: [''],
      city: [''],
      tvaNumber: [''],
      companyContacts: new FormArray([]),
      documentsUrl: new FormArray([])
    });

    this.form.disable();

    this.activatedRoute.url.subscribe(async () => {
      this.clientId = this.activatedRoute.snapshot.paramMap.get('clientId');

      if (this.clientId) {
        await this.aclService.checkAclAccess(EnumAcl.clientsEdit);
        this.loadData();
      } else {
        await this.aclService.checkAclAccess(EnumAcl.clientsAdd);
        this.form.enable();
      }
    });
  }

  ngOnDestroy(): void {
    this.removeModal();
  }

  removeModal(): void {
    window['$'](this.modalUploadElement.nativeElement).modal('hide');
    window['$']('body').removeClass('modal-open');
    window['$']('.modal-backdrop').remove();
  }

  loadData(): void {
    // if (this.isLogged) {
    //   this.loadClient()
    // } else {
    //   setTimeout(() => {
    //     this.loadData()
    //   }, 500)
    // }
  }

  getContinentCodes(): EnumContinentCode[] {
    return Object.values(EnumContinentCode);
  }

  async loadClient(): Promise<void> {
    const doc = await this.remoteService.getDocument('clients', this.clientId);

    this.client = doc as IClient;

    this.setClient();
  }

  setClient(): void {
    if (this.form && this.client) {
      this.form.get('title').setValue(this.client.title);
      this.form.get('countryCode').setValue(this.client.countryCode);
      this.form.get('continentCode').setValue(this.client.continentCode);
      this.form.get('website').setValue(this.client.website);
      this.form.get('streetNumber').setValue(this.client.streetNumber);
      this.form.get('route').setValue(this.client.route);
      this.form.get('postalCode').setValue(this.client.postalCode);
      this.form.get('city').setValue(this.client.city);
      this.form.get('tvaNumber').setValue(this.client.tvaNumber);
      for (const i in this.client.companyContacts) {
        this.addContactCategory();

        const companyContactsFormArray = this.form.get('companyContacts') as FormArray;

        companyContactsFormArray
          .at(parseInt(i))
          .get('title')
          .setValue(this.client.companyContacts[i].title);

        for (const j in this.client.companyContacts[i].contacts) {
          this.addContactToCategory(parseInt(i));

          const contactsFormArray = this.form
            .get('companyContacts')
            .get(i)
            .get('contacts') as FormArray;

          contactsFormArray
            .at(parseInt(j))
            .get('id')
            .setValue(this.client.companyContacts[i].contacts[j].id);
          contactsFormArray
            .at(parseInt(j))
            .get('lastname')
            .setValue(this.client.companyContacts[i].contacts[j].lastname);
          contactsFormArray
            .at(parseInt(j))
            .get('firstname')
            .setValue(this.client.companyContacts[i].contacts[j].firstname);
          contactsFormArray
            .at(parseInt(j))
            .get('fonction')
            .setValue(this.client.companyContacts[i].contacts[j].fonction);
          contactsFormArray
            .at(parseInt(j))
            .get('phone')
            .setValue(this.client.companyContacts[i].contacts[j].phone);
          contactsFormArray
            .at(parseInt(j))
            .get('mobile')
            .setValue(this.client.companyContacts[i].contacts[j].mobile);
          contactsFormArray
            .at(parseInt(j))
            .get('email')
            .setValue(this.client.companyContacts[i].contacts[j].email);
        }
      }

      for (const i in this.client.documentsUrl) {
        this.addDocument();

        const documentsUrlFormArray = this.form.get('documentsUrl') as FormArray;

        documentsUrlFormArray
          .at(parseInt(i))
          .get('title')
          .setValue(this.client.documentsUrl[i].title);
        documentsUrlFormArray.at(parseInt(i)).get('url').setValue(this.client.documentsUrl[i].url);
      }

      this.form.enable();
    }
  }

  submitForm(): void {
    // this.form.markAsTouched()
    // if (this.form.valid) {
    // 	let data = Object.assign({}, this.form.value)
    //     this.sending = true
    // 	this.form.disable()
    //     for (const field in data) {
    //       if (typeof data[field] == 'undefined') {
    //         data[field] = null
    //       }
    //     }
    //     const routes: Array<string> = []
    //     if (data['streetNumber']) {
    //       routes.push(data['streetNumber'])
    //     }
    //     if (data['route']) {
    //       routes.push(data['route'])
    //     }
    //     data['formattedAddress'] = ''
    //     if (routes.length) {
    //       data['formattedAddress'] += routes.join(' ') + ', '
    //     }
    //     data['formattedAddress'] += data['postalCode'] + ' ' + data['city']
    //     for (const contact of data['companyContacts']) {
    //     }
    //     if (this.clientId) {
    // 			this.remoteService.updateDocumentToCollection('clients', this.clientId, data).then(() => {
    // 				this.sending = false
    // 				this.router.navigate(['/admin/clients/' + this.clientId])
    // 			}).catch(err => {
    // 				this.sending = false
    // 				this.form.enable()
    // 				alert(err.message)
    // 			})
    //     } else {
    //       this.remoteService.addDocumentToCollection('clients', data).then((docId: string) => {
    //         this.clientId = docId
    //         this.sending = false
    // 				this.router.navigate(['/admin/clients/' + this.clientId])
    // 			}).catch(err => {
    // 				this.sending = false
    // 				this.form.enable()
    // 				alert(err.message)
    // 			})
    //     }
    // }
  }

  setValueToFormControl($event: {
    fields: Array<{
      name: string;
      value: string;
    }>;
  }): void {
    for (let field of $event.fields) {
      const nameList = field.name.split('.');

      let formControl: AbstractControl = this.form;
      for (let name of nameList) {
        formControl = formControl.get(name);
      }

      formControl.setValue(field.value);

      if (field.name == 'countryCode') {
        this.assignContinents();
      }

      formControl.markAsTouched();
      formControl.updateValueAndValidity();
    }
  }

  assignContinents(): void {
    for (const country of countriesFull) {
      if (country['ISO3166-1-Alpha-2'] == this.form.value.countryCode) {
        this.form.get('continentCode').setValue(country.Continent);
      }
    }
  }

  addContactCategory(): void {
    const companyContactsFormArray = this.form.get('companyContacts') as FormArray;

    companyContactsFormArray.push(
      new FormGroup({
        title: new FormControl('', [Validators.required]),
        contacts: new FormArray([])
      })
    );

    this.form.updateValueAndValidity();
  }

  deleteContactCategory(i: number): void {
    const result = confirm(
      'La suppression de la catégorie de contact sera permanente. Êtes-vous sûr de vouloir continuer?'
    );

    if (result) {
      const companyContactsFormArray = this.form.get('companyContacts') as FormArray;

      companyContactsFormArray.removeAt(i);

      this.form.updateValueAndValidity();
    }
  }

  addContactToCategory(i: number): void {
    const contactsFormArray = this.form
      .get('companyContacts')
      .get(i.toString())
      .get('contacts') as FormArray;

    contactsFormArray.push(
      new FormGroup({
        id: new FormControl(generateRandomId(), [Validators.required]),
        lastname: new FormControl(''),
        firstname: new FormControl(''),
        fonction: new FormControl(''),
        phone: new FormControl(''),
        mobile: new FormControl(''),
        email: new FormControl('')
      })
    );

    this.form.updateValueAndValidity();
  }

  deleteContactFromCategory(i: number, j: number): void {
    const result = confirm(
      'La suppression du contact sera permanent. Êtes-vous sûr de vouloir continuer?'
    );

    if (result) {
      const contactsFormArray = this.form
        .get('companyContacts')
        .get(i.toString())
        .get('contacts') as FormArray;

      contactsFormArray.removeAt(j);

      this.form.updateValueAndValidity();
    }
  }

  addDocument(): void {
    const documentsUrlFormArray = this.form.get('documentsUrl') as FormArray;

    documentsUrlFormArray.push(
      new FormGroup({
        title: new FormControl('', [Validators.required]),
        url: new FormControl('', [Validators.required])
      })
    );

    this.form.updateValueAndValidity();
  }

  deleteDocument(i: number): void {
    const result = confirm(
      'La suppression du document sera permanent. Êtes-vous sûr de vouloir continuer?'
    );

    if (result) {
      const documentsUrlFormArray = this.form.get('documentsUrl') as FormArray;

      documentsUrlFormArray.removeAt(i);

      this.form.updateValueAndValidity();
    }
  }

  changeInputFile(fileInput: any): void {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.currentFile = fileInput.target.files[0];
    }
  }

  showUploadModal(i: number = null): void {
    this.documentIndex = i;

    window['$'](this.modalUploadElement.nativeElement).modal('show');
  }

  upload(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.form.disable();
      this.uploading = true;
      this.uploadingProgress = 0;

      window['$'](this.modalUploadElement.nativeElement).modal('hide');

      let result = this.remoteService.upload('clients', null, this.currentFile, 'file');

      result['task'].percentageChanges().subscribe((progress: number) => {
        this.zone.run(() => {
          this.uploadingProgress = Math.round(progress);
        });
      });

      result['task']
        .snapshotChanges()
        .pipe(
          finalize(() => {
            result['ref'].getDownloadURL().subscribe((downloadUrl: string) => {
              this.form.enable();

              if (this.documentIndex === null) {
                this.addDocument();

                const documentsUrlFormArray = this.form.get('documentsUrl') as FormArray;

                this.documentIndex = documentsUrlFormArray.controls.length - 1;
              }

              this.form
                .get('documentsUrl')
                .get(this.documentIndex.toString())
                .get('title')
                .setValue(this.currentFile['name']);
              this.form
                .get('documentsUrl')
                .get(this.documentIndex.toString())
                .get('url')
                .setValue(downloadUrl);

              this.form.updateValueAndValidity();

              this.uploading = false;
            });
          })
        )
        .subscribe();
    });
  }
}
