import {
  Component,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef
} from '@angular/core';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { IEnquiry, getDisplayedEnquiryRefTitle } from 'src/app/interfaces/enquiry.interface';
import { AlgoliaService } from 'src/app/services/algolia.service';
import { EnquiryService } from 'src/app/services/enquiry/enquiry.service';
import { RemoteService } from 'src/app/services/remote.service';

@Component({
  selector: 'app-enquiry-ref-picker',
  templateUrl: './enquiry-ref-picker.component.html',
  styleUrls: ['./enquiry-ref-picker.component.scss']
})
export class EnquiryRefPickerComponent implements OnChanges {
  @Input('isInvalid') isInvalid: boolean;
  @Input('inputName') inputName: string;
  @Input('disabled') disabled: boolean = false;
  @Input('defaultValue') defaultValue: string;
  @Input('contractRefOnly') contractRefOnly: boolean = false;
  @Output() setValueToFormControl: EventEmitter<{
    fields: {
      name: string;
      value: string;
      label: string;
    }[];
  }> = new EventEmitter();

  faXmark = faXmark;

  getDisplayedEnquiryRefTitle = getDisplayedEnquiryRefTitle;

  value: string;
  selectedEnquiry: IEnquiry;
  enquiries: IEnquiry[] = [];
  optionsSuggested: IEnquiry[] = [];
  @ViewChild('searchInput', { static: false }) searchInput: ElementRef;
  // initiated: boolean = false

  query: string;

  constructor(private algoliaService: AlgoliaService, private enquiryService: EnquiryService) {}

  ngOnChanges() {
    // if (!this.initiated && this.defaultId) {
    this.value = this.defaultValue;

    // this.initiated = true
    // }

    this.updateSelectedEnquiry();
  }

  async updateSelectedEnquiry(): Promise<void> {
    if (this.value) {
      const enquiry: IEnquiry = await this.loadEnquiryAsPromise(this.value);

      if (enquiry) {
        this.enquiries.push(enquiry);
      }
    }

    for (const enquiry of this.enquiries) {
      if (enquiry.id == this.value) {
        this.selectedEnquiry = enquiry;

        break;
      }
    }
  }

  loadEnquiryAsPromise(enquiryId: string): Promise<IEnquiry> {
    return new Promise((resolve, reject) => {
      if (enquiryId) {
        const sub = this.enquiryService.getFromId(enquiryId).subscribe((enquiry: IEnquiry) => {
          sub.unsubscribe();
          resolve(enquiry);
        });
      }
    });
  }

  updateFormValue(selectedEnquiry: IEnquiry = null): void {
    this.value = selectedEnquiry ? selectedEnquiry.id : null;

    this.updateSelectedEnquiry();

    let label: string = getDisplayedEnquiryRefTitle(selectedEnquiry, 'refEnquiry');

    if (selectedEnquiry.refContractTitle) {
      label += ' / ' + getDisplayedEnquiryRefTitle(selectedEnquiry, 'refContract');
    }

    this.setValueToFormControl.emit({
      fields: [
        {
          name: this.inputName,
          value: this.value,
          label
        }
      ]
    });

    this.optionsSuggested = [];
  }

  async updateAutocomplete(): Promise<void> {
    this.optionsSuggested = [];

    if (this.query && this.query.length > 2) {
      try {
        this.enquiries = await this.algoliaService.searchEnquiries(
          this.query,
          this.contractRefOnly
            ? ['refContractTitleDisplayed']
            : ['refEnquiryTitleDisplayed', 'refContractTitleDisplayed']
        );

        this.optionsSuggested = this.enquiries;

        if (this.optionsSuggested.length === 1) {
          this.updateFormValue(this.optionsSuggested[0]);
        }
      } catch (err) {
        console.error(err);
      }
    }
  }

  clearValue(): void {
    this.query = '';

    this.updateAutocomplete();
    this.updateFormValue(null);

    setTimeout(() => {
      if (this.searchInput) {
        this.searchInput.nativeElement.focus();
      }
    });
  }
}
