import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { EnumInvoiceType, getEnumInvoiceTypeLabel } from 'src/app/enums/invoice-type.enum';
import { IBankAccount } from 'src/app/interfaces/bank-account.interface';
import { IEnquiry, getDisplayedEnquiryRefTitle } from 'src/app/interfaces/enquiry.interface';
import { IInvoice, getInvoiceDaysLate } from 'src/app/interfaces/invoice.interface';
import { AclService } from 'src/app/services/acl.service';
import {
  AlgoliaService,
  IAlgoliaFacetValuesResponse,
  IAlgoliaFilter
} from 'src/app/services/algolia.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { PipedriveService } from 'src/app/services/pipedrive.service';
import { RemoteService } from 'src/app/services/remote.service';

interface ISearchableField {
  field: string;
  label: string;
  type: 'text' | 'boolean' | 'number' | 'select' | 'facetSearch' | 'client' | 'date' | 'enquiry';
  isQuery: boolean;
  options?: {
    title: string;
    value: string;
  }[];
  operator?: '=' | '!=' | '<' | '<=' | '>' | '>=';
  value: string | number | boolean;
  facetValue?: string;
  facetOptions?: IAlgoliaFacetValuesResponse[];
  hidden?: boolean;
}

@Component({
  selector: 'app-invoices-list',
  templateUrl: './invoices-list.component.html',
  styleUrls: ['./invoices-list.component.scss']
})
export class InvoicesListComponent implements OnInit, OnChanges {
  @Input('invoiceType') invoiceType: string | string[] = null;
  @Input('enquiryId') enquiryId: string = null;
  @Input('coloredTr') coloredTr: boolean = true;

  rootUrl: string = '../';

  getDisplayedEnquiryRefTitle = getDisplayedEnquiryRefTitle;
  getEnumInvoiceTypeLabel = getEnumInvoiceTypeLabel;
  getInvoiceDaysLate = getInvoiceDaysLate;

  faXmark = faXmark;

  EnumInvoiceType = EnumInvoiceType;
  EnumAcl = EnumAcl;

  paginationName: string = 'invoices-dashboard-all-list';

  enquiriesObj: { [key: string]: IEnquiry } = {};
  bankAccountsObj = {};

  filtersForAlgolia: object = {};

  searchedInvoices: IInvoice[] = [];

  isSearchListing: boolean = false;
  searchLoading: boolean = false;

  bankAccountsList: IBankAccount[] = [];

  invoicesDisplayed: IInvoice[] = [];

  operatorsList: string[] = ['=', '!=', '<', '<=', '>', '>='];

  searchableFields: ISearchableField[] = [];

  isLogged: boolean = false;

  constructor(
    private remoteService: RemoteService,
    public paginationService: PaginationService,
    private pipedriveService: PipedriveService,
    private algoliaService: AlgoliaService,
    private aclService: AclService
  ) {
    this.rootUrl = window['rootUrl'];

    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
  }

  ngOnInit(): void {
    this.setSearchableFields();

    this.setPaginationName();
    this.loadData();
  }

  ngOnChanges(): void {
    this.setSearchableFields();

    this.setPaginationName();

    this.loadData();
  }

  ngOnDestroy(): void {
    this.paginationService.reset(this.paginationName, true);
  }

  setSearchableFields(): void {
    this.searchableFields = [
      {
        field: 'ref',
        label: 'Numéro de facture / Libellé',
        type: 'text',
        isQuery: true,
        operator: '=',
        value: ''
      },
      {
        field: 'invoiceType',
        label: 'Type',
        type: 'select',
        options: [],
        isQuery: false,
        operator: '=',
        value: ''
      },
      {
        field: 'issueDate',
        label: 'Date de facture',
        type: 'date',
        isQuery: false,
        operator: '=',
        value: ''
      },
      {
        field: 'dueDate',
        label: "Date d'échéance",
        type: 'date',
        isQuery: false,
        operator: '=',
        value: ''
      },
      {
        field: 'enquiryId',
        label: 'Numéro de dossier',
        type: 'enquiry',
        isQuery: false,
        value: ''
      },
      this.invoiceType === 'purchase'
        ? {
            field: 'clientBillingInfos.clientName',
            label: 'Fournisseur',
            type: 'facetSearch',
            isQuery: false,
            operator: '=',
            value: '',
            facetValue: '',
            facetOptions: []
          }
        : {
            field: 'clientId',
            label: 'Client',
            type: 'client',
            isQuery: false,
            value: ''
          },
      {
        field: 'sentToClient',
        label: 'Envoyé',
        type: 'boolean',
        isQuery: false,
        operator: '=',
        value: ''
      },
      {
        field: 'amountHtTotal',
        label: 'Montant HT',
        type: 'number',
        isQuery: false,
        operator: '=',
        value: ''
      },
      {
        field: 'amountTvaTotal',
        label: 'Montant TVA',
        type: 'number',
        isQuery: false,
        operator: '=',
        value: ''
      },
      {
        field: 'amountTtcTotal',
        label: 'Montant TTC',
        type: 'number',
        isQuery: false,
        operator: '=',
        value: ''
      },
      {
        field: 'bankAccount',
        label: 'Compte bancaire',
        type: 'select',
        isQuery: false,
        options: [],
        operator: '=',
        value: ''
      }
    ];
  }

  setPaginationName(): void {
    const invoiceType: string = Array.isArray(this.invoiceType)
      ? this.invoiceType.join('-')
      : this.invoiceType;

    this.paginationName = invoiceType ? 'invoices-' + invoiceType + '-list' : 'invoices-all-list';
  }

  async loadData(): Promise<void> {
    if (this.isLogged) {
      this.paginationService.reset(this.paginationName, true);

      this.fetchInvoicesToProcess();

      this.loadBankAccounts();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  async fetchInvoicesToProcess(): Promise<void> {
    if (this.hasAclAccess(EnumAcl.invoicesList)) {
      if (this.paginationName && this.paginationService.pagination[this.paginationName]) {
        if (this.enquiryId) {
          this.paginationService.pagination[this.paginationName].conditions.push({
            field: 'enquiryId',
            operator: '==',
            value: this.enquiryId
          });
        }
      }

      if (this.paginationName) {
        this.invoicesDisplayed = [];

        await this.paginationService.fetchData(this.paginationName);

        this.refreshInvoicesDisplayed();

        await this.loadEnquiriesObj();
      }

      setTimeout(() => {
        this.updateFloatingScroll();
      }, 1000);
    }
  }

  async loadEnquiriesObj(): Promise<void> {
    const enquiriesIdToLoad: string[] = [];

    if (this.invoicesDisplayed) {
      for (const item of this.invoicesDisplayed) {
        if (item.enquiryId && !this.enquiriesObj[item.enquiryId]) {
          enquiriesIdToLoad.push(item.enquiryId);
        }
      }
    }

    const enquiriesIdToLoadUniqueOnly: string[] = [...new Set(enquiriesIdToLoad)];

    const enquiriesObj: object[] = await this.remoteService.getDocumentsFromDocId(
      'enquiries',
      enquiriesIdToLoadUniqueOnly
    );

    for (const enquiryObj of enquiriesObj) {
      const enquiry: IEnquiry = enquiryObj as IEnquiry;

      this.enquiriesObj[enquiry.id] = enquiry;
    }
  }

  async loadPrevPage(paginationName: string): Promise<void> {
    await this.paginationService.loadPreviousPage(paginationName);

    this.refreshInvoicesDisplayed();

    await this.loadEnquiriesObj();

    setTimeout(() => {
      this.updateFloatingScroll();
    }, 1000);
  }

  async loadNextPage(paginationName: string): Promise<void> {
    await this.paginationService.loadNextPage(paginationName);

    this.refreshInvoicesDisplayed();

    await this.loadEnquiriesObj();

    setTimeout(() => {
      this.updateFloatingScroll();
    }, 1000);
  }

  refreshInvoicesDisplayed(): void {
    if (this.isSearchListing) {
      this.invoicesDisplayed = this.searchedInvoices;
    } else {
      this.invoicesDisplayed = this.paginationService.pagination[this.paginationName]
        .data as IInvoice[];
    }
  }

  updateFloatingScroll(): void {
    window['$']('.table-responsive').floatingScroll('init', {
      orientation: 'horizontal'
    });
  }

  formatPrice(value: number, currency: string = 'EUR'): string {
    const formatter = new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2
    });

    return formatter.format(value);
  }

  toggleSearch(): void {
    this.isSearchListing = !this.isSearchListing;
  }

  updateInvoiceFilter(): void {
    this.searchLoading = true;

    const filters: IAlgoliaFilter[] = [];

    if (this.invoiceType) {
      if (this.invoiceType === 'cancelled') {
        filters.push({
          field: 'status',
          type: 'string',
          operator: '=',
          value: this.invoiceType
        });
      } else if (this.invoiceType === 'all-without-proforma') {
        filters.push({
          field: 'definitiveInvoice',
          type: 'boolean',
          operator: '=',
          value: true
        });
      } else {
        let hasInvoiceTypeFilterAlready: boolean = false;
        for (const field of this.searchableFields) {
          if (field.value !== '' && field.field === 'invoiceType') {
            filters.push({
              field: 'invoiceType',
              type: 'string',
              operator: '=',
              value: field.value
            });
            hasInvoiceTypeFilterAlready = true;
          }
        }

        if (!hasInvoiceTypeFilterAlready) {
          switch (this.invoiceType) {
            case EnumInvoiceType.proforma:
              filters.push({
                field: 'invoiceType',
                type: 'string',
                operator: 'in',
                value: [EnumInvoiceType.proforma, EnumInvoiceType.proformaCreditNote]
              });

              break;
            case EnumInvoiceType.purchase:
              filters.push({
                field: 'invoiceType',
                type: 'string',
                operator: 'in',
                value: [EnumInvoiceType.purchase, EnumInvoiceType.purchaseCreditNote]
              });

              break;
            default:
              filters.push({
                field: 'invoiceType',
                type: 'string',
                operator: '=',
                value: this.invoiceType
              });
              break;
          }
        }
      }
    }

    if (this.enquiryId) {
      filters.push({
        field: 'enquiryId',
        type: 'string',
        operator: '=',
        value: this.enquiryId
      });
    }

    let query: string = '';

    for (const field of this.searchableFields) {
      if (field.value !== '' && field.field !== 'invoiceType') {
        if (field.isQuery) {
          query = field.value.toString();
        } else if (field.value !== null) {
          switch (field.type) {
            case 'boolean':
              filters.push({
                field: field.field,
                type: field.type,
                operator: field.operator,
                value: field.value === 'true'
              });
              break;
            case 'number':
              filters.push({
                field: field.field,
                type: field.type,
                operator: field.operator,
                value: field.value
              });
              break;
            case 'date':
              filters.push({
                field: field.field,
                type: field.type,
                operator: field.operator,
                value: new Date(field.value.toString()).getTime()
              });
              break;
            default:
              filters.push({
                field: field.field,
                type: field.type,
                operator: field.operator,
                value: field.value
              });
              break;
          }
        }
      } else if (field.facetValue) {
        filters.push({
          field: field.field,
          type: field.type,
          operator: field.operator,
          value: field.facetValue
        });
      }
    }

    this.algoliaService
      .searchInvoices(query, ['ref'], filters)
      .then(async (invoices: IInvoice[]) => {
        this.isSearchListing = true;
        this.searchedInvoices = invoices;

        this.refreshInvoicesDisplayed();

        await this.loadEnquiriesObj();

        this.searchLoading = false;
      })
      .catch(err => {
        console.log(err);
        this.isSearchListing = true;

        this.searchLoading = false;

        this.refreshInvoicesDisplayed();
      });
  }

  async updateSearchableFieldOperator(
    field: string,
    operator: '=' | '!=' | '<' | '<=' | '>' | '>='
  ): Promise<void> {
    for (const searchableField of this.searchableFields) {
      if (searchableField.field === field) {
        searchableField.operator = operator;
        break;
      }
    }

    this.updateInvoiceFilter();
  }

  async setValueToFormControl($event: {
    fields: Array<{
      name: string;
      value: string;
    }>;
  }): Promise<void> {
    for (let field of $event.fields) {
      const nameList = field.name.split('.');

      for (const searchableField of this.searchableFields) {
        if (searchableField.field === field.name) {
          searchableField.value = field.value;

          this.updateInvoiceFilter();
          break;
        }
      }
    }
  }

  async loadBankAccounts(): Promise<void> {
    const docs = await this.remoteService.getAllDocuments('bankAccounts', {
      field: 'name',
      direction: 'asc'
    });

    this.bankAccountsList = [];
    for (const doc of docs) {
      const bankAccount: IBankAccount = doc as IBankAccount;

      this.bankAccountsList.push(bankAccount);

      this.bankAccountsObj[bankAccount.id] = bankAccount;
    }

    for (const searchableField of this.searchableFields) {
      switch (searchableField.field) {
        case 'bankAccount':
          searchableField.options = [];

          for (const bankAccount of this.bankAccountsList) {
            searchableField.options.push({
              title: bankAccount.name,
              value: bankAccount.id
            });
          }
          break;
        case 'invoiceType':
          searchableField.options = [];
          searchableField.hidden = false;

          if (
            [EnumInvoiceType.definitive, EnumInvoiceType.creditNote].includes(
              this.invoiceType as EnumInvoiceType
            )
          ) {
            searchableField.hidden = true;
          } else if (this.invoiceType === 'all-without-proforma') {
            for (const invoiceType of Object.values(EnumInvoiceType)) {
              if (
                ![EnumInvoiceType.proforma, EnumInvoiceType.proformaCreditNote].includes(
                  invoiceType
                )
              ) {
                searchableField.options.push({
                  title: getEnumInvoiceTypeLabel(invoiceType),
                  value: invoiceType
                });
              }
            }
          } else if (this.invoiceType === EnumInvoiceType.proforma) {
            for (const invoiceType of Object.values(EnumInvoiceType)) {
              if (
                [EnumInvoiceType.proforma, EnumInvoiceType.proformaCreditNote].includes(invoiceType)
              ) {
                searchableField.options.push({
                  title: getEnumInvoiceTypeLabel(invoiceType),
                  value: invoiceType
                });
              }
            }
          } else if (this.invoiceType === 'cancelled') {
            for (const invoiceType of Object.values(EnumInvoiceType)) {
              if (
                ![EnumInvoiceType.purchase, EnumInvoiceType.purchaseCreditNote].includes(
                  invoiceType
                )
              ) {
                searchableField.options.push({
                  title: getEnumInvoiceTypeLabel(invoiceType),
                  value: invoiceType
                });
              }
            }
          } else if (this.invoiceType === EnumInvoiceType.purchase) {
            for (const invoiceType of Object.values(EnumInvoiceType)) {
              if (
                [EnumInvoiceType.purchase, EnumInvoiceType.purchaseCreditNote].includes(invoiceType)
              ) {
                searchableField.options.push({
                  title: getEnumInvoiceTypeLabel(invoiceType),
                  value: invoiceType
                });
              }
            }
          } else {
            for (const invoiceType of Object.values(EnumInvoiceType)) {
              searchableField.options.push({
                title: getEnumInvoiceTypeLabel(invoiceType),
                value: invoiceType
              });
            }
          }
          break;
      }
    }
  }

  searchFacet(searchableField: ISearchableField): void {
    this.algoliaService
      .facetSearch('invoices', searchableField.field, searchableField.value as string)
      .then((data: IAlgoliaFacetValuesResponse[]) => {
        searchableField.facetOptions = data;
      })
      .catch(err => {
        console.log(err);
      });
  }

  assignFacetOption(searchableField: ISearchableField, option: IAlgoliaFacetValuesResponse): void {
    searchableField.facetOptions = [];
    searchableField.value = '';
    searchableField.facetValue = option.value;

    this.updateInvoiceFilter();
  }

  clearFacetValue(searchableField: ISearchableField): void {
    searchableField.value = '';
    searchableField.facetValue = '';

    this.updateInvoiceFilter();
  }
}
