import { EnumLanguage } from '../enums/language.enum';
import { IStoreData } from './store-data.interface';
import { getCountryLabel } from '../enums/continent-code.enum';

export interface IHandling extends IStoreData {
  airportId: string;
  name: string;
  phoneNumber: string | null;
  email: string | null;
  street: string | null;
  city: string | null;
  postalCode: string | null;
  countryCode: string;
}

export const getHandlingFullAddress = (
  handling: IHandling,
  language: EnumLanguage = EnumLanguage.en
): string => {
  let fullAddressArr: string[] = [];

  if (handling.street) {
    fullAddressArr.push(handling.street);
  }

  if (handling.city && handling.postalCode) {
    fullAddressArr.push(handling.city + ' ' + handling.postalCode);
  } else {
    if (handling.city) {
      fullAddressArr.push(handling.city);
    }

    if (handling.postalCode) {
      fullAddressArr.push(handling.postalCode);
    }
  }

  if (handling.countryCode) {
    fullAddressArr.push(getCountryLabel(handling.countryCode));
  }

  return fullAddressArr.join(', ');
};

export const getHandlingAddressDetails = (handling: IHandling, language: EnumLanguage): string => {
  const arr: string[] = [];

  arr.push('<strong><u>' + handling.name + '</u></strong>');

  if (handling.phoneNumber) {
    arr.push(
      '<strong>' +
        (language === EnumLanguage.fr ? 'Tél.' : 'Phone') +
        ' :</strong> ' +
        handling.phoneNumber
    );
  }

  if (getHandlingFullAddress(handling)) {
    arr.push(
      '<strong>' +
        (language === EnumLanguage.fr ? 'Adresse' : 'Address') +
        ' :</strong> ' +
        getHandlingFullAddress(handling)
    );
  }

  return arr.join('<br>');
};
