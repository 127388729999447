import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';
import { ICrawlerUrl } from 'src/app/interfaces/crawler-url.interface';
import { Subscription } from 'rxjs';
import { CrawlerUrlService } from 'src/app/services/crawler-url/crawler-url.service';
import { EnumCrawlerUrlStatus } from 'src/app/enums/crawler-url-status.enum';
import {
  EnumCrawlerUrlSource,
  getEnumCrawlerUrlSourceLabel
} from 'src/app/enums/crawler-url-source.enum';
import { EnumAcl } from 'src/app/enums/acl.enum';

@Component({
  selector: 'app-crawler-url-edit',
  templateUrl: './crawler-url-edit.component.html',
  styleUrls: ['./crawler-url-edit.component.scss']
})
export class CrawlerUrlEditComponent implements OnInit, OnDestroy {
  isLogged: boolean = false;
  form: FormGroup;
  sending: boolean = false;
  crawlerUrl: ICrawlerUrl;
  crawlerUrlId: string;

  getEnumCrawlerUrlSourceLabel = getEnumCrawlerUrlSourceLabel;

  private subscriptions = new Subscription();

  constructor(
    private formBuilder: FormBuilder,
    private remoteService: RemoteService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private aclService: AclService,
    private crawlerUrlService: CrawlerUrlService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      url: ['', [Validators.required]],
      delayValidInSeconds: [24 * 60 * 60 * 7, [Validators.required]],
      source: [, [Validators.required]]
    });

    this.form.disable();

    this.activatedRoute.url.subscribe(async () => {
      this.crawlerUrlId = this.activatedRoute.snapshot.paramMap.get('crawlerUrlId');

      if (this.crawlerUrlId) {
        await this.aclService.checkAclAccess(EnumAcl.bankAccountsEdit);
        this.loadData();
      } else {
        await this.aclService.checkAclAccess(EnumAcl.bankAccountsAdd);
        this.form.enable();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getSources(): EnumCrawlerUrlSource[] {
    return Object.values(EnumCrawlerUrlSource);
  }

  loadData(): void {
    if (this.isLogged) {
      this.loadCrawlerUrl();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  async loadCrawlerUrl(): Promise<void> {
    this.subscriptions.add(
      this.crawlerUrlService.getFromId(this.crawlerUrlId).subscribe((crawlerUrl: ICrawlerUrl) => {
        this.crawlerUrl = crawlerUrl;

        this.setICrawlerUrl();
      })
    );
  }

  setICrawlerUrl(): void {
    if (this.form && this.crawlerUrl) {
      this.form.get('url').setValue(this.crawlerUrl.url);
      this.form.get('delayValidInSeconds').setValue(this.crawlerUrl.delayValidInSeconds);
      this.form.get('source').setValue(this.crawlerUrl.source);

      this.form.enable();
    }
  }

  submitForm(): void {
    this.form.markAsTouched();

    if (this.form.valid) {
      let data = Object.assign(
        {
          status: EnumCrawlerUrlStatus.notStarted,
          dateStart: null,
          nextDateToUpdate: new Date()
        },
        this.form.value
      );

      for (const field in data) {
        if (typeof data[field] == 'undefined') {
          data[field] = null;
        }
      }

      this.sending = true;

      this.form.disable();

      if (this.crawlerUrlId) {
        this.remoteService
          .updateDocumentToCollection('crawlerUrls', this.crawlerUrlId, data)
          .then(() => {
            this.sending = false;
            this.redirectAfterSaving();
          })
          .catch(err => {
            this.sending = false;
            this.form.enable();

            alert(err.message);
          });
      } else {
        this.remoteService
          .addDocumentToCollection('crawlerUrls', data)
          .then((docId: string) => {
            this.crawlerUrlId = docId;

            this.sending = false;
            this.redirectAfterSaving();
          })
          .catch(err => {
            this.sending = false;
            this.form.enable();

            alert(err.message);
          });
      }
    }
  }

  redirectAfterSaving(): void {
    this.router.navigate(['/admin/crawler-urls']);
  }
}
