<form (submit)="submitForm()" [formGroup]="form" (keydown.enter)="submitForm()" class="main-form">
    <div class="row justify-content-center">
        <div class="col-md-6">
            <div class="card">
                <div class="card-body">
                    <div class="form-group">
                        <label for="name">Nom</label>
                        <input
                            class="form-control"
                            id="name"
                            formControlName="name"
                            type="text"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['name'].touched) &&
                                form.controls['name'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['name'].touched) &&
                                    form.controls['name'].errors &&
                                    form.controls['name'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="name">Intitulé du compte</label>
                        <input
                            class="form-control"
                            id="accountLabel"
                            formControlName="accountLabel"
                            type="text"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['accountLabel'].touched) &&
                                form.controls['accountLabel'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['accountLabel'].touched) &&
                                    form.controls['accountLabel'].errors &&
                                    form.controls['accountLabel'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="name">IBAN</label>
                        <input
                            class="form-control"
                            id="naibanme"
                            formControlName="iban"
                            type="text"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['iban'].touched) &&
                                form.controls['iban'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['iban'].touched) &&
                                    form.controls['iban'].errors &&
                                    form.controls['iban'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="name">BIC</label>
                        <input
                            class="form-control"
                            id="bic"
                            formControlName="bic"
                            type="text"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['bic'].touched) &&
                                form.controls['bic'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['bic'].touched) &&
                                    form.controls['bic'].errors &&
                                    form.controls['bic'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="defaultCurrency">Devise principale</label>
                        <select
                            class="form-select"
                            id="defaultCurrency"
                            formControlName="defaultCurrency"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['defaultCurrency'].touched) &&
                                form.controls['defaultCurrency'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        >
                            <option
                                [value]="currency"
                                *ngFor="let currency of getCurrencies()"
                                [innerHtml]="
                                    getEnumCurrencySymbol(currency) +
                                    ' (' +
                                    getEnumCurrencyLabel(currency) +
                                    ')'
                                "
                            ></option>
                        </select>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['defaultCurrency'].touched) &&
                                    form.controls['defaultCurrency'].errors &&
                                    form.controls['defaultCurrency'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-check mb-2">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="enableForInvoicing"
                            formControlName="enableForInvoicing"
                        />
                        <label class="form-check-label" for="enableForInvoicing"
                            >Activé pour la facturation ?</label
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-center text-center mt-4">
        <div class="col-md-4">
            <button
                type="button"
                [routerLink]="
                    bankAccountId ? '/admin/bank-accounts/' + bankAccountId : '/admin/bank-accounts'
                "
                class="btn btn-secondary btn-block mb-4"
                [disabled]="form.disabled || sending"
            >
                Annuler
            </button>
        </div>
        <div class="col-md-4">
            <button
                type="submit"
                class="btn btn-primary btn-block mb-4"
                [innerHtml]="sending ? 'Sauvegarde ...' : 'Sauvegarder'"
                [disabled]="form.disabled || !form.valid || sending"
            ></button>
        </div>
    </div>
</form>
