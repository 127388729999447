<div class="mb-4">
    <strong>Nature de la demande</strong>
    <div>{{ getEnumEnquiryTypeLabel(enquiry.type) }}</div>
</div>

<div class="mb-4" *ngIf="enquiry.comments">
    <strong>Commentaires</strong><br />
    <div class="line-return" [innerHtml]="enquiry.comments"></div>
</div>

<div class="mb-4" *ngIf="enquiry.noteForAirlines">
    <strong>Note à destination des compagnies aériennes</strong><br />
    <div class="line-return" [innerHtml]="enquiry.noteForAirlines"></div>
</div>

<div class="row">
    <div class="col-md col-lg-12 mb-4">
        <div class="accordion mb-4" id="itineraries" *ngIf="enquiry && enquiry.itineraries?.length">
            <div
                class="accordion-item"
                *ngFor="let itinerary of enquiry.itineraries; let i = index"
            >
                <div class="accordion-header" id="heading-{{ i }}">
                    <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        [attr.data-bs-target]="'#collapse-' + i"
                        [attr.aria-expanded]="i == 0 ? 'true' : 'false'"
                        [attr.aria-controls]="'collapse-' + i"
                        [innerText]="itinerary.title"
                    ></button>
                </div>

                <div
                    id="collapse-{{ i }}"
                    class="accordion-collapse collapse"
                    [class.show]="i == 0"
                    [attr.aria-labelledby]="'heading-' + i"
                    data-bs-parent="#itineraries"
                >
                    <div class="accordion-body">
                        <ul class="list-group text-center">
                            <ng-container *ngFor="let trip of itinerary.trips; let j = index">
                                <li class="list-group-item">
                                    <h5 class="mb-0" *ngIf="itinerary.trips.length <= 2 && j == 0">
                                        Aller
                                    </h5>
                                    <h5 class="mb-0" *ngIf="itinerary.trips.length <= 2 && j == 1">
                                        Retour
                                    </h5>
                                    <h5 class="mb-0" *ngIf="itinerary.trips.length > 2">
                                        Vol {{ j + 1 }}
                                    </h5>
                                </li>
                                <li class="list-group-item">
                                    <strong>Départ</strong><br />
                                    <div [innerText]="trip.date | date : 'dd/MM/yyyy'"></div>
                                    <br />
                                    <span>{{ airportsObj[trip.airportDepart]?.title }}</span
                                    ><br />
                                    <div *ngIf="enquiry.type === EnumEnquiryType.cargo">
                                        <div *ngIf="trip.weight">{{ trip.weight }} kg</div>
                                        <div *ngIf="trip.volume">
                                            {{ trip.volume }} m<sup>3</sup>
                                        </div>
                                        <div *ngIf="trip.natureFret">
                                            Nature du fret: {{ trip.natureFret }}
                                        </div>
                                        <div *ngIf="trip.dimensions">
                                            Dimensions: {{ trip.dimensions }}
                                        </div>
                                    </div>
                                    <div *ngIf="enquiry.type !== EnumEnquiryType.cargo">
                                        <span
                                            class="text-primary"
                                            [innerText]="trip.passengersTotal + ' PAX'"
                                        ></span>
                                        <div *ngIf="trip.passengersBusiness > 0">
                                            dont {{ trip.passengersBusiness }} business
                                        </div>
                                    </div>

                                    <div class="plane-icon">
                                        <fa-icon [icon]="faPlane"></fa-icon>
                                    </div>

                                    <div *ngIf="trip.hasFuelStopOver" class="mt-2">
                                        <div><strong>Arrêt carburant</strong></div>

                                        <div>
                                            {{ airportsObj[trip.fuelStopOverAirport]?.title }}
                                        </div>

                                        <div class="plane-icon">
                                            <fa-icon [icon]="faPlane"></fa-icon>
                                        </div>
                                    </div>
                                    <div *ngIf="trip.hasCommercialStopOver" class="mt-2">
                                        <div><strong>Escale commerciale</strong></div>
                                        <div>
                                            {{ airportsObj[trip.commercialStopOverAirport]?.title }}
                                        </div>

                                        <div>
                                            <span
                                                class="text-success"
                                                *ngIf="trip.passengersCommercialStopEmbark > 0"
                                                [innerText]="
                                                    '+' +
                                                    trip.passengersCommercialStopEmbark +
                                                    ' PAX'
                                                "
                                            ></span>
                                            <span
                                                class="text-danger"
                                                *ngIf="trip.passengersCommercialStopDisembark > 0"
                                                [innerText]="
                                                    '-' +
                                                    trip.passengersCommercialStopDisembark +
                                                    ' PAX'
                                                "
                                            ></span>
                                        </div>

                                        <div class="plane-icon">
                                            <fa-icon [icon]="faPlane"></fa-icon>
                                        </div>
                                    </div>

                                    <div><strong>Arrivé</strong></div>
                                    <div>{{ airportsObj[trip.airportDestination]?.title }}</div>
                                    <div *ngIf="enquiry.type !== EnumEnquiryType.cargo">
                                        <span
                                            class="text-primary"
                                            [innerText]="getArrivalPassengerTotal(trip) + ' PAX'"
                                        ></span>
                                    </div>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <dl class="col-md col-lg-12 mb-4">
        <dt>Client</dt>
        <dd>
            <a
                [routerLink]="'/admin/clients/' + enquiry.clientId"
                [innerHtml]="client ? client.name : enquiry.clientTitle"
            ></a>
        </dd>
        <dt>Contact</dt>
        <dd>
            <a
                [href]="'https://artheauaviation2.pipedrive.com/person/' + enquiry.contactId"
                target="_blank"
                [innerHtml]="contact ? contact.name : enquiry.contactTitle"
            ></a>
        </dd>
    </dl>
</div>

<div class="text-center" *ngIf="isLogged">
    <div class="mb-4">
        <a
            [href]="'https://artheauaviation2.pipedrive.com/deal/' + enquiry.pipedriveDealId"
            class="btn btn-primary"
            *ngIf="enquiry.pipedriveDealId"
            target="_blank"
            >Ouvrir l'offre dans Pipedrive</a
        >
        <button
            type="button"
            (click)="createDealInPipedrive()"
            class="btn btn-primary"
            [disabled]="creatingPipedriveDeal"
            [innerHtml]="
                creatingPipedriveDeal
                    ? 'Création en cours ...'
                    : 'Créer une offre dans Pipedrive avec cette demande'
            "
            *ngIf="!enquiry.pipedriveDealId"
        ></button>
    </div>
    <button
        type="button"
        (click)="delete()"
        class="btn btn-outline-danger"
        *ngIf="hasAclAccess(EnumAcl.enquiriesDelete)"
    >
        Supprimer cette demande
    </button>
</div>
