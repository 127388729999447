<div class="alert alert-warning text-center" *ngIf="!lines.length">
    Aucune compagnie aérienne n'a été contactée.<br />
    <br />
    <a
        [routerLink]="'/admin/enquiries/' + enquiry.id + '/select-airlines'"
        class="btn btn-primary mb-1"
        [class.disabled]="enquiry.status === EnumEnquiryStatus.confirmed"
        *ngIf="enquiry"
        >Sélection des compagnies</a
    >
</div>

<div class="table-responsive main-table" *ngIf="lines.length">
    <table class="table table-striped table-hover">
        <thead>
            <tr>
                <th></th>
                <th
                    scope="col"
                    class="sortable text-center"
                    *ngFor="let field of fields"
                    [class.is-sorted]="orderBy.field == field.code"
                    [class.asc]="orderBy.field == field.code && orderBy.direction == 'asc'"
                    [class.desc]="orderBy.field == field.code && orderBy.direction == 'desc'"
                    (click)="sortField(field.code)"
                    [innerHtml]="field.title"
                ></th>
            </tr>
        </thead>
        <tbody>
            <tr
                *ngFor="let line of lines"
                [class.table-success]="line.status === EnumEnquiryCotationStatus.confirmed"
            >
                <td class="text-start">
                    <div class="btn-group">
                        <a
                            class="btn btn-primary btn-sm"
                            [routerLink]="[
                                '/admin/quotations/add/' + enquiry.id,
                                line.offerId,
                                line.cotationId ? line.cotationId : ''
                            ]"
                            rel="tooltip"
                            data-bs-title="Créer un devis"
                            data-bs-placement="left"
                            *ngIf="isLogged && hasAclAccess(EnumAcl.quotationsList)"
                        >
                            <fa-icon [icon]="faFileContract"></fa-icon>
                        </a>

                        <a
                            class="btn btn-primary btn-sm"
                            [routerLink]="
                                '/admin/enquiries/' +
                                enquiry.id +
                                '/send-message-to-airlines/' +
                                line.airlineId +
                                '/blank-email'
                            "
                            *ngIf="
                                line.status === EnumEnquiryCotationStatus.answered &&
                                hasAclAccess(EnumAcl.enquirieSendMessageToAirlines)
                            "
                            rel="tooltip"
                            data-bs-title="Contacter la compagnie aérienne"
                            data-bs-placement="left"
                        >
                            <fa-icon [icon]="faEnvelope"></fa-icon>
                        </a>

                        <ng-container
                            *ngIf="
                                isLogged &&
                                ((enquiry.status !== EnumEnquiryStatus.confirmed &&
                                    hasAclAccess(EnumAcl.enquiriesAddEditCotation)) ||
                                    (enquiry.status === EnumEnquiryStatus.confirmed &&
                                        hasAclAccess(
                                            EnumAcl.enquiriesAddEditCotationForConfirmedEnquiry
                                        )))
                            "
                        >
                            <a
                                class="btn btn-primary btn-sm"
                                [routerLink]="
                                    '/admin/enquiries/' +
                                    enquiry.id +
                                    '/send-message-to-airlines/' +
                                    line.airlineId
                                "
                                *ngIf="
                                    line.status === EnumEnquiryCotationStatus.toContact &&
                                    enquiry.status !== EnumEnquiryStatus.confirmed &&
                                    hasAclAccess(EnumAcl.enquirieSendMessageToAirlines)
                                "
                                rel="tooltip"
                                data-bs-title="Contacter la compagnie aérienne"
                                data-bs-placement="left"
                            >
                                <fa-icon [icon]="faEnvelope"></fa-icon>
                            </a>

                            <a
                                class="btn btn-primary btn-sm"
                                [routerLink]="
                                    '/admin/enquiries/' +
                                    enquiry.id +
                                    '/relance-airline/' +
                                    line.airlineId
                                "
                                rel="tooltip"
                                [title]="line.followedUp ? 'Relancer à nouveau' : 'Relancer'"
                                data-bs-placement="left"
                                *ngIf="
                                    line.status === EnumEnquiryCotationStatus.waitingForAnswer &&
                                    enquiry.status !== EnumEnquiryStatus.confirmed &&
                                    hasAclAccess(EnumAcl.enquiriesRelanceAirline)
                                "
                            >
                                <fa-icon [icon]="faEnvelope"></fa-icon>
                            </a>

                            <a
                                class="btn btn-primary btn-sm"
                                [routerLink]="
                                    '/admin/enquiries/' +
                                    enquiry.id +
                                    '/cancel-email-airline/' +
                                    line.airlineId
                                "
                                rel="tooltip"
                                title="Envoyer un email d'annulation"
                                data-bs-placement="left"
                                *ngIf="
                                    line.status === EnumEnquiryCotationStatus.answered &&
                                    [
                                        EnumEnquiryStatus.confirmed,
                                        EnumEnquiryStatus.cancelled
                                    ].includes(enquiry.status) &&
                                    hasAclAccess(EnumAcl.enquiriesCancelledEmailToAirline)
                                "
                            >
                                <fa-icon [icon]="faEnvelope"></fa-icon>
                            </a>

                            <ng-container *ngIf="line.offerId && line.cotationId">
                                <button
                                    type="button"
                                    class="btn btn-primary btn-sm"
                                    (click)="editCotation(line.offerId, line.cotationId)"
                                    rel="tooltip"
                                    data-bs-title="Éditer la cotation"
                                    data-bs-placement="left"
                                >
                                    <fa-icon [icon]="faEdit"></fa-icon>
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-primary btn-sm"
                                    (click)="addCotationToOffer(line.offerId)"
                                    rel="tooltip"
                                    data-bs-title="Ajouter une cotation à cette compagnie et appareil (par exemple, pour un autre leg)"
                                    data-bs-placement="left"
                                >
                                    <fa-icon [icon]="faPlus"></fa-icon>
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-primary btn-sm"
                                    *ngIf="
                                        hasAclAccess(
                                            EnumAcl.enquiriesViewAttachedDocumentsToCotation
                                        )
                                    "
                                    rel="tooltip"
                                    data-bs-title="Documents attachés à la cotation"
                                    (click)="viewAttachedDocumentsOfCotation(line.cotationId)"
                                >
                                    <fa-icon [icon]="faPaperclip"></fa-icon>
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-danger btn-sm"
                                    (click)="deleteCotation(line.cotationId)"
                                    rel="tooltip"
                                    data-bs-title="Supprimer la cotation"
                                    data-bs-placement="left"
                                >
                                    <fa-icon [icon]="faTrash"></fa-icon>
                                </button>
                            </ng-container>
                            <ng-container *ngIf="line.offerId && !line.cotationId">
                                <button
                                    type="button"
                                    class="btn btn-primary btn-sm"
                                    (click)="addCotationToOffer(line.offerId)"
                                    rel="tooltip"
                                    data-bs-title="Éditer la cotation"
                                    data-bs-placement="left"
                                >
                                    <fa-icon [icon]="faEdit"></fa-icon>
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-danger btn-sm"
                                    (click)="deleteOffer(line.offerId)"
                                    rel="tooltip"
                                    data-bs-title="Supprimer cette compagnie et appareil"
                                    data-bs-placement="left"
                                >
                                    <fa-icon [icon]="faTrash"></fa-icon>
                                </button>
                            </ng-container>
                        </ng-container>
                    </div>
                </td>
                <td>
                    <span
                        class="badge"
                        [class.bg-primary]="
                            line.status === EnumEnquiryCotationStatus.waitingForAnswer
                        "
                        [class.bg-success]="
                            [
                                EnumEnquiryCotationStatus.answered,
                                EnumEnquiryCotationStatus.confirmed
                            ].includes(line.status)
                        "
                        [class.bg-danger]="line.status === EnumEnquiryCotationStatus.rejected"
                        [class.bg-warning]="line.status === EnumEnquiryCotationStatus.toContact"
                        *ngIf="
                            enquiry.status === EnumEnquiryStatus.confirmed ||
                            !hasAclAccess(EnumAcl.enquiriesSwitchCotationStatus)
                        "
                        [innerHtml]="statusLabelObj[line.status]?.title"
                    ></span>

                    <div
                        class="dropdown"
                        *ngIf="
                            enquiry.status !== EnumEnquiryStatus.confirmed &&
                            hasAclAccess(EnumAcl.enquiriesSwitchCotationStatus)
                        "
                    >
                        <button
                            type="button"
                            class="btn btn-sm dropdown-toggle"
                            [class.btn-primary]="
                                line.status === EnumEnquiryCotationStatus.waitingForAnswer
                            "
                            [class.btn-success]="line.status === EnumEnquiryCotationStatus.answered"
                            [class.btn-danger]="
                                [
                                    EnumEnquiryCotationStatus.rejected,
                                    EnumEnquiryCotationStatus.cancelled
                                ].includes(line.status)
                            "
                            [class.btn-warning]="
                                line.status === EnumEnquiryCotationStatus.toContact
                            "
                            [attr.id]="'dropdownMenuButton-line-status'"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            (click)="openDropdownCotationStatus()"
                            [innerHtml]="statusLabelObj[line.status]?.title"
                        ></button>
                        <div
                            class="dropdown-menu"
                            [attr.aria-labelledby]="'dropdownMenuButton-line-status'"
                        >
                            <button
                                type="button"
                                class="dropdown-item"
                                *ngFor="let status of statusLabelList"
                                [innerHtml]="'Changer le statut en \'' + status.title + '\''"
                                (click)="
                                    line.cotationId
                                        ? changeOneCotationStatus(line.cotationId, status.value)
                                        : changeOfferStatus(line.offerId, status.value)
                                "
                                [class.disabled]="line.status === status.value"
                            ></button>
                        </div>
                    </div>
                </td>
                <td [innerHtml]="line.airlineTitle"></td>
                <td [innerHtml]="line.airlineCountryTitle"></td>
                <td>
                    <span [innerHtml]="line.legsTitle"></span>

                    <div
                        *ngIf="
                            line.legsId &&
                            legsList[line.itineraryId] &&
                            line.legsId.length &&
                            line.legsId.length < legsList[line.itineraryId].length
                        "
                    >
                        <fa-icon
                            [icon]="faWarning"
                            rel="tooltip"
                            [title]="
                                'Tous les legs ne sont pas présent sur cette cotation (' +
                                line.legsId.length +
                                '/' +
                                legsList[line.itineraryId].length +
                                ')'
                            "
                        ></fa-icon>
                    </div>
                </td>
                <td>
                    {{ line.aircraftTitle.toUpperCase() }}

                    <div *ngIf="line.aircraftErrorMessages.length">
                        <fa-icon
                            [icon]="faWarning"
                            rel="tooltip"
                            [title]="line.aircraftErrorMessages.join('<hr>')"
                        ></fa-icon>
                    </div>
                </td>
                <td
                    class="text-center"
                    [innerHtml]="line.ageAverage ? line.ageAverage + ' ans' : 'Inconnu'"
                ></td>
                <td
                    class="text-center"
                    [innerHtml]="line.capacityAircraft"
                    *ngIf="enquiry.type !== EnumEnquiryType.cargo"
                ></td>
                <td
                    class="text-center"
                    [innerHtml]="line.weightAircraft + ' kg'"
                    *ngIf="enquiry.type === EnumEnquiryType.cargo"
                ></td>
                <td
                    class="text-center"
                    [innerHtml]="line.volumeAircraft + ' m<sup>3</sup>'"
                    *ngIf="enquiry.type === EnumEnquiryType.cargo"
                ></td>
                <td class="text-center" *ngIf="enquiry.type !== EnumEnquiryType.cargo">
                    <span [innerHtml]="line.nbPax"></span>

                    <div *ngIf="line.nbPax > line.capacityAircraft">
                        <fa-icon
                            [icon]="faWarning"
                            rel="tooltip"
                            data-bs-title="Il y a plus de passagers que la capacité de l'appareil"
                        ></fa-icon>
                    </div>
                </td>
                <td class="text-end">
                    <div>
                        {{
                            line.buyingPriceInCurrency !== null
                                ? formatPrice(line.buyingPriceInCurrency, line.currency)
                                : ""
                        }}
                    </div>
                    <div *ngIf="line.currency !== defaultCurrency">
                        {{
                            line.buyingPrice !== null
                                ? "= " + formatPrice(line.buyingPrice, defaultCurrency)
                                : ""
                        }}
                    </div>

                    <div *ngIf="line.isNationalFlight && line.buyingPrice">
                        <fa-icon
                            [icon]="faWarning"
                            rel="tooltip"
                            data-bs-title="Attention: les prix sont avec TVA car c'est un vol national"
                        ></fa-icon>
                    </div>
                </td>
                <td class="text-end" *ngIf="enquiry.type !== EnumEnquiryType.cargo">
                    <div>
                        {{
                            line.taxesTotalPaxInCurrency !== null
                                ? formatPrice(line.taxesTotalPaxInCurrency, line.currency)
                                : ""
                        }}
                    </div>
                    <div *ngIf="line.currency !== defaultCurrency">
                        {{
                            line.taxesTotalPax !== null
                                ? "= " + formatPrice(line.taxesTotalPax, defaultCurrency)
                                : ""
                        }}
                    </div>
                </td>
                <td class="text-end" *ngIf="enquiry.type !== EnumEnquiryType.cargo">
                    <div>
                        {{
                            line.cateringInCurrency !== null
                                ? formatPrice(line.cateringInCurrency, line.currency)
                                : ""
                        }}
                    </div>
                    <div *ngIf="line.currency !== defaultCurrency">
                        {{
                            line.catering !== null
                                ? "= " + formatPrice(line.catering, defaultCurrency)
                                : ""
                        }}
                    </div>
                </td>
                <td class="text-end">
                    <div>
                        {{
                            line.priceNetTTCInCurrency !== null
                                ? formatPrice(line.priceNetTTCInCurrency, line.currency)
                                : ""
                        }}
                    </div>
                    <div *ngIf="line.currency !== defaultCurrency">
                        {{
                            line.priceNetTTC !== null
                                ? "= " + formatPrice(line.priceNetTTC, defaultCurrency)
                                : ""
                        }}
                    </div>

                    <div *ngIf="line.isNationalFlight && line.priceNetTTC">
                        <fa-icon
                            [icon]="faWarning"
                            rel="tooltip"
                            data-bs-title="Attention: les prix sont avec TVA car c'est un vol national"
                        ></fa-icon>
                    </div>
                </td>
                <td class="text-end" *ngIf="enquiry.type !== EnumEnquiryType.cargo">
                    <div>
                        {{
                            line.pricePPInCurrency !== null
                                ? formatPrice(line.pricePPInCurrency, line.currency)
                                : ""
                        }}
                    </div>
                    <div *ngIf="line.currency !== defaultCurrency">
                        {{
                            line.pricePP !== null
                                ? "= " + formatPrice(line.pricePP, defaultCurrency)
                                : ""
                        }}
                    </div>
                </td>
                <td class="text-end">
                    <div>
                        {{
                            line.sellingPriceInCurrency !== null
                                ? formatPrice(line.sellingPriceInCurrency, line.currency)
                                : ""
                        }}
                    </div>
                    <div *ngIf="line.currency !== defaultCurrency">
                        {{
                            line.sellingPrice !== null
                                ? "= " + formatPrice(line.sellingPrice, defaultCurrency)
                                : ""
                        }}
                    </div>

                    <div *ngIf="line.isNationalFlight && line.sellingPrice">
                        <fa-icon
                            [icon]="faWarning"
                            rel="tooltip"
                            data-bs-title="Attention: les prix sont avec TVA car c'est un vol national"
                        ></fa-icon>
                    </div>
                </td>
                <td class="text-end">
                    <div>
                        {{
                            line.marginInCurrency !== null
                                ? formatPrice(line.marginInCurrency, line.currency)
                                : ""
                        }}
                    </div>
                    <div *ngIf="line.currency !== defaultCurrency">
                        {{
                            line.margin !== null
                                ? "= " + formatPrice(line.margin, defaultCurrency)
                                : ""
                        }}
                    </div>

                    <em
                        *ngIf="line.marginOnConfirmed && line.marginOnConfirmed !== line.margin"
                        [innerText]="
                            'Marge à la confirmation : ' +
                            formatPrice(line.marginOnConfirmed, defaultCurrency)
                        "
                    ></em>
                </td>
                <td class="text-end">
                    <div>
                        {{
                            line.marginPercentInCurrency !== null
                                ? line.marginPercentInCurrency
                                : ""
                        }}
                    </div>
                    <div *ngIf="line.currency !== defaultCurrency">
                        {{ line.marginPercent !== null ? "= " + line.marginPercent + "%" : "" }}
                    </div>
                </td>
                <td class="text-end" *ngIf="enquiry.type !== EnumEnquiryType.cargo">
                    <div>
                        {{
                            line.sellingPricePPInCurrency !== null
                                ? formatPrice(line.sellingPricePPInCurrency, line.currency)
                                : ""
                        }}
                    </div>
                    <div *ngIf="line.currency !== defaultCurrency">
                        {{
                            line.sellingPricePP !== null
                                ? "= " + formatPrice(line.sellingPricePP, defaultCurrency)
                                : ""
                        }}
                    </div>
                </td>
                <td [innerHtml]="line.notes"></td>
            </tr>
        </tbody>
    </table>
</div>

<div class="modal" tabindex="-1" role="dialog" #modalCotationEdit>
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <form
                [formGroup]="form"
                class="main-form"
                (keydown.enter)="saveCotation()"
                *ngIf="form"
            >
                <div class="modal-header">
                    <h5 class="modal-title">Édition de la cotation</h5>
                    <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <div class="modal-body">
                    <div class="row justify-content-center">
                        <div class="col-sm-6 col-lg-3">
                            <div class="form-group">
                                <label>Compagnie</label>
                                <div class="form-control fake-input disabled">
                                    <a
                                        [routerLink]="
                                            '/admin/airlines/' +
                                            currentOffer.aircraftCompiled.airlineId
                                        "
                                        target="_blank"
                                        class="fake-input-text"
                                        [innerHtml]="
                                            currentOffer && currentOffer.aircraftCompiled
                                                ? currentOffer.aircraftCompiled.airlineTitle
                                                : ''
                                        "
                                    ></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-6 col-lg-3">
                            <div class="form-group">
                                <label>Nationalité</label>
                                <div class="form-control fake-input disabled">
                                    <span
                                        class="fake-input-text"
                                        [innerHtml]="
                                            currentOffer && currentOffer.aircraftCompiled
                                                ? getCountryLabel(
                                                      currentOffer.aircraftCompiled
                                                          .airlineCountryCode
                                                  )
                                                : ''
                                        "
                                    ></span>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-lg-6">
                            <div class="form-group">
                                <label>Appareil</label>

                                <select
                                    class="form-select"
                                    id="aircraftCompiledId"
                                    formControlName="aircraftCompiledId"
                                    [class.is-invalid]="
                                        (form.touched || form.get('aircraftCompiledId').touched) &&
                                        form.get('aircraftCompiledId').status == 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                >
                                    <option
                                        [value]="aircraftCompiled.id"
                                        *ngFor="
                                            let aircraftCompiled of airlinesAircrafts[
                                                currentOffer.aircraftCompiled.airlineId
                                            ]
                                        "
                                        [innerHtml]="
                                            aircraftCompiled.isCargo
                                                ? aircraftCompiled.modelTitle.toUpperCase() +
                                                  ' / ' +
                                                  aircraftCompiled.weight +
                                                  ' kg' +
                                                  ' / ' +
                                                  aircraftCompiled.volume +
                                                  ' m3'
                                                : aircraftCompiled.modelTitle.toUpperCase() +
                                                  ' / ' +
                                                  aircraftCompiled.seatTotal +
                                                  ' PAX'
                                        "
                                    ></option>
                                </select>

                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched ||
                                                form.get('aircraftCompiledId').touched) &&
                                            form.get('aircraftCompiledId').errors &&
                                            form.get('aircraftCompiledId').errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr />

                    <div class="row justify-content-center">
                        <div class="col-sm-6">
                            <div
                                class="form-group"
                                *ngIf="enquiry && enquiry.itineraries?.length > 1"
                            >
                                <label for="itineraryId">Leg</label>
                                <select
                                    class="form-select"
                                    id="itineraryId"
                                    formControlName="itineraryId"
                                    [class.is-invalid]="
                                        (form.touched || form.get('itineraryId').touched) &&
                                        form.get('itineraryId').status == 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                    (change)="updateItineraryIdOfCotation()"
                                >
                                    <option value=""></option>
                                    <option
                                        [value]="itinerary.value"
                                        *ngFor="let itinerary of itinerariesList"
                                        [innerHtml]="itinerary.title"
                                    ></option>
                                </select>

                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched || form.get('itineraryId').touched) &&
                                            form.get('itineraryId').errors &&
                                            form.get('itineraryId').errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                            </div>

                            <div
                                class="form-group"
                                *ngIf="
                                    form.get('itineraryId').value &&
                                    legsList[form.get('itineraryId').value].length
                                "
                            >
                                <label *ngIf="enquiry && enquiry.itineraries?.length === 1"
                                    >Leg</label
                                >

                                <div
                                    class="form-check"
                                    *ngFor="let leg of legsList[form.get('itineraryId').value]"
                                >
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        [attr.id]="leg.value"
                                        [checked]="form.get('legsId').value.includes(leg.value)"
                                        (click)="updateLegsId(leg.value)"
                                    />
                                    <label
                                        class="form-check-label"
                                        [attr.for]="leg.value"
                                        [innerHtml]="leg.title"
                                    ></label>
                                </div>

                                <div
                                    class="text-danger"
                                    *ngIf="
                                        (form.touched || form.get('legsId').touched) &&
                                        form.get('legsId').errors &&
                                        form.get('legsId').errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-6" *ngIf="enquiry.type !== EnumEnquiryType.cargo">
                            <div class="form-group">
                                <label for="nbPax">Nb pax</label>
                                <input
                                    class="form-control"
                                    id="nbPax"
                                    formControlName="nbPax"
                                    min="0"
                                    type="number"
                                    required
                                    [class.is-invalid]="
                                        (form.touched || form.get('nbPax').touched) &&
                                        form.get('nbPax').status == 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                />

                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched || form.get('nbPax').touched) &&
                                            form.get('nbPax').errors &&
                                            form.get('nbPax').errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="currency">Devise</label>
                                <select
                                    class="form-select"
                                    id="currency"
                                    formControlName="currency"
                                    required
                                    [class.is-invalid]="
                                        (form.touched || form.controls['currency'].touched) &&
                                        form.controls['currency'].status == 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                    (change)="updatedCurrency()"
                                >
                                    <option
                                        [value]="currency"
                                        *ngFor="let currency of getCurrencies()"
                                        [innerHtml]="
                                            getEnumCurrencySymbol(currency) +
                                            ' (' +
                                            getEnumCurrencyLabel(currency) +
                                            ')'
                                        "
                                    ></option>
                                </select>
                                <div
                                    class="text-muted"
                                    *ngIf="
                                        form.value.currency &&
                                        form.value.currency !== getDefaultCurrency()
                                    "
                                >
                                    Aujourd'hui
                                    {{ todayCurrencyExchangeRate.date | date : "HH:mm" }}
                                    :<br />
                                    1 {{ getDefaultCurrency() }} =
                                    {{ todayCurrencyExchangeRate.currencies[form.value.currency] }}
                                    {{ form.value.currency }}
                                </div>

                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched || form.controls['currency'].touched) &&
                                            form.controls['currency'].errors &&
                                            form.controls['currency'].errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-6">
                            <fieldset
                                [class.has-different-currency]="
                                    form.value.currency !== defaultCurrency
                                "
                            >
                                <legend>Prix d'achat</legend>
                                <div
                                    class="form-group"
                                    *ngFor="let fieldType of ['InCurrency', '']"
                                    [class.d-none]="
                                        fieldType === '' && form.value.currency === defaultCurrency
                                    "
                                >
                                    <div class="input-group">
                                        <input
                                            class="form-control"
                                            [id]="'buyingPrice' + fieldType"
                                            [formControlName]="'buyingPrice' + fieldType"
                                            type="number"
                                            required
                                            [class.is-invalid]="
                                                (form.touched ||
                                                    form.get('buyingPrice' + fieldType).touched) &&
                                                form.get('buyingPrice' + fieldType).status ==
                                                    'INVALID'
                                                    ? 'danger'
                                                    : ''
                                            "
                                            (keyup)="
                                                updatedValue(
                                                    'buyingPrice',
                                                    fieldType === 'InCurrency'
                                                )
                                            "
                                            [placeholder]="
                                                'Montant en ' +
                                                getEnumCurrencySymbol(defaultCurrency)
                                            "
                                        />
                                        <div class="invalid-tooltip">
                                            <div
                                                *ngIf="
                                                    (form.touched ||
                                                        form.get('buyingPrice' + fieldType)
                                                            .touched) &&
                                                    form.get('buyingPrice' + fieldType).errors &&
                                                    form.get('buyingPrice' + fieldType).errors[
                                                        'required'
                                                    ]
                                                "
                                            >
                                                Ce champ est obligatoire.
                                            </div>
                                        </div>

                                        <div
                                            class="input-group-text"
                                            [innerHtml]="
                                                getEnumCurrencySymbol(
                                                    fieldType === 'InCurrency'
                                                        ? form.value.currency
                                                        : defaultCurrency
                                                )
                                            "
                                        ></div>
                                    </div>
                                </div>
                            </fieldset>
                        </div>

                        <div class="col-sm-6" *ngIf="enquiry.type !== EnumEnquiryType.cargo">
                            <div class="form-group">
                                <label for="cateringByPersonIncluded"
                                    >Catering par passager inclus dans prix d'achat ?</label
                                >
                                <select
                                    class="form-select"
                                    id="cateringByPersonIncluded"
                                    formControlName="cateringByPersonIncluded"
                                    required
                                    [class.is-invalid]="
                                        (form.touched ||
                                            form.get('cateringByPersonIncluded').touched) &&
                                        form.get('cateringByPersonIncluded').status == 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                    (change)="updateByPersonIncludedField('cateringByPerson')"
                                >
                                    <option [ngValue]="true">Oui</option>
                                    <option [ngValue]="false">Non</option>
                                </select>
                            </div>

                            <fieldset
                                *ngIf="!form.get('cateringByPersonIncluded').value"
                                [class.has-different-currency]="
                                    form.value.currency !== defaultCurrency
                                "
                            >
                                <legend>Catering par passager</legend>

                                <div
                                    class="form-group"
                                    *ngFor="let fieldType of ['InCurrency', '']"
                                    [class.d-none]="
                                        fieldType === '' && form.value.currency === defaultCurrency
                                    "
                                >
                                    <div class="input-group">
                                        <input
                                            class="form-control"
                                            [id]="'cateringByPerson' + fieldType"
                                            [formControlName]="'cateringByPerson' + fieldType"
                                            type="number"
                                            [class.is-invalid]="
                                                (form.touched ||
                                                    form.get('cateringByPerson' + fieldType)
                                                        .touched) &&
                                                form.get('cateringByPerson' + fieldType).status ==
                                                    'INVALID'
                                                    ? 'danger'
                                                    : ''
                                            "
                                            (keyup)="
                                                updatedValue(
                                                    'cateringByPerson',
                                                    fieldType === 'InCurrency'
                                                )
                                            "
                                            [placeholder]="
                                                'Montant en ' +
                                                getEnumCurrencySymbol(defaultCurrency)
                                            "
                                        />

                                        <div class="invalid-tooltip">
                                            <div
                                                *ngIf="
                                                    (form.touched ||
                                                        form.get('cateringByPerson' + fieldType)
                                                            .touched) &&
                                                    form.get('cateringByPerson' + fieldType)
                                                        .errors &&
                                                    form.get('cateringByPerson' + fieldType).errors[
                                                        'isZeroOrBelow'
                                                    ]
                                                "
                                            >
                                                Ce champ ne peut être inférieur à zéro.
                                            </div>
                                        </div>

                                        <div
                                            class="input-group-text"
                                            [innerHtml]="
                                                getEnumCurrencySymbol(
                                                    fieldType === 'InCurrency'
                                                        ? form.value.currency
                                                        : defaultCurrency
                                                )
                                            "
                                        ></div>
                                    </div>
                                </div>
                            </fieldset>
                        </div>

                        <div class="col-sm-6" *ngIf="enquiry.type !== EnumEnquiryType.cargo">
                            <div class="form-group">
                                <label for="taxesByPersonIncluded"
                                    >Taxes par passager inclus dans prix d'achat ?</label
                                >
                                <select
                                    class="form-select"
                                    id="taxesByPersonIncluded"
                                    formControlName="taxesByPersonIncluded"
                                    required
                                    [class.is-invalid]="
                                        (form.touched ||
                                            form.get('taxesByPersonIncluded').touched) &&
                                        form.get('taxesByPersonIncluded').status == 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                    (change)="updateByPersonIncludedField('taxesByPerson')"
                                >
                                    <option [ngValue]="true">Oui</option>
                                    <option [ngValue]="false">Non</option>
                                </select>
                            </div>

                            <ng-container
                                *ngIf="
                                    !form.value.taxesByPersonIncluded && form.value.legsId.length
                                "
                            >
                                <ul class="nav nav-pills" id="myTab" role="tablist">
                                    <li class="nav-item">
                                        <a
                                            class="nav-link"
                                            [class.active]="
                                                form.value.isTaxesByPersonType === 'leg'
                                            "
                                            id="taxesByPersonByLeg-tab"
                                            data-bs-toggle="tab"
                                            href="#taxesByPersonByLeg"
                                            role="tab"
                                            aria-controls="taxesByPersonByLeg"
                                            (click)="updateTaxesByPersonType('leg')"
                                            >Taxes par passager par leg</a
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            class="nav-link"
                                            [class.active]="
                                                form.value.isTaxesByPersonType === 'airport'
                                            "
                                            id="taxesByPersonByAirport-tab"
                                            data-bs-toggle="tab"
                                            href="#taxesByPersonByAirport"
                                            role="tab"
                                            aria-controls="taxesByPersonByAirport"
                                            (click)="updateTaxesByPersonType('airport')"
                                            >Taxes par passager par aéroport</a
                                        >
                                    </li>
                                </ul>

                                <!-- Tab panes -->
                                <div class="tab-content">
                                    <div
                                        class="tab-pane active"
                                        [class.active]="form.value.isTaxesByPersonType === 'leg'"
                                        id="taxesByPersonByLeg"
                                        role="tabpanel"
                                        aria-labelledby="taxesByPersonByLeg-tab"
                                    >
                                        <ul class="list-group" formArrayName="taxesByPersonByLeg">
                                            <li
                                                class="list-group-item"
                                                [formGroupName]="i"
                                                *ngFor="
                                                    let taxByPersonByLeg of form.get(
                                                        'taxesByPersonByLeg'
                                                    )['controls'];
                                                    let i = index
                                                "
                                            >
                                                <div
                                                    class="form-group"
                                                    *ngFor="let fieldType of ['InCurrency', '']"
                                                    [class.d-none]="
                                                        fieldType === '' &&
                                                        form.value.currency === defaultCurrency
                                                    "
                                                >
                                                    <label
                                                        [for]="'taxesByPersonByLeg' + i + fieldType"
                                                        *ngIf="fieldType === 'InCurrency'"
                                                        [innerHtml]="
                                                            legsObj[
                                                                form.value.taxesByPersonByLeg[i]
                                                                    .legId
                                                            ].title
                                                        "
                                                    ></label>
                                                    <div class="input-group">
                                                        <input
                                                            class="form-control"
                                                            [id]="
                                                                'taxesByPersonByLeg' +
                                                                i +
                                                                'amount' +
                                                                fieldType
                                                            "
                                                            [formControlName]="'amount' + fieldType"
                                                            type="number"
                                                            [class.is-invalid]="
                                                                (form.touched ||
                                                                    form
                                                                        .get('taxesByPersonByLeg')
                                                                        .get(i.toString())
                                                                        .get('amount' + fieldType)
                                                                        .touched) &&
                                                                form
                                                                    .get('taxesByPersonByLeg')
                                                                    .get(i.toString())
                                                                    .get('amount' + fieldType)
                                                                    .status == 'INVALID'
                                                                    ? 'danger'
                                                                    : ''
                                                            "
                                                            (keyup)="
                                                                updatedTaxesByPersonByLeg(
                                                                    i,
                                                                    fieldType === 'InCurrency'
                                                                )
                                                            "
                                                            [placeholder]="
                                                                fieldType === ''
                                                                    ? 'Montant en ' +
                                                                      getEnumCurrencySymbol(
                                                                          defaultCurrency
                                                                      )
                                                                    : ''
                                                            "
                                                        />

                                                        <div class="invalid-tooltip">
                                                            <div
                                                                *ngIf="
                                                                    (form.touched ||
                                                                        form
                                                                            .get(
                                                                                'taxesByPersonByLeg'
                                                                            )
                                                                            .get(i.toString())
                                                                            .get(
                                                                                'amount' + fieldType
                                                                            ).touched) &&
                                                                    form
                                                                        .get('taxesByPersonByLeg')
                                                                        .get(i.toString())
                                                                        .get('amount' + fieldType)
                                                                        .errors &&
                                                                    form
                                                                        .get('taxesByPersonByLeg')
                                                                        .get(i.toString())
                                                                        .get('amount' + fieldType)
                                                                        .errors['required']
                                                                "
                                                            >
                                                                Ce champ est obligatoire.
                                                            </div>
                                                        </div>

                                                        <div
                                                            class="input-group-text"
                                                            [innerHtml]="
                                                                getEnumCurrencySymbol(
                                                                    fieldType === 'InCurrency'
                                                                        ? form.value.currency
                                                                        : defaultCurrency
                                                                )
                                                            "
                                                        ></div>
                                                    </div>
                                                </div>

                                                <div class="form-group">
                                                    <label for="nbPax">Nb pax</label>
                                                    <input
                                                        class="form-control"
                                                        id="nbPax"
                                                        formControlName="nbPax"
                                                        min="0"
                                                        type="number"
                                                        required
                                                        (keyup)="updatedTaxesByPersonByLeg(i)"
                                                        [class.is-invalid]="
                                                            (form.touched ||
                                                                form
                                                                    .get('taxesByPersonByLeg')
                                                                    .get(i.toString())
                                                                    .get('nbPax').touched) &&
                                                            form
                                                                .get('taxesByPersonByLeg')
                                                                .get(i.toString())
                                                                .get('nbPax').status == 'INVALID'
                                                                ? 'danger'
                                                                : ''
                                                        "
                                                    />

                                                    <div class="invalid-tooltip">
                                                        <div
                                                            *ngIf="
                                                                (form.touched ||
                                                                    form
                                                                        .get('taxesByPersonByLeg')
                                                                        .get(i.toString())
                                                                        .get('nbPax').touched) &&
                                                                form
                                                                    .get('taxesByPersonByLeg')
                                                                    .get(i.toString())
                                                                    .get('nbPax').errors &&
                                                                form
                                                                    .get('taxesByPersonByLeg')
                                                                    .get(i.toString())
                                                                    .get('nbPax').errors['required']
                                                            "
                                                        >
                                                            Ce champ est obligatoire.
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div
                                        class="tab-pane"
                                        [class.active]="
                                            form.value.isTaxesByPersonType === 'airport'
                                        "
                                        id="taxesByPersonByAirport"
                                        role="tabpanel"
                                        aria-labelledby="taxesByPersonByAirport-tab"
                                    >
                                        <ul
                                            class="list-group"
                                            formArrayName="taxesByPersonByAirport"
                                        >
                                            <li
                                                class="list-group-item"
                                                [formGroupName]="i"
                                                *ngFor="
                                                    let taxByPersonByLeg of form.get(
                                                        'taxesByPersonByAirport'
                                                    )['controls'];
                                                    let i = index
                                                "
                                            >
                                                <div
                                                    class="form-group"
                                                    *ngFor="let fieldType of ['InCurrency', '']"
                                                    [class.d-none]="
                                                        fieldType === '' &&
                                                        form.value.currency === defaultCurrency
                                                    "
                                                >
                                                    <label
                                                        [for]="
                                                            'taxesByPersonByAirport' + i + fieldType
                                                        "
                                                        *ngIf="fieldType === 'InCurrency'"
                                                        [innerHtml]="
                                                            form.value.taxesByPersonByAirport[i]
                                                                .airport
                                                        "
                                                    ></label>
                                                    <div class="input-group">
                                                        <input
                                                            class="form-control"
                                                            [id]="
                                                                'taxesByPersonByAirport' +
                                                                i +
                                                                'amount' +
                                                                fieldType
                                                            "
                                                            [formControlName]="'amount' + fieldType"
                                                            type="number"
                                                            [class.is-invalid]="
                                                                (form.touched ||
                                                                    form
                                                                        .get(
                                                                            'taxesByPersonByAirport'
                                                                        )
                                                                        .get(i.toString())
                                                                        .get('amount' + fieldType)
                                                                        .touched) &&
                                                                form
                                                                    .get('taxesByPersonByAirport')
                                                                    .get(i.toString())
                                                                    .get('amount' + fieldType)
                                                                    .status == 'INVALID'
                                                                    ? 'danger'
                                                                    : ''
                                                            "
                                                            (keyup)="
                                                                updatedTaxesByPersonByAirport(
                                                                    i,
                                                                    fieldType === 'InCurrency'
                                                                )
                                                            "
                                                            [placeholder]="
                                                                fieldType === ''
                                                                    ? 'Montant en ' +
                                                                      getEnumCurrencySymbol(
                                                                          defaultCurrency
                                                                      )
                                                                    : ''
                                                            "
                                                        />

                                                        <div class="invalid-tooltip">
                                                            <div
                                                                *ngIf="
                                                                    (form.touched ||
                                                                        form
                                                                            .get(
                                                                                'taxesByPersonByAirport'
                                                                            )
                                                                            .get(i.toString())
                                                                            .get(
                                                                                'amount' + fieldType
                                                                            ).touched) &&
                                                                    form
                                                                        .get(
                                                                            'taxesByPersonByAirport'
                                                                        )
                                                                        .get(i.toString())
                                                                        .get('amount' + fieldType)
                                                                        .errors &&
                                                                    form
                                                                        .get(
                                                                            'taxesByPersonByAirport'
                                                                        )
                                                                        .get(i.toString())
                                                                        .get('amount' + fieldType)
                                                                        .errors['required']
                                                                "
                                                            >
                                                                Ce champ est obligatoire.
                                                            </div>
                                                        </div>

                                                        =
                                                        <div
                                                            class="input-group-text"
                                                            [innerHtml]="
                                                                getEnumCurrencySymbol(
                                                                    fieldType === 'InCurrency'
                                                                        ? form.value.currency
                                                                        : defaultCurrency
                                                                )
                                                            "
                                                        ></div>
                                                        =
                                                    </div>
                                                </div>

                                                <div class="form-group">
                                                    <label for="nbPax">Nb pax</label>
                                                    <input
                                                        class="form-control"
                                                        id="nbPax"
                                                        formControlName="nbPax"
                                                        min="0"
                                                        type="number"
                                                        required
                                                        (keyup)="updatedTaxesByPersonByAirport(i)"
                                                        [class.is-invalid]="
                                                            (form.touched ||
                                                                form
                                                                    .get('taxesByPersonByAirport')
                                                                    .get(i.toString())
                                                                    .get('nbPax').touched) &&
                                                            form
                                                                .get('taxesByPersonByAirport')
                                                                .get(i.toString())
                                                                .get('nbPax').status == 'INVALID'
                                                                ? 'danger'
                                                                : ''
                                                        "
                                                    />

                                                    <div class="invalid-tooltip">
                                                        <div
                                                            *ngIf="
                                                                (form.touched ||
                                                                    form
                                                                        .get(
                                                                            'taxesByPersonByAirport'
                                                                        )
                                                                        .get(i.toString())
                                                                        .get('nbPax').touched) &&
                                                                form
                                                                    .get('taxesByPersonByAirport')
                                                                    .get(i.toString())
                                                                    .get('nbPax').errors &&
                                                                form
                                                                    .get('taxesByPersonByAirport')
                                                                    .get(i.toString())
                                                                    .get('nbPax').errors['required']
                                                            "
                                                        >
                                                            Ce champ est obligatoire.
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </ng-container>

                            <div
                                class="form-group"
                                *ngFor="let fieldType of ['InCurrency', '']"
                                [class.d-none]="
                                    fieldType === '' && form.value.currency === defaultCurrency
                                "
                            >
                                <label
                                    [for]="'taxesTotalPax' + fieldType"
                                    *ngIf="fieldType === 'InCurrency'"
                                    >Taxes total passagers</label
                                >
                                <div class="input-group">
                                    <input
                                        class="form-control"
                                        [id]="'taxesTotalPax' + fieldType"
                                        [formControlName]="'taxesTotalPax' + fieldType"
                                        type="number"
                                        [class.is-invalid]="
                                            (form.touched ||
                                                form.get('taxesTotalPax' + fieldType).touched) &&
                                            form.get('taxesTotalPax' + fieldType).status ==
                                                'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                        (keyup)="
                                            updatedValue(
                                                'taxesTotalPax',
                                                fieldType === 'InCurrency'
                                            )
                                        "
                                        [placeholder]="
                                            'Montant en ' + getEnumCurrencySymbol(defaultCurrency)
                                        "
                                        readonly
                                    />

                                    <div class="invalid-tooltip">
                                        <div
                                            *ngIf="
                                                (form.touched ||
                                                    form.get('taxesTotalPax' + fieldType)
                                                        .touched) &&
                                                form.get('taxesTotalPax' + fieldType).errors &&
                                                form.get('taxesTotalPax' + fieldType).errors[
                                                    'isZeroOrBelow'
                                                ]
                                            "
                                        >
                                            Ce champ ne peut être inférieur à zéro.
                                        </div>
                                    </div>

                                    <div
                                        class="input-group-text"
                                        [innerHtml]="
                                            getEnumCurrencySymbol(
                                                fieldType === 'InCurrency'
                                                    ? form.value.currency
                                                    : defaultCurrency
                                            )
                                        "
                                    ></div>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12">
                            <fieldset
                                [class.has-different-currency]="
                                    form.value.currency !== defaultCurrency
                                "
                            >
                                <legend>Prix de vente TC et CC</legend>
                                <div
                                    class="form-group"
                                    *ngFor="let fieldType of ['InCurrency', '']"
                                    [class.d-none]="
                                        fieldType === '' && form.value.currency === defaultCurrency
                                    "
                                >
                                    <div class="input-group">
                                        <input
                                            class="form-control"
                                            [id]="'sellingPrice' + fieldType"
                                            [formControlName]="'sellingPrice' + fieldType"
                                            type="number"
                                            [class.is-invalid]="
                                                (form.touched ||
                                                    form.get('sellingPrice' + fieldType).touched) &&
                                                form.get('sellingPrice' + fieldType).status ==
                                                    'INVALID'
                                                    ? 'danger'
                                                    : ''
                                            "
                                            (keyup)="
                                                updatedValue(
                                                    'sellingPrice',
                                                    fieldType === 'InCurrency'
                                                )
                                            "
                                            [placeholder]="
                                                fieldType === ''
                                                    ? 'Montant en ' +
                                                      getEnumCurrencySymbol(defaultCurrency)
                                                    : 'Laissez vide si inconnu'
                                            "
                                        />

                                        <div class="invalid-tooltip">
                                            <div
                                                *ngIf="
                                                    (form.touched ||
                                                        form.get('sellingPrice' + fieldType)
                                                            .touched) &&
                                                    form.get('sellingPrice' + fieldType).errors &&
                                                    form.get('sellingPrice' + fieldType).errors[
                                                        'required'
                                                    ]
                                                "
                                            >
                                                Ce champ est obligatoire.
                                            </div>
                                        </div>

                                        <div
                                            class="input-group-text"
                                            [innerHtml]="
                                                getEnumCurrencySymbol(
                                                    fieldType === 'InCurrency'
                                                        ? form.value.currency
                                                        : defaultCurrency
                                                )
                                            "
                                        ></div>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="notes">Notes</label>
                        <textarea
                            formControlName="notes"
                            class="form-control"
                            id="notes"
                            rows="5"
                            [class.is-invalid]="
                                (form.touched || form.get('notes').touched) &&
                                form.get('notes').status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        ></textarea>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.get('notes').touched) &&
                                    form.get('notes').errors &&
                                    form.get('notes').errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        Annuler
                    </button>
                    <button
                        type="button"
                        class="btn btn-primary"
                        (click)="saveCotation()"
                        [disabled]="form.disabled || !form.valid"
                    >
                        Sauvegarder
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="modal" tabindex="-1" role="dialog" #modalCotationAttachedDocuments>
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Documents attachés à la cotation</h5>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <ul class="list-group list-group-flush">
                <li
                    class="list-group-item list-group-item-warning text-center"
                    *ngIf="!currentCotation?.attachedDocuments?.length"
                >
                    Aucun document attaché à cette cotation.
                </li>
                <ng-container *ngIf="currentCotation?.attachedDocuments?.length">
                    <li
                        class="list-group-item"
                        *ngFor="
                            let attachedDocument of currentCotation?.attachedDocuments;
                            let i = index
                        "
                    >
                        <div class="row align-items-center">
                            <div class="col-9">
                                <a [href]="attachedDocument.url" target="_blank"
                                    >{{ attachedDocument.name }}
                                    <fa-icon [icon]="faExternalLink"></fa-icon></a
                                ><br />
                                {{ formatBytes(attachedDocument.size) }} {{ attachedDocument.type }}
                            </div>
                            <div
                                class="col-3 text-end"
                                *ngIf="
                                    hasAclAccess(EnumAcl.enquiriesEditAttachedDocumentsToCotation)
                                "
                            >
                                <button
                                    type="button"
                                    class="btn btn-danger btn-sm"
                                    (click)="deleteAttachedDocument(i)"
                                >
                                    <fa-icon [icon]="faTrash"></fa-icon>
                                </button>
                            </div>
                        </div>
                    </li>
                </ng-container>
            </ul>
            <div class="modal-footer justify-content-center">
                <button
                    type="button"
                    class="btn btn-primary"
                    (click)="triggerInputFile()"
                    *ngIf="hasAclAccess(EnumAcl.enquiriesEditAttachedDocumentsToCotation)"
                >
                    Ajout document
                </button>
            </div>
        </div>
    </div>
</div>

<input
    type="file"
    id="fileInput"
    class="form-control"
    (change)="changeInputFile($event)"
    #inputFile
/>
