import { Component, OnInit, NgZone, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { PaginationService } from '../../../services/pagination.service';
import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { Subscription } from 'rxjs';
import { BankAccountService } from 'src/app/services/bank-accounts/bank-accounts.service';
import { IBankAccount } from 'src/app/interfaces/bank-account.interface';

@Component({
  selector: 'app-bank-account-view',
  templateUrl: './bank-account-view.component.html',
  styleUrls: ['./bank-account-view.component.scss']
})
export class BankAccountViewComponent implements OnInit, OnDestroy {
  EnumAcl = EnumAcl;

  loading: boolean = false;
  bankAccountId: string;
  bankAccount: IBankAccount;
  isLogged: boolean = false;

  subscriptions = new Subscription();

  constructor(
    private remoteService: RemoteService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public paginationService: PaginationService,
    private aclService: AclService,
    private bankAccountService: BankAccountService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
  }

  ngOnInit(): void {
    this.activatedRoute.url.subscribe(() => {
      this.bankAccountId = this.activatedRoute.snapshot.paramMap.get('bankAccountId');

      this.loadData();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  loadData(): void {
    if (this.isLogged) {
      this.loadBankAccount();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  loadBankAccount(): void {
    this.loading = true;

    this.subscriptions.add(
      this.bankAccountService
        .getFromId(this.bankAccountId)
        .subscribe((bankAccount: IBankAccount) => {
          this.bankAccount = bankAccount;

          this.loading = false;
        })
    );
  }

  delete(): void {
    if (this.hasAclAccess(EnumAcl.bankAccountsDelete)) {
      const result = confirm(
        "La suppression d'un compte bancaire sera permanente. Êtes-vous sûr de vouloir continuer?"
      );

      if (result) {
        this.bankAccountService
          .delete(this.bankAccountId)
          .then(() => {
            this.router.navigate(['/admin/bank-accounts']);

            alert('La suppression a été effectuée avec succès.');
          })
          .catch(err => {
            console.log(err);
          });
      }
    }
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }
}
